import axios from 'axios';
import config from '../config';
import { meeapi } from '../node-actions/meeapi';
export const FARMER_PARTNERS_REQUEST = "FARMER_PARTNERS_REQUEST";
export const FARMER_PARTNERS_SUCCESS = "FARMER_PARTNERS_SUCCESS";
export const FARMER_PARTNERS_FAIL = "FARMER_PARTNERS_FAIL";

export const FARMER_PARTNER_UPDATE_REQUEST = "FARMER_PARTNER_UPDATE_REQUEST";
export const FARMER_PARTNER_UPDATE_SUCCESS = "FARMER_PARTNER_UPDATE_SUCCESS";
export const FARMER_PARTNER_UPDATE_FAIL = "FARMER_PARTNER_UPDATE_FAIL";

export const FARMER_PARTNER_PRODUCT_FILTER_REQUEST = "FARMER_PARTNER_PRODUCT_FILTER_REQUEST";
export const FARMER_PARTNER_PRODUCT_FILTER_SUCCESS = "FARMER_PARTNER_PRODUCT_FILTER_SUCCESS";
export const FARMER_PARTNER_PRODUCT_FILTER_FAIL = "FARMER_PARTNER_PRODUCT_FILTER_FAIL";


export const PRODUCT_FARMER_PARTNERS_DATA = "PRODUCT_FARMER_PARTNERS_DATA";

export const FARMER_PARTNER_CREATE_REQUEST = "FARMER_PARTNER_CREATE_REQUEST";
export const FARMER_PARTNER_CREATE_SUCCESS = "FARMER_PARTNER_CREATE_SUCCESS";
export const FARMER_PARTNER_CREATE_FAIL = "FARMER_PARTNER_CREATE_FAIL";











export const FARMER_PARTNER_PRODUCT_REQUEST = "FARMER_PARTNER_PRODUCT_REQUEST";
export const FARMER_PARTNER_PRODUCT_SUCCESS = "FARMER_PARTNER_PRODUCT_SUCCESS";
export const FARMER_PARTNER_PRODUCT_FAIL = "FARMER_PARTNER_PRODUCT_FAIL";


export const FARMER_PARTNERS_FILTER = "FARMER_PARTNERS_FILTER";




export const PHYSICALPARTNER_ADDRESS_REQUEST = "PHYSICALPARTNER_ADDRESS_REQUEST";
export const PHYSICALPARTNER_ADDRESS_SUCCESS = "PHYSICALPARTNER_ADDRESS_SUCCESS";
export const PHYSICALPARTNER_ADDRESS_FAIL = "PHYSICALPARTNER_ADDRESS_FAIL";

const headers = config.headersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}

export const invalid_data = () => {
  return {
    type: RESET
  }
}
/*====================================================================
                    Physical Product Partner
=======================================================================*/
export const getAllProductPartnerAction = (formData: any, pagination = {}) => async dispatch => {
  try {
    dispatch({
      type: FARMER_PARTNERS_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Product/Partner_Product_Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: FARMER_PARTNERS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: FARMER_PARTNERS_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: FARMER_PARTNERS_FAIL,
      payload: err
    });
  }
};
export const EditownPartnerproduct = (formData: any, productpartner: any, navigation: any, toast: any, partnerproductpage: any) => async dispatch => {
  dispatch({
    type: FARMER_PARTNER_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Partner_Product/Partner_Product_Update`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: FARMER_PARTNER_UPDATE_SUCCESS
        });
          navigation.navigate('FarmerPartnerProductsGet')

        toast.show({ message: 'Product Partner Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "PARTNER_PRODUCT_ID",
          SortOrder: "ASC"
        };
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Cashback_Label": "",
          "Discount_Label": "",
          "Partner_Details_Id": formData.Partner_Details_Id,
          "Records_Filter": "FILTER",
          "Partner_Email_Id": "",
          "Partner_Product_Id": "",
          "Product_Id": "",
          "Warranty_Period": ""
        };
        dispatch(getAllProductPartnerAction(formValue, pagination))
      } else {
        dispatch({
          type: FARMER_PARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FARMER_PARTNER_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};

export const getAllPartnerProductssearch = (formData: any) => async dispatch => {
   let formValues = {
      "Product_Name": formData,
      "Records_Filter": "FILTER",
    };
    try {
      dispatch({
        type: FARMER_PARTNER_PRODUCT_FILTER_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Product_Management/Product_Get_Dropdown_Search`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: FARMER_PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: FARMER_PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: FARMER_PARTNER_PRODUCT_FILTER_FAIL,
        payload: err
      });
    }
  };

//save cache data
export const productpartnerdata = (value: any) => {
  return function (dispatch) {
    dispatch({
      type: PRODUCT_FARMER_PARTNERS_DATA,
      // fType: fType,
      value: value,
    });
  }
}

export const addownPartnerproductAction = (formData: any, navigation: any, toast: any, resetForm: any, productpartnersid: any, productmasterids: any) => async dispatch => {
  dispatch({
    type: FARMER_PARTNER_CREATE_REQUEST
  });
  axios.post(`${config.url}/Partner_Product/Partner_Product_Create`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      navigation.navigate('FarmerPartnerProductsGet');
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: FARMER_PARTNER_CREATE_SUCCESS
        });
        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "PARTNER_PRODUCT_ID",
          SortOrder: "ASC"
        };
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Cashback_Label": "",
          "Discount_Label": "",
          "Partner_Details_Id": formData.Partner_Details_Id,
          "Records_Filter": "FILTER",
          "Partner_Email_Id": "",
          "Partner_Product_Id": "",
          "Product_Id": "",
          "Warranty_Period": ""
        };
        dispatch(getAllProductPartnerAction(formValue, pagination))
        resetForm()
      } else {
        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
        dispatch({
          type: FARMER_PARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FARMER_PARTNER_CREATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};
export const getAllProductmasterssearch = (formData: any) => async dispatch => {
    let formValues = {
      "search_by_filter": "",
      "search": formData,
      "Brand_Id": "",
      "Category_Id": "",
      "Is_Product_Active": "",
      "Min_Mrp": "",
      "Partner_Details_Id": "",
      "Product_Master_Id": "",
      "Product_Name": "",
      "Records_Filter": "FILTER",
      "Sku": "",
      "Status": "",
      "Upc_Code": "",
      "Warranty_Period": ""
    };
    try {
      dispatch({
        type: FARMER_PARTNER_PRODUCT_FILTER_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Product_Master/Get`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: FARMER_PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: FARMER_PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: FARMER_PARTNER_PRODUCT_FILTER_FAIL,
        payload: err
      });
    }
};

/*=============================================================
                  Get All PARTERS Action
===============================================================*/

export const getProductPartnerAction = (formData: any, pagination = {}) => async dispatch => {
  try {

    dispatch({
      type: FARMER_PARTNER_PRODUCT_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Product/Partner_Product_Get`, pagination), formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {

        dispatch({
          type: FARMER_PARTNER_PRODUCT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: FARMER_PARTNER_PRODUCT_SUCCESS,
          payload: data.results[0]
        });
      }
    })
  } catch (err) {
    dispatch({
      type: FARMER_PARTNER_PRODUCT_FAIL,
      payload: err
    });
  }
};

