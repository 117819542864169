import config from "../config";
import axios from 'axios';
export const meeapi = axios.create({
    baseURL:config.nodePartnerUrl,
    headers:config.nodeheadersCommon,
    
    // transformRequest: [function (data) {
    //   // Do whatever you want to transform the data
  
    //   return data;
    // }],

});
export const meeapicud = axios.create({
  baseURL:config.nodePartnercudUrl,
  
  // transformRequest: [function (data) {
  //   // Do whatever you want to transform the data

  //   return data;
  // }],

});
export const meenodeapi = axios.create({
  baseURL:config.nodeUserUrl,
  headers:config.nodeUserHeadersCommon,
    
    // transformRequest: [function (data) {
    //   // Do whatever you want to transform the data
  
    //   return data;
    // }],

});

// Add a request interceptor
meeapi.interceptors.request.use(
    
    request => {
        return request;
    },
    error => {
      // Do something with response error
    //   console.log('Interceptor Request API ERR:', error.message);
    //   console.log('Interceptor Request API ERR:', error.code);
    //   console.log('Interceptor Request API ERR:', error.status);


      return Promise.reject(error);
    },
  );
  
  // Add a response interceptor
  meeapi.interceptors.response.use(
    response => {
        return response;
    },
    
    error => {
      // Do something with response error
    //   console.log('Interceptor response API ERR:', error.message);
    //   console.log('Interceptor response API ERR:', error.code);
    //   console.log('Interceptor response API ERR:', error.status);
      return Promise.reject(error);
    },
  );

