import axios from 'axios';
import config from '../config';
import shopperconfig from '../shoppers/shopperconfig';
import { meeapi } from '../node-actions/meeapi';





export const FARMER_PRODUCTS_REQUEST = "FARMER_PRODUCTS_REQUEST";
export const FARMER_PRODUCTS_SUCCESS = "FARMER_PRODUCTS_SUCCESS";
export const FARMER_PRODUCTS_FAIL = "FARMER_PRODUCTS_FAIL";

export const FARMER_PRODUCTS_FILTER = "FARMER_PRODUCTS_FILTER";

export const FARMER_PRODUCT_CATEGORIES_REQUEST = "FARMER_PRODUCT_CATEGORIES_REQUEST";
export const FARMER_PRODUCT_CATEGORIES_SUCCESS = "FARMER_PRODUCT_CATEGORIES_SUCCESS";
export const FARMER_PRODUCT_CATEGORIES_FAIL = "FARMER_PRODUCT_CATEGORIES_FAIL";

export const FARMER_PRODUCT_SUBCATEGORIES_REQUEST = "FARMER_PRODUCT_SUBCATEGORIES_REQUEST";
export const FARMER_PRODUCT_SUBCATEGORIES_SUCCESS = "FARMER_PRODUCT_SUBCATEGORIES_SUCCESS";
export const FARMER_PRODUCT_SUBCATEGORIES_FAIL = "FARMER_PRODUCT_SUBCATEGORIES_FAIL";

export const FARMER_PRODUCTS_DATA = "FARMER_PRODUCTS_DATA";

export const FARMER_PRODUCT_CREATE_REQUEST = "FARMER_PRODUCT_CREATE_REQUEST";
export const FARMER_PRODUCT_CREATE_SUCCESS = "FARMER_PRODUCT_CREATE_SUCCESS";
export const FARMER_PRODUCT_CREATE_FAIL = "FARMER_PRODUCT_CREATE_FAIL";

export const FARMER_PRODUCT_UPDATE_REQUEST = "FARMER_PRODUCT_UPDATE_REQUEST";
export const FARMER_PRODUCT_UPDATE_SUCCESS = "FARMER_PRODUCT_UPDATE_SUCCESS";
export const FARMER_PRODUCT_UPDATE_FAIL = "FARMER_PRODUCT_UPDATE_FAIL";

/*=============================================================
                  Product Action
===============================================================*/
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
const cudheaders = config.nodeheadersCommon;
/*=============================================================
                  Get All Products Action
===============================================================*/

export const getAllProductAction = (formData: any, pagination = {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: FARMER_PRODUCTS_REQUEST
    });
    meeapi.post(urlGenarator(`/Product_Management/Products_Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: FARMER_PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: FARMER_PRODUCTS_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: FARMER_PRODUCTS_FAIL,
      payload: err
    });
  }
};

//page reset for te farmer
//Search 
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: FARMER_PRODUCTS_FILTER,
      fType: "search",
      value: "",
    });
  }
}
/*=============================================================
                   Get All Categories
===============================================================*/
export const getAllCategories = (id: any) => async (dispatch: any) => {
  try {
    let formData = {
      "search_by_filter": "Category_Id",
      "search": id,
      "Category_Id": "",
      "Category_Name": "",
      "Records_Filter": "ALL",
      "Status": ""
    };
    dispatch({
      type: FARMER_PRODUCT_CATEGORIES_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Category/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: FARMER_PRODUCT_CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: FARMER_PRODUCT_CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: FARMER_PRODUCT_CATEGORIES_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                Get All Sub Categories
===============================================================*/

export const getAllSubCategories = (id: any) => async (dispatch: any, pagination: any) => {
  try {
    let formData =
    {
      "search_by_filter": "Sub_Category_Id",
      "search": id,
      "Category_Id": "",
      "Sub_Category_Id": "",
      "Category_Name": "",
      "Sub_Category_Name": "",
      "Records_Filter": "ALL"

    };
    dispatch({
      type: FARMER_PRODUCT_SUBCATEGORIES_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Sub_Category_Management/sub_categories_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: FARMER_PRODUCT_SUBCATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: FARMER_PRODUCT_SUBCATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: FARMER_PRODUCT_SUBCATEGORIES_FAIL,
      payload: err
    });
  }
};

//save cache data
export const productsdata = (value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: FARMER_PRODUCTS_DATA,
      // fType: fType,
      value: value,
    });
  }
}



/*=============================================================
                  Add Product Action
===============================================================*/

export const createProductAction = (formData: any, blobArrayPics: any, navigation: any, setErrors: any, setGlobalError: any, toast: any,key:any) => async (dispatch: any) => {
  dispatch({
      type: FARMER_PRODUCT_CREATE_REQUEST
    });
    axios.post(`${config.url}/Product_Management/Product_Create`,
      formData,
      {
        headers: headers
      }
    )
      .then(response => {
        let successResponse = response.data;
        if (successResponse.Response_Status == "Success") {
  
          const formValue = {
            search: "",
            search_by_filter: "",
            Records_Filter: "FILTER",
            Partner_Details_Id: formData.Partner_Details_ID
          };
          const pagination = { PageNo: 1, PageSize: 5, SortBy: "PRODUCT_ID", SortOrder: "DESC" }
          dispatch(getAllProductAction(formValue, pagination));
          let count = 1;
          let Product_Id = successResponse.Product_Id;
          let Created_By = formData.Created_By_Email;
          let imageCreate = []
          blobArrayPics?.filter(item => { return !(!item.preview) }).map(item => {
            imageCreate.push({
              "Product_Id": Product_Id,
              "Created_By": Created_By,
              "Image_Url": item.preview,
              "Is_Default": item.isdefault,
              "Sort": item.key
            });
          })
          axios.post(`${config.nodecudurl}/Product_Images/Create`, 
         imageCreate, 
         {
            headers: cudheaders
          })
            .then(response => {
              let data = response.data;
            }).catch(error => {
           })
            navigation.navigate('FarmerProductGet');
          toast.show({ message: 'Product created Successfully.', type: 'info', duration: 3000, position: 'top' });
        } else {
          dispatch({
            type: FARMER_PRODUCT_CREATE_FAIL,
            payload: successResponse.UI_Display_Message
          });
        }
      })
      .catch(error => {  
      dispatch({
          type: FARMER_PRODUCT_CREATE_FAIL,
          payload: "Please Try Sometime Later.Currently Server is Not Working."
        });
      })
  };
  /*=============================================================
                  Update Product Action
===============================================================*/
export const updateProductAction = (formData: any, navigation: any, toast: any,EmailID:any,key:any) => async (dispatch: any) => {
  dispatch({
    type: FARMER_PRODUCT_UPDATE_REQUEST
  });

  axios.post(`${config.nodecudurl}/Product_Management/Product_Update`,
    formData, {
    headers: cudheaders
  })
    .then(response => {
      let successResponse = response.data;

      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: FARMER_PRODUCT_UPDATE_SUCCESS
        });
        const formValue = {
          search: '',
          Records_Filter: 'FILTER',
          search_by_filter:"",
          Partner_Details_Id:EmailID.Partner_Details_Id
        };
        const pagination = { PageNo: 1, PageSize: 5, SortBy: "PRODUCT_ID", SortOrder: "DESC" }
        dispatch(getAllProductAction(formValue, pagination));
        if(key){
          navigation.navigate('FarmerProductGet');
      
        }else{
          navigation.navigate('Products');
       
        }
        toast.show({ message: 'Product Updated Successfully.', type: 'info', duration: 3000, position: 'top' });

      } else {
        const errors = {};
        if (successResponse.Product_Name) {
          errors.Product_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Product_Name: { key: successResponse.Product_Name ? successResponse.Product_Name : '', message: successResponse.Product_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Product_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: FARMER_PRODUCT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FARMER_PRODUCT_CREATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working"
      });
    })
};
/*=============================================================
                  Get Single Product Action
===============================================================*/

export const getSingleProduct = (formData: any, pagination = {}, callBackProductData: any) => async (dispatch: any) => {
  try {
    meeapi.post(urlGenarator(`/Product_Management/Products_Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data) {
        callBackProductData(true, data.results[0]);
      }
    })
  } catch (err) {
    callBackProductData(false);

  }
};
export const getProductImages = (productId: any, callBackfunction: any) => async (dispatch: any) => {
  let payload = {
    "Product_ID": productId,
    "Product_Image_Id": "",
    "Records_Filter": "FILTER",
    "Status": ""
  };
  axios.post(`${shopperconfig.nodePartnerUrl}/Multi_Product_Image/Get`, payload,
    {
      headers: nodeheaders
    }
  )
    .then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === "0" && data.Response_Status == "Success") {
        callBackfunction([])
      }
      else if (data.Success_Response.Is_Data_Exist === "1" && data.Success_Response.Response_Status == "Success") {
        callBackfunction(data.results);
      } else {
        callBackfunction([]);
      }
    })
}

export const imageDefaultUpdate = (productId: any, modifiedBy: any, Product_Image_Id: any, callBackfunction: any) => async (dispatch: any) => {

  axios.put(`${config.url}/Multi_Product_Image/Update_Image`,
    {
      "Is_Default": 1,
      "Modified_By": modifiedBy,
      "Product_Id": productId,
      "Product_Image_Id": Product_Image_Id,
    }
    , {
      headers: headers
    })
    .then(response => {
      let data = response.data;
      if (data.Response_Status === "Success") {
        callBackfunction(true);
      }
      else {
        callBackfunction(false);
      }
    }).catch(error => {
      callBackfunction(false);
    })
}



// Product Image Delete
export const imageDelete = (input: any, callBackfunction: any, key: any) => async (dispatch: any) => {
  let payload = {
    "Product_Image_Id": input
  };
  axios.post(`${config.url}/Multi_Product_Image/Delete`, payload, {
    headers: headers
  })
    .then(response => {
      let data = response.data;
      callBackfunction(input, data.Response_Status == "Success" ? true : false, key);
    })
}


export const singleImageUploadUpdate = (formValues: any, productId: any, modifiedBy: any, key: any, callBackfunction: any) => async dispatch => {

  axios.post(`${config.url}/Multi_Product_Image/Update?filename=BTS&file_extension=jpg&filetype=Image&Modified_By=` + modifiedBy + `&Is_Default=0&Image_Sort=` + key + `&product_id=` + productId,
    formValues, {
    headers: headers,
    transformRequest: (d) => d,
  })
    .then(response => {
      let data = response.data;
      if (data.File_Url) {
        callBackfunction(true,data.File_Url);
      }
      else {
        callBackfunction(false);
      }
    }).catch(error => {  
      callBackfunction(false);
    })
}