import React, { useEffect, useRef } from 'react';
import { Card, Button, TextInput, HelperText, Surface, Checkbox, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import { getAllGiftAction, getAllimageids, singlegiftAction, updateGiftAction } from '../../../state/actions/giftsAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

const purposeOption = [
    {
        value: 'Birthday',
        label: 'Birthday'
    },
    {
        value: 'Anniversary',
        label: 'Anniversary'
    },
    {
        value: 'Festival',
        label: 'Festival'
    },
    {
        value: 'Occassion',
        label: 'Occasion'
    },

];


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");



const EditGifts = (props: any) => {
    //toast for popup after successful API calls.
    const toast = useToast();
    const { navigation } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Gifts_Management_Vendors_Images_User_Gifts === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const [showDropDown, setShowDropDown] = React.useState('');
    const giftsGets = props.route?.params?.Vendorgifts;
    const images = useSelector(state => state.gifts.giftImageid.imageid);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const [giftsGet, setData] = React.useState([]);
    const callBackData = (data: any) => {
        setData(data)
    }
    React.useEffect(() => {
        let formData = {

            "search": "",
            "Gift_Card_Vendor_Id": "",
            "Gift_Voucher_Id": giftsGets,
            "Order_By_Email_Id": "",
            "Records_Filter": "FILTER"
        };
        dispatch(singlegiftAction(formData, callBackData));
    }, []);
    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    /*====================================
            Handler
    ====================================*/
    const values = {
        "Gift_Card_Vendor_Id": giftsGet.Gift_Card_Vendor_Id,
        "Gift_Image_Id": giftsGet.Gift_Image_Id,
        "Gift_Message": giftsGet.Gift_Message,
        "Gift_Voucher_Id": giftsGet.Gift_Voucher_Id,
        "Is_Approved": giftsGet.Is_Approved > 0 ? true : false,
        "Modified_By": EmailID.Email_Id,
        "Order_By_Email_Id": giftsGet.Order_By_Email_Id,
        "Order_By_Name": giftsGet.Order_By_Name,
        "Order_To_Email_Id": giftsGet.Order_To_Email_Id,
        "Order_To_Moblie": giftsGet.Order_To_Moblie,
        "Order_To_Name": giftsGet.Order_To_Name,
        "Purpose": giftsGet.Purpose,
        "Total_Value": giftsGet.Total_Value,
        "Type": giftsGet.Type,
        "Voucher_Name": giftsGet.Voucher_Name
    }
    useEffect(() => {
        dispatch(getAllGiftAction());
        dispatch(getAllimageids());
    }, []);
    const Form_Validation = Yup.object().shape({
    })
    const onSubmit = (values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Is_Approved) {
                formValues.Is_Approved = 1;
            } else {
                formValues.Is_Approved = 0;
            }
            formValues.Created_By = EmailID.Email_Id;
            dispatch(updateGiftAction(formValues, props.navigation));
        }
    }
    const phoneInput = useRef(null);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        container: {
            flexShrink: 1,
        },
        texinputstyle: {
            flexGrow: 1,
            width: 200,
            marginRight: '0.5%',
            flexShrink: 1,
            marginTop: '1%',
        },
        checkboxstyle: {
            flexGrow: 1,
            width: 200,
            marginRight: '0.5%',
            flexShrink: 1,
            marginTop: '1%',
            justifyContent: 'flex-start',
            flexDirection: "row"
        },

        dropdownstyles: {
            flexGrow: 1,
            width: 200,
            marginRight: '0.5%',
            flexShrink: 1,
        },
        decsriptioninputstyle: {
            paddingTop: 10,
            width: "100%",
        },
        button: {
            marginTop: 30,
            width: '75%',
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'green',
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1,
            marginBottom: "2%"
        },

        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        checkcontainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: "-70%"
        },
        phonenumbercontainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        phoneContainer: {
            width: '100%',
            height: 50,
        },
        phonetextInput: {
            paddingVertical: 0,
        },
        containerone: {
            paddingTop: 15
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                    {(props) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        props.resetForm();
                                        // setReload(true);
                                        navigation.navigate('Gift', { giftsdetails: giftsGet.Gift_Card_Vendor_Id })
                                    }}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={styles.texinputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Gift_Card_Vendor_Id && props.errors.Gift_Card_Vendor_Id)}
                                                label="Gift Card Vendor ID"
                                                mode='outlined'
                                                value={props.values.Gift_Card_Vendor_Id}
                                                onChangeText={props.handleChange("Size")}
                                                disabled
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Gift_Card_Vendor_Id && props.errors.Gift_Card_Vendor_Id)}>
                                                {props.touched.Gift_Card_Vendor_Id && props.errors.Gift_Card_Vendor_Id}
                                            </HelperText>
                                        </View>
                                        <View style={styles.texinputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Gift_Image_Id && props.errors.Gift_Image_Id)}
                                                label="Gift Image ID"
                                                mode='outlined'
                                                disabled
                                                value={props.values.Gift_Image_Id}
                                                onChangeText={props.handleChange("Size")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Gift_Image_Id && props.errors.Gift_Image_Id)}>
                                                {props.touched.Gift_Image_Id && props.errors.Gift_Image_Id}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={styles.texinputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Voucher_Name && props.errors.Voucher_Name)}
                                                label="Voucher Name"
                                                mode='outlined'
                                                disabled
                                                value={props.values.Voucher_Name}
                                                onChangeText={props.handleChange("Voucher_Name")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Voucher_Name && props.errors.Voucher_Name)}>
                                                {props.touched.Voucher_Name && props.errors.Voucher_Name}
                                            </HelperText>
                                        </View>
                                        <View style={styles.dropdownstyles}>
                                            <View style={styles.container}>
                                                <View style={styles.containerone}>
                                                    <Text style={[styles.dropdownlabel, Boolean(props.touched.Purpose && props.errors.Purpose) && { color: '#b00020' }]}>
                                                        Purpose
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        data={purposeOption}
                                                        style={[styles.dropdown, Boolean(props.touched.Purpose && props.errors.Purpose) && { borderColor: '#b00020' }]}
                                                        labelField="label"
                                                        valueField="value"
                                                        maxHeight={purposeOption.length > 1 ? 250 : 200}
                                                        search={purposeOption.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Purpose'}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        maxHeight={180}
                                                        disable
                                                        value={props.values.Purpose}
                                                        onUpdateValue={item => {
                                                            props.setFieldValue('Purpose', item.value)
                                                            setShowDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(props.touched.Purpose && props.errors.Purpose)}>
                                                        <ErrorMessage name="Purpose" />
                                                    </HelperText>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={styles.texinputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Order_By_Email_Id && props.errors.Order_By_Email_Id)}
                                                label="Order By Email ID"
                                                mode='outlined'
                                                disabled
                                                value={props.values.Order_By_Email_Id}
                                                onChangeText={props.handleChange("Order_By_Email_Id")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Order_By_Email_Id && props.errors.Order_By_Email_Id)}>
                                                {props.touched.Order_By_Email_Id && props.errors.Order_By_Email_Id}
                                            </HelperText>
                                        </View>
                                        <View style={styles.texinputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Order_By_Name && props.errors.Order_By_Name)}
                                                label="Order By Name"
                                                mode='outlined'
                                                disabled
                                                value={props.values.Order_By_Name}
                                                onChangeText={props.handleChange("Order_By_Name")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Order_By_Name && props.errors.Order_By_Name)}>
                                                {props.touched.Order_By_Name && props.errors.Order_By_Name}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={styles.texinputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Order_To_Email_Id && props.errors.Order_To_Email_Id)}
                                                label="Order To Email ID"
                                                mode='outlined'
                                                disabled
                                                value={props.values.Order_To_Email_Id}
                                                onChangeText={props.handleChange("Order_To_Email_Id")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Order_To_Email_Id && props.errors.Order_To_Email_Id)}>
                                                {props.touched.Order_To_Email_Id && props.errors.Order_To_Email_Id}
                                            </HelperText>
                                        </View>
                                        <View style={styles.texinputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Order_To_Name && props.errors.Order_To_Name)}
                                                label="Order To Name"
                                                mode='outlined'
                                                disabled
                                                value={props.values.Order_To_Name}
                                                onChangeText={props.handleChange("Order_To_Name")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Order_To_Name && props.errors.Order_To_Name)}>
                                                {props.touched.Order_To_Name && props.errors.Order_To_Name}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>

                                        <View style={styles.texinputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Type && props.errors.Type)}
                                                label="Type"
                                                mode='outlined'
                                                disabled
                                                value={props.values.Type}
                                                onChangeText={props.handleChange("Type")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Type && props.errors.Type)}>
                                                {props.touched.Type && props.errors.Type}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={styles.texinputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Total_Value && props.errors.Total_Value)}
                                                label="Voucher Value"
                                                mode='outlined'
                                                disabled
                                                value={props.values.Total_Value}
                                                onChangeText={props.handleChange("Total_Value")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Total_Value && props.errors.Total_Value)}>
                                                {props.touched.Total_Value && props.errors.Total_Value}
                                            </HelperText>
                                        </View>
                                        <View style={styles.checkboxstyle} >
                                            <Checkbox.Android
                                                color={esiColor.itemColor}
                                                uncheckedColor={esiColor.SBorderColor}
                                                name="Is_Approved"
                                                status={props.values.Is_Approved ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Is_Approved", !props.values.Is_Approved);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, color: esiColor.Text }}>Is Approved</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={styles.decsriptioninputstyle}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Gift_Message && props.errors.Gift_Message)}
                                                label="Gift Message"
                                                mode='outlined'
                                                disabled
                                                multiline
                                                value={props.values.Gift_Message}
                                                onChangeText={props.handleChange("Gift_Message")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Gift_Message && props.errors.Gift_Message)}>
                                                {props.touched.Gift_Message && props.errors.Gift_Message}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <Button textColor={esiColor.itemButtenColor} style={{ width: 130, marginLeft: "auto", marginRight: "auto", marginTop: '2%', backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                        Save
                                    </Button>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default EditGifts;