import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';

export const SBCPARTNERCITY_REQUEST = "SBCPARTNERCITY_REQUEST";
export const SBCPARTNERCITY_SUCCESS = "SBCPARTNERCITY_SUCCESS";
export const SBCPARTNERCITY_FAIL = "SBCPARTNERCITY_FAIL";

export const SBCPARTNERCITY_GET_REQUEST = "SBCPARTNERCITY_GET_REQUEST";
export const SBCPARTNERCITY_GET_SUCCESS = "SBCPARTNERCITY_GET_SUCCESS";
export const SBCPARTNERCITY_GET_FAIL = "SBCPARTNERCITY_GET_FAIL";

export const SBCPARTNERCITY_CREATE_REQUEST = "SBCPARTNERCITY_CREATE_REQUEST";
export const SBCPARTNERCITY_CREATE_SUCCESS = "SBCPARTNERCITY_CREATE_SUCCESS";
export const SBCPARTNERCITY_CREATE_FAIL = "SBCPARTNERCITY_CREATE_FAIL";

export const SBCPARTNERCITY_UPDATE_REQUEST = "SBCPARTNERCITY_UPDATE_REQUEST";
export const SBCPARTNERCITY_UPDATE_SUCCESS = "SBCPARTNERCITY_UPDATE_SUCCESS";
export const SBCPARTNERCITY_UPDATE_FAIL = "SBCPARTNERCITY_UPDATE_FAIL";

export const SBCPARTNERCITY_DELETE_REQUEST = "SBCPARTNERCITY_DELETE_REQUEST";
export const SBCPARTNERCITY_DELETE_SUCCESS = "SBCPARTNERCITY_DELETE_SUCCESS";
export const SBCPARTNERCITY_DELETE_FAIL = "SBCPARTNERCITY_DELETE_FAIL";

export const SBCPARTNER_CITIES_REQUEST = "SBCPARTNER_CITIES_REQUEST";
export const SBCPARTNER_CITIES_SUCCESS = "SBCPARTNER_CITIES_SUCCESS";
export const SBCPARTNER_CITIES_FAIL = "SBCPARTNER_CITIES_FAIL";

export const SBCPARTNERCITY_FILTER = "SBCPARTNERCITY_FILTER";

/*=============================================================
                  Get All SBCPARTNERCITY Action
===============================================================*/
const cudheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
  }
export const getAllSBCPartnerCityAction = (formData: any,pagination = {}) => async dispatch => {
    try {
        dispatch({
            type: SBCPARTNERCITY_REQUEST
        });
        meeapi.post(urlGenarator(
            `/Partner_Service_City/PSC_Get`,pagination),
            formData,
        ).then(response => {
            let data = response.data;

            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SBCPARTNERCITY_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SBCPARTNERCITY_SUCCESS,
                    payload: data
                });
            }
        })
    } catch (err) {
        dispatch({
            type: SBCPARTNERCITY_FAIL,
            payload: err
        });
    }
};


/*=============================================================
                  SBCPARTNERCITY Create Action
===============================================================*/

export const createSBCPartnerCityAction = (formData: any, navigation: any, toast: any) => async dispatch => {
    dispatch({
        type: SBCPARTNERCITY_CREATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Partner_Service_City/PSC_Create`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SBCPARTNERCITY_CREATE_SUCCESS
                });
                navigation.navigate('SBCPartnercityGet')
                toast.show({ message: 'Partner city added successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                        "search": "",
                        "Partner_Service_City_Id": "",
                        "City_Id": "",
                        "Partner_Details_Id":formData.Partner_Details_Id,
                        "Partner_Name": "",
                        "Records_Filter": "FILTER"
                    };
                const pagination ={ PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_ID", SortOrder: "DESC" }
                dispatch(getAllSBCPartnerCityAction(formValue,pagination))
            } else {
                const errors = {};
                dispatch({
                    type: SBCPARTNERCITY_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SBCPARTNERCITY_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


/*=============================================================
                  SBCPARTNERCITY Update Action
===============================================================*/

export const updateSBCPartnerCityAction = (formData: any, navigation: any, toast: any) => async dispatch => {
    dispatch({
        type: SBCPARTNERCITY_UPDATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Partner_Service_City/PSC_Update`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SBCPARTNERCITY_UPDATE_SUCCESS
                });
                navigation.navigate('SBCPartnercityGet')
                toast.show({ message: 'Partner city updated successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    "search": "",
                    "Partner_Service_City_Id": "",
                    "City_Id": "",
                    "Partner_Details_Id":formData.Partner_Details_Id,
                    "Partner_Name": "",
                    "Records_Filter": "FILTER"
                };
            const pagination ={ PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_ID", SortOrder: "DESC" }
            dispatch(getAllSBCPartnerCityAction(formValue,pagination))
       
            } else {
                const errors = {};
                dispatch({
                    type: SBCPARTNERCITY_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SBCPARTNERCITY_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

/*=============================================================
                  SBCPARTNERCITY Delete
===============================================================*/

export const deleteSBCPartnerCityAction = (formData: any, toast: any) => async dispatch => {
    try {
        let { data, status } = await axios.post(`${config.url}/Partner_Service_City/PSC_Delete`, formData, {
            headers: headers
        });
        if (data) {
            toast.show({ message: 'Partner city deleted successfully.', type: 'info', duration: 3000, position: 'top' });
            const formValue = {
                "search": "",
                "Partner_Service_City_Id": "",
                "City_Id": "",
                "Partner_Details_Id":formData.Partner_Details_Id,
                "Partner_Name": "",
                "Records_Filter": "FILTER"
            };
        const pagination ={ PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_ID", SortOrder: "DESC" }
        dispatch(getAllSBCPartnerCityAction(formValue,pagination))
   
        }
    } catch (err) {
    }
};

/*=============================================================
                  Get All CITIES Action
===============================================================*/
export const getAllCities = () => async dispatch => {
    try {
        let formData = {
            Records_Filter: "ALL",
            Status: ""
        }
        dispatch({
            type: SBCPARTNER_CITIES_REQUEST
        });
        let { data } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Partner_Service_City/City_List_get`,
            formData,
            {
                headers: nodeheaders
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SBCPARTNER_CITIES_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SBCPARTNER_CITIES_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SBCPARTNER_CITIES_FAIL,
            payload: err
        });
    }
};


// Filter 
export const SBCPartnerCityFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: SBCPARTNERCITY_FILTER,
            fType: fType,
            value: value,
        });
    }
}

//search
export const resetFilter = () => {
    return function (dispatch: any) {
      dispatch({
        type: SBCPARTNERCITY_FILTER,
       fType: "search", 
        value: "",
      });
    }
  }

export const getIdSBCAction = (formData: any, callBackData: any) => async dispatch => {
    try {
      dispatch({
        type: SBCPARTNERCITY_REQUEST
      });
      let { data} = await axios.post(
        `${shopperconfig.nodePartnerUrl}/Partner_Service_City/PSC_Get`,
        formData,
        {
          // Axios looks for the `auth` option, and, if it is set, formats a
          // basic auth header for you automatically.
          headers: nodeheaders
        }
      );
      if (data) {
        callBackData(data?.results[0])
      }
    } catch (err) {
      dispatch({
        type: SBCPARTNERCITY_FAIL,
        payload: err
      });
    }
  };