import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';

export const PRODUCTMASTERS_REQUEST = "PRODUCTMASTERS_REQUEST";
export const PRODUCTMASTERS_SUCCESS = "PRODUCTMASTERS_SUCCESS";
export const PRODUCTMASTERS_FAIL = "PRODUCTMASTERS_FAIL";

export const PRODUCTMASTERS_DATA = "PRODUCTMASTERS_DATA";

export const PRODUCTMASTER_GET_REQUEST = "PRODUCTMASTER_GET_REQUEST";
export const PRODUCTMASTER_GET_SUCCESS = "PRODUCTMASTER_GET_SUCCESS";
export const PRODUCTMASTER_GET_FAIL = "PRODUCTMASTER_GET_FAIL";

export const PRODUCTMASTER_CREATE_REQUEST = "PRODUCTMASTER_CREATE_REQUEST";
export const PRODUCTMASTER_CREATE_SUCCESS = "PRODUCTMASTER_CREATE_SUCCESS";
export const PRODUCTMASTER_CREATE_FAIL = "PRODUCTMASTER_CREATE_FAIL";
export const PRODUCTMASTER_UPDATE_REQUEST = "PRODUCTMASTER_UPDATE_REQUEST";
export const PRODUCTMASTER_UPDATE_SUCCESS = "PRODUCTMASTER_UPDATE_SUCCESS";
export const PRODUCTMASTER_UPDATE_FAIL = "PRODUCTMASTER_UPDATE_FAIL";
export const PRODUCTMASTER_DELETE_REQUEST = "PRODUCTMASTER_DELETE_REQUEST";
export const PRODUCTMASTER_DELETE_SUCCESS = "PRODUCTMASTER_DELETE_SUCCESS";
export const PRODUCTMASTER_DELETE_FAIL = "PRODUCTMASTER_DELETE_FAIL";

export const PRODUCT_BRANDS_REQUEST = "PRODUCT_BRANDS_REQUEST";
export const PRODUCT_BRANDS_SUCCESS = "PRODUCT_BRANDS_SUCCESS";
export const PRODUCT_BRANDS_FAIL = "PRODUCT_BRANDS_FAIL";

export const PRODUCT_CATEGORIES_REQUEST = "PRODUCT_CATEGORIES_REQUEST";
export const PRODUCT_CATEGORIES_SUCCESS = "PRODUCT_CATEGORIES_SUCCESS";
export const PRODUCT_CATEGORIES_FAIL = "PRODUCT_CATEGORIES_FAIL";

export const PRODUCT_SUBCATEGORIES_REQUEST = "PRODUCT_SUBCATEGORIES_REQUEST";
export const PRODUCT_SUBCATEGORIES_SUCCESS = "PRODUCT_SUBCATEGORIES_SUCCESS";
export const PRODUCT_SUBCATEGORIES_FAIL = "PRODUCT_SUBCATEGORIES_FAIL";


export const PRODUCTMASTERS_FILTER = "PRODUCTMASTERS_FILTER";


const nodeheaders = shopperconfig.nodeheadersCommon;
/*=============================================================
                Get All Wallet Action
===============================================================*/
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
export const invalid_data = () => {
  return {
    type: RESET
  }
}
export const getAllProductMasterAction = (formData: any, pagination = {}) => async dispatch => {

  try {

    dispatch({
      type: PRODUCTMASTER_GET_REQUEST
    });

    meeapi.post(urlGenarator(`Product_Master/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCTMASTERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCTMASTERS_SUCCESS,
          payload: data
        });
      }
    })
  }
  catch (err) {
    dispatch({
      type: PRODUCTMASTER_GET_FAIL,
      payload: err
    });
  }
};


// Filter 
export const productmasterFilter = (fType: any, value: any) => {
  return function (dispatch) {
    dispatch({
      type: PRODUCTMASTERS_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//search reset
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: PRODUCTMASTERS_FILTER,
      fType: "search",
      value: "",
    });
  }
}
//save cache data
export const productmasterdata = (value: any) => {
  return function (dispatch) {
    dispatch({
      type: PRODUCTMASTERS_DATA,
      value: value,
    });
  }
}
export const productmastersdata = (value: any) => {
  return function (dispatch) {
    dispatch({
      type: PRODUCTMASTERS_DATA,
      // fType: fType,
      value: value,
    });
  }
}
/*=============================================================
                   Get All Categories
===============================================================*/
export const getAllCategories = (id: any) => async dispatch => {
  try {
    let formData = {
      "search_by_filter": "Category_Name",
      "search": id,
      "Category_Id": "",
      "Category_Name": "",
      "Records_Filter": "ALL",
      "Status": ""
    };
    dispatch({
      type: PRODUCT_CATEGORIES_REQUEST
    });
    let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/Category/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_CATEGORIES_FAIL,
      payload: err
    });
  }
};


/*=============================================================
                  Add Wallet Action
===============================================================*/
export const createProductMasterAction = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: PRODUCTMASTER_CREATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Product_Master/Create`,
    formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PRODUCTMASTER_CREATE_SUCCESS
        });
        const formData = {
          Records_Filter: "ALL",
          Category_Id: "",
          Category_Name: ""
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "Product_Master_Id",
          SortOrder: "ASC"
        }
        dispatch(getAllProductMasterAction(formData, pagination))
        navigation.navigate('ProductMasterGet');
        toast.show({ message: 'Product Master Add Successfully.', type: 'info', duration: 3000, position: 'top' });

      } else {
        dispatch({
          type: PRODUCTMASTER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })

    .catch(error => {
      dispatch({
        type: PRODUCTMASTER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Get All Brands Action
===============================================================*/

export const getAllBrands = (id:any) => async dispatch => {
  try {
    let formData = {
      "search_by_filter": "Brand_Name",
      "search": id,
      "Brand_Id": "",
      "Brand_Name": "",
      "Brand_Status": "",
      "Esi_Rating": "",
      "Records_Filter": "ALL",
      "Todays_Hot": "",
      "Top_Brands": ""
    };
    dispatch({
      type: PRODUCT_BRANDS_REQUEST
    });
    let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/Brand/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_BRANDS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_BRANDS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_BRANDS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Sub Categories
===============================================================*/

export const getAllSubCategories = (id:any) => async dispatch => {
  try {
    let formData =
    {
      "search_by_filter": "Sub_Category_Name",
      "search": id,
      "Category_Id": "",
      "Sub_Category_Id": "",
      "Category_Name": "",
      "Sub_Category_Name": "",
      "Records_Filter": "ALL"
    };
    dispatch({
      type: PRODUCT_SUBCATEGORIES_REQUEST
    });
    let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/Sub_Category_Management/sub_categories_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_SUBCATEGORIES_SUCCESS,
          payload: []
        });
      } else {

        dispatch({
          type: PRODUCT_SUBCATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_SUBCATEGORIES_FAIL,
      payload: err
    });
  }
};


/*=============================================================
                Update User Action
===============================================================*/

export const updateProductMasterAction = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: PRODUCTMASTER_UPDATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Product_Master/update`,
    formData,
    {
      headers: cudheaders
    }
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PRODUCTMASTER_UPDATE_SUCCESS
        });
        const formData = {
          Records_Filter: "ALL",
          Category_Id: "",
          Category_Name: ""
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "Product_Master_Id",
          SortOrder: "ASC"
        }
        dispatch(getAllProductMasterAction(formData, pagination))
        navigation.navigate('ProductMasterGet');
        toast.show({ message: 'Product Master Updated Successfully.', type: 'info', duration: 3000, position: 'top' });

      } else {
        dispatch({
          type: PRODUCTMASTER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTMASTER_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};


export const getProductByIdAction = (formData: any, callBackData: any) => async dispatch => {

  try {
    dispatch({
      type: PRODUCTMASTERS_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Product_Master/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === "0") {
        callBackData([])
      } else {

        callBackData(data.results)
      }
    }
  } catch (err) {

    dispatch({
      type: PRODUCTMASTERS_FAIL,
      payload: err
    });
  }
};


export const subcategoryAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Sub_Category_Management/sub_categories_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)

    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};
