    import React, { useState } from 'react';
    import { useDispatch, useSelector } from 'react-redux';
    import * as Yup from 'yup';
    import { Formik } from 'formik';
    import { Button, Card, HelperText, Surface, Text, TextInput } from 'react-native-paper';
    import { Dimensions, ScrollView, StyleSheet, View, RefreshControl } from 'react-native';
    import AsyncStorage from '@react-native-async-storage/async-storage';
    import { AddPartnerBiddingQuotesAction, getUserBiddingQuotesAction } from '../../state/actions/userNewbiddingQuotesAction';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { getAllAddressforBiddingAction } from '../../state/actions/biddingProductsAction';
import Header from '../auth/components/header';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
    
    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    
    const UserBiddingProductsOrderNotification = (props: any) => {
      const dispatch = useDispatch();
      const toast = useToast();
      const { navigation } = props;
      const ID = props.route?.params?.id;
      const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
      const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
      const isSubmited = useSelector(state => state.biddingproducts.biddingCreate.isLoading);
      const EmailID = useSelector(state => state.auth?.user);
      const [paginationforaddress, setPaginationForAdress] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_ADDRESS_ID", SortOrder: "DESC" });
    
      const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "User_Products_Bidding_Id", SortOrder: "DESC" });
      // Role restriction
      const permissions = useSelector(state => state.auth.permissions);
      React.useEffect(() => {
        const signNavigation = async () => {
          const user = await AsyncStorage.getItem("user");
          const user_parsed = JSON.parse(user);
          if (!user_parsed?.Partner_Session_Id) {
            navigation.navigate('SignIn');
          }
        };
        signNavigation();
      });
      React.useEffect(() => {
        if (!(permissions.Employees === "write")) {
          toast.show({
            message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
          });
        }
      }, [permissions]);
      const [addressdata, setAddresData] = useState([]);
      const [userbiddata, setUserBidData] = useState([]);
      const callBackAddressData = (data: any) => {
        setAddresData(data)
      }
      const callBackUserBidData = (data: any) => {
        setUserBidData(data)
      }
      let addressdatalist = []
      if (addressdata) {
        if (addressdata[0]) {
          for (let i = 0; i < addressdata.length; i++) {
            addressdatalist.push({
              label: addressdata[i].Location,
              value: addressdata[i].Partner_Address_Id
            })
          }
        }
      }
    
      React.useEffect(() => {
        let addressForm = {
          "search_by_filter": "",
          "search": "",
          "Partner_Address_Id": "",
          "Partner_Details_Id": EmailID?.Partner_Details_Id,
          "Records_Filter": "FILTER"
        }
        let formData = {
          "search_by_filter": "",
          "search": "",
          "User_Email_Id": "",
          "User_Products_Bidding_Id": ID,
          "Bidding_Product_Id": '',
          "User_Address_Id": "",
          "Records_Filter": "FILTER",
          "Bidding_Status": "",
          "Partner_Detail_Id": EmailID.Partner_Details_Id
        };
        dispatch(getAllAddressforBiddingAction(addressForm, callBackAddressData, paginationforaddress))
        dispatch(getUserBiddingQuotesAction(formData, callBackUserBidData, pagination))
      }, [permissions]);
      const [refreshing, setRefreshing] = React.useState(false);
      const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
      }
      const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
      }, []);
      React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
          "change",
          ({ window, screen }) => {
            setDimensions(window.width);
          }
        );
      });
    
      // Alert Popup
      const [addressshowDropDown, setAddressShowDropDown] = useState(false);
    
      //form reset
      const [reload, setReload] = React.useState(false);
    
      React.useEffect(() => {
        if (reload)
          setReload(false);
      }, [reload])
    
      return (
        <Surface style={{ flex: 1 }} >
          <Header navigation={navigation} />
          <ScrollView refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              colors={['#27B6CC']}
              tintColor={'#27B6CC'}
              title={"Pull to refresh"}
              titleColor={"#27B6CC"}
            />
          }>
            {!reload &&
              <Formik
                enableReinitialize={true}
                initialValues={{
                  "Advance_Token_Amount": '',
                  "Bidding_Status": userbiddata?.Bidding_Status,
                  "Created_By": EmailID.Email_Id,
                  "Partner_Bidding_Available_Product_Id": userbiddata?.Partner_Bidding_Available_Product_Id,
                  "Partner_Comments": '',
                  "Partner_Detail_Id": EmailID.Partner_Details_Id,
                  "Partner_Location": '',
                  "Quotation_Amount": '',
                  "Status": userbiddata?.Status,
                  "User_Products_Bidding_Id": userbiddata?.User_Products_Bidding_Id
                }}
    
                validationSchema={Yup.object().shape({
                  Advance_Token_Amount: Yup.number().moreThan(0, 'Advance Token Amount than to 0.').required('Advance Token Amount is required.'),
                  Quotation_Amount:Yup.number().moreThan(0, 'Quotation Amount than to 0.').required('Quotation Amount is required.'),
                  Partner_Comments: Yup.string().required('Partner Comments is required.'),
                  Partner_Location: Yup.string().required('Partner Address is required.'),
                })}
    
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  let submitTest = true;
                  setSubmitting(false);
                  if (values) {
                    let formData = JSON.parse(JSON.stringify(values));
                    dispatch(AddPartnerBiddingQuotesAction(formData, navigation,toast))
                  }
                }
                }
              >
    
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                  resetForm
                }) => (
                  <View style={{
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    flexWrap: "wrap"
                  }}>
                    <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                      <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                        onPress={() => {
                          resetForm();
                          setReload(true);
                          navigation.navigate('Notification')
                        }}
                      >
                        Go Back
                      </Button>
                    </View>
                    <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#d6d6d7' }}>
                      <Card.Content>
                        <Text style={{ fontSize: 20, fontWeight: "bold", paddingBottom: 20 }}>
                          User Request Details
                        </Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>Bidding Request ID:</Text><Text> {userbiddata?.User_Products_Bidding_Id}</Text>
                          </View>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>User Email ID:</Text><Text> {userbiddata?.User_Email_Id}</Text>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5, }}>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>Product Name:</Text><Text> {userbiddata?.Product_Name}</Text>
                          </View>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>Product ID:</Text><Text> {userbiddata?.Bidding_Product_Id}</Text>
                          </View>
                        </View>
    
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>QTY:</Text><Text> {userbiddata?.Quantity} {userbiddata?.Measurement_Type ? "(" + userbiddata?.Measurement_Type + ")" : null}</Text>
                          </View>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>Address:</Text><Text> {userbiddata?.City}, {userbiddata?.User_Pincode}</Text>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>Bid Date:</Text><Text> {userbiddata?.Created_Date}</Text>
                          </View>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>Bid Expiry Date: </Text><Text>{userbiddata?.Bidding_Expiry_Date}</Text>
                          </View>
                        </View>
                      </Card.Content>
                    </Card>
                    <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#d6d6d7' }}>
                      <Card.Content>
                        <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                          Quotation Form
                        </Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <TextInput
                              style={{ backgroundColor: 'white' }}
                              theme={{ colors: { primary: '#27B6CC' } }}
                              error={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}
                              onBlur={handleBlur}
                              onChangeText={handleChange('Quotation_Amount')}
                              label="Quotation Amount"
                              name="Quotation_Amount"
                              value={values.Quotation_Amount}
                              mode="outlined"
                              rupee
                              left={<TextInput.Icon name="currency-inr" />}
                            />
                            <HelperText type="error" visible={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}>
                              {touched.Quotation_Amount && errors.Quotation_Amount}
                            </HelperText>
                          </View>
    
                          <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <TextInput
                              style={{ backgroundColor: 'white' }}
                              theme={{ colors: { primary: '#27B6CC' } }}
                              error={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}
                              onBlur={handleBlur}
                              onChangeText={handleChange('Advance_Token_Amount')}
                              label="Advance Token Amount"
                              name="Advance_Token_Amount"
                              value={values.Advance_Token_Amount}
                              mode="outlined"
                              left={<TextInput.Icon name="currency-inr" />}
                            />
                            <HelperText type="error" visible={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}>
                              {touched.Advance_Token_Amount && errors.Advance_Token_Amount}
                            </HelperText>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                            <TextInput
                              style={{ backgroundColor: 'white' }}
                              theme={{ colors: { primary: '#27B6CC' } }}
                              error={Boolean(touched.Partner_Comments && errors.Partner_Comments)}
                              onBlur={handleBlur}
                              onChangeText={handleChange('Partner_Comments')}
                              label="Partner Comments"
                              name="Partner_Comments"
                              value={values.Partner_Comments}
                              mode="outlined"
                            />
                            <HelperText type="error" visible={Boolean(touched.Partner_Comments && errors.Partner_Comments)}>
                              {touched.Partner_Comments && errors.Partner_Comments}
                            </HelperText>
                          </View>
                          <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <View style={styles.container}>
                              <Text style={[styles.dropdownlabel, Boolean(touched.Partner_Location && errors.Partner_Location) && { color: '#b00020' }]}>
                                Partner Address
                              </Text>
                              <EsiSearchDropdown
                                style={[styles.dropdown, Boolean(touched.Partner_Location && errors.Partner_Location) && { borderColor: '#b00020' }]}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                data={addressdatalist}
                                labelField="label"
                                valueField="value"
                                maxHeight={addressdatalist.length > 1 ? 250 : 200}
                                search={addressdatalist.length > 1 ? true : false}
                                searchPlaceholder={'Select Partner Address'}
                                value={values.Partner_Location}
                                onFocus={() => setAddressShowDropDown(true)}
                                onBlur={() => setAddressShowDropDown(false)}
                                onUpdateValue={item => {
                                  setFieldValue('Partner_Location', item.value)
                                  setAddressShowDropDown(false);
                                }}
                              />
                              <HelperText type="error" visible={Boolean(touched.Partner_Location && errors.Partner_Location)}>
                                {touched.Partner_Location && errors.Partner_Location}
                              </HelperText>
                            </View>
                          </View>
                        </View>
                      </Card.Content>
                      <View style={{ marginHorizontal: 30 }}>
                        <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                      </View>
                      <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                        <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                          Save
                        </Button>
                      </View>
                    </Card>
                  </View>
                )}
              </Formik>}
          </ScrollView>
        </Surface>
      );
    };
    const styles = StyleSheet.create({
      container: {
        backgroundColor: 'white',
        paddingTop: 15
      },
      cardCircle: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2%",
        marginBottom: "2%",
        borderRadius: 6,
        borderWidth: 0.01,
        borderColor: '#D6D6D7'
      },
      itemContainer: {
        justifyContent: 'flex-end',
        flex: 1
      },
    
      checkbox: {
        alignSelf: "center",
      },
      label: {
        marginBottom: 30,
      },
      phonetextInput: {
        flex: 1
      },
      dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
      },
      icon: {
        marginRight: 5,
      },
      dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
      },
      placeholderStyle: {
        fontSize: 16,
      },
      selectedTextStyle: {
        fontSize: 12,
      },
      iconStyle: {
        width: 20,
        height: 20,
      },
      inputSearchStyle: {
        height: 40,
        fontSize: 16,
      },
    });
    export default UserBiddingProductsOrderNotification;