import React, { useState } from 'react';
import data from './userdata';
import { ScrollView, StyleSheet, View } from 'react-native';
import UserProductCard from './userProductCard';
import B2BProductCard from './b2bProductCard';
import { Surface, Text } from 'react-native-paper';
import Header from '../auth/components/header';
import b2bdata from './b2bdata';
import { useSelector } from 'react-redux';

const styles = StyleSheet.create({
  UserproductCard: {
    height: '100%'
  },
  B2BproductCard: {
    height: '100%'
  }
});

const PartnerBiddingMain = (props: any) => {
  const { navigation } = props;
  const [products] = useState(data);
  const esiColor = useSelector(state => state.theme);

  const [product] = useState(b2bdata);

  return (
    <Surface style={{ flex: 1 ,backgroundColor:esiColor.BackgroundColor }}>

      <Header navigation={navigation} />
      <ScrollView>
        <View style={{ justifyContent: 'center', alignItems: 'center',backgroundColor: esiColor.BackgroundColor, }}>
          <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10 ,color:esiColor.brandFontColor}}>
            User Bidding Quotes
          </Text>
        </View>
        <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
          {products.map(product => (
            <View key={product.id}>
              <UserProductCard
                style={styles.UserproductCard} esiColor={esiColor}
                product={product} navigation={navigation}
              />
            </View>
          ))}
        </View>

        <View style={{ justifyContent: 'center', alignItems: 'center',backgroundColor: esiColor.BackgroundColor }}>
          <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10,color:esiColor.brandFontColor }}>
            B2B Bidding Quotes
          </Text>
        </View>
        <View style={{ justifyContent: 'center',  flexDirection: 'row', flexWrap: 'wrap' }}>
          {product.map((product,i) => (
            <View key={i}>
              <B2BProductCard
                style={styles.B2BproductCard} esiColor={esiColor}
                product={product} navigation={navigation}
              />
            </View>
          ))}
        </View>
      </ScrollView>
    </Surface>
  );
};

export default PartnerBiddingMain;