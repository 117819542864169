
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  updateProductMasterAction,
  getAllBrands,
  getAllCategories,
  getAllSubCategories,
  getProductByIdAction

} from '../../../../state/actions/productmastersAction';
import { Card, Button, HelperText, Checkbox, Surface } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import EsiEditor from '../../../../components/custom/richtext-editor/richtext-editor';
import Header from '../../../auth/components/header';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import isEmpty from '../../../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const EditProductMasterthree = (props: any) => {
  const productmasters = useSelector(state => state.productmasters?.productmastersdata?.productmaster);
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);
  const toast = useToast();
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const ID = props?.route?.params?.ID;
  const CID = props?.route?.params?.CID;
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [descriptionvalue, setDescriptionValue] = React.useState();
  const [data, setData] = useState([]);

  const callbackProductData = (data: any) => {
    setData(data)

  }
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (ID) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Brand_Id": "",
        "Category_Id": "",
        "Is_Product_Active": "",
        "Min_Mrp": "",
        "Partner_Details_Id": "",
        "Product_Master_Id": ID,
        "Product_Name": "",
        "Records_Filter": "FILTER",
        "Sku": "",
        "Status": "",
        "Upc_Code": "",
        "Warranty_Period": ""
      };
      dispatch(getAllCategories());
      dispatch(getAllBrands());
      dispatch(getAllSubCategories());
      dispatch(getProductByIdAction(formData, callbackProductData));
    }
  }, [ID]);

  React.useEffect(() => {
    if (ID) {
      let formData = {
        Records_Filter: "FILTER",
        Product_Master_Id: ID
      };
      dispatch(getAllCategories());
      dispatch(getAllBrands());
      dispatch(getAllSubCategories());
      dispatch(getProductByIdAction(formData, callbackProductData));
    }
  }, [ID]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  React.useEffect(() => {
    if (isEmpty(productmasters)) {
      navigation.navigate('EditProductMaster', { ID, CID })
    } else {
      setDescriptionValue(productmasters?.Long_Description)
    }
  }, [productmasters]
  );
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  return (
    <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {descriptionvalue && <Formik
          initialValues={{
            Product_Master_Id: productmasters.Product_Master_Id,
            Sku: productmasters.Sku,
            Category: productmasters.Category,
            Sub_Category: productmasters.Sub_Category,
            Brand: productmasters.Brand,
            Product_Name: productmasters.Product_Name,
            Product_Type: productmasters.Product_Type,
            Pattern_Name: productmasters.Pattern_Name,
            Upc_Code: productmasters.Upc_Code,
            Height: productmasters.Height,
            Weight: productmasters.Weight,
            Width: productmasters.Width,
            Length: productmasters.Length,
            Manufacturer: productmasters.Manufacturer,
            Manufacturer_Contact: productmasters.Manufacturer_Contact,
            Manufacturer_Cost: productmasters.Manufacturer_Cost,
            Mrp: productmasters.Mrp,
            Max_Mrp: productmasters.Max_Mrp,
            Min_Mrp: productmasters.Min_Mrp,
            Short_Description: productmasters.Short_Description,
            Modified_By: productmasters.Modified_By,
            Features: productmasters.Features,
            Is_Product_Active: productmasters.Is_Product_Active > 0 ? true : false,
            Long_Description: productmasters.Long_Description,
            Sales_Start_Date: productmasters.Sales_Start_Date,
            Specifications: productmasters.Specifications,
            Status: productmasters.Status,
            Warranty_Period: productmasters.Warranty_Period,
          }}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            let submitTest = true;
            setSubmitting(false);
            const errors = {};
            values.Long_Description = descriptionvalue;

            if (!values.Long_Description || values.Long_Description.replace(/(<([^>]+)>)/ig, '').trim()?.length == 0) {
              submitTest = false;
              errors.Long_Description = "Long Description are required.";
            }
            else if (values.Long_Description.replace(/(<([^>]+)>)/ig, '').trim()?.length < rhDiscriptionMin) {
              submitTest = false;
              errors.Long_Description = "Please provide at least " + rhDiscriptionMin + " characters";
            }
            setErrors(errors);
            if (submitTest) {
              let formValues = JSON.parse(JSON.stringify(values));
              if (values.Is_Product_Active) {
                formValues.Is_Product_Active = 1;
              } else {
                formValues.Is_Product_Active = 0;
              }
              dispatch(updateProductMasterAction(formValues, navigation, toast));
            }
          }
          }
        >
          {({
            errors,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (

            <View style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              flexWrap: "wrap"
            }}>
              <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%" }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('EditProductMasters', { ID, CID })}>
                  Go Back
                </Button>
              </View>
              <ScrollView>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Edit Product Master
                    </Text>
                    <View style={{ paddingTop: Platform.OS !== "web" ? 10 : 10, paddingBottom: Platform.OS !== "web" ? '20%' : null }}>
                      <EsiEditor label={"Long_Description"} value={descriptionvalue} onUpdateValue={(value) => { setRhDiscriptionMin(value) }} />
                      <HelperText type="error" visible={(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim()?.length < rhDiscriptionMin) && touched.Long_Description
                        && errors.Long_Description}>
                        {(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim()?.length < rhDiscriptionMin) && touched.Long_Description
                          && errors.Long_Description}
                      </HelperText>
                    </View>

                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                      <Checkbox.Android
                        color={esiColor.SIconColor}
                        uncheckedColor={esiColor.SBorderColor}
                        name="Is_Product_Active"
                        status={values.Is_Product_Active ? 'checked' : 'unchecked'}
                        onPress={() => {
                          setFieldValue("Is_Product_Active", !values.Is_Product_Active);
                        }}
                      />
                      <Text style={{ marginTop: 10, color: esiColor.DescColor }}>Is Product Master Active</Text>
                    </View>
                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                      <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                        <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                      </Button>
                    </View>
                  </Card.Content>
                </Card>
              </ScrollView>
            </View>

          )}
        </Formik>}
      </ScrollView>
    </Surface>
  );
};

export default EditProductMasterthree;