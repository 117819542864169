import {
BIDDING_SERVICES_COMMENTS_CREATE_REQUEST,
BIDDING_SERVICES_COMMENTS_CREATE_SUCCESS,
BIDDING_SERVICES_COMMENTS_CREATE_FAIL,

BIDDING_SERVICES_COMMENTS_REQUEST,
BIDDING_SERVICES_COMMENTS_SUCCESS,
BIDDING_SERVICES_COMMENTS_FAIL,

} from '../actions/biddingServiceQuoteCommentsAction';


const initialState = {
    BiddingServiceComments: { all: [], error: '', isLoading: false },
    BiddingServiceCommentCreate: { ServiceComment: {}, error: '', isLoading: false },
};

export default function ticketingReducer(state = initialState, action) {
    switch (action.type) {

        case BIDDING_SERVICES_COMMENTS_REQUEST:
            return { ...state, BiddingServiceComments: { all: state.BiddingServiceComments.all, error: '', isLoading: true }, };
        case BIDDING_SERVICES_COMMENTS_SUCCESS:
            return { ...state, BiddingServiceComments: { all: action.payload, error: '', isLoading: false } };
        case BIDDING_SERVICES_COMMENTS_FAIL:
            return { ...state, BiddingServiceComments: { all: [], error: action.payload, isLoading: false } };
        case BIDDING_SERVICES_COMMENTS_CREATE_REQUEST:
            return { ...state, BiddingServiceCommentCreate: { ServiceComment: {}, error: '', isLoading: true } };
        case BIDDING_SERVICES_COMMENTS_CREATE_SUCCESS:
            return { ...state, BiddingServiceCommentCreate: { ServiceComment: action.payload, error: '', isLoading: false } };
        case BIDDING_SERVICES_COMMENTS_CREATE_FAIL:
            return { ...state, BiddingServiceCommentCreate: { ServiceComment: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}