import axios from 'react-native-axios';

export const PUSH_CREATE_REQUEST = "PUSH_CREATE_REQUEST";
export const PUSH_CREATE_SUCCESS = "PUSH_CREATE_SUCCESS";
export const PUSH_CREATE_FAIL = "PUSH_CREATE_FAIL";
/*=============================================================
 Can use this function below, OR use Expo's Push Notification Tool-> https://expo.dev/notifications
===============================================================*/
export const pushNotificationAction = (formData: any) => async (dispatch: any) => {
    dispatch({
        type: PUSH_CREATE_REQUEST
    });
    axios.post('https://exp.host/--/api/v2/push/send', formData, {
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: PUSH_CREATE_SUCCESS
                });

            } else {
                dispatch({
                    type: PUSH_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PUSH_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

