import React from "react";
import { getAllRelatedProductAction, resetFilter } from '../../../state/actions/relatedproductsAction';
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Card, DataTable, Button, Searchbar, Surface, Dialog, Portal, IconButton, DefaultTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../state/validations/is-empty'
import 'react-native-gesture-handler'
import Header from "../../auth/components/header";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from 'react-native-vector-icons/FontAwesome';

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Product_Id',
    label: 'Product ID'
  },

  {
    value: 'Product_Name',
    label: 'Product Name'
  },
  {
    value: 'Related_Prod_Id',
    label: 'Related Product ID'
  },
  {
    value: 'Created_By',
    label: 'Created By'
  },


];
const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const RelatedProductsGet = (props: any) => {
  const { navigation } = props;
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);
  // Role restriction
  const [page, setPage] = React.useState(0);
  const permissions = useSelector(state => state.auth.permissions);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const RelatedProducts = useSelector(state => state.relatedproducts.relatedproducts.all);
  let alllRelatedProducts = RelatedProducts?.results;
  const [relatedproductsMenu, setRelatedproductsMenu] = React.useState('All')
  const [relatedproductsSearch, setRelatedproductsSearch] = React.useState('');
  const [relatedproduct, setRelatedproducts] = React.useState('');
  const [webpage, setwebPage] = React.useState(1);
  const [relatedProducts, setData] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const hideDialog = () => setVisible(false);
  let allRelatedProducts = []
  if (alllRelatedProducts) {
    for (let i = 0; i < alllRelatedProducts?.length; i++) {
      let data = alllRelatedProducts[i]?.Related_Products
      for (let j = 0; j < data.length; j++) {
        allRelatedProducts.push({
          Product_Id: alllRelatedProducts[i].Product_ID,
          Product_Name: alllRelatedProducts[i].Product_Name,
          Related_Product_Of_Product_Id: data[j].Related_Product_Of_Product_Id,
          Related_Prod_Id: data[j].Related_Prod_Id,
          Created_By: data[j].Created_By,
        }

        )
      }
    }
  }
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > alllRelatedProducts?.Pagination?.TotalCount ? alllRelatedProducts?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setwebPage(1)
    setPagination({ PageNo: Number(1), PageSize: e, SortBy: "RELATED_PRODUCT_OF_PRODUCT_ID", SortOrder: "DESC" })
  }
  const DynamicPageIncrease = (e) => {
    setwebPage(e < RelatedProducts?.Pagination?.TotalPages ? e + Number(1) : RelatedProducts?.Pagination?.TotalPages)
    setPagination({ PageNo: e == RelatedProducts?.Pagination?.TotalPages ? RelatedProducts?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "RELATED_PRODUCT_OF_PRODUCT_ID", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "RELATED_PRODUCT_OF_PRODUCT_ID", SortOrder: "DESC" });
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "RELATED_PRODUCT_OF_PRODUCT_ID", SortOrder: "DESC" });
  }
  const relatedproductsid = props.route?.params?.relatedproductparams;

  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "RELATED_PRODUCT_OF_PRODUCT_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      relatedproductsCall();
    }
  }, [pagination])
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "RELATED_PRODUCT_OF_PRODUCT_ID", SortOrder: "DESC" }
    if (relatedproductsid === relatedproduct) {
      let formData = {
        "search": "",
        "search_by_filter": relatedproductsMenu,
        "Created_By": "",
        "Product_Id": relatedproduct,
        "Related_Product_Of_Product_Id": "",
        "Records_Filter": "FILTER"
      };
      setPage(0)
      setRelatedproducts(relatedproductsid)
      numberItemsPerPageChange(5)
      dispatch(getAllRelatedProductAction(formData, pagecall))
    }
  };
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const relatedproductsCall = () => {
    setIsLoading(true);
    let formData = {
      "search": "",
      "search_by_filter": relatedproductsMenu,
      "Created_By": "",
      "Product_Id": relatedproductsid,
      "Related_Product_Of_Product_Id": "",
      "Records_Filter": "FILTER"
    };
    dispatch(getAllRelatedProductAction(formData, pagination))
  };

  const handleClickadd = async () => {
    props.navigation.navigate('AddRelatedProducts', { relatedproductsid: relatedproductsid })
  };

  const handleClickedit = async (RelatedproductData) => {
    props.navigation.navigate('EditRelatedProducts', { relatedproductId: RelatedproductData.Related_Prod_Id, relatedofproductId: RelatedproductData.Related_Product_Of_Product_Id, productid: RelatedproductData.Product_Id })
  };
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });

  React.useEffect(() => {
    if (relatedproductsid != relatedproduct) {
      let formData = {
        "search": "",
        "search_by_filter": relatedproductsMenu,
        "Created_By": "",
        "Product_Id": relatedproductsid,
        "Related_Product_Of_Product_Id": "",
        "Records_Filter": "FILTER"
      };
      setRelatedproducts(relatedproductsid)
      dispatch(getAllRelatedProductAction(formData, pagination))
    }
  }, [relatedproductsid,]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Related_Product_Of_Product_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleChange = itemValue => {
    setRelatedproductsMenu(itemValue)
  };
  const handleSearch = value => {
    setRelatedproductsSearch(value)
    const formData = {
      "search": value,
      "search_by_filter": relatedproductsMenu,
      "Created_By": "",
      "Product_Id": relatedproductsid,
      "Related_Product_Of_Product_Id": "",
      "Records_Filter": "FILTER"
    };
    setRelatedproductsSearch(value)
    dispatch(getAllRelatedProductAction(formData, pagination))
  };

  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall()
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const styles = StyleSheet.create({
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 200
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'left',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 240
    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
    },
    rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
    },
    rowsPerPagePickers: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      borderColor: 'gray',
      borderWidth: 0.5,
      paddingHorizontal: 8,
    },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      // backgroundColor: esiColor.SIconColor
    },
    paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      // backgroundColor: esiColor.SIconColor
    },
    pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
            <IconButton
              icon="close"
              color={esiColor.GBFColor}
              size={20}
              onPress={() => setVisible(!visible)}
            />
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>Related Product View</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Dialog.Content style={{ marginLeft: -35 }}>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{relatedProducts?.Product_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Related Product ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{relatedProducts?.Related_Prod_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Related Product of Product ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{relatedProducts?.Related_Product_Of_Product_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{relatedProducts?.Created_Date} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{relatedProducts?.Created_By} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View >
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
          navigation.navigate('Products')
          handleSearch("")
          setRelatedproducts('')
          dispatch(resetFilter())
        }
        }>
          Go Back
        </Button>
      </View>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Related Products</Text>
        </View>
        {(permissions.Products_Partner_Products_Related_Products === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={(e) => { handleClickadd(); }}>
              Add Related Products
            </Button>
          </View>}
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[styles.dropdown]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={filterOptions}
                containerStyle={styles.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={relatedproductsMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={relatedproductsMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Product ID" value="Product_Id" />
                <Picker.Item label="Related Product ID" value="Related_Prod_Id" />
                <Picker.Item label="Created By" value="Created_By" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              variant="outlined"
              onChangeText={(value) => handleSearch(value)}
              value={relatedproductsSearch}
            />
          </View>
        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={styles.mainbox}>
            <Card>
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={styles.databeHeader}>
                  <DataTable.Title active={orderBy === 'Product_Id'} sortDirection={orderBy === 'Product_Id' ? order : 'ascending'} onPress={createSortHandler('Product_Id')} style={styles.title}><Text style={styles.titletext}>Product ID</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Product_Name'} sortDirection={orderBy === 'Product_Name' ? order : 'ascending'} onPress={createSortHandler('Product_Name')} style={styles.title}><Text style={styles.titletext}>Product Name</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Related_Prod_Id'} sortDirection={orderBy === 'Related_Prod_Id' ? order : 'ascending'} onPress={createSortHandler('Related_Prod_Id')} style={styles.title}><Text style={styles.titletext}>Related Product ID</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Created_By'} sortDirection={orderBy === 'Created_By' ? order : 'ascending'} onPress={createSortHandler('Created_By')} style={styles.title}><Text style={styles.titletext}>Created By</Text></DataTable.Title>
                  <DataTable.Title style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {allRelatedProducts?.map((RelatedproductData, i) => (
                  <DataTable.Row style={styles.databeBox} key={i}>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{RelatedproductData.Product_Id} </Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{RelatedproductData.Product_Name}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{RelatedproductData.Related_Prod_Id}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{RelatedproductData.Created_By}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>
                      {(permissions.Products_Partner_Products_Related_Products === "write") &&
                        <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClickedit(RelatedproductData); }} />
                      }<IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(RelatedproductData); setVisible(!visible) }} />
                    </DataTable.Cell>
                  </DataTable.Row>
                ))
                }
                {isEmpty(allRelatedProducts) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                {Platform.OS !== "web" ?

                  <DataTable.Pagination
                    // theme={customTheme}
                    page={page}
                    numberOfPages={Math.ceil(RelatedProducts?.Pagination?.TotalCount / numberOfItemsPerPage)}
                    onPageChange={page => DynamicPage(to < RelatedProducts?.Pagination?.TotalCount ? page : 0)}
                    label={RelatedProducts?.Pagination?.TotalCount ? `${from + 1}-${to} of ${RelatedProducts?.Pagination?.TotalCount}` : '0-0'}
                    showFastPaginationControls={(Number(page) + Number(1)) > RelatedProducts?.Pagination?.TotalPages ? false : true}
                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    onItemsPerPageChange={onItemsPerPageChange}
                    selectPageDropdownLabel={'Rows per page'}
                  />

                  :
                  <View style={styles.rowsPerPageContainer}>
                    <Text style={{color: esiColor.Text}}>rows per page: </Text>
                    <Picker
                      style={styles.rowsPerPagePicker}
                      selectedValue={numberOfItemsPerPage}
                      onValueChange={(e) => onItemsPerPageChange(e)}
                    >
                      <Picker.Item label="5" value={5} />
                      <Picker.Item label="10" value={10} />
                      <Picker.Item label="20" value={20} />
                    </Picker>
                    <View style={styles.paginationContainer}>
                      <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={styles.paginationButton}>
                        <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                      <Text style={styles.pageInfoText}>
                        {RelatedProducts?.Pagination?.PageNo + ' of ' + RelatedProducts?.Pagination?.TotalPages}
                      </Text>
                      <TouchableOpacity onPress={e => DynamicPageIncrease(to < RelatedProducts?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={styles.paginationButton}>
                        <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                    </View>
                  </View>}
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>

  );
}

export default RelatedProductsGet;