import axios from 'axios';
import config from '../config';
import shopperconfig from '../shoppers/shopperconfig';
import { meeapi } from '../node-actions/meeapi';
export const INVENTORIES_REQUEST = "INVENTORIES_REQUEST";
export const INVENTORIES_SUCCESS = "INVENTORIES_SUCCESS";
export const INVENTORIES_FAIL = "INVENTORIES_FAIL";
export const INVENTORIES_SIZES_REQUEST = "INVENTORIES_SIZES_REQUEST";
export const INVENTORIES_SIZES_SUCCESS = "INVENTORIES_SIZES_SUCCESS";
export const INVENTORIES_SIZES_FAIL = "INVENTORIES_SIZES_FAIL";
export const INVENTORY_GET_REQUEST = "INVENTORY_GET_REQUEST";
export const INVENTORY_GET_SUCCESS = "INVENTORY_GET_SUCCESS";
export const INVENTORY_GET_FAIL = "INVENTORY_GET_FAIL";
export const INVENTORY_CREATE_REQUEST = "INVENTORY_CREATE_REQUEST";
export const INVENTORY_CREATE_SUCCESS = "INVENTORY_CREATE_SUCCESS";
export const INVENTORY_CREATE_FAIL = "INVENTORY_CREATE_FAIL";
export const INVENTORY_UPDATE_REQUEST = "INVENTORY_UPDATE_REQUEST";
export const INVENTORY_UPDATE_SUCCESS = "INVENTORY_UPDATE_SUCCESS";
export const INVENTORY_UPDATE_FAIL = "INVENTORY_UPDATE_FAIL";

export const INVENTORY_PRODUCTS_REQUEST = "INVENTORY_PRODUCTS_REQUEST";
export const INVENTORY_PRODUCTS_SUCCESS = "INVENTORY_PRODUCTS_SUCCESS";
export const INVENTORY_PRODUCTS_FAIL = "INVENTORY_PRODUCTS_FAIL";

export const INVENTORY_PARTNERS_REQUEST = "INVENTORY_PARTNERS_REQUEST";
export const INVENTORY_PARTNERS_SUCCESS = "INVENTORY_PARTNERS_SUCCESS";
export const INVENTORY_PARTNERS_FAIL = "INVENTORY_PARTNERS_FAIL";

export const INVENTORY_DETAILS_REQUEST = "INVENTORY_DETAILS_REQUEST";
export const INVENTORY_DETAILS_SUCCESS = "INVENTORY_DETAILS_SUCCESS";
export const INVENTORY_DETAILS_FAIL = "INVENTORY_DETAILS_FAIL";

export const INVENTORY_PRODUCT_MASTER_REQUEST = "INVENTORY_PRODUCT_MASTER_REQUEST";
export const INVENTORY_PRODUCT_MASTER_SUCCESS = "INVENTORY_PRODUCT_MASTER_SUCCESS";
export const INVENTORY_PRODUCT_MASTER_FAIL = "INVENTORY_PRODUCT_MASTER_FAIL";

export const PRODUCTSIZE_INVENTORY_REQUEST = "PRODUCTSIZE_INVENTORY_REQUEST";
export const PRODUCTSIZE_INVENTORY_SUCCESS = "PRODUCTSIZE_INVENTORY_SUCCESS";
export const PRODUCTSIZE_INVENTORY_FAIL = "PRODUCTSIZE_INVENTORY_FAIL";

export const INVENTORIES_FILTER = "INVENTORIES_FILTER";

/*=============================================================
                  Add Inventory Action
===============================================================*/
const cudheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}

export const createInventoryAction = (formData: any, navigation: any, setErrorMessage: any, setErrors: any, toast: any) => async (dispatch: any) => {
  dispatch({
    type: INVENTORY_CREATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Inventory/Partner_Create`,
    formData, {
      headers: cudheaders
  })
    .then(response => {

      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: INVENTORY_CREATE_SUCCESS
        });
        toast.show({
          message: 'Inventory Added successfully.', type: 'info', duration: 3000, position: 'top'
        });
        let newformData = {
          Records_Filter: "All",
          Partner_Details_Id: formData.Partner_Details_Id,
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "INVENTORY_ID",
          SortOrder: "ASC"
        }
        dispatch(getAllInventoryAction(newformData, toast, pagination))
        navigation.navigate('Inventory');
      } else {
        const errors = {};
        if (successResponse.Product_Id) {
          errors.Product_Id = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Product_Id: { key: successResponse.Product_Id ? successResponse.Product_Id : '', message: successResponse.Product_Id ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Product_Id) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: INVENTORY_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: INVENTORY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update Inventory Action
===============================================================*/

export const updateInventoryAction = (formData: any, EmailID: any, navigation: any, setErrorMessage: any, setErrors: any, toast: any) => async (dispatch: any) => {
  dispatch({
    type: INVENTORY_UPDATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Inventory/Partner_Update`, formData, {
    headers: cudheaders
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: INVENTORY_UPDATE_SUCCESS
        });
        toast.show({
          message: 'Inventory updated successfully.', type: 'info', duration: 3000, position: 'top'
        });
        let formData = {
          "search": "",
          "Inventory_Id": "",
          "Partner_Details_Id": EmailID?.Partner_Details_Id,
          "Product_Id": '',
          "Records_Filter": "FILTER"
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "INVENTORY_ID",
          SortOrder: "ASC"
        }
        dispatch(getAllInventoryAction(formData, pagination))
        navigation.navigate('FarmerInventoryDetails');
      } else {
        const errors = {};
        if (successResponse.Product_Id) {
          errors.Product_Id = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Product_Id: { key: successResponse.Product_Id ? successResponse.Product_Id : '', message: successResponse.Product_Id ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Product_Id) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: INVENTORY_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: INVENTORY_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Get All Inventory Action
===============================================================*/

export const getAllInventoryAction = (formData: any, pagination = {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: INVENTORIES_REQUEST
    });
    meeapi.post(urlGenarator(`Inventory/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;

      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORIES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: INVENTORIES_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: INVENTORIES_FAIL,
      payload: err
    });
  }
};
export const getAllSizes = (Product_Master_id: any, EmailID: any) => async (dispatch: any) => {
  try {
    let formData = {
      Product_Master_Id: Product_Master_id,
      Partner_Details_Id: EmailID?.Partner_Details_Id
    };
    dispatch({
      type: INVENTORIES_SIZES_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Product/Size_Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORIES_SIZES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORIES_SIZES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORIES_SIZES_FAIL,
      payload: err
    });
  }
};

export const getAllsizes = (formData: any, callBackSizeData: any) => async (dispatch: any) => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Product/Size_Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      callBackSizeData(data.results)
    }
  } catch (err) {

  }
};

export const inventoryFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: INVENTORIES_FILTER,
      fType: fType,
      value: value,
    });
  }
}

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: INVENTORIES_FILTER,
      fType: "search",
      value: "",
    });
  }
}
/*=============================================================
               Get all products
===============================================================*/

export const getAllProducts = () => async (dispatch: any) => {
  try {
    let formData = {
      Records_Filter: "ALL",
      Partner_Details_Id: "",
      Product_Id: ""
    };
    dispatch({
      type: INVENTORY_PRODUCTS_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Products/Get_Admin`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORY_PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORY_PRODUCTS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORY_PRODUCTS_FAIL,
      payload: err
    });
  }
};
/*=============================================================
               Get all partners
===============================================================*/
export const getAllPartners = (EmailID: any) => async (dispatch: any) => {
  try {
    let formData = {

      "Partner_Details_Id":  EmailID?.Partner_Details_Id,
      "Product_Name": "",
      "Records_Filter": "FILTER"
    };
    dispatch({
      type: INVENTORY_PARTNERS_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Product/Physical_Partner_Product_GET`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORY_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORY_PARTNERS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORY_PARTNERS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               Get all details
===============================================================*/
export const getAllDetails = () => async (dispatch: any) => {
  try {
    let formData = {
      Records_Filter: "ALL",
      Partner_Details_Id: "",
      Name: "",
      Top_Stores: "",
      ExtraCashBack: ""
    };
    dispatch({
      type: INVENTORY_DETAILS_REQUEST
    });
    let { data, status } = await axios.post(
      `${config.url}/Partners/OnlineAndPhysical_Details_Get_Admin`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: headers
      }
    );
    if (data) {
      if (data.Success_Response) {
        dispatch({
          type: INVENTORY_DETAILS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORY_DETAILS_SUCCESS,
          payload: data.Partner_Details
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORY_DETAILS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
               Get All Master Id
===============================================================*/


export const getAllMasters = () => async (dispatch: any) => {
  try {
    let formData = {
      Active: "",
      Brand: " ",
      Category: " ",
      Msrp: " ",
      Product_Master_Id: " ",
      Product_Name: " ",
      Records_Filter: " ",
      Sku: " ",
      Stock_Status: " ",
      Upc_Code: " "
    };
    dispatch({
      type: INVENTORY_PRODUCT_MASTER_REQUEST
    });
    let { data, status } = await axios.post(
      `${config.url}/Products/Product_Master_Get_Admin?PageNo=0&PageSize=1000&SortBy=PRODUCT_MASTER_ID&SortOrder=ASC`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: INVENTORY_PRODUCT_MASTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: INVENTORY_PRODUCT_MASTER_SUCCESS,
          payload: data.Product_Master
        });
      }
    }
  } catch (err) {
    dispatch({
      type: INVENTORY_PRODUCT_MASTER_FAIL,
      payload: err
    });
  }
};


//get single inventory
export const getInventoryByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Inventory/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)
    }
  } catch (err) {
    dispatch({
      type: INVENTORIES_FAIL,
      payload: err
    });
  }
};