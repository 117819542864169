import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView, RefreshControl } from 'react-native';
import Header from '../../header/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GetEmployeeAction, getNewUserserviceByIdAction, updateUserservicesAction } from '../../../../state/actions/Services/userservicesActions';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import EsiDatePicker from '../../../../components/custom/date/date';

const StatusOption = [
  {
    value: 'INITIATED',
    label: 'INITIATED'
  },
  {
    value: 'IN-PROCESS',
    label: 'IN-PROCESS'
  },
  {
    value: 'REJECTED',
    label: 'REJECTED'
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED'
  },
];
const PaymentOption = [
  // {
  //   value: 'ONLINE',
  //   label: 'ONLINE',
  // },
  {
    value: 'COD',
    label: 'COLLECTED CASH FROM THE USER',
  },
];
// const StatusserviceOption = [
//   {
//     value: 'PENDING',
//     label: 'PENDING'
//   },
//   {
//     value: 'IN-PROCESS',
//     label: 'IN-PROCESS'
//   },
//   {
//     value: 'ACCEPTED',
//     label: 'ACCEPTED'
//   },
//   {
//     value: 'CANCELLED',
//     label: 'CANCELLED'
//   },
//   {
//     value: 'REJECTED',
//     label: 'REJECTED'
//   },
//   {
//     value: 'NEW',
//     label: 'NEW'
//   },
//   {
//     value: 'COMPLETED',
//     label: 'COMPLETED'
//   },
//   {
//     value: 'INITIATED',
//     label: 'INITIATED'
//   },
// ];
export default function EditInprogressuserservice(props) {
  //navigation is used for navigation between screens
  const { navigation } = props
  const dispatch = useDispatch();

  //toast for popup after successful API calls.
  const toast = useToast();
  const esiColor = useSelector(state => state.theme);

  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const [data, setData] = useState([]);
  const [days, setDays] = useState("");
  const [time, setTime] = useState("");
  const [currentDate, setCurrentdate] = useState("");
  const [employeedata, setEmployeeData] = useState([]);
  const [statusfield, setStatusField] = useState("");
  const [statusvalue, setStatusValue] = useState('');
  let employeedataopt = []
  if (employeedata) {
    if (employeedata[0]) {
      for (let i = 0; i < employeedata?.length; i++) {
        employeedataopt.push(
          {
            value: employeedata[i]?.Partner_Employee_Id,
            label: employeedata[i]?.Full_Name.substring(0, 40),
            Mobile_Number: employeedata[i]?.Mobile_Number,
          })
      }
    }
  }

  const ID = props?.route?.params?.id;
  const [servicedata, setServiceData] = useState([]);
  //partners contains the data from  GET API using state call
  // let userservice = useSelector(state => state.userservice.newuserservice.all);
  const EmailID = useSelector(state => state.auth?.user);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const callBackemployedata = (data: any) => {
    setEmployeeData(data)
  }
  const callBackData = (data: any) => {
    setData(data)
    setStatusField(data.Service_Request_Status_From_Partner)
    setStatusValue(data.Payment_Method)
    setDays(data.Service_Request_Total_Time_in_Days)
    setTime(data.Service_Request_Total_Time_in_Hours)
  }
  React.useEffect(() => {
    let formData = {
      Records_Filter: "FILTER",
      Partner_Details_Id: EmailID?.Partner_Details_Id
    };
    dispatch(GetEmployeeAction(formData, callBackemployedata))
  }, []);

  //dispatch is used to perform the API action
  useEffect(() => {
    let date = new Date().toJSON().slice(0, 10);;

    // This arrangement can be altered based on how we want the date's format to appear.
    // let currentDate = `${day}-${month}-${year}`;
    setCurrentdate(date)
    let formData = {
      "search": "",
      "search_by_filter": "",
      "Partner_Employee_Id": "",
      "Partner_Detail_Id": EmailID?.Partner_Details_Id,
      "User_Service_request_Status": "",
      "User_Service_request_Id": ID,
      "User_Email_Id": "",
      "Partner_Service_Id": "",
      "City_Id": "",
      "Service_Request_Status_From_Partner": "",
      "Records_Filter": "FILTER"
    };
    setServiceData(ID)
    dispatch(getNewUserserviceByIdAction(formData, callBackData));
  }, [ID, servicedata]);

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [statusDropDown, setStatusDropDown] = useState("");
  const [paymentDropDown, setPaymentDropDown] = useState("");
  const [countryField, setCountryField] = React.useState();
  let total = (days * Number(data.Price_Per_Day)) + (time * Number(data.Price_Per_Hour))
  let collect = (days * Number(data.Price_Per_Day)) + (time * Number(data.Price_Per_Hour)) - data?.Advance_Amount

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 15
  },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      left: 22,
      top: 0.5,
      color: esiColor.SIconColor,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{ flex: 1,backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {data &&
          <Formik
            initialValues={{
              User_Service_Request_Id: data?.User_Service_Request_Id,
              Partner_Detail_Id: EmailID?.Partner_Details_Id,
              Status: data?.Status ? data.Status : "",
              Service_End_Date: "",
              After_Discount_Service_Price: total,
              Modified_By: EmailID.Email_Id,
              Partner_Service_Id: data?.Partner_Service_Id,
              User_Cancel_Reason: "",
              Service_Request_Status_From_Partner: statusfield ? statusfield : "",
              Service_Request_Total_Time_In_Days: days ? days : "",
              Service_Request_Total_Time_In_Hours: time ? time : '',
              Payment_Status: statusvalue === "COD" ? 'SUCCESS' : data?.Payment_Status,
              Payment_Method: data?.Payment_Method ? data.Payment_Method : "",
              User_Service_Request_Status: statusfield === 'REJECTED' ? 'NEW' : statusfield,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              Service_Request_Status_From_Partner: Yup.string().required('Service Request Status From Partner is Required.'),
              Service_Request_Total_Time_In_Hours: Yup.number().when('Service_Request_Status_From_Partner', { is: "COMPLETED", then: Yup.number().required('Total Time In Hours is required.') }),
              Payment_Method: Yup.string().when('Service_Request_Status_From_Partner', { is: "COMPLETED", then: Yup.string().required('Paymnet Type is required.') }),
              Service_Request_Total_Time_In_Days: Yup.number().when('Service_Request_Status_From_Partner', { is: "COMPLETED", then: Yup.number().required('Total Time In Days is required.') }),
              User_Cancel_Reason: Yup.string().when('Service_Request_Status_From_Partner', { is: "REJECTED", then: Yup.string().required('reject reason is required.') }),
            })}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              const errors = {};
              setErrors(errors);
              if (submitTest) {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.User_Service_Request_Status === "COMPLETED") {
                  formValues.Service_End_Date = currentDate;
                }
                dispatch(updateUserservicesAction(formValues, navigation, toast, "3", EmailID));

              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => { resetForm(); navigation.navigate('Newuserservices') }}>
                    Go Back
                  </Button>
                </View>
                <View pointerEvents={!(permissions.Partner_Bank === "write") ? 'none' : 'auto'}>
                  <Card style={{
                   width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                   marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                   backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                  }}>
                    <Card.Content>
                      <Text style={{ fontSize: 20, fontWeight: "bold",color: esiColor.brandFontColor }}>
                        Edit User Service
                      </Text>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Price Per Hour:   {data.Price_Per_Hour} </Text>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Price Per Day:  {data.Price_Per_Day}  </Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Partner Employee ID:   {data.Partner_Employee_Id} </Text>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Employee Mobile Number:  {data.Service_Mobile_Number}  </Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                        <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Status && errors.Status) && { color: '#b00020' }]}>
                            Service Request Status From Partner
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Service_Request_Status_From_Partner && errors.Service_Request_Status_From_Partner) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={StatusOption}
                            maxHeight={StatusOption.length > 1 ? 250 : 200}
                            search={StatusOption.length > 1 ? true : false}
                            labelField="label"
                            valueField="value"
                            dropdownPosition="auto"
                            value={values.Service_Request_Status_From_Partner}
                            name="Service_Request_Status_From_Partner"
                            onFocus={() => setStatusDropDown(true)}
                            onBlur={() => setStatusDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('Service_Request_Status_From_Partner', item.value)
                              setFieldValue('User_Service_Request_Status', item.value)
                              setFieldValue('User_Cancel_Reason', "")
                              setStatusField(item.value)

                              setStatusDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Service_Request_Status_From_Partner && errors.Service_Request_Status_From_Partner)}>
                            <ErrorMessage name="Service_Request_Status_From_Partner" />
                          </HelperText>
                        </View>
                        </View>
                      </View>
                      {statusfield === 'COMPLETED' ? <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, margin: 'auto' }}>
                            <TextInput
                              style={{ backgroundColor: 'white' }}
                              theme={{ colors: { primary: '#27B6CC' } }}
                              error={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}
                              label="Total Time In Days"
                              mode='outlined'
                              value={values.Service_Request_Total_Time_In_Days}
                              onChangeText={
                                item => {
                                  setFieldValue("Service_Request_Total_Time_In_Days", item)
                                  setDays(item ? item : "")
                                }}
                            />
                            <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}>
                              {touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days}
                            </HelperText>
                          </View>
                          <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, margin: 'auto' }}>
                            <TextInput
                              style={{ backgroundColor: 'white' }}
                              theme={{ colors: { primary: '#27B6CC' } }}
                              error={Boolean(touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours)}
                              label="Total Time In Hours"
                              mode='outlined'
                              value={values.Service_Request_Total_Time_In_Hours}
                              onChangeText={item => {
                                setFieldValue("Service_Request_Total_Time_In_Hours", item)
                                setTime(item)
                              }}
                            />
                            <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours)}>
                              {touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours}
                            </HelperText>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Status && errors.Status) && { color: '#b00020' }]}>
                              Paymnet Type
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(touched.Payment_Method && errors.Payment_Method) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              data={PaymentOption}
                              maxHeight={PaymentOption.length > 1 ? 250 : 200}
                              search={PaymentOption.length > 1 ? true : false}
                              labelField="label"
                              valueField="value"
                              dropdownPosition="auto"
                              value={values.Payment_Method}
                              name="Payment_Method"
                              onFocus={() => setPaymentDropDown(true)}
                              onBlur={() => setPaymentDropDown(false)}
                              onUpdateValue={item => {
                                setFieldValue('Payment_Method', item.value)
                                setStatusValue(item.value)
                                setPaymentDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(touched.Payment_Method && errors.Payment_Method)}>
                              <ErrorMessage name="Payment_Method" />
                            </HelperText>
                          </View>
                        </View>
                        </View>
                      </View>
                        : null}

                      {values.Service_Request_Status_From_Partner != 'REJECTED' ? <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                        <Text style={{ fontWeight: "bold", color: 'red' }}>Collect From the User:   {collect} </Text>
                      </View> : null}
                      {values.Service_Request_Status_From_Partner === 'REJECTED' ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, margin: 'auto' }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.User_Cancel_Reason && errors.User_Cancel_Reason)}
                            label="Reject Reason"
                            mode='outlined'
                            value={values.User_Cancel_Reason}
                            onChangeText={handleChange("User_Cancel_Reason")}
                          />
                          <HelperText type="error" visible={Boolean(touched.User_Cancel_Reason && errors.User_Cancel_Reason)}>
                            {touched.User_Cancel_Reason && errors.User_Cancel_Reason}
                          </HelperText>
                        </View>
                      </View> : null}
                      <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                        {values.Service_Request_Status_From_Partner === 'REJECTED' ? <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} textColor={esiColor.itemButtenColor} mode="contained" onPress={handleSubmit}>
                          Reject
                        </Button> : values.Service_Request_Status_From_Partner === 'COMPLETED' ? <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }}textColor={esiColor.itemButtenColor} mode="contained" onPress={handleSubmit}>
                          Completed
                        </Button> : values.Service_Request_Status_From_Partner === 'IN-PROCESS' ? <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} textColor={esiColor.itemButtenColor} mode="contained" onPress={handleSubmit}>
                          In-process
                        </Button> : <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} textColor={esiColor.itemButtenColor} mode="contained" disabled onPress={handleSubmit}>
                          Save
                        </Button>}
                      </View>

                    </Card.Content>
                  </Card>
                </View>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface>
  );
};

