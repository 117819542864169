import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createDealAction } from '../../../state/actions/dealAction';
import { Card, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { View, ScrollView, Dimensions } from 'react-native';
import Header from '../../auth/components/header';
import EsiEditor from '../../../components/custom/richtext-editor/richtext-editor';
import isEmpty from '../../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

const AddDealConfig = (props: any) => {

  //toast for popup after successful API calls.
  const toast = useToast();
  //navigation is used for navigation between screens
  const { navigation } = props;

  //isSubmitted contains the data from Create API using state call
  const isSubmited = useSelector(state => state.deal.dealCreate.isLoading);
  //data contains the values from previous form using state call
  const data = useSelector(state => state.deal.dealData.data);
  const EmailID = useSelector(state => state.auth?.user);
  const esiColor = useSelector(state => state.theme);

  //Description
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [descriptionvalue, setDescriptionValue] = React.useState('');

  const dispatch = useDispatch();
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  //To check the data is empty or not
  React.useEffect(() => {
    if (isEmpty(data)) {
      navigation.navigate('AddDealBasic')
    }
  }, [])

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);


  /*====================================
          Handler
  ====================================*/
  const values = {
    Title: data.Title,
    Brand_Id: data.Brand_Id,
    Category_Id: data.Category_Id,
    Start_Date: data.Start_Date,
    End_Date: data.End_Date,
    Discount_Type: data.Discount_Type,
    Discount: data.Discount,
    Discount_Label: data.Discount_Label,
    Cashback_Label: data.Cashback_Label,
    Esi_Cashback_Type: data.Esi_Cashback_Type,
    Esi_Cashback_Amount: data.Esi_Cashback_Amount,
    Mobile_Redirect_Url: '',
    Web_Redirect_Url: '',
    Deal_Description: '',
    Image: data.Image,
    Verified_Deal: false,
    Whats_Hot: false,
    Exclusive: false,
    Extra_Cashback: false,
    Is_Top_Deal: false,
    Partner_Details_Id: EmailID?.Partner_Details_Id,
    Partner_Name: EmailID?.Partner_Name,
    Created_By_Email_Id: EmailID.Email_Id,
    Type: "Physical",
    Status: "In-Active",
  }
  //Form validation
  const Form_Validation = Yup.object().shape({
  })

  const onSubmit = (values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    values.Deal_Description = descriptionvalue;
    if (!values.Deal_Description || values.Deal_Description.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
      submitTest = false;
      errors.Deal_Description = "Description is required.";
    }
    else if (values.Deal_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
      submitTest = false;
      errors.Deal_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
    }

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Verified_Deal) {
        formValues.Verified_Deal = 1;
      } else {
        formValues.Verified_Deal = 0;
      }
      if (values.Exclusive) {
        formValues.Exclusive = 1;
      } else {
        formValues.Exclusive = 0;
      }
      if (values.Whats_Hot) {
        formValues.Whats_Hot = 1;
      } else {
        formValues.Whats_Hot = 0;
      }
      if (values.Is_Top_Deal) {
        formValues.Is_Top_Deal = 1;
      } else {
        formValues.Is_Top_Deal = 0;
      }
      if (values.Extra_Cashback) {
        formValues.Extra_Cashback = 1;
      } else {
        formValues.Extra_Cashback = 0;
      }
      if (values.Discount === "") {
        formValues.Discount_Amount = 0;
      }
      if (values.Esi_Cashback_Type === "") {
        formValues.Esi_Cashback_Amount = 0;
      }
      resetForm();
      dispatch(createDealAction(formValues, navigation, toast));

    }
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView>
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} >
          {(props) => (
            <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
              <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130 }} onPress={() => navigation.navigate('AddDealBasic')}>
                  Go Back
                </Button>
              </View>
              <Card style={{
                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
              }}>
                <Card.Content>
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                    Add Deal
                  </Text>
                  <View style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                  }}>
                    <View style={{ flexShrink: 1, width: 770 }}>
                      <EsiEditor label={"Description"} value={descriptionvalue} onUpdateValue={(value) => { setDescriptionValue(value) }} />
                      <HelperText type="error" visible={(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Deal_Description && props.errors.Deal_Description}>
                        {(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Deal_Description && props.errors.Deal_Description}
                      </HelperText>
                    </View>
                  </View>

                  <View style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                      Save
                    </Button>
                  </View>
                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};

export default AddDealConfig;