import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import ProductCard from './ProductCard';
import { Surface, Text } from 'react-native-paper';
import servicesdata from './servicesdata';
import Header from '../header/header';
import { useSelector } from 'react-redux';

const styles = StyleSheet.create({
  productCard: {
    height: '100%'
  }
});

const PartnerBiddingServicesMain = (props: any) => {
  const { navigation } = props;

  const esiColor = useSelector(state => state.theme);
  const [Services] = useState(servicesdata);

  return (
    <Surface style={{ flex: 1,  backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ fontSize: 23, fontWeight: "bold",color:esiColor.brandFontColor, padding: 10 }}>
            Services Bidding Quotes
          </Text>
        </View>
        <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
          {Services.map(service => (
            <View key={service.id}>
              <ProductCard
                style={styles.productCard} esiColor={esiColor}
                product={service} navigation={navigation}
              />
            </View>
          ))}
        </View>
      </ScrollView>
    </Surface>
  );
};

export default PartnerBiddingServicesMain;