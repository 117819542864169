import {
    ORDER_RETURN_REQUEST,
    ORDER_RETURN_SUCCESS,
    ORDER_RETURN_FAIL,
    ORDER_RETURN_FILTER,
    ORDER_CANCEL_REQUEST,
    ORDER_CANCEL_SUCCESS,
    ORDER_CANCEL_FAIL,
    ORDER_CANCEL_FILTER,
} from '../actions/orderreturnandcancelAction'
const initialState = {
    orderReturn: {  all: [], error: '', isLoading: false  },
    orderCancel: {  all: [], error: '', isLoading: false  },
    orderReturnFilter: { select: 'All', search: '' },
};
export default function (state = initialState, action) {
    switch (action.type) {
case ORDER_RETURN_REQUEST:
    return { ...state, orderReturn: { all: state.orderReturn.all, error: '', isLoading: true } };
  case ORDER_RETURN_SUCCESS:
    return { ...state, orderReturn: { all: action.payload, error: '', isLoading: false } };
  case ORDER_RETURN_FAIL:
    return { ...state, orderReturn: { all: [], error: action.payload, isLoading: false } };
    case ORDER_CANCEL_REQUEST:
      return { ...state, orderCancel: { all: state.orderCancel.all, error: '', isLoading: true } };
    case ORDER_CANCEL_SUCCESS:
      return { ...state, orderCancel: { all: action.payload, error: '', isLoading: false } };
    case ORDER_CANCEL_FAIL:
      return { ...state, orderCancel: { all: [], error: action.payload, isLoading: false } };

        case ORDER_RETURN_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
              res = { ...state, orderReturnFilter: { search: state.orderReturnFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
              res = { ...state, orderReturnFilter: { select: state.orderReturnFilter.select, search: action.value } };
            }
            return res; 
            default:
                return state;
            }
          }