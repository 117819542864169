import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';

export const BRANDS_REQUEST = "BRANDS_REQUEST";
export const BRANDS_SUCCESS = "BRANDS_SUCCESS";
export const BRANDS_FAIL = "BRANDS_FAIL";
export const BRAND_GET_REQUEST = "BRAND_GET_REQUEST";
export const BRAND_GET_SUCCESS = "BRAND_GET_SUCCESS";
export const BRAND_GET_FAIL = "BRAND_GET_FAIL";
export const BRAND_CREATE_REQUEST = "BRAND_CREATE_REQUEST";
export const BRAND_CREATE_SUCCESS = "BRAND_CREATE_SUCCESS";
export const BRAND_CREATE_FAIL = "BRAND_CREATE_FAIL";
export const BRANDS_FILTER = "BRANDS_FILTER";
export const RESET = "RESET";


axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
const cudheaders = config.nodeheadersCommon;
/*=============================================================
                  Add brand Action
===============================================================*/

const headers = config.headersCommon;

const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}

export const invalid_data = () => {
  return {
    type: RESET
  }
}

export const addBrandAction = (formData: any, navigation: any, setErrorMessage: any, setErrors: any, toast: any) => async dispatch => {
  dispatch({
    type: BRAND_CREATE_REQUEST
  });
  setErrorMessage({ Brand_Name: { key: '', message: '' }, global: { key: '', message: '' } });

  axios.post(`${config.nodecudurl}/Brand/Create`, formData, {
    headers: cudheaders
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: BRAND_CREATE_SUCCESS
        });
        const getformData = {
          Records_Filter: 'ALL'
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "BRAND_ID",
          SortOrder: "ASC"
        }
        dispatch(getAllBrandAction(getformData, pagination));
        navigation.navigate('BrandsGet');
        toast.show({
          message: 'Brand Created successfully.', type: 'info', duration: 3000, position: 'top'
        });
      } else {
        const errors = {};
        if (successResponse.Brand_Name) {
          errors.Brand_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Brand_Name: { key: successResponse.Brand_Name ? successResponse.Brand_Name : '', message: successResponse.Brand_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Brand_Name) ? successResponse.UI_Display_Message : '' } });

        dispatch({
          type: BRAND_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Brand_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: BRAND_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                  Get All brand Action
===============================================================*/

export const getAllBrandAction = (formData: any, pagination = {}) => async dispatch => {
  try {
    dispatch({
      type: BRANDS_REQUEST
    });
    meeapi.post(urlGenarator(`/Brand/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BRANDS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: BRANDS_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: BRANDS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Brand Image Upload API
===============================================================*/

export const uploadBrandImage = (
  formData,
  callBackUploadImgae
) => async dispatch => {
  try {

    let { data } = await axios.post(
      `${config.url}/Partner_Upload/Upload?filename=Sample&file_extension=jpg&filetype=image&brand_id=B-0000043`,
      formData,
      {
        headers:headers,
        transformRequest: (d) => d,
      }
    );

if (data) {
  callBackUploadImgae(data);
}
  } catch (err) {
}
};

export const brandFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: BRANDS_FILTER,
      fType: fType,
      value: value,
    });
  }
}


export const resetFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: BRANDS_FILTER,
      fType: "search",
      value: "",
    });
  }
}
