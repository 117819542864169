import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import { Avatar, Card, Text } from "react-native-paper";
import { useDispatch, useSelector } from 'react-redux';
import { Dimensions, Platform, View } from 'react-native';
import { dashboardproductsCount } from '../../../../state/actions/reports/dashboardActions';
import Ionicons from '@expo/vector-icons/Ionicons';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

function TotalProducts({ navigate, esiColor }) {
    //API dispatch
    const dispatch = useDispatch();
    //dashproducts contains the data from  GET API using state call
    const dashproducts = useSelector(state => state.dashboard?.Products?.data);
    const EmailID = useSelector(state => state.auth?.user);
    //Static formData
    useEffect(() => {
        let formData = {
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        dispatch(dashboardproductsCount(formData))
    }, []);

    return (
        <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', padding: 5 }}>
            <Card style={{
                borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 10, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                shadowOffset: { width: 1, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, elevation: 5, marginLeft: 10, marginRight: 10,
                maxWidth: Platform.OS === "ios" ? windowWidth > 300 ? 450 : 450 : 400, minWidth: Platform.OS === "ios" ? 350 : 320
            }}>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -70 }}>
                    <Avatar.Icon size={50} icon="cards-outline" color="white" style={{ backgroundColor: 'green' }} />
                </View>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                    <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.brandFontColor }}>TOTAL PRODUCTS</Text>
                    <Text style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: esiColor?.Text }}>{dashproducts.Total}</Text>
                </View>
                <View style={{ flexDirection: 'row', margin: 10 }}>
                    {dashproducts.Month - dashproducts.Avg >= 0 &&
                        <>
                            <Ionicons name="arrow-up" size={25} color="green" />
                            <View style={{ flexDirection: 'column', margin: 5 }}>
                                <Text style={{ color: esiColor?.Text }}>
                                    {dashproducts.Month}
                                </Text>
                            </View>
                        </>
                    }
                    {dashproducts.Month - dashproducts.Avg < 0 &&
                        <>
                            <Ionicons name="arrow-down" size={25} color="red" />
                            <View style={{ flexDirection: 'column', margin: 5 }}>
                                <Text style={{ color: esiColor?.Text }}>
                                    {dashproducts.Month}
                                </Text>
                            </View>
                        </>
                    }

                    <View style={{ flexDirection: 'column', margin: 5 }}>
                        <Text style={{ color: esiColor?.Text }}>
                            Since last 30 days
                        </Text>
                    </View>

                </View>
            </Card>
        </View>
    );
}


export default TotalProducts;