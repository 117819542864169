import axios from 'axios';
import { meeapi } from './node-actions/meeapi';
import config from './config';

export const USER_NEW_BIDDING_SERVICES_QUOTES_REQUEST = "USER_NEW_BIDDING_SERVICES_QUOTES_REQUEST";
export const USER_NEW_BIDDING_SERVICES_QUOTES_SUCCESS = "USER_NEW_BIDDING_SERVICES_QUOTES_SUCCESS";
export const USER_NEW_BIDDING_SERVICES_QUOTES_FAIL = "USER_NEW_BIDDING_SERVICES_QUOTES_FAIL";

export const BIDDING_SERVICE_QUOTE_CREATE_REQUEST = "BIDDING_SERVICE_QUOTE_CREATE_REQUEST";
export const BIDDING_SERVICE_QUOTE_CREATE_SUCCESS = "BIDDING_SERVICE_QUOTE_CREATE_SUCCESS";
export const BIDDING_SERVICE_QUOTE_CREATE_FAIL = "BIDDING_SERVICE_QUOTE_CREATE_FAIL";

export const USER_BIDDING_SERVICES_REQUEST = "USER_BIDDING_SERVICES_REQUEST";
export const USER_BIDDING_SERVICES_FAIL = "USER_BIDDING_SERVICES_FAIL";

export const BIDDING_SERVICE_FILTER = "BIDDING_SERVICE_FILTER";

/*=============================================================
                   Add User Action
 ===============================================================*/
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
/*=============================================================
                   Get All User Action
===============================================================*/
export const getAllNewUserServiceBiddingQuotesAction = (formData: any, pagination = {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_NEW_BIDDING_SERVICES_QUOTES_REQUEST
    });
    meeapi.post(urlGenarator(`/User_Services_Bidding/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USER_NEW_BIDDING_SERVICES_QUOTES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: USER_NEW_BIDDING_SERVICES_QUOTES_SUCCESS,
          payload: data
        });

      }
    })
  } catch (err) {
    dispatch({
      type: USER_NEW_BIDDING_SERVICES_QUOTES_FAIL,
      payload: err
    });
  }
};
export const getUserServicesBiddingQuotesAction = (formData: any, callBackData: any, pagination: any) => async dispatch => {
  try {
    dispatch({
      type: USER_BIDDING_SERVICES_REQUEST
    });
    meeapi.post(urlGenarator(`/User_Services_Bidding/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data) {
        callBackData(data.results)
      }
    })
  } catch (err) {
    dispatch({
      type: USER_BIDDING_SERVICES_FAIL,
      payload: err
    });
  }
};

//Add Partner Bidding Service Quote Action
export const AddPartnerBiddingServiceQuotesAction = (formData: any, navigation: any) => async dispatch => {
    dispatch({
      type: BIDDING_SERVICE_QUOTE_CREATE_REQUEST
    });
    axios.post(`${config.url}/Bidding_Service_Quotes/Create`, formData, {
      headers: headers
    })
      .then(response => {
        let successResponse = response.data;
        if (successResponse.Response_Status == "Success") {
          dispatch({
            type: BIDDING_SERVICE_QUOTE_CREATE_SUCCESS
          });
          const formValue = {
            "search_by_filter": "",
            "search": "",
            "User_Email_Id": "",
            "User_Products_Bidding_Id": "",
            "Bidding_Product_Id": "",
            "User_Address_Id": "",
            "Records_Filter": "FILTER",
            "Bidding_Status": "",
            "Partner_Detail_Id": formData.Partner_Detail_Id
          };
          const pagination = {
            PageNo: 1, PageSize: 5, SortBy: "User_Service_Bidding_Id", SortOrder: "DESC"
          }
          dispatch(getAllNewUserServiceBiddingQuotesAction(formValue, pagination));
          navigation.navigate('UserNewBiddingQuotesForServices');
        } else {
          dispatch({
            type: BIDDING_SERVICE_QUOTE_CREATE_FAIL,
            payload: successResponse.UI_Display_Message
          });
        }
      })
      .catch(error => {
        dispatch({
          type: BIDDING_SERVICE_QUOTE_CREATE_FAIL,
          payload: "Please try sometime later.Currently server is not working"
        });
      })
  };
/*=============================================================
                   User Delete
===============================================================*/

// Filter 
export const employeeFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: BIDDING_SERVICE_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//Search Filter
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: BIDDING_SERVICE_FILTER,
      fType: "search",
      value: "",
    });
  }
}
