import axios from "axios";
import shopperconfig from "./shopperconfig";
import config from "../config";
export const SET_ALL_PARTNERS = "SET_ALL_PARTNERS";
export const ALL_PARTNERS_FAIL = " ALL_PARTNERS_FAIL"
export const ALL_PARTNERS_REQUEST = "ALL_PARTNERS_REQUEST";

export const ALL_PARTNER_REQUEST = "ALL_PARTNER_REQUEST";
export const ALL_PARTNER_GET = "ALL_PARTNER_GET";
export const ALL_PARTNER_FAIL = "ALL_PARTNER_FAIL";
export const TOP_PARTNER = "TOP_PARTNER";
export const TOP_PARTNER_FAIL = "TOP_PARTNER_FAIL";
export const TOP_PARTNER_SUCCESS = "TOP_PARTNER_SUCCESS";

export const GET_SINGLE_PARTNER = "GET_SINGLE_PARTNER";
export const ALL_PARTNER_USER_REVIEWS_REQUEST = "ALL_PARTNER_USER_REVIEWS_REQUEST";
export const ALL_PARTNER_USER_REVIEWS = "ALL_PARTNER_USER_REVIEWS";
export const ALL_PARTNER_USER_REVIEWS_FAIL = "ALL_PARTNER_USER_REVIEWS_FAIL";

export const PARTNER_REVIEW_CREATE_REQUEST = "PARTNER_REVIEW_CREATE_REQUEST";
export const PARTNER_REVIEW_CREATE_SUCCESS = "PARTNER_REVIEW_CREATE_SUCCESS";
export const PARTNER_REVIEW_CREATE_FAIL = "PARTNER_REVIEW_CREATE_FAIL";

export const PARTNER_REVIEW_UPDATE_REQUEST = "PARTNER_REVIEW_UPDATE_REQUEST";
export const PARTNER_REVIEW_UPDATE_SUCCESS = "PARTNER_REVIEW_UPDATE_SUCCESS";
export const PARTNER_REVIEW_UPDATE_FAIL = "PARTNER_REVIEW_UPDATE_FAIL";

export const PARTIALPARTNERRESET = "PARTIALPARTNERRESET";
const cudheaders = config.nodeheadersCommon;
const headers = shopperconfig.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
/*=============================================================
                  Get All stores Action
===============================================================*/
export const getAllpartnerAction = (type: any, EmailID: any) => async (dispatch: any) => {
  try {
    let formData;
    if (type === "Is_Top_Partner") {
      dispatch({
        type: TOP_PARTNER,
      });
      formData = {
        "Records_Filter": "FILTER",
        "Top_Stores": "1",
        "Status": "Active"
      };
    }
    else if (type === "") {
      formData = {
        "Records_Filter": "FILTER",
        "Email_Id": EmailID.Email_Id,
        "Partner_Detail_Id": EmailID.Partner_Details_Id,
        "Status": "Active"
      };
    }
    dispatch({
      type: ALL_PARTNERS_REQUEST,
    });


    let { data } = await axios.post(

      `${shopperconfig.nodePartnerUrl}/B2B_Partner/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (type === "Is_Top_Partner") {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: TOP_PARTNER_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: TOP_PARTNER_SUCCESS,
            payload: data.results
          });
        }
      } else if (type === "") {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: SET_ALL_PARTNERS,
            payload: []
          });
        } else {
          dispatch({
            type: SET_ALL_PARTNERS,
            payload: data.Physical_Partners
          });
        }
      }

    }
  } catch (error) {
  }
};
export const getAllfilterpartnerAction = (callBack: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_PARTNERS_REQUEST,
    });
    let formData = {
      "Records_Filter": "FILTER",
      "Partner_Details_Id": "",
      "Name": "",
      "Status": "Active",
      "User_Tips": ""
    };

    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2B_Partner/Get`,
      formData,
      {
        headers: nodeheaders
      }

    );
    if (data) {
      callBack(data.results)
    }
  } catch (error) {
    dispatch({
      type: ALL_PARTNERS_FAIL,
      payload: error,
    });
  }
};
export const getPartnersByIdAction = (partnerId: any, callBackGetPartnerData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_PARTNER_REQUEST,
    });
    const formData = {
      Records_Filter: "FILTERS",
      Partner_Detail_Id: partnerId,
    };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2B_Partner/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackGetPartnerData(data.results[0]);
    }
  } catch (error) {
    dispatch({
      type: ALL_PARTNER_FAIL,
      payload: error
    });
  }
};

export const partialpartnerreset = () => async (dispatch: any) => {
  dispatch({
    type: PARTIALPARTNERRESET,
    payload: []
  });
}
export const getUserReviewsbyIdAction = (PartnerId: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_PARTNER_USER_REVIEWS_REQUEST,
    });
    const formData = {
      "search_by_filter": "",
      "search": "",
      "Records_Filter": "FILTERS",
      "Partner_Details_Id": PartnerId,
      "Partner_Review_Id": "",
      "Status": "Active"
      // Partner_Details_Id: PartnerId,
      // Records_Filter: "FILTERS",
      // Status:'Active'
    }
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Review_Partner/Get`,
      formData,
      {
        headers: nodeheaders
      }

    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_PARTNER_USER_REVIEWS,
          payload: []
        });
      } else {
        dispatch({
          type: ALL_PARTNER_USER_REVIEWS,
          payload: data.results
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ALL_PARTNER_USER_REVIEWS_FAIL,
      payload: error
    });
  }
};
export const addPartnerReviewAction = (formData:any, Product_Id:any,toast:any) => async (dispatch:any) => {

  dispatch({
    type: PARTNER_REVIEW_CREATE_REQUEST,
  });
  axios.post(
    `${config.nodecudurl}/Partner_Review_Partner/Create`,
    formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        toast.show({ message: 'Review added successfully.', type: 'info', duration: 3000, position: 'top' });
        dispatch({
          type: PARTNER_REVIEW_CREATE_SUCCESS
        });
        let form = {
          Product_Id: Product_Id,
          Record_Filter: "FILTER"
        }
        dispatch(getUserReviewsbyIdAction(Product_Id));
      } else {
        toast.show({ message: 'Failed to add Review, please try again.', type: 'info', duration: 3000, position: 'top' });
        dispatch({
          type: PARTNER_REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      toast.show({ message: "Please try sometime later.Currently server is not working", type: 'info', duration: 3000, position: 'top' });
      dispatch({
        type: PARTNER_REVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};
export const editPartnerReviewAction = (formData:any, Partner_Id:any) => async (dispatch:any) => {
  dispatch({
    type: PARTNER_REVIEW_UPDATE_REQUEST,
  });
  axios.post(
    `${config.nodecudurl}/Partner_Review_Partner/Update`,
    formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNER_REVIEW_UPDATE_SUCCESS
        });
        let form = {
          Product_Id: Partner_Id,
          Record_Filter: "FILTER"
        }
        dispatch(getUserReviewsbyIdAction(Partner_Id));
      } else {

        dispatch({
          type: PARTNER_REVIEW_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_REVIEW_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};