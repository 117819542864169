import React from 'react';
import { Card, Button, HelperText, Surface, TextInput, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import { singlevendorAction, updateGiftVendorAction } from '../../../state/actions/giftvendorAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const Statusopt = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    },
]
const EditGiftVendor = (props: any) => {
    const { navigation } = props;
    //toast for popup after successful API calls.
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const partnerid = props.route?.params?.partnerid;
    const Vendorid = props.route?.params?.Vendorid;
    const esiColor = useSelector(state => state.theme);

    const [vendorGet, setData] = React.useState([]);
    const callBackData = (data: any) => {
        setData(data)
    }
    React.useEffect(() => {
        let formData = {
            "search_by_filter": "",
            "search": "",
            "Partner_Details_Id": partnerid,
            "Records_Filter": "FILTER",
            "Vendor_Id": Vendorid,
            "Vendor_Name": ""
        };
        dispatch(singlevendorAction(formData, callBackData));
    }, []);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });

    React.useEffect(() => {
        if (!(permissions.Gifts_Management_Vendors_Images_User_Gifts === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const dispatch = useDispatch();
    const [showDropDown, setShowDropDown] = React.useState('');

    //Screen dimension calculting call.
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        editor: {
            backgroundColor: "black",
            borderColor: "black",
            borderWidth: 1,
        },
        rich: {
            minHeight: 130,

        },
        richBar: {
            height: 50,
            backgroundColor: "#F5FCFF",
        },
        text: {
            fontWeight: "bold",
            fontSize: 18,
        },
        tib: {
            textAlign: "center",
            color: "#515156",
        },
        a: {
            fontWeight: "bold",
            color: "purple",
        },
        div: {
            fontFamily: "monospace",
        },
        p: {
            fontSize: 30,
        }, container: {
            paddingTop: 15
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    /*====================================
            Handler
    ====================================*/
    return (
        <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik
                    initialValues={{
                        Modified_By: EmailID.Email_Id,
                        Partner_Details_Id: vendorGet.Partner_Details_Id,
                        Status: vendorGet.Status,
                        Vendor_Email_Id: vendorGet.Vendor_Email_Id,
                        Vendor_Id: vendorGet.Vendor_Id,
                        Vendor_Name: vendorGet.Vendor_Name
                    }}
                    // form validations
                    validationSchema={Yup.object().shape({
                        Vendor_Name: Yup.string().required('Vendor Name is required.'),
                        Status: Yup.string().required('Status is required.'),
                        Vendor_Email_Id: Yup.string().email('Must be a valid Email.').max(255).required('Vendor Email ID is required.')
                    })}
                    enableReinitialize={true}
                    onSubmit={(values: any, { setSubmitting, setErrors }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        setErrors(errors);
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            dispatch(updateGiftVendorAction(formValues, navigation, toast));
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        resetForm
                    }) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        resetForm();
                                        navigation.navigate('GiftVendorsGet')
                                    }}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                        Edit Gift Vendor
                                    </Text>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexShrink: 1, width: 730 }}>
                                            <View style={{ flexShrink: 1, paddingTop: 15 }}>
                                                <TextInput
                                                    // theme={customTheme}
                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Vendor_Name && errors.Vendor_Name)}
                                                    onBlur={handleBlur}
                                                    onChangeText={handleChange('Vendor_Name')}
                                                    label="Vendor Name"
                                                    name="Vendor_Name"
                                                    value={values.Vendor_Name}
                                                    mode="outlined"
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Vendor_Name && errors.Vendor_Name)}>
                                                    {touched.Vendor_Name && errors.Vendor_Name}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexShrink: 1, paddingTop: 15 }}>
                                                <TextInput
                                                    // theme={customTheme}
                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Vendor_Email_Id && errors.Vendor_Email_Id)}
                                                    onBlur={handleBlur}
                                                    onChangeText={handleChange('Vendor_Email_Id')}
                                                    label="Vendor Email ID"
                                                    name="Vendor_Email_Id"
                                                    value={values.Vendor_Email_Id}
                                                    mode="outlined"
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Vendor_Email_Id && errors.Vendor_Email_Id)}>
                                                    {touched.Vendor_Email_Id && errors.Vendor_Email_Id}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexShrink: 1, width: 730 }}>
                                            <View style={{ flexGrow: 1, flexShrink: 1, marginTop: '1%' }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Status && errors.Status) && { color: '#b00020' }]}>
                                                        Status
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Status && errors.Status) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={Statusopt}
                                                        labelField="label"
                                                        valueField="value"
                                                        maxHeight={Statusopt.length > 1 ? 250 : 200}
                                                        search={Statusopt.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Status'}
                                                        value={values.Status}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Status', item.value)
                                                            setShowDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Status && errors.Status)}>
                                                        {touched.Status && errors.Status}
                                                    </HelperText>
                                                </View>
                                            </View>
                                        </View>

                                    </View>
                                    <Button textColor={esiColor.itemButtenColor} style={{ width: 130, marginLeft: "auto", marginRight: "auto", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                        Save
                                    </Button>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface >
    );
};

export default EditGiftVendor;