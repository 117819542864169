import { useEffect, useState } from "react";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { getbiddingCategoriessAction } from "../../state/actions/bidding/biddingproductActions";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";

export const CategoryFilters = (props) => {
    const { filters, setFilters } = props;
  const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState([]);
    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setCategory(data?.map(item => ({ "value": item.Bidding_Products_Category_Id, "label": item.Bidding_Products_Category_Name, image: item.Bidding_Products_Category_Image })));
        } else {
            setCategory([]);
        }
    }
    const updateStates = (data) => {
        if (data) {
            dispatch(getbiddingCategoriessAction({
                "search_by_filter": "",
                "search": data,
                "Bidding_Products_Category_Id": "",
                "Records_Filter": "",
                "Bidding_Products_Category_Name": "",
                "NotEquelCategorys": filters?.map((item) => item.value) || []
            },
                { PageNo: 1, PageSize: 100, SortBy: "Bidding_Products_Category_Id", SortOrder: "ASC" },
                callBackGetData
            ));
        }

    };
    useEffect(() => {
        dispatch(getbiddingCategoriessAction({
            "search_by_filter": "",
            "search": '',
            "Bidding_Products_Category_Id": "",
            "Records_Filter": "",
            "Bidding_Products_Category_Name": "",
            "NotEquelCategorys": filters?.map((item) => item.value) || []
        },
            { PageNo: 1, PageSize: 100, SortBy: "Bidding_Products_Category_Id", SortOrder: "ASC" },
            callBackGetData
        ));
    }, [filters]);
    const style = StyleSheet.create({

        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
    
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
    
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        //   containerstyle: {
        //     width: 150
        //   }
    });
    return (
        <View style={[{flex:1, marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../assets/images/favicon.png')}
                />
                <Text style={{
                    position: 'absolute',
                    backgroundColor: esiColor.BackgroundColor,
                    color: esiColor.SIconColor,
                    left: 22,
                    top: 8,
                    zIndex: 999,
                    paddingHorizontal: 8,
                    fontSize: 14,
                }}>Category</Text>
            </View>
            <EsiSearchDropdown
                style={[style.dropdown]}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                inputSearchStyle={style.inputSearchStyle}
                iconStyle={style.iconStyle}
                containerStyle={style.containerstyle}
                data={category}
                labelField="label"
                valueField="value"
                placeholder={category ? 'Select Category' : '...'}
                searchQuery={item => { updateStates(item) }}
                maxHeight={category.length > 1 ? 250 : 200}
                search
                searchPlaceholder={'Search Category'}
                value={category.Bidding_Products_Category_Id}

                onUpdateValue={item => {

                    if (item?.value) {
                        if (filters?.filter((filters) => item?.value == filters?.value).length == 0)
                            setFilters((props) => props.concat([item]));
                    }
                }}
            />
            {filters?.length > 1 &&
                <View style={{ flexDirection: "row", justifyContent: "flex-end" }} >
                    <TouchableOpacity onPress={() => { setFilters([]) }} >
                        <Text style={{ color: esiColor.actionColor, margin: 5 }} >Clear All</Text>
                    </TouchableOpacity>
                </View>}
            <View>
                {filters?.map((item, index) => {
                    const isEvenIndex = index % 2 === 0;
                    const backgroundColor = isEvenIndex ? '#f0f0f0' : '#fff';
                    return (
                        <View style={{
                            flexDirection: "row", borderRadius: 25,
                            paddingRight: Platform.OS == "android" ? 0 : 10,
                            backgroundColor: backgroundColor,
                            borderWidth: 1,
                            shadowOffset: {
                                width: 0,
                                height: 5,
                            },
                            shadowOpacity: 0.3,
                            shadowRadius: 8.46,
                            marginVertical: 2,
                            elevation: 13
                        }} key={"cat" + item.value} >
                            <View style={{
                                flex: 3, flexDirection: "row"
                            }} >
                                <Text style={{ marginVertical: 5, marginLeft: 5 }} > {item.label} </Text>

                            </View>
                            <View style={{
                                flex: 1, flexDirection: "row", justifyContent: "flex-end"
                            }} >
                                <TouchableOpacity onPress={() => { setFilters((props) => props?.filter((prop) => prop.value != item.value)) }} style={{ marginTop: 2 }} >
                                    <Ionicons name="md-trash" size={22} color="#ee4d2d" />
                                </TouchableOpacity>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
}
