import { combineReducers } from "redux";
import addressReducer from "./addressReducer";
import authReducer from "./authReducer";
import brandReducer from "./brandReducer";
import dealReducer from "./dealReducer";
import employeeReducer from "./employeeReducer";
import ticketReducers from "./ticketReducers";
import productmasterReducer from './productmastersReducer';
import productReducer from './productsReducer';
import productsizeReducer from './productsizeReducer';
import productColorReducer from './productcolorReducer';
import relatedProcuctReducer from './relatedproductsReducer';
import productpartnerReducer from './/partnerproductsReducer';
import partnerprofileReducer from "./partnerprofileReducer";
import couponReducer from "./couponReducer";
import partnerpaymentReducer from "./partnerpaymentReducer";
import transactionhistoryReducer from "./transactionhistoryReducer";
import userpaymentReducer from "./userpaymentReducer";
import orderreturnandcancelReducer from "./orderreturnandcancelReducer";
import settingsReducer from "./settingsReducer";
import partnerbankReducer from "./partnerbankReducer";
import howpartnerassocworksReducer from "./howpartnerassocworksReducer";
import servicelevelagreementReducer from "./servicelevelagreementReducer";
import inventoryReducer from "./inventoryReducer";
import sbcpartnerCityReducer from "./sbcpartnerCityReducer";
import sbcpartnercityCouponReducer from "./sbcpartnercityCouponReducer";
import sbcpartnercityDealReducer from "./sbcpartnercityDealReducer";
import sbcpartnercityProductReducer from "./sbcpartnercityProductReducer";
import reviewsandratingsReducer from "./reviewsandratingsReducer";
import shippingReducer from "./shippingReducer";
import giftvendorReducer from "./giftvendorReducer";
import giftReducer from "./giftReducer";
import giftimageReducer from "./giftimageReducer";
import ordermanagementReducer from "./ordermanagementReducer";
import dashboardReducer from "./reports/dashboardReducer";
import Statistics from "../../screens/reports/statistics";
import statisticsReducer from "./reports/statisticsReducer";
import deliveryagentReducer from "./deliveryagentReducer";
import corporatedynamicReducer from "./dynamiccountReducers"
import contentReducer from "./contentReducer";
import ticketingReducer from "./ticketingReducer";
import pushnotificationReducer from "./pushnotificationReducer";
import { shopbycitylocationFilter } from "../actions/partnershopbylocationAction";
import partnershopbylocationReducer from "./partnershopbylocationReducer";
import productsReducer from "./shoppers/shopperproductsReducer";
import filterServiceReducer from "./shoppers/filterServiceReducer";
import categoriesReducer from "./shoppers/shppercategoriesReducer";
import brandsReducer from "./shoppers/shopperbrandsReducer";
import partnersReducer from "./shoppers/shopperpartnerReducer";
import addCartReducer from "./shoppers/shopperCartReducer";
import addressUserReducer from "./shoppers/addressUserReducer";
import shoppertransactionReducer from "./shoppers/shoppertransactionReducer";
import searchGlobal from "./shoppers/searchGlobal";
import orderdetailsReducer from "./shoppers/shoppersorderDetailsReducers";
import ordersReducer from "./shoppers/shoppersorderReducer";
import shopersReducer from "./shoppers/shopersReducer";
import shoppersinventoryReducer from "./shoppers/shoppersinventoryReducer";
import transactionReducer from "./transactionReducer";
import b2bordersReducer from "./b2bordersReducer";
import walletReducer from "./walletReducer";
import propertiesReducer from "./Properties/propertiesReducer";
import eventsReducer from "./Properties/eventsReducer";
import userservicesReducer from "./Services/userservicesReducer";
import partnerserviceReducer from "./Services/partnerserviceReducer";
import bookedReducer from "./Properties/bookedReducer";
import biddingServicessReducer from "./biddingServicessReducer";
import biddingProductsReducer from "./biddingProductsReducer";
import biddingQuotesReducer from "./userNewbiddingQuotesReducer";
import userNewbiddingQuotesReducer from "./userNewbiddingQuotesReducer";
import partnerbiddingQuotesReducer from "./partnerbiddingQuotesReducer";
import commentReducer from "./Bidding/biddingboomiReducer";
import userNewBiddingQuoteForServiceReducer from "./userNewBiddingQuoteForServiceReducer";
import partnerbiddingServiceQuotesReducer from "./partnerbiddingServiceQuotesReducer";
import biddingservicequotecommentsReducer from "./biddingservicequotecommentsReducer";
import b2buserProductsNewBiddingQuoteReducer from "./b2buserProductsNewBiddingQuoteReducer";
import b2buserpartnerReducer from "./b2cpartnerReducer";
import themeReducer from "./themeReducer";
import farmerproductsReducer from "./farmer/farmerproductsReducer";
import farmerpartnerproductsReducer from "./farmer/farmerpartnerproductsReducer";
import farmerinventoryReducer from "./farmer/farmerinventoryReducer";
import farmerordermanagementReducer from "./farmer/farmerordermanagementReducer";
import farmerorderreturnandcancelReducer from "./farmer/farmerorderreturnandcancelReducer";
import farmershippingReducer from "./farmer/farmershippingReducer";
import farmeraddressReducer from "./farmer/farmeraddressReducer";


const reducers = combineReducers({
  auth: authReducer,
  brand: brandReducer,
  employee: employeeReducer,
  ticket: ticketReducers,
  address: addressReducer,
  deal: dealReducer,
  product: productReducer,
  productmasters: productmasterReducer,
  productsize: productsizeReducer,
  productcolorvariant: productColorReducer,
  relatedproducts: relatedProcuctReducer,
  productpartners: productpartnerReducer,
  partner: partnerprofileReducer,
  coupon: couponReducer,
  partnerpayment: partnerpaymentReducer,
  userpayment: userpaymentReducer,
  transaction: transactionhistoryReducer,
  returnorders: orderreturnandcancelReducer,
  setting: settingsReducer,
  partnerbank: partnerbankReducer,
  howpartnerassocwork: howpartnerassocworksReducer,
  servicelevelagreement: servicelevelagreementReducer,
  inventory: inventoryReducer,
  sbcpartnercity: sbcpartnerCityReducer,
  sbcpartnercitycoupon: sbcpartnercityCouponReducer,
  sbcpartnercitydeal: sbcpartnercityDealReducer,
  sbcpartnercityproduct: sbcpartnercityProductReducer,
  reviewandrating: reviewsandratingsReducer,
  shipping: shippingReducer,
  giftvendor: giftvendorReducer,
  gifts: giftReducer,
  giftimage: giftimageReducer,
  ordersmanagement: ordermanagementReducer,
  dashboard: dashboardReducer,
  statistics: statisticsReducer,
  deliveryagent: deliveryagentReducer,
  corporatedateandcount: corporatedynamicReducer,
  allContents: contentReducer,
  Ticket: ticketingReducer,
  pushnotifications: pushnotificationReducer,
  shopbycitylocation: partnershopbylocationReducer,
  allProducts: productsReducer,
  filters: filterServiceReducer,
  allCategories: categoriesReducer,
  allBrands: brandsReducer,
  allPartners: partnersReducer,
  cart: addCartReducer,
  useraddress: addressUserReducer,
  alltransactions: shoppertransactionReducer,
  gSearch: searchGlobal,
  transactions: transactionReducer,
  orderDetail: orderdetailsReducer,
  allOrders: ordersReducer,
  shoppers: shopersReducer,
  shoppersinventory: shoppersinventoryReducer,
  b2borders: b2bordersReducer,
  wallet: walletReducer,
  property: propertiesReducer,
  userevent: eventsReducer,
  userservice: userservicesReducer,
  partnerservice: partnerserviceReducer,
  bookingsred: bookedReducer,
  biddingproducts: biddingProductsReducer,
  biddingservices: biddingServicessReducer,
  biddingquotes: partnerbiddingQuotesReducer,
  userBiddingquotes: userNewbiddingQuotesReducer,
  comment: commentReducer,
  userServiceBiddingquotes: userNewBiddingQuoteForServiceReducer,
  biddingservicequotes: partnerbiddingServiceQuotesReducer,
  biddingservicequotecomments: biddingservicequotecommentsReducer,
  b2bbiddinguserproductnewquotes: b2buserProductsNewBiddingQuoteReducer,
  b2buserpartnerReducer: b2buserpartnerReducer,
  theme: themeReducer,
  farmerproduct: farmerproductsReducer,
  farmerpartnerproduct: farmerpartnerproductsReducer,
  farmerinventory: farmerinventoryReducer,
  farmerordersget: farmerordermanagementReducer,
  farmerOrdersstatus: farmerorderreturnandcancelReducer,
  farmershipping: farmershippingReducer,
  farmeraddress: farmeraddressReducer,
})

export default reducers;
export type RootState = ReturnType<typeof reducers>