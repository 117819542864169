import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBrands, getAllCategories, uploadCouponImage, couponData, getCouponsByIdAction } from '../../../state/actions/couponAction';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, RefreshControl } from 'react-native';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import ImageUpload from '../../../components/custom/image-upload/upload';
import EsiDatePicker from '../../../components/custom/date/date';
import Header from '../../auth/components/header';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//discountType and esiCashbackTypeOption are used to store the data used for dropdowns
const discountType = [
  {
    value: '',
    label: '--Please Select--'
  },

  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
];



//discountlabelType and cashbacklabelType are used to get the data from dropdown
const discountlabelType = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'UPTO',
    label: 'UPTO'
  },
  {
    value: 'FLAT',
    label: 'FLAT'
  },
];


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const EditCouponBasic = (props: any) => {
  //navigation is used for navigation between screens
  const { navigation } = props
  const toast = useToast();
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const esiColor = useSelector(state => state.theme);
  React.useEffect(() => {
    if (!(permissions.Coupons_Management === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);
  //coupons contains the data from previous form using props 
  const ID = props?.route?.params?.ID;
  const Detailid = useSelector(state => state.auth?.user.Partner_Details_Id);
  //brands, categories contains the data from API's using state call
  const brands = useSelector(state => state.coupon.couponBrands.brands);
  const categories = useSelector(state => state.coupon.couponCategories.categories);
  const [data, setData] = useState([]);
  //dispatch is used to perform the API action

  const callBackCoupon = (data: any) => {
    setData(data)
    setImgUrl(data[0].Image_Path)
    setImage({
      preview: data[0].Image_Path,
      raw: data[0].Image_Path
    });
  }

  useEffect(() => {
    if (Detailid) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Partner_Details_Id": Detailid,
        "Records_Filter": "FILTER",
        "Coupon_Id": ID
      };
      dispatch(getCouponsByIdAction(formData, callBackCoupon))
      dispatch(getAllBrands());
      dispatch(getAllCategories());
    }
  }, [Detailid]);

  //Dropdowns
  const [brandDropDown, setBrandDropDown] = useState("");
  const [categoryDropDown, setCategoryDropDown] = useState("");
  const [discDropDown, setdiscDropDown] = useState("");
  const [disclabelDropDown, setdisclabelDropDown] = useState("");


  let brandlist = []
  if (brands) {
    if (brands[0]) {
      for (let i = 0; i < brands.length; i++) {
        brandlist.push({
          label: brands[i].Brand_Name,
          value: brands[i].Brand_Id
        })
      }
    }
  }

  let categorylist = []
  if (categories) {
    if (categories[0]) {
      for (let i = 0; i < categories.length; i++) {
        categorylist.push({
          label: categories[i].Category_Name,
          value: categories[i].Category_Id
        })
      }
    }
  }

  const dispatch = useDispatch();

  // img upload
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImage = (data: any) => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = async (result: any) => {
    const img = await fetchImageFromUri(result.uri);
    setImage({
      preview: result.uri,
      raw: img
    });
    dispatch(
      uploadCouponImage(img, callBackUploadImage)
    );
  };

  const fetchImageFromUri = async (uri: any) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );

  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  /*====================================
          Handler
  ====================================*/

  const values = {
    Brand_Id: data[0]?.Brand_Id ? data[0].Brand_Id : "",
    Category_Id: data[0]?.Category_Id ? data[0].Category_Id : "",
    Code: data[0]?.Code ? data[0].Code : "",
    Coupon_Id: data[0]?.Coupon_Id,
    Coupon_Max_Count: data[0]?.Coupon_Max_Count ? data[0].Coupon_Max_Count : "",
    Coupon_Pending_Count: data[0]?.Coupon_Pending_Count ? data[0].Coupon_Pending_Count : "" + "",
    Description: data[0]?.Description ? data[0].Description : "",
    Discount_Amount: data[0]?.Discount_Amount ? data[0].Discount_Amount : "",
    Discount_Type: data[0]?.Discount_Type ? data[0].Discount_Type : "",
    ESI_Cashback_Amount: data[0]?.ESI_Cashback_Amount ? data[0].ESI_Cashback_Amount : "",
    ESI_Cashback_Type: data[0]?.ESI_Cashback_Type ? data[0].ESI_Cashback_Type : "",
    Exclusive: data[0]?.Exclusive > 0 ? true : false,
    Extra_Cashback: data[0]?.Extra_Cashback > 0 ? true : false,
    Image_Path: imgUrl,
    Is_Coupon_Expired: data[0]?.Is_Coupon_Expired > 0 ? true : false,
    Is_Top_Coupon: data[0]?.Is_Top_Coupon > 0 ? true : false,
    Is_Upcoming: data[0]?.Is_Upcoming > 0 ? true : false,
    Mobile_Redirect_Url: data[0]?.Mobile_Redirect_Url ? data[0].Mobile_Redirect_Url : "",
    Name: data[0]?.Name ? data[0].Name : "",
    Partner_Details_Id: data[0]?.Partner_Details_Id,
    Partner_Name: data[0]?.Partner_Name,
    Partner_Type: data[0]?.Partner_Type,
    Status: data[0]?.Status,
    Type: data[0]?.Type ? data[0].Type : "",
    Valid_From_Date: data[0]?.Valid_From_Date ? data[0].Valid_From_Date : "" + " ",
    Valid_Through_Date: data[0]?.Valid_Through_Date ? data[0].Valid_Through_Date : "" + " ",
    Verified: data[0]?.Verified > 0 ? true : false,
    Web_Redirect_Url: data[0]?.Web_Redirect_Url ? data[0].Web_Redirect_Url : "",
    Whats_Hot: data[0]?.Whats_Hot > 0 ? true : false,
    Discount_Label: data[0]?.Discount_Label,
    Cashback_Label: data[0]?.Cashback_Label,
  }

  //Form validation
  const Form_Validation = Yup.object().shape({
    Category_Id: Yup.string().test(
      'oneOfRequired',
      'Category / Brand Name is required.',
      function (item) {
        return (this.parent.Category_Id || this.parent.Brand_Id)
      }
    ),
    Brand_Id: Yup.string().test(
      'oneOfRequired',
      'Category / Brand Name is required.',
      function (item) {
        return (this.parent.Category_Id || this.parent.Brand_Id)
      }
    ),
    Discount_Type: Yup.string().test(
      'oneOfRequired',
      'Discount Type is required.',
      function (item) {
        return (this.parent.Discount_Type)
      }
    ),
    Discount_Amount: Yup.number().when("Discount_Type", (Discount_Type, schema) => {
      if (Discount_Type == "A") {
        return schema.min(1, "Discount Amount must be at least 1.").required('Discount Amount is required.');
      } else if (Discount_Type == "P") {
        return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be <= 100.").required('Discount Percentage is required.');
      }
    }),
    Discount_Label: Yup.string().when("Discount_Amount", (Discount_Amount, schema) => {
      if (Discount_Amount) {
        return schema.required('Discount Label is required.');
      }
    }),
    Valid_From_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require From Date greater than or equal to today.").required("Valid From Date is required."),
    Valid_Through_Date: Yup.date().when("Valid_From_Date", (Valid_From_Date, schema) => Valid_From_Date && schema.min(Valid_From_Date, "Through Date can't be before From Date.")).required("Valid Through Date is required."),
    Coupon_Max_Count: Yup.number()
      .min(Yup.ref('Coupon_Pending_Count'), 'Coupon Max Count should be greater than or equal to Pending coupon count.')
      .required('Coupon Max Count is required.'), Coupon_Pending_Count: Yup.number().min(1, "Coupon Pending Count must be at least 1.").required('Coupon Pending Count is required.'),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    values.Image_Path = imgUrl;
    if (!values.Image_Path) {
      submitTest = false;
      errors.Image_Path = "Image is required.";
    }

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      //passing the formvalues to next page through dispatch
      dispatch(couponData(formValues));
      //navigation to next page after validating the form
      navigation.navigate('EditCouponConfig')
    }
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };


  const styles = StyleSheet.create({
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },

    containerone: {
      backgroundColor: 'white',
      paddingTop: 15
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },

  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      {data.length > 0 &&
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              colors={['#27B6CC']}
              tintColor={'#27B6CC'}
              title={"Pull to refresh"}
              titleColor={"#27B6CC"}
            />
          }
        >
          <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
            {(props) => (
              <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => { props.resetForm(); navigation.navigate('CouponsGet') }}>
                    Go Back
                  </Button>
                </View>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Edit Coupon
                    </Text>
                    <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                      <View style={{ flexShrink: 1, width: 650 }}>
                        {/*  Code Input Field  with Validation */}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                            <TextInput
                              // theme={customTheme}
                              style={{
                                marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                borderColor: esiColor.TextOuterLine, borderWidth: 1
                              }}
                              error={Boolean(props.touched.Code && props.errors.Code)}
                              label='Code'
                              name='Code'
                              mode='outlined'
                              disabled
                              value={props.values.Code}
                              onChangeText={props.handleChange('Code')}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Code && props.errors.Code)}>
                              <ErrorMessage name="Code" />
                            </HelperText>
                          </View>
                          {/*  Name Input Field  with Validation */}
                          <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                            <TextInput
                              // theme={customTheme}
                              style={{
                                marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                borderColor: esiColor.TextOuterLine, borderWidth: 1
                              }}
                              label='Coupon Name'
                              mode='outlined'
                              name="Name"
                              disabled
                              value={props.values.Name}
                              onChangeText={props.handleChange('Name')}
                              error={Boolean(props.touched.Name && props.errors.Name)}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Name && props.errors.Name)}>
                              <ErrorMessage name="Name" />
                            </HelperText>
                          </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          {/* Brand Name field with validation*/}
                          <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Brand_Id && props.errors.Brand_Id) && { color: '#b00020' }]}>
                              Brand Name
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Brand_Id && props.errors.Brand_Id) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              dropdownPosition="bottom"
                              placeholder={!brandDropDown ? '-- Please Select --' : ''}
                              data={brandlist}
                              maxHeight={180}
                              labelField="label"
                              valueField="value"
                              search
                              searchPlaceholder={'Select Brand Name'}
                              value={props.values.Brand_Id}
                              onUpdateValue={item => {
                                props.setFieldValue('Brand_Id', item.value)
                                setBrandDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Brand_Id && props.errors.Brand_Id)}>
                              <ErrorMessage name="Brand_Id" />
                            </HelperText>
                          </View>

                          {/* Category Name field with validation*/}
                          <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Category_Id && props.errors.Category_Id) && { color: '#b00020' }]}>
                              Category Name
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Category_Id && props.errors.Category_Id) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              dropdownPosition="bottom"
                              placeholder={!categoryDropDown ? '-- Please Select --' : ''}
                              data={categorylist}
                              maxHeight={180}
                              labelField="label"
                              valueField="value"
                              search
                              searchPlaceholder={'Select Category Name'}
                              value={props.values.Category_Id}
                              onUpdateValue={item => {
                                props.setFieldValue('Category_Id', item.value)
                                setCategoryDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Category_Id && props.errors.Category_Id)}>
                              <ErrorMessage name="Category_Id" />
                            </HelperText>
                          </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          {/* Discount Type field with validation*/}
                          <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { color: '#b00020' }]}>
                              Discount Type
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              dropdownPosition="bottom"
                              placeholder={!discDropDown ? '-- Please Select --' : ''}
                              data={discountType}
                              labelField="label"
                              valueField="value"
                              maxHeight={discountType.length > 1 ? 250 : 200}
                              search={discountType.length > 1 ? true : false}
                              searchPlaceholder={'Select Discount Type'}
                              value={props.values.Discount_Type}
                              onUpdateValue={item => {
                                props.setFieldValue('Discount_Type', item.value)
                                setdiscDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Discount_Type && props.errors.Discount_Type)}>
                              <ErrorMessage name="Discount_Type" />
                            </HelperText>
                          </View>

                          {/* Discount Amount field with validation*/}
                          <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                            <TextInput
                              // theme={customTheme}
                              style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                              outlineColor={esiColor.TextOuterLine}
                              underlineColor={esiColor.TextUnderline}
                              activeOutlineColor={esiColor.TextOuterLine}
                              mode='outlined'
                              name="Discount_Amount"
                              disabled={props.values.Discount_Type ? false : true}
                              label={"Discount  " + (props.values.Discount_Type === "A" ? "Amount ₹" : props.values.Discount_Type === "P" ? "Percentage %" : "")}
                              value={props.values.Discount_Amount.toString()}
                              onChangeText={props.handleChange('Discount_Amount')}
                              maxLength={10}
                              keyboardType='numeric'
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Discount_Amount && props.errors.Discount_Amount)}>
                              <ErrorMessage name="Discount_Amount" />
                            </HelperText>
                          </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          {/* Discount Label field with validation*/}
                          <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { color: '#b00020' }]}>
                              Discount Label
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              dropdownPosition="bottom"
                              placeholder={!disclabelDropDown ? '-- Please Select --' : ''}
                              data={discountlabelType}
                              labelField="label"
                              valueField="value"
                              maxHeight={discountlabelType.length > 1 ? 250 : 200}
                              search={discountlabelType.length > 1 ? true : false}
                              searchPlaceholder={'Select Discount Lable'}
                              disable={props.values.Discount_Amount ? false : true}
                              value={props.values.Discount_Label}
                              onUpdateValue={item => {
                                props.setFieldValue('Discount_Label', item.value)
                                setdisclabelDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Discount_Label && props.errors.Discount_Label)}>
                              <ErrorMessage name="Discount_Label" />
                            </HelperText>
                          </View>
                        </View>
                        {/* Valid From Date  Field with Validation */}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>

                            <EsiDatePicker
                              // theme={customTheme}
                              error={Boolean(props.touched.Valid_From_Date && props.errors.Valid_From_Date)}
                              style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                              name={'Valid_From_Date'}
                              label={'Valid From Date'}
                              value={props.values.Valid_From_Date}
                              valueUpdate={item => {
                                props.setFieldValue('Valid_From_Date', item)
                              }}

                            />
                            <HelperText type="error" visible={Boolean(props.touched.Valid_From_Date && props.errors.Valid_From_Date)}>
                              <ErrorMessage name="Valid_From_Date" />
                            </HelperText>
                          </View>

                          {/*  Valid_Through_Date  Field with Validation */}
                          <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                            <EsiDatePicker
                              // theme={customTheme}
                              error={Boolean(props.touched.Valid_Through_Date && props.errors.Valid_Through_Date)}
                              style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                              name={'Valid_Through_Date'}
                              label={'Valid Through Date'}
                              value={props.values.Valid_Through_Date + " "}
                              valueUpdate={item => {
                                props.setFieldValue('Valid_Through_Date', item)
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Valid_Through_Date && props.errors.Valid_Through_Date)}>
                              <ErrorMessage name="Valid_Through_Date" />
                            </HelperText>
                          </View>
                        </View>
                        {/*  Coupon Max Count  Field with Validation */}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                            <TextInput
                              // theme={customTheme}
                              style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                              outlineColor={esiColor.TextOuterLine}
                              underlineColor={esiColor.TextUnderline}
                              activeOutlineColor={esiColor.TextOuterLine}
                              keyboardType='numeric'
                              label='Coupon Max Count'
                              mode='outlined'
                              name="Coupon_Max_Count"
                              value={props.values.Coupon_Max_Count + " "}
                              onChangeText={props.handleChange('Coupon_Max_Count')}
                              error={Boolean(props.touched.Coupon_Max_Count && props.errors.Coupon_Max_Count)}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Coupon_Max_Count && props.errors.Coupon_Max_Count)}>
                              <ErrorMessage name="Coupon_Max_Count" />
                            </HelperText>
                          </View>

                          {/* Coupon Pending Count  Field with Validation */}
                          <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                            <TextInput
                              // theme={customTheme}
                              style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                              outlineColor={esiColor.TextOuterLine}
                              underlineColor={esiColor.TextUnderline}
                              activeOutlineColor={esiColor.TextOuterLine}
                              keyboardType='numeric'
                              label='Coupon Pending Count'
                              mode='outlined'
                              name="Coupon_Pending_Count"
                              value={props.values.Coupon_Pending_Count + ''}
                              onChangeText={props.handleChange('Coupon_Pending_Count')}
                              error={Boolean(props.touched.Coupon_Pending_Count && props.errors.Coupon_Pending_Count)}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Coupon_Pending_Count && props.errors.Coupon_Pending_Count)}>
                              <ErrorMessage name="Coupon_Pending_Count" />
                            </HelperText>
                          </View>
                        </View>
                      </View>

                      {/* Image upload with validation*/}
                      <View style={{ flexShrink: 1, paddingTop: 20, paddingLeft: 15, width: 250 }} >
                        <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                        <HelperText type="error" visible={!imgUrl && props.touched.Image_Path && props.errors.Image_Path}>
                          {!imgUrl && props.touched.Image_Path && props.errors.Image_Path}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                      <Button textColor={esiColor.itemButtenColor} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                        Next
                      </Button>
                    </View>

                  </Card.Content>
                </Card>
              </View>
            )}
          </Formik>
        </ScrollView>
      }
    </Surface>
  );
};

export default EditCouponBasic;