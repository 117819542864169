import { v4 as uuid } from 'uuid';
import productmanagement from "../../assets/static/Dashboard/productmanagement.png"
import inventorymanagement from "../../assets/static/Dashboard/inventorymanagement.jpg"
import coupon_management from "../../assets/static/Dashboard/coupon_management.jpg"

export default [
  {
    id: uuid(),
    link: 'PartnerBiddingProducts',
    createdAt: 'Updated 2 hr ago',
    description:
      'Product Management is a process that focuses on bringing a new producs to Bidding One for the Useers.',
    media: productmanagement,
    title: 'Bidding Products',
    total: ''
  },
  {
    id: uuid(),
    link: 'UserNewBiddingQuotes',
    createdAt: 'Updated 2 hr ago',
    description: 'New Bidding Quotes it is used to display maily User requsted biddings which we have the products',
    media: inventorymanagement,
    title: 'User New Biddng Quotes',
    total: ''
  },
  {
    id: uuid(),
    link: 'PartnerBiddingQuotes',
    createdAt: 'Updated 2 hr ago',
    description:
      'Partner Bidding Quotes It is used to get Our Quoted Products.',
    media: coupon_management,
    title: 'Partner Bidding Quotes',
    total: ''
  },
 ];