import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, IconButton } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView, RefreshControl } from 'react-native';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import Header from '../auth/components/header';
import { AddTicketsAction, uploadAttachment } from '../../state/actions/ticketingActions';
import FileUpload from '../../components/custom/file-upload/upload';
import isEmpty from '../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { uriToBlob } from '../../components/custom/uriBlob';
import ImageUpload from '../../components/custom/image-upload/upload';
import { imageORfileupload } from '../../state/actions/commonImageOrFileUpload';


const IssuerelatedOption = [
  {
    value: 'Order',
    label: 'Order'
  },
  {
    value: 'Payment',
    label: 'Payment'
  },
  {
    value: 'Delivery',
    label: 'Delivery'
  },
  {
    value: 'Product',
    label: 'Product'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

const filetype = [
  {
    value: false,
    label: 'Doc'
  },
  {
    value: true,
    label: 'Image'
  }
];
function Addtickets(props: any) {
  const { navigation, valueUpdate, ...rest } = props
  const dispatch = useDispatch();
  const toast = useToast();
  //here iterating Partners name and Partners ID values 
  const EmailID = useSelector(state => state.auth.user.Email_Id);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (isEmpty(EmailID)) {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Ticketing");
        }
      }, 1000)
    }
  }, [EmailID]);
  const [typeDropDown, settypeDropDown] = useState("");
  const [statusDropDown, setStatusDropDown] = useState("");

  const [fileTypeDropDown, setFileTypeDropDown] = useState("");
  const [fileTypevalue, setFiletypeValue] = useState(false);
  const isSubmited = useSelector(state => state.Ticket.TicketCreate.isLoading);
  // img upload
  const [file, setFile] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [uploadFile, setUploadFile] = useState(false);

  const callBackUploadFile = (data: any) => {
    if (data) {
      setFileUrl(data.File_URL);
    }
    setUploadFile(false);
  };
  const handleImage = async (result: any) => {
    let call = result.uri;
    if (result) {
      let exe = "img";
      let uriParts = result.uri.split('.');
      if (Platform.OS == "web") {
        let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
        let filformate = fileExtension[0].split(':')
        exe = filformate[1].split('/')[1]
      } else {
        exe = uriParts[uriParts.length - 1]
      }

      let fileData = {
        File_name: "Ticket",
        Functionality: "Ticket",
        ext: exe,
        File_Type: "Images",
      };
      const img = await fetchImageFromUri(call);
      setImage({
        preview: result.uri,
        raw: img
      });
      dispatch(
        imageORfileupload(img, fileData, callBackUploadFile)
      );
    }
  };
  const fetchImageFromUri = async (uri) => {
    const blob = await uriToBlob(uri);
    return blob;
  };
  const handleFile = async (result: any) => {
    let call = result.assets[0].uri
    if (result) {
      let fileData = {
        fileName: result.assets[0].name.split(".")[0],
        ext: result.assets[0].name.split(".")[1],
        ticketId: "T-0000002",
      };

      const imageData = await fetchFileFromUri(call);
      setFile(result.assets[0].name);
      dispatch(uploadAttachment(imageData, fileData, callBackUploadFile));
    };
  }
  const fetchFileFromUri = async (uri: any) => {
    const blob = await uriToBlob(uri);
    return blob;
  };
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);

  const handleRemoveFile = e => {
    setFileUrl('');
    setFile('');
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const esiColor = useSelector(state => state.theme);

  const styles = StyleSheet.create({
    cardCircle: {
      width: (Platform.OS === 'web') ? "90%" : "98%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "2%",
      borderRadius: 6,
      borderWidth: 0.01,
      borderColor: '#D6D6D7'
    },
    tableheader: {
      width: 120,
      justifyContent: 'center'
    },
    container: {
      backgroundColor: 'white',
      paddingTop: 15
    },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    files: {
      backgroundColor: 'grey',
    },
    containerone: {
      backgroundColor: 'white',
      paddingTop: 15
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor

    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor

    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    datePickerStyle: {
      width: 358,
      height: 60,
      marginTop: 6,
    },
    input: {
      borderColor: "white",
      width: 320,
      borderWidth: 1,
      borderRadius: 10,
      padding: 10,
    },

  });

  /*====================================
          Handler
  ====================================*/

  return (
    <Surface theme={{ colors: { surface: esiColor.BackgroundColor } }} style={{ flex: 1, }}>
      <Header navigation={navigation} />
      <IconButton
        icon="arrow-left"
        size={27}
        color={"#27B6CC"}
        onPress={() => navigation.navigate('Ticketing')} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <Formik initialValues={{
          Attachment: fileUrl,
          Created_By: EmailID,
          Issue_Related_To: "",
          Support_Owner: "",
          Ticket_Description: "",
          Ticket_Owner: EmailID,
          Ticket_Status: "NEW",
          Ticket_Subject: "",
          Ticket_User_Type: "Partner"
        }}
          //field validations
          validationSchema={Yup.object().shape({
            Issue_Related_To: Yup.string().required('Type  is required.'),
            Ticket_Subject: Yup.string().required('Ticket Subject is required.'),
            Ticket_Description: Yup.string().required('Ticket Description  is required.'),

          })}
          onSubmit={(values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
            let submitTest = true;
            setSubmitting(false);
            const errors = {};

            values.Attachment = fileUrl;
            if (!values.Attachment) {
              submitTest = false;
              errors.Attachment = "Attachment is required.";
            }
            setErrors(errors);
            if (submitTest) {
              let formValues = JSON.parse(JSON.stringify(values));
              dispatch(AddTicketsAction(formValues, navigation, toast, resetForm, EmailID));

            }
          }
          }
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Card style={[styles.cardCircle, { backgroundColor: esiColor.BackgroundColor }]}>
              <Card.Content>
                <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                  Add Support Ticket
                </Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 5 }}>
                    <TextInput
                      style={{ backgroundColor: esiColor.TextBC }}
                      activeUnderlineColor={esiColor.TextOuterLine}
                      selectionColor={esiColor.TextSelection}
                      theme={{
                        colors: {
                          primary: esiColor.TextHighlight,
                          text: esiColor.Text,
                          placeholder: esiColor.TextPlaceholder,
                        }
                      }}
                      outlineColor={esiColor.TextOuterLine}
                      underlineColor={esiColor.TextUnderline}
                      activeOutlineColor={esiColor.TextOuterLine}
                      label='Ticket Subject'
                      mode='outlined'
                      name="Ticket_Subject"
                      value={values.Ticket_Subject}
                      onChangeText={handleChange('Ticket_Subject')}
                      error={Boolean(touched.Ticket_Subject && errors.Ticket_Subject)}
                    />
                    <HelperText type="error" visible={Boolean(touched.Ticket_Subject && errors.Ticket_Subject)}>
                      <ErrorMessage name="Ticket_Subject" />
                    </HelperText>
                  </View>
                </View>
                <TextInput
                  style={{ backgroundColor: esiColor.TextBC }}
                  activeUnderlineColor={esiColor.TextOuterLine}
                  selectionColor={esiColor.TextSelection}
                  theme={{
                    colors: {
                      primary: esiColor.TextHighlight,
                      text: esiColor.Text,
                      placeholder: esiColor.TextPlaceholder,
                    }
                  }}
                  outlineColor={esiColor.TextOuterLine}
                  underlineColor={esiColor.TextUnderline}
                  activeOutlineColor={esiColor.TextOuterLine}
                  error={Boolean(touched.Ticket_Description && errors.Ticket_Description)}
                  label="Ticket Description"
                  mode='outlined'
                  multiline
                  value={values.Ticket_Description}
                  onChangeText={handleChange("Ticket_Description")}
                />
                <HelperText type="error" visible={Boolean(touched.Ticket_Description && errors.Ticket_Description)}>
                  <ErrorMessage name="Ticket_Description" />
                </HelperText>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                    <Text style={[styles.dropdownlabel, Boolean(touched.Issue_Related_To && errors.Issue_Related_To) && { color: '#b00020' }]}>
                      Type
                    </Text>
                    <EsiSearchDropdown
                      style={[styles.dropdown, Boolean(touched.Issue_Related_To && errors.Issue_Related_To) && { borderColor: '#b00020' }]}
                      placeholderStyle={styles.placeholderStyle}

                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      dropdownPosition="bottom"
                      placeholder={!typeDropDown ? '-- Please Select --' : ''}
                      data={IssuerelatedOption}
                      labelField="label"
                      valueField="value"
                      maxHeight={IssuerelatedOption.length > 1 ? 250 : 200}
                      search={IssuerelatedOption.length > 1 ? true : false}
                      searchPlaceholder={'Select Type'}
                      value={values.Issue_Related_To}
                      onUpdateValue={item => {
                        setFieldValue('Issue_Related_To', item.value)
                        settypeDropDown(false);
                      }}
                    />
                    <HelperText type="error" visible={Boolean(touched.Issue_Related_To && errors.Issue_Related_To)}>
                      <ErrorMessage name="Issue_Related_To" />
                    </HelperText>

                  </View>
                  <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                    <Text style={[styles.dropdownlabel]}>
                      File Type
                    </Text>
                    <EsiSearchDropdown
                      style={[styles.dropdown]}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      dropdownPosition="bottom"
                      placeholder={!fileTypeDropDown ? '-- Please Select --' : ''}
                      data={filetype}
                      maxHeight={180}
                      labelField="label"
                      valueField="value"
                      value={fileTypevalue}
                      onUpdateValue={item => {
                        setFiletypeValue(item?.value)
                        setFileTypeDropDown(false);
                      }}
                    />
                    <HelperText type="error" visible={Boolean(touched.Ticket_Status && errors.Ticket_Status)}>
                      <ErrorMessage name="Ticket_Status" />
                    </HelperText>
                  </View>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                  <View style={{ flexShrink: 1, width: 250, paddingTop: 20 }} >
                    {fileTypevalue ?
                      <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                      :
                      <FileUpload style={{ borderRadius: 6, borderWidth: 1, borderColor: '#D6D6D7' }} filestyle={styles.files} handleRemoveFile={handleRemoveFile} isImage={fileTypevalue} file={file} uploadFile={handleFile} />
                    }
                    <HelperText type="error" visible={!fileUrl && touched.Attachment && errors.Attachment}>
                      {!fileUrl && touched.Attachment && errors.Attachment}
                    </HelperText>
                  </View>
                </View>
                <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", }}>
                  <Button disabled={isSubmited || uploadFile} style={{ marginTop: "5%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                   <Text style={{color: esiColor.itemButtenColor}}>Save</Text> 
                  </Button>
                </View>

              </Card.Content>
            </Card>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};


export default Addtickets
