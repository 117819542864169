import {
  SETTING_REQUEST,
  SETTING_SUCCESS,
  SETTING_FAIL,

  SETTING_GET_REQUEST,
  SETTING_GET_SUCCESS,
  SETTING_GET_FAIL,

  SETTING_CREATE_REQUEST,
  SETTING_CREATE_SUCCESS,
  SETTING_CREATE_FAIL,

  SETTING_UPDATE_REQUEST,
  SETTING_UPDATE_SUCCESS,
  SETTING_UPDATE_FAIL,

  SETTING_DELETE_REQUEST,
  SETTING_DELETE_SUCCESS,
  SETTING_DELETE_FAIL,

  SETTING_FIELDNAME_REQUEST,
  SETTING_FIELDNAME_SUCCESS,
  SETTING_FIELDNAME_FAIL,

  SETTING_FILTER,

} from '../actions/settingsAction'

const initialState = {

  settings: { all: [], error: '', isLoading: false },
  setting: { setting: {}, error: '', isLoading: false },

  settingCreate: { setting: {}, error: '', isLoading: false },
  settingUpdate: { setting: {}, error: '', isLoading: false },
  settingDelete: { setting: {}, error: '', isLoading: false },

  settingFieldname: { fieldname: [], error: '', isLoading: false },

  settingFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

  switch (action.type) {
    case SETTING_REQUEST:
      return { ...state, settings: { all: state.settings.all, error: '', isLoading: true } };
    case SETTING_SUCCESS:
      return { ...state, settings: { all: action.payload, error: '', isLoading: false } };
    case SETTING_FAIL:
      return { ...state, settings: { all: [], error: action.payload, isLoading: false } };

    case SETTING_GET_REQUEST:
      return { ...state, setting: { setting: {}, error: '', isLoading: true } };
    case SETTING_GET_SUCCESS:
      return { ...state, setting: { setting: action.payload, error: '', isLoading: false } };
    case SETTING_GET_FAIL:
      return { ...state, setting: { setting: {}, error: action.payload, isLoading: false } };

    case SETTING_CREATE_REQUEST:
      return { ...state, settingCreate: { setting: {}, error: '', isLoading: true } };
    case SETTING_CREATE_SUCCESS:
      return { ...state, settingCreate: { setting: state.settingCreate.setting, error: '', isLoading: false } };
    case SETTING_CREATE_FAIL:
      return { ...state, settingCreate: { setting: {}, error: action.payload, isLoading: false } };

    case SETTING_UPDATE_REQUEST:
      return { ...state, settingUpdate: { setting: {}, error: '', isLoading: true } };
    case SETTING_UPDATE_SUCCESS:
      return { ...state, settingUpdate: { setting: state.settingUpdate.setting, error: '', isLoading: false } };
    case SETTING_UPDATE_FAIL:
      return { ...state, settingUpdate: { setting: {}, error: action.payload, isLoading: false } };

    case SETTING_DELETE_REQUEST:
      return { ...state, settingDelete: { setting: {}, error: '', isLoading: true } };
    case SETTING_DELETE_SUCCESS:
      return { ...state, settingDelete: { setting: action.payload, error: '', isLoading: false } };
    case SETTING_DELETE_FAIL:
      return { ...state, settingDelete: { setting: {}, error: action.payload, isLoading: false } };

    case SETTING_FIELDNAME_REQUEST:
      return { ...state, settingFieldname: { fieldname: state.settingFieldname.fieldname, error: '', isLoading: true }, };
    case SETTING_FIELDNAME_SUCCESS:
      return { ...state, settingFieldname: { fieldname: action.payload, error: '', isLoading: false } };
    case SETTING_FIELDNAME_FAIL:
      return { ...state, settingFieldname: { fieldname: [], error: action.payload, isLoading: false } };

    case SETTING_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, settingFilter: { search: state.settingFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, settingFilter: { select: state.settingFilter.select, search: action.value } };
      }
      return res;

    default:
      return state;
  }
}