import React, { useState } from "react";
import { Text, TextInput, HelperText, IconButton,DefaultTheme } from 'react-native-paper';
import { View, StyleSheet, ScrollView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Formik } from "formik";
import * as Yup from 'yup';
import isEmpty from "../../state/validations/is-empty";
import { AddBiddingServiceQuotecommentAction, GetAllBiddingServiceQuoteComments } from "../../state/actions/biddingServiceQuoteCommentsAction";

export default function ESIChatModelForServices(props: any) {
    // data is a deal object as we use this object to display the info front-end
    // isAuthenticated prop is used here to call authentication
    const { data } = props
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth?.user);
    
  const esiColor = useSelector(state => state.theme);
    const [reload, setReload] = useState(false);
    const [Comment, setComment] = useState([]);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 20, SortBy: "Bidding_Service_Comment_Id", SortOrder: "ASC" });
    const callBackGetData = (data) => {
        setReload(false);
        if (data) {
            setComment(data);
        }

    };
    const Refreshing = () => {
        let formdata = {
            search_by_filter: "",
            search: "",
            Records_Filter: 'FILTER',
            Bidding_Service_Comment_Id: "",
            Partner_Bidding_Service_Quote_Id: data.Partner_Bidding_Service_Quote_Id
        }
        dispatch(GetAllBiddingServiceQuoteComments(formdata, callBackGetData, pagination))

    };
    React.useEffect(() => {
        if (data) {
            let formdata = {
                search_by_filter: "",
                search: "",
                Records_Filter: 'FILTER',
                Bidding_Service_Comment_Id: "",
                Partner_Bidding_Service_Quote_Id: data.Partner_Bidding_Service_Quote_Id
            }
            dispatch(GetAllBiddingServiceQuoteComments(formdata, callBackGetData, pagination))

        }
    }, [data]);
    const customTheme = {
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          text: esiColor.Text,
          placeholder: esiColor.TextPlaceholder
        },
      };
      const styles = StyleSheet.create({
        container: {
            width: "100%",
            position: 'absolute', bottom: 0
        },
        leftContainer: {
            flex: 1,
            flexDirection: 'row',
            alignSelf: 'flex-start',
            backgroundColor: esiColor.CBColor, 
            borderColor: esiColor.SBorderColor,
            // width: 180,
            borderRadius: 10,
            padding: 10,
        },
        rightContainer: {
            flex: 1,
            flexDirection: 'row',
            alignSelf: 'flex-end',
            alignItems: 'center',
            backgroundColor: esiColor.CBColor, 
            borderColor: esiColor.SBorderColor,
            // width: 180,
            padding: 10,
            borderRadius: 10
        },
        timestamp: {
            alignSelf:'flex-end',
            bottom:0,
            marginLeft: 8,
            fontSize: 8,
            color: esiColor.itemColor
        }
    });
    return (

        <View style={{ height: 360 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'flex-end' }}>
                {/* <View style={{}}> */}
                <IconButton icon="refresh" size={20} color={esiColor.GBFColor} onPress={() => { Refreshing() }} />
                {/* refresh */}
            </View>


            <View style={{ paddingTop: 10 }}>
                {isEmpty(Comment) ? <View>
                    <Text style={{ fontSize: 16, textAlign: 'center' , color: esiColor.NoDataFound }}>
                        No comments found.
                    </Text>
                </View> :

                    <View style={{ height: 240 }}>
                        <ScrollView showsVerticalScrollIndicator={false} nestedScrollEnabled={true}>
                            {Comment?.length > 0 && Comment?.map((data: any, index: any) => (<View>
                                <View key={index} style={data.Commented_By == 'PARTNER' ? styles.rightContainer : styles.leftContainer} >
                                    <Text style={{ fontSize: 12, marginLeft: 16, padding: 2,color:esiColor?.text }}>{data.Comments}</Text>
                                    <Text style={styles.timestamp}>{data.Time} ago</Text>
                                </View>
                                <View style={{ paddingBottom: 5 }} />
                            </View>
                            ))}
                        </ScrollView>
                    </View>
                }

            </View>

            <Formik initialValues={{
                Comments: "",
                Created_By: user.Email_Id,
                Partner_Bidding_Service_Quote_Id: data.Partner_Bidding_Service_Quote_Id,
            }}

                //field validations
                validationSchema={Yup.object().shape({
                    Comments: Yup.string().required('Comment  is required.'),
                })}
                onSubmit={(values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
                    let submitTest = true;
                    setSubmitting(false);
                    const errors = {};
                    setErrors(errors);
                    if (values) {
                        let formValues = JSON.parse(JSON.stringify(values));
                        formValues.Created_By = user.Email_Id
                        formValues.Partner_Bidding_Service_Quote_Id = data.Partner_Bidding_Service_Quote_Id
                        formValues.Commented_By = 'PARTNER',
                            dispatch(AddBiddingServiceQuotecommentAction(formValues, data.Partner_Bidding_Service_Quote_Id, resetForm, setReload, callBackGetData));
                    }
                }
                }
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    resetForm
                }) => (
                    <View style={styles.container}>
                        <TextInput
                            theme={customTheme}
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                          placeholder="Send Message"
                            onChangeText={handleChange('Comments')}
                            value={values.Comments}
                            error={Boolean(touched.Comments && errors.Comments)}
                            right={<TextInput.Icon name="send" color="#27B6CC" mode="contained" onPress={handleSubmit} />}
                            mode='outlined'
                            outlineColor="#27B6CC"
                            activeOutlineColor="#27B6CC"
                        />
                        <HelperText type="error" visible={Boolean(touched.Comments && errors.Comments)}>
                            <ErrorMessage name="Comments" />
                        </HelperText>
                    </View>
                )}
            </Formik>
        </View >

    )
}



