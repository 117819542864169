import React from "react";
import { View, Text, ScrollView, Platform, RefreshControl } from "react-native";
import { Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Header from "../header/header";
import TotalBookings from "./dashboardView/count/totalBookings";
import TotalProperties from "./dashboardView/count/totalProperties";
import TotalActiveBookings from "./dashboardView/count/ActiveBookings";
export default function PropertiesDashboard(props: any) {
    const { navigation } = props;
  //Clean up
        React.useEffect(() => {
            return () => {
            };
          }, []);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    return (
        <Surface style={{ flex: 1 }}>
        <Header navigation={navigation} />
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10 }}>
                Dashboard
            </Text>
        </View>
        <ScrollView refreshControl={
            <RefreshControl
                refreshing={refreshing}
                onRefresh={onRefresh}
                colors={['#27B6CC']}
                tintColor={'#27B6CC'}
                title={"Pull to refresh"}
                titleColor={"#27B6CC"}
            />
        }>
            <View style={{ flexDirection: Platform.OS === "web" ? 'row' : 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flex: 1 }} >

                <View style={{ flexDirection: 'row' }}>
                    <TotalBookings navigate={navigation.navigate} />
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <TotalActiveBookings navigate={navigation.navigate} />
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <TotalProperties navigate={navigation.navigate} />
                </View>
            </View>
        </ScrollView>
    </Surface>
    );
};