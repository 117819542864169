import React, { useEffect, useState } from "react";
import { Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import TrackingMap from "../../components/custom/map/TrackingMap/TrackingMap";
import { useToast } from "../../components/custom/react-native-paper-toast/src";
import { getOrderTrackingDetails } from "../../state/actions/ordersAction";
import isEmpty from "../../state/validations/is-empty";
import Header from '../auth/components/header';

export default function DeliveryTracking(props: any) {
    const dispatch = useDispatch();
    const toast = useToast();
    const EmailID = useSelector(state => state.auth?.user);
    const [loading, setLoading] = useState(false);
    const returnOrderTrackingDetails = (status, data) => {
        setLoading(false);
        if (status) {
            let delTrack = data?.Delivery_Live_Location[0];
            let userTrack = { Latitude: data?.Shipping_Details[0]?.Latitude, Longitude:data?.Shipping_Details[0]?.Longitude};
            setDeliveryTracking({ latitude: Number(delTrack?.Latitude), longitude: Number(delTrack?.Longitude) });
            setUserTracking({ latitude: Number(userTrack.Latitude), longitude: Number(userTrack.Longitude) });
        }else{
            toast.show({ message: 'tracking details Not found!.', type: 'info', duration: 3000, position: 'top' });
      
        }
    }
    const [deliveryTracking, setDeliveryTracking] = useState({});
    const [userTracking, setUserTracking] = useState({});
    useEffect(() => {
        let data = props?.route?.params;
        if (data?.ID && !isEmpty(data?.ID) && !isEmpty(EmailID) && !loading) {
            setLoading(true);
            dispatch(getOrderTrackingDetails({
                "Shopper_Order_Line_Item_Id":data?.ID
            }, returnOrderTrackingDetails));
        } else {
            // props.navigation.navigate("Home");
        }
    }, [props?.route?.params])
    return (
        <Surface style={{ flex: 1 }} >
            <Header {...props} />
            {(!isEmpty(userTracking) && !isEmpty(deliveryTracking)) &&
                <TrackingMap origin={deliveryTracking} destination={userTracking} navigation={props.navigation} />}
        </Surface>
    )
}
