import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivityIndicator, Surface, Text } from 'react-native-paper';
import { Image, View, StyleSheet, ScrollView, Platform, TouchableOpacity, Dimensions } from "react-native";
import PartnerCart from "./PartnerCart";
import { getCart, paceOrderValidation, productRemoveAddressError, removePartnerInCart } from "../../../state/actions/shoppers/shoppercartActions";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../header/header";
import { GetAllAddressAction, getAllCartAddressAction } from "../../../state/actions/shoppers/addressUserActions";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Cart(props: any) {
  const dispatch = useDispatch();
  const { navigation, ...rest } = props;
  const user = useSelector((state) => state.auth.user);
  const esiColor = useSelector(state => state.theme);

  let cart = useSelector((state) => state.cart.cart);
  let allCart = useSelector((state) => state.cart.allCart);
  const [address, setAddress] = React.useState([]);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [wallet, setWallet] = React.useState(null);
  useEffect(() => {
    dispatch(getCart({ Buyer_Partner_Details_ID: user.Partner_Details_Id }))
  }, []);
  const addresslist = (address: any) => {
    let addresslist = []
    if (address) {
      if (address[0]) {
        for (let i = 0; i < address.length; i++) {
          addresslist.push({
            label: address[i].Address_Type + " " + "," + " " + address[i].Door_No + " " + "," + " " + address[i].Land_Mark + " " + "," + " " + address[i].Street + " " + "," + " " + address[i].City + " " + "," + " " + address[i].State + " " + "," + " " + address[i].Zip,
            value:
            //  JSON.stringify(
            //   {
                // "Partner_Address_Id": 
                address[i].Partner_Address_Id,
            //     "Email_Id": address[i].Email_Id,
            //     "Door_No": address[i].Door_No,
            //     "Street": address[i].Street,
            //     "City": address[i].City,
            //     "State": address[i].State,
            //     "Country": address[i].Country,
            //     "Zip": address[i].Zip,
            //     "Land_Mark": address[i].Land_Mark,
            //     "Is_Default": address[i].Is_Default,
            //     "Created_Date": address[i].Created_Date,
            //     "Modified_Date": address[i].Modified_Date,
            //     "Is_Active": address[i].Is_Active,
            //     "Partner_Details_Id": address[i].Partner_Details_Id,
            //     "Address_Type": address[i].Address_Type,
            //     "Created_By": address[i].Created_By,
            //     "Modified_By": address[i].Modified_By,
            //     "Is_Shipping_Available": address[i].Is_Shipping_Available,
            //     "Is_Pickup_Available": address[i].Is_Pickup_Available,
            //     "Location": address[i].Location,
            //     "Mobile": address[i].Mobile_Number,
            //     "Landline": address[i].Landline,
            //     "Latitude": address[i].Latitude,
            //     "Longitude": address[i].Longitude,
            //     "Name": address[i].Name,
            //   }
            // )
          })
        }
      }
    }
    addresslist.push({
      label: "Add New Address",
      value: "Add New Address"
    })
    return addresslist
  }
    const updateStates = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data ? data : []));
    setAddress(addresslist(add));
  };
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const refreshCall = () => {
    dispatch(getCart({ Buyer_Partner_Details_ID: user.Partner_Details_Id }))
  };
  const updateStatesWallet = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data));
    setWallet(add?.Wallet[0]);
  };
  // setAddress
  const getAddress = () => {
    let data = {
      Records_Filter: "FILTER",
      Partner_Details_Id: user.Partner_Details_Id,
      Email_Id: user.Email_Id,
      Partner_Address_Id: user.Partner_Address_Id
    };
    dispatch(getAllCartAddressAction(updateStates, data));
  }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isLoading = useSelector(state => state.auth.isLoading);
  const addresses = useSelector(state => state.address.addresses.all);
  useEffect(() => {
    updateStates(addresses, false);
  }, [addresses]);
  const handileNavigation = () => {
    getAddress();
    let formData = {
      Records_Filter: "FILTER",
      Partner_Details_Id: user.Partner_Details_Id,
      Email_Id: user.Email_Id,
      Partner_Address_Id: user.Partner_Address_Id
    };
    dispatch(GetAllAddressAction(formData))
    dispatch(productRemoveAddressError(cart));
  };
  useEffect(() => {
    handileNavigation();
  }, [isAuthenticated]);

  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });

  const [orderPlaced, setOrderPlaced] = useState(false);
  const placeNewOrderResponse = (status, response, item) => {
    setOrderPlaced(false);
    if (response[0]?.Response_Status === "Success") {
      dispatch(removePartnerInCart(cart, item));
    }
  };
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [callCount, setCallCount] = React.useState([]);
  const addressCheckResponse = (noError) => {
    if (noError) {
      navigation.navigate('CartSummary', { cart: 'all' });
    }
  };
  const [productError, setProductError] = useState([]);
  const handleSubmit = () => {
    dispatch(paceOrderValidation(cart, [], addressCheckResponse, productError, user, "all"));
  };
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window);
      }
    );
  });
  return (
    <Surface theme={{colors:{surface: esiColor.BackgroundColor}}} style={{ flex: 1, borderColor : esiColor.SBorderColor, borderWidth : 0.2 }} >
      <Header navigation={navigation} />
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ fontWeight: 'bold', fontSize: 35, color: esiColor.brandFontColor }}>Cart</Text>
      </View>
      <ScrollView 
        refreshing={refreshing}
        onRefresh={onRefresh}
        // colors={['#27B6CC']}
        // tintColor={'#27B6CC'}
        title={"Pull to refresh"}
        titleColor={"#27B6CC"}
        >
        <View style={{ width: dimensions.width >= 700 ? "80%" : "100%", marginLeft: "auto", marginRight: "auto", backgroundColor: esiColor.BackgroundColor }}>
          {orderPlaced ? <ActivityIndicator color='#27b6cc' /> :
            (!isEmpty(cart)) ? (<>
              {cart.map((item: any, index: any) => (
                <PartnerCart key={item?.partnerId} pindex={index} cart={cart} item={item} address={address} setOpenAddressPopup={setOpenAddressPopup}
                  expanded={expanded}
                  handleChange={handleChange}
                  wallet={wallet}
                  navigation={navigation}
                />
              ))}
              {allCart[1]?.Out_Of_Stock === '1' ? null : <View>
                {(!isEmpty(cart) && cart.length > 1) &&
                  <View style={{ flexDirection: "row", justifyContent: 'center', backgroundColor: esiColor.BackgroundColor }}>
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        backgroundColor: esiColor.globalButtonColor,
                        padding: 10,
                        margin: 'auto',
                        borderRadius: 8
                      }}
                      onPress={() => { handleSubmit(); }}
                    >
                      <Text style={{ color: esiColor.itemButtenColor }} >Place All Orders</Text>
                    </TouchableOpacity>
                  </View>}</View>}</>
            ) : (
              <View >
                <View style={{ justifyContent: 'center', alignItems: 'center', }}>
                  <Image style={{ width: 130, height: 130 }} source={{ uri: "https://i.imgur.com/dCdflKN.png" }} />
                  <Text style={{ fontWeight: 'bold', fontSize: 30, margin: 5, color: esiColor.NoDataFound }}> Your Cart is Empty.</Text>
                  <Text style={{ fontSize: 15, fontWeight: 'bold', margin: 5, color: esiColor.DescColor }}>Add products to make an order.</Text>
                  <TouchableOpacity onPress={() => navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } })} style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 10, padding: 10, margin: 5 }}><Text style={{ fontWeight: 'bold', color: esiColor.itemButtenColor }}>Shop Now</Text></TouchableOpacity>
                </View>
              </View>
            )
          }

        </View>
      </ScrollView>
    </Surface>
  );
}
const styles = StyleSheet.create({
  cardCircle: {
    width: (Platform.OS === 'web') ? "75%" : "98%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2%",
    marginBottom: "2%",
    borderRadius: 6,
    borderWidth: 0.01,
    borderColor: '#D6D6D7'
  },
  itemContainer: {
    justifyContent: 'flex-end',
    flex: 1
  },
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    marginBottom: 30,
  },

  containerone: {
    backgroundColor: 'white',
    paddingTop: 15
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    color: 'black',
    left: 22,
    top: 10,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  dropdown: {
    height: 58,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  datePickerStyle: {
    width: 358,
    height: 60,
    marginTop: 6,
  },
});