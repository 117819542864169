import axios from 'axios';
import config from '../config';

export const EVENT_REQUEST = "EVENT_REQUEST";
export const EVENT_SUCCESS = "EVENT_SUCCESS";
export const EVENT_FAIL = "EVENT_FAIL";

export const SINGLE_EVENT_REQUEST = "SINGLE_EVENT_REQUEST";
export const SINGLE_EVENT_FAIL = "SINGLE_EVENT_FAIL";

export const EVENT_UPDATE_REQUEST = "EVENT_UPDATE_REQUEST";
export const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS";
export const EVENT_UPDATE_FAIL = "EVENT_UPDATE_FAIL";
export const EVENT_DATA ="EVENT_DATA";
export const EVENT_FILTER = "EVENT_FILTER";

/*=============================================================
                  Get All EVENT Action
===============================================================*/

const headers = config.headersCommon;

export const getEventsAction = (formData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: EVENT_REQUEST
        });
        let { data } = await axios.post(
            `${config.url}/User_Events/Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: EVENT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: EVENT_SUCCESS,
                    payload: data?.User_Event
                });
            }
        }
    } catch (err) {
        dispatch({
            type: EVENT_FAIL,
            payload: err
        });
    }
};

/*=============================================================
                  Address Update Action
===============================================================*/

export const updateEventAction = (formData: any, navigation: any,partnerid:any) => async (dispatch: any) => {
   dispatch({
        type: EVENT_UPDATE_REQUEST
    });
    axios.put(`${config.url}/User_Events/Update`, formData,
        {
            headers: headers
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: EVENT_UPDATE_SUCCESS
                });
                const formValue = {
                    "Partner_Details_Id": partnerid.partnerid,
                    "Records_Filter": "ALL"
                };
                dispatch(getEventsAction(formValue))
                navigation.navigate('GetEvents')
            } else {
                const errors = {};
                dispatch({
                    type: EVENT_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: EVENT_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

/*=============================================================
                  Address Delete
===============================================================*/


// Filter 
export const EventFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: EVENT_FILTER,
            fType: fType,
            value: value,
        });
    }
}

export const resetFilter = () => {
    return function (dispatch: any) {
        dispatch({
            type: EVENT_FILTER,
            fType: "search",
            value: "",
        });
    }
}

export const getEventByIdAction = (formData: any, callBackData: any) => async dispatch => {
    try {
        dispatch({
            type: SINGLE_EVENT_REQUEST
        });
        let { data, status } = await axios.post(
            `${config.url}/User_Events/Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            callBackData(data.User_Event[0])
        }
    } catch (err) {
        dispatch({
            type: SINGLE_EVENT_FAIL,
            payload: err
        });
    }
};
export const eventsdata = (value:any) => {
    return function (dispatch) {
      dispatch({
        type: EVENT_DATA,
        value: value,
      });
    }
  }