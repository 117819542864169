import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView, RefreshControl } from 'react-native';
import Header from '../../../Services/header/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getPartnerserviceByIdAction, partnerserviceData } from '../../../../state/actions/Services/partnerservicesActions';
import EsiCountry from '../../../../components/custom/country-state-city/country';
import EsiState from '../../../../components/custom/country-state-city/state';
import EsiCity from '../../../../components/custom/country-state-city/city';
import { getLocationsServicesAction } from '../../../../state/actions/partnershopbylocationAction';


const EditPartnerservice = (props: any) => {
    //navigation is used for navigation between screens
    const { navigation } = props;
    const [statusDropDown, setStatusDropDown] = useState("");
    const ID = props?.route?.params?.id;
    const esiColor = useSelector(state => state.theme);
    const partnerlocation = useSelector(state => state.shopbycitylocation.services.services);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    const EmailID = useSelector(state => state.auth?.user);
    const [data, setData] = useState([]);
    const [servicedata, setServiceData] = useState([]);
    const maxhourprice = Number(partnerlocation?.Per_Hour_Rate)
    const maxdayprice = Number(partnerlocation?.Per_Daye_Rate)
    const setType = (cityid) => {
        if (cityid !== "") {
            let formData =
            {
                "City_Id": cityid,
                "Service_Type": "",
                "Partner_Services_Generic_Id": "",
                "Records_Filter": "FILTER",
                "Service_Name": EmailID.Business_Sub_Type
            };
            dispatch(getLocationsServicesAction(formData));
        }
    }
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const callBackData = (data: any) => {
        setData(data)
    }

    const dispatch = useDispatch();
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
        // return () => subscription?.remove();
    });
    useEffect(() => {
        if (data[0]?.City_Id) {
            let formData =
            {
                "City_Id": data[0]?.City_Id,
                "Service_Type": "",
                "Partner_Services_Generic_Id": "",
                "Records_Filter": "FILTER",
                "Service_Name": EmailID.Business_Sub_Type
            };
            dispatch(getLocationsServicesAction(formData));

        }
    }, [data]);

    useEffect(() => {
        if (ID !== servicedata) {
            let formData = {
                "search_by_filter": "",
                "search": "",
                "Partner_Detail_Id": "",
                "Partner_Service_Id": ID,
                "Records_Filter": "FILTER"
            };
            setServiceData(ID)
            dispatch(getPartnerserviceByIdAction(formData, callBackData));

        }
    }, [ID, servicedata]);

    const [countryField, setCountryField] = React.useState();
    const [stateField, setStateField] = React.useState();

    /*====================================
            Handler
    ====================================*/
    const values = {
        Partner_Service_Id: data[0]?.Partner_Service_Id,
        City: data[0]?.City_Id ? data[0]?.City_Id : "",
        Country: data[0]?.Country ? data[0].Country : "",
        Expected_Service_Fee: data[0]?.Expected_Service_Fee ? data[0].Expected_Service_Fee : "",
        Location: data[0]?.Location ? data[0].Location : "",
        Resource_Email_Id: data[0]?.Resource_Email_Id ? data[0].Resource_Email_Id : "",
        Resource_Mobile: data[0]?.Resource_Mobile ? data[0].Resource_Mobile : "",
        Resource_Name: data[0]?.Resource_Name ? data[0].Resource_Name : "",
        Sample_Service_Image_Url: data[0]?.Sample_Service_Image_Url ? data[0].Sample_Service_Image_Url : "",
        Modified_By: EmailID.Email_Id,
        Sample_Service_Video_Url: data[0]?.Sample_Service_Video_Url ? data[0].Sample_Service_Video_Url : "",
        Service_Description: data[0]?.Service_Description ? data[0].Service_Description : "",
        Service_Name: data[0]?.Service_Name ? data[0].Service_Name : "",
        Service_Price_Day: data[0]?.Service_Price_Day ? data[0].Service_Price_Day : "" + '',
        Price_Per_Hour: data[0]?.Service_Price_Per_Hour ? data[0].Service_Price_Per_Hour : "" + '',
        Service_Type: data[0]?.Service_Type ? data[0].Service_Type : "",
        State: data[0]?.State ? data[0].State : "",
        Status: data[0]?.Status ? data[0].Status : "",
        Zip: data[0]?.Zip ? data[0].Zip : "",
        Service_Price_Per_Hour: data[0]?.Service_Price_Per_Hour,
        Discount_Amount: data[0]?.Zip ? data[0].Discount_Amount : "",
        Is_Avaliable_For_Services: data[0]?.Is_Avaliable_For_Services > 0 ? true : false,
        Is_Custome_Service_Fee: data[0]?.Is_Custome_Service_Fee > 0 ? true : false,
        Is_Home_Service_Avaliable: data[0]?.Is_Home_Service_Avaliable > 0 ? true : false,
        Partner_Detail_Id: EmailID.Partner_Details_Id,
    }
    //Form validation
    const Form_Validation = Yup.object().shape({
        Expected_Service_Fee: Yup.number().required(' Expected Service Fee is required.'),
        Service_Price_Day: Yup.number().positive().max(maxdayprice, 'Require Service Price Per Day less than to ' + maxdayprice + '.').required('Service Price Per Day is required.'),
        Service_Price_Per_Hour: Yup.number().positive().max(maxhourprice, 'Require Service Price Per Hour less than to ' + maxhourprice + '.').required('Service Price Per Hour is required.'),
        State: Yup.string()
            .required('State is required.'),
        City: Yup.string()
            .required('City is required.'),
        Country: Yup.string()
            .required('Country is required.'),
        Zip: Yup.string()
            .required('Zip is required.'),
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            //passing the formvalues to next page through dispatch
            dispatch(partnerserviceData(formValues));
            //navigation to next page after validating the form
            navigation.navigate('EditPartnerservicepagetwo', { id: ID })
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        container: {
            paddingTop: 15
        },
        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            {data.length > 0 &&
                <ScrollView refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                } >
                    <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                        {(props) => (
                            <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => { props.resetForm(); navigation.navigate('Partnerservice') }}>
                                        Go Back
                                    </Button>
                                </View>
                                <Card style={{
                                    width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                    marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                    backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                                }}>
                                    <Card.Content>
                                        <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                            Edit Partner Service
                                        </Text>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                            , marginTop: 10
                                        }}>
                                            <View style={{ flexGrow: 1, width: 230, flexShrink: 1, paddingTop: 10 }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(props.touched.Country && props.errors.Country) && { color: '#b00020' }]}>
                                                        Country
                                                    </Text>
                                                    <EsiCountry
                                                        error={Boolean(props.touched.Country && props.errors.Country)}
                                                        labelField="NAME"
                                                        valueField="COUNTRY_CODE"
                                                        value={values.Country}
                                                        name="Country"
                                                        search
                                                        searchPlaceholder={'Select Country'}
                                                        onUpdateValue={item => {
                                                            props.setFieldValue('Country', item.COUNTRY_CODE);
                                                            if (countryField != item.NAME)
                                                                setCountryField(item.COUNTRY_CODE);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(props.touched.Country && props.errors.Country)}>
                                                        <ErrorMessage name="Country" />
                                                    </HelperText>
                                                </View>
                                            </View>

                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginLeft: '1%' }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(props.touched.State && props.errors.State) && { color: '#b00020' }]}>
                                                        State
                                                    </Text>
                                                    <EsiState
                                                        error={Boolean(props.touched.State && props.errors.State)}
                                                        labelField="NAME"
                                                        valueField="STATE_CODE"
                                                        value={values.State}
                                                        name="State"
                                                        search
                                                        searchPlaceholder={'Select State'}
                                                        CountryCode={countryField}
                                                        onUpdateValue={item => {
                                                            props.setFieldValue('State', item.STATE_CODE);
                                                            if (stateField != item.NAME)
                                                                setStateField(item.STATE_CODE);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(props.touched.State && props.errors.State)}>
                                                        <ErrorMessage name="State" />
                                                    </HelperText>
                                                </View>
                                            </View>

                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap",
                                            marginTop: '1%'
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '0.5%' }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(props.touched.City && props.errors.City) && { color: '#b00020' }]}>
                                                        City
                                                    </Text>
                                                    <EsiCity
                                                        error={Boolean(props.touched.City && props.errors.City)}
                                                        labelField="NAME"
                                                        valueField="CITY_ID"
                                                        CountryCode={countryField}
                                                        StateCode={stateField || data[0]?.State}
                                                        value={values.City}
                                                        name="City"
                                                        search
                                                        searchPlaceholder={'Select City'}
                                                        onUpdateValue={item => {
                                                            item &&
                                                                setType(item?.CITY_ID);
                                                            props.setFieldValue('City', item.CITY_ID);

                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(props.touched.City && props.errors.City)}>
                                                        <ErrorMessage name="City" />
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                                                <TextInput

                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(props.touched.Expected_Service_Fee && props.errors.Expected_Service_Fee)}
                                                    label="Expected Service Fee"
                                                    mode='outlined'
                                                    value={props.values.Expected_Service_Fee}
                                                    onChangeText={props.handleChange("Expected_Service_Fee")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Expected_Service_Fee && props.errors.Expected_Service_Fee)}>
                                                    {props.touched.Expected_Service_Fee && props.errors.Expected_Service_Fee}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput

                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(props.touched.Service_Price_Day && props.errors.Service_Price_Day)}
                                                    label="Service Price Day"
                                                    mode='outlined'
                                                    multiline
                                                    value={props.values.Service_Price_Day}
                                                    onChangeText={props.handleChange("Service_Price_Day")}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Service_Price_Day && props.errors.Service_Price_Day)}>
                                                    {props.touched.Service_Price_Day && props.errors.Service_Price_Day}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput

                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(props.touched.Service_Price_Per_Hour && props.errors.Service_Price_Per_Hour)}
                                                    label="Service Price Per Hour"
                                                    mode='outlined'
                                                    multiline
                                                    value={props.values.Service_Price_Per_Hour}
                                                    onChangeText={props.handleChange("Service_Price_Per_Hour")}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Service_Price_Per_Hour && props.errors.Service_Price_Per_Hour)}>
                                                    {props.touched.Service_Price_Per_Hour && props.errors.Service_Price_Per_Hour}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput

                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(props.touched.Zip && props.errors.Zip)}
                                                    label="Zip"
                                                    mode='outlined'
                                                    value={props.values.Zip}
                                                    onChangeText={props.handleChange("Zip")}

                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Zip && props.errors.Zip)}>
                                                    {props.touched.Zip && props.errors.Zip}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                            <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" textColor={esiColor.itemButtenColor} onPress={props.handleSubmit}>
                                                Next
                                            </Button>
                                        </View>
                                    </Card.Content>
                                </Card>
                            </View>
                        )}
                    </Formik>
                </ScrollView>
            }
        </Surface>
    );
};

export default EditPartnerservice;
