import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';
export const DELIVERY_AGENT_REQUEST = "DELIVERY_AGENT_REQUEST";
export const DELIVERY_AGENT_SUCCESS = "DELIVERY_AGENT_SUCCESS";
export const DELIVERY_AGENT_FAIL = "DELIVERY_AGENT_FAIL";


export const DELIVERY_AGENT_GET_REQUEST = "DELIVERY_AGENT_GET_REQUEST";
export const DELIVERY_AGENT_GET_SUCCESS = "DELIVERY_AGENT_GET_SUCCESS";
export const DELIVERY_AGENT_GET_FAIL = "DELIVERY_AGENT_GET_FAIL";

export const DELIVERY_AGENT_CREATE_REQUEST = "DELIVERY_AGENT_CREATE_REQUEST";
export const DELIVERY_AGENT_CREATE_SUCCESS = "DELIVERY_AGENT_CREATE_SUCCESS";
export const DELIVERY_AGENT_CREATE_FAIL = "DELIVERY_AGENT_CREATE_FAIL";

export const DELIVERY_AGENT_UPDATE_REQUEST = "DELIVERY_AGENT_UPDATE_REQUEST";
export const DELIVERY_AGENT_UPDATE_SUCCESS = "DELIVERY_AGENT_UPDATE_SUCCESS";
export const DELIVERY_AGENT_UPDATE_FAIL = "DELIVERY_AGENT_UPDATE_FAIL";

export const DELIVERY_AGENT_FILTER = "DELIVERY_AGENT_FILTER";
export const DELIVERY_AGENT_DATA = "DELIVERY_AGENT_DATA";
const nodeheaders = shopperconfig.nodeheadersCommon;
/*=============================================================
                  Get All DELIVERY_AGENT Action
===============================================================*/
const cudheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
const urlGenarator = (url: any, pagination: any) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
export const getAllDeliveryAgentAction = (formData: any, pagination = {},  responseFunction: any) => async dispatch => {
  try {
    dispatch({
      type: DELIVERY_AGENT_REQUEST
    });
    meeapi.post(urlGenarator(`/Delivery_Partner/Partner_Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        responseFunction(true, []);
        dispatch({
          type: DELIVERY_AGENT_SUCCESS,
          payload: data
        });
      } else {
        responseFunction(true, data.results, data.Pagination );
        dispatch({
          type: DELIVERY_AGENT_SUCCESS,
          payload: data
        });

      }
    })
  } catch (err) {
    responseFunction(false, err);
    dispatch({
      type: DELIVERY_AGENT_FAIL,
      payload: err
    });
  }
};
export const getdeliveryagentByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    dispatch({
      type: DELIVERY_AGENT_REQUEST
    });
    let { data, status } = await axios.post(
        `${shopperconfig.nodePartnerUrl}/Delivery_Partner/Partner_Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)
    }
  } catch (err) {
    dispatch({
      type: DELIVERY_AGENT_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                  DELIVERY_AGENT Create Action
===============================================================*/

export const createDeliveryAgentAction = (formData: any, navigation: any,setErrorMessage:any,setErrors:any, toast: any) => async (dispatch: any) => {
    dispatch({
        type: DELIVERY_AGENT_CREATE_REQUEST
    });
    setErrorMessage({ Del_User_Email_ID: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
    axios.post(`${config.delurl}/Delivery_Partner/Partner_Create`,
        formData,
        {
            headers: headers
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: DELIVERY_AGENT_CREATE_SUCCESS
                });
                navigation.navigate('DeliveryAgent')
                toast.show({ message: 'Delivery Agent Created Successfully.', type: 'info', duration: 3000, position: 'top' });
                const data = {
                    Records_Filter: "FILTER",
                    Partner_ID: formData.Partner_Id
                };
                const pagination = {
                  PageNo: 1,
                  PageSize: 5,
                  SortBy: "DEL_USER_EMAIL_ID",
                  SortOrder: "ASC"
                }
                dispatch(getAllDeliveryAgentAction(data,pagination,''))
            } else {
                const errors = {};
                setErrors(errors)
                toast.show({ message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' , type: 'info', duration: 3000, position: 'top' });
                setErrorMessage({ Del_User_Email_ID: { key: successResponse.Del_User_Email_ID ? successResponse.UI_Display_Message : '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' }, Mobile: { key: successResponse.Mobile ? successResponse.UI_Display_Message : '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' },global: {key: successResponse.Mobile ? successResponse.UI_Display_Message : '', message: successResponse.UI_Display_Message ? successResponse.UI_Display_Message : '' }});
                dispatch({
                    type: DELIVERY_AGENT_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: DELIVERY_AGENT_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


/*=============================================================
                  DELIVERY_AGENT Update Action
===============================================================*/

export const updateDeliveryAgentAction = (formData: any, navigation: any, setErrorMessage: any, setErrors: any, setData: any, toast: any) => async dispatch => {
    dispatch({
      type: DELIVERY_AGENT_UPDATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Delivery_Partner/Partner_Update`, formData, {
      headers: cudheaders
    })
      .then(response => {
        let successResponse = response.data;
        if (successResponse.Response_Status == "Success") {
          dispatch({
            type: DELIVERY_AGENT_UPDATE_SUCCESS
          });
          setData([])
          const formValue = {
            Records_Filter: 'FILTER',
            Partner_ID: formData.Partner_Id
          };
          const pagination = {
            PageNo: 1,
            PageSize: 5,
            SortBy: "DEL_USER_EMAIL_ID",
            SortOrder: "ASC"
          }
          dispatch(getAllDeliveryAgentAction(formValue, toast,pagination));
          navigation.navigate('DeliveryAgent');
          toast.show({
            message: 'Delivery Agent updated successfully.', type: 'info', duration: 3000, position: 'top'
          });
        } else {
          dispatch({
            type: DELIVERY_AGENT_UPDATE_FAIL,
            payload: successResponse.UI_Display_Message
          });
        }
      })
      .catch(error => {
        dispatch({
          type: DELIVERY_AGENT_UPDATE_FAIL,
          payload: "Please try again later.Currently server is not working."
        });
      })
  };
/*=============================================================
                Delivery agent Image Upload API
===============================================================*/

export const uploadDeliveryAgentImage = (
    formData: any,
    callBackUploadImgae: any
) => async (dispatch: any) => {
    try {

        let { data } = await axios.post(
            `${config.delurl}/Delivery_Profile/Logo_Upload?filename=easystepin&file_extension=jpg&filetype=image`,
            formData,
            {
              headers:headers,
                transformRequest: (d) => d,
            }
        );
        if (data) {
            callBackUploadImgae(data);
        }
    } catch (err) {
    }
};

// Filter 
export const DeliveryAgentFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: DELIVERY_AGENT_FILTER,
            fType: fType,
            value: value,
        });
    }
}
//save cache data
export const DeliveryAgentData = (value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: DELIVERY_AGENT_DATA,
            value: value,
        });
    }
}