import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView } from 'react-native';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import { updateSBCPartnerCityAction, getIdSBCAction } from '../../../state/actions/sbcpartnerCityAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//StatusOption used to get the data from dropdown
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const EditSBCPartnercity = (props: any) => {
    const ID = props.route?.params?.ID;
    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const esiColor = useSelector(state => state.theme);
    //toast for popup after successful API calls.
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);

    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);


    React.useEffect(() => {
        if (!(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
        setSeconds(2);
    }, [permissions, ID]);
    //isSubmitted contains the data from Create API using state call
    const isSubmited = useSelector(state => state.sbcpartnercity.sbcpartnercityUpdate.isLoading);
    //Dropdowns
    const [statusDropDown, setStatusDropDown] = useState("");

    //dispatch is used to perform the API action
    const dispatch = useDispatch();

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const [data, setData] = useState([]);
    const callBackData = (data: any) => {
        setData(data)
    };

    React.useEffect(() => {
        if (ID) {
            let formData = {
                "search_by_filter": "",
                "search": "",
                "Partner_Service_City_Id": ID,
                "City_Id": "",
                "Partner_Details_Id": "",
                "Partner_Name": "",
                "Records_Filter": "FILTER"
            }
            dispatch(getIdSBCAction(formData, callBackData))
        }
    }, [ID]);


    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    /*====================================
            Handler
    ====================================*/

    const values = {
        Partner_Service_City_Id: data?.Partner_Service_City_Id,
        City_Id: data?.City_Id,
        City_Name: data?.City_Name,
        Partner_Details_Id: data?.Partner_Details_Id,
        Partner_Name: data?.Partner_Name,
        Status: data?.Status,
        Modified_By: EmailID?.Email_Id
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView>
                {data && seconds <= 0 &&
                    <Formik enableReinitialize={true} initialValues={values}
                        validationSchema={Yup.object().shape({
                            Status: Yup?.string().required('Status is required.')
                        })}
                        onSubmit={(values: any, { setSubmitting, setErrors }) => {
                            let submitTest = true;
                            setSubmitting(false);
                            const errors = {};
                            setErrors(errors);

                            if (submitTest) {
                                let formValues = JSON.parse(JSON.stringify(values));
                                dispatch(updateSBCPartnerCityAction(formValues, navigation, toast));
                            }
                        }}
                    >
                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any, setFieldValue: any }) => (
                            <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 146, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('SBCPartnercityGet')}>
                                        Go Back
                                    </Button>
                                </View>
                                <Card style={{
                                    width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                    marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                    backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                                }}>
                                    <Card.Content>
                                        <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                            Edit Partner City
                                        </Text>

                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 16 }}>
                                                {/* City Name field*/}
                                                <TextInput
                                                    //    theme={customTheme}
                                                    style={{
                                                        marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                        borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                    }}
                                                    label='City Name'
                                                    mode='outlined'
                                                    name="City_Name"
                                                    value={values.City_Name}
                                                    onChangeText={handleChange('City_Name')}
                                                    disabled
                                                />
                                            </View>
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                                {/* Status input field with validation*/}
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Status && errors.Status) && { color: '#b00020' }]}>
                                                    Status
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Status && errors.Status) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={StatusOption}
                                                    labelField="label"
                                                    valueField="value"
                                                    maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                    search={StatusOption.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Status'}
                                                    dropdownPosition="auto"
                                                    value={values.Status}
                                                    name="Status"
                                                    onFocus={() => setStatusDropDown(true)}
                                                    onBlur={() => setStatusDropDown(false)}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Status', item.value)
                                                        setStatusDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Status && errors.Status)}>
                                                    <ErrorMessage name="Status" />
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                            <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                                Save
                                            </Button>
                                        </View>

                                    </Card.Content>
                                </Card>
                            </View>
                        )}
                    </Formik>
                }
            </ScrollView>
        </Surface>
    );
};

export default EditSBCPartnercity;