import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ServicesNavBar from './Servicesindex';

const ServicesDashboardLayout = (props: any) => {
  const { navigation } = props;
  const [isMobileNavB2COpen, setMobileNavB2COpen] = useState(true);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
      <ServicesNavBar
        onMobileClose={() => setMobileNavB2COpen(true)}
        openMobile={isMobileNavB2COpen} navigation={navigation}
      />
  );
};
export default ServicesDashboardLayout;
