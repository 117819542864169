import React, { useEffect, useState } from "react";
import { Image, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View, Dimensions, ActivityIndicator, RefreshControl } from "react-native";
import { Card, IconButton, Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/custom/pagination/pagination";
import { getSubcategory } from "../../../state/actions/node-actions/home-actions";

import Header from "../header/header";
const SubCategory = (props) => {
  const { navigation } = props;
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  const ID = props?.route?.params?.category_id;
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [categoryId, setCategoryId] = React.useState("");
  const halfdimentions = dimensions / 2;
  const [subcategories, setSubcategories] = useState([]);
  const [filters, setFilters] = React.useState({
    "search_by_filter": "",
    "search": "",
    "Category_Id": ID,
    "Sub_Category_Id": "",
    "Category_Name": "",
    "Sub_Category_Name": "",
    "Records_Filter": "FILTER"
  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "STATUS", SortOrder: "DESC" });
  const callBackGetData = async (status, data, pagination) => {
    goToTop();

    setIsLoading(false);
    if (status) {
      setSubcategories(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 30, SortBy: "STATUS", SortOrder: "DESC" });
    }
  }

  const [subcategorydata, setSubcategoryData] = useState([]);
  React.useEffect(() => {
    if (ID !== subcategorydata) {
      let data = {
        "search_by_filter": "",
        "search": "",
        "Category_Id": ID,
        "Sub_Category_Id": "",
        "Category_Name": "",
        "Sub_Category_Name": "",
        "Records_Filter": "FILTER"
      }
      dispatch(getSubcategory(data,
        pagination,
        callBackGetData

      ));
      setSubcategoryData(ID)
    }
  }, [ID, subcategorydata])
  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      subcategoryCall();
    }
  }, [pagination])
  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {

    if (JSON.stringify(testFilters) != JSON.stringify(filters)) {
      setTestFilters(filters);
      subcategoryCall();
    }
  }, [filters, ID]);
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const subcategoryCall = () => {
    setIsLoading(true);
    dispatch(getSubcategory(filters,
      pagination,
      callBackGetData
    ));
  };

  //Returns a function to scroll the page up or down by any amount, in pixels. Supports scrolling.
  const goToTop = () => {
    try {
      scroll.scrollTo({ x: 0, y: 0, animated: true });
    } catch (error) {

    }
  }
  return (
    <Surface style={{ flex: 1, position: Platform.OS === 'web' ? 'absolute' : null, top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null, right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : null, backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} />
      <View style={{ flexDirection: "column", marginVertical: 5, justifyContent: 'center' }}>
        <Text style={{ fontSize: 22, fontWeight: "bold", alignSelf: 'center', color: esiColor.brandFontColor, margin: 2 }}>Sub Categories</Text>
      </View>
      <IconButton
        icon="arrow-left"
        size={25}
        color={"#27B6CC"}
        style={{ marginTop: (Platform.OS === 'web' ? -38 : -40), marginLeft: -6 }}
        onPress={() => navigation.navigate('Categoryb2b')}
      />
      <ScrollView ref={(c) => { scroll = c }}
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={subcategoryCall}
          />
        }
      >
        {loading ?
          <ActivityIndicator color='#27b6cc' animating={loading} style={{ marginTop: 20 }} />
          :
          <View style={styles.Card}>
            {subcategories.length <= 0 ?
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <Text style={{ color: esiColor.NoDataFound }}>No sub categories available to display</Text>
              </View>
              : subcategories?.map((item, index) => {
                return (

                  <Card style={{
                    margin: 10, height: 150, maxHeight: 150, maxWidth: 230, width: (halfdimentions - 20),
                    shadowColor: esiColor.brandShadowColor, borderRadius: 2, shadowRadius: 10, elevation: 10, opacity: 10, backgroundColor: esiColor.BackgroundColor

                  }} >
                    <TouchableOpacity onPress={() => props.navigation.navigate('Main', { screen: 'All', params: { id: item.Sub_Category_Id, cid: categoryId, type: 'sub-category', page: 'products', filter: 'all' } })}>
                      <View style={{ alignItems: 'flex-end', flex: 1, marginTop: Platform.OS === 'web' ? 5 : 110, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Image source={{ uri: item.Sub_Category_Image_Mobile }}
                          style={{
                            height: 100, width: "100%", resizeMode: 'contain',
                            shadowColor: "#CACFD2", borderRadius: 2, shadowRadius: 10, elevation: 10, opacity: 10

                          }}
                        />
                      </View>
                    </TouchableOpacity>
                    <View style={{ alignItems: "stretch", marginTop: 1, paddingLeft: 10 }}>
                      <Text style={{ textAlign: 'center', color: esiColor.itemColor, height: 36 }}>{item?.Category_Name}</Text>
                    </View>
                  </Card>

                )
              })}
          </View>}
        <View style={{ marginBottom: Platform.OS == "web" ? -10 : 5 }}>
          <Pagination pagination={pagination} setPagination={setPagination} />
        </View>

      </ScrollView>
    </Surface >
  );
}
export default SubCategory;
const styles = StyleSheet.create({
  Card: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
    position: 'relative',
    marginBottom: 70
  },
});
