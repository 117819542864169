import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';
import { getLogedInUser } from './authActions';

export const PHYSICALPARTNER_REQUEST = "PHYSICALPARTNER_REQUEST";
export const PHYSICALPARTNER_SUCCESS = "PHYSICALPARTNER_SUCCESS";
export const PHYSICALPARTNER_FAIL = "PHYSICALPARTNER_FAIL";
export const PHYSICALPARTNERS_REQUEST = "PHYSICALPARTNERS_REQUEST";
export const PHYSICALPARTNERS_SUCCESS = "PHYSICALPARTNERS_SUCCESS";
export const PHYSICALPARTNERS_FAIL = "PHYSICALPARTNERS_FAIL";
export const EMPLOYEES_UPDATE_REQUEST = "EMPLOYEES_UPDATE_REQUEST";
export const EMPLOYEES_UPDATE_SUCCESS = "EMPLOYEES_UPDATE_SUCCESS";
export const EMPLOYEES_UPDATE_FAIL = "EMPLOYEES_UPDATE_FAIL";
export const PHYSICALPARTNER_GET_REQUEST = "PHYSICALPARTNER_GET_REQUEST";
export const PHYSICALPARTNER_GET_SUCCESS = "PHYSICALPARTNER_GET_SUCCESS";
export const PHYSICALPARTNER_GET_FAIL = "PHYSICALPARTNER_GET_FAIL";
export const PHYSICALPARTNER_UPDATE_REQUEST = "PHYSICALPARTNER_UPDATE_REQUEST";
export const PHYSICALPARTNER_UPDATE_SUCCESS = "PHYSICALPARTNER_UPDATE_SUCCESS";
export const PHYSICALPARTNER_UPDATE_FAIL = "PHYSICALPARTNER_UPDATE_FAIL";
export const PROFILERESET = "PROFILERESET";

const nodeheaders = config.nodeheadersCommon;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

/*=============================================================
                  Add brand Action
===============================================================*/

const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;

export const getPartnerProfileAction = (emailId:any,sessionid: any, setData: any, ) => async dispatch => {
  const formData = {
    "search_by_filter": "",
    "search": "",
    "Email_Id": emailId,
    "Partner_Details_Id": "",
    "Partner_Employee_Id": "",
    "Records_Filter": "FILTER"
};
  try {
    dispatch({
      type: PHYSICALPARTNER_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Login/Employee_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PHYSICALPARTNER_SUCCESS,
          payload: []
        });
        dispatch(getLogedInUser(sessionid, false, () => { }, sessionid, () => { },()=>{}))
        setData([])
      } else {
        dispatch({
          type: PHYSICALPARTNER_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PHYSICALPARTNER_FAIL,
      payload: err
    });
  }
};
export const PartnerProfileAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: PHYSICALPARTNERS_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Profile/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );

    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PHYSICALPARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PHYSICALPARTNERS_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PHYSICALPARTNERS_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                  Transaction Update Action
  ===============================================================*/

export const updatePartnerProfileAction = (formData: any, EmailID: any, navigation: any, toast: any, ID: any) => async dispatch => {
  dispatch({
    type: PHYSICALPARTNER_UPDATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Partner_Profile/Update`, formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {


        dispatch({
          type: PHYSICALPARTNER_UPDATE_SUCCESS
        });
        // if (ID === '1' ) {
        //   navigation.navigate('Dashboard')
        // } else if(ID === '2'){
        //   navigation.navigate("Properties", { screen: 'PropertiesDashboard' }); 
        //  }else if(ID === '4'){
        //   navigation.navigate("Services", { screen: 'ServicesDashboard' }); 
        //  }else if(ID === '5'){
        //   navigation.navigate("Services", { screen: 'ServicesDashboard' }); 
        //  }else {
        //   navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } });
        //   }


        toast.show({ message: 'Partner profile updated successfully.', type: 'info', duration: 3000, position: 'top' });
        dispatch(PartnerProfileAction(formData.Email_Id));
      } else {
        const errors = {};
        dispatch({
          type: PHYSICALPARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })

    .catch(error => {
      dispatch({
        type: PHYSICALPARTNER_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

export const UpadateEmployeeAction = (formData: any, navigation: any, setErrorMessage: any, setErrors: any, setData: any, toast: any, ID: any, sessionid: any) => async dispatch => {
  dispatch({
    type: EMPLOYEES_UPDATE_REQUEST
  });
  axios.post(`${config.url}/Partner_Login/Employee_Update`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: EMPLOYEES_UPDATE_SUCCESS
        });
        dispatch(getLogedInUser(formData?.Partner_Employee_Email_Id, false, () => { }, sessionid, () => { },() => { }))
        dispatch(getPartnerProfileAction(formData.Partner_Employee_Email_Id, false, () => { }, sessionid, () => { }))
        setData([])
        // if (ID) {
        //   navigation.navigate('EmployeProfile', { ID: ID });
        // } else {
        //   navigation.navigate('B2B', { screen: 'EmployeProfile' })
        // }
        toast.show({
          message: 'Employee updated successfully.', type: 'info', duration: 3000, position: 'top'
        });

      } else {
        dispatch({
          type: EMPLOYEES_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: EMPLOYEES_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working."
      });
    })
};

/*=============================================================
                partner logo Image Upload Api
===============================================================*/

export const uploadPartnerlogoImage = (formData, callBackUploadImage, emailid) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.url}/Partner_Login/Partner_Profile_Pic_Image_Upload?filename=Sample&file_extension=jpg&filetype=image&emailid=${emailid}`,

      formData,
      {
        headers: headers,
        transformRequest: (d) => d,

      }
    );
    if (data) {
      callBackUploadImage(data);

    }
  } catch (err) { }
};


/*=============================================================
                partner banner Image Upload Api
===============================================================*/

export const uploadPartnerbannerImage = (formData, callBackUploadImage1, emailid) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.url}/Partner_Login/Partner_Profile_Pic_Image_Upload?filename=Sample&file_extension=jpg&filetype=image&emailid=${emailid}`,


      formData,
      {
        headers: headers,
        transformRequest: (d) => d,
      }
    );
    if (data) {
      callBackUploadImage1(data);

    }

  } catch (err) {
  }

};
export const uploadPartnerGSTImage = (formData, callBackUploadImage1, emailid) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.url}/Partner_Login/Partner_Profile_Pic_Image_Upload?filename=Sample&file_extension=jpg&filetype=image&emailid=${emailid}`,


      formData,
      {
        headers: headers,
        transformRequest: (d) => d,
      }
    );
    if (data) {
      callBackUploadImage1(data);

    }

  } catch (err) {
  }

};
export const uploadPartnerPANImage = (formData, callBackUploadImage1, emailid) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.url}/Partner_Login/Partner_Profile_Pic_Image_Upload?filename=Sample&file_extension=jpg&filetype=image&emailid=${emailid}`,


      formData,
      {
        headers: headers,
        transformRequest: (d) => d,
      }
    );
    if (data) {
      callBackUploadImage1(data);

    }

  } catch (err) {
  }

};

export const profilereset = () => async (dispatch: any) => {
  dispatch({
    type: PROFILERESET,
    payload: []
  });
}

// company profile Update
export const updateOpenStoreAction = (formData: any, callBackOpenData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${config.nodecudurl}/Partner_Profile/Update`,
      formData,
      {
        headers: cudheaders
      }
    );
    if (data.Response_Status === "Success") {
      callBackOpenData(data)
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};
export const PartnerProfileActions = (formData: any, callBackOpenData: any) => async dispatch => {

  try {
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Profile/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data.Success_Response.Response_Status === "Success") {
      callBackOpenData(data.results[0]);
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};
// otp generation for E-mail verification
export const companymailVerification = (input: any, emailCheckResponse: any) => {
  return function (dispatch: any) {
    axios.post(`${config.url}/Partner_Profile/Partner_Email_OTP_Generation`, { Email_Id: input }, {
      headers: config.headersCommon
    })
      .then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
          emailCheckResponse(true, '', response.data, input);
        }
        else {
          emailCheckResponse(false, data.UI_Display_Message, data, input);
        }
      })
      .catch(error => {
      })
  }
}
export const companymailOTPVerification = (input: any, emailCheckResponse: any) => {
  return function (dispatch: any) {
    axios.post(`${config.url}/Partner_Profile/Partner_Email_OTP_Verification`, input, {
      headers: config.headersCommon
    })
      .then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
          emailCheckResponse(true, '', response.data, input);
        }
        else {
          emailCheckResponse(false, data.UI_Display_Message, data, input);
        }
      })
      .catch(error => {
      })
  }
}