
import {
  FARMER_PRODUCTS_REQUEST,
  FARMER_PRODUCTS_SUCCESS,
  FARMER_PRODUCTS_FAIL,
  FARMER_PRODUCT_CREATE_REQUEST,
  FARMER_PRODUCT_CREATE_SUCCESS,
  FARMER_PRODUCT_CREATE_FAIL,
  FARMER_PRODUCT_UPDATE_REQUEST,
  FARMER_PRODUCT_UPDATE_SUCCESS,
  FARMER_PRODUCT_UPDATE_FAIL,
  FARMER_PRODUCTS_FILTER,
  FARMER_PRODUCT_CATEGORIES_REQUEST,
  FARMER_PRODUCT_CATEGORIES_SUCCESS,
  FARMER_PRODUCT_CATEGORIES_FAIL,
  FARMER_PRODUCT_SUBCATEGORIES_REQUEST,
  FARMER_PRODUCT_SUBCATEGORIES_SUCCESS,
  FARMER_PRODUCT_SUBCATEGORIES_FAIL,
  FARMER_PRODUCTS_DATA,
} from '../../actions/farmer/farmerproductsAction'


const initialState = {
  products: { all: [], error: '', isLoading: false },
  productCreate: { product: {}, error: '', isLoading: false },
  productUpdate: { product: {}, error: '', isLoading: false },
  productCategories: { categories: [], error: '', isLoading: false },
  productSubCategories: { subcategories: [], error: '', isLoading: false },
  productFilter: { select: 'All', search: '' },
  productdata: { products: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FARMER_PRODUCTS_REQUEST:
      return { ...state, products: { all: state.products.all, error: '', isLoading: true } };
    case FARMER_PRODUCTS_SUCCESS:
      return { ...state, products: { all: action.payload, error: '', isLoading: false } };
    case FARMER_PRODUCTS_FAIL:
      return { ...state, products: { all: [], error: action.payload, isLoading: false } };
    case FARMER_PRODUCT_CREATE_REQUEST:
      return { ...state, productCreate: { product: {}, error: '', isLoading: true } };
    case FARMER_PRODUCT_CREATE_SUCCESS:
      return { ...state, productCreate: { product: state.productCreate.product, error: '', isLoading: false } };
    case FARMER_PRODUCT_CREATE_FAIL:
      return { ...state, productCreate: { product: {}, error: action.payload, isLoading: false } };
    case FARMER_PRODUCT_UPDATE_REQUEST:
      return { ...state, productUpdate: { product: {}, error: '', isLoading: true } };
    case FARMER_PRODUCT_UPDATE_SUCCESS:
      return { ...state, productUpdate: { product: action.payload, error: '', isLoading: false } };
    case FARMER_PRODUCT_UPDATE_FAIL:
      return { ...state, productUpdate: { product: {}, error: action.payload, isLoading: false } };
    case FARMER_PRODUCTS_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, productFilter: { search: state.productFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, productFilter: { select: state.productFilter.select, search: action.value } };
      }
      return res;

    case FARMER_PRODUCTS_DATA:
      return { ...state, productdata: { products: action.value, error: '', isLoading: true } };

    case FARMER_PRODUCT_CATEGORIES_REQUEST:
      return { ...state, productCategories: { categories: state.productCategories.categories, error: '', isLoading: true }, };
    case FARMER_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, productCategories: { categories: action.payload, error: '', isLoading: false } };
    case FARMER_PRODUCT_CATEGORIES_FAIL:
      return { ...state, productCategories: { categories: [], error: action.payload, isLoading: false } };
    case FARMER_PRODUCT_SUBCATEGORIES_REQUEST:
      return { ...state, productSubCategories: { subcategories: state.productSubCategories.subcategories, error: '', isLoading: true }, };
    case FARMER_PRODUCT_SUBCATEGORIES_SUCCESS:
      return { ...state, productSubCategories: { subcategories: action.payload, error: '', isLoading: false } };
    case FARMER_PRODUCT_SUBCATEGORIES_FAIL:
      return { ...state, productSubCategories: { subcategories: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}