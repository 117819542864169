import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, Image, RefreshControl } from 'react-native';
import { Card, DataTable, Searchbar, Button, Surface, Dialog, Portal, IconButton, DefaultTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGiftAction, giftFilter, resetFilter } from "../../../state/actions/giftsAction";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import Header from "../../auth/components/header";
import isEmpty from '../../../state/validations/is-empty';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useToast } from "../../../components/custom/react-native-paper-toast/src";

const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Gift_Message',
        label: 'Gift Message'
    },
    {
        value: 'Gift_Voucher_Id',
        label: 'Gift Voucher ID'
    },
    {
        value: 'Voucher_Name',
        label: 'Voucher Name'
    },
];
const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const GiftGet = (props: any) => {
    //toast for popup after successful API calls.
    const toast = useToast();
    const { navigation } = props;
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const esiColor = useSelector(state => state.theme);
    const vendorgifts = useSelector(state => state.gifts.gifts.all);
    const [showDropDown, setShowDropDown] = React.useState(false);
    let giftsMenu = useSelector(state => state.gifts.giftFilter.select);
    let giftsSearch = useSelector(state => state.gifts.giftFilter.search);
    const [page, setPage] = React.useState(0);
    const [gifts, setGifts] = useState([]);
    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, vendorgifts?.length);
    const Gifts = props.route?.params?.giftsdetails;
    const [Vendorgifts, setData] = React.useState();
    const [visible, setVisible] = React.useState(false);
    const hideDialog = () => setVisible(false);
    const dispatch = useDispatch()
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (isEmpty(vendorgifts) && Gifts) {
            const formData = {
                "Gift_Card_Vendor_Id": Gifts,
                'Records_Filter': 'FILTER'
            };
            setData(vendorgifts)
            handleSearch("")
            dispatch(getAllGiftAction(formData))
            dispatch(giftFilter('search', ''));
            dispatch(giftFilter('select', 'All'));
        }
    }, [vendorgifts]);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const handleClickedit = async (vendorGifts) => {
        props.navigation.navigate('EditGift', { Vendorgifts: vendorGifts.Gift_Voucher_Id })
    };
    const [oldSearch, setOldSearch] = React.useState(0);
    const checkGiftsForTable = () => {
        if (giftsSearch) {
            try {
                if (giftsMenu === "All") {
                    return vendorgifts.filter(x => {
                        let colummns = ["Gift_Message", "Gift_Voucher_Id", "Voucher_Name"];
                        for (let i = 0; i < colummns.length; i++) {
                            const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
                            const subString = typeof x[colummns[i]] === "string" ? giftsSearch.toLowerCase() : giftsSearch;
                            if (typeof x[colummns[i]] === "string") {
                                if (mainString.includes(giftsSearch.toLowerCase())) {
                                    return true;
                                }
                            }
                            else {
                                if (mainString == giftsSearch) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    });
                } else {
                    return vendorgifts.filter(x => {
                        let mainString = typeof x[giftsMenu] == "string" ? x[giftsMenu].toLowerCase() : x[giftsMenu];
                        let subString = typeof x[giftsMenu] == "string" ? giftsSearch.toLowerCase() : giftsSearch;
                        if (typeof x[giftsMenu] === "string") {
                            return mainString.includes(subString);
                        }
                        else {
                            if (mainString == giftsSearch) {
                                return true;
                            }
                        }
                    })
                }
            } finally {
                if (page > 0) {
                    if (oldSearch != giftsSearch.length) {
                        setOldSearch(giftsSearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch != giftsSearch.length) {
                    setOldSearch(giftsSearch.length);
                    setPage(0);
                }
            }
        }
        else {
            return vendorgifts
        }
    }

    useEffect(() => {
        if (!isEmpty(vendorgifts)) {
            setGifts(vendorgifts);
        } else {
            setGifts([]);
        }
    }, [vendorgifts]);



    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Gift_Voucher_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    function descendingComparator(a, b, orderBy) {
        try {
            if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
                return -1;
            }
            if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
                return 1;
            }
            return 0;
        } catch (error) {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }

    }
    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis?.map((el) => el[0]);
    }
    const handleChange = itemValue => {
        dispatch(giftFilter('select', itemValue));
    };
    const handleSearch = value => {
        dispatch(giftFilter('search', value));
    };
    const data = stableSort(
        checkGiftsForTable(), getComparator(order, orderBy))

    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };

    const styles = StyleSheet.create({
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 160
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'left',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 240
        },
    });

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} />
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto',backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
                    <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
                        <IconButton
                            icon="close"
                            color={esiColor.GBFColor}
                            size={20}
                            onPress={() => setVisible(!visible)}
                        />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                        <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>Gifts View</Text>
                    </Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView refreshControl={
                            <RefreshControl
                                refreshing={refreshing}
                                onRefresh={onRefresh}
                                colors={['#27B6CC']}
                                tintColor={'#27B6CC'}
                                title={"Pull to refresh"}
                                titleColor={"#27B6CC"}
                            />
                        } contentContainerStyle={{ paddingHorizontal: 24 }}>
                            <Dialog.Content style={{ marginLeft: -35 }}>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Gift Vendor ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{Vendorgifts?.Gift_Card_Vendor_Id} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Gift Voucher ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{Vendorgifts?.Gift_Voucher_Id} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Gift Image ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{Vendorgifts?.Gift_Image_Id} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Voucher Name</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Vendorgifts?.Voucher_Name} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Gift Message</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Vendorgifts?.Gift_Message} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Order By</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor}}>{Vendorgifts?.Order_By_Email_Id} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Vendorgifts?.Created_Date} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Purpose</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Vendorgifts?.Purpose} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Order To Name</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Vendorgifts?.Order_To_Name} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Order To Moblie</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Vendorgifts?.Order_To_Moblie} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Order To Email ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Vendorgifts?.Order_To_Email_Id} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Purpose</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Vendorgifts?.Purpose} </Text></View>
                                </View>
                                <View style={{
                                    flexDirection: "row", paddingTop: 15
                                }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Total Value</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Vendorgifts?.Total_Value} </Text></View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>
            <View>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                    navigation.navigate('GiftVendorsGet')
                    handleSearch("")
                    dispatch(resetFilter())
                }} >
                    Go Back
                </Button>
            </View>
            <ScrollView>
                <View style={{ flexDirection: "column" }}>
                    <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>User Gifts</Text>
                    <View style={{ flexDirection: "row", paddingTop: 20 }}>
                        <View style={{ flex: 1.7 }} />
                        <View style={{ flex: 0.1 }} />
                    </View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 10, marginLeft: 5 }}>
                    <View style={{ flex: dimensions <= 700 ? 0.1 : 0.04 }} />
                    <View style={{ flex: dimensions <= 700 ? 1.5 : 0.5 }}>
                        {Platform.OS !== 'web' ?
                            <EsiSearchDropdown
                                style={[styles.dropdown]}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                data={filterOptions}
                                containerStyle={styles.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={giftsMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            /> :
                            <Picker
                                selectedValue={giftsMenu}
                                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Gift Message" value="Gift_Message" />
                                <Picker.Item label="Gift Voucher ID" value="Gift_Voucher_Id" />
                                <Picker.Item label="Voucher Name" value="Voucher_Name" />
                            </Picker>}
                    </View>
                    <View style={{ flex: dimensions <= 700 ? 0.1 : 0.6 }} />
                    <View style={{ flex: 1.8 }}>
                        <Searchbar
                            style={{
                                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.BackgroundColor
                            }}
                            iconColor={esiColor.SIconColor}
                            theme={esiColor.pagination_theme}
                            placeholderTextColor={esiColor.SPHTextColor}
                            placeholder="Search"
                            onChangeText={(value) => handleSearch(value)}
                            value={giftsSearch}
                        />
                    </View>
                    <View style={{ flex: dimensions <= 700 ? 0.1 : 1.6 }} />
                    <View style={{ flex: dimensions <= 700 ? null : 0.07 }} />
                </View>
                <ScrollView horizontal={dimensions >= 700 ? false : true}>
                    <View style={styles.mainbox}>
                        <Card>
                            <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                <DataTable.Header style={styles.databeHeader}>
                                    <DataTable.Title  active={orderBy === 'Gift_Voucher_Id'} sortDirection={orderBy === 'Gift_Voucher_Id' ? order : 'ascending'} onPress={createSortHandler('Gift_Voucher_Id')} style={styles.title}><Text style={styles.titletext}>Gift Voucher ID</Text></DataTable.Title>
                                    <DataTable.Title  active={orderBy === 'Order_By_Email_Id'} sortDirection={orderBy === 'Order_By_Email_Id' ? order : 'ascending'} onPress={createSortHandler('Order_By_Email_Id')} style={styles.title}><Text style={styles.titletext}>Order By</Text></DataTable.Title>
                                    <DataTable.Title  active={orderBy === 'Gift_Message'} sortDirection={orderBy === 'Gift_Message' ? order : 'ascending'} onPress={createSortHandler('Gift_Message')} style={styles.title}><Text style={styles.titletext}>Gift Message</Text></DataTable.Title>
                                    <DataTable.Title  active={orderBy === 'Voucher_Name'} sortDirection={orderBy === 'Voucher_Name' ? order : 'ascending'} onPress={createSortHandler('Voucher_Name')} style={styles.title}><Text style={styles.titletext}>Voucher Name</Text></DataTable.Title>
                                    <DataTable.Title  active={orderBy === 'Created_Date'} sortDirection={orderBy === 'Created_Date' ? order : 'ascending'} onPress={createSortHandler('Created_Date')} style={styles.title}><Text style={styles.titletext}>Created Date</Text></DataTable.Title>
                                    <DataTable.Title theme={customTheme} style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                                </DataTable.Header>
                                {data?.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage)?.map((vendorGifts, i) => (
                                    <DataTable.Row style={styles.databeBox} key={i}>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{vendorGifts.Gift_Voucher_Id}</Text> </DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{vendorGifts.Order_By_Email_Id}</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{vendorGifts.Gift_Message}</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{vendorGifts.Voucher_Name}</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{vendorGifts.Created_Date}</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}>
                                            {(permissions.Gifts_Management_Vendors_Images_User_Gifts === "write") && <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClickedit(vendorGifts); }} />
                                            }
                                            <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(vendorGifts); setVisible(!visible) }} />
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                ))
                                }
                                {isEmpty(data) &&
                                    <View>
                                        <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                            No records found!
                                        </Text>
                                        <Image source={require('../../../assets/images/nodata.gif')}
                                            style={{ width: 300, height: 300, alignSelf: "center" }} />
                                    </View>
                                }
                                <DataTable.Pagination
                                    // theme={customTheme}
                                    page={page}
                                    numberOfPages={Math.ceil(vendorgifts?.length / numberOfItemsPerPage)}
                                    onPageChange={page => setPage(to < data?.length ? page : 0)}
                                    label={vendorgifts?.length ? `${from + 1}-${to} of ${data?.length}` : `0-0`}
                                    showFastPaginationControls={to > data?.length ? false : true}
                                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                                    numberOfItemsPerPage={numberOfItemsPerPage}
                                    onItemsPerPageChange={onItemsPerPageChange}
                                    selectPageDropdownLabel={'Rows per page'}
                                />
                            </DataTable>
                        </Card>
                    </View>
                </ScrollView>
            </ScrollView>
        </Surface>
    );
}
export default GiftGet;