import {
    PROPERTY_REQUEST,
    PROPERTY_SUCCESS,
    PROPERTY_FAIL,
  
    SINGLE_PROPERTY_REQUEST,
    SINGLE_PROPERTY_SUCCESS,
    SINGLE_PROPERTY_FAIL,
  
    PROPERTY_CREATE_REQUEST,
    PROPERTY_CREATE_SUCCESS,
    PROPERTY_CREATE_FAIL,
  
    PROPERTY_UPDATE_REQUEST,
    PROPERTY_UPDATE_SUCCESS,
    PROPERTY_UPDATE_FAIL,
  
    PROPERTY_FILTER,
    PROPERTY_DATA,
    DASHBOARD_TOTAL_PROPERTIRES,
    DASHBOARD_TOTAL_ACTIVE_BOOKINGS,
    DASHBOARD_TOTAL_BOOKINGS,
  } from '../../actions/Properties/PropertiesAction'
  
  const initialState = {
    Propertiescount: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Activebookingcount: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    bookingcount: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },

    propertys: { all: [], error: '', isLoading: false },
    property: { property: {}, error: '', isLoading: false },
  
    propertyCreate: { property: {}, error: '', isLoading: false },
    propertyUpdate: { property: {}, error: '', isLoading: false },
    propertydata: { propertysdata: {}, error: '', isLoading: false },
   
    propertyFilter: { select: 'All', search: '' },
    
  };
  
  export default function (state = initialState, action: any) {
  
    switch (action.type) {
      case PROPERTY_REQUEST:
        return { ...state, propertys: { all: state.propertys.all, error: '', isLoading: true } };
      case PROPERTY_SUCCESS:
        return { ...state, propertys: { all: action.payload, error: '', isLoading: false } };
      case PROPERTY_FAIL:
        return { ...state, propertys: { all: [], error: action.payload, isLoading: false } };
  
      case SINGLE_PROPERTY_REQUEST:
        return { ...state, property: { property: {}, error: '', isLoading: true } };
        case SINGLE_PROPERTY_SUCCESS:
          return { ...state, propertys: { property: action.payload, error: '', isLoading: false } };
       case SINGLE_PROPERTY_FAIL:
        return { ...state, property: { property: {}, error: action.payload, isLoading: false } };
  
      case PROPERTY_CREATE_REQUEST:
        return { ...state, propertyCreate: { property: {}, error: '', isLoading: true } };
      case PROPERTY_CREATE_SUCCESS:
        return { ...state, propertyCreate: { property: state.propertyCreate.property, error: '', isLoading: false } };
      case PROPERTY_CREATE_FAIL:
        return { ...state, propertyCreate: { property: {}, error: action.payload, isLoading: false } };
  
      case PROPERTY_UPDATE_REQUEST:
        return { ...state, propertyUpdate: { property: {}, error: '', isLoading: true } };
      case PROPERTY_UPDATE_SUCCESS:
        return { ...state, propertyUpdate: { property: state.propertyUpdate.property, error: '', isLoading: false } };
      case PROPERTY_UPDATE_FAIL:
        return { ...state, propertyUpdate: { property: {}, error: action.payload, isLoading: false } };
        case PROPERTY_DATA:
          return { ...state, propertydata: { propertysdata: action.value, error: '', isLoading: true } };
          case DASHBOARD_TOTAL_PROPERTIRES:
            return { ...state, Propertiescount: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }
            case DASHBOARD_TOTAL_ACTIVE_BOOKINGS:
              return { ...state, Activebookingcount: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }
              case DASHBOARD_TOTAL_BOOKINGS:
                return { ...state, bookingcount: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }
            
      case PROPERTY_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, propertyFilter: { search: state.propertyFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, propertyFilter: { select: state.propertyFilter.select, search: action.value } };
        }
        return res;
  
      default:
        return state;
    }
  }