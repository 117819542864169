import { v4 as uuid } from 'uuid';
import productmanagement from '../../../assets/static/Dashboard/productmanagement.png'
import inventorymanagement from "../../../assets/static/Dashboard/inventorymanagement.jpg"
import coupon_management from "../../../assets/static/Dashboard/coupon_management.jpg"
import { View } from 'react-native';

export default [
  {
    id: uuid(),
    link: 'PartnerBiddingServices',
    createdAt: 'Updated 2 hr ago',
    description:
      'Product Management is a process that focuses on bringing a new producs to Bidding One for the Useers.',
    media: productmanagement,
    title: 'Available Bidding Services',
    total: ''
  },
  // {
  //   id: uuid(),
  //   link: 'UserNewBiddingQuotes',
  //   createdAt: 'Updated 2 hr ago',
  //   description: 'New Bidding Quotes it is used to display maily User requsted biddings which we have the products',
  //   media: inventorymanagement,
  //   title: 'User New Biddng Quotes',
  //   total: ''
  // },
  // {
  //   id: uuid(),
  //   link: 'PartnerBiddingQuotes',
  //   createdAt: 'Updated 2 hr ago',
  //   description:
  //     'Partner Bidding Quotes It is used to get Our Quoted Products.',
  //   media: coupon_management,
  //   title: 'Partner Bidding Quotes',
  //   total: ''
  // },
  {
    id: uuid(),
    link: 'UserNewBiddingQuotesForServices',
    createdAt: 'Updated 2 hr ago',
    description: 'New Bidding Service Quotes it is used to display mainly User requsted biddings which we have the services',
    media: inventorymanagement,
    title: 'User New Biddng Service Quotes',
    total: ''
  },
  {
    id: uuid(),
    link: 'PartnerBiddingServiceQuotes',
    createdAt: 'Updated 2 hr ago',
    description:
      'Partner Bidding Service Quotes It is used to get Our Quoted Services.',
    media: coupon_management,
    title: 'Partner Bidding Service Quotes',
    total: ''
  },
  // {
  //   id: uuid(),
  //   link: 'DealsGet',
  //   createdAt: 'Updated 2 hr ago',
  //   description: 'Deal Management is a strategy or tool that gives companies the ability to define deal parameters – including customers history, product status, discount level and operational constraints.',
  //   media: Deal_management,
  //   title: 'Deal Management',
  //   total: ''
  // },
  // {
  //   id: uuid(),
  //   link: 'SBCPartnercityGet',
  //   createdAt: 'Updated 2 hr ago',
  //   description: 'Shop By City Management is used to refer the Coupons, Deals and Products related to partner, the user can search based on particular city.',
  //   media: shop_by_city,
  //   title: 'Shop By City',
  //   total: ''
  // },
  // {
  //   id: uuid(),
  //   link: 'GiftVendorsGet',
  //   createdAt: 'Updated 2 hr ago',
  //   description:
  //     'Gift Management is the process where all the gift information is stored.',
  //   media: gift_management,
  //   title: 'Gift Management',
  //   total: ''
  // },
];