import React, { useState, useEffect } from "react";
import { Avatar, Button, Card, Colors, IconButton, Surface, Text, TouchableRipple } from "react-native-paper";
import { Image, TouchableOpacity, View, Dimensions, ActivityIndicator, Linking, Platform, ImageBackground, StyleSheet } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { EvilIcons, FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-native-modal";
import * as Network from 'expo-network';
import SignIN from "../../auth/signin";
import { useToast } from "../../../components/custom/react-native-paper-toast/src";
import { addCart, addItemMultipleCart } from "../../../state/actions/shoppers/shoppercartActions";
import { getSingleCartItemCheck } from "../../../state/actions/node-actions/home-actions";
import { adduservisitAction, getProductSizeActionWishList, productsizereset } from "../../../state/actions/shoppers/productsActions";
import isEmpty from "../../../state/validations/is-empty";
// import esiColor from "../../../constants/Colors";

export default function ProductCard(props: any) {
    const { data, handleClick, navigation, ProductId, PartnerId } = props;
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(false);
    const EmailID = useSelector(state => state.auth.user?.Partner_Details_Id);
    const Email = useSelector(state => state.auth.user?.Email_Id);
    const esiColor = useSelector(state => state.theme);

    const Adress = useSelector(state => state.auth.user?.Partner_Address_Id);
    const [openredirect, setRedirectionOpen] = React.useState(false);
    const onOpenModal = (data) => {
        let Url = data.Web_Redirection_Url;
        setTimeout(function () {
            Linking.openURL(Url);
            setRedirectionOpen(false);
        }, 2000);
    };
    const [imageURL, setImageURL] = useState(false);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [cartLoading, setCartLoading] = useState(false);


    const handleClickCart = async (data) => {
        if (Email) {
            if (data.Partner_Type === "Online") {
                setRedirectionOpen(true)
                onOpenModal(data);
                setImageURL(data.Logo_Path)
            }
            else {
                dispatch(getProductSizeActionWishList(data.Product_Id, data.Partner_Details_Id, data, sizeAddtoCart));
                setModalIsOpen(true);
                setCartLoading(true);
            }
            let iPAddress = await Network.getIpAddressAsync();
            let formData = {
                "B2B_User_Pickup_Partner_Address_Id": Adress,
                "B2B_User_Shipping_Address_Id": "",
                "Buyer_Partner_Details_Id": EmailID,
                "Created_By": Email,
                "Is_Pickup": "",
                "Partner_Product_Id": data.Partner_Product_Id,
                "Quantity": data.Min_Quantity,
                "Seller_Partner_Details_Id": data.Partner_Details_Id,
                "Size": ""
            }
            dispatch(adduservisitAction(formData));
        } else {
            setModalVisible(!isModalVisible);
        }
    };

    const [cartSize, setCartSize] = useState([]);
    const [cartErrorMessage, setCartErrorMessage] = useState("");
    const sizeAddtoCart = (status, response, partnerData, message) => {
        setCartLoading(false);
        if (status && !isEmpty(response)) {
            let payload = {
                "Buyer_Partner_Detail_ID": EmailID,
                "Partner_Product_Id": partnerData.Partner_Product_Id,
                "Quantity": partnerData?.Min_Quantity,
                "Seller_Partner_Detail_Id": partnerData.Partner_Details_Id,
                "Size": response
            }
            dispatch(addCart(payload, EmailID, checkCartUpdateResponse));
            dispatch(productsizereset());
        } else {
            setCartErrorMessage(message);
        }
    }
    const [size, setSize] = useState({ size: "", quantity: "" });

    const onCloseModal = () => {
        setSize({ size: "", quantity: "" })
        setModalIsOpen(false);
        setSelected(false);
    };

    useEffect(() => {
        checkCart();
    }, [EmailID, ProductId])
    const sampleData = {
        Brand_Id: "B-0000194",
        Brand_Image_Path: "http://api.easystepin.com:8092/uploads/ESICB/Brand/20210526/Brand_20210526051658_Sample.jpg",
        Brand_Name: "Adidas",
        CashBack_Product_Price: 2.4402,
        Category_Id: "C-0000319",
        Country_Of_Origin: "IN",
        Created_By_Email: "devi.neralla@easystepin.com",
        Created_Date: "20/07/2021",
        Created_User_Type: null,
        Discount: 2,
        Discount_From_Date: "06/08/2021",
        Discount_To_Date: "31/01/2022",
        Discount_Type: "P",
        Esi_CashBack_Price: 2.4402,
        Esi_Cashback: 2,
        Esi_Cashback_Type: "P",
        Esi_Rating: 4,
        Esi_Reviews: "<p>Awsome to buy this product. It's nyce</p>\n",
        Extra_Cashback: 1,
        Features: "<p>24/7 freshness with a joy.</p>\n",
        Gift_Wrap_Amount: 30,
        Is_Gift_Wrap_Available: 1,
        Is_Popular: 1,
        Is_Top_Brand: 1,
        Is_Top_Product: 0,
        Is_Upcoming: 0,
        Is_Verified: 0,
        Logo_Path: "http://api.easystepin.com:8092/uploads/ESICB/PartnerProfile/20210624/PartnerProfile_Banner_20210624101653_JK.jpg",
        Mobile_Redirection_Url: "https://www.amazon.in/Adidas-Pure-Spray-developed-Athletes/dp/B00BVO9N2A/ref=sr_1_2?crid=3H70RRH5FHF6C&dchild=1&keywords=adidas+perfume&qid=1626753666&sprefix=addidas+p%2Caps%2C399&sr=8-2",
        Modified_By: null,
        Modified_Date: null,
        Original_Price: 249,
        Partner_Details_Id: "P-D-0000215",
        Partner_Name: "Amazon",
        Partner_Price: 249,
        Partner_Product_Id: "P-P-0001555",
        Partner_Selling_Price: 244.02,
        Partner_Type: "Online",
        Product_Color_Variant_Id: "C-V-0000038",
        Product_Description: "<p>ADIDAS PURE GAME by Adidas for MEN EDT SPRAY 3.4 OZ (DEVELOPED WITH ATHLETES) Launched by the design house of Adidas in 2010, ADIDAS PURE GAME by Adidas possesses a blend of Grapefruit, Incense, And Patchouli.</p>\n",
        Product_Id: "P-0000614",
        Product_Image: "http://api.easystepin.com:8092/uploads/ESICB/ProductImage/20210720/ProductImage_20210720041241_BTS.jpg",
        Product_Master_Id: "P-M-0000113",
        Product_Name: "Adidas Pure Game By Adidas Edt Spray 3.4 Oz (developed With Athletes) (men)",
        Product_Short_Description: "Fragrance Notes: Grapefruit, Incense, And Patchouli.",
        Specifications: "<p>Long time flavour.<br>Spay like a boom.</p>\n",
        Status: "Active",
        Sub_Category_Id: "C-S-0000229",
        Web_Redirection_Url: "https://www.amazon.in/Adidas-Pure-Spray-developed-Athletes/dp/B00BVO9N2A/ref=sr_1_2?crid=3H70RRH5FHF6C&dchild=1&keywords=adidas+perfume&qid=1626753666&sprefix=addidas+p%2Caps%2C399&sr=8-2",
        Whats_Hot: 0,
    }

    const [cart, setCart] = useState({ B2B_User_Cart_Id: 0, Quantity: 0 });
    const checkCartResponse = (status, data) => {
        if (status) {
            setCart(data);
        }
    }

    const checkCartUpdateResponse = (data) => {
        if (data?.Response_Status == "Failure") {
            setCartErrorMessage(data?.UI_Display_Message);
        } else {
            checkCart();
        }
    }

    const checkCart = () => {
        if (EmailID && data?.Partner_Product_Id) {
            let filter = {
                "Partner_Product_Id": data?.Partner_Product_Id,
                "Buyer_Partner_Detail_ID": EmailID
            }
            dispatch(getSingleCartItemCheck(filter, {}, checkCartResponse))
        }
    }

    const toast = useToast();
    const [isModalVisible, setModalVisible] = React.useState(false);

    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
        if (status) {
            onLikeClicked(false);
        }
    };

    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const halfdimensions = dimensions.width / 2;
    const [isNotProductAvailable, setIsNotProductAvailable] = React.useState(false);

    return (
        <View style={[{
            margin: 5, maxWidth: 230, width: (halfdimensions - 20), backgroundColor: esiColor.CBColor,
            borderRadius: 10, shadowColor: esiColor.brandShadowColor,
            shadowOpacity: 0.1,
            shadowRadius: 3,
            elevation: 5,

        }, Platform.OS != "android" ? {
            shadowOffset: { width: 1, height: 1 },
            shadowColor: esiColor.brandShadowColor,
            shadowOpacity: 0.1,
            shadowRadius: 3,
            elevation: 5,
            backgroundColor: esiColor.CBColor
        } : {
            shadowOffset: { width: 1, height: 1 },
            shadowColor: esiColor.brandShadowColor,
            shadowOpacity: 0.1,
            shadowRadius: 3,
            elevation: 5,
            overflow: "hidden",
            backgroundColor: esiColor.CBColor
        }]}>
            <View style={{ alignItems: 'flex-end', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Image source={{ cache: "force-cache", uri: data?.Logo_Path }} style={{ height: 22, maxHeight: 22, overflow: "hidden", width: 60, resizeMode: 'contain', paddingVertical: 2 }} />
                </View>
                {parseInt(data?.Discount) != 0 &&
                    <View style={{ alignItems: 'flex-end', marginTop: 5 }}>
                        <Text style={{ padding: 2, fontSize: 14, color: esiColor.FHColor, borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}>{data?.Discount_Type == 'A' && data?.Discount + "/-"}{(data?.Discount_Type == 'P' || data?.Discount_Type == 'percentage' || data?.Discount_Type == 'Percentage') && data?.Discount + "%"} Off</Text>
                    </View>
                }
            </View>

            <TouchableOpacity onPress={() => { handleClick(data) }} >

                <View style={{ flexDirection: 'column', width: '100%' }}>
                    <ImageBackground style={{ height: 100, width: "100%", backgroundColor: esiColor.ImgBck }}>

                        <Image source={{ cache: "force-cache", uri: data?.Product_Image }} style={{ height: 100, width: "100%", resizeMode: 'contain' }} />
                    </ImageBackground>
                </View>
                <View style={{ alignItems: 'stretch', marginTop: 1, paddingLeft: 10, height: 44 }}>
                    <Text style={{ textAlign: 'left', color: esiColor.itemColor, opacity: 0.8 }}>{data?.Product_Name.slice(0, 40)}</Text>
                </View>
                <View style={{ width: '100%', marginTop: 2, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <Text style={{ paddingVertical: 3, color: esiColor.itemColor, fontSize: 14, fontWeight: 'bold' }}>₹{data?.Partner_Selling_Price != null ? parseFloat(data?.Partner_Selling_Price) : '0'}     <Text style={{ fontSize: 14, textDecorationLine: 'line-through', color: esiColor.amountStrikeColor }}>₹{data?.Original_Price}</Text>
                    </Text>
                </View>
            </TouchableOpacity>
            <View style={{ flexDirection: "row", justifyContent: 'space-around', alignItems: 'center', marginVertical: 2 }}>
                {cart.B2B_User_Cart_Id ?
                    <View style={{ flexDirection: 'row' }}>
                        <TouchableOpacity disabled={cart.Quantity <= 1 ? true : false} onPress={(e) => dispatch(addItemMultipleCart({
                            B2B_User_Cart_Id: cart.B2B_User_Cart_Id,
                            partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                        }, Number(cart.Quantity) - 1, checkCartUpdateResponse))} style={{ borderWidth: 1, borderColor: cart.Quantity <= 1 ? esiColor.itemColor : "#27b6cc" }}>
                            <MaterialIcons name="remove" size={22} color={cart.Quantity <= 1 ? esiColor.itemColor : "#27b6cc"} />
                        </TouchableOpacity>
                        {
                            <QuentityField count={cart.Quantity} handleOnChangeCount={(count) => {
                                dispatch(addItemMultipleCart({
                                    B2B_User_Cart_Id: cart?.B2B_User_Cart_Id,
                                    partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                                }, count, checkCartUpdateResponse))
                            }} />
                        }
                        <TouchableOpacity onPress={(e) => dispatch(addItemMultipleCart({
                            B2B_User_Cart_Id: cart.B2B_User_Cart_Id,
                            partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                        }, Number(cart.Quantity) + 1, checkCartUpdateResponse))} style={{ borderWidth: 1, borderColor: '#27b6cc' }}>
                            <MaterialIcons name="add" size={22} color="#27b6cc" />
                        </TouchableOpacity>
                    </View> :
                    <TouchableOpacity disabled={data?.Is_Stock_Available > '0' ? false : true} onPress={() => {
                        if (data.Is_Open !== 1 || data?.Is_Stock_Available == '0') {
                            setIsNotProductAvailable(!isNotProductAvailable);
                        } else {
                            handleClickCart(data);
                        }
                    }} >
                        <View style={{
                            flexDirection: "row", backgroundColor: esiColor.globalButtonColor,
                            borderRadius: 8, paddingVertical: 5,
                            paddingHorizontal: 20, marginBottom: 4,
                        }
                        }>
                            <Text style={{ color: esiColor.itemButtenColor, fontWeight: "bold" }}>Add to Cart</Text>
                        </View>
                    </TouchableOpacity>
                }
            </View>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isNotProductAvailable}>
                <View style={{
                    flex: 1, maxWidth: 300, minWidth: 200, minHeight: 250, maxHeight: 300, borderRadius: 10,
                    backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5, borderColor: esiColor.SBorderColor
                }}>

                    <View style={{ marginHorizontal: 10, justifyContent: "center", flexDirection: "column", height: "100%" }} >
                        <Text style={{ textAlign: "center", color: esiColor.DescColor, fontSize: 24 }} >
                            Oops..
                        </Text>
                        <Text style={{ marginVertical: 10, color: esiColor.itemColor }} >
                            Currently the partner is not accepting orders, Please click okay to check this product available in other stores.
                        </Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-around" }} >
                            <TouchableOpacity style={{ flex: 2, paddingHorizontal: 10 }} onPress={() => {
                                setIsNotProductAvailable(!isNotProductAvailable);
                            }}>
                                <Button
                                    // backgroundColor = {esiColor.globalButtonColor}
                                    color={'gray'}
                                    mode="outlined"
                                    onPress={() => {
                                        setIsNotProductAvailable(!isNotProductAvailable);
                                    }}
                                >
                                    <Text style={{ color: esiColor.itemButtenColor }}>Cancel</Text>
                                </Button>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ flex: 2, paddingHorizontal: 10 }} onPress={() => {
                                handleClick(data);
                                setIsNotProductAvailable(!isNotProductAvailable);
                            }}>
                                <Button
                                    color={esiColor.globalButtonColor}
                                    mode="contained"
                                    onPress={() => {
                                        setIsNotProductAvailable(!isNotProductAvailable);
                                        handleClick(data);
                                    }}
                                >
                                    <Text style={{ color: esiColor.itemButtenColor }}>Okay</Text>
                                </Button>
                            </TouchableOpacity>
                        </View>
                    </View>

                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor,
                    shadowOpacity: 1, shadowRadius: 5,
                }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>

            {/* Partner Redirection Modal */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={openredirect}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 150, borderRadius: 10,
                    backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5
                }}>
                    <View style={{ alignItems: 'flex-end', marginTop: '-6%' }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setRedirectionOpen(false) }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View>
                        <Text style={{ color: esiColor.DescColor, textAlign: 'center', marginBottom: 10 }}>Prices may vary for this product. Redirecting to partner site. Please wait....</Text>
                    </View>
                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            {/* <Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} /> */}
                            <Button textColor={esiColor.globalButtonColor} icon="arrow-right-bold" style={{ size: 15, marginTop: 10 }} />
                            <Image source={{ cache: "force-cache", uri: imageURL }} style={{ height: 60, width: 60, resizeMode: 'contain' }} />
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={modalIsOpen}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, minHeight: 200, maxHeight: 250, borderRadius: 10,
                    backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5
                }}>

                    <View style={{
                        alignItems: 'flex-end', marginTop: '-6%'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { onCloseModal(); setCartErrorMessage(""); }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    {
                        !isEmpty(cartSize) ?
                            <View>
                                <Text style={{ textAlign: "center", color: esiColor.DescColor }}>
                                    Select Size
                                </Text>

                                <View style={{ textAlign: "center", justifyContent: "space-evenly", flexDirection: 'row' }}>
                                    {
                                        cartSize.map((size, index) => (
                                            <View key={index}>
                                                <TouchableOpacity
                                                    key={index}
                                                    onPress={() => { setSize({ size: size.Product_Size, quantity: size.Product_Available_Stock }); handleSelect(size.Product_Size) }}
                                                >
                                                    <View style={selected === size.Product_Size ? styles.productDetailsSize : styles.productDetailsSizeSelect}>
                                                        <Text style={{color: esiColor.DescColor}} icon="format-size">{size.Product_Size}</Text>
                                                    </View>

                                                </TouchableOpacity>
                                                {
                                                    size.Product_Available_Stock ?
                                                        <Text >
                                                            {parseInt(size.Product_Available_Stock) < 11 ?
                                                                <Text style={styles.ribbon}>{size.Product_Available_Stock} Left</Text>
                                                                : null}
                                                        </Text> : null
                                                }
                                            </View>
                                        ))
                                    }
                                </View>

                                <View>
                                    <TouchableOpacity onPress={cartData}>
                                        <Button
                                            variant="contained"
                                            color={esiColor.globalButtonColor}
                                            type="submit"
                                            disabled={size.size && size.quantity ? false : true}
                                            style={{ color: size.size && size.quantity ? "" : "blue" }}
                                        >
                                            <Text style={{ color: esiColor.itemButtenColor }}>Proceed</Text>
                                        </Button>
                                    </TouchableOpacity>
                                    {/* <button  onClick={cartData} className="sizebutton" >Proceed</button> */}
                                </View>
                            </View>
                            :
                            cartLoading ? <ActivityIndicator color='#27b6cc' /> :
                                <View style={{
                                    flexDirection: 'row', justifyContent: 'center',
                                    // backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                                    // shadowOpacity: 1, shadowRadius: 5
                                }}>
                                    {cartErrorMessage ? <Text style={{ color: "red" }} >{cartErrorMessage}</Text> :
                                        <View style={{ flexDirection: "column", alignItems: "center" }} >
                                            <Text style={{ fontSize: 16, fontWeight: "bold", color: esiColor.DescColor }} >Item added to the cart.</Text>
                                            <IconButton icon={"cart-check"} color={"#27b6cc"} size={60} />
                                            <TouchableOpacity onPress={() => {
                                                onCloseModal(); setCartErrorMessage("");
                                            }}>
                                                <Button
                                                    mode="contained"
                                                    color={esiColor.globalButtonColor}
                                                    labelStyle={{ color: "#fff" }}
                                                    type="submit"
                                                    // style={{ : "#fff" }}
                                                    onPress={() => {
                                                        onCloseModal(); setCartErrorMessage("");
                                                    }}
                                                >
                                                    <Text style={{ color: esiColor.itemButtenColor }}>Done</Text>

                                                </Button>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View>
                    }
                </View>
            </Modal >
        </View>

    );
}

const styles = StyleSheet.create({

    productDetailsSize: {
        width: 50,
        height: 50,
        backgroundColor: 'rgb(42, 175, 82)',
        // cursor: pointer,
        borderRadius: 50,
        borderColor: '#c4c4da',
        borderWidth: 1,
        color: 'white',
        flexDirection: "column",
        marginHorizontal: 4
    },

    productDetailsSizeSelect: {
        width: 50,
        height: 50,
        backgroundColor: 'white',
        // cursor: pointer;
        borderRadius: 50,
        // marginLeft: 'auto',
        // marginRight: 'auto',
        borderColor: '#c4c4da',
        borderWidth: 1,
        flexDirection: "column",
        marginHorizontal: 4
    },

    ribbon: {
        // padding: 15,
        // height: 20,
        backgroundColor: 'rgb(207, 152, 49)',
        color: '#dddeda',
        fontSize: 15,
        textAlign: "center"
    }
});