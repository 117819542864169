import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItemMultipleCart, paceOrderValidation, productInventoryAction, removeItemInCart } from "../../../state/actions/shoppers/shoppercartActions";;
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import CartProductCard from "./CartProductCard";
import CartAccordionListItem from "../../../components/custom/accordion/cart-accordion";
export default function PartnerCart({ cart, item, address, setOpenAddressPopup, pindex, expanded, handleChange, wallet, navigation }) {
  const dispatch = useDispatch();
  const [productError, setProductError] = useState([]);
  const Logindetails = useSelector((state) => state.auth.user);
  const esiColor = useSelector(state => state.theme);

  const handleOnClickRemoveCard = (index) => (e, data) => {
    dispatch(removeItemInCart(Logindetails, data))
  };

  const productInventoryResponse = (status, response, cart, data, count) => {
    if (status) {
      if (response.Status === "Failed") {
        let newOrderProduct = {
          "Product_Id": response?.Product_Id,
          "Partner_Detail_Id": response?.Partner_Details_Id,
          "Count": response?.Count,
          "Size": response?.Size,
          Status: true,
          Message: response?.Message,
          "Partner_Product_Id": response?.Partner_Product_Id,
        };
        
        let newProductError = JSON.parse(JSON.stringify(productError));
        let productExist = true;
        for (let i = 0; i < newProductError.length; i++) {
          if (newOrderProduct.Product_Id === newProductError[i].Product_Id) {
            newProductError[i] = newOrderProduct;
            setProductError(newProductError);
            productExist = false;
            break;
          }
        }
        if (productExist) {
          newProductError.push(newOrderProduct);
          setProductError(newProductError);
        }

      }
      else {
        dispatch(addItemMultipleCart(cart, data, count));
      }

    }
  };
  const checkInventoryResponse = (status, response, cart, data, count) => {
    if (status) {
      if (response.Status === "Failed") {
        let newOrderProduct = {
          "Product_Id": response?.Product_Id,
          "Partner_Detail_Id": response?.Partner_Details_Id,
          "Count": response?.Count,
          "Size": response?.Size,
          Status: true,
          Message: response?.Message,
          "Partner_Product_Id": response?.Partner_Product_Id,
        };
        let newProductError = JSON.parse(JSON.stringify(productError));
        let productExist = true;
        for (let i = 0; i < newProductError.length; i++) {
          if (newOrderProduct.Product_Id === newProductError[i].Product_Id) {
            newProductError[i] = newOrderProduct;
            setProductError(newProductError);
            productExist = false;
            break;
          }
        }
        if (productExist) {
          newProductError.push(newOrderProduct);
          setProductError(newProductError);
        }

      }
      else {
        dispatch(paceOrderValidation(cart, item, addressCheckResponse, productError, Logindetails));
      }

    }
  };

  const handleOnChangeCount = (index: any) => (e: any, data: any, cart: any, minQTY: any) => {
    let count = e >= minQTY ? e : minQTY
    let request = {
      "Count": Number(count),
      "Partner_Detail_Id": data?.partner.partnerProduct?.Partner_Details_Id,
      "Product_Id": data?.partner.partnerProduct?.Product_Id,
      "Size": data?.partner.size,
      "Partner_Product_Id": data?.partner?.partnerProductId,
    }

    let newProductError = JSON.parse(JSON.stringify(productError));
    for (let i = 0; i < newProductError?.length; i++) {
      if (request.Product_Id === newProductError[i]?.Product_Id) {
        newProductError.splice(i, 1);
        setProductError(newProductError);
        break;
      }
    }
    dispatch(productInventoryAction(request, productInventoryResponse, cart, data, Number(count)));
    dispatch(addItemMultipleCart(cart, data, Number(count)));
  };

  const handleOnClickCountIncrement = (index: any) => (e: any, count: any, data: any, cart: any) => {
    let request = {
      "Count": Number(count) + 1,
      "Partner_Detail_Id": data?.partner.partnerProduct?.Partner_Details_Id,
      "Product_Id": data?.partner.partnerProduct?.Product_Id,
      "Size": data?.partner.size,
      "Partner_Product_Id": data?.partner?.partnerProductId,
    }
    dispatch(addItemMultipleCart(cart, data, Number(count) + 1));
    dispatch(productInventoryAction(request, productInventoryResponse, cart, data, Number(count) + 1));

  };

  const handleOnClickCountDecrement = (index: any) => (e: any, count: any, data: any, cart: any) => {
    let request = {
      "Count": Number(count) - 1,
      "Partner_Detail_Id": data?.partner.partnerProduct?.Partner_Details_Id,
      "Product_Id": data?.partner.partnerProduct?.Product_Id,
      "Size": data?.partner.size,
      "Partner_Product_Id": data?.partner?.partnerProductId,
    }
    dispatch(addItemMultipleCart(cart, data, Number(count) - 1));
    dispatch(productInventoryAction(request, productInventoryResponse, cart, data, Number(count) - 1));
  };



  const getPartnerOrderPrice = (items: any) => {
    let amount = 0;
    let discount
    for (let i = 0; i < items.products?.length; i++) {
      for (let j = 0; j < items.products[i].Discount?.length; j++) {
        if (Number(items.products[i].Discount[j].Min_Quantity) <= Number(items.products[i].itemCount) && Number(items.products[i].itemCount) <= Number(items.products[i].Discount[j].Max_Quantity)) {
          amount = amount + (Number(items.products[i].Discount[j].Discounted_Price_Per_Quantity) * Number(items.products[i].itemCount))
          discount = Number(items.products[i].Discount[j].Discount_In_Percentage)
        } else if (j === items.products[i].Discount.length - 1 && Number(items.products[i].itemCount) > Number(items.products[i].Discount[j].Max_Quantity)) {
          amount = amount + (Number(items.products[i].Discount[j].Discounted_Price_Per_Quantity) * Number(items.products[i].itemCount))
          discount = Number(items.products[i].Discount[j].Discount_in_Percentage)
        }
      }
    }
    let data = amount.toFixed(2)
    return { data, discount };
  };

  const getPartnerOrderTotalPrice = (items: any) => {
    let amount = 0;
    return amount.toFixed(2);
  };



  const getPartnerOrderDeliveryPrice = (items) => {
    let amount = 0;
    return amount.toFixed(2);
  };

  const getPartnerOrderCouponDiscountPrice = (items) => {
    let coupon = items.coupon;
    let amount = 0;
    let productsAvailable = false;
    let products = items.products.filter(pro => {
      let found = false;
      return found;
    });
    products.map(product => (
      amount = amount + (Number(product?.product?.Original_Price) * Number(product?.itemCount))
    ));
    if (products.length > 0) {
      productsAvailable = true;
    }
    if (productsAvailable) {
      let response = {
        discountValid: coupon.Discount_Type ? true : false,
        discount: coupon.Discount_Type === "P" ? true : false,
        esiDiscountValid: coupon.ESI_Cashback_Type ? true : false,
        esiDiscount: coupon.ESI_Cashback_Type === "P" ? true : false,
        amount: 0,
        esiAmount: 0,
        valid: true,
        validResponse: "*COUPON APPLIED SUCCESFULLY"
      };
      let discountAmount = 0;
      if (response.discountValid) {
        if (response.discount) {
          discountAmount = (amount / 100) * Number(coupon?.Discount_Amount);
        } else {
          if (Number(coupon?.Discount_Amount) > amount) {
            discountAmount = amount;
          } else {
            discountAmount = coupon?.Discount_Amount;
          }
        }
      }
      let esiDiscountAmount = 0;
      if (response.esiDiscountValid) {
        if (response.esiDiscount) {
          esiDiscountAmount = (amount / 100) * Number(coupon?.ESI_Cashback_Amount);
        } else {
          if (Number(coupon?.ESI_Cashback_Amount) > amount) {
            esiDiscountAmount = amount;
          } else {
            esiDiscountAmount = coupon?.ESI_Cashback_Amount;
          }
        }
      }
      response.amount = discountAmount.toFixed(2);
      response.esiAmount = esiDiscountAmount.toFixed(2);
      return response;
    } else {
      return {
        amount: (0).toFixed(2),
        esiAmount: (0).toFixed(2),
        valid: true,
        validResponse: "*COUPON APPLIED SUCCESFULLY"
      };
    }

  };

  const getPartnerGiftPackPrice = (items) => {
    let amount = 0;
    items.products.map(product => {
      if (product.isGiftPack) {
        let amount2 = product?.partnerProduct?.Is_Gift_Wrap_Available === "1" ? Number(product.partnerProduct.Is_Gift_Wrap_Amount ? product.partnerProduct.Is_Gift_Wrap_Amount : 0) : 0;
        amount = amount + amount2;
      }
    });
    return amount.toFixed(2);
  };

  const giftAvailableAmount = (items) => {
    return (items?.gift?.Total_Value ? items?.gift?.Total_Value : 0).toFixed(2);
  };

  const getPartnerGiftDiscountPrice = (items) => {
    let coupon = getPartnerOrderCouponDiscountPrice(items);
    let delivery = Number(getPartnerOrderDeliveryPrice(items));
    let giftPackAmount = Number(getPartnerGiftPackPrice(items));
    let totalBeforeGift = Number(getPartnerOrderPrice(items)) + delivery + giftPackAmount - (coupon.valid ? coupon.percentage ? 0 : coupon.amount : 0);
    return (totalBeforeGift < Number(giftAvailableAmount(items)) ? totalBeforeGift : Number(giftAvailableAmount(items))).toFixed(2);
  };

  const getPartnerWalletDiscountPrice = (items) => {
    let giftUseAmont = Number(getPartnerGiftDiscountPrice(items));
    let coupon = getPartnerOrderCouponDiscountPrice(items);
    let delivery = Number(getPartnerOrderDeliveryPrice(items));
    let giftPackAmount = Number(getPartnerGiftPackPrice(items));
    let totalBeforeWallet = Number(getPartnerOrderPrice(items)) + delivery + giftPackAmount - (coupon.valid ? coupon.percentage ? 0 : coupon.amount : 0) - giftUseAmont;
    return (totalBeforeWallet < Number(wallet?.Remaining_Balance) ? totalBeforeWallet : Number(wallet?.Remaining_Balance)).toFixed(2);
  };

  const getPartnerOrderFinalPrice = (items) => {
    let coupon = getPartnerOrderCouponDiscountPrice(items);
    let delivery = Number(getPartnerOrderDeliveryPrice(items));
    let giftPackAmount = Number(getPartnerGiftPackPrice(items));
    return (Number(getPartnerOrderPrice(items).data) + delivery + giftPackAmount - Number(getPartnerGiftDiscountPrice(items)) - (coupon.valid ? coupon.percentage ? 0 : coupon.amount : 0)).toFixed(2);
  };
  const addressCheckResponse = (noError) => {
        if (noError) {
      setProductError([]);
      navigation.navigate('CartSummary', { cart: item.partnerId });
    } else {
    }
  };


  const handleSubmit = (partnerId) => {
        let request = {
      "Partner_Detail_Id": partnerId?.partnerId,
      "Count": partnerId.products[0].itemCount,
      "Product_Id": partnerId?.products[0]?.Product?.Product_Id,
      "Size": partnerId?.products[0]?.size,
      "Partner_Product_Id": partnerId?.products[0]?.partnerProductId,
    }

    dispatch(productInventoryAction(request, checkInventoryResponse, cart, partnerId, partnerId.products.itemCount));
  };

  let addresslist = []
  if (address) {
    if (address[0]) {
      for (let i = 0; i < address.length; i++) {
        addresslist.push({
          label: address[i].Address_Type + " " + "," + " " + address[i].Door_No + " " + "," + " " + address[i].Land_Mark + " " + "," + " " + address[i].Street + " " + "," + " " + address[i].City + " " + "," + " " + address[i].State + " " + "," + " " + address[i].Zip,
          value: address[i].User_Address_Id
        })
      }
    }
  }
  const accordionExpend = (expend, panelName) => {
    if (expend) {
      if (expend === true) {
        return true;
      } else {
        return panelName === expanded;
      }
    }
    return false;
  }
  return (
    <ScrollView>
      {item &&
        <View style={{ backgroundColor: esiColor.BackgroundColor, borderRadius: 7, paddingHorizontal: 20, shadowColor: esiColor.brandShadowColor, shadowOpacity: 7, shadowRadius: 7, borderColor: esiColor.SBorderColor, borderWidth: 0.3 }}>
        <CartAccordionListItem style={{ marginTop: "10px" }} key={item?.partnerId} item={item}
            getPartnerOrderFinalPrice={getPartnerOrderFinalPrice}
            expanded={accordionExpend(expanded, "panel" + pindex)} onChange={handleChange("panel" + pindex)}>
            {item.products.map((data, index) => (
              <CartProductCard
                key={'prodcart' + index}
                address={address}
                setOpenAddressPopup={setOpenAddressPopup}
                index={index}
                data={data.Product}
                partner={data}
                count={data.itemCount}
                Logindetails={Logindetails}
                handleOnChangeCount={handleOnChangeCount()}
                handleOnClickCountIncrement={handleOnClickCountIncrement()}
                handleOnClickCountDecrement={handleOnClickCountDecrement()}
                handleOnClickRemoveCard={handleOnClickRemoveCard()}
                completeProduct={data}
                cart={cart}
                productError={productError}
                Min={data.Min}
                discounts={getPartnerOrderPrice(item)}

              />
            ))}

            <View style={{ flexDirection: "row", justifyContent: 'center', backgroundColor: esiColor.BackgroundColor }}>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: esiColor.globalButtonColor,
                  padding: 10,
                  margin: '10%',
                  borderRadius: 8
                }}
                onPress={() => handleSubmit(item)}
              >
                <Text style={{ color: esiColor.itemButtenColor }} >Place Order</Text>
              </TouchableOpacity>
            </View>

          </CartAccordionListItem>
        </View>
      }
    </ScrollView>
  );
}
const styles = StyleSheet.create({

  cart_icon: {
    borderRadius: 50, right: 20, position: 'relative'
  },
  cart_count: {
    position: 'absolute',
    right: 60,
    marginTop: -10,
    backgroundColor: 'blue',
    borderRadius: 50,
    paddingHorizontal: 5,
    justifyContent: 'center',
  },
  label: {
    marginBottom: 30,
  },
  containerone: {
    backgroundColor: 'white',
    paddingTop: 15
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    color: 'black',
    left: 22,
    top: 10,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  dropdown: {
    height: 58,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  centerElement: { justifyContent: 'center', alignItems: 'center' },
});
