import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Text, Button, HelperText, Surface, RadioButton, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView } from 'react-native';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import { createPartnerShopByLocationAction, getAllLocationsAction, getPartnerAddressesAction } from '../../../state/actions/partnershopbylocationAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//StatusOption used to get the data from dropdown
const Addpartnershopbylocation = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();
    //toast for popup after successful API calls.
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const isSubmited = useSelector(state => state.shopbycitylocation.shoplocationCreate.isLoading);
    const partneraddress = useSelector(state => state.shopbycitylocation.address.address);
    const partnerlocation = useSelector(state => state.shopbycitylocation.location.location);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);

    const [errorMessage, setErrorMessage] = useState({ global: { key: '', message: '' } });
    //partnercityproductadd contains the data from Get page(Partner City Product Get) using props
    const partnercityid = props.route?.params?.partnercityid;
    const partnerid = props.route?.params?.partnerid;
    const partnercityname = props.route?.params?.partnercityname;
    const partnername = props.route?.params?.partnername;
    const cityid = props.route?.params?.cityid;
    //Dropdowns
    const [statusDropDown, setStatusDropDown] = useState("");
    const [productsDropDown, setProductsDropDown] = useState("");
    React.useEffect(() => {
        let formData =
        {
            "City_ID": cityid,
            "Records_Filter": "FILTER",
        };
        dispatch(getAllLocationsAction(formData));
    }, [cityid]);
    useEffect(() => {
        if (partnerid) {
            let formData =
            {
                "City_ID": cityid,
                "Records_Filter": "FILTER",
            };

            let filter = {
                Partner_Details_Id: partnerid,
                Records_Filter: "FILTER"
            }
            dispatch(getAllLocationsAction(formData));
            dispatch(getPartnerAddressesAction(filter));
        }
    }, [partnerid]);

    let partneraddresslist = []
    if (partneraddress) {
        if (partneraddress.length > 0) {
            for (let i = 0; i < partneraddress.length; i++) {
                partneraddresslist.push({
                    label: partneraddress[i].Location.substring(0, 60),
                    value: partneraddress[i].Partner_Address_Id,
                    Pickup_Location: partneraddress[i].Google_Map_Location,
                })
            }
        }
        else {
            partneraddresslist.push({
                label: 'No Address found!',
                value: null,

            })
        }
    }


    let partnerlocationlist = []
    if (partnerlocation) {
        if (partnerlocation.length > 0) {
            for (let i = 0; i < partnerlocation.length; i++) {
                partnerlocationlist.push({
                    label: partnerlocation[i].Location_Name,
                    value: partnerlocation[i].Location_Id,
                })
            }
        }
        else {
            partnerlocationlist.push({
                label: 'No Locations found!',
                value: null,

            })
        }
    }

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //form reset
    const [reload, setReload] = React.useState(false);
    React.useEffect(() => {
        if (reload)
            setReload(false);
    }, [reload])
    /*====================================
            Handler
    ====================================*/

    const values = {
        "City_Id": cityid,
        "Created_By": EmailID.Email_Id,
        "Partner_Detail_Id": partnerid,
        "Partner_Address_Id": " ",
        "Location_Id": " ",
        "Is_Active": false,
        "Is_Managed_By_Meepaisa": false,
        "Is_Partner_Delivery_Available": false,
        "Is_Pickup_Available": false
    }

    //Form validation
    const Form_Validation = Yup.object().shape({
        Partner_Address_Id: Yup.string().required('Partner Address ID is required.'),
        Location_Id: Yup.string().required('Location ID is required.'),
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Is_Active) {
                formValues.Is_Active = 1;
            } else {
                formValues.Is_Active = 0;
            }
            if (values.Is_Managed_By_Meepaisa) {
                formValues.Is_Managed_By_Meepaisa = 1;
            } else {
                formValues.Is_Managed_By_Meepaisa = 0;
            }
            if (values.Is_Partner_Delivery_Available) {
                formValues.Is_Partner_Delivery_Available = 1;
            } else {
                formValues.Is_Partner_Delivery_Available = 0;
            }
            if (values.Is_Pickup_Available) {
                formValues.Is_Pickup_Available = 1;
            } else {
                formValues.Is_Pickup_Available = 0;
            }
            dispatch(createPartnerShopByLocationAction(formValues, navigation, toast, partnercityid, partnerid, partnercityname, partnername, setErrorMessage, setErrors));
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 146, justifyContent: 'flex-start' }}
                                    onPress={() => {
                                        props.resetForm();
                                        setReload(true);
                                        navigation.navigate('partnershopbylocationget', { partnercityid: partnercityid, partnerid: partnerid, partnercityname: partnercityname, partnername: partnername })
                                    }}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                        Add Shop by City Location
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                            {/* Product Name input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Product_Id && props.errors.Product_Id) && { color: '#b00020' }]}>
                                                Partner Address
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Partner_Address_Id && props.errors.Partner_Address_Id) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={partneraddresslist}
                                                maxHeight={partneraddresslist.length > 1 ? 250 : 200}
                                                search={partneraddresslist.length > 1 ? true : false}
                                                labelField="label"
                                                valueField="value"
                                                searchPlaceholder={'Select Partner Address'}
                                                dropdownPosition="auto"
                                                value={props.values.Partner_Address_Id}
                                                name="Partner_Address_Id"
                                                onFocus={() => setProductsDropDown(true)}
                                                onBlur={() => setProductsDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Partner_Address_Id', item.value)
                                                    setProductsDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Partner_Address_Id && props.errors.Partner_Address_Id)}>
                                                <ErrorMessage name="Partner_Address_Id" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                            {/* Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Location_Id && props.errors.Location_Id) && { color: '#b00020' }]}>
                                                Partner Location
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Location_Id && props.errors.Location_Id) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={partnerlocationlist}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={partnerlocationlist.length > 1 ? 250 : 200}
                                                search={partnerlocationlist.length > 1 ? true : false}
                                                searchPlaceholder={'Select Partner Location'}
                                                dropdownPosition="auto"
                                                value={props.values.Location_Id}
                                                name="Location_Id"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Location_Id', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Location_Id && props.errors.Location_Id)}>
                                                <ErrorMessage name="Location_Id" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ width: "80%", margin: "auto" }}>
                                        <View style={{
                                            flexDirection: "row",
                                        }}>
                                            <RadioButton
                                                color={esiColor.itemColor}
                                                uncheckedColor={esiColor.SBorderColor}
                                                value={props.values.Is_Active}
                                                name="Is_Active"
                                                status={props.values.Is_Active ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue('Is_Active', !props.values.Is_Active)
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, color: Boolean(props.touched.Is_Active && props.errors.Is_Active) ? "#b00020" : esiColor.Text }}>Is Active</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "80%", margin: "auto" }}>
                                        <View style={{
                                            flexDirection: "row",
                                        }}>
                                            <RadioButton
                                                color={esiColor.itemColor}
                                                uncheckedColor={esiColor.SBorderColor}
                                                value={props.values.Is_Managed_By_Meepaisa}
                                                name="Is_Managed_By_Meepaisa"
                                                status={props.values.Is_Managed_By_Meepaisa ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue('Is_Managed_By_Meepaisa', !props.values.Is_Managed_By_Meepaisa)
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, color: Boolean(props.touched.Is_Managed_By_Meepaisa && props.errors.Is_Managed_By_Meepaisa) ? "#b00020" : esiColor.Text }}>Is Managed By Meepaisa</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "80%", margin: "auto" }}>
                                        <View style={{
                                            flexDirection: "row",
                                        }}>
                                            <RadioButton
                                                color={esiColor.itemColor}
                                                uncheckedColor={esiColor.SBorderColor}
                                                value={props.values.Is_Partner_Delivery_Available}
                                                name="Is_Partner_Delivery_Available"
                                                status={props.values.Is_Partner_Delivery_Available ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue('Is_Partner_Delivery_Available', !props.values.Is_Partner_Delivery_Available)
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, color: Boolean(props.touched.Is_Partner_Delivery_Available && props.errors.Is_Partner_Delivery_Available) ? "#b00020" : esiColor.Text }}>Is Partner Delivery Available</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "80%", margin: "auto" }}>
                                        <View style={{
                                            flexDirection: "row",
                                        }}>
                                            <RadioButton
                                                color={esiColor.itemColor}
                                                uncheckedColor={esiColor.SBorderColor}
                                                value={props.values.Is_Pickup_Available}
                                                name="Is_Pickup_Available"
                                                status={props.values.Is_Pickup_Available ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue('Is_Pickup_Available', !props.values.Is_Pickup_Available)
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, color: Boolean(props.touched.Is_Pickup_Available && props.errors.Is_Pickup_Available) ? "#b00020" : esiColor.Text }}>Is Pickup Available</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginHorizontal: 30 }}>
                                        <Text style={{ color: "red" }}>{errorMessage?.Response_Message?.message}</Text>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default Addpartnershopbylocation;