import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'react-native-elements';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Dimensions, Image, Platform, TouchableOpacity, View } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../state/validations/is-empty';
import { partnermaincenterDateandCount } from '../../state/actions/dynamicCountActions';

const ProductCard = ({ product, esiColor, navigation, ...rest }) => {
  const dispatch = useDispatch();
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const dynamicvalue = useSelector(state => state.corporatedateandcount.partnermaincenterdateandcounts.all);
  const isloading = useSelector(state => state.corporatedateandcount.partnermaincenterdateandcounts.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  useEffect(() => {

    if (product.title === "Brand Management") {
      product.total = dynamicvalue?.Brand?.Brand_Count;
      product.createdAt = dynamicvalue?.Brand?.Brand_Date;
    } else if (product.title === "Address Management") {
      product.total = dynamicvalue?.partner_address?.partner_address_Count;
      product.createdAt = dynamicvalue?.partner_address?.partner_address_Date;
    } else if (product.title === "Product Management") {
      product.total = parseInt(dynamicvalue?.product_master?.product_master_Count) + parseInt(dynamicvalue?.products?.products_Count)
      product.createdAt = dynamicvalue?.product_master?.product_master_Date;
    } else if (product.title === "Inventory Management") {
      product.total = dynamicvalue?.inventory?.inventory_Count;
      product.createdAt = dynamicvalue?.inventory?.inventory_Date;
    } else if (product.title === "Coupon Management") {
      product.total = dynamicvalue?.coupons?.coupons_Count;
      product.createdAt = dynamicvalue?.coupons?.coupons_Date;
    } else if (product.title === "Deal Management") {
      product.total = dynamicvalue?.deals?.deals_Count;
      product.createdAt = dynamicvalue?.deals?.deals_Date;
    } else if (product.title === "Shop By City") {
      product.total = dynamicvalue?.partner_service_city?.partner_service_city_Count;
      product.createdAt = dynamicvalue?.partner_service_city?.partner_service_city_Date;
    } else if (product.title === "Gift Management") {
      product.total = dynamicvalue?.gift?.gift_Count;
      product.createdAt = dynamicvalue?.gift?.gift_Date;
    }

  }, [dynamicvalue]);


  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  return (
    <View style={{ flexDirection: 'column', flex: 1, flexWrap: 'wrap', padding: 5 }}>
      <Card style={{
        borderRadius: 10, flex: 1, padding: 8, backgroundColor: esiColor.CBColor, margin: 10, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
        shadowOffset: { width: 1, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, elevation: 5, height: 370, maxHeight: 500,
        maxWidth: 350, width: Platform.OS === 'ios' ? dimensions <= 520 ? 370 : 370 : 160 && Platform.OS === 'web' ? dimensions <= 520 ? 300 : 350 : 160 && Platform.OS === 'android' ? 330 : 350,
      }}>
        <Card.Content>
          <View style={{ alignItems: 'center', justifyContent: "center", margin: 5, height: 270, flexWrap: 'wrap' }}>
            <TouchableOpacity onPress={() => { navigation.navigate(product.link) }}>
              <View style={{ margin: 10, alignSelf: 'center', flexWrap: 'wrap', height: 58 }}>
                <Image style={{ width: 58, height: 58, resizeMode: 'contain' }} source={product.media} />
              </View>
              <View style={{ justifyContent: 'center', margin: 10, height: 25 }}>
                <Text style={{ fontWeight: 'bold', fontSize: 20, color: esiColor.brandFontColor }}>{product.title}</Text>
              </View>
            </TouchableOpacity>
            <View style={{ height: 130 }}>
              <Text style={{ color: esiColor?.Text }}>{product.description}</Text>
            </View>
          </View>
        </Card.Content>
        <Divider orientation="vertical" width={5} color={esiColor.SBorderColor} />
        <View style={{ flexDirection: 'row', margin: 10, flex: 1, flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column' }}>
            <MaterialCommunityIcons name="clock-time-four-outline" size={24} color={esiColor.SIconColor} />
          </View >
          <View style={{ flexDirection: 'column', margin: 5 }}>
            <Text style={{ color: esiColor?.Text }}>  {product.createdAt}</Text>
          </View>
          <View style={{ flexDirection: 'column', flex: 1, marginVertical: 6, alignItems: 'flex-end' }}>
            <Text style={{ color: esiColor?.Text }}>{product.total}</Text>
          </View>
        </View>
      </Card>
    </View>
  );
};
ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};
export default ProductCard;