import AsyncStorage from '@react-native-async-storage/async-storage';
import * as React from 'react';
import { View, Text, ScrollView, Dimensions, StyleSheet, RefreshControl } from 'react-native';
import { Card, Surface, Button, DefaultTheme } from 'react-native-paper';
import { useSelector } from 'react-redux';
import Header from '../../auth/components/header';

const ViewShipping = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const esiColor = useSelector(state => state.theme);
    //shipping is used to store the values from previous page using props
    const shipping = props.route?.params?.data;

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
            }
        );
    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };

    const styles = StyleSheet.create({
        mainview: {
            flexDirection: "row", justifyContent: 'space-between', flexWrap: 'wrap', paddingTop: 15
        },
        text: {
            fontWeight: "bold", fontSize: 13, color: esiColor.Text
        }
    })


    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 150 }} onPress={() => navigation.navigate('ShippingGet')}>
                        Go Back
                    </Button>
                </View>
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Card style={{
                        width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                        marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                        backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                    }}>
                        <Card.Content>
                            <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                View Shipping Details
                            </Text>

                            <View style={{ margin: "2%" }}>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Shipping ID</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{shipping?.Shipping_Id}</Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Shipping Status</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: shipping.Shipping_Status === "DELIVERED" ? esiColor.GBFColor : shipping.Shipping_Status === "NEW" ? esiColor.itemColor : shipping.Shipping_Status === "PACKED" ? esiColor.itemColor : shipping.Shipping_Status === "ACCEPTED" ? "orange" : shipping.Shipping_Status === "PICK-UP" ? esiColor.GBFColor : esiColor.itemColor }}>{shipping?.Shipping_Status} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Order ID</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{shipping?.Order_Id} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Order Status</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{shipping?.Order_Status} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Partner Details ID</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{shipping?.Partner_Details_Id} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Shipping Address ID</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{shipping?.Shipping_Address_Id} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Shipping Channel Partner</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{shipping?.Shipping_Channel_Partner} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Shipping Reference ID</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{shipping?.Shipping_Reference_Id} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Shipment Net Price</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{shipping?.Shipment_Net_price} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Shipping Gross Price</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{shipping?.Shipping_Gross_Price} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Item Master ID</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{shipping?.Item_Master_Id} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Item Gross Price</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{shipping?.Item_Gross_Price} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Item Net Price</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{shipping?.Item_Net_Price} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Created By</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{shipping?.Created_By} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Modified By</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{shipping?.Modified_By} </Text></View>
                                </View>
                            </View>

                        </Card.Content>
                    </Card>
                </View>
            </ScrollView>
        </Surface>
    )
}

export default ViewShipping;