import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';
export const ALL_B2C_ORDERS_REQUEST = "ALL_B2C_ORDERS_REQUEST";
export const ALL_B2C_ORDERS_SUCCESS = "ALL_B2C_ORDERS_SUCCESS";
export const ALL_B2C_ORDERS_FAIL = "ALL_B2C_ORDERS_FAIL";
export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAIL = "ORDER_FAIL";
export const SINGLE_ORDER_REQUEST = "SINGLE_ORDER_REQUEST";
export const SINGLE_ORDER_SUCCESS = "SINGLE_ORDER_SUCCESS";
export const SINGLE_ORDER_FAIL = "SINGLE_ORDER_FAIL";

export const BRAND_GET_REQUEST = "BRAND_GET_REQUEST";
export const BRAND_GET_SUCCESS = "BRAND_GET_SUCCESS";
export const BRAND_GET_FAIL = "BRAND_GET_FAIL";
export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL";
export const ORDERS_FILTER = "ORDERS_FILTER";
export const RESET = "RESET";
const nodeheaders = shopperconfig.nodeheadersCommon;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const headers = config.headersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
      queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
export const invalid_data = () => {
  return {
    type: RESET
  }
}
/*=============================================================
                  Get All Orders Action
===============================================================*/
export const getAllB2COrdersAction = (formData: any,pagination = {}) => async (dispatch: any) => {
    try {
    dispatch({
      type: ALL_B2C_ORDERS_REQUEST
    });
    meeapi.post(urlGenarator(`/Order_Management/Order_GetFlow`,pagination),
      formData,
    ) .then(response => {
      let data = response.data;  
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_B2C_ORDERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ALL_B2C_ORDERS_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {  
    dispatch({
      type: ALL_B2C_ORDERS_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                Update Order Action
===============================================================*/
export const updateOrderAction = (formData: any, allorder: any, navigation: any,setErrorMessage) => async dispatch => {
  dispatch({
    type: ORDER_UPDATE_REQUEST
  });
  axios.post(`${config.url}/Order_Status_Tracking/Create`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: ORDER_UPDATE_SUCCESS
        });
        navigation.navigate('OrderDetails')
        const pagination={
          PageNo: 1, PageSize: 5, SortBy: "ORDER_ID", SortOrder: "DESC" 
        }
        const getformData = {
          "search_by_filter": "",
            "search": "",
            "Is_Approved": "",
            "Order_Id": "",
            "Order_Status": "",
            "Partner_Detail_ID": allorder,
            "User_Email_Id": "",
            "Records_Filter": "FILTERS"
        };
        dispatch(getAllB2COrdersAction(getformData, pagination));

      } else {
        const errors = {};
        dispatch({
          type: ORDER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ORDER_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};



export const OrdersFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: ORDERS_FILTER,
      fType: fType,
      value: value,
    });
  }
}

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: ORDERS_FILTER,
      fType: "search",
      value: "",
    });
  }
}

export const getAddressIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Address/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};
export const getOrdersIdAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: ORDER_REQUEST
    });
    let { data, status } = await axios.post(
      `${config.url}/Order_Management/Cancel_Order?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ORDER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ORDER_SUCCESS,
          payload: data.Orders?.Order_Data
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ORDER_FAIL,
      payload: err
    });
  }
};
export const getsingleorderAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: SINGLE_ORDER_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Order_Management/Order_GetFlow`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SINGLE_ORDER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SINGLE_ORDER_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SINGLE_ORDER_FAIL,
      payload: err
    });
  }
};

export const InvoiceAction = (formData: any, updateStates: any) => async (dispatch) => {
  axios.post(`${shopperconfig.nodePartnerUrl}/B2C_Invoice_Generation/Invoice_Generation`, formData, {
    headers: nodeheaders
  })
      .then(response => {
          let successResponse = response.data.B2C_Invoivce_Response;
        
          updateStates(true, successResponse);
      })
      .catch(error => {
 updateStates(false, null);
      })
};