import React, { useEffect, useState } from 'react';
import { Badge, Searchbar, Surface, Text } from 'react-native-paper';
import Header from '../auth/components/header';
import { getBiddingProductsCart, getbiddingProductsAction } from '../../state/actions/bidding/biddingproductActions';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollView } from 'react-native-gesture-handler';
import { Dimensions, Image, Platform, RefreshControl, StyleSheet, TouchableOpacity, View } from 'react-native';
import isEmpty from '../../state/validations/is-empty';
import { useRoute } from '@react-navigation/native';
import { FontAwesome5 } from '@expo/vector-icons';
// import esiColor from '../../constants/Colors';

export default function Biddingproducts(props: any) {
    const { navigation } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Partner_Details_Id);
    const [dimensions, setDimensions] = useState(Dimensions.get("window"));
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
        return () => subscription?.remove();
    });
    const { height, width } = dimensions;
    const esiColor = useSelector(state => state.theme);
    const [ordersFilter, setOrdersFilter] = React.useState('');
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 70, SortBy: "B2B_USER_PRODUCTS_BIDDING_ID", SortOrder: "DESC" });
    const [loading, setIsLoading] = React.useState(false);
    const [myBids, setMyBids] = React.useState([]);
    const [myCart, setMyCarts] = React.useState([]);
    const dispatch = useDispatch();
    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setMyBids(data);
        }
    }
    const callBackCartData = async (status, data) => {
        setIsLoading(false);
        if (status && data?.Success_Response.Is_Data_Exist == '1') {
            setMyCarts(data?.results);
        } else {
            setMyCarts([]);
        }
    }

    // API call for bidding products
    const getMyBids = () => {
        setIsLoading(true);
        let formData = {
            "search_by_filter": "",
            "search": ordersFilter,
            "Buyer_Partner_Detail_Id": logedInEmail,
            "Records_Filter": "FILTER"
        };

        dispatch(getbiddingProductsAction(formData, pagination, callBackGetData))
    }

    const route = useRoute();

    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            getMyBids();
        } else {
            navigation.navigate("Biddingproducts");
        }
    }, [logedInEmail, ordersFilter, route, navigation]);
    const handleSearch = itemValue => {
        setOrdersFilter(itemValue);
        let formData = {
            "search_by_filter": "",
            "search": itemValue,
            "Buyer_Partner_Detail_Id": logedInEmail,
            "Records_Filter": "FILTER"

        };
        dispatch(getbiddingProductsAction(formData, pagination, callBackGetData))
    };
    useEffect(() => {

        dispatch(getBiddingProductsCart({
            Buyer_Partner_Detail_Id: logedInEmail
        }, callBackCartData))
    }, []);

    const styles = StyleSheet.create({
        separator: {
            height: 1,
            backgroundColor: "#CCCCCC",
        },
        dropdown: {
            height: 30,
            maxWidth: 150,
            borderColor: '#27b6cc',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        placeholderStyle: {
            fontSize: 16,
        },
        selectedTextStyle: {
            fontSize: 16,
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            maxWidth: 200,
        },
        text: {
            fontSize: 12,
            fontFamily: 'system-ui',
            color: esiColor.itemColor

        }
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ marginBottom: (Platform.OS === 'web' ? 10 : 100), marginRight: 10, marginLeft: 10 }}>
                <View style={{
                    flexDirection: "row", paddingTop: 5, marginBottom: 5
                }}>

                    <View style={{ flex: 4 }}>
                    <Searchbar
                        style={{
                            borderRadius: 5, borderWidth: 0.5, maxWidth: 400, height: 50,
                            borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor
                        }}
                        placeholder="Search"
                        iconColor={esiColor.SIconColor}
                        placeholderTextColor={esiColor.SPHTextColor}
                        theme={{ colors: { text: esiColor.SBTextColor } }}
                        onChangeText={(value) => handleSearch(value)}
                        value={ordersFilter}
                    />
                    </View>

                    <View style={{ flex: 3, alignItems: "center", paddingTop: 5 }}>
                        {myCart?.length >= 0 &&
                            <TouchableOpacity onPress={(e) => { navigation.navigate("CartOfBids"); }} style={{ flex: 3, alignItems: "center", paddingTop: 5 }} >
                                <FontAwesome5 name={"shopping-cart"} size={20} color={esiColor.esiMainColor} />
                                <Badge style={{
                                    position: 'absolute',
                                    color: "#27b6cc",
                                    fontWeight: 'bold',
                                    bottom: 12,
                                    left: 18,
                                    backgroundColor: 'white'
                                }} >{myCart?.length}</Badge>
                            </TouchableOpacity>}
                    </View>
                    <View style={{ flex: 3, alignItems: "flex-end" }}>
                        {/* {Platform.OS !== "web" ? */}
                        <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10, maxWidth: 150, height: 30, justifyContent: 'center', alignItems: 'center' }} onPress={(e) => {
                            navigation.navigate("NewBidProducts");
                        }}>
                            <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>New Bid</Text>
                        </TouchableOpacity>

                    </View>
                </View>
                <ScrollView style={{ height: height - ((height / 100) * (Platform.OS === 'web' ? 20 : 30)) }}
                    refreshControl={
                        <RefreshControl
                            refreshing={false}
                            onRefresh={() => {
                                getMyBids();
                            }}
                        />
                    }
                >
                    {myBids?.length <= 0 ?
                        <View style={{ alignItems: "center", marginTop: 10 }}>

                            <View>
                                {/* <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={ordergif}></Image> */}
                                <Text style={{ textAlign: "center", color: esiColor.NoDataFound }}>Oops! No Bids found...</Text>
                            </View>

                        </View>
                        :
                        <View>
                            {myBids?.map((data, index) => (
                                <View
                                    key={index}
                                    pointerEvents={data.Bidding_Status != 'CANCEL' ? "auto" : 'none'}
                                >
                                    <TouchableOpacity

                                        onPress={() => {
                                            navigation.navigate('BidProductDetails', {
                                                id: data?.b2b_User_Products_Bidding_Id,
                                                productid: data?.Bidding_Product_Id,
                                                sec: true,
                                            });
                                        }}
                                    >
                                        <View
                                            style={{
                                                shadowColor: esiColor.brandShadowColor,
                                                // shadowOffset: { width: -2, height: 4 },
                                                shadowOpacity: 7,
                                                shadowRadius: 5,
                                                marginHorizontal: 2,
                                                borderRadius: 10,
                                                padding: 2,
                                                backgroundColor: esiColor.BackgroundColor,
                                                marginTop: Platform.OS === 'web' ? 10 : 10,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                elevation: 10,
                                                maxWidth: Platform.OS === 'web' ? 650 : 550,
                                                minWidth: Platform.OS === 'web' ? '30%' : '95%',
                                                maxHeight: height > 250 ? 250 : 90,
                                                minHeight: 100,
                                                // borderColor: 'grey',
                                                // borderWidth: 0.5,

                                            }}
                                        >
                                            <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }}>
                                                <Image
                                                    source={{ cache: 'force-cache', uri: data?.Product_Image }}
                                                    style={{ height: 100, width: 80, resizeMode: 'contain' }}
                                                />
                                            </View>
                                            <View style={{ flex: 2, flexDirection: 'column' }}>
                                                <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>
                                                    {data?.Product_Name}
                                                </Text>
                                                <Text style={styles.text}>Bidding ID: {data.b2b_User_Products_Bidding_Id}</Text>
                                                <Text style={styles.text}>Status: {data.Bidding_Status ? (data.Bidding_Status === '' || data.Bidding_Status === 'New' ? 'New' : data.Bidding_Status) : 'New'}</Text>
                                                <Text style={styles.text}>Quantity: {data?.Quantity}</Text>
                                            </View>
                                            {data.Bidding_Status == 'CANCEL' && <View
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-end',
                                                    opacity: 0.2, // Adjust the opacity of the watermark
                                                    marginRight: 5
                                                }}
                                            >
                                                {/* Use Image or Text for watermark */}
                                                {/* <Image source={require('path/to/watermark-image.png')} style={{ width: '50%', height: '50%' }} /> */}
                                                <Text style={{ fontSize: 15, color: 'red', marginTop: 40 }}>CANCELLED</Text>
                                            </View>}
                                        </View>
                                        {/* Watermark View */}

                                    </TouchableOpacity>
                                </View>

                            ))}
                            <View style={{ marginBottom: 100 }} ></View>
                        </View>
                    }
                </ScrollView>
            </View>
        </Surface>
    )
}

