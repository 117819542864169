import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput, Text, HelperText, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, ScrollView, TouchableOpacity, Image } from 'react-native';
import Header from '../auth/components/header';
import { GetAllComments, AddTicketcommentAction, getTicketsByIdAction, getallTicketByIdAction } from '../../state/actions/ticketingActions';
import isEmpty from '../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';


function Comment(props: any) {
  const { navigation, valueUpdate, ticketid, ...rest } = props
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  const getid = props.route?.params?.ticketid;
  const user = useSelector(state => state.auth?.user);
  const userPic = useSelector(state => state.auth.user?.Profile_Pic_Path ? { uri: state.auth.user?.Profile_Pic_Path } : require("../../assets/images/img/logo/meePaisa_logo.png"));
  const [visible, setVisible] = useState(5);
  const isSubmited = useSelector(state => state.Ticket.CommentCreate.isLoading);

  const [reload, setReload] = useState(false);
  const [Comment, setComment] = useState(false);
  const [id, setID] = useState([]);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])

  const callBackGetData = (data) => {
    setReload(false);
    if (data) {
      setComment(data);
    }

  };
  React.useEffect(() => {
    if (getid) {
      const formData = {
        "search_by_filter": "",
        "search": "",
        "Comments": "",
        "Created_By": "",
        "Records_Filter": "FILTER",
        "Status": "Active",
        "Ticket_Comment_id": "",
        "Ticket_Id": getid
      };
      dispatch(getTicketsByIdAction(getid, callBackGetData));
    }
  }, [getid]);
  React.useEffect(() => {
    if (user.Email_Id) {
      let data = {

        "search_by_filter": "",
        "search": "",
        "Comments": "",
        "Created_By": "",
        "Records_Filter": "FILTER",
        "Status": "Active",
        "Ticket_Comment_id": "",
        "Ticket_Id": getid
      }
      dispatch(GetAllComments(data, callBackGetData))
    }
  }, [user.Email_Id]);
  let Ticket = useSelector((state) => state.Ticket.Comments.all);
  const callBackData = (data) => {
    setReload(false);
    if (data) {
      setID(data)
    }

  };
  React.useEffect(() => {
    if (getid) {
      const formData = {
        Records_Filter: "FILTER",
        Ticket_Id: getid
      };
      dispatch(getallTicketByIdAction(formData, callBackData));
    }
  }, [getid]);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };;

  const styles = StyleSheet.create({
    input: {
      borderColor: "gray",
      color: 'red',
      width: 350,
      borderWidth: 1,
      borderRadius: 10,
      padding: 5,
      marginBottom: 10
    },
    textbox: {
      marginBottom: 80,
    },
    containerm: {
      paddingLeft: 19,
      paddingRight: 16,
      paddingVertical: 12,
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginLeft: 1,
      borderRadius: 2,
      backgroundColor: esiColor.BackgroundColor,
      marginTop: 10
    },
    content: {
      marginLeft: 16,
      flex: 1,
    },
    contentHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: 2
    },
    image: {
      width: 45,
      height: 45,
      borderRadius: 20,
    },

  });

  /*====================================
          Handler
  ====================================*/

  return (

    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <View style={{ flexDirection: 'row', margin: 3 }}>
        <IconButton
          icon="arrow-left"
          size={27}
          color={"#27B6CC"}
          onPress={() => navigation.navigate('Ticketing')} />
        <Text style={{ fontSize: 20, fontWeight: "bold", color: '#27B6CC', marginTop: 15 }}>
          Comments
        </Text>
      </View>

      <ScrollView>
        <Formik initialValues={{

          Comments: "",
          Created_By: user.Email_Id,
          Ticket_Id: getid,
        }}
          //field validations
          validationSchema={Yup.object().shape({
            Comments: Yup.string().required('Comment  is required.'),
          })}
          onSubmit={(values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
            let submitTest = true;
            setSubmitting(false);
            const errors = {};
            setErrors(errors);
            if (values) {
              let formValues = JSON.parse(JSON.stringify(values));
              formValues.Created_By = user.Email_Id
              dispatch(AddTicketcommentAction(formValues, getid, resetForm, setReload, callBackGetData));
            }
          }
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            resetForm
          }) => (
            <View style={styles.container}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', }}>
                <View>
                  {id?.length > 0 &&
                    <View >
                      <View style={{
                        paddingTop: 15, marginHorizontal: 2, borderRadius: 20, padding: 2, margin: 'auto', marginTop: 10,
                        // backgroundColor: esiColor.BackgroundColor,
                        borderColor: '#27B6CC',
                        shadowOpacity: 0.2,
                        shadowRadius: 20,
                        elevation: 10,
                        minHeight: 80,
                        flex: 1,
                        width: 350, justifyContent: 'space-between'
                      }}>

                        <View style={{ flex: 1, margin: '1%', padding: 10 }}>
                          <Text style={{ fontSize: 15, }}>Ticket ID :{id[0]?.Ticket_Id}</Text>
                          <Text style={{ fontSize: 15, marginTop: 10 }}>Subject :{id[0]?.Ticket_Subject} </Text>
                        </View>
                      </View>
                    </View>
                  }

                  {isEmpty(Ticket) ? <View>
                    <Text style={{ fontSize: 16, textAlign: 'center', color: 'red' }}>
                      No comments found.
                    </Text>
                  </View> : (Comment?.length > 0 && Comment?.slice(0, visible).map((data: any, index: any) => (
                    <View key={index} style={styles.containerm}>
                      <TouchableOpacity>
                        <Image style={styles.image} source={data?.Created_By ? userPic : null} />
                      </TouchableOpacity>
                      <View style={styles.content}>
                        <View style={styles.contentHeader}>
                          <Text style={{ fontSize: 15, marginBottom: 50, marginLeft: 16, color: esiColor.itemColor }}>{data.Comments}</Text>

                        </View>
                      </View>
                    </View>
                  )))}
                </View>
              </View>
              <View style={{ alignSelf: 'center', marginVertical: 15, marginTop: 2 }}>
                {Comment?.length > visible &&
                  <Text variant="contained" mode="outlined" style={{ fontWeight: 'bold', color: '#27b6cc' }} onPress={() => setVisible(visible + 5)}>Show More ▼</Text>
                }
              </View>

              <View style={styles.textbox}>
                <TextInput
                  multiline
                  numberOfLines={2}
                  placeholder="Add a Comment...."
                  onChangeText={handleChange('Comments')}
                  theme={customTheme}
                  style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                  outlineColor={esiColor.TextOuterLine}
                  underlineColor={esiColor.TextUnderline}
                  activeOutlineColor={esiColor.TextOuterLine}
                  name="Comments"
                  value={values.Comments}
                  onChangeText={handleChange('Comments')}
                  error={Boolean(touched.Comments && errors.Comments)}
                  right={<TextInput.Icon name="send" color="#27B6CC" disabled={isSubmited} mode="contained" onPress={handleSubmit} />}

                />
                <HelperText type="error" visible={Boolean(touched.Comments && errors.Comments)}>
                  <ErrorMessage name="Comments" />
                </HelperText>
              </View>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};

export default Comment











