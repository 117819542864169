import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Button, Searchbar, Portal, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../../auth/components/header';
import isEmpty from '../../../state/validations/is-empty';
import { getAllSBCPartnerCityAction, deleteSBCPartnerCityAction, resetFilter } from '../../../state/actions/sbcpartnerCityAction';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import Icon from 'react-native-vector-icons/FontAwesome';

//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Partner_Service_City_Id',
        label: 'Partner Service City ID'
    },
    {
        value: 'City_Name',
        label: 'City Name'
    },
    {
        value: 'Status',
        label: 'Status'
    },
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function SbcpartnercityDetails({ navigation }: { navigation: any }) {

    //sbcpartnercities contains the data from GET API using state call
    const sbcpartnercitys = useSelector(state => state.sbcpartnercity.sbcpartnercities.all);

    let sbcpartnercities = sbcpartnercitys?.results;
    const esiColor = useSelector(state => state.theme);
    //sbcpartnercityMenu is used to store the cache data in Menu select using state call
    const [sbcpartnercityMenu, setSbcpartnercityMenu] = React.useState('All')

    //sbcpartnercitySearch is used to store the cache data in search using state call
    const [sbcpartnercitySearch, setSbcpartnercitySearch] = React.useState('');

    //toast for popup after successful API calls.
    const toast = useToast();
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_ID", SortOrder: "DESC" });

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);

    const EmailID = useSelector(state => state.auth?.user);

    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);

    //API dispatch

    const dispatch = useDispatch()
    const [shopbycity, setShopbycity] = React.useState();

    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    //Static formData
    React.useEffect(() => {
        let formData = {
            "search": "",
            "search_by_filter": sbcpartnercityMenu,
            "Partner_Service_City_Id": "",
            "City_Id": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Partner_Name": "",
            "Records_Filter": "FILTER"
        };
        setShopbycity(sbcpartnercities);
        handleSearch("");
        dispatch(getAllSBCPartnerCityAction(formData, pagination))
    }, []);

    //handleClickcoupons contains the get data with navigation
    const handleClickcoupons = async (sbcpartnercities: any) => {
        navigation.navigate('SBCPartnercitycouponGet', { partnerid: sbcpartnercities.Partner_Details_Id, partnercityid: sbcpartnercities.Partner_Service_City_Id, partnercityname: sbcpartnercities.City_Name, partnername: sbcpartnercities.Partner_Name, cityid: sbcpartnercities.City_Id })
    };

    //handleClickdeals contains the get data with navigation
    const handleClickdeals = async (sbcpartnercities: any) => {
        navigation.navigate('SBCPartnercitydealGet', { partnerid: sbcpartnercities.Partner_Details_Id, partnercityid: sbcpartnercities.Partner_Service_City_Id, partnercityname: sbcpartnercities.City_Name, partnername: sbcpartnercities.Partner_Name, cityid: sbcpartnercities.City_Id })
    };

    //handleClickproducts contains the get data with navigation
    const handleClickproducts = async (sbcpartnercities: any) => {
        navigation.navigate('SBCPartnercityproductGet', { partnerid: sbcpartnercities.Partner_Details_Id, partnercityid: sbcpartnercities.Partner_Service_City_Id, partnercityname: sbcpartnercities.City_Name, partnername: sbcpartnercities.Partner_Name, cityid: sbcpartnercities.City_Id })
    };
    const handleClicklocations = async (sbcpartnercities: any) => {
        navigation.navigate('partnershopbylocationget', { partnerid: sbcpartnercities.Partner_Details_Id, partnercityid: sbcpartnercities.Partner_Service_City_Id, partnercityname: sbcpartnercities.City_Name, partnername: sbcpartnercities.Partner_Name, cityid: sbcpartnercities.City_Id })
    };

    //delete sbcpartnercity
    const deleteSBCPartnercity = (sbcpartnercity: any) => {
        if (sbcpartnercity) {
            const deleteData = {
                Partner_Details_Id: sbcpartnercity.Partner_Details_Id,
                Partner_Service_City_Id: sbcpartnercity.Partner_Service_City_Id
            };
            dispatch(deleteSBCPartnerCityAction(deleteData, toast));
        }
    };

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //sorting, pagination & search
    const [page, setPage] = React.useState(0);
    const [webpage, setwebPage] = React.useState(1);

    const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = (Number(from) + Number(numberOfItemsPerPage) > sbcpartnercitys?.Pagination?.TotalCount ? sbcpartnercitys?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
    const onItemsPerPageChange = (e) => {
        numberItemsPerPageChange(e)
        setPage(0)
        setwebPage(1)
        setPagination({ PageNo: Number(1), PageSize: e, SortBy: "PARTNER_SERVICE_CITY_ID", SortOrder: "DESC" })
    }
    const DynamicPageIncrease = (e) => {
        setwebPage(e < sbcpartnercitys?.Pagination?.TotalPages ? e + Number(1) : sbcpartnercitys?.Pagination?.TotalPages)
        setPagination({ PageNo: e == sbcpartnercitys?.Pagination?.TotalPages ? sbcpartnercitys?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PARTNER_SERVICE_CITY_ID", SortOrder: "DESC" });
    }
    const DynamicPagedecrese = (e) => {
        setwebPage(e > 1 ? e - Number(1) : 1)
        setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "PARTNER_SERVICE_CITY_ID", SortOrder: "DESC" });
    }
    const DynamicPage = (e) => {
        setPage(e)
        setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PARTNER_SERVICE_CITY_ID", SortOrder: "DESC" });
    }
    const [pageOld, setPageOld] = React.useState(false);
    React.useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            couponCall();
        }
    }, [pagination])
    // search and set search is used for the search functionality
    const [loading, setIsLoading] = React.useState(false);
    const couponCall = () => {
        let formData = {
            "search": "",
            "search_by_filter": sbcpartnercityMenu,
            "Partner_Service_City_Id": "",
            "City_Id": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Partner_Name": "",
            "Records_Filter": "FILTER"
        };
        dispatch(getAllSBCPartnerCityAction(formData, pagination))

    };


    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Partner_Service_City_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
        setOrderBy(property);
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const handleChange = itemValue => {
        setSbcpartnercityMenu(itemValue)
    };
    const handleSearch = value => {
        const formData = {
            "search": value,
            "search_by_filter": sbcpartnercityMenu,
            "Partner_Service_City_Id": "",
            "City_Id": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Partner_Name": "",
            "Records_Filter": "FILTER"
        };
        dispatch(getAllSBCPartnerCityAction(formData, pagination))
        setSbcpartnercitySearch(value)
    };


    // View Dialog
    const [visible, setVisible] = React.useState(false);
    const [sbcpartnercityData, setData] = React.useState();

    const hideDialog = () => setVisible(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const refreshCall = () => {
        setIsLoading(true);
        let pagecall = { PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_ID", SortOrder: "DESC" }
        let formData = {
            "search": "",
            "search_by_filter": sbcpartnercityMenu,
            "Partner_Service_City_Id": "",
            "City_Id": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Partner_Name": "",
            "Records_Filter": "FILTER"

        };
        setPage(0)
        numberItemsPerPageChange(5)
        dispatch(getAllSBCPartnerCityAction(formData, pagecall))
    };
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        refreshCall()
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const style = StyleSheet.create({
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 200
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 150
        },
        rowsPerPageContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 10,
            justifyContent: 'flex-end',
            borderTopColor: esiColor.SBorderColor,
            borderWidth: 0.5,
        },
        rowsPerPagePicker: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.itemColor
        },
        rowsPerPagePickers: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            borderColor: 'gray',
            borderWidth: 0.5,
            paddingHorizontal: 8,
        },
        paginationContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            // backgroundColor: esiColor.SIconColor
        },
        paginationButton: {
            padding: 10,
            marginHorizontal: 10,
            // backgroundColor: esiColor.SIconColor
        },
        pageInfoText: {
            fontSize: 16,
            padding: 10,
            fontWeight: 'bold',
            color: esiColor.itemColor
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
                    <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                        <IconButton icon="close" color={esiColor.GBFColor} size={20} onPress={() => setVisible(!visible)} />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                        <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>View Partner City</Text>
                    </Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 7 }}>
                            <Dialog.Content style={{ marginLeft: -30 }}>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Service City ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{sbcpartnercityData?.Partner_Service_City_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Details ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityData?.Partner_Details_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Name</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityData?.Partner_Name} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>City Name</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityData?.City_Name} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityData?.Status} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityData?.Created_By} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityData?.Created_Date} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Modified By</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityData?.Modified_By} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Modified Date</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityData?.Modified_Date} </Text></View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>

            <ScrollView >
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 146, justifyContent: 'flex-start' }} onPress={() => {
                        navigation.navigate('PartnerMainCenter')
                        handleSearch("")
                        dispatch(resetFilter())
                    }
                    }>
                        Go Back
                    </Button>
                </View>
                <View style={{ flexDirection: "column" }}>
                    <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, paddingBottom: 10, color: esiColor.brandFontColor }}>Shop By City - Partner Cities</Text>
                </View>
                <View >
                    {(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write") &&
                        <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
                            <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => navigation.navigate('AddSBCPartnercity')}>
                                ADD PARTNER CITY
                            </Button>
                        </View>}
                    <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
                        {Platform.OS !== 'web' ?
                            <View style={{ flex: dimensions <= 850 ? 1.8 : 0.5, marginLeft: 15 }}>
                                <EsiSearchDropdown
                                    style={[style.dropdown]}
                                    placeholderStyle={style.placeholderStyle}
                                    selectedTextStyle={style.selectedTextStyle}
                                    inputSearchStyle={style.inputSearchStyle}
                                    iconStyle={style.iconStyle}
                                    data={filterOptions}
                                    containerStyle={style.containerstyle}
                                    maxHeight={180}
                                    labelField="label"
                                    valueField="value"
                                    placeholder={!showDropDown ? 'All' : ''}
                                    value={sbcpartnercityMenu}
                                    onFocus={() => setShowDropDown(true)}
                                    onBlur={() => setShowDropDown(false)}
                                    onUpdateValue={item => {
                                        setShowDropDown(false);
                                        handleChange(item.value)
                                    }}
                                />
                            </View>
                            :
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
                                <Picker
                                    selectedValue={sbcpartnercityMenu}
                                    style={{ height: 50, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                    onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                                >
                                    <Picker.Item label="All" value="All" />
                                    <Picker.Item label="Partner Service City ID" value="Partner_Service_City_Id" />
                                    <Picker.Item label="City Name" value="City_Name" />
                                    <Picker.Item label="Status" value="Status" />
                                </Picker>
                            </View>}

                        <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

                        <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                            <Searchbar
                                style={{
                                    borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                                    backgroundColor: esiColor.BackgroundColor
                                }}
                                iconColor={esiColor.SIconColor}
                                placeholderTextColor={esiColor.SPHTextColor}
                                placeholder="Search"
                                onChangeText={(value) => handleSearch(value)}
                                value={sbcpartnercitySearch}
                            />
                        </View>
                    </View>
                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    <ScrollView refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                            colors={['#27B6CC']}
                            tintColor={'#27B6CC'}
                            title={"Pull to refresh"}
                            titleColor={"#27B6CC"}
                        />
                    } horizontal={dimensions >= 700 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Service_City_Id'}
                                            sortDirection={orderBy === 'Partner_Service_City_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Service_City_Id')}><Text style={style.titletext}>Partner Service City ID</Text></DataTable.Title>

                                        <DataTable.Title style={style.title}
                                        ><Text style={style.titletext}>Partner Name</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'City_Name'}
                                            sortDirection={orderBy === 'City_Name' ? order : 'ascending'}
                                            onPress={createSortHandler('City_Name')}><Text style={style.titletext}>City Name</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Status'}
                                            sortDirection={orderBy === 'Status' ? order : 'ascending'}
                                            onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Coupons</Text></DataTable.Title>
                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Deals</Text></DataTable.Title>
                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Products</Text></DataTable.Title>
                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Locations</Text></DataTable.Title>
                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        sbcpartnercities?.map((sbcpartnercity, index) => (
                                            <DataTable.Row style={style.databeBox} key={sbcpartnercity.Partner_Service_City_Id}>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercity.Partner_Service_City_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercity.Partner_Name}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercity.City_Name}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercity.Status}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    <IconButton icon="credit-card" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClickcoupons(sbcpartnercity); }}></IconButton>
                                                </DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    <IconButton icon="tag" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClickdeals(sbcpartnercity); }}></IconButton>
                                                </DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    <IconButton icon="clipboard-list" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClickproducts(sbcpartnercity); }}></IconButton>
                                                </DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    <IconButton icon="location-enter" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClicklocations(sbcpartnercity); }}></IconButton>
                                                </DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    {(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write") && (
                                                        <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={() => navigation.navigate('EditSBCPartnercity', { ID: sbcpartnercity.Partner_Service_City_Id })} />
                                                    )}
                                                    <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(sbcpartnercity); setVisible(!visible) }} />
                                                    {(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write") && <IconButton icon="delete" size={20} iconColor={esiColor.SIconColor} onPress={(e) => deleteSBCPartnercity(sbcpartnercity)} />
                                                    }
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                        ))
                                    }
                                    {isEmpty(sbcpartnercities) &&
                                        <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                                No records found!
                                            </Text>
                                            <Image source={require('../../../assets/images/nodata.gif')}
                                                style={{ width: 300, height: 300, alignSelf: "center" }} />
                                        </View>
                                    }
                                    {Platform.OS !== "web" ?

                                        <DataTable.Pagination
                                            // theme={customTheme}
                                            page={page}
                                            numberOfPages={Math.ceil(sbcpartnercitys?.Pagination?.TotalCount / numberOfItemsPerPage)}
                                            onPageChange={page => DynamicPage(to < sbcpartnercitys?.Pagination?.TotalCount ? page : 0)}
                                            label={sbcpartnercitys?.Pagination?.TotalCount ? `${from + 1}-${to} of ${sbcpartnercitys?.Pagination?.TotalCount}` : '0-0'}
                                            showFastPaginationControls={(Number(page) + Number(1)) > sbcpartnercitys?.Pagination?.TotalPages ? false : true}
                                            numberOfItemsPerPageList={numberOfItemsPerPageList}
                                            numberOfItemsPerPage={numberOfItemsPerPage}
                                            onItemsPerPageChange={onItemsPerPageChange}
                                            selectPageDropdownLabel={'Rows per page'}
                                        />
                                        :
                                        <View style={style.rowsPerPageContainer}>
                                            <Text style={{ color: esiColor.Text }}>rows per page: </Text>
                                            <Picker
                                                style={style.rowsPerPagePicker}
                                                selectedValue={numberOfItemsPerPage}
                                                onValueChange={(e) => onItemsPerPageChange(e)}
                                            >
                                                <Picker.Item label="5" value={5} />
                                                <Picker.Item label="10" value={10} />
                                                <Picker.Item label="20" value={20} />
                                            </Picker>
                                            <View style={style.paginationContainer}>
                                                <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                                                </TouchableOpacity>
                                                <Text style={style.pageInfoText}>
                                                    {sbcpartnercitys?.Pagination?.PageNo + ' of ' + sbcpartnercitys?.Pagination?.TotalPages}
                                                </Text>
                                                <TouchableOpacity onPress={e => DynamicPageIncrease(to < sbcpartnercitys?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                                                </TouchableOpacity>
                                            </View>
                                        </View>}
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>
                </View>
            </ScrollView>
        </Surface>
    );
}

export default SbcpartnercityDetails