import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Button, Searchbar, Portal, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage';
import isEmpty from '../../../state/validations/is-empty';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import { getAllBiddingProductsAction, resetFilter, updateproductavailablility } from '../../../state/actions/biddingProductsAction';
import Icon from 'react-native-vector-icons/FontAwesome';

//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Partner_Bidding_Available_Products_Id',
        label: 'Bidding Product ID'
    },
    {
        value: 'Product_Name',
        label: 'Product Name'
    },
    {
        value: 'Partner_Address_Id',
        label: 'Address ID'
    },
    {
        value: 'Available_Stock',
        label: 'Available Stock'
    },
    {
        value: 'Status',
        label: 'Status'
    },
    {
        value: 'Created_By',
        label: 'Created By'
    },
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function PartnerBiddingProducts(props: any) {
    const { navigation } = props;
    const ID = props.route?.params?.ID;
    const esiColor = useSelector(state => state.theme);
    //addresses contains the data from  GET API using state call
    const AllBiddinngProducts = useSelector(state => state.biddingproducts.bidding_products.all);
    let biddinngproducts = AllBiddinngProducts?.results;
    const [biddingproductsMenu, setbiddingproductsMenu] = React.useState('All')
    const [biddingproductsSearch, setbiddingproductsSearch] = React.useState('');
    //toast for popup after successful API calls.
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const [page, setPage] = React.useState(0);
    const [webpage, setwebPage] = React.useState(1);
    const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = (Number(from) + Number(numberOfItemsPerPage) > AllBiddinngProducts?.Pagination?.TotalCount ? AllBiddinngProducts?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
    const onItemsPerPageChange = (e) => {
        numberItemsPerPageChange(e)
        setPage(0)
        setwebPage(1)
        setPagination({ PageNo: Number(page) + Number(1), PageSize: e, SortBy: "PARTNER_BIDDING_AVAILABLE_PRODUCTS_ID", SortOrder: "DESC" })
    }
    const DynamicPageIncrease = (e) => {
        setwebPage(e < AllBiddinngProducts?.Pagination?.TotalPages ? e + Number(1) : AllBiddinngProducts?.Pagination?.TotalPages)
        setPagination({ PageNo: e == AllBiddinngProducts?.Pagination?.TotalPages ? AllBiddinngProducts?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PARTNER_BIDDING_AVAILABLE_PRODUCTS_ID", SortOrder: "DESC" });
    }
    const DynamicPagedecrese = (e) => {
        setwebPage(e > 1 ? e - Number(1) : 1)
        setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "PARTNER_BIDDING_AVAILABLE_PRODUCTS_ID", SortOrder: "DESC" });
    }
    const DynamicPage = (e) => {
        setPage(e)
        setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PARTNER_BIDDING_AVAILABLE_PRODUCTS_ID", SortOrder: "DESC" });
    }
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_BIDDING_AVAILABLE_PRODUCTS_ID", SortOrder: "DESC" });
    React.useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            biddinngproductsCall();
        }
    }, [pagination])
    // search and set search is used for the search functionality
    const [loading, setIsLoading] = React.useState(false);
    const biddinngproductsCall = () => {
        setIsLoading(true);
        let formData = {
            "search_by_filter": biddingproductsMenu,
            "search": "",
            "Partner_Bidding_Available_Products_Id": "",
            "Product_Id": "",
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Partner_Address_Id": "",
            "Records_Filter": "FILTER",
            "Is_Product_Available": "",
            "Available_Stock": ""
        };

        dispatch(getAllBiddingProductsAction(formData, pagination))
    };
    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);

    //API dispatch
    const dispatch = useDispatch()
    //Static formData
    const refreshCall = () => {
        setIsLoading(true);
        let pagecall = { PageNo: 1, PageSize: 5, SortBy: "Partner_Bidding_Available_Products_Id", SortOrder: "DESC" }
        let formData = {
            "search_by_filter": biddingproductsMenu,
            "search": "",
            "Partner_Bidding_Available_Products_Id": "",
            "Product_Id": "",
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Partner_Address_Id": "",
            "Records_Filter": "FILTER",
            "Is_Product_Available": "",
            "Available_Stock": ""
        };
        dispatch(getAllBiddingProductsAction(formData, pagecall))
        setPage(0)
        numberItemsPerPageChange(5)
    };
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (EmailID.Partner_Details_Id) {
            let formData = {
                "search_by_filter": biddingproductsMenu,
                "search": "",
                "Partner_Bidding_Available_Products_Id": "",
                "Product_Id": "",
                "Partner_Detail_Id": EmailID?.Partner_Details_Id,
                "Partner_Address_Id": "",
                "Records_Filter": "FILTER",
                "Is_Product_Available": "",
                "Available_Stock": ""
            };
            dispatch(getAllBiddingProductsAction(formData, pagination))

        }
    }, [permissions, EmailID]);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        refreshCall();
        wait(2000).then(() => setRefreshing(false));
    }, []);
    //delete address
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    //sorting, pagination & search
    const [order, setOrder] = React.useState('descending');
    const [orderBy, setOrderBy] = React.useState('Partner_Bidding_Available_Products_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
        setOrderBy(property);
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    // View Dialog
    const [visible, setVisible] = React.useState(false);
    const [addData, setData] = React.useState();

    const hideDialog = () => setVisible(false);

    const handleChange = itemValue => {
        setbiddingproductsMenu(itemValue);
    };
    const handleSearch = value => {
        let formData = {
            "search_by_filter": biddingproductsMenu,
            "search": value,
            "Partner_Bidding_Available_Products_Id": "",
            "Product_Id": "",
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Partner_Address_Id": "",
            "Records_Filter": "FILTER",
            "Is_Product_Available": "",
            "Available_Stock": ""
        };
        setbiddingproductsSearch(value)
        dispatch(getAllBiddingProductsAction(formData, pagination))
    };
    const UpdateBiddingProduct = (product) => {
        const formValues = {
            "Available_Stock": product?.Available_Stock,
            "Is_Product_Available": '1',
            "Modified_By": EmailID?.Email_Id,
            "Partner_Address_Id": product?.Partner_Address_Id,
            "Partner_Bidding_Available_Products_Id": product?.Partner_Bidding_Available_Products_Id,
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Product_Id": product?.Product_Id,
            "Status": product?.Status
        };
        numberItemsPerPageChange(5)
        dispatch(updateproductavailablility(formValues, navigation, toast));
    };
    const DeleteBiddingProduct = (product) => {
        const formValues = {
            "Available_Stock": product?.Available_Stock,
            "Is_Product_Available": product.Is_Product_Available,
            "Modified_By": EmailID?.Email_Id,
            "Partner_Address_Id": product?.Partner_Address_Id,
            "Partner_Bidding_Available_Products_Id": product?.Partner_Bidding_Available_Products_Id,
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Product_Id": product?.Product_Id,
            "Status": 'In-Active'
        };
        numberItemsPerPageChange(5)
        dispatch(updateproductavailablility(formValues, navigation, toast));
    };
    const UpdateBiddingProducts = (product) => {
        const formValues = {
            "Available_Stock": product?.Available_Stock,
            "Is_Product_Available": '0',
            "Modified_By": EmailID?.Email_Id,
            "Partner_Address_Id": product?.Partner_Address_Id,
            "Partner_Bidding_Available_Products_Id": product?.Partner_Bidding_Available_Products_Id,
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Product_Id": product?.Product_Id,
            "Status": product?.Status
        };
        numberItemsPerPageChange(5)
        dispatch(updateproductavailablility(formValues, navigation, toast));
    };

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };

    const style = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 160
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 150
        },
        rowsPerPageContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 10,
            justifyContent: 'flex-end',
            borderTopColor: esiColor.SBorderColor,
            borderWidth: 0.5,
        },
        rowsPerPagePicker: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.itemColor
        },
        rowsPerPagePickers: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            borderColor: 'gray',
            borderWidth: 0.5,
            paddingHorizontal: 8,
        },
        paginationContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            // backgroundColor: esiColor.SIconColor
        },
        paginationButton: {
            padding: 10,
            marginHorizontal: 10,
            // backgroundColor: esiColor.SIconColor
        },
        pageInfoText: {
            fontSize: 16,
            padding: 10,
            fontWeight: 'bold',
            color: esiColor.itemColor
        },
    });


    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
                    <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                        <IconButton icon="close" color={esiColor.GBFColor} size={20} onPress={() => setVisible(!visible)} />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                        <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>View Product details</Text>
                    </Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 7 }}>
                            <Dialog.Content style={{ marginLeft: -30 }}>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Bidding Product ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{addData?.Partner_Bidding_Available_Products_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Name</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Product_Name} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{addData?.Product_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Addres</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Door_No}, {addData?.Street}, {addData?.City}, {addData?.Zip} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Available Stock</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Available_Stock} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Available</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: addData?.Is_Product_Available == '0' ? 'red' : esiColor.GBFColor }}>{addData?.Is_Product_Available == '1' ? 'Yes' : 'No'} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Created_By} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Created_Date} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: addData?.Status == 'In-Active' ? 'red' : esiColor.GBFColor }}>{addData?.Status} </Text></View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>

            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>

                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                        navigation.navigate('PartnerBiddingMainCenter')
                        handleSearch("")
                        dispatch(resetFilter())
                    }
                    }>
                        Go Back
                    </Button>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <View style={{ flexDirection: "column" }}>
                        <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10,color:esiColor.brandFontColor }}>Partner Bidding Products</Text>
                    </View>

                    {/* {(permissions.Address_Management === "write") && */}
                    <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
                        <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => { navigation.navigate('AddPartnerBiddingProducts'), handleSearch("") }}>
                            Add Bidding Products
                        </Button>
                    </View>
                    {/* } */}

                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={filterOptions}
                                containerStyle={style.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={biddingproductsMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                            <Picker
                                selectedValue={biddingproductsMenu}
                                style={{ height: 51, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Bidding Product ID" value="Partner_Bidding_Available_Products_Id" />
                                <Picker.Item label="Product Name" value="Product_Name" />
                                <Picker.Item label="Address ID" value="Partner_Address_Id" />
                                <Picker.Item label="Available Stock" value="Available_Stock" />
                                <Picker.Item label="Created By" value="Created_By" />
                                <Picker.Item label="Status" value="Status" />

                            </Picker>
                        </View>}

                    <View style={{ flex: dimensions <= 100 ? 0.1 : 0.6 }} />

                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{
                                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.BackgroundColor
                            }}
                            iconColor={esiColor.SIconColor}
                            theme={esiColor.pagination_theme}
                            placeholderTextColor={esiColor.SPHTextColor}
                            placeholder="Search"
                            onChangeText={(value) => handleSearch(value)}
                            value={biddingproductsSearch}
                        />
                    </View>
                    <View style={{ flex: dimensions <= 700 ? null : 0.53 }} />
                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    <ScrollView horizontal={dimensions >= 700 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title  style={style.title} active={orderBy === 'Partner_Bidding_Available_Products_Id'}
                                            sortDirection={orderBy === 'Partner_Bidding_Available_Products_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Bidding_Available_Products_Id')}><Text style={style.titletext}>Bidding Product ID</Text></DataTable.Title>

                                        <DataTable.Title  style={style.title} active={orderBy === 'Product_Name'}
                                            sortDirection={orderBy === 'Product_Name' ? order : 'ascending'}
                                            onPress={createSortHandler('Product_Name')}><Text style={style.titletext}>Product Name</Text></DataTable.Title>

                                        <DataTable.Title  style={style.title} active={orderBy === 'Partner_Address_Id'}
                                            sortDirection={orderBy === 'Partner_Address_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Address_Id')}><Text style={style.titletext}>Address ID</Text></DataTable.Title>

                                        <DataTable.Title  style={style.title} active={orderBy === 'Available_Stock'}
                                            sortDirection={orderBy === 'Available_Stock' ? order : 'ascending'}
                                            onPress={createSortHandler('Available_Stock')}><Text style={style.titletext}>Available Stock</Text></DataTable.Title>


                                        <DataTable.Title  style={style.title} active={orderBy === 'Created_By'}
                                            sortDirection={orderBy === 'Created_By' ? order : 'ascending'}
                                            onPress={createSortHandler('Created_By')}><Text style={style.titletext}>Created By</Text></DataTable.Title>
                                        <DataTable.Title  style={style.title} active={orderBy === 'Status'}
                                            sortDirection={orderBy === 'Status' ? order : 'ascending'}
                                            onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                                        <DataTable.Title theme={customTheme} style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                        <DataTable.Title theme={customTheme} style={style.title}><Text style={style.titletext}>Product Available</Text></DataTable.Title>

                                    </DataTable.Header>
                                    {
                                        biddinngproducts?.map((biddingproduct, index) => (
                                            <DataTable.Row style={style.databeBox} key={index}>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Partner_Bidding_Available_Products_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Product_Name}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Partner_Address_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Available_Stock}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Created_By}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Status}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    {/* {(permissions.Address_Management === "write") && ( */}
                                                    <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={() => { navigation.navigate('EditPartnerBiddingProducts', { id: biddingproduct.Partner_Bidding_Available_Products_Id, ID }), handleSearch("") }} />
                                                    {/* )} */}
                                                    <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(biddingproduct); setVisible(!visible) }} />
                                                    {/* {(permissions.Address_Management === "write") &&  */}
                                                    <IconButton icon="delete" size={20} iconColor={esiColor.SIconColor} onPress={(e) => DeleteBiddingProduct(biddingproduct)} />
                                                    {/* } */}
                                                </DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}> {biddingproduct?.Is_Product_Available == '0' ? <IconButton icon="close" size={30} iconColor='red' onPress={(e) => { UpdateBiddingProduct(biddingproduct) }} /> : <IconButton icon="check" iconColor={esiColor.SIconColor} onPress={(e) => { UpdateBiddingProducts(biddingproduct) }} />}</DataTable.Cell>

                                            </DataTable.Row>
                                        ))
                                    }
                                    {isEmpty(biddinngproducts) &&
                                        <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                                No records found!
                                            </Text>
                                            <Image source={require('../../../assets/images/nodata.gif')}
                                                style={{ width: 300, height: 300, alignSelf: "center" }} />
                                        </View>
                                    }
                                    {Platform.OS !== "web" ?

                                        <DataTable.Pagination
                                            // theme={customTheme}
                                            page={page}
                                            numberOfPages={Math.ceil(AllBiddinngProducts?.Pagination?.TotalCount / numberOfItemsPerPage)}
                                            onPageChange={page => DynamicPage(to < AllBiddinngProducts?.Pagination?.TotalCount ? page : 0)}
                                            label={AllBiddinngProducts?.Pagination?.TotalCount ? `${from + 1}-${to} of ${AllBiddinngProducts?.Pagination?.TotalCount}` : '0-0'}
                                            showFastPaginationControls={(Number(page) + Number(1)) > AllBiddinngProducts?.Pagination?.TotalPages ? false : true}
                                            numberOfItemsPerPageList={numberOfItemsPerPageList}
                                            numberOfItemsPerPage={numberOfItemsPerPage}
                                            onItemsPerPageChange={onItemsPerPageChange}
                                            selectPageDropdownLabel={'Rows per page'}
                                        />
                                        :
                                        <View style={style.rowsPerPageContainer}>
                                            <Text style={{color: esiColor.Text}}>rows per page: </Text>
                                            <Picker
                                                style={style.rowsPerPagePicker}
                                                selectedValue={numberOfItemsPerPage}
                                                onValueChange={(e) => onItemsPerPageChange(e)}
                                            >
                                                <Picker.Item label="5" value={5} />
                                                <Picker.Item label="10" value={10} />
                                                <Picker.Item label="20" value={20} />
                                            </Picker>
                                            <View style={style.paginationContainer}>
                                                <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                                                </TouchableOpacity>
                                                <Text style={style.pageInfoText}>
                                                    {AllBiddinngProducts?.Pagination?.PageNo + ' of ' + AllBiddinngProducts?.Pagination?.TotalPages}
                                                </Text>
                                                <TouchableOpacity onPress={e => DynamicPageIncrease(to < AllBiddinngProducts?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-right" size={20} color={ esiColor.SIconColor} />
                                                </TouchableOpacity>
                                            </View>
                                        </View>}
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>

                </View>
            </ScrollView>
        </Surface>

    );
}

export default PartnerBiddingProducts