import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateCouponAction } from '../../../state/actions/couponAction';
import { Card, Text, Checkbox, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView, Dimensions } from 'react-native';
import Header from '../../auth/components/header';
import EsiEditor from '../../../components/custom/richtext-editor/richtext-editor';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

const createdTypes = [
  {
    value: '-Please Select-',
    label: '-Please Select-'
  },
  {
    value: 'Percentage discounts',
    label: 'Percentage discounts'
  },
  {
    value: 'BOGO',
    label: 'BOGO'
  },
  {
    value: 'Multi-buys',
    label: 'Multi-buys'
  },
  {
    value: 'Multi-save',
    label: 'Multi-save'
  }
  ,
  {
    value: 'Try before you buy',
    label: 'Try before you buy'
  }
];

//StatusOption used to get the data from dropdown
const StatusOption = [
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const EditCouponConfig = (props: any) => {

  //toast for popup after successful API calls.

  const toast = useToast();
  const { navigation } = props
  const isSubmited = useSelector(state => state.coupon.couponUpdate.isLoading);
  const data = useSelector(state => state.coupon.couponData.data);
  const EmailID = useSelector(state => state.auth?.user);

  //Dropdown
  const [typeDropDown, setTypeDropDown] = useState("");
  const [statusDropDown, setStatusDropDown] = useState("");

  //Description
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [descriptionvalue, setDescriptionValue] = React.useState(data.Description);


  const dispatch = useDispatch();
  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  /*====================================
          Handler
  ====================================*/
  const values = {
    Code: data.Code,
    Name: data.Name,
    Type: data.Type,
    Coupon_Id: data.Coupon_Id,
    Partner_Details_Id: data.Partner_Details_Id,
    Modified_By: EmailID.Email_Id,
    Partner_Name: data.Partner_Name,
    Partner_Type: data.Partner_Type,
    Brand_Id: data.Brand_Id,
    Category_Id: data.Category_Id,
    Mobile_Redirect_Url: data.Mobile_Redirect_Url,
    Web_Redirect_Url: data.Web_Redirect_Url,
    Status: data.Status,
    Discount_Type: data.Discount_Type,
    Discount_Amount: data.Discount_Amount,
    ESI_Cashback_Amount: data.ESI_Cashback_Amount,
    ESI_Cashback_Type: data.ESI_Cashback_Type,
    Extra_Cashback: data.Extra_Cashback > 0 ? true : false,
    Exclusive: data.Exclusive > 0 ? true : false,
    Is_Coupon_Expired: data.Is_Coupon_Expired > 0 ? true : false,
    Is_Upcoming: data.Is_Upcoming > 0 ? true : false,
    Verified: data.Verified > 0 ? true : false,
    Image_Path: data.Image_Path,
    Valid_From_Date: data.Valid_From_Date + " ",
    Valid_Through_Date: data.Valid_Through_Date + "",
    Whats_Hot: data.Whats_Hot > 0 ? true : false,
    Description: data.Description,
    Is_Top_Coupon: data.Is_Top_Coupon > 0 ? true : false,
    Coupon_Max_Count: data.Coupon_Max_Count + "",
    Coupon_Pending_Count: data.Coupon_Pending_Count + "",
    Discount_Label: data.Discount_Label,
    Cashback_Label: data.Cashback_Label,
  }

  const Form_Validation = Yup.object().shape({
    Type: Yup.string().required('Type is required.'),
    Status: Yup.string().required('Status is required.')
  })

  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    values.Description = descriptionvalue;
    if (!values.Description || values.Description.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
      submitTest = false;
      errors.Description = "Description is required.";
    }
    else if (values.Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
      submitTest = false;
      errors.Description = "Please provide at least " + rhDiscriptionMin + " characters.";
    }

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Verified) {
        formValues.Verified = 1;
      } else {
        formValues.Verified = 0;
      }
      if (values.Exclusive) {
        formValues.Exclusive = 1;
      } else {
        formValues.Exclusive = 0;
      }
      if (values.Whats_Hot) {
        formValues.Whats_Hot = 1;
      } else {
        formValues.Whats_Hot = 0;
      }
      if (values.Is_Top_Coupon) {
        formValues.Is_Top_Coupon = 1;
      } else {
        formValues.Is_Top_Coupon = 0;
      }
      if (values.Extra_Cashback) {
        formValues.Extra_Cashback = 1;
      } else {
        formValues.Extra_Cashback = 0;
      }
      if (values.Is_Upcoming) {
        formValues.Is_Upcoming = 1;
      } else {
        formValues.Is_Upcoming = 0;
      }

      if (values.Is_Coupon_Expired) {
        formValues.Is_Coupon_Expired = 1;
      } else {
        formValues.Is_Coupon_Expired = 0;
      }
      if (values.Discount_Type === "") {
        formValues.Discount_Amount = 0;
      }
      if (values.ESI_Cashback_Type === "") {
        formValues.ESI_Cashback_Amount = 0;
      }
      dispatch(updateCouponAction(formValues, navigation, toast));
    }
  }
  const esiColor = useSelector(state => state.theme);

  const styles = StyleSheet.create({
    checkbox: {
      alignSelf: "center",
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  })

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView>
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
          {(props) => (
            <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
              <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130 }} onPress={() => navigation.navigate('EditCouponBasic')}>
                  Go Back
                </Button>
              </View>
              <Card style={{
                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
              }}>
                <Card.Content>
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                    Edit Coupon
                  </Text>
                  <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    <View style={{ flexShrink: 1, width: 650 }}>

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        {/* Type field with validation*/}
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Type && props.errors.Type) && { color: '#b00020' }]}>
                            Type
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Type && props.errors.Type) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            dropdownPosition="bottom"
                            placeholder={!typeDropDown ? '-- Please Select --' : ''}
                            data={createdTypes}
                            labelField="label"
                            valueField="value"
                            maxHeight={createdTypes.length > 1 ? 250 : 200}
                            search={createdTypes.length > 1 ? true : false}
                            searchPlaceholder={'Select Type'}
                            value={props.values.Type}
                            onUpdateValue={item => {
                              props.setFieldValue('Type', item.value)
                              setTypeDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Type && props.errors.Type)}>
                            <ErrorMessage name="Type" />
                          </HelperText>
                        </View>

                        {/* Status field with validation*/}
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                            Status
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            dropdownPosition="bottom"
                            placeholder={!statusDropDown ? '-- Please Select --' : ''}
                            data={StatusOption}
                            maxHeight={StatusOption.length > 1 ? 250 : 200}
                            search={StatusOption.length > 1 ? true : false}
                            labelField="label"
                            valueField="value"
                            value={props.values.Status}
                            onUpdateValue={item => {
                              props.setFieldValue('Status', item.value)
                              setStatusDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                            <ErrorMessage name="Status" />
                          </HelperText>
                        </View>
                      </View>
                      {/* Is Coupon Expired  field with validation*/}
                      <View style={{ flexShrink: 1, flexDirection: "row" }} >
                        <Checkbox.Android
                          color={esiColor.itemColor}
                          uncheckedColor={esiColor.SBorderColor}
                          name="Is_Coupon_Expired"
                          status={props.values.Is_Coupon_Expired ? 'checked' : 'unchecked'}
                          onPress={() => {
                            props.setFieldValue("Is_Coupon_Expired", !props.values.Is_Coupon_Expired);
                          }}
                        />
                        <Text style={{ marginTop: 10, color: esiColor.Text }}>Is Coupon Expired </Text>
                      </View>
                      {/* Description field with validation*/}
                      <View style={{ paddingBottom: Platform.OS === "web" ? 10 : 30 }}>
                        <EsiEditor label={"Description"} value={descriptionvalue} onUpdateValue={(value) => { setDescriptionValue(value) }} />
                        <HelperText type="error" visible={(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Description && props.errors.Description}>
                          {(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Description && props.errors.Description}
                        </HelperText>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                    <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                      Save
                    </Button>
                  </View>
                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};

export default EditCouponConfig;