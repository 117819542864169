import React, { useEffect } from "react";
import { Image, StyleSheet, Text, TextInput, TouchableOpacity, View, Platform } from "react-native";
import { HelperText, TextInput as TextInputP } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { MaterialIcons, Ionicons } from '@expo/vector-icons';

import { useNavigation } from '@react-navigation/native';
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import { productAddOrRemoveAddress } from "../../../state/actions/shoppers/shoppercartActions";

export default function CartProductCard({
  address,
  data,
  partner,
  Logindetails,
  count,
  handleOnChangeCount,
  handleOnClickCountIncrement,
  handleOnClickCountDecrement,
  handleOnClickRemoveCard,
  completeProduct,
  cart,
  productError,
  Min,
}) {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);
  const getProductInventoryError = () => {
    for (let i = 0; i < productError.length; i++) {
      if (productError[i].Product_Id === data?.Product_Id) {
        return productError[i];
      }
    }
  };
  useEffect(() => {
    getProductInventoryError()
  }, []);
  const getPartnerOrderPrice = () => {
    let amount = 0;
    let discount
    if (partner) {
      for (let j = 0; j < partner.Discount.length; j++) {
        if (Number(partner.Discount[j].Min_Quantity) <= Number(count) && Number(count) <= Number(partner.Discount[j].Max_Quantity)) {
          amount = amount + (Number(partner.Discount[j].Discounted_Price_Per_Quantity) * Number(count))
          discount = Number(partner.Discount[j].Discount_In_Percentage)
        } else if (j === partner.Discount.length - 1 && Number(count) > Number(partner.Discount[j].Max_Quantity)) {
          discount = Number(partner.Discount[j].Discount_In_Percentage)
          amount = ((Number(partner.Discount[j].Discounted_Price_Per_Quantity) * Number(count)) / discount)
        }
      }
    }

    let data = amount.toFixed(2)
    return { data, discount };
  };
  return (
    <View style={{ flex: 1, backgroundColor: esiColor.BackgroundColor, padding: 3, borderRadius: 8, borderWidth: 0.5,borderColor: esiColor.SBorderColor, marginVertical: 5 }}>

      <View style={{ flexDirection: 'row', backgroundColor: esiColor.BackgroundColor, marginBottom: 2 }}>
        <View style={{ flexDirection: 'row', flexGrow: 1, flexShrink: 1, alignSelf: 'center' }}>
          <TouchableOpacity onPress={() => {/*this.props.navigation.navigate('ProductDetails', {productDetails: item})*/ }} style={{ paddingRight: 10 }}>
            <Image source={{ uri: data?.Product_Image }} style={[styles.centerElement, { height: 60, width: 80, backgroundColor: '#eeeeee' }]} />
          </TouchableOpacity>
          <View style={{ flexGrow: 1, flexShrink: 1, alignSelf: 'center', backgroundColor: esiColor.BackgroundColor }}>
            <Text numberOfLines={1} style={{ fontSize: 15, color: esiColor.brandFontColor }}>{data?.Product_Name}</Text>
            <Text numberOfLines={1} style={{ color: esiColor.itemColor }}>{data?.color ? 'Variation: ' + data.color : ''}</Text>
            <View style={{ flexDirection: 'row' }}>
              <Text numberOfLines={1} style={{ color: esiColor.itemColor, marginBottom: 10, textDecorationColor: '#c4c4c4', textDecorationLine: 'line-through' }}> ₹ {count * partner.partnerProduct.Product_Price} </Text>
              <Text numberOfLines={1} style={{ color: esiColor.itemColor, marginBottom: 10, fontWeight: 'bold', paddingLeft: 10 }}> ₹ {getPartnerOrderPrice().data}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity disabled={Number(count) <= Number(partner?.Discount[0]?.Min_Quantity)} onPress={(e) => handleOnClickCountDecrement(e, count, { partner: completeProduct }, Logindetails)} style={{ borderWidth: 1, borderColor: '#cccccc' }}>
                  <MaterialIcons name="remove" size={22} color="#cccccc" />
                </TouchableOpacity>
                {Platform.OS === 'web' ?
                  <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                    style={{ borderWidth: 1, width: 24, borderColor: '#27b6cc', textAlign: 'center', color: esiColor.Text }}
                    onChangeText={(value) => {
                      handleOnChangeCount(value, { partner: completeProduct }, Logindetails, Number(partner.Discount[0].Min_Quantity))
                    }}
                    onBlur={(e) => {
                      handleOnChangeCount(e.target.value, { partner: completeProduct }, Logindetails, Number(partner.Discount[0].Min_Quantity))
                    }}
                    value={count}
                  />
                  :
                  <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                    style={{ borderWidth: 1, width: 24, borderColor: '#27b6cc', textAlign: 'center', color: esiColor.Text }}
                    onChangeText={(value) => {
                      handleOnChangeCount(value, { partner: completeProduct }, Logindetails, Number(partner.Discount[0].Min_Quantity))
                    }}
                    onEndEditing={(e) => {
                      handleOnChangeCount(e.nativeEvent.text, { partner: completeProduct }, Logindetails, Number(partner.Discount[0].Min_Quantity))
                    }}
                    value={String(count)}
                  />
                }
                <TouchableOpacity disabled={Number(count) >= Number(partner?.Discount[0]?.Max_Quantity)} onPress={(e) => handleOnClickCountIncrement(e, count, { partner: completeProduct }, Logindetails)} style={{ borderWidth: 1, borderColor: '#cccccc' }}>
                  <MaterialIcons name="add" size={22} color="#cccccc" />
                </TouchableOpacity>
              </View>
              <TouchableOpacity onPress={(e) => handleOnClickRemoveCard(e, { partner: partner, product: data })} >
                <Ionicons name="md-trash" size={22} color="#ee4d2d" />
              </TouchableOpacity>
            </View>
            <View style={{ justifyContent: 'space-between', marginBottom: 10 }}>
              <HelperText type="error" visible={getProductInventoryError()?.Message ? true : false}>
                {getProductInventoryError()?.Message}
              </HelperText>
              <HelperText style={{ color: "green", marginLeft: -16, bottom: 12 }} visible={true}>
                {getPartnerOrderPrice().discount}% discount applied successfully.(Saved {(count * partner.partnerProduct.Product_Price - (getPartnerOrderPrice().data)).toFixed(2)}/-)
              </HelperText>
            </View>
          </View>
        </View>
      </View>
      {/* <View style={{ width: "100%" }}> */}
      <EsiSearchDropdown
        style={[styles.dropdown, { borderColor: completeProduct.addressError ? '#b00020' : '#27B6CC' }]}
        data={address}
        placeholderStyle={styles.placeholderStyle}
        selectedTextStyle={styles.selectedTextStyle}
        inputSearchStyle={styles.inputSearchStyle}
        iconStyle={styles.iconStyle}
        labelField="label"
        valueField="value"
        maxHeight={180}
        search
        placeholder='Shipping Address'
        defaultValue={completeProduct.address ? {
          label: completeProduct.address.Address_Type + " " + "," + " " + completeProduct.address.Door_No + " " + "," + " " + completeProduct.address.Land_Mark + " " + "," + " " + completeProduct.address.Street + " " + "," + " " + completeProduct.address.City + " " + "," + " " + completeProduct.address.State + " " + "," + " " + completeProduct.address.Zip,
          value: completeProduct.address?.Partner_Address_Id
        } : ""}
        className="address"
        onUpdateValue={(item: any) => {
          if (item.value === "Add New Address") {
            let testAddress = completeProduct.address?.Partner_Address_Id;
            dispatch(productAddOrRemoveAddress(Logindetails, completeProduct, ''));
            dispatch(productAddOrRemoveAddress(Logindetails, completeProduct, testAddress ? testAddress : ""));
            navigation.navigate('AddAddress', { page: 'cart' });
          } else {
            dispatch(productAddOrRemoveAddress(Logindetails, completeProduct, item.value));
          }
        }}
        value={completeProduct.address ? completeProduct.address?.Partner_Address_Id : ''}
      />
      <HelperText type="error" visible={completeProduct.address ? true : false}>
        {completeProduct.addressError ? completeProduct.addressError : ""}
      </HelperText>
    </View>
    // </View>
  );
}
const styles = StyleSheet.create({
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
    maxHeight: 70,
    minHeight: 40,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  dropdown: {
    maxHeight: 70,
    minHeight: 58,
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  centerElement: { justifyContent: 'center', alignItems: 'center' },

});