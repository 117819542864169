import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import { getDeliveryPesrsonAction, updateShippingAction } from '../../../state/actions/shippingAction';
import Header from '../../auth/components/header';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';

//StatusOption is used to get the data from dropdown
const StatusOption = [
    {
        value: 'NEW',
        label: 'NEW'
    },
    {
        value: 'ACCEPTED',
        label: 'ACCEPTED'
    },
    {
        value: 'PACKED',
        label: 'PACKED'
    },
    {
        value: 'READY-TO-PICKUP',
        label: 'READY TO PICKUP'
    },
    {
        value: 'PICKED',
        label: 'PICKED'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'RETURNED',
        label: 'RETURNED'
    },
    {
        value: 'DELIVERED',
        label: 'DELIVERED'
    }
];

const EditShipping = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    //toast is for popup message after succesfull API call
    const toast = useToast();
    const esiColor = useSelector(state => state.theme);
    const deliverypersons = useSelector(state => state.shipping.deliverypersons.persons);
    const permissions = useSelector(state => state.auth.permissions);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Shipping_Management === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);

    //isSubmitted contains the data from Update API using state call
    const isSubmited = useSelector(state => state.shipping.shippingUpdate.isLoading);
    //shipping is used to store the values from previous page using props
    const shipping = props.route?.params?.data;
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        if (shipping) {
            let filter = {
                Partner_ID: shipping?.Partner_Details_Id,
                Records_Filter: "FILTER"
            }
            dispatch(getDeliveryPesrsonAction(filter))
        }
    }, [])
    let deliverypersonslist = []
    if (deliverypersons) {
        if (deliverypersons[0]) {
            for (let i = 0; i < deliverypersons.length; i++) {
                deliverypersonslist.push({
                    label: deliverypersons[i].Full_Name,
                    value: deliverypersons[i].Del_User_Email_ID
                })
            }
        }
    }
    //Dropdown
    const [statusDropDown, setStatusDropDown] = useState("");
    const [brandDropDown, setPersonDropDown] = useState("");
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    /*====================================
            Handler
    ====================================*/

    const values = {
        Partner_Details_Id: shipping.Partner_Details_Id ? shipping.Partner_Details_Id : "",
        Shipping_Id: shipping.Shipping_Id ? shipping.Shipping_Id : "",
        Shipping_Address_Id: shipping.Shipping_Address_Id ? shipping.Shipping_Address_Id : "",
        Order_Id: shipping.Order_Id ? shipping.Order_Id : "",
        Status: shipping.Order_Status ? shipping.Order_Status : "",
        Item_Master_Id: shipping.Item_Master_Id ? shipping.Item_Master_Id : "",
        Product_Id: shipping.Product_Id ? shipping.Product_Id : "",
        Quantity: shipping.Quantity ? shipping.Quantity : "",
        Shipping_Channel_Partner: shipping.Shipping_Channel_Partner ? shipping.Shipping_Channel_Partner : "",
        Shipping_Delivery_By: shipping.Del_User_Email_ID ? shipping.Del_User_Email_ID : "",
        Shipping_Email: shipping.Shipping_Email ? shipping.Shipping_Email : "",
        Shipping_Reference_Id: shipping.Shipping_Reference_Id ? shipping.Shipping_Reference_Id : "",
        Shipping_Status: shipping.Shipping_Status ? shipping.Shipping_Status : "",
        Modified_By: EmailID.Email_Id
    }

    //Form validation
    const Form_Validation = Yup.object().shape({
        Shipping_Status: Yup.string().required('Shipping Status is required.')
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(updateShippingAction(formValues, navigation, toast));
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          text: esiColor.Text,
          placeholder: esiColor.TextPlaceholder
        },
      };

      const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
          },
          dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
          },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1,backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView  refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('ShippingGet')}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                 width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                 marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                 backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <View pointerEvents={!(shipping.Shipping_Channel_Partner === 'Partner') ? 'none' : 'auto'}>
                                        <Text style={{ fontSize: 20, fontWeight: "bold" ,color: esiColor.brandFontColor}}>
                                            Edit Shipping
                                        </Text>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Shipping ID field with data*/}
                                                <TextInput
                                                   theme={customTheme}
                                                   style={{
                                                     marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                     borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                   }}
                                                    label='Shipping ID'
                                                    mode='outlined'
                                                    name="Shipping_Id"
                                                    value={props.values.Shipping_Id}
                                                    onChangeText={props.handleChange('Shipping_Id')}
                                                    disabled
                                                />
                                            </View>
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Shipping_Address_Id field with data*/}
                                                <TextInput
                                                   theme={customTheme}
                                                   style={{
                                                     marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                     borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                   }}
                                                    label='Shipping Address ID'
                                                    mode='outlined'
                                                    name="Shipping_Address_Id"
                                                    value={props.values.Shipping_Address_Id}
                                                    onChangeText={props.handleChange('Shipping_Address_Id')}
                                                    disabled
                                                />
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Order_Id field with data*/}
                                                <TextInput
                                                    theme={customTheme}
                                                    style={{
                                                      marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                      borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                    }}
                                                    label='Order ID'
                                                    mode='outlined'
                                                    name="Order_Id"
                                                    value={props.values.Order_Id}
                                                    onChangeText={props.handleChange('Order_Id')}
                                                    disabled
                                                />
                                            </View>
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Status field with data*/}
                                                <TextInput
                                                    theme={customTheme}
                                                    style={{
                                                      marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                      borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                    }}
                                                    label='Order Status'
                                                    mode='outlined'
                                                    name="Status"
                                                    value={props.values.Status}
                                                    onChangeText={props.handleChange('Status')}
                                                    disabled
                                                />
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Item_Master_Id field with data*/}
                                                <TextInput
                                                   theme={customTheme}
                                                   style={{
                                                     marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                     borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                   }}
                                                    label='Item Master ID'
                                                    mode='outlined'
                                                    name="Item_Master_Id"
                                                    value={props.values.Item_Master_Id}
                                                    onChangeText={props.handleChange('Item_Master_Id')}
                                                    disabled
                                                />
                                            </View>
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Product_Id field with data*/}
                                                <TextInput
                                                   theme={customTheme}
                                                   style={{
                                                     marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                     borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                   }}
                                                    label='Product ID'
                                                    mode='outlined'
                                                    name="Product_Id"
                                                    value={props.values.Product_Id}
                                                    onChangeText={props.handleChange('Product_Id')}
                                                    disabled
                                                />
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Quantity field with data*/}
                                                <TextInput
                                                    theme={customTheme}
                                                    style={{
                                                      marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                      borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                    }}
                                                    label='Quantity'
                                                    mode='outlined'
                                                    name="Quantity"
                                                    value={props.values.Quantity}
                                                    onChangeText={props.handleChange('Quantity')}
                                                    disabled
                                                />
                                            </View>
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Shipping_Channel_Partner field with data*/}
                                                <TextInput
                                                   theme={customTheme}
                                                   style={{
                                                     marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                     borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                   }}
                                                    label='Shipping Channel Partner'
                                                    mode='outlined'
                                                    name="Shipping_Channel_Partner"
                                                    value={props.values.Shipping_Channel_Partner}
                                                    onChangeText={props.handleChange('Shipping_Channel_Partner')}
                                                    disabled
                                                />
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 21 }}>
                                                {/* Shipping_Status input field with validation*/}
                                                <Text style={[styles.dropdownlabel, Boolean(props.touched.Shipping_Status && props.errors.Shipping_Status) && { color: '#b00020' }]}>
                                                    Shipping Status
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(props.touched.Shipping_Status && props.errors.Shipping_Status) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={deliverypersonslist}
                                                    labelField="label"
                                                    valueField="value"
                                                    maxHeight={deliverypersonslist.length > 1 ? 250 : 200}
                                                    search={deliverypersonslist.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Shipping Status'}
                                                    dropdownPosition="auto"
                                                    value={props.values.Shipping_Status}
                                                    name="Shipping_Status"
                                                    onFocus={() => setPersonDropDown(true)}
                                                    onBlur={() => setPersonDropDown(false)}
                                                    onUpdateValue={item => {
                                                        props.setFieldValue('Shipping_Status', item.value)
                                                        setPersonDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Shipping_Status && props.errors.Shipping_Status)}>
                                                    <ErrorMessage name="Shipping_Status" />
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Shipping_Email field with data*/}
                                                <TextInput
                                                   theme={customTheme}
                                                   style={{
                                                     marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                     borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                   }}
                                                    label='Shipping Email'
                                                    mode='outlined'
                                                    name="Shipping_Email"
                                                    value={props.values.Shipping_Email}
                                                    onChangeText={props.handleChange('Shipping_Email')}
                                                    disabled
                                                />
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Shipping_Reference_Id field with data*/}
                                                <TextInput
                                                    theme={customTheme}
                                                    style={{
                                                      marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                      borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                    }}
                                                    label='Shipping Reference ID'
                                                    mode='outlined'
                                                    name="Shipping_Reference_Id"
                                                    value={props.values.Shipping_Reference_Id}
                                                    onChangeText={props.handleChange('Shipping_Reference_Id')}
                                                    disabled
                                                />
                                            </View>
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 21 }}>
                                                {/* Shipping_Status input field with validation*/}
                                                <Text style={[styles.dropdownlabel, Boolean(props.touched.Shipping_Status && props.errors.Shipping_Status) && { color: '#b00020' }]}>
                                                    Shipping Status
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(props.touched.Shipping_Status && props.errors.Shipping_Status) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={StatusOption}
                                                    labelField="label"
                                                    valueField="value"
                                                    maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                    search={StatusOption.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Shipping Status'}
                                                    dropdownPosition="auto"
                                                    value={props.values.Shipping_Status}
                                                    name="Shipping_Status"
                                                    onFocus={() => setStatusDropDown(true)}
                                                    onBlur={() => setStatusDropDown(false)}
                                                    onUpdateValue={item => {
                                                        props.setFieldValue('Shipping_Status', item.value)
                                                        setStatusDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Shipping_Status && props.errors.Shipping_Status)}>
                                                    <ErrorMessage name="Shipping_Status" />
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                            <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                                Save
                                            </Button>
                                        </View>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default EditShipping;