import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Card, HelperText, Surface, Text, TextInput } from 'react-native-paper';
import { Dimensions, Platform, RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import Header from '../auth/components/header';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { EditTicketsAction, uploadTicketAttachment } from '../../state/actions/ticketActions';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import EsiEditor from '../../components/custom/richtext-editor/richtext-editor';
import FileUpload from '../../components/custom/file-upload/upload';
import AsyncStorage from '@react-native-async-storage/async-storage';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const UserType = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'PARTNER_ADMIN',
        label: 'ADMIN'
    },
    {
        value: 'PARTNER_EMPLOYEE',
        label: 'EMPLOYEE'
    }
];

const Status = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const EditTicket = (props: any) => {

    const ticket = props.route?.params?.ticket;
    const { navigation } = props;

    const dispatch = useDispatch();
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Ticket_Management === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

    const [errorMessage, setErrorMessage] = useState({ Ticket_Subject: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
    const isSubmited = useSelector(state => state.ticket.ticketUpdate.isLoading);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    // Alert Popup
    const [rhDiscription, setRhDiscription] = useState(ticket.Ticket_Description);
    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);

    const [showDropDown, setShowDropDown] = useState(false);
    const [showstatusDropDown, setstatusShowDropDown] = useState(false);

    // img upload

    const [file, setFile] = useState(ticket?.Ticket_Attachment?.split("/").pop());
    const [fileUrl, setFileUrl] = useState(ticket?.Ticket_Attachment);
    const [uploadFile, setUploadFile] = useState(false);

    const callBackUploadFile = (data: any) => {
        if (data) {
            setFileUrl(data.File_URL);
        }
        setUploadFile(false);
    };

    const handleFile = async (result: any) => {

        if (result) {
            let call = result.file
            let fileData = {
                fileName: result.name.split(".")[0],
                ext: result.name.split(".")[1],
                TicketsId: "T-000001",
            };
            const imageData = await fetchFileFromUri(call);
            setFile(result.name);
            dispatch(uploadTicketAttachment(() => { }, imageData, fileData, callBackUploadFile));
        };
    }
    const fetchFileFromUri = async (uri: any) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        return blob;
    };
    const handleRemoveFile = e => {
        setFileUrl('');
        setFile('');
    };

    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);


    return (
        <Surface style={{ flex: 1 }} >
            <Header navigation={navigation} />
            <ScrollView  refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik
                    initialValues={{
                        Partner_Details_Id: EmailID?.Partner_Details_Id,
                        Ticket_Id: ticket?.Ticket_Id,
                        Ticket_Subject: ticket?.Ticket_Subject,
                        Ticket_Status: ticket?.Ticket_Status,
                        Ticket_Description: ticket?.Ticket_Description,
                        Ticket_User_Type: ticket?.Ticket_User_Type,
                        Modified_By: EmailID?.Email_Id,
                        Attachment: fileUrl
                    }}
                    validationSchema={Yup.object().shape({
                        Ticket_Subject: Yup.string().required('Subject is required.'),
                        Ticket_Status: Yup.string().required('Status is required.'),
                        Ticket_User_Type: Yup.string().required('User Type is required.'),
                    })}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting, setErrors }) => {

                        const errors = {};
                        let submitTest = true;

                        values.Attachment = fileUrl;
                        values.Ticket_Description = rhDiscription;

                        setSubmitting(false);
                        if (!values.Attachment) {
                            submitTest = false;
                            errors.Attachment = "Attachment is required.";
                        }
                        if (!values.Ticket_Description || values.Ticket_Description.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
                            submitTest = false;
                            errors.Ticket_Description = "Description is required.";
                        }
                        else if (values.Ticket_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                            submitTest = false;
                            errors.Ticket_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                        }

                        setErrors(errors);

                        if (submitTest) {
                            let formData = JSON.parse(JSON.stringify(values));
                            dispatch(EditTicketsAction(formData, navigation, setErrorMessage, setErrors, toast))
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        resetForm
                    }) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        resetForm();
                                        navigation.navigate('Tickets')
                                    }}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={styles.cardCircle}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Edit Ticket
                                    </Text>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-evenly",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexShrink: 1, width: 750, paddingTop: 15 }}>
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                error={Boolean(touched.Ticket_Subject && errors.Ticket_Subject)}
                                                onBlur={handleBlur}
                                                onChangeText={handleChange('Ticket_Subject')}
                                                label="Subject"
                                                name="Ticket_Subject"
                                                value={values.Ticket_Subject}
                                                mode="outlined"
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Ticket_Subject && errors.Ticket_Subject)}>
                                                {touched.Ticket_Subject && errors.Ticket_Subject}
                                            </HelperText>
                                            <View style={{ paddingTop: Platform.OS !== "web" ? 10 : 10, paddingBottom: Platform.OS !== "web" ? 10 : null }}>
                                                <EsiEditor label={"Description"} value={rhDiscription} onUpdateValue={(value) => { setRhDiscription(value) }} />
                                                <HelperText type="error" visible={(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Ticket_Description
                                                    && errors.Ticket_Description}>
                                                    {(!rhDiscription || rhDiscription.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Ticket_Description
                                                        && errors.Ticket_Description}
                                                </HelperText>
                                            </View>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Ticket_User_Type && errors.Ticket_User_Type) && { color: '#b00020' }]}>
                                                    User Type
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Ticket_User_Type && errors.Ticket_User_Type) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={UserType}
                                                    maxHeight={UserType.length > 1 ? 250 : 200}
                                                    search={UserType.length > 1 ? true : false}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder={!showDropDown ? 'Select item' : '...'}
                                                    searchPlaceholder={'Select User Type'}
                                                    value={values.Ticket_User_Type}
                                                    onFocus={() => setShowDropDown(true)}
                                                    onBlur={() => setShowDropDown(false)}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Ticket_User_Type', item.value)
                                                        setShowDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Ticket_User_Type && errors.Ticket_User_Type)}>
                                                    {touched.Ticket_User_Type && errors.Ticket_User_Type}
                                                </HelperText>
                                            </View>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Ticket_Status && errors.Ticket_Status) && { color: '#b00020' }]}>
                                                    Status
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Ticket_Status && errors.Ticket_Status) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={Status}
                                                    maxHeight={Status.length > 1 ? 250 : 200}
                                                    search={Status.length > 1 ?true:false}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder={!showstatusDropDown ? 'Select item' : '...'}
                                                    searchPlaceholder={'Select  Status'}
                                                    value={values.Ticket_Status}
                                                    onFocus={() => setstatusShowDropDown(true)}
                                                    onBlur={() => setstatusShowDropDown(false)}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Ticket_Status', item.value)
                                                        setstatusShowDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Ticket_Status && errors.Ticket_Status)}>
                                                    {touched.Ticket_Status && errors.Ticket_Status}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexShrink: 1, width: 270, paddingTop: 20 }} >
                                            <FileUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} filestyle={styles.files} handleRemoveFile={handleRemoveFile} file={file} uploadFile={handleFile} />
                                            <HelperText type="error" visible={!fileUrl && touched.Attachment && errors.Attachment}>
                                                {!fileUrl && touched.Attachment && errors.Attachment}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button disabled={isSubmited || uploadFile} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                                            Save
                                        </Button>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};
const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        paddingTop: 15
    },
    cardCircle: {
        width: (window.width >= 500) ? "75%" : "97%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2%",
        marginBottom: "2%",
        borderRadius: 6,
        borderWidth: 0.01,
        borderColor: '#D6D6D7'
    },
    itemContainer: {
        justifyContent: 'flex-end',
        flex: 1
    },
    files: {
        width: '100%',
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    checkbox: {
        alignSelf: "center",
    },
    label: {
        marginBottom: 30,
    },
    phonetextInput: {
        flex: 1
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});


export default EditTicket;