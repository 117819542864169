import axios from 'axios';
import config from '../config';
import shopperconfig from './shopperconfig';
export const CATEGORYRESET = "CATEGORYRESET";
export const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";

const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
/*=============================================================
                  Get All Categories Action
===============================================================*/




export const getAllfilterCategoriesAction = (formData:any,callBack: any) => async (dispatch: any) => {
  try {
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2B_Categories/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    
    var AllCategoriesData = data.AllCategories[0]
    if (data) {
      callBack(AllCategoriesData.Category)
    }
  } catch (error) {
  }
};


export const getFilterCategoriesAction = (formData:any,callBack: any) => async (dispatch: any) => {
  try {
    let { data } = await axios.post(
      `${config.url}/Partner_Categories/Get?PageNo=0&PageSize=500&SortBy=CATEGORY_NAME&SortOrder=ASC`,
      formData,
      {
        headers: headers
      }
    );
    if (data) {
      callBack(data)
    }
  } catch (error) {
  }
};
