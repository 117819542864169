import React, { useState, useCallback} from 'react';
import { Card, Button, TextInput, HelperText,  Surface, ActivityIndicator } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../auth/components/header';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import isEmpty from '../../../../state/validations/is-empty';
import { getProductPartnerAction, productpartnerdata } from '../../../../state/actions/partnerproductsAction';
import { Formik } from 'formik';



const Discount_Type = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'A',
        label: 'Amount'
    },
    {
        value: 'P',
        label: 'Percentage'
    }
];


//discountlabelType and cashbacklabelType are used to get the data from dropdown
const discountlabelType = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'UPTO',
        label: 'UPTO'
    },
    {
        value: 'FLAT',
        label: 'FLAT'
    },
];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");


const EditProductPartner = (props: any) => {
    const { navigation } = props;
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Products_Partner_Products_Related_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const [errorMessage, setErrorMessage] = useState()
    const productid = props.route?.params?.productid;
    const partnerproductid = props.route?.params?.partnerproductid;
    const dispatch = useDispatch();
    const editpartnerproductdetails = useSelector(state => state.productpartners.partnerproduct.all);
    const [showDropDownProductPartnersDiscount, setShowDropDownProductPartnersDiscount] = React.useState('');
    const [disclabelDropDown, setdisclabelDropDown] = React.useState("");
    const [partnerproduct, setPartnerProduct] = React.useState();
    React.useEffect(() => {
        if(isEmpty(editpartnerproductdetails)||partnerproductid!==partnerproduct){
        const formData = {
            "Partner_Product_Id":partnerproductid ,
            "Product_Id":productid,
            "Records_Filter": "FILTER"
          };
          setPartnerProduct(partnerproductid);
        dispatch(getProductPartnerAction(formData))
}
}, [editpartnerproductdetails,partnerproductid]);
    let Product

    // screen Dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
      /*====================================
            Handler
    ====================================*/
    const values = {
        Partner_Product_Id: editpartnerproductdetails.Partner_Product_Id,
        Price: editpartnerproductdetails.Partner_Price,
        Gift_Wrap_Amount: editpartnerproductdetails.Gift_Wrap_Amount,
        Discount_Type: editpartnerproductdetails.Discount_Type,
        Discount: editpartnerproductdetails.Discount,
        Esi_Cashback_Type: editpartnerproductdetails.Esi_Cashback_Type,
        Esi_Cashback: editpartnerproductdetails.Esi_Cashback,
        Discount_From_Date: editpartnerproductdetails.Discount_From_Date,
        Discount_To_Date: editpartnerproductdetails.Discount_To_Date,
        Max_Days_To_Return: editpartnerproductdetails.Max_Days_To_Return,
        Shipping_Amount: editpartnerproductdetails.Shipping_Amount,
        Modified_By: EmailID.Email_Id,
        Is_Partner_Shipping_Available: editpartnerproductdetails.Is_Partner_Shipping_Available > 0 ? true : false,
        Add_To_Cart: editpartnerproductdetails.Add_To_Cart > 0 ? true : false,
        Esi_Commission: editpartnerproductdetails.Esi_Commission,
        Esi_Commission_Type: editpartnerproductdetails.Esi_Commission_Type,
        Exclusive: editpartnerproductdetails.Exclusive > 0 ? true : false,
        Extra_CashBack: editpartnerproductdetails.Extra_Cashback > 0 ? true : false,
        Get_Coupon: editpartnerproductdetails.Get_Coupon > 0 ? true : false,
        Is_Default: editpartnerproductdetails.Is_Default > 0 ? true : false,
        Is_Esi_Shipping_Aavailable: editpartnerproductdetails.Is_Shipping_Available > 0 ? true : false,
        Is_Gift_Wrap_Available: editpartnerproductdetails.Is_Gift_Wrap_Available > 0 ? true : false,
        Is_Popular: editpartnerproductdetails.Is_Popular > 0 ? true : false,
        Is_Return_Available: editpartnerproductdetails.Is_Return_Available > 0 ? true : false,
        Is_Upcoming: editpartnerproductdetails.Is_Coming > 0 ? true : false,
        Partner_Address_Id: editpartnerproductdetails.Partner_Address_Id,
        Partner_Details_Id: editpartnerproductdetails.Partner_Details_Id,
        Product_Id: editpartnerproductdetails.Product_Id,
        Status: editpartnerproductdetails.Status,
        Todays_Hot: editpartnerproductdetails.Todays_Hot > 0 ? true : false,
        Verified: editpartnerproductdetails.Verified > 0 ? true : false,
        What_Hot: editpartnerproductdetails.Whats_Hot > 0 ? true : false,
        Cashback_Label: editpartnerproductdetails.Cashback_Label,
        Discount_Label: editpartnerproductdetails.Discount_Label,
        Warranty_Period: editpartnerproductdetails.Warranty_Period,
        Is_Shop_By_City_Only: editpartnerproductdetails.Is_Shop_By_City_Only > 0 ? true : false
    }
    //Form Validations
    const Form_Validation = Yup.object().shape({
        Price: Yup.number().required(' Price is required.'),
        Warranty_Period: Yup.string().required('Warranty Period is required.'),
        Discount_Type: Yup.string().test('oneOfRequired', 'Discount Type is required.',
            function (item) {
                return (this.parent.Discount_Type)
            }
        ),
        Discount: Yup.number().when("Discount_Type", (Discount_Type, schema) => {
            if (Discount_Type == "A") {
                return schema.min(1, "Discount Amount must be at least 1.").required('Discount Amount is required.');
            } else if (Discount_Type == "P") {
                return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be less than 100.").required('Discount Percentage is required.');
            }
        }),
        Discount_Label: Yup.string().when("Discount", (Discount, schema) => {
            if (Discount) {
                return schema.required('Discount Label is required.');
            }
        }),
        
    })
    const onSubmit = (values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Add_To_Cart) {
                formValues.Add_To_Cart = 1;
            } else {
                formValues.Add_To_Cart = 0;
            }
            if (values.Is_Gift_Wrap_Available) {
                formValues.Is_Gift_Wrap_Available = 1;
            } else {
                formValues.Is_Gift_Wrap_Available = 0;
            }
            if (values.Is_Esi_Shipping_Aavailable) {
                formValues.Is_Esi_Shipping_Aavailable = 1;
            } else {
                formValues.Is_Esi_Shipping_Aavailable = 0;
            }
            if (values.Verified) {
                formValues.Verified = 1;
            } else {
                formValues.Verified = 0;
            }
            if (values.Get_Coupon) {
                formValues.Get_Coupon = 1;
            } else {
                formValues.Get_Coupon = 0;
            }
            if (values.Exclusive) {
                formValues.Exclusive = 1;
            } else {
                formValues.Exclusive = 0;
            }
            if (values.Is_Popular) {
                formValues.Is_Popular = 1;
            } else {
                formValues.Is_Popular = 0;
            }
            if (values.What_Hot) {
                formValues.What_Hot = 1;
            } else {
                formValues.What_Hot = 0;
            }
            if (values.Todays_Hot) {
                formValues.Todays_Hot = 1;
            } else {
                formValues.Todays_Hot = 0;
            }
            if (values.Extra_CashBack) {
                formValues.Extra_CashBack = 1;
            } else {
                formValues.Extra_CashBack = 0;
            }
            if (values.Is_Upcoming) {
                formValues.Is_Upcoming = 1;
            } else {
                formValues.Is_Upcoming = 0;
            }
            if (values.Is_Default) {
                formValues.Is_Default = 1;
            } else {
                formValues.Is_Default = 0;
            }
            if (values.Is_Esi_Shipping_Available) {
                formValues.Is_Esi_Shipping_Available = 1;
            } else {
                formValues.Is_Esi_Shipping_Available = 0;
            }
            if (values.Is_Partner_Shipping_Available) {
                formValues.Is_Partner_Shipping_Available = 1;
            } else {
                formValues.Is_Partner_Shipping_Available = 0;
            }
            if (values.Is_Return_Available) {
                formValues.Is_Return_Available = 1;
            } else {
                formValues.Is_Return_Available = 0;
            }
            dispatch(productpartnerdata(formValues));
            navigation.navigate('EditPartnerProductsPagetwo')
        }
    }
    return (
        <Surface style={{
            flex: 1
        }}>
            <Header navigation={navigation} />
            <ScrollView  refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
              {!isEmpty(editpartnerproductdetails)?  <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        props.resetForm();
                                        navigation.navigate('ProductPartnerGet', { productpartnerparams: editpartnerproductdetails.Product_Id })
                                    }} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#d6d6d7' }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Edit Product Partner
                                    </Text>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(props.touched.Price && props.errors.Price)}
                                                    label="Price"
                                                    mode='outlined'
                                                    value={props.values.Price}
                                                    onChangeText={props.handleChange("Price")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Price && props.errors.Price)}>
                                                    {props.touched.Price && props.errors.Price}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(props.touched.Gift_Wrap_Amount && props.errors.Gift_Wrap_Amount)}
                                                    label="Gift Wrap Amount"
                                                    mode='outlined'
                                                    value={props.values.Gift_Wrap_Amount}
                                                    onChangeText={props.handleChange("Gift_Wrap_Amount")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Gift_Wrap_Amount && props.errors.Gift_Wrap_Amount)}>
                                                    {props.touched.Gift_Wrap_Amount && props.errors.Gift_Wrap_Amount}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { color: '#b00020' }]}>
                                                        Discount Type
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={Discount_Type}
                                                        labelField="label"
                                                        valueField="value"
                                                        maxHeight={Discount_Type.length > 1 ? 250 : 200}
                                                        search={Discount_Type.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Discount Type'}
                                                        placeholder={!showDropDownProductPartnersDiscount ? 'Select item' : '...'}
                                                        value={props.values.Discount_Type}
                                                        onUpdateValue={item => {
                                                            props.setFieldValue('Discount_Type', item.value)
                                                            setShowDropDownProductPartnersDiscount(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(props.touched.Discount_Type && props.errors.Discount_Type)}>
                                                        {props.touched.Discount_Type && props.errors.Discount_Type}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1.6%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    disabled={props.values.Discount_Type ? false : true}
                                                    error={Boolean(props.touched.Discount && props.errors.Discount)}
                                                    label={"Discount " + (props.values.Discount_Type === "A" ? "Amount ₹" : props.values.Discount_Type === "P" ? "Percentage %" : "")}
                                                    mode='outlined'
                                                    value={props.values.Discount}
                                                    onChangeText={props.handleChange("Discount")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Discount && props.errors.Discount)}>
                                                    {props.touched.Discount && props.errors.Discount}
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { color: '#b00020' }]}>
                                                        Discount Label
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={discountlabelType}
                                                        labelField="label"
                                                        valueField="value"
                                                        maxHeight={discountlabelType.length > 1 ? 250 : 200}
                                                        search={discountlabelType.length > 1 ? true : false}
                                                        searchPlaceholder={'Select  Discount Label'}
                                                        disable={props.values.Discount ? false : true}
                                                        placeholder={!disclabelDropDown ? 'Select item' : '...'}
                                                        value={props.values.Discount_Label}
                                                        onUpdateValue={item => {
                                                            props.setFieldValue('Discount_Label', item.value)
                                                            setdisclabelDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(props.touched.Discount_Label && props.errors.Discount_Label)}>
                                                        {props.touched.Discount_Label && props.errors.Discount_Label}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(props.touched.Warranty_Period && props.errors.Warranty_Period)}
                                                    label="Warranty Period"
                                                    mode='outlined'
                                                    multiline
                                                    value={props.values.Warranty_Period}
                                                    onChangeText={props.handleChange("Warranty_Period")}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Warranty_Period && props.errors.Warranty_Period)}>
                                                    {props.touched.Warranty_Period && props.errors.Warranty_Period}
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            </View>

                                        </View>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                        </View>
                                        <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                            <Button style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={props.handleSubmit}>
                                                Next
                                            </Button>
                                        </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>: <ActivityIndicator  color='#27b6cc' style={{ marginVertical: 20, marginHorizontal: 150 }} />}
            </ScrollView >
        </Surface >
    );
};
const styles = StyleSheet.create({
    cardCircle: {
        width: (Platform.OS === 'web') ? "75%" : "95%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2%",
        marginBottom: "2%",
    }, datePickerStyle: {
        width: 350,
        height: 60,
        marginTop: 6,
    },
    itemContainer: {
        justifyContent: 'flex-end',
        flex: 1
    },
    images: {
        width: '100%',
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    checkbox: {
        flexDirection: "row",
        justifyContent: 'flex-start'
    },
    label: {
        marginBottom: 30,
    },
    editor: {
        backgroundColor: "black",
        borderColor: "black",
        borderWidth: 1,
    },
    rich: {
        minHeight: 130,
        // flex: 1,
    },
    richBar: {
        height: 50,
        backgroundColor: "#F5FCFF",
    },
    text: {
        fontWeight: "bold",
        fontSize: 18,
    },
    tib: {
        textAlign: "center",
        color: "#515156",
    },
    a: {
        fontWeight: "bold",
        color: "purple",
    },
    div: {
        fontFamily: "monospace",
    },
    p: {
        fontSize: 30,
    }, container: {
        backgroundColor: 'white',
        paddingTop: 15
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});

export default EditProductPartner;