import axios from 'axios';
import config from '../config';
import { meeapi } from '../node-actions/meeapi';
import shopperconfig from '../shoppers/shopperconfig';
export const USERSERVICES_REQUEST = "USERSERVICES_REQUEST";
export const USERSERVICES_SUCCESS = "USERSERVICES_SUCCESS";
export const USERSERVICES_FAIL = "USERSERVICES_FAIL";
export const EMPLOYEE_FAIL = "EMPLOYEE_FAIL";
export const USER_SERVICES_FAIL = "USER_SERVICES_FAIL";
export const NEWUSERSERVICES_REQUEST = "NEWUSERSERVICES_REQUEST";
export const NEWUSERSERVICES_SUCCESS = "NEWUSERSERVICES_SUCCESS";
export const NEWUSERSERVICES_FAIL = "NEWUSERSERVICES_FAIL";
export const USERSERVICES_GET_REQUEST = "USERSERVICES_GET_REQUEST";
export const USERSERVICES_GET_SUCCESS = "USERSERVICES_GET_SUCCESS";
export const USERSERVICES_GET_FAIL = "USERSERVICES_GET_FAIL";
export const USERSERVICES_UPDATE_REQUEST = "USERSERVICES_UPDATE_REQUEST";
export const USERSERVICES_UPDATE_SUCCESS = "USERSERVICES_UPDATE_SUCCESS";
export const USERSERVICES_UPDATE_FAIL = "USERSERVICES_UPDATE_FAIL";
export const USERSERVICES_DATA="USERSERVICES_DATA"
export const DASHBOARD_SERVICE_TOTAL_COUNT = "DASHBOARD_SERVICE_TOTAL_COUNT";
export const DASHBOARD_PARTNER_SERVICE_COUNT="DASHBOARD_PARTNER_SERVICE_COUNT";
export const DASHBOARD_USER_SERVICE_TOTAL_COUNT="DASHBOARD_USER_SERVICE_TOTAL_COUNT";		
export const ACCEPTED_USER_SERVICES_REQUEST ="ACCEPTED_USER_SERVICES_REQUEST";
export const ACCEPTED_USER_SERVICES_SUCCESS ="ACCEPTED_USER_SERVICES_SUCCESS";
export const ACCEPTED_USER_SERVICES_FAIL ="ACCEPTED_USER_SERVICES_FAIL";
export const IN_PROGRESS_USER_SERVICES_REQUEST ="IN_PROGRESS_USER_SERVICES_REQUEST";
export const IN_PROGRESS_USER_SERVICES_SUCCESS ="IN_PROGRESS_USER_SERVICES_SUCCESS";
export const IN_PROGRESS_USER_SERVICES_FAIL ="IN_PROGRESS_USER_SERVICES_FAIL";
export const REJECTED_USER_SERVICES_REQUEST ="REJECTED_USER_SERVICES_REQUEST";
export const REJECTED_USER_SERVICES_SUCCESS ="REJECTED_USER_SERVICES_SUCCESS";
export const REJECTED_USER_SERVICES_FAIL ="REJECTED_USER_SERVICES_FAIL";
export const COMPLETED_USER_SERVICES_REQUEST ="COMPLETED_USER_SERVICES_REQUEST";
export const COMPLETED_USER_SERVICES_SUCCESS ="COMPLETED_USER_SERVICES_SUCCESS";
export const COMPLETED_USER_SERVICES_FAIL ="COMPLETED_USER_SERVICES_FAIL";

export const EMPLOYEE_NEW_USER_SERVICES_REQUEST ="EMPLOYEE_NEW_USER_SERVICES_REQUEST";
export const EMPLOYEE_NEW_USER_SERVICES_SUCCESS ="EMPLOYEE_NEW_USER_SERVICES_SUCCESS";
export const EMPLOYEE_NEW_USER_SERVICES_FAIL ="EMPLOYEE_NEW_USER_SERVICES_FAIL";
export const EMPLOYEE_IN_PROGRESS_USER_SERVICES_REQUEST ="EMPLOYEE_IN_PROGRESS_USER_SERVICES_REQUEST";
export const EMPLOYEE_IN_PROGRESS_USER_SERVICES_SUCCESS ="EMPLOYEE_IN_PROGRESS_USER_SERVICES_SUCCESS";
export const EMPLOYEE_IN_PROGRESS_USER_SERVICES_FAIL ="EMPLOYEE_IN_PROGRESS_USER_SERVICES_FAIL";
export const EMPLOYEE_COMPLETED_USER_SERVICES_REQUEST ="EMPLOYEE_COMPLETED_USER_SERVICES_REQUEST";
export const EMPLOYEE_COMPLETED_USER_SERVICES_SUCCESS ="EMPLOYEE_COMPLETED_USER_SERVICES_SUCCESS";
export const EMPLOYEE_COMPLETED_USER_SERVICES_FAIL ="EMPLOYEE_COMPLETED_USER_SERVICES_FAIL";

export const USERSERVICES_FILTER = "USERSERVICES_FILTER";
export const NEWUSERSERVICES_FILTER = "NEWUSERSERVICES_FILTER";

const nodeheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
      queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}  

/*=============================================================
                Get All User Services  Action
===============================================================*/
export const userserviceData = (value: any) => {
  return function (dispatch) {
    dispatch({
      type: USERSERVICES_DATA,
      value: value,
    });
  }
}
export const getUserservicesRequestsAction = (formData: any,pagination = {}) => async dispatch => {

  try {
    dispatch({
      type: USERSERVICES_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_User_Service_Request/Partner_User_Service_Get`,pagination),
    formData,
    ) .then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USERSERVICES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: USERSERVICES_SUCCESS,
          payload: data
        });
      
    }
  })
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAIL,
      payload: err
    });
  }
};
export const getAcceptedUserservicesAction = (formData: any,pagination = {}) => async dispatch => {

  try {
    dispatch({
      type: ACCEPTED_USER_SERVICES_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_User_Service_Request/Get`,pagination),
    formData,
    ) .then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ACCEPTED_USER_SERVICES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: ACCEPTED_USER_SERVICES_SUCCESS,
          payload: data
        });
      
    }
  })
  } catch (err) {
    dispatch({
      type: ACCEPTED_USER_SERVICES_FAIL,
      payload: err
    });
  }
};
  export const getInprogrssUserservicesAction = (formData: any,pagination = {}) => async dispatch => {

    try {
      dispatch({
        type: IN_PROGRESS_USER_SERVICES_REQUEST
      });
      meeapi.post(urlGenarator(`/Partner_User_Service_Request/Get`,pagination),
      formData,
      ) .then(response => {
        let data = response.data;
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: IN_PROGRESS_USER_SERVICES_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: IN_PROGRESS_USER_SERVICES_SUCCESS,
            payload: data
          });
        
      }
    })
    } catch (err) {
      dispatch({
        type: IN_PROGRESS_USER_SERVICES_FAIL,
        payload: err
      });
    }
  };
  export const getRejectedUserservicesAction = (formData: any,pagination = {}) => async dispatch => {

    try {
      dispatch({
        type: REJECTED_USER_SERVICES_REQUEST
      });
      meeapi.post(urlGenarator(`/Partner_User_Service_Request/Get`,pagination),
      formData,
      ) .then(response => {
        let data = response.data;
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: REJECTED_USER_SERVICES_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: REJECTED_USER_SERVICES_SUCCESS,
            payload: data
          });
        
      }
    })
    } catch (err) {
      dispatch({
        type: REJECTED_USER_SERVICES_FAIL,
        payload: err
      });
    }
  };
  export const getCompletedUserservicesAction = (formData: any,pagination = {}) => async dispatch => {

    try {
      dispatch({
        type: COMPLETED_USER_SERVICES_REQUEST
      });
      meeapi.post(urlGenarator(`/Partner_User_Service_Request/Get`,pagination),
      formData,
      ) .then(response => {
        let data = response.data;
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: COMPLETED_USER_SERVICES_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: COMPLETED_USER_SERVICES_SUCCESS,
            payload: data
          });
        
      }
    })
    } catch (err) {
      dispatch({
        type: COMPLETED_USER_SERVICES_FAIL,
        payload: err
      });
    }
  };
  export const getEmployeNewRequestsActionget = (formData: any,pagination = {}) => async dispatch => {

    try {
      dispatch({
        type: EMPLOYEE_NEW_USER_SERVICES_REQUEST
      });
      meeapi.post(urlGenarator(`/Partner_User_Service_Request/Get`,pagination),
      formData,
      ) .then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === '1') {
          dispatch({
            type: EMPLOYEE_NEW_USER_SERVICES_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: EMPLOYEE_NEW_USER_SERVICES_FAIL,
            payload: data
          });
        
      }
    })
    } catch (err) {
      dispatch({
        type: EMPLOYEE_NEW_USER_SERVICES_FAIL,
        payload: err
      });
    }
  };
  export const EmployeeinprogressRequestsAction = (formData: any,pagination = {}) => async dispatch => {

    try {
      dispatch({
        type: EMPLOYEE_IN_PROGRESS_USER_SERVICES_REQUEST
      });
      meeapi.post(urlGenarator(`/Partner_User_Service_Request/Get`,pagination),
      formData,
      ) .then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === '1') {
          dispatch({
            type: EMPLOYEE_IN_PROGRESS_USER_SERVICES_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: EMPLOYEE_IN_PROGRESS_USER_SERVICES_FAIL,
            payload: data
          });
        
      }
    })
    } catch (err) {
      dispatch({
        type: EMPLOYEE_IN_PROGRESS_USER_SERVICES_FAIL,
        payload: err
      });
    }
  };
  export const getEmployeCompletedRequestsAction = (formData: any,pagination = {}) => async dispatch => {

    try {
      dispatch({
        type: EMPLOYEE_COMPLETED_USER_SERVICES_REQUEST
      });
      meeapi.post(urlGenarator(`/Partner_User_Service_Request/Get`,pagination),
      formData,
      ) .then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist === '1') {
          dispatch({
            type: EMPLOYEE_COMPLETED_USER_SERVICES_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: EMPLOYEE_COMPLETED_USER_SERVICES_FAIL,
            payload: data
          });
        
      }
    })
    } catch (err) {
      dispatch({
        type: EMPLOYEE_COMPLETED_USER_SERVICES_FAIL,
        payload: err
      });
    }
  };
/*=============================================================
                Update User Services Action
===============================================================*/

export const updateUserservicesAction = (formData: any, navigation: any, toast: any,ID:any,EmailID:any) => async dispatch => {
  dispatch({
    type: USERSERVICES_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Partner_User_Service_Request/Update`, formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: USERSERVICES_UPDATE_SUCCESS
        });
        if(ID===""){
          const formValue = {
              "Partner_Detail_Id": formData?.Partner_Detail_Id,
              "City_Id": EmailID?.City_Id,
              "Records_Filter": "FILTER"
           
          };
          const pagination ={
            PageNo: 1,
             PageSize: 5, 
             SortBy: "USER_SERVICE_REQUEST_ID", 
             SortOrder: "ASC"
          }
          dispatch(getUserservicesRequestsAction(formValue,pagination))
          navigation.navigate('EmployeInprogressserservices')
          toast.show({ message: 'User Services successfully.', type: 'info', duration: 3000, position: 'top' });
         
        }else if(ID==="1"){
          const formValue = {
              "Partner_Detail_Id": formData?.Partner_Detail_Id,
              "City_Id": EmailID?.City_Id,
              "Records_Filter": "FILTER"
           
          };
          const pagination ={
            PageNo: 1,
             PageSize: 5, 
             SortBy: "USER_SERVICE_REQUEST_ID", 
             SortOrder: "ASC"
          }
          dispatch(getUserservicesRequestsAction(formValue,pagination))
          navigation.navigate('Newuserservices')
          toast.show({ message: 'User Services successfully.', type: 'info', duration: 3000, position: 'top' });
         
        } else if(ID==="2"){
          navigation.navigate('Accepteduserservices')
          toast.show({ message: 'User Services successfully.', type: 'info', duration: 3000, position: 'top' });
          const formValue = {
              "Partner_Detail_Id": formData?.Partner_Detail_Id,
              "City_Id": EmailID?.City_Id,
              "Records_Filter": "FILTER"
           
          };
          const pagination ={
            PageNo: 1,
             PageSize: 5, 
             SortBy: "USER_SERVICE_REQUEST_ID", 
             SortOrder: "ASC"
          }
          dispatch(getUserservicesRequestsAction(formValue,pagination))
        }else if(ID==="3"){
          navigation.navigate('Inprogressuserservices')
          toast.show({ message: 'User Services successfully.', type: 'info', duration: 3000, position: 'top' });
          const formValue = {
              "Partner_Detail_Id": formData?.Partner_Detail_Id,
              "City_Id": EmailID?.City_Id,
              "Records_Filter": "FILTER"
           
          };
          const pagination ={
            PageNo: 1,
             PageSize: 5, 
             SortBy: "USER_SERVICE_REQUEST_ID", 
             SortOrder: "ASC"
          }
          dispatch(getUserservicesRequestsAction(formValue,pagination))
        }
       
      } else {
        const errors = {};
        dispatch({
          type: USERSERVICES_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USERSERVICES_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
export const userserviceFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: USERSERVICES_FILTER,
      fType: fType,
      value: value,
    });
  }
}

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: USERSERVICES_FILTER,
      fType: "search",
      value: "",
    });
  }
}
export const getUserserviceByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_User_Service_Request/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results[0])
    }
  } catch (err) {
    dispatch({
      type: USERSERVICES_FAIL,
      payload: err
    });
  }
};
export const GetEmployeeAction = (formData: any, callBackemployedata: any) => async dispatch => {
  try {
let { data, status } = await axios.post(
  `${shopperconfig.nodePartnerUrl}/Partner_Login/Employee_Get`,
 formData,
 {
  headers: nodeheaders
 }
);
if (data) {
 callBackemployedata(data.results)
}
} catch (err) {
dispatch({
 type: USERSERVICES_FAIL,
 payload: err
});
}
};
export const GetServicesAction = (formData: any, callBackservicedata: any) => async dispatch => {
  try {
let { data, status } = await axios.post(
  `${shopperconfig.nodePartnerUrl}/Partner_Service/Get`,
 formData,
 {
  headers: nodeheaders
 }
);
if (data) {
  callBackservicedata(data.results)
}
} catch (err) {
dispatch({
 type: USERSERVICES_FAIL,
 payload: err
});
}
};
export const userserviceByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
let { data, status } = await axios.post(
 `${shopperconfig.nodePartnerUrl}/Partner_User_Service_Request/Get`,
 formData,
 {
  headers: nodeheaders
 }
);
if (data) {
 callBackData(data.results[0])
}
} catch (err) {
dispatch({
 type: USER_SERVICES_FAIL,
 payload: err
});
}
};
export const getNewUserserviceByIdAction = (formData: any, callBackData: any) => async dispatch => {
       try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_User_Service_Request/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results[0])
    }
  } catch (err) {
    dispatch({
      type: USERSERVICES_FAIL,
      payload: err
    });
  }
};
export const dashboardServicecount = (formData: any) => {
  return function (dispatch: any) {
      let payload = {
          "Records_Filter": "FILTER",
          "Partner_Detail_ID": formData.Partner_Detail_ID,
          "Date": ''
      };
      axios.post(`${shopperconfig.nodePartnerUrl}/Partner_Dashboard/Partner_Total_Services_Count_Get`, payload,
          {
            headers: nodeheaders
          })
       
          .then(response => {
              
              let data = response.data;
              if (data.Success_Response.Response_Status === "Success") {
                  dispatch({
                      type: DASHBOARD_SERVICE_TOTAL_COUNT,
                      data: data.results[0],
                      success: true
                  });
              }
              else {
                  dispatch({
                      type: DASHBOARD_SERVICE_TOTAL_COUNT,
                      data: data.Success_Response.UI_Display_Message,
                      success: false
                  });
              }
          })
          .catch(error => {
              dispatch({
                  type: DASHBOARD_SERVICE_TOTAL_COUNT,
                  data: String(error),
                  success: false
              });
          })
  }
}
export const servicePartnercount = (formData: any) => {
  return function (dispatch: any) {
      axios.post(`${shopperconfig.nodePartnerUrl}/Partner_Dashboard/Partner_Active_Services_Count_Get`, formData,
          {
            headers: nodeheaders
          })
       
          .then(response => {
              
              let data = response.data;
              if (data.Success_Response.Response_Status === "Success") {
                  dispatch({
                      type: DASHBOARD_PARTNER_SERVICE_COUNT,
                      data: data.results[0],
                      success: true
                  });
              }
              else {
                  dispatch({
                      type: DASHBOARD_PARTNER_SERVICE_COUNT,
                      data: data.Success_Response.UI_Display_Message,
                      success: false
                  });
              }
          })
          .catch(error => {
              dispatch({
                  type: DASHBOARD_PARTNER_SERVICE_COUNT,
                  data: String(error),
                  success: false
              });
          })
  }
}

export const dashboarduserServicecount = (formData: any) => {
  return function (dispatch: any) {
      let payload = {
          "Partner_Details_Id": formData.Partner_Details_Id,
          "Date": ''
      };
      axios.post(`${shopperconfig.nodePartnerUrl}/Partner_Service/Count_Get`, payload,
          {
            headers: nodeheaders
          })
       
          .then(response => {
              
              let data = response.data;
              if (data.Success_Response.Response_Status === "Success") {
                  dispatch({
                      type: DASHBOARD_USER_SERVICE_TOTAL_COUNT,
                      data: data.results[0],
                      success: true
                  });
              }
              else {
                  dispatch({
                      type: DASHBOARD_USER_SERVICE_TOTAL_COUNT,
                      data: data.Success_Response.UI_Display_Message,
                      success: false
                  });
              }
          })
          .catch(error => {
              dispatch({
                  type: DASHBOARD_USER_SERVICE_TOTAL_COUNT,
                  data: String(error),
                  success: false
              });
          })
  }
}
export const newuserserviceFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: NEWUSERSERVICES_FILTER,
     fType: "search", 
      value: "",
    });
  }
}
export const ServiceInvoiceAction = (formData: any, updateStates: any) => async (dispatch) => {
  axios.post(`${shopperconfig.newurl}/Partner_Service_Invoice/Partner_Service_Invoice_Generation`, formData, {
    headers: headers
})
    .then(response => {
      
      let successResponse = response.data;
      if (successResponse.Is_Data_Exist === '0') {
        updateStates(false, []);
      }else{
         updateStates(true, successResponse); 
      }
    })
    .catch(error => {
      updateStates(false, null);
    })
};
export const updateEmployeUserservicesAction = (formData: any, navigation: any, toast: any,ID:any,EmailID:any) => async dispatch => {
  dispatch({
    type: USERSERVICES_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Partner_User_Service_Request/Update`, formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: USERSERVICES_UPDATE_SUCCESS
        });
        if(ID){
          navigation.navigate('EmployeInprogressserservices')
          toast.show({ message: 'User Services successfully.', type: 'info', duration: 3000, position: 'top' });
          const formValue = {
            "City_Id": "",
            "Email_Id": "",
            "Partner_Detail_Id": formData?.Partner_Details_Id,
            "Partner_Employee_Id": formData?.Partner_Employee_Id,
            "Partner_Service_Id": "",
            "Records_Filter": "FILTER",
            "User_Service_Status": "IN-PROCESS",
            "User_Service_request_Id": ""           
          };
          dispatch(getUserservicesRequestsAction(formValue))
               }else{
                
           toast.show({ message: 'User Services successfully.', type: 'info', duration: 3000, position: 'top' });
          const formValue = {
            "City_Id": "",
            "Email_Id": "",
            "Partner_Detail_Id": formData?.Partner_Details_Id,
            "Partner_Employee_Id": formData?.Partner_Employee_Id,
            "Partner_Service_Id": "",
            "Records_Filter": "FILTER",
            "User_Service_Status": "INITIATED",
            "User_Service_request_Id": ""           
          };
          dispatch(getUserservicesRequestsAction(formValue))
          navigation.navigate('EmployeNewuserservices')
        }
        
       
      } else {
        const errors = {};
        dispatch({
          type: USERSERVICES_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USERSERVICES_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

export const UserservicecityesAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Service/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)
    }
  } catch (err) {
    dispatch({
      type: USERSERVICES_FAIL,
      payload: err
    });
  }
};