import { shareAsync } from "expo-sharing";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Dimensions, Image, Platform, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View, TextInput as NativeTextInput } from "react-native";
import { Dialog, Button, IconButton, Portal, Surface, Text, Card, Divider, DataTable, HelperText } from "react-native-paper";
import StepIndicator from 'react-native-step-indicator';
import { useDispatch, useSelector } from "react-redux";
import { getOrderdetailAction, getSingleOrdersAction, OrderInvoiceAction, OrderStatusUpdateAction } from "../../../state/actions/shoppers/shoppersorderDetailsAction";
import { getAllOrdersAction } from "../../../state/actions/shoppers/shoppersordersAction";
import * as Print from "expo-print";
import ESIDeliveryRating from "./delireviewrating";
import Header from "../header/header";
import isEmpty from "../../../state/validations/is-empty";
import { ErrorMessage, Formik } from "formik";
import * as Yup from 'yup';
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import { useToast } from "../../../components/custom/react-native-paper-toast/src";
export default function MyOrderDetails(props) {

    const { navigation } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    const toast = useToast();
    const { orderId, masterId, addressId } = props?.route?.params || "";
    const windowHeight = Dimensions.get('window').height;
    const [data, setData] = React.useState();
    const EmailID = useSelector(state => state.auth.user.Email_Id)
    const [invoiceDetails, setDetails] = useState();
    const [orderInfo, setOrderInfo] = useState({});
    const handleClick = async (data) => {
        navigation.navigate('ProductDetails', { Product_Id: data?.Product_Id })
    };
    const [visible, setVisible] = React.useState(false);
    const hideDialog = () => {
        setVisible(false);
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [issubmitted, setSubmit] = React.useState(false);
    const [oLoading, setOLoading] = React.useState(false);
    const [orderDetailsFilter, setOrderDetailsFilter] = React.useState("");
    useEffect(() => {
        if (isEmpty(orderId)) {
            navigation.navigate('Orders')
        }
        else {
            if (EmailID) {
                let formData1 = {
                    "Item_Master_Id": masterId,
                    "Order_Id": orderId,
                    "Partner_Email_Id": EmailID,
                }
                let formData2 = {
                    Records_Filter: "FILTER",
                    Partner_Address_Id: addressId
                }
                dispatch(getOrderdetailAction(formData2));
                let formDataInvoice = {
                    "Shopper_Order_Id": orderId,
                    "Shopper_Order_Line_Item_Id": masterId
                }
                dispatch(OrderInvoiceAction(formDataInvoice, updateStatesInvoice));
                // if (!oLoading && orderDetailsFilter != JSON.stringify(formData1)) {
                // setOrderInfo({});
                setOLoading(true);
                // setOrderDetailsFilter(JSON.stringify(formData1));
                dispatch(getSingleOrdersAction(formData1, orderDetailsResponse));
                // }
            }
        }
    }, [EmailID, orderId, masterId, addressId]);
    const orderDetailsResponse = (data) => {
        setOrderInfo(data);
        setOLoading(false);
    };
    const updateStatesInvoice = (status, data) => {
        if (status) {
            setDetails(data);
        }
    };
    const statusChangeResponse = (status, message) => {
        toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
        setSubmit(false);
        if (status) {
            let formData1 = {
                Order_Id: orderId,
                Item_Master_Id: masterId,
                Partner_Email_Id: EmailID,
            }
            let formData2 = {
                Records_Filter: "FILTER",
                Partner_Address_Id: addressId
            }
            dispatch(getOrderdetailAction(formData2));
            let formDataInvoice = {
                "Shopper_Order_Line_Item_Id": masterId,
                "Shopper_Order_Id": orderId,
            }
            dispatch(OrderInvoiceAction(formDataInvoice, updateStatesInvoice));
            dispatch(getSingleOrdersAction(formData1, orderDetailsResponse));
            if (!oLoading) {
                setOrderInfo({});
                setOLoading(true);
                setOrderDetailsFilter(JSON.stringify(formData1));

            }
            dispatch(getAllOrdersAction({

                "Order_Status": "",
                "Payment_Status": "",
                "Records_Filter": "FILTER",
                "Shopper_Email_Id": EmailID,
                "Shopper_Line_Item_Order_Status": "",
                "Shopper_Order_Id": "",
                "Shopper_Order_Line_Item_Id": ""
            }));
        }
    }
    const getCurrentDeliveryStatus = (deliveryType, ItemMasterStatus) => {
        let orderlabels = [];
        if (deliveryType == "LIVE" && ["NEW", "ACCEPTED", "PACKED", "IN-TRANSIT", "DELIVERED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Packed", "In Transit", "Delivered"];
        } else if (["REJECTED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Rejected", "Settled"];
        } else if (["CANCELLED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Cancelled", "Settled"];
        }
        else if (["SETTLED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Settled"];
        } else {
            orderlabels = ["New", "Shipped", "In Transit", "Out For Delivery", "Delivered"];
        }
        return orderlabels
    }
    const getCurrentStatusPoission = (data, ItemMasterStatus, deliveryType) => {
        let poission = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].toUpperCase() == ItemMasterStatus) {
                poission = i;
                break;
            } else if (data[i] == "Out For Delivery" && ItemMasterStatus == "OUT-FOR-DELIVERY") {
                poission = i;
                break;
            }
            else if (data[i] == "In Transit" && ItemMasterStatus == "IN-TRANSIT") {
                poission = i;
                break;
            }
        }
        if (poission < 1) {
            if (deliveryType == "LIVE" && ["NEW", "ACCEPTED", "PACKED", "IN-TRANSIT", "DELIVERED"].includes(ItemMasterStatus)) {
                if (["ACCEPTED", "PACKED"].includes(ItemMasterStatus)) {
                    poission = 2;
                }
            } else if (["REJECTED"].includes(ItemMasterStatus)) {
                if (!(["NEW", "REJECTED", "SETTLED"].includes(ItemMasterStatus))) {
                    poission = 2;
                }
            } else if (["CANCELLED"].includes(ItemMasterStatus)) {
                if (!(["NEW", "CANCELLED", "SETTLED"].includes(ItemMasterStatus))) {
                    poission = 2;
                }
            }
            else if (["SETTLED"].includes(ItemMasterStatus)) {
                poission = 2;
            }
        }
        return poission;
    }
    const customStyles = {
        stepIndicatorSize: 25,
        currentStepIndicatorSize: 30,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: 'green',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: 'green',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: 'green',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'green',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#fe7013',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: 'green'
    }
    const cancelStyles = {
        stepIndicatorSize: 25,
        currentStepIndicatorSize: 30,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: '#fe7013',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: 'red',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: 'red',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'red',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#fe7013',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: 'red'
    }
    const getInvoice = () => {
        return `
                        <html>
                        <head>
                    <style>
                    .grid-container {
                    display: grid;
                    grid-template-columns: auto auto;
                    }.hr.solid {
                    border-top: 5px solid #2EE1F4;
                    }
                    table {
                    font-family: arial, sans-serif;
                    border-collapse: collapse;
                    width: 100%;
                    }
                    td{
                    border: 1px solid #2EE1F4;
                    text-align: right;
                    padding: 8px;
                    }
                    th {
                    border: 1px solid #2EE1F4;
                    text-align: right;
                    background-color: #2EE1F4;
                    padding: 8px;
                    }
                    </style>
                    
                            <meta charset="utf-8">
                            <title>Invoice</title>
                            <script src="script.js"></script>
                        </head>
                        <body>
                            <header>
                        <center>
                                <h1 style="color:#2EE1F4;">Invoice</h1>
                        </center>
                                <address contenteditable>
                        <div class="grid-container">
                        <img src="../../../assets/images/img/logo/logoNew.png" width="100" height="80">
                        <span style="text-align:right">
                        <p style="font-size:30px">Order Id: `+ invoiceDetails?.Shopper_Order_Id + `</p>
                        <p style="font-size:30px">Order Date: `+ invoiceDetails?.Order_Date + `</p>
                        <p style="font-size:30px">Invoice No: `+ invoiceDetails?.Invoice_No + `</p>
                        <p style="font-size:30px">Invoice Date:`+ invoiceDetails?.Invoice_Date + `</p>
                        </span>
                        </div>
                        <hr class="solid">
                        <div class="grid-container">
                        <span>
                        <h4>Billing Address:</h4>
                        <p style="font-size:30px">`+ invoiceDetails?.BillingAddress?.Name + `</p>
                        <p style="font-size:30px">`+ invoiceDetails?.BillingAddress?.Address_Door_To_Street + `</p>
                        <p style="font-size:30px">`+ invoiceDetails?.BillingAddress?.City + `,` + invoiceDetails?.BillingAddress?.State + `,` + invoiceDetails?.BillingAddress?.Country + `</p>
                        <p style="font-size:30px">+`+ invoiceDetails?.BillingAddress?.Phone_Number + `</p>
                        <p style="font-size:30px">`+ invoiceDetails?.BillingAddress?.Email + `</p>
                        </span>
                        <span style="text-align:right">
                        <h4>Shipping Address:</h4>
                        <p style="font-size:30px">`+ invoiceDetails?.ShippingAddress?.Name + `</p>
                        <p style="font-size:30px">`+ invoiceDetails?.ShippingAddress?.Address_Door_To_Street + `</p>
                        <p style="font-size:30px">`+ invoiceDetails?.ShippingAddress?.City + `,` + invoiceDetails?.ShippingAddress?.State + `,` + invoiceDetails?.ShippingAddress?.Country + `</p>
                        <p style="font-size:30px">+`+ invoiceDetails?.ShippingAddress?.Phone_Number + `</p>
                        <p style="font-size:30px">`+ invoiceDetails?.ShippingAddress?.Email + `</p>
                        </span>
                        </div>
                        <hr class="solid">
                        <div class="grid-container">
                        <span>
                        <h4>Sold By:</h4>
                        <p style="font-size:30px">`+ invoiceDetails?.Partner?.Name + `</p>
                        <p style="font-size:30px">`+ invoiceDetails?.Partner?.Address_Door_To_Street + `</p>
                        <p style="font-size:30px">`+ invoiceDetails?.Partner?.City + `,` + invoiceDetails?.Partner?.State + `,` + invoiceDetails?.Partner?.Country + `</p>
                        <p style="font-size:30px">`+ invoiceDetails?.Partner?.Name + `</p>
                        </span>
                        <span style="text-align:right">
                        <p style="font-size:30px">PAN NO: `+ invoiceDetails?.Partner?.Pan + `</p>
                        <p style="font-size:30px">GST: `+ invoiceDetails?.Partner?.GST + `</p>
                        </span>
                        </div>
                        <hr class="solid">
                        <table>
                    <tr>
                        <th>SI No</th>
                        <th style="width:70%">Description</th>
                        <th>rate</th>
                        <th>QTY</th>
                        <th>Amount</th> 
                        <th>IGST</th>
                        <th>IGST Amount</th>
                    </tr>
        
                    <tr> 
                        <td>1</td>
                        <td style="font-size:30px">`+ invoiceDetails?.Product?.Name + `</td>
                        <td style="font-size:30px">`+ invoiceDetails?.Product?.Amount + `</td>
                        <td style="font-size:30px">`+ invoiceDetails?.Product?.Qty + `</td>
                        <td style="font-size:30px">`+ invoiceDetails?.Product?.Grand_Total + `</td>
                        <td style="font-size:30px">`+ invoiceDetails?.Product?.IGST + `</td>
                        <td style="font-size:30px">`+ invoiceDetails?.Product?.IGSTAmount + `</td>
                    </tr>
                    
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                   
                    </table>
                                </address>
                                </header>
                            <aside>
                                <div contenteditable>
                                    <p>Note: This is computer generated Invoice and signature is not required.</p>
                                </div>
                            </aside>
                        </body>
                    </html>
        `;
    }
    const [invoiceMobile, setinvoiceMobile] = useState(false)

    const printToFile = async () => {
        if (Platform.OS == 'web') {
            setinvoiceMobile(true);
        }
        else {
            const html = getInvoice();
            try {
                const { uri } = await Print.printToFileAsync({ html });
                await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
            } catch (error) {
            }
        }
    }
    const printToFileWeb = async () => {

        try {
            const html = getInvoice();
            const { uri } = await Print.printToFileAsync({ html });
            await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' })
                .finally((data) => {
                    setinvoiceMobile(false)
                }
                )

        } catch (error) {
        }
    }
    const [refreshing, setRefreshing] = React.useState(false);
    const [discountprice, setDiscountPrice] = React.useState('');
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    useEffect(() => {
        if (orderInfo?.ItemPaymentSummary) {
            setDiscountPrice(((Number(orderInfo?.ItemPaymentSummary?.Product_Price) * Number(orderInfo?.ItemPaymentSummary?.Quantity)) ? Number(orderInfo?.ItemPaymentSummary?.Product_Price) * Number(orderInfo?.ItemPaymentSummary?.Quantity) : "0.00") + (Number(orderInfo?.ItemPaymentSummary?.Delivery_Charges)) - (Number(orderInfo?.ItemPaymentSummary?.Total_Gross_Price)))
        }
    }, [orderInfo]);
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    return (
        <Surface style={{ backgroundColor: esiColor.BackgroundColor, flex: 1 }}>
            {invoiceMobile ?
                (invoiceDetails && <>
                    <IconButton
                        icon="close"
                        size={20}
                        color={"#27b6cc"}
                        onPress={() => setinvoiceMobile(false)}
                        style={{ position: "absolute", zIndex: 1, right: 0 }}
                    />
                    <TouchableOpacity style={{ borderRadius: 5, alignItems: "center", marginHorizontal: 20 }} onPress={() => printToFileWeb()}>
                        <ScrollView refreshControl={
                            <RefreshControl
                                refreshing={refreshing}
                                onRefresh={onRefresh}
                                colors={['#27B6CC']}
                                tintColor={'#27B6CC'}
                                title={"Pull to refresh"}
                                titleColor={"#27B6CC"}
                            />
                        }>
                            <Card>
                                <View style={{ margin: 20, flex: 1 }}>
                                    <View style={{ alignSelf: 'center', marginBottom: 30 }}><Text style={{ color: '#2EE1F4', fontSize: 28 }}>Invoice</Text></View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                                        <Image source={require('../../../assets/images/img/logo/logo.png')} style={{ width: 120, height: 45 }} />
                                        <View style={styles.headerContainer}>
                                            <View>
                                                <View style={styles.invoiceNoContainer}>
                                                    <Text style={{ fontSize: 11, fontWeight: "bold" }}>Order ID:</Text><Text style={{ fontSize: 10 }}> {invoiceDetails?.Shopper_Order_Id} </Text>
                                                </View>
                                                <View style={styles.invoiceDateContainer}>
                                                    <Text style={{ fontSize: 11, fontWeight: "bold" }}>Order Date:</Text><Text style={{ fontSize: 10 }}> {invoiceDetails?.Order_Date} </Text>
                                                </View>
                                                <View style={styles.invoiceNoContainer}>
                                                    <Text style={{ fontSize: 11, fontWeight: "bold" }}>Invoice No: </Text><Text style={{ fontSize: 10 }}>{invoiceDetails?.Invoice_No} </Text>
                                                </View>
                                                <View style={styles.invoiceDateContainer}>
                                                    <Text style={{ fontSize: 11, fontWeight: "bold" }}>Invoice Date:</Text><Text style={{ fontSize: 10 }}>{invoiceDetails?.Invoice_Date} </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <Divider />
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                                        <View>
                                            <Text style={{ fontSize: 11, marginTop: 20, paddingBottom: 3, fontWeight: 'bold', fontFamily: 'Helvetica-Oblique' }}>Billing Address: </Text>
                                            <Text style={{ fontSize: 10 }}>{invoiceDetails?.BillingAddress?.Name}</Text>
                                            <Text style={{ fontSize: 10 }}>{invoiceDetails?.BillingAddress?.Address_Door_To_Street}</Text>
                                            <Text style={{ fontSize: 10 }}>{invoiceDetails?.BillingAddress?.City}</Text>
                                            <Text style={{ fontSize: 10 }}>{invoiceDetails?.BillingAddress?.State}, {invoiceDetails?.BillingAddress?.Country} </Text>
                                            <Text style={{ fontSize: 10 }}>+{invoiceDetails?.BillingAddress?.Phone_Number} </Text>
                                            <Text style={{ fontSize: 10 }}>{invoiceDetails?.BillingAddress?.email} </Text>
                                        </View>
                                        <View >
                                            <Text style={{ fontSize: 11, fontStyle: 'italic', textAlign: 'right', marginTop: 20, paddingBottom: 3, fontWeight: 'bold' }}>Shipping Address: </Text>
                                            <Text style={{ fontSize: 10, textAlign: 'right' }}>{invoiceDetails?.ShippingAddress?.Name}</Text>
                                            <Text style={{ fontSize: 10, textAlign: 'right' }}>{invoiceDetails?.ShippingAddress?.Address_Door_To_Street}</Text>
                                            <Text style={{ fontSize: 10, textAlign: 'right' }}>{invoiceDetails?.ShippingAddress?.City}</Text>
                                            <Text style={{ fontSize: 10, textAlign: 'right' }}>{invoiceDetails?.BillingAddress?.State}, {invoiceDetails?.BillingAddress?.Country}</Text>
                                            <Text style={{ fontSize: 10, textAlign: 'right' }}>+{invoiceDetails?.ShippingAddress?.Phone_Number} </Text>
                                            <Text style={{ fontSize: 10, textAlign: 'right' }}>{invoiceDetails?.ShippingAddress?.Email} </Text>
                                        </View>
                                    </View>
                                    <Divider style={{ color: 'blue' }} />
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                                        <View >
                                            <Text style={{ fontSize: 11, fontStyle: 'italic', marginTop: 20, paddingBottom: 3, fontWeight: 'bold' }}>Sold By: </Text>
                                            <Text style={{ fontSize: 10 }}>{invoiceDetails?.Partner?.Name} </Text>
                                            <Text style={{ fontSize: 10 }}>{invoiceDetails?.Partner?.Address_Door_To_Street} </Text>
                                            <Text style={{ fontSize: 10 }}>{invoiceDetails?.Partner?.City}, {invoiceDetails?.Partner?.State}, {invoiceDetails?.Partner?.Country} </Text>
                                            <Text style={{ fontSize: 10 }}>{invoiceDetails?.Partner?.Name} </Text>
                                        </View>
                                        <View>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', textAlign: 'right', marginTop: 20 }}>PAN NO: {invoiceDetails?.Partner?.Pan} </Text>
                                            <Text style={{ fontSize: 11, fontWeight: 'bold', textAlign: 'right' }}>GST: {invoiceDetails?.Partner?.GST} </Text>

                                        </View>
                                    </View>
                                    <Divider />
                                    <View style={styles.tableContainer}>
                                        <DataTable>
                                            <View>
                                                <DataTable.Header>
                                                    <DataTable.Title><Text style={{ fontSize: 11 }} >SI No</Text></DataTable.Title>
                                                    <DataTable.Title><Text style={{ fontSize: 11 }}>Description</Text></DataTable.Title>
                                                    <DataTable.Title> <Text style={{ fontSize: 11 }}>Rate</Text></DataTable.Title>
                                                    <DataTable.Title><Text style={{ fontSize: 11 }}>QTY</Text></DataTable.Title>
                                                    <DataTable.Title><Text style={{ fontSize: 11 }}>Amount</Text></DataTable.Title>
                                                    <DataTable.Title><Text style={{ fontSize: 11 }}>IGST</Text></DataTable.Title>
                                                    <DataTable.Title><Text style={{ fontSize: 11 }}>IGST Amount</Text></DataTable.Title>
                                                </DataTable.Header>
                                            </View>
                                            <View >
                                                <DataTable.Row >
                                                    <DataTable.Cell>1</DataTable.Cell>
                                                    <DataTable.Cell><Text style={{ fontSize: 10 }}>{invoiceDetails?.Product?.Name}</Text></DataTable.Cell>
                                                    <DataTable.Cell><Text style={{ fontSize: 10 }}>{invoiceDetails?.Product?.Rate}</Text></DataTable.Cell>
                                                    <DataTable.Cell><Text style={{ fontSize: 10 }}>{invoiceDetails?.Product?.Qty}</Text></DataTable.Cell>
                                                    <DataTable.Cell><Text style={{ fontSize: 10 }}>{invoiceDetails?.Product?.Grand_Total}</Text></DataTable.Cell>
                                                    <DataTable.Cell><Text style={{ fontSize: 10 }}>{invoiceDetails?.Product?.IGST}</Text></DataTable.Cell>
                                                    <DataTable.Cell><Text style={{ fontSize: 10 }}>{invoiceDetails?.Product?.IGSTAmount}</Text></DataTable.Cell>
                                                </DataTable.Row>
                                            </View>
                                        </DataTable>
                                    </View>
                                </View>
                            </Card>
                            <Text style={styles.titleContainer}>Note: This is computer generated Invoice and signature is not required.</Text>
                        </ScrollView>
                    </TouchableOpacity>
                </>
                ) : <>
                    <Header navigation={navigation} />
                    <ScrollView>
                        {oLoading ? <ActivityIndicator color='#27b6cc' /> :
                            <Surface style={{ backgroundColor: esiColor.BackgroundColor }}>
                                <View style={{ marginVertical: -10 }}>
                                    <IconButton
                                        icon="arrow-left"
                                        size={27}
                                        color={"#27B6CC"}
                                        style={{ marginLeft: 1 }}
                                        onPress={() => {
                                            navigation.navigate("Orders");
                                        }} />
                                </View>
                                <Portal>
                                    <Dialog visible={visible} onDismiss={hideDialog}
                                        style={{
                                            width: (dimensions.width >= 500) ? 400 : dimensions.width - 50,
                                            maxHeight: (dimensions.height >= 500) ? 500 : dimensions.height - 50,
                                            marginLeft: 'auto', marginRight: 'auto',
                                            backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5, shadowOpacity: 1, shadowRadius: 5
                                        }}>

                                        <Dialog.ScrollArea>
                                            <View style={{ flexDirection: "row" }} >
                                                <View style={{ flex: 1, alignItems: "flex-end" }}>
                                                    <IconButton style={{ marginTop: 1, marginRight: -10 }}
                                                        icon="close"
                                                        size={20}
                                                        color={esiColor.itemColor}
                                                        onPress={() => setVisible(!visible)}
                                                    />
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: "row" }} >
                                                <View style={{ flex: 1 }} >
                                                    <Text style={{ color: "#27b6cc", fontSize: 20, textAlign: "center", marginTop: -20, marginBottom: 20 }}>Order Cancellation Form</Text>
                                                </View>
                                            </View>
                                            <ScrollView >
                                                <Dialog.Content>
                                                    {(() => {

                                                        let reasonTypes = [
                                                            { label: "Select Reason Type", value: "" },
                                                            { label: "By Mistakenly Places Order", value: "By Mistakenly Places Order" },
                                                            { label: "Bought from other Partner", value: "Bought from other Partner" },
                                                            { label: "Delivery Time is too long", value: "Delivery Time is too long" },
                                                            { label: "Other Reason", value: "Other Reason" }];
                                                        return (
                                                            <Formik
                                                                initialValues={
                                                                    {
                                                                        Cancel_Reason: '',
                                                                        Cancel_Comment: ''
                                                                    }
                                                                }
                                                                validationSchema={Yup.object().shape({
                                                                    Cancel_Reason: Yup.string()
                                                                        .required('Reason is required.')
                                                                })}
                                                                onSubmit={values => {
                                                                    let updateData = {
                                                                        B2B_Email: EmailID,
                                                                        Cancel_Comment: values.Cancel_Comment,
                                                                        Cancel_Reason: values.Cancel_Reason,
                                                                        Item: [
                                                                            {
                                                                                Item_ID: data?.ProductDetails?.Item_Master_Id,
                                                                                Item_Status: "CANCELLED",
                                                                            }
                                                                        ],
                                                                        Order_ID: data?.OrderDetails?.Order_Id,
                                                                    }
                                                                    dispatch(OrderStatusUpdateAction(updateData, data, navigation, statusChangeResponse))
                                                                    setSubmit(!issubmitted);
                                                                    setVisible(!visible);
                                                                }}
                                                            >
                                                                {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                                                                    <View style={{
                                                                        flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}>
                                                                        <View style={{ margin: "auto", width: "100%" }}>
                                                                            <EsiSearchDropdown
                                                                                style={[{
                                                                                    maxHeight: 70,
                                                                                    minHeight: 58,
                                                                                    borderWidth: 0.5,
                                                                                    borderRadius: 5,
                                                                                    paddingHorizontal: 8,
                                                                                    borderColor: esiColor.SBorderColor,
                                                                                    activeColor: esiColor.itemColor,
                                                                                    iconColor: esiColor.itemColor,
                                                                                    backgroundColor: esiColor.BackgroundColor,
                                                                                    width: "100%"
                                                                                }, { borderColor: Boolean(touched.Cancel_Reason && errors.Cancel_Reason) ? '#b00020' : esiColor.SBorderColor }]}
                                                                                data={reasonTypes}
                                                                                placeholderStyle={{ fontSize: 16, color: esiColor.TextPlaceholder }}
                                                                                selectedTextStyle={{
                                                                                    fontSize: 16,
                                                                                    width: "100%",
                                                                                    color: esiColor.SIconColor

                                                                                }}
                                                                                inputSearchStyle={{
                                                                                    height: 40,
                                                                                    fontSize: 16
                                                                                }}
                                                                                iconStyle={{
                                                                                    width: 20,
                                                                                    height: 20
                                                                                }}
                                                                                labelField="label"
                                                                                valueField="value"
                                                                                maxHeight={180}

                                                                                search
                                                                                placeholder='Select Reason'
                                                                                defaultValue={""}
                                                                                className="address"
                                                                                onUpdateValue={(item: any) => {
                                                                                    setFieldValue('Cancel_Reason', item.value);
                                                                                }}
                                                                                value={values.Cancel_Reason}
                                                                            />
                                                                            <HelperText style={{}} type="error" visible={Boolean(touched.Cancel_Reason && errors.Cancel_Reason)}>
                                                                                {touched.Cancel_Reason && errors.Cancel_Reason}
                                                                            </HelperText>
                                                                        </View>
                                                                        <View style={{ width: "100%", margin: "auto", paddingTop: 18 }}>
                                                                            <Text style={[{
                                                                                position: 'absolute',
                                                                                backgroundColor: esiColor.BackgroundColor,
                                                                                left: 22,
                                                                                top: 9,
                                                                                zIndex: 999,
                                                                                paddingHorizontal: 8,
                                                                                fontSize: 14,
                                                                                color: esiColor.itemColor
                                                                            }, Boolean(touched.Cancel_Comment && errors.Cancel_Comment) && { color: '#b00020' }]}>
                                                                                Comment
                                                                            </Text>
                                                                            <NativeTextInput
                                                                                // style={{ backgroundColor: esiColor.TextBC }}
                                                                                editable
                                                                                maxLength={120}
                                                                                multiline
                                                                                numberOfLines={5}
                                                                                onChangeText={text => setFieldValue('Cancel_Comment', text)}
                                                                                value={values.Cancel_Comment}
                                                                                selectionColor={esiColor.TextSelection}
                                                                                // theme={{
                                                                                //     colors: {
                                                                                //         primary: esiColor.TextHighlight,
                                                                                //         text: esiColor.Text,
                                                                                //         placeholder: esiColor.TextPlaceholder,

                                                                                //     }
                                                                                // }}
                                                                                outlineColor={esiColor.TextOuterLine}
                                                                                underlineColor={esiColor.TextUnderline}
                                                                                activeOutlineColor={esiColor.TextOuterLine}
                                                                                style={[{
                                                                                    paddingTop: 7,
                                                                                    borderWidth: 0.5,
                                                                                    borderRadius: 5,
                                                                                    paddingHorizontal: 10,
                                                                                    backgroundColor: esiColor.TextBC,
                                                                                    color: esiColor.Text
                                                                                }, { borderColor: Boolean(touched.Cancel_Comment && errors.Cancel_Comment) ? '#b00020' : 'gray' }]}
                                                                            />
                                                                            <HelperText type="error" visible={Boolean(touched.Cancel_Comment && errors.Cancel_Comment)}>
                                                                                <ErrorMessage name="Cancel_Comment" />
                                                                            </HelperText>
                                                                        </View>
                                                                        <View style={{ margin: "auto" }}>
                                                                            <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                                                                <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                                                            </Button>
                                                                        </View>
                                                                    </View>
                                                                )}
                                                            </Formik>
                                                        )
                                                    })()}

                                                </Dialog.Content>
                                            </ScrollView>
                                        </Dialog.ScrollArea>
                                    </Dialog>
                                </Portal>
                                <View style={{ marginLeft: 5, marginRight: 5 }}>
                                    <View style={{
                                        shadowColor: esiColor.brandShadowColor,
                                        shadowOffset: { width: -2, height: 4 },
                                        shadowOpacity: 0.2,
                                        shadowRadius: 3,
                                       
                                    }}>
                                        {orderInfo &&
                                            <View style={{ flex: 5, flexDirection: 'column', marginLeft: 10 }}  >
                                                <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Delivery Address </Text>
                                                <Text style={{ fontSize: 14, marginBottom: 10, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.UserDeleveryAddress?.Name},</Text>
                                                <Text style={{ fontSize: 13, color: esiColor.itemColor }}>D-No : {orderInfo?.UserDeleveryAddress?.Door_No},{orderInfo?.UserDeleveryAddress?.Street}, {orderInfo?.UserDeleveryAddress?.Landmark},</Text>
                                                <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{orderInfo?.UserDeleveryAddress?.City_Name},{orderInfo?.UserDeleveryAddress?.State},{orderInfo?.UserDeleveryAddress?.Country},</Text>
                                                <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{orderInfo?.UserDeleveryAddress?.City_Name}-{orderInfo?.UserDeleveryAddress?.Pin},</Text>
                                                <Text style={{ fontSize: 13, marginBottom: 10, color: esiColor.itemColor }}>Phone number : {orderInfo?.UserDeleveryAddress?.PhoneNumber}.  </Text>
                                            </View>
                                        }
                                    </View>
                                    <View >
                                        <View
                                            style={{
                                                shadowColor: esiColor.brandShadowColor,
                                                // shadowOffset: { width: -2, height: 4 },
                                                shadowOpacity: 5,
                                                shadowRadius: 7,
                                                marginHorizontal: 1, padding: 1, margin: 'auto',
                                                backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 15 : 0),
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                elevation: 5,
                                                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                                maxHeight: windowHeight > 250 ? 250 : 75,
                                                minHeight: 140,
                                                // borderColor: 'grey',
                                                borderWidth: 0.5,
                                                borderRadius: 10
                                            }}
                                        >
                                            <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                                                <TouchableOpacity onPress={() => handleClick(orderInfo?.ProductDetails)}>
                                                    <Image source={{ uri: orderInfo?.ProductDetails?.Product_Image }} style={{ height: 100, marginTop: 15, width: 80, resizeMode: 'contain' }}></Image>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ flex: 2, flexDirection: 'column' }}  >
                                                <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.brandFontColor }}>{orderInfo?.ProductDetails?.Product_Name}</Text>
                                                <Text style={{ color: esiColor.itemColor }}>Order ID : {orderInfo?.OrderDetails?.Order_Id}</Text>
                                                <Text style={{ color: esiColor.itemColor }}>Order Product ID : {orderInfo?.ProductDetails?.Item_Master_Id}</Text>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Text style={{ color: esiColor.itemColor }}>Quantity : {orderInfo?.ProductDetails?.Quantity}</Text>
                                                </View>
                                                <Text style={{ color: esiColor.itemColor }}>Order Date : {orderInfo?.ProductDetails?.Order_Date}</Text>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Text style={{ color: esiColor.itemColor }}>Partner Name : </Text><Text style={{ color: esiColor.brandFontColor }}>{orderInfo?.ProductDetails?.Selling_Partner_Name}</Text>
                                                </View>
                                                {(!(["CANCELLED", "DELIVERED", "SETTLED"].includes(orderInfo?.ProductDetails?.Line_Item_Status)) && orderInfo?.ProductDetails?.Is_Item_Cancel_Flag != "0") &&
                                                    <View style={{ flexDirection: 'row', margin: 5, }}>
                                                        <TouchableOpacity
                                                            style={{
                                                                backgroundColor: esiColor.globalButtonColor, width: 80, borderRadius: 5, alignItems: "center", padding: 5, opacity: issubmitted ? 0.2 : "",

                                                            }}
                                                            onPress={() => { setData(orderInfo); setVisible(!visible) }}
                                                        >
                                                            <Text style={{ color: esiColor.itemButtenColor }}> CANCEL </Text>
                                                        </TouchableOpacity>
                                                    </View>}
                                            </View>
                                        </View>
                                        {!(["CANCELLED"].includes(orderInfo?.ProductDetails?.Line_Item_Status)) &&
                                            <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5 }} onPress={printToFile}>
                                                <View >
                                                    <Text style={{ color: esiColor.itemButtenColor }} >Download Invoice</Text>
                                                </View>
                                            </TouchableOpacity>
                                        }
                                        {getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.ProductDetails?.Line_Item_Status).length > 0 &&
                                            < View style={{ marginTop: 5 }}>
                                                <StepIndicator
                                                    customStyles={orderInfo?.ProductDetails?.Line_Item_Status === "CANCELLED" ? cancelStyles : customStyles}
                                                    labels={getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.ProductDetails?.Line_Item_Status)}
                                                    currentPosition={getCurrentStatusPoission(getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.ProductDetails?.Line_Item_Status), orderInfo?.ProductDetails?.Line_Item_Status, orderInfo?.ProductDetails?.Delivery_Type)}
                                                    stepCount={getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.ProductDetails?.Line_Item_Status).length}
                                                />
                                            </View>
                                        }
                                        {!(["DELIVERED", "SETTLED"].includes(orderInfo?.ProductDetails?.Line_Item_Status)) &&
                                            <View style={{ marginVertical: 5, flexDirection: "column" }} >
                                                <Text style={{ textAlign: "center" }} >
                                                    {orderInfo?.ProductDetails?.Expected_Delivery_Date_Time}
                                                </Text>
                                                <Text style={{ textAlign: "center" }} >
                                                    {orderInfo?.ProductDetails?.Delivery_Message}
                                                </Text>
                                            </View>
                                        }
                                        {(orderInfo?.ProductDetails?.Delivery_Person_PhoneNumber && !(["DELIVERED", "CANCELLED", "SETTLED"].includes(orderInfo?.ProductDetails?.Line_Item_Status))) &&
                                            <View
                                                style={{
                                                    shadowColor: esiColor.brandShadowColor,
                                                    // shadowOffset: { width: -2, height: 4 },
                                                    shadowOpacity: 0.2,
                                                    shadowRadius: 3,
                                                    marginHorizontal: 1, padding: 1, margin: 'auto',
                                                    backgroundColor: esiColor.BackgroundColor,
                                                    marginTop: (Platform.OS === 'web' ? 15 : 0), flexDirection: 'column',
                                                    // elevation: 10,
                                                    // borderColor: 'grey',
                                                    borderWidth: 0.5,
                                                    borderRadius: 10,

                                                }}
                                            >
                                                <Text style={{ fontSize: 15, fontWeight: 'bold', color: '#27b6cc', marginLeft: 3, marginTop: 1 }}>Delivery Person </Text>
                                                <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => {
                                                    if (orderInfo?.ProductDetails?.Line_Item_Status == "IN-TRANSIT") {
                                                        navigation.navigate("OrderTracking", { ID: orderInfo?.ProductDetails?.Item_Master_Id });
                                                    }
                                                }} >
                                                    <View style={{ flex: 1, alignContent: 'center', alignItems: "center", marginLeft: 10 }} >
                                                        <Image source={{ uri: orderInfo?.ProductDetails?.Delivery_Person_Image }} style={{ height: 50, width: 50, resizeMode: 'contain' }}></Image>
                                                    </View>
                                                    <View style={{ flex: 2, flexDirection: 'column' }}  >
                                                        <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.ProductDetails?.Delivery_Person_Name}</Text>
                                                        <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.ProductDetails?.Delivery_Person_PhoneNumber}</Text>
                                                    </View>
                                                </TouchableOpacity>
                                            </View>
                                        }
                                    </View>
                                    {(masterId && orderId && (orderInfo?.ProductDetails?.Line_Item_Status == "DELIVERED")) &&
                                        <View style={{ marginVertical: 5 }} >
                                            <ESIDeliveryRating
                                                orderid={orderId}
                                                lineitemid={masterId}
                                                ordStatus={orderInfo?.ProductDetails?.Line_Item_Status}
                                            />
                                        </View>}
                                    <View style={{

                                        borderBottomColor: esiColor.SBorderColor,
                                        borderBottomWidth: 0.5
                                    }} >
                                        <View style={{ flex: 5, flexDirection: 'column', marginLeft: 10 }}  >
                                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Billing Address </Text>

                                            <Text style={{ fontSize: 14, marginBottom: 10, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.UserBillingAddressDetails?.Name},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.itemColor }}>D-No : {orderInfo?.UserBillingAddressDetails?.Door_No},{orderInfo?.UserBillingAddressDetails?.Street}, {orderInfo?.UserBillingAddressDetails?.Landmark},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{orderInfo?.UserBillingAddressDetails?.City_Name},{orderInfo?.UserBillingAddressDetails?.State},{orderInfo?.UserBillingAddressDetails?.Country},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.itemColor }}>{orderInfo?.UserBillingAddressDetails?.City_Name}-{orderInfo?.UserBillingAddressDetails?.Pin},</Text>
                                            <Text style={{ fontSize: 13, marginBottom: 10, color: esiColor.itemColor }}>Phone number : {orderInfo?.UserBillingAddressDetails?.PhoneNumber}.  </Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        marginVertical: 5, shadowColor: esiColor.brandShadowColor,
                                        shadowOffset: { width: -2, height: 4 },
                                        shadowOpacity: 0.2,
                                        shadowRadius: 3
                                    }} >
                                        <View style={{ flex: 5, flexDirection: 'column', marginLeft: 10 }}  >
                                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Payment Summary</Text>
                                            <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                                <View style={{ flex: 4 }} >
                                                    <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Items Total Cost</Text>
                                                </View>
                                                <View style={{ flex: 2, }}>
                                                    <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>{((Number(orderInfo?.ItemPaymentSummary?.Product_Price) * Number(orderInfo?.ItemPaymentSummary?.Quantity)) ? Number(orderInfo?.ItemPaymentSummary?.Product_Price) * Number(orderInfo?.ItemPaymentSummary?.Quantity) : "0.00") - (Number(orderInfo?.ItemPaymentSummary?.Total_Tax))}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                                <View style={{ flex: 4 }} >
                                                    <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>IGST {orderInfo?.ItemPaymentSummary?.IGSTPercentage}%</Text>
                                                </View>
                                                <View style={{ flex: 2, }}>
                                                    <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>{orderInfo?.ItemPaymentSummary?.IGST}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                                <View style={{ flex: 4 }} >
                                                    <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>VAT {orderInfo?.ItemPaymentSummary?.VATPercentage}%</Text>
                                                </View>
                                                <View style={{ flex: 2, }}>
                                                    <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>{orderInfo?.ItemPaymentSummary?.VATPercentage}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                                <View style={{ flex: 4 }} >
                                                    <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Tax Amount</Text>
                                                </View>
                                                <View style={{ flex: 2, }}>
                                                    <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>{orderInfo?.ItemPaymentSummary?.Total_Tax}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                                <View style={{ flex: 4 }} >
                                                    <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Delivery Charges</Text>
                                                </View>
                                                <View style={{ flex: 2, }}>
                                                    <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>{orderInfo?.ItemPaymentSummary?.Delivery_Charges}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                                <View style={{ flex: 4 }} >
                                                    <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Discount Amount</Text>
                                                </View>
                                                <View style={{ flex: 2, }}>
                                                    <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>- {discountprice}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                                <View style={{ flex: 4 }} >
                                                    <Text style={{ fontSize: 13, alignSelf: "flex-start", fontWeight: "bold", color: esiColor.DescColor }}>Total Gross Price</Text>
                                                </View>
                                                <View style={{ flex: 2, }}>
                                                    <Text style={{ fontSize: 13, alignSelf: "center", fontWeight: "bold", color: esiColor.itemColor }}>{orderInfo?.ItemPaymentSummary?.Total_Gross_Price}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </Surface>}
                    </ScrollView >
                </>}
        </Surface >
    )
}
const styles = StyleSheet.create({
    separator: {
        height: 1,
        backgroundColor: "#CCCCCC",
    },
    text: {
    },
    button: {
        alignItems: "center",
        backgroundColor: "#27b6cc",
        padding: 10
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        fontSize: 12,
    },
    page: {
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    tableContainer: {
        flexDirection: 'row',
        // flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
    headerContainer: {
        marginTop: -10
    },
    container: {
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        flexGrow: 1,
    },
    titleContainer: {
        marginTop: "auto",
        textAlign: 'center',
        fontSize: 10,
    },
});
