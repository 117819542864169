import React, { useEffect, useState } from 'react';
import data from './data';
import { ScrollView, StyleSheet, View } from 'react-native';
import ProductCard from './ProductCard';
import { Surface, Text } from 'react-native-paper';
import Header from '../auth/components/header';
import { useDispatch, useSelector } from 'react-redux';
import { partnermaincenterDateandCount } from '../../state/actions/dynamicCountActions';
import isEmpty from '../../state/validations/is-empty';

const PartnerMain = (props: any) => {
  const { navigation } = props;
  const EmailID = useSelector(state => state.auth?.user);
  const esiColor = useSelector(state => state.theme);
  const dynamicvalue = useSelector(state => state.corporatedateandcount.partnermaincenterdateandcounts.all);

  const dispatch = useDispatch();
  const [products] = useState(data);
  useEffect(() => {
    if (!isEmpty(EmailID)&&!isEmpty(dynamicvalue)) {
       let formData = {
          Records_Filter: "FILTER",
          Partner_Details_Id: EmailID.Partner_Details_Id
        }
        dispatch(partnermaincenterDateandCount(formData));
       }
  }, [EmailID]);


  const styles = StyleSheet.create({
    productCard: {
      height: '100%'  
    }
  });

  return (
    <Surface style={{ flex: 1,backgroundColor:esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <View style={{ justifyContent: 'center', backgroundColor: esiColor.BackgroundColor, alignItems: 'center' }}>
        <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10 ,color:esiColor.brandFontColor}}>
          Partner Main Centre
        </Text>
      </View>
      <ScrollView>
        <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
          {products.map(product => (
            <View key={product.id}>
              <ProductCard
                style={styles.productCard} esiColor={esiColor}
                product={product} navigation={navigation}
              />
            </View>
          ))}
        </View>
      </ScrollView>
    </Surface>
  );
};

export default PartnerMain;