import {
    CONTENTDATEANDCOUNT_REQUEST,
    CONTENTDATEANDCOUNT_SUCCESS,
    CONTENTDATEANDCOUNT_FAIL,
    PARTNERMAINCENTERDATEANDCOUNT_REQUEST,
    PARTNERMAINCENTERDATEANDCOUNT_SUCCESS,
    PARTNERMAINCENTERDATEANDCOUNT_FAIL,
    ORDERDATEANDCOUNT_REQUEST,
    ORDERDATEANDCOUNT_SUCCESS,
    ORDERDATEANDCOUNT_FAIL,
    PARTNERMAINCENTERDATEANDCOUNTNEW_REQUEST,
    PARTNERMAINCENTERDATEANDCOUNTNEW_SUCCESS,
    PARTNERMAINCENTERDATEANDCOUNTNEW_FAIL,
    USERBIDDINGDATEANDCOUNT_REQUEST,
    USERBIDDINGDATEANDCOUNT_SUCCESS,
    USERBIDDINGDATEANDCOUNT_FAIL,
    SERVICEBIDDINGDATEANDCOUNT_REQUEST,
    SERVICEBIDDINGDATEANDCOUNT_SUCCESS,
    SERVICEBIDDINGDATEANDCOUNT_FAIL,
    B2BBIDDINGDATEANDCOUNT_REQUEST,
    B2BBIDDINGDATEANDCOUNT_SUCCESS,
    B2BBIDDINGDATEANDCOUNT_FAIL
} from '../actions/dynamicCountActions'

const initialState = {
    contentdateandcounts: { all: [], error: '', isLoading: false },
    partnermaincenterdateandcounts: { all: [], error: '', isLoading: false },
    partnermaincenterdateandcountsnew: { all: [], error: '', isLoading: false },
    orderdateandcounts: { all: [], error: '', isLoading: false },
    userbiddingdateandcounts: { all: [], error: '', isLoading: false },
    servicebiddingdateandcounts: { all: [], error: '', isLoading: false },
    b2bbiddingdateandcounts: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case CONTENTDATEANDCOUNT_REQUEST:
            return { ...state, contentdateandcounts: { all: state.contentdateandcounts.all, error: '', isLoading: true } };
        case CONTENTDATEANDCOUNT_SUCCESS:
            return { ...state, contentdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case CONTENTDATEANDCOUNT_FAIL:
            return { ...state, contentdateandcounts: { all: [], error: action.payload, isLoading: false } };

        case PARTNERMAINCENTERDATEANDCOUNT_REQUEST:
            return { ...state, partnermaincenterdateandcounts: { all: state.partnermaincenterdateandcounts.all, error: '', isLoading: true } };
        case PARTNERMAINCENTERDATEANDCOUNT_SUCCESS:
            return { ...state, partnermaincenterdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case PARTNERMAINCENTERDATEANDCOUNT_FAIL:
            return { ...state, partnermaincenterdateandcounts: { all: [], error: action.payload, isLoading: false } };



        case PARTNERMAINCENTERDATEANDCOUNTNEW_REQUEST:
            return { ...state, partnermaincenterdateandcountsnew: { all: state.partnermaincenterdateandcountsnew.all, error: '', isLoading: true } };
        case PARTNERMAINCENTERDATEANDCOUNTNEW_SUCCESS:
            return { ...state, partnermaincenterdateandcountsnew: { all: action.payload, error: '', isLoading: false } };
        case PARTNERMAINCENTERDATEANDCOUNTNEW_FAIL:
            return { ...state, partnermaincenterdateandcountsnew: { all: [], error: action.payload, isLoading: false } };

        case ORDERDATEANDCOUNT_REQUEST:
            return { ...state, orderdateandcounts: { all: state.orderdateandcounts.all, error: '', isLoading: true } };
        case ORDERDATEANDCOUNT_SUCCESS:
            return { ...state, orderdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case ORDERDATEANDCOUNT_FAIL:
            return { ...state, orderdateandcounts: { all: [], error: action.payload, isLoading: false } };


        case USERBIDDINGDATEANDCOUNT_REQUEST:
            return { ...state, userbiddingdateandcounts: { all: state.userbiddingdateandcounts.all, error: '', isLoading: true } };
        case USERBIDDINGDATEANDCOUNT_SUCCESS:
            return { ...state, userbiddingdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case USERBIDDINGDATEANDCOUNT_FAIL:
            return { ...state, userbiddingdateandcounts: { all: [], error: action.payload, isLoading: false } };


        case SERVICEBIDDINGDATEANDCOUNT_REQUEST:
            return { ...state, servicebiddingdateandcounts: { all: state.servicebiddingdateandcounts.all, error: '', isLoading: true } };
        case SERVICEBIDDINGDATEANDCOUNT_SUCCESS:
            return { ...state, servicebiddingdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case SERVICEBIDDINGDATEANDCOUNT_FAIL:
            return { ...state, servicebiddingdateandcounts: { all: [], error: action.payload, isLoading: false } };


        case B2BBIDDINGDATEANDCOUNT_REQUEST:
            return { ...state, b2bbiddingdateandcounts: { all: state.b2bbiddingdateandcounts.all, error: '', isLoading: true } };
        case B2BBIDDINGDATEANDCOUNT_SUCCESS:
            return { ...state, b2bbiddingdateandcounts: { all: action.payload, error: '', isLoading: false } };
        case B2BBIDDINGDATEANDCOUNT_FAIL:
            return { ...state, b2bbiddingdateandcounts: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}

