import React, { useState } from 'react';
import { Card, Button, TextInput, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import {
  getProductsizeByIdAction,
  updateProductSizeAction,
} from '../../../state/actions/productsizeAction';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';

// import esiColor from '../../../constants/Colors';

const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];
const messure = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Millimeter(mm)',
    label: 'Millimeter(mm)'
  },
  {
    value: 'Centimeter(cm)',
    label: 'Centimeter(cm)'
  },
  {
    value: 'Inches(Inch)',
    label: 'Inches(Inch)'
  },
  {
    value: 'Foot(ft)',
    label: 'Foot(ft)'
  },
  {
    value: 'Meter(m)',
    label: 'Meter(m)'
  },
  {
    value: 'Kilogram(kg)',
    label: 'Kilogram(kg)'
  },
  {
    value: 'millilitre(ml)',
    label: 'millilitre(ml)'
  },
];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");



const EditProductSize = (props: any) => {
  const toast = useToast();
  const { navigation } = props;
  const dispatch = useDispatch();
  const [showDropDown, setShowDropDown] = React.useState('');
  const [showDropDownactive, setShowDropDownactive] = React.useState('');
  const productsize = props.route?.params?.productsizes;
  const [value, setValue] = React.useState('');
  const EmailID = useSelector(state => state.auth?.user);

  const [data, setData] = useState([]);
  const productsizes = props.route?.params?.productsizess;

  const callbackProductsizeData = (data: any) => {
    setData(data)
  }
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (productsize) {
      let formData = {
        "search": "",
        "Product_Category": "",
        "Product_Master_Id": "",
        "Product_Size_Id": productsize,
        "Records_Filter": "FILTER",
        "Size": "",
        "Size_Type": "",
        "Status": ""
      };
      dispatch(getProductsizeByIdAction(formData, callbackProductsizeData));
    }
  }, [productsize]);

  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const esiColor = useSelector(state => state.theme);

  React.useEffect(() => {
    if (!(permissions.Product_Master_Product_Color_Product_Size === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  //form reset
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  /*====================================
          Handler
  ====================================*/
  const values = {
    Modified_By: EmailID.Email_Id,
    Product_Master_Id: data.Product_Master_Id,
    Product_Size_Id: data.Product_Size_Id,
    Size: data.Size,
    Size_Measurement: data.Size_Measurement,
    Size_Type: data.Size_Type,
    Status: data.Status
  }

  const Form_Validation = Yup.object().shape({
    Size_Measurement: Yup.string().required('Size Measurement is required.'),
    Size: Yup.string().required('Size is required.'),
    Size_Type: Yup.string().required('Size Type is required.'),
    Status: Yup.string().required('Status is required.'),
  })
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      formValues.Modified_By = EmailID.Email_Id;
      dispatch(updateProductSizeAction(formValues, productsize, props.navigation, toast));
    }
  }

  const styles = StyleSheet.create({
    container: {
      flexShrink: 1,
    },
    texinputstyle: {
      backgroundColor: 'white',
      paddingTop: 10,
      width: (Platform.OS === 'web') ? "49%" : "100%"
    },
    button: {
      marginTop: 30,
      width: '75%',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'green',
    },
    cardCircle: {
      width: (Platform.OS === 'web') ? "75%" : "95%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "2%",
      marginBottom: "2%",
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1,
      marginBottom: "2%"
    },

    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    checkcontainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: "-70%"
    },
    phonenumbercontainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    phoneContainer: {
      width: '100%',
      height: 50,
    },
    phonetextInput: {
      paddingVertical: 0,
    },
    containerone: {
      // backgroundColor: 'white',
      paddingTop: 15
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 8,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor

    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor

    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
          {(props) => (
            <View style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              flexWrap: "wrap"
            }}>
              <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                  onPress={() => {
                    props.resetForm();
                    navigation.navigate('ProductSizes', { master: productsizes })
                  }}>
                  <Text style={{ color: esiColor.GBFColor }}>Go Back</Text>
                </Button>
              </View>
              <Card style={{
                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.5,
                borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 1
              }}>
                <Card.Content>
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                    Edit Size
                  </Text>
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <View style={styles.container}>
                        <View style={styles.containerone}>
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Size_Measurement && props.errors.Size_Measurement) && { color: '#b00020' }]}>
                            Size Measurement
                          </Text>
                          <EsiSearchDropdown
                            data={messure}
                            style={[styles.dropdown, Boolean(props.touched.Size_Measurement && props.errors.Size_Measurement) && { borderColor: '#b00020' }]}
                            labelField="label"
                            valueField="value"
                            searchPlaceholder={'Select  Size Measurement'}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            maxHeight={messure.length > 1 ? 250 : 200}
                            search={messure.length > 1 ? true : false}
                            value={props.values.Size_Measurement}
                            onUpdateValue={item => {
                              props.setFieldValue('Size_Measurement', item.value)
                              setShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Size_Measurement && props.errors.Size_Measurement)}>
                            <ErrorMessage name="Size_Measurement" />
                          </HelperText>
                        </View>
                      </View>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: 7 }}>
                      <TextInput
                        style={{ backgroundColor: esiColor.BackgroundColor }}
                        theme={{ colors: { text: esiColor.DescColor, placeholder: esiColor.itemColor } }}
                        underlineColor={esiColor.itemColor}
                        outlineColor={esiColor.itemColor}
                        activeOutlineColor={esiColor.itemColor}
                        placeholderTextColor={esiColor.SPHTextColor}
                        error={Boolean(props.touched.Size && props.errors.Size)}
                        label="Size"
                        mode='outlined'

                        value={props.values.Size}
                        onChangeText={props.handleChange("Size")}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Size && props.errors.Size)}>
                        {props.touched.Size && props.errors.Size}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: 7 }}>
                      <TextInput
                        style={{ backgroundColor: esiColor.BackgroundColor, }}
                        theme={{ colors: { text: esiColor.DescColor, placeholder: esiColor.itemColor } }}
                        underlineColor={esiColor.itemColor}
                        outlineColor={esiColor.itemColor}
                        activeOutlineColor={esiColor.itemColor}
                        placeholderTextColor={esiColor.SPHTextColor}
                        error={Boolean(props.touched.Size_Type && props.errors.Size_Type)}
                        label="Size Type"
                        mode='outlined'
                       
                        value={props.values.Size_Type}
                        onChangeText={props.handleChange("Size_Type")}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Size_Type && props.errors.Size_Type)}>
                        {props.touched.Size_Type && props.errors.Size_Type}
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <View style={styles.container} >
                        <View style={styles.containerone}>
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                            Status
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={statusOption}
                            labelField="label"
                            valueField="value"
                            maxHeight={statusOption.length > 1 ? 250 : 200}
                            search={statusOption.length > 1 ? true : false}
                            searchPlaceholder={'Select Status'}
                            value={props.values.Status}
                            onUpdateValue={item => {
                              props.setFieldValue('Status', item.value)
                              setShowDropDownactive(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                            <ErrorMessage name="Status" />
                          </HelperText>
                        </View>
                      </View>
                    </View>
                  </View>
                  <Button style={{ width: 130, marginTop: "3%", marginLeft: "auto", marginRight: "auto", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                    <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                  </Button>
                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};


export default EditProductSize;