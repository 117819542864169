// /**
//  * Learn more about deep linking with React Navigation
//  * https://reactnavigation.org/docs/deep-linking
//  * https://reactnavigation.org/docs/configuring-links

//  */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { RootStackParamList } from '../../types';
const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      SignIn: 'sign-in',
      SignUp: 'sign-up',
      ForgotPassword: 'forgot-password',
      SetPassword: 'set-password',
      ActivateAccount: 'activate-account',
      Employees: 'employees',
      AddEmployee: 'add-employee',
      EditEmployee: 'edit-employee',
      OrderTracking: "order-tracking",
      PartnerBiddingProducts: 'my-bidding-products',
      WalletHistory: 'wallet-history',
      MyWallet: 'my-wallet',
      B2C: {
        path: 'b2c',
        screens: {
          Dashboard: 'dashboard',
          PartnerMain: 'partner-main',
          Addtickets: 'add-tickets',
          Reports: 'reports',
          OrderMain: 'order-main',
          PartnerProfile: 'partner-profile',
          EmployeProfile: 'employe-profile',
          BrandsGet: 'brands',
          AddBrand: 'add-brands',
          ReportsGet: 'get-reports',
          OrderStatistics: 'reports-orders',
          ProfitStatistics: 'reports-profit',
          UserStatistics: 'reports-users',
          CouponStatistics: 'reports-coupons',
          DealStatistics: 'reports-deals',
          ProductStatistics: 'reports-products',
          Employees: 'employees',
          PartnerBank: 'partner-bank',
          Transactions: 'transactions',
          AddEmployee: 'add-employee',
          EditEmployee: 'edit-employee',
          Notification: 'notification',
          UserBiddingProductsOrderNotification: 'user-bidding-products-notification',
          B2BBiddingProductOrderNotification: 'b2b-bidding-products-notification',
          UserBiddingServicesOrderNotification: 'user-bidding-services-notification',
          HowPartnerAssociationWorks: 'how-partner-association-works',
          ServiceLevelAgreement: 'service-level-agreement',
          deliveryAgentDetails: 'delivery-agent-details',
          Settings: 'settings',
          couponNotification: 'coupon-notification',
          dealNotification: 'deal-notification',
          productNotification: 'product-notification',
          orderNotification: 'order-notification',
          b2borderNotification: 'b2b-order-notification',
          Tickets: 'tickets',
          AddTicket: 'add-ticket',
          EditTicket: 'edit-ticket',
          AddressGet: 'address',
          AddAddress: 'add-address',
          EditAddress: 'edit-address',
          DealsGet: 'deals',
          AddDealBasic: 'add-deal-basic',
          AddDealConfig: 'add-deal-config',
          EditDealBasic: 'edit-deal-basic',
          EditDealConfig: 'edit-deal-config',
          PartnerpaymentGet: 'partnerpayment',
          EditPartnerpayment: 'edit-partnerpayment',
          UserpaymentGet: 'user-payment',
          EditUserpayment: 'edit-userpayment',
          TransactionhistoryGet: 'transaction-history',
          EditTransactionhistory: 'edit-transaction-history',
          ProductMasterGet: 'product-masters',
          AddProductMaster: 'add-product-master-basic-info',
          AddProductMasters: 'add-product-master-configuration-info',
          AddProductmasters: 'add-product-master-features-info',
          EditProductMaster: 'edit-product-master-basic-info',
          EditProductMasters: 'edit-product-master-configuration-info',
          EditProductmasters: 'edit-product-master-features-info',
          ProductColors: 'product-Color',
          AddProductColor: 'add-product-color',
          EditProductColor: 'edit-product-color',
          ProductSizes: 'product-size',
          AddProductSize: 'add-product-size',
          EditProductSize: 'edit-product-size',
          OrderManagementGet: 'order-management',
          EditOrder: 'edit-Order',
          B2bEditOrder: 'b2b-edit-Order',
          OrderDetails: 'order-details',
          B2bOrderDetails: 'b2b-order-details',
          GiftVendorsGet: 'gift-vendors',
          EditGiftVendor: 'edit-gift-vendor',
          Gift: 'gifts-get',
          GiftImage: 'images-get',
          EditGift: 'edit-gift',
          AddImage: 'add-image',
          EditImage: 'edit-image',
          Products: 'products',
          WalletHistory: 'wallet-history',
          MyWallet: 'my-wallet',
          AddProduct: 'add-product-basic-info',
          AddProducts: 'add-product-configuration-info',
          Addproducts: 'add-product-features-info',
          addproducts: 'add-product-upload-info',
          EditProduct: 'edit-product-basic-info',
          EditProducts: 'edit-products-configuration-info',
          Editproducts: 'edit-products-features-info',
          editproducts: 'edit-products-upload-info',
          RelatedProducts: 'related-products',
          AddRelatedProducts: 'add-related-products',
          EditRelatedProducts: 'edit-related-products',
          ProductPartnerGet: 'partner-products',
          AddProductPartner: 'add-product-partner-basic-info',
          AddPartnerProductsPagetwo: 'add-product-partners-configuration-info',
          EditProductPartner: 'edit-product-partner-basic-info',
          EditPartnerProductsPagetwo: 'edit-product-partner-configuration-info',
          CouponsGet: 'coupons',
          AddCouponBasic: 'add-coupon-basic',
          AddcouponConfig: 'add-coupon-config',
          EditCouponBasic: 'edit-coupon-basic',
          EditCouponConfig: 'edit-coupon-config',
          OrderReturnGet: 'return-orders',
          OrderCancelGet: 'cancel-orders',
          SettingsGet: 'get-settings',
          AddSetting: 'add-setting',
          EditSetting: 'edit-setting',
          Inventory: 'inventory',
          AddInventory: 'add-inventory',
          EditInventory: 'edit-inventory',
          HowPartnerAssociationWorksGet: 'get-how-partner-association-works',
          ServiceLevelAgreementGet: 'get-service-level-agreement',
          SBCPartnercityGet: 'shopbycity/partner-cities',
          AddSBCPartnercity: 'shopbycity/add-partnercity',
          EditSBCPartnercity: 'shopbycity/edit-partnercity',
          SBCPartnercitycouponGet: 'shopbycity/partnercity-coupons',
          AddSBCPartnercitycoupon: 'shopbycity/add-partner-city-coupon',
          EditSBCPartnercitycoupon: 'shopbycity/edit-partner-city-coupon',
          SBCPartnercitydealGet: 'shopbycity/partnercity-deals',
          AddSBCPartnercitydeal: 'shopbycity/add-partnercitydeal',
          EditSBCPartnercitydeal: 'shopbycity/edit-partner-city-deal',
          SBCPartnercityproductGet: 'shopbycity/partnercity-products',
          AddSBCPartnercityproduct: 'shopbycity/add-partner-city-product',
          EditSBCPartnercityproduct: 'shopbycity/edit-partner-city-product',
          ReviewsandRatingsGet: 'reviews-and-ratings',
          ShippingGet: 'shipping',
          TabOneScreen: 'one',
          TabTwoScreen: 'two',
          DeliveryAgent: 'get-delivery-agent-details',
          AddDeliveryAgent: "add-delivery-agent",
          EditDeliveryAgent: "edit-delivery-agent",
          EditShipping: 'edit-shipping',
          ViewShipping: 'view-shipping',
          ReturnedShippingGet: 'returned-shippings',
          ViewReturnedShipping: 'view-returned-shipping',
          CancelledShippingGet: 'cancelled-shippings',
          ViewCancelledShipping: 'view-cancelled-shipping',
          PrivacyPolicy: 'privacy-policy',
          ContactUs: 'contact-us',
          TermsAndConditions: 'terms-and-conditions',
          DashboardLayout: 'dashboard-layout',
          PartnerMainCenter: 'partner-main-center',
          OrderMainCenter: 'main-ordermanagement',
          ProductMainCenter: 'product-management',
          PaymentsMainCenter: 'payment-management',
          Ticketing: 'ticket',
          AddTicketing: 'add-ticketing',
          Comments: 'comment',
          addpartnershopbylocation: 'add-partner-shop-by-location',
          editpartnershopbylocation: 'edit-partner-shop-by-location',
          partnershopbylocationget: 'partner-shop-by-location-get',
          Modal: 'modal',
          NotFound: '*',
          ShoppersGet: 'all-shopper',
          AddShoppersProducts: 'add-shoppers-product',
          EditShoppersProduct: 'edit-shoppers-product',
          // ShoppersInventoryGet: 'inventory-shoppers-get',
          ShoppersInventoryCreate: 'add-inventory-shoppers',
          ShoppersInventoryUpdate: 'edit-inventory-shoppers',
          PartnerownProductsGet: 'own-partner-products-get',
          AddownProductPartner: 'add-own-product-partner-basicinfo',
          AddownProductPartnerPagetwo: 'add-own-product-partner-configurationinfo',
          EditownProductPartner: 'edit-own-product-partner-basicinfo',
          EditownProductPartnerPageTwo: 'Edit-own-product-partner-configurationinfo',
          ShoppersownGet: 'shoppers-own-get',
          AddownShopperProducts: 'add-own-shopper-products',
          EditownShoppersProduct: 'edit-own-shoppers-product',
          PartnerBiddingMainCenter: 'partner-bidding-main-center',
          PartnerBiddingProducts: 'my-bidding-products',
          AddPartnerBiddingProducts: 'add-bidding-products',
          EditPartnerBiddingProducts: 'edit-bidding-products',
          UserNewBiddingQuotes: 'user-new-bidding-requests',
          PartnerBiddingQuotes: 'partner-bidding-quotes',
          EditPartnerBiddingQuotes: 'edit-partner-bidding-quote',
          userNewbiddingRequestDetails: 'user-new-did-details',
          B2BUserNewBiddingProductQuotes: "b2b-user-new-bidding-products-quotes",
          userb2bNewbiddingRequestDetails: "b2b-user-new-bidding-products-quotes-details",
          B2BUserPartnerBiddingQuotes: 'b2b-user-partner-bidding-quotes',
          EditB2BUserPartnerBiddingQuotes: 'edit-b2b-user-partner-bidding-quote',
        },
      },
      B2B: {
        path: 'b2b',
        screens: {
          Main: {

            screens: {

              All: 'all'

            },

          },
          DeliveryAgent: 'get-delivery-agent-details',
          AddDeliveryAgent: "add-delivery-agent",
          EditDeliveryAgent: "edit-delivery-agent",
          PartnerProfile: 'partner-profile',
          EmployeProfile: 'employe-profile',
          ServiceLevelAgreementGet: 'get-service-level-agreement',
          AddressGet: 'address',
          AddAddress: 'add-address',
          EditAddress: 'edit-address',
          AllProducts: "allproducts",
          ProductDetails: "product-details",
          Transactions: "transactions",
          OrderDetail: "orderdetails",
          PrivacyPolicy: 'privacy-policy',
          TermsAndConditions: 'terms-and-conditions',
          Ticketing: 'ticket',
          Addtickets: 'add-tickets',
          ContactUs: 'contact-us',
          Orders: "orders",
          Cart: "cart",
          CartSummary: "cart-summary",
          Brandsb2b: "Brandsb2b",
          Categoryb2b: "Categoryb2b",
          Partnersb2b: "Partnersb2b",
          BrandDetailsb2b: "BrandDetailsb2b",
          PartnerDetailsb2b: "PartnerDetailsb2b",
          SubCategory: "Sub-Category"
        },
      },
      Services: {
        path: 'services',
        screens: {
          WalletHistory: 'wallet-history',
          MyWallet: 'my-wallet',
          ServicesDashboard: 'dashboard',
          Newuserservices: 'new-user-services',
          EditUserservice: 'edit-userservice',
          EditUserservicepagetwo: 'edit-Userservicepagetwo',
          Partnerservice: 'partner-service',
          Addpartnerservice: 'add-partnerservice',
          AddPartnerservicesPagetwo: 'add-PartnerservicesPagetwo',
          EditPartnerservice: 'edit-Partnerservice',
          EditPartnerservicepagetwo: 'edit-Partnerservicepagetwo',
          PartnerProfile: 'partner-profile',
          EmployeProfile: 'employe-profile',
          Accepteduserservices: 'accepted-user-services',
          EditAcceptedUserservice: 'edit-accepted-user-service',
          EditNewUserservice: 'edit--user-service',
          EditInprogressuserservice: 'edit-in-progress-user-service',
          Rejecteduserservices: 'rejected-user-services',
          Completeduserservices: 'completed-user-services',
          Inprogressuserservices: 'in-progress-user-services',
          UserBiddingServicesOrderNotification: 'user-bidding-services-notification',
          EmployeNewuserservices: 'emplyee-new-services',
          WalletHistory: 'wallet-history',
          AddressGet: 'address',
          AddAddress: 'add-address',
          EditAddress: 'edit-address',
          EmployeInprogressserservices: 'emplyee-in-progress-services',
          EmployeCompletedserservices: 'emplyee-completed-services',
          EditEmployeInprogressuserservice: 'edit-emplyee-in-progress-services',
          PartnerBiddingServicesMain: 'bidding-service-main-center',
          AddPartnerBiddingServices: 'add-available-services',
          EditPartnerBiddingServices: "edit-available-services",
          PartnerBiddingServices: 'available-services',
          UserNewBiddingQuotesForServices: 'new-user-bidding-services',
          userNewbiddingRequestServiceDetails: 'new-user-bidding-service-request',
          PartnerBiddingServiceQuotes: 'partner-bidding-service-quotes',
          EditPartnerServiceBiddingQuotes: 'edit-partner-bidding-service-quotes',
          Employees: 'employees',
          AddEmployee: 'add-employee',
          EditEmployee: 'edit-employee',

        },
      },
      Properties: {
        path: 'properties',
        screens: {
          PartnerProfile: 'partner-profile',
          EmployeProfile: 'employe-profile',
          PropertiesDashboard: 'dashboard',
          GetProperties: 'all-properties',
          AddProperty: 'add-property',
          EditProperty: 'edit-property',
          EditPropertyPageTwo: 'edit-property-image-info',
          GetEvents: 'all-events',
          EditUserEvents: 'edit-events-basic-info',
          EditUserEventspagetwo: 'edit-events-configuration-info',
          bookeddatesget: "bookeddatesget",
        },
      },
      Biddingproducts: {
        path: 'bidding',
        screens: {
          Biddingproducts: 'my-bidding-product',
          CategoryFilters: 'category-filters',
          NewBidProducts: 'products',
          BidProductDetails: 'bid-products-details',
          CartOfBids: 'bidding-cart',
          PartnerQuotedetails: 'bid-quotes',
        },
      },
      Farmer: {
        path: 'farmer',
        screens: {
          Farmer_Dashboard: 'farmer_dashboard',
          FarmerPartnerProductMain: 'products-dashboard',
          FarmerOrdersMain:'orders-list',
          FarmerProductGet: 'farmer-products',
          AddFarmerProducts: 'add-farmer-product-basic-info',
          AddFarmerProductspagetwo: 'add-farmer-product-configuration-info',
          AddFarmerProductspagethree: 'add-farmer-product-features-info',
          AddFarmerProductspagefour: 'add-farmer-product-upload-info',
          FarmerPartnerProductsGet: 'my-own-products',
          EditFarmerProduct: 'edit-farmer-product-basic-info',
          EditFarmerProductpagetwo: 'edit-farmer-product-configuration-info',
          EditFarmerProductpagethree: 'edit-farmer-product-features-info',
          EditFarmerProductpagefour: 'edit-farmer-product-upload-info',
          AddFarmerPartnerProducts: 'add-my-own-product-basic-info',
          AddFarmerPartnerProductspagetwo: 'add-my-own-product-configuration-info',
          EditFarmerPartnerProducts: 'edit-my-own-product-basic-info',
          EditFarmerPartnerProductspagetwo: 'edit-my-own-product-configuration-info',
          FarmerInventoryDetails: 'inventory',
          EditFarmerIneventory: 'edit-inventory',
          PartnerProfile: 'partner-profile',
          WalletHistory: 'wallet-history',
          PartnerBank: 'partner-bank',
          MyWallet: 'my-wallet',
          Transactions: 'transactions',
          PrivacyPolicy: 'privacy-policy',
          ContactUs: 'contact-us',
          TermsAndConditions: 'terms-and-conditions',
          Ticketing: 'support-tickets',
          Addtickets: 'add-tickets',
          Comments: 'comments',
          FarmerOrderDetails:'farmer-orders-list',
          FarmerOrderCancelGet:'farmer-cancel-orders',
          FarmerOrderReturnGet:'farmer-resturn-orders',
          FarmerEditOrders:'edit-farmer-order',
          EmployeProfile:'employe-profile-update',
          FarmerAddressDetails:'farmer-address',
          AddFarmerAddress:'add-farmer-address',
          EditFarmerAddress:'edit-farmer-address'
        },
      },
    },
  },
};

export default linking;