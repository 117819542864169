import React, { useState, useCallback } from 'react';
import { Card, Button, TextInput, HelperText, Checkbox, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    EditownPartnerproduct
} from '../../../state/actions/partnerproductsAction'
import EsiDatePicker from '../../../components/custom/date/date';
import Header from '../../auth/components/header';
import isEmpty from '../../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");


const EditownProductPartnerPageTwo = (props: any) => {
    const { navigation } = props;
    const toast = useToast();
    const esiColor = useSelector(state => state.theme);
    const productpartner = useSelector(state => state.productpartners.productpartnerdata.partner);
    const EmailID = useSelector(state => state.auth?.user);
    const [errorMessage, setErrorMessage] = useState()
    const dispatch = useDispatch();
    let Product
    const Change = (value) => {
        Product = value
    }
    const setType = (Product_Master_Id) => {
        if (Product_Master_Id !== "") {
            dispatch(getAllColours(Product_Master_Id, useCallback));
        }
    }
    //Screen dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    React.useEffect(() => {
        if (isEmpty(productpartner)) {
            navigation.navigate('EditownProductPartner')
        }
    }, [productpartner]
    );
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    /*====================================
            Handler
    ====================================*/
    const values = {
        Partner_Product_Id: productpartner.Partner_Product_Id,
        Partner_Original_Discount_Amount: productpartner.Partner_Original_Discount_Amount,
        Partner_Original_Discount: productpartner.Partner_Original_Discount,
        Partner_Original_Selling_Price: productpartner.Partner_Original_Selling_Price,
        Price: productpartner.Partner_Price,
        Gift_Wrap_Amount: productpartner.Gift_Wrap_Amount,
        Discount_Type: productpartner.Discount_Type,
        Discount: productpartner.Discount,
        Esi_Cashback_Type: productpartner.Esi_Cashback_Type,
        Esi_Cashback: productpartner.Esi_Cashback,
        Discount_From_Date: productpartner.Discount_From_Date,
        Discount_To_Date: productpartner.Discount_To_Date,
        Max_Days_To_Return: productpartner.Max_Days_To_Return,
        Shipping_Amount: productpartner.Shipping_Amount,
        Warranty_Period: productpartner.Warranty_Period,
        Modified_By: EmailID.Email_Id,
        Is_Partner_Shipping_Available: productpartner.Is_Partner_Shipping_Available > 0 ? true : false,
        Add_To_Cart: productpartner.Add_To_Cart > 0 ? true : false,
        Esi_Commission: productpartner.Esi_Commission,
        Esi_Commission_Type: productpartner.Esi_Commission_Type,
        Exclusive: productpartner.Exclusive > 0 ? true : false,
        Extra_CashBack: productpartner.Extra_Cashback > 0 ? true : false,
        Get_Coupon: productpartner.Get_Coupon > 0 ? true : false,
        Is_Default: productpartner.Is_Default > 0 ? true : false,
        Is_Esi_Shipping_Aavailable: productpartner.Is_Shipping_Available > 0 ? true : false,
        Is_Gift_Wrap_Available: productpartner.Is_Gift_Wrap_Available > 0 ? true : false,
        Is_Popular: productpartner.Is_Popular > 0 ? true : false,
        Is_Return_Available: productpartner.Is_Return_Available > 0 ? true : false,
        Is_Shop_By_City_Only: productpartner.Is_Shop_By_City_Only > 0 ? true : false,
        Is_Product_Available: productpartner.Is_Product_Available > 0 ? true : false,
        Is_Upcoming: productpartner.Is_Coming > 0 ? true : false,
        Partner_Address_Id: productpartner.Partner_Address_Id,
        Partner_Details_Id: productpartner.Partner_Details_Id,
        Product_Id: productpartner.Product_Id,
        Status: productpartner.Status,
        Todays_Hot: productpartner.Todays_Hot > 0 ? true : false,
        Verified: productpartner.Verified > 0 ? true : false,
        What_Hot: productpartner.Whats_Hot > 0 ? true : false,
        Cashback_Label: productpartner.Cashback_Label,
        Discount_Label: productpartner.Discount_Label,
    }
    //Form validations
    const Form_Validation = Yup.object().shape({
        // Discount_From_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Required From Date should be greater than or equal to today.").required("From Date is required."),
        // Discount_To_Date: Yup.date().when("Discount_From_Date", (Discount_From_Date, schema) => Discount_From_Date && schema.min(Discount_From_Date, "To Date can't be before From Date.")).required("To Date is required."),
        Max_Days_To_Return: Yup.number().max(30, "Max Days To Return must be less than 30.").typeError('Max Days To Return must be a number'),
    })
    const onSubmit = (values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Add_To_Cart) {
                formValues.Add_To_Cart = 1;
            } else {
                formValues.Add_To_Cart = 0;
            }
            if (values.Is_Gift_Wrap_Available) {
                formValues.Is_Gift_Wrap_Available = 1;
            } else {
                formValues.Is_Gift_Wrap_Available = 0;
            }
            if (values.Is_Esi_Shipping_Aavailable) {
                formValues.Is_Esi_Shipping_Aavailable = 1;
            } else {
                formValues.Is_Esi_Shipping_Aavailable = 0;
            }
            if (values.Verified) {
                formValues.Verified = 1;
            } else {
                formValues.Verified = 0;
            }
            if (values.Get_Coupon) {
                formValues.Get_Coupon = 1;
            } else {
                formValues.Get_Coupon = 0;
            }
            if (values.Exclusive) {
                formValues.Exclusive = 1;
            } else {
                formValues.Exclusive = 0;
            }
            if (values.Is_Popular) {
                formValues.Is_Popular = 1;
            } else {
                formValues.Is_Popular = 0;
            }
            if (values.What_Hot) {
                formValues.What_Hot = 1;
            } else {
                formValues.What_Hot = 0;
            }
            if (values.Todays_Hot) {
                formValues.Todays_Hot = 1;
            } else {
                formValues.Todays_Hot = 0;
            }
            if (values.Extra_CashBack) {
                formValues.Extra_CashBack = 1;
            } else {
                formValues.Extra_CashBack = 0;
            }
            if (values.Is_Upcoming) {
                formValues.Is_Upcoming = 1;
            } else {
                formValues.Is_Upcoming = 0;
            }
            if (values.Is_Default) {
                formValues.Is_Default = 1;
            } else {
                formValues.Is_Default = 0;
            }
            if (values.Is_Esi_Shipping_Available) {
                formValues.Is_Esi_Shipping_Available = 1;
            } else {
                formValues.Is_Esi_Shipping_Available = 0;
            }
            if (values.Is_Partner_Shipping_Available) {
                formValues.Is_Partner_Shipping_Available = 1;
            } else {
                formValues.Is_Partner_Shipping_Available = 0;
            }
            if (values.Is_Return_Available) {
                formValues.Is_Return_Available = 1;
            } else {
                formValues.Is_Return_Available = 0;
            }
            if (values.Is_Shop_By_City_Only) {
                formValues.Is_Shop_By_City_Only = 1;
            } else {
                formValues.Is_Shop_By_City_Only = 0;
            }
            if (values.Is_Product_Available) {
                formValues.Is_Product_Available = 1;
            } else {
                formValues.Is_Product_Available = 0;
            }
            formValues.Modified_By = EmailID.Email_Id;
            dispatch(EditownPartnerproduct(formValues, productpartner, navigation, toast, 1));
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        datePickerStyle: {
            width: 350,
            height: 60,
            marginTop: 6,
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },
        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        checkbox: {
            flexDirection: "row",
            justifyContent: 'flex-start'
        },
        label: {
            marginBottom: 30,
        },
        editor: {
            backgroundColor: "black",
            borderColor: "black",
            borderWidth: 1,
        },
        rich: {
            minHeight: 130,
        },
        richBar: {
            height: 50,
            backgroundColor: "#F5FCFF",
        },
        text: {
            fontWeight: "bold",
            fontSize: 18,
        },
        tib: {
            textAlign: "center",
            color: "#515156",
        },
        a: {
            fontWeight: "bold",
            color: "purple",
        },
        div: {
            fontFamily: "monospace",
        },
        p: {
            fontSize: 30,
        }, container: {
            backgroundColor: 'white',
            paddingTop: 15
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
                    {(props) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('PartnerownProductsGet', { productpartnerparams: productpartner.Product_Id })} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <View>
                                        <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                            Edit Own Product Partner
                                        </Text>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        {/* <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <EsiDatePicker
                                                style={{ width: "100%", backgroundColor: 'white', height: 50 }}
                                                label={'Discount From Date'}
                                                value={props.values.Discount_From_Date}
                                                valueUpdate={(date) => {
                                                    props.setFieldValue('Discount_From_Date', date);
                                                }} />
                                            <HelperText type="error" visible={Boolean(props.touched.Discount_From_Date && props.errors.Discount_From_Date)}>
                                                {props.touched.Discount_From_Date && props.errors.Discount_From_Date}
                                            </HelperText>
                                        </View> */}
                                        {/* <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}> */}
                                        {/* <EsiDatePicker
                                                style={{ width: "100%", backgroundColor: 'white' }} label={'Discount to Date'} value={props.values.Discount_To_Date} valueUpdate={(date) => {
                                                    props.setFieldValue('Discount_To_Date', date);
                                                }} />
                                            <HelperText type="error" visible={Boolean(props.touched.Discount_To_Date && props.errors.Discount_To_Date)}>
                                                {props.touched.Discount_To_Date && props.errors.Discount_To_Date}
                                            </HelperText> */}
                                        {/* </View> */}
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(props.touched.Max_Days_To_Return && props.errors.Max_Days_To_Return)}
                                                label="Max Days To Return"
                                                mode='outlined'
                                                value={props.values.Max_Days_To_Return}
                                                keyboardType='numeric'
                                                onChangeText={props.handleChange("Max_Days_To_Return")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Max_Days_To_Return && props.errors.Max_Days_To_Return)}>
                                                {props.touched.Max_Days_To_Return && props.errors.Max_Days_To_Return}
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(props.touched.Shipping_Amount && props.errors.Shipping_Amount)}
                                                label="Shipping Amount"
                                                mode='outlined'
                                                keyboardType='numeric'
                                                value={props.values.Shipping_Amount}
                                                onChangeText={props.handleChange("Shipping_Amount")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Shipping_Amount && props.errors.Shipping_Amount)}>
                                                {props.touched.Shipping_Amount && props.errors.Shipping_Amount}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={styles.checkbox}>
                                        <Checkbox.Android
                                            color={esiColor.itemColor}
                                            uncheckedColor={esiColor.SBorderColor}
                                            name="Is_Partner_Shipping_Available"
                                            status={props.values.Is_Partner_Shipping_Available ? 'checked' : 'unchecked'}
                                            onPress={() => {
                                                props.setFieldValue("Is_Partner_Shipping_Available", !props.values.Is_Partner_Shipping_Available);
                                            }}
                                        />
                                        <Text style={{ marginTop: 10, marginRight: '3%',color: esiColor.Text  }}>You'r Shipping Available</Text>
                                    </View>
                                    {props?.values?.Max_Days_To_Return > 0 ?
                                        <View style={styles.checkbox}>
                                            <Checkbox.Android
                                                color={esiColor.itemColor}
                                                uncheckedColor={esiColor.SBorderColor}
                                                name="Is_Return_Available"
                                                status={props.values.Is_Return_Available ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Is_Return_Available", !props.values.Is_Return_Available);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, marginRight: '3%', color: esiColor.Text  }}>Is Return Available</Text>
                                        </View>
                                        : null}
                                    <View style={styles.checkbox}>
                                        <Checkbox.Android
                                            color={esiColor.itemColor}
                                            uncheckedColor={esiColor.SBorderColor}
                                            name="Is_Product_Available"
                                            status={props.values.Is_Product_Available ? 'checked' : 'unchecked'}
                                            onPress={() => {
                                                props.setFieldValue("Is_Product_Available", !props.values.Is_Product_Available);
                                            }}
                                        />
                                        <Text style={{ marginTop: 10, marginRight: '3%' ,color: esiColor.Text }}>Is Product Available</Text>
                                    </View>
                                    <Button textColor={esiColor.itemButtenColor} style={{ width: 130, marginTop: "3%", marginLeft: "auto", marginRight: "auto", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                        Save
                                    </Button>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface>
    );
};

export default EditownProductPartnerPageTwo;