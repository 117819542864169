import axios from 'axios';
import config from '../config';

export const PROPERTY_REQUEST = "PROPERTY_REQUEST";
export const PROPERTY_SUCCESS = "PROPERTY_SUCCESS";
export const PROPERTY_FAIL = "PROPERTY_FAIL";

export const SINGLE_PROPERTY_REQUEST = "SINGLE_PROPERTY_REQUEST";
export const SINGLE_PROPERTY_SUCCESS = "SINGLE_PROPERTY_SUCCESS";
export const SINGLE_PROPERTY_FAIL = "SINGLE_PROPERTY_FAIL";

export const PROPERTY_CREATE_REQUEST = "PROPERTY_CREATE_REQUEST";
export const PROPERTY_CREATE_SUCCESS = "PROPERTY_CREATE_SUCCESS";
export const PROPERTY_CREATE_FAIL = "PROPERTY_CREATE_FAIL";

export const PROPERTY_UPDATE_REQUEST = "PROPERTY_UPDATE_REQUEST";
export const PROPERTY_UPDATE_SUCCESS = "PROPERTY_UPDATE_SUCCESS";
export const PROPERTY_UPDATE_FAIL = "PROPERTY_UPDATE_FAIL";

export const PROPERTY_FILTER = "PROPERTY_FILTER";

export const PROPERTY_DATA = 'PROPERTY_DATA';
export const DASHBOARD_TOTAL_PROPERTIRES ='DASHBOARD_TOTAL_PROPERTIRES';
export const DASHBOARD_TOTAL_ACTIVE_BOOKINGS ='DASHBOARD_TOTAL_ACTIVE_BOOKINGS';
export const DASHBOARD_TOTAL_BOOKINGS ='DASHBOARD_TOTAL_BOOKINGS';
/*=============================================================
                  Get All PROPERTY Action
===============================================================*/

const headers = config.headersCommon;

export const getPropertysAction = (formData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: PROPERTY_REQUEST
        });
        let { data } = await axios.post(
            `${config.url}/Function_Hall/Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: PROPERTY_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: PROPERTY_SUCCESS,
                    payload: data?.Function_Hall_Get_Response
                });
            }
        }
    } catch (err) {
        dispatch({
            type: PROPERTY_FAIL,
            payload: err
        });
    }
};

/*=============================================================
                  PROPERTY Create Action
===============================================================*/

export const createPropertyAction = (formData: any, navigation: any, toast: any) => async (dispatch: any) => {
    dispatch({
        type: PROPERTY_CREATE_REQUEST
    });
    axios.post(`${config.url}/Function_Hall/Create`, formData,
        {
            headers: headers
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: PROPERTY_CREATE_SUCCESS
                });
                navigation.navigate('GetProperties')
                const formValue = {
                    "Partner_Detail_ID": formData?.Partner_Detail_Id,
                    "Records_Filter": "FILTER"
                };
                dispatch(getPropertysAction(formValue))
            } else {
                const errors = {};
                dispatch({
                    type: PROPERTY_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PROPERTY_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


/*=============================================================
                  Address Update Action
===============================================================*/

export const updatePropertyAction = (formData: any, navigation: any,) => async (dispatch: any) => {
    dispatch({
        type: PROPERTY_UPDATE_REQUEST
    });
    axios.put(`${config.url}/Function_Hall/Update`, formData,
        {
            headers: headers
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: PROPERTY_UPDATE_SUCCESS
                });
                navigation.navigate('GetProperties')
                const formValue = {
                    "Partner_Detail_ID": formData?.Partner_Detail_Id,
                    "Records_Filter": "FILTER"
                };
                dispatch(getPropertysAction(formValue))
            } else {
                const errors = {};
                dispatch({
                    type: PROPERTY_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PROPERTY_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

/*=============================================================
                  Address Delete
===============================================================*/


// Filter 
export const PropertyFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: PROPERTY_FILTER,
            fType: fType,
            value: value,
        });
    }
}

export const resetFilter = () => {
    return function (dispatch: any) {
        dispatch({
            type: PROPERTY_FILTER,
            fType: "search",
            value: "",
        });
    }
}
export const propertydata = (value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: PROPERTY_DATA,
            value: value,
        });
    }
}
export const getPropertyByIdAction = (formData: any) => async dispatch => {
    try {
        dispatch({
            type: SINGLE_PROPERTY_REQUEST
        });
        let { data, status } = await axios.post(
            `${config.url}/Function_Hall/Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SINGLE_PROPERTY_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SINGLE_PROPERTY_SUCCESS,
                    payload: data?.Function_Hall_Get_Response[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SINGLE_PROPERTY_FAIL,
            payload: err
        });
    }
};
export const singleImageUploadUpdate = (formValues: any, functionhall: any, modifiedBy: any, key: any, callBackfunction: any) => async dispatch => {
    axios.put(`${config.url}/Function_Hall_Document_Image/Multi_Function_Hal_Image_Update?filename=BTS&file_extension=jpg&filetype=Image&Modified_By=` + modifiedBy + `&Is_Default=0&Image_Sort=` + key + `&function_hall_id=` + functionhall,
        formValues, {
        headers: headers,
        transformRequest: (d) => d,
    })
        .then(response => {
            let data = response.data;
            if (data.File_Url) {
                callBackfunction(true);
            }
            else {
                callBackfunction(false);
            }
        }).catch(error => {
            callBackfunction(false);
        })
}

export const getFunctionhallImages = (functionhallid: any, callBackfunction: any) => async (dispatch: any) => {
    let payload = {
        "Function_Hall_Id": functionhallid,
        "Records_Filter": "FILTER"
    };
    axios.post(`${config.url}/Function_Hall_Document_Image/Function_Hall_Image_Get?PageNo=0&PageSize=1000&SortBy=CREATED_BY&SortOrder=ASC`, payload,
        {
            headers: headers
        }
    )
        .then(response => {
            let data = response.data;
            if (data.Is_Data_Exist === "0" && data.Response_Status == "Success") {
                callBackfunction([])
            }
            else if (data.Success_Response.Is_Data_Exist === "1" && data.Success_Response.Response_Success == "Success") {
                callBackfunction(data.Images[0].Function_Hall_Image);
            } else {

                callBackfunction([]);
            }
        })
}
export const imageDefaultUpdate = (functionhallid: any, modifiedBy: any, Function_Hall_Document_Id: any, callBackfunction: any) => async (dispatch: any) => {
    axios.put(`${config.url}/Function_Hall_Document_Image/Update`,
        {
            "Function_Hall_Document_Id": Function_Hall_Document_Id,
            "Function_Hall_ID": functionhallid,
            "Is_Default": 1,
            "Modified_by": modifiedBy
        }
        , {
            headers: headers
        })
        .then(response => {
            let data = response.data;
            if (data.Response_Status === "Success") {
                callBackfunction(true);
            }
            else {
                callBackfunction(false);
            }
        }).catch(error => {
            callBackfunction(false);
        })
}
export const imageDelete = (input: any, callBackfunction: any, key: any) => async (dispatch: any) => {
    let payload = {
        "Function_Hall_Document_ID": input
    };
    axios.post(`${config.userurl}/Function_Hall_Document/Delete`, payload, {
        headers: headers
    })
        .then(response => {
            let data = response.data;
            callBackfunction(input, data.Response_Status == "Success" ? true : false, key);
        })
}
export const dashboardpropertiesCount = (formData: any) => {
    return function (dispatch: any) {
        axios.post(`${config.url}/Partner_Dashboard/Partner_Total_Function_Halls_Count_Get`, formData,
            {
                headers: headers
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_PROPERTIRES,
                        data: data.Partner_Total_Function_Halls[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_PROPERTIRES,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_PROPERTIRES,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const dashboardActivebokingCount = (formData: any) => {
   return function (dispatch: any) {
        axios.post(`${config.url}/Partner_Dashboard/ESICB_Partner_Active_Bookings_Count_Get`, formData,
            {
                headers: headers
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_ACTIVE_BOOKINGS,
                        data: data.Partner_Active_Bookings[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_ACTIVE_BOOKINGS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_ACTIVE_BOOKINGS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const dashboardbookingCount = (formData: any) => {
    return function (dispatch: any) {
        axios.post(`${config.url}/Partner_Dashboard/ESICB_Partner_Total_Bookings_Count_Get`, formData,
            {
                headers: headers
            })
            .then(response => {
                let data = response.data;
               if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_BOOKINGS,
                        data: data.Partner_Total_Bookings[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_BOOKINGS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_BOOKINGS,
                    data: String(error),
                    success: false
                });
            })
    }
}