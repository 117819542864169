import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import { StyleSheet, Text, View, } from 'react-native';
import { TextInput, HelperText, Button, TouchableRipple, ProgressBar } from 'react-native-paper';
import { changePasswordAction } from '../../state/actions/profileActions';
import * as Yup from 'yup';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';

const ChangePassword = ({ props, className, profiletemp, callgetuser, loading, setLoading, setScreenType, ...rest }) => {
    const dispatch = useDispatch();
    const { navigation } = props;
    //toast for popup after successful API calls.
    const toast = useToast();
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const [errorMessage, setErrorMessage] = useState('');
    const [hidePass, setHidePass] = useState(true);
    const [changehidePass, setChangeHidePass] = useState(true);
    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const changePasswordResponse = (status, errorMessage) => {
        if (!status) {

            setErrorMessage(errorMessage);
            setSeconds(3)

        } else {
            

            setScreenType("profile");
        }


        setLoading(false);
    }
    const copyPasteCutChange = (e) => {
        e.preventDefault();
    };
    /*====================================
           Handler
   ====================================*/
    const values = {

        Current_Password: '',
        New_Password: '',
        Partner_Email_Id: logedInEmail,
        cpassword: ''

    }

    //Form Validation
    const Form_Validation = Yup.object().shape({
        Current_Password: Yup.string().required('Current Password is required.'),
        New_Password: Yup.string()
            .max(255)
            .matches(/[a-z]+/, "At least one lowercase letter is required.")
            .matches(/[A-Z]+/, "At least one uppercase letter is required.")
            .matches(/[^\da-zA-Z]+/, "At least one special character is required.")
            .matches(/.*\d+/, "At least one number is required.")
            .min(8, "At least minimum 8 characters are required.")
            .notOneOf([Yup.ref(' Current_Password'), null], "Don't use Old Password.")
            .required('New Password is required.'),

        cpassword: Yup.string()
            .max(255).oneOf([Yup.ref('New_Password'), null], 'Passwords did not match.')
            .required('Confirm Password is required.')
    })

    const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(false);
        setErrorMessage("");
        if (values) {
            let formData1 = JSON.parse(JSON.stringify(values));
            dispatch(changePasswordAction(formData1, changePasswordResponse, resetForm, toast,navigation));
            setLoading(true);
        }
    }

    return (
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
            {(props) => (
                <View style={{ margin: "5%" }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }} >
                        {/*   Change Password Field with validation */}
                        <Text style={styles.heading}>Change Password</Text>
                        <TouchableRipple
                            onPress={() => setScreenType("profile")}
                        >
                            <Text style={{ fontSize: 14, textDecorationLine: 'underline', color: "#27b6cc", marginTop: 5 }}>Back to Profile</Text>
                        </TouchableRipple>
                    </View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                        <View style={{ flexShrink: 1, width: 650 }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                    <TextInput
                                        style={{ backgroundColor: 'white' }}
                                        theme={{ colors: { primary: '#27B6CC' } }}
                                        error={Boolean(props.touched.Current_Password && props.errors.Current_Password)}
                                        label='Current Password'
                                        mode='outlined'
                                        name="Current_Password"
                                        value={props.values.Current_Password}
                                        onChangeText={props.handleChange('Current_Password')}
                                        onCut={copyPasteCutChange}
                                        onCopy={copyPasteCutChange}
                                        onPaste={copyPasteCutChange}
                                    />
                                    <HelperText type="error" visible={Boolean(props.touched.Current_Password && props.errors.Current_Password)}>
                                        <ErrorMessage name="Current_Password" />
                                    </HelperText>
                                </View>

                                {/*   New Password Field with validation */}
                                <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                                    <TextInput
                                        style={{ backgroundColor: 'white' }}
                                        theme={{ colors: { primary: '#27B6CC' } }}
                                        error={Boolean(props.touched.New_Password && props.errors.New_Password)}
                                        label='New Password'
                                        mode='outlined'
                                        name="New_Password"
                                        value={props.values.New_Password}
                                        onChangeText={props.handleChange('New_Password')}
                                        secureTextEntry={hidePass ? true : false}
                                        right={<TextInput.Icon name={hidePass ? 'eye-off' : 'eye'}
                                            size={20}
                                            onPress={() => setHidePass(!hidePass)}
                                        />}
                                        onCut={copyPasteCutChange}
                                        onCopy={copyPasteCutChange}
                                        onPaste={copyPasteCutChange}
                                    />
                                    <HelperText type="error" visible={Boolean(props.touched.New_Password && props.errors.New_Password)}>
                                        <ErrorMessage name="New_Password" />
                                    </HelperText>
                                </View>
                            </View>

                            {/*   Confirm Password Field with validation */}
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                    <TextInput
                                        style={{ backgroundColor: 'white' }}
                                        theme={{ colors: { primary: '#27B6CC' } }}
                                        error={Boolean(props.touched.cpassword && props.errors.cpassword)}
                                        label='Confirm Password'
                                        mode='outlined'
                                        name="cpassword"
                                        value={props.values.cpassword}
                                        onChangeText={props.handleChange('cpassword')}
                                        secureTextEntry={changehidePass ? true : false}
                                        right={<TextInput.Icon name={changehidePass ? 'eye-off' : 'eye'}
                                            size={20}
                                            onPress={() => setChangeHidePass(!changehidePass)}
                                        />}


                                        onCut={copyPasteCutChange}
                                        onCopy={copyPasteCutChange}
                                        onPaste={copyPasteCutChange}
                                    />
                                    <HelperText type="error" visible={Boolean(props.touched.cpassword && props.errors.cpassword)}>
                                        <ErrorMessage name="cpassword" />
                                    </HelperText>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', alignSelf: 'center' }} >
                        <Text style={{ color: "red", textAlign: 'center' }} > {seconds > 0 && <><Text  >{errorMessage}</Text><ProgressBar style={{
                            height: 5,
                            width: 300,
                        }} progress={.30 * seconds} color={"red"} /></>} </Text>
                    </View>
                    <View style={{ width: 200, marginLeft: "auto", marginRight: "auto" }}>
                        <Button style={{ width: "60%", marginTop: "3%", marginLeft: "20.5%", backgroundColor: "#27B6CC" }} mode="contained" onPress={props.handleSubmit}>
                            Confirm
                        </Button>
                    </View>
                </View>
            )}
        </Formik>
    );
}

const styles = StyleSheet.create({
    heading: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 5
    },
})

ChangePassword.propTypes = {
    className: PropTypes.string,
    profiletemp: PropTypes.object,
    callgetuser: PropTypes.func
};




export default ChangePassword;