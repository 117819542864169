import React, { Component, useState } from "react";
import GoogleMapReact from "google-map-react";
import api from "../../../../state/actions/api";
import { ActivityIndicator, Button, Image, SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { useSelector } from "react-redux";


const DraggableMap = (props) => {
  const { latitude = 12.956028, longitude = 77.694848, setZipcode = (value) => { }, closeGetZipcode = () => { }, showSelectedAddress = false, setAddressWithLanLog = (address, lan, lon, zip) => { } } = props;
  const [marker, setMarker] = useState();
  const loadMap = (map, maps) => {
    // const cityCircle = new google.maps.Circle({
    //   strokeColor: "#FF0000",
    //   strokeOpacity: 0.8,
    //   strokeWeight: 2,
    //   fillColor: "#FF0000",
    //   fillOpacity: 0.35,
    //   map,
    //   center: { lat: 40.756795, lng: -73.954298 },
    //   radius: 10000,
    //   draggable: true
    // });

    let marker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      draggable: true
    })
    setMarker(marker);
  };

  const locationSearch = React.useRef();
  const GOOGLE_MAP_API_KEY = "AIzaSyB8X9VRFX7CwQ08_QRGmpVeUMzlQK86Rcs";
  const [searchSelected, setSearchSelected] = useState(false);

  const esiColor = useSelector(state => state.theme);

  const [gioLocations, setGioLocations] = useState({ latitude, longitude });

  React.useEffect(() => {
    getLocation(gioLocations);
  }, [gioLocations]);

  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({});
  const [oldLatLon, setOldLanLon] = useState();

  const getLocation = (data) => {
    setLoading(true);
    if (JSON.stringify(oldLatLon) != JSON.stringify({ latitude: data.latitude, longitude: data.longitude })) {
      setOldLanLon({ latitude: data.latitude, longitude: data.longitude });
      setLocation({ latitude: data.latitude, longitude: data.longitude });
      api.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${data.latitude}&lon=${data.longitude}`).then(async (response) => {
        let locationInfo = response.data?.display_name || "";
        setLocation({ latitude: data.latitude, longitude: data.longitude, zipcode: response.data?.address?.postcode || false, locationInfo });
        // setZipcode(location.zipcode);
        setLoading(false);
      }).catch(async (reason) => {
        setLoading(false);
        getLocation(data);
      });
    } else {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (marker) {
      setSeconds(2);
    }
  }, [marker]);


  const [seconds, setSeconds] = React.useState(-1);

  const getMapPin = () => {
    let latChange = false;
    let lngChange = false;
    if (marker.getPosition().lat() != gioLocations.latitude) {
      latChange = true;
    }
    if (marker.getPosition().lng() != gioLocations.longitude) {
      lngChange = true;
    }
    if (latChange && lngChange) {
      setGioLocations({ latitude: marker.getPosition().lat(), longitude: marker.getPosition().lng() })
    } else if (latChange && !lngChange) {
      setGioLocations({ ...gioLocations, latitude: marker.getPosition().lat() })
    }
    else if (!latChange && lngChange) {
      setGioLocations({ ...gioLocations, longitude: marker.getPosition().lng() })
    }
  }
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) { setSeconds(seconds - 1) }
      else {
        getMapPin();
        try {
          if(searchSelected){
            locationSearch.current?.clear();
            locationSearch.current?.setAddressText("");
            setSearchSelected(false);
          }
          } catch (error) {
          }
        setSeconds(1);
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  const [pinCenter, setPinCenter] = useState([latitude, longitude]);

  const styles = StyleSheet.create({
    map: {
      flex: 1
    },
    markerFixed: {
      left: '50%',
      marginLeft: -24,
      marginTop: -48,
      position: 'absolute',
      top: '50%'
    },
    marker: {
      height: 48,
      width: 48
    },
    footer: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      bottom: 0,
      position: 'absolute',
      width: '100%'
    },
    region: {
      color: esiColor.Text,
      lineHeight: 20,
      marginTop: 5,
      textAlign: "center"
    },
    buttonLabel: {
      color: esiColor.itemButtenColor,
      marginHorizontal: 20,
      marginBottom: 2,
      fontWeight: "bold",
      textTransform: "uppercase"
    }
  
  })

  return (
    <View style={styles.map} >
      <SafeAreaView style={{
        zIndex: 9
      }}>
        <GooglePlacesAutocomplete
          ref={locationSearch}
          placeholder='Search'
          minLength={2}
          styles={{textInput: {
            backgroundColor: esiColor.BackgroundColor,
            borderColor: esiColor.SBorderColor,
            borderWidth:1,
            height: 44,
            borderRadius: 5,
            paddingVertical: 5,
            paddingHorizontal: 10,
            fontSize: 15,
            flex: 1,
            color: esiColor.Text
          }}}
          enterKeyHint={'search'}
          listViewDisplayed={false}
          fetchDetails={true}
          debounce={200}
          query={{
            key: GOOGLE_MAP_API_KEY,
            language: 'en'
          }}
          onPress={(data, details = null) => {
            if (details?.geometry?.location?.lat) {
              marker.setPosition({ lat: details?.geometry?.location?.lat, lng: details?.geometry?.location?.lng });
              setPinCenter([details?.geometry?.location?.lat, details?.geometry?.location?.lng]);
            }
            setSearchSelected(true);
          }}

          requestUrl={{
            useOnPlatform: 'web', // or "all"
            url:
              'https://cors-anywhere.meepaisa.com/https://maps.googleapis.com/maps/api',
          }}


          // renderDescription={(row) => row.description}
          keepResultsAfterBlur={true}
          onFail={(error) => console.error(error)}
        />
      </SafeAreaView>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB8X9VRFX7CwQ08_QRGmpVeUMzlQK86Rcs" }}
        defaultCenter={{ lat: latitude, lng: longitude }}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
        center={pinCenter}
      />
      <SafeAreaView >
        {loading ? <ActivityIndicator /> :
          <View>
            {(!(location?.zipcode) || showSelectedAddress) &&
              <Text style={styles.region}>{location?.zipcode ? location.locationInfo : "Please choice different location."}</Text>}
            <View style={{ flexDirection: "row", justifyContent: "space-around", marginBottom: 10 }} >

              {/* <TouchableOpacity style={{ backgroundColor: "#27b6cc", borderRadius: 8, maxHeight: 40 }} onPress={() => {
                closeGetZipcode();
              }} >
                <Text style={styles.buttonLabel}>Back</Text>
              </TouchableOpacity> */}
              <TouchableOpacity disabled={location?.zipcode ? false : true} style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 8, maxHeight: 40 }} onPress={() => {
                setZipcode(location.zipcode);
                setAddressWithLanLog(location.locationInfo, location.latitude, location.longitude, location.zipcode);
              }} >
                <Text style={styles.buttonLabel}>{showSelectedAddress ? "Okay" : "get locations"}</Text>
              </TouchableOpacity>
            </View>
          </View>
        }
      </SafeAreaView>
    </View>
  );

}

export default DraggableMap