import React, { useState } from 'react';
import { Card, Button, TextInput, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Header from '../auth/components/header';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import EsiDatePicker from '../../components/custom/date/date';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAllPartnerProductssearch } from '../../state/actions/partnerproductsAction';
import { addshopperownPhysicalPartnerAction } from '../../state/actions/shopersAction';
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }

];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddownShopperProducts = (props: any) => {
    const { navigation } = props;
    const esiColor = useSelector(state => state.theme);
    const EmailID = useSelector(state => state.auth?.user);
    const dispatch = useDispatch();
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const [statusDropDown, setStatusDropDown] = useState("");
    const partnerproductssearch = useSelector(state => state.productpartners.partnerproductssearch.partners);
    const [showProductMasterDropDown, setShowProductDropDown] = React.useState('');
    const productid = props.route?.params?.shoppersproducts;
    const partnerproductid = props.route?.params?.partnerproductid;
    //screen dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        dispatch(getAllPartnerProductssearch("1"))
    }, []);
    let productopt = []
    if (partnerproductssearch) {
        if (partnerproductssearch[0]) {
            for (let i = 0; i < partnerproductssearch?.length; i++) {
                productopt.push(
                    {
                        value: partnerproductssearch[i]?.Product_Id,
                        label: partnerproductssearch[i]?.Product_Name,
                    })
            }
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        checkbox: {
            flexDirection: "row",
            justifyContent: 'flex-start'
        },
        label: {
            marginBottom: 30,
        },
        editor: {
            backgroundColor: "black",
            borderColor: "black",
            borderWidth: 1,
        },
        rich: {
            minHeight: 130,
            // flex: 1,
        },
        richBar: {
            height: 50,
            backgroundColor: "#F5FCFF",
        },
        text: {
            fontWeight: "bold",
            fontSize: 18,
        },
        tib: {
            textAlign: "center",
            color: "#515156",
        },
        a: {
            fontWeight: "bold",
            color: "purple",
        },
        div: {
            fontFamily: "monospace",
        },
        p: {
            fontSize: 30,
        }, container: {
            paddingTop: 15
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{
            flexShrink: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            } >
                <Formik initialValues={{
                    "Partner_Product_Id": partnerproductid,
                    "Created_By": EmailID?.Email_Id,
                    "Discount_End_Date": "",
                    "Discount_In_Percentage": "",
                    "Discount_Start_Date": "",
                    "Max_Quantity": "",
                    "Min_Quantity": "",
                    "Partner_Details_Id": EmailID?.Partner_Details_Id,
                    "Product_Id": productid,
                    "Status": "",
                }}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        Min_Quantity: Yup.number().required(' Min Quantity is required.'),
                        Max_Quantity: Yup.number().positive().moreThan(Yup.ref('Min_Quantity'), 'Require Min Quantity greater than to Max Quantity.').required(' Max Quantity is required.'),
                        Status: Yup.string().required('Status is required.'),
                        Discount_In_Percentage: Yup.number().required(' Discount In Percentage is required.'),
                        // Discount_Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Discount Start Date greater than or equal to today.").required("Discount Start Date is required."),
                        // Discount_End_Date: Yup.date().when("Discount_Start_Date", (Discount_Start_Date, schema) => Discount_Start_Date && schema.min(Discount_Start_Date, "Discount End Date can't be before Discount Start Date.")).required("Discount End Date is required."),
                    })}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        setErrors(errors);
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            formValues.Created_By = EmailID?.Email_Id
                            formValues.Partner_Details_Id = EmailID?.Partner_Details_Id
                            dispatch(addshopperownPhysicalPartnerAction(formValues, props.navigation, toast, partnerproductid));
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        resetForm
                    }) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('ShoppersownGet', { shoppersproductparams: productid, partnerproductid: partnerproductid })} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                        Add Own Shopper products
                                    </Text>
                                    <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Min_Quantity && errors.Min_Quantity)}
                                                label="Min Quantity"
                                                mode='outlined'
                                                value={values.Min_Quantity}
                                                onChangeText={handleChange("Min_Quantity")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Min_Quantity && errors.Min_Quantity)}>
                                                {touched.Min_Quantity && errors.Min_Quantity}
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Max_Quantity && errors.Max_Quantity)}
                                                label="Max Quantity"
                                                mode='outlined'
                                                value={values.Max_Quantity}
                                                onChangeText={handleChange("Max_Quantity")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Max_Quantity && errors.Max_Quantity)}>
                                                {touched.Max_Quantity && errors.Max_Quantity}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, paddingTop: 10, marginRight: '0.5%', flexShrink: 1 }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Discount_In_Percentage && errors.Discount_In_Percentage)}
                                                label="Discount In Percentage"
                                                mode='outlined'
                                                value={values.Discount_In_Percentage}
                                                onChangeText={handleChange("Discount_In_Percentage")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Discount_In_Percentage && errors.Discount_In_Percentage)}>
                                                {touched.Discount_In_Percentage && errors.Discount_In_Percentage}
                                            </HelperText>
                                        </View>
                                        {/* <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <EsiDatePicker
                                                    error={Boolean(touched.Discount_Start_Date && errors.Discount_Start_Date)}
                                                    style={{ width: "100%", backgroundColor: 'white',height:50 }}
                                                    name={'Discount_Start_Date'}
                                                    label={'Discount Start Date'}
                                                    value={values.Discount_Start_Date}
                                                    valueUpdate={item => {
                                                        setFieldValue('Discount_Start_Date', item)
                                                    }}
                                                />

                                                <HelperText type="error" visible={Boolean(touched.Discount_Start_Date && errors.Discount_Start_Date)}>
                                                    {touched.Discount_Start_Date && errors.Discount_Start_Date}
                                                </HelperText>
                                            </View> */}
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        {/* <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <EsiDatePicker
                                                    error={Boolean(touched.Discount_End_Date && errors.Discount_End_Date)}
                                                    style={{ width: "100%", backgroundColor: 'white' }}
                                                    name={'Discount_End_Date'}
                                                    label={'Discount End Date'}
                                                    value={values.Discount_End_Date}
                                                    valueUpdate={item => {
                                                        setFieldValue('Discount_End_Date', item)
                                                    }}
                                                />

                                                <HelperText type="error" visible={Boolean(touched.Discount_End_Date && errors.Discount_End_Date)}>
                                                    {touched.Discount_End_Date && errors.Discount_End_Date}
                                                </HelperText>
                                            </View> */}
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                {/* Status input field with validation*/}
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Status && errors.Status) && { color: '#b00020' }]}>
                                                    Status
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Status && errors.Status) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={StatusOption}
                                                    placeholder={!statusDropDown ? '-- Please Select --' : ''}
                                                    labelField="label"
                                                    valueField="value"
                                                    maxHeight={StatusOption?.length > 1 ? 250 : 200}
                                                    search={StatusOption?.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Status'}
                                                    dropdownPosition="auto"
                                                    value={values.Status}
                                                    name="Status"
                                                    onFocus={() => setStatusDropDown(true)}
                                                    onBlur={() => setStatusDropDown(false)}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Status', item.value)
                                                        setStatusDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Status && errors.Status)}>
                                                    {touched.Status && errors.Status}
                                                </HelperText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ alignItems: "center" }}>
                                        <Button textColor={esiColor.itemButtenColor} style={{ width: '10%', backgroundColor: esiColor.globalButtonColor }}
                                            // mode="outlined"
                                            onPress={handleSubmit}
                                        >
                                            Save  
                                        </Button>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface>
    );
};

export default AddownShopperProducts;