import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Card, DefaultTheme, HelperText, Surface, Text, TextInput } from 'react-native-paper';
import { Dimensions, RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import { getAllsizes, getInventoryByIdAction, updateInventoryAction } from '../../../state/actions/farmer/farmerinventoryAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import Header from '../header';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import EsiDatePicker from '../../../components/custom/date/date';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const Status = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const EditFarmerIneventory = (props: any) => {
    const esiColor = useSelector(state => state.theme);
    const ID = props.route?.params?.id;
    const PartnerID = props.route?.params?.partnerid;
    const EmailID = useSelector(state => state.auth?.user);
    const { navigation } = props;
    const dispatch = useDispatch();
    const toast = useToast();
    const [size, setSize] = useState([]);
    const callBackSizeData = (data: any) => {
        setSize(data)
    }
    let sizedata = []
    if (size) {
        if (size[0]) {
            for (
                let i = 0;
                i < size?.length;
                i++) {
                sizedata.push({
                    label: size[i].Size,
                    value: size[i].Product_Size_Id
                })
            }
        }
    }
    //data reload
    const [data, setData] = useState([]);
    const callBackData = (data: any) => {
        setData(data)
    }
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });

    useEffect(() => {
        if (data[0]?.Product_Master_ID) {
            let formData = {
                Product_Master_Id: data[0]?.Product_Master_ID,
                Partner_Details_Id: PartnerID
            };
            dispatch(getAllsizes(formData, callBackSizeData));
        }
    }, [data]);


    useEffect(() => {
        let formData = {
            Inventory_Id: ID,
            Records_Filter: "FILTER"
        }
        dispatch(getInventoryByIdAction(formData, callBackData))
    }, []);

    //set seconds
    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);

    React.useEffect(() => {
        if (!(permissions.Inventory_Management === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);

    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

    const [errorMessage, setErrorMessage] = useState({ Product_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
    const isSubmited = useSelector(state => state.inventory.inventoryUpdate.isLoading);
    const loginEmail = useSelector(state => state.auth.user.Email_Id);

    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    // Alert Popup
    const [showstatusDropDown, setstatusShowDropDown] = useState(false);

    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        container: {
            backgroundColor: esiColor.BackgroundColor,
            paddingTop: 15
        },
        cardCircle: {
            width: (window.width >= 500) ? "75%" : "97%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "2%",
            marginBottom: "2%",
            borderRadius: 6,
            borderWidth: 0.01,
            borderColor: '#D6D6D7'
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },
        files: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        phonetextInput: {
            flex: 1
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1 }} >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }>
                {data?.length > 0 && seconds <= 0 &&
                    <Formik
                        initialValues={{
                            Availability_Status: data[0]?.Availability_Status,
                            Available_Date: data[0]?.Available_Date,
                            Availability_Stock: data[0]?.Availability_Stock,
                            Modified_By: loginEmail,
                            Total_Stock: data[0]?.Total_Stock,
                            Product_Master_Id: data[0]?.Product_Master_ID,
                            Product_Size: data[0]?.Size,
                            Inventory_Version: data[0]?.Inventory_Version,
                            Partner_Details_Id: data[0]?.Partner_Details_Id,
                            Partner_Product_Id: data[0]?.Partner_Product_Id,
                            Product_Id: data[0]?.Product_Id,
                            Inventory_Id: data[0]?.Inventory_Id
                        }}
                        validationSchema={Yup.object().shape({
                            Availability_Status: Yup.string().required('Availability Status is required.'),
                            Available_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Required Available Date should be greater than or equal to today").required('Available Date is required.'),
                            Product_Master_Id: Yup.string().required('Product Master Name is required.'),
                            Product_Size: Yup.string().required('Product Size is required.'),
                            Availability_Stock: Yup.number().min(1, "Availability Stock greater than or equal zero required.").required('Availability Stock is required.'),
                            Inventory_Version: Yup.string().required('Inventory Version is required.'),
                            // Total_Stock: Yup.number().min(1,"Total Stock greater than or equal zero required.").required('Total Stock is required.'),
                            Total_Stock: Yup.number().when("Availability_Stock", (Availability_Stock, schema) => Availability_Stock && schema.min(Availability_Stock, "Require Total Stock greater than or equal to Availability Stock.")).required("Total Stock is required."),
                            //  Total_Stock: Yup.number().positive().moreThan(Yup.ref('Availability_Stock'), 'Require Total Stock greater than or equal to Availability Stock.').required(' Total Stock is required.'),
                            Product_Id: Yup.string().notOneOf([errorMessage.Product_Id.key, null], errorMessage.Product_Id.message).required('Product ID is required.'),
                            Partner_Product_Id: Yup.string().required('Partner Product ID is required.'),
                        })}
                        enableReinitialize={true}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            let formData = JSON.parse(JSON.stringify(values));
                            dispatch(updateInventoryAction(formData, EmailID, navigation, setErrorMessage, setErrors, toast))
                        }
                        }
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            resetForm
                        }) => (
                            <View style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                flexWrap: "wrap"
                            }}>
                                <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                    <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                                        onPress={() => {
                                            resetForm();
                                            // setReload(true);
                                            navigation.navigate('FarmerInventoryDetails')
                                        }}>
                                        Go Back
                                    </Button>
                                </View>
                                <Card style={styles.cardCircle}>
                                    <Card.Content>
                                        <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                            Edit Inventory
                                        </Text>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-evenly",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexShrink: 1, width: 750, paddingTop: 15 }}>
                                                <View ><Text style={{ fontSize: 20, fontWeight: "bold", textAlign: 'center' }}>{"Product Name:"}</Text><Text style={{ fontSize: 20, fontWeight: "bold", textAlign: 'center', color: '#27B6CC' }}>{data[0]?.Product_Name}</Text></View>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Product_Size && errors.Product_Size) && { color: '#b00020' }]}>
                                                        Product Size
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Product_Size && errors.Product_Size) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={sizedata}
                                                        labelField="label"
                                                        valueField="value"
                                                        maxHeight={sizedata.length > 1 ? 250 : 200}
                                                        search={sizedata.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Product Size'}
                                                        value={values.Product_Size}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Product_Size', item.value)
                                                            setstatusShowDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Product_Size && errors.Product_Size)}>
                                                        {touched.Product_Size && errors.Product_Size}
                                                    </HelperText>
                                                </View>
                                                <TextInput
                                                    theme={customTheme}
                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Inventory_Version && errors.Inventory_Version)}
                                                    onBlur={handleBlur}
                                                    onChangeText={handleChange('Inventory_Version')}
                                                    label="Inventory Version"
                                                    name="Inventory_Version"
                                                    value={values.Inventory_Version}
                                                    mode="outlined"
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Inventory_Version && errors.Inventory_Version)}>
                                                    {touched.Inventory_Version && errors.Inventory_Version}
                                                </HelperText>
                                                <View style={{ flexGrow: 1, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                    <EsiDatePicker
                                                        theme={customTheme}
                                                        label={'Available Date'}
                                                        style={{ width: "100%", backgroundColor: 'white', height: 50 }}
                                                        value={values.Available_Date}
                                                        valueUpdate={date => {
                                                            setFieldValue('Available_Date', date)
                                                        }} />
                                                    <HelperText type="error" visible={Boolean(touched.Available_Date && errors.Available_Date)}>
                                                        {touched.Available_Date && errors.Available_Date}
                                                    </HelperText>
                                                </View>

                                                <TextInput
                                                     theme={customTheme}
                                                     style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                     outlineColor={esiColor.TextOuterLine}
                                                     underlineColor={esiColor.TextUnderline}
                                                     activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Availability_Stock && errors.Availability_Stock)}
                                                    onBlur={handleBlur}
                                                    onChangeText={handleChange('Availability_Stock')}
                                                    label="Availability Stock"
                                                    name="Availability_Stock"
                                                    value={`${values.Availability_Stock}`}
                                                    mode="outlined"
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Availability_Stock && errors.Availability_Stock)}>
                                                    {touched.Availability_Stock && errors.Availability_Stock}
                                                </HelperText>
                                                <TextInput
                                                     theme={customTheme}
                                                     style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                     outlineColor={esiColor.TextOuterLine}
                                                     underlineColor={esiColor.TextUnderline}
                                                     activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Availability_Stock && errors.Total_Stock)}
                                                    onBlur={handleBlur}
                                                    onChangeText={handleChange('Total_Stock')}
                                                    label="Total Stock"
                                                    name="Total_Stock"
                                                    value={`${values.Total_Stock}`}
                                                    mode="outlined"
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Total_Stock && errors.Total_Stock)}>
                                                    {touched.Total_Stock && errors.Total_Stock}
                                                </HelperText>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Availability_Status && errors.Availability_Status) && { color: '#b00020' }]}>
                                                        Availability Status
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Availability_Status && errors.Availability_Status) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={Status}
                                                        labelField="label"
                                                        valueField="value"
                                                        placeholder={!showstatusDropDown ? 'Select item' : '...'}
                                                        maxHeight={Status.length > 1 ? 250 : 200}
                                                        search={Status.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Availability Status'}
                                                        value={values.Availability_Status}
                                                        onFocus={() => setstatusShowDropDown(true)}
                                                        onBlur={() => setstatusShowDropDown(false)}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Availability_Status', item.value)
                                                            setstatusShowDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Availability_Status && errors.Availability_Status)}>
                                                        {touched.Availability_Status && errors.Availability_Status}
                                                    </HelperText>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                            <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                                Save
                                            </Button>
                                        </View>
                                    </Card.Content>
                                </Card>
                            </View>
                        )}
                    </Formik>
                }
            </ScrollView>
        </Surface>
    );
};

export default EditFarmerIneventory;