import React, { useState, useEffect } from "react";
import { getAllProductMasterAction, productmasterFilter, resetFilter } from "../../../state/actions/productmastersAction";
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Card, DataTable, Button, Searchbar, Surface, Dialog, Portal, IconButton, DefaultTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../state/validations/is-empty'
import 'react-native-gesture-handler'
import RenderHtml from 'react-native-render-html';
import Header from "../../auth/components/header";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from 'react-native-vector-icons/FontAwesome';

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Product_Master_Id',
    label: 'Product Master ID'
  },
  {
    value: 'Product_Name',
    label: 'Product Master Name'
  },
  {
    value: 'Brand_Name',
    label: 'Brand Name'
  },
  {
    value: 'Category_Name',
    label: 'Category Name'
  },
  {
    value: 'Sub_Category_Name',
    label: 'Sub Category Name'
  },

];

const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const ProductMastersGet = ({ navigation }) => {

  const permissions = useSelector(state => state.auth.permissions);
  const [products, setProducts] = useState([]);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [page, setPage] = React.useState(0);
  const Allproductmaster = useSelector(state => state?.productmasters?.productmasters?.all);
  let productmaster = Allproductmaster?.results;
  const [productmasterMenu, setProductmasterMenu] = React.useState('All')
  const [productmasterSearch, setProductmasterSearch] = React.useState('');
  const [productmasters, setData] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [webpage, setwebPage] = React.useState(1);
  const esiColor = useSelector(state => state.theme);

  const hideDialog = () => setVisible(false);
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > Allproductmaster?.Pagination?.TotalCount ? Allproductmaster?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setwebPage(1)
    setPagination({ PageNo: Number(1), PageSize: e, SortBy: "PRODUCT_MASTER_ID", SortOrder: "DESC" })
  }
  const DynamicPageIncrease = (e) => {
    setwebPage(e < Allproductmaster?.Pagination?.TotalPages ? e + Number(1) : Allproductmaster?.Pagination?.TotalPages)
    setPagination({ PageNo: e == Allproductmaster?.Pagination?.TotalPages ? Allproductmaster?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PRODUCT_MASTER_ID", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "PRODUCT_MASTER_ID", SortOrder: "DESC" });
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PRODUCT_MASTER_ID", SortOrder: "DESC" });
  }
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PRODUCT_MASTER_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      brandsCall();
    }
  }, [pagination])
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const brandsCall = () => {
    setIsLoading(true);
    let formData = {
      "search": '',
      "Brand_Id": "",
      "Category_Id": "",
      "Is_Product_Active": "",
      "Min_Mrp": "",
      "Partner_Details_Id": "",
      "Product_Master_Id": "",
      "Product_Name": "",
      "Records_Filter": "ALL",
      "Sku": "",
      "Status": "",
      "Upc_Code": "",
      "Warranty_Period": ""
    };
    dispatch(getAllProductMasterAction(formData, pagination))
  };
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "PRODUCT_MASTER_ID", SortOrder: "DESC" }
    let formData = {
      "search": '',
      "Brand_Id": "",
      "Category_Id": "",
      "Is_Product_Active": "",
      "Min_Mrp": "",
      "Partner_Details_Id": "",
      "Product_Master_Id": "",
      "Product_Name": "",
      "Records_Filter": "ALL",
      "Sku": "",
      "Status": "",
      "Upc_Code": "",
      "Warranty_Period": ""
    };
    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getAllProductMasterAction(formData, pagecall))
  };
  const [master, setMaster] = React.useState();
  const dispatch = useDispatch()
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (isEmpty(productmaster)) {
      let formData = {
        "search": '',
        "Brand_Id": "",
        "Category_Id": "",
        "Is_Product_Active": "",
        "Min_Mrp": "",
        "Partner_Details_Id": "",
        "Product_Master_Id": "",
        "Product_Name": "",
        "Records_Filter": "ALL",
        "Sku": "",
        "Status": "",
        "Upc_Code": "",
        "Warranty_Period": ""
      };
      setMaster(productmaster)
      dispatch(getAllProductMasterAction(formData, pagination))
    }
  }, [productmaster]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const handleClicksize = async (productMaster) => {
    navigation.navigate('ProductSizes', { ID: productMaster })
  };
  const handleClickcolor = async (productmaster) => {
    navigation.navigate('ProductColors', { ID: productmaster })
  };
  const handleClickadd = async (productmaster) => {
    navigation.navigate('AddProductMaster', { master: productmaster })
  };
  const [oldSearch, setOldSearch] = React.useState(0);
  const checkproductmasterForTable = () => {
    if (productmasterSearch) {
      try {
        if (productmasterMenu === "All") {
          return productmaster?.filter(x => {
            let colummns = ["Product_Master_Id", "Sku", "Product_Name", "Status", "Category", "Sub_Category", "Brand"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
              const subString = typeof x[colummns[i]] === "string" ? productmasterSearch.toLowerCase() : productmasterSearch;
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(productmasterSearch.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString == productmasterSearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return productmaster?.filter(x => {
            let mainString = typeof x[productmasterMenu] == "string" ? x[productmasterMenu].toLowerCase() : x[productmasterMenu];
            let subString = typeof x[productmasterMenu] == "string" ? productmasterSearch.toLowerCase() : productmasterSearch;
            if (typeof x[productmasterMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString == productmasterSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (page > 0) {
          if (oldSearch != productmasterSearch.length) {
            setOldSearch(productmasterSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch != productmasterSearch.length) {
          setOldSearch(productmasterSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return productmaster
    }
  }

  useEffect(() => {
    if (!isEmpty(productmaster)) {
      setProducts(productmaster);
    } else {
      setProducts([]);
    }
  }, [productmaster]);

  // Sorting 
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('PRODUCT_MASTER_ID');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleChange = itemValue => {
    setProductmasterMenu(itemValue)
  };
  const handleSearch = value => {
    setProductmasterSearch(value)
    const formData = {
      "search": value,
      "Brand_Id": "",
      "Category_Id": "",
      "Is_Product_Active": "",
      "Min_Mrp": "",
      "Partner_Details_Id": "",
      "Product_Master_Id": "",
      "Product_Name": "",
      "Records_Filter": "ALL",
      "Sku": "",
      "Status": "",
      "Upc_Code": "",
      "Warranty_Period": ""
    };
    dispatch(getAllProductMasterAction(formData, pagination))
  };
  const data =
    checkproductmasterForTable()
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const styles = StyleSheet.create({
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 160
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
      width: 130
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'left',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 240,
      backgroundColor: esiColor.BackgroundColor

    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
    },
    rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
    },
    rowsPerPagePickers: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      borderColor: 'gray',
      borderWidth: 0.5,
      paddingHorizontal: 8,
    },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      // backgroundColor: esiColor.SIconColor
    },
    paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      // backgroundColor: esiColor.SIconColor
    },
    pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
    },
  });


  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 600 ? '60%' : '95%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
            <IconButton
              icon="close"
              color={esiColor.GBFColor}
              size={20}
              onPress={() => setVisible(!visible)}
            />
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>Product Master View</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 10 }}>
              <Dialog.Content style={{ marginLeft: -33 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}>
                    <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Master ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{productmasters?.Product_Master_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Master Name</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Product_Name} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>SKU</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Sku} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Category ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Category} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Sub Category ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Sub_Category} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Brand ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Brand} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Type</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Product_Type} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>UPC Code</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Upc_Code} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Sales Start Date</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Sales_Start_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Height</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Height} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Weight</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Weight} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Width</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Width} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Length</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Length} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Manufacturer Cost</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Manufacturer_Cost} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Max MRP</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Max_Mrp} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>MRP</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Mrp} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Min MRP</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Min_Mrp} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Pattern Name</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Pattern_Name} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Manufacturer</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Manufacturer} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Manufacturer Contact</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productmasters?.Manufacturer_Contact} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Short Description</Text></View><View style={{ flex: 2 }}><RenderHtml
                    source={{ html: `<span style="color: ${esiColor.Text}">${productmasters?.Short_Description}</span>` }}
                  /></View>
                </View>

                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Long Description</Text></View><View style={{ flex: 2, marginTop: -15 }}><RenderHtml
                    source={{ html: `<span style="color: ${esiColor.Text}">${productmasters?.Long_Description}</span>` }}
                  /></View>
                </View>

                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Specifications</Text></View><View style={{ flex: 2, marginTop: -15 }}><RenderHtml
                    source={{ html: `<span style="color: ${esiColor.Text}">${productmasters?.Specifications}</span>` }}
                  /></View>
                </View>

                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Features</Text></View><View style={{ flex: 2, marginTop: -15 }}><RenderHtml
                    source={{ html: `<span style="color: ${esiColor.Text}">${productmasters?.Features}</span>` }}
                  /></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View><View style={{ flex: 2 }}><Text style={{ color: productmasters?.Status === "Active" ? esiColor.GBFColor : "red" }}>{productmasters?.Status} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View>
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
          navigation.navigate('ProductMainCenter')
          handleSearch("")
          dispatch(resetFilter())
        }
        }>
          Go Back
        </Button>
      </View>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Product Masters</Text>
        </View>
        {(permissions.Product_Master_Product_Color_Product_Size === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={(e) => { handleClickadd(); }}>
              Add Product Master
            </Button>
          </View>}



        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[styles.dropdown]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={filterOptions}
                containerStyle={styles.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={productmasterMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={productmasterMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Product Master ID" value="Product_Master_Id" />
                <Picker.Item label="Product Master Name" value="Product_Name" />
                <Picker.Item label="Brand Name" value="Brand_Name" />
                <Picker.Item label="Category Name" value="Category_Name" />
                <Picker.Item label="Sub Category Name" value="Sub_Category_Name" />
              </Picker>
            </View>}
          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />
          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              variant="outlined"
              onChangeText={(value) => handleSearch(value)}
              value={productmasterSearch}
            />
          </View>

        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={styles.mainbox}>
            <Card >
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={styles.databeHeader}>
                  <DataTable.Title active={orderBy === 'Product_Master_Id'} sortDirection={orderBy === 'Product_Master_Id' ? order : 'ascending'} onPress={createSortHandler('Product_Master_Id')} style={styles.title}><Text style={styles.titletext}>Product Master ID</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Product_Name'} sortDirection={orderBy === 'Product_Name' ? order : 'ascending'} onPress={createSortHandler('Product_Name')} style={styles.title}><Text style={styles.titletext}>Product Master Name</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Category_Name'} sortDirection={orderBy === 'Category_Name' ? order : 'ascending'} onPress={createSortHandler('Category_Name')} style={styles.title}><Text style={styles.titletext}>Category Name</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Sub_Category_Name'} sortDirection={orderBy === 'Sub_Category_Name' ? order : 'ascending'} onPress={createSortHandler('Sub_Category_Name')} style={styles.title}><Text style={styles.titletext}>Sub Category Name</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Brand_Name'} sortDirection={orderBy === 'Name' ? order : 'ascending'} onPress={createSortHandler('Name')} style={styles.title}><Text style={styles.titletext}>Brand Name</Text></DataTable.Title>
                  <DataTable.Title style={styles.title}><Text style={styles.titletext}>Product size</Text></DataTable.Title>
                  <DataTable.Title style={styles.title}><Text style={styles.titletext}>Product color</Text></DataTable.Title>
                  <DataTable.Title style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {data?.map((productMaster, i) => (
                  <DataTable.Row style={styles.databeBox} key={i}>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productMaster.Product_Master_Id}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productMaster.Product_Name}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productMaster.Category_Name}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productMaster.Sub_Category_Name}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productMaster.Brand_Name}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Button icon="animation-outline" textColor={esiColor.SIconColor} onPress={(e) => { handleClicksize(productMaster.Product_Master_Id); }}></Button></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}> <Button icon="brush" textColor={esiColor.SIconColor} onPress={(e) => { handleClickcolor(productMaster.Product_Master_Id); }}></Button></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>
                      {(permissions.Product_Master_Product_Color_Product_Size === "write") && (<IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={() => navigation.navigate('EditProductMaster', { ID: productMaster.Product_Master_Id, CID: productMaster.Category_Id })} />
                      )}
                      <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(productMaster); setVisible(!visible) }} /></DataTable.Cell>
                  </DataTable.Row>
                ))
                }
                {isEmpty(data) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                {Platform.OS !== "web" ?

                  <DataTable.Pagination
                    page={page}
                    numberOfPages={Math.ceil(Allproductmaster?.Pagination?.TotalCount / numberOfItemsPerPage)}
                    onPageChange={page => DynamicPage(to < Allproductmaster?.Pagination?.TotalCount ? page : 0)}
                    label={Allproductmaster?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Allproductmaster?.Pagination?.TotalCount}` : '0-0'}
                    showFastPaginationControls={(Number(page) + Number(1)) > Allproductmaster?.Pagination?.TotalPages ? false : true}
                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    onItemsPerPageChange={onItemsPerPageChange}
                    selectPageDropdownLabel={'Rows per page'}
                  />
                  :
                  <View style={styles.rowsPerPageContainer}>
                    <Text style={{color: esiColor.Text}}>rows per page: </Text>
                    <Picker
                      style={styles.rowsPerPagePicker}
                      selectedValue={numberOfItemsPerPage}
                      onValueChange={(e) => onItemsPerPageChange(e)}
                    >
                      <Picker.Item label="5" value={5} />
                      <Picker.Item label="10" value={10} />
                      <Picker.Item label="20" value={20} />
                    </Picker>
                    <View style={styles.paginationContainer}>
                      <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={styles.paginationButton}>
                        <Icon name="angle-left" size={20} color={esiColor.SIconColor}/>
                      </TouchableOpacity>
                      <Text style={styles.pageInfoText}>
                        {Allproductmaster?.Pagination?.PageNo + ' of ' + Allproductmaster?.Pagination?.TotalPages}
                      </Text>
                      <TouchableOpacity onPress={e => DynamicPageIncrease(to < Allproductmaster?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={styles.paginationButton}>
                        <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                    </View>
                  </View>}
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface >
  );
}

export default ProductMastersGet;