import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createSettingsAction, getAllSettingFieldnames, getAllSettingsAction } from '../../state/actions/settingsAction';
import { Card, TextInput, Text, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView } from 'react-native';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import Header from '../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';

//StatusOption used to get the data from dropdown
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const AddSetting = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    //toast for popup after successful API calls.
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const [errorMessage, setErrorMessage] = useState({ Setting_Field_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Settings === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);

    //isSubmitted contains the data from Create API using state call
    const isSubmited = useSelector(state => state.setting.settingCreate.isLoading);
    //settings and settingsFieldname contains the data from API's using state call
    const settingsFieldname = useSelector(state => state.setting.settingFieldname.fieldname);
    useEffect(() => {
        let formData = {
            Records_Filter: "FILTER",
            Partner_Details_Id: EmailID?.Partner_Details_Id
        };
        dispatch(getAllSettingsAction(formData));
        dispatch(getAllSettingFieldnames());
    }, []);
    const [statusDropDown, setStatusDropDown] = useState("");
    const [DropDown, setNameDropDown] = useState("");
    //fieldnamelist used to store the label,value in an array from API data  
    let fieldnamelist = []
    if (settingsFieldname) {
        if (settingsFieldname[0]) {
            for (let i = 0; i < settingsFieldname.length; i++) {
                fieldnamelist.push({
                    label: settingsFieldname[i].Default_Value,
                    value: settingsFieldname[i].Default_Value
                })
            }
        }
    }
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //form reset
    const [reload, setReload] = React.useState(false);

    React.useEffect(() => {
        if (reload)
            setReload(false);
    }, [reload])

    /*====================================
            Handler
    ====================================*/

    const values = {
        Field_Name: '',
        Field_Value: '',
        Purpose: '',
        Status: '',
        Partner_Details_Id: EmailID?.Partner_Details_Id,
        Created_By: EmailID?.Email_Id,
    }

    //Form validation
    const Form_Validation = Yup.object().shape({
        Field_Name: Yup.string().notOneOf([errorMessage.Setting_Field_Name.key, null], errorMessage.Setting_Field_Name.message).required('Field Name is required.'),
        Field_Value: Yup.string().required('Field Value is required.'),
        Purpose: Yup.string().required('Purpose is required.').max(45, 'Please provide below 45 characters.'),
        Status: Yup.string().required('Status is required.')
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);

        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(createSettingsAction(formValues, navigation, toast, setErrorMessage));
        }
    }

    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <ScrollView>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        props.resetForm();
                                        setReload(true);
                                        navigation.navigate('SettingsGet')
                                    }}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "65%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2.1%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: '#D6D6D7'
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Add Settings
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                            {/* Field Name input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Field_Name && props.errors.Field_Name) && { color: '#b00020' }]}>
                                                Field Name
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Field_Name && props.errors.Field_Name) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={fieldnamelist}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={fieldnamelist.length > 1 ? 250 : 200}
                                                search={fieldnamelist.length > 1 ? true : false}
                                                searchPlaceholder={'Select  Field Name'}
                                                dropdownPosition="auto"
                                                value={props.values.Field_Name}
                                                name="Field_Name"
                                                onFocus={() => setNameDropDown(true)}
                                                onBlur={() => setNameDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Field_Name', item.value)
                                                    setNameDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Field_Name && props.errors.Field_Name)}>
                                                <ErrorMessage name="Field_Name" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Field Value input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Field Value'
                                                mode='outlined'
                                                name="Field_Value"
                                                placeholder="Value for Field Name"
                                                value={props.values.Field_Value}
                                                onChangeText={props.handleChange('Field_Value')}
                                                error={Boolean(props.touched.Field_Value && props.errors.Field_Value)}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Field_Value && props.errors.Field_Value)}>
                                                <ErrorMessage name="Field_Value" />
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Purpose input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Purpose'
                                                mode='outlined'
                                                name="Purpose"
                                                value={props.values.Purpose}
                                                onChangeText={props.handleChange('Purpose')}
                                                error={Boolean(props.touched.Purpose && props.errors.Purpose)}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Purpose && props.errors.Purpose)}>
                                                <ErrorMessage name="Purpose" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 21 }}>
                                            {/* Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                                                Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ? true : false}
                                                searchPlaceholder={'Select Status'}
                                                dropdownPosition="auto"
                                                value={props.values.Status}
                                                name="Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                                                <ErrorMessage name="Status" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ marginLeft: "1%" }}>
                                        <Text style={{ color: "red" }}>{errorMessage.Setting_Field_Name.message}</Text>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

const styles = StyleSheet.create({
    label: {
        marginBottom: 30,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        left: 22,
        top: 10,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});

export default AddSetting;