import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import isEmpty from "../../../state/validations/is-empty";
import { changePartnerData } from "../../../state/actions/shoppers/filterServiceActions";
import { getAllfilterpartnerAction } from "../../../state/actions/shoppers/shopperpartnerActions";
import EsiCheckboxTree from "../../../components/custom/checkbox-tree";


export default function SortByPartners(props) {
    // const partnerId = props?.route?.params?.id;
    const partnerId = props.state?.routes[0]?.params?.id;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const callback = (data: any) => {
        let physicalPart = [];
        if (data) {
            data.map((item: any, index: Number) => {
                if (item.Type === "Physical") {
                    physicalPart.push({
                        label: item.Name,
                        value: item.Partner_Details_Id,
                        checked: item.Partner_Details_Id === partnerId ? "checked" : "unchecked",
                        key: "2-" + String(index),
                    });
                }
            })
        }

        dispatch(changePartnerData(
            [
                {
                    label: "Physical",
                    value: "Physical",
                    checked: "unchecked",
                    key: '2',
                    children: physicalPart.slice(0, 10)
                }
            ]
        ));
    };
    let sortByOptionsInit = useSelector((state) => state.filters.partners);

    useEffect(() => {
        if (isEmpty(sortByOptionsInit)) {
            dispatch(getAllfilterpartnerAction(callback));
        }
    }, [sortByOptionsInit]);
    const { navigation } = props;


    const checkChildChange = (data: any, status: string) => {
        navigation.navigate('Partnersb2b')
        let key = data.key;
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        response.filter((item: any) => { return item.key === key })[0].children.map((item: any) => {
            if (status === 'unchecked' || status === 'indeterminate') {
                item.checked = "checked";
            } else {
                item.checked = "unchecked";
            }
        })

        response.filter((item: any) => { return !(item.key === key) }).map((children => {
            children.children.map((item: any) => {
                item.checked = "unchecked";
            })
        }))

        dispatch(changePartnerData(response));
    }

    const changeChecked = (data: any, status: string) => {
        navigation.navigate('Partnersb2b')
        let key = data.key;
        let keys = key.split('-');
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        if (keys.length === 1) {
            response.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
        } else if (keys.length === 2) {
            response.filter((item: any) => { return item.key === keys[0] })[0].children.filter((item: any) => {
                return item.key === key
            })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
            response.filter((item: any) => { return !(item.key === keys[0]) }).map(children => {
                children.children.map(item => {
                    item.checked = 'unchecked';
                })
            })
        }
        dispatch(changePartnerData(response));
    }

    const updateOptions = ((data: any) => {
        navigation.navigate('Partnersb2b')
    })

    return (
        <View style={{
            flex: 1,
            backgroundColor: esiColor.BackgroundColor
        }}>
            <View style={{ marginLeft: 10, flexDirection: 'row' }}>
                <View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <Text style={{ fontSize: 14, color: esiColor.itemColor, marginTop: 5 }}>Partners</Text>
                </View>
                <TouchableOpacity style={{ position: 'absolute', right: 10, marginTop: 5 }} onPress={() => { navigation.navigate('Partnersb2b') }} >
                    <Text style={{ textDecorationLine: "underline", color: esiColor.itemColor }}  >See All</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

