import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, Dimensions, ScrollView, Platform, RefreshControl } from 'react-native';
import { TextInput, Card, Button, HelperText, TouchableRipple, ActivityIndicator, Surface, RadioButton } from 'react-native-paper';
import PhoneInput from 'react-native-phone-number-input';
import Header from '../auth/components/header';
import Headers from '../Services/header/header'
import ImageUpload from '../../components/custom/image-upload/upload';
import { getPartnerProfileAction, UpadateEmployeeAction } from '../../state/actions/partnerprofileActions';
import ChangePassword from './ChangePassword';
import { uploadProfileAction } from '../../state/actions/profileActions';
import { CountryCode as mobilecode } from '../../components/custom/countrycode';
import EsiDatePicker from '../../components/custom/date/date';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { imageORfileupload } from '../../state/actions/commonImageOrFileUpload';
import { uriToBlob } from '../../components/custom/uriBlob';
import { getLogedInUser } from '../../state/actions/authActions';
import FarmerHeader from '../../screens/Farmer-Module/header'
const IdProofType = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Aadhar',
        label: 'Aadhar'
    },
    {
        value: 'PAN',
        label: 'PAN'
    },
    {
        value: 'Driving Licence',
        label: 'Driving Licence'
    }
];
const gender = [
    {
        value: 'Male',
        label: 'Male'
    },
    {
        value: 'Female',
        label: 'Female'
    },

];
const UserType = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'PARTNER_ADMIN',
        label: 'ADMIN'
    },
    {
        value: 'PARTNER_EMPLOYEE',
        label: 'EMPLOYEE'
    }
];
export default function EmployeProfile(props) {
    //navigation is used for navigation between screens
    const { navigation, profiletemp, callgetuser, ...rest } = props
    const dispatch = useDispatch();

    //toast for popup after successful API calls.
    const toast = useToast();
    const [loading, setLoading] = React.useState(true);
    const EmailID = useSelector(state => state.auth?.user);
    //isSubmitted contains the data from Update API using state call
    const isSubmited = useSelector(state => state.partner.physicalpartnerUpdate.isLoading);

    const userPic = useSelector(state => state.auth?.user?.Profile_Pic_Path);
    //partners contains the data from  GET API using state call
    const partners = useSelector(state => state.partner.partners.all);
    const isLoading = useSelector(state => state.partner.partners.isLoading);
    const ID = props.route?.params?.ID
    //Mobile
    const phoneInput = useRef(null);
    //mobile number country code
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const RetrieveUserValue = async () => {
        try {
            return await AsyncStorage.getItem('user')
        }
        catch (e) {
        }
    }

    const MINUTE_MS = 10000;
    useEffect(() => {
        setProfile_Pic({
            preview:EmailID?.Profile_Pic_Path, raw: null
        });
      const interval = setInterval(() => {
        dispatch(getLogedInUser(EmailID?.Email_Id, false, () => { }, EmailID?.Partner_Session_Id, () => { },EmailID))
        }, MINUTE_MS);
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.

    }, [EmailID])
    const [email, setEmail] = React.useState('');
    const [dob, setDob] = React.useState('');
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [fullname, setFullName] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [idprooftype, setIdprooftype] = React.useState('');
    const [idproofnumber, setIdproofnumber] = React.useState('');
    const [genders, setGenders] = React.useState('');
    const [usertype, setUsertype] = React.useState('');
    const [data, setData] = useState([]);
    const callBackData = (data: any) => {
        setData(data)
    }
    const [seconds, setSeconds] = React.useState(-1);
    useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const [screenType, setScreenType] = React.useState("profile");
    const [profilepic, setProfile_Pic] = useState({ preview: null, raw: null });
    const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, global: { key: '', message: '' } });
    const [profilepicurl, setProfile_Picurl] = useState(userPic);
    const [showDropDown, setShowDropDown] = useState(false);
    const [uploadPic, setUploadPic] = useState(false);
    //dispatch is used to perform the API action
    React.useEffect(() => {
        setSeconds(3);
        if (partners != 0) {
            setProfile_Pic({
                preview: partners?.Profile_Pic_Path,
                raw: partners?.Profile_Pic_Path
            })
            setProfile_Picurl(partners?.Profile_Pic_Path)
            setEmail(partners?.Email_Id)
            setDob(partners?.DOB)
            setFirstname(partners?.First_Name)
            setLastname(partners?.Last_Name)
            setFullName(partners?.Full_Name)
            setMobile(partners?.Mobile_Number)
            setIdprooftype(partners?.ID_Proof_Type)
            setIdproofnumber(partners?.ID_Proof_Number)
            setGenders(partners?.Gender)
            setUsertype(partners?.User_Type)
        }
        else{
       if(EmailID?.Email_Id)
        dispatch(getPartnerProfileAction(EmailID.Email_Id,EmailID?.Partner_Session_Id,setData))
    }
    }, [partners,EmailID.Email_Id]);


    // const profilelBackUploadImage = (data: any) => {
    //     if (data) {
    //         setProfile_Picurl(data.File_URL);

    //     }
    // };
    const profilelBackUploadImage = (data: any) => {
        if (data) {
            setProfile_Picurl(data.File_URL);
        }
        setUploadPic(false);
      };
    
    
    const profilePichandleImage = async (result: any) => {
        let call = result.uri
        if (result) {
          let exe = "img";
          let uriParts = result.uri.split('.');
          if (Platform.OS == "web") {
            let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
            let filformate = fileExtension[0].split(':')
            exe = filformate[1].split('/')[1]
          } else {
            exe = uriParts[uriParts.length - 1]
          }
          let fileData = {
            File_name: "Profile",
            Functionality: "Profiles",
            ext: exe,
            File_Type: "Images",
          };
          const img = await profilefetchImageFromUri(call);
          setProfile_Pic({
            preview: result.uri,
            raw: img
          });
          dispatch(
            imageORfileupload(img, fileData, profilelBackUploadImage)
          );
        }
      };
    const profilefetchImageFromUri = async (uri) => {
        const blob = await uriToBlob(uri);
        return blob;
    };



    const handleRemoveProfileImage = e => {
        setProfile_Picurl('');
        setProfile_Pic({
            preview: null,
            raw: null
        });
    };

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );

    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        dispatch(getPartnerProfileAction(EmailID.Email_Id, EmailID?.Partner_Session_Id, setData))
        wait(2000).then(() => setRefreshing(false));
    }, []);
    const pages = props.route?.params?.page;
    /*====================================
            Handler
    ====================================*/
    return (
        <Surface style={{
            flex: 1
        }}>
            {/* <Header navigation={navigation} /> */}
            {pages === 'Services' ? <Headers navigation={navigation} /> :pages === 'farmer'? <FarmerHeader navigation={navigation} />:<Header navigation={navigation} />}
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                {isLoading ? < ActivityIndicator color='#27b6cc' style={{ marginTop: 20 }} /> :
                    <Formik
                        initialValues={{
                            DOB: partners?.DOB ? partners?.DOB : "",
                            First_Name: partners?.First_Name ? partners?.First_Name : "",
                            Full_Name: partners?.Full_Name ? partners?.Full_Name : "",
                            Gender: partners?.Gender ? partners?.Gender : "",
                            Id_Proof_Number: partners?.ID_Proof_Number ? partners?.ID_Proof_Number : "",
                            Id_Proof_Type: partners?.ID_Proof_Type ? partners?.ID_Proof_Type : "",
                            Last_Name: partners?.Last_Name ? partners?.Last_Name : "",
                            Partner_User_Details_Id: partners?.Partner_User_Details_Id ? partners?.Partner_User_Details_Id : "",
                            Profile_Pic_Path: profilepicurl,
                            Partner_Details_Id: partners?.Partner_Details_Id ? partners?.Partner_Details_Id : "",
                            Partner_Employee_Email_Id: partners?.Email_Id ? partners?.Email_Id : "",
                            Mobile_Number: partners?.Mobile_Number ? partners?.Mobile_Number : "",
                            Is_Active: partners?.Is_Active == "1" ? true : false,
                            User_Type: partners?.User_Type ? partners?.User_Type : "",
                            Partner_Employee_Id: partners?.Partner_Employee_Id ? partners?.Partner_Employee_Id : "",
                        }}
                        validationSchema={Yup.object().shape({
                            // Partner_Employee_Email_Id: Yup.string().email("Please provide a valid Email ID. ").notOneOf([errorMessage.Email_Id.key, null], errorMessage.Email_Id.message).required('Email ID is required.'),
                            User_Type: Yup.string().required('User Type is required.'),
                            Mobile_Number: Yup.string().min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number.").required("Mobile Number is required."),
                            DOB: Yup.date()
                                .max(new Date(Date.now() - 5600), "Date Of Birth should be less than today.")
                                .test('not-today', "Date Of Birth should not be today's date.", function (value) {
                                    // 'this' refers to the Yup validation context
                                    const today = new Date();
                                    today.setHours(0, 0, 0, 0); // Set time to midnight to compare dates only
                                    return !value || !value.setHours(0, 0, 0, 0) || value.getTime() !== today.getTime();
                                })
                                .required('Date Of Birth is required.'),
                            //  Yup.date().max(new Date(Date.now() - 5600), "Date Of Birth should be less than to today.").required('Date Of Birth is required.'),
                            Id_Proof_Number: Yup.string().when('Id_Proof_Type', (Id_Proof_Type, schema) => {
                                if (Id_Proof_Type === "Aadhar") {
                                    return schema.min(12, "Aadhaar Card Number is not valid.").max(12, "Aadhaar Card Number is not valid.").matches(/^[0-9]*$/, "Aadhaar Card Number is not valid.").required("Aadhaar Card Number is required.");
                                } else if (Id_Proof_Type === "PAN") {
                                    return schema.matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "PAN Card Number is not valid.").required("PAN Card Number is required.");
                                } else if (Id_Proof_Type === "Driving Licence") {
                                    return schema.matches(/^([a-zA-Z]){2}([0-9]){14}$/, "Driving License Number is not valid.").required("Driving License Number is required.");
                                }
                            }),
                            First_Name: Yup.string().required('First Name is required.'),
                            Full_Name: Yup.string().min(3, "expecting 3 char").required('Full Name is required.'),
                            Gender: Yup.string().required('Gender is required.'),
                            // Last_Name: Yup.string().required('Last Name is required.'),
                        })}
                        enableReinitialize={true}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            let submitTest = true;
                            setSubmitting(false);
                            if (values) {
                                let formValues = JSON.parse(JSON.stringify(values));
                                if (values.Is_Active) {
                                    formValues.Is_Active = 1;
                                } else {
                                    formValues.Is_Active = 0;
                                }
                                if (Platform.OS === 'web') {
                                    // Retrieve the current data from local storage
                                    const currentData = localStorage.getItem('user');

                                    if (currentData) {
                                        // Parse the current data (assuming it's stored as JSON)
                                        const parsedData = JSON.parse(currentData);

                                        // Update the specific value you want to change
                                        parsedData.Profile_Pic_Path = profilepicurl; // Replace "someValue" and "newValue" with your specific field and value

                                        // Store the updated data back in local storage
                                        localStorage.setItem('user', JSON.stringify(parsedData));
                                    }
                                } else {
                                    AsyncStorage.getItem('user', (error, currentData) => {
                                        if (!error) {
                                            if (currentData) {
                                                // Parse the current data (assuming it's stored as JSON)
                                                const parsedData = JSON.parse(currentData);

                                                // Update the specific value you want to change
                                                parsedData.Profile_Pic_Path = profilepicurl; // Replace "someValue" and "newValue" with your specific field and value

                                                // Store the updated data back in local storage
                                                AsyncStorage.setItem('user', JSON.stringify(parsedData));
                                            }
                                        } else {
                                            // Handle the error, if any
                                        }
                                    });
                                }

                                dispatch(UpadateEmployeeAction(formValues, props.navigation, setErrorMessage, setErrors, setData, toast, ID, EmailID?.Partner_Session_Id));
                            }
                        }
                        }
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            resetForm
                        }) => (
                            <View style={{
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap'
                            }}>
                                <View style={{ width: dimensions <= 500 ? '98%' : '75%', marginLeft: 'auto', marginRight: 'auto', marginTop: '2%', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    {ID === '1' ? <View>
                                        <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                            resetForm(); setSeconds(3); navigation.navigate('Dashboard');
                                        }}>
                                            Go Back
                                        </Button>
                                    </View> : ID === '2' ? <View>
                                        <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                            resetForm(); setSeconds(3); navigation.navigate("Properties", { screen: 'PropertiesDashboard' });
                                        }}>
                                            Go Back
                                        </Button>
                                    </View> : pages === 'Services' ? <View>
                                        <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                             setSeconds(3); navigation.navigate("Services", { screen: 'ServicesDashboard' });

                                        }}>
                                            Go Back
                                        </Button>
                                    </View> : pages === 'farmer' ? <View>
                                        <Button textColorolor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                            resetForm(); setSeconds(3); navigation.navigate("Farmer", { screen: 'Farmer_Dashboard' });

                                        }}>
                                            Go Back
                                        </Button>
                                    </View>:<View>
                                        <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                            resetForm(); setSeconds(3); navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } });

                                        }}>
                                            Go Back
                                        </Button>
                                    </View>}
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        {partners?.Partner_Name}{" "}Employee
                                    </Text>
                                </View>
                                {screenType === "profile" && <Card style={[styles.cardCircle, { width: dimensions >= 700 ? "75%" : "95%" }]}>

                                    <Card.Content>
                                        <View>
                                            <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 10 }} >
                                                <Text style={styles.heading}>Profile Picture</Text>
                                                <TouchableRipple
                                                    onPress={() => setScreenType("password")}
                                                >
                                                    <Text style={{ fontSize: 14, textDecorationLine: 'underline', color: "#27b6cc", marginTop: 3 }}>Change Password</Text>
                                                </TouchableRipple>
                                            </View>
                                            {/* TextInput for Logo Image Field */}
                                            <View style={{ marginLeft: "auto", marginRight: "auto", width: 350, paddingBottom: 20 }} >
                                                <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} imagestyle={styles.images} handleRemoveImage={handleRemoveProfileImage} image={profilepic} uploadPic={profilePichandleImage} />
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>

                                            <View style={{ flexShrink: 1, width: 450, paddingTop: 15 }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(touched.Partner_Employee_Email_Id && errors.Partner_Employee_Email_Id)}
                                                    onBlur={handleBlur}
                                                    // onChangeText={handleChange('Partner_Employee_Email_Id')}
                                                    label="Email ID"
                                                    name="Partner_Employee_Email_Id"
                                                    value={email}
                                                    mode="outlined"
                                                    onChangeText={item => {
                                                        setFieldValue('Partner_Employee_Email_Id', item)
                                                        setEmail(item)
                                                    }}
                                                    disabled
                                                />
                                            </View>
                                            <View style={{ flexShrink: 1, width: 450, paddingTop: 10 }}>
                                                <EsiDatePicker
                                                    error={Boolean(touched.DOB && errors.DOB)}
                                                    style={{ width: "100%", margin: "auto" }}
                                                    name={'DOB'}
                                                    label='DOB'
                                                    value={dob}
                                                    valueUpdate={item => {
                                                        setFieldValue('DOB', item)
                                                        setDob(item)
                                                    }}
                                                     />
                                                <HelperText type="error" visible={Boolean(touched.DOB && errors.DOB)}>
                                                    {touched.DOB && errors.DOB}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexShrink: 1, width: 450, paddingTop: 15 }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(touched.First_Name && errors.First_Name)}
                                                    onBlur={handleBlur}
                                                    // onChangeText={handleChange('First_Name')}
                                                    onChangeText={item => {
                                                        setFieldValue('First_Name', item)
                                                        setFirstname(item)
                                                    }}
                                                    label="First Name"
                                                    name="First_Name"
                                                    value={firstname}
                                                    mode="outlined"
                                                />
                                                <HelperText type="error" visible={Boolean(touched.First_Name && errors.First_Name)}>
                                                    {touched.First_Name && errors.First_Name}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexShrink: 1, width: 450, paddingTop: 15 }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(touched.Last_Name && errors.Last_Name)}
                                                    onBlur={handleBlur}
                                                    // onChangeText={handleChange('Last_Name')}
                                                    onChangeText={item => {
                                                        setFieldValue('Last_Name', item)
                                                        setLastname(item)
                                                    }}
                                                    label="Last Name"
                                                    name="Last_Name"
                                                    value={lastname}
                                                    mode="outlined"
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Last_Name && errors.Last_Name)}>
                                                    {touched.Last_Name && errors.Last_Name}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexShrink: 1, width: 450, paddingTop: 15 }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(touched.Full_Name && errors.Full_Name)}
                                                    onBlur={handleBlur}
                                                    // onChangeText={handleChange('Full_Name')}
                                                    onChangeText={item => {
                                                        setFieldValue('Full_Name', item)
                                                        setFullName(item)
                                                    }}
                                                    label="Full Name"
                                                    name="Full_Name"
                                                    value={fullname}
                                                    mode="outlined"
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Full_Name && errors.Full_Name)}>
                                                    {touched.Full_Name && errors.Full_Name}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexShrink: 1, width: 450 }}>
                                                <TextInput style={{ width: "100%", margin: "auto", backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    label="Mobile Number"
                                                    mode='outlined'
                                                    error={Boolean(touched.Mobile_Number && errors.Mobile_Number)}
                                                    // onChangeText={handleChange('Mobile_Number')}
                                                    onChangeText={item => {
                                                        setFieldValue('Mobile_Number', item)
                                                        setMobile(item)
                                                    }}
                                                    left={<TextInput.Affix text="+91" />}
                                                    value={mobile}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Mobile_Number && errors.Mobile_Number)}>
                                                    <ErrorMessage name="Mobile_Number" />
                                                </HelperText>
                                            </View>
                                            <View style={{ flexShrink: 1, width: 450, paddingTop: 10 }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Id_Proof_Type && errors.Id_Proof_Type) && { color: '#b00020' }]}>
                                                        ID Proof Type
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Id_Proof_Type && errors.Id_Proof_Type) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={IdProofType}
                                                        labelField="label"
                                                        valueField="value"
                                                        placeholder={!showDropDown ? 'Select item' : '...'}
                                                        maxHeight={IdProofType.length > 1 ? 250 : 200}
                                                        search={IdProofType.length > 1 ? true : false}
                                                        searchPlaceholder={'Select  ID Proof Type'}
                                                        value={idprooftype}
                                                        onFocus={() => setShowDropDown(true)}
                                                        onBlur={() => setShowDropDown(false)}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Id_Proof_Type', item.value)
                                                            setShowDropDown(false);
                                                            setIdprooftype(item.value)
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Id_Proof_Type && errors.Id_Proof_Type)}>
                                                        {touched.Id_Proof_Type && errors.Id_Proof_Type}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ flexShrink: 1, width: 450, paddingTop: 20 }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(touched.Id_Proof_Number && errors.Id_Proof_Number)}
                                                    onBlur={handleBlur}
                                                    // onChangeText={handleChange('Id_Proof_Number')}
                                                    onChangeText={item => {
                                                        setFieldValue('Id_Proof_Number', item)
                                                        setIdproofnumber(item)
                                                    }}
                                                    label="Id Proof Number"
                                                    name="Id_Proof_Number"
                                                    value={idproofnumber}
                                                    mode="outlined"
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Id_Proof_Number && errors.Id_Proof_Number)}>
                                                    {touched.Id_Proof_Number && errors.Id_Proof_Number}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexShrink: 1, width: 450, paddingTop: 10 }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Gender && errors.Gender) && { color: '#b00020' }]}>
                                                        Gender
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Gender && errors.Gender) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={gender}
                                                        labelField="label"
                                                        valueField="value"
                                                        placeholder={!showDropDown ? 'Select item' : '...'}
                                                        maxHeight={gender.length > 1 ? 250 : 200}
                                                        search={gender.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Gender'}
                                                        value={genders}
                                                        onFocus={() => setShowDropDown(true)}
                                                        onBlur={() => setShowDropDown(false)}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Gender', item.value)
                                                            setGenders(item.value)
                                                            setShowDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Gender && errors.Gender)}>
                                                        {touched.Gender && errors.Gender}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ flexShrink: 1, width: 450, paddingTop: 10 }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.User_Type && errors.User_Type) && { color: '#b00020' }]}>
                                                        User Type
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.User_Type && errors.User_Type) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={UserType}
                                                        labelField="label"
                                                        valueField="value"
                                                        placeholder={!showDropDown ? 'Select item' : '...'}
                                                        maxHeight={UserType.length > 1 ? 250 : 200}
                                                        search={UserType.length > 1 ? true : false}
                                                        searchPlaceholder={'Select  User Type'}
                                                        value={usertype}
                                                        onFocus={() => setShowDropDown(true)}
                                                        onBlur={() => setShowDropDown(false)}
                                                        onUpdateValue={item => {
                                                            setFieldValue('User_Type', item.value)
                                                            setUsertype(item.value)
                                                            setShowDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.User_Type && errors.User_Type)}>
                                                        {touched.User_Type && errors.User_Type}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ width: "80%", margin: "auto" }}>
                                                <View style={{
                                                    flexDirection: "row",
                                                }}>
                                                    <RadioButton
                                                        color="#27B6CC"
                                                        name="Is_Active"
                                                        status={values.Is_Active ? 'checked' : 'unchecked'}
                                                        onPress={() => setFieldValue('Is_Active', !values.Is_Active)}
                                                    />
                                                    <Text style={{ marginTop: 10, color: Boolean(touched.Is_Active && errors.Is_Active) ? "#b00020" : "#000000" }}>Is Active</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </Card.Content>
                                    <View style={{ marginHorizontal: 30 }}>
                                        <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                                        <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                                            Save
                                        </Button>
                                    </View>
                                </Card>}
                                <View>
                                    {screenType === "password" &&
                                        <Card style={{
                                            width: dimensions <= 500 ? "100%" : "100%", marginLeft: "auto", marginRight: "auto",
                                            marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7'
                                        }}>
                                            <ChangePassword navigation={navigation}
                                                props={props}
                                                profiletemp={profiletemp}
                                                callgetuser={callgetuser}
                                                loading={loading}
                                                setLoading={setLoading}
                                                setScreenType={setScreenType}

                                            />
                                        </Card>
                                    }
                                </View>
                            </View>
                        )}
                    </Formik>
                }
            </ScrollView>
        </Surface >
    );
};
const styles = StyleSheet.create({
    errorText: {
        fontSize: 15,
        color: 'red',
    },

    heading: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 3
    },

    profile: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginLeft: "2%",
        marginTop: "1%"
    },
    images: {
        width: '100%',
        height: 100,
        borderRadius: 5,
        marginBottom: 20
    },
    container: {
        paddingTop: 16
    },
    cardCircle: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2%",
        marginBottom: "2%",
        borderRadius: 6,
        borderWidth: 0.01,
        borderColor: '#D6D6D7'
    },
    itemContainer: {
        justifyContent: 'flex-end',
        flex: 1
    },

    checkbox: {
        alignSelf: "center",
    },
    label: {
        marginBottom: 30,
    },
    phonetextInput: {
        flex: 1
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});