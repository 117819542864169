import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Card, HelperText, Text, TextInput, Surface, RadioButton, DefaultTheme } from 'react-native-paper';
import { Dimensions, StyleSheet, ScrollView, View, RefreshControl } from 'react-native';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAllAddressforBiddingAction, getBiddingProductIdAction, getGenericProductsAction, updateproductavailablility } from '../../../state/actions/biddingProductsAction';
import Header from '../../auth/components/header';
import EsiDatePicker from '../../../components/custom/date/date';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import isEmpty from '../../../state/validations/is-empty';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const Status_Types = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In Active'
  }
];




const EditPartnerBiddingProducts = (props: any) => {

  const ID = props.route?.params?.id || "";
  const page = props.route?.params?.page;
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);
  const dispatch = useDispatch();

  const toast = useToast();
  const isSubmited = useSelector(state => state.biddingproducts.biddingUpdate.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "Partner_Address_Id", SortOrder: "DESC" });
  const [productpagination, setProductPagination] = React.useState({ PageNo: 1, PageSize: 20, SortBy: "Bidding_Product_Id", SortOrder: "DESC" });

  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  React.useEffect(() => {
    return () => {
    };
  }, []);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);

  React.useEffect(() => {
    if (!(permissions.Employees === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
    setSeconds(2);
  }, [permissions, ID]);

  const [data, setData] = useState([]);
  const callBackData = (data: any) => {
    setData(data)
  }
  const [addressdata, setAddresData] = useState([]);
  const [product, setProductData] = useState([]);
  const [statusshowDropDown, setStatusShowDropDown] = useState(false);
  const [addressshowDropDown, setAddressShowDropDown] = useState(false);
  const [productsshowDropDown, setProductsShowDropDown] = useState(false);
  const genericpagination = {}

  const callBackAddressData = (data: any) => {
    setAddresData(data)
  }
  const callBackproductData = (data: any) => {
    setProductData(data)
  }
  let Productslist = []
  if (product) {
    if (product[0]) {
      for (let i = 0; i < product.length; i++) {
        Productslist.push({
          label: product[i].Bidding_Products_Name,
          value: product[i].Bidding_Product_Id
        })
      }
    }
  }
  let addressdatalist = []
  if (addressdata) {
    if (addressdata[0]) {
      for (let i = 0; i < addressdata.length; i++) {
        addressdatalist.push({
          label: addressdata[i].Location.substring(0, 100),
          value: addressdata[i].Partner_Address_Id
        })
      }
    }
  }
  React.useEffect(() => {
    if (data[0]?.Product_Name) {
      let formData = {
        "search": data[0]?.Product_Name,
        "Bidding_Products_Name": '',
        "Bidding_Product_Id": ''
      }
      dispatch(getGenericProductsAction(formData, callBackproductData, productpagination))

    }

  }, [permissions, data]);
  React.useEffect(() => {

    let addressForm = {
      "search_by_filter": "",
      "search": "",
      "Partner_Address_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    }
    dispatch(getAllAddressforBiddingAction(addressForm, callBackAddressData, pagination))
  }, [permissions]);
  const updateStates = (data) => {
    if (data) {
      let formData = {
        "search": data,
        "Bidding_Products_Name": '',
        "Bidding_Product_Id": ''
      }
      dispatch(getGenericProductsAction(formData, callBackproductData, genericpagination))
    }

  };

  React.useEffect(() => {
    if (seconds <= 0 && (!isEmpty(ID))) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Partner_Bidding_Available_Products_Id": ID,
        "Product_Id": "",
        "Partner_Detail_Id": "",
        "Partner_Address_Id": "",
        "Records_Filter": "FILTER",
        "Is_Product_Available": "",
        "Available_Stock": ""
      }
      dispatch(getBiddingProductIdAction(formData, callBackData, pagination))
    }
  }, [seconds, ID]);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [showDropDown, setShowDropDown] = useState(false);
  //mobile number country code
  const [reload, setReload] = React.useState(false);
  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 16
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },

    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    phonetextInput: {
      flex: 1
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        {data[0] &&

          <Formik
            enableReinitialize={true}
            initialValues={{
              "Available_Stock": data[0].Available_Stock,
              "Is_Product_Available": data[0].Is_Product_Available,
              "Modified_By": EmailID?.Email_Id,
              "Partner_Address_Id": data[0].Partner_Address_Id,
              "Partner_Bidding_Available_Products_Id": data[0].Partner_Bidding_Available_Products_Id,
              "Partner_Detail_Id": data[0].Partner_Detail_Id,
              "Product_Id": data[0].Product_Id,
              "Status": data[0].Status,
              "ESI_Commission":data[0].ESI_Commission+""
            }}
            validationSchema={Yup.object().shape({
              Status: Yup.string().required('Status is required.'),
              Product_Id: Yup.string().required('Product Name is required.'),
              Partner_Address_Id: Yup.string().required('Partner Address is required.'),
              Available_Stock: Yup.number().moreThan(0, 'Available Stock greater than to 0.').required('Available Stock is required.'),

            })}

            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              if (values) {
                let formData = JSON.parse(JSON.stringify(values));
                if (values.Is_Product_Available) {
                  formData.Is_Product_Available = 1;
                } else {
                  formData.Is_Product_Available = 0;
                }
                dispatch(updateproductavailablility(formData, props.navigation, toast));
              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"

              }}>
                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm(),
                        setReload(true);
                      setData([]),
                        setSeconds(2)
                      navigation.navigate('PartnerBiddingProducts')
                    }}
                  >
                    Go Back
                  </Button>
                </View>

                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Edit Bidding Product
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Product_Id && errors.Product_Id) && { color: '#b00020' }]}>
                            Product Name
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Product_Id && errors.Product_Id) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={Productslist}
                            labelField="label"
                            valueField="value"
                            maxHeight={Productslist.length > 1 ? 250 : 200}
                            search={Productslist.length >= 0 ? true : false}
                            searchPlaceholder={'Select Product Name'}
                            value={values.Product_Id}
                            searchQuery={item => { updateStates(item) }}
                            onFocus={() => setProductsShowDropDown(true)}
                            onBlur={() => setProductsShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('Product_Id', item.value)
                              setProductsShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Product_Id && errors.Product_Id)}>
                            {touched.Product_Id && errors.Product_Id}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Partner_Address_Id && errors.Partner_Address_Id) && { color: '#b00020' }]}>
                            Partner Address
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Partner_Address_Id && errors.Partner_Address_Id) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={addressdatalist}
                            labelField="label"
                            valueField="value"
                            maxHeight={addressdatalist.length > 1 ? 250 : 200}
                            search={addressdatalist.length > 1 ? true : false}
                            searchPlaceholder={'Select Partner Address'}
                            value={values.Partner_Address_Id}
                            onFocus={() => setAddressShowDropDown(true)}
                            onBlur={() => setAddressShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('Partner_Address_Id', item.value)
                              setAddressShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Partner_Address_Id && errors.Partner_Address_Id)}>
                            {touched.Partner_Address_Id && errors.Partner_Address_Id}
                          </HelperText>
                        </View>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Available_Stock && errors.Available_Stock)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Available_Stock')}
                          label="Available Stock"
                          name="Available_Stock"
                          value={values.Available_Stock}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Available_Stock && errors.Available_Stock)}>
                          {touched.Available_Stock && errors.Available_Stock}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Status && errors.Status) && { color: '#b00020' }]}>
                            Status
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Status && errors.Status) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={Status_Types}
                            labelField="label"
                            valueField="value"
                            maxHeight={Status_Types.length > 1 ? 250 : 200}
                            search={Status_Types.length > 1 ? true : false}
                            searchPlaceholder={'Select Status'}
                            value={values.Status}
                            onFocus={() => setStatusShowDropDown(true)}
                            onBlur={() => setStatusShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('Status', item.value)
                              setStatusShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Status && errors.Status)}>
                            {touched.Status && errors.Status}
                          </HelperText>
                        </View>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                          // theme={customTheme}
                          style={{
                            marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                            borderColor: esiColor.TextOuterLine, borderWidth: 1
                          }}
                          label="ESI Commission"
                          name="ESI_Commission"
                          disabled
                          value={data[0].ESI_Commission +""!= ''+"" ? data[0].ESI_Commission+"" : 0}
                          mode="outlined"
                        />
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                        <TextInput
                          // theme={customTheme}
                          style={{
                            marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                            borderColor: esiColor.TextOuterLine, borderWidth: 1
                          }}
                          label="ESI Commission Type"
                          name="ESI_Commission_Type"
                          disabled
                          value={data[0].ESI_Commission_Type ? (data[0].ESI_Commission_Type == 'P' ? 'PERCENTAGE' : 'AMOUNT') : 'PERCENTAGE'}
                          mode="outlined"
                        />
                      </View>
                    </View>
                    <View style={{ width: "80%", margin: "auto" }}>
                      <View style={{
                        flexShrink: 1, flexDirection: "row"
                      }}>
                        <RadioButton
                          color={esiColor.itemColor}
                          uncheckedColor={esiColor.SBorderColor}
                          value={values.Is_Product_Available}
                          name="Is_Product_Available"
                          status={values.Is_Product_Available ? 'checked' : 'unchecked'}
                          onPress={() => setFieldValue('Is_Product_Available', !values.Is_Product_Available)}
                        />
                        <Text style={{ marginTop: 10, color: Boolean(touched.Is_Product_Available && errors.Is_Product_Available) ? "#b00020" : esiColor.Text }}>Is Product Available</Text>
                      </View>
                    </View>

                  </Card.Content>
                  <View style={{ marginHorizontal: 30 }}>
                    <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                    <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                      Save
                    </Button>
                  </View>
                </Card>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface >

  );
};

export default EditPartnerBiddingProducts;