import axios from 'axios';
import config from './config';
import { getAllshopperPartnerAction } from './shoppers/shopersAction';

export const SHOPPERPARTNERS_REQUEST = "SHOPPERPARTNERS_REQUEST";
export const SHOPPERPARTNERS_SUCCESS = "SHOPPERPARTNERS_SUCCESS";
export const SHOPPERPARTNERS_FAIL = "SHOPPERPARTNERS_FAIL";

export const SHOPPERPRODUCT_REQUEST = "SHOPPERPRODUCT_REQUEST";
export const SHOPPERPRODUCT_SUCCESS = "SHOPPERPRODUCT_SUCCESS";
export const SHOPPERPRODUCT_FAIL = "SHOPPERPRODUCT_FAIL";

export const SHOPPERPARTNERS_FILTER = "SHOPPERPARTNERS_FILTER";
export const SHOPPERPARTNERS_DATA = "SHOPPERPARTNERS_DATA";

export const SHOPPERPARTNER_CREATE_REQUEST = "SHOPPERPARTNER_CREATE_REQUEST";
export const SHOPPERPARTNER_CREATE_SUCCESS = "SHOPPERPARTNER_CREATE_SUCCESS";
export const SHOPPERPARTNER_CREATE_FAIL = "SHOPPERPARTNER_CREATE_FAIL";

export const SHOPPERPARTNER_UPDATE_REQUEST = "SHOPPERPARTNER_UPDATE_REQUEST";
export const SHOPPERPARTNER_UPDATE_SUCCESS = "SHOPPERPARTNER_UPDATE_SUCCESS";
export const SHOPPERPARTNER_UPDATE_FAIL = "SHOPPERPARTNER_UPDATE_FAIL";

export const SHOPPERPHYSICALPARTNER_ADDRESS_REQUEST = "SHOPPERPHYSICALPARTNER_ADDRESS_REQUEST";
export const SHOPPERPHYSICALPARTNER_ADDRESS_SUCCESS = "SHOPPERPHYSICALPARTNER_ADDRESS_SUCCESS";
export const SHOPPERPHYSICALPARTNER_ADDRESS_FAIL = "SHOPPERPHYSICALPARTNER_ADDRESS_FAIL";

const headers = config.headersCommon;

const cudheaders = config.nodeheadersCommon;
export const invalid_data = () => {
    return {
        type: RESET
    }
}
/*====================================================================
                  Shoppers Product
=======================================================================*/

export const addshopperPhysicalPartnerAction = (formData: any, navigation: any, toast: any) => async dispatch => {
    dispatch({
        type: SHOPPERPARTNER_CREATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Discounted_Products/Create`,
        formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SHOPPERPARTNER_CREATE_SUCCESS
                });
                const formValue = {
                    Records_Filter: 'FILTER',
                    Product_Id: formData.Product_Id,
                    Partner_Details_Id: formData.Partner_Details_Id,
                };
                dispatch(getAllshopperPartnerAction(formValue))
                navigation.navigate('ShoppersownGet', { shoppersproductparams: formData.Product_Id });
                toast.show({ message: 'Shopper Product Added Successfully.', type: 'info', duration: 3000, position: 'top' });

            } else {
                dispatch({
                    type: SHOPPERPARTNER_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SHOPPERPARTNER_CREATE_FAIL,
                payload: "Currently server is not working.Please try again later."
            });
        })
};
/*=============================================================
          Update Shopper
===============================================================*/
export const EditshopperPhysicalPartner = (formData: any, navigation: any, toast: any,) => async dispatch => {
    dispatch({
        type: SHOPPERPARTNER_UPDATE_REQUEST
    });
    axios.put(`${config.nodecudurl}/Discounted_Products/Update`,
        formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SHOPPERPARTNER_UPDATE_SUCCESS
                });
                navigation.navigate('ShoppersownGet', { shoppersproductparams:formData.Product_Id });
                toast.show({ message: 'Shopper Product Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    Records_Filter: 'FILTER',
                    Product_Id: formData.Product_Id,
                    Partner_Details_Id: formData.Partner_Details_Id,
                };
                dispatch(getAllshopperPartnerAction(formValue))
            } else {
                dispatch({
                    type: SHOPPERPARTNER_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SHOPPERPARTNER_UPDATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

// Filter 
export const shopperpartnerFilter = (fType: any, value: any) => {
    return function (dispatch) {
        dispatch({
            type: SHOPPERPARTNERS_FILTER,
            fType: fType,
            value: value,
        });
    }
}
//save cache data
export const shopperpartnerdata = (value: any) => {
    return function (dispatch) {
        dispatch({
            type: SHOPPERPARTNERS_DATA,
            value: value,
        });
    }
}


export const addshopperownPhysicalPartnerAction = (formData: any, navigation: any, toast: any,partnerproductid:any) => async dispatch => {
    dispatch({
        type: SHOPPERPARTNER_CREATE_REQUEST
    });
    axios.post(`${config.url}/Discounted_Products/Create`,
        formData,
        {
            headers: headers
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SHOPPERPARTNER_CREATE_SUCCESS
                });
                const formValue = {
                    "search": '',
                    "Discounted_Product_Id": "",
                    "search_by_filter": '',
                    "Max_Discount": "",
                    "Min_Discount": "",
                    "Partner_Details_Id": formData.Partner_Details_Id,
                    "Product_Id": formData.Product_Id,
                    "Records_Filter": "FILTER",
                    "Status": ""     
                };
                const pagination={
                    PageNo: 1, PageSize: 5, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" 
                }
                dispatch(getAllshopperPartnerAction(formValue,pagination))
                navigation.navigate('ShoppersownGet', { shoppersproductparams: formData.Product_Id,partnerproductid:partnerproductid });
                toast.show({ message: 'Shopper Product Added Successfully.', type: 'info', duration: 3000, position: 'top' });

            } else {
                dispatch({
                    type: SHOPPERPARTNER_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SHOPPERPARTNER_CREATE_FAIL,
                payload: "Currently server is not working.Please try again later."
            });
        })
};

export const EditshopperownPhysicalPartner = (formData: any, navigation: any, toast: any,partnerproductid:any) => async dispatch => {
    dispatch({
        type: SHOPPERPARTNER_UPDATE_REQUEST
    });
    axios.put(`${config.url}/Discounted_Products/Update`,
        formData,
        {
            headers: headers
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SHOPPERPARTNER_UPDATE_SUCCESS
                });
                navigation.navigate('ShoppersownGet', { shoppersproductparams:formData.Product_Id,partnerproductid:partnerproductid });
                toast.show({ message: 'Shopper Product Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    "search": '',
                    "Discounted_Product_Id": "",
                    "Max_Discount": "",
                    "Min_Discount": "",
                    "Partner_Details_Id":  formData.Partner_Details_Id,
                    "Product_Id": formData.Product_Id,
                    "Records_Filter": "FILTER",
                    "Status": "" 
                };
                const pagination = { PageNo: 1, PageSize: 5, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" }
 
                dispatch(getAllshopperPartnerAction(formValue,pagination))
            } else {
                dispatch({
                    type: SHOPPERPARTNER_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SHOPPERPARTNER_UPDATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};