import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDealAction, getAllBrands, getAllCategories, dealData, uploadDealImage, getBothDealsAction, getAllbrandsearch, getAllcategorysearch } from '../../../state/actions/dealAction';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, RefreshControl, Platform } from 'react-native';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import ImageUpload from '../../../components/custom/image-upload/upload';
import Header from '../../auth/components/header';
import EsiDatePicker from '../../../components/custom/date/date';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import { imageORfileupload } from '../../../state/actions/commonImageOrFileUpload';
import { uriToBlob } from '../../../components/custom/uriBlob';

//discountType and esiCashbackTypeOption are used to get the data from dropdown
const discountType = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
];
const DiscountlabelOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'UPTO',
    label: 'UPTO'
  },
  {
    value: 'FLAT',
    label: 'FLAT'
  }
];

const AddDealBasic = (props: any) => {
  //navigation is used for navigation between screens
  const { navigation } = props
  const toast = useToast();
  const esiColor = useSelector(state => state.theme);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const EmailID = useSelector(state => state.auth?.user);
  //deals, brands, categories contains the data from API's using state call
  const [dealsdata, setDealsData] = useState("");
  const callBackData = (data) => {
    if (data) {
      setDealsData(data);
    }
  };
  React.useEffect(() => {
    if (EmailID) {
      let formData = {
        "Partner_Details_Id": EmailID?.Partner_Details_Id,
        "Records_Filter": "ALL"
      };
      dispatch(getBothDealsAction(formData, callBackData));
    }
    // dispatch(getAllBrands());
    // dispatch(getAllCategories());
  },
    [EmailID]);
  React.useEffect(() => {
    if (!(permissions.Deals_Management === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);
  const brands = useSelector(state => state.deal.dealBrands.brands);
  const categories = useSelector(state => state.deal.dealCategories.categories);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "DEAL_ID", SortOrder: "DESC" });

  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });


  //Dropdowns
  const [brandDropDown, setBrandDropDown] = useState("");
  const [categoryDropDown, setCategoryDropDown] = useState("");
  const [discDropDown, setdiscDropDown] = useState("");
  const [disclabelDropDown, setdisclabelDropDown] = useState("");

  //brandlist used to store the label,value in an array from API data  
  let brandlist = []
  if (brands) {
    if (brands[0]) {
      for (let i = 0; i < brands.length; i++) {
        brandlist.push({
          label: brands[i].Brand_Name,
          value: brands[i].Brand_Id
        })
      }
    }
  }
  //categorylist used to store the label,value in an array from API data  
  let categorylist = []
  if (categories) {
    if (categories[0]) {
      for (let i = 0; i < categories.length; i++) {
        categorylist.push({
          label: categories[i].Category_Name,
          value: categories[i].Category_Id
        })
      }
    }
  }

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAllbrandsearch("1"))
  }, []);
  React.useEffect(() => {
    dispatch(getAllcategorysearch("1"))
  }, []);
  const updateStatescategory = (data) => {
    if (data) {
      dispatch(getAllcategorysearch(data))
    }
  };
  const updateStates = (data) => {
    if (data) {
      dispatch(getAllbrandsearch(data))
    }
  };
  // img upload

  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImage = (data: any) => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };
  const fetchImageFromUri = async (uri: any) => {
    const blob = await uriToBlob(uri);
    return blob;
  };

  const handleImage = async (result: any) => {
    let call = result.uri
    if (result) {
      let exe = "img";
      let uriParts = result.uri.split('.');
      if (Platform.OS == "web") {
        let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
        let filformate = fileExtension[0].split(':')
        exe = filformate[1].split('/')[1]
      } else {
        exe = uriParts[uriParts.length - 1]
      }
      let fileData = {
        File_name: "Deal",
        Functionality: "Deals",
        ext: exe,
        File_Type: "Images",
      };
      const img = await fetchImageFromUri(call);
      setImage({
        preview: result.uri,
        raw: img
      });
      dispatch(
        imageORfileupload(img, fileData, callBackUploadImage)
      );
    }
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  /*====================================
          Handler
  ====================================*/

  const values = {
    Title: '',
    Brand_Id: '',
    Category_Id: '',
    Discount_Type: '',
    Discount: '',
    Esi_Cashback_Type: '',
    Esi_Cashback_Amount: '',
    Discount_Label: '',
    Cashback_Label: '',
    Start_Date: '',
    End_Date: '',
    Image: '',
  }

  //Form validation
  const Form_Validation = Yup.object().shape({
    Title: Yup.string().matches(/^[A-Za-z ]*$/, 'Please provide valid Title.').notOneOf(dealsdata ? dealsdata.map(item => item['Title']) : [], "Deal Title is already exist.").required('Deal Title is required.'),
    Category_Id: Yup.string().test(
      'oneOfRequired',
      'Category / Brand Name is required.',
      function (item) {
        return (this.parent.Category_Id || this.parent.Brand_Id)
      }
    ),
    Brand_Id: Yup.string().test(
      'oneOfRequired',
      'Category / Brand Name is required.',
      function (item) {
        return (this.parent.Category_Id || this.parent.Brand_Id)
      }
    ),
    Discount_Type: Yup.string().test(
      'oneOfRequired',
      'Discount Type is required.',
      function (item) {
        return (this.parent.Discount_Type)
      }
    ),
    Discount: Yup.number().when("Discount_Type", (Discount_Type, schema) => {
      if (Discount_Type == "A") {
        return schema.min(1, "Discount Amount must be at least 1.").required('Discount Amount is required.');
      } else if (Discount_Type == "P") {
        return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be <= 100.").required('Discount Percentage is required.');
      }
    }),
    Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Start Date greater than or equal to today.").required("Start Date is required."),
    End_Date: Yup.date().when("Start_Date", (Start_Date, schema) => Start_Date && schema.min(Start_Date, "End Date can't be before Start Date.")).required("End Date is required."),
    Discount_Label: Yup.string().when("Discount", (Discount, schema) => {
      if (Discount) {
        return schema.required('Discount Label is required.');
      }
    }),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    values.Image = imgUrl;
    if (!values.Image) {
      submitTest = false;
      errors.Image = "Image is required.";
    }

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      //passing the formvalues to next page through dispatch
      setImage({ preview: '', raw: '' })
      setImgUrl('');
      resetForm();
      dispatch(dealData(formValues));
      //navigation to next page after validating the form
      navigation.navigate('AddDealConfig')
    }
  }

  //form reset
  const [reload, setReload] = React.useState(false);

  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };;

  const styles = StyleSheet.create({
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      } >
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
          {(props) => (
            <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
              <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }}
                  onPress={() => {
                    props.resetForm();
                    setReload(true);
                    navigation.navigate('DealsGet')
                  }}>
                  Go Back
                </Button>
              </View>
              <Card style={{
                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
              }}>
                <Card.Content>
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                    Add Deal
                  </Text>
                  <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    {/* Title input field with validation*/}
                    <View style={{ flexShrink: 1, width: 650 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(props.touched.Title && props.errors.Title)}
                        label='Title'
                        name='Title'
                        mode='outlined'
                        value={props.values.Title}
                        onChangeText={props.handleChange('Title')}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Title && props.errors.Title)}>
                        <ErrorMessage name="Title" />
                      </HelperText>
                      {/* Brand Name field with validation*/}
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Brand_Id && props.errors.Brand_Id) && { color: '#b00020' }]}>
                            Brand Name
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Brand_Id && props.errors.Brand_Id) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            dropdownPosition="bottom"
                            placeholder={!brandDropDown ? '-- Please Select --' : ''}
                            data={brandlist}
                            labelField="label"
                            valueField="value"
                            maxHeight={brandlist.length > 1 ? 250 : 200}
                            searchQuery={item => { updateStates(item) }}
                            search={brandlist.length > 1 ? true : false}
                            searchPlaceholder={'Select Brand Name'}
                            value={props.values.Brand_Id}
                            onUpdateValue={item => {
                              props.setFieldValue('Brand_Id', item.value)
                              setBrandDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Brand_Id && props.errors.Brand_Id)}>
                            <ErrorMessage name="Brand_Id" />
                          </HelperText>
                        </View>
                        {/* Category Name field with validation*/}
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Category_Id && props.errors.Category_Id) && { color: '#b00020' }]}>
                            Category Name
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Category_Id && props.errors.Category_Id) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            dropdownPosition="bottom"
                            placeholder={!categoryDropDown ? '-- Please Select --' : ''}
                            data={categorylist}
                            labelField="label"
                            valueField="value"
                            maxHeight={categorylist.length > 1 ? 250 : 200}
                            searchQuery={item => { updateStatescategory(item) }}
                            search={categorylist.length > 1 ? true : false}
                            searchPlaceholder={'Select Category Name'}
                            value={props.values.Category_Id}
                            onUpdateValue={item => {
                              props.setFieldValue('Category_Id', item.value)
                              setCategoryDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Category_Id && props.errors.Category_Id)}>
                            <ErrorMessage name="Category_Id" />
                          </HelperText>
                        </View>
                      </View>
                      {/* Discount Type field with validation*/}
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { color: '#b00020' }]}>
                            Discount Type
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            dropdownPosition="bottom"
                            placeholder={!discDropDown ? '-- Please Select --' : ''}
                            data={discountType}
                            labelField="label"
                            valueField="value"
                            maxHeight={discountType.length > 1 ? 250 : 200}
                            search={discountType.length > 1 ? true : false}
                            searchPlaceholder={'Select Discount Type'}
                            value={props.values.Discount_Type}
                            onUpdateValue={item => {
                              props.setFieldValue('Discount_Type', item.value)
                              setdiscDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Discount_Type && props.errors.Discount_Type)}>
                            <ErrorMessage name="Discount_Type" />
                          </HelperText>
                        </View>
                        {/* Discount field with validation*/}
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 14 }}>
                          <TextInput
                            // theme={customTheme}
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            mode='outlined'
                            name="Discount"
                            disabled={props.values.Discount_Type ? false : true}
                            label={"Discount " + (props.values.Discount_Type === "A" ? "Amount ₹" : props.values.Discount_Type === "P" ? "Percentage %" : "")}
                            value={props.values.Discount}
                            onChangeText={props.handleChange('Discount')}
                            maxLength={10}
                            keyboardType='numeric'
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Discount && props.errors.Discount)}>
                            <ErrorMessage name="Discount" />
                          </HelperText>
                        </View>
                      </View>


                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        {/* Discount_Label field with validation*/}
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { color: '#b00020' }]}>
                            Discount Label
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            dropdownPosition="bottom"
                            disable={props.values.Discount ? false : true}
                            placeholder={!disclabelDropDown ? '-- Please Select --' : ''}
                            data={DiscountlabelOption}
                            labelField="label"
                            valueField="value"
                            maxHeight={DiscountlabelOption.length > 1 ? 250 : 200}
                            search={DiscountlabelOption.length > 1 ? true : false}
                            searchPlaceholder={'Select Discount Lable'}
                            value={props.values.Discount_Label}
                            onUpdateValue={item => {
                              props.setFieldValue('Discount_Label', item.value)
                              setdisclabelDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Discount_Label && props.errors.Discount_Label)}>
                            <ErrorMessage name="Discount_Label" />
                          </HelperText>
                        </View>
                      </View>
                      {/* Start Date field with validation*/}
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>

                          <EsiDatePicker
                            error={Boolean(props.touched.Start_Date && props.errors.Start_Date)}
                            theme={customTheme}
                            style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                            name={'Start_Date'}
                            label={'Start Date'}
                            value={props.values.Start_Date}
                            valueUpdate={item => {
                              props.setFieldValue('Start_Date', item)
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Start_Date && props.errors.Start_Date)}>
                            <ErrorMessage name="Start_Date" />
                          </HelperText>
                        </View>
                        {/* End Date field with validation*/}
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          <EsiDatePicker
                            theme={customTheme}
                            style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                            error={Boolean(props.touched.End_Date && props.errors.End_Date)}
                            name={'End_Date'}
                            label={'End Date'}
                            value={props.values.End_Date}
                            valueUpdate={item => {
                              props.setFieldValue('End_Date', item)
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.End_Date && props.errors.End_Date)}>
                            <ErrorMessage name="End_Date" />
                          </HelperText>
                        </View>
                      </View>

                    </View>
                    {/* Image upload with validation*/}
                    <View style={{ flexShrink: 1, paddingTop: 20, paddingLeft: 15, width: 250 }} >
                      <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                      <HelperText type="error" visible={!imgUrl && props.touched.Image && props.errors.Image}>
                        {!imgUrl && props.touched.Image && props.errors.Image}
                      </HelperText>
                    </View>

                  </View>

                  <View style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Button textColor={esiColor.itemButtenColor} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                      Next
                    </Button>
                  </View>

                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};

export default AddDealBasic;