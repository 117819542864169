import React, { useEffect, useState } from "react";
import { IconButton, Surface, Text } from 'react-native-paper';
import { Dimensions, Platform, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { CountryCode } from "../../../utils/countrycode";
import isEmpty from "../../../state/validations/is-empty";
import { getAllTransactions } from "../../../state/actions/shoppers/shoppertransactionAction";
import Header from "../header/header";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default function AllTransaction(props: any) {
    const { item, navigation, ...rest } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    let transactiuon = useSelector((state) => state.alltransactions.transactiuon.all);
    const EmailID = useSelector(state => state.auth.user.Email_Id);

    // Pagination
    let currentItems;
    let length;
    const pages = [];
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxpageNumberLimit, setmaxpageNumberLimit] = useState(5);
    const [minpageNumberLimit, setminpageNumberLimit] = useState(0);
    const handleClick = (event) => {
        setCurrentPage(Number(event))
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
    });

    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });

    const handleprev = () => {
        setCurrentPage(currentPage - 1)
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setmaxpageNumberLimit(maxpageNumberLimit - pageNumberLimit);
            setminpageNumberLimit(minpageNumberLimit - pageNumberLimit);
        }
    }
    const goToTop = () => {
        scroll.scrollTo({ x: 0, y: 0, animated: true });
    }
    const handlenext = () => {
        setCurrentPage(currentPage + 1)
        if (currentPage + 1 > maxpageNumberLimit) {
            setmaxpageNumberLimit(maxpageNumberLimit + pageNumberLimit);
            setminpageNumberLimit(minpageNumberLimit + pageNumberLimit);
        }
    }
    if (transactiuon) {
        length = transactiuon.length
        for (let i = 1; i <= Math.ceil(transactiuon.length / itemsPerPage); i++) {
            pages.push(i);
        }
        const indexOfLastItem = transactiuon.length > 17 ? currentPage * itemsPerPage : itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage
        currentItems = transactiuon.length > 0 ? transactiuon?.slice(indexOfFirstItem, indexOfLastItem) : 0
    }
    let pageIncrementbtn = null;
    if (pages.length > maxpageNumberLimit) {
        pageIncrementbtn =
            <View style={{ alignItems: 'center', margin: "0.5%" }}>
                <TouchableOpacity style={{
                    width: 35, borderWidth: 1, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor,
                    shadowRadius: 3, borderRadius: 5, marginRight: 5, height: 35, justifyContent: 'center',
                    alignItems: 'center',
                }} onPress={() => { handlenext(); goToTop() }}>
                    <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>....</Text>
                </TouchableOpacity>
            </View>
    }
    let pageDecrementbtn =
        null;
    if (minpageNumberLimit >= 1) {
        pageDecrementbtn =
            <View style={{ alignItems: 'center', margin: "0.5%" }}>
                <TouchableOpacity style={{
                    width: 35, borderWidth: 1, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor,
                    shadowRadius: 3, borderRadius: 5, marginRight: 5, height: 35, justifyContent: 'center',
                    alignItems: 'center',
                }} onPress={() => { handleprev(); goToTop() }}>
                    <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>....</Text>
                </TouchableOpacity>
            </View>
    }
    const renderPageNumber = pages.map((number) => {
        if (number < maxpageNumberLimit + 1 && number > minpageNumberLimit) {
            return (
                <View key={number} style={{ alignItems: 'center', margin: "0.5%" }}>
                    <TouchableOpacity style={{
                        width: 35, borderWidth: 1, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor,
                        shadowRadius: 3, borderRadius: 5, marginRight: 5, height: 35, justifyContent: 'center',
                        alignItems: 'center',
                    }} onPress={() => { handleClick(number); goToTop() }}>
                        <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>{number}</Text>
                    </TouchableOpacity>
                </View>
            )
        }
        else {
            return null;
        }

    })

    // API call
    useEffect(() => {
        if (!isEmpty(EmailID)) {
            let formData = {
                "Records_Filter": "FILTER",
                "Shopper_Email_Id": EmailID
            }
            dispatch(getAllTransactions(formData));
        }
        else {
            setTimeout(() => {
                if (isEmpty(EmailID)) {
                    navigation.navigate("SignIn");
                }
            }, 1000)
        }
    }, [EmailID]);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />

            <View style={{ flexDirection: "column", marginVertical: 5, justifyContent: 'center' }}>

                <Text style={{ fontSize: 22, fontWeight: "bold", alignSelf: 'center', color: esiColor.brandFontColor }}>Transaction History</Text>
            </View>
            <IconButton
                icon="arrow-left"
                size={25}
                color={"#27B6CC"}
                style={{ marginTop: (Platform.OS === 'web' ? -35 : -40), marginLeft: -6 }}
                onPress={() => navigation.navigate('Main', { screen: 'All', params: { page: 'products', filter: 'all' } })}
            />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ margin: 7 }}>
                    <View style={{ flex: 1 }}>
                        {
                            currentItems?.length > 0 ? currentItems.map((transaction, index) => (
                                <View key={index}
                                    style={{
                                        padding: 7,
                                        height: 100,
                                        shadowColor: esiColor.brandShadowColor,
                                        // shadowOffset: { width: -2, height: 4 },
                                        shadowOpacity: 7,
                                        shadowRadius: 5,
                                        paddingTop: 20,
                                        borderWidth: 1,
                                        marginBottom: 10,
                                        width: dimensions.width <= 700 ? '100%' : '70%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        borderRadius: 5,
                                        backgroundColor: esiColor.CBColor

                                    }}>
                                    <View style={{ flex: 1, flexDirection: 'row', flexWrap: "wrap" }}>
                                        <View style={{ marginBottom: 10, flex: 6 }}>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.DescColor }}>Transaction ID: </Text><Text style={{ fontSize: 13, color: esiColor.itemColor }}>{transaction.Shopper_Transaction_Id}</Text></View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.DescColor }}>Payment Mode: </Text><Text style={{ fontSize: 13, color: esiColor.itemColor }}>{transaction.Payment_Mode}</Text></View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.DescColor }}>Payment Method: </Text><Text style={{ fontSize: 13, color: esiColor.itemColor }}>{transaction.Payment_Method}</Text></View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.DescColor }}>Discount Value: </Text><Text style={{ fontSize: 13, color: esiColor.itemColor }}>{transaction.Discount_Value}</Text></View>
                                        </View>
                                        <View style={{ marginBottom: 10, flex: 5 }}>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.DescColor }}>Status:</Text><Text style={{ fontSize: 13, color: esiColor.itemColor }}> {transaction.Payment_Gateway_Response_Message}</Text></View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.DescColor }}>Order Amount: </Text><Text style={{ fontSize: 13, color: esiColor.itemColor }}> {transaction.Order_Amount}</Text></View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.DescColor }}>City: </Text><Text style={{ fontSize: 13, color: esiColor.itemColor }}>{transaction.City}</Text></View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.DescColor }}>Country: </Text><Text style={{ fontSize: 13, color: esiColor.itemColor }}>{CountryCode.find((item) => item.code == transaction.Country)?.name}</Text></View>
                                        </View>
                                    </View>
                                </View>
                            ))
                                : <View >
                                    <View style={{ alignSelf: 'center' }}>
                                    </View>

                                </View>}
                        {isEmpty(currentItems) &&
                            <View >
                                <View style={{ alignSelf: 'center' }}>
                                </View>
                                <View style={{ alignSelf: 'center' }}>
                                    <Text style={{ fontSize: 16, padding: 10, marginBottom: 15, color: esiColor.NoDataFound }}>
                                        No records found.
                                    </Text>
                                </View>
                            </View>
                        }
                    </View>
                    <View>
                        {length > 16 ? (
                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <TouchableOpacity disabled={currentPage === pages[0] ? true : false} onPress={() => { handleprev(); goToTop() }}
                                    style={{
                                        width: 35, height: 35, justifyContent: 'center', alignItems: 'center',
                                        flexDirection: 'column',
                                        borderRadius: 5, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 3, borderWidth: 1, opacity: currentPage === pages[0] ? "0.6" : null
                                    }}>
                                    <Text style={{ color: currentPage === pages[0] === true ? esiColor.itemColor : esiColor.itemColor }}>Prev</Text>
                                </TouchableOpacity>
                                {pageDecrementbtn}
                                {renderPageNumber}
                                {pageIncrementbtn}
                                <TouchableOpacity disabled={currentPage === pages[pages.length - 1] ? true : false} onPress={() => { handlenext(); goToTop() }}
                                    style={{
                                        width: 35, height: 35, justifyContent: 'center', alignItems: 'center',
                                        flexDirection: 'column', borderRadius: 5, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 3, borderWidth: 1,
                                        opacity: currentPage === pages[pages.length - 1] ? "0.6" : null
                                    }}>
                                    <Text style={{ color: currentPage === pages[pages.length - 1] === true ? esiColor.itemColor : esiColor.itemColor }}>Next</Text>
                                </TouchableOpacity>
                            </View>
                        ) : (null)}

                    </View>
                </View>
            </ScrollView>
        </Surface>

    );
}
// const style = StyleSheet.create({

//     title: {
//         fontWeight: "bold",
//         fontSize: 12,
//         justifyContent: "center",
//         textAlign: "center",
//         width: 80
//     },
//     tablerow: {
//         justifyContent: "center",
//         textAlign: "center",
//         width: 140
//     },
//     databeBox: {
//         margin: 10,
//         textAlign: 'center',
//     },
// });