
import React, { useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Card, Button, TextInput, HelperText, Checkbox, Surface } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../auth/components/header';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getEventByIdAction, updateEventAction } from '../../../../state/actions/Properties/eventsAction';
const statusOption = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const EditUserEventspagetwo = (props: any) => {
  const { navigation } = props;
  const ID = props?.route?.params?.ID;
  // const productmaster = route?.params?.data;
  const toast = useToast();
  const dispatch = useDispatch();
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const EmailID = useSelector(state => state.auth?.user);
  //reload
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  React.useEffect(() => {
    if (!(permissions.Product_Master_Product_Color_Product_Size === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
    setSeconds(2);
  }, [permissions, ID]);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
   const [showDropDownactive, setShowDropDownactive] = React.useState('');
   const [eventsbyids, setEventByIds] = React.useState();
   
  const [data, setData] = useState([]);
  const callbackData = (data: any) => {
    setData(data)

  }
  React.useEffect(() => {
    if(ID!==eventsbyids){
    let formData = {
      "Partner_Details_Id":EmailID.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "User_Event_Id": ID
    };
    setEventByIds(ID)
    dispatch(getEventByIdAction(formData, callbackData));
}
}, [ID,eventsbyids]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  //  const productmasters = useSelector(state => state.productmasters.productmasters.all);
  const eventsPagetwo = useSelector(state => state.userevent.eventdata.eventsdata);
  //form reset
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  return (
    <Surface style={{ flex: 1, }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {seconds <= 0 &&
          <Formik
            initialValues={{
              "User_Event_ID": eventsPagetwo?.User_Event_ID,
              "Function_Hall_Id": eventsPagetwo?.Function_Hall_Id,
              "Price_Per_Day": eventsPagetwo?.Price_Per_Day,
              "Price_Per_Hour": eventsPagetwo?.Price_Per_Hour,
              "Room_Cost_Per_Night": eventsPagetwo?.Room_Cost_Per_Night,
              "User_Email_ID": eventsPagetwo?.User_Email_ID,
              "Any_Extra_Charges": eventsPagetwo?.Any_Extra_Charges,
              "Event_Price": eventsPagetwo?.Event_Price,
              "Hall_Decoration_Price": eventsPagetwo?.Hall_Decoration_Price,
              "Electricity_Price_Per_Hour": eventsPagetwo?.Electricity_Price_Per_Hour,
              "AC_Electricty_Price_Per_Hour": eventsPagetwo?.AC_Electricty_Price_Per_Hour,
              "Event_Booking_Requested_Date": eventsPagetwo?.Event_Booking_Requested_Date,
              "Event_End_Date": eventsPagetwo?.Event_End_Date,
              "Event_Start_Date": eventsPagetwo?.Event_Start_Date,
              "Event_Total_Members": eventsPagetwo?.Event_Total_Members,
              "Event_Total_Time_In_Days": eventsPagetwo?.Event_Total_Time_In_Days,
              "Event_Total_Time_In_Hours": eventsPagetwo?.Event_Total_Time_In_Hours,
              "Food_NonVeg_Price_Per_Plate": eventsPagetwo?.Food_NonVeg_Price_Per_Plate,
              "Food_Veg_Price_Per_Plate": eventsPagetwo?.Food_Veg_Price_Per_Plate,
              "Vent_Date": eventsPagetwo?.Vent_Date,
              "SGST": eventsPagetwo?.SGST,
              "SGST_Percentage": eventsPagetwo?.SGST_Percentage,
              "IGST": eventsPagetwo?.IGST,
              "IGST_Percentage": data?.IGST_Percentage,
              "CGST": data?.CGST,
              "CGST_Percentage": data?.CGST_Percentage,
              "Cashback_Amount": data?.Cashback_Amount,
              "Cashback_Settled_To_User": data?.Cashback_Settled_To_User,
              "Discount_Amount": data?.Discount_Amount,
              "Discount_Percentage": data?.Discount_Percentage,
              "Event_Total_Tax": data?.Event_Total_Tax,
              "Event_Total_Price_Before_Tax": data?.Event_Total_Price_before_Tax,
              "Event_Total_Price_After_Tax": data?.Event_Total_Price_After_Tax,
              "Partner_Total_Share": data?.Partner_Total_Share,
              "Payment_Reference_ID": data?.Payment_Reference_ID,
              "Payment_Status": data?.Payment_Status,
              "Meepaisa_Commission": data?.Meepaisa_Commission,
              "Booking_Status": data?.Booking_Status,
              "Created_By": data?.Created_By,
              "Created_Date": data?.Created_Date,
              "Event_Status": data?.Event_Status,
              "IS_Event_Has_Legal_Permission": data?.IS_Event_Has_Legal_Permission> 0 ? true : false,
              "IS_Event_Required_Alchol": data?.IS_Event_Required_Alchol> 0 ? true : false,
              "Modified_By": EmailID?.Email_Id,
              "User_Cancel_Reason": data?.User_Cancel_Reason,
              "Status": data?.Status,
            }}
            validationSchema={Yup.object().shape({
              IGST_Percentage: Yup.number().required('IGST Percentage is required.'),
              CGST: Yup.number().required(' CGST is required.'),
              CGST_Percentage: Yup.number().required(' CGST Percentage is required.'),
              Cashback_Amount: Yup.number().required(' Cashback Amount is required.'),
              Cashback_Settled_To_User: Yup.number().required('Cashback Settled To User is required.'),
              Discount_Amount: Yup.number().required('Discount Amount is required.'),
              Discount_Percentage: Yup.number().required('Discount Percentage is required.'),
              Event_Total_Tax: Yup.number().required('Event Total Tax is required.'),
              Event_Total_Price_Before_Tax: Yup.number().required(' Event Total Price Before Tax is required.'),
              Event_Total_Price_After_Tax: Yup.number().required(' Event Total Price After Tax is required.'),
              Partner_Total_Share: Yup.number().required(' Partner Total Share is required.'),
              Payment_Status: Yup.string().required(' Payment Status is required.'),
              Meepaisa_Commission: Yup.number().required('Meepaisa Commission is required.'),
              Status: Yup.string().required(' Status is required.'), 
            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              const errors = {};
              setErrors(errors);
              if (submitTest) {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.IS_Event_Has_Legal_Permission) {
                  formValues.IS_Event_Has_Legal_Permission = 1;
                } else {
                  formValues.IS_Event_Has_Legal_Permission = 0;
                }
                if (values.IS_Event_Required_Alchol) {
                  formValues.IS_Event_Required_Alchol = 1;
                } else {
                  formValues.IS_Event_Required_Alchol = 0;
                }
                dispatch(updateEventAction(formValues,navigation,{partnerid:EmailID.Partner_Details_Id}));
              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (

              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}>
                <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%" }}>
                  <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm();
                      navigation.navigate('EditUserEvents',{ID:ID})
                    }}>
                    Go Back
                  </Button>
                </View>
                <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#d6d6d7' }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                      Edit Events
                    </Text>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        flexShrink: 1
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.IGST_Percentage && errors.IGST_Percentage)}
                            label="IGST Percentage"
                            mode='outlined'
                            value={values.IGST_Percentage}
                            onChangeText={handleChange("IGST_Percentage")}
                          />
                          <HelperText type="error" visible={Boolean(touched.IGST_Percentage && errors.IGST_Percentage)}>
                            {touched.IGST_Percentage && errors.IGST_Percentage}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1,marginTop: '1%'  }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.CGST && errors.CGST)}
                            label="CGST"
                            mode='outlined'
                            value={values.CGST}
                            onChangeText={handleChange("CGST")}
                          />
                          <HelperText type="error" visible={Boolean(touched.CGST && errors.CGST)}>
                            {touched.CGST && errors.CGST}
                          </HelperText>
                        </View>

                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        flexShrink: 1
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.CGST_Percentage && errors.CGST_Percentage)}
                            label="CGST Percentage"
                            mode='outlined'
                            value={values.CGST_Percentage}
                            onChangeText={handleChange("CGST_Percentage")}
                          />
                          <HelperText type="error" visible={Boolean(touched.CGST_Percentage && errors.CGST_Percentage)}>
                            {touched.CGST_Percentage && errors.CGST_Percentage}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1,marginTop: '1%'  }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Cashback_Amount && errors.Cashback_Amount)}
                            label="Cashback Amount"
                            mode='outlined'
                            value={values.Cashback_Amount}
                            onChangeText={handleChange("Cashback_Amount")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Cashback_Amount && errors.Cashback_Amount)}>
                            {touched.Cashback_Amount && errors.Cashback_Amount}
                          </HelperText>
                        </View>

                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Cashback_Settled_To_User && errors.Cashback_Settled_To_User)}
                            label="Cashback Settled To User"
                            mode='outlined'
                            value={values.Cashback_Settled_To_User}
                            onChangeText={handleChange("Cashback_Settled_To_User")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Cashback_Settled_To_User && errors.Cashback_Settled_To_User)}>
                            {touched.Cashback_Settled_To_User && errors.Cashback_Settled_To_User}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Discount_Amount && errors.Discount_Amount)}
                            label="Discount Amount"
                            mode='outlined'
                            value={values.Discount_Amount}
                            onChangeText={handleChange("Discount_Amount")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Discount_Amount && errors.Discount_Amount)}>
                            {touched.Discount_Amount && errors.Discount_Amount}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Discount_Percentage && errors.Discount_Percentage)}
                            label="Discount Percentage"
                            mode='outlined'
                            value={values.Discount_Percentage}
                            onChangeText={handleChange("Discount_Percentage")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Discount_Percentage && errors.Discount_Percentage)}>
                            {touched.Discount_Percentage && errors.Discount_Percentage}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Event_Total_Tax && errors.Event_Total_Tax)}
                            label="Event Total Tax"
                            mode='outlined'
                            value={values.Event_Total_Tax}
                            onChangeText={handleChange("Event_Total_Tax")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Event_Total_Tax && errors.Event_Total_Tax)}>
                            {touched.Event_Total_Tax && errors.Event_Total_Tax}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                         <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Event_Total_Price_Before_Tax && errors.Event_Total_Price_Before_Tax)}
                            label="Event Total Price Before Tax"
                            mode='outlined'
                            value={values.Event_Total_Price_Before_Tax}
                            onChangeText={handleChange("Event_Total_Price_Before_Tax")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Event_Total_Price_Before_Tax && errors.Event_Total_Price_Before_Tax)}>
                            {touched.Event_Total_Price_Before_Tax && errors.Event_Total_Price_Before_Tax}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Event_Total_Price_After_Tax && errors.Event_Total_Price_After_Tax)}
                            label="Event Total Price After Tax"
                            mode='outlined'
                            value={values.Event_Total_Price_After_Tax}
                            onChangeText={handleChange("Event_Total_Price_After_Tax")}
                            keyboardType='numeric'
                          />
                          <HelperText type="error" visible={Boolean(touched.Event_Total_Price_After_Tax && errors.Event_Total_Price_After_Tax)}>
                            {touched.Event_Total_Price_After_Tax && errors.Event_Total_Price_After_Tax}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Partner_Total_Share && errors.Partner_Total_Share)}
                            label="Partner Total Share"
                            mode='outlined'
                            value={values.Partner_Total_Share}
                            onChangeText={handleChange("Partner_Total_Share")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Partner_Total_Share && errors.Partner_Total_Share)}>
                            {touched.Partner_Total_Share && errors.Partner_Total_Share}
                          </HelperText>
                        </View>

                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Payment_Reference_ID && errors.Payment_Reference_ID)}
                            label="Payment Reference ID"
                            mode='outlined'
                            value={values.Payment_Reference_ID}
                            onChangeText={handleChange("Payment_Reference_ID")}
                            keyboardType='numeric'
                          />
                          <HelperText type="error" visible={Boolean(touched.Payment_Reference_ID && errors.Payment_Reference_ID)}>
                            {touched.Payment_Reference_ID && errors.Payment_Reference_ID}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Payment_Status && errors.Payment_Status)}
                            label="Payment Status"
                            mode='outlined'
                            value={values.Payment_Status}
                            onChangeText={handleChange("Payment_Status")}
                            keyboardType='numeric'
                          />
                          <HelperText type="error" visible={Boolean(touched.Payment_Status && errors.Payment_Status)}>
                            {touched.Payment_Status && errors.Payment_Status}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Meepaisa_Commission && errors.Meepaisa_Commission)}
                            label="Meepaisa Commission"
                            mode='outlined'
                            value={values.Meepaisa_Commission}
                            onChangeText={handleChange("Meepaisa_Commission")}
                            keyboardType='numeric'
                          />
                          <HelperText type="error" visible={Boolean(touched.Meepaisa_Commission && errors.Meepaisa_Commission)}>
                            {touched.Meepaisa_Commission && errors.Meepaisa_Commission}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                        <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Booking_Status && errors.Booking_Status)}
                            label="Booking Status"
                            mode='outlined'
                            value={values.Booking_Status}
                            onChangeText={handleChange("Booking_Status")}
                            keyboardType='numeric'
                          />
                          <HelperText type="error" visible={Boolean(touched.Booking_Status && errors.Booking_Status)}>
                            {touched.Booking_Status && errors.Booking_Status}
                          </HelperText>
                        
                         
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Event_Status && errors.Event_Status)}
                            label="Event Status"
                            mode='outlined'
                            value={values.Event_Status}
                            onChangeText={handleChange("Event_Status")}
                            />
                          <HelperText type="error" visible={Boolean(touched.Event_Status && errors.Event_Status)}>
                            {touched.Event_Status && errors.Event_Status}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginBottom: '3%', marginTop: 25 }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.User_Cancel_Reason && errors.User_Cancel_Reason)}
                            label="User Cancel Reason"
                            mode='outlined'
                            value={values.User_Cancel_Reason}
                            onChangeText={handleChange("User_Cancel_Reason")}
                          />
                          <HelperText type="error" visible={Boolean(touched.User_Cancel_Reason && errors.User_Cancel_Reason)}>
                            {touched.User_Cancel_Reason && errors.User_Cancel_Reason}
                          </HelperText>

                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <View style={styles.container} >
                        <View style={styles.containerone}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Status && errors.Status) && { color: '#b00020' }]}>
                            Status
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Status && errors.Status) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={statusOption}
                            labelField="label"
                            valueField="value"
                            maxHeight={statusOption.length > 1 ? 250 : 200}
                            search={statusOption.length > 1 ?true:false}
                            searchPlaceholder={'Select Status'}
                            value={values.Status}
                            onUpdateValue={item => {
                              setFieldValue('Status', item.value)
                              setShowDropDownactive(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Status && errors.Status)}>
                          {touched.Status && errors.Status}
                          </HelperText>
                        </View>
                      </View>
                    </View>
                      </View>
                      <View style={{ flexShrink: 1, flexDirection: "row" }} >
                      <Checkbox.Android
                        color= "#27B6CC"
                        name="IS_Event_Has_Legal_Permission"
                        status={values.IS_Event_Has_Legal_Permission ? 'checked' : 'unchecked'}
                        onPress={() => {
                          setFieldValue("IS_Event_Has_Legal_Permission", !values.IS_Event_Has_Legal_Permission);
                        }}
                      />
                      <Text style={{ marginTop: 10 }}>IS Event Has Legal Permission</Text>
                    </View>
                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                      <Checkbox.Android
                        color= "#27B6CC"
                        name="IS_Event_Required_Alchol"
                        status={values.IS_Event_Required_Alchol ? 'checked' : 'unchecked'}
                        onPress={() => {
                          setFieldValue("IS_Event_Required_Alchol", !values.IS_Event_Required_Alchol);
                        }}
                      />
                      <Text style={{ marginTop: 10 }}>IS Event Required Alchol</Text>
                    </View>
                      <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                        <Button style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                          Next
                        </Button>
                      </View>
                  </Card.Content>
                </Card>
              </View>

            )}
          </Formik>}
      </ScrollView>
    </Surface>
  );
};

const styles = StyleSheet.create({
  cardCircle: {
    width: (Platform.OS === 'web') ? "75%" : "95%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2%",
    marginBottom: "2%",
  }, datePickerStyle: {
    width: 350,
    height: 60,
    marginTop: 6,
  },
  containerone: {
    backgroundColor: 'white',
    paddingTop: 15
  },
  itemContainer: {
    justifyContent: 'flex-end',
    flex: 1
  },
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    marginBottom: 30,
  },
  editor: {
    backgroundColor: "black",
    borderColor: "black",
    borderWidth: 1,
  },
  rich: {
    minHeight: 130,
    // flex: 1,
  },
  richBar: {
    height: 50,
    backgroundColor: "#F5FCFF",
  },
  text: {
    fontWeight: "bold",
    fontSize: 18,
  },
  tib: {
    textAlign: "center",
    color: "#515156",
  },
  a: {
    fontWeight: "bold",
    color: "purple",
  },
  div: {
    fontFamily: "monospace",
  },
  p: {
    fontSize: 30,
  }, container: {
    backgroundColor: 'white',
    paddingTop: 15
  },
  dropdown: {
    height: 58,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    color: 'black',
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  scrollView: {
    height: '20%',
    width: '80%',
    margin: 20,
    alignSelf: 'center',
    padding: 20,
    borderWidth: 5,
    borderRadius: 5,
    borderColor: 'black',
    backgroundColor: 'lightblue'
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    paddingBottom: 50
  }
});
export default EditUserEventspagetwo;