import 'react-native-gesture-handler';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimensions, ScrollView, Text, View } from 'react-native';
import Statistics from '.';
import { statisticsProfit } from '../../../state/actions/reports/statisticsActions';
import { Button, Surface } from 'react-native-paper';
import Header from '../../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';

function ProfitStatistics(props: any) {
    //navigation property is used for navigation between screens
    const { navigation } = props;
    //API dispatch
    const dispatch = useDispatch();

    //statProfit contains the data from  GET API using state call
    const statProfit = useSelector(state => state.statistics.Profit.data);
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    //Static formData
    React.useEffect(() => {
        let formData = {
            "Partner_Details_Id": EmailID?.Partner_Details_Id
        }
        dispatch(statisticsProfit(formData))
    }, []);

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <View>
                <Button style={{ width: 160, marginLeft: dimensions <= 500 ? "-5%" : "9%" }} color="#27B6CC" icon="keyboard-backspace" onPress={() => navigation.navigate('ReportsGet')} >
                    <Text style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>Profit</Text>
                </Button>
            </View>
            <ScrollView>
                <ScrollView horizontal={dimensions <= 1000 ? true : false}>
                    <View style={{ margin: "auto", flexDirection: 'column' }}>
                        <View style={{ margin: 10 }}>
                            <Statistics statistic={statProfit} />
                        </View>
                    </View>
                </ScrollView>
            </ScrollView>
        </Surface>
    );
}


export default ProfitStatistics;