import axios from "axios";
import config from './../config'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getBiddingProductsCart } from "./biddingproductActions";
import Navigation from "../../../navigation";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
const getDataAsync = async () => {
    try {
        const jsonValue = await AsyncStorage.getItem('cart');
        return jsonValue != null ? JSON.parse(jsonValue) : [];
    } catch (e) {
    }
}
const cudheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
export const createBids = (values:any, formCallbackResponse:any,navigation:any) => {
    return function (dispatch) {
        axios.post(`${config.url}/B2B_Bidding_Partner_Request/Create`, values, {
            headers: headers,
        })
            .then(response => {
                let data = response.data;
                if(data.Response_Code=='200'){
                    navigation.navigate("Biddingproducts", { screen: 'Biddingproducts' })
                }
                if (data.Availabitly_Stock === "0") {
                    formCallbackResponse(data.UI_Display_Message);
                }
                else {
                    formCallbackResponse(data.UI_Display_Message);
                }

            })
            .catch(error => {
            })
    }
}
export const cartDeletecreate = (values:any, formCallbackResponse:any) => {
    return function (dispatch) {
        axios.post(`${config.url}/B2B_Bidding_Product_Cart/Cart_Delete`, values, {
            headers: headers,
        })
            .then(response => {
                let data = response.data;
                if (data.Availabitly_Stock === "0") {
                    formCallbackResponse(data.UI_Display_Message);
                }
                else {
                    formCallbackResponse(data.UI_Display_Message);
                }

            })
            .catch(error => {
            })
    }
}

export const placeCartBids = (values:any, formCallbackResponse:any,navigation:any,ID:any,toast:any) => {
    return function (dispatch) {
        axios.post(`${config.url}/B2B_Bidding_Product_Cart/Create`, values, {
            headers: headers,
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Code == "200") {
                    formCallbackResponse(true,data.UI_Display_Message);
                     navigation.navigate("CartOfBids",{id:true})
                     toast.show({
                        message:'cart added successfully.', type: 'info', duration: 3000, position: 'top'
                      });
                }
                else {
                    // dispatch(getBiddingProductsCart(formCallbackResponse));
                    toast.show({
                        message: data.UI_Display_Message, type: 'info', duration: 3000, position: 'top'
                      });
                    formCallbackResponse(false,data.UI_Display_Message);
                }

            })
            .catch(error => {
            })
    }
}

export const updateCartBids = (values:any, formCallbackResponse:any,Email_Id:any) => {
    return function (dispatch) {
        axios.put(`${config.url}/B2B_Bidding_Product_Cart/Update`, values, {
            headers: headers,
        })
            .then(response => {
                let data = response.data;
                if (data.Availabitly_Stock === "0") {
                    formCallbackResponse(data.UI_Display_Message);
                   
                }
                else {
                    dispatch(getBiddingProductsCart(Email_Id,formCallbackResponse));
                    formCallbackResponse(data.UI_Display_Message);
                    
                }
            })
            .catch(error => {
            })
    }
}

export const deleteCartBids = (formData: any, toast: any) => async dispatch => {
    try {
      let { data, status } = await axios.post(`${config.url}/B2B_Bidding_Product_Cart/Delete`, formData, {
  
        headers: headers
      });
      if (data) {
        const formValue = {
          Records_Filter: 'FILTER',
          Buyer_Partner_Detail_Id: formData.Partner_Details_Id
        };
        dispatch(getBiddingProductsCart(formValue, toast));
        toast.show({
          message: 'Cart Item deleted successfully.', type: 'info', duration: 3000, position: 'top'
        });
      }
    } catch (err) {
    }
  };






  export const createbiddingComments = (values:any, formCallbackResponse:any,MailID:any) => {
    return function (dispatch) {
        axios.post(`${config.nodecudurl}/B2B_Bidding_Product_Comments/Create`, values, {
            headers: cudheaders,
        })
            .then(response => {
                let data = response.data;
                if (data.Availabitly_Stock === "0") {
                    formCallbackResponse(data.UI_Display_Message);
                }
                else {
                    formCallbackResponse(data.UI_Display_Message);
                }

            })
            .catch(error => {
            })
    }
}




export const cartUpdate = (data) => {
    return {
        type: GET_CART_ITEMS,
        data: data
    }
}






