import React, { useState } from 'react';
import EsiSearchDropdown from '../searchable-dropdown';
import { StyleSheet } from 'react-native';
import axios from 'axios';
import config from '../../../state/actions/config';
import shopperconfig from '../../../state/actions/shoppers/shopperconfig';
import { useSelector } from 'react-redux';
// import esiColor from '../../../constants/Colors';
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
const EsiCountry = (props: any) => {
  // props variable consist all react text field properties along with valueUpdate function.
  // valueUpdate function will return onChange value of field.

  // valueUpdate function is a custom property as it dont have in react native textfield properties.
  const { valueUpdate, ...rest } = props
  const [country, setCountry] = useState();
  React.useEffect(() => {
    let country = { "Filter_Type": "COUNTRY" }
    getCountry(country)
  }, [])
  const getCountry = (country: any) => {
    axios.post(`${shopperconfig.nodePartnerUrl}/Country_State_City/Get`, country,
      {
        headers: nodeheaders
      })
      .then(response => {
        setCountry(response?.data?.results[0]?.country);
      })
      .catch(error => {
      })
  }
  const esiColor = useSelector(state => state.theme);

  const styles = StyleSheet.create({
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.BackgroundColor
  
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
  
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    dropdown: {
      height: 58,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
  })

  return (
    <EsiSearchDropdown
      {...props}
      style={[styles.dropdown, props.styles, { borderColor: props.error ? '#b00020' : 'gray' }]}
      // data param here is used to assign the data from the function values that will be provided.
      data={country}
      // Providing styles for placeholder
      placeholderStyle={styles.placeholderStyle}
      // Providing styles for selectedText
      selectedTextStyle={styles.selectedTextStyle}
      // Providing styles for inputSearch
      inputSearchStyle={styles.inputSearchStyle}
      // Providing styles for icon
      iconStyle={styles.iconStyle}
      // valueField is used to get the value
      valueField={props.valueField}
      // labelField is used to get the label
      labelField={props.labelField}
      // search is used to get the value from the dropdown
      search={props.search}
      searchPlaceholder={props.searchPlaceholder}
      maxHeight={180}
      dropdownPosition="auto"
    />

  );
};

export default EsiCountry;

