import {
  SBCPARTNERCITY_REQUEST,
  SBCPARTNERCITY_SUCCESS,
  SBCPARTNERCITY_FAIL,

  SBCPARTNERCITY_GET_REQUEST,
  SBCPARTNERCITY_GET_SUCCESS,
  SBCPARTNERCITY_GET_FAIL,

  SBCPARTNERCITY_CREATE_REQUEST,
  SBCPARTNERCITY_CREATE_SUCCESS,
  SBCPARTNERCITY_CREATE_FAIL,

  SBCPARTNERCITY_UPDATE_REQUEST,
  SBCPARTNERCITY_UPDATE_SUCCESS,
  SBCPARTNERCITY_UPDATE_FAIL,

  SBCPARTNERCITY_DELETE_REQUEST,
  SBCPARTNERCITY_DELETE_SUCCESS,
  SBCPARTNERCITY_DELETE_FAIL,

  SBCPARTNER_CITIES_REQUEST,
  SBCPARTNER_CITIES_SUCCESS,
  SBCPARTNER_CITIES_FAIL,

  SBCPARTNERCITY_FILTER,

} from '../actions/sbcpartnerCityAction'

const initialState = {

  sbcpartnercities: { all: [], error: '', isLoading: false },
  sbcpartnercity: { sbcpartnercity: {}, error: '', isLoading: false },

  sbcpartnercityCreate: { sbcpartnercity: {}, error: '', isLoading: false },
  sbcpartnercityUpdate: { sbcpartnercity: {}, error: '', isLoading: false },
  sbcpartnercityDelete: { sbcpartnercity: {}, error: '', isLoading: false },

  sbcpartcities: { cities: [], error: '', isLoading: false },

  sbcpartnercityFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

  switch (action.type) {
    case SBCPARTNERCITY_REQUEST:
      return { ...state, sbcpartnercities: { all: state.sbcpartnercities.all, error: '', isLoading: true } };
    case SBCPARTNERCITY_SUCCESS:
      return { ...state, sbcpartnercities: { all: action.payload, error: '', isLoading: false } };
    case SBCPARTNERCITY_FAIL:
      return { ...state, sbcpartnercities: { all: [], error: action.payload, isLoading: false } };

    case SBCPARTNERCITY_GET_REQUEST:
      return { ...state, sbcpartnercity: { sbcpartnercity: {}, error: '', isLoading: true } };
    case SBCPARTNERCITY_GET_SUCCESS:
      return { ...state, sbcpartnercity: { sbcpartnercity: action.payload, error: '', isLoading: false } };
    case SBCPARTNERCITY_GET_FAIL:
      return { ...state, sbcpartnercity: { sbcpartnercity: {}, error: action.payload, isLoading: false } };

    case SBCPARTNERCITY_CREATE_REQUEST:
      return { ...state, sbcpartnercityCreate: { sbcpartnercity: {}, error: '', isLoading: true } };
    case SBCPARTNERCITY_CREATE_SUCCESS:
      return { ...state, sbcpartnercityCreate: { sbcpartnercity: state.sbcpartnercityCreate.sbcpartnercity, error: '', isLoading: false } };
    case SBCPARTNERCITY_CREATE_FAIL:
      return { ...state, sbcpartnercityCreate: { sbcpartnercity: {}, error: action.payload, isLoading: false } };

    case SBCPARTNERCITY_UPDATE_REQUEST:
      return { ...state, sbcpartnercityUpdate: { sbcpartnercity: {}, error: '', isLoading: true } };
    case SBCPARTNERCITY_UPDATE_SUCCESS:
      return { ...state, sbcpartnercityUpdate: { sbcpartnercity: state.sbcpartnercityUpdate.sbcpartnercity, error: '', isLoading: false } };
    case SBCPARTNERCITY_UPDATE_FAIL:
      return { ...state, sbcpartnercityUpdate: { sbcpartnercity: {}, error: action.payload, isLoading: false } };

    case SBCPARTNERCITY_DELETE_REQUEST:
      return { ...state, sbcpartnercityDelete: { sbcpartnercity: {}, error: '', isLoading: true } };
    case SBCPARTNERCITY_DELETE_SUCCESS:
      return { ...state, sbcpartnercityDelete: { sbcpartnercity: action.payload, error: '', isLoading: false } };
    case SBCPARTNERCITY_DELETE_FAIL:
      return { ...state, sbcpartnercityDelete: { sbcpartnercity: {}, error: action.payload, isLoading: false } };

    case SBCPARTNER_CITIES_REQUEST:
      return { ...state, sbcpartcities: { cities: state.sbcpartcities.cities, error: '', isLoading: true }, };
    case SBCPARTNER_CITIES_SUCCESS:
      return { ...state, sbcpartcities: { cities: action.payload, error: '', isLoading: false } };
    case SBCPARTNER_CITIES_FAIL:
      return { ...state, sbcpartcities: { cities: [], error: action.payload, isLoading: false } };

    case SBCPARTNERCITY_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, sbcpartnercityFilter: { search: state.sbcpartnercityFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, sbcpartnercityFilter: { select: state.sbcpartnercityFilter.select, search: action.value } };
      }
      return res;

    default:
      return state;
  }
}