import {
  BIDDING_PRODUCTS_REQUEST,
  BIDDING_PRODUCTS_SUCCESS,
  BIDDING_PRODUCTS_FAIL,
  BIDDING_PRODUCT_AVAILABLE_UPDATE_REQUEST,
  BIDDING_PRODUCT_AVAILABLE_UPDATE_SUCCESS,
  BIDDING_PRODUCT_AVAILABLE_UPDATE_FAIL,


  BIDDING_PRODUCT_CREATE_REQUEST,
  BIDDING_PRODUCT_CREATE_SUCCESS,
  BIDDING_PRODUCT_CREATE_FAIL,
  BIDDING_PRODUCT_UPDATE_REQUEST,
  BIDDING_PRODUCT_UPDATE_SUCCESS,
  BIDDING_PRODUCT_UPDATE_FAIL,
  BIDDING_PRODUCT_FILTER,


} from '../actions/biddingProductsAction'

const initialState = {
  bidding_products: { all: [], error: '', isLoading: false },
  biddingproductavailability: { partner: {}, error: '', isLoading: false },
  biddingCreate: { bidding: {}, error: '', isLoading: false },
  biddingUpdate: { bidding: {}, error: '', isLoading: false },
  biddingFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case BIDDING_PRODUCTS_REQUEST:
      return { ...state, bidding_products: { all: state.bidding_products.all, error: '', isLoading: true } };
    case BIDDING_PRODUCTS_SUCCESS:
      return { ...state, bidding_products: { all: action.payload, error: '', isLoading: false } };
    case BIDDING_PRODUCTS_FAIL:
      return { ...state, bidding_products: { all: [], error: action.payload, isLoading: false } };
    case BIDDING_PRODUCT_AVAILABLE_UPDATE_REQUEST:
      return { ...state, biddingproductavailability: { partner: {}, error: '', isLoading: true } };
    case BIDDING_PRODUCT_AVAILABLE_UPDATE_SUCCESS:
      return { ...state, biddingproductavailability: { partner: state.biddingproductavailability.partner, error: '', isLoading: false } };
    case BIDDING_PRODUCT_AVAILABLE_UPDATE_FAIL:
      return { ...state, biddingproductavailability: { partner: {}, error: action.payload, isLoading: false } };

    case BIDDING_PRODUCT_CREATE_REQUEST:
      return { ...state, biddingCreate: { bidding: {}, error: '', isLoading: true } };
    case BIDDING_PRODUCT_CREATE_SUCCESS:
      return { ...state, biddingCreate: { bidding: state.biddingCreate.user, error: '', isLoading: false } };
    case BIDDING_PRODUCT_CREATE_FAIL:
      return { ...state, biddingCreate: { bidding: {}, error: action.payload, isLoading: false } };
    case BIDDING_PRODUCT_UPDATE_REQUEST:
      return { ...state, biddingUpdate: { bidding: {}, error: '', isLoading: true } };
    case BIDDING_PRODUCT_UPDATE_SUCCESS:
      return { ...state, biddingUpdate: { bidding: state.biddingUpdate.bidding, error: '', isLoading: false } };
    case BIDDING_PRODUCT_UPDATE_FAIL:

    case BIDDING_PRODUCT_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, biddingFilter: { search: state.biddingFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, biddingFilter: { select: state.biddingFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}


