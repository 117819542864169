import React, { useState } from 'react';
import data from './data';
import { ScrollView, StyleSheet, View } from 'react-native';
import ProductCard from './ProductCard';
import { Surface, Text, Button } from 'react-native-paper';
import { useSelector } from 'react-redux';
import Header from '../header';

const styles = StyleSheet.create({
  productCard: {
    height: '100%'
  }
});

const FarmerPartnerProductMain = (props: any) => {
  const { navigation } = props;
  const [products] = useState(data);
  const EmailID = useSelector(state => state.auth?.user);
  const esiColor = useSelector(state => state.theme);
    return (
      <Surface style={{ flex: 1,backgroundColor:esiColor.BackgroundColor }}>
        <Header navigation={navigation} />
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10,color:esiColor.brandFontColor }}>
            Product Management
          </Text>
        </View>
        <ScrollView>
        <View style={{ width: 50, alignItems: "flex-start", flexShrink: 1 }}>
              <Button textColor={esiColor.globalButtonColor} icon="keyboard-backspace" style={{ width: 150 ,justifyContent: 'flex-start'}} onPress={() => navigation.navigate('Farmer_Dashboard')}>
                Go Back
              </Button>
        </View>
          <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
            {products.map(product => (
              <View key={product.id}>
                <ProductCard
                  style={styles.productCard} esiColor={esiColor}
                  product={product} navigation={navigation}
                />
              </View>
            ))}
          </View>
        </ScrollView>
      </Surface>
    );  
};

export default FarmerPartnerProductMain;