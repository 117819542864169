import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dimensions, Image, Platform, TouchableOpacity, View } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { Divider } from 'react-native-elements';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from 'react-redux';
import { orderDateandCount } from '../../../state/actions/dynamicCountActions';
import isEmpty from '../../../state/validations/is-empty';

const ProductCard = ({ product,esiColor, navigation, ...rest }) => {
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const dispatch = useDispatch();
  const dynamicvalue = useSelector(state => state.corporatedateandcount.orderdateandcounts.all);
   const EmailID = useSelector(state => state.auth?.user);

  useEffect(() => {

    if(product.title==="Order History" ){
        product.total=dynamicvalue?.Order_History?.Order_History_Count;
        product.createdAt=dynamicvalue?.Order_History?.Order_History_Date;
      }else if(product.title==="Order Cancel & Return"){
        product.total=dynamicvalue?.Orders?.Orders_Count;
        product.createdAt=dynamicvalue?.Orders?.Orders_Date;
      }
      //else if(product.title==="Reviews & Ratings"){
      //   product.total=dynamicvalue?.Partner_reviews?.Partner_reviews_Count;
      //   product.createdAt=dynamicvalue?.Partner_reviews?.Partner_reviews_Date;
      // }else if(product.title==="Ticketing Management"){
      //   product.total=dynamicvalue?.Tickets?.Tickets_Count;
      //   product.createdAt=dynamicvalue?.Tickets?.Tickets_Date;
      // }else if(product.title==="Transaction History"){
      //   product.total=dynamicvalue?.Transactions?.Transactions_Count;
      //   product.createdAt=dynamicvalue?.Transactions?.Transactions_Date;
      // }else if(product.title==="Payments & Settlements"){
      //   product.total=parseInt(dynamicvalue?.Partner_payment_settlement?.Partner_payment_settlement_Count)+parseInt(dynamicvalue?.User_payment_settlement?.User_payment_settlement_Count);
      //   product.createdAt=dynamicvalue?.Partner_payment_settlement?.Partner_payment_settlement_Date;
      // }else if(product.title==="Order Shipping"){
      //   product.total=dynamicvalue?.Shipping?.Shipping_Count;
      //   product.createdAt=dynamicvalue?.Shipping?.Shipping_Date;
      // }

  }, [dynamicvalue]);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  return (
    <View style={{ flexDirection: 'column', flex: 1, flexWrap: 'wrap', padding: 5 }}>
      <Card style={{
        borderRadius: 10, flex: 1, padding: 8, backgroundColor: esiColor.CBColor, margin: 10, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, elevation: 10, height: 370, maxHeight: 500,
        maxWidth: 350, width: Platform.OS === 'ios' ? dimensions <= 520 ? 370 : 370 : 160 && Platform.OS === 'web' ? dimensions <= 520 ? 300 : 350 : 160 && Platform.OS === 'android' ? 330 : 350,
      }}>
        <Card.Content>
          <View style={{ alignItems: 'center', justifyContent: "center", margin: 5, height: 270, flexWrap: 'wrap' }}>
            <TouchableOpacity onPress={() => { navigation.navigate(product.link) }}>
              <View style={{ margin: 10, alignSelf: 'center', flexWrap: 'wrap', height: 58 }}>
                <Image style={{ width: 58, height: 58, resizeMode: 'contain' }} source={product.media} />
              </View>
              <View style={{ justifyContent: 'center', margin: 4, height: 25 }}>
                <Text style={{ fontWeight: 'bold', fontSize: 20 ,color: esiColor.brandFontColor }}>{product.title}</Text>
              </View>
            </TouchableOpacity>
            <View style={{ height: 130 }}>
              <Text style={{ color: esiColor?.Text }}>{product.description}</Text>
            </View>
          </View>
        </Card.Content>
        <Divider orientation="vertical" width={5} />
        <View style={{ flexDirection: 'row', margin: 10, flex: 1, flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column' }}>
            <MaterialCommunityIcons name="clock-time-four-outline" size={24} color={esiColor.SIconColor} />
          </View >
          <View style={{ flexDirection: 'column', flex: 2, margin: 5 ,flexWrap: 'wrap'}}>
          <Text style={{ color: esiColor?.Text }}>  {product.createdAt}</Text>
          </View>
          <View style={{ flexDirection: 'column', flex: 1, marginVertical: 6, alignItems: 'flex-end' }}>
            <Text style={{ color: esiColor?.Text }}>{product.total}</Text>
          </View>
        </View>
      </Card>
    </View>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;