import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';

export const SBCPARTNERCITYDEAL_REQUEST = "SBCPARTNERCITYDEAL_REQUEST";
export const SBCPARTNERCITYDEAL_SUCCESS = "SBCPARTNERCITYDEAL_SUCCESS";
export const SBCPARTNERCITYDEAL_FAIL = "SBCPARTNERCITYDEAL_FAIL";

export const SBCPARTNERCITYDEAL_GET_REQUEST = "SBCPARTNERCITYDEAL_GET_REQUEST";
export const SBCPARTNERCITYDEAL_GET_SUCCESS = "SBCPARTNERCITYDEAL_GET_SUCCESS";
export const SBCPARTNERCITYDEAL_GET_FAIL = "SBCPARTNERCITYDEAL_GET_FAIL";

export const SBCPARTNERCITYDEAL_CREATE_REQUEST = "SBCPARTNERCITYDEAL_CREATE_REQUEST";
export const SBCPARTNERCITYDEAL_CREATE_SUCCESS = "SBCPARTNERCITYDEAL_CREATE_SUCCESS";
export const SBCPARTNERCITYDEAL_CREATE_FAIL = "SBCPARTNERCITYDEAL_CREATE_FAIL";

export const SBCPARTNERCITYDEAL_UPDATE_REQUEST = "SBCPARTNERCITYDEAL_UPDATE_REQUEST";
export const SBCPARTNERCITYDEAL_UPDATE_SUCCESS = "SBCPARTNERCITYDEAL_UPDATE_SUCCESS";
export const SBCPARTNERCITYDEAL_UPDATE_FAIL = "SBCPARTNERCITYDEAL_UPDATE_FAIL";

export const SBCPARTNERCITYDEAL_DELETE_REQUEST = "SBCPARTNERCITYDEAL_DELETE_REQUEST";
export const SBCPARTNERCITYDEAL_DELETE_SUCCESS = "SBCPARTNERCITYDEAL_DELETE_SUCCESS";
export const SBCPARTNERCITYDEAL_DELETE_FAIL = "SBCPARTNERCITYDEAL_DELETE_FAIL";

export const SBCPARTNERCITY_DEALS_REQUEST = "SBCPARTNERCITY_DEALS_REQUEST";
export const SBCPARTNERCITY_DEALS_SUCCESS = "SBCPARTNERCITY_DEALS_SUCCESS";
export const SBCPARTNERCITY_DEALS_FAIL = "SBCPARTNERCITY_DEALS_FAIL";
export const SBC_PARTNER_CITY_DEAL_REQUEST = "SBC_PARTNER_CITY_DEAL_REQUEST";
export const SBC_PARTNER_CITY_DEAL_SUCCESS = "SBC_PARTNER_CITY_DEAL_SUCCESS";
export const SBC_PARTNER_CITY_DEAL_FAIL = "SBC_PARTNER_CITY_DEAL_FAIL";

export const SBCPARTNERCITYDEAL_FILTER = "SBCPARTNERCITYDEAL_FILTER";

/*=============================================================
                  Get All SBCPARTNERCITYDEAL Action
===============================================================*/

const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}
export const getSBCPartnerCityDealAction = (formData: any, pagination = {}, callBackGetData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: SBC_PARTNER_CITY_DEAL_REQUEST
        });
        meeapi.post(urlGenarator(
            `/Partner_Service_City/PSC_Deals_Get`, pagination),
            formData,
        )
            .then(response => {
                let data = response.data;
                if (data.Is_Data_Exist === '0') {
                    dispatch({
                        type: SBC_PARTNER_CITY_DEAL_SUCCESS,
                        payload: data
                    });
                } else {
                    callBackGetData(data.results)
                    dispatch({
                        type: SBC_PARTNER_CITY_DEAL_SUCCESS,
                        payload: data.results
                    });
                }
            })
    } catch (err) {
        dispatch({
            type: SBC_PARTNER_CITY_DEAL_FAIL,
            payload: err
        });
    }
};
export const getAllSBCPartnerCityDealAction = (formData: any, pagination = {}) => async dispatch => {

    try {
        dispatch({
            type: SBCPARTNERCITYDEAL_REQUEST
        });
        meeapi.post(urlGenarator(
            `/Partner_Service_City/PSC_Deals_Get`, pagination),
            formData,
        )
            .then(response => {
                let data = response.data;
                if (data.Is_Data_Exist === '0') {
                    dispatch({
                        type: SBCPARTNERCITYDEAL_SUCCESS,
                        payload: []
                    });
                } else {
                    dispatch({
                        type: SBCPARTNERCITYDEAL_SUCCESS,
                        payload: data
                    });
                }
            })
    } catch (err) {
        dispatch({
            type: SBCPARTNERCITYDEAL_FAIL,
            payload: err
        });
    }
};


/*=============================================================
                  SBCPARTNERCITYDEAL Create Action
===============================================================*/

export const createSBCPartnerCityDealAction = (formData: any, navigation: any, toast: any, partnercityid: any, partnerid: any, partnercityname: any, partnername: any, cityid: any) => async dispatch => {
    dispatch({
        type: SBCPARTNERCITYDEAL_CREATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Partner_Service_City/Deals_Create`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SBCPARTNERCITYDEAL_CREATE_SUCCESS
                });
                navigation.navigate('SBCPartnercitydealGet', { partnercityid: partnercityid, partnerid: partnerid, partnercityname: partnercityname, partnername: partnername, cityid: cityid })
                toast.show({ message: 'Partner city deal created successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    "City_Name": "",
                    "Partner_Details_Id": formData.Partner_Details_Id,
                    "search": "",
                    "City_Id": "",
                    "Deal_Id": "",
                    "Partner_Name": "",
                    "Partner_Service_City_Deal_Id": "",
                    "Partner_Service_City_Id": formData.Partner_Service_City_Id,
                    "Records_Filter": "FILTER",
                    "Status": ""
                };
                const pagination = { PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_DEAL_ID", SortOrder: "DESC" };
                dispatch(getAllSBCPartnerCityDealAction(formValue, pagination))
            } else {
                const errors = {};
                dispatch({
                    type: SBCPARTNERCITYDEAL_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SBCPARTNERCITYDEAL_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


/*=============================================================
                  SBCPARTNERCITYDEAL Update Action
===============================================================*/

export const updateSBCPartnerCityDealAction = (formData: any, navigation: any, toast: any, partnercityid: any, partnerid: any, partnercityname: any, partnername: any, cityid: any) => async dispatch => {
    dispatch({
        type: SBCPARTNERCITYDEAL_UPDATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Partner_Service_City/Deals_Update`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SBCPARTNERCITYDEAL_UPDATE_SUCCESS
                });
                navigation.navigate('SBCPartnercitydealGet', { partnercityid: partnercityid, partnerid: partnerid, partnercityname: partnercityname.partnercityname, partnername: partnername, cityid: cityid })
                toast.show({ message: 'Partner city deal updated successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    "City_Name": "",
                    "Partner_Details_Id": formData.Partner_Details_Id,
                    "search": "",
                    "City_Id": "",
                    "Deal_Id": "",
                    "Partner_Name": "",
                    "Partner_Service_City_Deal_Id": "",
                    "Partner_Service_City_Id": formData.Partner_Service_City_Id,
                    "Records_Filter": "FILTER",
                    "Status": ""
                };
                const pagination = { PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_DEAL_ID", SortOrder: "DESC" };
                dispatch(getAllSBCPartnerCityDealAction(formValue, pagination))

            } else {
                const errors = {};
                dispatch({
                    type: SBCPARTNERCITYDEAL_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
           dispatch({
                type: SBCPARTNERCITYDEAL_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

/*=============================================================
                  SBCPARTNERCITYDEAL Delete
===============================================================*/

export const deleteSBCPartnerCityDealAction = (formData: any, toast: any) => async dispatch => {
    try {
        let { data, status } = await axios.post(`${config.nodecudurl}/Partner_Service_City/Deals_Delete`, formData, {
            headers: cudheaders
        });
        if (data) {
            toast.show({ message: 'Partner city deal deleted successfully.', type: 'info', duration: 3000, position: 'top' });
            const formValue = {
                "City_Name": "",
                "Partner_Details_Id": formData.Partner_Details_Id,
                "search": "",
                "City_Id": "",
                "Deal_Id": "",
                "Partner_Name": "",
                "Partner_Service_City_Deal_Id": "",
                "Partner_Service_City_Id": formData.Partner_Service_City_Id,
                "Records_Filter": "FILTER",
                "Status": ""
            };
            const pagination = { PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_DEAL_ID", SortOrder: "DESC" };
            dispatch(getAllSBCPartnerCityDealAction(formValue, pagination))

        }
    } catch (err) {
    }
};

/*=============================================================
                  Get All deals Action
===============================================================*/
export const getAllDeals = (formDatadeal: any, pagination = {}) => async dispatch => {
    try {
        dispatch({
            type: SBCPARTNERCITY_DEALS_REQUEST
        });
         meeapi.post(urlGenarator(`/Deals/Get`, pagination),
          formDatadeal,
          ).then(response => { 
            let data = response.data; 
            if (data.Is_Data_Exist === '0')
             {
                 dispatch({ type: SBCPARTNERCITY_DEALS_SUCCESS, payload: [] });
                 } else { 
                    dispatch({ type: SBCPARTNERCITY_DEALS_SUCCESS, payload: data }); 
                }
             })
    } catch (err) { 
        dispatch({ type: SBCPARTNERCITY_DEALS_FAIL, payload: err });
     }
};




// Filter 
export const SBCPartnerCitydealFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: SBCPARTNERCITYDEAL_FILTER,
            fType: fType,
            value: value,
        });
    }
}

//Search
export const resetFilter = () => {
    return function (dispatch: any) {
        dispatch({
            type: SBCPARTNERCITYDEAL_FILTER,
            fType: "search",
            value: "",
        });
    }
}