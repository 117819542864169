import React, { useState } from "react";
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, Portal, IconButton, DataTable, Button, Searchbar, Surface, DefaultTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../state/validations/is-empty'
import 'react-native-gesture-handler'
import Header from "../auth/components/header";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";
import { getAllshopperPartnerAction } from "../../state/actions/shoppers/shopersAction";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from 'react-native-vector-icons/FontAwesome';

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Discounted_Products_Id',
    label: 'Discounted Products ID'
  },
  {
    value: 'Product_Id',
    label: 'Product ID'
  },
  {
    value: 'Partner_Details_Id',
    label: 'Partner Details ID'
  },
  {
    value: 'Created_By',
    label: 'Created By'
  },
];
const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const ShoppersownGet = (props: any) => {
  const { navigation } = props;
  const dispatch = useDispatch()
  const esiColor = useSelector(state => state.theme);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const partnerproductid = props.route?.params?.partnerproductid;

  const [showDropDown, setShowDropDown] = React.useState(false);

  const [products, setProducts] = useState([]);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const shopperpartnersGet = useSelector(state => state.shoppers.shopperpartners.all);
  let allshopperpartnersGet = shopperpartnersGet?.results;
  const [shopperpartnersMenu, setShopperpartnersMenu] = React.useState('All')
  const [shopperpartnersSearch, setShopperpartnersSearch] = React.useState('');
  // let shopperpartnersMenu = useSelector(state => state.shoppers.shopperpartnerFilter.select);
  // let shopperpartnersSearch = useSelector(state => state.shoppers.shopperpartnerFilter.search);

  const EmailID = useSelector(state => state.auth?.user);

  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > shopperpartnersGet?.Pagination?.TotalCount ? shopperpartnersGet?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const shopperspartnersid = props.route?.params?.shoppersproductparams;
  const [productId, setProductID] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [shopperproduct, setData] = React.useState();
  const [pageOld, setPageOld] = React.useState(false);
  const [webpage, setwebPage] = React.useState(1);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      productCall();
    }
  }, [pagination])
  const [loading, setIsLoading] = React.useState(false);
  const productCall = () => {
    setIsLoading(true);
    let formData = {
      "search": '',
      "Discounted_Product_Id": "",
      "search_by_filter": shopperpartnersMenu,
      "Max_Discount": "",
      "Min_Discount": "",
      "Partner_Details_Id": EmailID.Partner_Details_Id,
      "Product_Id": shopperspartnersid,
      "Records_Filter": "FILTER",
      "Status": ""
    };
    dispatch(getAllshopperPartnerAction(formData, pagination))
  };
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setwebPage(1)
    setPagination({ PageNo: Number(1), PageSize: e, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" })
  }
  const DynamicPageIncrease = (e) => {
    setwebPage(e < shopperpartnersGet?.Pagination?.TotalPages ? e + Number(1) : shopperpartnersGet?.Pagination?.TotalPages)
    setPagination({ PageNo: e == shopperpartnersGet?.Pagination?.TotalPages ? shopperpartnersGet?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" });
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" });
  }


  const hideDialog = () => setVisible(false);
  const handleClickadd = () => {
    props.navigation.navigate('AddownShopperProducts', { shoppersproducts: shopperspartnersid, partnerproductid: partnerproductid })
  };

  const handleClickedit = async (allshopperpartnersGet) => {
    props.navigation.navigate('EditownShoppersProduct', { discountedproductid: allshopperpartnersGet.Discounted_Products_Id, partnerid: allshopperpartnersGet.Partner_Details_Id })
  };
  React.useEffect(() => {
    if (shopperspartnersid !== productId) {
      const formData = {
        "search": '',
        "search_by_filter": shopperpartnersMenu,
        "Discounted_Product_Id": "",
        "Max_Discount": "",
        "Min_Discount": "",
        "Partner_Details_Id": EmailID.Partner_Details_Id,
        "Product_Id": shopperspartnersid,
        "Records_Filter": "FILTER",
        "Status": ""
      };
      setProductID(shopperspartnersid)
      dispatch(getAllshopperPartnerAction(formData, pagination))
    }
    if (!isEmpty(allshopperpartnersGet)) {
      setProducts(allshopperpartnersGet);
    } else {
      setProducts([]);
    }


  }, [productId, shopperspartnersid]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" }
    let formData = {
      "search": '',
      "search_by_filter": shopperpartnersMenu,
      "Discounted_Product_Id": "",
      "Max_Discount": "",
      "Min_Discount": "",
      "Partner_Details_Id": EmailID.Partner_Details_Id,
      "Product_Id": shopperspartnersid,
      "Records_Filter": "FILTER",
      "Status": ""
    };
    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getAllshopperPartnerAction(formData, pagecall))
  };
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall()
    wait(2000).then(() => setRefreshing(false));
  }, []);

  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  //sorting
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Discounted_Products_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleChange = itemValue => {
    setShopperpartnersMenu(itemValue)
  };
  const handleSearch = value => {
    const formData = {
      "search": value,
      "search_by_filter": shopperpartnersMenu,
      "Discounted_Product_Id": "",
      "Max_Discount": "",
      "Min_Discount": "",
      "Partner_Details_Id": EmailID.Partner_Details_Id,
      "Product_Id": shopperspartnersid,
      "Records_Filter": "FILTER",
      "Status": ""
    };
    setShopperpartnersSearch(value)
    dispatch(getAllshopperPartnerAction(formData, pagination))
  };

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const styles = StyleSheet.create({
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 220
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'left',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 240
    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
    },
    rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
    },
    // rowsPerPagePickers: {
    //   margin: 10,
    //   width: 70,
    //   height: 40,
    //   borderRadius: 10,
    //   borderColor: 'gray',
    //   borderWidth: 0.5,
    //   paddingHorizontal: 8,
    // },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      // backgroundColor: esiColor.SIconColor
    },
    paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      // backgroundColor: esiColor.SIconColor
    },
    pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 1 }}>
          <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
            <IconButton
              color={esiColor.GBFColor}
              icon="close"
              size={20}
              onPress={() => setVisible(!visible)}
            />
          </View>
          <Dialog.Title style={{ fontSize: 20, marginTop: -35, fontWeight: 'bold', textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>Shoppers Product View</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Dialog.Content style={{ marginLeft: -30 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discounted Products ID</Text></View><View style={{ flex: 1 }}><Text style={{ color: esiColor.GBFColor }}>{shopperproduct?.Discounted_Products_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Details ID</Text></View><View style={{ flex: 1 }}><Text style={{ color: esiColor.GBFColor }}>{shopperproduct?.Partner_Details_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View><View style={{ flex: 1 }}><Text style={{ color: shopperproduct?.Status === "Active" ? esiColor.GBFColor : "red" }}>{shopperproduct?.Status} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Max Quantity</Text></View><View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{shopperproduct?.Max_Quantity} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Min Quantity</Text></View><View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{shopperproduct?.Min_Quantity} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product ID</Text></View><View style={{ flex: 1 }}><Text style={{ color: esiColor.GBFColor }}>{shopperproduct?.Product_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discount Start Date</Text></View><View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{shopperproduct?.Discount_Start_Date} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discount End Date</Text></View><View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{shopperproduct?.Discount_End_Date} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discount In Percentage</Text></View><View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{shopperproduct?.Discount_In_Percentage} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View><View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{shopperproduct?.Created_By} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View >
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('PartnerownProductsGet')}>
          Go Back
        </Button>
      </View>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      } >
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Shopper Own Products</Text>
        </View>

        {(permissions.Products_Partner_Products_Related_Products === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={(e) => { handleClickadd(e); }}>
              Add Own Shopper Products
            </Button>
          </View>}
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[styles.dropdown]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={filterOptions}
                containerStyle={styles.containerstyle}
                // search
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                // searchPlaceholder="Search..."
                value={shopperpartnersMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={shopperpartnersMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Discounted Products ID" value="Discounted_Products_Id" />
                <Picker.Item label="Product ID" value="Product_Id" />
                <Picker.Item label="Partner Details ID" value="Partner_Details_Id" />
                <Picker.Item label="Created By" value="Created_By" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              variant="outlined"
              onChangeText={(value) => handleSearch(value)}
              value={shopperpartnersSearch}
            />
          </View>
          {/* <View style={{ flex: dimensions <= 700 ? 0.1 : 1.6 }} />
            {(permissions.Products_Partner_Products_Related_Products === "write") &&
              (dimensions >= 700 ?
                <View style={{ flexShrink: 0.1, paddingTop: 8 }}>
                  <Button style={{ backgroundColor: '#27B6CC' }} mode="contained" onPress={(e) => { handleClickadd(e); }}>
                    Add Shoppers Products
                  </Button>
                </View>
                : null)}

            <View style={{ flex: dimensions <= 700 ? null : 0.07 }} /> */}
        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={styles.mainbox}>
            <Card>
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={styles.databeHeader}>
                  <DataTable.Title  active={orderBy === 'Discounted_Products_Id'} sortDirection={orderBy === 'Discounted_Products_Id' ? order : 'ascending'} onPress={createSortHandler('Discounted_Products_Id')} style={styles.title}><Text style={styles.titletext}>Discounted Products ID</Text></DataTable.Title>
                  <DataTable.Title  active={orderBy === 'Product_Id'} sortDirection={orderBy === 'Product_Id' ? order : 'ascending'} onPress={createSortHandler('Product_Id')} style={styles.title}><Text style={styles.titletext}>Product ID</Text></DataTable.Title>
                  <DataTable.Title  active={orderBy === 'Partner_Details_Id'} sortDirection={orderBy === 'Partner_Details_Id' ? order : 'ascending'} onPress={createSortHandler('Partner_Details_Id')} style={styles.title}><Text style={styles.titletext}>Partner Details ID</Text></DataTable.Title>
                  <DataTable.Title  active={orderBy === 'Created_By'} sortDirection={orderBy === 'Created_By' ? order : 'ascending'} onPress={createSortHandler('Created_By')} style={styles.title}><Text style={styles.titletext}>Created By</Text></DataTable.Title>
                  <DataTable.Title theme={customTheme} style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {allshopperpartnersGet?.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((ShoppersproductData, i) => (
                  <DataTable.Row style={styles.databeBox} key={i}>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{ShoppersproductData.Discounted_Products_Id}</Text> </DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{ShoppersproductData.Product_Id} </Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{ShoppersproductData.Partner_Details_Id}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{ShoppersproductData.Created_By}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>
                      {(permissions.Products_Partner_Products_Related_Products === "write") &&
                        <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClickedit(ShoppersproductData); }} />
                      }
                      <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(ShoppersproductData); setVisible(!visible) }} /></DataTable.Cell>
                  </DataTable.Row>
                ))
                }
                {isEmpty(allshopperpartnersGet) &&
                  <Text style={{ fontSize: 16, textAlign: 'center', color: esiColor.NoDataFound }}>
                    No records found.
                  </Text>
                }
                {Platform.OS !== "web" ?

                  <DataTable.Pagination
                    // theme={customTheme}
                    page={page}
                    numberOfPages={Math.ceil(shopperpartnersGet?.Pagination?.TotalCount / numberOfItemsPerPage)}
                    onPageChange={page => DynamicPage(to < shopperpartnersGet?.Pagination?.TotalCount ? page : 0)}
                    label={shopperpartnersGet?.Pagination?.TotalCount ? `${from + 1}-${to} of ${shopperpartnersGet?.Pagination?.TotalCount}` : '0-0'}
                    showFastPaginationControls={(Number(page) + Number(1)) > shopperpartnersGet?.Pagination?.TotalPages ? false : true}
                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    onItemsPerPageChange={onItemsPerPageChange}
                    selectPageDropdownLabel={'Rows per page'}
                  />
                  :
                  <View style={styles.rowsPerPageContainer}>
                    <Text style={{color: esiColor.Text}}>rows per page: </Text>
                    <Picker
                      style={styles.rowsPerPagePicker}
                      selectedValue={numberOfItemsPerPage}
                      onValueChange={(e) => onItemsPerPageChange(e)}
                    >
                      <Picker.Item label="5" value={5} />
                      <Picker.Item label="10" value={10} />
                      <Picker.Item label="20" value={20} />
                    </Picker>
                    <View style={styles.paginationContainer}>
                      <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={styles.paginationButton}>
                        <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                      <Text style={styles.pageInfoText}>
                        {shopperpartnersGet?.Pagination?.PageNo + ' of ' + shopperpartnersGet?.Pagination?.TotalPages}
                      </Text>
                      <TouchableOpacity onPress={e => DynamicPageIncrease(to < shopperpartnersGet?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={styles.paginationButton}>
                        <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                    </View>
                  </View>}
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}

export default ShoppersownGet;