import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateDealAction } from '../../../state/actions/dealAction';
import { Card, Text, Button, HelperText, Surface } from 'react-native-paper';
import { View, ScrollView, Dimensions, StyleSheet } from 'react-native';
import Header from '../../auth/components/header';
import EsiEditor from '../../../components/custom/richtext-editor/richtext-editor';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//StatusOption used to get the data from dropdown
const StatusOption = [
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  }
];

const EditDealConfig = (props: any) => {

  //toast for popup after successful API calls.
  const toast = useToast();
  //navigation is used for navigation between screens
  const { navigation } = props;
  //isSubmitted contains the data from Update API using state call
  const isSubmited = useSelector(state => state.deal.dealUpdate.isLoading);
  //data contains the values from previous form using state call
  const data = useSelector(state => state.deal.dealData.data);
  const EmailID = useSelector(state => state.auth?.user);

  //Description
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [descriptionvalue, setDescriptionValue] = React.useState(data.Deal_Description);
  const dispatch = useDispatch();

  //Dropdowns
  const [statusDropDown, setStatusDropDown] = useState("");

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  /*====================================
          Handler
  ====================================*/
  const values = {
    Deal_Id: data.Deal_Id,
    Title: data.Title,
    Type: data.Type,
    Partner_Details_Id: data.Partner_Details_Id,
    Partner_Name: data.Partner_Name,
    Brand_Id: data.Brand_Id,
    Category_Id: data.Category_Id,
    Category_Name:data.Category_Name,
    Start_Date: data.Start_Date,
    End_Date: data.End_Date,
    Discount_Type: data.Discount_Type,
    Discount: data.Discount,
    Discount_Label: data.Discount_Label,
    Cashback_Label: data.Cashback_Label,
    Esi_Cashback_Type: data.Esi_Cashback_Type,
    Esi_Cashback_Amount: data.Esi_Cashback_Amount,
    Mobile_Redirect_Url: data.Mobile_Redirect_Url,
    Web_Redirect_Url: data.Web_Redirect_Url,
    Deal_Description: data.Deal_Description,
    Image: data.Image,
    Verified_Deal: data.Verified_Deal > 0 ? true : false,
    Whats_Hot: data.Whats_Hot > 0 ? true : false,
    Exclusive: data.Exclusive > 0 ? true : false,
    Extra_Cashback: data.Extra_Cashback > 0 ? true : false,
    Is_Top_Deal: data.Is_Top_Deal > 0 ? true : false,
    Status: data.Status,
    Modified_By: EmailID.Email_Id,
  }
  //Form validation
  const Form_Validation = Yup.object().shape({
    Status: Yup.string().required('Status is required.')
  })

  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    values.Deal_Description = descriptionvalue;
    if (!values.Deal_Description || values.Deal_Description.replace(/(<([^>]+)>)/ig, '')?.trim().length == 0) {
      submitTest = false;
      errors.Deal_Description = "Description is required.";
    }
    else if (values.Deal_Description.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) {
      submitTest = false;
      errors.Deal_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
    }

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Verified_Deal) {
        formValues.Verified_Deal = 1;
      } else {
        formValues.Verified_Deal = 0;
      }
      if (values.Exclusive) {
        formValues.Exclusive = 1;
      } else {
        formValues.Exclusive = 0;
      }
      if (values.Whats_Hot) {
        formValues.Whats_Hot = 1;
      } else {
        formValues.Whats_Hot = 0;
      }
      if (values.Is_Top_Deal) {
        formValues.Is_Top_Deal = 1;
      } else {
        formValues.Is_Top_Deal = 0;
      }
      if (values.Extra_Cashback) {
        formValues.Extra_Cashback = 1;
      } else {
        formValues.Extra_Cashback = 0;
      }
      if (values.Discount === "") {
        formValues.Discount_Amount = 0;
      }
      if (values.Esi_Cashback_Type === "") {
        formValues.Esi_Cashback_Amount = 0;
      }
      dispatch(updateDealAction(formValues, navigation, toast));
    }
  }

  return (
    <Surface style={{ flex: 1 }}>
      <Header navigation={navigation} />
      <ScrollView>
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
          {(props) => (
            <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
              <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130 }} onPress={() => navigation.navigate('EditDealBasic', { data: data })}>
                  Go Back
                </Button>
              </View>
              <Card style={{
                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: '#D6D6D7'
              }}>
                <Card.Content>
                  <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                    Edit Deal
                  </Text>
                  <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    {/* Mobile Redirect URL input filed with validation*/}
                    <View style={{ flexShrink: 1, width: 650 }}>

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      </View>

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ width: 322, flexShrink: 1, paddingTop: 15 }}>
                          {/* Status input field with validation*/}
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                            Status
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={StatusOption}
                            maxHeight={StatusOption.length > 1 ? 250 : 200}
                            search={StatusOption.length > 1 ? true : false}
                            labelField="label"
                            valueField="value"
                            dropdownPosition="auto"
                            value={props.values.Status}
                            name="Status"
                            onFocus={() => setStatusDropDown(true)}
                            onBlur={() => setStatusDropDown(false)}
                            onUpdateValue={item => {
                              props.setFieldValue('Status', item.value)
                              setStatusDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                            <ErrorMessage name="Status" />
                          </HelperText>
                        </View>
                      </View>

                      <View>
                        <EsiEditor label={"Description"} value={descriptionvalue} onUpdateValue={(value) => { setDescriptionValue(value) }} />
                        <HelperText type="error" visible={(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Deal_Description && props.errors.Deal_Description}>
                          {(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Deal_Description && props.errors.Deal_Description}
                        </HelperText>
                      </View>

                    </View>
                  </View>

                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", flexShrink: 1 }}>
                    <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={props.handleSubmit}>
                      Save
                    </Button>
                  </View>

                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};


const styles = StyleSheet.create({
  label: {
    marginBottom: 30,
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    color: 'black',
    left: 22,
    top: 10,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  dropdown: {
    height: 58,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
});

export default EditDealConfig;