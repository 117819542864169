import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';
export const COUPON_DATA = "COUPON_DATA";
export const COUPONS_REQUEST = "COUPONS_REQUEST";
export const COUPONS_SUCCESS = "COUPONS_SUCCESS";
export const COUPONS_FAIL = "COUPONS_FAIL";

export const COUPONS_ACTIVE_REQUEST = "COUPONS_ACTIVE_REQUEST";
export const COUPONS_ACTIVE_FAIL = "COUPONS_ACTIVE_FAIL";
export const COUPON_GET_REQUEST = "COUPON_GET_REQUEST";
export const COUPON_GET_SUCCESS = "COUPON_GET_SUCCESS";
export const COUPON_GET_FAIL = "COUPON_GET_FAIL";
export const COUPON_CREATE_REQUEST = "COUPON_CREATE_REQUEST";
export const COUPON_CREATE_SUCCESS = "COUPON_CREATE_SUCCESS";
export const COUPON_CREATE_FAIL = "COUPON_CREATE_FAIL";
export const COUPON_UPDATE_REQUEST = "COUPON_UPDATE_REQUEST";
export const COUPON_UPDATE_SUCCESS = "COUPON_UPDATE_SUCCESS";
export const COUPON_UPDATE_FAIL = "COUPON_UPDATE_FAIL";
export const COUPONS_FILTER = "COUPONS_FILTER";

export const COUPON_PARTNERS_REQUEST = "COUPON_PARTNERS_REQUEST";
export const COUPON_PARTNERS_SUCCESS = "COUPON_PARTNERS_SUCCESS";
export const COUPON_PARTNERS_FAIL = "COUPON_PARTNERS_FAIL";
export const COUPON_BRANDS_REQUEST = "COUPON_BRANDS_REQUEST";
export const COUPON_BRANDS_SUCCESS = "COUPON_BRANDS_SUCCESS";
export const COUPON_BRANDS_FAIL = "COUPON_BRANDS_FAIL";
export const COUPON_CATEGORIES_REQUEST = "COUPON_CATEGORIES_REQUEST";
export const COUPON_CATEGORIES_SUCCESS = "COUPON_CATEGORIES_SUCCESS";
export const COUPON_CATEGORIES_FAIL = "COUPON_CATEGORIES_FAIL";



/*=============================================================
                  Add Coupon Action
===============================================================*/

const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
const cudheaders = config.nodeheadersCommon;
export const invalid_data = () => {
    return {
        type: RESET
    }
}

axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded';
const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}


export const createCouponAction = (formData: any, navigation: any, toast: any) => async dispatch => {
    dispatch({
        type: COUPON_CREATE_REQUEST
    });

    axios.post(`${config.nodecudurl}/Coupons/Create`, formData, {
        headers: cudheaders
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: COUPON_CREATE_SUCCESS
                });
                const formValues = {
                    "Partner_Details_Id": formData.Partner_Details_Id,
                    "Records_Filter": "FILTER",
                };
                const pagination = {
                    PageNo: 1,
                    PageSize: 5,
                    SortBy: "COUPON_ID",
                    SortOrder: "ASC"
                }
                dispatch(getAllCouponAction(formValues, pagination));
                toast.show({ message: 'Coupon created successfully.', type: 'info', duration: 3000, position: 'top' });
                navigation.navigate('CouponsGet');

            }
            else {
                dispatch({
                    type: COUPON_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: COUPON_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

/*=============================================================
                  Get All Coupon Action
===============================================================*/
export const getAllCouponAction = (formData: any, pagination = {}) => async dispatch => {
    try {
        dispatch({
            type: COUPONS_REQUEST
        });
        meeapi.post(urlGenarator(`/Coupons/Get`, pagination),
            formData,
        ).then(response => {
            let data = response.data;
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: COUPONS_SUCCESS,
                    payload: data
                });
            } else {
                dispatch({
                    type: COUPONS_SUCCESS,
                    payload: data
                });
            }
        })
    } catch (err) {
        dispatch({
            type: COUPONS_FAIL,
            payload: err
        });
    }
};

export const getBothCouponsAction = (formData: any, callBackData: any) => async dispatch => {
    try {
        dispatch({
            type: COUPON_PARTNERS_REQUEST
        });
        let { data } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Coupons/Get`,
            formData,
            {
                // Axios looks for the `auth` option, and, if it is set, formats a
                // basic auth header for you automatically.
                headers: nodeheaders
            }
        );
        if (data) {
            if (data.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            type: COUPONS_ACTIVE_FAIL,
            payload: err
        });
    }
};
export const getCouponsByIdAction = (formData: any, callBackData: any) => async dispatch => {
    try {
        dispatch({
            type: COUPONS_REQUEST
        });
        let { data } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Coupons/Coupon_Get`,
            formData,
            {
                // Axios looks for the `auth` option, and, if it is set, formats a
                // basic auth header for you automatically.
                headers: nodeheaders
            }
        );
        if (data) {
            if (data.Is_Data_Exist === "0") {
                callBackData([])
            } else {
                callBackData(data.results)
            }
        }
    } catch (err) {
        dispatch({
            type: COUPONS_FAIL,
            payload: err
        });
    }
};
/*=============================================================
                Coupon Image Upload API
===============================================================*/

export const uploadCouponImage = (
    formData,
    callBackUploadImage
) => async dispatch => {
    try {
        let { data } = await axios.post(
            `${config.url}/Coupons/Image_Upload?filename=timeimage&file_extension=png&filetype=Image&Coupon_Id=C-0000361`,
            formData,
            {
                headers: headers,
                transformRequest: (d) => d,
            }
        );
        if (data) {
            callBackUploadImage(data);

        }
    } catch (err) {
    }
};

/*=============================================================
                    Update Coupon Action
===============================================================*/

export const updateCouponAction = (formData: any, navigation: any, toast: any) => async dispatch => {
    dispatch({
        type: COUPON_UPDATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Coupons/Update`, formData, {
        headers: cudheaders
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: COUPON_UPDATE_SUCCESS
                });
                navigation.navigate('CouponsGet');
                toast.show({ message: 'Coupon updated successfully.', type: 'info', duration: 3000, position: 'top' });
                const form = {
                    Records_Filter: 'FILTER',
                    Partner_Details_Id: formData.Partner_Details_Id,

                };
                const pagination = {
                    PageNo: 1,
                    PageSize: 5,
                    SortBy: "COUPON_ID",
                    SortOrder: "ASC"
                }
                dispatch(getAllCouponAction(form, pagination));


            } else {
                const errors = {};
                dispatch({
                    type: COUPON_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: COUPON_UPDATE_FAIL,
                payload: "Please try again later.Currently server is not working"
            });
        })
};



/*=============================================================
                  Get All Brands Action
===============================================================*/

export const getAllBrands = (id: any) => async dispatch => {
    try {
        let formData = {
            "search_by_filter": "Brand_Name",
            "search": id,
            "Brand_Id": "",
            "Brand_Name": "",
            "Brand_Status": "",
            "Esi_Rating": "",
            "Records_Filter": "ALL",
            "Todays_Hot": "",
            "Top_Brands": ""
        };
        dispatch({
            type: COUPON_BRANDS_REQUEST
        });
        let { data, status } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Brand/Get`,
            formData,

            // Axios looks for the `auth` option, and, if it is set, formats a
            // basic auth header for you automatically.
            {
                headers: nodeheaders
            }

        );
        if (data) {
            if (data.Success_Response.Is_Data_Exist === '0') {
                dispatch({
                    type: COUPON_BRANDS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: COUPON_BRANDS_SUCCESS,
                    payload: data.results ? data.results : []
                });
            }
        }
    } catch (err) {
        dispatch({
            type: COUPON_BRANDS_FAIL,
            payload: err
        });
    }
};



/*=============================================================
                  Get All Categories Action
===============================================================*/

export const getAllCategories = (id: any) => async dispatch => {
    try {
        let formData = {
            "search_by_filter": "Category_Name",
            "search": id,
            "Category_Id": "",
            "Category_Name": "",
            "Records_Filter": "ALL",
            "Status": ""

        };
        dispatch({
            type: COUPON_CATEGORIES_REQUEST
        });
        let { data, status } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Category/Get`,
            formData,

            // Axios looks for the `auth` option, and, if it is set, formats a
            // basic auth header for you automatically.
            {
                headers: nodeheaders
            }

        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: COUPON_CATEGORIES_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: COUPON_CATEGORIES_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: COUPON_CATEGORIES_FAIL,
            payload: err
        });
    }
};

// Filter 
export const couponFilter = (fType, value) => {
    return function (dispatch) {
        dispatch({
            type: COUPONS_FILTER,
            fType: fType,
            value: value,
        });
    }
}

//search
export const resetFilter = () => {
    return function (dispatch: any) {
        dispatch({
            type: COUPONS_FILTER,
            fType: "search",
            value: "",
        });
    }
}

export const couponData = (value: any) => {
    return function (dispatch) {
        dispatch({
            type: COUPON_DATA,
            value: value,
        });

    }

}
export const getSingleCoupon = (formData: any, callBackCouponData: any) => async dispatch => {
    try {
        let { data, status } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Coupons/Coupon_Get`,
            formData,
            {
                headers: nodeheaders
            }
        );
        if (data) {
            callBackCouponData(data.results[0])
        }
    } catch (err) {
    }
};