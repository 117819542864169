import { ALL_TRANSACTION_FAIL, ALL_TRANSACTION_GET, ALL_TRANSACTION_REQUEST } from "../../actions/shoppers/shoppertransactionAction";

const initialState = {
    transactiuon: { all: [], error: '', isLoading: false },
};
export default function (state = initialState, action: any) {
    switch (action.type) {
        case ALL_TRANSACTION_REQUEST:
            return { ...state, transactiuon: { all: state.transactiuon.all, error: '', isLoading: true } };
        case ALL_TRANSACTION_GET:
            return { ...state, transactiuon: { all: action.payload, error: '', isLoading: false } };
        case ALL_TRANSACTION_FAIL:
            return { ...state, transactiuon: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}