import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, Image, Dimensions, ScrollView, Platform, RefreshControl } from 'react-native';
import { TextInput, Card, Button, HelperText, TouchableRipple, ActivityIndicator, Switch, Checkbox, } from 'react-native-paper';
import PhoneInput from 'react-native-phone-number-input';
import Header from '../auth/components/header';
import Headers from '../Services/header/header';
import ImageUpload from '../../components/custom/image-upload/upload';
import { uploadPartnerbannerImage, uploadPartnerlogoImage, updatePartnerProfileAction, PartnerProfileAction, PartnerProfileActions, updateOpenStoreAction, uploadPartnerGSTImage, uploadPartnerPANImage, companymailVerification, companymailOTPVerification } from '../../state/actions/partnerprofileActions';
import { uploadProfileAction } from '../../state/actions/profileActions';
import { CountryCode as mobilecode } from '../../components/custom/countrycode';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import EsiDatePicker from '../../components/custom/date/date';
import isEmpty from '../../state/validations/is-empty';
import { imageORfileupload } from '../../state/actions/commonImageOrFileUpload';
import { uriToBlob } from '../../hooks/uriBlob';


export default function PartnerProfile(props) {
    //navigation is used for navigation between screens
    const { navigation, profiletemp, callgetuser, ...rest } = props
    const dispatch = useDispatch();

    //toast for popup after successful API calls.
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const [loading, setLoading] = React.useState(true);
    const EmailID = useSelector(state => state.auth?.user);
    //isSubmitted contains the data from Update API using state call
    const isSubmited = useSelector(state => state.partner.physicalpartnerUpdate.isLoading);
    const userPic = useSelector(state => state.auth?.user?.Profile_Pic_Path);
    const user = useSelector((state) => state.auth.user);
    //partners contains the data from  GET API using state call
    const partners = useSelector(state => state.partner.profile.all);
    const [isSwitchOn, setIsSwitchOn] = React.useState(false);
    const [PanText, setPanText] = React.useState('');
    const [GstText, setGstText] = React.useState('');
    const [LandLine, setLandLine] = React.useState('');
    const [UserTips, setUserTips] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [established, setEstablished] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [wesiteurl, setWebsiteurl] = React.useState('');
    //Mobile
    const phoneInput = useRef(null);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const RetrieveUserValue = async () => {
        try {
            return await AsyncStorage.getItem('user')
        }
        catch (e) {
        }
    }
    //dispatch is used to perform the API action
    React.useEffect(() => {
        setSeconds(3);
        if (!isEmpty(partners)) {
            setLogoImage({
                preview: partners?.Logo_Path,
                raw: partners?.Logo_Path
            });
            setGSTImage({
                preview: partners?.GST_Image,
                raw: partners?.GST_Image
            })
            setPANImage({
                preview: partners?.PAN_Card_Image,
                raw: partners?.PAN_Card_Image
            })
            setBannerImage({
                preview: partners?.Banner_Path,
                raw: partners?.Banner_Path
            })
            setLogoImgUrl(partners?.Logo_Path)
            setBannerImgUrl(partners?.Banner_Path)
            setGSTImgUrl(partners?.GST_Image)
            setPANImgUrl(partners?.PAN_Card_Image)
            setPanText(partners?.PAN)
            setGstText(partners?.GST)
            setLandLine(partners?.Land_Phone)
            setUserTips(partners?.User_Tips)
            setEmail(partners?.Email_Id)
            setEstablished(partners?.Established_Date)
            setMobile(partners?.Mobile_Number)
            setWebsiteurl(partners?.Website_Url)
        }
        else {
            let formData = {
                "Partner_Details_Id": EmailID?.Partner_Details_Id,
            };
            dispatch(PartnerProfileAction(formData))
        }

    }, [partners]);

    const [seconds, setSeconds] = React.useState(-1);
    useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 2000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const [logoimage, setLogoImage] = useState({ preview: null, raw: null });
    const [profilepic, setProfile_Pic] = useState({ preview: userPic, raw: null });
    const ID = props.route?.params?.ID
    const [profilepicurl, setProfile_Picurl] = useState(userPic);
    const [logoimgUrl, setLogoImgUrl] = useState(partners?.Logo_Path);
    const [logouploadPic, setLogoUploadPic] = useState(false);
    const [screenType, setScreenType] = React.useState("profile");
    const [modal, setModal] = React.useState(false);

    const LogocallBackUploadImage = (data) => {
        if (data) {
            let formData = {
                "Partner_Details_Id": EmailID?.Partner_Details_Id,
            };

            setLogoImgUrl(data.File_URL);
        }
        setLogoUploadPic(false);
    };

    const profilelBackUploadImage = (data: any) => {
        if (data) {
            let formData = {
                "Partner_Details_Id": EmailID?.Partner_Details_Id,
            };
            setProfile_Picurl(data.File_URL);

        }
    };
    React.useEffect(() => {
        if (user.Partner_Details_Id) {
            const formData = {
                Partner_Details_Id: user.Partner_Details_Id
            };
            dispatch(PartnerProfileActions(formData, callBackOpengetData));
        }
    }, [user]);
    const formikRef = React.useRef();
    const [emailExist, setEmailExist] = React.useState("");
    const [emailExistError, setEmailExistError] = React.useState([]);

    const [emailValidationExist, setEmailValidationExist] = React.useState("");
    const [emailValidationExistError, setEmailValidationExistError] = React.useState([]);

    const onEmailFieldOnBlur = () => {
        if (!(formikRef.current?.errors.Email_Id) && emailExist != formikRef.current?.values.Email_Id) {
            setEmailExist(formikRef.current?.values.Email_Id);
            dispatch(companymailVerification(formikRef.current?.values.Email_Id, emailCheckResponse));
        }
        else if (emailExist == formikRef.current?.values.Email_Id) {
        }
        else {
            setEmailExist("");
        }
    }
    const onEmailValidateFieldOnBlur = () => {
        if (!(formikRef.current?.errors.Mail_OTP) && emailValidationExist != formikRef.current?.values.Mail_OTP) {
            setEmailValidationExist(formikRef.current?.values.Mail_OTP);
            const formValue = {
                Partner_Details: EmailID?.Partner_Details_Id,
                Email_Id: formikRef.current?.values.Email_Id,
                OTP: formikRef.current?.values.Mail_OTP
            };
            dispatch(companymailOTPVerification(formValue, OTPResponse));
        }
        else if (emailValidationExist == formikRef.current?.values.Mail_OTP) {
        }
        else {
            setEmailValidationExist("");
        }
    }
    const OTPResponse = (status: any, errorMessage: any, response: any, input: any) => {
        if (!status) {
            setEmailValidationExistError(errorMessage);
            setSecondsOTP(30);
        } else {
            let formData = {
                "Partner_Details_Id": EmailID?.Partner_Details_Id,
            };
            dispatch(PartnerProfileAction(formData))
        }
    }
    const emailCheckResponse = (status: any, errorMessage: any, response: any, input: any) => {
        if (!status) {
            let emailExistErrorN = JSON.parse(JSON.stringify(emailExistError));
            emailExistErrorN.push(input);
            setEmailExistError(emailExistErrorN);
        } else {
            setSecondsOTP(30);
        }
    }

    React.useEffect(() => {
        let error = false;
        for (let i = 0; i <= emailExistError.length; i++) {
            if (emailExistError[i] == formikRef.current?.values.Email_Id) {
                error = true;
                break;
            }
        }
        if (error)
            formikRef.current?.setFieldError("Email_Id", 'Email Address is already exist.');
    }, [emailExistError])
    React.useEffect(() => {
        let error = false;
        for (let i = 0; i <= emailValidationExistError.length; i++) {
            if (emailValidationExistError[i] == formikRef.current?.values.Email_Id) {
                error = true;
                break;
            }
        }
        if (error)
            formikRef.current?.setFieldError("Mail_OTP", 'Please Enter Valied OTP.');
    }, [emailValidationExistError])
    const [secondsOTP, setSecondsOTP] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => secondsOTP > 0 ? setSecondsOTP(secondsOTP - 1) : setSecondsOTP(-1), 2000)
        return () => clearTimeout(timer)
    }, [secondsOTP]);
    const callBackOpengetData = (data: any) => {
        if (data) {
            setIsSwitchOn(data.Is_Open == '1' ? true : false)
        }
    }
    const setCallBackOpengetData = (data: any) => {
        if (data) {
            const formData = {
                Partner_Details_Id: data.Partner_Details_Id
            };
            dispatch(PartnerProfileActions(formData, callBackOpengetData));

        }
    }
    const callBackOpenData = (data: any) => {
        if (data) {
            setCallBackOpengetData(data)
        }
    }
    const onToggleSwitch = () => {
        if (!isEmpty(isSwitchOn)) {
            let formData = {
                Name: user.Partner_Name,
                Partner_Details_Id: user.Partner_Details_Id,
                Is_Open: !isSwitchOn ? 1 : 0,
                Partner_User_Detail_Id: user.Partner_User_Details_Id
            }
            dispatch(updateOpenStoreAction(formData, callBackOpenData));
            setIsSwitchOn(!isSwitchOn);
        }
    }
    const LogohandleImage = async (result: any) => {
        let call = result.uri
        if (result) {
            let exe = "img";
            let uriParts = result.uri.split('.');
            if (Platform.OS == "web") {
                let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
                let filformate = fileExtension[0].split(':')
                exe = filformate[1].split('/')[1]
            } else {
                exe = uriParts[uriParts.length - 1]
            }
            let fileData = {
                File_name: "LogoImage",
                Functionality: "LogoImages",
                ext: exe,
                File_Type: "Images",
            };
            const img = await LogofetchImageFromUri(call);
            setLogoImage({
                preview: result.uri,
                raw: img
            });
            dispatch(
                imageORfileupload(img, fileData, LogocallBackUploadImage)
            );
        }
    };
    const LogofetchImageFromUri = async (uri) => {
        const blob = await uriToBlob(uri);
        return blob;
    };

    const profilePichandleImage = async (result) => {
        const img = await profilefetchImageFromUri(result.uri);
        setProfile_Pic({
            preview: result.uri,
            raw: img
        });
        const local_user = await RetrieveUserValue();
        const asyncuser = JSON.parse(local_user)
        dispatch(
            uploadProfileAction(profilelBackUploadImage, img, EmailID.Email_Id, asyncuser.Partner_Session_Id)
        );
    };

    const profilefetchImageFromUri = async (uri) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        return blob;
    };


    const handleRemoveLogoImage = e => {
        setLogoImgUrl('');
        setLogoImage({
            preview: null,
            raw: null
        });
    };


    //  GST Image Upload 

    const [GSTimage, setGSTImage] = useState({ preview: null, raw: null });
    const [GSTimgUrl, setGSTImgUrl] = useState(partners?.GST_Image);
    const [GSTuploadpic, setGSTUploadPic] = useState(false);


    const GSTcallBackUploadImage = (data) => {
        if (data) {
            let formData = {
                "Partner_Details_Id": EmailID?.Partner_Details_Id,
            };

            setGSTImgUrl(data.File_URL);
        }
        setGSTUploadPic(false);
    };
    const GSThandleImage = async (result: any) => {
        let call = result.uri
        if (result) {
            let exe = "img";
            let uriParts = result.uri.split('.');
            if (Platform.OS == "web") {
                let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
                let filformate = fileExtension[0].split(':')
                exe = filformate[1].split('/')[1]
            } else {
                exe = uriParts[uriParts.length - 1]
            }
            let fileData = {
                File_name: "GSTImage",
                Functionality: "GSTImages",
                ext: exe,
                File_Type: "Images",
            };
            const img = await GSTfetchImageFromUri(call);
            setGSTImage({
                preview: result.uri,
                raw: img
            });
            dispatch(
                imageORfileupload(img, fileData, GSTcallBackUploadImage)
            );
        }
    };
    const GSTfetchImageFromUri = async (uri) => {
        const blob = await uriToBlob(uri);
        return blob;
    };

    const handleRemoveGSTImage = e => {
        setGSTImgUrl('');
        setGSTImage({
            preview: '',
            raw: ''
        });
    };

    //  PAN Image Upload 

    const [PANimage, setPANImage] = useState({ preview: null, raw: null });
    const [PANimgUrl, setPANImgUrl] = useState(partners?.PAN_Card_Image);
    const [PANuploadpic, setPANUploadPic] = useState(false);


    const PANcallBackUploadImage = (data) => {
        if (data) {
            let formData = {
                "Partner_Details_Id": EmailID?.Partner_Details_Id,
            };

            setPANImgUrl(data.File_URL);
        }
        setPANUploadPic(false);
    };
    const PANhandleImage = async (result: any) => {
        let call = result.uri
        if (result) {
            let exe = "img";
            let uriParts = result.uri.split('.');
            if (Platform.OS == "web") {
                let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
                let filformate = fileExtension[0].split(':')
                exe = filformate[1].split('/')[1]
            } else {
                exe = uriParts[uriParts.length - 1]
            }
            let fileData = {
                File_name: "PANImage",
                Functionality: "PANImages",
                ext: exe,
                File_Type: "Images",
            };
            const img = await PANfetchImageFromUri(call);
            setPANImage({
                preview: result.uri,
                raw: img
            });
            dispatch(
                imageORfileupload(img, fileData, PANcallBackUploadImage)
            );
        }
    };
    const PANfetchImageFromUri = async (uri) => {
        const blob = await uriToBlob(uri);
        return blob;
    };

    const handleRemovePANImage = e => {

        setPANImgUrl('');
        setPANImage({
            preview: '',
            raw: ''
        });
    };

    //  Banner Image Upload 

    const [bannerimage, setBannerImage] = useState({ preview: null, raw: null });
    const [bannerimgUrl, setBannerImgUrl] = useState(partners?.Banner_Path);
    const [banneruploadpic, setBannerUploadPic] = useState(false);
    const BannercallBackUploadImage = (data) => {
        if (data) {
            let formData = {
                "Partner_Details_Id": EmailID?.Partner_Details_Id,
            };

            setBannerImgUrl(data.File_URL);
        }
        setBannerUploadPic(false);
    };
    const BannerhandleImage = async (result: any) => {
        let call = result.uri
        if (result) {
            let exe = "img";
            let uriParts = result.uri.split('.');
            if (Platform.OS == "web") {
                let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
                let filformate = fileExtension[0].split(':')
                exe = filformate[1].split('/')[1]
            } else {
                exe = uriParts[uriParts.length - 1]
            }
            let fileData = {
                File_name: "BannerImage",
                Functionality: "BannerImages",
                ext: exe,
                File_Type: "Images",
            };
            const img = await BannerfetchImageFromUri(call);
            setBannerImage({
                preview: result.uri,
                raw: img
            });
            dispatch(
                imageORfileupload(img, fileData, BannercallBackUploadImage)
            );
        }
    };
    const BannerfetchImageFromUri = async (uri) => {
        const blob = await uriToBlob(uri);
        return blob;
    };

    const handleRemoveBannerImage = e => {

        setBannerImgUrl('');
        setBannerImage({
            preview: '',
            raw: ''
        });
    };

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );

    });

    //mobile number country code
    const getCountryCode = (value) => {
        let dailCode = "91";
        if (value) {
            if (value.split('+').length > 0) {
                let pSplit = value.split('+');
                let count = pSplit.length > 1 ? 1 : 0;
                if (pSplit[count].split(' ').length > 0) {
                    dailCode = value.split('+')[count].split(' ')[0];
                }
            }
        }
        return mobilecode.find((item) => item.dial_code == dailCode)?.code;
    }
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const getCurrentMobileNumber = (value) => {
        let dailNumber = value;
        if (value) {
            if (value.split(' ').length > 1) {
                dailNumber = value.split(' ')[1];
            }
        }
        return dailNumber;
    }
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        dispatch(PartnerProfileAction(EmailID.Email_Id))
        wait(2000).then(() => setRefreshing(false));
    }, []);
    /*====================================
            Handler
    ====================================*/

    // Form Validation
    const Form_Validation = Yup.object().shape({
        // Mobile_Number: Yup.string().min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number.").required("Mobile Number is required."),
        // Email_Id: Yup.string().email("Please provide valid Email ID.").required('Email ID is required.'),
        User_Tips: Yup.string().required('User Tips are required.'),
    })
    const onSubmit = (values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Logo_Path = logoimgUrl;
        values.Banner_Path = bannerimgUrl;
        values.GST_Image = GSTimgUrl;
        values.Pan_Card_Image = PANimgUrl;

        if (!values.Logo_Path) {
            errors.Logo_Path = "Logo Path or Logo Image is required.";
            handleRemoveLogoImage()
            submitTest = false;
        }
        if (!values.Banner_Path) {
            errors.Banner_Path = "Banner Path or Banner Image is required.";
            handleRemoveBannerImage()
            submitTest = false;
        }
        if (values.GST) {
            if (!values.GST_Image) {
                errors.GST_Image = "GST Image is required.";
                handleRemoveGSTImage()
                submitTest = false;
            }
        }
        if (values.PAN) {
            if (!values.Pan_Card_Image) {
                errors.Pan_Card_Image = "PAN Image is required.";
                handleRemovePANImage()
                submitTest = false;
            }
        }
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Is_Partner_Profile_Update) {
                formValues.Is_Partner_Profile_Update = 1;
            } else {
                formValues.Is_Partner_Profile_Update = 0;
            }
            if (values.Is_Email_Verified) {
                formValues.Is_Email_Verified = 1;
            } else {
                formValues.Is_Email_Verified = 0;
            }
            dispatch(updatePartnerProfileAction(formValues, EmailID, props.navigation, toast, ID));
        }
    }

    const esiColor = useSelector(state => state.theme);
    const pages = props.route?.params?.page;

    return (
        <>
            {/* <Header navigation={navigation} /> */}
            {pages === 'Services' ? <Headers navigation={navigation} /> : <Header navigation={navigation} />}
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                {seconds <= 0 ?
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            User_Tips: partners?.User_Tips ? partners?.User_Tips : "",
                            Logo_Path: logoimgUrl,
                            Banner_Path: bannerimgUrl,
                            GST_Image: GSTimgUrl,
                            Pan_Card_Image: PANimgUrl,
                            Email_Id: EmailID?.Email_Id,
                            GST: partners?.GST ? partners?.GST : "",
                            Modified_By: EmailID.Email_Id,
                            Established_Date: partners?.Established_Date ? partners?.Established_Date : "",
                            Land_Phone: partners?.Land_Phone ? partners?.Land_Phone : "",
                            Mobile_Number: partners?.Mobile_Number ? partners?.Mobile_Number : "",
                            PAN: partners?.PAN ? partners?.PAN : "",
                            Partner_Details_Id: EmailID?.Partner_Details_Id,
                            Is_Partner_Profile_Update: '1',
                            Name: partners?.Name ? partners?.Name : "",
                            Is_Email_Verified: partners.Is_Email_Verified > 0 ? true : false,
                            Website_Url: partners?.Website_Url ? partners?.Website_Url : "",
                            Mail_OTP: '',
                        }} enableReinitialize={true} onSubmit={onSubmit} validationSchema={Form_Validation} >
                        {(props) => (

                            <View style={{ flexDirection: 'column', justifyContent: 'flex-start', flexWrap: 'wrap', backgroundColor: esiColor.BackgroundColor }}>
                                <View style={{ width: dimensions > 500 ? '75%' : '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '2%' }}>
                                    <View style={{ flexDirection: 'row', flex: 1, padding: 5, justifyContent: 'space-between' }}>
                                        {ID === '1' ? <View>
                                            <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                                props.resetForm(); setSeconds(3); navigation.navigate('Dashboard');
                                            }}>
                                                Go Back
                                            </Button>
                                        </View> : ID === '2' ? <View>
                                            <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                                props.resetForm(); setSeconds(3); navigation.navigate("Properties", { screen: 'PropertiesDashboard' });

                                            }}>
                                                Go Back
                                            </Button>
                                        </View> : pages === 'Services' ? <View>
                                            <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                                props.resetForm(); setSeconds(3); navigation.navigate("Services", { screen: 'ServicesDashboard' });

                                            }}>
                                                Go Back
                                            </Button>
                                        </View> : ID === '5' ? <View>
                                            <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                                props.resetForm(); setSeconds(3); navigation.navigate("Farmer", { screen: 'Farmer_Dashboard' });

                                            }}>
                                                Go Back
                                            </Button>
                                        </View> : <View>
                                            <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                                                props.resetForm(); setSeconds(3); navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } });

                                            }}>
                                                Go Back
                                            </Button>
                                        </View>}
                                        <View style={{ flexDirection: 'row', padding: 5, justifyContent: 'space-between' }}>
                                            <Text style={{ fontWeight: 'bold', marginRight: 5, color: esiColor.DescColor }}>Partner Available:</Text>
                                            <Switch value={isSwitchOn}
                                                onValueChange={() => {
                                                    if (isSwitchOn == false) {
                                                        setModal(true);
                                                    }
                                                    onToggleSwitch()
                                                }
                                                } />
                                        </View>
                                    </View>
                                    <Card style={{
                                        width: dimensions <= 500 ? "100%" : "100%", marginLeft: "auto", marginRight: "auto",
                                        marginTop: "2%", marginBottom: "2%", borderRadius: 6,
                                        //  borderWidth: 0.01, borderColor: '#D6D6D7',
                                        backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor,
                                        shadowOffset: { width: 1, height: 2 },
                                        shadowOpacity: 0.2,
                                        shadowRadius: 3
                                    }}>
                                        <Card.Content>
                                            <Text style={{ fontSize: 20, fontWeight: "bold", textAlign: "center", color: esiColor.brandFontColor }}>  Company Profile
                                            </Text>
                                            {screenType === "profile" &&
                                                <Card style={{
                                                    width: dimensions <= 500 ? "100%" : "100%", marginLeft: "auto", marginRight: "auto",
                                                    marginTop: "3%", marginBottom: "3%", backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor,
                                                    shadowOffset: { width: 1, height: 2 },
                                                    shadowOpacity: 0.2,
                                                    shadowRadius: 3
                                                }}>

                                                    <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 10 }} >
                                                        <Text style={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                            marginTop: 3, color: esiColor.DescColor
                                                        }}>Company Logo
                                                        </Text>
                                                    </View>
                                                    <View style={{ marginLeft: "auto", marginRight: "auto", width: 350, paddingBottom: 20 }} >
                                                        {/* TextInput for Logo Image Field */}
                                                        <ImageUpload style={{
                                                            borderRadius: 6, borderWidth: 0.01,
                                                            backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor,
                                                            // shadowOffset: { width: -2, height: 4 },
                                                            shadowOpacity: 0.2,
                                                            shadowRadius: 3
                                                        }} imagestyle={styles.images} handleRemoveImage={handleRemoveLogoImage} image={logoimage} uploadPic={LogohandleImage} />
                                                        <HelperText type="error" visible={!logoimgUrl && props.touched.Logo_Path && props.errors.Logo_Path}>
                                                            {!logoimgUrl && props.touched.Logo_Path && props.errors.Logo_Path}
                                                        </HelperText>
                                                    </View>
                                                </Card>
                                            }
                                            <View style={{ flexGrow: 1, marginTop: '5%', flexShrink: 1 }} />
                                            {screenType === "profile" &&
                                                <View>
                                                    <View pointerEvents={!(permissions?.Partner_Profile === "write") ? 'none' : 'auto'} style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                                                        <View style={{ flexShrink: 1, marginTop: '1%', alignContent: "center", width: 350 }} >
                                                            <Text style={{
                                                                fontWeight: 'bold',
                                                                textAlign: 'center',
                                                                marginTop: 3, color: esiColor.DescColor
                                                            }}>Banner Image</Text>
                                                            {/* TextInput for  Banner Image Field */}
                                                            <ImageUpload style={{
                                                                borderRadius: 6, borderWidth: 0.01,
                                                                backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor,
                                                                // shadowOffset: { width: -2, height: 4 },
                                                                shadowOpacity: 0.2,
                                                                shadowRadius: 3
                                                            }} imagestyle={styles.images} handleRemoveImage={handleRemoveBannerImage} image={bannerimage} uploadPic={BannerhandleImage} />
                                                            <HelperText type="error" visible={!bannerimgUrl && props.touched.Banner_Path && props.errors.Banner_Path}>
                                                                {!bannerimgUrl && props.touched.Banner_Path && props.errors.Banner_Path}
                                                            </HelperText>
                                                        </View>
                                                        <View style={{ flexShrink: 1, width: 650 }}>

                                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                                    {/*  Name Field with Validation*/}
                                                                    <TextInput
                                                                        style={{ backgroundColor: esiColor.TextBC, marginTop: 15 }}
                                                                        // theme={{ colors: { primary: '#27B6CC' } }}
                                                                        activeUnderlineColor={esiColor.TextOuterLine}
                                                                        selectionColor={esiColor.TextSelection}
                                                                        theme={{
                                                                            colors: {
                                                                                primary: esiColor.TextHighlight,
                                                                                text: esiColor.Text,
                                                                                placeholder: esiColor.TextPlaceholder,
                                                                            }
                                                                        }}
                                                                        outlineColor={esiColor.TextOuterLine}
                                                                        underlineColor={esiColor.TextUnderline}
                                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                                        error={Boolean(props.touched.Name && props.errors.Name)}
                                                                        label='Partner Name'
                                                                        mode='outlined'
                                                                        disabled
                                                                        name="Name"
                                                                        value={props.values.Name}
                                                                        onChangeText={props.handleChange('Name')}
                                                                    />
                                                                    <HelperText type="error" visible={Boolean(props.touched.Name && props.errors.Name)}>

                                                                        {props.touched.Name && props.errors.Name}
                                                                    </HelperText>
                                                                </View>
                                                                <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15, }}>
                                                                    <EsiDatePicker
                                                                        style={{ width: "100%", margin: "auto", height: 50 }}
                                                                        name={'Established_Date'}
                                                                        theme={esiColor.pagination_theme}
                                                                        label='Established_Date'
                                                                        value={established}
                                                                        valueUpdate={item => {
                                                                            props.setFieldValue('Established_Date', item)
                                                                            setEstablished(item)
                                                                        }}
                                                                    />
                                                                </View>
                                                            </View>
                                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                                    {/*   GST Field with Validation*/}
                                                                    <TextInput
                                                                        style={{ backgroundColor: esiColor.TextBC }}
                                                                        // theme={{ colors: { primary: '#27B6CC' } }}
                                                                        selectionColor={esiColor.TextSelection}
                                                                        theme={{
                                                                            colors: {
                                                                                primary: esiColor.TextHighlight,
                                                                                text: esiColor.Text,
                                                                                placeholder: esiColor.TextPlaceholder
                                                                            }
                                                                        }}
                                                                        outlineColor={esiColor.TextOuterLine}
                                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                                        underlineColor={esiColor.TextUnderline}
                                                                        error={Boolean(props.touched.GST && props.errors.GST)}
                                                                        label='GST Number'
                                                                        mode='outlined'
                                                                        name="GST"
                                                                        disabled={partners?.GST != "" ? true : false}
                                                                        value={GstText}
                                                                        onChangeText={item => {
                                                                            props.setFieldValue('GST', item)
                                                                            setGstText(item)
                                                                        }}
                                                                    // value={props.values.GST}
                                                                    // onChangeText={props.handleChange('GST')}
                                                                    />
                                                                    <HelperText type="error" visible={Boolean(props.touched.GST && props.errors.GST)}>
                                                                        {props.touched.GST && props.errors.GST}
                                                                    </HelperText>
                                                                </View>
                                                                <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                                    {/* PAN Field with Validation*/}
                                                                    <TextInput
                                                                        style={{ backgroundColor: esiColor.TextBC }}
                                                                        // theme={{ colors: { primary: '#27B6CC' } }}
                                                                        selectionColor={esiColor.TextSelection}
                                                                        theme={{
                                                                            colors: {
                                                                                primary: esiColor.TextHighlight,
                                                                                text: esiColor.Text,
                                                                                placeholder: esiColor.TextPlaceholder
                                                                            }
                                                                        }}
                                                                        outlineColor={esiColor.TextOuterLine}
                                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                                        underlineColor={esiColor.TextUnderline}
                                                                        error={Boolean(props.touched.PAN && props.errors.PAN)}
                                                                        label='PAN Number'
                                                                        mode='outlined'
                                                                        disabled={partners?.PAN != "" ? true : false}
                                                                        name="PAN"
                                                                        value={PanText}
                                                                        onChangeText={item => {
                                                                            props.setFieldValue('PAN', item)
                                                                            setPanText(item)
                                                                        }}
                                                                    />
                                                                    <HelperText type="error" visible={Boolean(props.touched.PAN && props.errors.PAN)}>

                                                                        {props.touched.PAN && props.errors.PAN}
                                                                    </HelperText>
                                                                </View>
                                                            </View>

                                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                                    {/*  UserTips Field with Validation*/}
                                                                    <TextInput
                                                                        style={{ backgroundColor: esiColor.TextBC, marginTop: 15 }}
                                                                        // theme={{ colors: { primary: '#27B6CC' } }}
                                                                        selectionColor={esiColor.TextSelection}
                                                                        theme={{
                                                                            colors: {
                                                                                primary: esiColor.TextHighlight,
                                                                                text: esiColor.Text,
                                                                                placeholder: esiColor.TextPlaceholder
                                                                            }
                                                                        }}
                                                                        outlineColor={esiColor.TextOuterLine}
                                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                                        underlineColor={esiColor.TextUnderline}
                                                                        error={Boolean(props.touched.User_Tips && props.errors.User_Tips)}
                                                                        label='User Tips'
                                                                        mode='outlined'
                                                                        name="User_Tips"
                                                                        value={UserTips}
                                                                        onChangeText={item => {
                                                                            props.setFieldValue('User_Tips', item)
                                                                            setUserTips(item)
                                                                        }}
                                                                    // value={props.values.User_Tips}
                                                                    // onChangeText={props.handleChange('User_Tips')}
                                                                    />
                                                                    <HelperText type="error" visible={Boolean(props.touched.User_Tips && props.errors.User_Tips)}>
                                                                        {props.touched.User_Tips && props.errors.User_Tips}
                                                                    </HelperText>
                                                                </View>

                                                                {/* EmailID Field with Validation*/}
                                                                <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                                    <TextInput
                                                                        style={{ backgroundColor: esiColor.TextBC, marginTop: 15 }}
                                                                        // theme={{ colors: { primary: '#27B6CC' } }}
                                                                        selectionColor={esiColor.TextSelection}
                                                                        theme={{
                                                                            colors: {
                                                                                primary: esiColor.TextHighlight,
                                                                                text: esiColor.Text,
                                                                                placeholder: esiColor.TextPlaceholder
                                                                            }
                                                                        }}
                                                                        outlineColor={esiColor.TextOuterLine}
                                                                        underlineColor={esiColor.TextUnderline}
                                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                                        label='Email ID'
                                                                        mode='outlined'
                                                                        disabled={permissions.Employees === "write" || partners.Is_Email_Verified === '1' ? false : true}
                                                                        value={email}
                                                                        right={partners.Is_Email_Verified === '1' ? <TextInput.Icon color={"green"} icon="checkbox-marked-circle-outline" /> : ''}
                                                                        // onChangeText={props.handleChange('Email_Id')}
                                                                        onChangeText={item => {
                                                                            props.setFieldValue('Email_Id', item)
                                                                            setEmail(item)
                                                                        }}
                                                                        error={Boolean(props.touched.Email_Id && props.errors.Email_Id)}
                                                                    />
                                                                    <HelperText type="error" visible={Boolean(props.touched.Email_Id && props.errors.Email_Id)}>
                                                                        <ErrorMessage name="Email_Id" />
                                                                    </HelperText>
                                                                </View>
                                                            </View>
                                                            {partners.Is_Email_Verified === "0" &&
                                                                <View>
                                                                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                                                        <Checkbox.Android
                                                                            color="#27B6CC"
                                                                            name="Is_Email_Verified"
                                                                            status={props.values.Is_Email_Verified ? 'checked' : 'unchecked'}
                                                                            onBlur={(props) => {
                                                                                onEmailFieldOnBlur();
                                                                            }}
                                                                            onPress={() => {
                                                                                props.setFieldValue("Is_Email_Verified", !props.values.Is_Email_Verified);
                                                                            }}
                                                                        />
                                                                        <Text style={{ marginTop: 10 }}>Is Email Verified </Text>
                                                                        {(!isEmpty(emailExist) && secondsOTP > 0 && !Boolean((props.touched.Is_Email_Verified || emailExistError.length > 0) && props.errors.Is_Email_Verified)) &&
                                                                            <HelperText type="info" style={{ color: "#27b6cc", textAlign: "right", marginTop: 10 }}
                                                                                visible={!isEmpty(emailExist)}>
                                                                                {secondsOTP} Secs To Resend OTP
                                                                            </HelperText>}
                                                                        {(!isEmpty(emailExist) && secondsOTP <= 0 && !Boolean((props.touched.Is_Email_Verified || emailExistError.length > 0) && props.errors.Is_Email_Verified)) &&
                                                                            <HelperText type="info" style={{ color: "#27b6cc", textAlign: "right", marginTop: 10 }}
                                                                                onPress={() => {
                                                                                    dispatch(companymailVerification(emailExist, emailCheckResponse));
                                                                                }}
                                                                                visible={!isEmpty(emailExist)}>
                                                                                Resend OTP
                                                                            </HelperText>}
                                                                    </View>

                                                                    {(!isEmpty(emailExist) && !Boolean((props.touched.Is_Email_Verified == true || emailExistError.length > 0) && props.errors.Is_Email_Verified)) &&
                                                                        <View style={{ width: "80%", margin: "auto" }}>
                                                                            <TextInput
                                                                                style={{ width: "100%", margin: "auto", backgroundColor: esiColor.TextBC }}
                                                                                // style={{ backgroundColor: esiColor.TextBC, marginTop: 15 }}
                                                                                // theme={{ colors: { primary: '#27B6CC' } }}
                                                                                selectionColor={esiColor.TextSelection}
                                                                                theme={{
                                                                                    colors: {
                                                                                        primary: esiColor.TextHighlight,
                                                                                        text: esiColor.Text,
                                                                                        placeholder: esiColor.TextPlaceholder
                                                                                    }
                                                                                }}
                                                                                outlineColor={esiColor.TextOuterLine}
                                                                                underlineColor={esiColor.TextUnderline}
                                                                                activeOutlineColor={esiColor.TextOuterLine}
                                                                                label="Enter OTP"
                                                                                mode='outlined'
                                                                                onChangeText={props.handleChange('Mail_OTP')}
                                                                                onBlur={(props) => {
                                                                                    onEmailValidateFieldOnBlur();
                                                                                }}
                                                                                value={props.values.Mail_OTP}

                                                                            />
                                                                            <HelperText type="error" visible={Boolean((props.touched.Mail_OTP || emailExistError.length > 0) && props.errors.Mail_OTP)}>
                                                                                {(props.touched.Mail_OTP || emailExistError.length > 0) && props.errors.Mail_OTP}
                                                                            </HelperText>
                                                                        </View>
                                                                    }</View>}
                                                            {/* TextInput for Mobile Number Field with Validation*/}
                                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                                                                    <TextInput style={{ width: "100%", margin: "auto", backgroundColor: esiColor.TextBC }}
                                                                        selectionColor={esiColor.TextSelection}
                                                                        theme={{
                                                                            colors: {
                                                                                primary: esiColor.TextHighlight,
                                                                                text: esiColor.Text,
                                                                                placeholder: esiColor.TextPlaceholder
                                                                            }
                                                                        }}
                                                                        outlineColor={esiColor.TextOuterLine}
                                                                        underlineColor={esiColor.TextUnderline}
                                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                                        label="Mobile Number"
                                                                        mode='outlined'
                                                                        // onChangeText={props.handleChange('Mobile_Number')}
                                                                        onChangeText={item => {
                                                                            props.setFieldValue('Mobile_Number', item)
                                                                            setMobile(item)
                                                                        }}
                                                                        left={<TextInput.Affix textStyle={{ color: esiColor.DescColor }} text="+91" />}
                                                                        value={mobile}
                                                                    />
                                                                    <HelperText type="error" visible={Boolean(props.touched.Mobile_Number && props.errors.Mobile_Number)}>
                                                                        <ErrorMessage name="Mobile_Number" />
                                                                    </HelperText>
                                                                </View>

                                                                {/*  Land Phone Field with Validation*/}
                                                                <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                                    <TextInput
                                                                        style={{ backgroundColor: esiColor.TextBC }}
                                                                        selectionColor={esiColor.TextSelection}
                                                                        theme={{
                                                                            colors: {
                                                                                primary: esiColor.TextHighlight,
                                                                                text: esiColor.Text,
                                                                                placeholder: esiColor.TextPlaceholder
                                                                            }
                                                                        }}
                                                                        outlineColor={esiColor.TextOuterLine}
                                                                        underlineColor={esiColor.TextUnderline}
                                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                                        label='Landline Number'
                                                                        mode='outlined'
                                                                        maxLength={13}
                                                                        keyboardType='numeric'
                                                                        name="Land_Phone"
                                                                        // value={props.values.Land_Phone}
                                                                        // onChangeText={props.handleChange('Land_Phone')}
                                                                        value={LandLine}
                                                                        onChangeText={item => {
                                                                            props.setFieldValue('Land_Phone', item)
                                                                            setLandLine(item)
                                                                        }}
                                                                        error={Boolean(props.touched.Land_Phone && props.errors.Land_Phone)}
                                                                    />
                                                                    <HelperText type="error" visible={Boolean(props.touched.Land_Phone && props.errors.Land_Phone)}>
                                                                        {props.touched.Land_Phone && props.errors.Land_Phone}
                                                                    </HelperText>
                                                                </View>
                                                            </View>

                                                            {/* Logo Path Field with Validation*/}
                                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                                    <TextInput
                                                                        style={{ backgroundColor: esiColor.TextBC }}
                                                                        selectionColor={esiColor.TextSelection}
                                                                        theme={{
                                                                            colors: {
                                                                                primary: esiColor.TextHighlight,
                                                                                text: esiColor.Text,
                                                                                placeholder: esiColor.TextPlaceholder
                                                                            }
                                                                        }}
                                                                        outlineColor={esiColor.TextOuterLine}
                                                                        underlineColor={esiColor.TextUnderline}
                                                                        activeOutlineColor={esiColor.TextOuterLine} label='Logo Path'
                                                                        mode='outlined'
                                                                        name="Logo_Path"
                                                                        value={logoimgUrl}
                                                                        type="url"
                                                                        onChangeText={(data) => {
                                                                            setLogoImgUrl(data);
                                                                            setLogoImage({
                                                                                preview: data,
                                                                                raw: data
                                                                            });

                                                                        }}
                                                                    />

                                                                    <HelperText type="error" visible={!logoimgUrl && props.touched.Logo_Path && props.errors.Logo_Path}>
                                                                        {props.touched.Logo_Path && props.errors.Logo_Path}
                                                                    </HelperText>
                                                                </View>

                                                                {/*  Banner Path Field with Validation*/}
                                                                <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                                    <TextInput
                                                                        style={{ backgroundColor: esiColor.TextBC }}
                                                                        selectionColor={esiColor.TextSelection}
                                                                        theme={{
                                                                            colors: {
                                                                                primary: esiColor.TextHighlight,
                                                                                text: esiColor.Text,
                                                                                placeholder: esiColor.TextPlaceholder
                                                                            }
                                                                        }}
                                                                        outlineColor={esiColor.TextOuterLine}
                                                                        underlineColor={esiColor.TextUnderline}
                                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                                        label='Banner Path'
                                                                        mode='outlined'
                                                                        name='Banner_Path'
                                                                        value={bannerimgUrl}
                                                                        type="url"
                                                                        onChangeText={(data) => {
                                                                            setBannerImgUrl(data);
                                                                            setBannerImage({
                                                                                preview: data,
                                                                                raw: data
                                                                            })
                                                                        }}
                                                                    />

                                                                    <HelperText type="error" visible={!bannerimgUrl && props.touched.Banner_Path && props.errors.Banner_Path}>
                                                                        {props.touched.Banner_Path && props.errors.Banner_Path}
                                                                    </HelperText>
                                                                </View>
                                                            </View>
                                                            <View style={{ flexGrow: 1, flexShrink: 1, paddingTop: 15 }}>
                                                                {/*  Website URL Field with Validation*/}
                                                                <TextInput
                                                                    style={{ backgroundColor: esiColor.TextBC }}
                                                                    selectionColor={esiColor.TextSelection}
                                                                    theme={{
                                                                        colors: {
                                                                            primary: esiColor.TextHighlight,
                                                                            text: esiColor.Text,
                                                                            placeholder: esiColor.TextPlaceholder
                                                                        }
                                                                    }}
                                                                    outlineColor={esiColor.TextOuterLine}
                                                                    underlineColor={esiColor.TextUnderline}
                                                                    activeOutlineColor={esiColor.TextOuterLine} error={Boolean(props.touched.Website_Url && props.errors.Website_Url)}
                                                                    label='Website Url'
                                                                    mode='outlined'
                                                                    name="Website_Url"
                                                                    value={wesiteurl}
                                                                    // onChangeText={props.handleChange('Website_Url')}
                                                                    onChangeText={item => {
                                                                        props.setFieldValue('Website_Url', item)
                                                                        setWebsiteurl(item)
                                                                    }}

                                                                />
                                                                <HelperText type="error" visible={Boolean(props.touched.Website_Url && props.errors.Website_Url)}>
                                                                    {props.touched.Website_Url && props.errors.Website_Url}
                                                                </HelperText>
                                                            </View>

                                                        </View>
                                                        {GstText !== '' ?
                                                            <View style={{ flexShrink: 1, marginTop: '1%', alignContent: "center", width: 350 }} >
                                                                <Text style={{
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'center',
                                                                    marginTop: 3, color: esiColor.DescColor
                                                                }}>GST Image</Text>
                                                                <ImageUpload style={{
                                                                    borderRadius: 6, borderWidth: 0.01, backgroundColor: esiColor.BackgroundColor,
                                                                    shadowColor: esiColor.brandShadowColor,
                                                                    // shadowOffset: { width: -2, height: 4 },
                                                                    shadowOpacity: 0.2,
                                                                    shadowRadius: 3
                                                                }} imagestyle={styles.images} handleRemoveImage={handleRemoveGSTImage} image={GSTimage} uploadPic={GSThandleImage} />
                                                                <HelperText type="error" visible={!GSTimgUrl && props.touched.GST_Image && props.errors.GST_Image}>
                                                                    {!GSTimgUrl && props.touched.GST_Image && props.errors.GST_Image}
                                                                </HelperText>
                                                            </View> : null}
                                                        {PanText !== '' ?
                                                            <View style={{ flexShrink: 1, marginTop: '1%', alignContent: "center", width: 350 }} >
                                                                <Text style={{
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'center',
                                                                    marginTop: 3, color: esiColor.DescColor
                                                                }}>PAN Image</Text>
                                                                <ImageUpload style={{
                                                                    borderRadius: 6, borderWidth: 0.01, backgroundColor: esiColor.BackgroundColor,
                                                                    shadowColor: esiColor.brandShadowColor,
                                                                    // shadowOffset: { width: -2, height: 4 },
                                                                    shadowOpacity: 0.2,
                                                                    shadowRadius: 3
                                                                }} imagestyle={styles.images} handleRemoveImage={handleRemovePANImage} image={PANimage} uploadPic={PANhandleImage} />
                                                                <HelperText type="error" visible={!PANimgUrl && props.touched.Pan_Card_Image && props.errors.Pan_Card_Image}>
                                                                    {!PANimgUrl && props.touched.Pan_Card_Image && props.errors.Pan_Card_Image}
                                                                </HelperText>
                                                            </View> : null}
                                                    </View>
                                                    <Button disabled={isSubmited || logouploadPic || banneruploadpic || !(permissions.Partner_Profile === "write")} style={{ width: "30%", marginTop: "3%", marginLeft: "30.5%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                                        <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                                                    </Button>

                                                </View>
                                            }
                                        </Card.Content>
                                    </Card>
                                </View>
                            </View>

                        )}
                    </Formik >
                    : < ActivityIndicator color='#27b6cc' style={{ marginTop: 20 }} />}
            </ScrollView >
        </>
    );
};

const styles = StyleSheet.create({
    errorText: {
        fontSize: 15,
        color: 'red',
    },

    heading: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 3
    },

    profile: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginLeft: "2%",
        marginTop: "1%"
    },
    images: {
        width: '100%',
        height: 100,
        borderRadius: 5,
        marginBottom: 20
    },
});