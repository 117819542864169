import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import { updateUserpaymentAction } from '../../../state/actions/userpaymentAction';
import Header from '../../auth/components/header';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//StatusOption is used to get the data from dropdown
const StatusOption = [
    {
        value: 'PAID',
        label: 'PAID'
    },
    {
        value: 'UNPAID',
        label: 'UNPAID'
    },
    {
        value: 'REFUND',
        label: 'REFUND'
    },
    {
        value: 'PENDING',
        label: 'PENDING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    }
];

const EditUserpayment = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    //toast is for popup message after succesfull API call
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.User_Payments === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    //isSubmitted contains the data from Update API using state call
    const isSubmited = useSelector(state => state.userpayment.userpaymentUpdate.isLoading);
    //userpayment is used to store the values from previous page using props
    const userpayment = props.route?.params?.data;

    //Dropdowns
    const [statusDropDown, setStatusDropDown] = useState("");

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    /*====================================
            Handler
    ====================================*/

    const values = {
        Partner_Details_Id: userpayment.Partner_Details_Id ? userpayment.Partner_Details_Id : "",
        User_Payment_Settlement_Id: userpayment.User_Payment_Settlement_Id ? userpayment.User_Payment_Settlement_Id : "",
        Partner_Name: userpayment.Partner_Name ? userpayment.Partner_Name : "",
        Transaction_Id: userpayment.Transaction_Id ? userpayment.Transaction_Id : "",
        User_Email_Id: userpayment.User_Email_Id ? userpayment.User_Email_Id : "",
        Order_Product_Id: userpayment.Order_Line_Items[0].Order_Product_Id ? userpayment.Order_Line_Items[0].Order_Product_Id : "",
        Order_Line_Item_Id: userpayment.Order_Line_Items[0].Order_Line_Item_Id ? userpayment.Order_Line_Items[0].Order_Line_Item_Id : "",
        Order_Line_Item_Amount: userpayment.Order_Line_Items[0].Order_Line_Item_Amount ? userpayment.Order_Line_Items[0].Order_Line_Item_Amount : "",
        Order_Id: userpayment.Order_Id ? userpayment.Order_Id : "",
        Final_Order_Amount: userpayment.Final_Order_Amount ? userpayment.Final_Order_Amount : "",
        Esi_Commission_Amount: userpayment.Esi_Commission_Amount ? userpayment.Esi_Commission_Amount : "",
        Payment_Status: userpayment.Order_Payment_Status ? userpayment.Order_Payment_Status : "",
        Total_Order_Amount: userpayment.Total_Order_Amount ? userpayment.Total_Order_Amount : "",
        Modified_By: EmailID.Email_Id
    }
    //Form validation
    const Form_Validation = Yup.object().shape({
        Payment_Status: Yup.string().required('Payment Status is required.')
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(updateUserpaymentAction(formValues, navigation, toast));
        }
    }

    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <ScrollView>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('UserpaymentGet')}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: '#D6D6D7'
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Edit User Payment
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Partner_Details_Id field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Partner Details ID'
                                                mode='outlined'
                                                name="Partner_Details_Id"
                                                value={props.values.Partner_Details_Id}
                                                onChangeText={props.handleChange('Partner_Details_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* user Payment Settlement ID field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='User Payment Settlement ID'
                                                mode='outlined'
                                                name="User_Payment_Settlement_Id"
                                                value={props.values.User_Payment_Settlement_Id}
                                                onChangeText={props.handleChange('User_Payment_Settlement_Id')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* User_Email_Id field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='User Email ID'
                                                mode='outlined'
                                                name="User_Email_Id"
                                                value={props.values.User_Email_Id}
                                                onChangeText={props.handleChange('User_Email_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Transaction ID field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Transaction ID'
                                                mode='outlined'
                                                name="Transaction_Id"
                                                value={props.values.Transaction_Id}
                                                onChangeText={props.handleChange('Transaction_Id')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order_Id field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Order ID'
                                                mode='outlined'
                                                name="Order_Id"
                                                value={props.values.Order_Id}
                                                onChangeText={props.handleChange('Order_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Final_Order_Amount field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Final Order Amount'
                                                mode='outlined'
                                                name="Final_Order_Amount"
                                                value={props.values.Final_Order_Amount.toString()}
                                                onChangeText={props.handleChange('Final_Order_Amount')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order_Line_Item ID field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Order Line Item ID'
                                                mode='outlined'
                                                name="Order_Line_Item_Id"
                                                value={props.values.Order_Line_Item_Id}
                                                onChangeText={props.handleChange('Order_Line_Item_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order_Line_Item_Amount field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Order Line Item Amount'
                                                mode='outlined'
                                                name="Order_Line_Item_Amount"
                                                value={props.values.Order_Line_Item_Amount.toString()}
                                                onChangeText={props.handleChange('Order_Line_Item_Amount')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Esi_Commission_Amount field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='ESI Commission Amount'
                                                mode='outlined'
                                                name="Esi_Commission_Amount"
                                                value={props.values.Esi_Commission_Amount.toString()}
                                                onChangeText={props.handleChange('Esi_Commission_Amount')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order product ID field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Order Product ID'
                                                mode='outlined'
                                                name="Order_Product_Id"
                                                value={props.values.Order_Product_Id}
                                                onChangeText={props.handleChange('Order_Product_Id')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ width: 453, flexShrink: 1, paddingTop: 15 }}>
                                            {/* Payment Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Payment_Status && props.errors.Payment_Status) && { color: '#b00020' }]}>
                                                Payment Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Payment_Status && props.errors.Payment_Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ?true:false}
                                                searchPlaceholder={'Select Payment Status'}
                                                dropdownPosition="auto"
                                                value={props.values.Payment_Status}
                                                name="Payment_Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Payment_Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Payment_Status && props.errors.Payment_Status)}>
                                                <ErrorMessage name="Payment_Status" />
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};



const styles = StyleSheet.create({
    label: {
        marginBottom: 30,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        left: 22,
        top: 10,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});

export default EditUserpayment;