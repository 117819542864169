import {
  USER_NEW_BIDDING_QUOTES_REQUEST,
  USER_NEW_BIDDING_QUOTES_SUCCESS,
  USER_NEW_BIDDING_QUOTES_FAIL,
  BIDDING_PRODUCT_AVAILABLE_UPDATE_REQUEST,
  BIDDING_PRODUCT_AVAILABLE_UPDATE_SUCCESS,
  BIDDING_PRODUCT_AVAILABLE_UPDATE_FAIL,

  
  BIDDING_QUOTE_CREATE_REQUEST,
  BIDDING_QUOTE_CREATE_SUCCESS,
  BIDDING_QUOTE_CREATE_FAIL,
  BIDDING_PRODUCT_UPDATE_REQUEST,
  BIDDING_PRODUCT_UPDATE_SUCCESS,
  BIDDING_PRODUCT_UPDATE_FAIL,
  BIDDING_PRODUCT_FILTER,


} from '../actions/userNewbiddingQuotesAction'

const initialState = {
  bidding_quotes: { all: [], error: '', isLoading: false },
  biddingproductavailability: { partner: {}, error: '', isLoading: false },
  biddingCreate: { bidding: {}, error: '', isLoading: false },
  biddingUpdate: { bidding: {}, error: '', isLoading: false },
  biddingFilter: { select: 'All', search: '' }, 
};

export default function (state = initialState, action:any) {
  switch (action.type) {
    case USER_NEW_BIDDING_QUOTES_REQUEST:
      return { ...state, bidding_quotes: { all: state.bidding_quotes.all, error: '', isLoading: true } };
    case USER_NEW_BIDDING_QUOTES_SUCCESS:
      return { ...state, bidding_quotes: { all: action.payload, error: '', isLoading: false } };
    case USER_NEW_BIDDING_QUOTES_FAIL:
      return { ...state, bidding_quotes: { all: [], error: action.payload, isLoading: false } };
      case BIDDING_PRODUCT_AVAILABLE_UPDATE_REQUEST:
        return { ...state, biddingproductavailability: { partner: {}, error: '', isLoading: true } };
      case BIDDING_PRODUCT_AVAILABLE_UPDATE_SUCCESS:
        return { ...state, biddingproductavailability: { partner: state.biddingproductavailability.partner, error: '', isLoading: false } };
      case   BIDDING_PRODUCT_AVAILABLE_UPDATE_FAIL:
        return { ...state, biddingproductavailability: { partner: {}, error: action.payload, isLoading: false } };
  
      case BIDDING_QUOTE_CREATE_REQUEST:
      return { ...state, biddingCreate: { bidding: {}, error: '', isLoading: true } };
    case BIDDING_QUOTE_CREATE_SUCCESS:
      return { ...state, biddingCreate: { bidding: state.biddingCreate.user, error: '', isLoading: false } };
    case BIDDING_QUOTE_CREATE_FAIL:
      return { ...state, biddingCreate: { bidding: {}, error: action.payload, isLoading: false } };
    case BIDDING_PRODUCT_UPDATE_REQUEST:
      return { ...state, biddingUpdate: { bidding: {}, error: '', isLoading: true } };
    case BIDDING_PRODUCT_UPDATE_SUCCESS:
      return { ...state, biddingUpdate: { bidding: state.biddingUpdate.bidding, error: '', isLoading: false } };
    case BIDDING_PRODUCT_UPDATE_FAIL:
       
     case BIDDING_PRODUCT_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, biddingFilter: { search: state.biddingFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, biddingFilter: { select: state.biddingFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}



