import axios from 'axios';
import config from './config';

export const PARTNERBANK_REQUEST = "PARTNERBANK_REQUEST";
export const PARTNERBANK_SUCCESS = "PARTNERBANK_SUCCESS";
export const PARTNERBANK_FAIL = "PARTNERBANK_FAIL";
export const PARTNERBANK_GET_REQUEST = "PARTNERBANK_GET_REQUEST";
export const PARTNERBANK_GET_SUCCESS = "PARTNERBANK_GET_SUCCESS";
export const PARTNERBANK_GET_FAIL = "PARTNERBANK_GET_FAIL";
export const PARTNERBANK_UPDATE_REQUEST = "PARTNERBANK_UPDATE_REQUEST";
export const PARTNERBANK_UPDATE_SUCCESS = "PARTNERBANK_UPDATE_SUCCESS";
export const PARTNERBANK_UPDATE_FAIL = "PARTNERBANK_UPDATE_FAIL";


axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

/*=============================================================
                  Get PartnerBank Action
===============================================================*/

const headers = config.headersCommon;

export const getPartnerBankAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: PARTNERBANK_REQUEST
    });
    let { data } = await axios.post(
      `${config.url}/Partner_Bank_Details/Get`,
      formData,
      {
        headers: headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERBANK_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERBANK_SUCCESS,
          payload: data.Partner_Bank_Details[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERBANK_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Partner Bank Update Action
  ===============================================================*/

export const updatePartnerBankAction = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: PARTNERBANK_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Partner_Bank_Details/Update`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNERBANK_UPDATE_SUCCESS
        });
        navigation.navigate('Dashboard')
        toast.show({ message: 'Partner bank updated successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          "Partner_Details_Id": formData.Partner_Details_Id,
          "Records_Filter": "FILTER"
        };
        dispatch(getPartnerBankAction(formValue));
      } else {
        const errors = {};
        dispatch({
          type: PARTNERBANK_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })

    .catch(error => {
      dispatch({
        type: PARTNERBANK_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};