import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';

export const PRODUCTSIZES_REQUEST = "PRODUCTSIZES_REQUEST";
export const PRODUCTSIZES_SUCCESS = "PRODUCTSIZES_SUCCESS";
export const PRODUCTSIZES_FAIL = "PRODUCTSIZES_FAIL";
export const PRODUCTSIZE_GET_REQUEST = "PRODUCTSIZE_GET_REQUEST";
export const PRODUCTSIZE_GET_SUCCESS = "PRODUCTSIZE_GET_SUCCESS";
export const PRODUCTSIZE_GET_FAIL = "PRODUCTSIZE_GET_FAIL";
export const PRODUCTSIZE_CREATE_REQUEST = "PRODUCTSIZE_CREATE_REQUEST";
export const PRODUCTSIZE_CREATE_SUCCESS = "PRODUCTSIZE_CREATE_SUCCESS";
export const PRODUCTSIZE_CREATE_FAIL = "PRODUCTSIZE_CREATE_FAIL";
export const PRODUCTSIZE_UPDATE_REQUEST = "PRODUCTSIZE_UPDATE_REQUEST";
export const PRODUCTSIZE_UPDATE_SUCCESS = "PRODUCTSIZE_UPDATE_SUCCESS";
export const PRODUCTSIZE_UPDATE_FAIL = "PRODUCTSIZE_UPDATE_FAIL";
export const PRODUCTSIZE_DELETE_REQUEST = "PRODUCTSIZE_DELETE_REQUEST";
export const PRODUCTSIZE_DELETE_SUCCESS = "PRODUCTSIZE_DELETE_SUCCESS";
export const PRODUCTSIZE_DELETE_FAIL = "PRODUCTSIZE_DELETE_FAIL";

export const PRODUCT_MASTER_REQUEST = "PRODUCT_MASTER_REQUEST";
export const PRODUCT_MASTER_SUCCESS = "PRODUCT_MASTER_SUCCESS";
export const PRODUCT_MASTER_FAIL = "PRODUCT_MASTER_FAIL";

export const PRODUCTSIZE_REQUEST = "PRODUCTSIZE_REQUEST";
export const PRODUCTSIZE_FAIL = "PRODUCTSIZE_FAIL";

export const PRODUCTSIZES_FILTER = "PRODUCTSIZES_FILTER";

const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
export const invalid_data = () => {
  return {
    type: RESET
  }
}
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
      queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
 
  /*=============================================================
                  Get All Sizes Action
===============================================================*/

export const getAllProductSizeAction = (formData:any,pagination = {}) => async dispatch => {
  try {
    dispatch({
      type: PRODUCTSIZES_REQUEST
    });
    meeapi.post(urlGenarator(`Product_Management/Product_Size_Get`,pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {          
        dispatch({
          type: PRODUCTSIZES_SUCCESS,
          payload: []
        });
      } else {
         dispatch({
          type: PRODUCTSIZES_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: PRODUCTSIZES_FAIL,
      payload: err
    });
  }
};




export const createProductSizeAction = (formData:any,productsize:any,navigation:any,toast:any) => async dispatch => { 
 dispatch({
    type: PRODUCTSIZE_CREATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Product_Management/Product_Size_Create`,
  formData,
  {
    headers: cudheaders
  }
)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PRODUCTSIZE_CREATE_SUCCESS
        });
     const formValue ={
      Records_Filter: 'FILTER',
      Product_Master_Id: productsize
    };
    dispatch(getAllProductSizeAction(formValue));
    navigation.navigate("ProductSizes",{ID:productsize});
    toast.show({ message: 'Product Size Added Successfully.', type: 'info', duration: 3000, position: 'top' });
  
    } else {
        dispatch({
          type: PRODUCTSIZE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTSIZE_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
  };

  /*=============================================================
                  Update User Action
===============================================================*/

export const updateProductSizeAction = (formData:any,productsize:any,navigation:any,toast:any) => async dispatch => {
    dispatch({
      type: PRODUCTSIZE_UPDATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Product_Management/Product_Size_Update`,
    formData,
    {
      headers: cudheaders
    }

  )
      .then(response => {
        let successResponse = response.data;
        if (successResponse.Response_Status == "Success") {
          dispatch({
            type: PRODUCTSIZE_UPDATE_SUCCESS
          });
         const formValue ={
      Records_Filter: 'FILTER',
      Product_Master_Id:formData.Product_Master_Id
    };
    dispatch(getAllProductSizeAction(formValue));
    navigation.navigate("ProductSizes",{ID: formData.Product_Master_Id});
    toast.show({ message: 'Product Size Updated Successfully.', type: 'info', duration: 3000, position: 'top' });

    } else {
          dispatch({
            type: PRODUCTSIZE_UPDATE_FAIL,
            payload: successResponse.UI_Display_Message
          });
        }
      })
      .catch(error => {
        dispatch({         
          type: PRODUCTSIZE_CREATE_FAIL,
          payload: "Currently server is not working. Please try again later."
        });
      })
  };
  
  /*=============================================================
                  Wallet Delete
===============================================================*/

export const productmasterFilter = (fType:any, value:any) => {
    return function (dispatch:any) {
      dispatch({
        type: PRODUCTSIZES_FILTER,
        fType: fType,
        value: value,
      });
    }
  }

  //search reset
  export const resetFilter = () => {
    return function (dispatch: any) {
      dispatch({
        type: PRODUCTSIZES_FILTER,
       fType: "search", 
        value: "",
      });
    }
  }

export const getProductsizeByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
      dispatch({
         type: PRODUCTSIZE_REQUEST
      });
      let { data } = await axios.post(
          `${shopperconfig.nodePartnerUrl}/Product_Management/Product_Size_Get`,
          formData,
          {
             // Axios looks for the `auth` option, and, if it is set, formats a
             // basic auth header for you automatically.
                headers: nodeheaders
          }
      );

      if (data) {
          if (data.Is_Data_Exist === "0") {
            callBackData([])
          } else {         
            callBackData(data.results[0])
          }
      }
  } catch (err) {
      dispatch({
          type: PRODUCTSIZE_FAIL,
          payload: err
      });
  }
};