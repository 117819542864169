import axios from 'axios';
import config from './config';
import { Dispatch } from "redux"



export enum ActionType {
    MAIN_FILTER_DATA = "MAIN_FILTER_DATA",
    PARTNER_FILTER_DATA = "PARTNER_FILTER_DATA",
    BRAND_FILTER_DATA='BRAND_FILTER_DATA',
    CASHBACK_FILTER_DATA='CASHBACK_FILTER_DATA',
    DISCOUNT_FILTER_DATA='DISCOUNT_FILTER_DATA',
    DISCOUNT_PRICE_DATA='DISCOUNT_PRICE_DATA',
    CATEGORY_FILTER_DATA='CATEGORY_FILTER_DATA',
}



export const changeMainData = (input:any) => {
    return function (dispatch:any) {
        dispatch({
            type: ActionType.MAIN_FILTER_DATA,payload:input
        });
        
    }
}

export const changePartnerData = (input:any) => {
    return function (dispatch:any) {
        dispatch({
            type: ActionType.PARTNER_FILTER_DATA,payload:input
        });
        
    }
}

export const changeCategoriesData = (input:any) => {
    return function (dispatch:any) {
        dispatch({
            type: ActionType.CATEGORY_FILTER_DATA,payload:input
        });
        
    }
}

export const changeBrandsData = (input:any) => {
    return function (dispatch:any) {
        dispatch({
            type: ActionType.BRAND_FILTER_DATA,payload:input
        });
        
    }
}

export const changeCashbackData = (input:any) => {
    return function (dispatch:any) {
        dispatch({
            type: ActionType.CASHBACK_FILTER_DATA,payload:input
        });
        
    }
}

export const changeDiscountData = (input:any) => {
    return function (dispatch:any) {
        dispatch({
            type: ActionType.DISCOUNT_FILTER_DATA,payload:input
        });
        
    }
}
export const changePriceData = (input:any) => {
    return function (dispatch:any) {
        dispatch({
            type: ActionType.DISCOUNT_PRICE_DATA,payload:input
        });
        
    }
}
