
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Card, Button, TextInput, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import EsiDatePicker from '../../../../components/custom/date/date';
import Header from '../../../auth/components/header';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { eventsdata, getEventByIdAction } from '../../../../state/actions/Properties/eventsAction';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const EditUserEvents = (props: any) => {
  const { navigation } = props;
  const ID = props?.route?.params?.ID;
  const dispatch = useDispatch();
  // Role restriction
  const EmailID = useSelector(state => state.auth?.user);
  const [eventsbyids, setEventByIds] = React.useState();
   

  //reload
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [data, setData] = useState([]);
  const callbackData = (data: any) => {
    setData(data)
  }
  React.useEffect(() => {
    if(ID!==eventsbyids){
    let formData = {
      "Partner_Details_Id":EmailID.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "User_Event_Id": ID
    };
    setEventByIds(ID)
    dispatch(getEventByIdAction(formData, callbackData));
  }
 }, [ID,eventsbyids]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  return (
    <Surface style={{ flex: 1, }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {seconds <= 0 &&
          <Formik
            initialValues={{
              "User_Event_ID": data?.User_Event_ID,
              "Function_Hall_Id": data?.Function_Hall_Id,
              "Price_Per_Day": data?.Price_Per_Day,
              "Price_Per_Hour": data?.Price_Per_Hour,
              "Room_Cost_Per_Night": data?.Room_Cost_Per_Night,
              "User_Email_ID": data?.User_Email_ID,
              "Any_Extra_Charges": data?.Any_Extra_Charges,
              "Event_Price": data?.Event_Price,
              "Hall_Decoration_Price": data?.Hall_Decoration_Price,
              "Electricity_Price_Per_Hour": data?.Electricity_Price_Per_Hour,
              "AC_Electricty_Price_Per_Hour": data?.AC_Electricty_Price_Per_Hour,
              "Event_Booking_Requested_Date": data?.Event_Booking_Requested_Date,
              "Event_End_Date": data?.Event_End_Date,
              "Event_Start_Date": data?.Event_Start_Date,
              "Event_Total_Members": data?.Event_Total_Members,
              "Event_Total_Time_In_Days": data?.Event_Total_Time_In_Days,
              "Event_Total_Time_In_Hours": data?.Event_Total_Time_In_Hours,
              "Food_NonVeg_Price_Per_Plate": data?.Food_NonVeg_Price_Per_Plate,
              "Food_Veg_Price_Per_Plate": data?.Food_Veg_Price_Per_Plate,
              "Vent_Date": data?.Vent_Date,
              "SGST": data?.SGST,
              "SGST_Percentage": data?.SGST_Percentage,
              "IGST": data?.IGST,
              "IGST_Percentage": data?.IGST_Percentage,
              "CGST": data?.CGST,
              "CGST_Percentage": data?.CGST_Percentage,
              "Cashback_Amount": data?.Cashback_Amount,
              "Cashback_Settled_To_User": data?.Cashback_Settled_To_User,
              "Discount_Amount": data?.Discount_Amount,
              "Discount_Percentage": data?.Discount_Percentage,
              "Event_Total_Tax": data?.Event_Total_Tax,
              "Event_Total_Price_Before_Tax": data?.Event_Total_Price_before_Tax,
              "Event_Total_Price_After_Tax": data?.Event_Total_Price_After_Tax,
              "Partner_Total_Share": data?.Partner_Total_Share,
              "Payment_Reference_ID": data?.Payment_Reference_ID,
              "Payment_Status": data?.Payment_Status,
              "Meepaisa_Commission": data?.Meepaisa_Commission,
              "Booking_Status": data?.Booking_Status,
              "Created_By": data?.Created_By,
              "Created_Date": data?.Created_Date,
              "Event_Status": data?.Event_Status,
              "IS_Event_Has_Legal_Permission": data?.IS_Event_Has_Legal_Permission,
              "IS_Event_Required_Alchol": data?.IS_Event_Required_Alchol,
              "Modified_By": EmailID?.Email_Id,
              "User_Cancel_Reason": data?.User_Cancel_Reason,
              "Status": data?.Status,
            }}
            validationSchema={Yup.object().shape({
              Price_Per_Day: Yup.number().required(' Price Per Day is required.'),
              Price_Per_Hour: Yup.number().required(' Price Per Hour is required.'),
              Room_Cost_Per_Night: Yup.number().required(' Room Cost Per Night is required.'),
              Any_Extra_Charges: Yup.number().required(' Any Extra Charges is required.'),
              Event_Price: Yup.number().required(' Event Price is required.'),
              Hall_Decoration_Price: Yup.number().required(' Hall Decoration Price is required.'),
              Electricity_Price_Per_Hour: Yup.number().required(' Electricity Price Per Hour is required.'),
              AC_Electricty_Price_Per_Hour: Yup.number().required(' AC Electricty Price Per Hour is required.'),
              Event_Booking_Requested_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Event Booking Requested Date greater than or equal to today.").required("Event Booking Requested Date is required."),
              Event_End_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Event End Date greater than or equal to today.").required("Event End Date Date is required."),
              Event_Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Event Start Date greater than or equal to today.").required("Event Start Date is required."),
              Event_Total_Members: Yup.number().required(' Event Total Members is required.'),
              Event_Total_Time_In_Days: Yup.number().required(' Event Total Time In Days is required.'),
              Event_Total_Time_In_Hours: Yup.number().required(' Event Total Time In Hours is required.'),
              Food_NonVeg_Price_Per_Plate: Yup.number().required(' Food NonVeg Price Per Plate is required.'),
              Food_Veg_Price_Per_Plate: Yup.number().required(' Food Veg Price Per Plate is required.'),
              Vent_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Event Date greater than or equal to today.").required("Event Date is required."),
              SGST: Yup.number().required(' SGST is required.'),
              SGST_Percentage: Yup.number().required(' SGST Percentage is required.'),
              IGST: Yup.number().required(' IGST is required.'),
             })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              const errors = {};
              setErrors(errors);
              if (submitTest) {
                let formValues = JSON.parse(JSON.stringify(values));
                dispatch(eventsdata(formValues));
                navigation.navigate('EditUserEventspagetwo', { ID })
              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (

              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}>
                <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%" }}>
                  <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm();
                      navigation.navigate('GetEvents')
                    }}>
                    Go Back
                  </Button>
                </View>
                <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#d6d6d7' }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                      Edit Events
                    </Text>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        flexShrink: 1
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Price_Per_Day && errors.Price_Per_Day)}
                            label="Price Per Day"
                            mode='outlined'
                            value={values.Price_Per_Day}
                            onChangeText={handleChange("Price_Per_Day")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Price_Per_Day && errors.Price_Per_Day)}>
                            {touched.Price_Per_Day && errors.Price_Per_Day}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1,marginTop: '1%' }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Price_Per_Hour && errors.Price_Per_Hour)}
                            label="Price Per Hour"
                            mode='outlined'
                            value={values.Price_Per_Hour}
                            onChangeText={handleChange("Price_Per_Hour")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Price_Per_Hour && errors.Price_Per_Hour)}>
                            {touched.Price_Per_Hour && errors.Price_Per_Hour}
                          </HelperText>
                        </View>

                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        flexShrink: 1
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Room_Cost_Per_Night && errors.Room_Cost_Per_Night)}
                            label="Room Cost Per Night"
                            mode='outlined'
                            value={values.Room_Cost_Per_Night}
                            onChangeText={handleChange("Room_Cost_Per_Night")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Room_Cost_Per_Night && errors.Room_Cost_Per_Night)}>
                            {touched.Room_Cost_Per_Night && errors.Room_Cost_Per_Night}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Any_Extra_Charges && errors.Any_Extra_Charges)}
                            label="Any Extra Charges"
                            mode='outlined'
                            value={values.Any_Extra_Charges}
                            onChangeText={handleChange("Any_Extra_Charges")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Any_Extra_Charges && errors.Any_Extra_Charges)}>
                            {touched.Any_Extra_Charges && errors.Any_Extra_Charges}
                          </HelperText>
                        </View>

                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Event_Price && errors.Event_Price)}
                            label="Event Price"
                            mode='outlined'
                            value={values.Event_Price}
                            onChangeText={handleChange("Event_Price")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Event_Price && errors.Event_Price)}>
                            {touched.Event_Price && errors.Event_Price}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Hall_Decoration_Price && errors.Hall_Decoration_Price)}
                            label="Hall Decoration Price"
                            mode='outlined'
                            value={values.Hall_Decoration_Price}
                            onChangeText={handleChange("Hall_Decoration_Price")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Hall_Decoration_Price && errors.Hall_Decoration_Price)}>
                            {touched.Hall_Decoration_Price && errors.Hall_Decoration_Price}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Electricity_Price_Per_Hour && errors.Electricity_Price_Per_Hour)}
                            label="Electricity Price Per Hour"
                            mode='outlined'
                            value={values.Electricity_Price_Per_Hour}
                            onChangeText={handleChange("Electricity_Price_Per_Hour")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Electricity_Price_Per_Hour && errors.Electricity_Price_Per_Hour)}>
                            {touched.Electricity_Price_Per_Hour && errors.Electricity_Price_Per_Hour}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.AC_Electricty_Price_Per_Hour && errors.AC_Electricty_Price_Per_Hour)}
                            label="AC Electricty Price Per Hour"
                            mode='outlined'
                            value={values.AC_Electricty_Price_Per_Hour}
                            onChangeText={handleChange("AC_Electricty_Price_Per_Hour")}
                          />
                          <HelperText type="error" visible={Boolean(touched.AC_Electricty_Price_Per_Hour && errors.AC_Electricty_Price_Per_Hour)}>
                            {touched.AC_Electricty_Price_Per_Hour && errors.AC_Electricty_Price_Per_Hour}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginBottom: '3%', marginTop: 10 }}>
                          <EsiDatePicker label={'Event Booking Requested Date'}
                            style={{ width: "100%", backgroundColor: 'white',height:50 }}
                            value={values.Event_Booking_Requested_Date}
                            valueUpdate={date => {
                              setFieldValue('Event_Booking_Requested_Date', date)
                            }} />
                          <HelperText type="error" visible={Boolean(touched.Event_Booking_Requested_Date && errors.Event_Booking_Requested_Date)}>
                            {touched.Event_Booking_Requested_Date && errors.Event_Booking_Requested_Date}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: 10 }}>
                          <EsiDatePicker label={'Event Start Date'}
                            style={{ width: "100%", backgroundColor: 'white' }}
                            value={values.Event_Start_Date}
                            valueUpdate={date => {
                              setFieldValue('Event_Start_Date', date)
                            }} />
                          <HelperText type="error" visible={Boolean(touched.Event_Start_Date && errors.Event_Start_Date)}>
                            {touched.Event_Start_Date && errors.Event_Start_Date}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <EsiDatePicker label={'Event End Date'}
                            style={{ width: "100%", backgroundColor: 'white' }}
                            value={values.Event_End_Date}
                            valueUpdate={date => {
                              setFieldValue('Event_End_Date', date)
                            }} />
                          <HelperText type="error" visible={Boolean(touched.Event_End_Date && errors.Event_End_Date)}>
                            {touched.Event_End_Date && errors.Event_End_Date}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Event_Total_Members && errors.Event_Total_Members)}
                            label="Event Total Members"
                            mode='outlined'
                            value={values.Event_Total_Members}
                            onChangeText={handleChange("Event_Total_Members")}
                            keyboardType='numeric'
                            placeholder='cm'
                          />
                          <HelperText type="error" visible={Boolean(touched.Event_Total_Members && errors.Event_Total_Members)}>
                            {touched.Event_Total_Members && errors.Event_Total_Members}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Event_Total_Time_In_Days && errors.Event_Total_Time_In_Days)}
                            label="Event Total Time In Days"
                            mode='outlined'
                            value={values.Event_Total_Time_In_Days}
                            onChangeText={handleChange("Event_Total_Time_In_Days")}
                          />
                          <HelperText type="error" visible={Boolean(touched.Event_Total_Time_In_Days && errors.Event_Total_Time_In_Days)}>
                            {touched.Event_Total_Time_In_Days && errors.Event_Total_Time_In_Days}
                          </HelperText>
                        </View>

                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Event_Total_Time_In_Hours && errors.Event_Total_Time_In_Hours)}
                            label="Event Total Time In Hours"
                            mode='outlined'
                            value={values.Event_Total_Time_In_Hours}
                            onChangeText={handleChange("Event_Total_Time_In_Hours")}
                            keyboardType='numeric'
                            placeholder='cm'
                          />
                          <HelperText type="error" visible={Boolean(touched.Event_Total_Time_In_Hours && errors.Event_Total_Time_In_Hours)}>
                            {touched.Event_Total_Time_In_Hours && errors.Event_Total_Time_In_Hours}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Food_NonVeg_Price_Per_Plate && errors.Food_NonVeg_Price_Per_Plate)}
                            label="Food Non Veg Price Per Plate"
                            mode='outlined'
                            value={values.Food_NonVeg_Price_Per_Plate}
                            onChangeText={handleChange("Food_NonVeg_Price_Per_Plate")}
                            keyboardType='numeric'
                          />
                          <HelperText type="error" visible={Boolean(touched.Food_NonVeg_Price_Per_Plate && errors.Food_NonVeg_Price_Per_Plate)}>
                            {touched.Food_NonVeg_Price_Per_Plate && errors.Food_NonVeg_Price_Per_Plate}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.Food_Veg_Price_Per_Plate && errors.Food_Veg_Price_Per_Plate)}
                            label="Food Veg Price Per Plate"
                            mode='outlined'
                            value={values.Food_Veg_Price_Per_Plate}
                            onChangeText={handleChange("Food_Veg_Price_Per_Plate")}
                            keyboardType='numeric'
                          />
                          <HelperText type="error" visible={Boolean(touched.Food_Veg_Price_Per_Plate && errors.Food_Veg_Price_Per_Plate)}>
                            {touched.Food_Veg_Price_Per_Plate && errors.Food_Veg_Price_Per_Plate}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                          <EsiDatePicker label={'Event Date'}
                            style={{ width: "100%", backgroundColor: 'white' }}
                            value={values.Vent_Date}
                            valueUpdate={date => {
                              setFieldValue('Vent_Date', date)
                            }} />
                          <HelperText type="error" visible={Boolean(touched.Vent_Date && errors.Vent_Date)}>
                            {touched.Vent_Date && errors.Vent_Date}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.SGST && errors.SGST)}
                            label="SGST"
                            mode='outlined'
                            value={values.SGST}
                            onChangeText={handleChange("SGST")}
                            keyboardType='numeric'
                          />
                          <HelperText type="error" visible={Boolean(touched.SGST && errors.SGST)}>
                            {touched.SGST && errors.SGST}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginBottom: '3%', marginTop: 10 }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.SGST_Percentage && errors.SGST_Percentage)}
                            label="SGST Percentage"
                            mode='outlined'
                            value={values.SGST_Percentage}
                            onChangeText={handleChange("SGST_Percentage")}
                          />
                          <HelperText type="error" visible={Boolean(touched.SGST_Percentage && errors.SGST_Percentage)}>
                            {touched.SGST_Percentage && errors.SGST_Percentage}
                          </HelperText>

                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginBottom: '3%', marginTop: 10  }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(touched.IGST && errors.IGST)}
                            label="IGST"
                            mode='outlined'
                            value={values.IGST}
                            onChangeText={handleChange("IGST")}
                          />
                          <HelperText type="error" visible={Boolean(touched.IGST && errors.IGST)}>
                            {touched.IGST && errors.IGST}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                        <Button style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                          Next
                        </Button>
                      </View>
                  </Card.Content>
                </Card>
              </View>

            )}
          </Formik>}
      </ScrollView>
    </Surface>
  );
};

const styles = StyleSheet.create({
  cardCircle: {
    width: (Platform.OS === 'web') ? "75%" : "95%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2%",
    marginBottom: "2%",
  }, datePickerStyle: {
    width: 350,
    height: 60,
    marginTop: 6,
  },
  itemContainer: {
    justifyContent: 'flex-end',
    flex: 1
  },
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    marginBottom: 30,
  },
  editor: {
    backgroundColor: "black",
    borderColor: "black",
    borderWidth: 1,
  },
  rich: {
    minHeight: 130,
  },
  richBar: {
    height: 50,
    backgroundColor: "#F5FCFF",
  },
  text: {
    fontWeight: "bold",
    fontSize: 18,
  },
  tib: {
    textAlign: "center",
    color: "#515156",
  },
  a: {
    fontWeight: "bold",
    color: "purple",
  },
  div: {
    fontFamily: "monospace",
  },
  p: {
    fontSize: 30,
  }, container: {
    backgroundColor: 'white',
    paddingTop: 15
  },
  dropdown: {
    height: 58,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    color: 'black',
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  scrollView: {
    height: '20%',
    width: '80%',
    margin: 20,
    alignSelf: 'center',
    padding: 20,
    borderWidth: 5,
    borderRadius: 5,
    borderColor: 'black',
    backgroundColor: 'lightblue'
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    paddingBottom: 50
  }
});
export default EditUserEvents;