import { v4 as uuid } from 'uuid';
import brandmanagement from "../../assets/static/Dashboard/brandmanagement.jpg"
import partnermanagement from "../../assets/static/Dashboard/partnermanagement.jpg"
import productmanagement from "../../assets/static/Dashboard/productmanagement.png"
import inventorymanagement from "../../assets/static/Dashboard/inventorymanagement.jpg"
import coupon_management from "../../assets/static/Dashboard/coupon_management.jpg"
import Deal_management from "../../assets/static/Dashboard/Deal_management.png"
import shop_by_city from "../../assets/static/Dashboard/shop_by_city.jpg"
import gift_management from "../../assets/static/Dashboard/gift_management.jpg"

export default [
  {
    id: uuid(),
    link: 'BrandsGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Brand Management is the functionality where we identify the core value of a specific brand and reflecting the core value among the targeted customers.',
    media: brandmanagement,
    title: 'Brand Management',
    total: ''
  },
  {
    id: uuid(),
    link: 'AddressGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Partner Address Management is the process where organizations manage their Address details, so they can provide effective information about their organization details.',
    media: partnermanagement,
    title: 'Address Management',
    total: ''
  },
  {
    id: uuid(),
    link: 'ProductMainCenter',
    createdAt: 'Updated 2 hr ago',
    description:
      'Product Management is a process that focuses on bringing a new product to market or developing an existing one. It starts with an idea of a product that a customer will interact with and ends with the evaluation of the product.',
    media: productmanagement,
    title: 'Product Management',
    total: ''
  },
  {
    id: uuid(),
    link: 'Inventory',
    createdAt: 'Updated 2 hr ago',
    description:
      'Inventory Management refers to the process of ordering, storing and using a company inventory. This includes the management of raw materials, components and finished products, as well as warehousing and processing such items.',
    media: inventorymanagement,
    title: 'Inventory Management',
    total: ''
  },
  {
    id: uuid(),
    link: 'CouponsGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Coupon Management system helps you engage with your customers while giving you real time data of how and when the coupons are being used.',
    media: coupon_management,
    title: 'Coupon Management',
    total: ''
  },
  {
    id: uuid(),
    link: 'DealsGet',
    createdAt: 'Updated 2 hr ago',
    description: 'Deal Management is a strategy or tool that gives companies the ability to define deal parameters – including customers history, product status, discount level and operational constraints.',
    media: Deal_management,
    title: 'Deal Management',
    total: ''
  },
  {
    id: uuid(),
    link: 'SBCPartnercityGet',
    createdAt: 'Updated 2 hr ago',
    description: 'Shop By City Management is used to refer the Coupons, Deals and Products related to partner, the user can search based on particular city.',
    media: shop_by_city,
    title: 'Shop By City',
    total: ''
  },
  {
    id: uuid(),
    link: 'GiftVendorsGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Gift Management is the process where all the gift information is stored.',
    media: gift_management,
    title: 'Gift Management',
    total: ''
  },
];