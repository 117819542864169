import {
  ALL_B2C_ORDERS_REQUEST,
  ALL_B2C_ORDERS_SUCCESS,
  ALL_B2C_ORDERS_FAIL,
  BRAND_GET_REQUEST,
  BRAND_GET_SUCCESS,
  BRAND_GET_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDERS_FILTER,
  RESET,
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_FAIL,
  SINGLE_ORDER_REQUEST,
  SINGLE_ORDER_SUCCESS,
  SINGLE_ORDER_FAIL
} from '../actions/ordermanagementAction'
const initialState = {
  b2corders: { all: [], error: '', isLoading: false },
  order: { all: [], error: '', isLoading: false },
  singleorder: { all: {}, error: '', isLoading: false },
  brand: { brand: {}, error: '', isLoading: false },
  brandCreate: { brand: {}, error: '', isLoading: false },
  orderFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ALL_B2C_ORDERS_REQUEST:
      return { ...state,b2corders: { all: state.b2corders.all, error: '', isLoading: true } };
    case ALL_B2C_ORDERS_SUCCESS:
      return { ...state, b2corders: { all: action.payload, error: '', isLoading: false } };
    case ALL_B2C_ORDERS_FAIL:
      return { ...state, b2corders: { all: [], error: action.payload, isLoading: false } };
    case ORDER_REQUEST:
      return { ...state, order: { all: state.order.all, error: '', isLoading: true } };
    case ORDER_SUCCESS:
      return { ...state, order: { all: action.payload, error: '', isLoading: false } };
    case ORDER_FAIL:
      return { ...state, order: { all: [], error: action.payload, isLoading: false } };
   
      case  SINGLE_ORDER_REQUEST:
        return { ...state, singleorder: { all: state.singleorder.all, error: '', isLoading: true } };
      case  SINGLE_ORDER_SUCCESS:
        return { ...state, singleorder: { all: action.payload, error: '', isLoading: false } };
      case  SINGLE_ORDER_FAIL:
        return { ...state, singleorder: { all: {}, error: action.payload, isLoading: false } };
       case BRAND_GET_REQUEST:
      return { ...state, brand: { brand: {}, error: '', isLoading: true } };
    case BRAND_GET_SUCCESS:
      return { ...state, brand: { brand: action.payload, error: '', isLoading: false } };
    case BRAND_GET_FAIL:
      return { ...state, brand: { brand: {}, error: action.payload, isLoading: false } };
    case ORDER_UPDATE_REQUEST:
      return { ...state, brandCreate: { brand: {}, error: '', isLoading: true } };
    case ORDER_UPDATE_SUCCESS:
      return { ...state, brandCreate: { brand: state.brandCreate.brand, error: '', isLoading: false } };
    case ORDER_UPDATE_FAIL:
      return { ...state, brandCreate: { brand: {}, error: action.payload, isLoading: false } };
    case RESET:
      return { ...state, brandCreate: { brand: {}, error: '', isLoading: false } };
    case ORDERS_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, orderFilter: { search: state.orderFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, orderFilter: { select: state.orderFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}
