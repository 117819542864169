import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';

export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAIL = "NOTIFICATION_FAIL";

axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded';
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
const headers = config.headersCommon
const nodeheaders = config.nodeheadersCommon
/*=============================================================
                  Get All Notification Action
===============================================================*/

export const getAllNotification = (formData: any, callBackData: any) => async (dispatch: any) => {
   try {
        let { data } = await axios.post(
            `${config.nodePartnerUrl}/Notification/Get?PageNo=1&PageSize=30&SortBy=NOTIFICATION_ID&SortOrder=DESC`,
            formData,
            {
                headers:nodeheaders
            }
        );
        if (data) {
             callBackData(data);
        }
    } catch (err) {
      }
};
export const getMoreNotification = (formData: any, callBackData: any,pagination = {}) => async (dispatch: any) => {
  try {
      //  let { data } = await axios.post(
        meeapi.post(urlGenarator(`/Notification/Get`, pagination),
          //  `${config.nodePartnerUrl}/Notification/Get?PageNo=1&PageSize=30&SortBy=NOTIFICATION_ID&SortOrder=DESC`,
           formData,
       ).then(response => {
       let data = response.data;
       if (data) {
            callBackData(data);
       }
       })} catch (err) {
     }
};

export const updateNotificationAction = (formData: any, EmailID: any,callBackData:any) => async (dispatch:any) => {
  dispatch({
      type: NOTIFICATION_REQUEST
    });
    axios.put(`${config.url}/Notification/Update`, formData,
      {
        headers: headers
      })
      .then(response => {
        let successResponse = response.data;
        if (successResponse.Response_Status == "Success") {
          dispatch({
            type: NOTIFICATION_SUCCESS
          });
        let filter = {
          
            "Partner_Details_Id": EmailID.Partner_Details_Id,
            "PARTNER_EMAIL_ID":EmailID?.Email_Id, 
            "Records_Filter": "FILTER"
          }
          dispatch(getAllNotification(filter, callBackData))
        } else {
          const errors = {};
          dispatch({
            type: NOTIFICATION_FAIL,
            payload: successResponse.UI_Display_Message
          });
        }
      })
      .catch(error => {
        dispatch({
          type: NOTIFICATION_FAIL,
          payload: "Currently server is not working.Please try sometime later."
        });
      })
  };