import config from "./config";
import axios from 'axios';
const headers = config.headersCommon;
export const imageORfileupload = (imageData: any, fileData: any, callBackUploadFile: any) => async (dispatch: any) => {
  try {
    let { data } = await axios.post(
      `${config.url}/Partner_Upload/Upload?filename=${fileData.File_name}&fileextension=${fileData.ext}&functionality=${fileData.Functionality}&filetype=${fileData.File_Type}`,
      imageData,
      {
        headers: headers,
        transformRequest: (d) => d,
      }
    );
    if (data) {
      callBackUploadFile(data);
    }
  } catch (err) {
  }
};