import {
    ORDER_STATUS_UPDATE_REQUEST,
    ORDER_STATUS_UPDATE_SUCCESS,
    ORDER_STATUS_UPDATE_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL
   
} from '../../actions/shoppers/shoppersorderDetailsAction'
const initialState = {
    Orders: { all: [], error: '', isLoading: false },
    Orderdetails: { all: [], error: '', isLoading: false },
    orderStatusUpdate: { order: {}, error: '', isLoading: false },
};

export default function orderdetailsReducer(state = initialState, action) {
    switch (action.type) {
      case ORDER_STATUS_UPDATE_REQUEST:
            return { ...state, orderStatusUpdate: { orders: {}, error: '', isLoading: true } };
        case ORDER_STATUS_UPDATE_SUCCESS:
            return { ...state, orderStatusUpdate: { orders: action.payload, error: '', isLoading: false } };
        case ORDER_STATUS_UPDATE_FAIL:
            return { ...state, orderStatusUpdate: { orders: {}, error: action.payload, isLoading: false } };
            case ORDER_DETAILS_REQUEST:
                return { ...state, Orderdetails: { all: state.Orderdetails.all, error: '', isLoading: true } };
            case ORDER_DETAILS_SUCCESS:
                return { ...state, Orderdetails: { all: action.payload, error: '', isLoading: false } };
            case ORDER_DETAILS_FAIL:
                return { ...state, Orderdetails: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }
}
