import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateAddressAction, getAddressByIdAction } from '../../../state/actions/farmer/farmeraddressAction';
import { Card, TextInput, Checkbox, Text, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, Platform, RefreshControl, TouchableOpacity } from 'react-native';
import PhoneInput from 'react-native-phone-number-input';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Modal from "react-native-modal";
import AddressLocation from '../../../components/custom/location/address-location';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import Header from '../header';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import EsiCountry from '../../../components/custom/country-state-city/country';
import EsiState from '../../../components/custom/country-state-city/state';
import EsiCity from '../../../components/custom/country-state-city/city';
import isEmpty from '../../../state/validations/is-empty';

//AddresstypeOption and countryOption are used to get the data from dropdown
const AddresstypeOption = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Home',
        label: 'Home'
    },
    {
        value: 'Business',
        label: 'Business'
    },
    {
        value: 'Office',
        label: 'Office'
    }
];
const EditFarmerAddress = (props: any) => {
    const ID = props.route?.params?.id;
    const EID = props.route?.params?.ID;
    //navigation is used for navigation between screens
    const { navigation } = props
    const dispatch = useDispatch();
    //toast for popup after successful API calls.
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const [countryField, setCountryField] = React.useState();
    const [stateField, setStateField] = React.useState();
    //addresses is used to store the values from previous page using props
    const [typeDropDown, setTypeDropDown] = useState("");
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Address_Management === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
        setSeconds(2);
    }, [permissions, ID]);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    const [data, setData] = useState([]);
    const callBackData = (data: any) => {
        setData(data)
    }


    React.useEffect(() => {
        if (ID) {
            let formData = {
                Records_Filter: "FILTER",
                Partner_Address_Id: ID
            }
            dispatch(getAddressByIdAction(formData, callBackData))
        }
    }, [ID]);

    //isSubmitted contains the data from Update API using state call
    const isSubmited = useSelector(state => state.farmeraddress.addressUpdate.isLoading);

    //mobile
    const phoneInput = useRef(null);

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //reload
    const [seconds, setSeconds] = React.useState(0);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);


    //form reset
    const [reload, setReload] = React.useState(false);
    const [isLocationVisible, setIsLocationVisible] = React.useState(false);
    React.useEffect(() => {
        if (reload)
            setReload(false);
    }, [reload])
    /*====================================
            Handler
    ====================================*/

    const values = {
        Partner_Address_Id: data?.Partner_Address_Id,
        Partner_Details_Id: data?.Partner_Details_Id ? data?.Partner_Details_Id : "",
        Address_Type: data?.Address_Type ? data?.Address_Type : "",
        Country: data?.Country ? data?.Country : "",
        State: data?.State ? data?.State : "",
        City: data?.City_Id ? data?.City_Id : "",
        Zip: data?.Zip ? data?.Zip : "",
        Land_Mark: data?.Land_Mark ? data?.Land_Mark : "",
        Street: data?.Street ? data?.Street : "",
        Location: data?.Location ? data?.Location : "",
        Door_No: data?.Door_No ? data?.Door_No : "",
        Google_Map_Location: data?.Google_Map_Location ? data?.Google_Map_Location : "",
        Mobile: data?.Mobile_Number ? data?.Mobile_Number : "",
        Landline: data?.Landline ? data?.Landline : "",
        Latitude: data?.Latitude ? data?.Latitude : "",
        Longitude: data?.Longitude ? data?.Longitude : "",
        Is_Active: data?.Is_Active > 0 ? true : false,
        Is_Default: data?.Is_Default > 0 ? true : false,
        Is_Pickup_Available: data?.Is_Pickup_Available > 0 ? true : false,
        Is_Shipping_Available: data?.Is_Shipping_Available > 0 ? true : false,
        Email_Id: EmailID.Email_Id,
        Modified_By: EmailID.Email_Id
    }

    const esiColor = useSelector(state => state.theme);

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor

        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor

        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                {data && seconds <= 0 &&
                    <Formik enableReinitialize={true}
                        initialValues={values}
                        validationSchema={Yup.object().shape({
                            Address_Type: Yup.string().required('Address Type is required.'),
                            Country: Yup.string().required('Country is required.'),
                            State: Yup.string().required('State is required.'),
                            City: Yup.string().required('City is required.'),
                            Zip: Yup.string().min(1, "Please provide valid Pin code.")
                                .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Please provide valid Pin Code.')
                                .required('Pin Code is required.'),
                            Land_Mark: Yup.string().matches(/^[A-Za-z0-9 ]*$/, 'Please provide valid Land Mark.').required('Land Mark is required.'),
                            Street: Yup.string().matches(/^[A-Za-z0-9 ]*$/, 'Please provide valid Street.').required('Street is required.'),
                            Location: Yup.string().required('Location is required.'),
                            Door_No: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Door No.')
                                .required('Door No is required.'),
                            Mobile: Yup.string().min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number.").required("Mobile Number is required."),
                        })}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            let submitTest = true;
                            setSubmitting(false);
                            if (submitTest) {
                                let formValues = JSON.parse(JSON.stringify(values));
                                if (values.Is_Active) {
                                    formValues.Is_Active = 1;
                                } else {
                                    formValues.Is_Active = 0;
                                }
                                if (values.Is_Default) {
                                    formValues.Is_Default = 1;
                                } else {
                                    formValues.Is_Default = 0;
                                }
                                if (values.Is_Pickup_Available) {
                                    formValues.Is_Pickup_Available = 1;
                                } else {
                                    formValues.Is_Pickup_Available = 0;
                                }
                                if (values.Is_Shipping_Available) {
                                    formValues.Is_Shipping_Available = 1;
                                } else {
                                    formValues.Is_Shipping_Available = 0;
                                }
                                dispatch(updateAddressAction(formValues, navigation, toast));
                            }
                        }

                        }
                    >
                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any, setFieldValue: any }) => (
                            <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                    <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                                        onPress={() => {
                                            setReload(true);
                                            navigation.navigate('FarmerAddressDetails')
                                        }}>
                                        Go Back
                                    </Button>
                                </View>
                                <Card style={{
                                    width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                    marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                    backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                                }}>
                                    <Card.Content>
                                        <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                            Edit Address
                                        </Text>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Address_Type && errors.Address_Type) && { color: '#b00020' }]}>
                                                    Address Type
                                                </Text>
                                                {/* Address Type input field with validation*/}
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Address_Type && errors.Address_Type) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={AddresstypeOption}
                                                    maxHeight={AddresstypeOption.length > 1 ? 250 : 200}
                                                    search={AddresstypeOption.length > 1 ? true : false}
                                                    searchPlaceholder={'Select  Address Type '}
                                                    labelField="label"
                                                    valueField="value"
                                                    dropdownPosition="bottom"
                                                    value={values.Address_Type}
                                                    name="Address_Type"
                                                    onFocus={() => setTypeDropDown(true)}
                                                    onBlur={() => setTypeDropDown(false)}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Address_Type', item.value)
                                                        setTypeDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Address_Type && errors.Address_Type)}>
                                                    <ErrorMessage name="Address_Type" />
                                                </HelperText>
                                            </View>

                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Country && errors.Country) && { color: '#b00020' }]}>
                                                    Country
                                                </Text>
                                                {/*  country input field with validation*/}
                                                <EsiCountry
                                                    error={Boolean(touched.Country && errors.Country)}
                                                    labelField="NAME"
                                                    valueField="COUNTRY_CODE"
                                                    value={values.Country}
                                                    name="Country"
                                                    search
                                                    searchPlaceholder={'Select Country'}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Country', item.COUNTRY_CODE);
                                                        if (countryField != item.NAME)
                                                            setCountryField(item.COUNTRY_CODE);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Country && errors.Country)}>
                                                    <ErrorMessage name="Country" />
                                                </HelperText>
                                            </View>

                                        </View>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.State && errors.State) && { color: '#b00020' }]}>
                                                    State
                                                </Text>
                                                {/* state input field with validation*/}
                                                <EsiState
                                                    error={Boolean(touched.State && errors.State)}
                                                    labelField="NAME"
                                                    valueField="STATE_CODE"
                                                    value={values.State}
                                                    name="State"
                                                    search
                                                    searchPlaceholder={'Select State'}
                                                    CountryCode={countryField}
                                                    onUpdateValue={item => {
                                                        setFieldValue('State', item.STATE_CODE);
                                                        if (stateField != item.NAME)
                                                            setStateField(item.STATE_CODE);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.State && errors.State)}>
                                                    <ErrorMessage name="State" />
                                                </HelperText>
                                            </View>

                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.City && errors.City) && { color: '#b00020' }]}>
                                                    City
                                                </Text>
                                                {/* City input field with validation*/}
                                                <EsiCity
                                                    error={Boolean(touched.City && errors.City)}
                                                    labelField="NAME"
                                                    valueField="CITY_ID"
                                                    CountryCode={countryField}
                                                    StateCode={stateField || data.State}
                                                    value={values.City}
                                                    name="City"
                                                    search
                                                    searchPlaceholder={'Select City'}
                                                    onUpdateValue={(item) => {
                                                        setFieldValue('City', item.CITY_ID)
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.City && errors.City)}>
                                                    <ErrorMessage name="City" />
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Zip input field with validation*/}
                                                <TextInput
                                                    style={{ backgroundColor: esiColor.TextBC }}
                                                    selectionColor={esiColor.TextSelection}
                                                    theme={{
                                                        colors: {
                                                            primary: esiColor.TextHighlight,
                                                            text: esiColor.Text,
                                                            placeholder: esiColor.TextPlaceholder
                                                        }
                                                    }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    label='Pin Code'
                                                    mode='outlined'
                                                    name="Zip"
                                                    value={values.Zip}
                                                    onChangeText={handleChange('Zip')}
                                                    error={Boolean(touched.Zip && errors.Zip)}
                                                    maxLength={6}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Zip && errors.Zip)}>
                                                    <ErrorMessage name="Zip" />
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Land Mark input field with validation*/}
                                                <TextInput
                                                    style={{ backgroundColor: esiColor.TextBC }}
                                                    selectionColor={esiColor.TextSelection}
                                                    theme={{
                                                        colors: {
                                                            primary: esiColor.TextHighlight,
                                                            text: esiColor.Text,
                                                            placeholder: esiColor.TextPlaceholder
                                                        }
                                                    }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    label='Land Mark'
                                                    mode='outlined'
                                                    name="Land_Mark"
                                                    value={values.Land_Mark}
                                                    onChangeText={handleChange('Land_Mark')}
                                                    error={Boolean(touched.Land_Mark && errors.Land_Mark)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Land_Mark && errors.Land_Mark)}>
                                                    <ErrorMessage name="Land_Mark" />
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Street input field with validation*/}
                                                <TextInput
                                                    style={{ backgroundColor: esiColor.TextBC }}
                                                    selectionColor={esiColor.TextSelection}
                                                    theme={{
                                                        colors: {
                                                            primary: esiColor.TextHighlight,
                                                            text: esiColor.Text,
                                                            placeholder: esiColor.TextPlaceholder
                                                        }
                                                    }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    label='Street'
                                                    mode='outlined'
                                                    name="Street"
                                                    value={values.Street}
                                                    onChangeText={handleChange('Street')}
                                                    error={Boolean(touched.Street && errors.Street)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Street && errors.Street)}>
                                                    <ErrorMessage name="Street" />
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                {/* Door No input field with validation*/}
                                                <TextInput
                                                    style={{ backgroundColor: esiColor.TextBC }}
                                                    selectionColor={esiColor.TextSelection}
                                                    theme={{
                                                        colors: {
                                                            primary: esiColor.TextHighlight,
                                                            text: esiColor.Text,
                                                            placeholder: esiColor.TextPlaceholder
                                                        }
                                                    }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    label='Door No'
                                                    mode='outlined'
                                                    name="Door_No"
                                                    value={values.Door_No}
                                                    onChangeText={handleChange('Door_No')}
                                                    error={Boolean(touched.Door_No && errors.Door_No)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Door_No && errors.Door_No)}>
                                                    <ErrorMessage name="Door_No" />
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, flexShrink: 1, paddingTop: 15 }}>
                                                {/* Location input field with validation*/}
                                                <TouchableOpacity onPress={() => { setIsLocationVisible(!isLocationVisible); }}>
                                                    <TextInput
                                                        style={{ backgroundColor: esiColor.TextBC }}
                                                        selectionColor={esiColor.TextSelection}
                                                        theme={{
                                                            colors: {
                                                                primary: esiColor.TextHighlight,
                                                                text: esiColor.Text,
                                                                placeholder: esiColor.TextPlaceholder
                                                            }
                                                        }}
                                                        outlineColor={esiColor.TextOuterLine}
                                                        underlineColor={esiColor.TextUnderline}
                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                        label='Location'
                                                        mode='outlined'
                                                        name="Location"
                                                        value={values.Location}
                                                        onChangeText={(value) => {
                                                            setFieldValue("Location", value);
                                                        }}
                                                        error={Boolean(touched.Location && errors.Location)}
                                                        right={<TextInput.Icon icon={!(isEmpty(values.Latitude) || isEmpty(values.Longitude)) ? "map-marker-check-outline" : "map-marker-plus-outline"} onPress={() => {
                                                            setIsLocationVisible(!isLocationVisible);
                                                        }} color={!(isEmpty(values.Latitude) || isEmpty(values.Longitude)) ? "green" : "#27b6cc"} />}
                                                    />
                                                </TouchableOpacity>
                                                <HelperText type="error" visible={Boolean(touched.Location && errors.Location)}>
                                                    <ErrorMessage name="Location" />
                                                </HelperText>
                                            </View>

                                        </View>
                                        {/*Mobile number Field with Validation*/}
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                <TextInput style={{ width: "100%", margin: "auto", backgroundColor: esiColor.TextBC }}
                                                    selectionColor={esiColor.TextSelection}
                                                    theme={{
                                                        colors: {
                                                            primary: esiColor.TextHighlight,
                                                            text: esiColor.Text,
                                                            placeholder: esiColor.TextPlaceholder
                                                        }
                                                    }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    label="Mobile Number"
                                                    mode='outlined'
                                                    onChangeText={handleChange('Mobile')}
                                                    left={<TextInput.Affix textStyle={{ color: esiColor.DescColor }} text="+91" />}
                                                    value={values.Mobile}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Mobile && errors.Mobile)}>
                                                    {touched.Mobile && errors.Mobile}
                                                </HelperText>
                                            </View>

                                            {/*  Landline number Field with Validation*/}
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                <TextInput
                                                    style={{ backgroundColor: esiColor.TextBC }}
                                                    theme={{
                                                        colors: {
                                                            primary: esiColor.TextHighlight,
                                                            text: esiColor.Text,
                                                            placeholder: esiColor.TextPlaceholder
                                                        }
                                                    }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    label='Landline Number'
                                                    mode='outlined'
                                                    maxLength={13}
                                                    keyboardType='numeric'
                                                    name="Landline"
                                                    value={values.Landline}
                                                    onChangeText={handleChange('Landline')}
                                                    error={Boolean(touched.Landline && errors.Landline)}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Landline && errors.Landline)}>
                                                    <ErrorMessage name="Landline" />
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", paddingTop: 15, paddingBottom: 15 }}>
                                            {/* Is Active checkbox field*/}
                                            <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                                <Checkbox.Android
                                                    color={esiColor.itemColor}
                                                    uncheckedColor={esiColor.SBorderColor}
                                                    name="Is_Active"
                                                    status={values.Is_Active ? 'checked' : 'unchecked'}
                                                    onPress={() => {
                                                        setFieldValue("Is_Active", !values.Is_Active);
                                                    }}
                                                />
                                                <Text style={{ marginTop: 10, color: esiColor.DescColor }}>Is Active</Text>
                                            </View>

                                            {/* Is Default checkbox field*/}
                                            <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                                <Checkbox.Android
                                                    color={esiColor.itemColor}
                                                    uncheckedColor={esiColor.SBorderColor}
                                                    name="Is_Default"
                                                    status={values.Is_Default ? 'checked' : 'unchecked'}
                                                    onPress={() => {
                                                        setFieldValue("Is_Default", !values.Is_Default);
                                                    }}
                                                />
                                                <Text style={{ marginTop: 10, color: esiColor.DescColor }}>Is Default</Text>
                                            </View>

                                            {/* Is Pickup Available checkbox field*/}
                                            <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                                <Checkbox.Android
                                                    color={esiColor.itemColor}
                                                    uncheckedColor={esiColor.SBorderColor}
                                                    name="Is_Pickup_Available"
                                                    status={values.Is_Pickup_Available ? 'checked' : 'unchecked'}
                                                    onPress={() => {
                                                        setFieldValue("Is_Pickup_Available", !values.Is_Pickup_Available);
                                                    }}
                                                />
                                                <Text style={{ marginTop: 10, color: esiColor.DescColor }}>Is Available</Text>
                                            </View>

                                            {/* Is Shipping Available checkbox field*/}
                                            <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                                <Checkbox.Android
                                                    color={esiColor.itemColor}
                                                    uncheckedColor={esiColor.SBorderColor}
                                                    name="Is_Shipping_Available"
                                                    status={values.Is_Shipping_Available ? 'checked' : 'unchecked'}
                                                    onPress={() => {
                                                        setFieldValue("Is_Shipping_Available", !values.Is_Shipping_Available);
                                                    }}
                                                />
                                                <Text style={{ marginTop: 10, color: esiColor.DescColor }}>Is Shipping Available</Text>
                                            </View>

                                        </View>
                                        <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                            <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                                <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                                            </Button>
                                        </View>

                                    </Card.Content>
                                </Card>
                                <Modal animationIn={"slideInDown"}
                                    deviceWidth={dimensions.width}
                                    deviceHeight={dimensions.height}
                                    style={{ alignItems: 'center' }} isVisible={isLocationVisible}>
                                    <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                                        <AddressLocation isLocationVisible={isLocationVisible} setIsLocationVisible={setIsLocationVisible} filters={{
                                            Latitude: values.Latitude,
                                            Longitude: values.Longitude
                                        }}
                                            setFilters={(address, lan, lon, zip) => {
                                                setFieldValue("Location", address);
                                                setFieldValue("Latitude", lan);
                                                setFieldValue("Longitude", lon);
                                                setFieldValue("Zip", zip);
                                                setIsLocationVisible(!isLocationVisible);
                                            }}

                                        />
                                    </View>
                                </Modal>
                            </View>
                        )}
                    </Formik>
                }
            </ScrollView>
        </Surface>
    );
};



export default EditFarmerAddress;