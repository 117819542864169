import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image, RefreshControl } from 'react-native';
import { Dialog, Card, DataTable, Button, Searchbar, Portal, Surface, IconButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import { getAllSettingsAction, settingFilter, deleteSettingsAction, resetFilter } from '../../state/actions/settingsAction';
import Header from '../auth/components/header';
import RenderHtml from 'react-native-render-html';
import isEmpty from '../../state/validations/is-empty';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';

//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Setting_Id',
        label: 'Setting ID'
    },
    {
        value: 'Field_Name',
        label: 'Field Name'
    },
    {
        value: 'Field_Value',
        label: 'Field Value'
    },
    {
        value: 'Status',
        label: 'Status'
    },
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function SettingsDetails({ navigation }: { navigation: any }) {

    //settings contains the data from  GET API using state call
    const settings = useSelector(state => state.setting.settings.all);
    //settingMenu is used to store the cache data in Menu select using state call
    let settingMenu = useSelector(state => state.setting.settingFilter.select);
    //settingSearch is used to store the cache data in search using state call
    let settingSearch = useSelector(state => state.setting.settingFilter.search);
    //toast for popup after successful API calls.
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);

    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);
    const [setting, setSetting] = React.useState();
    //API dispatch
    const dispatch = useDispatch()
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    //Static formData
    const MINUTE_MS = 20000;
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (isEmpty(settings)) {
                let formData = {
                    Records_Filter: "FILTER",
                    Partner_Details_Id: EmailID?.Partner_Details_Id
                };
                setSetting(settings);
                handleSearch("");
                dispatch(getAllSettingsAction(formData))
                dispatch(settingFilter('search', ''));
                dispatch(settingFilter('select', 'All'));
            }
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [settings])

    //delete setting
    const deleteSetting = (setting: any) => {
        if (setting) {
            const deleteData = {
                Partner_Details_Id: setting.Partner_Details_Id,
                Setting_Id: setting.Setting_Id
            };
            dispatch(deleteSettingsAction(deleteData, toast));
        }
    };

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });


    //sorting, pagination & search
    const [page, setPage] = React.useState(0);
    const [sort, setSort] = React.useState(false);
    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, settings?.length);

    const [oldSearch, setOldSearch] = React.useState(0);

    const checkSettingsForTable = () => {

        if (settingSearch) {
            try {
                if (settingMenu === "All") {
                    return settings.filter(x => {
                        let keys = ["Setting_Id", "Field_Name", "Field_Value", "Status", "Purpose"];
                        for (let i = 0; i < keys.length; i++) {
                            try {
                                if (x[keys[i]].toLowerCase().includes(settingSearch.toLowerCase())) {
                                    return true;
                                }
                            } catch (error) {
                                if (x[keys[i]].toString().toLowerCase().includes(settingSearch.toLowerCase())) {
                                    return true;
                                }
                            }
                        }
                    });

                } else {
                    return settings.filter(x => (x[settingMenu] ? x[settingMenu].toLowerCase() : '').includes(settingSearch.toLowerCase()));
                }
            } finally {
                if (page > 0) {
                    if (oldSearch !== settingSearch.length) {
                        setOldSearch(settingSearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch !== settingSearch.length) {
                    setOldSearch(settingSearch.length);
                    setPage(0);
                }
            }
        }
        else {
            return settings
        }
    }
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Setting_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
            return -1;
        }
        if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {

            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // View Dialog
    const [visible, setVisible] = React.useState(false);
    const [settingData, setData] = React.useState();

    const hideDialog = () => setVisible(false);

    const handleChange = itemValue => {
        dispatch(settingFilter('select', itemValue));
    };
    const handleSearch = value => {
        dispatch(settingFilter('search', value));
    };

    const data = stableSort(
        checkSettingsForTable(), getComparator(order, orderBy))

    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto' }}>
                    <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                        <IconButton icon="close" size={20} onPress={() => setVisible(!visible)} />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                        <Text style={{ color: '#27B6CC' }}>View Settings</Text>
                    </Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 7 }}>
                            <Dialog.Content style={{ marginLeft: -30 }}>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Setting ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: '#27B6CC' }}>{settingData?.Setting_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Field Name</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{settingData?.Field_Name} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Field Value</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{settingData?.Field_Value} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Status</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{settingData?.Status} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Purpose</Text></View>
                                    <View style={{ flex: 2.5 }}><RenderHtml source={{ html: settingData?.Purpose }} /></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Created By</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: '#27B6CC' }}>{settingData?.Created_By} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Created Date</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{settingData?.Created_Date} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Modified By</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: '#27B6CC' }}>{settingData?.Modified_BY} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Modified Date</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{settingData?.Modified_Date} </Text></View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>

            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                    <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                        navigation.navigate('Dashboard')
                        handleSearch("")
                        dispatch(resetFilter())
                    }
                    }>
                        Go Back
                    </Button>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: "column" }}>
                        <Text style={{ fontSize: 22, fontWeight: "bold", paddingTop: 20, paddingBottom: 20, paddingLeft: 15 }}>Partner Settings</Text>
                    </View>
                    {dimensions <= 700 ?
                        <View >
                            {(permissions.Settings === "write") && <View style={{ flexWrap: "wrap", flexShrink: 1, marginLeft: 10, marginRight: 14, marginTop: 10, marginBottom: 20 }}>
                                <Button style={{ backgroundColor: '#27B6CC' }} mode="contained" onPress={() => navigation.navigate('AddSetting')}>
                                    ADD SETTINGS
                                </Button>
                            </View>}
                        </View>
                        : null}
                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 500 ? 1 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={filterOptions}
                                containerStyle={style.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={settingMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                            <Picker
                                selectedValue={settingMenu}
                                style={{ height: 51, width: dimensions <= 500 ? 130 : 130, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', flexShrink: 1, backgroundColor: '#ffff' }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Setting ID" value="Setting_Id" />
                                <Picker.Item label="Field Name" value="Field_Name" />
                                <Picker.Item label="Field Value" value="Field_Value" />
                                <Picker.Item label="Status" value="Status" />
                            </Picker>
                        </View>
                    }
                    <View style={{ flex: dimensions <= 500 ? 0.2 : 0.1 }} />
                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{ borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7' }}
                            placeholder="Search"
                            onChangeText={(value) => handleSearch(value)}
                            value={settingSearch}
                        />
                    </View>
                    <View style={{ flex: dimensions <= 700 ? null : 0.13 }} />

                    <View style={{ flex: dimensions <= 700 ? null : 0.5 }}>
                        {(permissions.Settings === "write") &&
                            (dimensions >= 700 ?
                                <View style={{ flexWrap: "wrap", flexShrink: 1, marginLeft: 10, marginRight: 14, marginTop: 5 }}>
                                    <Button style={{ backgroundColor: '#27B6CC' }} mode="contained" onPress={() => navigation.navigate('AddSetting')}>
                                        ADD SETTINGS
                                    </Button>
                                </View> : null)}
                    </View>

                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    <ScrollView horizontal={dimensions >= 700 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title style={style.title} active={orderBy === 'Setting_Id'}
                                            sortDirection={orderBy === 'Setting_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Setting_Id')}><Text style={style.titletext}>Setting ID</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Field_Name'}
                                            sortDirection={orderBy === 'Field_Name' ? order : 'ascending'}
                                            onPress={createSortHandler('Field_Name')}><Text style={style.titletext}>Field Name</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Field_Value'}
                                            sortDirection={orderBy === 'Field_Value' ? order : 'ascending'}
                                            onPress={createSortHandler('Field_Value')}><Text style={style.titletext}>Field Value</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Status'}
                                            sortDirection={orderBy === 'Status' ? order : 'ascending'}
                                            onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        data.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((setting, index) => (
                                            <DataTable.Row style={style.databeBox} key={setting.Setting_Id}>
                                                <DataTable.Cell style={style.tablerow}>{setting.Setting_Id}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{setting.Field_Name}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{setting.Field_Value}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{setting.Status}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    {(permissions.Settings === "write") && (<IconButton icon="square-edit-outline" size={20} color='#27B6CC' onPress={() => navigation.navigate("EditSetting", { ID: setting.Setting_Id })} />
                                                    )}
                                                    <IconButton icon="eye" size={20} color='#27B6CC' onPress={(e) => { setData(setting); setVisible(!visible) }} />
                                                    {(permissions.Settings === "write") && <IconButton icon="delete" size={20} color='#27B6CC' onPress={(e) => deleteSetting(setting)} />
                                                    }</DataTable.Cell>
                                            </DataTable.Row>
                                        ))
                                    }
                                    {isEmpty(data) &&
                                        <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20 }}>
                                                No records found!
                                            </Text>
                                            <Image source={require('../../assets/images/nodata.gif')}
                                                style={{ width: 300, height: 300, alignSelf: "center" }} />
                                        </View>
                                    }
                                    <DataTable.Pagination
                                        page={page}
                                        numberOfPages={Math.ceil(settings?.length / numberOfItemsPerPage)}
                                        onPageChange={page => setPage(to < data?.length ? page : 0)}
                                        label={settings.length ? `${from + 1}-${to} of ${data?.length}` : '0-0'}
                                        showFastPaginationControls={to > data?.length ? false : true}
                                        numberOfItemsPerPageList={numberOfItemsPerPageList}
                                        numberOfItemsPerPage={numberOfItemsPerPage}
                                        onItemsPerPageChange={onItemsPerPageChange}
                                        selectPageDropdownLabel={'Rows per page'}
                                    />
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>
                </View>
            </ScrollView>
        </Surface>

    );
}

const style = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontWeight: "bold",
        fontSize: 12,
        justifyContent: "center",
        textAlign: "center",
        width: 160
    },
    titletext: {
        fontWeight: "bold",
        fontSize: 14,
        color: 'black',
    },
    tablerow: {
        justifyContent: "center",
        textAlign: "center",
        width: 140
    },
    mainbox: {
        textAlign: 'center',
        margin: 15,
        flex: 1,
        justifyContent: 'space-between',
    },
    databeBox: {
        margin: 10,
        textAlign: 'center',
    },
    databeHeader: {
        margin: 10,
        textAlign: 'center',
    },
    dropdown: {
        height: 50,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    containerstyle: {
        width: 150
    },
});

export default SettingsDetails