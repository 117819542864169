import axios from 'axios';
import { meeapi } from '../node-actions/meeapi';
import config from '../config';

export const BIDDING_SERVICES_REQUEST = "BIDDING_SERVICES_REQUEST";
export const BIDDING_SERVICES_SUCCESS = "BIDDING_SERVICES_SUCCESS";
export const BIDDING_SERVICES_FAIL = "BIDDING_SERVICES_FAIL";

export const BIDDING_SERVICES_AVAILABLE_UPDATE_REQUEST = "BIDDING_SERVICES_AVAILABLE_UPDATE_REQUEST";
export const BIDDING_SERVICES_AVAILABLE_UPDATE_SUCCESS = "BIDDING_SERVICES_AVAILABLE_UPDATE_SUCCESS";
export const BIDDING_SERVICES_AVAILABLE_UPDATE_FAIL = "BIDDING_SERVICES_AVAILABLE_UPDATE_FAIL";

export const BIDDING_SINGLE_SERVICES_REQUEST = "BIDDING_SINGLE__SERVICES_REQUEST";
export const BIDDING_SINGLE__SERVICES_FAIL = "BIDDING_SINGLE__SERVICES_FAIL";


export const GENERIC_SERVICES_REQUEST = "GENERIC_SERVICES_REQUEST";
export const GENERIC_SERVICES_FAIL = "GENERIC_SERVICES_FAIL";


export const PARTNER_BIDDING_ADDRESS_REQUEST = "PARTNER_BIDDING_ADDRESS_REQUEST";
export const PARTNER_BIDDING_ADDRESS_FAIL = "PARTNER_BIDDING_ADDRESS_FAIL";

export const BIDDING_SERVICES_FILTER = "BIDDING_SERVICES_FILTER";
export const BIDDING_SERVICES_CREATE_REQUEST = "BIDDING_SERVICES_CREATE_REQUEST";
export const BIDDING_SERVICES_CREATE_SUCCESS = "BIDDING_SERVICES_CREATE_SUCCESS";
export const BIDDING_SERVICES_CREATE_FAIL = "BIDDING_SERVICES_CREATE_FAIL";

export const BIDDING_SERVICES_UPDATE_REQUEST = "BIDDING_SERVICES_UPDATE_REQUEST";
export const BIDDING_SERVICES_UPDATE_SUCCESS = "BIDDING_SERVICES_UPDATE_SUCCESS";
export const BIDDING_SERVICES_UPDATE_FAIL = "BIDDING_SERVICES_UPDATE_FAIL";

/*=============================================================
                   Add User Action
 ===============================================================*/
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
/*=============================================================
                   Get All User Action
===============================================================*/
export const getAllBiddingServicessAction = (formData: any, pagination = {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: BIDDING_SERVICES_REQUEST
    });
    meeapi.post(urlGenarator(`/Bidding_Available_Services/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BIDDING_SERVICES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: BIDDING_SERVICES_SUCCESS,
          payload: data
        });

      }
    })
  } catch (err) {
    dispatch({
      type: BIDDING_SERVICES_FAIL,
      payload: err
    });
  }
};

export const updateserviceavailablility = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: BIDDING_SERVICES_AVAILABLE_UPDATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Bidding_Available_Services/Update`,
    formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
     
        dispatch({
          type: BIDDING_SERVICES_AVAILABLE_UPDATE_SUCCESS
        });
        toast.show({ message: 'Service Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "Partner_Bidding_Available_Service_Id",
          SortOrder: "DESC"
        };
        const formValue = {
          "search_by_filter": '',
          "search": "",
          "Partner_Bidding_Available_Service_Id": "",
          "Bidding_Service_Id": "",
          "Partner_Detail_Id": formData?.Partner_Detail_Id,
          "Partner_Address_Id": "",
          "Records_Filter": "FILTER",
          "Is_Service_Available": "",
        };
        dispatch(getAllBiddingServicessAction(formValue, pagination))
        navigation.navigate('PartnerBiddingServices')
      } else {
        dispatch({
          type: BIDDING_SERVICES_AVAILABLE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_SERVICES_AVAILABLE_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};

export const getBiddingserviceIdAction = (formData: any, callBackData: any, pagination: any) => async dispatch => {
  try {
    dispatch({
      type: BIDDING_SINGLE_SERVICES_REQUEST
    });
    meeapi.post(urlGenarator(`/Bidding_Available_Services/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data) {
        callBackData(data.results)
      }
    })
  } catch (err) {
    dispatch({
      type: BIDDING_SINGLE__SERVICES_FAIL,
      payload: err
    });
  }
};



export const getAllAddressforBiddingAction = (formData: any, callBackData: any, pagination: any) => async dispatch => {
  try {
    dispatch({
      type: PARTNER_BIDDING_ADDRESS_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Address/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data) {
        callBackData(data.results)
      }
    })
  } catch (err) {
    dispatch({
      type: PARTNER_BIDDING_ADDRESS_FAIL,
      payload: err
    });
  }
};
export const getGenericservicesAction = (formData: any, callBackData: any, pagination: any) => async dispatch => {
  try {
    dispatch({
      type: GENERIC_SERVICES_REQUEST
    });
    meeapi.post(urlGenarator(`/Bidding_Services/Get`,pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data) {
        callBackData(data.results)
      }
    })
  } catch (err) {
    dispatch({
      type: GENERIC_SERVICES_FAIL,
      payload: err
    });
  }
};

export const AddBiddingservicesAction = (formData: any, navigation: any, setErrorMessage: any, toast: any) => async dispatch => {
  dispatch({
    type: BIDDING_SERVICES_CREATE_REQUEST
  });
  setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  axios.post(`${config.nodecudurl}/Bidding_Available_Services/Create`, formData, {
    headers: cudheaders
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: BIDDING_SERVICES_CREATE_SUCCESS
        });
        toast.show({
          message: 'Bidding service added successfully.', type: 'info', duration: 3000, position: 'top'
        });
        navigation.navigate('PartnerBiddingServices')
     const pagination= { PageNo: 1, PageSize: 5, SortBy: "Partner_Bidding_Available_Service_Id", SortOrder: "DESC" };
   
        const formValue = {
          "search_by_filter": '',
          "search": "",
          "Partner_Bidding_Available_Service_Id": "",
          "Bidding_Service_Id": "",
          "Partner_Detail_Id": formData.Partner_Detail_Id,
          "Partner_Address_Id": "",
          "Records_Filter": "FILTER",
          "Is_Service_Available": "",
        };
        dispatch(getAllBiddingServicessAction(formValue, pagination));
         } else {
        setErrorMessage({ Email_Id: { key: successResponse.Email_Id ? successResponse.Email_Id : '', message: successResponse.Email_Id ? successResponse.UI_Display_Message : '' }, Mobile: { key: successResponse.Mobile_Number ? successResponse.Mobile_Number : '', message: successResponse.Mobile_Number ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Mobile_Number || successResponse.Email_Id) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: BIDDING_SERVICES_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: BIDDING_SERVICES_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};


/*=============================================================
                   User Delete
===============================================================*/

// Filter 
export const employeeFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: BIDDING_SERVICES_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//Search Filter
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: BIDDING_SERVICES_FILTER,
      fType: "search",
      value: "",
    });
  }
}
/*=============================================================
                   Get All User Action
===============================================================*/