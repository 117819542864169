import React, { useState, useEffect } from 'react';
import { Card, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    getAllBrands,
    getAllCategories,
    getAllSubCategories,
    productsdata,
} from '../../../../state/actions/productsAction';
import EsiEditor from '../../../../components/custom/richtext-editor/richtext-editor';
import Header from '../../../auth/components/header';
import isEmpty from '../../../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const EditProductpagethree = (props: any) => {
    const [errorMessage, setErrorMessage] = useState({ Product_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    const { navigation } = props;
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const product_id = props.route?.params?.product_id;
    const productmaster = useSelector(state => state.product.productdata.products);
    const EmailID = useSelector(state => state.auth?.user);
    const esiColor = useSelector(state => state.theme);

    const dispatch = useDispatch();

    const [rhSpecificationsMin, setRhSpecificationsMin] = useState(20);
    const [specificationsvalue, setSpecificationsValue] = React.useState();

    const [rhEsiReviewMin, setRhEsiReviewMin] = useState(20);
    const [EsiReviewvalue, setEsiReviewValue] = React.useState();
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    useEffect(() => {
        dispatch(getAllCategories());
        dispatch(getAllBrands());
        dispatch(getAllSubCategories());
    }, []);


    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    React.useEffect(() => {
        if (isEmpty(product_id)) {
            navigation.navigate('EditProduct')
        } else if (!isEmpty(productmaster)) {
            setEsiReviewValue(productmaster.Esi_Reviews)
            setSpecificationsValue(productmaster.Specifications)

        } else {
            navigation.navigate('EditProduct', { product_id: product_id })
        }
    }, [product_id, productmaster]
    );

    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    return (
        <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }}  >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }>
                {specificationsvalue &&
                    <Formik enableReinitialize={true}
                        initialValues={{
                            Product_Name: productmaster.Product_Name,
                            Category_Id: productmaster.Category_Id,
                            Product_Id: productmaster.Product_Id,
                            Brand_Id: productmaster.Brand_Id,
                            Product_Short_Description: productmaster.Product_Short_Description,
                            Product_Description: productmaster.Product_Description,
                            Features: productmaster.Features,
                            Specifications: productmaster.Specifications,
                            Product_Price: productmaster.Product_Price,
                            Product_Size_Id: productmaster.Product_Size_Id,
                            Product_Color_Variant_Id: productmaster.Product_Color_Variant_Id,
                            Product_Master_Id: productmaster.Product_Master_Id,
                            Is_Popular: productmaster.Is_Popular > 0 ? true : false,
                            Whats_Hot: productmaster.Whats_Hot > 0 ? true : false,
                            Is_Top_Product: productmaster.Is_Top_Product > 0 ? true : false,
                            Esi_Review: productmaster.Esi_Review,
                            Esi_Rating: Number(productmaster.Esi_Rating),
                            Country_Of_Origin: productmaster.Country_Of_Origin,
                            Status: productmaster.Status,
                            Is_Upcoming: productmaster.Is_Upcoming > 0 ? true : false,
                            Is_Verified: productmaster.Is_Verified > 0 ? true : false,
                            Sub_Category_Id: productmaster.Sub_Category_Id,
                            Modified_By_Email: EmailID.Email_Id,
                            Warranty_Period: productmaster.Warranty_Period,
                        }}
                        validationSchema={Yup.object().shape({
                        })}

                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            let submitTest = true;
                            setSubmitting(false);
                            const errors = {};
                            values.Specifications = specificationsvalue;
                            if (!values.Specifications || values.Specifications.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
                                submitTest = false;
                                errors.Specifications = "Specifications are required.";
                            }
                            else if (values.Specifications.replace(/(<([^>]+)>)/ig, '').trim().length < rhSpecificationsMin) {
                                submitTest = false;
                                errors.Specifications = "Please provide at least " + rhSpecificationsMin + " characters";
                            }
                            setErrors(errors);
                            if (submitTest) {
                                let formValues = JSON.parse(JSON.stringify(values));
                                dispatch(productsdata(formValues, navigation));
                                navigation.navigate('editproducts', { product_id: product_id })
                            }
                        }
                        }
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <View style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                backgroundColor: esiColor.BackgroundColor
                            }}>
                                <View style={{
                                    width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%"
                                }}>
                                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('EditProducts', { product_id: product_id })} >
                                        Go Back
                                    </Button>
                                </View>
                                <Card style={{
                                    width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                    marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                    backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                                }}>
                                    <Card.Content>
                                        <Text style={{ fontSize: 20, fontWeight: "bold", marginBottom: '1%', color: esiColor.brandFontColor }}>
                                            Edit Product
                                        </Text>
                                        <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>
                                            <View style={{ flexShrink: 1, width: 730 }}>
                                                <View style={{ paddingTop: Platform.OS !== "web" ? 10 : 10, paddingBottom: Platform.OS !== "web" ? 10 : null }}>
                                                    <EsiEditor label={"Specifications"} value={specificationsvalue} onUpdateValue={(value) => { setSpecificationsValue(value) }} />
                                                    <HelperText type="error" visible={(!specificationsvalue || specificationsvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhSpecificationsMin) && touched.Specifications
                                                        && errors.Specifications}>
                                                        {(!specificationsvalue || specificationsvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhSpecificationsMin) && touched.Specifications
                                                            && errors.Specifications}
                                                    </HelperText>
                                                </View>
                                                <View style={{ paddingTop: "2%", flexDirection: "row" }}>
                                                </View>
                                            </View>
                                        </View>
                                    </Card.Content>
                                    <View style={{ marginLeft: "1%" }}>
                                        <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", marginVertical: 10 }}>
                                        <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                            Next
                                        </Button>
                                    </View>
                                </Card>
                            </View>
                        )}
                    </Formik>
                }
            </ScrollView >
        </Surface >
    );
};

export default EditProductpagethree;