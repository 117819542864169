import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';


export const PRODUCTCOLORVARIANTS_REQUEST = "PRODUCTCOLORVARIANTS_REQUEST";
export const PRODUCTCOLORVARIANTS_SUCCESS = "PRODUCTCOLORVARIANTS_SUCCESS";
export const PRODUCTCOLORVARIANTS_FAIL = "PRODUCTCOLORVARIANTS_FAIL";
export const PRODUCTCOLORVARIANT_GET_REQUEST = "PRODUCTCOLORVARIANT_GET_REQUEST";
export const PRODUCTCOLORVARIANT_GET_SUCCESS = "PRODUCTCOLORVARIANT_GET_SUCCESS";
export const PRODUCTCOLORVARIANT_GET_FAIL = "PRODUCTCOLORVARIANT_GET_FAIL";
export const PRODUCTCOLORVARIANT_CREATE_REQUEST = "PRODUCTCOLORVARIANT_CREATE_REQUEST";
export const PRODUCTCOLORVARIANT_CREATE_SUCCESS = "PRODUCTCOLORVARIANT_CREATE_SUCCESS";
export const PRODUCTCOLORVARIANT_CREATE_FAIL = "PRODUCTCOLORVARIANT_CREATE_FAIL";
export const PRODUCTCOLORVARIANT_UPDATE_REQUEST = "PRODUCTCOLORVARIANT_UPDATE_REQUEST";
export const PRODUCTCOLORVARIANT_UPDATE_SUCCESS = "PRODUCTCOLORVARIANT_UPDATE_SUCCESS";
export const PRODUCTCOLORVARIANT_UPDATE_FAIL = "PRODUCTCOLORVARIANT_UPDATE_FAIL";
export const PRODUCTCOLORVARIANT_DELETE_REQUEST = "PRODUCTCOLORVARIANT_DELETE_REQUEST";
export const PRODUCTCOLORVARIANT_DELETE_SUCCESS = "PRODUCTCOLORVARIANT_DELETE_SUCCESS";
export const PRODUCTCOLORVARIANT_DELETE_FAIL = "PRODUCTCOLORVARIANT_DELETE_FAIL";

export const PRODUCT_MASTER_REQUEST = "PRODUCT_MASTER_REQUEST";
export const PRODUCT_MASTER_SUCCESS = "PRODUCT_MASTER_SUCCESS";
export const PRODUCT_MASTER_FAIL = "PRODUCT_MASTER_FAIL";

export const PRODUCTCOLORVARIANT_REQUEST = "PRODUCTCOLORVARIANT_REQUEST";
export const PRODUCTCOLORVARIANT_FAIL = "PRODUCTCOLORVARIANT_FAIL";

export const PRODUCTCOLORVARIANTS_FILTER = "PRODUCTCOLORVARIANTS_FILTER";

/*=============================================================
                  Add Wallet Action
===============================================================*/
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
export const invalid_data = () => {
  return {
    type: RESET
  }
}
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
      queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}


export const createProductColorVariantAction = (formData: any, productcolors: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: PRODUCTCOLORVARIANT_CREATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Product_Management/Color_Variant_Create`,
    formData,
    {
      headers: cudheaders
    }
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {
        dispatch({
          type: PRODUCTCOLORVARIANT_CREATE_SUCCESS
        });
        const formValue = {
          "search": '',
          "Color": "",
          "Product_Color_Variant_Id": "",
          "Product_Master_Id": productcolors,
          "Records_Filter": "FILTER",
          "Status": ""
        };
        const pagination ={
          PageNo: 1,
           PageSize: 5, 
           SortBy: "PRODUCT_COLOR_VARIANT_ID", 
           SortOrder: "ASC"
        }
        dispatch(getAllProductColorVariantAction(formValue,pagination))
        navigation.navigate('ProductColors', { ID: productcolors });
        toast.show({ message: 'Product Color Added Successfully.', type: 'info', duration: 3000, position: 'top' });

      } else {
        dispatch({
          type: PRODUCTCOLORVARIANT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });

    
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTCOLORVARIANT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Update User Action
===============================================================*/

export const updateProductColorVariantAction = (formData: any, productcolors: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: PRODUCTCOLORVARIANT_UPDATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Product_Management/Color_Variant_Update`,
    formData,
    {
      headers: cudheaders
    }
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PRODUCTCOLORVARIANT_UPDATE_SUCCESS
        });
        const formValue = {
          "search": '',
          "Color": "",
          "Product_Color_Variant_Id": "",
          "Product_Master_Id":formData.Product_Master_Id,
          "Records_Filter": "FILTER",
          "Status": ""
        };
        const pagination ={
          PageNo: 1,
           PageSize: 5, 
           SortBy: "PRODUCT_COLOR_VARIANT_ID", 
           SortOrder: "ASC"
        }
        dispatch(getAllProductColorVariantAction(formValue,pagination))
        navigation.navigate('ProductColors', { ID: productcolors });
        toast.show({ message: 'Product Color Updated Successfully.', type: 'info', duration: 3000, position: 'top' });

      } else {
        dispatch({
          type: PRODUCTCOLORVARIANT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCTCOLORVARIANT_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                Get All Wallet Action
===============================================================*/

export const getAllProductColorVariantAction = (formData: any,pagination = {}) => async dispatch => {
  try {
    dispatch({
      type: PRODUCTCOLORVARIANTS_REQUEST
    });
    meeapi.post(urlGenarator(`Product_Management/Color_Variant_Get`,pagination),
    formData, 
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {  
        dispatch({
          type: PRODUCTCOLORVARIANTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCTCOLORVARIANTS_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: PRODUCTCOLORVARIANTS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Wallet Delete
===============================================================*/

export const deleteProductColorVariantAction = (formData: any, Product_Master_id: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(`${config.url}/Products/ColorVariantDelete`,
      formData,
      {
        headers: headers
      }
    );
    if (data) {
      toast('Product color variant deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      const formData = {
        Records_Filter: 'FILTER',
        Product_Master_Id: Product_Master_id
      };
      const pagination ={
        PageNo: 1,
         PageSize: 5, 
         SortBy: "PRODUCT_COLOR_VARIANT_ID", 
         SortOrder: "ASC"
      }
      dispatch(getAllProductColorVariantAction(formData,pagination));
    }
  } catch (err) {
  }
};
// Filter 
export const productcolorvariantFilter = (fType: any, value: any) => {
  return function (dispatch) {
    dispatch({
      type: PRODUCTCOLORVARIANTS_FILTER,
      fType: fType,
      value: value,
    });
  }
}

// Search Filter
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: PRODUCTCOLORVARIANTS_FILTER,
     fType: "search", 
      value: "",
    });
  }
}
/*=============================================================
                Get All Masters 
===============================================================*/
export const getAllMasters = () => async dispatch => {
  try {
    let formData = {
      Active: "",
      Brand: " ",
      Category: " ",
      Msrp: " ",
      Product_Master_Id: " ",
      Product_Name: " ",
      Records_Filter: " ",
      Sku: " ",
      Stock_Status: " ",
      Upc_Code: " "
    };
    dispatch({
      type: PRODUCT_MASTER_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Products/Product_Master_Get_Admin`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_MASTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_MASTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_MASTER_FAIL,
      payload: err
    });
  }
};


export const getProductColorByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
      dispatch({
          type: PRODUCTCOLORVARIANT_REQUEST
      });
      let { data } = await axios.post(
          `${shopperconfig.nodePartnerUrl}/Product_Management/Color_Variant_Get`,
          formData,
          {
              // Axios looks for the `auth` option, and, if it is set, formats a
              // basic auth header for you automatically.
              headers: nodeheaders
          }
      );

      if (data) {
          if (data.Is_Data_Exist === "0") {
            callBackData([])
          } else { 
           callBackData(data.results[0])
          }
      }
  } catch (err) {
      dispatch({
          type: PRODUCTCOLORVARIANT_FAIL,
          payload: err
      });
  }
};