import { v4 as uuid } from 'uuid';
import partner_payment from "../../assets/static/Dashboard/partner_payment.jpg"

export default [
  {
    id: uuid(),
    link: 'PartnerpaymentGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Partner payment settlements provides the information about the order amount, cashback amount and the payment status related to partner payments.',
    media: partner_payment,
    title: 'Partner Payments',
    total: ''
  },
];