import 'react-native-gesture-handler';
import * as React from 'react';
import { View, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { Card, Button, Text, Surface, ActivityIndicator } from 'react-native-paper';
import isEmpty from '../../state/validations/is-empty';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../auth/components/header';
import { getAllServicelevelAgreementAction } from '../../state/actions/servicelevelagreementAction';
import RenderHTML from "react-native-render-html";


function ServiceLevelAgreement(props: any) {

    //navigation property is used for navigation between screens
    const { navigation } = props;
    //API dispatch
    const dispatch = useDispatch();

    const ID = props.route?.params?.ID;
    const esiColor = useSelector(state => state.theme);
    //SLAdata contains the data from  GET API using state call
    const SLAdata = useSelector(state => state.servicelevelagreement.servicelevelagreements.all);
    //for loading
    const loading = useSelector(state => state.servicelevelagreement.servicelevelagreements.isLoading);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    //Static formData
    React.useEffect(() => {
        let formData = {
            "Cms_Static_Id": "",
            "Name": "Partner SLA",
            "Records_Filter": "FILTER"
        };
        dispatch(getAllServicelevelAgreementAction(formData))
    }, []);
    const tagsStyles = {
        p: {
          color: esiColor.itemColor        
        }   
      };

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    return (
        <Surface style={{ flex: 1 ,backgroundColor: esiColor.BackgroundColor}}>
            <Header navigation={navigation} />
            {ID ? <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", height: 50, flexShrink: 1 }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                    navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } });
                }
                }>
                    Go Back
                </Button>
            </View> : <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, height: 50, justifyContent: 'flex-start' }} onPress={() => navigation.navigate("B2C", { screen: 'Dashboard' })}>
                    Go Back
                </Button>
            </View>}
            <Text style={{color:esiColor.brandFontColor, fontSize: 20, fontWeight: "bold", textAlign: 'center' }}>
                Service Level Agreement
            </Text>
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }}>
                    <Card style={{
                        flex: 1,
                        borderRadius: 25, borderWidth: 0.5, shadowColor: '#000', borderColor: "#CACFD2",
                        backgroundColor: esiColor.BackgroundColor, flexDirection: 'column',
                        width: dimensions >= 500 ? "70%" : "95%", flexShrink: 1, flexWrap: 'wrap', marginTop: "2%"
                    }}>
                        <View>
                            {
                                !isEmpty(SLAdata) ? (
                                    SLAdata.Is_Data_Exist === "0" ?
                                        <Text style={{color:esiColor.itemColor, justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                            No Data found.
                                        </Text> : (
                                            SLAdata.map((data, index) => (
                                                <View key={index} style={{ margin: '3%', flexWrap: 'wrap', flex: 1 }}>
                                                    <RenderHTML tagsStyles={tagsStyles} contentWidth={320} source={{ html: data?.Description }} />
                                                </View>
                                            ))
                                        )) : <ActivityIndicator animating={loading} color='#27b6cc' style={{ marginVertical: 20, marginHorizontal: 150 }} />
                            }
                        </View>
                    </Card>
                </View>
            </ScrollView>
        </Surface>
    );
}

export default ServiceLevelAgreement;