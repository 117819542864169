import React from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
import { Rating } from 'react-native-ratings';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Dimensions } from 'react-native';
import { Surface } from 'react-native-paper';
import { FontAwesome } from '@expo/vector-icons';
import { useSelector } from 'react-redux';

const { height, width } = Dimensions.get('window');

const EsiPartnerCard = (props: any) => {

    const sampleData = {
        Partner_Details_Id: "P-D-0000215",
        Email_Id: "devi.neralla@easystepin.com",
        Name: "Amazon",
        Logo_Path: "http://api.easystepin.com:8092/uploads/ESICB/PartnerProfile/20210624/PartnerProfile_Banner_20210624101653_JK.jpg",
        Website_Url: "https://www.amazon.com/",
        Type: "Online",
        Mobile: "+91 9769759758",
        Land_Phone: "+91 86586868",
        Gst: "45EDFGR7654F4Z5",
        Pan: "RTGYU1234K",
        Short_Description: "Amazon is the largest and most successful retailer in the western world because they built the best customer experience.",
        Description: "<p>Amazon is a vast Internet-based enterprise that sells books, music, movies, housewares, electronics, toys, and many other goods.</p>\n",
        Tips: "Best products",
        Banner_Path: "http://api.easystepin.com:8092/uploads/ESICB/PartnerProfile/20210722/PartnerProfile_Banner_20210722072033_JK.jpg",
        Is_Popular: "1",
        Whats_Hot: "1",
        Todays_Hot: "1",
        Extra_Cash_Back: "1",
        Top_Stores: "1",
        Esi_Review: "<p>Amazon offers same-day or one-day delivery. No wonder it is considered to be the best online shopping website.</p>\n",
        Esi_Rating: "5",
        Esi_Commission: "1.00",
        Status: "Active",
        Created_Date: "2021-05-19",
        Updated_Date: "2021-08-20",
        Modified_By: "devi.neralla@easystepin.com",
        Created_By: "devi.neralla@easystepin.com",
        Web_Redirection_Url: "https://amzn.to/3lAotgI",
        Mobile_Redirect_Url: "https://amzn.to/3lAotgI",
        Coupon_Count: "4",
        Deal_Count: "7",
        Partner_Products_Count: "53",
        Final_Rating: "3.4545454545454546",
    }
    const { height, width } = Dimensions.get('window');
    const esiColor = useSelector(state => state.theme);

    // item is partner object , using this object item to display info.
    const { item, deal_count, navigation, isAuthenticated, toggleModal, signInModalResponse, setModalVisible, isModalVisible, isLocation = false, ...rest } = props

    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const halfdimensions = dimensions;
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    return (
        <Surface theme={{
            colors: {
                surface: esiColor.BackgroundColor,
            }
        }} style={{
            marginHorizontal: 2, borderRadius: 10, padding: 2, margin: 'auto', marginBottom: 15, flexDirection: 'column',
            shadowColor: esiColor.brandShadowColor, borderColor: esiColor.CBColor, borderWidth: 0.5,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            alignItems: 'flex-start',
            elevation: 5,
            // height: 135,
            // maxWidth: (halfdimensions *0.7), width: (halfdimensions*0.7),
        }}>
            {item.Is_Open !== 1 &&
                <View style={{ zIndex: 1, position: "absolute", right: 0, top: 10, backgroundColor: '#DC143C', borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}>
                    <Text style={{ fontSize: 14, color: 'white', marginHorizontal: 10, marginVertical: 5, fontWeight: "bold" }}> Store Closed</Text>
                </View>
            }
            <TouchableOpacity onPress={() => {
                // navigate function is used to redirect to partner Details.
                if (!(item?.Partner_Products_Count == '0')) {

                    if (isLocation) {
                        navigation.navigate('PartnerDetailsb2b', { Partner_Details_Id: item.Partner_Details_Id, address: item.Partner_Address_Id, back: rest.route.key });
                    } else {
                        navigation.navigate('PartnerDetailsb2b', { Partner_Details_Id: item.Partner_Details_Id, back: rest.route.key });
                    }

                } else
                    navigation.navigate('PartnerDetailsb2b', { Partner_Details_Id: item.Partner_Details_Id, back: rest.route.key });


            }}>
                <View style={{ justifyContent: 'center', flexDirection: "row", marginHorizontal: dimensions * 0.04 }} >
                    {/* Toggle model is used here for Signing in */}

                    <Image source={{ cache: "force-cache", uri: item?.Logo_Path }} style={{ height: 150, marginTop: 10, width: dimensions * 0.9, alignSelf: "center", opacity: 1, borderRadius: 8 }} resizeMode='stretch' ></Image>

                </View>
                <View style={{ flexDirection: 'column', marginHorizontal: dimensions * 0.04, width: dimensions * 0.7 }}  >

                    <Text style={{ fontSize: 20, fontWeight: 'bold', color: "#27b6cc" }}>{item.Name}</Text>
                    <View style={{ overflow: "hidden" }}>
                        <Text style={{color: esiColor.DescColor}}>{item.Short_Description?.substring(0, 30)} {item.Short_Description?.length > 30 && "..."}</Text>
                    </View>
                </View>
            </TouchableOpacity>

            <View style={{ flexDirection: 'column', zIndex: 100, width: dimensions * 0.7, marginHorizontal: dimensions * 0.04 }}>
                <TouchableOpacity onPress={() => {
                    navigation.navigate('PartnerDetailsb2b', { Partner_Details_Id: item.Partner_Details_Id });
                }}>
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start" }} >
                        <View style={{ flex: 3 }}>
                            <Rating
                                readonly={true}
                                type='heart'
                                // ratingBackgroundColor='#c8c7c8'
                                tintColor={esiColor.TintColor}
                                startingValue={item?.Esi_Rating || 0}
                                ratingCount={5}
                                imageSize={16}
                                style={{ paddingVertical: 7, alignSelf: "flex-start" }}
                            />
                        </View>

                        <View style={{ flex: 3, flexDirection: "row", justifyContent: "center", marginTop: 6 }}>
                            <FontAwesome name={'clock-o'} color='#27b6cc' size={20}></FontAwesome>
                            <Text style={{ color: esiColor.DescColor, marginTop: 2 }}> {item?.Estimated_Delivery_Time}</Text>
                        </View>
                        <View style={{ flex: 3, flexDirection: "row", justifyContent: "center", marginTop: 6 }}>
                            <FontAwesome name={'eye'} color='#27b6cc' size={20}></FontAwesome>
                            <Text style={{ color: esiColor.DescColor, marginTop: 2 }}> View</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            </View>


        </Surface>
    );
};

export default EsiPartnerCard;
