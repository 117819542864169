import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import isEmpty from "../../../state/validations/is-empty";
import { changeCategoriesData } from "../../../state/actions/shoppers/filterServiceActions";
import { getAllfilterCategoriesAction } from "../../../state/actions/shoppers/shoppercategoriesActions";
import EsiCheckboxTree from "../../../components/custom/checkbox-tree";


export default function SortByCategories(props) {
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const callback = (data: any) => {
        let catData = [];
        if (data) {
            data.slice(0, 10).map((item: any, index: Number) => {
                let children = [];
                item.SubCategories.map((sub, index1) => {
                    children.push({
                        label: sub.Sub_Category_Name,
                        value: sub.Sub_Category_Id,
                        checked: sub.Sub_Category_Id === props.state?.routes[0]?.params?.id ? "checked" : "unchecked",
                        key: String(index) + '-' + String(index1),
                    })
                })


                catData.push({
                    label: item.Category_Name,
                    value: item.Category_Id,
                    checked: "unchecked",
                    key: String(index),
                    children: children
                });

            })
        }
        dispatch(changeCategoriesData(catData));
    };

    let sortByOptionsInit = useSelector((state) => state.filters.categories);

    useEffect(() => {
        if (!isEmpty(sortByOptionsInit)) {
            if (props.state?.routes[0]?.params?.type === "sub-category") {
                let category = JSON.parse(JSON.stringify(sortByOptionsInit));
                let exist = false;
                category.map((item) => {
                    item.children.map((sItem) => {
                        if (sItem.value === props.state?.routes[0]?.params?.id) {
                            if (sItem.checked === "checked") {
                                exist = false;
                            } else {
                                sItem.checked = "checked";
                                exist = true;
                            }
                        } else {
                            sItem.checked = "unchecked";
                        }
                    })
                })
                if (exist)
                    dispatch(changeCategoriesData(category));
            }
        }
    }, [props.state?.routes[0]?.params?.id]);

    useEffect(() => {
        if (isEmpty(sortByOptionsInit)) {
            dispatch(getAllfilterCategoriesAction(callback));
        }
    }, [sortByOptionsInit]);

    const { navigation } = props;


    const checkChildChange = (data: any, status: string) => {
        let key = data.key;
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        response.filter((item: any) => { return item.key === key })[0].children.map((item: any) => {
            if (status === 'unchecked' || status === 'indeterminate') {
                item.checked = "checked";
            } else {
                if (item.value === 'Is_Top_Product' || item.value === 'Is_Top_Coupon' || item.value === 'Is_Top_Deal') {
                    item.checked = "checked";
                } else {
                    item.checked = "unchecked";
                }
            }
        })
        response.filter((item: any) => { return item.key != key }).map((children: any) => {
            children.children.map((item: any) => {
                item.checked = "unchecked";
            })
        })
        dispatch(changeCategoriesData(response));
    }

    const changeChecked = (data: any, status: string) => {
        let key = data.key;
        let keys = key.split('-');
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        if (keys.length === 1) {
            response.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
        } else if (keys.length === 2) {
            response.filter((item: any) => { return item.key === keys[0] })[0].children.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
            response.filter((item: any) => { return item.key != keys[0] }).map(children => {
                children.children.map((item: any) => { item.checked = "unchecked" });
            });
        }
        dispatch(changeCategoriesData(response));
    }

    const updateOptions = ((data: any) => {
        dispatch(changeCategoriesData(data));
    })

    return (
        <View style={{
            flex: 1,
            backgroundColor: esiColor.BackgroundColor
        }}>
            <View style={{ marginLeft: 10, flexDirection: 'row' }}>
                <View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <Text style={{ fontSize: 14,color: esiColor.itemColor, marginTop: 5 }}>Categories</Text>
                </View>
                <TouchableOpacity style={{ position: 'absolute', right: 10, marginTop: 5 }} onPress={() => { navigation.navigate('Categoryb2b') }} >
                    <Text style={{ textDecorationLine: "underline", color: esiColor.itemColor }}>See All</Text>
                </TouchableOpacity>
            </View>
            <EsiCheckboxTree
                sortByOptionsInit={sortByOptionsInit}
                updateOptions={updateOptions}
                checkChildChange={checkChildChange}
                changeChecked={changeChecked}
                {...props} />
        </View>
    );
}
