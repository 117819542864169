import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBrands, getAllCategories, dealData, uploadDealImage, getDealByIdAction } from '../../../state/actions/dealAction';
import { Card, TextInput, Text, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView, RefreshControl } from 'react-native';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import ImageUpload from '../../../components/custom/image-upload/upload';
import Header from '../../auth/components/header';
import EsiDatePicker from '../../../components/custom/date/date';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//discountType and esiCashbackTypeOption are used to get the data from dropdown
const discountType = [
  {
    value: '',
    label: '--Please Select--'
  },

  {
    value: 'A',
    label: 'Amount'
  },
  {
    value: 'P',
    label: 'Percentage'
  },
];



const DiscountlabelOption = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'UPTO',
    label: 'UPTO'
  },
  {
    value: 'FLAT',
    label: 'FLAT'
  }
];

const EditDealBasic = (props: any) => {
  //navigation is used for navigation between screens
  const { navigation } = props;
  const toast = useToast();

  const ID = props?.route?.params?.ID;
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);

  React.useEffect(() => {
    if (!(permissions.Deals_Management === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const deals = props?.route?.params?.data;
  const brands = useSelector(state => state.deal.dealBrands.brands);
  const categories = useSelector(state => state.deal.dealCategories.categories);
  const [data, setData] = useState([]);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const callbackDealData = (data: any) => {
    setData(data)
    setImgUrl(data[0].Image)
    setImage({
      preview: data[0].Image,
      raw: data[0].Image
    });
  }
  useEffect(() => {
    if (ID) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Brand_Id": "",
        "CASHBACK_LABEL": "",
        "Category_Id": "",
        "DISCOUNT_LABEL": "",
        "Deal_Id":ID,
        "Partner_Details_Id": "",
        "Records_Filter": "FILTER",
        "Status": "",
        "Title": ""
      };
      dispatch(getDealByIdAction(formData, callbackDealData));
      dispatch(getAllBrands());
      dispatch(getAllCategories());
    }
  }, [ID]);

  //Dropdowns
  const [brandDropDown, setBrandDropDown] = useState("");
  const [categoryDropDown, setCategoryDropDown] = useState("");
  const [discDropDown, setdiscDropDown] = useState("");
  const [disclabelDropDown, setdisclabelDropDown] = useState("");

  //brandlist used to store the label,value in an array from API data 
  let brandlist = []
  if (brands) {
    if (brands[0]) {
      for (let i = 0; i < brands.length; i++) {
        brandlist.push({
          label: brands[i].Brand_Name,
          value: brands[i].Brand_Id
        })
      }
    }
  }
  //categorylist used to store the label,value in an array from API data  
  let categorylist = []
  if (categories) {
    if (categories[0]) {
      for (let i = 0; i < categories.length; i++) {
        categorylist.push({
          label: categories[i].Category_Name,
          value: categories[i].Category_Id
        })
      }
    }
  }

  const dispatch = useDispatch();

  // img upload

  const [image, setImage] = useState({ preview: deals?.Image, raw: deals?.Image });
  const [imgUrl, setImgUrl] = useState(deals?.Image);
  const [uploadPic, setUploadPic] = useState(false);

  const callBackUploadImage = (data: any) => {
    if (data) {
      setImgUrl(data.File_Url);
    }
    setUploadPic(false);
  };

  const handleImage = async (result: any) => {
    const img = await fetchImageFromUri(result.uri);
    setImage({
      preview: result.uri,
      raw: img
    });
    dispatch(
      uploadDealImage(img, callBackUploadImage)
    );
  };

  const fetchImageFromUri = async (uri: any) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  /*====================================
          Handler
  ====================================*/
  const values = {
    Deal_Id: data[0]?.Deal_Id,
    Partner_Details_Id: data[0]?.Partner_Details_Id,
    Partner_Name: data[0]?.Partner_Name,
    Title: data[0]?.Title ? data[0].Title : "",
    Brand_Id: data[0]?.Brand_Id ? data[0].Brand_Id : "",
    Category_Id: data[0]?.Category_Id ? data[0].Category_Id : "",
    Discount_Type: data[0]?.Discount_Type ? data[0].Discount_Type : "",
    Discount: data[0]?.Discount ? data[0].Discount : "",
    Esi_Cashback_Type: data[0]?.Esi_Cashback_Type ? data[0].Esi_Cashback_Type : "",
    Esi_Cashback_Amount: data[0]?.Esi_Cashback_Amount ? data[0].Esi_Cashback_Amount : "",
    Start_Date: data[0]?.Start_Date ? data[0].Start_Date : "",
    End_Date: data[0]?.End_Date ? data[0].End_Date : "",
    Image: imgUrl,
    Mobile_Redirect_Url: data[0]?.Mobile_Redirect_Url ? data[0].Mobile_Redirect_Url : "",
    Web_Redirect_Url: data[0]?.Web_Redirect_Url ? data[0].Web_Redirect_Url : "",
    Deal_Description: data[0]?.Deal_Description ? data[0].Deal_Description : "",
    Verified_Deal: data[0]?.Verified_Deal > 0 ? true : false,
    Whats_Hot: data[0]?.Whats_Hot > 0 ? true : false,
    Exclusive: data[0]?.Exclusive > 0 ? true : false,
    Extra_Cashback: data[0]?.Extra_Cashback > 0 ? true : false,
    Is_Top_Deal: data[0]?.Is_Top_Deal > 0 ? true : false,
    Status: data[0]?.Status ? data[0].Status : "",
    Cashback_Label: data[0]?.Cashback_Label ? data[0].Cashback_Label : "",
    Discount_Label: data[0]?.Discount_Label ? data[0].Discount_Label : "",
  }

  //Form validation
  const Form_Validation = Yup.object().shape({
    Category_Id: Yup.string().test(
      'oneOfRequired',
      'Category / Brand Name is required.',
      function (item) {
        return (this.parent.Category_Id || this.parent.Brand_Id)
      }
    ),
    Brand_Id: Yup.string().test(
      'oneOfRequired',
      'Category / Brand Name is required.',
      function (item) {
        return (this.parent.Category_Id || this.parent.Brand_Id)
      }
    ),
    Discount_Type: Yup.string().test(
      'oneOfRequired',
      'Discount Type  is required.',
      function (item) {
        return (this.parent.Discount_Type)
      }
    ),
    Discount: Yup.string().when("Discount_Type", (Discount_Type, schema) => {
      if (Discount_Type == "A") {
        return schema.min(1, "Discount Amount must be at least 1.").required('Discount Amount is required.');
      } else if (Discount_Type == "P") {
        return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be <= 100.").required('Discount Percentage is required.');
      }
    }),
    Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Start Date greater than or equal to today.").required("Start Date is required."),
    End_Date: Yup.date().when("Start_Date", (Start_Date, schema) => Start_Date && schema.min(Start_Date, "End Date can't be before Start Date.")).required("End Date is required."),
    Discount_Label: Yup.string().when("Discount", (Discount, schema) => {
      if (Discount) {
        return schema.required('Discount Label is required.');
      }
    }),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    values.Image = imgUrl;
    if (!values.Image) {
      submitTest = false;
      errors.Image = "Image is required.";
    }

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      //passing the formvalues to next page through dispatch
      dispatch(dealData(formValues));
      //navigation to next page after validating the form
      navigation.navigate('EditDealConfig')
    }
  }

  return (
    <Surface style={{ flex: 1 }}>
      <Header navigation={navigation} />
      {data.length > 0 &&
        <ScrollView refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        } >
          <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
            {(props) => (
              <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130 }} onPress={() => { props.resetForm(); navigation.navigate('DealsGet') }}>
                    Go Back
                  </Button>
                </View>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: '#D6D6D7'
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                      Edit Deal
                    </Text>
                    <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                      {/* Title input filed with validation*/}
                      <View style={{ flexShrink: 1, width: 650 }}>
                        <TextInput
                          style={{ marginTop: 15, backgroundColor: "white" }}
                          theme={{ colors: { primary: '#27B6CC' } }}
                          error={Boolean(props.touched.Title && props.errors.Title)}
                          label='Title'
                          name='Title'
                          mode='outlined'
                          disabled
                          value={props.values.Title}
                          onChangeText={props.handleChange('Title')}
                        />
                        <HelperText type="error" visible={Boolean(props.touched.Title && props.errors.Title)}>
                          <ErrorMessage name="Title" />
                        </HelperText>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Brand_Id && props.errors.Brand_Id) && { color: '#b00020' }]}>
                              Brand Name
                            </Text>
                            {/* Brand Name field with validation*/}
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Brand_Id && props.errors.Brand_Id) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              dropdownPosition="bottom"
                              placeholder={!brandDropDown ? '-- Please Select --' : ''}
                              data={brandlist}
                              maxHeight={brandlist.length > 1 ? 250 : 200}
                              search={brandlist.length > 1 ? true : false}
                              labelField="label"
                              valueField="value"
                              value={props.values.Brand_Id}
                              onUpdateValue={item => {
                                props.setFieldValue('Brand_Id', item.value)
                                setBrandDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Brand_Id && props.errors.Brand_Id)}>
                              <ErrorMessage name="Brand_Id" />
                            </HelperText>
                          </View>
                          <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Category_Id && props.errors.Category_Id) && { color: '#b00020' }]}>
                              Category Name
                            </Text>
                            {/* Category Name field with validation*/}
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Category_Id && props.errors.Category_Id) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              dropdownPosition="bottom"
                              placeholder={!categoryDropDown ? '-- Please Select --' : ''}
                              data={categorylist}
                              labelField="label"
                              valueField="value"
                              maxHeight={categorylist.length > 1 ? 250 : 200}
                              search={categorylist.length > 1 ? true : false}
                              searchPlaceholder={'Select Category Name'}
                              value={props.values.Category_Id}
                              onUpdateValue={item => {
                                props.setFieldValue('Category_Id', item.value)
                                setCategoryDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Category_Id && props.errors.Category_Id)}>
                              <ErrorMessage name="Category_Id" />
                            </HelperText>
                          </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { color: '#b00020' }]}>
                              Discount Type
                            </Text>
                            {/* Discount Type field with validation*/}
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              dropdownPosition="bottom"
                              placeholder={!discDropDown ? '-- Please Select --' : ''}
                              data={discountType}
                              maxHeight={discountType.length > 1 ? 250 : 200}
                              search={discountType.length > 1 ? true : false}
                              labelField="label"
                              valueField="value"
                              searchPlaceholder={'Select Discount Type'}
                              value={props.values.Discount_Type}
                              onUpdateValue={item => {
                                props.setFieldValue('Discount_Type', item.value)
                                setdiscDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Discount_Type && props.errors.Discount_Type)}>
                              <ErrorMessage name="Discount_Type" />
                            </HelperText>
                          </View>
                          {/* Discount field with validation*/}
                          <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 14 }}>
                            <TextInput
                              style={{ backgroundColor: "white" }}
                              theme={{ colors: { primary: '#27B6CC' } }}
                              mode='outlined'
                              name="Discount"
                              disabled={props.values.Discount_Type ? false : true}
                              label={"Discount " + (props.values.Discount_Type === "A" ? "Amount ₹" : props.values.Discount_Type === "P" ? "Percentage %" : "")}
                              value={props.values.Discount}
                              onChangeText={props.handleChange('Discount')}
                              maxLength={10}
                              keyboardType='numeric'
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Discount && props.errors.Discount)}>
                              <ErrorMessage name="Discount" />
                            </HelperText>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          {/* Discount_Label field with validation*/}
                          <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { color: '#b00020' }]}>
                              Discount Label
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              disable={props.values.Discount ? false : true}
                              dropdownPosition="bottom"
                              placeholder={!disclabelDropDown ? '-- Please Select --' : ''}
                              data={DiscountlabelOption}
                              labelField="label"
                              valueField="value"
                              maxHeight={DiscountlabelOption.length > 1 ? 250 : 200}
                              search={DiscountlabelOption.length > 1 ? true : false}
                              searchPlaceholder={'Select Discount Lable'}
                              value={props.values.Discount_Label}
                              onUpdateValue={item => {
                                props.setFieldValue('Discount_Label', item.value)
                                setdisclabelDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Discount_Label && props.errors.Discount_Label)}>
                              <ErrorMessage name="Discount_Label" />
                            </HelperText>
                          </View>
                        </View>
                        {/* Start Date field with validation*/}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>

                            <EsiDatePicker
                              error={Boolean(props.touched.Start_Date && props.errors.Start_Date)}
                              style={{ width: "100%", backgroundColor: 'white', height: 50 }}
                              name={'Start_Date'}
                              label={'Start Date'}
                              value={props.values.Start_Date}
                              valueUpdate={item => {
                                props.setFieldValue('Start_Date', item)
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Start_Date && props.errors.Start_Date)}>
                              <ErrorMessage name="Start_Date" />
                            </HelperText>
                          </View>
                          {/* End Date field with validation*/}
                          <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                            <EsiDatePicker
                              error={Boolean(props.touched.End_Date && props.errors.End_Date)}
                              style={{ width: "100%", backgroundColor: 'white' }}
                              name={'End_Date'}
                              label={'End Date'}
                              value={props.values.End_Date}
                              valueUpdate={item => {
                                props.setFieldValue('End_Date', item)
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.End_Date && props.errors.End_Date)}>
                              <ErrorMessage name="End_Date" />
                            </HelperText>
                          </View>
                        </View>

                      </View>
                      {/* Image upload with validation*/}
                      <View style={{ flexShrink: 1, paddingTop: 20, paddingLeft: 15, width: 250 }} >
                        <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                        <HelperText type="error" visible={!imgUrl && props.touched.Image && props.errors.Image}>
                          {!imgUrl && props.touched.Image && props.errors.Image}
                        </HelperText>
                      </View>

                    </View>
                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                      <Button style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={props.handleSubmit}>
                        Next
                      </Button>
                    </View>

                  </Card.Content>
                </Card>
              </View>
            )}
          </Formik>
        </ScrollView>
      }
    </Surface>
  );
};

const styles = StyleSheet.create({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    color: 'black',
    left: 22,
    top: 10,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  dropdown: {
    height: 58,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
});

export default EditDealBasic;