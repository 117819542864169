
import {
    PRODUCTS_REQUEST,
    PRODUCTS_SUCCESS,
    PRODUCTS_FAIL,
    SIZES_REQUEST,
    SIZES_SUCCESS,
    SIZES_FAIL,
    PRODUCT_GET_REQUEST,
    PRODUCT_GET_SUCCESS,
    PRODUCT_GET_FAIL,
    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_FAIL,
    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_UPDATE_FAIL,
    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DELETE_FAIL,
    PRODUCTS_FILTER,
    PRODUCT_BRANDS_REQUEST,
    PRODUCT_BRANDS_SUCCESS,
    PRODUCT_COLOURS_FAIL,
    PRODUCT_COLOURS_REQUEST,
    PRODUCT_COLOURS_SUCCESS,
    PRODUCT_MASTER_FAIL,
    PRODUCT_MASTER_REQUEST,
    PRODUCT_MASTER_SUCCESS,
    PRODUCT_BRANDS_FAIL,
    PRODUCT_CATEGORIES_REQUEST,
    PRODUCT_CATEGORIES_SUCCESS,
    PRODUCT_CATEGORIES_FAIL,
    PRODUCT_SUBCATEGORIES_REQUEST,
    PRODUCT_SUBCATEGORIES_SUCCESS,
    PRODUCT_SUBCATEGORIES_FAIL,
    PRODUCT_ROLES_REQUEST,
    PRODUCT_ROLES_SUCCESS,
    PRODUCT_ROLES_FAIL, 
    PRODUCT_SIZE_REQUEST,
    PRODUCT_SIZE_SUCCESS,
    PRODUCT_SIZE_FAIL, 
    PRODUCTS_DATA,  
  } from '../actions/productsAction'
  
  
  const initialState = {
    products: { all: [], error: '', isLoading: false },
    product: { product: {}, error: '', isLoading: false },
    productCreate: { product: {}, error: '', isLoading: false },
    productUpdate: { product: {}, error: '', isLoading: false },
    productDelete: { product: {}, error: '', isLoading: false },
    productBrands: { brands: [], error: '', isLoading: false },
    productCategories: { categories: [], error: '', isLoading: false },
    productSubCategories: { subcategories: [], error: '', isLoading: false },
    productRoles: { roles: [], error: '', isLoading: false },  
    productColours: { colours: [], error: '', isLoading: false }, 
    productMasters: { masters: [], error: '', isLoading: false }, 
    productSizes: { sizes: [], error: '', isLoading: false }, 
    Sizes: { sizes: [], error: '', isLoading: false }, 
    productFilter: { select: 'All', search: '' },
    productdata: { products: {}, error: '', isLoading: false },
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case PRODUCTS_REQUEST:
        return { ...state, products: { all: state.products.all, error: '', isLoading: true } };
      case PRODUCTS_SUCCESS:
        return { ...state, products: { all: action.payload, error: '', isLoading: false } };
      case PRODUCTS_FAIL:
        return { ...state, products: { all: [], error: action.payload, isLoading: false } };
      case PRODUCT_GET_REQUEST:
        return { ...state, product: { product: {}, error: '', isLoading: true } };
      case PRODUCT_GET_SUCCESS:
        return { ...state, product: { product: action.payload, error: '', isLoading: false } };
      case PRODUCT_GET_FAIL:
        return { ...state, product: { product: {}, error: action.payload, isLoading: false } };
      case PRODUCT_CREATE_REQUEST:
        return { ...state, productCreate: { product: {}, error: '', isLoading: true } };
      case PRODUCT_CREATE_SUCCESS:
        return { ...state, productCreate: { product: state.productCreate.product, error: '', isLoading: false } };
      case PRODUCT_CREATE_FAIL:
        return { ...state, productCreate: { product: {}, error: action.payload, isLoading: false } };
      case PRODUCT_UPDATE_REQUEST:
        return { ...state, productUpdate: { product: {}, error: '', isLoading: true } };
      case PRODUCT_UPDATE_SUCCESS:
        return { ...state, productUpdate: { product: action.payload, error: '', isLoading: false } };
      case PRODUCT_UPDATE_FAIL:
        return { ...state, productUpdate: { product: {}, error: action.payload, isLoading: false } };
      case PRODUCT_DELETE_REQUEST:
        return { ...state, productDelete: { product: {}, error: '', isLoading: true } };
      case PRODUCT_DELETE_SUCCESS:
        return { ...state, productDelete: { product: action.payload, error: '', isLoading: false } };
      case PRODUCT_DELETE_FAIL:
        return { ...state, productDelete: { product: {}, error: action.payload, isLoading: false } };
      case PRODUCTS_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, productFilter: { search: state.productFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, productFilter: { select: state.productFilter.select, search: action.value } };
        }
        return res;
        
    case PRODUCTS_DATA:
      return { ...state, productdata: { products: action.value, error: '', isLoading: true } };

      case PRODUCT_BRANDS_REQUEST:
        return { ...state, productBrands: { brands: state.productBrands.brands, error: '', isLoading: true }, };
      case PRODUCT_BRANDS_SUCCESS:
        return { ...state, productBrands: { brands: action.payload, error: '', isLoading: false } };
      case PRODUCT_BRANDS_FAIL:
        return { ...state, productBrands: { brands: [], error: action.payload, isLoading: false } };
        case PRODUCT_CATEGORIES_REQUEST:
        return { ...state, productCategories: { categories: state.productCategories.categories, error: '', isLoading: true }, };
      case PRODUCT_CATEGORIES_SUCCESS:
        return { ...state, productCategories: { categories: action.payload, error: '', isLoading: false } };
      case PRODUCT_CATEGORIES_FAIL:
        return { ...state, productCategories: { categories: [], error: action.payload, isLoading: false } };
        case PRODUCT_SUBCATEGORIES_REQUEST:
          return { ...state, productSubCategories: { subcategories: state.productSubCategories.subcategories, error: '', isLoading: true }, };
        case PRODUCT_SUBCATEGORIES_SUCCESS:
          return { ...state, productSubCategories: { subcategories: action.payload, error: '', isLoading: false } };
        case PRODUCT_SUBCATEGORIES_FAIL:
          return { ...state, productSubCategories: { subcategories: [], error: action.payload, isLoading: false } };
          case PRODUCT_ROLES_REQUEST:
        return { ...state, productRoles: { roles: state.productRoles.roles, error: '', isLoading: true }, };
      case PRODUCT_ROLES_SUCCESS:
        return { ...state, productRoles: { roles: action.payload, error: '', isLoading: false } };
      case PRODUCT_ROLES_FAIL:
        return { ...state, productRoles: { roles: [], error: action.payload, isLoading: false } };  
        case PRODUCT_MASTER_REQUEST:
          return { ...state, productMasters: { masters: state.productMasters.masters, error: '', isLoading: true }, };
        case PRODUCT_MASTER_SUCCESS:
          return { ...state, productMasters: { masters: action.payload, error: '', isLoading: false } };
        case PRODUCT_MASTER_FAIL:
          return { ...state, productMasters: { masters: [], error: action.payload, isLoading: false } };      
          case PRODUCT_COLOURS_REQUEST:
            return { ...state, productColours: { colours: state.productColours.colours, error: '', isLoading: true }, };
          case PRODUCT_COLOURS_SUCCESS:
            return { ...state, productColours: { colours: action.payload, error: '', isLoading: false } };
          case PRODUCT_COLOURS_FAIL:
            return { ...state, productColours: { colours: [], error: action.payload, isLoading: false } };      
            case PRODUCT_SIZE_REQUEST:
              return { ...state, productSizes: { sizes: state.productSizes.sizes, error: '', isLoading: true }, };
            case PRODUCT_SIZE_SUCCESS:
              return { ...state, productSizes: { sizes: action.payload, error: '', isLoading: false } };
            case PRODUCT_SIZE_FAIL:
              return { ...state, productSizes: { sizes: [], error: action.payload, isLoading: false } };  
              case SIZES_REQUEST:
                return { ...state, Sizes: { sizes: state.Sizes.sizes, error: '', isLoading: true }, };
              case SIZES_SUCCESS:
                return { ...state, Sizes: { sizes: action.payload, error: '', isLoading: false } };
              case SIZES_FAIL:
                return { ...state, Sizes: { sizes: [], error: action.payload, isLoading: false } };  
     
                default:
        return state;    
    }
  }