import {
    TRANSACTION_REQUEST,
    TRANSACTION_SUCCESS,
    TRANSACTION_FAIL,
    TRANSACTION_FILTER,
  } from '../actions/transactionAction'
  
  const initialState = {
    transactions: { all: [], error: '', isLoading: false },
    transactionsFilter: { select: 'All', search: '' },
  };
  
  export default function (state = initialState, action:any) {
    switch (action.type) {
      case TRANSACTION_REQUEST:
        return { ...state, transactions: { all: state.transactions.all, error: '', isLoading: true } };
      case TRANSACTION_SUCCESS:
        return { ...state, transactions: { all: action.payload, error: '', isLoading: false } };
      case TRANSACTION_FAIL:
        return { ...state, transactions: { all: [], error: action.payload, isLoading: false } };
     case TRANSACTION_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, transactionsFilter: { search: state.transactionsFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, transactionsFilter: { select: state.transactionsFilter.select, search: action.value } };
        }
        return res;
      default:
        return state;
    }
  }
  
  
  