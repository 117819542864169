import {
    PARTNERSERVICES_REQUEST,
    PARTNERSERVICES_SUCCESS,
    PARTNERSERVICES_FAIL,
    PARTNERSERVICES_GET_REQUEST,
    PARTNERSERVICES_GET_SUCCESS,
    PARTNERSERVICES_GET_FAIL,
    PARTNERSERVICES_UPDATE_REQUEST,
    PARTNERSERVICES_UPDATE_SUCCESS,
    PARTNERSERVICES_UPDATE_FAIL,
    PARTNERSERVICES_FILTER,
    PARTNERSERVICES_CREATE_REQUEST,
    PARTNERSERVICES_CREATE_SUCCESS,
    PARTNERSERVICES_CREATE_FAIL,
    PARTNERSERVICES_DATA
} from '../../actions/Services/partnerservicesActions'
 
const initialState = {
    partnerservices: { all: [], error: '', isLoading: false },
    partnerservice: { partnerservice: {}, error: '', isLoading: false },
    partnerserviceUpdate: { partnerservice: {}, error: '', isLoading: false },
    partnerserviceCreate: { partnerservice: {}, error: '', isLoading: false },
    partnerserviceFilter: { select: 'All', search: '' },
    partnerserviceData: { data: {}, error: '', isLoading: false },
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case PARTNERSERVICES_REQUEST:
        return { ...state, partnerservices: { all: state.partnerservices.all, error: '', isLoading: true } };
      case PARTNERSERVICES_SUCCESS:
        return { ...state, partnerservices: { all: action.payload, error: '', isLoading: false } };
      case PARTNERSERVICES_FAIL:
        return { ...state, partnerservices: { all: [], error: action.payload, isLoading: false } };
       case PARTNERSERVICES_GET_REQUEST:
        return { ...state, partnerservice: { partnerservice: {}, error: '', isLoading: true } };
      case PARTNERSERVICES_GET_SUCCESS:
        return { ...state, partnerservice: { partnerservice: action.payload, error: '', isLoading: false } };
      case PARTNERSERVICES_GET_FAIL:
        return { ...state, partnerservice: { partnerservice: {}, error: action.payload, isLoading: false } };
      case PARTNERSERVICES_UPDATE_REQUEST:
        return { ...state, partnerserviceUpdate: { partnerservice: {}, error: '', isLoading: true } };
      case PARTNERSERVICES_UPDATE_SUCCESS:
        return { ...state, partnerserviceUpdate: { partnerservice: state.partnerserviceUpdate.partnerservice, error: '', isLoading: false } };
      case PARTNERSERVICES_UPDATE_FAIL:
        return { ...state, partnerserviceUpdate: { partnerservice: {}, error: action.payload, isLoading: false } };
        case PARTNERSERVICES_CREATE_REQUEST:
            return { ...state, partnerserviceCreate: { partnerservice: {}, error: '', isLoading: true } };
          case PARTNERSERVICES_CREATE_SUCCESS:
            return { ...state, partnerserviceCreate: { partnerservice: state.partnerserviceCreate.partnerservice, error: '', isLoading: false } };
          case PARTNERSERVICES_CREATE_FAIL:
            return { ...state, partnerserviceCreate: { partnerservice: {}, error: action.payload, isLoading: false } };
            case PARTNERSERVICES_DATA:
              return { ...state, partnerserviceData: { data: action.value, error: '', isLoading: true } };
        case PARTNERSERVICES_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, partnerserviceFilter: { search: state.partnerserviceFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, partnerserviceFilter: { select: state.partnerserviceFilter.select, search: action.value } };
        }
        return res;   
      default:
        return state;
    }
  }
  