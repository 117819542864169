import React, { useState } from 'react';
import { Card, Button, TextInput, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  productmastersdata,
} from '../../../../state/actions/productmastersAction';
import EsiEditor from '../../../../components/custom/richtext-editor/richtext-editor';
import Header from '../../../auth/components/header';
import isEmpty from '../../../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddProductMasterpagetwo = (props: any) => {
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);
  const productmasters = useSelector(state => state.productmasters.productmastersdata.productmaster);
  const dispatch = useDispatch();

  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [descriptionvalue, setDescriptionValue] = React.useState('');

  const EmailID = useSelector(state => state.auth?.user);

  const [rhFeaturesMin, setRhFeaturesMin] = useState(20);
  const [featuresvalue, setFeaturesValue] = React.useState('');
  //Screen dimension calculting call.
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  React.useEffect(() => {
    if (isEmpty(productmasters)) {
      navigation.navigate('AddProductMaster')
    } else {
      setDescriptionValue(productmasters.Long_Description)
      setFeaturesValue(productmasters.Features)
    }
  }, [productmasters]
  );
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  /*====================================
          Handler
  ====================================*/
  return (
    <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView
        ScrollView refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {productmasters &&
          <Formik enableReinitialize={true}
            initialValues={{
              Sku: productmasters.Sku,
              Category: productmasters.Category,
              Sub_Category: productmasters.Sub_Category,
              Brand: productmasters.Brand,
              Product_Name: productmasters.Product_Name,
              Product_Type: productmasters.Product_Type,
              Pattern_Name: productmasters.Pattern_Name,
              Upc_Code: productmasters.Upc_Code,
              Height: productmasters.Height,
              Weight: productmasters.Weight,
              Width: productmasters.Width,
              Length: productmasters.Length,
              Manufacturer: productmasters.Manufacturer,
              Manufacturer_Contact: productmasters.Manufacturer_Contact,
              Manufacturer_Cost: productmasters.Manufacturer_Cost,
              Mrp: productmasters.Mrp,
              Max_Mrp: productmasters.Max_Mrp,
              Min_Mrp: productmasters.Min_Mrp,
              Short_Description: productmasters.Short_Description,
              Warranty_Period: "",
              Created_By: EmailID.Email_Id,
              Features: "",
              Is_Product_Active: false,
              Long_Description: "",
              Sales_Start_Date: productmasters.Sales_Start_Date,
              Specifications: "",
              Status: "In-Active",
              Partner_Details_Id: EmailID.Partner_Details_Id
            }}
            validationSchema={Yup.object().shape({
              Warranty_Period: Yup.string().required('Warranty Period is required.'),
            })}
            onSubmit={(values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
              let submitTest = true;
              setSubmitting(false);
              const errors = {};
              values.Long_Description = descriptionvalue;
              values.Features = featuresvalue;
              if (!values.Long_Description || values.Long_Description.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
                submitTest = false;
                errors.Long_Description = "Description is required.";
              }
              else if (values.Long_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                submitTest = false;
                errors.Long_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
              }
              if (!values.Features || values.Features.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
                submitTest = false;
                errors.Features = "Features is required.";
              }
              else if (values.Features.replace(/(<([^>]+)>)/ig, '').trim().length < rhFeaturesMin) {
                submitTest = false;
                errors.Features = "Please provide at least " + rhFeaturesMin + " characters.";
              }
              setErrors(errors);
              if (submitTest) {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.Is_Product_Active) {
                  formValues.Is_Product_Active = 1;
                } else {
                  formValues.Is_Product_Active = 0;
                }
                dispatch(productmastersdata(formValues, navigation));
                navigation.navigate('AddProductmasters')
              }

            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}>
                <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('AddProductMaster')} >
                    Go Back
                  </Button>
                </View>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Add Product Master
                    </Text>
                    <View style={{ flexGrow: 1, marginLeft: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Warranty_Period && errors.Warranty_Period)}
                        label="Warranty Period"
                        mode='outlined'
                        multiline
                        value={values.Warranty_Period}
                        onChangeText={handleChange("Warranty_Period")}
                      />
                      <HelperText type="error" visible={Boolean(touched.Warranty_Period && errors.Warranty_Period)}>
                        {touched.Warranty_Period && errors.Warranty_Period}
                      </HelperText>
                    </View>
                    <View>
                      <EsiEditor label={"Description"} value={descriptionvalue} onUpdateValue={(value) => { setDescriptionValue(value) }} />
                      <HelperText type="error" visible={(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Long_Description && errors.Long_Description}>
                        {(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Long_Description && errors.Long_Description}
                      </HelperText>
                    </View>
                    <View>
                      <EsiEditor label={"Features"} value={featuresvalue} onUpdateValue={(value) => { setFeaturesValue(value) }} />
                      <HelperText type="error" visible={(!featuresvalue || featuresvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhFeaturesMin) && touched.Features && errors.Features}>
                        {(!featuresvalue || featuresvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhFeaturesMin) && touched.Features && errors.Features}
                      </HelperText>
                    </View>
                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                      <Button textColor={esiColor.itemButtenColor} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                       Next
                      </Button>
                    </View>
                  </Card.Content>
                </Card>
              </View>
            )}
          </Formik>}
      </ScrollView>
    </Surface >
  );
};

export default AddProductMasterpagetwo;
