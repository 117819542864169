import { Platform } from 'react-native';
import {
  GET_CART_ITEMS
} from '../../actions/shoppers/shoppercartActions';
import AsyncStorage from '@react-native-async-storage/async-storage';

const storeData = async (value: any) => {
  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem('cart', jsonValue)
  } catch (e) {
    // saving error
  }
}

const removeValue = async () => {
  try {
    await AsyncStorage.removeItem('cart');
  } catch (e) {
  }

}
const initialState = {
  cart: [],
  error: '',
  isLoading: false,
  defaultAddress: ""
}

const addCartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CART_ITEMS:
      return { ...state, cart: action.data, allCart: action.allCart?.data }
    default:
      return state;
  }
}
export default addCartReducer;