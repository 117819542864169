import axios from "axios";
import { meeapi } from "../node-actions/meeapi";
import shopperconfig from "../shoppers/shopperconfig";
import config from "../config";

const nodeheaders = shopperconfig.nodeheadersCommon;
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const urlGenarator = (url: any, pagination: any) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
export const BIDDING_COMMENTS_CREATE_REQUEST = "BIDDING_COMMENTS_CREATE_REQUEST";
export const BIDDING_COMMENTS_CREATE_SUCCESS = "BIDDING_COMMENTS_CREATE_SUCCESS";
export const BIDDING_COMMENTS_CREATE_FAIL = "BIDDING_COMMENTS_CREATE_FAIL";
export const ALL_BIDDING_COMMENTS_REQUEST = "ALL_BIDDING_COMMENTS_REQUEST";
export const ALL_BIDDING_COMMENTS_SUCCESS = "ALL_BIDDING_COMMENTS_SUCCESS";
export const ALL_BIDDING_COMMENTS_FAIL = "ALL_BIDDING_COMMENTS_FAIL";
export const ALL_BIDDING_PARTNER_PRODUCTS_REQUEST = "ALL_BIDDING_PARTNER_PRODUCTS_REQUEST";
export const ALL_BIDDING_PARTNER_PRODUCTS = "ALL_BIDDING_PARTNER_PRODUCTS";
export const ALL_BIDDING_PARTNER_PRODUCTS_FAIL = "ALL_BIDDING_PARTNER_PRODUCTS_FAIL";
export const QUOTE_REVIEW_CREATE_REQUEST = "QUOTE_REVIEW_CREATE_REQUEST";
export const QUOTE_REVIEW_CREATE_SUCCESS = "QUOTE_REVIEW_CREATE_SUCCESS";
export const QUOTE_REVIEW_CREATE_FAIL = "QUOTE_REVIEW_CREATE_FAIL";
export const QUOTE_ALL_USER_REVIEWS_REQUEST = "QUOTE_ALL_USER_REVIEWS_REQUEST";
export const QUOTE_ALL_USER_REVIEWS = "QUOTE_ALL_USER_REVIEWS";
export const QUOTE_ALL_USER_REVIEWS_FAIL = "QUOTE_ALL_USER_REVIEWS_FAIL";
export const ALL_BIDDING_IMAGE_PRODUCTS_REQUEST = "ALL_BIDDING_IMAGE_PRODUCTS_REQUEST";
export const ALL_BIDDING_IMAGE_PRODUCTS = "ALL_BIDDING_IMAGE_PRODUCTS";
export const ALL_BIDDING_IMAGE_PRODUCTS_FAIL = "ALL_BIDDING_IMAGE_PRODUCTS_FAIL";

export const getbiddingProductsAction = (
  formData: any, pagination = {},
  responseFunction: any
) => async (dispatch: any) => {
  meeapi.post(urlGenarator('/B2B_User_Products_Bidding/Get', pagination),
    formData,
  ).then(response => {
    let data = response.data;
    if (data.Success_Response.Is_Data_Exist == "0") {
      responseFunction(true, []);
    } else {
      try {
        responseFunction(true, data.results, data.Pagination);
      } catch (error) {
        responseFunction(true, data.results);
      }
    }
  }).catch(error => {
    responseFunction(false, error);
  });
};


export const getbiddingCategoriessAction = (
  formData = { Records_Filter: "All" }, pagination = {},
  responseFunction: any
) => async (dispatch: any) => {
  meeapi.post(urlGenarator('/Bidding_Products_Category/Get', pagination),
    formData,
  ).then(response => {
    let data = response.data;
    if (data.Success_Response.Is_Data_Exist == "0") {
      responseFunction(true, []);
    } else {
      try {
        responseFunction(true, data.results, data.Pagination);
      } catch (error) {
        responseFunction(true, data.results);
      }
    }
  }).catch(error => {
    responseFunction(false, error);
  });
};
export const availabileBiddingProducts = (
  formData = { Records_Filter: "All" }, pagination = {},
  responseFunction: any
) => async (dispatch: any) => {
  meeapi.post(urlGenarator('/B2B_New_Bids/Get', pagination),
    formData,
  ).then(response => {
    let data = response.data;
    if (data.Success_Response.Is_Data_Exist == "0") {
      responseFunction(true, []);
    } else {
      try {
        responseFunction(true, data.results, data.Pagination);
      } catch (error) {
        responseFunction(true, data.results);
      }
    }
  }).catch(error => {
    responseFunction(false, error);
  });
};
export const getSingleProductImagesAction = (Bidding_product: any,responseFunction:any) => async (dispatch) => {
  const formData = {
    "Bidding_Product_Id": Bidding_product,
    "Records_Filter": "FILTER",
    "Bidding_Product_Image_Id": ""
  }
  try {
    dispatch({
      type: ALL_BIDDING_IMAGE_PRODUCTS_REQUEST,
    });
    meeapi.post(urlGenarator(`/B2B_Bidding_Product_Images/Get`, formData),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        responseFunction([]);
      } else {
        responseFunction(data.results)
      }
    })
  } catch (error) {
  }
};


export const getBiddingProductsCart = (
  formData: any,
  responseFunction: any
) => async (dispatch: any) => {
  meeapi.post(urlGenarator('/B2B_Bidding_Product_Cart/Get', {}),
    formData,
  ).then(response => {
    let data = response.data;
  
    if (formData?.getCount) {
      try {
        responseFunction(true, data[0]);
      } catch (error) {

        responseFunction(true,
          {
            "Counts": 0
          }
        );
      }
    } else {
      try {
        if(data.Is_Data_Exist=='0'){
          responseFunction(true, []);
        }else{
          responseFunction(true, data);
        }
      
      } catch (error) {
        responseFunction(true, []);
      }
    }
  }).catch(error => {
    responseFunction(false, error);
  });
};

export const biddingPaymentAction = (request:any, setResponse:any,toast:any) => {
  return function (dispatch:any) {
    axios.put(`${config.url}/B2B_User_Products_Bidding/Update`, request, {
      headers: headers,
    })
      .then(response => {
        toast.show({ message: response.data.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
        setResponse(true, response.data.UI_Display_Message);
      })
      .catch(error => {
        toast.show({ message:'Currently server is not working. Please try again later.', type: 'info', duration: 3000, position: 'top' });
        setResponse(false, "Currently server is not working. Please try again later.");
      })
  }
}
export const getBiddingProductDetails = (
  Product_Id,
  callBackGetData,
  ID
) => async () => {
  try {
    const formData = {
      "Bidding_Product_Id": "",
      "Bidding_Product_Category_Id": "",
      "B2B_User_Products_Bidding_Id": Product_Id,
      "Records_Filter": "FILTER"

    };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Bidding_Details/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackGetData(data.results);
    }
  } catch (error) {
  }

};

export const getPartnerQuoteDetails = (
  Product_Id,
  callBackGetData,
  b2bbidding
) => async () => {
  try {
    const formData =
    {
      "B2B_Partner_Bidding_Quote_Id": Product_Id,
      "Bidding_Product_Id": "",
      "Records_Filter": "FILTER",
      "B2B_User_Products_Bidding_Id":b2bbidding
        };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2B_Partner_Bidding_Quotes/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackGetData(data.results);
    }
  } catch (error) {
  }

};
export const getAllBiddingAddressAction =
  (updateStates: any, formData: any) => async (dispatch: any) => {
    try {
      let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/Partner_Address/Get`, formData, {
        headers: nodeheaders
      });
      if (data) {
        if (data.Is_Data_Exist === '0') {
          updateStates([], false);
        } else {
          updateStates(data.results, false);
        }
      }
    } catch (error) {
    }
  };
export const getbiddingpartnerProductsAction = (ProductId: any, ID: any) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_BIDDING_PARTNER_PRODUCTS_REQUEST,
    });
    const formData = {
      "B2B_Partner_Bidding_Quote_Id": "",
      "Bidding_Product_Id": "",
      "Records_Filter": "FILTER",
      "B2B_User_Products_Bidding_Id":ProductId
    };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2B_Partner_Bidding_Quotes/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      dispatch({
        type: ALL_BIDDING_PARTNER_PRODUCTS,
        payload: data.results,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_BIDDING_PARTNER_PRODUCTS_FAIL,
      payload: error
    });
  }
};
export const getbiddingcommentsAction = (formData: any, callBackGetData: any, pagination: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_BIDDING_COMMENTS_REQUEST
    });
    meeapi.post(urlGenarator(`/B2B_Bidding_Product_Comments/Get`, pagination),
      formData,).then(response => {
        let data = response.data;
        if (data) {

          if (data.Is_Data_Exist === '0') {
            dispatch({
              type: ALL_BIDDING_COMMENTS_SUCCESS,
              payload: data
            });
          } else {
            callBackGetData(data.results)
            dispatch({
              type: ALL_BIDDING_COMMENTS_SUCCESS,
              payload: data.results
            });
          }
        }
      })
  } catch (err) {
    dispatch({
      type: ALL_BIDDING_COMMENTS_FAIL,
      payload: err
    });
  }
};
export const AddBiddingQuotecommentAction = (formData: any, getid: any, resetForm: any, setReload: any, callBackGetData: any) => async dispatch => {
  dispatch({
    type: BIDDING_COMMENTS_CREATE_REQUEST
  });
  await axios.post(`${config.nodecudurl}/B2B_Bidding_Product_Comments/Create`, formData, {
    headers: cudheaders,
  })
    .then(response => {

      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {

        dispatch({
          type: BIDDING_COMMENTS_CREATE_SUCCESS
        });
        resetForm();
        setReload(true);
        const formValue = {
          Partner_Bidding_Product_Quote_Id: getid,
          Records_Filter: "FILTER",
          b2b_Bidding_Product_Comment_Id: "",
          search: "",
          search_by_filter: ""
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "b2b_Bidding_Product_Comment_Id",
          SortOrder: "DESC"
        }
        dispatch(getbiddingcommentsAction(formValue, callBackGetData, pagination))
      } else {
        const errors = {};

        dispatch({
          type: BIDDING_COMMENTS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: BIDDING_COMMENTS_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};


export const addbiddingProductReviewAction = (formData: any, getid: any, resetForm: any, setReload: any, callBackGetData: any) => async dispatch => {
  dispatch({
    type: QUOTE_REVIEW_CREATE_REQUEST
  });
  await axios.put(`${config.url}/B2B_User_Products_Bidding/Update`, formData, {
    headers: headers,
  })
    .then(response => {

      let successResponse = response.data;
      if (successResponse.Response_Status === "Success") {

        dispatch({
          type: QUOTE_REVIEW_CREATE_SUCCESS
        });
        resetForm();
        setReload(true);
        const formValue = {
          Records_Filter: 'FILTER',
          B2B_User_Products_Bidding_Id: getid
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "B2B_User_Products_Bidding_Id",
          SortOrder: "DESC"
        }
        dispatch(getbiddingUserReviewsbyIdAction(formValue, callBackGetData, pagination))
      } else {
        const errors = {};

        dispatch({
          type: QUOTE_REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: QUOTE_REVIEW_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
export const getbiddingUserReviewsbyIdAction = (
  formData: any,
  callBackGetData: any
) => async (dispatch: any) => {
  meeapi.post(urlGenarator(`/B2B_Bidding_Product_Reviews/Get`, {}),
    formData,).then(response => {
      let data = response.data;
      if (data) {

        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: QUOTE_REVIEW_CREATE_SUCCESS,
            payload: data
          });
        } else {
          callBackGetData(data.results)
          dispatch({
            type: QUOTE_REVIEW_CREATE_SUCCESS,
            payload: data.results
          });
        }
      }
    })
}

export const getProductsImageAction = (Bidding_product: any, pagination = {}) => async (dispatch) => {
  const formData = {
    "Bidding_Product_Id": Bidding_product,
    "Records_Filter": "FILTER",
    "Bidding_Product_Image_Id": ""
  }
  try {
    dispatch({
      type: ALL_BIDDING_IMAGE_PRODUCTS_REQUEST,
    });
    meeapi.post(urlGenarator(`/B2B_Bidding_Product_Images/Get`, formData),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_BIDDING_IMAGE_PRODUCTS,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_BIDDING_IMAGE_PRODUCTS,
          payload: data.results,
        });

      }
    })
  } catch (error) {
    dispatch({
      type: ALL_BIDDING_IMAGE_PRODUCTS_FAIL,
      payload: error
    });
  }
};

export const payandplaceOrderAction = (request, setResponse, item) => {
  return function (dispatch) {
    axios.put(`${config.url}/B2B_User_Products_Bidding/Update`, request, {
      headers: headers,
    })
      .then(response => {
        setResponse(true, response.data, item);
      })
      .catch(error => {
        setResponse(false, "Currently server is not working. Please try again later.");
      })
  }
}
export const biddingCancelAction = (request:any, setResponse:any,toast:any) => {
  return function (dispatch:any) {
    axios.put(`${config.url}/B2B_User_Products_Bidding/Update`, request, {
      headers: headers,
    })
      .then(response => {
        toast.show({ message: response.data.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
        setResponse(true, response.data.UI_Display_Message);
      })
      .catch(error => {
        toast.show({ message:'Currently server is not working. Please try again later.', type: 'info', duration: 3000, position: 'top' });
        setResponse(false, "Currently server is not working. Please try again later.");
      })
  }
}