import {
    DELIVERY_AGENT_CREATE_FAIL,
    DELIVERY_AGENT_CREATE_REQUEST,
    DELIVERY_AGENT_CREATE_SUCCESS,

    DELIVERY_AGENT_DATA,
    DELIVERY_AGENT_FILTER,

    DELIVERY_AGENT_FAIL,
    DELIVERY_AGENT_REQUEST,
    DELIVERY_AGENT_SUCCESS,
   DELIVERY_AGENT_GET_REQUEST,
  DELIVERY_AGENT_GET_SUCCESS,
  DELIVERY_AGENT_GET_FAIL ,
      DELIVERY_AGENT_UPDATE_FAIL,
    DELIVERY_AGENT_UPDATE_REQUEST,
    DELIVERY_AGENT_UPDATE_SUCCESS

} from "../actions/deliveryagentAction";


const initialState = {

    deliveryagents: { all: [], error: '', isLoading: false },
    deliveryagent: { deliveryagent: {}, error: '', isLoading: false },

    deliveryagentCreate: { deliveryagent: {}, error: '', isLoading: false },
    deliveryagentUpdate: { deliveryagent: {}, error: '', isLoading: false },

    deliveryagentFilter: { select: 'All', search: '' },
    deliveryagentData: { data: {}, error: '', isLoading: false },
};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case DELIVERY_AGENT_REQUEST:
            return { ...state, deliveryagents: { all: state.deliveryagents.all, error: '', isLoading: true } };
        case DELIVERY_AGENT_SUCCESS:
            return { ...state, deliveryagents: { all: action.payload, error: '', isLoading: false } };
        case DELIVERY_AGENT_FAIL:
            return { ...state, deliveryagents: { all: [], error: action.payload, isLoading: false } };
            case DELIVERY_AGENT_GET_REQUEST:
                return { ...state, deliveryagent: { deliveryagent: {}, error: '', isLoading: true } };
              case DELIVERY_AGENT_GET_SUCCESS:
                return { ...state, deliveryagent: { deliveryagent: action.payload, error: '', isLoading: false } };
              case DELIVERY_AGENT_GET_FAIL:
                return { ...state, deliveryagent: { deliveryagent: {}, error: action.payload, isLoading: false } };
        case DELIVERY_AGENT_CREATE_REQUEST:
            return { ...state, deliveryagentCreate: { deliveryagent: {}, error: '', isLoading: true } };
        case DELIVERY_AGENT_CREATE_SUCCESS:
            return { ...state, deliveryagentCreate: { deliveryagent: state.deliveryagentCreate.deliveryagent, error: '', isLoading: false } };
        case DELIVERY_AGENT_CREATE_FAIL:
            return { ...state, deliveryagentCreate: { deliveryagent: {}, error: action.payload, isLoading: false } };

        case DELIVERY_AGENT_UPDATE_REQUEST:
            return { ...state, deliveryagentUpdate: { deliveryagent: {}, error: '', isLoading: true } };
        case DELIVERY_AGENT_UPDATE_SUCCESS:
            return { ...state, deliveryagentUpdate: { deliveryagent: state.deliveryagentUpdate.deliveryagent, error: '', isLoading: false } };
        case DELIVERY_AGENT_UPDATE_FAIL:
            return { ...state, deliveryagentUpdate: { deliveryagent: {}, error: action.payload, isLoading: false } };

        case DELIVERY_AGENT_DATA:
            return { ...state, deliveryagentData: { data: action.value, error: '', isLoading: true } };

        case DELIVERY_AGENT_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, deliveryagentFilter: { search: state.deliveryagentFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, deliveryagentFilter: { select: state.deliveryagentFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}