import axios from 'axios';
import config from './config';

export const RELATEDPRODUCTS_REQUEST = "RELATEDPRODUCTS_REQUEST";
export const RELATEDPRODUCTS_SUCCESS = "RELATEDPRODUCTS_SUCCESS";
export const RELATEDPRODUCTS_FAIL = "RELATEDPRODUCTS_FAIL";

export const PRODUCT_FILTER_REQUEST = "PRODUCT_FILTER_REQUEST";
export const PRODUCT_FILTER_SUCCESS = "PRODUCT_FILTER_SUCCESS";
export const PRODUCT_FILTER_FAIL = "PRODUCT_FILTER_FAIL";

export const RELATEDPRODUCTS_FILTER = "RELATEDPRODUCTS_FILTER";

export const RELATEDPRODUCTS_DELETE_REQUEST = "RELATEDPRODUCTS_DELETE_REQUEST";
export const RELATEDPRODUCTS_DELETE_SUCCESS = "RELATEDPRODUCTS_DELETE_SUCCESS";
export const RELATEDPRODUCTS_DELETE_FAIL = "RELATEDPRODUCTS_DELETE_FAIL";

export const RELATED_PRODUCT_CREATE_REQUEST = "RELATED_PRODUCT_CREATE_REQUEST";
export const RELATED_PRODUCT_CREATE_SUCCESS = "RELATED_PRODUCT_CREATE_SUCCESS";
export const RELATED_PRODUCT_CREATE_FAIL = "RELATED_PRODUCT_CREATE_FAIL";

export const RELATED_PRODUCT_UPDATE_REQUEST = "PARTNER_UPDATE_REQUEST";
export const RELATED_PRODUCT_UPDATE_SUCCESS = "PARTNER_UPDATE_SUCCESS";
export const RELATED_PRODUCT_UPDATE_FAIL = "PARTNER_UPDATE_FAIL";
import { meeapi } from './node-actions/meeapi';
const headers = config.headersCommon;
export const invalid_data = () => {
  return {
    type: RESET
  }
}
const cudheaders = config.nodeheadersCommon;
/*=============================================================
                  update ProductPartner Action
===============================================================*/
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
      queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
export const EditrelatedproductAction = (formData: any, editrelatedproductdetails: any, navigation: any,toast:any) => async dispatch => {
  dispatch({
    type: RELATED_PRODUCT_UPDATE_REQUEST
  });
  axios.put(`${config.nodecudurl}/RelatedProducts/Update`,
    formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: RELATED_PRODUCT_UPDATE_SUCCESS
        });
        navigation.navigate('RelatedProducts', {relatedproductparams:editrelatedproductdetails});
        const formValue = {
          Records_Filter: 'FILTER',
          Product_Id: formData.Product_Id,
        };
        const pagination ={
          PageNo: 1,
           PageSize: 5, 
           SortBy: "PRODUCT_ID", 
           SortOrder: "ASC"
        }
        dispatch(getAllRelatedProductAction(formValue,pagination))
        toast.show({ message: 'Related Product Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
      } else {
        dispatch({
          type: RELATED_PRODUCT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: RELATED_PRODUCT_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};

/*=============================================================
                  create related product  Action
===============================================================*/
export const createRelatedProductAction = (formData: any, productid: any, navigation: any,toast:any,resetForm) => async dispatch => {
  dispatch({
    type: RELATED_PRODUCT_CREATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/RelatedProducts/Create`,
    formData,
    {
      headers: cudheaders
    })
    .then(response => {
      
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: RELATED_PRODUCT_CREATE_SUCCESS
        });
        const formValue = {
          Records_Filter: 'FILTER',
          Product_Id: productid,
        };
        const pagination ={
          PageNo: 1,
           PageSize: 5, 
           SortBy: "PRODUCT_ID", 
           SortOrder: "ASC"
        }
        resetForm()
        dispatch(getAllRelatedProductAction(formValue,pagination))
        navigation.navigate('RelatedProducts', {relatedproductparams: productid});
        toast.show({ message: 'Related Product Created Successfully.', type: 'info', duration: 3000, position: 'top' });
      } else {
        dispatch({
          type: RELATED_PRODUCT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: RELATED_PRODUCT_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};
/*=============================================================
              Get All Related Products Action
===============================================================*/
export const getAllRelatedProductAction = (formData: any,pagination:any) => async dispatch => {
  try {
    dispatch({
      type: RELATEDPRODUCTS_REQUEST
    });
    meeapi.post(urlGenarator(`/RelatedProducts/Get`,pagination),
    formData,
       ).then(response => {
         let data = response.data;
         if (data.Is_Data_Exist === '0') {
           dispatch({
             type: RELATEDPRODUCTS_SUCCESS,
             payload: []
           });
         } else {
            dispatch({
             type: RELATEDPRODUCTS_SUCCESS,
             payload: data
           });
         }
       })
     } 
     catch (err) {
       dispatch({
         type: RELATEDPRODUCTS_FAIL,
         payload: err
       });
     }
   };

   export const getAllProductssearch = (formData: any) => async dispatch => {
    if (formData === '1') {
      let formValues = {
        "Records_Filter": "ALL"
      };
      try {
        dispatch({
          type: PRODUCT_FILTER_REQUEST
        });
        let { data } = await axios.post(
          `${config.nodePartnerUrl}/Product_Management/Product_Get_Dropdown_Search`,
          formValues,
          {
            headers: config.nodeheadersCommon
          }
        );
        if (data) {
          if (data.Is_Data_Exist === '0') {
            dispatch({
              type: PRODUCT_FILTER_SUCCESS,
              payload: []
            });
          } else {
            dispatch({
              type: PRODUCT_FILTER_SUCCESS,
              payload: data?.results
            });
          }
        }
      } catch (err) {
        dispatch({
          type: PRODUCT_FILTER_FAIL,
          payload: err
        });
      }
    } else {
      let formValues = {
        "Product_Name": formData,
        "Records_Filter": "FILTER"
      };
      try {
        dispatch({
          type: PRODUCT_FILTER_REQUEST
        });
        let { data } = await axios.post(
          `${config.nodePartnerUrl}/Product_Management/Product_Get_Dropdown_Search`,
          formValues,
          {
            headers: config.nodeheadersCommon
          }
        );
        if (data) {
          if (data.Is_Data_Exist === '0') {
            dispatch({
              type: PRODUCT_FILTER_SUCCESS,
              payload: []
            });
          } else {
            dispatch({
              type: PRODUCT_FILTER_SUCCESS,
              payload: data?.results
            });
          }
        }
      } catch (err) {
        dispatch({
          type: PRODUCT_FILTER_FAIL,
          payload: err
        });
      }
    }
  };
export const relatedproductFilter = (fType: any, value: any) => {
  return function (dispatch) {
    dispatch({
      type: RELATEDPRODUCTS_FILTER,
      fType: fType,
      value: value,
    });
  }
}


//search
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: RELATEDPRODUCTS_FILTER,
     fType: "search", 
      value: "",
    });
  }
}