import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { Dialog, Card, DataTable, Button, Text, Searchbar, Portal, Surface } from 'react-native-paper';
import { IconButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import RenderHtml from 'react-native-render-html';
import Header from '../auth/components/header';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { deleteTicketsAction, getAllTicketsAction, ticketFilter } from '../../state/actions/ticketActions';
import isEmpty from '../../state/validations/is-empty';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';

const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Ticket_Id',
        label: 'Ticket ID'
    },
    {
        value: 'Ticket_Subject',
        label: 'Ticket Subject'
    },
    {
        value: 'Ticket_Status',
        label: 'Status'
    },
];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const numberOfItemsPerPageList = [5, 10, 20];

function TicketDetails({ navigation }: { navigation: any }) {
    const [page, setPage] = React.useState(0);
    const [showDropDown, setShowDropDown] = React.useState(false);

    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);

    const tickets = useSelector(state => state.ticket.tickets.all);
    let ticketMenu = useSelector(state => state.ticket.ticketFilter.select);
    let ticketSearch = useSelector(state => state.ticket.ticketFilter.search);

    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, tickets?.length);
    const EmailID = useSelector(state => state.auth?.user);

    const dispatch = useDispatch()
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    useEffect(() => {
        if (isEmpty(tickets)) {
            let formData = {
                Records_Filter: "All",
                Partner_Details_Id: EmailID?.Partner_Details_Id
            };
            dispatch(getAllTicketsAction(formData, toast))
            dispatch(ticketFilter('search', ''));
            dispatch(ticketFilter('select', 'All'));
        }
    }, [tickets]);

    const [oldSearch, setOldSearch] = React.useState(0);

    const checkTicketsForTable = () => {

        if (ticketSearch) {
            try {
                if (ticketMenu === "All") {
                    return tickets.filter(x => {
                        let keys = ["Ticket_Id", "Ticket_Subject", "Ticket_Status"];
                        for (let i = 0; i < keys.length; i++) {
                            try {
                                if (x[keys[i]].toLowerCase().includes(ticketSearch.toLowerCase())) {
                                    return true;
                                }
                            } catch (error) {
                                if (x[keys[i]].toString().toLowerCase().includes(ticketSearch.toLowerCase())) {
                                    return true;
                                }
                            }
                        }
                    });

                } else {
                    return tickets.filter(x => (x[ticketMenu] ? x[ticketMenu].toLowerCase() : '').includes(ticketSearch.toLowerCase()));
                }
            } finally {
                if (page > 0) {
                    if (oldSearch !== ticketSearch.length) {
                        setOldSearch(ticketSearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch !== ticketSearch.length) {
                    setOldSearch(ticketSearch.length);
                    setPage(0);
                }
            }
        }
        else {
            return tickets
        }
    }

    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Ticket_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
            return -1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {

            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const [visible, setVisible] = React.useState(false);
    const [Edata, setData] = React.useState();

    const hideDialog = () => setVisible(false);

    const handleChange = itemValue => {
        dispatch(ticketFilter('select', itemValue));
    };
    const handleSearch = value => {
        dispatch(ticketFilter('search', value));
    };

    const data = stableSort(
        checkTicketsForTable(), getComparator(order, orderBy))


    const deleteTicket = (ticket) => {
        if (ticket) {
            const deleteData = {
                "Ticket_Id": ticket.Ticket_Id
            }
            dispatch(deleteTicketsAction(deleteData, toast));
        }
    };
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
                <Portal>
                    <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions <= 500 ? "95%" : "75%", maxHeight: (dimensions >= 500) ? "auto" : 500, marginLeft: 'auto', marginRight: 'auto' }}>
                        <View style={{ flexDirection: "row" }}><View style={{ flex: dimensions <= 500 ? 1 : 3 }} /><View style={{ flex: dimensions <= 500 ? 1 : 3 }} /><View style={{ flex: 0.3, justifyContent: "flex-end", paddingTop: 10 }}>
                            <IconButton icon="close" size={20} onPress={() => setVisible(!visible)} />
                        </View></View>
                        <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                            <Text style={{ color: '#27B6CC' }}>View Ticket</Text>
                        </Dialog.Title>
                        <Dialog.ScrollArea>
                            <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
                                <Dialog.Content style={{ marginLeft: -30 }}>
                                    <View style={{ flexDirection: "column", paddingTop: 15 }}>
                                        <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                            <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Ticket ID</Text></View>
                                            <View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{Edata?.Ticket_Id} </Text></View>
                                        </View>
                                        <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                            <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Subject</Text></View>
                                            <View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{Edata?.Ticket_Subject} </Text></View>
                                        </View>
                                        <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                            <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>User Type</Text></View>
                                            <View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{Edata?.Ticket_User_Type} </Text></View>
                                        </View>
                                        <View style={{ flexDirection: "row", paddingTop: 15 }}></View>
                                        <View style={{
                                            flexDirection: "row", paddingTop: 15,
                                            borderBottomWidth: 0.01,
                                            borderTopWidth: 0.01,
                                            borderColor: '#D6D6D7'
                                        }}>
                                            <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Description</Text></View>
                                            <View style={{ flex: 2, marginTop: -15 }}><RenderHtml source={{ html: Edata?.Ticket_Description }} /></View>
                                        </View>
                                        <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                            <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Created By</Text></View>
                                            <View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{Edata?.Created_By} </Text></View>
                                        </View>
                                        <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                            <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Status</Text></View>
                                            <View style={{ flex: 2 }}><Text style={{ color: Edata?.Ticket_Status === "Active" ? '#27B6CC' : "red" }}>{Edata?.Ticket_Status} </Text></View>
                                        </View>
                                    </View>
                                </Dialog.Content>
                            </ScrollView>
                        </Dialog.ScrollArea>
                    </Dialog>
                </Portal>
                <ScrollView refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  colors={['#27B6CC']}
                  tintColor={'#27B6CC'}
                  title={"Pull to refresh"}
                  titleColor={"#27B6CC"}
                />
              } >
                    <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                        <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('OrderMainCenter')}>
                            Go Back
                        </Button>
                    </View>
                    <View style={{
                        flexDirection: "column"
                    }}>
                        <Text style={{ fontSize: 20, fontWeight: "bold", paddingLeft: 17, paddingTop: 10 }}>Ticketing Management</Text>
                        <View style={{
                            flexDirection: "row", paddingTop: 5,
                        }}>
                            <View style={{ flex: 1.7 }} />
                            <View style={{ flex: 0.1 }} />
                        </View>
                    </View>
                    <View style={{
                        flexDirection: "row", paddingTop: 20
                    }}>
                        <View style={{ flex: dimensions <= 700 ? 0.15 : 0.04 }} />
                        <View style={{ flex: dimensions <= 700 ? 1.5 : 0.5 }}>
                            {Platform.OS !== "web" ?
                                <EsiSearchDropdown
                                    style={[style.dropdown]}
                                    placeholderStyle={style.placeholderStyle}
                                    selectedTextStyle={style.selectedTextStyle}
                                    inputSearchStyle={style.inputSearchStyle}
                                    iconStyle={style.iconStyle}
                                    data={filterOptions}
                                    containerStyle={style.containerstyle}
                                    maxHeight={180}
                                    labelField="label"
                                    valueField="value"
                                    placeholder={!showDropDown ? 'All' : ''}
                                    value={ticketMenu}
                                    onFocus={() => setShowDropDown(true)}
                                    onBlur={() => setShowDropDown(false)}
                                    onUpdateValue={item => {
                                        setShowDropDown(false);
                                        handleChange(item.value)
                                    }}
                                /> :
                                <Picker
                                    selectedValue={ticketMenu}
                                    style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', backgroundColor: '#ffff' }}
                                    onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                                >
                                    <Picker.Item label="All" value="All" />
                                    <Picker.Item label="Ticket ID" value="Ticket_Id" />
                                    <Picker.Item label="Ticket Subject" value="Ticket_Subject" />
                                    <Picker.Item label="Status" value="Ticket_Status" />
                                </Picker>}
                        </View>
                        <View style={{ flex: dimensions <= 700 ? 0.1 : 0.6 }} />
                        <View style={{ flex: 1.8 }}>
                            <Searchbar
                                style={{ borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7' }}
                                placeholder="Search"
                                onChangeText={(value) => handleSearch(value)}
                                value={ticketSearch}
                            />
                        </View>
                        <View style={{ flex: dimensions <= 700 ? 0.1 : 1.6 }} />
                        {(permissions.Ticket_Management === "write") && (dimensions >= 700 ?
                            <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 14 }}>
                                <Button style={{ backgroundColor: '#27B6CC' }} mode="contained" onPress={() => navigation.navigate('AddTicket')}>
                                    Add Ticket
                                </Button>
                            </View>

                            : null)}
                        <View style={{ flex: dimensions <= 700 ? null : 0.07 }} />
                    </View>
                    <ScrollView  horizontal={Platform.OS === 'web' && dimensions >= 500 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Employee_Id'}
                                            sortDirection={orderBy === 'Ticket_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Ticket_Id')}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Ticket ID</Text></DataTable.Title>
                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Employee_Email_Id'}
                                            sortDirection={orderBy === 'Ticket_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Ticket_Id')}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Ticket Subject</Text></DataTable.Title>
                                        <DataTable.Title style={style.title} active={orderBy === 'Is_Active'}
                                            sortDirection={orderBy === 'Is_Active' ? order : 'ascending'}
                                            onPress={createSortHandler('Is_Active')}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Status</Text></DataTable.Title>
                                        <DataTable.Title style={style.title}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Actions</Text></DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        data.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((ticket, index) => (
                                            <DataTable.Row style={style.databeBox} key={ticket.Ticket_Id}>
                                                <DataTable.Cell style={style.tablerow}>{ticket.Ticket_Id}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{ticket.Ticket_Subject}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{ticket.Ticket_Status}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    <IconButton
                                                        icon="eye"
                                                        color={'#27B6CC'}
                                                        size={20}
                                                        onPress={(e) => { setData(ticket); setVisible(!visible) }}
                                                    />
                                                    {(permissions.Ticket_Management === "write") &&
                                                        <IconButton
                                                            icon="square-edit-outline"
                                                            color={'#27B6CC'}
                                                            size={20}
                                                            onPress={(e) => navigation.navigate("EditTicket", { ticket: ticket })}
                                                        />}
                                                    {(permissions.Ticket_Management === "write") &&
                                                        <IconButton
                                                            icon="delete"
                                                            color={'#27B6CC'}
                                                            size={20}
                                                            onPress={(e) => deleteTicket(ticket)}
                                                        />}
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                        ))
                                    }
                                    {isEmpty(data) &&
                                        <Text style={{ fontSize: 16, display: 'flex', justifyContent: 'center', textAlign: "center" }}>
                                            No records found.
                                        </Text>
                                    }

                                    <DataTable.Pagination
                                        page={page}
                                        numberOfPages={Math.ceil(tickets?.length / numberOfItemsPerPage)}
                                        onPageChange={page => setPage(numberOfItemsPerPage < data?.length ? page : 0)}
                                        label={tickets?.length ? `${from + 1}-${to} of ${data?.length}` : '0-0'}
                                        showFastPaginationControls={numberOfItemsPerPage > data?.length ? false : true}
                                        numberOfItemsPerPageList={numberOfItemsPerPageList}
                                        numberOfItemsPerPage={numberOfItemsPerPage}
                                        onItemsPerPageChange={onItemsPerPageChange}
                                        selectPageDropdownLabel={'Rows per page'}
                                    />
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>
                </ScrollView>
        </Surface>
    );
}

const style = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainbox: {
        textAlign: 'center',
        margin: 15,
        flex: 1,
        justifyContent: 'space-between',
    },
    databeBox: {
        margin: 10,
        textAlign: 'center',
    },
    databeHeader: {
        margin: 10,
        textAlign: 'center',
    },
    dropdown: {
        height: 50,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    containerstyle: {
        width: 150
    },
    title: {
        fontWeight: "bold",
        fontSize: 12,
        justifyContent: "center",
        textAlign: "center",
        width: 160
    },
    titletext: {
        fontWeight: "bold",
        fontSize: 14,
        color: 'black',
    },
    tablerow: {
        justifyContent: "center",
        textAlign: "center",
        width: 140
    },
    status: {
        justifyContent: "center",
        textAlign: "center",
        width: 120
    },
});

export default TicketDetails