import {
    SBCPARTNERCITYPRODUCT_REQUEST,
    SBCPARTNERCITYPRODUCT_SUCCESS,
    SBCPARTNERCITYPRODUCT_FAIL,

    SBCPARTNERCITYPRODUCT_GET_REQUEST,
    SBCPARTNERCITYPRODUCT_GET_SUCCESS,
    SBCPARTNERCITYPRODUCT_GET_FAIL,

    SBCPARTNERCITYPRODUCT_CREATE_REQUEST,
    SBCPARTNERCITYPRODUCT_CREATE_SUCCESS,
    SBCPARTNERCITYPRODUCT_CREATE_FAIL,

    SBCPARTNERCITYPRODUCT_UPDATE_REQUEST,
    SBCPARTNERCITYPRODUCT_UPDATE_SUCCESS,
    SBCPARTNERCITYPRODUCT_UPDATE_FAIL,

    SBCPARTNERCITYPRODUCT_DELETE_REQUEST,
    SBCPARTNERCITYPRODUCT_DELETE_SUCCESS,
    SBCPARTNERCITYPRODUCT_DELETE_FAIL,

    SBCPARTNERCITY_PRODUCTS_REQUEST,
    SBCPARTNERCITY_PRODUCTS_SUCCESS,
    SBCPARTNERCITY_PRODUCTS_FAIL,

    SBCPARTNERCITYPRODUCT_FILTER,

} from '../actions/sbcpartnercityProductAction'

const initialState = {

    sbcpartnercityproducts: { all: [], error: '', isLoading: false },
    sbcpartnercityproduct: { sbcpartnercityproduct: {}, error: '', isLoading: false },

    sbcpartnercityproductCreate: { sbcpartnercityproduct: {}, error: '', isLoading: false },
    sbcpartnercityproductUpdate: { sbcpartnercityproduct: {}, error: '', isLoading: false },
    sbcpartnercityproductDelete: { sbcpartnercityproduct: {}, error: '', isLoading: false },

    sbcpartcityproducts: { products: [], error: '', isLoading: false },

    sbcpartnercityproductFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case SBCPARTNERCITYPRODUCT_REQUEST:
            return { ...state, sbcpartnercityproducts: { all: state.sbcpartnercityproducts.all, error: '', isLoading: true } };
        case SBCPARTNERCITYPRODUCT_SUCCESS:
            return { ...state, sbcpartnercityproducts: { all: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITYPRODUCT_FAIL:
            return { ...state, sbcpartnercityproducts: { all: [], error: action.payload, isLoading: false } };

        case SBCPARTNERCITYPRODUCT_GET_REQUEST:
            return { ...state, sbcpartnercityproduct: { sbcpartnercityproduct: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYPRODUCT_GET_SUCCESS:
            return { ...state, sbcpartnercityproduct: { sbcpartnercityproduct: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITYPRODUCT_GET_FAIL:
            return { ...state, sbcpartnercityproduct: { sbcpartnercityproduct: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITYPRODUCT_CREATE_REQUEST:
            return { ...state, sbcpartnercityproductCreate: { sbcpartnercityproduct: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYPRODUCT_CREATE_SUCCESS:
            return { ...state, sbcpartnercityproductCreate: { sbcpartnercityproduct: state.sbcpartnercityproductCreate.sbcpartnercityproduct, error: '', isLoading: false } };
        case SBCPARTNERCITYPRODUCT_CREATE_FAIL:
            return { ...state, sbcpartnercityproductCreate: { sbcpartnercityproduct: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITYPRODUCT_UPDATE_REQUEST:
            return { ...state, sbcpartnercityproductUpdate: { sbcpartnercityproduct: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYPRODUCT_UPDATE_SUCCESS:
            return { ...state, sbcpartnercityproductUpdate: { sbcpartnercityproduct: state.sbcpartnercityproductUpdate.sbcpartnercityproduct, error: '', isLoading: false } };
        case SBCPARTNERCITYPRODUCT_UPDATE_FAIL:
            return { ...state, sbcpartnercityproductUpdate: { sbcpartnercityproduct: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITYPRODUCT_DELETE_REQUEST:
            return { ...state, sbcpartnercityproductDelete: { sbcpartnercityproduct: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYPRODUCT_DELETE_SUCCESS:
            return { ...state, sbcpartnercityproductDelete: { sbcpartnercityproduct: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITYPRODUCT_DELETE_FAIL:
            return { ...state, sbcpartnercityproductDelete: { sbcpartnercityproduct: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITY_PRODUCTS_REQUEST:
            return { ...state, sbcpartcityproducts: { products: state.sbcpartcityproducts.products, error: '', isLoading: true }, };
        case SBCPARTNERCITY_PRODUCTS_SUCCESS:
            return { ...state, sbcpartcityproducts: { products: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITY_PRODUCTS_FAIL:
            return { ...state, sbcpartcityproducts: { products: [], error: action.payload, isLoading: false } };

        case SBCPARTNERCITYPRODUCT_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, sbcpartnercityproductFilter: { search: state.sbcpartnercityproductFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, sbcpartnercityproductFilter: { select: state.sbcpartnercityproductFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}