

import axios from 'axios';
import config from './config';
import { meeapi, meenodeapi } from './node-actions/meeapi';
import { Dispatch } from "redux"
import AsyncStorage from '@react-native-async-storage/async-storage';
import shopperconfig from './shoppers/shopperconfig';

const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
export enum ActionType {
    USER_SIGNIN = "USER_SIGNIN",
    USER_REQUEST = "USER_REQUEST",
    USER_SUCCESS = "USER_SUCCESS",
    USER_FAIL = "USER_FAIL",
    USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST",
    USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS",
    USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL",
    USER_LOGOUT = "USER_LOGOUT",
}
export const api = axios.create({
    // baseURL: BASE_URL,
    baseURL: config.delurl,
    headers: config.headersCommon,

    // transformRequest: [function (data) {
    //   // Do whatever you want to transform the data

    //   return data;
    // }],

});
const removeValue = async () => {
    try {
        await AsyncStorage.removeItem('user')
        await AsyncStorage.removeItem('Total_Count')
    } catch (e) {
    }

}
const getDataAsync = async () => {
    try {
        const jsonValue = await AsyncStorage.getItem('user');
        return jsonValue != null ? jsonValue : null;
    } catch (e) {
        // error reading value
    }
}
api.interceptors.request.use(

    request => {
        return request;
    },
    error => {
        // Do something with response error


        return Promise.reject(error);
    },
);

// Add a response interceptor
api.interceptors.response.use(
    response => {
        return response;
    },

    error => {
        // Do something with response error
        return Promise.reject(error);
    },
);

export const getData = () => {
    return (dispatch: Dispatch) => {
        getDataAsync().then(value => {
            dispatch({
                type: ActionType.USER_SIGNIN,
                data: value
            })
        });
    }

}
const urlGenarator = (url: any, pagination: any) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}
export const signUpEmailExist = (input: any, emailCheckResponse: any) => {
    return function (dispatch: any) {
        axios.post(`${config.url}/Partner_Signup_Flow_New/Signup_Email_Check`, { Email_ID: input }, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    emailCheckResponse(true, '', response.data, input);
                }
                else {
                    emailCheckResponse(false, data.UI_Display_Message, data, input);
                }
            })
            .catch(error => {
                // emailCheckResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}
export const signUpMobileExist = (input: any, mobileCheckResponse: any) => {
    return function (dispatch: any) {
        axios.post(`${config.url}/Partner_Signup_Flow_New/Signup_Mobile_Check_OTP_Generation`, { Mobile: "+91 " + input }, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    mobileCheckResponse(true, '', response.data, input);
                }
                else {
                    mobileCheckResponse(false, data.UI_Display_Message, data, input);
                }
            })
            .catch(error => {
            })
    }
}


export const sessionGetStatus = (formData: any, getresponse: any) => async dispatch => {
    const pagination = {
        PageNo: 1, PageSize: 2, SortBy: 'Partner_Session_Id', SortOrder: 'DESC'
    }
    const payload = {
        "Is_Active": "",
        "Partner_Email_Id": "",
        "Partner_Session_Id": formData
    }
    try {
        meeapi.post(urlGenarator(`/Partner_Session/Get`, pagination),
            payload
        ).then(response => {
            let data = response.data;
            if (data?.Success_Response?.Response_Status === "Success") {
                if (data.results[0]?.Is_Active === 0) {
                    getresponse(true)
                } else {
                    getresponse(false)
                }
            } else {
                getresponse(true)
            }
        })
    } catch (err) {
    }
};


export const signUpNew = (input: any, signupResponse: any, signupsuccessResponse: any) => {
    return function (dispatch: any) {
        dispatch({
            type: ActionType.USER_SIGNUP_REQUEST
        });
        axios.post(`${config.url}/Partner_Signup_Flow_New/Partner_Signup`, input, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    signupResponse(true, '', response.data);
                    signupsuccessResponse(true, response.data);
                }
                else {
                    signupResponse(false, data.UI_Display_Message, data);
                    signupsuccessResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                signupResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const getLocationByIpAddress = (pagination = {},
    responseFunction: any
) => async (dispatch: any) => {
    meenodeapi.get(urlGenarator('/Home/ip-geolocation', pagination),
    ).then(response => {
        let data = response.data;
        responseFunction(data);
    }).catch(error => {
        responseFunction(error);
    });
};
export const signInMobileExist = (input: any, mobileCheckResponse: any) => {
    return function (dispatch: any) {
        axios.post(`${config.url}/Partner_Signup_Flow_New/Login_Mobile_OTP_Generation`, { Mobile: "+91 " + input }, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    mobileCheckResponse(true, '', response.data, input);
                }
                else {
                    mobileCheckResponse(false, data.UI_Display_Message, data, input);
                }
            })
            .catch(error => {
            })
    }
}
export const loginCheckNew = (input: any, signinResponse: any, loginUser: any) => {
    return function (dispatch: any) {
        dispatch(loginCheckRequest());
        axios.post(`${config.url}/Partner_Signup_Flow_New/Partner_Signin`, input, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    dispatch(getLogedInUser(data.Partner_Email_ID, true, signinResponse, data.User_Session_Id, loginUser, () => { }));
                }
                else {
                    signinResponse(false, data.UI_Display_Message, data?.Active ? "activate" : "");
                    dispatch(loginCheckFail(data.UI_Display_Message, true));
                }
            })
            .catch(error => {
                signinResponse(false, "Currently server is not working. Please try again later.", "");
                dispatch(loginCheckFail("Currently server is not working. Please try again later.", true));
            })
    }
}
export const getLogedInUser = (email: any, isLogin: any, signinResponse: any, Partner_Session_Id: any, loginUser: any, EmailID: any) => {
    return function (dispatch: any) {
        axios.post(`${shopperconfig.nodePartnerUrl}/Partner_Login/Employee_Get`, {
            "search_by_filter": "",
            "search": "",
            "Email_Id": email,
            "Partner_Details_Id": "",
            "Partner_Employee_Id": "",
            "Records_Filter": "FILTER"
        },
            {
                headers: nodeheaders
            })
            .then(response => {
                let successResponse = response.data.Success_Response ? response.data.Success_Response : response.data;
                let user = response.data.Success_Response ? JSON.parse(JSON.stringify(response.data.results[0])) : false;
                if (successResponse.Response_Status == "Success" && user) {
                    const modifiedPayload = { ...EmailID };
                    delete modifiedPayload.Partner_Session_Id;
                    const areObjectsEqual = JSON.stringify(modifiedPayload) === JSON.stringify(user);
                    user.Partner_Session_Id = Partner_Session_Id;
                    if (!areObjectsEqual) {
                        if (isLogin) {
                            signinResponse(true);
                            loginUser(true, user)
                        }
                        dispatch(loginCheckSuccess(user, isLogin));
                    }
                } else {
                    if (isLogin) {
                        signinResponse(false, successResponse.UI_Display_Message);
                        dispatch(loginCheckFail(successResponse.UI_Display_Message, isLogin));
                    }
                }
            })
            .catch(error => {
                if (isLogin) {
                    signinResponse(false, "Currently server is not working. Please try again later.");
                    dispatch(loginCheckFail("Currently server is not working. Please try again later.", isLogin));
                }
            })
    }
}




export const loginCheck = (input: any, signinResponse: any, loginUser: any) => {
    return function (dispatch: any) {
        dispatch(loginCheckRequest());
        axios.post(`${config.url}/Partner_Login/Partner_Login`, input, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;

                if (data.Response_Status === "Success") {
                    dispatch(getLogedInUser(data.Partner_EmailID, true, signinResponse, data.Partner_Session_Id, loginUser, () => { }));

                }
                else {
                    signinResponse(false, data.UI_Display_Message, data?.Active ? "activate" : "");
                    dispatch(loginCheckFail(data.UI_Display_Message, true));
                }
            })
            .catch(error => {
                signinResponse(false, "Currently server is not working. Please try again later.", "");
                dispatch(loginCheckFail("Currently server is not working. Please try again later.", true));
            })
    }
}

export const signUpCheck = (input: any, signupResponse: any, signupsuccessResponse: any) => {
    return function (dispatch: any) {
        dispatch({
            type: ActionType.USER_SIGNUP_REQUEST
        });
        axios.post(`${config.url}/Partner_Login/Partner_Signup`, input, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    signupResponse(true, '', response.data);
                    signupsuccessResponse(true, response.data);
                }

                else {
                    signupResponse(false, data.UI_Display_Message, data);
                    signupsuccessResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                signupResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}
export const signUp = (input: any, signupResponse: any) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.USER_SIGNUP_REQUEST
        });
        let payload = {
            "Authorised_Person_Name": input.Authorised_Person_Name,
            "Billing_Email": input.Billing_Email,
            "City": input.City,
            "Company_Name": input.Company_Name,
            "Country": input.Country,
            "Delivery_Availability": input.Delivery_Availability,
            "Description": input.Description,
            "Email_Id": input.Email_Id,
            "Established_Date": input.Established_Date,
            "Is_Email_Verified": input.Is_Email_Verified,
            "Is_Mobile_Verified": input.Is_Mobile_Verified,
            "Is_Reseller": input.Is_Reseller,
            "Is_Shopper": input.Is_Shopper,
            "Mobile": input.Mobile,
            "Partner_Person_Name": input.Partner_Person_Name,
            "Partner_Type": input.Partner_Type,
            "State": input.State
        };
        axios.post(`${config.url}/Partner_Login/Partner_Signup`, payload, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    signupResponse(true, '');

                }
                else {
                    signupResponse(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                signupResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const forgotCheck = (input: any, forgotResponse: any) => {
    return (dispatch: Dispatch) => {
        let payload = {
            "Email_Id": input.Email_Id,
            "Base_URL": input.Base_URL
        };
        axios.post(`${config.url}/Partner_Login/Partner_Forget_Password`, payload, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    forgotResponse(true, '', data.OTP, data.Mobile, data.Is_Email_OTP);
                }
                else {
                    forgotResponse(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                forgotResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const forgot = (input: any, forgotResponse: any) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.USER_SIGNUP_REQUEST
        });
        let payload = {
            "Email_Id": input.email,
            "Password": input.password
        };
        axios.post(`${config.url}/Partner_Login/Reset_Password`, payload, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    forgotResponse(true, '');
                }
                else {
                    forgotResponse(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                forgotResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}
export const tokenActivateAccountAction = (input: any, setResponse: any) => {
    return function (dispatch: any) {
        axios.put(`${config.url}/Partner_Login/Status_Active`, input, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    setResponse(true, '', response.data);
                }
                else {
                    setResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const loginOut = (navigate: any, notificationToken: any, user: any, setLogoutDisabile: any, toast: any) => {
    return function (dispatch: any) {
        let payload = {
            "Device_Token": notificationToken,
            "Partner_Session_ID": user.Partner_Session_Id,
            "User_Email_Id": user.Email_Id
        };
        axios.post(`${config.nodecudurl}/Partner_Deletion/LogOut`, payload, {
            headers: cudheaders
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    removeValue().then((data) => {
                        getDataAsync().then((data) => {
                            navigate.replace('SignIn');
                            toast.show({ message: 'Logged Out Successfully.', type: 'info', duration: 3000, position: 'top' });
                            setLogoutDisabile(true);
                            dispatch(loginOutSuccess());
                        })
                    });
                }
                else {
                    setLogoutDisabile(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                setLogoutDisabile(false, "Please try again later");
            })

    }
}
export const sessionCheckAction = (user: any) => {
    return function (dispatch: any) {
        let payload = {
            "Is_Active": "1",
            "Partner_Email_Id": user.Email_Id,
            "Partner_Session_Id": user.Partner_Session_Id
        };
        axios.post(`${config.nodePartnerUrl}/Partner_Session/Get`, payload, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data?.Success_Response?.Response_Status == "Success") {
                    if (!(data?.User_Session[0].Is_Active == "1")) {
                        removeValue().then((data) => {
                            getDataAsync().then((data) => {
                                dispatch(loginOutSuccess());
                            })
                        });
                    }
                }
                else {

                }
            })
            .catch(error => {
            })
    }
}
export const loginCheckRequest = () => {
    return {
        type: ActionType.USER_REQUEST
    }
}
export const loginCheckSuccess = (user: any, isLogin: any) => {
    return {
        type: ActionType.USER_SUCCESS,
        data: user,
        login: isLogin
    }
}
export const loginCheckFail = (error: any, isLogin: any) => {
    return {
        type: ActionType.USER_FAIL,
        data: error,
        login: isLogin

    }
}
export const loginOutSuccess = () => {
    return {
        type: ActionType.USER_LOGOUT
    }
}

export const deleteAccountAction = (navigate: any, user: any, setLogoutDisabile: any) => {
    return function (dispatch: any) {
        let payload = {
            "Email_Id": user.Email_Id
        };

        axios.post(`${config.nodecudurl}/Partner_Deletion/Account_Deletion`, payload,
            {

                headers: cudheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {

                    removeValue().then((data) => {
                        getDataAsync().then((data) => {

                            navigate.navigate("SignIn");
                            setLogoutDisabile(true);
                            dispatch(loginOutSuccess());
                        })
                    });
                }
                else {
                    setLogoutDisabile(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                setLogoutDisabile(false, "Please try again later.");
            })
    }
}

export const tokenVarivicationAction = (input: any, setResponse: any) => {
    return function (dispatch: any) {
        axios.post(`${config.url}/Partner_Login/Check_Password_URL`, input, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    setResponse(true, '', data);
                }
                else {
                    setResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}
export const setPasswordAction = (input: any, setResponse: any) => {
    return function (dispatch: any) {
        axios.post(`${config.url}/Partner_Login/Password_Creation`, input, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    setResponse(true, '', response.data);
                }
                else {
                    setResponse(false, data.UI_Display_Message, data);
                }
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const accountActiveActions = (input: any, responseFunction: any) => {
    return function (dispatch: any) {
        axios.post(`${config.url}/Partner_Login/Active_URL_Generate`, input, {
            headers: config.headersCommon
        })
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    responseFunction(true, '');
                }
                else {
                    responseFunction(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                responseFunction(false, "Currently server is not working. Please try again later.");
            })
    }
}
export const getbussinestypeAction = (formData: any, callBacktypeData: any) => async dispatch => {
    try {
        let { data, status } = await axios.post(
            `${config.url}/Partner_Registraton/Partner_Business_Type_Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
            formData,
            {
                // Axios looks for the `auth` option, and, if it is set, formats a
                // basic auth header for you automatically.
                headers: headers
            }
        );
        if (data) {
            callBacktypeData(data.Business_Types)

        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};
export const getbussinessubtypeAction = (formData: any, callBacksubtypeData: any) => async dispatch => {
    try {
        let { data, status } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Partner_Registraton/Partner_Business_Type_Get`,
            formData,
            {
                // Axios looks for the `auth` option, and, if it is set, formats a
                // basic auth header for you automatically.
                headers: nodeheaders
            }
        );
        if (data) {
            callBacksubtypeData(data.results[0].Business_Sub_Types)
        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};
export const getCountryAction = (formData: any, callBackCountryData: any) => async dispatch => {
    try {
        let { data, status } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Country_State_City/Get`,
            formData,
            {
                // Axios looks for the `auth` option, and, if it is set, formats a
                // basic auth header for you automatically.
                headers: nodeheaders
            }
        );
        if (data) {
            callBackCountryData(data.results)

        }
    } catch (err) {
        dispatch({
            payload: err
        });
    }
};
