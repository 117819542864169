import {
    PHYSICALPARTNERS_REQUEST,
    PHYSICALPARTNERS_SUCCESS,
     PHYSICALPARTNERS_FAIL,
     PHYSICALPARTNER_REQUEST,
     PHYSICALPARTNER_SUCCESS,
      PHYSICALPARTNER_FAIL,
    PHYSICALPARTNER_GET_REQUEST,
     PHYSICALPARTNER_GET_SUCCESS,
     PHYSICALPARTNER_GET_FAIL,
     PHYSICALPARTNER_UPDATE_REQUEST,
     PHYSICALPARTNER_UPDATE_SUCCESS,
     PHYSICALPARTNER_UPDATE_FAIL,
     PROFILERESET
        
      } from '../actions/partnerprofileActions';
    
      const initialState = {
        partners: { all: [], error: '', isLoading: false },
        profile: { all: [], error: '', isLoading: false },
        partner: { partner: {}, error: '', isLoading: false },
        physicalpartnerUpdate: { physicalpartner: {}, error: '', isLoading: false },
     
      };
      
      export default function (state = initialState, action:any) {
        switch (action.type) {
          case PHYSICALPARTNERS_REQUEST:
            return { ...state, profile: { all: state.profile.all, error: '', isLoading: true } };
          case PHYSICALPARTNERS_SUCCESS:
            return { ...state, profile: { all: action.payload, error: '', isLoading: false } };
          case PHYSICALPARTNERS_FAIL:
            return { ...state, profile: { all: [], error: action.payload, isLoading: false } };
    case PHYSICALPARTNER_REQUEST:
            return { ...state, partners: { all: state.partners.all, error: '', isLoading: true } };
          case PHYSICALPARTNER_SUCCESS:
            return { ...state, partners: { all: action.payload, error: '', isLoading: false } };
          case PHYSICALPARTNER_FAIL:
            return { ...state, partners: { all: [], error: action.payload, isLoading: false } };
    
          case PHYSICALPARTNER_GET_REQUEST:
            return { ...state, partner: { partner: {}, error: '', isLoading: true } };
          case PHYSICALPARTNER_GET_SUCCESS:
            return { ...state, partner: { partner: action.payload, error: '', isLoading: false } };
          case PHYSICALPARTNER_GET_FAIL:
            return { ...state, partner: { partner: {}, error: action.payload, isLoading: false } };
    
          case PHYSICALPARTNER_UPDATE_REQUEST:
            return { ...state, physicalpartnerUpdate: {partner: {}, error: '', isLoading: true } };
          case PHYSICALPARTNER_UPDATE_SUCCESS:
            return { ...state, physicalpartnerUpdate: {partner: state.physicalpartnerUpdate.physicalpartner, error: '', isLoading: false } };
          case PHYSICALPARTNER_UPDATE_FAIL:
            return { ...state, physicalpartnerUpdate: {partner: {}, error: action.payload, isLoading: false } };
            case PROFILERESET:
              return { ...state, partners: { all: [], error: '', isLoading: false } };
          default:
            return state;      
        }
      }