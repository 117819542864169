import {
  BIDDING_QUOTES_REQUEST,
  BIDDING_QUOTES_SUCCESS,
  BIDDING_QUOTES_FAIL,
    SINGLE_BIDDING_QUOTES_REQUEST,
  SINGLE_BIDDING_QUOTES_SUCCESS,
  SINGLE_BIDDING_QUOTES_FAIL,
  PARTNER_BIDDING_QUOTE_UPDATE_REQUEST,
  PARTNER_BIDDING_QUOTE_UPDATE_SUCCESS,
  PARTNER_BIDDING_QUOTE_UPDATE_FAIL,

  
  BIDDING_QUOTE_CREATE_REQUEST,
  BIDDING_QUOTE_CREATE_SUCCESS,
  BIDDING_QUOTE_CREATE_FAIL,
  BIDDING_PRODUCT_UPDATE_REQUEST,
  BIDDING_PRODUCT_UPDATE_SUCCESS,
  BIDDING_PRODUCT_UPDATE_FAIL,
  BIDDING_PRODUCT_FILTER,


} from '../actions/partnerbiddingQuotesActionts'

const initialState = {
  bidding_quotes: { all: [], error: '', isLoading: false },
  single_quote: { all: [], error: '', isLoading: false },
  biddingQuoteUpdate: { partner: {}, error: '', isLoading: false },
  biddingCreate: { bidding: {}, error: '', isLoading: false },
  biddingUpdate: { bidding: {}, error: '', isLoading: false },
  biddingFilter: { select: 'All', search: '' }, 
};

export default function (state = initialState, action:any) {
  switch (action.type) {
    case BIDDING_QUOTES_REQUEST:
      return { ...state, bidding_quotes: { all: state.bidding_quotes.all, error: '', isLoading: true } };
    case BIDDING_QUOTES_SUCCESS:
      return { ...state, bidding_quotes: { all: action.payload, error: '', isLoading: false } };
    case BIDDING_QUOTES_FAIL:
      return { ...state, single_quote: { all: [], error: action.payload, isLoading: false } };
      case SINGLE_BIDDING_QUOTES_REQUEST:
      return { ...state, single_quote: { all: state.single_quote.all, error: '', isLoading: true } };
    case SINGLE_BIDDING_QUOTES_SUCCESS:
      return { ...state, single_quote: { all: action.payload, error: '', isLoading: false } };
    case SINGLE_BIDDING_QUOTES_FAIL:
      return { ...state, single_quote: { all: [], error: action.payload, isLoading: false } };
      case PARTNER_BIDDING_QUOTE_UPDATE_REQUEST:
        return { ...state, biddingQuoteUpdate: { partner: {}, error: '', isLoading: true } };
      case PARTNER_BIDDING_QUOTE_UPDATE_SUCCESS:
        return { ...state, biddingQuoteUpdate: { partner: state.biddingQuoteUpdate.partner, error: '', isLoading: false } };
      case   PARTNER_BIDDING_QUOTE_UPDATE_FAIL:
        return { ...state, biddingQuoteUpdate: { partner: {}, error: action.payload, isLoading: false } };
  
      case BIDDING_QUOTE_CREATE_REQUEST:
      return { ...state, biddingCreate: { bidding: {}, error: '', isLoading: true } };
    case BIDDING_QUOTE_CREATE_SUCCESS:
      return { ...state, biddingCreate: { bidding: state.biddingCreate.user, error: '', isLoading: false } };
    case BIDDING_QUOTE_CREATE_FAIL:
      return { ...state, biddingCreate: { bidding: {}, error: action.payload, isLoading: false } };
    case BIDDING_PRODUCT_UPDATE_REQUEST:
      return { ...state, biddingUpdate: { bidding: {}, error: '', isLoading: true } };
    case BIDDING_PRODUCT_UPDATE_SUCCESS:
      return { ...state, biddingUpdate: { bidding: state.biddingUpdate.bidding, error: '', isLoading: false } };
    case BIDDING_PRODUCT_UPDATE_FAIL:
       
     case BIDDING_PRODUCT_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, biddingFilter: { search: state.biddingFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, biddingFilter: { select: state.biddingFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}



