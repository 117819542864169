import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';
export const B2B_ALL_ORDERS_REQUEST = "ALL_ORDERS_REQUEST";
export const B2B_ALL_ORDERS_SUCCESS = "ALL_ORDERS_SUCCESS";
export const B2B_ALL_ORDERS_FAIL = "ALL_ORDERS_FAIL";

export const B2B_SINGLE_ORDER_REQUEST = "SINGLE_ORDER_REQUEST";
export const B2B_SINGLE_ORDER_SUCCESS = "SINGLE_ORDER_SUCCESS";
export const B2B_SINGLE_ORDER_FAIL = "SINGLE_ORDER_FAIL";

export const B2B_ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST";
export const B2B_ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const B2B_ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL";

export const B2B_ORDERS_FILTER = "ORDERS_FILTER";
export const RESET = "RESET";
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
export const invalid_data = () => {
  return {
    type: RESET
  }
}
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
/*=============================================================
                  Get All Orders Action
===============================================================*/
export const getAllb2bOrdersAction = (formData: any, pagination = {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: B2B_ALL_ORDERS_REQUEST
    });
    meeapi.post(urlGenarator(`/B2C_order_Get/Order_Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: B2B_ALL_ORDERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: B2B_ALL_ORDERS_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: B2B_ALL_ORDERS_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                Update Order Action
===============================================================*/
export const updateb2bOrderAction = (Order_Update: any, navigation: any, toast: any, ID: any) => async dispatch => {
  dispatch({
    type: B2B_ORDER_UPDATE_REQUEST
  });
  axios.put(`${config.url}/B2B_Order/Partner_B2B_Order_Update`, Order_Update, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: B2B_ORDER_UPDATE_SUCCESS
        });
        navigation.navigate('B2bOrderDetails')
        const getformData = {
            "search_by_filter": 'All',
            "search": "",
            "Seller_Partner_Detail_Id": ID,
            "Records_Filter": "FILTER",
            "Shopper_Order_Id": ""
        };
        const pagination = { PageNo: 1, PageSize: 5, SortBy: "SHOPPER_ORDER_ID", SortOrder: "DESC"}
        dispatch(getAllb2bOrdersAction(getformData, pagination));

      } else {
        const errors = {};
        dispatch({
          type: B2B_ORDER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: B2B_ORDER_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};



export const b2bOrdersFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: B2B_ORDERS_FILTER,
      fType: fType,
      value: value,
    });
  }
}

export const b2bresetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: B2B_ORDERS_FILTER,
      fType: "search",
      value: "",
    });
  }
}

export const getAddressIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Address/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)
    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};
export const getsingleb2borderAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: B2B_SINGLE_ORDER_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2C_order_Get/Order_Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: B2B_SINGLE_ORDER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: B2B_SINGLE_ORDER_SUCCESS,
          payload: data?.results[0].Order_Data
        });
      }
    }
  } catch (err) {

    dispatch({
      type: B2B_SINGLE_ORDER_FAIL,
      payload: err
    });
  }
};

export const B2bInvoiceAction = (formData: any, updateStates: any) => async (dispatch) => {
  axios.post(`${config.url}/B2B_Order/B2B_B2C_Invoice_Generation`, formData, {
    headers: headers
  })
    .then(response => {

      let successResponse = response.data;
      updateStates(true, successResponse);
    })
    .catch(error => {
      updateStates(false, null);
    })
};