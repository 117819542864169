import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Image, RefreshControl } from 'react-native';
import { Dialog, Card, DataTable, Text, Searchbar, Portal, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../auth/components/header';
import Headers from '../Farmer-Module/header';
import RenderHtml from 'react-native-render-html';
import isEmpty from '../../state/validations/is-empty';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import { getAllTransactionAction } from '../../state/actions/transactionAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Partner_Financial_Transaction_Id',
    label: 'Transaction ID'
  },
  {
    value: 'Transaction_Status',
    label: 'Transaction Status'
  },
  {
    value: 'Transaction_Type',
    label: 'Transaction Type'
  },
  {
    value: 'Transaction_From',
    label: 'Transaction From'
  },
  {
    value: 'Transaction_Channel',
    label: 'Transaction Channel'
  },
  {
    value: 'Status',
    label: 'Status'
  }
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function Transactions(props: any) {
  const { navigation } = props;
  const pages = props.route?.params?.page;
  const esiColor = useSelector(state => state.theme);
  //coupons contains the data from  GET API using state call
  // const transactions = useSelector(state => state.transactions.transactions.all);
  const Alltransactions = useSelector(state => state.transactions.transactions.all);
  let transactions = Alltransactions?.results;
  const [transaction, setTransactions] = React.useState();
  const EmailID = useSelector(state => state.auth?.user);
  //transactionMenu is used to store the cache data in Menu select using state call
  const [transactionMenu, setTransactionMenu] = React.useState('All')
  const [transactionSearch, setTransactionSearch] = React.useState('');
  //transactionSearch is used to store the cache data in search using state call
  // let transactionSearch = useSelector(state => state.transactions.transactionsFilter.search);
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > Alltransactions?.Pagination?.TotalCount ? Alltransactions?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setPagination({ PageNo: Number(page) + Number(1), PageSize: e, SortBy: "PARTNER_FINANCIAL_TRANSACTION_ID", SortOrder: "DESC" })
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PARTNER_FINANCIAL_TRANSACTION_ID", SortOrder: "DESC" });
  }
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_FINANCIAL_TRANSACTION_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      transactionscell();
    }
  }, [pagination])
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const transactionscell = () => {
    setIsLoading(true);
    let formData = {
      "search_by_filter": transactionMenu,
      "search": "",
      "Records_Filter": "FILTER",
      "Partner_Detail_Id": EmailID?.Partner_Details_Id
    };

    dispatch(getAllTransactionAction(formData, pagination))
  };
  const [showDropDown, setShowDropDown] = React.useState(false);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  //API dispatch
  const dispatch = useDispatch()
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "PARTNER_FINANCIAL_TRANSACTION_ID", SortOrder: "DESC" }
    let formData = {
      "search_by_filter": transactionMenu,
      "search": "",
      "Records_Filter": "FILTER",
      "Partner_Detail_Id": EmailID?.Partner_Details_Id
    };
    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getAllTransactionAction(formData, pagecall))
  };
  // const permissions = useSelector(state => state.auth.permissions);
  React.useEffect(() => {
    let formData = {
      "search_by_filter": transactionMenu,
      "search": "",
      "Records_Filter": "FILTER",
      "Partner_Detail_Id": EmailID?.Partner_Details_Id
    };
    setTransactions(transactions);
    dispatch(getAllTransactionAction(formData, pagination))
  }, [permissions]);

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );

  });


  //sorting and pagination
  const [oldSearch, setOldSearch] = React.useState(0);


  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Partner_Financial_Transaction_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  //dialog
  const [visible, setVisible] = React.useState(false);
  const [transactionData, setData] = React.useState();
  const hideDialog = () => setVisible(false);

  const handleChange = itemValue => {
    setTransactionMenu(itemValue);
  };
  const handleSearch = value => {
    let formData = {
      "search_by_filter": transactionMenu,
      "search": value,
      "Records_Filter": "FILTER",
      "Partner_Detail_Id": EmailID?.Partner_Details_Id
    };
    setTransactionSearch(value)
    dispatch(getAllTransactionAction(formData, pagination))
  };
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall()
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const style = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 180
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 160,
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'center',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 150
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      {pages === 'farmer' ? <Headers navigation={navigation} /> : <Header navigation={navigation} />}
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 500, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
            <IconButton color={esiColor.GBFColor} icon="close" size={20} onPress={() => setVisible(!visible)} />
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>View Transactions</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 5 }}>
              <Dialog.Content style={{ marginLeft: -10 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Coupon ID</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Partner_Financial_Transaction_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Transaction Status</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: transactionData?.Transaction_Status == "Success" ? "green" : "red" }}>{transactionData?.Transaction_Status} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Transaction Reference ID</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Transaction_Reference_Meepaisa_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Payment Reference ID</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Transaction_Payment_Reference_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Transaction Channel</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{transactionData?.Transaction_Channel} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Transaction From</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{transactionData?.Transaction_From} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Details ID</Text></View>
                  <View style={{ flex: 0.95 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Partner_Detail_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Transaction Type</Text></View>
                  <View style={{ flex: 0.95 }}><Text style={{ color: esiColor.itemColor }}>{transactionData?.Transaction_Type} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Amount</Text></View>
                  <View style={{ flex: 0.95 }}><Text style={{ color: esiColor.itemColor }}>{transactionData?.Amount} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Payment Method</Text></View>
                  <View style={{ flex: 0.95 }}><Text style={{ color: esiColor.itemColor }}>{transactionData?.Payment_Method} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Transaction Date</Text></View>
                  <View style={{ flex: 0.95 }}><Text style={{ color: esiColor.itemColor }}>{transactionData?.Transaction_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{transactionData?.Created_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Updated Date</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{transactionData?.Updated_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Reason</Text></View>
                  <View style={{ flex: 0.9, marginTop: -2 }}><RenderHtml source={{ html: `<span style="color: ${esiColor.Text}">${transactionData?.Reason}</span>` }} /></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Modified By</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Modified_By} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Created_By} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: transactionData?.Status == "Active" ? "green" : "red" }}>{transactionData?.Status} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>

      <ScrollView>
        {/* <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
            <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {navigation.navigate('Dashboard')
            handleSearch("")
            dispatch(resetFilter())
  }
          }>
              Go Back
            </Button>
          </View> */}
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 5, paddingBottom: 5, color: esiColor.brandFontColor }}>Transactions</Text>
        </View>
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 500 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[style.dropdown]}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                inputSearchStyle={style.inputSearchStyle}
                iconStyle={style.iconStyle}
                data={filterOptions}
                containerStyle={style.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={transactionMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
              <Picker
                selectedValue={transactionMenu}
                style={{ height: 50, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', flexShrink: 1, backgroundColor: '#ffff' }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Transaction ID" value="Partner_Financial_Transaction_Id" />
                <Picker.Item label="Transaction Channel" value="Transaction_Channel" />
                <Picker.Item label="Transaction Type" value="Transaction_Type" />
                <Picker.Item label="Transaction From" value="Transaction_From" />
                <Picker.Item label="Transaction Status" value="Transaction_Status" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>
          }
          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />
          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              theme={customTheme}
              placeholderTextColor={esiColor.SPHTextColor}
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              placeholder="Search"
              onChangeText={(value) => handleSearch(value)}
              value={transactionSearch}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>

          <ScrollView refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              colors={['#27B6CC']}
              tintColor={'#27B6CC'}
              title={"Pull to refresh"}
              titleColor={"#27B6CC"}
            />
          } horizontal={dimensions >= 700 ? false : true}>
            <View style={style.mainbox}>
              <Card>
                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                  <DataTable.Header style={style.databeHeader}>
                    <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Partner_Financial_Transaction_Id'}
                      sortDirection={orderBy === 'Partner_Financial_Transaction_Id' ? order : 'ascending'}
                      onPress={createSortHandler('Partner_Financial_Transaction_Id')}><Text style={style.titletext}>Transaction ID</Text></DataTable.Title>

                    <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Transaction_Channel'}
                      sortDirection={orderBy === 'Transaction_Type' ? order : 'ascending'}
                      onPress={createSortHandler('Transaction_Channel')}><Text style={style.titletext}>Transaction Channel</Text></DataTable.Title>

                    <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Transaction_Type'}
                      sortDirection={orderBy === 'Transaction_Type' ? order : 'ascending'}
                      onPress={createSortHandler('Transaction_Type')}><Text style={style.titletext}>Transaction Type</Text></DataTable.Title>

                    <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Transaction_From'}
                      sortDirection={orderBy === 'Transaction_From' ? order : 'ascending'}
                      onPress={createSortHandler('Transaction_From')}><Text style={style.titletext}>Transaction From</Text></DataTable.Title>

                    <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Transaction_Status'}
                      sortDirection={orderBy === 'Transaction_Status' ? order : 'ascending'}
                      onPress={createSortHandler('Transaction_Status')}><Text style={style.titletext}>Transaction Status</Text></DataTable.Title>

                    <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Status'}
                      sortDirection={orderBy === 'Status' ? order : 'ascending'}
                      onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                    <DataTable.Title theme={customTheme} style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                  </DataTable.Header>
                  {
                    transactions?.map((transaction, index) => (
                      <DataTable.Row style={style.databeBox} key={transaction.Partner_Financial_Transaction_Id}>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{transaction.Partner_Financial_Transaction_Id}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{transaction.Transaction_Channel}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{transaction.Transaction_Type}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{transaction.Transaction_From}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{transaction.Transaction_Status}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{transaction.Status}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}>
                          <IconButton icon="eye" size={20} color={esiColor.globalButtonColor} onPress={(e) => { setData(transaction); setVisible(!visible) }} /></DataTable.Cell>
                      </DataTable.Row>


                    ))
                  }
                  {isEmpty(transactions) &&
                    <View>
                      <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.itemColor }}>
                        No records found!
                      </Text>
                      <Image source={require('../../assets/images/nodata.gif')}
                        style={{ width: 300, height: 300, alignSelf: "center" }} />
                    </View>
                  }

                  <DataTable.Pagination
                    theme={customTheme}
                    page={page}
                    numberOfPages={Math.ceil(Alltransactions?.Pagination?.TotalCount / numberOfItemsPerPage)}
                    onPageChange={page => DynamicPage(to < Alltransactions?.Pagination?.TotalCount ? page : 0)}
                    label={Alltransactions?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Alltransactions?.Pagination?.TotalCount}` : '0-0'}
                    showFastPaginationControls={(Number(page) + Number(1)) > Alltransactions?.Pagination?.TotalPages ? false : true}
                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    onItemsPerPageChange={onItemsPerPageChange}
                    selectPageDropdownLabel={'Rows per page'}
                  />
                </DataTable>
              </Card>
            </View>
          </ScrollView>

        </View>
      </ScrollView>
    </Surface>

  );
}

export default Transactions