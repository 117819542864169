import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivityIndicator, Button, HelperText, Modal, Portal, Surface, Text, TextInput as TextInputP } from 'react-native-paper';
import * as Yup from 'yup';
import { View, StyleSheet, ScrollView, TouchableOpacity, Platform, Dimensions } from "react-native";
import { Formik } from "formik";
import { FontAwesome5 } from "@expo/vector-icons";
import isEmpty from "../../../state/validations/is-empty";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import razorpayPay from "../../../hooks/razorpay";
import { MD5 } from "../../../hooks/md5/md5Encripion";
import { getAllOrdersAction } from "../../../state/actions/shoppers/shoppersordersAction";
import useRazorpay from "react-razorpay";
import Header from "../header/header";
import { placeNewOrderAction, productRemoveAddressError, removePartnerInCart } from "../../../state/actions/shoppers/shoppercartActions";
import { useToast } from "../../../components/custom/react-native-paper-toast/src";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAllCartAddressAction } from "../../../state/actions/addressAction";

export default function CartSummary(props: any) {
  const dispatch = useDispatch();
  let RazorPay = null;
  if (Platform.OS === "web") {
    RazorPay = useRazorpay();
  }
  const { navigation, route, ...rest } = props;
  const esiColor = useSelector(state => state.theme);

  let cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.auth.user);

  // Wallet logic start
  const [useWallet, setUseWallet] = useState(false);
  // Billing Address login start
  const [address, setAddress] = React.useState([]);
  const [billingAddress, setBillingAddress] = React.useState({});
  const addresslist = (address: any) => {
    let addresslist = []
    if (address) {
      if (address[0]) {
        for (let i = 0; i < address.length; i++) {
          addresslist.push({
            label: address[i].Address_Type + " " + "," + " " + address[i].Door_No + " " + "," + " " + address[i].Land_Mark + " " + "," + " " + address[i].Street + " " + "," + " " + address[i].City + " " + "," + " " + address[i].State + " " + "," + " " + address[i].Zip,
            value: JSON.stringify(address[i])
          })
        }
      }
    }
    addresslist.push({
      label: "Add New Address",
      value: "Add New Address"
    })
    return addresslist
  }
  const updateStates = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data ? data : []));
    setAddress(addresslist(add));
  };



  const getAddress = () => {
    let data = {
      Partner_Details_Id: user.Partner_Details_Id,
      Records_Filter: "FILTER",
    };
    dispatch(getAllCartAddressAction(updateStates, data));
  }
  const [visible, setVisible] = React.useState(false);
  const [seconds, setSeconds] = React.useState(-1);


  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  const [giftVoucher, setGiftVoucher] = React.useState({
    text: '', giftValidate: {}, loading: false, errorMessage: '',
    result: {}
  });
  const giftVoucherActionResponse = (response, data) => {
    if (response) {
      setGiftVoucher({ ...giftVoucher, giftValidate: JSON.parse(JSON.stringify(data)), loading: false, errorMessage: '' });
      setVisible(true);
      setSeconds(30);
    } else {
      setGiftVoucher({ ...giftVoucher, giftValidate: {}, loading: false, errorMessage: data });
    }
  }

  const responseGiftVoucher = (response, data) => {
    setGiftVoucher({ ...giftVoucher, loading: false });
    if (response) {
      setVisible(false);
      let newGift = JSON.parse(JSON.stringify(data));
      newGift.validResponse = "*GIFT VOUCHER APPLIED SUCCESFULLY";
      setGiftVoucher({ ...giftVoucher, loading: false, result: newGift, errorMessage: "*GIFT VOUCHER APPLIED SUCCESFULLY" });
    } else {
      setGiftVoucher({ ...giftVoucher, loading: false, errorMessage: data });
    }
  }

  const giftVoucherCheckLocal = () => {
    setGiftVoucher({ ...giftVoucher, loading: true, errorMessage: "data" });
    dispatch(giftVoucherCheck(giftVoucher.text, giftVoucherActionResponse));
  };
  // Coupon logic start.
  const [coupons, setCoupons] = React.useState([]);

  React.useEffect(() => {
    let couponsL = [];
    if (route.params?.cart) {
      if (route.params?.cart === 'all') {
        // 
        cart.map((item) => {
          couponsL.push(
            {
              text: '', isLoading: false, result: {}, errorMessage: '', partner: { id: item.partnerId, Name: item.partnerName }
            }
          )
        })
      } else {
        cart.filter((item) => item.partnerId === route.params.cart).map((item) => {
          couponsL.push(
            {
              text: '', isLoading: false, result: {}, errorMessage: '', partner: { id: item.partnerId, Name: item.partnerName }
            }
          )
        })
      }
      if (couponsL.length > 0) {
        setCoupons(couponsL);
      }
      else {
        navigation.navigate('Cart');
      }

    }
    else {
      navigation.navigate('Cart');
    }
  }, [route.params?.cart])

  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });

  const couponCheck = (index) => {
    let dummyCoupon = JSON.parse(JSON.stringify(coupons));
    let coupon = dummyCoupon[index];
    coupon.errorMessage = '';
    coupon.isLoading = true;
    setCoupons(dummyCoupon);
    dispatch(couponCodeCheckAction(coupon.text, user.Email_Id, coupon.partner.id, couponResponse, index));
  };

  const couponResponse = (status, response, index) => {
    let dummyCoupon = JSON.parse(JSON.stringify(coupons));
    let coupon = dummyCoupon[index];
    coupon.isLoading = false;
    if (status) {
      coupon.result = response;
      coupon.errorMessage = "*COUPON APPLIED SUCCESFULLY"
    } else {
      coupon.errorMessage = response;
    }
    setCoupons(dummyCoupon);
  };
  // CORP Voucher logic started.
  const [visibleC, setVisibleC] = React.useState(false);
  const [secondsC, setSecondsC] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => secondsC > 0 ? setSecondsC(secondsC - 1) : setSecondsC(-1), 1000)
    return () => clearTimeout(timer)
  }, [secondsC]);
  const [corpVoucher, setCorpVoucher] = React.useState({
    text: '', validate: {}, loading: false, errorMessage: '',
    result: {}
  });
  const corpVoucherActionResponse = (response, data) => {
    if (response) {
      setCorpVoucher({ ...corpVoucher, validate: JSON.parse(JSON.stringify(data)), loading: false, errorMessage: '' });
      setVisibleC(true);
      setSeconds(30);
    } else {
      setCorpVoucher({ ...corpVoucher, validate: {}, loading: false, errorMessage: data });
    }
  }

  const responseCorpVoucher = (response, data) => {
    setCorpVoucher({ ...corpVoucher, loading: false });
    if (response) {
      setVisibleC(false);
      let newGift = JSON.parse(JSON.stringify(data));
      newGift.validResponse = "*CORPORATE VOUCHER APPLIED SUCCESFULLY";
      setCorpVoucher({ ...corpVoucher, loading: false, result: newGift, errorMessage: "*CORPORATE VOUCHER APPLIED SUCCESFULLY" });
    } else {
      setCorpVoucher({ ...corpVoucher, loading: false, errorMessage: data });
    }
  }

  const corpVoucherCheckLocal = () => {
    setCorpVoucher({ ...corpVoucher, loading: true, errorMessage: "" });
    dispatch(corpVoucherCheck(corpVoucher.text, corpVoucherActionResponse));
  };

  // CORP Voucher end.

  const [wallet, setWallet] = React.useState(null);
  const updateStatesWallet = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data));
    setWallet(add?.Wallet[0]);
  };

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isLoading = useSelector(state => state.auth.isLoading);

  const addresses = useSelector(state => state.address.addresses.all);

  useEffect(() => {
    updateStates(addresses, false);
  }, [addresses]);

  const handileNavigation = () => {
    if (isAuthenticated) {
      getAddress();
      dispatch(productRemoveAddressError(cart));
      let data = {
        User_Id: user.Email_Id,
        Wallet_Id: "",
      };
    } else {
      if (!isLoading)
        navigation.navigate('CartSummary');
    }
  };

  useEffect(() => {
    handileNavigation();
  }, [isAuthenticated]);

  // Discount Logics

  const getPartnerOrderOriginalPrice = (items) => {
    let amount = 0;
    let discount
    for (let i = 0; i < items.products.length; i++) {
      for (let j = 0; j < items.products[i].Discount.length; j++) {
        if (Number(items.products[i].Discount[j].Min_Quantity) <= Number(items.products[i].itemCount) && Number(items.products[i].itemCount) <= Number(items.products[i].Discount[j].Max_Quantity)) {
          amount = amount + (Number(items.products[i].Discount[j].Discounted_Price_Per_Quantity) * Number(items.products[i].itemCount))
          discount = Number(items.products[i].Discount[j].Discount_In_Percentage)
        } else if (j === items.products[i].Discount.length - 1 && Number(items.products[i].itemCount) > Number(items.products[i].Discount[j].Max_Quantity)) {
          amount = amount + (Number(items.products[i].Discount[j].Discounted_Price_Per_Quantity) * Number(items.products[i].itemCount))
          discount = Number(items.products[i].Discount[j].Discount_In_Percentage)
        }
      }
    }
    let data = amount.toFixed(2)
    return data
  };

  const getPartnerOrderLineOriginalPrice = (items) => {
    let amount = 0;
    let discount
    for (let j = 0; j < items.Discount.length; j++) {
      if (Number(items.Discount[j].Min_Quantity) <= Number(items.itemCount) && Number(items.itemCount) <= Number(items.Discount[j].Max_Quantity)) {
        amount = amount + (Number(items.Discount[j].Discounted_Price_Per_Quantity) * Number(items.itemCount))
        discount = Number(items.Discount[j].Discount_In_Percentage)
      } else if (j === items.Discount.length - 1 && Number(items.itemCount) > Number(items.Discount[j].Max_Quantity)) {
        amount = amount + (Number(items.Discount[j].Discounted_Price_Per_Quantity) * Number(items.itemCount))
        discount = Number(items.Discount[j].Discount_In_Percentage)
      }
    }
    let data = amount.toFixed(2)
    return { data, discount }
  };

  const getPartnerSellingOrderLineOriginalPrice = (items) => {
    let amount = 0;
    let discount
    for (let j = 0; j < items.Discount.length; j++) {
      if (Number(items.Discount[j].Min_Quantity) <= Number(items.itemCount) && Number(items.itemCount) <= Number(items.Discount[j].Max_Quantity)) {
        amount = (Number(items.Discount[j].Discounted_Price_Per_Quantity))
        discount = Number(items.Discount[j].Discount_In_Percentage)

      } else if (j === items.Discount.length - 1 && Number(items.itemCount) > Number(items.Discount[j].Max_Quantity)) {
        amount = (Number(items.Discount[j].Discounted_Price_Per_Quantity))
        discount = Number(items.Discount[j].Discount_In_Percentage)

      }
    }

    let data = amount.toFixed(2)
    return { data, discount }
  };

  const getAllPartnerOriginalPrice = () => {



    let amount = 0;
    coupons.map((coupon, index) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {
        amount = amount + Number(getPartnerOrderOriginalPrice(partner[0]));
      }
    })
    return amount.toFixed(2)
  };

  const getDiscountPrice = () => {
    return (Number(getAllPartnerOrderPrice()) - Number(getAllPartnerOriginalPrice())).toFixed(2);
  };

  const getAllPartnerOrderPrice = () => {
    let amount = 0;
    coupons.map((coupon, index) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {
        amount = amount + Number(getPartnerOrderPrice(partner[0]));
      }
    })
    return amount
  };


  const getPartnerOrderPrice = (items) => {
    let amount = 0;
    items.products.map(product => (

      amount = amount + (Number(product.partnerProduct.Product_Price) * Number(product.itemCount))
    ));
    return amount.toFixed(2);
  };

  const egetAllPartnerOrderPrice = () => {
    let amount = 0;
    if (!isEmpty(cart)) {
      amount = amount + Number(getPartnerOrderPrice(cart[0]));
    }

    return amount
  };

  const couponDiscountAmount = (items) => {
    let coupon = items.coupon;
    let amount = 0;
    let productsAvailable = false;
    let products = items.products.filter(pro => {
      let found = false;
      if (coupon.Category_Id && coupon.Brand_Id) {
        if (coupon.Category_Id === pro.Product.Category_Id && coupon.Brand_Id === pro.Product.Brand_Id) {
          found = true;
        }
      } else if (coupon.Brand_Id) {
        if (coupon.Brand_Id === pro.Product.Brand_Id) {
          found = true;
        }
      } else if (coupon.Category_Id === pro.Product.Category_Id) {
        found = true;
      }
      return found;
    });
    products.map(product => (
      amount = amount + (Number(product.product.Original_Price) * Number(product.itemCount))
    ));
    if (products.length > 0) {
      productsAvailable = true;
    }
    if (productsAvailable) {
      let response = {
        discountValid: coupon.Discount_Type ? true : false,
        discount: coupon.Discount_Type === "P" ? true : false,
        amount: 0,
      };
      let discountAmount = 0;
      if (response.discountValid) {
        if (response.discount) {
          discountAmount = (amount / 100) * Number(coupon.Discount_Amount);
        } else {
          if (Number(coupon.Discount_Amount) > amount) {
            discountAmount = amount;
          } else {
            discountAmount = coupon.Discount_Amount;
          }
        }
      }

      return discountAmount.toFixed(2);
    } else {
      return (0).toFixed(2);
    }
  }

  const allCouponDiscount = () => {
    let amount = 0;
    coupons.filter((coupon) => !isEmpty(coupon.result)).map((coupon, index) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {
        amount = amount + Number(couponDiscountAmount({ "coupon": coupon.result, "products": partner[0]?.products }));
      }
    })
    return amount.toFixed(2)
  }

  const getDeliveryPrice = (items) => {
    let amount = 0;

    items.products.map(product => {
      amount = amount + (Number(product.partnerProduct.Shipping_Amount ? product.partnerProduct.Shipping_Amount : 0) * Number(product.itemCount))
    });
    return amount.toFixed(2);
  };

  const getAllDeliveryPrice = () => {
    let amount = 0;
    coupons.map((coupon, index) => {

      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {
        amount = amount + Number(getDeliveryPrice(partner[0]));
      }
    })
    return amount.toFixed(2)
  };

  const getGiftPackPrice = (items) => {
    let amount = 0;
    items.products.map(product => {
      if (product.isGiftPack) {
        let amount2 = product?.partnerProduct?.Is_Gift_Wrap_Available === "1" ? Number(product.partnerProduct.Is_Gift_Wrap_Amount ? product.partnerProduct.Is_Gift_Wrap_Amount : 0) : 0;
        amount = amount + amount2;
      }
    });
    return amount.toFixed(2);
  };

  const getAllGiftPackPrice = () => {
    let amount = 0;
    coupons.map((coupon, index) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {
        amount = amount + Number(getGiftPackPrice(partner[0]));
      }
    })
    return amount.toFixed(2)
  };

  const corpDiscountAmount = () => {
    let amount = 0;
    if (!isEmpty(corpVoucher.result)) {
      let coupon = Number(allCouponDiscount());
      let delivery = Number(getAllDeliveryPrice());
      let giftPackAmount = Number(getAllGiftPackPrice());
      let totalBeforeCrop = Number(getAllPartnerOrderPrice()) + delivery + giftPackAmount - coupon;
      amount = (totalBeforeCrop < Number(corpVoucher.result.Discount_Amount) ? totalBeforeCrop : Number(corpVoucher.result.Discount_Amount))
    }
    return amount.toFixed(2)
  }


  const getGiftDiscountPrice = () => {
    let amount = 0;
    if (!isEmpty(giftVoucher.result)) {
      let coupon = Number(allCouponDiscount());
      let delivery = Number(getAllDeliveryPrice());
      let giftPackAmount = Number(getAllGiftPackPrice());
      let getCorporateCoupon = Number(corpDiscountAmount());
      let totalBeforeGift = Number(getAllPartnerOrderPrice()) + delivery + giftPackAmount - coupon - getCorporateCoupon;
      amount = (totalBeforeGift < Number(giftVoucher.result.Total_Value) ? totalBeforeGift : Number(giftVoucher.result.Total_Value))
    }
    return amount.toFixed(2);
  };

  const getWalletDiscountPrice = () => {
    let amount = 0;
    let giftUseAmont = Number(getGiftDiscountPrice());
    let coupon = Number(allCouponDiscount());
    let delivery = Number(getAllDeliveryPrice());
    let giftPackAmount = Number(getAllGiftPackPrice());
    let getCorporateCoupon = Number(corpDiscountAmount());
    let totalBeforeWallet = Number(getAllPartnerOrderPrice()) + delivery + giftPackAmount - coupon - getCorporateCoupon - giftUseAmont;
    amount = totalBeforeWallet < Number(wallet?.Remaining_Balance) ? totalBeforeWallet : Number(wallet?.Remaining_Balance);
    return amount.toFixed(2)
  };

  const getWalletUsedAmount = () => {
    return useWallet ? getWalletDiscountPrice() : "0.00"
  };

  const orderFinalAmount = () => {
    let amount = 0;
    amount = Number(getAllPartnerOrderPrice());
    return amount.toFixed(2)
  }

  const [orderPlaced, setOrderPlaced] = useState(false);

  // Order place logic

  const payNow = () => {
    let amount = (Math.round(Number(getAllPartnerOriginalPrice()) + Number(getAllDeliveryPrice()) + Number.EPSILON));
    if (amount > 0) {
      razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
        if (status) {
          placeNewOrder("razorpay", response.razorpay_payment_id, Number(getAllPartnerOriginalPrice()) + Number(getAllDeliveryPrice()));
        } else {

        }
      });
    } else {
      placeNewOrder("Wallet", "", Number(getAllPartnerOriginalPrice()) + Number(getAllDeliveryPrice()));
    }
  }
  // coupon code is start
  const placeNewOrder = (type, transactionId, amount) => {


    let Partner_Orders = [];
    coupons.map((coupon) => {
      let partner = cart.filter((item) => item.partnerId === coupon.partner.id);
      if (!isEmpty(partner)) {


        let OrderTotals = {
          "Order_Totals": {
            "Order_Actual_Total": orderFinalAmount(),
            "Order_Amount_Collected_Using_Payment_Gateway": Number(getAllPartnerOriginalPrice()) + Number(getAllDeliveryPrice()),
            "Order_Discount_Amount": getDiscountPrice(),
            "Order_Total_After_Discount": Number(getAllPartnerOriginalPrice()) + Number(getAllDeliveryPrice()),
            "Wallet_Amount": useWallet ? Number(getWalletUsedAmount()) : 0
          },
          "Shipment_Total": {
            "Shipping_Total": getAllDeliveryPrice()
          }
        };

        let orderProducts = [
        ];
        partner[0].products.map((masterProduct) => {
          let newOrderProduct = {
            "Line_Item_Product_ID": masterProduct.partnerProduct.Product_Id,
            "Line_Item_Partner_Product_ID": masterProduct.partnerProduct.Partner_Product_Id,
            "Line_Item_Qty": masterProduct.itemCount,
            "Original_Price": masterProduct.partnerProduct.Partner_Selling_Price,
            "Selling_Price": getPartnerSellingOrderLineOriginalPrice(masterProduct).data,
            "Total_Price_Before_Discount": masterProduct.Product.Original_Price * masterProduct.itemCount,
            "Discount_Percentage": getPartnerSellingOrderLineOriginalPrice(masterProduct).discount,
            "Discount_Amount": (masterProduct.Product.Original_Price * masterProduct.itemCount) - getPartnerOrderLineOriginalPrice(masterProduct).data,
            "Total_Price_After_Discount": getPartnerOrderLineOriginalPrice(masterProduct).data,
            "Size": masterProduct.size,
            "Shipping_Address": {
              "User_Address_Id": masterProduct.address.Partner_Address_Id
            },
          }
          orderProducts.push(newOrderProduct);
        });


        Partner_Orders.push(
          {
            "Partner_Id": partner[0].partnerId,
            "Product_Line_Items": orderProducts,
          }
        )
      }
    })

    let OrdersNew = {

      "User_Details": {
        "User_Email_ID": user.Email_Id,
        "Partner_Details_ID": user.Partner_Details_Id
      },
      "Billing_Address": {
        "User_Address_Id": billingAddress?.address?.Partner_Address_Id
      },
      "Wallet": {
        "Wallet_Amount": Number(getWalletUsedAmount()),
        "Wallet_Id": wallet?.Wallet_Id ? wallet?.Wallet_Id : ""
      },
      "Order_Payments": {
        "Currency": "INR",
        "Payment_Mode": type,
        "Response_Code": "200",
        "Transaction_Actual_Amount": amount,
        "Transaction_Id": transactionId,
        "Transcation_Status": "Success"
      },
      "Order_Totals": {
        "Order_Totals": {
          "Order_Actual_Total": orderFinalAmount(),
          "Order_Amount_Collected_Using_Payment_Gateway": Number(getAllPartnerOriginalPrice()) + Number(getAllDeliveryPrice()),
          "Order_Discount_Amount": getDiscountPrice(),
          "Order_Total_After_Discount": Number(getAllPartnerOriginalPrice()) + Number(getAllDeliveryPrice()),
          "Wallet_Amount": useWallet ? Number(getWalletUsedAmount()) : 0
        },
        "Shipment_Total": {
          "Shipping_Total": getAllDeliveryPrice()
        }
      },
      "Partner_Orders": Partner_Orders
    };
    setOrderPlaced(true);
    dispatch(placeNewOrderAction({ Orders: OrdersNew }, placeNewOrderResponse, {}));
  };
  // Order place logic end.


  const placeNewOrderResponse = (status, response, item) => {
    setOrderPlaced(false);
    if (response?.Response_Status === "Success") {
      let partners = [];
      coupons.map((coupon) => {
        partners.push(coupon.partner.id);

      })
      if (!isEmpty(partners)) {
        dispatch(removePartnerInCart(cart, partners));
      }

      dropDownAlertFun('info', 'Success', 'Order created successfully.');

      const setPagination = { PageNo: 1, PageSize: 20, SortBy: "Shopper_Order_Id", SortOrder: "DESC" };
      let orderformData = {
        "Order_Status": "",
        "Payment_Status": "",
        "Records_Filter": "FILTER",
        "Shopper_Email_Id": user.Email_Id,
        "Shopper_Line_Item_Order_Status": "",
        "Shopper_Order_Id": "",
        "Shopper_Order_Line_Item_Id": ""

      }
      dispatch(getAllOrdersAction(orderformData, setPagination));
      navigation.navigate('Orders');
    }
    else {
      dropDownAlertFun('info', 'Failed', 'Sorry we unable to place your order, please try again.');
    }
  };

  // Tost message code.
  const toast = useToast();

  // Tost message code.
  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window);
      }
    );
  });
  return (
    <Surface style={{ flex: 1, flexDirection: 'column', backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <View style={{ flexDirection: "row" }} >
        <View style={{ flex: 1 }} >
          <TouchableOpacity style={{ marginLeft: 5 }} onPress={() => {
            navigation.navigate('Cart');
          }}>
            <FontAwesome5 name='arrow-left' color= {esiColor.SIconColor} style={{ marginVertical: 10 }} size={20}></FontAwesome5>
          </TouchableOpacity>
        </View>
        <View style={{ flex: 8, flexDirection: "row", justifyContent: 'center' }}>
          <Text style={{ fontWeight: 'bold', fontSize: 30, color: esiColor.brandFontColor }}>Cart Summary</Text>
        </View>
      </View>
      <ScrollView pointerEvents={orderPlaced ? "none" : 'auto'} >
        <ActivityIndicator color='#27b6cc' style={{ position: "absolute", zIndex: 2, left: "45%", top: '50%' }} animating={orderPlaced} />
        <View style={{ width: dimensions.width >= 700 ? "80%" : "100%", marginLeft: "auto", marginRight: "auto", borderColor: esiColor.SBorderColor, borderWidth: 0.2, borderRadius: 5, backgroundColor: esiColor.BackgroundColor }}>
          <View style={{ backgroundColor: esiColor.BackgroundColor, margin: 5, padding: 2 }}>

            <View style={{ marginLeft: 10, marginRight: 10, marginTop: 5, marginBottom: 5 }}>
              <View >
                <Text style={{ fontWeight: 'bold', fontSize: 18, color: '#27b6cc', marginBottom: 10, marginLeft: 5 }}>Billing Address</Text>
              </View>
              <EsiSearchDropdown
                style={[styles.dropdown, { borderColor: billingAddress?.addressError ? '#b00020' : 'gray' }]}
                data={address}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                labelField="label"
                valueField="value"
                maxHeight={180}
                search
                placeholder='Billing Address'
                defaultValue={billingAddress?.address ? {
                  label: billingAddress.address.Address_Type + " " + "," + " " + billingAddress.address.Door_Number + " " + "," + " " + billingAddress.address.Landmark + " " + "," + " " + billingAddress.address.Street + " " + "," + " " + billingAddress.address.City + " " + "," + " " + billingAddress.address.State + " " + "," + " " + billingAddress.address.Zip,
                  value: JSON.stringify(billingAddress.address)
                } : ""}
                className="address"
                onUpdateValue={(item: any) => {
                  if (item.value === "Add New Address") {
                    setBillingAddress({ ...billingAddress, addressError: '' });
                    navigation.navigate('AddAddress', { page: 'cart-summary' });
                  } else {
                    setBillingAddress({ address: JSON.parse(item.value) });
                  }
                }}
                value={billingAddress?.address ? JSON.stringify(billingAddress?.address) : ''}
              />
              <HelperText type="error" visible={billingAddress?.addressError ? true : false}>
                {billingAddress?.addressError ? billingAddress?.addressError : ""}
              </HelperText>
            </View>
            <View style={{ margin: 10 }} >
              <View >
                <Text style={{ fontWeight: 'bold', fontSize: 18, color: '#27b6cc', marginBottom: 10, marginLeft: 5 }}>Price</Text>
              </View>
            </View>
            <View style={{ margin: 10 }} >
              <View>
                <View style={{ margin: 5, flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>Price</Text>
                  <Text style={{ fontWeight: 'bold', color: esiColor.DescColor }}>₹ {orderFinalAmount()}</Text>
                </View>
                {/* {Number(getPartnerOrderDiscountPrice(item)) > 0 && */}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                  <Text style={{ fontWeight: 'bold', color: 'green' }}>Discount</Text>
                  <Text style={{ fontWeight: 'bold', color: 'green' }}>
                    -₹ {getDiscountPrice()}
                  </Text>
                </View>
                {Number(getAllDeliveryPrice()) > 0 &&
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                    <Text style={{ fontWeight: 'bold', color: "#D522DE" }}>Delivery Charges</Text>
                    <Text style={{ fontWeight: 'bold', color: "#D522DE" }}>₹ {getAllDeliveryPrice()}</Text>
                  </View>
                }
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 5 }}>
                  <Text style={{ fontWeight: 'bold', color: esiColor.DescColor}}>Total Price</Text>
                  <Text style={{ fontWeight: 'bold', color: "blue" }}>₹ {Number(getAllPartnerOriginalPrice()) + Number(getAllDeliveryPrice())}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'center', backgroundColor: esiColor.BackgroundColor }}>
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: esiColor.globalButtonColor,
                      padding: 10,
                      margin: 'auto',
                      borderRadius: 8
                    }}
                    onPress={() => {
                      if (isEmpty(billingAddress?.address)) {
                        setBillingAddress({ ...billingAddress, addressError: 'Billing Address is required.' })
                      } else {
                        payNow();
                      }
                    }}
                  >
                    <Text style={{ color: esiColor.itemButtenColor }} >Pay and Place New Order</Text>
                  </TouchableOpacity>
                </View>
              </View>

            </View>
          </View>
        </View>
        <Portal>
          <Modal visible={visible} onDismiss={() => setVisible(false)}
            contentContainerStyle={{
              backgroundColor: 'white',
              paddingLeft: 10,
              paddingRight: 10,
              marginLeft: 20,
              marginRight: 20,
              minHeight: 100,
              borderRadius: 8
            }}>
            <View  >
              <View style={{ marginTop: 5, marginBottom: 5 }}  >
                <Text style={{ fontSize: 24, fontWeight: 'bold', marginLeft: "auto", marginRight: "auto" }} >Gift Card OTP</Text>
              </View>
              <View style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignSelf: 'flex-start',
              }}>
                {!isEmpty(giftVoucher?.giftValidate) &&
                  <Text style={{ paddingLeft: 10, paddingRight: 10, textAlign: 'justify' }} >OTP has been sent to email({giftVoucher.giftValidate ? (giftVoucher.giftValidate?.Email.split("@"))[0].substring(0, 4) + "***@" + (giftVoucher.giftValidate?.Email.split("@"))[1] : ""}) and mobile number( ****{giftVoucher.giftValidate ? giftVoucher.giftValidate?.Mobile.substring(giftVoucher.giftValidate?.Mobile.length - 4) : ""})
                  </Text>
                }
              </View>
              <Formik
                initialValues={{ otp: "" }}

                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .required('Voucher OTP is required.')
                })}

                onSubmit={(values, { setSubmitting, setErrors }) => {
                  setSubmitting(false);
                  if (MD5(values.otp) === giftVoucher.giftValidate?.OTP) {
                    setGiftVoucher({ ...giftVoucher, loading: true, errorMessage: '', result: {} });
                    dispatch(getValidGiftVoucher(giftVoucher.text, responseGiftVoucher));
                  } else {
                    setErrors({ otp: "Invalid Voucher OTP." });
                  }

                }}
              >
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm }) => (
                  <>
                    <View style={{ marginLeft: 10, marginRight: 10 }}>
                      <TextInputP selectionColor='rgba(0, 0, 0, 0.5)' style={{ width: "100%", margin: "auto" }}
                        label="Voucher OTP"
                        mode='outlined'
                        onChangeText={handleChange('otp')}
                        onBlur={handleBlur('otp')}
                        value={values.otp}
                        error={Boolean(touched.otp && errors.otp)}
                      />
                      <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.otp && errors.otp)}>
                        {touched.otp && errors.otp}
                      </HelperText>
                    </View>
                    <View style={{ marginLeft: 10, marginRight: 10 }}>
                      <HelperText style={{ width: "90%" }} type="error" visible={Boolean(giftVoucher.errorMessage)}>
                        {giftVoucher.errorMessage}
                      </HelperText>
                    </View>

                    <View style={{ width: "90%", flexDirection: 'row', justifyContent: 'space-around', margin: "auto", marginBottom: 15 }}>
                      <Button style={{ marginRight: 10, backgroundColor: esiColor.globalButtonColor }} mode="contained" disabled={giftVoucher.loading} onPress={handleSubmit}>
                        Validate OTP
                      </Button>
                      <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" disabled={giftVoucher.loading || seconds >= 0} onPress={() => {
                        if (!(seconds >= 0)) {
                          giftVoucherCheckLocal();
                          resetForm();
                        }

                      }}>
                        Resend OTP {seconds > 0 ? seconds : ""}
                      </Button>
                    </View>
                  </>
                )}
              </Formik>

            </View>
          </Modal>

          <Modal visible={visibleC} onDismiss={() => setVisibleC(false)}
            contentContainerStyle={{
              backgroundColor: 'white',
              paddingLeft: 10,
              paddingRight: 10,
              marginLeft: 20,
              marginRight: 20,
              minHeight: 100,
              borderRadius: 8
            }}>
            <View  >
              <View style={{ marginTop: 5, marginBottom: 5 }}  >
                <Text style={{ fontSize: 24, fontWeight: 'bold', marginLeft: "auto", marginRight: "auto" }} >Corprate Voucher OTP</Text>
              </View>
              <View style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignSelf: 'flex-start',
              }}>
                {!isEmpty(corpVoucher?.validate) &&
                  <Text style={{ paddingLeft: 10, paddingRight: 10, textAlign: 'justify' }} >OTP has been sent to email({corpVoucher.validate ? (corpVoucher.validate?.Email_Id.split("@"))[0].substring(0, 4) + "***@" + (corpVoucher.validate?.Email_Id.split("@"))[1] : ""})
                  </Text>
                }
              </View>
              <Formik
                initialValues={{ otp: "" }}

                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .required('Voucher OTP is required.')
                })}

                onSubmit={(values, { setSubmitting, setErrors }) => {
                  setSubmitting(false);
                  if (MD5(values.otp) === corpVoucher.validate?.OTP) {
                    setCorpVoucher({ ...corpVoucher, loading: true, errorMessage: '', result: {} });

                    dispatch(getValidCorpVoucher(corpVoucher.validate.Coupon_Id_Corp, responseCorpVoucher));
                  } else {
                    setErrors({ otp: "Invalid Voucher OTP." });
                  }

                }}
              >
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm }) => (
                  <>
                    <View style={{ marginLeft: 10, marginRight: 10 }}>
                      <TextInputP selectionColor='rgba(0, 0, 0, 0.5)' style={{ width: "100%", margin: "auto" }}
                        label="Voucher OTP"
                        mode='outlined'
                        onChangeText={handleChange('otp')}
                        onBlur={handleBlur('otp')}
                        value={values.otp}
                        error={Boolean(touched.otp && errors.otp)}
                      />
                      <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.otp && errors.otp)}>
                        {touched.otp && errors.otp}
                      </HelperText>
                    </View>
                    <View style={{ marginLeft: 10, marginRight: 10 }}>
                      <HelperText style={{ width: "90%" }} type="error" visible={Boolean(corpVoucher.errorMessage)}>
                        {corpVoucher.errorMessage}
                      </HelperText>
                    </View>

                    <View style={{ width: "90%", flexDirection: 'row', justifyContent: 'space-around', margin: "auto", marginBottom: 15 }}>
                      <Button style={{ marginRight: 10, backgroundColor: esiColor.globalButtonColor }} mode="contained" disabled={corpVoucher.loading} onPress={handleSubmit}>
                        Validate OTP
                      </Button>
                      <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" disabled={corpVoucher.loading || seconds >= 0} onPress={() => {
                        if (!(seconds >= 0)) {
                          corpVoucherCheckLocal();
                          resetForm();
                        }
                      }}>
                        Resend OTP {seconds > 0 ? seconds : ""}
                      </Button>
                    </View>
                  </>
                )}
              </Formik>
            </View>
          </Modal>
        </Portal >
      </ScrollView >
    </Surface>

  );
}

const styles = StyleSheet.create({
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  dropdown: {
    height: 58,
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  centerElement: { justifyContent: 'center', alignItems: 'center' },
});