import React from "react";
import { View, Text, ScrollView, Platform, RefreshControl } from "react-native";
import { Surface } from "react-native-paper";
import Header from "../../Services/header/header";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Servicetotal from "./servicetotalcount";
import Partnerservicecount from "./partnerservicecount";
import UserServicetotal from "./userservicecount";
import { useSelector } from "react-redux";
export default function ServicesDashboard(props: any) {
    const { navigation } = props;
    React.useEffect(() => {
        return () => {
        };
    }, []);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);           
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const esiColor = useSelector(state => state.theme);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    return (
        <Surface style={{ flex: 1,backgroundColor:esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10,color:esiColor.brandFontColor }}>
                    Dashboard
                </Text>
            </View>
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ flexDirection: Platform.OS === "web" ? 'row' : 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flex: 1 }} >

                    <View style={{ flexDirection: 'row' }}>
                        <Servicetotal navigate={navigation.navigate} esiColor={esiColor} />
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Partnerservicecount navigate={navigation.navigate} esiColor={esiColor} />
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <UserServicetotal navigate={navigation.navigate} esiColor={esiColor} />
                    </View>
                </View>
            </ScrollView>
        </Surface>
    );
};