import React, { useState, useCallback, useEffect } from 'react';
import { Card, Button, TextInput, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import * as Yup from 'yup';

import {
    getAllBrands,
    getAllCategories,
    getAllSubCategories,
    getAllMasters,
    getAllColours,
    getAllSizes,
    productsdata,
    getSizes
} from '../../../../state/actions/productsAction';
import Header from '../../../auth/components/header';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import EsiCountry from '../../../../components/custom/country-state-city/country';
import AsyncStorage from '@react-native-async-storage/async-storage';
import isEmpty from '../../../../state/validations/is-empty';
import { getAllProductmasterssearch } from '../../../../state/actions/partnerproductsAction';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");



const AddProducts = (props: any) => {
    const dispatch = useDispatch();
    const { navigation } = props;
    const toast = useToast();
    const esiColor = useSelector(state => state.theme);
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Products_Partner_Products_Related_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const [showProductMasterDropDown, setShowProductMasterDropDown] = React.useState('');
    const [errorMessage, setErrorMessage] = useState({ Product_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    const [showDropDowncolor, setShowDropDowncolor] = React.useState('');
    const [showDropDown, setShowDropDown] = React.useState('');
    const [showDropDownbrand, setShowDropDownbrand] = React.useState('');
    const [showDropDownsubcategory, setShowDropDownsubcategory] = React.useState('');
    const [specificationsvalue, setSpecificationsValue] = React.useState('');
    const [descriptionvalue, setDescriptionValue] = React.useState('');

    const [allItems, setAllValues] = React.useState('');
    const [featuresvalue, setFeaturesValue] = React.useState('');

    useEffect(() => {
        dispatch(getAllMasters());
        dispatch(getAllSizes());
    }, []);

    //getting colours by calling reducer
    const colours = useSelector(state => state.product.productColours.colours);
    //here iterating colours name and colours ID values 
    let colorsopt = []
    if (colours) {
        if (colours[0]) {
            for (let i = 0; i < colours.length; i++) {
                colorsopt.push(
                    {
                        label: colours[i].Color,
                        value: colours[i].Product_Color_Variant_Id
                    })
            }
        }
    }

    //getting masters by calling reducer
    const masters = useSelector(state => state.
        product.productMasters.masters);
    const partnerproductssearch = useSelector(state => state.productpartners.partnerproductssearch.partners);
    //here iterating masters Data 
    let productmasteropt = []
    if (partnerproductssearch) {
        if (partnerproductssearch[0]) {
            for (let i = 0; i < partnerproductssearch?.length; i++) {
                productmasteropt.push(
                    {
                        value: partnerproductssearch[i]?.Product_Master_Id,
                        label: partnerproductssearch[i]?.Product_Name.substring(0, 60),
                        Category: partnerproductssearch[i]?.Category_Id,
                        Sub_Category: partnerproductssearch[i]?.Sub_Category_Id,
                        Brand: partnerproductssearch[i]?.Brand_Id,
                        Short_Description: partnerproductssearch[i]?.Short_Description,
                        Long_Description: partnerproductssearch[i]?.Long_Description,
                        Features: partnerproductssearch[i]?.Features,
                        Specifications: partnerproductssearch[i]?.Specifications,
                    })
            }
        }
    }
    //getting categories by calling reducer
    const categories = useSelector(state => state.product.productCategories.categories);
    //here iterating categorie name and categories ID values 
    let categoryopt = []
    if (categories) {
        if (categories[0]) {
            for (
                let i = 0;
                i < categories.length;
                i++) {
                categoryopt.push({
                    label: categories[i].Category_Name,
                    value: categories[i].Category_Id
                })
            }
        }
    }
    //getting subcategories by calling reducer
    const subcategories = useSelector(state => state.product.productSubCategories.subcategories);
    //here iterating subcategories name and subcategories ID values 
    let subcategorieopt = []
    if (subcategories) {
        if (subcategories[0]) {
            for (let i = 0; i < subcategories.length; i++) {
                subcategorieopt.push(
                    {
                        label: subcategories[i].Sub_Category_Name,
                        value: subcategories[i].Sub_Category_Id
                    })
            }
        }
    }
    //getting brands by calling reducer 
    const brands = useSelector(state => state.product.productBrands.brands);
    const sizes = useSelector(state => state.product.Sizes.sizes);
    let sizesopt = []
    if (sizes) {
        if (sizes[0]) {
            for (let i = 0; i < sizes.length; i++) {
                sizesopt.push(
                    {
                        label: sizes[i].Size,
                        value: sizes[i].Product_Size_Id
                    })
            }
        }
    }
    //here iterating brands name and brands ID values 
    let brandopt = []
    if (brands) {
        if (brands[0]) {
            for (let i = 0; i < brands?.length; i++) {
                brandopt.push({
                    label: brands[i]?.Brand_Name,
                    value: brands[i]?.Brand_Id
                })
            }
        }
    }
    //sending product masterId
    const setType = (Product_Master_Id) => {
        if (Product_Master_Id !== "") {
            dispatch(getAllColours(Product_Master_Id, useCallback));
            dispatch(getSizes(Product_Master_Id));
        }
    }
    // for screen dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    React.useEffect(() => {
        dispatch(getAllProductmasterssearch("1"))
    }, []);
    useEffect(() => {
        if (!isEmpty(allItems)) {
            dispatch(getAllBrands(allItems?.Brand));
            dispatch(getAllCategories(allItems?.Category));
            dispatch(getAllSubCategories(allItems?.Sub_Category));
            // dispatch(getAllMasters());
            // dispatch(getAllPartnerProductssearch("1"))
            dispatch(getAllSizes());
        }

    }, [allItems]);
    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    const updateStates = (data) => {
        if (data) {
            dispatch(getAllProductmasterssearch(data))
        }

    };

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        container: {
            paddingTop: 15
        }, images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },

        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        phonetextInput: {
            flex: 1
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        countrydropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            left: 22,
            top: 0.5,
            color: esiColor.itemColor,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }} >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }
            >
                <Formik
                    initialValues={{
                        Brand_Id: "",
                        Category_Id: '',
                        Country_Of_Origin: "IN",
                        Created_User_Type: EmailID.User_Type,
                        Esi_Rating: '',
                        Esi_Review: "",
                        Features: "",
                        Is_Popular: false,
                        Is_Top_Product: false,
                        Is_Upcoming: false,
                        Is_Verified: false,
                        Product_Color_Variant_Id: "",
                        Product_Description: "",
                        Product_Master_Id: "",
                        Product_Name: "",
                        Product_Price: "",
                        Product_Short_Description: "",
                        Product_Size_Id: "",
                        Specifications: "",
                        Created_By_Email: EmailID.Email_Id,
                        Partner_Details_ID: EmailID.Partner_Details_Id,
                        Status: "In-Active",
                        Sub_Category_Id: "",
                        Whats_Hot: false,
                        Warranty_Period: "",
                    }}
                    //field validations
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        Brand_Id: Yup.string().required('Brand Name is required.'),
                        Category_Id: Yup.string().required('Category Name is required.'),
                        Country_Of_Origin: Yup.string().required('Country is required.'),
                        Product_Master_Id: Yup.string().required('Product Master Name is required.'),
                        Product_Name: Yup.string().required('Product Name is required.'),
                        Product_Price: Yup.string().matches(/^\d+(\.\d{1,2})?$/,'Please enter valued Product Price').required('Product Price is required.'),
                        Product_Short_Description: Yup.string().required('Short Description is required.'),
                        Product_Size_Id: Yup.string().required('Product Size is required.'),
                        Status: Yup.string().required('Status is required.'),
                        Sub_Category_Id: Yup.string().required('Sub Category Name is required.'),
                        Warranty_Period: Yup.string().required('Warranty Period is required.'),
                    })}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        setErrors(errors);
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            dispatch(productsdata(formValues, props.navigation));
                            navigation.navigate('AddProducts')
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        resetForm
                    }) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        resetForm();
                                        navigation.navigate('Products')
                                    }} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold",color: esiColor.brandFontColor }}>
                                        Add Product
                                    </Text>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        {/* Text input for product name */}
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Product_Name && errors.Product_Name)}
                                                label="Product Name"
                                                mode='outlined'
                                                value={values.Product_Name}
                                                onChangeText={handleChange("Product_Name")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Product_Name && errors.Product_Name)}>
                                                {touched.Product_Name && errors.Product_Name}
                                            </HelperText>
                                        </View>
                                        {/* Dropdown for Product Masters */}
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Product_Master_Id && errors.Product_Master_Id) && { color: '#b00020' }]}>
                                                    Product Master Name
                                                </Text>

                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Product_Master_Id && errors.Product_Master_Id) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={productmasteropt}
                                                    labelField="label"
                                                    valueField="value"
                                                    searchQuery={item => { updateStates(item) }}
                                                    maxHeight={productmasteropt.length > 1 ? 250 : 200}
                                                    search
                                                    searchPlaceholder={'Select  Product Master Name'}
                                                    value={values.Product_Master_Id}
                                                    onUpdateValue={item => {
                                                        item &&
                                                            setType(item?.value ? item.value : "");
                                                        setFieldValue('Product_Master_Id', item?.value)
                                                        setFieldValue('Category_Id', item?.Category)
                                                        setFieldValue('Sub_Category_Id', item?.Sub_Category)
                                                        setFieldValue('Brand_Id', item?.Brand)
                                                        setFieldValue('Product_Short_Description', item?.Short_Description)
                                                        setFieldValue('Product_Description', item?.Long_Description)
                                                        setFieldValue('Features', item?.Features)
                                                        setFieldValue('Specifications', item?.Specifications)
                                                        setDescriptionValue(item?.Long_Description)
                                                        setFeaturesValue(item?.Features)
                                                        setSpecificationsValue(item?.Specifications)
                                                        setAllValues(item)
                                                        setShowProductMasterDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Product_Master_Id && errors.Product_Master_Id)}>
                                                    {touched.Product_Master_Id && errors.Product_Master_Id}
                                                </HelperText>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        {/* Dropdown for Categorys  */}
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Category_Id && errors.Category_Id) && { color: '#b00020' }]}>
                                                    Category Name
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Category_Id && errors.Category_Id) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={categoryopt}
                                                    labelField="label"
                                                    valueField="value"
                                                    maxHeight={categoryopt.length > 1 ? 250 : 200}
                                                    search={categoryopt.length > 1 ? true : false}
                                                    searchPlaceholder={'Select   Category Name'}
                                                    value={values.Category_Id}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Category_Id', item.value)
                                                        setShowDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Category_Id && errors.Category_Id)}>
                                                    {touched.Category_Id && errors.Category_Id}
                                                </HelperText>
                                            </View>
                                        </View>
                                        {/* Dropdown for subCategorys  */}
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id) && { color: '#b00020' }]}>
                                                    Sub Category
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={subcategorieopt}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder={!showDropDownsubcategory ? 'Select item' : '...'}
                                                    maxHeight={subcategorieopt.length > 1 ? 250 : 200}
                                                    search={subcategorieopt.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Sub Category'}
                                                    value={values.Sub_Category_Id}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Sub_Category_Id', item.value)
                                                        setShowDropDownsubcategory(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id)}>
                                                    {touched.Sub_Category_Id && errors.Sub_Category_Id}
                                                </HelperText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        {/* Dropdown for Brands  */}
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Brand_Id && errors.Brand_Id) && { color: '#b00020' }]}>
                                                    Brand Name
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Brand_Id && errors.Brand_Id) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={brandopt}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder={!showDropDownbrand ? 'Select item' : '...'}
                                                    maxHeight={brandopt.length > 1 ? 250 : 200}
                                                    search={brandopt.length > 1 ? true : false}
                                                    searchPlaceholder={'Select  Brand Name'}
                                                    value={values.Brand_Id}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Brand_Id', item.value)
                                                        setShowDropDownbrand(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Brand_Id && errors.Brand_Id)}>
                                                    {touched.Brand_Id && errors.Brand_Id}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            {/* Dropdown for colors  */}
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id) && { color: '#b00020' }]}>
                                                    Color Name
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={colorsopt}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder={!showDropDowncolor ? 'Select item' : '...'}
                                                    maxHeight={colorsopt.length > 1 ? 250 : 200}
                                                    search={colorsopt.length > 1 ? true : false}
                                                    searchPlaceholder={'Select  Color Name'}
                                                    value={values.Product_Color_Variant_Id}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Product_Color_Variant_Id', item.value)
                                                        setShowDropDowncolor(false);
                                                    }}
                                                />

                                                <HelperText type="error" visible={Boolean(touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id)}>
                                                    {touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id}
                                                </HelperText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        {/* TextInput for Product Size  */}
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Product_Size_Id && errors.Product_Size_Id) && { color: '#b00020' }]}>
                                                    Product Size
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Product_Size_Id && errors.Product_Size_Id) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={sizesopt}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder={!showDropDowncolor ? 'Select item' : '...'}
                                                    maxHeight={sizesopt.length > 1 ? 250 : 200}
                                                    search={sizesopt.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Product Size'}
                                                    value={values.Product_Size_Id}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Product_Size_Id', item.value)
                                                        setShowDropDowncolor(false);
                                                    }}
                                                />

                                                <HelperText type="error" visible={Boolean(touched.Product_Size_Id && errors.Product_Size_Id)}>
                                                    {touched.Product_Size_Id && errors.Product_Size_Id}
                                                </HelperText>
                                            </View>
                                        </View>
                                        {/* TextInput for Product Price  */}
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Product_Price && errors.Product_Price)}
                                                label="Product Price"
                                                mode='outlined'
                                                value={values.Product_Price}
                                                onChangeText={handleChange("Product_Price")}
                                                keyboardType='numeric'
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Product_Price && errors.Product_Price)}>
                                                {touched.Product_Price && errors.Product_Price}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: '1%' }}>
                                            <Text style={[styles.countrydropdownlabel, Boolean(touched.Country_Of_Origin && errors.Country_Of_Origin) && { color: '#b00020' }]}>
                                                Country
                                            </Text>
                                            {/*  country input field with validation*/}
                                            <EsiCountry
                                                error={Boolean(touched.Country_Of_Origin && errors.Country_Of_Origin)}
                                                labelField="NAME"
                                                valueField="COUNTRY_CODE"
                                                value={values.Country_Of_Origin}
                                                name="Country_Of_Origin"
                                                search
                                                disable
                                                searchPlaceholder={'Select Country'}
                                                onUpdateValue={item => {
                                                    setFieldValue('Country_Of_Origin', item.COUNTRY_CODE);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Country_Of_Origin && errors.Country_Of_Origin)}>
                                                <ErrorMessage name="Country_Of_Origin" />
                                            </HelperText>
                                        </View>
                                        {/* TextInput for Product Short Description  */}
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Product_Short_Description && errors.Product_Short_Description)}
                                                label="Short Description"
                                                mode='outlined'
                                                value={values.Product_Short_Description}
                                                onChangeText={handleChange("Product_Short_Description")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Product_Short_Description && errors.Product_Short_Description)}>
                                                {touched.Product_Short_Description && errors.Product_Short_Description}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexGrow: 1, marginLeft: '0.5%', flexShrink: 1 }}>
                                        <TextInput
                                            // theme={customTheme}
                                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                            outlineColor={esiColor.TextOuterLine}
                                            underlineColor={esiColor.TextUnderline}
                                            activeOutlineColor={esiColor.TextOuterLine}
                                            error={Boolean(touched.Warranty_Period && errors.Warranty_Period)}
                                            label="Warranty Period"
                                            mode='outlined'
                                            multiline
                                            value={values.Warranty_Period}
                                            onChangeText={handleChange("Warranty_Period")}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.Warranty_Period && errors.Warranty_Period)}>
                                            {touched.Warranty_Period && errors.Warranty_Period}
                                        </HelperText>
                                    </View>
                                </Card.Content>
                                <View style={{ marginLeft: "1%" }}>
                                    <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                                </View>
                                <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", marginVertical: 10 }}>
                                    <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                        Next
                                    </Button>
                                </View>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface >
    );
};

export default AddProducts;