import React, { useState } from "react";
import { Text, Colors, TouchableRipple, Avatar, Surface, TextInput, HelperText, DefaultTheme } from 'react-native-paper';
import { View, Dimensions, StyleSheet, Image, TouchableOpacity, useWindowDimensions, Linking, ScrollView, Platform } from "react-native";
import RenderHTML from "react-native-render-html";
import { adduservisitAction } from "../../state/actions/dealActions";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Formik } from "formik";
import * as Yup from 'yup';
import { AddTicketcommentAction, GetAllComments } from "../../state/actions/ticketingActions";
import { Divider } from "react-native-elements";
import isEmpty from "../../state/validations/is-empty";

export default function ESICommentModel(props: any) {
    // data is a deal object as we use this object to display the info front-end
    // isAuthenticated prop is used here to call authentication
    const { data } = props
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth?.user);
    const [reload, setReload] = useState(false);
    const [Comment, setComment] = useState([]);
    const esiColor = useSelector(state => state.theme);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };;
    // const window = Dimensions.get("window");
    // const { width } = useWindowDimensions();
    // const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    // React.useEffect(() => {
    //     const subscription = Dimensions.addEventListener(
    //         "change",
    //         ({ window, screen }) => {
    //             setDimensions(window);
    //         }
    //     );
    // });
    const callBackGetData = (data) => {
        setReload(false);
        if (data) {
            setComment(data);
        }

    };
    React.useEffect(() => {
        if (user.Email_Id) {
            let formdata = {
                "search_by_filter": "",
                "search": "",
                "Comments": "",
                "Created_By": "",
                "Records_Filter": "FILTER",
                "Status": "Active",
                "Ticket_Comment_id": "",
                "Ticket_Id": data.Ticket_Id,
            }
            dispatch(GetAllComments(formdata, callBackGetData))
        }
    }, [user.Email_Id]);
    return (

        <View style={{ height: 360 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                <View style={{ flex: 1, flexDirection: "column", justifyContent: "space-between", borderBottomColor: "blue" }}>
                    <Text style={{ fontSize: 15, fontWeight: "bold", color: "#27B6CC", textAlign: "center" }}>Ticket ID: {data.Ticket_Id}</Text>
                    <Text style={{ fontSize: 15, fontWeight: "bold", color: "#27B6CC", textAlign: "center" }}>Subject: {data.Ticket_Subject} </Text>
                </View>
            </View>
            <View style={{ borderBottomColor: esiColor.SBorderColor, borderBottomWidth: 1 }} />

            <View style={{ paddingTop: 10 }}>
                {isEmpty(Comment) ? <View>
                    <Text style={{ fontSize: 16, textAlign: 'center', color: esiColor.NoDataFound }}>
                        No comments found.
                    </Text>
                </View> :

                    <View style={{ height: 240 }}>
                        <ScrollView>
                            {Comment?.length > 0 && Comment?.map((data: any, index: any) => (
                                // : <ActivityIndicator animating={loading} color='#27b6cc' style={{ marginTop: 20 }} />

                                <View>
                                    <View key={index} style={{ backgroundColor: esiColor.BackgroundColor, width: 180, borderRadius: 10 }}>
                                        <Text style={{ fontSize: 15, marginLeft: 16, padding: 2, color: esiColor.itemColor }}>{data.Comments}</Text>
                                    </View>
                                    <View style={{ paddingBottom: 5 }} />
                                </View>
                            ))}
                        </ScrollView>
                    </View>
                }

            </View>

            <Formik initialValues={{
                Comments: "",
                Created_By: user.Email_Id,
                Ticket_Id: data.Ticket_Id,
            }}
                //field validations
                validationSchema={Yup.object().shape({
                    Comments: Yup.string().required('Comment  is required.'),
                })}
                onSubmit={(values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
                    let submitTest = true;
                    setSubmitting(false);
                    const errors = {};
                    setErrors(errors);
                    if (values) {
                        let formValues = JSON.parse(JSON.stringify(values));
                        formValues.Created_By = user.Email_Id
                        dispatch(AddTicketcommentAction(formValues, data.Ticket_Id, resetForm, setReload, callBackGetData));
                    }
                }
                }
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    resetForm
                }) => (
                    <View style={styles.container}>
                        <TextInput
                            placeholder="write...."
                            onChangeText={handleChange('Comments')}
                            value={values.Comments}
                            error={Boolean(touched.Comments && errors.Comments)}
                            right={<TextInput.Icon name="send" color={esiColor.SIconColor} mode="contained" onPress={handleSubmit} />}
                            mode='outlined'
                            theme={customTheme}
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                        />
                        <HelperText type="error" visible={Boolean(touched.Comments && errors.Comments)}>
                            <ErrorMessage name="Comments" />
                        </HelperText>
                    </View>
                )}
            </Formik>
        </View >

    )
}
const styles = StyleSheet.create({
    container: {
        width: "100%",
        position: 'absolute', bottom: 0
    },
});


