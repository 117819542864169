import React from 'react';
import { View, Text } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import { Button, Card, IconButton } from 'react-native-paper';

const FileUpload = (props: any) => {

    //props consists of style props along with uploadPic function and custom props like image.

    // uploadPic function will return onchange value of perticular upload image responce. 

    // pickImage is a function for selecting images from mobile or web using ImagePicker plugin and will get uploaded image URL.
    const pickFile = async () => {
        // No permissions request is necessary for launching the file library
        let result = await DocumentPicker.getDocumentAsync({
            type: ['text/*','application/*']
        })
        // if file is not cancelled after upload then  responce will passing to the uploadFile
        if (!result.cancelled) {
            props.uploadFile(result);
        }
    };
    return (

        <Card {...props}>
            <Card.Content>
                <View
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    {/* This is condition if file is uploaded we are providing Remove file function, If not will display file uplaod option.  */}
                    {props.file ? <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                        <View style={{flexShrink:1,width:200}}>
                            <Text>{props.file}</Text>
                        </View>
                        <View style={{flexShrink:1}}>
                            <IconButton
                                icon="delete"
                                color={'#27B6CC'}
                                // size={20}
                                onPress={props.handleRemoveFile}
                            />
                        </View>
                    </View>
                        : <Button style={{ fontSize: 10, color: ' #425A70 ' }} uppercase={false} title="Choose Photo" onPress={pickFile} ><Text style={{color: '#27B6CC'}}>CHOOSE FILE</Text></Button>}
                </View>
            </Card.Content>
        </Card>
    );
};

export default FileUpload;