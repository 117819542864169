import 'react-native-get-random-values';
import * as React from 'react';
import * as Sentry from 'sentry-expo';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {useEffect, useState} from 'react';
import useCachedResources from './src/hooks/useCachedResources';
import useColorScheme from './src/hooks/useColorScheme';
import Navigation from './src/navigation';
import { DefaultTheme,DarkTheme, Provider as PaperProvider } from 'react-native-paper';
// import { Provider } from "react-redux";
// import {store} from "./store";
import { LogBox ,Platform, StyleSheet, View, Text, Image} from 'react-native';
import { Provider } from "react-redux";
import store from './src/state/store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthLayer from './AuthLayer';
import GenerateExpoPushToken from './src/components/GenerateExpoPushToken';
import AppIntroSlider from 'react-native-app-intro-slider';
import { ToastProvider } from './src/components/custom/react-native-paper-toast/src';
// import secureLocalStorage from 'react-secure-storage';
// import * as SecureStore from 'expo-secure-store';
Sentry.init({
  dsn: 'https://aabe1c36526cb3377b4e0ca503dafe71@sentry.meepaisa.com/2',
  enableInExpoDevelopment: true,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  tracesSampleRate: 1.0,
});

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const isWeb = Platform.OS === 'web';
  const isAndroid = Platform.OS === 'android';
  const isIos = Platform.OS === 'ios';
  const [showRealApp, setShowRealApp] = useState(false);
  const onDone = () => {
    setShowRealApp(true);
    
  };
  const onSkip = () => {
    setShowRealApp(true);
    
  };
  const verifyLogged = async () => {
    const logged = await AsyncStorage.getItem("user");
    if (logged) {
      setTimeout(() => {
        setIsInitialLoading(false)
      }, 10)
    } else {
      setIsInitialLoading(true)
    }
  }

  // const verifyLogged = async () => {
  //   if(Platform.OS==='web'){
  //       const logged = await secureLocalStorage.getItem("user");
  //   if (logged) {
  //     setTimeout(() => {
  //       setIsInitialLoading(false)
  //     }, 10)
  //   } else {
  //     setIsInitialLoading(true)
  //   }
  // }else{
  //   const logged = await SecureStore.getItemAsync("user");
  //   if (logged) {
  //     setTimeout(() => {
  //       setIsInitialLoading(false)
  //     }, 10)
  //   } else {
  //     setIsInitialLoading(true)
  //   }
  // }
  // }
  useEffect(() => {
verifyLogged()  


});
const RenderItem = ({ item }) => {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: item.backgroundColor,
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingBottom: 100,
      }}>
      <Text style={styles.introTitleStyle}>{item.title}</Text>
      <Image style={styles.introImageStyle} source={item.image} />
      <Text style={styles.introTextStyle}>{item.text}</Text>
    </View>
  );
};
const [isInitialLoading, setIsInitialLoading] = useState(true);
  LogBox.ignoreAllLogs();
  LogBox.ignoreLogs(['Remote debugger']);
  if (!isLoadingComplete) {
    return null;
  } else if ( ( showRealApp || !isInitialLoading || isWeb)){ {
    return (
      <SafeAreaProvider>
        <PaperProvider theme={colorScheme=='dark'?DarkTheme:DefaultTheme}>
          <Provider store={store}>
          <ToastProvider>
            <AuthLayer colorScheme ={colorScheme}/>
            {Platform.OS==='web'?null:<GenerateExpoPushToken/>}

          </ToastProvider>
          </Provider>
        </PaperProvider>
      </SafeAreaProvider>
    );
  }
}
else if (isInitialLoading && (isAndroid || isIos)){

  return (
    <AppIntroSlider
    data={slides}
    renderItem={RenderItem}
    onDone={onDone}
    showSkipButton={true}
    onSkip={onSkip}
  />


  );
}

}
const styles = StyleSheet.create({
  container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      padding: 10,
      justifyContent: 'center',
    },
    
    introImageStyle: {
      width: 270,
      height: 320,
    },
    introTextStyle: {
      fontSize: 18,
      color: 'white',
      textAlign: 'center',
      paddingVertical: 30,
    },
    introTitleStyle: {
      fontSize: 25,
      color: 'white',
      textAlign: 'center',
      marginBottom: 10,
      marginTop:50,
      fontWeight: 'bold',
    },

    
  });

const slides = [
  {
    key: 's1',
    text: 'End-to-End product management platform with proven technology.Manage your products,brands,deals,coupons,SKU,gifts in a jiffy.',
    title: '360 degree platform for Ecommerce Management',
    image: require("./src/assets/images/walkthrough_screens/gift_one.gif"),
    backgroundColor: '#27b6cc',
  },
  {
    key: 's2',
    title: 'Reach customers in a snap with your customized offers/coupons/deals.',
    text: 'Provide the deals, coupons and offers right from your dashboard instantly with MeePaisa,customer will get see the curated deals and offers in their dashboard😉.',
    image:require("./src/assets/images/walkthrough_screens/gift_two.gif"),
    backgroundColor: '#27b6cc',
  },
  {
    key: 's3',
    title: 'Customized reports for every scenario provided with in a click from your dashboard.',
    text: 'Drill-down into your data, slice-and-dice it by multiple dimensions, metrics, and attributes. Reduce time spent on finding information you need to make a gifting, marketing,deals or product decision',
    image:require("./src/assets/images/walkthrough_screens/gift_three.gif"),
    backgroundColor: '#27b6cc',
  },
  {
    key: 's4',
    title: 'Deal with Businnesses directly from meepaisa',
    text: 'We have integrated a humongous B2B module which will fit just in your palms, get benifitted with our B2B integration and sell your products directly to businees users with meepaisa!',
    image: require("./src/assets/images/walkthrough_screens/gift_four.gif"),
    backgroundColor: '#27b6cc',
  },
  {
    key: 's5',
    title: '24/7 Customer support to address your queries and concerns!!',
    text: 'You made a successful delivery to the end user and incentive is not credited to your account yet,Don’t worry our customer support executives work 24/7 to address your concerns,all you have to provide is the order ID and our people will get back to you with a solution with in 24 hours',
    image: require("./src/assets/images/walkthrough_screens//gift_five.gif"),
    backgroundColor: '#27b6cc',
  },
  // {
  //   key: 's6',
  //   title: 'Deliver happiness to your professional relationships that matter most',
  //   text: ' From birthdays to personal milestones, holidays, anniversaries, thank you cards and more, MeePaisa has designs for every occasion, ready for you to drag-n-drop your logo and unique messaging',
  //   image: require("./src/assets/images/walkthrough_screens/gift_six.png"),
  //   backgroundColor: '#27b6cc',
  // },
];