import React from 'react';
import { Image, View, StyleSheet, Text } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { Button, Card } from 'react-native-paper';
import SampleImage from '../../../assets/custom-components/image-placeholder.png';
import { useSelector } from 'react-redux';

const ImageUpload = (props: any) => {
    
    const esiColor = useSelector(state => state.theme);

    //props consists of style props along with uploadPic function and custom props like image.

    // uploadPic function will return onchange value of perticular upload image responce. 

    // pickImage is a function for selecting images from mobile or web using ImagePicker plugin and will get uploaded image URL.
    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({  
            mediaTypes: ImagePicker.MediaTypeOptions.Images,

            allowsEditing: true,
            //   aspect: [4, 3],
            quality: 1,
        });
        // if image is not cancelled after upload then  responce will passing to the uploadPic
        if (!result.cancelled) {
            props.uploadPic(result);
        }
    };

    return (

        <Card {...props} style={{backgroundColor:esiColor.BackgroundColor, borderRadius: 6,
            borderWidth: 1,
            borderColor: esiColor.TextOuterLine,}}>
            <Card.Content>
                <View style={{alignItems:"center"}} >
                    {/* checking image is uploaded or not.if uploaded we are diplaying the preview,Otherwise we are displaying just Upload condition. */}
                    {props.image.preview ? (
                        <Image
                            source={{ uri: props.image.preview }}
                            alt="Upload"
                            style={styles.images}
                        ></Image>
                    ) : (
                        <>
                            <Image
                                source={SampleImage}
                                alt="Upload"
                                style={styles.images}
                            ></Image>
                        </>
                    )}
                </View>
                <View
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    {/* This is condition if image is uploaded we are providing Remove image function, If not will display image uplaod option.  */}
                    {props.image.preview ? <Button style={{  backgroundColor: esiColor.globalButtonColor }}
                        onPress={props.handleRemoveImage}
                    >
                       <Text style={{color: esiColor.itemButtenColor}}> Remove picture</Text>
                    </Button> : <Button style={{ backgroundColor: esiColor.globalButtonColor }} uppercase={false} title="Choose Photo" onPress={pickImage} ><Text style={{color: esiColor.itemButtenColor}}>CHOOSE FILE</Text></Button>}
                </View>
            </Card.Content>
        </Card>
    );
};

//Basic Image tag style for displaying image inside the card.
const styles = StyleSheet.create({
    images: {
        width: 120,
        height: 120,
        borderRadius: 5,
        marginBottom: 15
    }
});
export default ImageUpload;