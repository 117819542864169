import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';
export const REVIEWSANDRATINGS_DATA = "REVIEWSANDRATINGS_DATA";
export const REVIEWSANDRATINGS_REQUEST = "REVIEWSANDRATINGS_REQUEST";
export const REVIEWSANDRATINGS_SUCCESS = "REVIEWSANDRATINGS_SUCCESS";
export const REVIEWSANDRATINGS_FAIL = "REVIEWSANDRATINGS_FAIL";
export const REVIEWSANDRATINGS_GET_REQUEST = "REVIEWSANDRATINGS_GET_REQUEST";
export const REVIEWSANDRATINGS_GET_SUCCESS = "REVIEWSANDRATINGS_GET_SUCCESS";
export const REVIEWSANDRATINGS_GET_FAIL = "REVIEWSANDRATINGS_GET_FAIL";
export const REVIEWSANDRATINGS_FILTER = "REVIEWSANDRATINGS_FILTER";

export const AVERAGE_REVIEWSANDRATINGS_REQUEST = "AVERAGE_REVIEWSANDRATINGS_REQUEST";
export const AVERAGE_REVIEWSANDRATINGS_SUCCESS = "AVERAGE_REVIEWSANDRATINGS_SUCCESS";
export const AVERAGE_REVIEWSANDRATINGS_FAIL = "AVERAGE_REVIEWSANDRATINGS_FAIL";


/*=============================================================
                 Headers
===============================================================*/

const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
  }
export const invalid_data = () => {
    return {
        type: RESET
    }
}


/*=============================================================
                  Get All Review and Rating Action
===============================================================*/
export const getAllReviewsandRatingsAction = (formData:any,pagination = {}) => async dispatch => {
    try {
      dispatch({
        type: REVIEWSANDRATINGS_REQUEST
      });
     meeapi.post(urlGenarator(`/Partner_Management/Partner_Reviews`,pagination),
        formData,
      ) .then(response => {
        let data = response.data;
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: REVIEWSANDRATINGS_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: REVIEWSANDRATINGS_SUCCESS,
            payload: data
          });
        }
      })
    } catch (err) {
      dispatch({
        type: REVIEWSANDRATINGS_FAIL,
        payload: err
      });
    }
  };
  
/*=============================================================
                  Get All Review and Rating Action
===============================================================*/

export const getAverageReviewsandRatingsAction = (formData1: any) => async dispatch => {

    try {
        dispatch({
            type: AVERAGE_REVIEWSANDRATINGS_REQUEST
        });
        let { data, status } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Partner_Management/Partner_Reviews`,
            formData1,
            {

              headers: nodeheaders
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: AVERAGE_REVIEWSANDRATINGS_SUCCESS,
                    payload: []
                });
            } else {

                dispatch({
                    type: AVERAGE_REVIEWSANDRATINGS_SUCCESS,
                    payload: data.results[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: AVERAGE_REVIEWSANDRATINGS_FAIL,
            payload: err
        });
    }
};

// Filter 
export const ReviewsandRatingsFilter = (fType, value) => {
    return function (dispatch) {
        dispatch({
            type: REVIEWSANDRATINGS_FILTER,
            fType: fType,
            value: value,
        });
    }
}

//search
export const resetFilter = () => {
    return function (dispatch: any) {
      dispatch({
        type: REVIEWSANDRATINGS_FILTER,
       fType: "search", 
        value: "",
      });
    }
  }

export const ReviewsandRatingsData = (value: any) => {
    return function (dispatch) {
        dispatch({
            type: REVIEWSANDRATINGS_DATA,
            value: value,
        });

    }

}