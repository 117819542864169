import * as React from 'react';
import { View, Text, ScrollView, Dimensions, StyleSheet, RefreshControl } from 'react-native';
import { Card, Surface, Button } from 'react-native-paper';
import Header from '../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';

const CouponNotification = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props

    //coupons is used to store the values from previous page using props
    const coupons = props.route?.params?.data;
    
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
            }
        );
    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <ScrollView 
             refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
                    <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 126 ,justifyContent: 'flex-start'}} onPress={() => props.navigation.navigate('Notification')}>
                        Go Back
                    </Button>
                </View>
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Card style={{
                        width: dimensions <= 500 ? "96%" : "75%", marginLeft: "auto", marginRight: "auto",
                        marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: '#D6D6D7'
                    }}>
                        <Card.Content>
                            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                View coupons Details
                            </Text>

                            <View style={{ margin: "2%" }}>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Coupon ID</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color:'#27B6CC' }}>{coupons?.Coupon_Id}</Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Coupon Name</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color:'#27B6CC' }}>{coupons?.Name}</Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Partner Name</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color:'#27B6CC' }}>{coupons?.Partner_Name}</Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Expired Status</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color:'#27B6CC' }}>{coupons?.Expired_Status}</Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Partner Type</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color:'#27B6CC' }}>{coupons?.Partner_Type}</Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Coupon Description</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Description} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Code</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Code} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Discount Type</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Discount_Type} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Partner Details ID</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Partner_Details_Id} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Discount Amount</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Discount_Amount} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>ESI Cashback Type</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.ESI_Cashback_Type} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>ESI Cashback Amount</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.ESI_Cashback_Amount} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Valid From Date</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Valid_From_Date} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Valid Through Date</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Valid_Through_Date} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Item Master ID</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Item_Master_Id} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Status</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Status} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Mobile Redirect Url</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Mobile_Redirect_Url} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Physical Routing Path</Text></View>
                                    <View style={{ flex: 2 }}><Text>{coupons?.Physical_Routing_Path} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Created By</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color:'#27B6CC' }}>{coupons?.Created_By} </Text></View>
                                </View>
                                <View style={styles.mainview}>
                                    <View style={{ flex: 2 }}><Text style={styles.text}>Modified By</Text></View>
                                    <View style={{ flex: 2 }}><Text style={{ color:'#27B6CC' }}>{coupons?.Modified_By} </Text></View>
                                </View>
                            </View>

                        </Card.Content>
                    </Card>
                </View>
            </ScrollView>
        </Surface>
    )
}

export default CouponNotification;


const styles = StyleSheet.create({
    mainview: {
        flexDirection: "row", justifyContent: 'space-between', flexWrap: 'wrap', paddingTop: 15
    },
    text: {
        fontWeight: "bold", fontSize: 13
    }
})