import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Checkbox, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { View, ScrollView, Dimensions, StyleSheet, RefreshControl } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../Services/header/header';
import { getPartnerserviceByIdAction, updatePartnerAction, uploadPartnerImage } from '../../../../state/actions/Services/partnerservicesActions';
import ImageUpload from '../../../../components/custom/image-upload/upload';
import EsiEditor from '../../../../components/custom/richtext-editor/richtext-editor';
import { getbussinessubtypeAction } from '../../../../state/actions/authActions';
import isEmpty from '../../../../state/validations/is-empty';

const StatusOption = [
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'In-Active',
    label: 'In-Active'
  },
];

const EditPartnerservicepagetwo = (props: any) => {
  const toast = useToast();
  //navigation is used for navigation between screens
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);
  const isSubmited = useSelector(state => state.partnerservice.partnerserviceUpdate.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  const Firstpagedata = useSelector(state => state.partnerservice.partnerserviceData.data);
  const [data, setData] = useState([]);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
  const [descriptionvalue, setDescriptionValue] = React.useState();
  const ID = props?.route?.params?.id;
  const dispatch = useDispatch();
  const [statusDropDown, setStatusDropDown] = useState("");
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [image, setImage] = useState({ preview: data[0]?.Sample_Service_Image_Url, raw: data[0]?.Sample_Service_Image_Url });
  const [imgUrl, setImgUrl] = useState(data[0]?.Sample_Service_Image_Url);
  const [uploadPic, setUploadPic] = useState(false);
  const callBackData = (data: any) => {
    setData(data)
    setImgUrl(data[0].Sample_Service_Image_Url)
    setImage({
      preview: data[0].Sample_Service_Image_Url,
      raw: data[0].Sample_Service_Image_Url
    });
  }
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  useEffect(() => {
    if (ID) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Partner_Detail_Id": "",
        "Partner_Service_Id": ID,
        "Records_Filter": "FILTER"
      };
      dispatch(getPartnerserviceByIdAction(formData, callBackData));
    }
  }, [ID]);
  const callBackUploadImage = (data: any) => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = async (result: any) => {
    const img = await fetchImageFromUri(result.uri);
    setImage({
      preview: result.uri,
      raw: img
    });
    dispatch(
      uploadPartnerImage(img, callBackUploadImage)
    );
  };

  const fetchImageFromUri = async (uri: any) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };
  //Clean up
  React.useEffect(() => {
    return () => {
    };
  }, []);
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };
  useEffect(() => {
    let data = {
      "Business_Type": "SERVICES",
      "Records_Filter": "FILTER"
    }
    dispatch(getbussinessubtypeAction(data, callBacksubtypeData))
  }, []);
  const [subtypedata, setsubtypeData] = React.useState([]);
  let BUSINESSSUBTYPE = []
  if (subtypedata) {
    for (let i = 0; i < subtypedata?.length; i++) {

      BUSINESSSUBTYPE.push(
        {
          label: subtypedata[i].BUSINESS_SUB_TYPE,
          value: subtypedata[i].BUSINESS_SUB_TYPE
        })
    }
  }
  const callBacksubtypeData = (data) => {
    setsubtypeData(data)
  }
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  React.useEffect(() => {
    if (isEmpty(ID)) {
      navigation.navigate('EditPartnerservice', { id: data.Partner_Service_Id })
    } else {
      setDescriptionValue(data[0]?.Service_Description)
    }
  }, [ID, data])
  /*====================================
          Handler
  ====================================*/
  const values = {
    Partner_Service_Id: data[0]?.Partner_Service_Id,
    City: Firstpagedata?.City,
    Country: Firstpagedata?.Country,
    Expected_Service_Fee: Firstpagedata?.Expected_Service_Fee,
    Location: Firstpagedata?.Location,
    Resource_Email_Id: Firstpagedata?.Resource_Email_Id,
    Resource_Mobile: Firstpagedata?.Resource_Mobile,
    Resource_Name: Firstpagedata?.Resource_Name,
    Sample_Service_Image_Url: data[0]?.Sample_Service_Image_Url,
    Modified_By: EmailID.Email_Id,
    Sample_Service_Video_Url: data[0]?.Sample_Service_Video_Url,
    Service_Description: data[0]?.Service_Description,
    Service_Name: Firstpagedata?.Service_Name,
    Service_Price_Day: Firstpagedata?.Service_Price_Day,
    Service_Price_Per_Hour: Firstpagedata?.Service_Price_Per_Hour,
    Service_Type: data[0]?.Service_Type,
    State: Firstpagedata?.State,
    Status: data[0]?.Status,
    Zip: Firstpagedata?.Zip,
    Discount_Amount: Firstpagedata?.Discount_Amount,
    Is_Avaliable_For_Services: data[0]?.Is_Avaliable_For_Services > 0 ? true : false,
    Is_Custome_Service_Fee: data[0]?.Is_Custome_Service_Fee > 0 ? true : false,
    Is_Home_Service_Avaliable: data[0]?.Is_Home_Service_Avaliable > 0 ? true : false,
    Partner_Detail_Id: EmailID.Partner_Details_Id,
  }
  //Form validation
  const Form_Validation = Yup.object().shape({
    Sample_Service_Video_Url: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
      'Please provide valid URL.').required('Sample Service Video URL is required.'),
    Service_Type: Yup.string().required('Service Type is required.'),
    Status: Yup.string().required('Status is required.'),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    values.Sample_Service_Image_Url = imgUrl;
    values.Service_Description = descriptionvalue;
    if (!values.Sample_Service_Image_Url) {
      submitTest = false;
      errors.Sample_Service_Image_Url = "Image is required.";
    }
    if (!values.Service_Description || values.Service_Description?.replace(/(<([^>]+)>)/ig, '')?.trim().length == 0) {
      submitTest = false;
      errors.Service_Description = "Description is required.";
    }
    else if (values.Service_Description?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) {
      submitTest = false;
      errors.Service_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
    }

    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));

      if (values.Is_Avaliable_For_Services) {
        formValues.Is_Avaliable_For_Services = 1;
      } else {
        formValues.Is_Avaliable_For_Services = 0;
      }
      if (values.Is_Custome_Service_Fee) {
        formValues.Is_Custome_Service_Fee = 1;
      } else {
        formValues.Is_Custome_Service_Fee = 0;
      }
      if (values.Is_Home_Service_Avaliable) {
        formValues.Is_Home_Service_Avaliable = 1;
      } else {
        formValues.Is_Home_Service_Avaliable = 0;
      }
      dispatch(updatePartnerAction(formValues, navigation, toast));
    }
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 15
    },
    label: {
      marginBottom: 30,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      left: 22,
      top: 0.5,
      color: esiColor.SIconColor,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 12,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }
      >
        {descriptionvalue && seconds <= 0 &&
          <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
            {(props) => (
              <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('EditPartnerservice', { id: data[0].Partner_Service_Id })}>
                    Go Back
                  </Button>
                </View>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Edit Partner Services
                    </Text>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                        <TextInput

                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(props.touched.Service_Type && props.errors.Service_Type)}
                          label="Service Type"
                          mode='outlined'
                          multiline
                          disabled
                          value={props.values.Service_Type}
                          onChangeText={props.handleChange("Service_Type")}
                        />
                        <HelperText type="error" visible={Boolean(props.touched.Service_Type && props.errors.Service_Type)}>
                          <ErrorMessage name="Service_Type" />
                        </HelperText>
                      </View>

                      <View style={{ flexGrow: 1, width: 200, flexShrink: 1, marginTop: '1%' }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                            Status
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={StatusOption}
                            maxHeight={StatusOption.length > 1 ? 250 : 200}
                            search={StatusOption.length > 1 ? true : false}
                            labelField="label"
                            valueField="value"
                            dropdownPosition="auto"
                            value={props.values.Status}
                            name="Status"
                            onFocus={() => setStatusDropDown(true)}
                            onBlur={() => setStatusDropDown(false)}
                            onUpdateValue={item => {
                              props.setFieldValue('Status', item.value)
                              setStatusDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                            <ErrorMessage name="Status" />
                          </HelperText>
                        </View>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                        <View style={styles.container}>
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Sample_Service_Video_Url && props.errors.Sample_Service_Video_Url)}
                            label="Sample Service Video Url"
                            mode='outlined'
                            multiline
                            value={props.values.Sample_Service_Video_Url}
                            onChangeText={props.handleChange("Sample_Service_Video_Url")}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Sample_Service_Video_Url && props.errors.Sample_Service_Video_Url)}>
                            {props.touched.Sample_Service_Video_Url && props.errors.Sample_Service_Video_Url}
                          </HelperText>
                        </View>
                      </View>
                    </View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", paddingTop: 15, paddingBottom: 15 }}>
                      <View style={{ flexShrink: 1, flexDirection: "row" }} >
                        <Checkbox.Android
                          color={esiColor.itemColor}
                          name="Is_Avaliable_For_Services"
                          status={props.values.Is_Avaliable_For_Services ? 'checked' : 'unchecked'}
                          onPress={() => {
                            props.setFieldValue("Is_Avaliable_For_Services", !props.values.Is_Avaliable_For_Services);
                          }}
                        />
                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>Is Avaliable For Services</Text>
                      </View>
                      <View style={{ flexShrink: 1, flexDirection: "row" }} >
                        <Checkbox.Android
                          color={esiColor.itemColor}
                          name="Is_Custome_Service_Fee"
                          status={props.values.Is_Custome_Service_Fee ? 'checked' : 'unchecked'}
                          onPress={() => {
                            props.setFieldValue("Is_Custome_Service_Fee", !props.values.Is_Custome_Service_Fee);
                          }}
                        />
                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>Is Custom Service Fee</Text>
                      </View>
                      <View style={{ flexShrink: 1, flexDirection: "row" }} >
                        <Checkbox.Android
                          color={esiColor.itemColor}
                          name="Is_Home_Service_Avaliable"
                          status={props.values.Is_Home_Service_Avaliable ? 'checked' : 'unchecked'}
                          onPress={() => {
                            props.setFieldValue("Is_Home_Service_Avaliable", !props.values.Is_Home_Service_Avaliable);
                          }}
                        />
                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>Is Home Service Avaliable</Text>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>

                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                        <EsiEditor label={"Description"} value={descriptionvalue} onUpdateValue={(value) => { setDescriptionValue(value) }} />
                        <HelperText type="error" visible={(!descriptionvalue || descriptionvalue?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) && props.touched.Service_Description && props.errors.Service_Description}>
                          {(!descriptionvalue || descriptionvalue?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhDiscriptionMin) && props.touched.Service_Description && props.errors.Service_Description}
                        </HelperText>
                      </View>


                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                        <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                        <HelperText type="error" visible={!imgUrl && props.touched.Sample_Service_Image_Url && props.errors.Sample_Service_Image_Url}>
                          {!imgUrl && props.touched.Sample_Service_Image_Url && props.errors.Sample_Service_Image_Url}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{ marginLeft: "auto", marginRight: "auto" }}>
                      <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" textColor={esiColor.itemButtenColor}onPress={props.handleSubmit}>
                        Save
                      </Button>
                    </View>
                  </Card.Content>
                </Card>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface>
  );
};

export default EditPartnerservicepagetwo;