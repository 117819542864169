import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Button, Text, Searchbar, Portal, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import { getAllReviewsandRatingsAction, getAverageReviewsandRatingsAction, resetFilter } from '../../state/actions/reviewsandratingsAction';
import Header from '../auth/components/header';
import RenderHtml from 'react-native-render-html';
import isEmpty from '../../state/validations/is-empty';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import { Rating } from "react-native-ratings";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from 'react-native-vector-icons/FontAwesome';

const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Partner_Review_Id',
        label: 'Partner Review ID'
    },
    {
        value: 'Partner_Email_Id',
        label: 'Partner Email ID'
    },
    {
        value: 'Reviewer_Name',
        label: 'Reviewer Name'
    },
    {
        value: 'Rating',
        label: 'Rating'
    },
    {
        value: 'Status',
        label: 'Status'
    },

];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function ReviewsandratingsDetails({ navigation }: { navigation: any }) {

    //reviewsandratings contains the data from  GET API using state call
    const Allreviewsandratings = useSelector(state => state.reviewandrating.reviewsandratings.all);
    let reviewsandratings = Allreviewsandratings?.results;
    const esiColor = useSelector(state => state.theme);
    const Averagereviewandrating = useSelector(state => state.reviewandrating.Averagereviewandrating.Average);
    const [reviewsandratingMenu, setReviewsandratingsMenu] = React.useState('All')
    const [reviewsandratingSearch, setReviewsandratingsSearch] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [webpage, setwebPage] = React.useState(1);
    const [reviewsandrating, setReviewsandratings] = React.useState();
    const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = (Number(from) + Number(numberOfItemsPerPage) > Allreviewsandratings?.Pagination?.TotalCount ? Allreviewsandratings?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
    const onItemsPerPageChange = (e) => {
        numberItemsPerPageChange(e)
        setPage(0)
        setwebPage(1)
        setPagination({ PageNo: Number(1), PageSize: e, SortBy: "PARTNER_REVIEW_ID", SortOrder: "DESC" })
    }
    const DynamicPageIncrease = (e) => {
        setwebPage(e < Allreviewsandratings?.Pagination?.TotalPages ? e + Number(1) : Allreviewsandratings?.Pagination?.TotalPages)
        setPagination({ PageNo: e == Allreviewsandratings?.Pagination?.TotalPages ? Allreviewsandratings?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PARTNER_REVIEW_ID", SortOrder: "DESC" });
    }
    const DynamicPagedecrese = (e) => {
        setwebPage(e > 1 ? e - Number(1) : 1)
        setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "PARTNER_REVIEW_ID", SortOrder: "DESC" });
    }
    const DynamicPage = (e) => {
        setPage(e)
        setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PARTNER_REVIEW_ID", SortOrder: "DESC" });
    }
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_REVIEW_ID", SortOrder: "DESC" });
    React.useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            brandsCall();
        }
    }, [pagination])
    // search and set search is used for the search functionality
    const [loading, setIsLoading] = React.useState(false);
    const brandsCall = () => {
        setIsLoading(true);
        let formData = {
            "search_by_filter": reviewsandratingMenu,
            "search": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Partner_Review_Id": "",
            "Rating": "",
            "Records_Filter": "FILTER",
            "Status": "",
            "Status_Condition": ""
        };
        dispatch(getAllReviewsandRatingsAction(formData, pagination))
    };

    const refreshCall = () => {
        setIsLoading(true);
        let pagecall = { PageNo: 1, PageSize: 5, SortBy: "PARTNER_REVIEW_ID", SortOrder: "DESC" }
        let formData = {
            "search_by_filter": reviewsandratingMenu,
            "search": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Partner_Review_Id": "",
            "Rating": "",
            "Records_Filter": "FILTER",
            "Status": "",
            "Status_Condition": ""
        };
        setPage(0)
        numberItemsPerPageChange(5)
        dispatch(getAllReviewsandRatingsAction(formData, pagecall))
    };
    const [showDropDown, setShowDropDown] = React.useState(false);
    const EmailID = useSelector(state => state.auth?.user);
    //API dispatch
    const dispatch = useDispatch()
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (isEmpty(reviewsandratings)) {
            let formData = {
                "search_by_filter": reviewsandratingMenu,
                "search": "",
                "Partner_Details_Id": EmailID?.Partner_Details_Id,
                "Partner_Review_Id": "",
                "Rating": "",
                "Records_Filter": "FILTER",
                "Status": "",
                "Status_Condition": ""
            };
            setReviewsandratings(reviewsandratings)
            dispatch(getAllReviewsandRatingsAction(formData, pagination))
        }
    }, [reviewsandratings]);

    React.useEffect(() => {
        let formData1 = {
            "search_by_filter": "",
            "search": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Partner_Review_Id": "",
            "Rating": "",
            "Records_Filter": "FILTER",
            "Status": "",
            "Status_Condition": ""

        };
        dispatch(getAverageReviewsandRatingsAction(formData1))
    }, []);

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );

    });
    React.useEffect(() => {
        if (!isEmpty(reviewsandratings)) {
            setReviewsandratings(reviewsandratings);
        } else {
            setReviewsandratings([]);
        }
    }, [reviewsandratings]);
    //Sorting and Pagination


    const [oldSearch, setOldSearch] = React.useState(0);
    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Partner_Details_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
        setOrderBy(property);
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        refreshCall();
        wait(2000).then(() => setRefreshing(false));
    }, []);
    //dialog
    const [visible, setVisible] = React.useState(false);
    const [ReviewsandRatingsData, setData] = React.useState();

    const hideDialog = () => setVisible(false);

    const handleChange = itemValue => {
        setReviewsandratingsMenu(itemValue);
    };
    const handleSearch = value => {
        setReviewsandratingsSearch(value);
        let formData = {
            "search_by_filter": reviewsandratingMenu,
            "search": value,
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Partner_Review_Id": "",
            "Rating": "",
            "Records_Filter": "FILTER",
            "Status": "",
            "Status_Condition": ""
        };
        dispatch(getAllReviewsandRatingsAction(formData, pagination))
    };

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };

    const style = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 160
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 150
        },
        rating: {
            flexDirection: 'row'
        },
        rowsPerPageContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 10,
            justifyContent: 'flex-end',
            borderTopColor: esiColor.SBorderColor,
            borderWidth: 0.5,
        },
        rowsPerPagePicker: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.itemColor
        },
        // rowsPerPagePickers: {
        //     margin: 10,
        //     width: 70,
        //     height: 40,
        //     borderRadius: 10,
        //     borderColor: 'gray',
        //     borderWidth: 0.5,
        //     paddingHorizontal: 8,
        // },
        paginationContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: esiColor.BackgroundColor
        },
        paginationButton: {
            padding: 10,
            marginHorizontal: 10,
            backgroundColor: esiColor.BackgroundColor
        },
        pageInfoText: {
            fontSize: 16,
            padding: 10,
            fontWeight: 'bold',
            color: esiColor.itemColor
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "95%", maxHeight: (dimensions >= 500) ? 500 : 500, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
                    <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                        <IconButton color={esiColor.GBFColor} icon="close" size={20} onPress={() => setVisible(!visible)} />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                        <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>View Reviews & Ratings</Text>
                    </Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
                            <Dialog.Content style={{ marginLeft: -30 }}>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Review ID</Text></View>
                                    <View style={{ flex: 1 }}><Text style={{ color: esiColor.GBFColor }}>{ReviewsandRatingsData?.Partner_Review_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Reviewer Name</Text></View>
                                    <View style={{ flex: 1 }}><Text style={{ color: esiColor.GBFColor }}>{ReviewsandRatingsData?.Reviewer_Email_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View>
                                    <View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{ReviewsandRatingsData?.Created_Date} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Email ID</Text></View>
                                    <View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{ReviewsandRatingsData?.Partner_Email_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Details ID</Text></View>
                                    <View style={{ flex: 1 }}><Text style={{ color: esiColor.GBFColor }}>{ReviewsandRatingsData?.Partner_Details_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}> Rating</Text></View>
                                    <View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{ReviewsandRatingsData?.Rating} </Text></View>
                                </View>

                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                                    <View style={{ flex: 1 }}><Text style={{ color: esiColor.itemColor }}>{ReviewsandRatingsData?.Status} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Comments</Text></View>
                                    <View style={{ flex: 1, marginTop: -12 }}><RenderHtml source={{ html: `<span style="color: ${esiColor.Text}">${ReviewsandRatingsData?.Comments}</span>` }} /></View>
                                </View>

                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>

            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                        navigation.navigate('OrderMainCenter')
                        handleSearch("")
                        dispatch(resetFilter())
                    }}>
                        Go Back
                    </Button>
                </View>
                <View style={{ flexDirection: "column" }}>
                    <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Reviews & Ratings</Text>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={filterOptions}
                                containerStyle={style.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={reviewsandratingMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                            <Picker
                                selectedValue={reviewsandratingMenu}
                                style={{ height: 50, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Partner Review ID" value="Partner_Review_Id" />
                                <Picker.Item label="Partner Email ID" value="Partner_Email_Id" />
                                <Picker.Item label="Reviewer Name" value="Reviewer_Name" />
                                <Picker.Item label="Rating" value="Rating" />
                                <Picker.Item label="Status" value="Status" />
                            </Picker>
                        </View>
                    }


                    <View style={{ flex: dimensions <= 500 ? 0.1 : 0.1 }} />
                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{
                                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.BackgroundColor
                            }}
                            iconColor={esiColor.SIconColor}
                            theme={esiColor.pagination_theme}
                            placeholderTextColor={esiColor.SPHTextColor}
                            placeholder="Search"
                            onChangeText={(value) => handleSearch(value)}
                            value={reviewsandratingSearch}
                        />
                    </View>
                </View>
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 5 }}>
                    <View style={style.rating}>
                        <Text style={{ marginTop: 5, color: esiColor.Text }}>Average Rating :{" "}</Text>
                        <Rating
                            readonly={true}
                            rating={Averagereviewandrating.Rating}
                            type='heart'
                            // ratingBackgroundColor='#c8c7c8'
                            tintColor={esiColor.TintColor}
                            startingValue={Averagereviewandrating.Rating}
                            ratingCount={5}
                            imageSize={20}
                            style={{ paddingVertical: 5 }}
                            minValue={1}
                            name="rating"
                        />
                    </View>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    <ScrollView horizontal={dimensions >= 700 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Review_Id'}
                                            sortDirection={orderBy === 'Partner_Review_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Review_Id')}><Text style={style.titletext}>Partner Review ID</Text></DataTable.Title>
                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Email_Id'}
                                            sortDirection={orderBy === 'Partner_Email_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Email_Id')}><Text style={style.titletext}>Partner Email ID</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Reviewer_Email_Id'}
                                            sortDirection={orderBy === 'Reviewer_Email_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Reviewer_Email_Id')}><Text style={style.titletext}>Reviewer Name</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Rating'}
                                            sortDirection={orderBy === 'Rating' ? order : 'ascending'}
                                            onPress={createSortHandler('Rating')}><Text style={style.titletext}>Rating</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Status'}
                                            sortDirection={orderBy === 'Status' ? order : 'ascending'}
                                            onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        reviewsandratings?.map((reviewsandrating, index) => (
                                            <DataTable.Row style={style.databeBox} key={reviewsandrating.Partner_Review_Id}>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{reviewsandrating.Partner_Review_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{reviewsandrating.Partner_Email_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{reviewsandrating.Reviewer_Email_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{reviewsandrating.Rating}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{reviewsandrating.Status}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    <Button icon="eye" textColor={esiColor.SIconColor} onPress={(e) => { setData(reviewsandrating); setVisible(!visible) }} /></DataTable.Cell>
                                            </DataTable.Row>
                                        ))
                                    }

                                    {isEmpty(reviewsandratings) &&
                                        <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                                No records found!
                                            </Text>
                                            <Image source={require('../../assets/images/nodata.gif')}
                                                style={{ width: 300, height: 300, alignSelf: "center" }} />
                                        </View>
                                    }
                                    {Platform.OS !== "web" ?

                                        <DataTable.Pagination
                                            // theme={customTheme}
                                            page={page}
                                            numberOfPages={Math.ceil(Allreviewsandratings?.Pagination?.TotalCount / numberOfItemsPerPage)}
                                            onPageChange={page => DynamicPage(to < Allreviewsandratings?.Pagination?.TotalCount ? page : 0)}
                                            label={Allreviewsandratings?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Allreviewsandratings?.Pagination?.TotalCount}` : '0-0'}
                                            showFastPaginationControls={(Number(page) + Number(1)) > Allreviewsandratings?.Pagination?.TotalPages ? false : true}
                                            numberOfItemsPerPageList={numberOfItemsPerPageList}
                                            numberOfItemsPerPage={numberOfItemsPerPage}
                                            onItemsPerPageChange={onItemsPerPageChange}
                                            selectPageDropdownLabel={'Rows per page'}
                                        />

                                        :
                                        <View style={style.rowsPerPageContainer}>
                                            <Text style={{color: esiColor.Text}}>rows per page: </Text>
                                            <Picker
                                                style={style.rowsPerPagePicker}
                                                selectedValue={numberOfItemsPerPage}
                                                onValueChange={(e) => onItemsPerPageChange(e)}
                                            >
                                                <Picker.Item label="5" value={5} />
                                                <Picker.Item label="10" value={10} />
                                                <Picker.Item label="20" value={20} />
                                            </Picker>
                                            <View style={style.paginationContainer}>
                                                <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                                                </TouchableOpacity>
                                                <Text style={style.pageInfoText}>
                                                    {Allreviewsandratings?.Pagination?.PageNo + ' of ' + Allreviewsandratings?.Pagination?.TotalPages}
                                                </Text>
                                                <TouchableOpacity onPress={e => DynamicPageIncrease(to < Allreviewsandratings?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                                                </TouchableOpacity>
                                            </View>
                                        </View>}
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>

                </View>
            </ScrollView>
        </Surface>

    );
}

export default ReviewsandratingsDetails;