import {
  ALL_BRANDS_REQUEST,
  ALL_BRANDS_FAIL,
  SET_ALL_BRANDS,
  GET_SINGLE_BRAND,
  GET_SINGLE_BRAND_REQUEST,
  GET_SINGLE_BRAND_FAIL,  
  TOP_BRAND,
  TOP_BRAND_FAIL,
  TOP_BRAND_SUCCESS,
  ALL_BRAND_USER_REVIEWS_REQUEST,
  ALL_BRAND_USER_REVIEWS,
  ALL_BRAND_USER_REVIEWS_FAIL,
  BRAND_REVIEW_CREATE_REQUEST,
  BRAND_REVIEW_CREATE_SUCCESS,
  BRAND_REVIEW_CREATE_FAIL,
  BRAND_REVIEW_UPDATE_REQUEST,
  BRAND_REVIEW_UPDATE_SUCCESS,
  BRAND_REVIEW_UPDATE_FAIL,
} from '../../actions/shoppers/shopperbrandsActions'

const initialState = {
  brands: { all: [], error: '', isLoading: false },
  brand: { brand: [], error: '', isLoading: false },
  products: { all: [], error: '', isLoading: false },
  coupons: { all: [], error: '', isLoading: false },
  coupon: { coupon: [], error: '', isLoading: false },
  brandcoupons: { all: [], error: '', isLoading: false },
  partnercoupons: { all: [], error: '', isLoading: false },
  deals: { all: [], error: '', isLoading: false },
  branddeals: { all: [], error: '', isLoading: false },
  partnerdeals: { all: [], error: '', isLoading: false },
  deal: { deal: [], error: '', isLoading: false },
  brandReviews: { all: [], error: '', isLoading: false },
  reviewsCreate: { review: {}, error: '', isLoading: false },
  reviewsUpdate: { review: {}, error: '', isLoading: false },
  topbrand: { topbrand: {}, error: '', isLoading: false }};

export default function brandsReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_BRANDS_REQUEST:
      return { ...state, brands: { all: state.brands.all, error: '', isLoading: true } };
    case SET_ALL_BRANDS:
      return { ...state, brands: { all: action.payload, error: '', isLoading: false } };
    case ALL_BRANDS_FAIL:
      return { ...state, brands: { all: [], error: action.payload, isLoading: false } };
    case GET_SINGLE_BRAND_REQUEST:
      return { ...state, brand: { brand: state.brand.brand, error: '', isLoading: true } };
    case GET_SINGLE_BRAND:
      return { ...state, brand: { brand: action.payload, error: '', isLoading: false } };
    case GET_SINGLE_BRAND_FAIL:
      return { ...state, brand: { brand: [], error: action.payload, isLoading: false } };
      case TOP_BRAND:
      return { ...state, topbrand: { topbrand: state.topbrand.topbrand, error: '', isLoading: true } };
    case TOP_BRAND_SUCCESS:
      return { ...state, topbrand: { topbrand: action.payload, error: '', isLoading: false } };
    case TOP_BRAND_FAIL:
      return { ...state, topbrand: { topbrand: [], error: action.payload, isLoading: false } };
      case ALL_BRAND_USER_REVIEWS_REQUEST:
        return { ...state, brandReviews: { all: state.brandReviews.all, error: '', isLoading: true } };
      case ALL_BRAND_USER_REVIEWS:
        return { ...state, brandReviews: { all: action.payload, error: '', isLoading: false } };
      case ALL_BRAND_USER_REVIEWS_FAIL:
        return { ...state, brandReviews: { all: [], error: action.payload, isLoading: false } };
  
      case BRAND_REVIEW_CREATE_REQUEST:
        return { ...state, reviewsCreate: { review: {}, error: '', isLoading: true } };
      case BRAND_REVIEW_CREATE_SUCCESS:
        return { ...state, reviewsCreate: { review: state.reviewsCreate.review, error: '', isLoading: false } };
      case BRAND_REVIEW_CREATE_FAIL:
        return { ...state, reviewsCreate: { review: {}, error: action.payload, isLoading: false } };
  
      case BRAND_REVIEW_UPDATE_REQUEST:
        return { ...state, reviewsUpdate: { review: {}, error: '', isLoading: true } };
      case BRAND_REVIEW_UPDATE_SUCCESS:
        return { ...state, reviewsUpdate: { review: state.reviewsUpdate.review, error: '', isLoading: false } };
      case BRAND_REVIEW_UPDATE_FAIL:
        return { ...state, reviewsUpdate: { review: {}, error: action.payload, isLoading: false } };
  
  
    default:
      return state;
  }
}