import React, { useState } from "react";
import { EditownPartnerproduct, getAllProductPartnerAction, productpartnerFilter, resetFilter } from '../../state/actions/partnerproductsAction';
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, Portal, IconButton, DataTable, Button, Searchbar, Surface, Switch, DefaultTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../state/validations/is-empty'
import 'react-native-gesture-handler'
import Header from "../auth/components/header";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useToast } from "../../components/custom/react-native-paper-toast/src";
import Icon from 'react-native-vector-icons/FontAwesome';

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Product_Name',
    label: 'Product Name'
  },
  {
    value: 'Partner_Product_Id',
    label: 'Partner Product ID'
  },
  {
    value: 'Created_By',
    label: 'Created By'
  },
];
const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const PartnerownProductsGet = (props: any) => {
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);
  const toast = useToast();
  const dispatch = useDispatch()
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);
  const onToggleSwitch = () => setIsSwitchOn(!isSwitchOn);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const ProductPartners = useSelector(state => state.productpartners.partners.all);
  const [productspartnersMenu, setProductspartnersMenu] = React.useState('All')
  let allProductPartners = ProductPartners?.results;
  const [productpartnersSearch, setProductpartnersSearch] = React.useState('');
  const EmailID = useSelector(state => state.auth?.user);

  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > ProductPartners?.Pagination?.TotalCount ? ProductPartners?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setPagination({ PageNo: Number(1), PageSize: e, SortBy: "PARTNER_PRODUCT_ID", SortOrder: "DESC" })
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PARTNER_PRODUCT_ID", SortOrder: "DESC" });
  }
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_PRODUCT_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      ProductPartnersCall();
    }
  }, [pagination])
  const [loading, setIsLoading] = React.useState(false);
  const ProductPartnersCall = () => {
    setIsLoading(true);
    let formData = {
      "search_by_filter": "",
      "search": "",
      "Cashback_Label": "",
      "Discount_Label": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Partner_Email_Id": "",
      "Partner_Product_Id": "",
      "Product_Id": "",
      "Warranty_Period": ""
    };
    dispatch(getAllProductPartnerAction(formData, pagination))
  };
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "PARTNER_PRODUCT_ID", SortOrder: "DESC" }
    let formData = {
      "search_by_filter": "",
      "search": "",
      "Cashback_Label": "",
      "Discount_Label": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Partner_Email_Id": "",
      "Partner_Product_Id": "",
      "Product_Id": "",
      "Warranty_Period": ""
    };
    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getAllProductPartnerAction(formData, pagecall))
  };
  const productpartnersid = props.route?.params?.productpartnerparams;
  const productmasterid = props.route?.params?.productmaster;
  const [visible, setVisible] = React.useState(false);
  const [productpartner, setData] = React.useState();
  const hideDialog = () => setVisible(false);
  const handleClickadd = () => {
    props.navigation.navigate('AddownProductPartner', { productspartners: productpartnersid, productmasterid: productmasterid })
  };

  const handleClickedit = async (PartnerproductData) => {
    props.navigation.navigate('EditownProductPartner', { partnerproductid: PartnerproductData.Partner_Product_Id, productid: PartnerproductData.Product_Id })
  };
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (EmailID?.Partner_Details_Id != '') {
      const formData = {
        "search_by_filter": "",
        "search": "",
        "Cashback_Label": "",
        "Discount_Label": "",
        "Partner_Details_Id": EmailID?.Partner_Details_Id,
        "Records_Filter": "FILTER",
        "Partner_Email_Id": "",
        "Partner_Product_Id": "",
        "Product_Id": "",
        "Warranty_Period": ""
      };
      handleSearch("");
      dispatch(getAllProductPartnerAction(formData, pagination))
    }

  }, [EmailID]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  const UpdatePartnerproduct = (productpartner) => {
    const formValues = {
      Partner_Product_Id: productpartner.Partner_Product_Id,
      Partner_Original_Discount_Amount: productpartner.Partner_Original_Discount_Amount,
      Partner_Original_Discount: productpartner.Partner_Original_Discount,
      Partner_Original_Selling_Price: productpartner.Partner_Original_Selling_Price,
      Price: productpartner.Partner_Price,
      Gift_Wrap_Amount: productpartner.Gift_Wrap_Amount,
      Discount_Type: productpartner.Discount_Type,
      Discount: productpartner.Discount,
      Esi_Cashback_Type: productpartner.Esi_Cashback_Type,
      Esi_Cashback: productpartner.Esi_Cashback,
      Discount_From_Date: productpartner.Discount_From_Date,
      Discount_To_Date: productpartner.Discount_To_Date,
      Max_Days_To_Return: productpartner.Max_Days_To_Return,
      Shipping_Amount: productpartner.Shipping_Amount,
      Warranty_Period: productpartner.Warranty_Period,
      Modified_By: EmailID.Email_Id,
      Is_Partner_Shipping_Available: productpartner.Is_Partner_Shipping_Available,
      Add_To_Cart: productpartner.Add_To_Cart,
      Esi_Commission: productpartner.Esi_Commission,
      Esi_Commission_Type: productpartner.Esi_Commission_Type,
      Exclusive: productpartner.Exclusive,
      Extra_CashBack: productpartner.Extra_Cashback,
      Get_Coupon: productpartner.Get_Coupon,
      Is_Default: productpartner.Is_Default,
      Is_Esi_Shipping_Aavailable: productpartner.Is_Shipping_Available,
      Is_Gift_Wrap_Available: productpartner.Is_Gift_Wrap_Available,
      Is_Popular: productpartner.Is_Popular,
      Is_Return_Available: productpartner.Is_Return_Available,
      Is_Shop_By_City_Only: productpartner.Is_Shop_By_City_Only,
      Is_Product_Available: '1',
      Is_Upcoming: productpartner.Is_Coming,
      Partner_Address_Id: productpartner.Partner_Address_Id,
      Partner_Details_Id: productpartner.Partner_Details_Id,
      Product_Id: productpartner.Product_Id,
      Status: productpartner.Status,
      Todays_Hot: productpartner.Todays_Hot,
      Verified: productpartner.Verified,
      What_Hot: productpartner.Whats_Hot,
      Cashback_Label: productpartner.Cashback_Label,
      Discount_Label: productpartner.Discount_Label,
    };
    numberItemsPerPageChange(5)
    dispatch(EditownPartnerproduct(formValues, productpartner, navigation, toast, 1));
  };
  const UpdatePartnerproducts = (productpartner) => {
    const formValues = {
      Partner_Product_Id: productpartner.Partner_Product_Id,
      Partner_Original_Discount_Amount: productpartner.Partner_Original_Discount_Amount,
      Partner_Original_Discount: productpartner.Partner_Original_Discount,
      Partner_Original_Selling_Price: productpartner.Partner_Original_Selling_Price,
      Price: productpartner.Partner_Price,
      Gift_Wrap_Amount: productpartner.Gift_Wrap_Amount,
      Discount_Type: productpartner.Discount_Type,
      Discount: productpartner.Discount,
      Esi_Cashback_Type: productpartner.Esi_Cashback_Type,
      Esi_Cashback: productpartner.Esi_Cashback,
      Discount_From_Date: productpartner.Discount_From_Date,
      Discount_To_Date: productpartner.Discount_To_Date,
      Max_Days_To_Return: productpartner.Max_Days_To_Return,
      Shipping_Amount: productpartner.Shipping_Amount,
      Warranty_Period: productpartner.Warranty_Period,
      Modified_By: EmailID.Email_Id,
      Is_Partner_Shipping_Available: productpartner.Is_Partner_Shipping_Available,
      Add_To_Cart: productpartner.Add_To_Cart,
      Esi_Commission: productpartner.Esi_Commission,
      Esi_Commission_Type: productpartner.Esi_Commission_Type,
      Exclusive: productpartner.Exclusive,
      Extra_CashBack: productpartner.Extra_Cashback,
      Get_Coupon: productpartner.Get_Coupon,
      Is_Default: productpartner.Is_Default,
      Is_Esi_Shipping_Aavailable: productpartner.Is_Shipping_Available,
      Is_Gift_Wrap_Available: productpartner.Is_Gift_Wrap_Available,
      Is_Popular: productpartner.Is_Popular,
      Is_Return_Available: productpartner.Is_Return_Available,
      Is_Shop_By_City_Only: productpartner.Is_Shop_By_City_Only,
      Is_Product_Available: '0',
      Is_Upcoming: productpartner.Is_Coming,
      Partner_Address_Id: productpartner.Partner_Address_Id,
      Partner_Details_Id: productpartner.Partner_Details_Id,
      Product_Id: productpartner.Product_Id,
      Status: productpartner.Status,
      Todays_Hot: productpartner.Todays_Hot,
      Verified: productpartner.Verified,
      What_Hot: productpartner.Whats_Hot,
      Cashback_Label: productpartner.Cashback_Label,
      Discount_Label: productpartner.Discount_Label,
    };
    numberItemsPerPageChange(5)
    dispatch(EditownPartnerproduct(formValues, productpartner, navigation, toast, 1));
  };
  const [oldSearch, setOldSearch] = React.useState(0);
  const checkproductpartnersForTable = () => {
    if (productpartnersSearch) {
      try {
        if (productspartnersMenu === "All") {
          return allProductPartners?.filter(x => {
            let keys = ["Product_Name", "Partner_Details_Id", "Partner_Product_Id", "Partner_Address_Id", "Partner_Product_Id", "Created_By"];
            for (let i = 1; i < keys.length; i++) {
              const mainString = typeof x[keys[i]] === "string" ? x[keys[i]]?.toLowerCase() : x[keys[i]];
              if (typeof x[keys[i]] === "string") {
                return true;
              }
              else {
                if (mainString == productpartnersSearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return allProductPartners?.filter(x => {
            let mainString = typeof x[productspartnersMenu] == "string" ? x[productspartnersMenu]?.toLowerCase() : x[productspartnersMenu];
            let subString = typeof x[productspartnersMenu] == "string" ? productpartnersSearch?.toLowerCase() : productpartnersSearch;
            if (typeof x[productspartnersMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString == productpartnersSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (pagination.PageNo - 1 > 1) {
          if (oldSearch != productpartnersSearch?.length) {
            setOldSearch(productpartnersSearch?.length);
          }
        }
        else if (oldSearch != productpartnersSearch?.length) {
          setOldSearch(productpartnersSearch?.length);
        }
      }
    }
    else {
      return allProductPartners
    }
  }
  //sorting
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Partner_Details_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleChange = itemValue => {
    setProductspartnersMenu(itemValue)
    // dispatch(productpartnerFilter('select', itemValue));
  };
  const handleSearch = value => {
    setProductpartnersSearch(value)
    const formData = {
      "search_by_filter": "",
      "search": value,
      "Cashback_Label": "",
      "Discount_Label": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Partner_Email_Id": "",
      "Partner_Product_Id": "",
      "Product_Id": "",
      "Warranty_Period": ""
    };
    dispatch(getAllProductPartnerAction(formData, pagination))
  };
  const data =
    checkproductpartnersForTable()

  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    const formData = {
      "search_by_filter": "",
      "search": "",
      "Cashback_Label": "",
      "Discount_Label": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Partner_Email_Id": "",
      "Partner_Product_Id": "",
      "Product_Id": "",
      "Warranty_Period": ""
    };
    handleSearch("");
    dispatch(getAllProductPartnerAction(formData, pagination))
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const handleClickshoppersproducts = async (allProducts) => {
    navigation.navigate('ShoppersownGet', { shoppersproductparams: allProducts.Product_Id, partnerproductid: allProducts.Partner_Product_Id })
  };
  const handleClickInventory = async (allProducts) => {
    navigation.navigate('Inventory', { Id: 1 })
  };

  const [webpage, setwebPage] = React.useState(1);
  const DynamicPageIncrease = (e) => {
    setwebPage(e < ProductPartners?.Pagination?.TotalPages ? e + Number(1) : ProductPartners?.Pagination?.TotalPages)
    setPagination({ PageNo: e == ProductPartners?.Pagination?.TotalPages ? ProductPartners?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PARTNER_PRODUCT_ID", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "PARTNER_PRODUCT_ID", SortOrder: "DESC" });
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 200
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'left',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 240
    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
    },
    rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
    },
    rowsPerPagePickers: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      borderColor: 'gray',
      borderWidth: 0.5,
      paddingHorizontal: 8,
    },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: esiColor.BackgroundColor
    },
    paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      backgroundColor: esiColor.BackgroundColor
    },
    pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
            <IconButton
              color={esiColor.GBFColor}
              icon="close"
              size={20}
              onPress={() => setVisible(!visible)}
            />
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>Product Partner View</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView
              contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Dialog.Content style={{ marginLeft: -30 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Partner Type</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productpartner?.Partner_Type} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2.5 }}>
                    <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Details ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{productpartner?.Partner_Details_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View><View style={{ flex: 2 }}><Text style={{ color: productpartner?.Status === "Active" ? esiColor.GBFColor : "red" }}>{productpartner?.Status} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Product ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{productpartner?.Partner_Product_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{productpartner?.Product_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Name</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productpartner?.Product_Name} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Gift Wrap Amount</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productpartner?.Gift_Wrap_Amount} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Shipping Amount</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productpartner?.Shipping_Amount} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productpartner?.Created_By} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View >
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
          navigation.navigate('ProductMainCenter', { productspartners: productpartnersid, productmasterid })
          handleSearch("")
          dispatch(resetFilter())
        }
        }>
          Go Back
        </Button>
      </View>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <View style={{ flexDirection: "column", }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>{allProductPartners ? allProductPartners[0]?.Partner_Name : ''}{" "}Own Partner Products</Text>
        </View>
        {(permissions.Products_Partner_Products_Related_Products === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={(e) => { handleClickadd(e); }}>
              Add Partner Products
            </Button>
          </View>}
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[styles.dropdown]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={filterOptions}
                containerStyle={styles.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={productspartnersMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={productspartnersMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Product Name" value="Product_Name" />
                <Picker.Item label="Partner Product ID" value="Partner_Product_Id" />
                <Picker.Item label="Created By" value="Created_By" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              variant="outlined"
              onChangeText={(value) => handleSearch(value)}
              value={productpartnersSearch}
            />
          </View>
        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={styles.mainbox}>
            <Card>
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={styles.databeHeader}>
                  <DataTable.Title active={orderBy === 'Partner_Product_Id'} sortDirection={orderBy === 'Partner_Product_Id' ? order : 'ascending'} onPress={createSortHandler('Partner_Product_Id')} style={styles.title}><Text style={styles.titletext}>Partner Product ID</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Product_Name'} sortDirection={orderBy === 'Product_Name' ? order : 'ascending'} onPress={createSortHandler('Product_Name')} style={styles.title}><Text style={styles.titletext}>Product Name</Text></DataTable.Title>
                  {/* <DataTable.Title active={orderBy === 'Partner_Details_Id'} sortDirection={orderBy === 'Partner_Details_Id' ? order : 'ascending'} onPress={createSortHandler('Partner_Details_Id')} style={styles.title}><Text style={styles.titletext}>Partner Details ID</Text></DataTable.Title> */}
                  <DataTable.Title  active={orderBy === 'Created_By'} sortDirection={orderBy === 'Created_By' ? order : 'ascending'} onPress={createSortHandler('Created_By')} style={styles.title}><Text style={styles.titletext}>Created By</Text></DataTable.Title>
                  <DataTable.Title  style={styles.title}><Text style={styles.titletext}>Shoppers Products</Text></DataTable.Title>
                  <DataTable.Title  style={styles.title}><Text style={styles.titletext}>Inventory</Text></DataTable.Title>
                  <DataTable.Title  style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                  <DataTable.Title  style={styles.title}><Text style={styles.titletext}>Product Available</Text></DataTable.Title>
                </DataTable.Header>
                {data?.map((PartnerproductData, i) => (
                  <DataTable.Row style={styles.databeBox} key={i}>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{PartnerproductData.Partner_Product_Id}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{PartnerproductData.Product_Name} </Text></DataTable.Cell>
                    {/* <DataTable.Cell style={styles.tablerow}>{PartnerproductData.Partner_Details_Id}</DataTable.Cell> */}
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{PartnerproductData.Created_By}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}> <Button icon="shopping" textColor={esiColor.SIconColor} onPress={(e) => { handleClickshoppersproducts(PartnerproductData); }}></Button></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}> <Button icon="alpha-i-box-outline" textColor={esiColor.SIconColor} onPress={(e) => { handleClickInventory(PartnerproductData); }}></Button></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>
                      {(permissions.Products_Partner_Products_Related_Products === "write") &&
                        <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClickedit(PartnerproductData); }} />
                      }
                      <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(PartnerproductData); setVisible(!visible) }} /></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}> {PartnerproductData?.Is_Product_Available == '0' ? <Button textColor={esiColor.itemButtenColor} buttonColor={esiColor.globalButtonColor} mode="contained" onPress={(e) => { UpdatePartnerproduct(PartnerproductData) }} >YES</Button> : <Button buttonColor="gray" textColor={esiColor.itemButtenColor} mode="contained" onPress={(e) => { UpdatePartnerproducts(PartnerproductData) }} >NO</Button>}</DataTable.Cell>
                  </DataTable.Row>
                ))
                }
                {isEmpty(data) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                 {Platform.OS !== "web" ?
                <DataTable.Pagination
                  // theme={customTheme}
                  page={page}
                  numberOfPages={Math.ceil(ProductPartners?.Pagination?.TotalCount / numberOfItemsPerPage)}
                  onPageChange={page => DynamicPage(to < ProductPartners?.Pagination?.TotalCount ? page : 0)}
                  label={ProductPartners?.Pagination?.TotalCount ? `${from + 1}-${to} of ${ProductPartners?.Pagination?.TotalCount}` : '0-0'}
                  showFastPaginationControls={(Number(page) + Number(1)) > ProductPartners?.Pagination?.TotalPages ? false : true} numberOfItemsPerPageList={numberOfItemsPerPageList}
                  numberOfItemsPerPage={numberOfItemsPerPage}
                  onItemsPerPageChange={onItemsPerPageChange}
                  selectPageDropdownLabel={'Rows per page'}
                />:
                <View style={styles.rowsPerPageContainer}>
                  <Text style={{ color: esiColor.Text }}>rows per page: </Text>
                  <Picker
                    style={styles.rowsPerPagePicker}
                    selectedValue={numberOfItemsPerPage}
                    onValueChange={(e) => onItemsPerPageChange(e)}
                  >
                    <Picker.Item label="5" value={5} />
                    <Picker.Item label="10" value={10} />
                    <Picker.Item label="20" value={20} />
                  </Picker>
                  <View style={styles.paginationContainer}>
                    <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={styles.paginationButton}>
                      <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                    </TouchableOpacity>
                    <Text style={styles.pageInfoText}>
                      {ProductPartners?.Pagination?.PageNo + ' of ' + ProductPartners?.Pagination?.TotalPages}
                    </Text>
                    <TouchableOpacity onPress={e => DynamicPageIncrease(to < ProductPartners?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={styles.paginationButton}>
                      <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                    </TouchableOpacity>
                  </View>
                </View>}
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}

export default PartnerownProductsGet;