
import axios from 'axios';
import { meeapi } from '../node-actions/meeapi';
import config from './../config';

export const SHOPPERPARTNERS_REQUEST = "SHOPPERPARTNERS_REQUEST";
export const SHOPPERPARTNERS_SUCCESS = "SHOPPERPARTNERS_SUCCESS";
export const SHOPPERPARTNERS_FAIL = "SHOPPERPARTNERS_FAIL";

export const SHOPPER_PARTNERS_REQUEST = "SHOPPER_PARTNERS_REQUEST";
export const SHOPPER_PARTNERS_SUCCESS = "SHOPPER_PARTNERS_SUCCESS";
export const SHOPPER_PARTNERS_FAIL = "SHOPPER_PARTNERS_FAIL";

export const SHOPPERPRODUCT_REQUEST = "SHOPPERPRODUCT_REQUEST";
export const SHOPPERPRODUCT_SUCCESS = "SHOPPERPRODUCT_SUCCESS";
export const SHOPPERPRODUCT_FAIL = "SHOPPERPRODUCT_FAIL";

export const SHOPPERPARTNERS_FILTER = "SHOPPERPARTNERS_FILTER";
export const SHOPPERPARTNERS_DATA = "SHOPPERPARTNERS_DATA";

export const SHOPPERPARTNER_CREATE_REQUEST = "SHOPPERPARTNER_CREATE_REQUEST";
export const SHOPPERPARTNER_CREATE_SUCCESS = "SHOPPERPARTNER_CREATE_SUCCESS";
export const SHOPPERPARTNER_CREATE_FAIL = "SHOPPERPARTNER_CREATE_FAIL";

export const SHOPPERPARTNER_UPDATE_REQUEST = "SHOPPERPARTNER_UPDATE_REQUEST";
export const SHOPPERPARTNER_UPDATE_SUCCESS = "SHOPPERPARTNER_UPDATE_SUCCESS";
export const SHOPPERPARTNER_UPDATE_FAIL = "SHOPPERPARTNER_UPDATE_FAIL";

export const SHOPPERPHYSICALPARTNER_ADDRESS_REQUEST = "SHOPPERPHYSICALPARTNER_ADDRESS_REQUEST";
export const SHOPPERPHYSICALPARTNER_ADDRESS_SUCCESS = "SHOPPERPHYSICALPARTNER_ADDRESS_SUCCESS";
export const SHOPPERPHYSICALPARTNER_ADDRESS_FAIL = "SHOPPERPHYSICALPARTNER_ADDRESS_FAIL";

const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
  }
  
export const invalid_data = () => {
    return {
        type: RESET
    }
}
/*=============================================================
                  Get Shoppers Action
===============================================================*/
export const getAllshopperPartnerAction = (formData: any,pagination = {}) => async dispatch =>{
       try {

        dispatch({
            type: SHOPPERPARTNERS_REQUEST
        });
        meeapi.post(urlGenarator(`Discounted_Products/Get`,pagination),
        formData,
        ).then(response => {
            let data = response.data;
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SHOPPERPARTNERS_SUCCESS,
                    payload: data
                });
            } else {
                dispatch({
                    type: SHOPPERPARTNERS_SUCCESS,
                    payload: data
                });
            }
        })
    } catch (err) {
        dispatch({
            type: SHOPPERPARTNERS_FAIL,
            payload: err
        });
    }
}
export const getshopperProductAction = (formData: any,pagination = {},callBackGetData:any) => async dispatch =>{
     try {

     dispatch({
         type: SHOPPER_PARTNERS_REQUEST
     });
     meeapi.post(urlGenarator(`Discounted_Products/Get`,pagination),
     formData,
     ).then(response => {
        
         let data = response.data;
         if (data.Is_Data_Exist === '0') {
            callBackGetData([])
             dispatch({
                 type: SHOPPER_PARTNERS_SUCCESS,
                 payload: data
             });
         } else {
            callBackGetData(data.results[0])
             dispatch({
                 type: SHOPPER_PARTNERS_SUCCESS,
                 payload: data.results[0]
             });
         }
     })
 } catch (err) {
     dispatch({
         type: SHOPPER_PARTNERS_FAIL,
         payload: err
     });
 }
}
export const getshopperproductAction = (formData: any) => async dispatch =>{
      try {

        dispatch({
            type: SHOPPERPRODUCT_REQUEST
        });
        let { data, status } = await axios.post(`${config.url}/Discounted_Products/Get`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SHOPPERPRODUCT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SHOPPERPRODUCT_SUCCESS,
                    payload: data.Discounted_Products[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SHOPPERPRODUCT_FAIL,
            payload: err
        });
    }
}
/*====================================================================
                  Shoppers Product
=======================================================================*/

export const addshopperPhysicalPartnerAction = (formData: any, navigation: any, toast: any) => async dispatch => {
    dispatch({
        type: SHOPPERPARTNER_CREATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Discounted_Products/Create`,
        formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SHOPPERPARTNER_CREATE_SUCCESS
                });
                navigation.navigate('ShoppersownGet', { shoppersproductparams: formData?.Product_Id });
                const formValue = {
                    "search": '',
                    "Discounted_Product_Id": "",
                    "Max_Discount": "",
                    "Min_Discount": "",
                    "Partner_Details_Id": formData.Partner_Details_Id,
                    "Product_Id": formData.Product_Id,
                    "Records_Filter": "FILTER",
                    "Status": ""
                };
                const pagination ={ PageNo: 1, PageSize: 5, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" };
                dispatch(getAllshopperPartnerAction(formValue,pagination))
                toast.show({ message: 'Shopper Product Added Successfully.', type: 'info', duration: 3000, position: 'top' });

            } else {
                dispatch({
                    type: SHOPPERPARTNER_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SHOPPERPARTNER_CREATE_FAIL,
                payload: "Currently server is not working.Please try again later."
            });
        })
};
/*=============================================================
          Update Shopper
===============================================================*/
export const EditshopperPhysicalPartner = (formData: any, navigation: any, toast: any,) => async dispatch => {
    dispatch({
        type: SHOPPERPARTNER_UPDATE_REQUEST
    });
    axios.put(`${config.nodecudurl}/Discounted_Products/Update`,
        formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SHOPPERPARTNER_UPDATE_SUCCESS
                });
                navigation.navigate('ShoppersownGet', { shoppersproductparams:formData?.Product_Id });
                toast.show({ message: 'Shopper Product Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    "search": '',
                    "Discounted_Product_Id": "",
                    "Max_Discount": "",
                    "Min_Discount": "",
                    "Partner_Details_Id": formData.Partner_Details_Id,
                    "Product_Id": formData.Product_Id,
                    "Records_Filter": "FILTER",
                    "Status": ""
                };
                const pagination ={ PageNo: 1, PageSize: 5, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" };
 
                dispatch(getAllshopperPartnerAction(formValue,pagination))
            } else {
                dispatch({
                    type: SHOPPERPARTNER_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SHOPPERPARTNER_UPDATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};

export const shopperpartnerFilter = (fType: any, value: any) => {
    return function (dispatch) {
        dispatch({
            type: SHOPPERPARTNERS_FILTER,
            fType: fType,
            value: value,
        });
    }
}
export const shopperpartnerdata = (value: any) => {
    return function (dispatch) {
        dispatch({
            type: SHOPPERPARTNERS_DATA,
            value: value,
        });
    }
}
