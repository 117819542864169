import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image,RefreshControl } from 'react-native';
import { Dialog, Card, DataTable, Button, Searchbar, Portal, Surface, IconButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../../auth/components/header';
import isEmpty from '../../../state/validations/is-empty';
import { getAllUserpaymentAction, userpaymentFilter,resetFilter } from '../../../state/actions/userpaymentAction';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';

//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'User_Payment_Settlement_Id',
        label: 'User Payment Settlement ID'
    },
    {
        value: 'Order_Id',
        label: 'Order ID'
    },
    {
        value: 'Order_Payment_Status',
        label: 'Payment Status'
    },
    {
        value: 'User_Email_Id',
        label: 'User Email ID'
    },
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function UserpaymentDetails({ navigation }: { navigation: any }) {

    //userpayments contains the data from  GET API using state call
    const userpayment = useSelector(state => state.userpayment.userpayments.all);
    //userpaymentMenu is used to store the cache data in Menu select using state call
    let userpaymentMenu = useSelector(state => state.userpayment.userpaymentFilter.select);
    //userpaymentSearch is used to store the cache data in search using state call
    let userpaymentSearch = useSelector(state => state.userpayment.userpaymentFilter.search);
    const EmailID = useSelector(state => state.auth?.user);

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
      return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
      setRefreshing(true);
      wait(2000).then(() => setRefreshing(false));
    }, []);
    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);
    const [userpayments, setUserPayment] = React.useState([]);
    //API dispatch 
    const dispatch = useDispatch()
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    //Static formData
    React.useEffect(() => {
        if(isEmpty(userpayment)){
        let formData = {
            Records_Filter: "FILTER",
            Partner_Details_Id: EmailID?.Partner_Details_Id
        };
        
    handleSearch("");
        dispatch(getAllUserpaymentAction(formData))
        dispatch(userpaymentFilter('search', ''));
        dispatch(userpaymentFilter('select', 'All'));
    }else if(userpayment?.Is_Data_Exist==="0"){
        setUserPayment([])
    }else{
        setUserPayment(userpayment)
    }
    }, [userpayment]);

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });


    //sorting, pagination & search
    const [page, setPage] = React.useState(0);
    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, userpayments?.length);

    const [oldSearch, setOldSearch] = React.useState(0);

    const checkUserpaymentForTable = () => {

        if (userpaymentSearch) {
            try {
                if (userpaymentMenu === "All") {
                    return userpayments.filter(x => {
                        let keys = ["Partner_Details_Id", "User_Payment_Settlement_Id", "Order_Id", "Order_Payment_Status", "User_Email_Id"];
                        for (let i = 0; i < keys.length; i++) {
                            try {
                                if (x[keys[i]]?.toLowerCase()?.includes(userpaymentSearch?.toLowerCase())) {
                                    return true;
                                }
                            } catch (error) {
                                if (x[keys[i]]?.toString()?.toLowerCase()?.includes(userpaymentSearch?.toLowerCase())) {
                                    return true;
                                }
                            }
                        }
                    });

                } else {
                    return userpayments?.filter(x => (x[userpaymentMenu] ? x[userpaymentMenu]?.toLowerCase() : '')?.includes(userpaymentSearch?.toLowerCase()));
                }
            } finally {
                if (page > 0) {
                    if (oldSearch !== userpaymentSearch.length) {
                        setOldSearch(userpaymentSearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch !== userpaymentSearch.length) {
                    setOldSearch(userpaymentSearch.length);
                    setPage(0);
                }
            }
        }
        else {
            return userpayments
        }
    }

    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('User_Payment_Settlement_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
            return -1;
        }
        if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {

            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // View Dialog
    const [visible, setVisible] = React.useState(false);
    const [userpaymentData, setData] = React.useState();

    const hideDialog = () => setVisible(false);

    const handleChange = itemValue => {
        dispatch(userpaymentFilter('select', itemValue));
    };
    const handleSearch = value => {
        dispatch(userpaymentFilter('search', value));
    };

    const data = stableSort(
        checkUserpaymentForTable(), getComparator(order, orderBy))

    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
                <Portal>
                    <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto' }}>
                        <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                            <IconButton icon="close" size={20} onPress={() => setVisible(!visible)} />
                        </View>
                        <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                            <Text style={{ color: '#27B6CC' }}>View User Payment</Text>
                        </Dialog.Title>
                        <Dialog.ScrollArea>
                            <ScrollView contentContainerStyle={{ paddingHorizontal: 10 }}>
                                <Dialog.Content style={{ marginLeft: -30 }}>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>User Payment Settlement ID</Text></View>
                                        <View style={{ flex: 2.8 }}><Text style={{ color: '#27B6CC' }}>{userpaymentData?.User_Payment_Settlement_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Partner Details ID</Text></View>
                                        <View style={{ flex: 2.8 }}><Text>{userpaymentData?.Partner_Details_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Transaction ID</Text></View>
                                        <View style={{ flex: 2.8 }}><Text>{userpaymentData?.Transaction_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Order ID</Text></View>
                                        <View style={{ flex: 2.8 }}><Text>{userpaymentData?.Order_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Final Order Amount</Text></View>
                                        <View style={{ flex: 2.8 }}><Text>{userpaymentData?.Final_Order_Amount} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Order Line Item ID</Text></View>
                                        <View style={{ flex: 2.8 }}><Text>{userpaymentData?.Order_Line_Items[0]?.Order_Line_Item_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Order Line Item Amount</Text></View>
                                        <View style={{ flex: 2.8 }}><Text>{userpaymentData?.Order_Line_Items[0]?.Order_Line_Item_Amount} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Order Product ID</Text></View>
                                        <View style={{ flex: 2.8 }}><Text>{userpaymentData?.Order_Line_Items[0]?.Order_Product_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>ESI Commission Amount</Text></View>
                                        <View style={{ flex: 2.8 }}><Text>{userpaymentData?.Esi_Commission_Amount} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Order Payment Status</Text></View>
                                        <View style={{ flex: 2.8 }}><Text style={{ color: '#27B6CC' }}>{userpaymentData?.Order_Payment_Status} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>User Email ID</Text></View>
                                        <View style={{ flex: 2.8 }}><Text style={{ color: '#27B6CC' }}>{userpaymentData?.User_Email_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Created By</Text></View>
                                        <View style={{ flex: 2.8 }}><Text style={{ color: '#27B6CC' }}>{userpaymentData?.Created_By} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Modified By</Text></View>
                                        <View style={{ flex: 2.8 }}><Text style={{ color: '#27B6CC' }}>{userpaymentData?.Modified_By} </Text></View>
                                    </View>
                                </Dialog.Content>
                            </ScrollView>
                        </Dialog.ScrollArea>
                    </Dialog>
                </Portal>

                <ScrollView refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  colors={['#27B6CC']}
                  tintColor={'#27B6CC'}
                  title={"Pull to refresh"}
                  titleColor={"#27B6CC"}
                />
              }>
                    <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                        <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {navigation.navigate('PaymentsMainCenter')
                     handleSearch("")
                     dispatch(resetFilter())
                    }}>
                            Go Back
                        </Button>
                    </View>
                    <View style={{ flexDirection: "column" }}>
                        <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 20, paddingBottom: 10 }}>User Payment Settlements</Text>
                    </View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                        {Platform.OS !== 'web' ?
                            <View style={{ flex: dimensions <= 500 ? 2.5 : 0.5, marginLeft: 15 }}>
                                <EsiSearchDropdown
                                    style={[style.dropdown]}
                                    placeholderStyle={style.placeholderStyle}
                                    selectedTextStyle={style.selectedTextStyle}
                                    inputSearchStyle={style.inputSearchStyle}
                                    iconStyle={style.iconStyle}
                                    data={filterOptions}
                                    containerStyle={style.containerstyle}
                                    maxHeight={180}
                                    labelField="label"
                                    valueField="value"
                                    placeholder={!showDropDown ? 'All' : ''}
                                    value={userpaymentMenu}
                                    onFocus={() => setShowDropDown(true)}
                                    onBlur={() => setShowDropDown(false)}
                                    onUpdateValue={item => {
                                        setShowDropDown(false);
                                        handleChange(item.value)
                                    }}
                                />
                            </View>
                            :
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                                <Picker
                                    selectedValue={userpaymentMenu}
                                    style={{ height: 50, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', flexShrink: 1, backgroundColor: '#ffff' }}
                                    onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                                >
                                    <Picker.Item label="All" value="All" />
                                    <Picker.Item label="Payment Settlement ID" value="User_Payment_Settlement_Id" />
                                    <Picker.Item label="Order ID" value="Order_Id" />
                                    <Picker.Item label="Payment Status" value="Order_Payment_Status" />
                                    <Picker.Item label="User Email ID" value="User_Email_Id" />
                                </Picker>
                            </View>
                        }
                        <View style={{ flex: dimensions <= 500 ? 0.1 : 0.1 }} />
                        <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                            <Searchbar
                                style={{ borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', marginRight: 15, marginLeft: 10 }}
                                placeholder="Search"
                                onChangeText={(value) => handleSearch(value)}
                                value={userpaymentSearch}
                            />
                        </View>
                    </View>

                    <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                        <ScrollView horizontal={dimensions >= 700 ? false : true}>
                            <View style={style.mainbox}>
                                <Card>
                                    <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                                        <DataTable.Header style={style.databeHeader}>
                                            <DataTable.Title style={style.title} active={orderBy === 'User_Payment_Settlement_Id'}
                                                sortDirection={orderBy === 'User_Payment_Settlement_Id' ? order : 'ascending'}
                                                onPress={createSortHandler('User_Payment_Settlement_Id')}><Text style={style.titletext}>Payment Settlement ID</Text></DataTable.Title>

                                            <DataTable.Title style={style.title} active={orderBy === 'Partner_Details_Id'}
                                                sortDirection={orderBy === 'Partner_Details_Id' ? order : 'ascending'}
                                                onPress={createSortHandler('Partner_Details_Id')}><Text style={style.titletext}>Partner ID</Text></DataTable.Title>

                                            <DataTable.Title style={style.title} active={orderBy === 'Order_Id'}
                                                sortDirection={orderBy === 'Order_Id' ? order : 'ascending'}
                                                onPress={createSortHandler('Order_Id')}><Text style={style.titletext}>Order ID</Text></DataTable.Title>

                                            <DataTable.Title style={style.title} active={orderBy === 'Order_Payment_Status'}
                                                sortDirection={orderBy === 'Order_Payment_Status' ? order : 'ascending'}
                                                onPress={createSortHandler('Order_Payment_Status')}><Text style={style.titletext}>Payment Status</Text></DataTable.Title>

                                            <DataTable.Title style={style.title} active={orderBy === 'User_Email_Id'}
                                                sortDirection={orderBy === 'User_Email_Id' ? order : 'ascending'}
                                                onPress={createSortHandler('User_Email_Id')}><Text style={style.titletext}>User Email ID</Text></DataTable.Title>

                                            <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                        </DataTable.Header>
                                        {
                                            data.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((userpayment, index) => (
                                                <DataTable.Row style={style.databeBox} key={userpayment.User_Payment_Settlement_Id}>
                                                    <DataTable.Cell style={style.tablerow}>{userpayment.User_Payment_Settlement_Id}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>{userpayment.Partner_Details_Id}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>{userpayment.Order_Id}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>{userpayment.Order_Payment_Status}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>{userpayment.User_Email_Id}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>
                                                        {(permissions.User_Payments === "write") && <IconButton icon="square-edit-outline" size={20} color='#27B6CC' onPress={() => navigation.navigate('EditUserpayment', { data: userpayment })} />}
                                                        <IconButton icon="eye" size={20} color='#27B6CC' onPress={(e) => { setData(userpayment); setVisible(!visible) }} /></DataTable.Cell>
                                                </DataTable.Row>
                                            ))
                                        }
                                        {isEmpty(data) &&
                                            <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center",paddingTop:20 }}>
                                              No records found!
                                            </Text>
                                            <Image source={require('../../../assets/images/nodata.gif')}
                                             style={{ width: 300, height: 300,alignSelf:"center" }} />
                                           </View>
                                        }
                                        <DataTable.Pagination
                                            page={page}
                                            numberOfPages={Math.ceil(userpayments?.length / numberOfItemsPerPage)}
                                            onPageChange={page => setPage(to < data?.length ? page : 0)}
                                            label={userpayments?.length ? `${from + 1}-${to} of ${data?.length}` : `0-0`}
                                            showFastPaginationControls={to > data?.length ? false : true}
                                            numberOfItemsPerPageList={numberOfItemsPerPageList}
                                            numberOfItemsPerPage={numberOfItemsPerPage}
                                            onItemsPerPageChange={onItemsPerPageChange}
                                            selectPageDropdownLabel={'Rows per page'}
                                        />
                                    </DataTable>
                                </Card>
                            </View>
                        </ScrollView>
                    </View>
                </ScrollView>
        </Surface>

    );
}

const style = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontWeight: "bold",
        fontSize: 12,
        justifyContent: "center",
        textAlign: "center",
        width: 160
    },
    titletext: {
        fontWeight: "bold",
        fontSize: 14,
        color: 'black',
    },
    tablerow: {
        justifyContent: "center",
        textAlign: "center",
        width: 190
    },
    mainbox: {
        textAlign: 'center',
        margin: 15,
        flex: 1,
        justifyContent: 'space-between',
    },
    databeBox: {
        margin: 10,
        textAlign: 'center',
    },
    databeHeader: {
        margin: 10,
        textAlign: 'center',
    },
    dropdown: {
        height: 50,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    containerstyle: {
        width: 180
    },
});

export default UserpaymentDetails