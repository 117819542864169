import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollView, StyleSheet, TouchableOpacity, View, Dimensions } from "react-native";
import { Surface, Text } from "react-native-paper";
import ProductsF from "./productFilter";
import { getProductfilterAction } from "../../../state/actions/shoppers/productsActions";
import Header from "../header/header";
import isEmpty from "../../../state/validations/is-empty";

export default function AllCombinations(props) {
    const { navigation } = props;
    const searchKeyValue = props?.route?.params?.searchValue;
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
    });
    const dispatch = useDispatch();
    let product;
    let allproducts = useSelector((state) => state.allProducts.products.all.data);
    useEffect(() => {
        dispatch(getProductfilterAction(""));

    }, []);
    // Product  data assign
    if (allproducts) {
        product = allproducts.data
    }
    const [productfilter, setProductfilter] = useState([]);
    useEffect(() => {
        if (searchKeyValue) {
            if (!isEmpty(allproducts?.length)) {
                setProductfilter(allproducts?.filter(item => item.Product_Name.toLowerCase().includes(searchKeyValue.toLowerCase())));

            }
        }
    }, [product, searchKeyValue]);

    const handleProductClick = async (item) => {
        navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id })
    };

    const esiColor = useSelector(state => state.theme);

    const styles = StyleSheet.create({

        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            margin: 10,
            padding: 15,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 1, height: 2 },
            shadowOpacity: 5,
            shadowRadius: 7,
            elevation: 5,
            marginBottom: 10,
            marginTop: 35
        },

    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <ScrollView>

                <View style={styles.content}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: "#27b6cc" }}>Products</Text>
                        <TouchableOpacity onPress={(e) => navigation.navigate('Main', { screen: 'All', params: { page: 'products', filter: 'all' } })} style={{ position: 'absolute', right: 10 }}>
                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>View All</Text>
                        </TouchableOpacity>
                    </View>
                    <ScrollView contentContainerStyle={{ paddingVertical: 10 }} horizontal showsHorizontalScrollIndicator={false}>
                        <View style={{ flexDirection: 'row' }}>
                            {!isEmpty(productfilter) ? (
                                <ProductsF productfilter={productfilter} navigate={navigation.navigate} handleProductClick={handleProductClick} />
                            ) :
                                <ProductsF productfilter={[]} navigate={navigation.navigate} handleProductClick={handleProductClick} />
                            }
                        </View>
                    </ScrollView>
                </View>
            </ScrollView>
        </Surface>

    )

}
