import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';

export const PRODUCTS_DATA = "PRODUCTS_DATA";
export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_FAIL = "PRODUCTS_FAIL";

export const PRODUCT_GET_REQUEST = "PRODUCT_GET_REQUEST";
export const PRODUCT_GET_SUCCESS = "PRODUCT_GET_SUCCESS";
export const PRODUCT_GET_FAIL = "PRODUCT_GET_FAIL";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCTS_FILTER = "PRODUCTS_FILTER";

export const PRODUCT_BRANDS_REQUEST = "PRODUCT_BRANDS_REQUEST";
export const PRODUCT_BRANDS_SUCCESS = "PRODUCT_BRANDS_SUCCESS";
export const PRODUCT_BRANDS_FAIL = "PRODUCT_BRANDS_FAIL";

export const PRODUCT_CATEGORIES_REQUEST = "PRODUCT_CATEGORIES_REQUEST";
export const PRODUCT_CATEGORIES_SUCCESS = "PRODUCT_CATEGORIES_SUCCESS";
export const PRODUCT_CATEGORIES_FAIL = "PRODUCT_CATEGORIES_FAIL";

export const PRODUCT_SUBCATEGORIES_REQUEST = "PRODUCT_SUBCATEGORIES_REQUEST";
export const PRODUCT_SUBCATEGORIES_SUCCESS = "PRODUCT_SUBCATEGORIES_SUCCESS";
export const PRODUCT_SUBCATEGORIES_FAIL = "PRODUCT_SUBCATEGORIES_FAIL";

export const PRODUCT_COLOURS_REQUEST = "PRODUCT_COLOURS_REQUEST";
export const PRODUCT_COLOURS_SUCCESS = "PRODUCT_COLOURS_SUCCESS";
export const PRODUCT_COLOURS_FAIL = "PRODUCT_COLOURS_FAIL";

export const PRODUCT_ROLES_REQUEST = "PRODUCT_ROLES_REQUEST";
export const PRODUCT_ROLES_SUCCESS = "PRODUCT_ROLES_SUCCESS";
export const PRODUCT_ROLES_FAIL = "PRODUCT_ROLES_FAIL";

export const PRODUCT_MASTER_REQUEST = "PRODUCT_MASTER_REQUEST";
export const PRODUCT_MASTER_SUCCESS = "PRODUCT_MASTER_SUCCESS";
export const PRODUCT_MASTER_FAIL = "PRODUCT_MASTER_FAIL";

export const PRODUCT_SIZE_REQUEST = "PRODUCT_SIZE_REQUEST";
export const PRODUCT_SIZE_SUCCESS = "PRODUCT_SIZE_SUCCESS";
export const PRODUCT_SIZE_FAIL = "PRODUCT_SIZE_FAIL";

export const SIZES_REQUEST = 'SIZES_REQUEST';
export const SIZES_SUCCESS = 'SIZES_SUCCESS';
export const SIZES_FAIL = 'SIZES_FAIL';
/*=============================================================
                  Product Action
===============================================================*/
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
import shopperconfig from './shoppers/shopperconfig';
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
const cudheaders = config.nodeheadersCommon;
/*=============================================================
                  Get All Products Action
===============================================================*/

export const getAllProductAction = (formData: any, pagination = {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: PRODUCTS_REQUEST
    });
    meeapi.post(urlGenarator(`/Product_Management/Products_Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCTS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCTS_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: PRODUCTS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Get All Master Id
===============================================================*/
export const getAllMasters = () => async (dispatch: any) => {
  try {
    let formData = {
      Active: "",
      Brand: " ",
      Category: " ",
      Msrp: " ",
      Product_Master_Id: " ",
      Product_Name: " ",
      Records_Filter: " ",
      Sku: " ",
      Stock_Status: " ",
      Upc_Code: " "
    };
    dispatch({
      type: PRODUCT_MASTER_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Product_Master/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_MASTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_MASTER_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_MASTER_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Add Product Action
===============================================================*/

export const createProductAction = (formData: any, blobArrayPics: any, navigation: any, setErrors: any, setGlobalError: any, toast: any,key:any) => async (dispatch: any) => {
dispatch({
    type: PRODUCT_CREATE_REQUEST
  });
  axios.post(`${config.url}/Product_Management/Product_Create`,
    formData,
    {
      headers: headers
    }
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {

        const formValue = {
          search: "",
          search_by_filter: "",
          Records_Filter: "FILTER",
          Partner_Details_Id: formData.Partner_Details_ID
        };
        const pagination = { PageNo: 1, PageSize: 5, SortBy: "PRODUCT_ID", SortOrder: "DESC" }
        dispatch(getAllProductAction(formValue, pagination));
        let count = 1;
        let Product_Id = successResponse.Product_Id;
        let Created_By = formData.Created_By_Email;
        let imageCreate = []
        blobArrayPics?.filter(item => { return !(!item.preview) }).map(item => {
          imageCreate.push({
            "Product_Id": Product_Id,
            "Created_By": Created_By,
            "Image_Url": item.preview,
            "Is_Default": item.isdefault,
            "Sort": item.key
          });
        })
        axios.post(`${config.nodecudurl}/Product_Images/Create`, 
       imageCreate, 
       {
          headers: cudheaders
        })
          .then(response => {
            let data = response.data;
          }).catch(error => {
         })

         if(key){
          navigation.navigate('FarmerProductGet');
      
        }else{
          navigation.navigate('Products');
       
        }
        toast.show({ message: 'Product created Successfully.', type: 'info', duration: 3000, position: 'top' });
      } else {
        dispatch({
          type: PRODUCT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {  
    dispatch({
        type: PRODUCT_CREATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working."
      });
    })
};

/*=============================================================
                  Get All Brands Action
===============================================================*/

export const getAllBrands = (brand_id: any) => async (dispatch: any) => {
  try {
    let formData = {
      "search_by_filter": "Brand_Id",
      "search": brand_id,
      "Brand_Id": "",
      "Brand_Name": "",
      "Brand_Status": "",
      "Esi_Rating": "",
      "Records_Filter": "ALL",
      "Todays_Hot": "",
      "Top_Brands": ""
    };
    dispatch({
      type: PRODUCT_BRANDS_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Brand/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_BRANDS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_BRANDS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_BRANDS_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                  Get All partners Action
===============================================================*/

export const getAllRoles = () => async (dispatch: any) => {
  try {
    let formData = {
      Records_Filter: "",
      Role_Name: ""
    };
    dispatch({
      type: PRODUCT_ROLES_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Admin/Roles_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_ROLES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_ROLES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_ROLES_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                   Get All Categories
===============================================================*/
export const getAllCategories = (CID: any) => async (dispatch: any) => {
  try {
    let formData = {
      "search_by_filter": "Category_Id",
      "search": CID,
      "Category_Id": "",
      "Category_Name": "",
      "Records_Filter": "ALL",
      "Status": ""
    };
    dispatch({
      type: PRODUCT_CATEGORIES_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Category/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_CATEGORIES_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                Get All Sub Categories
===============================================================*/

export const getAllSubCategories = (CID: any) => async (dispatch: any, pagination: any) => {
  try {
    let formData =
    {
      "search_by_filter": "Category_Id",
      "search": CID,
      "Category_Id": "",
      "Sub_Category_Id": "",
      "Category_Name": "",
      "Sub_Category_Name": "",
      "Records_Filter": "ALL"
      

    };
    dispatch({
      type: PRODUCT_SUBCATEGORIES_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Sub_Category_Management/sub_categories_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_SUBCATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_SUBCATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_SUBCATEGORIES_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                Get All Sub Categories
===============================================================*/
export const getAllColours = (Product_Master_id: any) => async (dispatch: any) => {
  try {
    let formData = {

      "search_by_filter": "",
      "search": "",
      "Color": "",
      "Product_Color_Variant_Id": "",
      "Product_Master_Id": Product_Master_id,
      "Records_Filter": "FILTER",
      "Status": ""
    };
    dispatch({
      type: PRODUCT_COLOURS_REQUEST
    });
    let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/Product_Management/Color_Variant_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_COLOURS_SUCCESS,
          payload: []
        });
      } else {

        dispatch({
          type: PRODUCT_COLOURS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_COLOURS_FAIL,
      payload: err
    });
  }
};

export const getSizes = (Product_Master_id: any) => async (dispatch: any) => {
  try {
    let formData = {
      "search": "",
      "Product_Category": "",
      "Product_Master_Id": Product_Master_id,
      "Product_Size_Id": "",
      "Records_Filter": "FILTER",
      "Size": "",
      "Size_Type": "",
      "Status": ""
    };
    dispatch({
      type: SIZES_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Product_Management/Product_Size_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SIZES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SIZES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SIZES_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                Get All Master Id
===============================================================*/
export const getAllSizes = () => async (dispatch: any) => {
  try {
    let formData = {
    };
    dispatch({
      type: PRODUCT_SIZE_REQUEST
    });
    let { data, status } = await axios.post(`${shopperconfig.nodePartnerUrl}/Product_Management/Product_Size_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PRODUCT_SIZE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PRODUCT_SIZE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PRODUCT_MASTER_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Update Product Action
===============================================================*/
export const updateProductAction = (formData: any, navigation: any, toast: any,EmailID:any,key:any) => async (dispatch: any) => {
  dispatch({
    type: PRODUCT_UPDATE_REQUEST
  });

  axios.post(`${config.nodecudurl}/Product_Management/Product_Update`,
    formData, {
    headers: cudheaders
  })
    .then(response => {
      let successResponse = response.data;

      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PRODUCT_UPDATE_SUCCESS
        });
        const formValue = {
          search: '',
          Records_Filter: 'FILTER',
          search_by_filter:"",
          Partner_Details_Id:EmailID.Partner_Details_Id
        };
        const pagination = { PageNo: 1, PageSize: 5, SortBy: "PRODUCT_ID", SortOrder: "DESC" }
        dispatch(getAllProductAction(formValue, pagination));
        if(key){
          navigation.navigate('FarmerProductGet');
      
        }else{
          navigation.navigate('Products');
       
        }
        toast.show({ message: 'Product Updated Successfully.', type: 'info', duration: 3000, position: 'top' });

      } else {
        const errors = {};
        if (successResponse.Product_Name) {
          errors.Product_Name = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Product_Name: { key: successResponse.Product_Name ? successResponse.Product_Name : '', message: successResponse.Product_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Product_Name) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: PRODUCT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_CREATE_FAIL,
        payload: "Please Try Sometime Later.Currently Server is Not Working"
      });
    })
};

// Product Image Upload Action
export const singleImageUploadCreate = (formData: any, callBackUploadImage: any, key: any) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.url}/Product_Management/Multiple_Product_Image_Upload?filename=BTS&file_extension=jpg&filetype=Image`,
      formData,
      {
        headers: headers,
        transformRequest: (d) => d,
      }
    );
    if (data) {
      if (data.File_Url) {
        callBackUploadImage(true, data.File_Url, key);
      }
      else {
        callBackUploadImage(false, data, key);
      }
    }
  } catch (err) {
    callBackUploadImage(false, "", key);
  }
};
// Product Image Delete

export const singleImageUploadUpdate = (formValues: any, productId: any, modifiedBy: any, key: any, callBackfunction: any) => async dispatch => {

  axios.post(`${config.url}/Multi_Product_Image/Update?filename=BTS&file_extension=jpg&filetype=Image&Modified_By=` + modifiedBy + `&Is_Default=0&Image_Sort=` + key + `&product_id=` + productId,
    formValues, {
    headers: headers,
    transformRequest: (d) => d,
  })
    .then(response => {
      let data = response.data;
      if (data.File_Url) {
        callBackfunction(true,data.File_Url);
      }
      else {
        callBackfunction(false);
      }
    }).catch(error => {  
      callBackfunction(false);
    })
}
//save cache data
export const productsdata = (value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: PRODUCTS_DATA,
      // fType: fType,
      value: value,
    });
  }
}




// Product Image Delete
export const imageDelete = (input: any, callBackfunction: any, key: any) => async (dispatch: any) => {
  let payload = {
    "Product_Image_Id": input
  };
  axios.post(`${config.url}/Multi_Product_Image/Delete`, payload, {
    headers: headers
  })
    .then(response => {
      let data = response.data;
      callBackfunction(input, data.Response_Status == "Success" ? true : false, key);
    })
}

export const getProductImages = (productId: any, callBackfunction: any) => async (dispatch: any) => {
  let payload = {
    "Product_ID": productId,
    "Product_Image_Id": "",
    "Records_Filter": "FILTER",
    "Status": ""
  };
  axios.post(`${shopperconfig.nodePartnerUrl}/Multi_Product_Image/Get`, payload,
    {
      headers: nodeheaders
    }
  )
    .then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === "0" && data.Response_Status == "Success") {
        callBackfunction([])
      }
      else if (data.Success_Response.Is_Data_Exist === "1" && data.Success_Response.Response_Status == "Success") {
        callBackfunction(data.results);
      } else {
        callBackfunction([]);
      }
    })
}
export const imageDefaultUpdate = (productId: any, modifiedBy: any, Product_Image_Id: any, callBackfunction: any) => async (dispatch: any) => {

  axios.put(`${config.url}/Multi_Product_Image/Update_Image`,
    {
      "Is_Default": 1,
      "Modified_By": modifiedBy,
      "Product_Id": productId,
      "Product_Image_Id": Product_Image_Id,
    }
    , {
      headers: headers
    })
    .then(response => {
      let data = response.data;
      if (data.Response_Status === "Success") {
        callBackfunction(true);
      }
      else {
        callBackfunction(false);
      }
    }).catch(error => {
      callBackfunction(false);
    })
}
/*=============================================================
                  Get All Coupons Action
===============================================================*/

export const deleteProductAction = (formData: any) => async (dispatch: any) => {
  try {
    let { data, status } = await axios.post(`${config.url}/Products/Delete`, formData, {
      headers: headers
    });
    if (data) {
      toast('Product deleted successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      const formData = {
        search: '',
        Records_Filter: 'ALL'
      };
      const pagination = { PageNo: 1, PageSize: 5, SortBy: "PRODUCT_ID", SortOrder: "DESC" }

      dispatch(getAllProductAction(formData, pagination));
    }
  } catch (err) { }
};
/*=============================================================
                  Get Single Product Action
===============================================================*/

export const getSingleProduct = (formData: any, pagination = {}, callBackProductData: any) => async (dispatch: any) => {
  try {
    meeapi.post(urlGenarator(`/Product_Management/Products_Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data) {
        callBackProductData(true, data.results[0]);
      }
    })
  } catch (err) {
    callBackProductData(false);

  }
};


// Filter 
export const productFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: PRODUCTS_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//Search 
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: PRODUCTS_FILTER,
      fType: "search",
      value: "",
    });
  }
}


export const subcategoryActions = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Sub_Category_Management/sub_categories_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)

    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};
