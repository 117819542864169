import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView, RefreshControl, Platform } from 'react-native';
import Header from '../../../Services/header/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getUserserviceByIdAction, ServiceInvoiceAction, updateUserservicesAction } from '../../../../state/actions/Services/userservicesActions';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import { TouchableOpacity } from 'react-native-gesture-handler';
import EsiDatePicker from '../../../../components/custom/date/date';

const StatusOption = [

  {
    value: 'CANCELLED',
    label: 'CANCELLED'
  },
  {
    value: 'REJECTED',
    label: 'REJECTED'
  },
  {
    value: 'NEW',
    label: 'NEW'
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED'
  },
  {
    value: 'INITIATED',
    label: 'INITIATED'
  },
  {
    value: 'IN-PROCESS',
    label: 'IN-PROCESS'
  },
];
const StatusserviceOption = [
  {
    value: 'PENDING',
    label: 'PENDING'
  },
  {
    value: 'ACCEPTED',
    label: 'ACCEPTED'
  },
  {
    value: 'CANCELLED',
    label: 'CANCELLED'
  },
  {
    value: 'REJECTED',
    label: 'REJECTED'
  },
  {
    value: 'NEW',
    label: 'NEW'
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED'
  },
  {
    value: 'INITIATED',
    label: 'INITIATED'
  },
  {
    value: 'IN-PROCESS',
    label: 'IN-PROCESS'
  },
];
export default function EditUserservice(props) {
  //navigation is used for navigation between screens
  const { navigation } = props
  const dispatch = useDispatch();

  //toast for popup after successful API calls.
  const toast = useToast();
  const esiColor = useSelector(state => state.theme);

  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  //isSubmitted contains the data from Update API using state call
  const isSubmited = useSelector(state => state.userservice.userserviceUpdate.isLoading);
  const [data, setData] = useState([]);
  const [itemprint, setItemprint] = React.useState('');
  const ID = props?.route?.params?.id;
  const [item, setItem] = React.useState('');
  const [servicedata, setServiceData] = useState([]);
  //partners contains the data from  GET API using state call
  let userservice = useSelector(state => state.userservice.userservices.all);
  const EmailID = useSelector(state => state.auth?.user);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const callBackData = (data: any) => {
    setData(data)
  }
  const [invoiceDetails, setDetails] = useState();
  const updateStatesInvoice = (status, data) => {
    if (status) {
      setDetails(data);
      printToFile(data);
    }
  }
  const updateStatesprint = (status, data) => {
    if (status) {
      setDetails(data);
      printToFileprint(data);
    }
  }

  //dispatch is used to perform the API action
  useEffect(() => {
    if (ID !== servicedata) {
      let formData = {
        "search": "",
        "search_by_filter": "",
        "Partner_Employee_Id": "",
        "Partner_Detail_Id": "",
        "User_Service_request_Status": "",
        "User_Service_request_Id": ID,
        "User_Email_Id": "",
        "Partner_Service_Id": "",
        "City_Id": "",
        "Service_Request_Status_From_Partner": "",
        "Records_Filter": "FILTER"
      };
      setServiceData(ID)
      dispatch(getUserserviceByIdAction(formData, callBackData));

    }
  }, [ID, servicedata]);


  React.useEffect(() => {
    if (item) {
      let formData1 = {
        "User_Service_Request_Id": data?.User_Service_Request_Id,

      }
      dispatch(ServiceInvoiceAction(formData1, updateStatesInvoice))
    }
  }, [item]);
  React.useEffect(() => {
    if (itemprint) {
      let formData1 = {
        "User_Service_Request_Id": data?.User_Service_Request_Id,

      }
      dispatch(ServiceInvoiceAction(formData1, updateStatesprint))
    }
  }, [itemprint]);

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [statusDropDown, setStatusDropDown] = useState("");
  const [countryField, setCountryField] = React.useState();

  /*====================================
          Handler
  ====================================*/
  const values = {
    User_Service_Request_Id: data?.User_Service_Request_Id,
    User_Email_ID: data?.User_Email_Id,
    Partner_Detail_Id: EmailID?.Partner_Details_Id,
    User_Cancel_Reason: data?.User_cancel_Reason ? data?.User_cancel_Reason : "",
    Status: data?.Status ? data.Status : "",
    Service_Start_Date: data?.Service_Start_Date ? data.Service_Start_Date : "",
    Service_Required_Date: data?.Service_Required_Date ? data.Service_Required_Date : "",
    Service_Requested_Date: data?.Service_Request_date ? data.Service_Request_date : "",
    Service_Request_Total_Time_In_Hours: data?.Service_Request_Total_Time_in_Hours ? data.Service_Request_Total_Time_in_Hours : "",
    Service_Request_Total_Time_In_Days: data?.Service_Request_Total_Time_in_Days ? data.Service_Request_Total_Time_in_Days : "",
    Service_Request_Total_Tax: data?.Service_Request_Total_Tax ? data.Service_Request_Total_Tax : "",
    Service_Request_Total_Price_Before_Tax: data?.Service_Request_Total_Price_Before_tax ? data.Service_Request_Total_Price_Before_tax : "",
    Service_Request_Status_From_Partner: data?.Service_Request_Status_From_Partner ? data.Service_Request_Status_From_Partner : "",
    Service_Price: data?.Service_Price ? data.Service_Price : "",
    Service_End_Date: data?.Service_End_Date ? data.Service_End_Date : "",
    Seervice_Request_Total_Price_After_Tax: data?.Service_Request_Total_Price_After_Tax ? data.Service_Request_Total_Price_After_Tax : "",
    SGST_Percentage: data?.SGST_Percentage ? data.SGST_Percentage : "",
    SGST: data?.SGST ? data.SGST : "",
    Price_Per_Hour: data?.Price_Per_Hour ? data.Price_Per_Hour : "",
    Price_Per_Day: data?.Price_Per_Day ? data.Price_Per_Day : "",
    Payment_Status: data?.Payment_Status ? data.Payment_Status : "",
    Payment_Reference_Id: data?.Payment_Reference_Id ? data.Payment_Reference_Id : "",
    Partner_Total_Share: data?.Partner_Total_share ? data.Partner_Total_share : "",
    Partner_Service_Id: data?.Partner_Service_Id ? data?.Partner_Service_Id : "",
    Modified_By: EmailID.Email_Id,
    Meepaisa_Commission: data?.Meepaisa_Commission ? data.Meepaisa_Commission : "",
    IGST_Percentage: data?.IGST_Percentage ? data.IGST_Percentage : "",
    IGST: data?.IGST ? data.IGST : "",
    Discount_Amount: data?.Discount_Amount ? data.Discount_Amount : "",
    Cashback_Amount: data?.Cash_Back_Amount ? data.Cash_Back_Amount : "",
    CashBack_Settled_To_Users: data?.CashBack_Settled_To_Users > 0 ? 1 : 0,
    CGST_Percentage: data?.CGST_Percentage ? data.CGST_Percentage : "",
    CGST: data?.CGST ? data.CGST : "",
    Service_Typa: data?.Service_Type ? data.Service_Type : "",
    Any_Extra_Charges: data?.Any_Extra_Charges ? data.Any_Extra_Charges : "",
    After_Discount_Service_Price: data?.After_Discount_Amount_Service_Price ? data.After_Discount_Amount_Service_Price : "",
    Service_Person_Name: data?.Service_Person_Name ? data.Service_Person_Name : "",
    Service_Mobile_Number: data?.Service_Mobile_Number ? data.Service_Mobile_Number : "",
    User_Service_Request_Status: data?.User_Service_Request_Status ? data.User_Service_Request_Status : "",
    Service_Person_Email_Id: data?.Service_Person_Email_Id ? data.Service_Person_Email_Id : "",

  }
  //Form validation
  const Form_Validation = Yup.object().shape({
    Service_Person_Name: Yup.string().required('Service Person Name is Required.'),
    Service_Request_Status_From_Partner: Yup.string().required('Service Request Status From Partner is Required.'),
    User_Service_Request_Status: Yup.string().required('User Service Request Status is Required.'),
    Service_Mobile_Number: Yup.string().required("Mobile Number is required."),
    Service_Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Start Date greater than or equal to today.").required("Start Date is required."),
    Service_End_Date: Yup.date().when("Service_Start_Date", (Service_Start_Date, schema) => Service_Start_Date && schema.min(Service_Start_Date, "End Date can't be before Start Date.")).required("End Date is required."),
    Service_Person_Email_Id: Yup.string()
      .email('Please provide valid Email Address.')
      .max(255)
      .required('Email Address is required.'),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};


    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(updateUserservicesAction(formValues, props.navigation, toast));

    }
  }
  const getInvoice = (data) => {
    return `
            <html>
            <head>
        <style>
        .grid-container {
        display: grid;
        grid-template-columns: auto auto;
        }.hr.solid {
        border-top: 5px solid #2EE1F4;
        }
        table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        }
        td{
        border: 1px solid #2EE1F4;
        text-align: right;
        padding: 8px;
        }
        th {
        border: 1px solid #2EE1F4;
        text-align: right;
        background-color: #2EE1F4;
        padding: 8px;
        }
        </style>
        
                <meta charset="utf-8">
                <title>Invoice</title>
                <script src="script.js"></script>
            </head>
            <body onclick="window.print()" >
                <header>
            <center>
                    <h1 style="color:#2EE1F4;">Invoice</h1>
            </center>
                    <address contenteditable>
            <div class="grid-container">
            <img src="https://www.meepaisa.com/static/media/logo.faf9c90f.png" width="100" height="80" style="background-color: rgb(39, 182, 204);">
            <span style="text-align:right">

            <p>Order Id: `+ data?.Partner_Service_Id + `</p>

            <p>Order Date: `+ data?.Service_Date + `</p>        
            </span>
            </div>
            <hr class="solid">
            <div class="grid-container">
            <span>
            <h4>Billing Address:</h4>
            <p> `+ data?.User_Address?.Name + `</p>
            <p> `+ data?.User_Address?.AddressDrToSt + `</p>
            <p>`+ data?.User_Address?.City + `,` + data?.User_Address?.State + `,` + data?.User_Address?.Country + `</p>
            <p>`+ data?.User_Address?.Phone_Number + `</p>
            <p>`+ data?.User_Address?.Email + `</p>
            </span>
            <span style="text-align:right">
            <h4>Shipping Address:</h4>
            <p> `+ data?.Partner_Address?.Name + `</p>
            <p> `+ data?.Partner_Address?.AddressDrToSt + `</p>
            <p>`+ data?.Partner_Address?.City + `,` + data?.Partner_Address?.State + `,` + data?.Partner_Address?.Country + `</p>
            <p>`+ data?.Partner_Address?.PhoneNumber + `</p>
            <p>`+ data?.Partner_Address?.email + `</p>
            </span>
            </div>
            <hr class="solid">
            <div class="grid-container">
            <span>
            <h4>Sold By:</h4>
            <p>`+ data?.Partner_Address.Name + `</p>
                <p>`+ data?.Partner?.AddressDrToSt + `</p>
                <p>`+ data?.Partner_Address?.City + `,` + data?.Partner_Address?.State + `,` + data?.Partner_Address?.Country + `</p>
                <p>`+ data?.Partner_Address?.GST + `</p>
                <p>`+ data?.Partner_Address?.Pan + `</p>

            </span>
            <span style="text-align:right">
         
            </span>
            </div>
            <hr class="solid">
            <table>
        <tr>
        <th>SI No</th>
        <th style="width:70%">Description</th>
        <th>rate</th>
        <th>Amount In Words</th>
        <th>Amount</th> 
        <th>IGST Percentage</th>
        <th>CGST Percentage</th>
        </tr>

        <tr> 
        <td>1</td>
        <td>`+ data?.Payment_Details?.Service_Name + `</td>
        <td>`+ data?.Payment_Details?.Expected_Service_fee + `</td>
        <td>`+ data?.Payment_Details?.AmountInWords + `</td>
        <td>`+ data?.Payment_Details?.Final_Amount + `</td>
        <td>`+ data?.Payment_Details?.IGST_Percentage + `</td>
        <td>`+ data?.Payment_Details?.CGST_Percentage + `</td>
        </tr>
        
        <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        </tr>
       
        </table>
                    </address>
                    </header>
                <aside>
                    <div contenteditable>
                        <p>Note: This is computer generated Invoice and signature is not required.</p>
                    </div>
                </aside>
            </body>
        </html>
`;
  }
  const printToFile = async (data) => {
    const html = getInvoice(data);
    if (Platform.OS == 'web') {
      const pW = window.open('', '', 'height=500, width=500')
      pW?.document.write(html);
      pW?.document.close();
    }
    else {
      try {
        const { uri } = await Print.printToFileAsync({ html });
        await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
      } catch (error) {
      }
    }
  }
  const getInvoiceprint = (data) => {
    return `
            <html>
            <head>
        <style>
        .grid-container {
        display: grid;
        grid-template-columns: auto auto;
        }.hr.solid {
        border-top: 5px solid #2EE1F4;
        }
        table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        }
        td{
        border: 1px solid #2EE1F4;
        text-align: right;
        padding: 8px;
        }
        th {
        border: 1px solid #2EE1F4;
        text-align: right;
        background-color: #2EE1F4;
        padding: 8px;
        }
        </style>
        
                <meta charset="utf-8">
                <title>Invoice</title>
                <script src="script.js"></script>
            </head>
            <body onclick="window.print()" >
                <header>
            <center>
                    <h1 style="color:#2EE1F4;">Invoice</h1>
            </center>
                    <address contenteditable>
            <div class="grid-container">
            <img src="https://www.meepaisa.com/static/media/logo.faf9c90f.png" width="100" height="80" style="background-color: rgb(39, 182, 204);">
            <span style="text-align:right">

            <p>Order Id: `+ data?.Partner_Service_Id + `</p>

            <p>Order Date: `+ data?.Service_Date + `</p>        
            </span>
            </div>
            <hr class="solid">
            <div class="grid-container">
            <span>
            <h4>Billing Address:</h4>
            <p>`+ data?.User_Address?.Name + `</p>
            <p> `+ data?.User_Address?.AddressDrToSt + `</p>
            <p>`+ data?.User_Address?.City + `,` + data?.User_Address?.State + `,` + data?.User_Address?.Country + `</p>
            <p>`+ data?.User_Address?.Phone_Number + `</p>
            <p>`+ data?.User_Address?.Email + `</p>
            </span>
            <span style="text-align:right">
            <h4>Shipping Address:</h4>
            <p>`+ data?.Partner_Address?.Name + `</p>
            <p> `+ data?.Partner_Address?.AddressDrToSt + `</p>
            <p>`+ data?.Partner_Address?.City + `,` + data?.Partner_Address?.State + `,` + data?.Partner_Address?.Country + `</p>
            <p>`+ data?.Partner_Address?.PhoneNumber + `</p>
            <p>`+ data?.Partner_Address?.email + `</p>
            </span>
            </div>           
            <hr class="solid">
                    </address>
                    </header>
                <aside>
                    <div contenteditable>
                        <p>Note: This is computer generated Invoice and signature is not required.</p>
                    </div>
                </aside>
            </body>
        </html>
`;
  }
  const printToFileprint = async (data) => {
    const html = getInvoiceprint(data);
    if (Platform.OS == 'web') {
      const pW = window.open('', '', 'height=500, width=500')
      pW?.document.write(html);
      pW?.document.close();
    }
    else {
      try {
        const { uri } = await Print.printToFileAsync({ html });
        await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
      } catch (error) {
      }
    }
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 15
    },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      left: 22,
      top: 0.5,
      color: esiColor.SIconColor,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {userservice &&
          <Formik initialValues={{ ...values }} enableReinitialize={true} onSubmit={onSubmit} validationSchema={Form_Validation}>
            {(props) => (
              <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => { props.resetForm(); navigation.navigate('Newuserservices') }}>
                    Go Back
                  </Button>
                </View>
                <View pointerEvents={!(permissions.Partner_Bank === "write") ? 'none' : 'auto'}>
                  <Card style={{
                    width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                    marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                    backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                  }}>
                    <Card.Content>
                      <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                        Edit User Service
                      </Text>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Price Per Hour:   {props.values.Price_Per_Hour} </Text>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Price Per Day:  {props.values.Price_Per_Day}  </Text>
                        </View>

                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          {/*  Bank Name Input Field  with Validation */}
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Service_Person_Name && props.errors.Service_Person_Name)}
                            label='Service Person Name'
                            name='Service_Person_Name'
                            mode='outlined'
                            value={props.values.Service_Person_Name}
                            onChangeText={props.handleChange('Service_Person_Name')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_Person_Name && props.errors.Service_Person_Name)}>
                            <ErrorMessage name="Service_Person_Name" />
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: "1.2%" }}>
                          {/* Bank Beneficiary Name Input Field  with Validation */}

                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            label="Mobile Number"
                            mode='outlined'
                            onChangeText={props.handleChange('Service_Mobile_Number')}
                            left={<TextInput.Affix text="+91" />}
                            value={props.values.Service_Mobile_Number}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_Mobile_Number && props.errors.Service_Mobile_Number)}>
                            {props.touched.Service_Mobile_Number && props.errors.Service_Mobile_Number}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          <View style={styles.container}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                              Service Request Status From Partner
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Service_Request_Status_From_Partner && props.errors.Service_Request_Status_From_Partner) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              data={StatusOption}
                              maxHeight={StatusOption.length > 1 ? 250 : 200}
                              search={StatusOption.length > 1 ? true : false}
                              labelField="label"
                              valueField="value"
                              dropdownPosition="auto"
                              value={props.values.Service_Request_Status_From_Partner}
                              name="Service_Request_Status_From_Partner"
                              onFocus={() => setStatusDropDown(true)}
                              onBlur={() => setStatusDropDown(false)}
                              onUpdateValue={item => {
                                props.setFieldValue('Service_Request_Status_From_Partner', item.value)
                                setStatusDropDown(false);
                              }}
                            />

                            <HelperText type="error" visible={Boolean(props.touched.Service_Request_Status_From_Partner && props.errors.Service_Request_Status_From_Partner)}>
                              <ErrorMessage name="Service_Request_Status_From_Partner" />
                            </HelperText>
                          </View>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          <View style={styles.container}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.User_Service_Request_Status && props.errors.User_Service_Request_Status) && { color: '#b00020' }]}>
                              User Service Request Status
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Service_Request_Status_From_Partner && props.errors.Service_Request_Status_From_Partner) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              data={StatusserviceOption}
                              maxHeight={StatusserviceOption.length > 1 ? 250 : 200}
                              search={StatusserviceOption.length > 1 ? true : false}
                              labelField="label"
                              valueField="value"
                              dropdownPosition="auto"
                              value={props.values.User_Service_Request_Status}
                              name="User_Service_Request_Status"
                              onFocus={() => setStatusDropDown(true)}
                              onBlur={() => setStatusDropDown(false)}
                              onUpdateValue={item => {
                                props.setFieldValue('User_Service_Request_Status', item.value)
                                setStatusDropDown(false);
                              }}
                            />

                            <HelperText type="error" visible={Boolean(props.touched.User_Service_Request_Status && props.errors.User_Service_Request_Status)}>
                              <ErrorMessage name="User_Service_Request_Status" />
                            </HelperText>
                          </View>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>

                          <EsiDatePicker

                            style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                            error={Boolean(props.touched.Service_Start_Date && props.errors.Service_Start_Date)}
                            name={'Service_Start_Date'}
                            label={'Service Start Date'}
                            value={props.values.Service_Start_Date}
                            valueUpdate={item => {
                              props.setFieldValue('Service_Start_Date', item)
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_Start_Date && props.errors.Service_Start_Date)}>
                            <ErrorMessage name="Service_Start_Date" />
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>

                          <EsiDatePicker
                            error={Boolean(props.touched.Service_End_Date && props.errors.Service_End_Date)}

                            style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                            name={'Service_End_Date'}
                            label={'Service End Date'}
                            value={props.values.Service_End_Date}
                            valueUpdate={item => {
                              props.setFieldValue('Service_End_Date', item)
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_End_Date && props.errors.Service_End_Date)}>
                            <ErrorMessage name="Service_End_Date" />
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, margin: 'auto' }}>
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Service_Person_Email_Id && props.errors.Service_Person_Email_Id)}
                            label="Resource Email ID"
                            mode='outlined'
                            value={props.values.Service_Person_Email_Id}
                            onChangeText={props.handleChange("Service_Person_Email_Id")}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_Person_Email_Id && props.errors.Service_Person_Email_Id)}>
                            {props.touched.Service_Person_Email_Id && props.errors.Service_Person_Email_Id}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                        <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} textColor={esiColor.itemButtenColor} mode="contained" onPress={props.handleSubmit}>
                          Save
                        </Button>
                      </View>
                      {(userservice?.User_Service_Request_Status != "CANCELLED" && userservice?.User_Service_Request_Status != "RETURNED") &&

                        <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5, width: 130 }}
                          onPress={() => { setItem(data.User_Service_Request_Id) }}>
                          <View  >
                            <Text style={{ color: esiColor.itemColor }} >Download Invoice</Text>
                          </View>
                        </TouchableOpacity>
                      }

                      {(userservice?.User_Service_Request_Status != "CANCELLED" && userservice?.User_Service_Request_Status != "RETURNED") &&

                        <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5, width: 130 }}
                          onPress={() => { setItemprint(data.User_Service_Request_Id) }}>
                          {/* onPress={printToFile}> */}
                          <View >
                            <Text style={{ color: esiColor.itemColor }} >Print</Text>
                          </View>
                        </TouchableOpacity>
                      }
                    </Card.Content>
                  </Card>
                </View>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface>
  );
};

