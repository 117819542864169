import axios from 'axios';
import shopperconfig from '../shoppers/shopperconfig';
import config from '../config';
import { meeapi } from '../node-actions/meeapi';

export const FARMER_ADDRESS_REQUEST = "FARMER_ADDRESS_REQUEST";
export const FARMER_ADDRESS_SUCCESS = "FARMER_ADDRESS_SUCCESS";
export const FARMER_ADDRESS_FAIL = "FARMER_ADDRESS_FAIL";

export const FARMER_ADDRESS_GET_REQUEST = "FARMER_ADDRESS_GET_REQUEST";
export const FARMER_ADDRESS_GET_SUCCESS = "FARMER_ADDRESS_GET_SUCCESS";
export const FARMER_ADDRESS_GET_FAIL = "FARMER_ADDRESS_GET_FAIL";

export const FARMER_ADDRESS_CREATE_REQUEST = "FARMER_ADDRESS_CREATE_REQUEST";
export const FARMER_ADDRESS_CREATE_SUCCESS = "FARMER_ADDRESS_CREATE_SUCCESS";
export const FARMER_ADDRESS_CREATE_FAIL = "FARMER_ADDRESS_CREATE_FAIL";

export const FARMER_ADDRESS_UPDATE_REQUEST = "FARMER_ADDRESS_UPDATE_REQUEST";
export const FARMER_ADDRESS_UPDATE_SUCCESS = "FARMER_ADDRESS_UPDATE_SUCCESS";
export const FARMER_ADDRESS_UPDATE_FAIL = "FARMER_ADDRESS_UPDATE_FAIL";

export const FARMER_ADDRESS_DELETE_REQUEST = "FARMER_ADDRESS_DELETE_REQUEST";
export const FARMER_ADDRESS_DELETE_SUCCESS = "FARMER_ADDRESS_DELETE_SUCCESS";
export const FARMER_ADDRESS_DELETE_FAIL = "FARMER_ADDRESS_DELETE_FAIL";

export const FARMER_ADDRESS_FILTER = "FARMER_ADDRESS_FILTER";
const nodeheaders = shopperconfig.nodeheadersCommon;
/*=============================================================
                  Get All Address Action
===============================================================*/

const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}

export const getAllAddressAction = (formData: any, pagination = {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: FARMER_ADDRESS_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Address/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: FARMER_ADDRESS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: FARMER_ADDRESS_SUCCESS,
          payload: data
        });

      }
    })
  } catch (err) {
    dispatch({
      type: FARMER_ADDRESS_FAIL,
      payload: err
    });
  }
};

export const getAllCartAddressAction =
  (updateStates: any, formData: any) => async (dispatch: any) => {
    try {
      let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/Partner_Address/Get`, formData, {
        headers: nodeheaders
      });
      if (data) {
        if (data.Is_Data_Exist === '0') {
          updateStates([], false);
        } else {
          updateStates(data.results, false);
        }
      }
    } catch (error) {
    }
  };
/*=============================================================
                  Address Create Action
===============================================================*/

export const createAddressAction = (formData: any, navigation: any, toast: any) => async (dispatch: any) => {
  dispatch({
    type: FARMER_ADDRESS_CREATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Partner_Address/Create`, formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: FARMER_ADDRESS_CREATE_SUCCESS
        });
        navigation.navigate('FarmerAddressDetails')
        toast.show({ message: 'Address created successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          Partner_Details_Id: formData.Partner_Details_Id
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "PARTNER_ADDRESS_ID",
          SortOrder: "ASC"
        }
        dispatch(getAllAddressAction(formValue, pagination))
      } else {
        const errors = {};
        dispatch({
          type: FARMER_ADDRESS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FARMER_ADDRESS_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};


/*=============================================================
                  Address Update Action
===============================================================*/

export const updateAddressAction = (formData: any, navigation: any, toast: any) => async (dispatch: any) => {
  dispatch({
    type: FARMER_ADDRESS_UPDATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Partner_Address/Update`, formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: FARMER_ADDRESS_UPDATE_SUCCESS
        });
        navigation.navigate('FarmerAddressDetails')
        toast.show({ message: 'Address updated successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          Partner_Details_Id: formData.Partner_Details_Id
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "PARTNER_ADDRESS_ID",
          SortOrder: "ASC"
        }
        dispatch(getAllAddressAction(formValue, pagination))
      } else {
        const errors = {};
        dispatch({
          type: FARMER_ADDRESS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FARMER_ADDRESS_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                  Address Delete
===============================================================*/

export const deleteAddressAction = (formData: any, toast: any) => async (dispatch: any) => {
  try {
    let { data, status } = await axios.post(`${config.nodecudurl}/Partner_Address/Delete`, formData, {
      headers: cudheaders
    });
    if (data) {
      toast.show({ message: 'Address deleted successfully.', type: 'info', duration: 3000, position: 'top' });
      const formValue = {
        Records_Filter: "FILTER",
        Partner_Details_Id: formData.Partner_Details_Id
      };
      dispatch(getAllAddressAction(formValue))
    }
  } catch (err) {
  }
};

// Filter 
export const addressFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: FARMER_ADDRESS_FILTER,
      fType: fType,
      value: value,
    });
  }
}

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: FARMER_ADDRESS_FILTER,
      fType: "search",
      value: "",
    });
  }
}

export const getAddressByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    dispatch({
      type: FARMER_ADDRESS_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Address/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results[0])
    }
  } catch (err) {
    dispatch({
      type: FARMER_ADDRESS_FAIL,
      payload: err
    });
  }
};