import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const GIFTVENDOR_REQUEST = "GIFTVENDOR_REQUEST";
export const GIFTVENDOR_SUCCESS = "GIFTVENDOR_SUCCESS";
export const GIFTVENDOR_FAIL = "GIFTVENDOR_FAIL";
export const GIFTVENDOR_UPDATE_REQUEST = "GIFTVENDOR_UPDATE_REQUEST";
export const GIFTVENDOR_UPDATE_SUCCESS = "GIFTVENDOR_UPDATE_SUCCESS";
export const GIFTVENDOR_UPDATE_FAIL = "GIFTVENDOR_UPDATE_FAIL";
export const GIFTVENDORS_FILTER = "GIFTVENDORS_FILTER";
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
/*=============================================================
                 Get All Gift vendors Action
===============================================================*/
const nodeheaders = shopperconfig.nodeheadersCommon;
export const getAllGiftVendorsAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: GIFTVENDOR_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Gift/Vendor_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: GIFTVENDOR_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFTVENDOR_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFTVENDOR_FAIL,
      payload: err
    });
  }
};
// Filter 
export const giftvendorsFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: GIFTVENDORS_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//serach

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: GIFTVENDORS_FILTER,
      fType: "search",
      value: "",
    });
  }
}
/*=============================================================
                Update gift vendor Action
===============================================================*/

export const updateGiftVendorAction = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: GIFTVENDOR_UPDATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Gift/Vendor_Update`,
    formData, {
    headers: cudheaders
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: GIFTVENDOR_UPDATE_SUCCESS
        });
        const formdata = {
          "search_by_filter": "",
          "search": "",
          "Partner_Details_Id":formData.Partner_Details_Id,
          "Records_Filter": "FILTER",
          "Vendor_Id": "",
          "Vendor_Name": ""
        };
        dispatch(getAllGiftVendorsAction(formdata))
        navigation.navigate('GiftVendorsGet')
        toast.show({ message: 'Gift Vendor Updated Successfully.', type: 'info', duration: 3000, position: 'top' });

      } else {
        dispatch({
          type: GIFTVENDOR_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GIFTVENDOR_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

export const singlevendorAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Gift/Vendor_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data?.results[0])

    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};