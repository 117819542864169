import {
    TRANSACTIONS_REQUEST,
    TRANSACTIONS_SUCCESS,
    TRANSACTIONS_FAIL,

    TRANSACTION_GET_REQUEST,
    TRANSACTION_GET_SUCCESS,
    TRANSACTION_GET_FAIL,

    TRANSACTION_UPDATE_REQUEST,
    TRANSACTION_UPDATE_SUCCESS,
    TRANSACTION_UPDATE_FAIL,

    TRANSACTIONS_FILTER,

} from '../actions/transactionhistoryAction'

const initialState = {
    transactions: { all: [], error: '', isLoading: false },
    transaction: { transaction: {}, error: '', isLoading: false },
    transactionUpdate: { transaction: {}, error: '', isLoading: false },
    transactionFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case TRANSACTIONS_REQUEST:
            return { ...state, transactions: { all: state.transactions.all, error: '', isLoading: true } };
        case TRANSACTIONS_SUCCESS:
            return { ...state, transactions: { all: action.payload, error: '', isLoading: false } };
        case TRANSACTIONS_FAIL:
            return { ...state, transactions: { all: [], error: action.payload, isLoading: false } };

        case TRANSACTION_GET_REQUEST:
            return { ...state, transaction: { transaction: {}, error: '', isLoading: true } };
        case TRANSACTION_GET_SUCCESS:
            return { ...state, transaction: { transaction: action.payload, error: '', isLoading: false } };
        case TRANSACTION_GET_FAIL:
            return { ...state, transaction: { transaction: {}, error: action.payload, isLoading: false } };

        case TRANSACTION_UPDATE_REQUEST:
            return { ...state, transactionUpdate: { transaction: {}, error: '', isLoading: true } };
        case TRANSACTION_UPDATE_SUCCESS:
            return { ...state, transactionUpdate: { transaction: state.transactionUpdate.transaction, error: '', isLoading: false } };
        case TRANSACTION_UPDATE_FAIL:
            return { ...state, transactionUpdate: { transaction: {}, error: action.payload, isLoading: false } };

        case TRANSACTIONS_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, transactionFilter: { search: state.transactionFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, transactionFilter: { select: state.transactionFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}