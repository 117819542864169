import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Card, DefaultTheme, HelperText, Surface, Text, TextInput } from 'react-native-paper';
import { Dimensions, ScrollView, StyleSheet, View, RefreshControl } from 'react-native';
import { getAllAddressforBiddingAction } from '../../../../state/actions/biddingProductsAction';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import Header from '../../../auth/components/header';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AddPartnerBiddingb2bProductQuotesAction, getUserb2bproductsBiddingQuotesAction } from '../../../../state/actions/b2buserProductsNewBiddingQuoteAction';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const StatusOption = [
  {
    value: 'INITIATED',
    label: 'INITIATED'
  },
  {
    value: 'PROCESSING',
    label: 'PROCESSING'
  },
  {
    value: 'CANCELLED',
    label: 'CANCELLED'
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED'
  }
];
const userb2bNewbiddingRequestDetails = (props: any) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);
  const ID = props.route?.params?.id;
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.b2bbiddinguserproductnewquotes.biddingb2bCreate.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  const [paginationforaddress, setPaginationForAdress] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_ADDRESS_ID", SortOrder: "DESC" });
  const [ESICOMMISIONAMOUNT, setESICOMMISIONAMOUNT] = useState('');
  const ESICommisionCalculations = (data: any) => {
    setESICOMMISIONAMOUNT(data)
  }
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "DESC" });
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (!(permissions.Employees === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);
  const [addressdata, setAddresData] = useState([]);
  const [userbiddata, setUserBidData] = useState([]);
  const callBackAddressData = (data: any) => {
    setAddresData(data)
  }
  const callBackUserBidData = (data: any) => {
    setUserBidData(data)
  }
  let addressdatalist = []
  if (addressdata) {
    if (addressdata[0]) {
      for (let i = 0; i < addressdata.length; i++) {
        addressdatalist.push({
          label: addressdata[i].Location,
          value: addressdata[i].Partner_Address_Id
        })
      }
    }
  }

  React.useEffect(() => {
    let addressForm = {
      "search_by_filter": "",
      "search": "",
      "Partner_Address_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    }
    let formData = {
      "search_by_filter": "",
      "search": "",
      "Records_Filter": "FILTER",
      "Partner_Detail_Id": EmailID.Partner_Details_Id,
      "B2B_User_Products_Bidding_Id": ID,
      "Buyer_Partner_Detail_Id": "",
      "Seller_Partner_Detail_Id": "",
    }
    dispatch(getAllAddressforBiddingAction(addressForm, callBackAddressData, paginationforaddress))
    dispatch(getUserb2bproductsBiddingQuotesAction(formData, callBackUserBidData, pagination))
  }, [permissions]);
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  // Alert Popup
  const [addressshowDropDown, setAddressShowDropDown] = useState(false);

  //form reset
  const [reload, setReload] = React.useState(false);

  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 15
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },

    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    phonetextInput: {
      flex: 1
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 99,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }} >
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        {!reload &&
          <Formik
            enableReinitialize={true}
            initialValues={{
              "Advance_Token_Amount": '',
              "Bidding_Status": userbiddata.Bidding_Status,
              "Created_By": EmailID.Email_Id,
              // "Delivery_Fee": biddingsQuotedata.Delivery_Fee,
              // "Delivery_Managed_By": biddingsQuotedata,
              // "Estimated_Delivery_Date": biddingsQuotedata,
              // "No_Of_Delivery_Days": biddingsQuotedata,
              "Partner_Bidding_Available_Product_Id": userbiddata.Partner_Bidding_Available_Products_Id,
              "Partner_Comments": '',
              "Partner_Detail_Id": EmailID?.Partner_Details_Id,
              "Partner_Location": '',
              "Quotation_Amount": '',
              "Status": userbiddata.Status,
              "B2B_User_Products_Bidding_Id": userbiddata.B2B_User_Products_Bidding_Id
            }}

            validationSchema={Yup.object().shape({
              Quotation_Amount: Yup.number()
                .positive()
                .min(Yup.ref('Advance_Token_Amount'), 'Quotation Amount must be greater than or equal to Advance Token Amount.')
                .required('Quotation Amount is required.'),
              // Quotation_Amount: Yup.number().positive().moreThan(Yup.ref('Advance_Token_Amount'), 'Require Quotation Amount greater than or  equal to Advance Token Amount.').required(' Quotation Amount is required.'),
              "User_Products_Bidding_Id": userbiddata?.User_Products_Bidding_Id,
              "Delivery_Fee": userbiddata?.Delivery_Fee,
              "No_Of_Delivery_Days": userbiddata?.No_Of_Delivery_Days,
              Advance_Token_Amount: Yup.number().required('Advance Token Amount is required.'),
              // Quotation_Amount: Yup.number().required('Quotation Amount is required.'),
              Partner_Comments: Yup.string().required('Partner Comments is required.'),
              Partner_Location: Yup.string().required('Partner Address is required.'),
            })}

            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              if (values) {
                let formData = JSON.parse(JSON.stringify(values));
                dispatch(AddPartnerBiddingb2bProductQuotesAction(formData, navigation))
              }
            }
            }
          >

            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}>
                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm();
                      setReload(true);
                      navigation.navigate('B2BUserNewBiddingProductQuotes')
                    }}
                  >
                    Go Back
                  </Button>
                </View>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", paddingBottom: 20, color: esiColor.brandFontColor }}>
                      User Request Details
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Bidding Reqest ID:</Text><Text style={{ color: esiColor.GBFColor }}> {userbiddata.B2B_User_Products_Bidding_Id}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>User Email ID:</Text><Text style={{ color: esiColor.GBFColor }}> {userbiddata.Created_By}</Text>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5, }}>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Product Name:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.Product_Name}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Product ID:</Text><Text style={{ color: esiColor.GBFColor }}> {userbiddata.Bidding_Product_Id}</Text>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>QTY:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.Quantity}  {userbiddata?.Measurement_Type ? "(" + userbiddata?.Measurement_Type + ")" : null}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Address:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.City}, {userbiddata.User_Pincode}</Text>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Bid Date:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.Created_Date}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Bid Expiry Date: </Text><Text style={{ color: esiColor.itemColor }}>{userbiddata.Bidding_Expiry_Date}</Text>
                      </View>
                      {values.Quotation_Amount != '' ?
                        <View>
                          <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                            <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                              <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.Text }}>ESI Commision:</Text>
                              <Text style={{ color: esiColor.itemColor }}> {userbiddata.Esi_Commision_Type == "P" ? userbiddata.Esi_Commision + '%' : userbiddata.Esi_Commision + 'Rs'}
                              </Text>
                            </View>
                            <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                              <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.Text }}>Total commision Amount: </Text>
                              <Text style={{ color: esiColor.itemColor }}>{ESICOMMISIONAMOUNT}</Text>
                            </View>
                          </View>
                        </View> : null}
                    </View>
                  </Card.Content>
                </Card>
                <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.CBColor }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.Text }}>
                      Quotation Form
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                          style={{ backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}
                          onBlur={handleBlur}
                          onChangeText={item => {
                            let PPrice = userbiddata.Esi_Commision_Type == "P" ? (item / 100 * userbiddata.Esi_Commision).toFixed(2) : userbiddata.Esi_Commision
                            setFieldValue("Quotation_Amount", item);
                            ESICommisionCalculations(PPrice)
                          }}
                          label="Quotation Amount"
                          name="Quotation_Amount"
                          keyboardType='numeric'
                          value={values.Quotation_Amount}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}>
                          {touched.Quotation_Amount && errors.Quotation_Amount}
                        </HelperText>
                      </View>

                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                          style={{ backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Advance_Token_Amount')}
                          label="Advance Token Amount"
                          name="Advance_Token_Amount"
                          value={values.Advance_Token_Amount}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}>
                          {touched.Advance_Token_Amount && errors.Advance_Token_Amount}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                        <TextInput
                          style={{ backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Partner_Comments && errors.Partner_Comments)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Partner_Comments')}
                          label="Partner Comments"
                          name="Partner_Comments"
                          value={values.Partner_Comments}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Partner_Comments && errors.Partner_Comments)}>
                          {touched.Partner_Comments && errors.Partner_Comments}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Partner_Location && errors.Partner_Location) && { color: '#b00020' }]}>
                            Partner Address
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Partner_Location && errors.Partner_Location) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={addressdatalist}
                            labelField="label"
                            valueField="value"
                            maxHeight={addressdatalist.length > 1 ? 250 : 200}
                            search={addressdatalist.length > 1 ? true : false}
                            searchPlaceholder={'Select Partner Address'}
                            value={values.Partner_Location}
                            onFocus={() => setAddressShowDropDown(true)}
                            onBlur={() => setAddressShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('Partner_Location', item.value)
                              setAddressShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Partner_Location && errors.Partner_Location)}>
                            {touched.Partner_Location && errors.Partner_Location}
                          </HelperText>
                        </View>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                          style={{ backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Delivery_Fee && errors.Delivery_Fee)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Delivery_Fee')}
                          label="Delivery Fee"
                          name="Delivery_Fee"
                          value={values.Delivery_Fee}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Delivery_Fee && errors.Delivery_Fee)}>
                          {touched.Delivery_Fee && errors.Delivery_Fee}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                          style={{ backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.No_Of_Delivery_Days && errors.No_Of_Delivery_Days)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('No_Of_Delivery_Days')}
                          label="No Of Delivery Days"
                          name="No_Of_Delivery_Days"
                          value={values.No_Of_Delivery_Days}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.No_Of_Delivery_Days && errors.No_Of_Delivery_Days)}>
                          {touched.No_Of_Delivery_Days && errors.No_Of_Delivery_Days}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                        <Text style={[styles.dropdownlabel, Boolean(touched.Bidding_Status && errors.Bidding_Status) && { color: '#b00020' }]}>
                          Bidding Status
                        </Text>
                        <TextInput
                          style={{ backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Bidding_Status && errors.Bidding_Status)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Bidding_Status')}
                          label="Bidding Status"
                          name="Bidding_Status"
                          value={values.Bidding_Status}
                          mode="outlined"
                          disabled
                        />
                      </View>
                    </View>
                  </Card.Content>
                  <View style={{ marginHorizontal: 30 }}>
                    <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                    <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                      Save
                    </Button>
                  </View>
                </Card>
              </View>
            )}
          </Formik>}
      </ScrollView>
    </Surface>
  );
};

export default userb2bNewbiddingRequestDetails;