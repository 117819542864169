import * as React from 'react';
import { Dimensions, Image, Platform, SafeAreaView, ScrollView, StyleSheet, Linking, TouchableOpacity, View } from 'react-native';
import { Avatar, Button, Dialog, IconButton, Portal, Surface, Switch, TouchableRipple } from 'react-native-paper';
import { Text } from '../../../Themed';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import Modal from "react-native-modal";
import DropdownAlert from 'react-native-dropdownalert';
import { Appearance, useColorScheme } from 'react-native';
import { useToast } from '../../../custom/react-native-paper-toast/src';
import { Feather, MaterialCommunityIcons, AntDesign, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { deleteAccountAction, loginOut } from '../../../../state/actions/authActions';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SignIN from '../../../../screens/auth/signin';
import VersionCheck from 'expo-react-native-version-checker';
import { PartnerProfileActions, updateOpenStoreAction } from '../../../../state/actions/partnerprofileActions';
import isEmpty from '../../../../state/validations/is-empty';
const window = Dimensions.get("window");

export default function ServicesNavBar(props: any) {
  let colorScheme = useColorScheme();


  // DEFAULT LIGHT THEME
  const DefaultTheme = {
    dark: false,
    colors: {
      primary: '#27b6cc',
      background: 'rgb(242, 242, 242)',
      card: 'rgb(255, 255, 255)',
      text: 'rgb(28, 28, 30)',
      border: 'rgb(216, 216, 216)',
      notification: 'rgb(255, 59, 48)',
    },
  };

  // DEFAULT DARK THEME
  const DarkTheme = {
    dark: true,
    colors: {
      primary: '#27b6cc',
      background: 'rgb(1, 1, 1)',
      card: 'rgb(18, 18, 18)',
      text: 'rgb(229, 229, 231)',
      border: 'rgb(39, 39, 41)',
      notification: 'rgb(255, 69, 58)',
    },
  };
  const { navigation } = props;
  const dispatch = useDispatch();
  const toast = useToast();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const username = useSelector(state => state.auth?.user?.Partner_Name ? state.auth.user.Partner_Name : "");
  const userPic = useSelector(state => state.auth?.user?.Profile_Pic_Path ? state.auth.user.Profile_Pic_Path : require("./../../../../assets/images/icons/user.svg"));

  // const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  // const userPic = useSelector(state => state.auth.user?.Profile_Pic_Path ? { uri: state.auth.user?.Profile_Pic_Path } : require("../../assets/images/icons/userlogo.png"));
  // const username = useSelector(state => state.auth.user?.User_Name)
  const user = useSelector((state) => state.auth.user);
  const [visible, setVisible] = React.useState(false);
  const esiColor = useSelector(state => state.theme);
  const hideDialog = () => setVisible(false);
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  const [notifications, setNotifications] = React.useState();
  const [notificationToken, setNotificationToken] = React.useState("");
  const [needed, setNeeded] = React.useState("")
  const [version, setVersion] = React.useState()
  const [storeurl, setStoreurl] = React.useState('')
  const [currentState, setCurrentState] = React.useState("SignUp");
  const [modal, setModal] = React.useState(false);
  const [versionModel, setSersionModel] = React.useState(false);
  const _handleVersion = () => {
    setSersionModel(true);
  };
  if (Platform.OS != 'web') {
    VersionCheck('IN').getLatestVersion()
      .then(latestVersion => {
        setVersion(latestVersion)
      });
    VersionCheck('IN').needUpdate()
      .then(async res => {
        setNeeded(res?.isNeeded)
        setStoreurl(res.storeUrl)
        if (currentState == "SignUp") {
          if (res.isNeeded) {
            setModal(true)
          }
        } else if (currentState == "verify") {
          setModal(false)
        }
      });
  }
  const versionCheck = () => {
    if (currentState == "verify") {
      setModal(false)
    }
  }

  const signInModalResponse = (status: any) => {
    setModalVisible(!isModalVisible);
  };
  React.useEffect(() => {

    (async () => {
      if (Platform.OS != "web") {
        const ExpoPushToken = await AsyncStorage.getItem('ExpoPushToken');
        setNotificationToken(ExpoPushToken || "");
      }

    })();
  }, []);
  //partner open actions
  React.useEffect(() => {
    if (user.Partner_Details_Id) {
      const formData = {
        Partner_Details_Id: user.Partner_Details_Id
      };
      dispatch(PartnerProfileActions(formData, callBackOpengetData));
    }
  }, [user]);
  const callBackOpengetData = (data: any) => {
    if (data) {
      { data.Is_Partner_Profile_Update == '0' && setVisible(!visible) }
      setIsSwitchOn(data.Is_Open == '1' ? true : false)
    }
  }
  const setCallBackOpengetData = (data: any) => {
    if (data) {
      const formData = {
        Partner_Details_Id: data.PARTNER_DETAILS_ID
      };
      dispatch(PartnerProfileActions(formData, callBackOpengetData));

    }
  }
  const callBackOpenData = (data: any) => {
    if (data) {
      setCallBackOpengetData(data)
    }
  }
  const onToggleSwitch = () => {
    if (!isEmpty(isSwitchOn)) {
      let formData = {
        "Partner_Details_Id": user.Partner_Details_Id,
        "Banner_Path": "",
        "Description": "",
        "Dob": "",
        "Email_Id": "",
        "Esi_Comission": "",
        "Esi_Ratting": "",
        "Esi_Reviews": "",
        "Established_Date": "",
        "Extra_Cashback": "",
        "First_Name": "",
        "GST": "",
        "GST_Image": "",
        "Gender": "",
        "Id_Proof_Number": "",
        "Id_Proof_Type": "",
        "Is_Email_Verified": "",
        "Is_Open": !isSwitchOn ? 1 : 0,
        "Is_Partner_Profile_Update": "",
        "Is_Popular": "",
        "Land_Phone": "",
        "Last_Name": "",
        "Logo_Path": "",
        "Marketing_Agreed": "",
        "Mobile_Number": "",
        "Modified_By": "",
        "Name": user.Partner_Name,
        "PAN": "",
        "Pan_Card_Image": "",
        "Physical_Routing_Path": "",
        "Profile_Pic_Path": "",
        "Short_Description": "",
        "Status": "",
        "T_And_C_Agreed": "",
        "Todays_Hot": "",
        "Top_Stores": "",
        "User_Tips": "",
        "Website_Url": "",
        "Whats_Hot": "",
        "Partner_User_Details_ID": user.Partner_User_Details_Id
      }
      dispatch(updateOpenStoreAction(formData, callBackOpenData));
      setIsSwitchOn(!isSwitchOn);
    }
  }
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  const callBackData = (data: any) => {
    if (data) {
      setNotifications(data.Count)
    }
  }
  const [logoutLoading, setLogoutLoading] = React.useState(false);

  const setLogoutDisabile = (status: any, message: any) => {
    setLogoutLoading(false);
    if (!status) {
      toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
    }
  }
  const _handleSignOut = () => {
    setLogoutLoading(true);
    dispatch(loginOut(navigation, notificationToken, user, setLogoutDisabile, toast));
  };
  // Tost message code.
  let dropDownAlertRef = React.useRef();
  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }

  const [deleteModel, setDeleteModel] = React.useState(false);
  const _handleDelete = () => {
    setDeleteModel(true);
  };

  const styles = StyleSheet.create({
    profile_name: {
      fontSize: 20,
      color: esiColor.Text
    },
    profile_email: {
      fontSize: 14,
      color: esiColor.Text
    },
    item_styles: {
      flexDirection: 'row',
      alignItems: 'center'
    },
  });

  const customModels = () => {
    return (
      <View>
        <Modal animationIn={"slideInDown"}
          // deviceWidth={Dimensions.width}
          // deviceHeight={Dimensions.height}
          style={{ alignItems: 'center' }} isVisible={isModalVisible}>
          <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: 'white' }}>

            <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

          </View>
        </Modal>

        {/* delete Account */}

        <Modal animationIn={"slideInDown"}
          // deviceWidth={Dimensions.width}
          // deviceHeight={Dimensions.height}
          style={{ alignItems: 'center' }} isVisible={deleteModel}>
          <View style={{ flex: 1, width: - 50, maxWidth: 500, minWidth: 300, minHeight: 250, maxHeight: 350, borderRadius: 10, backgroundColor:esiColor.BackgroundColor }}>

            <View style={{ alignItems: 'flex-end' }}>
              <TouchableRipple style={{ width: 36 }} onPress={() => { setDeleteModel(false) }} >
              <Avatar.Icon color={esiColor?.Text} style={{ backgroundColor: esiColor.BackgroundColor}} size={36} icon="close" />
             </TouchableRipple>
            </View>
            <View style={{ flexDirection: "column", marginTop: -20, marginHorizontal: 10 }} >
              <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Image source={require("../../../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />

              </View>
              <Text style={{ color: esiColor?.Text }}>
                We are sad to see you go, will make sure for better performance.
                We will keep your data in our records for security reasons and you can also reactivate your account in the future.
              </Text>
              <Image source={require("../../../../assets/images/GIF/delete_account.gif")} style={{ width: "100%", height: 150, alignSelf: "center", resizeMode: 'contain' }} />

            </View>
            <View style={{ width: "100%", flexDirection: 'row', justifyContent: 'space-around', margin: "auto" }}>

            <Button textColor={esiColor.itemButtenColor} style={{ marginRight: 10, backgroundColor:esiColor?.SIconColor }} mode="contained" onPress={() => {
                setDeleteModel(false);
                dispatch(deleteAccountAction(navigation, user, setLogoutDisabile));
              }}>
                Delete
              </Button>
              <Button textColor={esiColor.itemButtenColor}  mode="contained" style={{ backgroundColor: esiColor?.SIconColor }} onPress={() => {
                 setDeleteModel(false);
              }}>
                Cancel
              </Button>

            </View>

          </View>
        </Modal>
      </View>
    )
  }
  const updateModels = () => {

    return (
      <View>

        {/* delete Account */}

        <Modal animationIn={"slideInDown"}
          // deviceWidth={Dimensions.width}
          // deviceHeight={Dimensions.height}
          style={{ alignItems: 'center' }} isVisible={versionModel}>
          <View style={{ flex: 1, width: - 50, maxWidth: 500, minWidth: 300, minHeight: 250, maxHeight: 350, borderRadius: 10,  backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>

            <View style={{ alignItems: 'flex-end' }}>
              <TouchableRipple style={{ width: 36 }} onPress={() => { setSersionModel(false) }} >
                <Avatar.Icon color={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
              </TouchableRipple>
            </View>
            <View style={{ flexDirection: "column", marginTop: -20, marginHorizontal: 10 }} >
              <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Image source={require("../../../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />

              </View>
              <Text>
                Your are using an old version of the app. Get your hands on the latest version of the app and enjoy the best of delivery services at your fingertips.it will take only a few moments.
              </Text>
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, paddingTop: 10
                }}
              >
                <Text >Provider </Text>
                <Text style={{ color: '#27b6cc' }}>{"EasyStepIn IT Service Private Limited"}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10
                }}
              >
                <Text >Version </Text>
                <Text style={{ color: '#27b6cc' }}>{version}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10
                }}
              >
                <Text >Size </Text>
                <Text style={{ color: '#27b6cc' }}>{"8.97MB"}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10
                }}
              >
                <Text >Category </Text>
                <Text style={{ color: '#27b6cc' }}>{"Shopping"}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10
                }}
              >
                <Text>Languages </Text>
                <Text style={{ color:esiColor.itemButtenColor}}>{"English"}</Text>
              </View>
              {/* <Image source={require("../../../../assets/images/GIF/delete_account.gif")} style={{ width: "100%", height: 150, alignSelf: "center", resizeMode: 'contain' }} /> */}

            </View>
            <View style={{ width: "100%", flexDirection: 'row', justifyContent: 'space-evenly', margin: "auto" }}>

              <Button mode="contained" style={{ backgroundColor: '#27b6cc' }}textColor={esiColor.itemButtenColor} onPress={() => {
                versionCheck()
                setSersionModel(false);
                setCurrentState("verify")
              }}>
                Cancel
              </Button>

              <Button textColor={esiColor.itemButtenColor} style={{ marginRight: 10, backgroundColor: '#27b6cc' }} mode="contained" onPress={() => {
                setSersionModel(false);
                setModal(false);
                Linking.openURL(storeurl)
              }}>
                Update
              </Button>

            </View>

          </View>
        </Modal>
      </View>
    )
  }
  if (colorScheme == "light") {
    return (
      <Surface style={{ flex: 1 ,backgroundColor:esiColor.BackgroundColor}}>
        <View style={{ backgroundColor:esiColor.BackgroundColor, padding: 10, flexDirection: 'row', paddingTop: Platform.OS == 'web' ? 2 : 25 }}>

          {!isAuthenticated ?
            <View style={{ justifyContent: 'center' }}>
              <TouchableOpacity onPress={toggleModal} >
                <Text style={{ color: esiColor.itemColor, fontSize: 18, fontWeight: 'bold' }}>Log In</Text>
              </TouchableOpacity>
            </View>
            :
            <>
              <TouchableOpacity onPress={() => navigation.navigate('EmployeProfile',  { page: 'Services'})}>
                <Image source={userPic} style={{ height: 50, width: 50, borderRadius: 50, backgroundColor:esiColor.BackgroundColor, marginTop: 10 }}></Image>
              </TouchableOpacity>
              <Text style={[styles.profile_name, { alignSelf: 'flex-end', flex: 1, paddingLeft: 7, marginBottom: 10 ,color:esiColor.itemColor}]}>Hi, {username}</Text>
            </>
          }

        </View>
        <ScrollView>
          <Portal>
            <Dialog visible={visible} onDismiss={hideDialog} style={{ width: (dimensions >= 500) ? 400 : 270, maxHeight: (dimensions >= 500) ? 500 : 350, marginLeft: 'auto', marginRight: 'auto', }}>
              <View style={{ alignItems: 'flex-end' }}>
                <IconButton
                  icon="close"
                  size={20}
                  color={esiColor?.SIconColor}
                  onPress={() => setVisible(!visible)}
                />
              </View>
              <Dialog.Title style={{ fontSize: 20, marginTop: -20 }}>
                <Text style={{ color: esiColor.itemColor, fontSize: 20, textAlign: 'center' }}>Please Update you're Profile</Text>
              </Dialog.Title>
              <Dialog.ScrollArea>
                <ScrollView >
                  <Dialog.Content>
                    <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5 }}
                      onPress={() => { navigation.navigate('PartnerProfile'), setVisible(!visible) }}>
                      <View>
                        <Text>Update</Text>
                      </View>
                    </TouchableOpacity>

                  </Dialog.Content>
                </ScrollView>
              </Dialog.ScrollArea>
            </Dialog>
          </Portal>

          <SafeAreaView style={{ flexDirection: 'column' }}>
            <View style={{ padding: 10 }}>
              <ScrollView showsVerticalScrollIndicator={false}>
                {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <View style={{ flexDirection: 'row', margin: 10 }}>
                    <View style={{ flexDirection: 'column' }}>
                      {isSwitchOn == false ? <MaterialCommunityIcons name="account-off" size={24} color={esiColor?.SIconColor} /> : <MaterialCommunityIcons name="account" size={24} color={esiColor?.SIconColor} />}
                    </View>
                    <View style={{ flexDirection: 'row', flex: 1, padding: 5, justifyContent: 'space-between' }}>
                      {isSwitchOn == false ? <Text style={{ fontSize: 16 }}>Store Close</Text> : <Text style={{ fontSize: 16,color:esiColor.itemColor }}>Store Open</Text>}
                      <Switch value={isSwitchOn}
                        trackColor={{ true: esiColor?.SIconColor, false: 'red' }}
                        style={{ height: 24 }}
                        onValueChange={() => {
                          if (isSwitchOn == false) {
                            setModal(true);
                          }
                          onToggleSwitch()
                        }
                        } />
                    </View>
                  </View>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('ServicesDashboard')} >
                    <FontAwesome name="th-large" size={16} style={{ margin: 11 }} color={esiColor?.SIconColor}></FontAwesome>
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Dashboard</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('PartnerProfile', { pages: 'Services' })}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Company Profile</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('AddressGet', {page:'Services'})}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>My Address</Text>
                  </TouchableOpacity>
                }

                {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Employees', {page:'Services'})}>
                    <Feather name="user" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Partner Employees</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('WalletHistory',{page:'Services'})}>
                    <FontAwesome name="money" size={24} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>My Wallet</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Newuserservices')}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>User Services</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Partnerservice')}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Partner Service</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('EmployeNewuserservices')}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Employe Services</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('PartnerBiddingServicesMain')}>
                    <MaterialCommunityIcons name="offer" size={24} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Bidding Services</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={_handleSignOut}>
                    <Feather name="power" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>LogOut</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={_handleDelete}>
                    <FontAwesome name="user-times" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor}></FontAwesome>
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Delete Account</Text>
                  </TouchableOpacity>
                }
                {Platform.OS == 'web' ? null :
                  <View style={{ flexDirection: 'row', margin: 10 }}>
                    <TouchableOpacity style={styles.item_styles} disabled={logoutLoading} onPress={_handleVersion}>
                      <View style={{ flexDirection: 'column' }}>
                        <MaterialIcons name="info" size={24} color={esiColor?.SIconColor} />
                        {needed && needed !== "0" ?
                          <View
                            style={{
                              position: 'absolute',
                              backgroundColor: 'red',
                              width: 16,
                              height: 16,
                              borderRadius: 15 / 2,
                              right: 5,
                              top: 9,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            <Text
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: "#FFFFFF",
                                fontSize: 8,
                              }}>
                              {"!"}
                            </Text>
                          </View>
                          : null}
                      </View>
                      <View style={{ flexDirection: 'column', padding: 5 }}>
                        <Text style={{ fontWeight: 'bold' ,color:esiColor?.Text}}>About</Text>
                      </View>
                    </TouchableOpacity>
                  </View>}

              </ScrollView>
            </View>

          </SafeAreaView>
        </ScrollView>
        {updateModels()}
        {customModels()}
      </Surface>
    )

  }
  else {
    return (
      <Surface style={{ flex: 1 ,backgroundColor:esiColor.BackgroundColor}}>
        <DropdownAlert ref={ref => { if (ref) { dropDownAlertRef = ref; } }} zIndex={1} showCancel={true} />

        <View style={{ backgroundColor:esiColor.BackgroundColor, padding: 10, flexDirection: 'row', paddingTop: Platform.OS == 'web' ? 2 : 25 }}>

          {!isAuthenticated ?
            <View style={{ justifyContent: 'center' }}>
              <TouchableOpacity onPress={toggleModal} >
                <Text style={{ color: esiColor.Text, fontSize: 18, fontWeight: 'bold' }}>Log In</Text>
              </TouchableOpacity>
            </View>
            :
            <>
              <TouchableOpacity onPress={() => navigation.navigate('ProfileDetails')}>
                <Image source={userPic} style={{ height: 50, width: 50, borderRadius: 50, backgroundColor:esiColor.BackgroundColor, marginTop: 10 }}></Image>
              </TouchableOpacity>
              <Text style={[styles.profile_name, { alignSelf: 'flex-end', flex: 1, paddingLeft: 7, marginBottom: 10, color:esiColor?.Text }]}>Hi, {username}</Text>
            </>
          }

        </View>
        <ScrollView>

          <SafeAreaView style={{ flexDirection: 'column' }}>
            <View style={{ padding: 10 }}>
              <ScrollView showsVerticalScrollIndicator={false}>
                {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <View style={{ flexDirection: 'row', margin: 10 }}>
                    <View style={{ flexDirection: 'column' }}>
                      {isSwitchOn == false ? <MaterialCommunityIcons name="account-off" size={24} color={esiColor?.SIconColor} /> : <MaterialCommunityIcons name="account" size={24} color={esiColor?.SIconColor} />}
                      {/* <MaterialIcons name="account-off" size={24} color="#27B6CC" /> */}
                    </View>
                    <View style={{ flexDirection: 'row', flex: 1, padding: 5, justifyContent: 'space-between' }}>
                      {isSwitchOn == false ? <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Store Close</Text> : <Text style={{ fontSize: 16, color: esiColor.itemColor }}>Store Open</Text>}
                      {/* <View > */}
                      <Switch value={isSwitchOn}
                        trackColor={{ true: esiColor?.SIconColor, false: 'red' }}
                        style={{ height: 24 }}
                        onValueChange={() => {
                          if (isSwitchOn == false) {
                            setModal(true);
                          }
                          onToggleSwitch()
                        }
                        } />
                      {/* </View> */}
                    </View>
                  </View>
                }

                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('ServicesDashboard')} >
                    <FontAwesome name="th-large" size={16} style={{ margin: 11 }} color={esiColor?.SIconColor} ></FontAwesome>
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Dashboard</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('PartnerProfile', {page:'Services' })}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Company Profile</Text>
                  </TouchableOpacity>
                }

                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('AddressGet', { page:'Services'  })}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>My Address</Text>
                  </TouchableOpacity>
                }
                 {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Employees', {page:'Services'})}>
                    <Feather name="user" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Partner Employees</Text>
                  </TouchableOpacity>
                }
                 {
                  (isAuthenticated) && user.User_Type == "PARTNER_ADMIN" &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('WalletHistory',{page:'Services'})}>
                    <FontAwesome name="money" size={24} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>My Wallet</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Newuserservices')}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>User Service</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Partnerservice')}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Partner User Service</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('EmployeNewuserservices')}>
                    <AntDesign name="profile" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Employe Services</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('PartnerBiddingServicesMain')}>
                    <MaterialCommunityIcons name="offer" size={24} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Bidding Service</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={_handleSignOut}>
                    <Feather name="power" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor} />
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>LogOut</Text>
                  </TouchableOpacity>
                }
                {
                  (isAuthenticated) &&
                  <TouchableOpacity style={styles.item_styles} onPress={_handleDelete}>
                    <FontAwesome name="user-times" size={22} style={{ margin: 10 }} color={esiColor?.SIconColor}></FontAwesome>
                    <Text style={{ fontSize: 16, color:esiColor?.Text }}>Delete Account</Text>
                  </TouchableOpacity>
                }
                {Platform.OS == 'web' ? null :
                  <View style={{ flexDirection: 'row', margin: 10 }}>
                    <TouchableOpacity style={styles.item_styles} disabled={logoutLoading} onPress={_handleVersion}>
                      <View style={{ flexDirection: 'column' }}>
                        <MaterialIcons name="info" size={24} color={esiColor?.SIconColor} />
                        {needed && needed !== "0" ?
                          <View
                            style={{
                              position: 'absolute',
                              backgroundColor: 'red',
                              width: 16,
                              height: 16,
                              borderRadius: 15 / 2,
                              right: 5,
                              top: 9,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            <Text
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: "#FFFFFF",
                                fontSize: 8,
                              }}>
                              {"!"}
                            </Text>
                          </View>
                          : null}
                      </View>
                      <View style={{ flexDirection: 'column', padding: 5 }}>
                        <Text style={{ fontWeight: 'bold' ,color:esiColor?.Text }}>About</Text>
                      </View>
                    </TouchableOpacity>
                  </View>}
              </ScrollView>
            </View>

          </SafeAreaView>
        </ScrollView>
        <Modal animationIn={"slideInDown"}
          deviceWidth={dimensions.width}
          deviceHeight={dimensions.height}
          style={{ alignItems: 'center' }} isVisible={isModalVisible}>
          <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>

            <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

          </View>
        </Modal>
        {updateModels()}
        {customModels()}
      </Surface>
    )


  }
}
