import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView, RefreshControl } from 'react-native';
import Header from '../../header/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GetEmployeeAction, getNewUserserviceByIdAction, updateUserservicesAction } from '../../../../state/actions/Services/userservicesActions';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';

const StatusOption = [
  {
    value: 'IN-PROCESS',
    label: 'IN-PROCESS'
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED'
  },
];
const PaymentOption = [
  {
    value: 'ONLINE',
    label: 'ONLINE',
  },
  {
    value: 'COD',
    label: 'OFFLINE',
  },
];
export default function EditEmployeInprogressuserservice(props) {
  //navigation is used for navigation between screens
  const { navigation } = props
  const dispatch = useDispatch();

  //toast for popup after successful API calls.
  const toast = useToast();

  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const esiColor = useSelector(state => state.theme);
  const [data, setData] = useState([]);
  const [days, setDays] = useState("");
  const [time, setTime] = useState("");
    const [currentDate, setCurrentdate] = useState("");
  const [employeedata, setEmployeeData] = useState([]);
  const [employeDropDown, setEmployeDropDown] = React.useState('');

  let employeedataopt = []
  if (employeedata) {
    if (employeedata[0]) {
      for (let i = 0; i < employeedata?.length; i++) {
        employeedataopt.push(
          {
            value: employeedata[i]?.Partner_User_Details_Id,
            label: employeedata[i]?.Full_Name.substring(0, 40),
            Mobile_Number: employeedata[i]?.Mobile_Number,
          })
      }
    }
  }
  const ID = props?.route?.params?.id;
  const [servicedata, setServiceData] = useState([]);
  // let userservice = useSelector(state => state.userservice.newuserservice.all);
  const EmailID = useSelector(state => state.auth?.user);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const callBackemployedata = (data: any) => {
    setEmployeeData(data)
  }
  const callBackData = (data: any) => {
    setData(data)
    setDays(data.Service_Request_Total_Time_in_Days)
    setTime(data.Service_Request_Total_Time_in_Hours)
  }
  React.useEffect(() => {
    let formData = {
      Records_Filter: "FILTER",
      Partner_Details_Id: EmailID?.Partner_Details_Id
    };
    dispatch(GetEmployeeAction(formData, callBackemployedata))
  }, []);
  useEffect(() => {
    let date = new Date().toJSON().slice(0, 10);
    setCurrentdate(date)
    let formData = {
      "search": "",
       "search_by_filter": "",
       "Email_Id":"",
       "Partner_Detail_Id":EmailID?.Partner_Details_Id,
       "Partner_Service_Id":"",
       "Records_Filter":"FILTER",
       "User_Service_request_Id":ID
    };
    setServiceData(ID)
    dispatch(getNewUserserviceByIdAction(formData, callBackData));
  }, [ID, servicedata]);

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [statusDropDown, setStatusDropDown] = useState("");
  const [paymentDropDown, setPaymentDropDown] = useState("");
  let total = (days * Number(data.Price_Per_Day)) + (time * Number(data.Price_Per_Hour))
  let collect = (days * Number(data.Price_Per_Day)) + (time * Number(data.Price_Per_Hour)) - data?.Advance_Amount
  /*====================================
          Handler
  ====================================*/

  return (
    <Surface style={{ flex: 1 }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {data &&
          <Formik
            initialValues={{
              User_Service_Request_Id: data?.User_Service_Request_Id,
              Partner_Detail_Id: EmailID?.Partner_Details_Id,
              Status: data?.Status ? data.Status : "",
              Advance_Amount:data?.Advance_Amount,
              Service_End_Date: currentDate,
              Partner_Service_Id:data?.Partner_Service_Id,
              After_Discount_Service_Price: total,
              Partner_Employee_Id: data?.Partner_Employee_Id, 
              Price_Per_Day: data?.Price_Per_Day,
              Price_Per_Hour: data?.Price_Per_Hour,
              Modified_By: EmailID.Email_Id,
              User_Cancel_Reason: "",
              Payment_Status:"",
              Service_Request_Total_Time_In_Days: days?days:'',
              Service_Request_Total_Time_In_Hours: time?time:'',  
              Payment_Method: data?.Payment_Method ? data.Payment_Method : "",
              User_Service_Request_Status: "COMPLETED",
              Service_Request_Status_From_Partner:"COMPLETED",
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              Payment_Method: Yup.string().required('Payment Method is Required.'),
              Service_Request_Total_Time_In_Days: Yup.string().required('Service Request Total Time in Days is Required.'),
              Service_Request_Total_Time_In_Hours: Yup.string().required('Service Request Total Time in Hours is Required.'),
            })}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              const errors = {};
              setErrors(errors);
              if (submitTest) {
                let formValues = JSON.parse(JSON.stringify(values));  
                dispatch(updateUserservicesAction(formValues, navigation, toast, "", EmailID));

              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => { resetForm(); navigation.navigate('EmployeInprogressserservices') }}>
                    Go Back
                  </Button>
                </View>
                <View>
                  <Card style={{
                    width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                    marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7'
                  }}>
                    <Card.Content>
                      <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                        Edit Employee User Service
                      </Text>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' ,marginBottom:10}}>
                      
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Price Per Day:  {data.Price_Per_Day}  </Text>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Price Per Hour:   {data.Price_Per_Hour} </Text>
                        </View>
                      </View>
                      <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, margin: 'auto' }}>
                            <TextInput
                              style={{ backgroundColor: 'white' }}
                              theme={{ colors: { primary: '#27B6CC' } }}
                              error={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}
                              label="Total Time In Days"
                              mode='outlined'
                              value={values.Service_Request_Total_Time_In_Days}
                              onChangeText={
                                item => {
                                  setFieldValue("Service_Request_Total_Time_In_Days", item)
                                  setDays(item ? item : "")
                                }}
                            />
                            <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}>
                              {touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days}
                            </HelperText>
                          </View>
                          <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, margin: 'auto' }}>
                            <TextInput
                              style={{ backgroundColor: 'white' }}
                              theme={{ colors: { primary: '#27B6CC' } }}
                              error={Boolean(touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours)}
                              label="Total Time In Hours"
                              mode='outlined'
                              value={values.Service_Request_Total_Time_In_Hours}
                              onChangeText={item => {
                                setFieldValue("Service_Request_Total_Time_In_Hours", item)
                                setTime(item ? item : "")
                              }}
                            />
                            <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours)}>
                              {touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours}
                            </HelperText>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                            <Text style={[styles.dropdownlabel, Boolean(touched.Status && errors.Status) && { color: '#b00020' }]}>
                              Paymnet Type
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(touched.Payment_Method && errors.Payment_Method) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              data={PaymentOption}
                              maxHeight={PaymentOption.length > 1 ? 250 : 200}
                              labelField="label"
                              valueField="value"
                              dropdownPosition="auto"
                              value={values.Payment_Method}
                              name="Payment_Method"
                              onFocus={() => setPaymentDropDown(true)}
                              onBlur={() => setPaymentDropDown(false)}
                              onUpdateValue={item => {
                                setFieldValue('Payment_Method', item.value)
                                setFieldValue('Payment_Status', item.value === "COD" ? "SUCCESS" : "")
                                setPaymentDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(touched.Payment_Method && errors.Payment_Method)}>
                              <ErrorMessage name="Payment_Method" />
                            </HelperText>
                          </View>
                        </View>
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                        <Text style={{ fontWeight: "bold", color: 'red' }}>Collect From the User:   {collect} </Text>
                      </View>
                      <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                        <Button style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                          Completed
                        </Button>
                      </View>

                    </Card.Content>
                  </Card>
                </View>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface>
  );
};

const styles = StyleSheet.create({
  images: {
    width: '100%',
    height: 100,
    borderRadius: 5,
    marginBottom: 15
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    color: 'black',
    left: 22,
    top: 8,
    // color: 'black',
    zIndex: 999,
    paddingHorizontal: 1,
    fontSize: 14,
  },
  dropdown: {
    height: 58,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
});

