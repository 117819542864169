
import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Image, Dimensions, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Button, Text, Searchbar, Portal, Surface, DefaultTheme } from 'react-native-paper';
import { IconButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from 'react-native-vector-icons/FontAwesome';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import { getAllInventoryAction, resetFilter } from '../../../state/actions/farmer/farmerinventoryAction';
import Header from '../header';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import isEmpty from '../../../state/validations/is-empty';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const numberOfItemsPerPageList = [5, 10, 20];

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Inventory_Id',
    label: 'Inventory ID'
  },
  {
    value: 'Inventory_Version',
    label: 'Version'
  },
  {
    value: 'Product_Name',
    label: 'Product Name'
  },
  {
    value: 'Total_Stock',
    label: 'Total Stock'
  },
  {
    value: 'Availability_Status',
    label: 'Status'
  }

];

function FarmerInventoryDetails({ navigation }: { navigation: any }) {
  const [page, setPage] = React.useState(0);

  const toast = useToast();
  const esiColor = useSelector(state => state.theme);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const EmailID = useSelector(state => state.auth?.user);
  let Allinventory = useSelector(state => state.inventory.inventories.all);
  let inventory = Allinventory?.results;
  const [inventoryMenu, setinventoryMenu] = React.useState('All')
  const [inventorySearch, setinventorySearch] = React.useState('');
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const [inventories, setInventories] = React.useState();
  const [webpage, setwebPage] = React.useState(1);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > Allinventory?.Pagination?.TotalCount ? Allinventory?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setwebPage(1)
    setPagination({ PageNo: Number(1), PageSize: e, SortBy: "INVENTORY_ID", SortOrder: "DESC" })
  }
  const DynamicPageIncrease = (e) => {
    setwebPage(e < Allinventory?.Pagination?.TotalPages ? e + Number(1) : Allinventory?.Pagination?.TotalPages)
    setPagination({ PageNo: e == Allinventory?.Pagination?.TotalPages ? Allinventory?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "INVENTORY_ID", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "INVENTORY_ID", SortOrder: "DESC" });
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "INVENTORY_ID", SortOrder: "DESC" });
  }
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "INVENTORY_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      inventoryCall();
    }
  }, [pagination])
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const inventoryCall = () => {
    setIsLoading(true);
    let formData = {
      "search": "",
      "Inventory_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Product_Id": '',
      "Records_Filter": "FILTER"
    };
    dispatch(getAllInventoryAction(formData, pagination))
  };
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const dispatch = useDispatch()
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "INVENTORY_ID", SortOrder: "DESC" }
    let formData = {
      "search": "",
      "Inventory_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Product_Id": '',
      "Records_Filter": "FILTER"
    };
    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getAllInventoryAction(formData, pagecall))
  };

  React.useEffect(() => {
    if (!isEmpty(EmailID)) {
      let formData = {
        "search": "",
        "Inventory_Id": "",
        "Partner_Details_Id": EmailID?.Partner_Details_Id,
        "Product_Id": "",
        "Records_Filter": "FILTER"
      };
      setInventories(inventory);
      dispatch(getAllInventoryAction(formData, pagination))
    }

  }, [EmailID]);

  const [oldSearch, setOldSearch] = React.useState(0);

  const checkInventoryForTable = () => {

    if (inventorySearch) {
      try {
        if (inventoryMenu === "All") {
          return inventory?.filter(x => {
            let colummns = ["Inventory_Id", "Inventory_Version", "Product_Name", "Availability_Date", "Total_Stock", "Availability_Status"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
              const subString = typeof x[colummns[i]] === "string" ? inventorySearch.toLowerCase() : inventorySearch;
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(inventorySearch.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString == inventorySearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return inventory?.filter(x => {
            let mainString = typeof x[inventoryMenu] == "string" ? x[inventoryMenu].toLowerCase() : x[inventoryMenu];
            let subString = typeof x[inventoryMenu] == "string" ? inventorySearch.toLowerCase() : inventorySearch;
            if (typeof x[inventoryMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString == inventorySearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (page > 0) {
          if (oldSearch != inventorySearch.length) {
            setOldSearch(inventorySearch.length);
            setPage(0);
          }
        }
        else if (oldSearch != inventorySearch.length) {
          setOldSearch(inventorySearch.length);
          setPage(0);
        }
      }
    }
    else {
      return inventory
    }
  }
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Inventory_Id');

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property);
  };

  const [visible, setVisible] = React.useState(false);
  const [Idata, setData] = React.useState();

  const hideDialog = () => setVisible(false);

  const handleChange = (itemValue: any) => {
    setinventoryMenu(itemValue);
  };
  const handleSearch = (value: any) => {
    setinventorySearch(value);
    let formData = {
      search: value,
      "Inventory_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Product_Id": '',
      "Records_Filter": "FILTER"
    };
    dispatch(getAllInventoryAction(formData, pagination))
  };
  const data =
    checkInventoryForTable()
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const style = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    containerstyle: {
      width: 180
    },
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 160
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      marginLeft: 7,
      width: 140
    },
    tablerowaction: {
      justifyContent: "center",
      textAlign: "center",
      marginLeft: -5,
      width: 140
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'center',
      borderBottomColor: esiColor.SBorderColor,

    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: 'white',
      left: 22,
      top: 8,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,

    },
    rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
    },
    rowsPerPagePickers: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      borderColor: 'gray',
      borderWidth: 0.5,
      paddingHorizontal: 8,
    },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: esiColor.BackgroundColor
    },
    paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      backgroundColor: esiColor.BackgroundColor
    },
    pageInfoText: {
      fontSize: 16,
      padding: 10,
      color: esiColor.itemColor,
      fontWeight: 'bold',
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ flexDirection: "row" }}><View style={{ flex: dimensions <= 500 ? 1 : 3 }} /><View style={{ flex: dimensions <= 500 ? 1 : 3 }} /><View style={{ flex: 0.3, justifyContent: "flex-end", paddingTop: 10 }}><IconButton
            icon="close"
            size={20}
            color={esiColor.GBFColor}
            onPress={() => setVisible(!visible)}
          />
          </View></View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center", borderBottomColor: esiColor.SBorderColor, borderBottomWidth: 1 }}>
            <Text style={{ color: esiColor.brandFontColor }}>Inventory View</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView >
              <Dialog.Content >
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Inventory ID</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Idata?.Inventory_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Name</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Idata?.Product_Name} </Text></View>
                </View>

                <View style={{ flexDirection: "row", justifyContent: 'space-between', paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Inventory Version</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Idata?.Inventory_Version} </Text></View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product_Name</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Idata?.Product_Name} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Available Date</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Idata?.Available_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Total Stock</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Idata?.Total_Stock} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: Idata?.Availability_Status === "Active" ? esiColor.GBFColor : "red" }}>{Idata?.Availability_Status} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
          <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
            navigation.navigate('FarmerPartnerProductsGet')
            handleSearch("")
            dispatch(resetFilter())
          }
          }>

            Go Back
          </Button>
        </View>
        <View style={{
          flexDirection: "column"
        }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Inventory Management</Text>
        </View>
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== "web" ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[style.dropdown]}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                inputSearchStyle={style.inputSearchStyle}
                iconStyle={style.iconStyle}
                data={filterOptions}
                containerStyle={style.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={inventoryMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={inventoryMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Inventory ID" value="Inventory_Id" />
                <Picker.Item label="Version" value="Inventory_Version" />
                <Picker.Item label="Product Name" value="Product_Name" />
                <Picker.Item label="Total Stock" value="Total_Stock" />
                <Picker.Item label="Status" value="Availability_Status" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              placeholder="Search"
              iconColor={esiColor.SIconColor}
              theme={customTheme}
              placeholderTextColor={esiColor.SPHTextColor}
              onChangeText={(value) => handleSearch(value)}
              value={inventorySearch}
            />
          </View>
        </View>
        <ScrollView horizontal={Platform.OS === 'web' && dimensions >= 500 ? false : true}>
          <View style={style.mainbox}>
            <Card>
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={style.databeHeader}>
                  <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Inventory_Id'}
                    sortDirection={orderBy === 'Inventory_Id' ? order : 'ascending'}
                    onPress={createSortHandler('Inventory_Id')}><Text style={style.titletext}>Inventory ID</Text></DataTable.Title>

                  <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Inventory_Version'}
                    sortDirection={orderBy === 'Inventory_Version' ? order : 'ascending'}
                    onPress={createSortHandler('Inventory_Version')}><Text style={style.titletext}>Version</Text></DataTable.Title>

                  <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Product_Name'}
                    sortDirection={orderBy === 'Product_Name' ? order : 'ascending'}
                    onPress={createSortHandler('Product_Name')}><Text style={style.titletext}>Product Name</Text></DataTable.Title>

                  <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Available_Date'}
                    sortDirection={orderBy === 'Available_Date' ? order : 'ascending'}
                    onPress={createSortHandler('Available_Date')}><Text style={style.titletext}>Available Date</Text></DataTable.Title>

                  <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Total_Stock'}
                    sortDirection={orderBy === 'Total_Stock' ? order : 'ascending'}
                    onPress={createSortHandler('Total_Stock')}><Text style={style.titletext}>Total Stock</Text></DataTable.Title>

                  <DataTable.Title theme={customTheme} style={style.title} active={orderBy === 'Availability_Status'}
                    sortDirection={orderBy === 'Availability_Status' ? order : 'ascending'}
                    onPress={createSortHandler('Availability_Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                  <DataTable.Title style={style.title}><Text style={{ justifyContent: 'center', fontSize: 14, fontWeight: "bold" }}>Actions</Text></DataTable.Title>

                </DataTable.Header>
                {
                  data?.map((inventory, index) => (
                    <DataTable.Row style={style.databeBox} key={inventory?.Inventory_Id}>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{inventory?.Inventory_Id}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{inventory?.Inventory_Version}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{inventory?.Product_Name}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{inventory?.Available_Date}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{inventory?.Total_Stock}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{inventory?.Availability_Status}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerowaction}>
                        <IconButton
                          icon="eye"
                          color={esiColor.GBFColor}
                          size={20}
                          onPress={(e) => { setData(inventory); setVisible(!visible) }}
                        />
                        {/* {(permissions.Inventory_Management === "write") &&  */}
                        <IconButton
                          icon="square-edit-outline"
                          color={esiColor.GBFColor}
                          size={20}
                          onPress={(e) => navigation.navigate("EditFarmerIneventory", { id: inventory.Inventory_Id, partnerid: inventory.Partner_Details_Id })}
                        />
                        {/* } */}
                      </DataTable.Cell>
                    </DataTable.Row>
                  ))
                }
                {isEmpty(data) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                {Platform.OS !== "web" ?

                  <DataTable.Pagination
                    page={page}
                    theme={customTheme}
                    numberOfPages={Math.ceil(Allinventory?.Pagination?.TotalCount / numberOfItemsPerPage)}
                    onPageChange={page => DynamicPage(to < Allinventory?.Pagination?.TotalCount ? page : 0)}
                    label={Allinventory?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Allinventory?.Pagination?.TotalCount}` : '0-0'}
                    showFastPaginationControls={(Number(page) + Number(1)) > Allinventory?.Pagination?.TotalPages ? false : true}
                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    onItemsPerPageChange={onItemsPerPageChange}
                    selectPageDropdownLabel={'Rows per page'}
                  />
                  :
                  <View style={style.rowsPerPageContainer}>
                    <Text style={{color: esiColor.Text}}>rows per page: </Text>
                    <Picker
                      style={style.rowsPerPagePicker}
                      selectedValue={numberOfItemsPerPage}
                      onValueChange={(e) => onItemsPerPageChange(e)}
                    >
                      <Picker.Item label="5" value={5} />
                      <Picker.Item label="10" value={10} />
                      <Picker.Item label="20" value={20} />
                    </Picker>
                    <View style={style.paginationContainer}>
                      <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                        <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                      <Text style={style.pageInfoText}>
                        {Allinventory?.Pagination?.PageNo ? Allinventory?.Pagination?.PageNo : '0' + ' of ' + Allinventory?.Pagination?.TotalPages ? Allinventory?.Pagination?.TotalPages : '0'}
                      </Text>
                      <TouchableOpacity onPress={e => DynamicPageIncrease(to < Allinventory?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                        <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                    </View>
                  </View>}
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}


export default FarmerInventoryDetails