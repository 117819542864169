import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';

export const SBCPARTNERCITYCOUPON_REQUEST = "SBCPARTNERCITYCOUPON_REQUEST";
export const SBCPARTNERCITYCOUPON_SUCCESS = "SBCPARTNERCITYCOUPON_SUCCESS";
export const SBCPARTNERCITYCOUPON_FAIL = "SBCPARTNERCITYCOUPON_FAIL";

export const SBCPARTNERCITYCOUPON_GET_REQUEST = "SBCPARTNERCITYCOUPON_GET_REQUEST";
export const SBCPARTNERCITYCOUPON_GET_SUCCESS = "SBCPARTNERCITYCOUPON_GET_SUCCESS";
export const SBCPARTNERCITYCOUPON_GET_FAIL = "SBCPARTNERCITYCOUPON_GET_FAIL";

export const SBCPARTNERCITYCOUPON_CREATE_REQUEST = "SBCPARTNERCITYCOUPON_CREATE_REQUEST";
export const SBCPARTNERCITYCOUPON_CREATE_SUCCESS = "SBCPARTNERCITYCOUPON_CREATE_SUCCESS";
export const SBCPARTNERCITYCOUPON_CREATE_FAIL = "SBCPARTNERCITYCOUPON_CREATE_FAIL";

export const SBCPARTNERCITYCOUPON_UPDATE_REQUEST = "SBCPARTNERCITYCOUPON_UPDATE_REQUEST";
export const SBCPARTNERCITYCOUPON_UPDATE_SUCCESS = "SBCPARTNERCITYCOUPON_UPDATE_SUCCESS";
export const SBCPARTNERCITYCOUPON_UPDATE_FAIL = "SBCPARTNERCITYCOUPON_UPDATE_FAIL";

export const SBCPARTNERCITYCOUPON_DELETE_REQUEST = "SBCPARTNERCITYCOUPON_DELETE_REQUEST";
export const SBCPARTNERCITYCOUPON_DELETE_SUCCESS = "SBCPARTNERCITYCOUPON_DELETE_SUCCESS";
export const SBCPARTNERCITYCOUPON_DELETE_FAIL = "SBCPARTNERCITYCOUPON_DELETE_FAIL";
export const SBCPARTNERCITY_COUPONS_REQUEST = "SBCPARTNERCITY_COUPONS_REQUEST";
export const SBCPARTNERCITY_COUPONS_SUCCESS = "SBCPARTNERCITY_COUPONS_SUCCESS";
export const SBCPARTNERCITY_COUPONS_FAIL = "SBCPARTNERCITY_COUPONS_FAIL";

export const SBC_PARTNER_CITY_COUPONS_REQUEST = "SBC_PARTNER_CITY_COUPONS_REQUEST";
export const SBC_PARTNER_CITY_COUPONS_SUCCESS = "SBC_PARTNER_CITY_COUPONS_SUCCESS";
export const SBC_PARTNER_CITY_COUPONS_FAIL = "SBC_PARTNER_CITY_COUPONS_FAIL";

export const SBCPARTNERCITYCOUPON_FILTER = "SBCPARTNERCITYCOUPON_FILTER";
const cudheaders = config.nodeheadersCommon;
/*=============================================================
                  Get All SBCPARTNERCITYCOUPON Action
===============================================================*/
const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
  }

const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
export const getAllSBCPartnerCityCouponAction = (formData: any,pagination = {}) => async dispatch => {

    try {
        dispatch({
            type: SBCPARTNERCITYCOUPON_REQUEST
        });
        meeapi.post(urlGenarator(
            `/Partner_Service_City/PSC_Coupon_Get`,pagination),
            formData,
        ).then(response => {
            let data = response.data;
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SBCPARTNERCITYCOUPON_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SBCPARTNERCITYCOUPON_SUCCESS,
                    payload: data
                });
            }
        })
    } catch (err) {
        dispatch({
            type: SBCPARTNERCITYCOUPON_FAIL,
            payload: err
        });
    }
};

export const getSBCPartnerCityDealAction = (formData: any,pagination = {},callBackGetData:any) => async (dispatch:any) => {
    try {
        dispatch({
            type: SBC_PARTNER_CITY_COUPONS_REQUEST
        });
        meeapi.post(urlGenarator(
            `/Partner_Service_City/PSC_Coupon_Get`,pagination),
            formData,
        )
        .then(response => {
            let data = response.data;
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SBC_PARTNER_CITY_COUPONS_SUCCESS,
                    payload: data
                });
            } else {
              callBackGetData(data.results)
                dispatch({
                    type: SBC_PARTNER_CITY_COUPONS_SUCCESS,
                    payload: data.results
                });
            }
        })
    } catch (err) {
        dispatch({
            type: SBC_PARTNER_CITY_COUPONS_FAIL,
            payload: err
        });
    }
};
/*=============================================================
                  SBCPARTNERCITYCOUPON Create Action
===============================================================*/

export const createSBCPartnerCityCouponAction = (formData: any, navigation: any, toast: any, partnercityid:any,partnerid: any,partnercityname:any,partnername:any,cityid:any) => async dispatch => {
    dispatch({
        type: SBCPARTNERCITYCOUPON_CREATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Partner_Service_City/Coupons_Create`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SBCPARTNERCITYCOUPON_CREATE_SUCCESS
                });
                navigation.navigate('SBCPartnercitycouponGet', { partnercityid:partnercityid,partnerid:partnerid,partnercityname:partnercityname,partnername:partnername,cityid:cityid })
                toast.show({ message: 'Partner city coupon created successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    "search": "",
                    "Partner_Details_Id": formData.Partner_Details_Id,
                    "City_Id": "",
                    "Partner_Name": "",
                    "Partner_Service_City_Coupon_Id": "",
                    "Partner_Service_City_Id":formData.Partner_Service_City_Id,
                    "Records_Filter": "FILTER",
                    "Status": ""
                };
                const pagination={ PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_COUPON_ID", SortOrder: "DESC" };
                dispatch(getAllSBCPartnerCityCouponAction(formValue,pagination))
            } else {
                const errors = {};
                dispatch({
                    type: SBCPARTNERCITYCOUPON_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SBCPARTNERCITYCOUPON_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


/*=============================================================
                  SBCPARTNERCITYCOUPON Update Action
===============================================================*/

export const updateSBCPartnerCityCouponAction = (formData: any, navigation: any, toast: any, partnercityid:any,partnerid: any,partnercityname:any,partnername:any) => async dispatch => {
    dispatch({
        type: SBCPARTNERCITYCOUPON_UPDATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Partner_Service_City/PSC_Coupon_Update`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SBCPARTNERCITYCOUPON_UPDATE_SUCCESS
                });
                navigation.navigate('SBCPartnercitycouponGet',{partnercityid:partnercityid,partnerid:partnerid,partnercityname:partnercityname.partnercityname,partnername:partnername })
                toast.show({ message: 'Partner city coupon updated successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    "search": "",
                    "Partner_Details_Id": formData.Partner_Details_Id,
                    "City_Id": "",
                    "Partner_Name": "",
                    "Partner_Service_City_Coupon_Id": "",
                    "Partner_Service_City_Id": formData.Partner_Service_City_Id,
                    "Records_Filter": "FILTER",
                    "Status": ""
                };

                const pagination={ PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_COUPON_ID", SortOrder: "DESC" }
                dispatch(getAllSBCPartnerCityCouponAction(formValue,pagination))
         
            } else {
                const errors = {};
                dispatch({
                    type: SBCPARTNERCITYCOUPON_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SBCPARTNERCITYCOUPON_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

/*=============================================================
                  SBCPARTNERCITYCOUPON Delete
===============================================================*/

export const deleteSBCPartnerCityCouponAction = (formData: any, toast: any) => async dispatch => {
    try {
        let { data, status } = await axios.post(`${config.nodecudurl}/Partner_Service_City/Coupons_Delete`, formData, {
            headers: cudheaders
        });
        if (data) {
            toast.show({ message: 'Partner city coupon deleted successfully.', type: 'info', duration: 3000, position: 'top' });
            const formValue = {
                "search": "",
                "Partner_Details_Id": formData.Partner_Details_Id,
                "City_Id": "",
                "Partner_Name": "",
                "Partner_Service_City_Coupon_Id": "",
                "Partner_Service_City_Id": formData.Partner_Service_City_Id,
                "Records_Filter": "FILTER",
                "Status": ""
            };
            const pagination={ PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_COUPON_ID", SortOrder: "DESC" };
            dispatch(getAllSBCPartnerCityCouponAction(formValue,pagination))
     
        }
    } catch (err) {
    }
};

/*=============================================================
                  Get All CITIES Action
===============================================================*/
export const getAllCoupons = (formDatacoupon: any) => async dispatch => {
    try {
        dispatch({
            type: SBCPARTNERCITY_COUPONS_REQUEST
        });
        let { data } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Coupons/Get`,
            formDatacoupon,
            {
                headers: nodeheaders
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SBCPARTNERCITY_COUPONS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SBCPARTNERCITY_COUPONS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SBCPARTNERCITY_COUPONS_FAIL,
            payload: err
        });
    }
};


// Filter 
export const SBCPartnerCitycouponFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: SBCPARTNERCITYCOUPON_FILTER,
            fType: fType,
            value: value,
        });
    }
}

//search
export const resetFilter = () => {
    return function (dispatch: any) {
      dispatch({
        type: SBCPARTNERCITYCOUPON_FILTER,
       fType: "search", 
        value: "",
      });
    }
  }