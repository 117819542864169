import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { Button, Card, HelperText, Text, TextInput, Surface, RadioButton, Avatar, Paragraph, IconButton, DefaultTheme } from 'react-native-paper';
import { Dimensions, StyleSheet, ScrollView, View, RefreshControl } from 'react-native';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from '../../../auth/components/header';
import EsiDatePicker from '../../../../components/custom/date/date';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import { getAddressIdAction, getSingleBiddingQuotesAction, updateBiddingQuote } from '../../../../state/actions/partnerbiddingQuotesActionts';
import ESIChatModel from '../../../../components/custom/chatmodel';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const StatusOption = [
  {
    value: 'INITIATED',
    label: 'INITIATED'
  },
  {
    value: 'PROCESSING',
    label: 'PROCESSING'
  },
  {
    value: 'CANCELLED',
    label: 'CANCELLED'
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED'
  }
];
const Payment_Mode_Types = [
  {
    value: 'ONLINE',
    label: 'ONLINE'
  },
  {
    value: 'COD',
    label: 'COD'
  }
];

const EditPartnerBiddingQuotes = (props: any) => {

  const ID = props.route?.params?.id || "";
  const currentDate = new Date();
  const page = props.route?.params?.page;
  const { navigation } = props;
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  const toast = useToast();
  const isSubmited = useSelector(state => state.biddingproducts.biddingUpdate.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "User_Products_Bidding_Id", SortOrder: "DESC" });
  const [paginations, setPaginations] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "User_Address_Id", SortOrder: "DESC" });
  const [typeDropDown, setTypeDropDown] = useState("");
  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    biddingquotesCall()
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [seconds, setSeconds] = React.useState(-1);

  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  React.useEffect(() => {
    return () => {
    };
  }, []);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);

  React.useEffect(() => {
    if (!(permissions.Employees === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
    setSeconds(1);
  }, [permissions, ID]);

  const [data, setData] = useState([]);
  const callBackData = (data: any) => {
    setData(data)
  }

  const [addressData, setAddressData] = useState([]);
  const callBackAddressData = (data: any) => {
    setAddressData(data)
  }

  const biddingExpDate = new Date(data?.Bidding_Exp_Date);

  const [statusshowDropDown, setStatusShowDropDown] = useState(false);
  React.useEffect(() => {
    if (data?.user_Address_Id) {
      let addressForm = {
        "Records_Filter": "FILTER",
        "User_Address_Id": data?.user_Address_Id,
      }
      dispatch(getAddressIdAction(addressForm, callBackAddressData, paginations))
    }
  }, [data]);
  const [pageOld, setPageOld] = React.useState(false);
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      biddingquotesCall();
    }
  }, [pagination])
  const biddingquotesCall = () => {
    let formData = {
      "search_by_filter": "",
      "search": "",
      "User_Email_Id": "",
      "Partner_Bidding_Quote_Id": ID,
      "User_Products_Bidding_Id": '',
      "Bidding_Product_Id": "",
      "User_Address_Id": "",
      "Records_Filter": "FILTER",
      "Bidding_Status": "",
      "Partner_Detail_Id": ""
    }
    // /User/Address_Get
    dispatch(getSingleBiddingQuotesAction(formData, callBackData, pagination))

  };
  // Finalize_Partner_Bidding_Quote_Id Partner_Bidding_Quote_Id
  React.useEffect(() => {
    if (seconds <= 0) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "User_Email_Id": "",
        "Partner_Bidding_Quote_Id": ID,
        "User_Products_Bidding_Id": '',
        "Bidding_Product_Id": "",
        "User_Address_Id": "",
        "Records_Filter": "FILTER",
        "Bidding_Status": "",
        "Partner_Detail_Id": ""
      }
      // /User/Address_Get
      dispatch(getSingleBiddingQuotesAction(formData, callBackData, pagination))
    }
  }, [seconds]);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  //mobile number country code
  const [reload, setReload] = React.useState(false);
  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])
  const styles = StyleSheet.create({
    container: {
      backgroundColor: 'white',
      paddingTop: 16
    },
    cardCircle: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "2%",
      marginBottom: "2%",
      borderRadius: 6,
      borderWidth: 0.01,
      borderColor: esiColor.brandBorderColor,
      backgroundColor: esiColor.BackgroundColor

    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },

    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    phonetextInput: {
      flex: 1
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 8,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });
  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };
  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        {data && biddingExpDate &&

          <Formik
            enableReinitialize={true}
            initialValues={{
              "created_by": data?.Created_By || "",
              "created_date": data?.Created_Date || "",
              "modified_by": EmailID.Email_Id || "",
              "user_products_bidding_id": data?.User_Products_Bidding_Id || "",
              "Advance_Token_Amount": data?.Advance_Token_Amount + " ",
              "partner_detail_id": data?.Partner_Detail_Id || "",
              "partner_bidding_available_product_id": data?.Partner_Bidding_Available_Product_Id || "",
              "partner_bidding_quote_id": data?.Partner_Bidding_Quote_Id || "",
              "partner_location": data?.Partner_Location || "",
              "delivery_fee": data?.Delivery_Fee + " ",
              "delivery_managed_by": data?.Delivery_Managed_By || "",
              "estimated_delivery_date": data?.Estimated_Delivery_Date || "",
              "no_of_delivery_days": data?.No_Of_Delivery_Days || "",
              "partner_comments": data?.Partner_Comments || "",
              "quotation_amount": data?.Quotation_Amount + " ",
              "bidding_status": data?.Bidding_Status || "",
              "status": data?.Status || "",
              "Payment_Mode": data?.Payment_Mode || "ONLINE",
              "No_Of_Delivery_Days": data?.No_Of_Delivery_Days,
            }}
            validationSchema={Yup.object().shape({
              quotation_amount: Yup.number()
                .positive()
                .min(Yup.ref('Advance_Token_Amount'), 'Quotation Amount must be greater than or equal to Advance Token Amount.')
                .required('Quotation Amount is required.'),
              Advance_Token_Amount: Yup.number().required('Advance Token Amount is required.'),
              estimated_delivery_date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require estimated delivery date greater than or equal to today.").required("Valid estimated delivery date is required."),
              Payment_Mode: Yup.string().required('Payment Mode is required.'),
              bidding_status: Yup.string().required('Bidding Status is required.'),
              partner_comments: Yup.string().required('partner comments is required.'),
              delivery_fee: Yup.number().min(0, 'delivery fee than to 0.').required('delivery fee is required.'),
              No_Of_Delivery_Days: Yup.number().required('delivery days is required.'),

            })}

            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              if (values) {
                let formData = JSON.parse(JSON.stringify(values));
                dispatch(updateBiddingQuote(formData, props.navigation, toast));
              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"

              }}
              >
                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm();
                      setReload(true);
                      setData([]);
                      setSeconds(1);
                      navigation.navigate('PartnerBiddingQuotes')
                    }}
                  >
                    Go Back
                  </Button>
                </View>
                <View
                  pointerEvents={((currentDate <= biddingExpDate) || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') && (data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id))) ? "auto" : 'none'}
                >
                  <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: ((currentDate <= biddingExpDate) || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') && (data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id))) ? '#d6d6d7' : 'red', backgroundColor: esiColor.CBColor }}>
                    <Card.Content>{data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id ?
                      <Card.Actions style={{ alignContent: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                        <Paragraph style={{ color: 'green', fontSize: 20, fontWeight: 'bold' }}>Bid Accepted</Paragraph>
                      </Card.Actions> :

                      (currentDate >= biddingExpDate || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') ? false : (data.Finalize_Partner_Bidding_Quote_Id != data?.Partner_Bidding_Quote_Id))) ?
                        <Card.Actions style={{ alignContent: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                          <Paragraph style={{ color: 'red', fontSize: 20, fontWeight: 'bold' }}>User Accepted Other Bid</Paragraph>
                        </Card.Actions> : null}

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor?.brandFontColor }}>Product Details</Text>
                        </View>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          {data.Profile_Pic_Path ? <Avatar.Image size={70} source={{ uri: data.Profile_Pic_Path }} /> : <Avatar.Image source={require('../../../../assets/images/noUser.png')} />}
                          {/* {'  '} */}
                          <Text style={{ marginTop: 10, color: esiColor?.Text }}>  {'   '}{data.Full_Name}</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>

                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', marginBottom: 5, flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>User Email:</Text>
                          <Text style={{ color: esiColor?.Text }}> {data.Email_Id}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>User Mobile Number:</Text>
                          <Text style={{ color: esiColor?.Text }}> {data.Mobile}</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          {data.Product_Image ? <Avatar.Image size={70} source={{ uri: data.Product_Image }} /> : <Avatar.Image style={{ backgroundColor: 'white' }} source={require('../../../../assets/images/noImages.png')} />}
                          {/* {'  '} */}
                          <Text style={{ marginTop: 10, color: esiColor?.Text }}>  {'   '}{data.Product_Name}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Product ID:</Text>
                          <Text style={{ color: esiColor?.Text }}> {data.Bidding_Product_Id}</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5, }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>QTY:</Text>
                          <Text style={{ color: esiColor?.Text }}> {data.Quantity}{data?.Measurement_Type ? "(" + data?.Measurement_Type + ")" : null}</Text>
                        </View>

                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Bidding Reqest ID:</Text>
                          <Text style={{ color: esiColor?.Text }}> {data.User_Products_Bidding_Id}</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Bid Date:</Text>
                          <Text style={{ color: esiColor?.Text }}> {data.Created_Date}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Bid Expiry Date: </Text><Text style={{ color: esiColor?.Text }}>{data.Bidding_Exp_Date}</Text>
                        </View>

                      </View>
                      {data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id ?
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, marginTop: 30, marginBottom: 30 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor?.Text }}>Transactions Details</Text>
                        </View> : null}

                      {data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id ? <View><View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Advance Transaction ID:</Text>
                          <Text style={{ fontSize: 15, color: esiColor?.Text }}> {data.Advance_Online_Transaction_Id}</Text>
                        </View>
                        {data.Is_Advance_Amount_settled == '1' ? <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Advance Amount Settled: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Advance_Token_Amount}</Text>
                        </View> :
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Balance Advance Amount: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Advance_Token_Amount}</Text>
                          </View>}

                      </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>ESI Commision:</Text>
                            <Text style={{ fontSize: 15, color: 'green' }}> {data.ESI_Commision}</Text>
                          </View>
                          {data.Is_Final_Amount_Settled == '1' ? <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Final Amount Settled: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Remaing_Amount_After_Advance}</Text>
                          </View> :
                            <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                              <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Remaining Balance Amount: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Remaing_Amount_After_Advance}</Text>
                            </View>}
                        </View>
                        {data.Is_Final_Amount_Settled == '1' ? <View style={{ flexDirection: 'row', flexGrow: 1, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor?.Text }}>Final Amount Transaction ID: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Online_Transaction_Id}</Text>
                        </View> : null}</View> : null}
                    </Card.Content>
                  </Card>
                  {addressData.length > 0 ?
                    <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: ((currentDate <= biddingExpDate) || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') && (data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id))) ? esiColor.SBorderColor : 'red', backgroundColor: esiColor.CBColor }}>
                      <Card.Content>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor?.brandFontColor }}>Delivery Details</Text>
                        </View>
                        <View style={{ flexDirection: 'column', justifyContent: 'space-between', flexWrap: 'wrap', margin: 15 }}>
                          {addressData[0]?.Address_Type && <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>
                            {addressData[0]?.Address_Type} Address
                          </Text>}
                          <View>
                            <Text style={{ color: esiColor?.Text }}>
                              {addressData[0]?.Name}
                            </Text>
                            <Text style={{ color: esiColor?.Text }}>
                              {addressData[0]?.Mobile}
                            </Text>
                          </View>
                          <Text style={{ color: esiColor?.Text }}>
                            {addressData[0]?.Door_No},  {addressData[0]?.Street},  {addressData[0]?.Land_Mark},  {addressData[0]?.City}, {addressData[0]?.State}, {addressData[0]?.Country}, {addressData[0]?.Partner_Location}
                          </Text>
                          <Text style={{ color: esiColor?.Text }}>
                            {addressData[0]?.Location}
                          </Text>
                          {addressData[0]?.Latitude && <Text style={{ color: esiColor?.Text }}>
                            Latitude: {addressData[0]?.Latitude}
                          </Text>}
                          {addressData[0]?.Longitude && <Text style={{ color: esiColor?.Text }}>
                            Longitude: {addressData[0]?.Longitude}
                          </Text>}
                        </View>
                      </Card.Content>
                    </Card> : null}
                  <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: ((currentDate <= biddingExpDate) || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') && (data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id))) ? esiColor.SBorderColor : 'red', backgroundColor: esiColor.CBColor }}>
                    <Card.Content>

                      <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                        Edit Bidding Quotes
                      </Text>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            // theme={customTheme}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(touched.quotation_amount && errors.quotation_amount)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('quotation_amount')}
                            disabled={true}
                            label="quotation amount"
                            name="quotation_amount"
                            value={values.quotation_amount}
                            mode="outlined"
                            left={<TextInput.Icon color={esiColor.SIconColor} name="currency-inr" />}
                          />
                          <HelperText type="error" visible={Boolean(touched.quotation_amount && errors.quotation_amount)}>
                            {touched.quotation_amount && errors.quotation_amount}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput
                            // theme={customTheme}
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('Advance_Token_Amount')}
                            label="Advance Token  Amount"
                            name="Advance_Token_Amount"
                            value={values.Advance_Token_Amount}
                            mode="outlined"
                          />
                          <HelperText type="error" visible={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}>
                            {touched.Advance_Token_Amount && errors.Advance_Token_Amount}
                          </HelperText>
                        </View>

                      </View>
                      {data.Advance_Payment_Status_From_User == true ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput
                            // theme={customTheme}
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(touched.no_of_delivery_days && errors.no_of_delivery_days)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('no_of_delivery_days')}
                            label="delivery days"
                            name="no_of_delivery_days"
                            value={values.no_of_delivery_days}
                            mode="outlined"
                          />
                          <HelperText type="error" visible={Boolean(touched.no_of_delivery_days && errors.no_of_delivery_days)}>
                            {touched.no_of_delivery_days && errors.no_of_delivery_days}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput
                            // theme={customTheme}
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(touched.delivery_fee && errors.delivery_fee)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('delivery_fee')}
                            label="delivery fee"
                            name="delivery_fee"
                            value={values.delivery_fee}
                            mode="outlined"
                            left={<TextInput.Icon name="currency-inr" />}
                          />
                          <HelperText type="error" visible={Boolean(touched.delivery_fee && errors.delivery_fee)}>
                            {touched.delivery_fee && errors.delivery_fee}
                          </HelperText>
                        </View>
                      </View> : null}
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                        <View style={{ flexGrow: 1, width: 230, flexShrink: 1, paddingTop: 20 }}>
                          <TextInput
                            // theme={customTheme}
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine} error={Boolean(touched.partner_comments && errors.partner_comments)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('partner_comments')}
                            label="partner comments"
                            name="partner_comments"
                            value={values.partner_comments}
                            mode="outlined"
                          />
                          <HelperText type="error" visible={Boolean(touched.partner_comments && errors.partner_comments)}>
                            {touched.partner_comments && errors.partner_comments}
                          </HelperText>
                        </View>
                        {data.Advance_Payment_Status_From_User == true ?
                          <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>

                            <EsiDatePicker
                              theme={customTheme}
                              error={Boolean(touched.estimated_delivery_date && errors.estimated_delivery_date)}
                              style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                              name={'estimated_delivery_date'}
                              label={'estimated delivery date'}
                              value={values.estimated_delivery_date}
                              valueUpdate={item => {
                                setFieldValue('estimated_delivery_date', item)
                              }}
                            />
                            <HelperText type="error" visible={Boolean(touched.estimated_delivery_date && errors.estimated_delivery_date)}>
                              <ErrorMessage name="estimated_delivery_date" />
                            </HelperText>
                          </View> : null}
                      </View>
                      {/* {(data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') ? null : */}
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, flexShrink: 1, paddingTop: 10 }}>
                          {/* <View style={styles.container}> */}
                          <Text style={[styles.dropdownlabel, Boolean(touched.Payment_Mode && errors.Payment_Mode) && { color: '#b00020' }]}>
                            Payment Mode
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Payment_Mode && errors.Payment_Mode) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={Payment_Mode_Types}
                            labelField="label"
                            valueField="value"
                            maxHeight={Payment_Mode_Types.length > 1 ? 250 : 200}
                            search={Payment_Mode_Types.length > 5 ? true : false}
                            searchPlaceholder={'Select Payment Mode'}
                            value={values.Payment_Mode}
                            onFocus={() => setStatusShowDropDown(true)}
                            onBlur={() => setStatusShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('Payment_Mode', item.value)
                              setStatusShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Payment_Mode && errors.Payment_Mode)}>
                            {touched.Payment_Mode && errors.Payment_Mode}
                          </HelperText>
                        </View>
                      </View>
                      {/* } */}
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Bidding_Status && errors.Bidding_Status) && { color: '#b00020' }]}>
                            Bidding Status
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.bidding_status && errors.bidding_status) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={StatusOption}
                            maxHeight={StatusOption.length > 1 ? 250 : 200}
                            search={StatusOption.length > 1 ? true : false}
                            searchPlaceholder={'Select Bidding Status '}
                            labelField="label"
                            valueField="value"
                            dropdownPosition="bottom"
                            value={values.bidding_status}
                            name="Bidding_Status"
                            onFocus={() => setTypeDropDown(true)}
                            onBlur={() => setTypeDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('bidding_status', item.value)
                              setTypeDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.bidding_status && errors.bidding_status)}>
                            {touched.bidding_status && errors.bidding_status}
                          </HelperText>
                        </View>
                      </View>
                    </Card.Content>
                    <View style={{ marginHorizontal: 30 }}>
                      <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                    </View>
                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                      <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                        Save
                      </Button>
                    </View>
                  </Card>
                  <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: ((currentDate <= biddingExpDate) || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') && (data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id))) ? esiColor.SBorderColor : 'red', backgroundColor: esiColor.CBColor }}>

                    <Card.Content>
                      <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor?.brandFontColor }}>
                        Chat for Bidding Quote
                      </Text>
                      <ESIChatModel navigation={navigation} data={data} />
                    </Card.Content>
                  </Card>
                </View>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface >

  );
};

export default EditPartnerBiddingQuotes;