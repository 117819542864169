import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { AddEmployeeAction } from '../../state/actions/employeeAction';
import { Button, Card, DefaultTheme, HelperText, RadioButton, Surface, Text, TextInput } from 'react-native-paper';
import { Dimensions, ScrollView, StyleSheet, View, RefreshControl } from 'react-native';
import Header from '../auth/components/header';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import getEnvVars from '../../../environment';
const { BASEURL } = getEnvVars();

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const UserType = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'PARTNER_ADMIN',
    label: 'ADMIN'
  },
  {
    value: 'PARTNER_EMPLOYEE',
    label: 'EMPLOYEE'
  }
];

const AddEmployee = (props: any) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { navigation } = props;
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.employee.employeeCreate.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  const phoneInput = useRef(null);
  const Page = props.route?.params?.ID;
  const esiColor = useSelector(state => state.theme);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (!(permissions.Employees === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  // Alert Popup

  const [showDropDown, setShowDropDown] = useState(false);

  //form reset
  const [reload, setReload] = React.useState(false);

  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 15
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },

    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    phonetextInput: {
      flex: 1
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }} >
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        {!reload &&
          <Formik
            enableReinitialize={true}
            initialValues={{
              Base_URL: BASEURL + "set-password?token=",
              Partner_Details_Id: EmailID?.Partner_Details_Id,
              Partner_Employee_Id: '',
              Email_Id: '',
              Mobile: '',
              First_Name: '',
              Full_Name: '',
              Is_Active: false,
              Last_Name: '',
              User_Type: ''

            }}

            validationSchema={Yup.object().shape({
              Email_Id: Yup.string().email("Please provide a valid Email ID. ").notOneOf([errorMessage.Email_Id.key, null], errorMessage.Email_Id.message).required('Email ID is required.'),
              User_Type: Yup.string().required('User Type is required.'),
              First_Name: Yup.string().required('First Name is required.'),
              Last_Name: Yup.string().required('Last Name is required.'),
              Partner_Employee_Id: Yup.string().required('Employee ID is required.'),
              Mobile: Yup.string().min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number.").required("Mobile Number is required."),
            })}

            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              if (values) {
                let formData = JSON.parse(JSON.stringify(values));
                if (values.Is_Active) {
                  formData.Is_Active = 1;
                } else {
                  formData.Is_Active = 0;
                }

                dispatch(AddEmployeeAction(formData, navigation, setErrorMessage, setErrors, toast, Page))
              }
            }
            }
          >

            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}>
                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm();
                      setReload(true);
                      navigation.navigate('Employees', { page: Page })
                    }}
                  >
                    Go Back
                  </Button>
                </View>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Add Employee
                    </Text>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Email_Id && errors.Email_Id)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Email_Id')}
                          label="Email ID"
                          name="Email_Id"
                          value={values.Email_Id}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Email_Id && errors.Email_Id)}>
                          {touched.Email_Id && errors.Email_Id}
                        </HelperText>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Partner_Employee_Id && errors.Partner_Employee_Id)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Partner_Employee_Id')}
                          label="Employee ID"
                          name="Partner_Employee_Id"
                          value={values.Partner_Employee_Id}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Partner_Employee_Id && errors.Partner_Employee_Id)}>
                          {touched.Partner_Employee_Id && errors.Partner_Employee_Id}
                        </HelperText>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.First_Name && errors.First_Name)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('First_Name')}
                          label="First Name"
                          name="First_Name"
                          value={values.First_Name}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.First_Name && errors.First_Name)}>
                          {touched.First_Name && errors.First_Name}
                        </HelperText>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Last_Name && errors.Last_Name)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Last_Name')}
                          label="Last Name"
                          name="Last_Name"
                          value={values.Last_Name}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Last_Name && errors.Last_Name)}>
                          {touched.Last_Name && errors.Last_Name}
                        </HelperText>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Full_Name && errors.Full_Name)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Full_Name')}
                          label="Full Name"
                          name="Full_Name"
                          value={values.Full_Name}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Full_Name && errors.Full_Name)}>
                          {touched.Full_Name && errors.Full_Name}
                        </HelperText>
                      </View>


                      <View style={{ flexShrink: 1, width: 400 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          label="Mobile Number"
                          mode='outlined'
                          onChangeText={handleChange('Mobile')}
                          left={<TextInput.Affix text="+91" />}
                          value={values.Mobile}
                        />
                        <HelperText type="error" visible={Boolean(touched.Mobile && errors.Mobile)}>
                          {touched.Mobile && errors.Mobile}
                        </HelperText>
                      </View>

                      <View style={{ flexShrink: 1, width: 400 }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.User_Type && errors.User_Type) && { color: '#b00020' }]}>
                            User Type
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.User_Type && errors.User_Type) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={UserType}
                            labelField="label"
                            valueField="value"
                            maxHeight={UserType.length > 1 ? 250 : 200}
                            search={UserType.length > 1 ? true : false}
                            searchPlaceholder={'Select User Type'}
                            value={values.User_Type}
                            onFocus={() => setShowDropDown(true)}
                            onBlur={() => setShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('User_Type', item.value)
                              setShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.User_Type && errors.User_Type)}>
                            {touched.User_Type && errors.User_Type}
                          </HelperText>
                        </View>
                      </View>

                      <View style={{ width: "80%", margin: "auto" }}>
                        <View style={{
                          flexShrink: 1, flexDirection: "row"
                        }}>
                          <RadioButton
                            color={esiColor.itemColor}
                            value={values.Is_Active}
                            name="Is_Active"
                            status={values.Is_Active ? 'checked' : 'unchecked'}
                            onPress={() => setFieldValue('Is_Active', !values.Is_Active)}
                          />
                          <Text style={{ marginTop: 10, color: Boolean(touched.Is_Active && errors.Is_Active) ? esiColor.itemColor : esiColor.itemColor }}>Is Active</Text>
                        </View>
                      </View>
                    </View>
                  </Card.Content>
                  <View style={{ marginHorizontal: 30 }}>
                    <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                    <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                      Save
                    </Button>
                  </View>
                </Card>
              </View>
            )}
          </Formik>}
      </ScrollView>
    </Surface>
  );
};

export default AddEmployee;