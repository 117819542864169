import React, { useEffect, useMemo, useState } from "react";
import { Dimensions, ScrollView, Image, Platform, StyleSheet, TouchableOpacity, View, RefreshControl, ActivityIndicator } from "react-native";
import { Avatar, Button, HelperText, IconButton, Surface, Text, TextInput, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { useRoute } from "@react-navigation/native";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import { Formik } from "formik";
import * as Location from "expo-location";
import * as Yup from 'yup';
import { api } from "../../state/actions/authActions";
import Modal from "react-native-modal";
import isEmpty from "../../state/validations/is-empty";
import Header from '../auth/components/header';
// import esiColor from "../../constants/Colors";
import { getBiddingProductsCart } from "../../state/actions/bidding/biddingproductActions";
import { cartDeletecreate, deleteCartBids, updateCartBids } from "../../state/actions/bidding/biddingboomiActions";
import EsiDatePicker from "../../components/custom/date/date";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";


export default function CartOfBids(props: any) {
    const { navigation } = props;
    const esiColor = useSelector(state => state.theme);

    const ID = props.route?.params?.id;
    const logedInEmail = useSelector((state) => state.auth.user.Partner_Details_Id);
    const [dimensions, setDimensions] = useState(Dimensions.get("window"));
    const [measurement, setMeasurement] = React.useState([]);
    const [deleting, setDelete] = React.useState([]);
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
        return () => subscription?.remove();
    });

    const { height, width } = dimensions;


    const [loading, setIsLoading] = React.useState(false);

    const [timeee, setTime] = React.useState(false);
    const [myBids, setMyBids] = React.useState([]);
    React.useEffect(() => {
        if (ID) {
            dispatch(getBiddingProductsCart({ Buyer_Partner_Detail_Id: logedInEmail },
                callBackGetData));
            setIsLoading(true);
        }

    }, [ID]);
    const dispatch = useDispatch();

    const callBackGetData = async (status, data) => {
        setTime(true)
        setIsLoading(false);
        if (status && data.Success_Response?.Is_Data_Exist == '1') {
            setMyBids(data?.results);
        } else {

            setMyBids([]);
        }
    }

    const getMyCartBids = () => {
        dispatch(getBiddingProductsCart({ Buyer_Partner_Detail_Id: logedInEmail },
            callBackGetData));
        setIsLoading(true);
    }

    const route = useRoute();

    useEffect(() => {

        if (!isEmpty(logedInEmail)) {
            getMyCartBids();
        } else {
            // navigation.navigate("Home");
        }
    }, [logedInEmail, route]);


    const [disable, setDisable] = React.useState(false);
    const [pincode, setPincode] = React.useState("");
    useEffect(() => {
        (async () => {
            if (Platform.OS === 'android' && !Constants.isDevice) {
                alert(
                    'Oops, this will not work on Snack in an Android emulator. Try it on your device!'
                );
                return;
            }
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                // Alert.alert('Location Service not enabled', 'Please enable your location services to continue', [{ text: 'OK' }], { cancelable: false });
                return;
            }

            const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
            api.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${latitude}&lon=${longitude}`).then(async (response) => {
                let info = response?.data?.address?.postcode;
                if (info)
                    setPincode(info)
            }).catch(async (reason) => {
            });
        })();
    }, [])
    const placeCartAction = () => {
        setIsLoading(true);
        dispatch(cartDeletecreate({ Buyer_Partner_Detail_Id: logedInEmail }, placeCallbackResponse));
    }

    const placeCallbackResponse = (status: boolean) => {
        setIsLoading(false);
        if (status) {
            setDisable(false);
            getMyCartBids();
            navigation.navigate("Biddingproducts")
        }
    }

    const deleteCallbackResponse = (status: boolean, item: any) => {
        setIsLoading(false);
        if (status) {
            setDisable(false);
            getMyCartBids();
        }
    }

    const editCartAction = (item) => {
        setAddCartItem(item);
        setAddCartPop(true);
        setMeasurement(item?.Measurement_Type?.split(',').map(Type => ({ Measurement_Type: Type })))
    }

    let productopt = []
    if (measurement) {
        if (measurement[0]) {
            for (let i = 0; i < measurement?.length; i++) {
                productopt.push(
                    {

                        value: measurement[i]?.Measurement_Type,
                        label: measurement[i]?.Measurement_Type,
                    })
            }
        }
    }
    const [addCartPop, setAddCartPop] = React.useState(false);
    const [addCartItem, setAddCartItem] = React.useState({});
    const [loadingForm, setloadingForm] = React.useState(false);
    const [formResults, setFormResults] = React.useState(false);
    const formCallbackResponse = (status: boolean) => {
        if (status) {
            setloadingForm(false);
            setAddCartPop(false);
            setFormResults(false);
            getMyCartBids();
        }
    }
    const deleteCartAction = (item) => {
        setIsLoading(true);
        let formData = {
            B2B_Bidding_Product_Cart_Id: item
        }
        dispatch(deleteCartBids(formData, deleteCallbackResponse));
    }

    const styles = StyleSheet.create({
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor

        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor

        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },

        text: {
            fontSize: 12,
            fontFamily: 'system-ui',
        },
        containerstyle: {
            maxWidth: 200,
        }
    });


    return (
        <Surface theme={{ colors: { surface: esiColor.BackgroundColor } }} style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ marginBottom: (Platform.OS === 'web' ? 10 : 100), marginRight: 10, marginLeft: 10 }}>
                <View style={{ marginTop: 20 }}>
                    <IconButton
                        icon="arrow-left"
                        // size={30}
                        iconColor={esiColor.SIconColor}
                        style={{ marginVertical: -10, flex: 1, marginLeft: 0 }}
                        onPress={() => {
                            if (navigation.canGoBack())
                                navigation.goBack();
                            else navigation.navigate("Biddingproducts")

                        }} />
                    {/* <View style={{ flex: 11 }}>
                        <Text style={{ fontSize: 20, fontWeight: "400", marginTop: -2, alignSelf: "center" }}>
                            Cart
                        </Text>
                    </View> */}
                </View>
                <ScrollView style={{ height: height - ((height / 100) * (Platform.OS === 'web' ? 20 : 30)) }}
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                            onRefresh={() => {
                                getMyCartBids();
                            }}
                        />
                    }
                >

                    {myBids?.length <= 0 ? <View style={{ alignItems: "center", marginTop: 10 }}>
                        <View style={{ justifyContent: 'center', alignItems: 'center', }}>
                            <Image style={{ width: 130, height: 130 }} source={{ cache: "force-cache", uri: "https://i.imgur.com/dCdflKN.png" }} />
                            <Text style={{ fontWeight: 'bold', fontSize: 30, margin: 5, color: esiColor.NoDataFound }}> Your Cart is Empty.</Text>
                            <Text style={{ fontSize: 15, fontWeight: 'bold', margin: 5, color: esiColor.itemColor }}>Add products to make an biding service.</Text>
                            <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 10, padding: 10, margin: 5 }} onPress={() => navigation.navigate('NewBidProducts')}>
                                <Text style={{ fontWeight: 'bold', color: esiColor.itemButtenColor }}>Shop Now</Text>
                            </TouchableOpacity>
                        </View>
                    </View> : <View>
                        {myBids?.map((data, index) => (
                            <View key={index} style={{
                                shadowColor: esiColor.brandShadowColor,
                                // shadowOffset: { width: -2, height: 4 },
                                shadowOpacity: 5,
                                shadowRadius: 5,
                                marginHorizontal: 2, borderRadius: 10, padding: 2, backgroundColor: esiColor.CBColor,
                                marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                                alignItems: 'flex-start',
                                // elevation: 10,
                                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
                                maxHeight: height > 250 ? 250 : 90,
                                minHeight: 100,
                                // borderColor: 'grey',
                                borderWidth: 0.5,
                            }}>
                                <View style={{ flex: 3, alignContent: 'center', marginLeft: 10 }} >
                                    <Image source={{ cache: "force-cache", uri: data?.Product_Image }} style={{ height: 100, width: 80, resizeMode: 'contain' }}></Image>
                                </View>
                                <View style={{ flex: 6, flexDirection: 'column' }}  >
                                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{data?.Product_Name}</Text>
                                    <Text style={[styles.text, { color: esiColor.Text }]}>Quantity: {data?.Quantity} {data?.Measurement_Type}</Text>
                                    <Text style={[styles.text, { color: esiColor.Text }]}>Description : {data?.Description}</Text>
                                    <Text style={[styles.text, { color: esiColor.Text }]}>Pincode : {data?.Pincode}</Text>
                                    <Text style={[styles.text, { color: esiColor.Text }]}>Bidding Expiry Date : {data?.Bidding_Expiry_Date}</Text>
                                </View>
                                <View style={{ flex: 1, flexDirection: "column", justifyContent: "space-around", alignSelf: "center" }}>
                                    <TouchableOpacity style={{ marginVertical: 6 }} onPress={(e) => { editCartAction(data); }} >
                                        <FontAwesome5 name="edit" size={22} color={esiColor.SIconColor} />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ marginVertical: 6 }} onPress={(e) => { deleteCartAction(data.b2b_Bidding_Product_Cart_Id) }} >
                                        <Ionicons name="md-trash" size={22} color="red" />
                                    </TouchableOpacity>
                                </View>

                            </View>
                        ))}

                        <View style={{ minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                            <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" disabled={disable}
                                onPress={() => {
                                    placeCartAction()
                                    setDisable(true)
                                }}>
                                {/* //  onPress={placeCartAction}> */}

                                <Text style={{ fontWeight: 'bold', color: esiColor.itemButtenColor }}> Ask Quotation</Text>

                            </Button>

                        </View>
                    </View>}


                </ScrollView>
                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={addCartPop}>
                    <View style={{ flex: 1, maxWidth: 500, minWidth: 450, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>

                        <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                            setAddCartPop((prop) => !prop);
                        }}>
                            <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                        <ScrollView
                            showsVerticalScrollIndicator={false}>
                            <Surface style={{
                                justifyContent: 'center',
                                borderRadius: 10,
                                top: Platform.OS === 'web' ? 10 : 20,
                                backgroundColor: esiColor.BackgroundColor
                            }} >
                                <Formik
                                    key={addCartItem?.Bidding_Product_Cart_Id}
                                    initialValues={
                                        {
                                            B2B_Bidding_Product_Cart_Id: addCartItem.b2b_Bidding_Product_Cart_Id,
                                            Quantity:(addCartItem?.Quantity || "").toString().replace(/\s/g, ''),
                                            Measurement_Type: addCartItem?.Measurement_Type+" ",
                                            Buyer_Partner_Detail_Id: logedInEmail,
                                            Description: addCartItem?.Description || "",
                                            Pincode: addCartItem?.Pincode+ "",
                                            Bidding_Expiry_Date: addCartItem?.Bidding_Expiry_Date || '',
                                            Bidding_Status: 'INITIATED'
                                        }
                                    }
                                    validationSchema={Yup.object().shape({
                                        Quantity: Yup.number().min(1, "Quantity should be more then Zere.").required("Quantity is required."),
                                        Measurement_Type: Yup.string().required("Size is required."),
                                        Pincode: Yup.string().min(6, "Pincode should be 6 numbers.").max(6, "Pincode should be 6 numbers.").required("Pincode is required."),

                                    })}
                                    onSubmit={values => {
                                        setloadingForm(true);
                                        values.Quantity = values.Quantity.replace(/\s/g, '');
                                        dispatch(updateCartBids(values, formCallbackResponse));
                                    }}
                                >
                                    {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                        <View style={{
                                            flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                                        }}>
                                            {loadingForm ?
                                                <ActivityIndicator color={esiColor.esiMainColor} />
                                                :
                                                formResults ?
                                                    <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                                                        <View style={{ width: "90%", margin: "auto" }}>
                                                            <Text style={{ textAlign: "center", fontWeight: "bold", color: esiColor.DescColor }} >Successfully updated cart item.
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    :
                                                    <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>

                                                        <View style={{ width: "90%", margin: "auto" }}>
                                                            <TextInput
                                                                style={{ width: "100%", backgroundColor: esiColor.TextBC }}
                                                                label="Quantity*"
                                                                mode='outlined'
                                                                onChangeText={handleChange("Quantity")}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={values.Quantity}
                                                                error={Boolean(touched.Quantity && errors.Quantity)}
                                                                selectionColor={esiColor.TextSelection}
                                                                theme={{
                                                                    colors: {
                                                                        primary: esiColor.TextHighlight,
                                                                        text: esiColor.Text,
                                                                        placeholder: esiColor.TextPlaceholder
                                                                    }
                                                                }}
                                                                outlineColor={esiColor.TextOuterLine}
                                                                underlineColor={esiColor.TextUnderline}
                                                                activeOutlineColor={esiColor.TextOuterLine}
                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.Quantity && errors.Quantity)}>
                                                                {touched.Quantity && errors.Quantity}
                                                            </HelperText>
                                                        </View>
                                                        <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>

                                                            <EsiSearchDropdown
                                                                style={[styles.dropdown]}
                                                                placeholderStyle={styles.placeholderStyle}
                                                                selectedTextStyle={styles.selectedTextStyle}
                                                                inputSearchStyle={styles.inputSearchStyle}
                                                                iconStyle={styles.iconStyle}
                                                                containerStyle={styles.containerstyle}
                                                                data={productopt}
                                                                labelField="label"
                                                                valueField="value"
                                                                maxHeight={productopt?.length > 1 ? 250 : 200}
                                                                search
                                                                searchPlaceholder={'Select Size'}
                                                                placeholder={productopt ? 'Select Size' : '...'}
                                                                value={productopt?.Measurement_Type}
                                                                onUpdateValue={item => {

                                                                    item &&
                                                                        setFieldValue('Measurement_Type', item?.value);
                                                                }}

                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.Measurement_Type && errors.Measurement_Type)}>
                                                                {touched.Measurement_Type && errors.Measurement_Type}
                                                            </HelperText>
                                                        </View>

                                                        <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>

                                                            <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Description:</Text>
                                                            <TextInput
                                                                error={Boolean(touched.Description && errors.Description)}
                                                                containerStyle={{ height: 170 }}
                                                                style={{ height: 170, backgroundColor: esiColor.TextBC }}
                                                                mode='outlined'
                                                                value={values.Description}
                                                                textareavalueUpdate={value => {
                                                                    setFieldValue("Description", value)
                                                                }}
                                                                placeholder={'Please provide additional information'}
                                                                underlineColorAndroid={'transparent'}
                                                                selectionColor={esiColor.TextSelection}
                                                                theme={{
                                                                    colors: {
                                                                        primary: esiColor.TextHighlight,
                                                                        text: esiColor.Text,
                                                                        placeholder: esiColor.TextPlaceholder
                                                                    }
                                                                }}
                                                                outlineColor={esiColor.TextOuterLine}
                                                                underlineColor={esiColor.TextUnderline}
                                                                activeOutlineColor={esiColor.TextOuterLine}
                                                            />
                                                        </View>
                                                        <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                            <TextInput
                                                                style={{ width: "100%", backgroundColor: esiColor.TextBC }}
                                                                label="Pincode*"
                                                                mode='outlined'
                                                                onChangeText={(text: any) => { setFieldValue("Pincode", text.replace(/\D/gm, '')) }}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={values.Pincode}
                                                                error={Boolean(touched.Pincode && errors.Pincode)}
                                                                selectionColor={esiColor.TextSelection}
                                                                theme={{
                                                                    colors: {
                                                                        primary: esiColor.TextHighlight,
                                                                        text: esiColor.Text,
                                                                        placeholder: esiColor.TextPlaceholder
                                                                    }
                                                                }}
                                                                outlineColor={esiColor.TextOuterLine}
                                                                underlineColor={esiColor.TextUnderline}
                                                                activeOutlineColor={esiColor.TextOuterLine}
                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.Pincode && errors.Pincode)}>
                                                                {touched.Pincode && errors.Pincode}
                                                            </HelperText>
                                                        </View>
                                                        <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>
                                                            <EsiDatePicker
                                                                theme={esiColor.pagination_theme}
                                                                style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                                                                name={'Bidding_Expiry_Date'}
                                                                label={'Bidding Expiry Date'}
                                                                value={values.Bidding_Expiry_Date}
                                                                valueUpdate={item => {
                                                                    setFieldValue('Bidding_Expiry_Date', item)
                                                                }}
                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.Measurement_Type && errors.Measurement_Type)}>
                                                                {touched.Measurement_Type && errors.Measurement_Type}
                                                            </HelperText>
                                                        </View>
                                                        <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                            <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {

                                                                handleSubmit()
                                                            }}>
                                                                <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                                                            </Button>

                                                        </View>
                                                    </View>}
                                        </View>)}
                                </Formik>
                            </Surface>
                        </ScrollView>
                    </View>
                </Modal>
            </View>
        </Surface>
    )
}