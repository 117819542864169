import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView } from 'react-native';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import { getAllCoupons, getAllSBCPartnerCityCouponAction, createSBCPartnerCityCouponAction } from '../../../state/actions/sbcpartnercityCouponAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//StatusOption used to get the data from dropdown
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const AddSBCPartnercitycoupon = (props: any) => {
    const esiColor = useSelector(state => state.theme);
    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    //toast for popup after successful API calls.
    const toast = useToast();
    const EmailID = useSelector(state => state.auth?.user);


    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);

    //isSubmitted contains the data from Create API using state call
    const isSubmited = useSelector(state => state.sbcpartnercitycoupon.sbcpartnercitycouponCreate.isLoading);

    //sbcpartnercitycoupons and sbcpartnercitycouponnames contains the data from API's using state call
    const sbcpartnercitycoupons = useSelector(state => state.sbcpartnercitycoupon.sbcpartnercitycoupons.all);
    const sbcpartnercitycouponnames = useSelector(state => state.sbcpartnercitycoupon.sbcpartcitycoupons.coupons);
    //partnercitycouponadd contains the data from Get page(Partner City Coupon Get) using props
    const partnercityid = props.route?.params?.partnercityid;
    const partnerid = props.route?.params?.partnerid;
    const partnercityname = props.route?.params?.partnercityname;
    const partnername = props.route?.params?.partnername;
    const cityid = props.route?.params?.cityid;

    //Dropdowns
    const [statusDropDown, setStatusDropDown] = useState("");
    const [couponsDropDown, setCouponsDropDown] = useState("");

    //dispatch is used to perform the API action
    //static formData

    useEffect(() => {
        let formData =
        {
            Records_Filter: "FILTER",
            Partner_Details_Id: partnerid,
            Partner_Service_City_Id: partnercityid,
        };
        dispatch(getAllSBCPartnerCityCouponAction(formData));

        let formDatacoupon =
        {
            Records_Filter: "FILTER",
            Partner_Details_Id: partnerid,
        };
        dispatch(getAllCoupons(formDatacoupon));
    }, []);

    //couponslist used to store the label,value in an array from API data  
    let couponslist = []
    if (sbcpartnercitycouponnames) {
        if (sbcpartnercitycouponnames.length >= 1) {
            for (let i = 0; i < sbcpartnercitycouponnames.length; i++) {

                couponslist.push({
                    label: sbcpartnercitycouponnames[i].Name,
                    value: sbcpartnercitycouponnames[i].Coupon_Id
                })
            }
        }
        else {
            couponslist.push({
                label: 'No Coupons found!',
                value: null
            })
        }
    }

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //form reset
    const [reload, setReload] = React.useState(false);

    React.useEffect(() => {
        if (reload)
            setReload(false);
    }, [reload])

    /*====================================
            Handler
    ====================================*/

    const values = {
        Coupon_Id: "",
        Status: "",
        Partner_Service_City_Id: partnercityid,
        City_Name: partnercityname,
        Partner_Details_Id: partnerid,
        Partner_Name: partnername,
        Created_By: EmailID.Email_Id,
        City_Id: cityid
    }

    //Form validation
    const Form_Validation = Yup.object().shape({
        Coupon_Id: Yup.string().notOneOf(sbcpartnercitycoupons?.results ? sbcpartnercitycoupons?.results.map(item => item['Title']) : [], "Coupon Name is already exist.").required('Coupon Name is required.'),
        Status: Yup.string().required('Status is required.')
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);

        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(createSBCPartnerCityCouponAction(formValues, navigation, toast, partnercityid, partnerid, partnercityname, partnername, cityid));
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 146, justifyContent: 'flex-start' }}
                                    onPress={() => {
                                        props.resetForm();
                                        setReload(true);
                                        navigation.navigate('SBCPartnercitycouponGet', { partnercityid: partnercityid, partnerid: partnerid, partnercityname: partnercityname, partnername: partnername, cityid: cityid })
                                    }}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                        Add Partner City Coupon
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                            {/* Coupon Name input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Coupon_Id && props.errors.Coupon_Id) && { color: '#b00020' }]}>
                                                Coupon Name
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Coupon_Id && props.errors.Coupon_Id) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={couponslist}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={couponslist.length > 1 ? 250 : 200}
                                                search={couponslist.length > 1 ? true : false}
                                                searchPlaceholder={'Select Coupon Name'}
                                                dropdownPosition="auto"
                                                value={props.values.Coupon_Id}
                                                name="Coupon_Id"
                                                onFocus={() => setCouponsDropDown(true)}
                                                onBlur={() => setCouponsDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Coupon_Id', item.value)
                                                    setCouponsDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Coupon_Id && props.errors.Coupon_Id)}>
                                                <ErrorMessage name="Coupon_Id" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                            {/* Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                                                Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ? true : false}
                                                searchPlaceholder={'Select Status'}
                                                dropdownPosition="auto"
                                                value={props.values.Status}
                                                name="Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                                                <ErrorMessage name="Status" />
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default AddSBCPartnercitycoupon;