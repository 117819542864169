import React, { useState } from 'react';
import { Card, Button, HelperText, Checkbox, Surface, DefaultTheme } from 'react-native-paper';
import { View, Text, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  createProductMasterAction,
} from '../../../../state/actions/productmastersAction';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import EsiEditor from '../../../../components/custom/richtext-editor/richtext-editor';
import Header from '../../../auth/components/header';
import isEmpty from '../../../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddProductMasterpagethree = (props: any) => {
  const { navigation } = props;
  const productmasters = useSelector(state => state.productmasters.productmastersdata.productmaster);
  const esiColor = useSelector(state => state.theme);
  //toast for popup after successful API calls.
  const toast = useToast();
  const dispatch = useDispatch();
  const [rhSpecificationsMin, setRhSpecificationsMin] = useState(20);
  const [specificationsvalue, setSpecificationsValue] = React.useState('');

  //Screen dimension calculting call.
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  React.useEffect(() => {
    if (isEmpty(productmasters)) {
      navigation.navigate('AddProductMasters')
    } else {
      setSpecificationsValue(productmasters.Specifications)
    }
  }, [productmasters]
  );

  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);


  /*====================================
          Handler
  ====================================*/
  return (
    <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView
        ScrollView refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {productmasters &&
          <Formik enableReinitialize={true}
            initialValues={{
              Sku: productmasters.Sku,
              Category: productmasters.Category,
              Sub_Category: productmasters.Sub_Category,
              Brand: productmasters.Brand,
              Product_Name: productmasters.Product_Name,
              Product_Type: productmasters.Product_Type,
              Pattern_Name: productmasters.Pattern_Name,
              Upc_Code: productmasters.Upc_Code,
              Height: productmasters.Height,
              Weight: productmasters.Weight,
              Width: productmasters.Width,
              Length: productmasters.Length,
              Manufacturer: productmasters.Manufacturer,
              Manufacturer_Contact: productmasters.Manufacturer_Contact,
              Manufacturer_Cost: productmasters.Manufacturer_Cost,
              Warranty_Period: productmasters.Warranty_Period,
              Mrp: productmasters.Mrp,
              Max_Mrp: productmasters.Max_Mrp,
              Min_Mrp: productmasters.Min_Mrp,
              Short_Description: productmasters.Short_Description,
              Created_By: productmasters.Created_By,
              Features: productmasters.Features,
              Is_Product_Active: false,
              Long_Description: productmasters.Long_Description,
              Sales_Start_Date: productmasters.Sales_Start_Date,
              Specifications: "",
              Status: "In-Active",
            }}
            onSubmit={(values: any, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              const errors = {};
              values.Specifications = specificationsvalue;
              if (!values.Specifications || values.Specifications.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
                submitTest = false;
                errors.Specifications = "Specifications are required.";
              }
              else if (values.Specifications.replace(/(<([^>]+)>)/ig, '').trim().length < rhSpecificationsMin) {
                submitTest = false;
                errors.Specifications = "Please provide at least " + rhSpecificationsMin + " characters";
              }
              setErrors(errors);
              if (submitTest) {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.Is_Product_Active) {
                  formValues.Is_Product_Active = 1;
                } else {
                  formValues.Is_Product_Active = 0;
                }
                dispatch(createProductMasterAction(formValues, navigation, toast));
              }

            }
            }
          >
            {({
              errors,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}>
                <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('AddProductMasters')} >
                    Go Back
                  </Button>
                </View>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Add Product Master
                    </Text>
                    <EsiEditor label={"Specifications"} value={specificationsvalue} onUpdateValue={(value) => { setSpecificationsValue(value) }} />
                    <HelperText type="error" visible={(!specificationsvalue || specificationsvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhSpecificationsMin) && touched.Specifications
                      && errors.Specifications}>
                      {(!specificationsvalue || specificationsvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhSpecificationsMin) && touched.Specifications
                        && errors.Specifications}
                    </HelperText>
                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                      <Checkbox.Android
                        color={esiColor.SIconColor}
                        uncheckedColor={esiColor.SBorderColor}
                        name="Is_Product_Active"
                        status={values.Is_Product_Active ? 'checked' : 'unchecked'}
                        onPress={() => {
                          setFieldValue("Is_Product_Active", !values.Is_Product_Active);
                        }}
                      />
                      <Text style={{ marginTop: 10, color: esiColor.DescColor }}>Is Product Master Active</Text>
                    </View>
                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                      <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                        <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                      </Button>
                    </View>
                  </Card.Content>
                </Card>
              </View>
            )}
          </Formik>}
      </ScrollView>
    </Surface >
  );
};


export default AddProductMasterpagethree;