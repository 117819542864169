import { Platform, UIManager, LayoutAnimation, Dimensions } from "react-native";
import { useCallback, useState } from 'react';

export const isObjEmpty = (obj) => {
  if (obj == null) return true;
  if (obj === null) return true;
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;
  if (typeof obj !== "object") return true;
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }
  return true;
}

export const enableExperimental = () => {
  if (Platform.OS === "android") {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
};

export const heightHeader = () => {
  const width = Dimensions.get("window").width;
  const height = Dimensions.get("window").height;
  const landscape = width > height;

  if (Platform.OS === "android") return 40;
  if (Platform.isPad) return 40;
  switch (height) {
    case 375:
    case 414:
    case 812:
    case 896:
      return landscape ? 30 : 40;
    default:
      return landscape ? 30 : 40;
  }
};


export const isNumber = (number) => {
  if (/^\d+$/.test(number) || number == "") {
    return true;
  } else {
    return false;
  }
}



export const useComponentSize = () => {
  const [size, setSize] = useState(null);

  const onLayout = useCallback(event => {
    const { width, height } = event.nativeEvent.layout;
    setSize({ width, height });
  }, []);

  return [size, onLayout];
};
