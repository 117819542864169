import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  getAllBrands,
  getAllCategories,
  getAllSubCategories,
  productmastersdata,
  getProductByIdAction
} from '../../../../state/actions/productmastersAction';
import { Card, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import EsiEditor from '../../../../components/custom/richtext-editor/richtext-editor';
import Header from '../../../auth/components/header';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import isEmpty from '../../../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const EditProductMastertwo = (props: any) => {
  const productmasters = useSelector(state => state.productmasters?.productmastersdata?.productmaster);

  const { navigation } = props;
  const dispatch = useDispatch();

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [rhSpecificationsMin, setRhSpecificationsMin] = useState(20);
  const [specificationsvalue, setSpecificationsValue] = React.useState();
  const EmailID = useSelector(state => state.auth?.user);
  const ID = props?.route?.params?.ID;
  const CID = props?.route?.params?.CID;
  const esiColor = useSelector(state => state.theme);

  const [rhFeaturesMin, setRhFeaturesMin] = useState(20);
  const [featuresvalue, setFeaturesValue] = React.useState();

  const [data, setData] = useState([]);

  const callbackProductData = (data: any) => {
    setData(data)
  }
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (ID) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Brand_Id": "",
        "Category_Id": "",
        "Is_Product_Active": "",
        "Min_Mrp": "",
        "Partner_Details_Id": "",
        "Product_Master_Id": ID,
        "Product_Name": "",
        "Records_Filter": "FILTER",
        "Sku": "",
        "Status": "",
        "Upc_Code": "",
        "Warranty_Period": ""
      };
      dispatch(getAllCategories());
      dispatch(getAllBrands());
      dispatch(getAllSubCategories());
      dispatch(getProductByIdAction(formData, callbackProductData));
    }
  }, [ID]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  React.useEffect(() => {
    if (isEmpty(productmasters)) {
      navigation.navigate('EditProductMasters', { ID, CID })
    } else {
      setFeaturesValue(productmasters.Features)
      setSpecificationsValue(productmasters.Specifications)
    }
  }, [productmasters]
  );
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  return (
    <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {specificationsvalue && <Formik
          initialValues={{
            Product_Master_Id: productmasters.Product_Master_Id,
            Sku: productmasters.Sku,
            Category: productmasters.Category,
            Sub_Category: productmasters.Sub_Category,
            Brand: productmasters.Brand,
            Product_Name: productmasters.Product_Name,
            Product_Type: productmasters.Product_Type,
            Pattern_Name: productmasters.Pattern_Name,
            Upc_Code: productmasters.Upc_Code,
            Height: productmasters.Height,
            Weight: productmasters.Weight,
            Width: productmasters.Width,
            Length: productmasters.Length,
            Manufacturer: productmasters.Manufacturer,
            Manufacturer_Contact: productmasters.Manufacturer_Contact,
            Manufacturer_Cost: productmasters.Manufacturer_Cost,
            Mrp: productmasters.Mrp,
            Max_Mrp: productmasters.Max_Mrp,
            Min_Mrp: productmasters.Min_Mrp,
            Short_Description: productmasters.Short_Description,
            Modified_By: EmailID.Email_Id,
            Features: data.Features,
            Warranty_Period: productmasters.Warranty_Period,
            Is_Product_Active: data.Is_Product_Active > 0 ? true : false,
            Long_Description: productmasters.Long_Description,
            Sales_Start_Date: productmasters.Sales_Start_Date,
            Specifications: data.Specifications,
            Status: productmasters.Status,
          }}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            let submitTest = true;
            setSubmitting(false);
            const errors = {};
            values.Specifications = specificationsvalue;
            values.Features = featuresvalue;
            if (!values.Specifications || values.Specifications?.replace(/(<([^>]+)>)/ig, '')?.trim().length == 0) {
              submitTest = false;
              errors.Specifications = "Specifications is required.";
            }
            else if (values.Specifications.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhSpecificationsMin) {
              submitTest = false;
              errors.Specifications = "Please provide at least " + rhSpecificationsMin + " characters.";
            }
            if (!values.Features || values.Features.replace(/(<([^>]+)>)/ig, '')?.trim().length == 0) {
              submitTest = false;
              errors.Features = "Features is required.";
            }
            else if (values.Features.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhFeaturesMin) {
              submitTest = false;
              errors.Features = "Please provide at least " + rhFeaturesMin + " characters.";
            }
            setErrors(errors);
            if (submitTest) {
              let formValues = JSON.parse(JSON.stringify(values));
              dispatch(productmastersdata(formValues));
              navigation.navigate('EditProductmasters', { ID: productmasters.Product_Master_Id, CID: CID })
            }
          }
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (

            <View style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              flexWrap: "wrap"
            }}>
              <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%" }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('EditProductMaster', { ID, CID })}>
                  Go Back
                </Button>
              </View>
              <ScrollView>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Edit Product Master
                    </Text>
                    <EsiEditor label={"Specifications"} value={specificationsvalue} onUpdateValue={(value) => { setSpecificationsValue(value) }} />
                    <HelperText type="error" visible={(!specificationsvalue || specificationsvalue?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhSpecificationsMin) && touched.Specifications
                      && errors.Specifications}>
                      {(!specificationsvalue || specificationsvalue?.replace(/(<([^>]+)>)/ig, '')?.trim().length < rhSpecificationsMin) && touched.Specifications
                        && errors.Specifications}
                    </HelperText>
                    <EsiEditor label={"Features"} value={featuresvalue} onUpdateValue={(value) => { setFeaturesValue(value) }} />
                    <HelperText type="error" visible={(!featuresvalue || featuresvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhFeaturesMin) && touched.Features && errors.Features}>
                      {(!featuresvalue || featuresvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhFeaturesMin) && touched.Features && errors.Features}
                    </HelperText>

                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                      <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                        <Text style={{ color: esiColor.itemButtenColor }}>  Next</Text>
                      </Button>
                    </View>
                  </Card.Content>
                </Card>
              </ScrollView>
            </View>

          )}
        </Formik>}
      </ScrollView>
    </Surface>
  );
};

export default EditProductMastertwo;