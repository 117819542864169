import {
    CITY_ID_GET_REQUEST,
    CITY_ID_GET_SUCCESS,
    CITY_ID_GET_FAIL,
    LOCATION_SERVICES_GET_REQUEST,
    LOCATION_SERVICES_GET_SUCCESS,
    LOCATION_SERVICES_GET_FAIL,


    LOCATION_ID_GET_REQUEST,
    LOCATION_ID_GET_SUCCESS,
    LOCATION_ID_GET_FAIL,

    ADDRESS_ID_GET_REQUEST,
    ADDRESS_ID_GET_SUCCESS,
    ADDRESS_ID_GET_FAIL,

    SHOP_BY_CITY_LOCATION_GET_REQUEST,
    SHOP_BY_CITY_LOCATION_GET_SUCCESS,
    SHOP_BY_CITY_LOCATION_GET_FAIL,

    SHOP_BY_CITY_LOCATION_CREATE_REQUEST,
    SHOP_BY_CITY_LOCATION_CREATE_SUCCESS,
    SHOP_BY_CITY_LOCATION_CREATE_FAIL,

    SHOP_BY_CITY_LOCATION_UPDATE_REQUEST,
    SHOP_BY_CITY_LOCATION_UPDATE_SUCCESS,
    SHOP_BY_CITY_LOCATION_UPDATE_FAIL,

    SHOP_BY_CITY_LOCATION_FILTER,

} from '../actions/partnershopbylocationAction'

const initialState = {
    citys: { all: [], error: '', isLoading: false },
    location: { location: {}, error: '', isLoading: false },
    services: { services: {}, error: '', isLoading: false },
    address: { address: {}, error: '', isLoading: false },
    shopbylocation: { all: [], error: '', isLoading: false },
    shoplocationCreate: { shoplocation: {}, error: '', isLoading: false },
    shoplocationUpdate: { location: {}, error: '', isLoading: false },
    shopbycitylocationFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case CITY_ID_GET_REQUEST:
            return { ...state, citys: { all: state.citys.all, error: '', isLoading: true } };
        case CITY_ID_GET_SUCCESS:
            return { ...state, citys: { all: action.payload, error: '', isLoading: false } };
        case CITY_ID_GET_FAIL:
            return { ...state, citys: { all: [], error: action.payload, isLoading: false } };

        case LOCATION_ID_GET_REQUEST:
            return { ...state, location: { location: {}, error: '', isLoading: true } };
        case LOCATION_ID_GET_SUCCESS:
            return { ...state, location: { location: action.payload, error: '', isLoading: false } };
        case LOCATION_ID_GET_FAIL:
            return { ...state, location: { location: {}, error: action.payload, isLoading: false } };
            case LOCATION_SERVICES_GET_REQUEST:
                return { ...state, services: { services: {}, error: '', isLoading: true } };
            case LOCATION_SERVICES_GET_SUCCESS:
                return { ...state, services: { services: action.payload, error: '', isLoading: false } };
            case LOCATION_SERVICES_GET_FAIL:
                return { ...state, services: { services: {}, error: action.payload, isLoading: false } };
            
        case ADDRESS_ID_GET_REQUEST:
            return { ...state, address: { address: {}, error: '', isLoading: true } };
        case ADDRESS_ID_GET_SUCCESS:
            return { ...state, address: { address: action.payload, error: '', isLoading: false } };
        case ADDRESS_ID_GET_FAIL:
            return { ...state, address: { address: {}, error: action.payload, isLoading: false } };

        case SHOP_BY_CITY_LOCATION_GET_REQUEST:
            return { ...state, shopbylocation: { all: state.shopbylocation.all, error: '', isLoading: true } };
         case SHOP_BY_CITY_LOCATION_GET_SUCCESS:
            return { ...state, shopbylocation: { all: action.payload, error: '', isLoading: false } };
            case SHOP_BY_CITY_LOCATION_GET_FAIL:
            return { ...state, shopbylocation: { all: [], error: action.payload, isLoading: false } };
            
        case SHOP_BY_CITY_LOCATION_CREATE_REQUEST:
            return { ...state, shoplocationCreate: { shoplocation: {}, error: '', isLoading: true } };
        case SHOP_BY_CITY_LOCATION_CREATE_SUCCESS:
            return { ...state, shoplocationCreate: { shoplocation: state.shoplocationCreate.shoplocation, error: '', isLoading: false } };
        case SHOP_BY_CITY_LOCATION_CREATE_FAIL:
            return { ...state, shoplocationCreate: { shoplocation: {}, error: action.payload, isLoading: false } };

        case SHOP_BY_CITY_LOCATION_UPDATE_REQUEST:
            return { ...state, shoplocationUpdate: { shoplocation: {}, error: '', isLoading: true } };
        case SHOP_BY_CITY_LOCATION_UPDATE_SUCCESS:
            return { ...state, shoplocationUpdate: { shoplocation: state.shoplocationUpdate.shoplocation, error: '', isLoading: false } };
        case SHOP_BY_CITY_LOCATION_UPDATE_FAIL:
            return { ...state, shoplocationUpdate: { shoplocation: {}, error: action.payload, isLoading: false } };
            case SHOP_BY_CITY_LOCATION_FILTER:
                let res = { ...state };
                if (action.fType === "select") {
                    res = { ...state, shopbycitylocationFilter: { search: state.shopbycitylocationFilter.search, select: action.value } }
                }
                else if (action.fType === "search") {
                    res = { ...state, shopbycitylocationFilter: { select: state.shopbycitylocationFilter.select, search: action.value } };
                }
                return res;
    
            default:
                return state;
        }
    }