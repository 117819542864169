import React, { useState, useEffect } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, ScrollView, Dimensions, Image, TouchableOpacity, Linking, Platform, RefreshControl } from 'react-native';
import { Text, Surface, ActivityIndicator, Button } from 'react-native-paper';
import isEmpty from '../../state/validations/is-empty';
import { getAllContentManagementStaticAction } from '../../state/actions/contentActions';
import Header from '../auth/components/header';
import Headers from '../Farmer-Module/header';
export default function ContactUs(props: any) {
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const { navigation } = props;
    const page = props.route?.params?.page;
    const [contentsOne, setContentsOne] = useState({});
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    const MY_URL = 'https://www.google.com/maps/place/EasyStepIn+IT+Services+Private+Limited/@12.9560352,77.6945393,19z/data=!4m13!1m7!3m6!1s0x3bae13cc14d607cb:0x5eaf25d9f64c2215!2sEasyStepIn+IT+Services+Private+Limited!3b1!8m2!3d12.9560347!4d77.6950702!3m4!1s0x3bae13cc14d607cb:0x5eaf25d9f64c2215!8m2!3d12.9560347!4d77.6950702'
    const responseFunction = (data) => {
        setContentsOne(data)
    }

    useEffect(() => {
        if (isEmpty(contentsOne)) {
            let formdata = {
                "Cms_Static_Id": "",
                "Name": "Partner_Contactus",
                "Records_Filter": "FILTER"
            }
            dispatch(getAllContentManagementStaticAction(formdata, responseFunction));
        }
    }, [contentsOne]);
    const tagsStyles = {
        p: {
          color: esiColor.itemColor        
        }   
      };

    return (
        <Surface style={{ flex: 1,backgroundColor: esiColor.BackgroundColor }}>
            {page === 'farmer' ? <Headers navigation={navigation} /> : <Header navigation={navigation} />}
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ flexDirection: 'column', margin: 5 }}>
                    {/* {ID ? <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                        <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                            navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } });
                           }
                        }>
                            Go Back
                        </Button>
                    </View>:<View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130,height:50, justifyContent: 'flex-start' }} onPress={() => navigation.navigate("B2C",{screen:'Dashboard'})}>
                        Go Back
                    </Button>
                </View>} */}
                    <Text style={{color:esiColor.brandFontColor, fontSize: 20, fontWeight: "bold", justifyContent: 'center', textAlign: 'center', margin: 5 }}>
                        Contact Us
                    </Text>
                    <View style={{ justifyContent: 'center', alignItems: 'center' }} >
                        <Image source={require('../../assets/images/cms.png')} style={{ padding: 3, flex: 1, flexWrap: 'wrap', height: Platform.OS === 'web' ? 110 : 110, width: Platform.OS === 'web' ? 350 : 350, borderRadius: 10 }} />
                    </View>
                    <View
                        style={{
                            flex: 1,
                            marginHorizontal: 2, borderRadius: 25, padding: 5, margin: 'auto', backgroundColor: esiColor.BackgroundColor, flexDirection: 'column', shadowColor: '#000', borderColor: "#CACFD2", borderWidth: 0.5,
                            shadowOffset: { width: 0, height: 2 },
                            marginTop: 5,
                            justifyContent: 'center',
                            alignItems: 'center',
                            shadowOpacity: 0.2,
                            shadowRadius: 10,
                            paddingTop: 5,
                            width: dimensions >= 500 ? "75%" : "98%",
                            marginLeft: "auto", marginRight: "auto",
                            flexWrap: 'wrap'
                        }}
                    >
                        <View style={{ margin: 5, justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap' }} >
                            <TouchableOpacity onPress={() => Linking.openURL(MY_URL)}>
                                <View style={{ flexDirection: 'row', height: 60, marginLeft: -5 }}>
                                    <Image source={require('../../assets/images/location.gif')} style={{ padding: 30, flexWrap: 'wrap', borderRadius: 5, height: 3, width: 3 }} />
                                    <Text style={{ marginTop: 25, fontWeight: 'bold', fontSize: 15 ,color:esiColor.itemColor}}>EasyStepIn IT Services Private Limited</Text>
                                </View>
                            </TouchableOpacity>
                            {!isEmpty(contentsOne) ? (contentsOne?.Description ?
                                <View style={{ flexDirection: 'row' }}>
                                    <RenderHTML tagsStyles={tagsStyles}
                                        source={{ html: contentsOne?.Description }} />
                                </View> : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                    <Text style={{color:esiColor.itemColor}}>No Data found!</Text></View>) :
                                <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                    <ActivityIndicator color='#27b6cc' animating={true} style={{ marginTop: 25 }} />
                                </View>}
                        </View>

                    </View>
                </View>

            </ScrollView>
        </Surface>

    )
}