import axios from 'axios';
import config from '../config';
import shopperconfig from '../shoppers/shopperconfig';
import { meeapi } from '../node-actions/meeapi';
export const ALL_FARMER_ORDERS_REQUEST = "ALL_FARMER_ORDERS_REQUEST";
export const ALL_FARMER_ORDERS_SUCCESS = "ALL_FARMER_ORDERS_SUCCESS";
export const ALL_FARMER_ORDERS_FAIL = "ALL_FARMER_ORDERS_FAIL";
export const FARMER_ORDERS_FILTER = "FARMER_ORDERS_FILTER";
export const SINGLE_ORDER_REQUEST = "SINGLE_ORDER_REQUEST";
export const SINGLE_ORDER_SUCCESS = "SINGLE_ORDER_SUCCESS";
export const SINGLE_ORDER_FAIL = "SINGLE_ORDER_FAIL";
const nodeheaders = shopperconfig.nodeheadersCommon;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const headers = config.headersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
      queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}

/*=============================================================
                  Get All Orders Action
===============================================================*/
export const getAllFARMEROrdersAction = (formData: any,pagination = {}) => async (dispatch: any) => {
    try {
    dispatch({
      type: ALL_FARMER_ORDERS_REQUEST
    });
    meeapi.post(urlGenarator(`/Order_Management/Order_GetFlow`,pagination),
      formData,
    ) .then(response => {
      let data = response.data;  
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_FARMER_ORDERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ALL_FARMER_ORDERS_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {  
    dispatch({
      type: ALL_FARMER_ORDERS_FAIL,
      payload: err
    });
  }
};
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: FARMER_ORDERS_FILTER,
      fType: "search",
      value: "",
    });
  }
}

export const getsingleorderAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: SINGLE_ORDER_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Order_Management/Order_GetFlow`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SINGLE_ORDER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SINGLE_ORDER_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SINGLE_ORDER_FAIL,
      payload: err
    });
  }
};

export const InvoiceAction = (formData: any, updateStates: any) => async (dispatch) => {
  axios.post(`${shopperconfig.nodePartnerUrl}/B2C_Invoice_Generation/Invoice_Generation`, formData, {
    headers: nodeheaders
  })
      .then(response => {
          let successResponse = response.data.B2C_Invoivce_Response;
        
          updateStates(true, successResponse);
      })
      .catch(error => {
 updateStates(false, null);
      })
};
