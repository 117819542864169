import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Image, Dimensions, TouchableOpacity, RefreshControl } from 'react-native';
import { Dialog,Card, DataTable, Button, Text, Searchbar, Portal, Surface, IconButton, TextInput, HelperText } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../auth/components/header';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import isEmpty from '../../state/validations/is-empty';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import { getAllOrdersAction, OrdersFilter, updateOrderAction,resetFilter } from '../../state/actions/ordermanagementAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
//for dropdown
const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Order_Id',
    label: 'Order ID'
  },
  {
    value: 'User_Email_Id',
    label: 'User Email ID'
  },
  {
    value: 'Order_Status',
    label: 'Status'
  }
];
const numberOfItemsPerPageList = [5, 10, 20];
const Ordersget=(props: any)=> {
  const { navigation } = props;
  const toast = useToast();
  const [page, setPage] = React.useState(0);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const orders = useSelector(state => state.ordersmanagement.orders.all);
  let orderMenu = useSelector(state => state.ordersmanagement.orderFilter.select);
  let orderSearch = useSelector(state => state.ordersmanagement.orderFilter.search);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const EmailID = useSelector(state => state.auth?.user);
  const to = Math.min((page + 1) * numberOfItemsPerPage, orders?.length);

  const [showDropDown, setShowDropDown] = React.useState(false);
  const handleClickOrderDetails = async (allorder) => {
    props.navigation.navigate('OrderDetails', { orders: allorder })
  };
  const [Order, setOrders] = React.useState();
  const dispatch = useDispatch()
  React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
  // React.useEffect(() => {
  //   if(isEmpty(orders)){
  //   let formData = {
  //     "Records_Filter": "ALL",
  //     "Partner_Details_Id": EmailID?.Partner_Details_Id
  //   };
  //   setOrders(orders);
  //   handleSearch("");
  //   dispatch(getAllOrdersAction(formData, toast))
  //   dispatch(OrdersFilter('search', ''));
  //   dispatch(OrdersFilter('select', 'All'));
  // }

  // }, [orders]);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  //search
  const [oldSearch, setOldSearch] = React.useState(0);

  const checkOrdersForTable = () => {

    if (orderSearch) {
      try {
        if (orderMenu === "All") {
          return orders.filter(x => {
            let keys = ["User_Email_Id", "Order_Id", "Order_Status"];
            for (let i = 0; i < keys.length; i++) {
              try {
                if (x[keys[i]].toLowerCase().includes(orderSearch.toLowerCase())) {
                  return true;
                }
              } catch (error) {
                if (x[keys[i]].toString().toLowerCase().includes(orderSearch.toLowerCase())) {
                  return true;
                }
              }
            }
          });

        } else {
          return orders.filter(x => (x[orderMenu] ? x[orderMenu].toLowerCase() : '').includes(orderSearch.toLowerCase()));
        }
      } finally {
        if (page > 0) {
          if (oldSearch !== orderSearch.length) {
            setOldSearch(orderSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch !== orderSearch.length) {
          setOldSearch(orderSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return orders
    }
  }
  //sorting
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Order_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'descending'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {

      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  const [visible, setVisible] = React.useState(false);
  const [allorder, setData] = React.useState();
  const [visibleone, setVisibleOne] = React.useState(false);
  const [note, setNote] = React.useState();
  const hideDialog = () => setVisible(false);
  const [Error, setError] = React.useState("");
  const handleChange = itemValue => {
    dispatch(OrdersFilter('select', itemValue));
  };
  const handleSearch = value => {
    dispatch(OrdersFilter('search', value));
  };
  const data = stableSort(
    checkOrdersForTable(), getComparator(order, orderBy))
  const handleClick = (allorder) => {
    if (allorder) {
      const updateData = {
        Is_Approved: '1',
        Item_Master_Id: allorder.Item_Master_Id,
        Modified_By: EmailID.Email_Id,
        Notes: note,
        Order_Id: allorder.Order_Id,
        Product_Id: allorder.Product_Id,
        Status: allorder.Order_Status,
      };
      let submitTest = true;
      let errors
      updateData.Notes = note;
      if (!note) {
        submitTest = false;
        setError("Notes is required.");
      } else {
        setError('');

        dispatch(updateOrderAction(updateData, allorder, navigation, toast, EmailID?.Partner_Details_Id))
        setVisible(!visible)
      }
    }
  };
  const handleClicks = (allorder) => {
    if (allorder) {
      const updateData = {
        Is_Approved: '2',
        Item_Master_Id: allorder.Item_Master_Id,
        Modified_By: EmailID.Email_Id,
        Notes: note,
        Order_Id: allorder.Order_Id,
        Product_Id: allorder.Product_Id,
        Status: allorder.Order_Status,
      };
      let submitTest = true;
      let errors
      updateData.Notes = note;
      if (!note) {
        submitTest = false;
        setError("Notes is required.");
      } else {
        setError('');
        dispatch(updateOrderAction(updateData, allorder, navigation, toast, EmailID?.Partner_Details_Id));
        setVisibleOne(!visibleone)
      }
    }
  };
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
      return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
      setRefreshing(true);
      wait(2000).then(() => setRefreshing(false));
  }, []);
  return (
    <Surface style={{
      flex: 1
    }}>
      <Header navigation={navigation}></Header>
        <Portal>
          <Dialog visible={visible} onDismiss={hideDialog} style={{ width: (dimensions >= 500) ? 400 : 300, maxHeight: (dimensions >= 500) ? 500 : 350, marginLeft: 'auto', marginRight: 'auto' }}>
            <View style={{ alignItems: 'flex-end' }}>
              <IconButton
                icon="close"
                size={20}
                onPress={() => setVisible(!visible)}
              />
            </View>
            <Dialog.Title style={{ textAlign: "center", marginTop: -20, fontSize: 20 }}>
              <View style={{ flexDirection: "row" }}>
                <Image style={{
                  width: 35,
                  height: 35,
                }} source={require('../../assets/images/orders/warnings.jpg')} />
                <View style={{ width: 20 }} />
                <Text style={{ color: '#27B6CC', fontSize: 25 }}>Alert!</Text>
              </View>

            </Dialog.Title>
            <Dialog.ScrollArea>
              <ScrollView >
                <Dialog.Content>
                  <View>
                    <Text style={{ marginTop: 20, marginBottom: 10, fontSize: 16, fontWeight: 'bold' }}>Are you sure you want to Accept?</Text>
                    <View>
                      <TextInput
                        style={{ backgroundColor: 'white' }}
                        theme={{ colors: { primary: '#27B6CC' } }}
                        label="Notes"
                        mode='outlined'
                        multiline
                        value={note}
                        onChangeText={(value) => { setNote(value) }}
                      />
                      <HelperText type="error" visible={!note}>
                        <Text style={{ color: 'red' }}>{Error}</Text>
                      </HelperText>
                    </View>
                    <View style={{ justifyContent: 'center', alignSelf: 'center', marginTop: 15 }}>
                      <Button style={{ margin: 'auto', justifyContent: 'center', width: 120 }} color="#27B6CC" mode="contained" onPress={() => { handleClick(allorder) }}>
                        <Text style={{ color: "white" }}>Yes</Text>
                      </Button>
                    </View>
                  </View>
                </Dialog.Content>
              </ScrollView>
            </Dialog.ScrollArea>
          </Dialog>
          <Dialog visible={visibleone} onDismiss={hideDialog} style={{ width: (dimensions >= 500) ? 400 : 300, maxHeight: (dimensions >= 500) ? 500 : 350, marginLeft: 'auto', marginRight: 'auto', }}>
            <View style={{ alignItems: 'flex-end' }}>
              <IconButton
                icon="close"
                size={20}
                onPress={() => setVisibleOne(!visibleone)}
              />
            </View>
            <Dialog.Title style={{ textAlign: "center", marginTop: -20, fontSize: 20 }}>
              <View style={{ flexDirection: "row" }}>
                <Image style={{
                  width: 35,
                  height: 35,
                }} source={require('../../assets/images/orders/warnings.jpg')} />
                <View style={{ width: 20 }} />
                <Text style={{ color: '#27B6CC', fontSize: 28, }}>Alert!</Text>
              </View>

            </Dialog.Title>
            <Dialog.ScrollArea>
              <ScrollView >
                <Dialog.Content>
                  <View>
                    <Text style={{ marginTop: 20, marginBottom: 10, fontSize: 16, fontWeight: 'bold' }}>Are you sure you want to Reject?</Text>
                    <View>
                      <TextInput
                        style={{ backgroundColor: 'white' }}
                        theme={{ colors: { primary: '#27B6CC' } }}
                        label="Notes"
                        mode='outlined'
                        multiline
                        value={note}
                        onChangeText={(value) => { setNote(value) }}
                      />
                      <HelperText type="error" visible={!note}>
                        <Text style={{ color: 'red' }}>{Error}</Text>
                      </HelperText>
                    </View>
                    <View style={{ justifyContent: 'center', alignSelf: 'center', marginTop: 15 }}>
                      <Button style={{ margin: 'auto', justifyContent: 'center', width: 120 }} color="#27B6CC" mode="contained" onPress={() => { handleClicks(allorder) }}>
                        <Text style={{ color: "white" }}>Yes</Text>
                      </Button>
                    </View>
                  </View>
                </Dialog.Content>
              </ScrollView>
            </Dialog.ScrollArea>
          </Dialog>
        </Portal>
        <View>
          <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {navigation.navigate('OrderMainCenter')
        handleSearch("")
        dispatch(resetFilter())
        }} >
            Go Back
          </Button>
        </View>
        <ScrollView refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  colors={['#27B6CC']}
                  tintColor={'#27B6CC'}
                  title={"Pull to refresh"}
                  titleColor={"#27B6CC"}
                />
              }>
          <View style={{
            flexDirection: "column"
          }}>
            <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10 }}>Order Management</Text>
            <View style={{
              flexDirection: "row", paddingTop: 10,
            }}>
              <View style={{ flex: 1.7 }} />
              <View style={{ flex: 0.1 }} />
            </View>
          </View>
          <View style={{
            flexDirection: "row", paddingTop: 20,
          }}>
            <View style={{ flex: dimensions <= 700 ? 0.15 : 0.04 }} />
            <View style={{ flex: dimensions <= 700 ? 1.5 : 0.5 }}>
              {Platform.OS !== "web" ?
                <EsiSearchDropdown
                  style={[style.dropdown]}
                  placeholderStyle={style.placeholderStyle}
                  selectedTextStyle={style.selectedTextStyle}
                  inputSearchStyle={style.inputSearchStyle}
                  iconStyle={style.iconStyle}
                  data={filterOptions}
                  containerStyle={style.containerstyle}
                  maxHeight={180}
                  labelField="label"
                  valueField="value"
                  placeholder={!showDropDown ? 'All' : ''}
                  value={orderMenu}
                  onFocus={() => setShowDropDown(true)}
                  onBlur={() => setShowDropDown(false)}
                  onUpdateValue={item => {
                    setShowDropDown(false);
                    handleChange(item.value)
                  }}
                />
                :
                <Picker
                  selectedValue={orderMenu}
                  style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', backgroundColor: '#ffff' }}
                  onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                >
                  <Picker.Item label="All" value="All" />
                  <Picker.Item label="Order ID" value="Order_Id" />
                  <Picker.Item label="User Email ID" value="User_Email_Id" />
                  <Picker.Item label="Status" value="Order_Status" />
                </Picker>}
            </View>
            <View style={{ flex: dimensions <= 700 ? 0.1 : 0.6 }} />
            <View style={{ flex: 1.8 }}>
              <Searchbar
                style={{ borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7' }}
                placeholder="Search"
                onChangeText={(value) => handleSearch(value)}
                value={orderSearch}
              />
            </View>
            <View style={{ flex: dimensions <= 700 ? 0.1 : 1.6 }} />
            <View style={{ flex: dimensions <= 700 ? null : 0.07 }} />
          </View>
          <ScrollView horizontal={dimensions >= 700 ? false : true}>
            <View style={style.mainbox}>
              <Card>
                <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                  <DataTable.Header style={style.databeHeader}>
                    <DataTable.Title style={style.titles} active={orderBy === 'Order_Id'} sortDirection={orderBy === 'Order_Id' ? order : 'ascending'} onPress={createSortHandler('Order_Id')}>
                      <Text style={style.titletext}>Order ID</Text></DataTable.Title>
                    <DataTable.Title style={style.titles} active={orderBy === 'User_Email_Id'} sortDirection={orderBy === 'User_Email_Id' ? order : 'ascending'} onPress={createSortHandler('User_Email_Id')}>
                      <Text style={style.titletext}>User Email ID</Text></DataTable.Title>
                    <DataTable.Title style={style.titles} active={orderBy === 'Order_Status'} sortDirection={orderBy === 'Order_Status' ? order : 'ascending'} onPress={createSortHandler('Order_Status')}>
                      <Text style={style.titletext}>Status</Text></DataTable.Title>
                    <DataTable.Title style={style.titles}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                  </DataTable.Header>
                  {data.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage)?.map((allorder, index) => (
                    <View key={index}>
                      <TouchableOpacity onPress={() => { handleClickOrderDetails(allorder); }}>
                        <DataTable.Row style={style.databeBox} >
                          <DataTable.Cell style={style.tablerow}>{allorder.Order_Id}</DataTable.Cell>
                          <DataTable.Cell style={style.tablerow}>{allorder.User_Email_Id}</DataTable.Cell>
                          <DataTable.Cell style={style.tablerow}>{allorder.Order_Status}</DataTable.Cell>
                          <DataTable.Cell style={style.tablerow}>{allorder.Is_Approved === "1" ? <Text style={{ color: "green" }}>Approved</Text> : allorder.Is_Approved === "2" ? <Text style={{ color: "red" }}>Rejected</Text> : allorder.Is_Approved === "0" ? <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}><Button textColor='green' mode="contained" onPress={() => { setData(allorder); setVisible(!visible) }} >Accept</Button><Button textColor='red' mode="contained" onPress={() => { setData(allorder); setVisibleOne(!visibleone) }} >Reject</Button></View> : null}
                          </DataTable.Cell>
                        </DataTable.Row>
                      </TouchableOpacity>
                    </View>
                  ))
                  }
                  {isEmpty(data) &&
                   <View>
                   <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center",paddingTop:20 }}>
                     No records found!
                   </Text>
                   <Image source={require('../../assets/images/nodata.gif')}
                    style={{ width: 300, height: 300,alignSelf:"center" }} />
                  </View>
                  }
                  {/* pagination */}
                  <DataTable.Pagination
                    page={page}
                    numberOfPages={Math.ceil(orders?.length / numberOfItemsPerPage)}
                    onPageChange={page => setPage(to < data?.length ? page : 0)}
                    label={orders.length ? `${from + 1}-${to} of ${data?.length}` : '0-0'}
                    showFastPaginationControls={to > data?.length ? false : true}
                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    onItemsPerPageChange={onItemsPerPageChange}
                    selectPageDropdownLabel={'Rows per page'}
                  />
                </DataTable>
              </Card>
            </View>
          </ScrollView>
        </ScrollView>
    </Surface>

  );
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 35,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titles: {
    fontWeight: "bold",
    fontSize: 12,
    justifyContent: "center",
    textAlign: "center",
    width: 160
  },
  titletext: {
    fontWeight: "bold",
    fontSize: 14,
    color: 'black',
  },
  tablerow: {
    justifyContent: "center",
    textAlign: "center",
    width: 180
  },
  mainbox: {
    textAlign: 'center',
    margin: 15,
    flex: 1,
    justifyContent: 'space-between',
  },
  databeBox: {
    margin: 10,
    textAlign: 'center',
  },
  databeHeader: {
    margin: 10,
    textAlign: 'center',
  },
  dropdown: {
    height: 50,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  containerstyle: {
    width: 150
  },
});
export default Ordersget;