import { v4 as uuid } from 'uuid';
import orderhistory from "../../assets/static/Dashboard/orderhistory.png"
import reviews_and_ratings from "../../assets/static/Dashboard/reviews_and_ratings.png"
import partner_payment from "../../assets/static/Dashboard/partner_payment.jpg"
import order_shipping from "../../assets/static/Dashboard/order_shipping.jpg"

export default [
  {
    id: uuid(),
    link: 'OrderDetails',
    createdAt: 'Updated 2 hr ago',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: orderhistory,
    title: 'Order History',
    total: ''
  },
  {
    id: uuid(),
    link: 'B2bOrderDetails',
    createdAt: 'Updated 2 hr ago',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: orderhistory,
    title: 'B2B Order History',
    total: ''
  },
  {
    id: uuid(),
    link: 'OrderCancelGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: orderhistory,
    title: 'Order Cancel & Return',
    total: ''
  },
  {
    id: uuid(),
    link: 'ReviewsandRatingsGet',
    createdAt: 'Updated 2 hr ago',
    description:
      ' Review module includes to write a review that lets users sign in, assign a rating, and write a review of a product. This module also lets users edit a rating or review that they previously submitted.',
    media: reviews_and_ratings,
    title: 'Reviews & Ratings',
    total: ''
  },
  {
    link: 'PartnerpaymentGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Partner payment settlements provides the information about the order amount, cashback amount and the payment status related to partner payments.',
    media: partner_payment,
    title: 'Payments & Settlements',
    total: ''
  },
  {
    id: uuid(),
    link: 'ShippingGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'The Order Shipping module, located in the sales group, facilitates the process of packing and shipping items that have already been picked. Typically, orders are packed before they are shipped, but these steps can be performed simultaneously in Fishbowl if necessary. ',
    media: order_shipping,
    title: 'Order Shipping',
    total: ''
  },
];