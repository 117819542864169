import React, { useState } from 'react';
import { Card, Button, TextInput, HelperText, Surface, ActivityIndicator, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    getAllPartnerProductssearch, getProductPartnerAction, productpartnerdata
} from '../../../../state/actions/farmer/farmerpartnerproductsAction'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import isEmpty from '../../../../state/validations/is-empty';
import Header from '../../header';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';


const Discount_Type = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'A',
        label: 'Amount'
    },
    {
        value: 'P',
        label: 'Percentage'
    }
];


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");


const EditFarmerPartnerProducts = (props: any) => {
    const { navigation } = props;
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const esiColor = useSelector(state => state.theme);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Products_Partner_Products_Related_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const [errorMessage, setErrorMessage] = useState()
    const productid = props.route?.params?.productid;
    const [showProductMasterDropDown, setShowProductDropDown] = React.useState('');
    const partnerproductid = props.route?.params?.partnerproductid;
    const dispatch = useDispatch();
    const editpartnerproductdetails = useSelector(state => state.farmerpartnerproduct.partnerproduct.all);
    const [showDropDownProductPartnersDiscount, setShowDropDownProductPartnersDiscount] = React.useState('');
    const [partnerproduct, setPartnerProduct] = React.useState();
    const partnerproductssearch = useSelector(state => state.farmerpartnerproduct.partnerproductssearch.partners);
    const [seconds, setSeconds] = React.useState(2);
    const [discounttype, setValue] = React.useState(editpartnerproductdetails?.Discount_Type);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_PRODUCT_ID", SortOrder: "DESC" });
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 500)
        return () => clearTimeout(timer)
    }, [seconds]);
    React.useEffect(() => {
        setSeconds(2)
        if (isEmpty(editpartnerproductdetails) || partnerproductid !== partnerproduct) {
            const formData = {
                "search": "",
                "Cashback_Label": "",
                "Discount_Label": "",
                "Partner_Details_Id": "",
                "Records_Filter": "FILTER",
                "Partner_Email_Id": "",
                "Partner_Product_Id": partnerproductid,
                "Product_Id": productid,
                "Warranty_Period": ""
            };
            setPartnerProduct(partnerproductid);
            dispatch(getProductPartnerAction(formData, pagination))
        }
    }, [editpartnerproductdetails, partnerproductid]);
    React.useEffect(() => {
        if(!isEmpty(editpartnerproductdetails)){
            setValue(editpartnerproductdetails?.Discount_Type)
        }
    }, [editpartnerproductdetails]);
    React.useEffect(() => {
        if (!isEmpty(editpartnerproductdetails)) {
        dispatch(getAllPartnerProductssearch(editpartnerproductdetails?.Product_Name))
        }
    }, [editpartnerproductdetails]);
    // screen Dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    let productopt = []
    if (partnerproductssearch) {
        if (partnerproductssearch[0]) {
            for (let i = 0; i < partnerproductssearch?.length; i++) {
                productopt.push(
                    {
                        value: partnerproductssearch[i]?.Product_Id,
                        label: partnerproductssearch[i]?.Product_Name,
                    })
            }
        }
    }
    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    /*====================================
            Handler
    ====================================*/
    const values = {
        Partner_Product_Id: editpartnerproductdetails.Partner_Product_Id,
        Price: editpartnerproductdetails.Partner_Price,
        Partner_Original_Discount_Amount: editpartnerproductdetails.Partner_Original_Discount_Amount,
        Partner_Original_Discount: editpartnerproductdetails.Partner_Original_Discount,
        Partner_Original_Selling_Price: editpartnerproductdetails.Partner_Original_Selling_Price,
        Product_Id: editpartnerproductdetails.Product_Id,
        Gift_Wrap_Amount: editpartnerproductdetails.Gift_Wrap_Amount,
        Discount_Type: discounttype,
        Discount: editpartnerproductdetails.Discount,
        Esi_Cashback_Type: editpartnerproductdetails.Esi_Cashback_Type,
        Esi_Cashback: editpartnerproductdetails.Esi_Cashback,
        Discount_From_Date: editpartnerproductdetails.Discount_From_Date,
        Discount_To_Date: editpartnerproductdetails.Discount_To_Date,
        Max_Days_To_Return: editpartnerproductdetails.Max_Days_To_Return,
        Shipping_Amount: editpartnerproductdetails.Shipping_Amount,
        Modified_By: EmailID.Email_Id,
        Is_Partner_Shipping_Available: editpartnerproductdetails.Is_Partner_Shipping_Available > 0 ? true : false,
        Add_To_Cart: editpartnerproductdetails.Add_To_Cart > 0 ? true : false,
        Esi_Commission: editpartnerproductdetails.Esi_Commission,
        Esi_Commission_Type: editpartnerproductdetails.Esi_Commission_Type,
        Exclusive: editpartnerproductdetails.Exclusive > 0 ? true : false,
        Extra_CashBack: editpartnerproductdetails.Extra_Cashback > 0 ? true : false,
        Get_Coupon: editpartnerproductdetails.Get_Coupon > 0 ? true : false,
        Is_Default: editpartnerproductdetails.Is_Default > 0 ? true : false,
        Is_Esi_Shipping_Aavailable: editpartnerproductdetails.Is_Shipping_Available > 0 ? true : false,
        Is_Gift_Wrap_Available: editpartnerproductdetails.Is_Gift_Wrap_Available > 0 ? true : false,
        Is_Popular: editpartnerproductdetails.Is_Popular > 0 ? true : false,
        Is_Return_Available: editpartnerproductdetails.Is_Return_Available > 0 ? true : false,
        Is_Upcoming: editpartnerproductdetails.Is_Coming > 0 ? true : false,
        Partner_Address_Id: editpartnerproductdetails.Partner_Address_Id,
        Partner_Details_Id: editpartnerproductdetails.Partner_Details_Id,
        Status: editpartnerproductdetails.Status,
        Is_Product_Available: editpartnerproductdetails.Is_Product_Available > 0 ? true : false,
        Todays_Hot: editpartnerproductdetails.Todays_Hot > 0 ? true : false,
        Verified: editpartnerproductdetails.Verified > 0 ? true : false,
        What_Hot: editpartnerproductdetails.Whats_Hot > 0 ? true : false,
        Cashback_Label: editpartnerproductdetails.Cashback_Label,
        Discount_Label: editpartnerproductdetails.Discount_Label,
        Warranty_Period: editpartnerproductdetails.Warranty_Period,
        Is_Shop_By_City_Only: editpartnerproductdetails.Is_Shop_By_City_Only > 0 ? true : false
    }
    //Form Validations
    const Form_Validation = Yup.object().shape({
        Partner_Original_Selling_Price: Yup.number().required(' Price is required.'),
        Discount_Type: Yup.string().test('oneOfRequired', 'Discount Type is required.',
            function (item) {
                return (this.parent.Discount_Type)
            }
        ),
        Discount: Yup.number().when("Discount_Type", (Discount_Type, schema) => {
            if (Discount_Type == "A") {
                return schema.min(1, "Discount Amount must be at least 1.").required('Discount Amount is required.');
            } else if (Discount_Type == "P") {
                return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be less than 100.").required('Discount Percentage is required.');
            }
        }),
    })
    const onSubmit = (values, { setSubmitting, setErrors }) => {

        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Add_To_Cart) {
                formValues.Add_To_Cart = 1;
            } else {
                formValues.Add_To_Cart = 0;
            }
            if (values.Is_Gift_Wrap_Available) {
                formValues.Is_Gift_Wrap_Available = 1;
            } else {
                formValues.Is_Gift_Wrap_Available = 0;
            }
            if (values.Is_Esi_Shipping_Aavailable) {
                formValues.Is_Esi_Shipping_Aavailable = 1;
            } else {
                formValues.Is_Esi_Shipping_Aavailable = 0;
            }
            if (values.Verified) {
                formValues.Verified = 1;
            } else {
                formValues.Verified = 0;
            }
            if (values.Get_Coupon) {
                formValues.Get_Coupon = 1;
            } else {
                formValues.Get_Coupon = 0;
            }
            if (values.Exclusive) {
                formValues.Exclusive = 1;
            } else {
                formValues.Exclusive = 0;
            }
            if (values.Is_Popular) {
                formValues.Is_Popular = 1;
            } else {
                formValues.Is_Popular = 0;
            }
            if (values.What_Hot) {
                formValues.What_Hot = 1;
            } else {
                formValues.What_Hot = 0;
            }
            if (values.Todays_Hot) {
                formValues.Todays_Hot = 1;
            } else {
                formValues.Todays_Hot = 0;
            }
            if (values.Extra_CashBack) {
                formValues.Extra_CashBack = 1;
            } else {
                formValues.Extra_CashBack = 0;
            }
            if (values.Is_Upcoming) {
                formValues.Is_Upcoming = 1;
            } else {
                formValues.Is_Upcoming = 0;
            }
            if (values.Is_Default) {
                formValues.Is_Default = 1;
            } else {
                formValues.Is_Default = 0;
            }
            if (values.Is_Esi_Shipping_Available) {
                formValues.Is_Esi_Shipping_Available = 1;
            } else {
                formValues.Is_Esi_Shipping_Available = 0;
            }
            if (values.Is_Partner_Shipping_Available) {
                formValues.Is_Partner_Shipping_Available = 1;
            } else {
                formValues.Is_Partner_Shipping_Available = 0;
            }
            if (values.Is_Product_Available) {
                formValues.Is_Product_Available = 1;
            } else {
                formValues.Is_Product_Available = 0;
            }
            if (values.Is_Return_Available) {
                formValues.Is_Return_Available = 1;
            } else {
                formValues.Is_Return_Available = 0;
            }
            dispatch(productpartnerdata(formValues, props.navigation, setErrorMessage, setErrors));
            navigation.navigate('EditFarmerPartnerProductspagetwo')
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
      datePickerStyle: {
            width: 350,
            height: 60,
            marginTop: 6,
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },
        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        checkbox: {
            flexDirection: "row",
            justifyContent: 'flex-start'
        },
        label: {
            marginBottom: 30,
        },
        editor: {
            backgroundColor: "black",
            borderColor: "black",
            borderWidth: 1,
        },
        rich: {
            minHeight: 130,
            // flex: 1,
        },
        richBar: {
            height: 50,
            backgroundColor: "#F5FCFF",
        },
        text: {
            fontWeight: "bold",
            fontSize: 18,
        },
        tib: {
            textAlign: "center",
            color: "#515156",
        },
        a: {
            fontWeight: "bold",
            color: "purple",
        },
        div: {
            fontFamily: "monospace",
        },
        p: {
            fontSize: 30,
        }, container: {
            paddingTop: 15
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            left: 22,
            top: 0.5,
            color: esiColor.SIconColor,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{
            flex: 1,backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                {!isEmpty(editpartnerproductdetails) && seconds === -1 ? <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        props.resetForm();
                                        navigation.navigate('FarmerPartnerProductsGet', { productpartnerparams: editpartnerproductdetails.Product_Id })
                                    }} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{ 
                                 width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                 marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                 backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                             }}>
                                <Card.Content>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text style={{ fontSize: 20, fontWeight: "bold",color:esiColor.brandFontColor }}>
                                            Edit Own Product Partner
                                        </Text>
                                        {editpartnerproductdetails.Partner_Price ? <Text style={{ fontSize: 16,color:esiColor.itemColor }}>
                                            Product Price: {editpartnerproductdetails.Partner_Price}
                                        </Text> : null}
                                    </View>
                                    <View style={{ flexGrow: 1, flexShrink: 1 }}>

                                        <View style={styles.container}>
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Product_Id && props.errors.Product_Id) && { color: '#b00020' }]}>
                                                Product Name
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Product_Id && props.errors.Product_Id) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={productopt}
                                                labelField="label"
                                                valueField="value"
                                                searchQuery={item => { dispatch(getAllPartnerProductssearch(item)) }}
                                                maxHeight={productopt.length > 1 ? 250 : 200}
                                                search={productopt.length > 1 ? true : false}
                                                searchPlaceholder={'Select Product Name'}
                                                value={values.Product_Id}
                                                disable
                                                onUpdateValue={item => {
                                                    item &&
                                                        props.setFieldValue('Product_Id', item?.value)
                                                    props.setFieldValue("Product_Master_ID", item?.Product_Master_Id),
                                                        props.setFieldValue("Price", item?.Price);
                                                    setShowProductDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Product_Id && props.errors.Product_Id)}>
                                                {props.touched.Product_Id && props.errors.Product_Id}
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { color: '#b00020' }]}>
                                                    Discount Type
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={Discount_Type}
                                                    labelField="label"
                                                    valueField="value"
                                                    maxHeight={Discount_Type.length > 1 ? 250 : 200}
                                                    search={Discount_Type.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Discount Type'}
                                                    placeholder={!showDropDownProductPartnersDiscount ? 'Select item' : '...'}
                                                    value={props.values.Discount_Type}
                                                    onUpdateValue={item => {
                                                        props.setFieldValue('Discount_Type', item.value)
                                                        setValue(item.value)
                                                        setShowDropDownProductPartnersDiscount(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Discount_Type && props.errors.Discount_Type)}>
                                                    {props.touched.Discount_Type && props.errors.Discount_Type}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                theme={customTheme}
                                                style={{
                                                  marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                //   borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                disabled={props.values.Discount_Type ? false : true}
                                                error={Boolean(props.touched.Partner_Original_Discount_Amount && props.errors.Partner_Original_Discount_Amount)}
                                                label={"Discount" + (props.values.Discount_Type === "A" ? "Amount ₹" : props.values.Discount_Type === "P" ? "Percentage %" : "")}
                                                mode='outlined'
                                                value={props.values.Partner_Original_Discount_Amount}
                                                onChangeText={item => {
                                                    let PPrice = discounttype == "P" ? (item / 100 * editpartnerproductdetails.Partner_Price).toFixed(2) : item
                                                    props.setFieldValue("Partner_Original_Discount_Amount", item);
                                                    props.setFieldValue("Partner_Original_Selling_Price", editpartnerproductdetails.Partner_Price - PPrice);
                                                    props.setFieldValue("Partner_Original_Discount", PPrice);
                                                }}
                                                keyboardType='numeric'
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Partner_Original_Discount_Amount && props.errors.Partner_Original_Discount_Amount)}>
                                                {props.touched.Partner_Original_Discount_Amount && props.errors.Partner_Original_Discount_Amount}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                theme={customTheme}
                                                style={{
                                                  marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                //   borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                error={Boolean(props.touched.Partner_Original_Selling_Price && props.errors.Partner_Original_Selling_Price)}
                                                label="The product amount we give you"
                                                mode='outlined'
                                                disabled
                                                value={props.values.Partner_Original_Selling_Price.toString()}
                                                onChangeText={props.handleChange("Partner_Original_Selling_Price")}
                                                keyboardType='numeric'
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Partner_Original_Selling_Price && props.errors.Partner_Original_Selling_Price)}>
                                                {props.touched.Partner_Original_Selling_Price && props.errors.Partner_Original_Selling_Price}
                                            </HelperText>
                                        </View>
                                        {/* <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                error={Boolean(props.touched.Warranty_Period && props.errors.Warranty_Period)}
                                                label="Warranty Period"
                                                mode='outlined'
                                                multiline
                                                value={props.values.Warranty_Period}
                                                onChangeText={props.handleChange("Warranty_Period")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Warranty_Period && props.errors.Warranty_Period)}>
                                                {props.touched.Warranty_Period && props.errors.Warranty_Period}
                                            </HelperText>
                                        </View> */}
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                            Next
                                        </Button>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik> : <ActivityIndicator color='#27b6cc' style={{ marginVertical: 20, marginHorizontal: 150 }} />}
            </ScrollView >
        </Surface >
    );
};

export default EditFarmerPartnerProducts;