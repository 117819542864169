import React from 'react';
import EsiSearchDropdown from '../searchable-dropdown';
import { StyleSheet } from 'react-native';
import axios from 'axios';
import config from '../../../state/actions/config';
import shopperconfig from '../../../state/actions/shoppers/shopperconfig';
// import esiColor from '../../../constants/Colors';
import { useSelector } from 'react-redux';
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
const EsiCity = (props: any) => {
    // props variable consist all react text field properties along with valueUpdate function.
    // valueUpdate function will return onChange value of field.

    // valueUpdate function is a custom property as it dont have in react native textfield properties.
    // CountryCode and StateCode are required.
    const { CountryCode, StateCode, ...rest } = props
    const [city, setCity] = React.useState([]);
    const [countryField, setCountryField] = React.useState();
    const [stateField, setStateField] = React.useState([]);
    React.useLayoutEffect(() => {
        setStateField(StateCode);
        setCountryField(CountryCode);
        if ((StateCode != stateField)) {
            let citysdata = {
                "Filter_Type": "CITY",
                "Country": "IN",
                "State": StateCode
            }
            getCitys(citysdata)
        }
    }, [CountryCode, StateCode])

    const getCitys = (citysdata: any) => {
        axios.post(`${shopperconfig.nodePartnerUrl}/Country_State_City/Get?PageNo=1&PageSize=1000&SortBy=NAME&SortOrder=ASC`, citysdata,
            {
                headers: nodeheaders
            }
        )
            .then(response => {
                let city = []
                if (response?.data?.results) {
                    if (response?.data?.results[0]) {
                        for (let i = 0; i < response?.data?.results.length; i++) {
                            city.push(
                                response?.data?.results[i]?.City_List[0],
                            )
                        }
                    }
                }
                setCity(city);
            })
            .catch(error => {
            })
    }

    const esiColor = useSelector(state => state.theme);


    const styles = StyleSheet.create({
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.BackgroundColor
    
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
    
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        iconStyle: {
            width: 20,
            height: 20,
    
        },
        dropdown: {
            height: 58,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
    })

    return (
        <EsiSearchDropdown
            {...props}
            // data param here is used to assign the data from the function values that will be provided.
            data={city}
            style={[styles.dropdown, props.styles, { borderColor: props.error ? '#b00020' : 'gray' }]}

            // Providing styles for placeholder
            placeholderStyle={styles.placeholderStyle}
            // Providing styles for selectedText
            selectedTextStyle={styles.selectedTextStyle}
            // Providing styles for inputSearch
            inputSearchStyle={styles.inputSearchStyle}
            // Providing styles for icon
            iconStyle={styles.iconStyle}
            // valueField is used to get the value
            valueField={props.valueField}
            // labelField is used to get the label
            labelField={props.labelField}
            // search is used to get the value from the dropdown
            search={props.search}
            searchPlaceholder={props.searchPlaceholder}
            maxHeight={180}
            dropdownPosition="auto"
            onUpdateValue={props.onUpdateValue}
        />

    );
};

export default EsiCity;
