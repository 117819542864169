import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { EditEmployeeAction, getEmployeeByIdAction } from '../../state/actions/employeeAction';
import { Button, Card, HelperText, Text, TextInput, Surface, RadioButton, DefaultTheme } from 'react-native-paper';
import { Dimensions, StyleSheet, ScrollView, View, RefreshControl } from 'react-native';
import Header from '../auth/components/header';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import EsiDatePicker from '../../components/custom/date/date';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const UserType = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'PARTNER_ADMIN',
    label: 'ADMIN'
  },
  {
    value: 'PARTNER_EMPLOYEE',
    label: 'EMPLOYEE'
  }
];


const IdProofType = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Aadhar',
    label: 'Aadhar'
  },
  {
    value: 'PAN',
    label: 'PAN'
  },
  {
    value: 'Driving Licence',
    label: 'Driving Licence'
  }
];


const gender = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'Male',
    label: 'Male'
  },
  {
    value: 'Female',
    label: 'Female'
  },

];

const EditEmployee = (props: any) => {
  const esiColor = useSelector(state => state.theme);
  const ID = props.route?.params?.id || "";
  const page = props.route?.params?.page;
  const { navigation } = props;
  const dispatch = useDispatch();
  const phoneRegExp = /^([+]\d{2}[ ])?\d{10}$/

  const toast = useToast();

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.employee.employeeUpdate.isLoading);
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  React.useEffect(() => {
    return () => {
    };
  }, []);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);

  React.useEffect(() => {
    if (!(permissions.Employees === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
    setSeconds(2);
  }, [permissions, ID]);

  const [data, setData] = useState([]);
  const callBackData = (data: any) => {
    setData(data)
  }

  React.useEffect(() => {
    if (ID) {
      let formData = {
        "search_by_filter": "",
        "Partner_User_Details_Id": ID,
        "search": "",
        "Email_Id": "",
        "Partner_Details_Id": "",
        "Partner_Employee_Id": "",
        "Records_Filter": "FILTER"
      }
      dispatch(getEmployeeByIdAction(formData, callBackData))
    }
  }, [seconds, ID]);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [showDropDown, setShowDropDown] = useState(false);
  //mobile number country code
  const [reload, setReload] = React.useState(false);
  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 16
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },

    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    phonetextInput: {
      flex: 1
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        {data &&

          <Formik
            enableReinitialize={true}
            initialValues={{
              DOB: data.DOB,
              First_Name: data.First_Name,
              Full_Name: data.Full_Name,
              Gender: data.Gender,
              Id_Proof_Number: data.ID_Proof_Number,
              Id_Proof_Type: data.ID_Proof_Type,
              Last_Name: data.Last_Name,
              Partner_User_Details_Id: data.Partner_User_Details_Id,
              Profile_Pic_Path: data.Profile_Pic_Path,
              Partner_Details_Id: data.Partner_Details_Id,
              Partner_Employee_Email_Id: data.Email_Id,
              Mobile_Number: data.Mobile_Number,
              Is_Active: data.Is_Active == "1" ? true : false,
              User_Type: data.User_Type,
              Partner_Employee_Id: data.Partner_Employee_Id,
            }}
            validationSchema={Yup.object().shape({
              Partner_Employee_Email_Id: Yup.string().email("Please provide a valid Email ID. ").notOneOf([errorMessage.Email_Id.key, null], errorMessage.Email_Id.message).required('Email ID is required.'),
              User_Type: Yup.string().required('User Type is required.'),
              Mobile_Number: Yup.string().min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number.").required("Mobile Number is required."),
              DOB: Yup.string().required('Date Of Birth is required.'),
              First_Name: Yup.string().required('First Name is required.'),
              Gender: Yup.string().required('Gender is required.'),
              Id_Proof_Type: Yup.string().required('Id Proof Type is required.'),
              Id_Proof_Number: Yup.string().when('Id_Proof_Type', (Id_Proof_Type, schema) => {
                if (Id_Proof_Type === "Aadhar") {
                  return schema.min(12, "Aadhaar Card Number is not valid.").max(12, "Aadhaar Card Number is not valid.").matches(/^[0-9]*$/, "Aadhaar Card Number is not valid.").required("Aadhaar Card Number is required.");
                } else if (Id_Proof_Type === "PAN") {
                  return schema.matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "PAN Card Number is not valid.").required("PAN Card Number is required.");
                } else if (Id_Proof_Type === "Driving Licence") {
                  return schema.matches(/^([a-zA-Z]){2}([0-9]){14}$/, "Driving License Number is not valid.").required("Driving License Number is required.");
                }
              }),
              Last_Name: Yup.string().required('Last Name is required.'),
            })}

            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              if (values) {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.Is_Active) {
                  formValues.Is_Active = 1;
                } else {
                  formValues.Is_Active = 0;
                }
                setSeconds(2);
                dispatch(EditEmployeeAction(formValues, props.navigation, setErrorMessage, setErrors, setData, toast, page));
              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"

              }}>
                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm(),
                        setReload(true);
                      setData([]),
                        setSeconds(2);
                      navigation.navigate('Employees', { page: page })
                    }}
                  >
                    Go Back
                  </Button>
                </View>

                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Edit Employee
                    </Text>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Email_Id && errors.Email_Id)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Partner_Employee_Email_Id')}
                          label="Email ID"
                          name="Partner_Employee_Email_Id"
                          value={values.Partner_Employee_Email_Id}
                          mode="outlined"
                        />
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <EsiDatePicker
                          
                          error={Boolean(touched.DOB && errors.DOB)}
                          style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                          name={'DOB'}
                          label='DOB'
                          value={values.DOB}
                          valueUpdate={item => {
                            setFieldValue('DOB', item)
                          }} />
                        <HelperText type="error" visible={Boolean(touched.DOB && errors.DOB)}>
                          {touched.DOB && errors.DOB}
                        </HelperText>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.First_Name && errors.First_Name)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('First_Name')}
                          label="First Name"
                          name="First_Name"
                          value={values.First_Name}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.First_Name && errors.First_Name)}>
                          {touched.First_Name && errors.First_Name}
                        </HelperText>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Last_Name && errors.Last_Name)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Last_Name')}
                          label="Last Name"
                          name="Last_Name"
                          value={values.Last_Name}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Last_Name && errors.Last_Name)}>
                          {touched.Last_Name && errors.Last_Name}
                        </HelperText>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Full_Name && errors.Full_Name)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Full_Name')}
                          label="Full Name"
                          name="Full_Name"
                          value={values.Full_Name}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Full_Name && errors.Full_Name)}>
                          {touched.Full_Name && errors.Full_Name}
                        </HelperText>
                      </View>
                      <View style={{ flexShrink: 1, width: 400 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          label="Mobile Number"
                          mode='outlined'
                          onChangeText={handleChange('Mobile_Number')}
                          left={<TextInput.Affix text="+91" />}
                          value={values.Mobile_Number}
                        />
                        <HelperText type="error" visible={Boolean(touched.Mobile_Number && errors.Mobile_Number)}>
                          {touched.Mobile_Number && errors.Mobile_Number}
                        </HelperText>
                      </View>

                      <View style={{ flexShrink: 1, width: 400, paddingTop: 10 }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Id_Proof_Type && errors.Id_Proof_Type) && { color: '#b00020' }]}>
                            ID Proof Type
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Id_Proof_Type && errors.Id_Proof_Type) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={IdProofType}
                            labelField="label"
                            valueField="value"
                            maxHeight={IdProofType.length > 1 ? 250 : 200}
                            search={IdProofType.length > 1 ? true : false}
                            searchPlaceholder={'Select ID Proof Type'}
                            value={values.Id_Proof_Type}
                            onFocus={() => setShowDropDown(true)}
                            onBlur={() => setShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('Id_Proof_Type', item.value)
                              setShowDropDown(false);
                            }}
                          />
                        </View>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 15 }}>
                        <TextInput
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Id_Proof_Number && errors.Id_Proof_Number)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Id_Proof_Number')}
                          label="ID Proof Number"
                          name="Id_Proof_Number"
                          value={values.Id_Proof_Number}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Id_Proof_Number && errors.Id_Proof_Number)}>
                          {touched.Id_Proof_Number && errors.Id_Proof_Number}
                        </HelperText>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 10 }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Gender && errors.Gender) && { color: '#b00020' }]}>
                            Gender
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Gender && errors.Gender) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={gender}
                            labelField="label"
                            valueField="value"
                            maxHeight={gender.length > 1 ? 250 : 200}
                            search={gender.length > 1 ? true : false}
                            searchPlaceholder={'Select Gender'}
                            value={values.Gender}
                            onFocus={() => setShowDropDown(true)}
                            onBlur={() => setShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('Gender', item.value)
                              setShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Gender && errors.Gender)}>
                            {touched.Gender && errors.Gender}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexShrink: 1, width: 400, paddingTop: 10 }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.User_Type && errors.User_Type) && { color: '#b00020' }]}>
                            User Type
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.User_Type && errors.User_Type) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={UserType}
                            labelField="label"
                            valueField="value"
                            maxHeight={UserType.length > 1 ? 250 : 200}
                            search={UserType.length > 1 ? true : false}
                            searchPlaceholder={'Select User Type'}
                            value={values.User_Type}
                            onFocus={() => setShowDropDown(true)}
                            onBlur={() => setShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('User_Type', item.value)
                              setShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.User_Type && errors.User_Type)}>
                            {touched.User_Type && errors.User_Type}
                          </HelperText>
                        </View>
                      </View>

                      <View style={{ width: "80%", margin: "auto" }}>
                        <View style={{
                          flexDirection: "row",
                        }}>
                          <RadioButton
                            color={esiColor.itemColor}
                            name="Is_Active"
                            status={values.Is_Active ? 'checked' : 'unchecked'}
                            onPress={() => setFieldValue('Is_Active', !values.Is_Active)}
                          />
                          <Text style={{ marginTop: 10, color: Boolean(touched.Is_Active && errors.Is_Active) ? "#b00020" : esiColor.itemColor }}>Is Active</Text>
                        </View>
                      </View>
                    </View>
                  </Card.Content>
                  <View style={{ marginHorizontal: 30 }}>
                    <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                    <Button textColor={esiColor.itemButtenColor}disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                      Save
                    </Button>
                  </View>
                </Card>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface >

  );
};

export default EditEmployee;