import axios from "axios";
import config from "./../config";
import { getSingleB2COrder } from "../ordersAction";
import shopperconfig from "./shopperconfig";
import { meeapi } from "../node-actions/meeapi";

export const ALL_ORDERS_REQUEST = "ALL_ORDERS_REQUEST";
export const ALL_ORDERS_SUCCESS = "ALL_ORDERS_SUCCESS";
export const ALL_ORDERS_FAIL = "ALL_ORDERS_FAIL";
export const ALL_ORDERS_FILTER = "ALL_ORDERS_FILTER";
export const ORDERS_REQUEST = "ORDERS_REQUEST";
export const ORDERS_SUCCESS = "ORDERS_SUCCESS";
export const ORDERS_FAIL = "ORDERS_FAIL";
export const PRODUCT_REVIEW_CREATE_REQUEST = "PRODUCT_REVIEW_CREATE_REQUEST";
export const PRODUCT_REVIEW_CREATE_SUCCESS = "PRODUCT_REVIEW_CREATE_SUCCESS";
export const PRODUCT_REVIEW_CREATE_FAIL = "PRODUCT_REVIEW_CREATE_FAIL";
export const PRODUCT_REVIEW_UPDATE_REQUEST = "PRODUCT_REVIEW_UPDATE_REQUEST";
export const PRODUCT_REVIEW_UPDATE_SUCCESS = "PRODUCT_REVIEW_UPDATE_SUCCESS";
export const PRODUCT_REVIEW_UPDATE_FAIL = "PRODUCT_REVIEW_UPDATE_FAIL";
export const USERSREVIEW_REQUEST = "USERSREVIEW_REQUEST";
export const USERSREVIEW_SUCCESS = "USERSREVIEW_SUCCESS";
export const USERSREVIEW_FAIL = "USERSREVIEW_FAIL";
export const NOTIFICATION_SHIPPING_UPDATE_REQUEST = "NOTIFICATION_SHIPPING_UPDATE_REQUEST";
export const NOTIFICATION_SHIPPING_UPDATE_SUCCESS = "NOTIFICATION_SHIPPING_UPDATE_SUCCESS";
export const NOTIFICATION_SHIPPING_UPDATE_FAIL = "NOTIFICATION_SHIPPING_UPDATE_FAIL";
export const B2B_NOTIFICATION_ORDER_UPDATE_REQUEST = "B2B_NOTIFICATION_ORDER_UPDATE_REQUEST";
export const B2B_NOTIFICATION_ORDER_UPDATE_SUCCESS = "B2B_NOTIFICATION_ORDER_UPDATE_SUCCESS";
export const B2B_NOTIFICATION_ORDER_UPDATE_FAIL = "B2B_NOTIFICATION_ORDER_UPDATE_FAIL";

const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
const urlheaders = config.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}

/*=============================================================
               get all user product reviews 
===============================================================*/
export const updateb2bOrderAction = (Order_Update: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
 type: B2B_NOTIFICATION_ORDER_UPDATE_REQUEST
});
axios.put(`${config.url}/B2B_Order/Partner_B2B_Order_Update`, Order_Update, {
 headers: headers
})
 .then(response => {
   let successResponse = response.data;
   if (successResponse.Response_Status == "Success") {
     dispatch({
       type: B2B_NOTIFICATION_ORDER_UPDATE_SUCCESS
     });
     navigation.navigate('b2borderNotification', { data: Order_Update.Order_ID }) 

   } else {
     const errors = {};
     dispatch({
       type: B2B_NOTIFICATION_ORDER_UPDATE_FAIL,
       payload: successResponse.UI_Display_Message
     });
   }
 })
 .catch(error => {
    dispatch({
     type: B2B_NOTIFICATION_ORDER_UPDATE_FAIL,
     payload: "Please try sometime later.Currently server is not working"
   });
 })
};


export const updateShippingAction = (Order_Update: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: NOTIFICATION_SHIPPING_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Order_Management/Order_UpdateFlow`, { Order_Update },
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;

      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: NOTIFICATION_SHIPPING_UPDATE_SUCCESS
        });
          toast.show({ message: 'Order Accepted successfully.', type: 'info', duration: 3000, position: 'top' });
          //   const formValue = {
          //     "Records_Filter": "FILTER",
          //     "PartnerDetailsID": Order_Update?.Item_Data?.Item_Master_Data[0]?.Partner_Details_Id,
          //     "Order_Id": Order_Update.Order_Id
          // };
          // dispatch(getSingleB2COrder(formValue))
           navigation.navigate('orderNotification', { data: Order_Update.Order_Id }) 
        }
    })
    .catch(error => {
      dispatch({
        type: NOTIFICATION_SHIPPING_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
export const getAllUserReviewAction = (formData:any) => async dispatch => {
  try {
    dispatch({
      type: USERSREVIEW_REQUEST,
    });
    let { data } = await axios.post(`${config.url}/B2B_Product_Review/Get`,
      formData,
      {
        headers: headers,
      }
    );
    if (data) {
      if (data.Is_Data_Exist === "0") {
        dispatch({
          type: USERSREVIEW_SUCCESS,
          payload: data,
        });
      }

      else {
        dispatch({
          type: USERSREVIEW_SUCCESS,
          payload: data.Product_Review,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: USERSREVIEW_FAIL,
      payload: error
    });
  }
};
/*=============================================================
                  Get All Deals Action
===============================================================*/

export const getAllOrdersAction =(formData:any,setPagination={}) => async (dispatch) => {
    try {
      dispatch({
        type: ALL_ORDERS_REQUEST,
      });
      meeapi.post(urlGenarator(`/B2B_Item_Master_Product/Order_Get`, setPagination),
      formData, 
      ).then(response => {
        let data = response.data;
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: ALL_ORDERS_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: ALL_ORDERS_SUCCESS,
            payload: data.results
          });
        }
      })
    }
    catch (error) {
      dispatch({
        type: ALL_ORDERS_FAIL,
        payload: error
      });
    }
  };

/*=============================================================
                  Get single order Action
===============================================================*/

export const getOrdersAction =
  (updateStates, formData) => async (dispatch, state) => {
    try {
      dispatch({
        type: ORDERS_REQUEST,
      });

      let { data } = await axios.post(`${url}/B2B_Order/Get_By_OrderId_And_Line_Item?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
        formData, {
        headers: headersCommon,
        auth: auth,
      });
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: ORDERS_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: ORDERS_SUCCESS,
            payload: data.Orders.Order_Data
          });
        }
      }
    }
    catch (error) {
      dispatch({
        type: ORDERS_FAIL,
        payload: error
      });
    }
  };
/*=============================================================
              product reviews and rating
===============================================================*/

export const createprodutctAction = (formData, state) => async dispatch => {

  dispatch({
    type: PRODUCT_REVIEW_CREATE_REQUEST,
  });
  axios.post(`${url}/Reviews/Product_Review_Create`, formData, {
    headers: headersCommon,
    auth: auth,
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PRODUCT_REVIEW_CREATE_SUCCESS
        });

        toast('Review added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch(getAllOrdersAction());

      } else {

        dispatch({
          type: PRODUCT_REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_REVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                update product user review action
===============================================================*/


export const updateUserAction = (formData, state, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: PRODUCT_REVIEW_UPDATE_REQUEST
  });
  setErrorMessage({ Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  axios.put(`${url}/Reviews/Product_Review_Update`, formData, {
    auth: {
      headers: headersCommon,
      auth: auth,
    }
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PRODUCT_REVIEW_UPDATE_SUCCESS
        });
        navigate('/app/product/user-reviews', state = { state });
      } else {
        const errors = {};
        if (successResponse.Mobile) {
          errors.Mobile = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Mobile: { key: successResponse.Mobile ? successResponse.Mobile : '', message: successResponse.Mobile ? successResponse.UI_Display_Message : '' }, Mobile: { key: successResponse.Mobile ? successResponse.Mobile : '', message: successResponse.Mobile ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Mobile) ? successResponse.UI_Display_Message : '' } });

        dispatch({
          type: PRODUCT_REVIEW_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Mobile: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: PRODUCT_REVIEW_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};




/*=============================================================
                 order cancel By Id
===============================================================*/

export const ordercancelById = formData => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${config.url}/Order/Status_Update`,
      formData,
      {
          headers: headers,
      }
    );
    if (data) {
      dispatch(getAllOrdersAction(formData));
    }
  } catch (err) { }
};


export const ordersFilter = (fType:any, value:any) => {
  return function (dispatch) {
    dispatch({
      type: ALL_ORDERS_FILTER,
      fType: fType,
      value: value,
    });
  }
}