import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';

export const DEALS_REQUEST = "DEALS_REQUEST";
export const DEALS_SUCCESS = "DEALS_SUCCESS";
export const DEALS_FAIL = "DEALS_FAIL";

export const DEAL_PARTNERS_REQUEST = "DEAL_PARTNERS_REQUEST";
export const DEAL_ACTIVE_FAIL = "DEAL_ACTIVE_FAIL";

export const DEAL_GET_REQUEST = "DEAL_GET_REQUEST";
export const DEAL_GET_SUCCESS = "DEAL_GET_SUCCESS";
export const DEAL_GET_FAIL = "DEAL_GET_FAIL";

export const DEAL_CREATE_REQUEST = "DEAL_CREATE_REQUEST";
export const DEAL_CREATE_SUCCESS = "DEAL_CREATE_SUCCESS";
export const DEAL_CREATE_FAIL = "DEAL_CREATE_FAIL";

export const DEAL_UPDATE_REQUEST = "DEAL_UPDATE_REQUEST";
export const DEAL_UPDATE_SUCCESS = "DEAL_UPDATE_SUCCESS";
export const DEAL_UPDATE_FAIL = "DEAL_UPDATE_FAIL";

export const DEAL_BRANDS_REQUEST = "DEAL_BRANDS_REQUEST";
export const DEAL_BRANDS_SUCCESS = "DEAL_BRANDS_SUCCESS";
export const DEAL_BRANDS_FAIL = "DEAL_BRANDS_FAIL";

export const DEAL_CATEGORIES_REQUEST = "DEAL_CATEGORIES_REQUEST";
export const DEAL_CATEGORIES_SUCCESS = "DEAL_CATEGORIES_SUCCESS";
export const DEAL_CATEGORIES_FAIL = "DEAL_CATEGORIES_FAIL";

export const DEALS_FILTER = "DEALS_FILTER";
export const DEAL_DATA = "DEAL_DATA";

/*=============================================================
                  Get All Deal Action
===============================================================*/
const nodeheaders = shopperconfig.nodeheadersCommon;
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
export const getBothDealsAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    dispatch({
      type: DEAL_PARTNERS_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Deals/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === "0") {
        callBackData([])
      } else {
        callBackData(data.results)
      }
    }
  } catch (err) {
    dispatch({
      type: DEAL_ACTIVE_FAIL,
      payload: err
    });
  }
};
export const getAllbrandsearch = (formData: any) => async dispatch => {
  if (formData === '1') {
    let formValues = {
      "search_by_filter": "",
      "search": "",
      "Brand_Id": "",
      "Brand_Name": "",
      "Brand_Status": "",
      "Esi_Rating": "",
      "Records_Filter": "ALL",
      "Todays_Hot": "",
      "Top_Brands": ""
    };
    try {
      dispatch({
        type: DEAL_BRANDS_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Brand/Get`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: DEAL_BRANDS_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: DEAL_BRANDS_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: DEAL_BRANDS_FAIL,
        payload: err
      });
    }
  } else {
    let formValues = {
      "search_by_filter": "",
      "search": formData,
      "Brand_Id": "",
      "Brand_Name": "",
      "Brand_Status": "",
      "Esi_Rating": "",
      "Records_Filter": "FILTER",
      "Todays_Hot": "",
      "Top_Brands": ""
    };
    try {
      dispatch({
        type: DEAL_BRANDS_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Brand/Get`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: DEAL_BRANDS_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: DEAL_BRANDS_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: DEAL_BRANDS_FAIL,
        payload: err
      });
    }
  }
};

export const getAllcategorysearch = (formData: any) => async dispatch => {
  if (formData === '1') {
    let formValues = {
      "search_by_filter": "",
      "search": ""
    };
    try {
      dispatch({
        type: DEAL_CATEGORIES_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Category/Get`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: DEAL_CATEGORIES_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: DEAL_CATEGORIES_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: DEAL_CATEGORIES_FAIL,
        payload: err
      });
    }
  } else {
    let formValues = {
      "search_by_filter": "Category_Name",
      "search": formData
    };
    try {
      dispatch({
        type: DEAL_CATEGORIES_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Category/Get`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: DEAL_CATEGORIES_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: DEAL_CATEGORIES_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: DEAL_CATEGORIES_FAIL,
        payload: err
      });
    }
  }
};
export const getAllDealAction = (formData: any, pagination = {}) => async dispatch => {

  try {
    dispatch({
      type: DEALS_REQUEST
    });
    meeapi.post(urlGenarator(`/Deals/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DEALS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: DEALS_SUCCESS,
          payload: data
        });

      }
    })
  } catch (err) {
    dispatch({
      type: DEALS_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Deal Create Action
===============================================================*/

export const createDealAction = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: DEAL_CREATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Deals/Create`, formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;

      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: DEAL_CREATE_SUCCESS
        });
        navigation.navigate('DealsGet')
        toast.show({ message: 'Deal created successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          Partner_Details_Id: formData.Partner_Details_Id
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "DEAL_ID",
          SortOrder: "ASC"
        }
        dispatch(getAllDealAction(formValue, pagination))
      } else {
        const errors = {};
        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });

        dispatch({
          type: DEAL_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DEAL_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};


/*=============================================================
                  Deal Update Action
===============================================================*/

export const updateDealAction = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: DEAL_UPDATE_REQUEST
  });
  axios.put(`${config.nodecudurl}/Deals/Update`, formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: DEAL_UPDATE_SUCCESS
        });
        navigation.navigate('DealsGet')
        toast.show({ message: 'Deal updated successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "ALL",
          Partner_Details_Id: formData.Partner_Details_Id
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "DEAL_ID",
          SortOrder: "ASC"
        }
        dispatch(getAllDealAction(formValue, pagination))
      } else {
        const errors = {};
        dispatch({
          type: DEAL_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: DEAL_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};


/*=============================================================
                  Get All Brands Action
===============================================================*/
export const getAllBrands = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "ALL",
      Brand_Id: ""
    };
    dispatch({
      type: DEAL_BRANDS_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Brand/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DEAL_BRANDS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DEAL_BRANDS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DEAL_BRANDS_FAIL,
      payload: err
    });
  }
};


export const getDealByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    dispatch({
      type: DEALS_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Deals/Deals_Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );

    if (data) {
      if (data.Is_Data_Exist === "0") {
        callBackData([])
      } else {
        callBackData(data.results)
      }
    }
  } catch (err) {
    dispatch({
      type: DEALS_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                        Get All Categories
===============================================================*/
export const getAllCategories = () => async dispatch => {
  try {
    let formData = {
      Records_Filter: "ALL",
      Category_Name: "",
      Category_Id: "",
    };
    dispatch({
      type: DEAL_CATEGORIES_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Category/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DEAL_CATEGORIES_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DEAL_CATEGORIES_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DEAL_CATEGORIES_FAIL,
      payload: err
    });
  }
};


/*=============================================================
                Deal Image Upload Api
===============================================================*/

export const uploadDealImage = (formData: any, callBackUploadImage: any) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.url}/Deals/Image_Upload?filename=book&file_extension=png&filetype=image&deal_id=D-I-0000027`,
      formData,
      {
        headers: headers,
        transformRequest: (d) => d,
      }
    );
    if (data) {
      callBackUploadImage(data);
    }
  } catch (err) {
  }
};

// Filter 
export const dealFilter = (fType: any, value: any) => {
  return function (dispatch) {
    dispatch({
      type: DEALS_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//Search
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: DEALS_FILTER,
      fType: "search",
      value: "",
    });
  }
}

//save cache data

export const dealData = (value: any) => {
  return function (dispatch) {
    dispatch({
      type: DEAL_DATA,
      value: value,
    });
  }
}
export const getSingleDeal = (formData: any, callBackDealData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Deals/Deals_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackDealData(data.results[0])
    }
  } catch (err) { }
};