import axios from "axios";
import config from "./../config";
import shopperconfig from "./shopperconfig";
export const ORDER_STATUS_UPDATE_REQUEST = "ORDER_STATUS_UPDATE_REQUEST";
export const ORDER_STATUS_UPDATE_SUCCESS = "ORDER_STATUS_UPDATE_SUCCESS";
export const ORDER_STATUS_UPDATE_FAIL = "ORDER_STATUS_UPDATE_FAIL";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
const urlheaders = config.nodeheadersCommon;

/*=============================================================
               Order return update
===============================================================*/

export const getSingleOrdersAction = (orderformData: any, responseFunction: any) => async (dispatch) => {
    try {

        let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/B2B_Item_Master/Single_Item_Master_Get`,
            orderformData, {
                headers: nodeheaders
        });
        if (data) {
            responseFunction(data.results[0]);
        }
    }
    catch (error) {

    }
};


export const OrderInvoiceAction = (formData: any, updateStates: any) => async (dispatch) => {
    axios.post(`${config.url}/B2B_Order/Invoice_Get`, formData, {
        headers: headers
    })
        .then(response => {
            let successResponse = response.data;
            updateStates(true, successResponse);
        })
        .catch(error => {
            updateStates(false, null);
        })
};
/*=============================================================
               OrderStatusUpdate
===============================================================*/
export const OrderStatusUpdateAction = (formData: any, data: any, navigation: any, setSubmitReturn: any) => async (dispatch) => {
    dispatch({
        type: ORDER_STATUS_UPDATE_REQUEST,
    });
    axios.put(`${config.url}/B2B_Order/Partner_B2B_Order_Update`, formData, {
        headers: headers,
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: ORDER_STATUS_UPDATE_SUCCESS
                });
                if(formData.Order_Status=="CANCELLED"){
                    setSubmitReturn(true, 'Your order cancelled successfully.');
                }else{
                setSubmitReturn(true, 'Order status updated successfully..');
                }
            } else {
                setSubmitReturn(false, successResponse.UI_Display_Message);
                dispatch({
                    type: ORDER_STATUS_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_STATUS_UPDATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

export const getOrderdetailAction = (formData: any) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_DETAILS_REQUEST,
        });
  
        let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/Partner_Address/Get`,
            formData, {
                headers: nodeheaders
        });
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: ORDER_DETAILS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: ORDER_DETAILS_SUCCESS,
                    payload: data.results[0]
                });
            }
        }
    }
    catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error
        });
    }
  };
  