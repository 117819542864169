import React, { useEffect } from 'react';
import { Avatar, Button, Dialog, Colors, HelperText, ProgressBar, RadioButton, Surface, Text, TextInput, TouchableRipple, Portal, IconButton, ActivityIndicator } from 'react-native-paper';
import RenderHTML from "react-native-render-html";
import Header from './components/header';
import { material } from 'react-native-typography';
import { Linking, View, Dimensions, ScrollView, ImageBackground, StyleSheet, TextInput as NativeTextInput, TouchableOpacity } from 'react-native';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getbussinessubtypeAction, getbussinestypeAction, signUpMobileExist, signUpEmailExist, signUpNew } from '../../state/actions/authActions';
import EsiCity from '../../components/custom/country-state-city/city';
import EsiState from '../../components/custom/country-state-city/state';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import { getAllContentManagementStaticAction } from '../../state/actions/contentActions';
import isEmpty from '../../state/validations/is-empty';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
function Copyright() {
    return (
        <View style={{ alignItems: 'center', marginBottom: 10, flexWrap: 'wrap' }}>
            <Text onPress={() => {
                Linking.openURL("https://www.easystepin.com/").catch((err) =>
                    console.error('An error occurred', err),
                );
            }
            } style={{ fontSize: 11, textAlign: 'center' }} > {'Copyright © '}
                2016 -{' '}
                {new Date().getFullYear()}{' '}
                EasyStepIn. All Rights Reserved.
            </Text>
        </View>
    );
}
const SignUP = (props: any) => {
    const { navigation } = props;
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const EmailID = useSelector(state => state.auth?.user);
    const toast = useToast();
    const [otpMobileNumber, setOtpMobileNumber] = React.useState("");
    const [otpMobileNumberError, setOtpMobileNumberError] = React.useState([]);
    const [currentState, setCurrentState] = React.useState("signup");
    const [isSubmited, setIsSubmited] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

    const [businesstypeDropDown, setBusinessTypeDropDown] = React.useState("");
    const [businesssubtypeDropDown, setBusinesssubTypeDropDown] = React.useState("");
    const [show, setShow] = React.useState(false);
    const [countryField, setCountryField] = React.useState();
    const [stateField, setStateField] = React.useState();
    const [typedata, settypeData] = React.useState([]);
    const formikRef = React.useRef();
    const [signupInit, setSignupInit] = React.useState({
        Partner_Company_Name: "",
        Partner_Person_Name: "",
        Email_Id: "",
        Mobile: "",
        Business_Type: "",
        Business_Sub_Type: "",
        State: "",
        City: "",
        Country: "IN",
        Terms: false,
        Marketing_Agreed: false,
        Mobile_OTP: "",
        Password: "",
        cpassword: ''
    });
    React.useEffect(() => {
        if (isAuthenticated) {
            if (EmailID?.Type === "PROPERTIES") {
                navigation.navigate("Properties", { screen: 'PropertiesDashboard' });
            } else if (EmailID?.Type === "SERVICES") {
                navigation.navigate("Services", { screen: 'ServicesDashboard' });
            } else if (EmailID?.Type === "Reseller" || EmailID?.Type === "Partner" || EmailID?.Type === "ResellerShopper") {
                navigation.navigate("B2C", { screen: 'Dashboard' });
            } else {
                navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } });
            }
        }
    }, []);
    const callBacktypeData = (data: any) => {
        settypeData(data)
    }
    let BUSINESSTYPE = []
    if (typedata) {
        if (typedata[0]) {
            for (let i = 0; i < typedata.length; i++) {
                BUSINESSTYPE.push(
                    {
                        label: typedata[i].BUSINESS_TYPE,
                        value: typedata[i].BUSINESS_TYPE
                    })
            }
        }
    }

    //data reload
    const [subtypedata, setsubtypeData] = React.useState([]);
    let BUSINESSSUBTYPE = []
    if (typedata) {
        if (subtypedata) {
            for (let i = 0; i < subtypedata?.length; i++) {
                BUSINESSSUBTYPE.push(
                    {
                        label: subtypedata[i].BUSINESS_SUB_TYPE,
                        value: subtypedata[i].BUSINESS_SUB_TYPE
                    })
            }
        }
    }
    const callBacksubtypeData = (data: any) => {
        setsubtypeData(data)
    }
    const [secondsOTP, setSecondsOTP] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => secondsOTP > 0 ? setSecondsOTP(secondsOTP - 1) : setSecondsOTP(-1), 1000)
        return () => clearTimeout(timer)
    }, [secondsOTP]);
    const onMobileFieldOnBlur = () => {
        if (!(formikRef.current?.errors.Mobile) && otpMobileNumber != formikRef.current?.values.Mobile) {
            setOtpMobileNumber(formikRef.current?.values.Mobile);
            dispatch(signUpMobileExist(formikRef.current?.values.Mobile, mobileCheckResponse));
        }
        else if (otpMobileNumber == formikRef.current?.values.Mobile) {
        }
        else {
            setOtpMobileNumber("");
        }
    }
    const [signupseconds, setSignupSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => signupseconds > 0 ? setSignupSeconds(signupseconds - 1) : setSignupSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [signupseconds]);
    React.useEffect(() => {
        let error = false;
        for (let i = 0; i <= otpMobileNumberError.length; i++) {
            if (otpMobileNumberError[i] == formikRef.current?.values.Mobile) {
                error = true;
                break;
            }
        }
        if (error)
            formikRef.current?.setFieldError("Mobile", 'Mobile Number is already exist.');
    }, [otpMobileNumberError])

    const mobileCheckResponse = (status: any, errorMessage: any, response: any, input: any) => {
        if (status) {
            setSecondsOTP(30);
        } else {
            let newOtpMobileNumberError = JSON.parse(JSON.stringify(otpMobileNumberError));
            newOtpMobileNumberError.push(input);
            setOtpMobileNumberError(newOtpMobileNumberError);
        }
    }

    const [emailExist, setEmailExist] = React.useState("");
    const [emailExistError, setEmailExistError] = React.useState([]);

    const onEmailFieldOnBlur = () => {
        if (!(formikRef.current?.errors.Email_Id) && emailExist != formikRef.current?.values.Email_Id) {
            setEmailExist(formikRef.current?.values.Email_Id);
            dispatch(signUpEmailExist(formikRef.current?.values.Email_Id, emailCheckResponse));
        }
        else if (emailExist == formikRef.current?.values.Email_Id) {
        }
        else {
            setEmailExist("");
        }
    }

    const emailCheckResponse = (status: any, errorMessage: any, response: any, input: any) => {
        if (!status) {
            let emailExistErrorN = JSON.parse(JSON.stringify(emailExistError));
            emailExistErrorN.push(input);
            setEmailExistError(emailExistErrorN);
        }
    }

    React.useEffect(() => {
        let error = false;
        for (let i = 0; i <= emailExistError.length; i++) {
            if (emailExistError[i] == formikRef.current?.values.Email_Id) {
                error = true;
                break;
            }
        }
        if (error)
            formikRef.current?.setFieldError("Email_Id", 'Email Address is already exist.');
    }, [emailExistError])
    const [seconds, setSeconds] = React.useState(-1);
    useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const [reSeconds, setReSeconds] = React.useState(0);
    useEffect(() => {
        const timer = setTimeout(() => reSeconds > 0 ? setReSeconds(reSeconds - 1) : setReSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [reSeconds]);
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const [visibles, setVisible] = React.useState(false);
    const hideDialog = () => setVisible(false);
    const [contentsOne, setContentsOne] = React.useState({});
    const responseFunction = (data) => {
        setContentsOne(data)
    }
    React.useEffect(() => {
        let formData = {
            "Business_Type": "",
            "Records_Filter": "ALL"
        };
        let formdata = {
            "Cms_Static_Id": "",
            "Name": "Partner_Terms and conditions",
            "Records_Filter": "FILTER"
        }
        dispatch(getAllContentManagementStaticAction(formdata, responseFunction));
        dispatch(getbussinestypeAction(formData, callBacktypeData));
    }, []);
    const setType = (BUSINESSTYPE) => {
        if (BUSINESSTYPE !== "") {
            let formData = {
                "Business_Type": BUSINESSTYPE,
                "Records_Filter": "FILTER"
            }
            dispatch(getbussinessubtypeAction(formData, callBacksubtypeData))
        }
    }
    const signIn = () => {
        setResetSeconds(3);
        navigation.navigate('SignIn');
    }

    const [errorMessage, setErrorMessage] = React.useState('');
    const signUpCallResponse = (status: any, errorMessage: any, response: any) => {
        setErrorMessage(errorMessage);
        setIsSubmited(status);
        if (!status) {
            setSeconds(3);
        } else {

        }
    }

    const [resetSeconds, setResetSeconds] = React.useState(0);
    useEffect(() => {
        const timer = setTimeout(() => resetSeconds > 0 ? setResetSeconds(resetSeconds - 1) : setResetSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [resetSeconds]);

    const signUpSuccessResponse = (status: any, errorMessage: any) => {
        setErrorMessage(errorMessage);
        setIsSubmited(false);
        if (status) {
            toast.show(
                { message: 'Your sign up was successful. Please login.', type: 'info', duration: 3000, position: 'top' }
            );
            setResetSeconds(3);
            navigation.navigate('SignIn');
        }
        setCurrentState("signup");
    }
    const esiColor = useSelector(state => state.theme);

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.Text,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        placeholderStyle: {
            fontSize: 16,
        },
        selectedTextStyle: {
            fontSize: 16,
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} page={'Auth'} />
            <Portal>
                <Dialog visible={visibles} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 500, marginLeft: 'auto', marginRight: 'auto' }}>
                    <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                        <IconButton icon="close" size={20} onPress={() => setVisible(!visibles)} />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                        <Text style={{ fontSize: 20, fontWeight: "bold", justifyContent: 'center', textAlign: 'center', margin: 5, color: esiColor.Text }}>
                            Terms and Conditions
                        </Text>
                    </Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView
                            contentContainerStyle={{ paddingHorizontal: 5 }}>
                            <Dialog.Content style={{ marginLeft: -10 }}>
                                <View style={{
                                    flex: 1,
                                    marginHorizontal: 2, borderRadius: 25, padding: 10, margin: 'auto', 
                                    backgroundColor: esiColor.BackgroundColor, flexDirection: 'column',
                                    borderColor: esiColor.SBorderColor, borderWidth: 1,
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowColor: esiColor.brandShadowColor,
                                    marginTop: "2%",
                                    marginBottom: "5%",
                                    shadowOpacity: 0.2,
                                    shadowRadius: 10,
                                    elevation: 10,
                                    marginLeft: "auto", marginRight: "auto",
                                    width: dimensions >= 10000 ? "90%" : "95%",
                                }}>
                                    <View>
                                        {!isEmpty(contentsOne) ? (contentsOne?.Description ?
                                            <RenderHTML
                                                source={{ html: contentsOne?.Description }} /> : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                                <Text style={{color: esiColor.NoDataFound}}>No Data found!</Text></View>) :
                                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                                <ActivityIndicator color='#27b6cc' animating={true} style={{ marginTop: 25 }} />
                                            </View>}

                                    </View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>
            {resetSeconds <= 0 &&
                <ScrollView keyboardShouldPersistTaps='always'>
                    <View style={{ flexDirection: "row", flexWrap: "wrap", flex: 1 }}>
                        <View style={{
                            flex: dimensions <= 400 ? 0 : dimensions <= 600 ? 1 : dimensions <= 800 ? 3 : 4
                        }}>
                            <ImageBackground style={{ height: "100%", width: "100%" }} source={require('../../assets/images/img/loginBg.png')} resizeMode="cover" >
                            </ImageBackground>
                        </View>
                        <View style={{ padding: 30, flex: dimensions <= 400 ? 4 : dimensions <= 600 ? 180 : 2 }}>
                            <Surface style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: "100%",
                                paddingTop: 15,
                                backgroundColor: esiColor.BackgroundColor
                            }} >
                                <View style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: 20
                                }}>
                                    <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10 }} />
                                    <Text style={{color: esiColor.Text}}>Partner Sign Up</Text>
                                </View>
                                <View>
                                    {currentState == "signup" &&
                                        <Formik
                                            innerRef={formikRef}
                                            initialValues={signupInit}
                                            validationSchema={Yup.object().shape({
                                                Partner_Company_Name: Yup.string()
                                                    .min(5, 'At least minimum 5 characters are required.')
                                                    .max(255)
                                                    .required('Partner or Store or Shop Name is required.'),
                                                Partner_Person_Name: Yup.string()
                                                    .min(3, 'At least minimum 5 characters are required.')
                                                    .max(255)
                                                    .required('Authorised Person Name is required.'),
                                                // Mobile: Yup.number().notOneOf(otpMobileNumberError, 'Mobile Number is already exist.').min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number.").required("Mobile Number is required."),
                                                Mobile: Yup.string()
                                                    .notOneOf(otpMobileNumberError, 'Mobile Number is already exist.')
                                                    .matches(/^\d+$/, 'Mobile number must contain only numbers')
                                                    .min(10, 'Mobile number must be exactly 10 digits')
                                                    .max(10, 'Mobile number must be exactly 10 digits')
                                                    .required('Mobile Number is required.'),
                                                Business_Type: Yup.string()
                                                    .required('Business Type is required.'),
                                                Business_Sub_Type: Yup.string()
                                                    .required('Business Sub Type is required.'),
                                                State: Yup.string()
                                                    .required('State is required.'),
                                                City: Yup.string()
                                                    .required('City is required.'),
                                                Terms: Yup.boolean().oneOf([true], "You must accept the terms and conditions."),
                                                Marketing_Agreed: Yup.boolean().oneOf([true], "You must accept the marketing terms."),
                                                Mobile_OTP: Yup.string().when('Mobile', (Mobile, schema) => {
                                                    if (Mobile?.length == 10) {
                                                        return schema.min(6, 'Please provide valid OTP.')
                                                            .required('Mobile OTP is required.');
                                                    } else {
                                                        return schema
                                                    }
                                                }),
                                                Email_Id: Yup.string()
                                                    .email("Please provide a valid Email ID.")
                                                    .test('email-length', 'Email ID must be between 5 and 50 characters.', function (value) {
                                                        if (value) {
                                                            return value.length >= 5 && value.length <= 50;
                                                        }
                                                        return true; // If value is empty, validation passes (handled by .required())
                                                    }),
                                                Password: Yup.string()
                                                    .max(255)
                                                    .matches(/[a-z]+/, "At least one lowercase letter is required.")
                                                    .matches(/[A-Z]+/, "At least one uppercase letter is required.")
                                                    .matches(/[^\da-zA-Z]+/, "At least one special character is required.")
                                                    .matches(/.*\d+/, "At least one number is required.")
                                                    .min(8, "At least minimum 8 characters are required.")
                                                    .required('Password is required.'),
                                                cpassword: Yup.string()
                                                    .max(255).oneOf([Yup.ref('Password'), null], 'Passwords must match.')
                                                    .required('Confirm Password is required.'),
                                            })}

                                            onSubmit={(values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
                                                setSubmitting(false);
                                                setSignupInit(values);
                                                setErrorMessage("");
                                                setSignupSeconds(5)
                                                dispatch(signUpNew({
                                                    ...values,
                                                    Mobile: "+91 " + values.Mobile,
                                                    Terms: values.Terms ? 1 : 0,
                                                    Marketing_Agreed: values.Marketing_Agreed ? 1 : 0
                                                }, signUpCallResponse, signUpSuccessResponse));
                                            }
                                            }
                                        >
                                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any, setFieldValue: any }) => (
                                                <View style={{
                                                    flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <View style={{ width: "80%", margin: "auto" }}>
                                                        <TextInput style={{ width: "100%", margin: "auto" }}
                                                            // theme={{ colors: { primary: '#27B6CC' } }}
                                                            label="Partner or Store or Shop Name"
                                                            mode='outlined'
                                                            onChangeText={handleChange('Partner_Company_Name')}
                                                            onBlur={handleBlur('Partner_Company_Name')}
                                                            value={values.Partner_Company_Name}
                                                            error={Boolean(touched.Partner_Company_Name && errors.Partner_Company_Name)}
                                                        />
                                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Partner_Company_Name && errors.Partner_Company_Name)}>
                                                            {touched.Partner_Company_Name && errors.Partner_Company_Name}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "80%", margin: "auto" }}>
                                                        <TextInput style={{ width: "100%", margin: "auto" }}
                                                            // theme={{ colors: { primary: '#27B6CC' } }}
                                                            label="Authorised Person Name"
                                                            mode='outlined'
                                                            onChangeText={handleChange('Partner_Person_Name')}
                                                            onBlur={handleBlur('Partner_Person_Name')}
                                                            value={values.Partner_Person_Name}
                                                            error={Boolean(touched.Partner_Person_Name && errors.Partner_Person_Name)}
                                                        />
                                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Partner_Person_Name && errors.Partner_Person_Name)}>
                                                            {touched.Partner_Person_Name && errors.Partner_Person_Name}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "80%", margin: "auto", paddingTop: 18 }}>
                                                        <Text style={[styles.dropdownlabel, Boolean(touched.Business_Type && errors.Business_Type) && { color: '#b00020' }]}>
                                                            Business Type
                                                        </Text>
                                                        <EsiSearchDropdown
                                                            style={[styles.dropdown, Boolean(touched.Business_Type && errors.Business_Type) && { borderColor: '#b00020' }]}
                                                            placeholderStyle={styles.placeholderStyle}
                                                            selectedTextStyle={styles.selectedTextStyle}
                                                            inputSearchStyle={styles.inputSearchStyle}
                                                            iconStyle={styles.iconStyle}
                                                            dropdownPosition="bottom"
                                                            placeholder={!businesstypeDropDown ? '-- Please Select --' : ''}
                                                            data={BUSINESSTYPE}
                                                            labelField="label"
                                                            valueField="value"
                                                            maxHeight={BUSINESSTYPE.length > 1 ? 250 : 200}
                                                            search={BUSINESSTYPE.length > 1 ? true : false}
                                                            searchPlaceholder={'Select Partner Type'}
                                                            value={values.Business_Type}
                                                            onUpdateValue={item => {
                                                                setType(item?.value ? item.value : "");
                                                                setFieldValue('Business_Type', item.value)
                                                                setBusinessTypeDropDown(false);
                                                            }}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Business_Type && errors.Business_Type)}>
                                                            <ErrorMessage name="Business_Type" />
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "80%", margin: "auto", paddingTop: 18 }}>
                                                        <Text style={[styles.dropdownlabel, Boolean(touched.Business_Sub_Type && errors.Business_Sub_Type) && { color: '#b00020' }]}>
                                                            Business Sub Type
                                                        </Text>
                                                        <EsiSearchDropdown
                                                            style={[styles.dropdown, Boolean(touched.Business_Sub_Type && errors.Business_Sub_Type) && { borderColor: '#b00020' }]}
                                                            placeholderStyle={styles.placeholderStyle}
                                                            selectedTextStyle={styles.selectedTextStyle}
                                                            inputSearchStyle={styles.inputSearchStyle}
                                                            iconStyle={styles.iconStyle}
                                                            dropdownPosition="bottom"
                                                            placeholder={!businesssubtypeDropDown ? '-- Please Select --' : ''}
                                                            data={BUSINESSSUBTYPE}
                                                            labelField="label"
                                                            valueField="value"
                                                            maxHeight={BUSINESSSUBTYPE.length > 1 ? 250 : 200}
                                                            search={BUSINESSSUBTYPE.length > 1 ? true : false}
                                                            searchPlaceholder={'Select Partner Type'}
                                                            value={values.Business_Sub_Type}
                                                            onUpdateValue={item => {
                                                                setFieldValue('Business_Sub_Type', item.value)
                                                                setBusinesssubTypeDropDown(false);
                                                            }}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Business_Sub_Type && errors.Business_Sub_Type)}>
                                                            <ErrorMessage name="Business_Sub_Type" />
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "80%", margin: "auto" }}>
                                                        <TextInput style={{ width: "100%", margin: "auto" }}
                                                            // theme={{ colors: { primary: '#27B6CC' } }}
                                                            label="Email Address"
                                                            mode='outlined'
                                                            autoCapitalize='none'
                                                            onChangeText={handleChange('Email_Id')}
                                                            onBlur={(props) => {
                                                                handleBlur(props);
                                                                onEmailFieldOnBlur();
                                                            }}
                                                            value={values.Email_Id}
                                                            error={Boolean(touched.Email_Id && errors.Email_Id)}
                                                        />
                                                        <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Email_Id && errors.Email_Id)}>
                                                            {touched.Email_Id && errors.Email_Id}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "80%", margin: "auto", paddingTop: 18 }}>
                                                        <TextInput style={{ width: "100%", margin: "auto" }}
                                                            // theme={{ colors: { primary: '#27B6CC' } }}
                                                            label="Mobile Number"
                                                            mode='outlined'
                                                            onChangeText={handleChange('Mobile')}
                                                            left={<TextInput.Affix textStyle={{color: esiColor.Text}} text="+91" />}
                                                            onBlur={(props) => {
                                                                handleBlur(props);
                                                                onMobileFieldOnBlur();
                                                            }}
                                                            value={values.Mobile}
                                                            error={Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)}
                                                        />
                                                        <HelperText type="error" visible={Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)}>
                                                            {(touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile}
                                                        </HelperText>

                                                        {(!isEmpty(otpMobileNumber) && secondsOTP > 0 && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                                            <HelperText type="info" style={{ color: "#27b6cc", textAlign: "right" }}
                                                                visible={!isEmpty(otpMobileNumber)}>
                                                                {secondsOTP} Secs To Resend OTP
                                                            </HelperText>}
                                                        {(!isEmpty(otpMobileNumber) && secondsOTP <= 0 && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                                            <HelperText type="info" style={{ color: "#27b6cc", textAlign: "right" }}
                                                                onPress={() => {
                                                                    dispatch(signUpMobileExist(otpMobileNumber, mobileCheckResponse));
                                                                }}
                                                                visible={!isEmpty(otpMobileNumber)}>
                                                                Resend OTP
                                                            </HelperText>}
                                                    </View>
                                                    {(!isEmpty(otpMobileNumber) && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                                        <View style={{ width: "80%", margin: "auto" }}>
                                                            <TextInput style={{ width: "100%", margin: "auto" }}
                                                                // theme={{ colors: { primary: '#27B6CC' } }}
                                                                label="Mobile OTP"
                                                                mode='outlined'
                                                                onChangeText={handleChange('Mobile_OTP')}
                                                                onBlur={handleBlur('Mobile_OTP')}
                                                                value={values.Mobile_OTP}
                                                                error={Boolean(touched.Mobile_OTP && errors.Mobile_OTP)}
                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.Mobile_OTP && errors.Mobile_OTP)}>
                                                                {touched.Mobile_OTP && errors.Mobile_OTP}
                                                            </HelperText>
                                                        </View>
                                                    }
                                                    <View style={{ width: "80%", margin: "auto", paddingTop: 18 }}>
                                                        <Text style={[styles.dropdownlabel, Boolean(touched.State && errors.State) && { color: '#b00020' }]}>
                                                            State
                                                        </Text>
                                                        {/* state input field with validation*/}
                                                        <EsiState
                                                            error={Boolean(touched.State && errors.State)}
                                                            labelField="NAME"
                                                            valueField="STATE_CODE"
                                                            value={values.State}
                                                            name="State"
                                                            search
                                                            searchPlaceholder={'Select State'}
                                                            CountryCode={countryField}
                                                            onUpdateValue={item => {
                                                                setFieldValue('State', item.STATE_CODE);
                                                                if (stateField != item.NAME)
                                                                    setStateField(item.STATE_CODE);
                                                            }}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.State && errors.State)}>
                                                            <ErrorMessage name="State" />
                                                        </HelperText>
                                                    </View>

                                                    <View style={{ width: "80%", margin: "auto", paddingTop: 18 }}>
                                                        <Text style={[styles.dropdownlabel, Boolean(touched.City && errors.City) && { color: '#b00020' }]}>
                                                            City
                                                        </Text>
                                                        {/* City input field with validation*/}
                                                        <EsiCity
                                                            error={Boolean(touched.City && errors.City)}
                                                            labelField="NAME"
                                                            valueField="CITY_ID"
                                                            CountryCode={"IN"}
                                                            StateCode={stateField}
                                                            value={values.City}
                                                            name="City"
                                                            search
                                                            searchPlaceholder={'Select City'}
                                                            onUpdateValue={(item) => {
                                                                setFieldValue('City', item.CITY_ID)
                                                            }}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.City && errors.City)}>
                                                            <ErrorMessage name="City" />
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "80%", margin: "auto" }}>
                                                        <TextInput style={{ width: "100%", margin: "auto"}}
                                                            // theme={{ colors: { primary: '#27B6CC' } }}
                                                            style={{ width: "100%" }}
                                                            label="Password"
                                                            mode='outlined'
                                                            onChangeText={handleChange('Password')}
                                                            onBlur={handleBlur('Password')}
                                                            value={values.Password}
                                                            secureTextEntry={!show}
                                                            right={<TextInput.Icon onPress={() => setShow(!show)} name={show ? "eye" : "eye-off"} />}
                                                            error={Boolean(touched.Password && errors.Password)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Password && errors.Password)}>
                                                            {touched.Password && errors.Password}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "80%", margin: "auto" }}>
                                                        <TextInput style={{ width: "100%", margin: "auto" }}
                                                            // theme={{ colors: { primary: '#27B6CC' } }}
                                                            style={{ width: "100%" }}
                                                            label="Confirm Password"
                                                            mode='outlined'
                                                            onChangeText={handleChange('cpassword')}
                                                            onBlur={handleBlur('cpassword')}
                                                            value={values.cpassword}
                                                            secureTextEntry={true}
                                                            error={Boolean(touched.cpassword && errors.cpassword)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.cpassword && errors.cpassword)}>
                                                            {touched.cpassword && errors.cpassword}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "80%", margin: "auto" }}>
                                                        <View style={{
                                                            flexDirection: "row",
                                                        }}>
                                                            <RadioButton
                                                                color={esiColor.SIconColor}
                                                                value={values.Marketing_Agreed}
                                                                name="Marketing_Agreed"
                                                                status={values.Marketing_Agreed ? 'checked' : 'unchecked'}
                                                                onPress={() => setFieldValue('Marketing_Agreed', !values.Marketing_Agreed)}
                                                            />
                                                            <Text style={{ marginTop: 10, color: Boolean(touched.Marketing_Agreed && errors.Marketing_Agreed) ? "#b00020" : esiColor.Text }}>Market Agreed</Text>
                                                        </View>
                                                    </View>

                                                    <View style={{ width: "80%", margin: "auto" }}>
                                                        <View style={{
                                                            flexDirection: "row",
                                                        }}>
                                                            <RadioButton
                                                                color={esiColor.SIconColor}
                                                                value={values.Terms}
                                                                name="Terms"
                                                                status={values.Terms ? 'checked' : 'unchecked'}
                                                                onPress={() => setFieldValue('Terms', !values.Terms)}
                                                            />
                                                            <Text style={{ marginTop: 10, color: Boolean(touched.Terms && errors.Terms) ? "#b00020" : esiColor.Text }}>I agree to the </Text>
                                                            <TouchableOpacity onPress={(e) => { setVisible(!visibles) }}>
                                                                <Text style={{ marginTop: 10, textDecorationLine: 'underline', color: esiColor.GBFColor }} >terms and conditions.</Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>

                                                    <View style={{
                                                        width: "80%", margin: "auto", marginTop: -5, alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        {seconds > 0 && <><Text style={{color: esiColor.Text}} >{errorMessage}</Text><ProgressBar style={{
                                                            height: 5,
                                                            width: 250,
                                                        }} progress={.30 * seconds} color={'red'} /></>}
                                                    </View>
                                                    <View style={{
                                                        width: "80%", margin: "auto", marginTop: -5, alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} disabled={isSubmited || signupseconds > 0} mode="contained" onPress={handleSubmit}>
                                                            <Text style={{ color: esiColor.itemButtenColor }}>Sign Up</Text>
                                                        </Button>
                                                    </View>
                                                    <View style={{ width: "80%", marginTop: 15, flexDirection: "row", flexWrap: "wrap", justifyContent: 'center', fontSize: 10 }}>

                                                        <TouchableRipple
                                                            onPress={signIn}
                                                        >
                                                            <Text style={{ fontSize: 11, textDecorationLine: 'underline', color: esiColor.Text }}>Already have an account? Sign in</Text>
                                                        </TouchableRipple>
                                                    </View>
                                                </View>
                                            )}
                                        </Formik>
                                    }
                                </View>
                                <Copyright />
                            </Surface>
                        </View>
                    </View>
                </ScrollView>
            }
        </Surface>
    );
};

export default SignUP;