import {
  PARTNERS_REQUEST,
  PARTNERS_SUCCESS,
  PARTNERS_FAIL,
  PARTNER_PRODUCT_REQUEST,
  PARTNER_PRODUCT_SUCCESS,
  PARTNER_PRODUCT_FAIL,

  PARTNER_CREATE_REQUEST,
  PARTNER_CREATE_SUCCESS,
  PARTNER_CREATE_FAIL,

  PARTNER_UPDATE_REQUEST,
  PARTNER_UPDATE_SUCCESS,
  PARTNER_UPDATE_FAIL,

  PARTNERS_FILTER,
  PRODUCTPARTNERS_DATA,

  PHYSICALPARTNER_ADDRESS_REQUEST,
  PHYSICALPARTNER_ADDRESS_SUCCESS,
  PHYSICALPARTNER_ADDRESS_FAIL,
  PARTNER_PRODUCT_FILTER_REQUEST,
  PARTNER_PRODUCT_FILTER_SUCCESS,
  PARTNER_PRODUCT_FILTER_FAIL 
} from '../actions/partnerproductsAction'

const initialState = {
  partnerCreate: { partner: {}, error: '', isLoading: false },
  partnerUpdate: { partner: {}, error: '', isLoading: false },
  partners: { all: [], error: '', isLoading: false },
  partnerproductssearch: { partners: [], error: '', isLoading: false },
  partnerproduct: { all: [], error: '', isLoading: false },
  partnerFilter: { select: 'All', search: '' },
  productpartnerdata: { partner: {}, error: '', isLoading: false },
  partnerAddress: { address: [], error: '', isLoading: false },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case PARTNERS_REQUEST:
      return { ...state, partners: { all: state.partners.all, error: '', isLoading: true } };
    case PARTNERS_SUCCESS:
      return { ...state, partners: { all: action.payload, error: '', isLoading: false } };
    case PARTNERS_FAIL:
      return { ...state, partners: { all: [], error: action.payload, isLoading: false } };
    case PARTNER_PRODUCT_REQUEST:
      return { ...state, partnerproduct: { all: state.partnerproduct.all, error: '', isLoading: true } };
    case PARTNER_PRODUCT_SUCCESS:
      return { ...state, partnerproduct: { all: action.payload, error: '', isLoading: false } };
    case PARTNER_PRODUCT_FAIL:
      return { ...state, partnerproduct: { all: [], error: action.payload, isLoading: false } };
    case PARTNERS_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, partnerFilter: { search: state.partnerFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, partnerFilter: { select: state.partnerFilter.select, search: action.value } };
      }
      return res;

    case PRODUCTPARTNERS_DATA:
      return { ...state, productpartnerdata: { partner: action.value, error: '', isLoading: true } };

    case PARTNER_CREATE_REQUEST:
      return { ...state, partnerCreate: { partner: {}, error: '', isLoading: true } };
    case PARTNER_CREATE_SUCCESS:
      return { ...state, partnerCreate: { partner: state.partnerCreate.partner, error: '', isLoading: false } };
    case PARTNER_CREATE_FAIL:
      return { ...state, partnerCreate: { partner: {}, error: action.payload, isLoading: false } };

    case PARTNER_UPDATE_REQUEST:
      return { ...state, partnerUpdate: { partner: {}, error: '', isLoading: true } };
    case PARTNER_UPDATE_SUCCESS:
      return { ...state, partnerUpdate: { partner: state.partnerUpdate.partner, error: '', isLoading: false } };
    case PARTNER_UPDATE_FAIL:
      return { ...state, partnerUpdate: { partner: {}, error: action.payload, isLoading: false } };

    case PHYSICALPARTNER_ADDRESS_REQUEST:
      return { ...state, partnerAddress: { address: state.partnerAddress.address, error: '', isLoading: true }, };
    case PHYSICALPARTNER_ADDRESS_SUCCESS:
      return { ...state, partnerAddress: { address: action.payload, error: '', isLoading: false } };
    case PHYSICALPARTNER_ADDRESS_FAIL:
      return { ...state, partnerAddress: { address: state.partnerAddress.address, error: action.payload, isLoading: false } };

      case PARTNER_PRODUCT_FILTER_REQUEST:
        return { ...state, partnerproductssearch: { partners: state.partnerproductssearch.partners, error: '', isLoading: true }, };
      case PARTNER_PRODUCT_FILTER_SUCCESS:
        return { ...state, partnerproductssearch: { partners: action.payload, error: '', isLoading: false } };
      case PARTNER_PRODUCT_FILTER_FAIL:
        return { ...state, partnerproductssearch: { partners: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}