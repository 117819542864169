import { v4 as uuid } from 'uuid';
import orderhistory from '../../../assets/static/Dashboard/orderhistory.png'

export default [
  {
    id: uuid(),
    link: 'FarmerOrderDetails',
    createdAt: 'Updated 2 hr ago',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: orderhistory,
    title: 'Order History',
    total: ''
  },
  {
    id: uuid(),
    link: 'FarmerOrderCancelGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Order history is a convenient way for a buyer to keep track of all current and past orders and their status in the order process. It allows the buyer to get up-to-date information on each order placed online, including shipping updates, delivery, and payment details.',
    media: orderhistory,
    title: 'Order Cancel & Return',
    total: ''
  },
];