import * as React from 'react';
import { Avatar, Button, Colors, HelperText, ProgressBar, Surface, Text, TextInput, TouchableRipple } from
    'react-native-paper';
import { material } from 'react-native-typography';
import { Linking, View, Dimensions, ScrollView, ActivityIndicator, ImageBackground } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { accountActiveActions, api, loginCheckNew, signInMobileExist, getLocationByIpAddress } from '../../state/actions/authActions';
import { Platform } from 'react-native';
import Modal from "react-native-modal";
import SignUP from './signup';
import DropdownAlert from 'react-native-dropdownalert';
import * as Device from 'expo-device';
import * as Network from 'expo-network';
import * as Location from "expo-location";
import Constants from 'expo-constants';
import getEnvVars from '../../../environment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from './components/header';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import isEmpty from '../../state/validations/is-empty';
import { isNumber } from '../../utils';
const { BASEURL } = getEnvVars();


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function Copyright() {
    return (
        <View style={{ alignItems: 'center', marginBottom: 10, flexWrap: 'wrap' }}>
            <Text onPress={() => {
                Linking.openURL("https://www.easystepin.com/").catch((err) =>
                    console.error('An error occurred', err),
                );
            }
            } style={{ fontSize: 11, textAlign: 'center' }} > {'Copyright © '}
                2016 -{' '}
                {new Date().getFullYear()}{' '}
                EasyStepIn. All Rights Reserved.
            </Text>
        </View>
    );
}

export default function SignIN(props) {
    const { navigation, dropDownAlertFunHead } = props;
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const EmailID = useSelector(state => state.auth?.user);
    const toast = useToast();
    const [isSubmited, setIsSubmited] = React.useState(false);
    const [show, setShow] = React.useState(false);
    //  new
    const [otpMobileNumber, setOtpMobileNumber] = React.useState("");
    const [mobileNumberCheck, setMobileNumberCheck] = React.useState("");
    const [otpMobileNumberError, setOtpMobileNumberError] = React.useState([]);
    const formikRef = React.useRef();
    const formikRefM = React.useRef();
    const [ipBasedLocation, setIpBasedLocation] = React.useState(true);
    const [locationStock, setLocationStock] = React.useState(false);
    const [deviceLocation, setLocation] = React.useState({});
    const [locationLoad, setLocationLoad] = React.useState(false);
    let deviceModelName = Device.modelName;
    const [deviceIPAddress, setDeviceIPAddress] = React.useState("");
    const [notificationToken, setNotificationToken] = React.useState("");

    const onMobileFieldOnBlur = () => {
        if (!(formikRef.current?.errors.Mobile) && otpMobileNumber != formikRef.current?.values.Mobile) {
            setOtpMobileNumber(formikRef.current?.values.Mobile);
            dispatch(signInMobileExist(formikRef.current?.values.Mobile, mobileCheckResponse));
        }
        else if (otpMobileNumber == formikRef.current?.values.Mobile) {
        }
        else {
            setOtpMobileNumber("");
        }
    }

    const mobileCheckResponse = (status: any, input: any) => {
        if (status) {
            setSecondsP(30);
        } else {
            setMobileNumberCheck(input)
            let newOtpMobileNumberError = JSON.parse(JSON.stringify(otpMobileNumberError));
            newOtpMobileNumberError.push(input);
            setOtpMobileNumberError(newOtpMobileNumberError);
        }
    }
    React.useEffect(() => {
        const retrieveExpoPushToken = async () => {
            if (Platform.OS !== "web" && !isEmpty(notificationToken)) {
                try {
                    const ExpoPushToken = await AsyncStorage.getItem('ExpoPushToken');
                    setNotificationToken(ExpoPushToken || "");
                } catch (error) {
                    console.error("Error retrieving ExpoPushToken:", error);
                }
            }
        };

        retrieveExpoPushToken();
    }, [notificationToken]);

    React.useEffect(() => {
        const { referral } = props?.route?.params || false;
        if (referral) {
            setSignUpModalVisible(true);
        }
        (async () => {
            if (Platform.OS != "web") {
                const ExpoPushToken = await AsyncStorage.getItem('ExpoPushToken');
                setNotificationToken(ExpoPushToken || "");
            }

            setLocationLoad(true);
            let iPAddress = await Network.getIpAddressAsync();
            setDeviceIPAddress(iPAddress);
            if (Platform.OS === 'android' && !Constants.isDevice) {
                alert(
                    'Oops, this will not work on Snack in an Android emulator. Try it on your device!'
                );
                return;
            }
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                setLocationStock(true);
                setLocationLoad(false);
                return;
            } else {
                setLocationStock(false);

            }

            const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
            setLocation({ latitude, longitude });
            api.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${latitude}&lon=${longitude}`).then(async (response) => {
                let locationInfo = response.data.display_name;
                setLocation({ latitude, longitude, locationInfo });
                setLocationLoad(false);
            }).catch(async (reason) => {
                setLocationLoad(false);
            });
        })();

        (async () => {
            let iPAddress = await Network.getIpAddressAsync();
            const callbackResponse = (data) => {
                if (data) {
                    // setIpBasedLocation({ locationInfo: data?.city + ',' + data?.region, latitude: data?.ll[0], longitude: data?.ll[1] });
                } else {
                    setIpBasedLocation(false);
                }
            }
            dispatch(getLocationByIpAddress({ iPAddress: iPAddress }, callbackResponse));
        })();
    }, []);
    React.useEffect(() => {
        if (!isEmpty(EmailID)) {
            if (isAuthenticated) {
                if (EmailID?.Type === "PROPERTIES") {
                    navigation.navigate("Properties", { screen: 'PropertiesDashboard' });
                }
                else if (EmailID?.Type === "FARMER") {
                    navigation.navigate("Farmer", { screen: 'Farmer_Dashboard' });
                } else if (EmailID?.Type === "SERVICES") {
                    navigation.navigate("Services", { screen: 'ServicesDashboard' });
                } else {
                    {
                        navigation.navigate("B2C", { screen: 'Dashboard' });
                    }
                }
            }
        }
    }, [EmailID]);

    React.useEffect(() => {

        (async () => {
            if (Platform.OS != "web") {
                const ExpoPushToken = await AsyncStorage.getItem('ExpoPushToken');
                setNotificationToken(ExpoPushToken || "");
            }
            let iPAddress = await Network.getIpAddressAsync();
            setDeviceIPAddress(iPAddress);
            if (Platform.OS === 'android' && !Constants.isDevice) {
                alert(
                    'Oops, this will not work on Snack in an Android emulator. Try it on your device!'
                );
                return;
            }
        })();

    }, []);
    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const [secondsP, setSecondsP] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => secondsP > 0 ? setSecondsP(secondsP - 1) : setSecondsP(-1), 1000)
        return () => clearTimeout(timer)
    }, [secondsP]);


    //Clean up
    React.useEffect(() => {
        return () => {
        };
    }, []);
    const [activateSuccess, setActivateSuccess] = React.useState(false);
    const [toggle, toggleModal] = React.useState(false);
    const activateAccountResponse = (status: any) => {
        setIsSubmited(false);
        React.useEffect(() => {
            const subscription = Dimensions.addEventListener(
                "change",
                ({ window, screen }) => {
                    setDimensions(window);
                }
            );
        });

        setIsSubmited(false);
        if (status) {
            try {
                setTimeout(() => {
                    if (!isEmpty(EmailID)) {
                        if (EmailID?.Type === "PROPERTIES") {
                            navigation.navigate("Properties", { screen: 'PropertiesDashboard' });
                        }
                        else if (EmailID?.Type === "FARMER") {
                            navigation.navigate("Farmer", { screen: 'Farmer_Dashboard' });
                        } else if (EmailID?.Type === "SERVICES") {
                            navigation.navigate("Services", { screen: 'ServicesDashboard' });
                        } else {
                            {
                                navigation.navigate("B2C", { screen: 'Dashboard' });
                            }
                        }
                    }
                }, 200)

            } catch (error) {

            }
            toast.show({ message: 'You have successfully logged in.', type: 'info', duration: 3000, position: 'top' });
        }
        else {
            setErrorMessage(errorMessage);
            setSeconds(3);
        }
    }
    const activateAccount = () => {
        setIsSubmited(true);
        dispatch(accountActiveActions(
            {
                "BaseURL": BASEURL + "activate-account?token=",
                "Email_Id": accountActivateEmail,
                "Expo_Push_Notification": notificationToken,
            }, activateAccountResponse
        ));
    }

    const [errorMessage, setErrorMessage] = React.useState('');
    const [accountActivateEmail, setAccountActivateEmail] = React.useState("");
    const [accountActivate, setAccountActivate] = React.useState(false);


    const signinCallResponse = (status: any, errorMessage: any, field: any) => {
        setIsSubmited(false);
        if (status) {
            setTimeout(() => {
            }, 200)
            toast.show({ message: 'You have successfully logged in.', type: 'info', duration: 3000, position: 'top' });
            toggleModal(true);

        }
        else {
            setErrorMessage(errorMessage);
            setSeconds(3);
            if (field == "activate") {
                setAccountActivate(true);
            }
        }
    }
    const register = () => {
        navigation.navigate('SignUp');
    }

    const [isForgotModalVisible, setForgotModalVisible] = React.useState(false);
    const toggleForgotModal = () => {
        setForgotModalVisible(!isForgotModalVisible);
        formikRef.current?.resetForm();
        formikRefM.current?.resetForm();
        setOtpMobileNumber("");
    };

    const [isSignUpModalVisible, setSignUpModalVisible] = React.useState(false);
    const toggleSignUpModal = (close: any) => {
        if (close === true) {
            toggleModal();
        }
        setSignUpModalVisible(!isSignUpModalVisible);
    };

    let dropDownAlertRef = React.useRef();
    const dropDownAlertFun = (type, title, sub_title) => {
        setSecondsP(2);
        const inMilliSeconds = Math.floor(Math.random() * 6000 + 1);
        const inSeconds = (inMilliSeconds / 1000).toFixed(2);
        dropDownAlertRef.alertWithType(
            type,
            title,
            sub_title,
            {},
            inMilliSeconds
        );
    }
    const [signUpSuccess, setSignUpSuccess] = React.useState(false);
    const signUpSuccessResponse = () => {
        setSignUpSuccess(true);
    }

    const loginUser = (status: any, data: any) => {
        setIsSubmited(false);
        if (status) {
            setTimeout(() => {
                if (!isEmpty(data)) {
                    if (data?.Type === "PROPERTIES") {
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'Properties', params: { screen: 'PropertiesDashboard' } }],
                        });
                    } else if (data?.Type === "FARMER") {
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'Farmer', params: { screen: 'Farmer_Dashboard' } }],
                        });
                    } else if (data?.Type === "SERVICES") {
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'Services', params: { screen: 'ServicesDashboard' } }],
                        });
                    } else {
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'B2C', params: { screen: 'Dashboard' } }],
                        });
                    }
                }
            }, 200);
        }
    };

    const esiColor = useSelector(state => state.theme);

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} page={'Auth'} />
            <View style={{ flexDirection: "row", flexWrap: "wrap", flex: 1 }}>

                <View style={{
                    flex: dimensions.width <= 400 ? 0 : dimensions.width <= 600 ? 1 : dimensions.width <= 800 ? 3 : 4
                }}>
                    <ImageBackground style={{ height: "100%", width: "100%" }} source={require('../../assets/images/img/loginBg.png')} resizeMode="cover" >
                    </ImageBackground>
                </View>
                <View style={{ padding: 30, flex: dimensions.width <= 400 ? 4 : dimensions.width <= 600 ? 180 : 2 }}>
                    <Surface style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: "100%",
                        paddingTop: 15,
                        backgroundColor: esiColor.BackgroundColor,
                        borderBottomColor: esiColor.SBorderColor,
                        borderBottomWidth: 0.5
                    }} >
                        <ScrollView >
                            <View style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: 20
                            }}>
                                <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10, borderColor: esiColor.SBorderColor }} />
                                <Text style={{ color: esiColor.Text }}>Partner Sign In</Text>
                            </View>
                            <View>
                                {isForgotModalVisible ?
                                    <Formik
                                        innerRef={formikRef}

                                        initialValues={
                                            {
                                                Mobile: '',
                                                Mobile_OTP: ''
                                            }
                                        }
                                        validationSchema={Yup.object().shape({
                                            Mobile: Yup.string().notOneOf(otpMobileNumberError, 'In valid Mobile Number.').min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number.").required("Mobile Number is required."),
                                            Mobile_OTP: Yup.string().when('Mobile', (Mobile, schema) => {
                                                if (Mobile?.length == 10) {
                                                    return schema.min(6, 'Please provide valid OTP.')
                                                        .required('Mobile OTP is required.');
                                                } else {
                                                    return schema
                                                }
                                            })
                                        })}
                                        onSubmit={values => {
                                            setIsSubmited(true);
                                            let formdata = {
                                                "Device_Identity": deviceModelName,
                                                "Email_ID": !isNumber(values.Mobile) ? values.Mobile : "",
                                                "Mobile": isNumber(values.Mobile) ? "+91 " + values.Mobile : "",
                                                "IP_Address": deviceIPAddress,
                                                "Latitude": deviceLocation?.latitude || ipBasedLocation?.latitude || "",
                                                "Location": deviceLocation?.locationInfo || ipBasedLocation?.locationInfo || "",
                                                "Longitude": deviceLocation?.longitude || ipBasedLocation?.longitude || "",
                                                "Mobile_OTP": values.Mobile_OTP,
                                                "Expo_Push_Notification": "",
                                            }
                                            if (Platform.OS != "web") {
                                                formdata.Expo_Push_Notification = notificationToken;
                                            }
                                            setAccountActivate(false);
                                            dispatch(loginCheckNew(formdata, signinCallResponse, loginUser))

                                        }}
                                    >
                                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                                            <View style={{
                                                flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <View style={{ width: "90%", margin: "auto" }}>
                                                    <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                                        style={{ width: "100%" }}
                                                        label="Mobile Number*"
                                                        mode='outlined'
                                                        onChangeText={handleChange('Mobile')}
                                                        onBlur={(props) => {
                                                            if (Platform.OS === 'android') {
                                                                if (!isNaN(props._dispatchInstances.memoizedProps?.text)) {
                                                                    handleChange('Mobile', props._dispatchInstances.memoizedProps?.text); // Update the 'Mobile' value as a number
                                                                    if (props._dispatchInstances.memoizedProps?.text.length !== 10) {

                                                                        setMobileNumberCheck('Please enter a valid 10-digit mobile number');
                                                                    } else {
                                                                        setMobileNumberCheck(''); // Clear the error message
                                                                    }
                                                                } else {
                                                                    setMobileNumberCheck('Please enter a valid number');
                                                                }
                                                            } else {
                                                                if (!isNaN(props.nativeEvent.text)) {
                                                                    handleChange('Mobile', props.nativeEvent.text); // Update the 'Mobile' value as a number
                                                                    if (props.nativeEvent.text.length !== 10) {
                                                                        setMobileNumberCheck('Please enter a valid 10-digit mobile number');
                                                                    } else {
                                                                        setMobileNumberCheck(''); // Clear the error message
                                                                    }
                                                                } else {
                                                                    setMobileNumberCheck('Please enter a valid number');
                                                                }
                                                            }

                                                            handleBlur(props);
                                                            onMobileFieldOnBlur();
                                                        }}
                                                        value={values.Mobile}
                                                        left={<TextInput.Affix textStyle={{ color: esiColor.Text }} text="+91" />}
                                                        error={Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)}
                                                    />
                                                    <HelperText type="error" visible={Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)}>
                                                        {(touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile}
                                                    </HelperText>
                                                    <View><Text style={{ color: "red" }}>{mobileNumberCheck}</Text></View>
                                                    {(!isEmpty(otpMobileNumber) && secondsP > 0 && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                                        <HelperText type="info" style={{ color: esiColor.Text, textAlign: "right" }}
                                                            visible={!isEmpty(otpMobileNumber)}>
                                                            {secondsP} Secs To Resend OTP
                                                        </HelperText>}
                                                    {(!isEmpty(otpMobileNumber) && secondsP <= 0 && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                                        <HelperText type="info" style={{ color: esiColor.Text, textAlign: "right" }}
                                                            onPress={() => {
                                                                dispatch(signInMobileExist(otpMobileNumber, mobileCheckResponse));
                                                            }}
                                                            visible={!isEmpty(otpMobileNumber)}>
                                                            Resend OTP
                                                        </HelperText>}
                                                </View>
                                                {(!isEmpty(otpMobileNumber) && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) &&
                                                    // isEmpty(mobileNumberCheck)&&
                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                                            style={{ width: "100%" }}
                                                            label="Mobile OTP"
                                                            mode='outlined'
                                                            onChangeText={handleChange('Mobile_OTP')}
                                                            onBlur={handleBlur('Mobile_OTP')}
                                                            value={values.Mobile_OTP}
                                                            error={Boolean(touched.Mobile_OTP && errors.Mobile_OTP)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Mobile_OTP && errors.Mobile_OTP)}>
                                                            {touched.Mobile_OTP && errors.Mobile_OTP}
                                                        </HelperText>
                                                    </View>
                                                }
                                                <View style={{
                                                    width: "90%", margin: "auto", marginTop: -5, alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    {seconds > 0 && <><Text style={{ color: esiColor.Text }} >{errorMessage}</Text><ProgressBar style={{
                                                        height: 5,
                                                        width: 300,
                                                    }} progress={.30 * seconds} color={'red'} /></>}
                                                </View>
                                                {isSubmited ?
                                                    <View style={{
                                                        width: "90%", margin: "auto", marginTop: 5, alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <ActivityIndicator color='#27b6cc' style={{ margin: "auto" }} />
                                                    </View> :
                                                    !(!isEmpty(otpMobileNumber) && !Boolean((touched.Mobile || otpMobileNumberError.length > 0) && errors.Mobile)) ?
                                                        <Button textColor={esiColor.itemButtenColor} style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} disabled={isSubmited} mode="contained" onPress={() => {
                                                            if (!(formikRef.current?.errors.Mobile) && otpMobileNumber != formikRef.current?.values.Mobile) {
                                                                setOtpMobileNumber(formikRef.current?.values.Mobile);
                                                                dispatch(signInMobileExist(formikRef.current?.values.Mobile, mobileCheckResponse));
                                                            }
                                                            else if (otpMobileNumber == formikRef.current?.values.Mobile) {
                                                            }
                                                            else {
                                                                setOtpMobileNumber("");
                                                            }
                                                        }}>
                                                            Send OTP
                                                        </Button> :
                                                        <Button textColor={esiColor.itemButtenColor} style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} disabled={isSubmited} mode="contained" onPress={handleSubmit}>
                                                            Sign In
                                                        </Button>}



                                                <View style={{ width: "90%", marginTop: 15, flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>

                                                    <TouchableRipple
                                                        onPress={toggleForgotModal}
                                                    >
                                                        <Text style={{ fontSize: 11, textDecorationLine: 'underline', color: esiColor.Text }}>Sign In using Password?</Text>
                                                    </TouchableRipple>

                                                    <TouchableRipple
                                                        onPress={() => {
                                                            register()
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: 11, textDecorationLine: 'underline', color: esiColor.Text }}>Don't have an account? Sign Up</Text>
                                                    </TouchableRipple>
                                                </View>
                                            </View>
                                        )}
                                    </Formik>
                                    : <Formik
                                        innerRef={formikRefM}
                                        initialValues={
                                            {
                                                Email_Id: '',
                                                password: '',
                                                Mobile: '',
                                            }
                                        }
                                        validationSchema={Yup.object().shape({
                                            Email_Id: Yup.string().trim().when('Mobile', (Mobile, schema) => {
                                                if (isNumber(Mobile) && (Mobile?.length > 10 || Mobile?.length < 10)) {
                                                    return schema.email('Please provide valid Mobile or Email.')
                                                        .max(255);
                                                }
                                                else if (!(Mobile?.length) || (Mobile?.length == 0)) {
                                                    return schema.required("Mobile or Email is required.")
                                                }
                                                else if (!isNumber(Mobile)) {
                                                    return schema.email('Please provide valid Mobile or Email.')
                                                        .max(255)
                                                }
                                            }).required("Mobile or Email is required.")
                                            ,
                                            Mobile: Yup.string(),
                                            password: Yup.string()
                                                .max(255)
                                                .required('Password is required.')
                                        })}
                                        onSubmit={values => {
                                            setIsSubmited(true);
                                            let formdata = {
                                                "Device_Identity": deviceModelName,
                                                "Email_ID": !isNumber(values.Email_Id) ? values.Email_Id : "",
                                                "Mobile": isNumber(values.Email_Id) ? "+91 " + values.Email_Id : "",
                                                "IP_Address": deviceIPAddress,
                                                "Latitude": deviceLocation?.latitude || ipBasedLocation?.latitude || "",
                                                "Location": deviceLocation?.locationInfo || ipBasedLocation?.locationInfo || "",
                                                "Longitude": deviceLocation?.longitude || ipBasedLocation?.longitude || "",
                                                "Password": values.password,
                                                "Expo_Push_Notification": ""
                                            }
                                            if (Platform.OS != "web") {
                                                formdata.Expo_Push_Notification = notificationToken;
                                            }
                                            setAccountActivate(false);
                                            dispatch(loginCheckNew(formdata, signinCallResponse, loginUser))
                                        }}
                                    >
                                        {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                            <View style={{
                                                flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <View style={{ width: "90%", margin: "auto" }}>
                                                    <TextInput style={{ width: "100%", margin: "auto" }}
                                                        label="Mobile or Email"
                                                        mode='outlined'
                                                        onChangeText={(props) => {
                                                            setFieldValue('Email_Id', props);
                                                            setFieldValue('Mobile', props);
                                                        }}
                                                        onBlur={(props) => {
                                                            handleBlur(props);
                                                            if (Platform.OS == "web") {
                                                                setFieldValue('Mobile', props.target.value);
                                                            } else {
                                                                setFieldValue('Mobile', props.nativeEvent.text);
                                                            }
                                                        }}
                                                        value={values.Email_Id}
                                                        error={Boolean(touched.Email_Id && errors.Email_Id)}
                                                    />
                                                    <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.Email_Id && errors.Email_Id)}>
                                                        {touched.Email_Id && errors.Email_Id}
                                                    </HelperText>
                                                </View>
                                                <View style={{ width: "90%", margin: "auto" }}>
                                                    <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                                        style={{ width: "100%" }}
                                                        label="Password"
                                                        mode='outlined'
                                                        onChangeText={handleChange('password')}
                                                        onBlur={handleBlur('password')}
                                                        value={values.password}
                                                        secureTextEntry={true}
                                                        error={Boolean(touched.password && errors.password)}
                                                        secureTextEntry={!show}
                                                        right={<TextInput.Icon color={esiColor.SIconColor} onPress={() => setShow(!show)} icon={show ? "eye" : "eye-off"} />}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.password && errors.password)}>
                                                        {touched.password && errors.password}
                                                    </HelperText>
                                                </View>
                                                <View style={{
                                                    width: "90%", margin: "auto", marginTop: -5, alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    {seconds > 0 && <><Text style={{color: esiColor.Text}} >{errorMessage}</Text><ProgressBar style={{
                                                        height: 5,
                                                        width: 300,
                                                    }} progress={.30 * seconds} color={'red'} /></>}
                                                </View>
                                                {isSubmited ?
                                                    <View style={{
                                                        width: "90%", margin: "auto", marginTop: 5, alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <ActivityIndicator color='#27b6cc' style={{ margin: "auto" }} />
                                                    </View> :
                                                    <Button textColor={esiColor.itemButtenColor} style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} disabled={isSubmited} mode="contained" onPress={handleSubmit}>
                                                        Sign In
                                                    </Button>}

                                                {accountActivate &&
                                                    <View style={{ width: "90%", marginTop: 15, flexDirection: "row", flexWrap: "wrap", justifyContent: 'center' }}>
                                                        { }
                                                        <TouchableRipple
                                                            onPress={toggleForgotModal} disabled={isSubmited}
                                                        >
                                                            <Text style={{ fontSize: 14, textDecorationLine: 'underline', color: esiColor.Text }}>Activate and Sign In by using OTP.</Text>
                                                        </TouchableRipple>


                                                    </View>
                                                }

                                                <View style={{ width: "90%", marginTop: 15, flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-between' }}>

                                                    <TouchableRipple
                                                        onPress={toggleForgotModal}
                                                    >
                                                        <Text style={{ fontSize: 11, textDecorationLine: 'underline', color: esiColor.Text }}>Sign In using OTP?</Text>
                                                    </TouchableRipple>

                                                    <TouchableRipple
                                                        onPress={() => {
                                                            register()
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: 11, textDecorationLine: 'underline', color: esiColor.Text }}>Don't have an account? Sign Up</Text>
                                                    </TouchableRipple>
                                                </View>
                                            </View>
                                        )}
                                    </Formik>}
                            </View>

                        </ScrollView>
                        <Modal animationIn={"slideInDown"}
                            deviceWidth={dimensions.width}
                            deviceHeight={dimensions.height}
                            style={{ alignItems: 'center' }} isVisible={isSignUpModalVisible}>
                            <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                                <SignUP navigation={navigation} dropDownAlertFun={signUpSuccessResponse} toggleModal={toggleSignUpModal} />

                            </View>
                        </Modal>

                        <Modal animationIn={"slideInDown"}
                            deviceWidth={dimensions.width}
                            deviceHeight={dimensions.height}
                            style={{ alignItems: 'center' }} isVisible={signUpSuccess}>
                            <View style={{ flex: 1, flexDirection: "column", padding: 15, maxWidth: dimensions.width - 100, minWidth: 300, minHeight: 100, maxHeight: 250, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                                <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10, alignSelf: "center", borderColor: esiColor.SBorderColor }} />

                                <Text style={{ alignSelf: "center", color: esiColor.Text }} >Congratulations, your account has been successfully created. Please check register email for activation link. </Text>

                                <Button textColor={esiColor.itemButtenColor} style={{ marginTop: 15, maxWidth: 150, backgroundColor: esiColor.GBFColor, alignSelf: "center" }} mode="contained" onPress={() => { setSignUpSuccess(false) }}>
                                    Okay
                                </Button>
                            </View>
                        </Modal>

                        <Modal animationIn={"slideInDown"}
                            deviceWidth={dimensions.width}
                            deviceHeight={dimensions.height}
                            style={{ alignItems: 'center' }} isVisible={activateSuccess}>
                            <View style={{ flex: 1, flexDirection: "column", padding: 15, maxWidth: dimensions.width - 100, minWidth: 300, minHeight: 100, maxHeight: 250, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                                <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10, alignSelf: "center", borderColor: esiColor.SBorderColor }} />

                                <Text style={{ alignSelf: "center", color: esiColor.Text }} >Congratulations, your account has now ready for activation. Please check register email for activation link.</Text>

                                <Button textColor={esiColor.itemButtenColor} style={{ marginTop: 15, maxWidth: 150, backgroundColor: esiColor.GBFColor, alignSelf: "center" }} mode="contained" onPress={() => { setActivateSuccess(false); toggleModal(false); }}>
                                    Okay
                                </Button>
                            </View>
                        </Modal>
                        {
                            (isSignUpModalVisible || isForgotModalVisible || secondsP > 0)
                            &&
                            <DropdownAlert
                                ref={ref => {
                                    if (ref) {
                                        dropDownAlertRef = ref;
                                    }
                                }}
                                zIndex={1}
                                showCancel={true}
                                infoColor={"#27b6cc"}
                            />}
                    </Surface>
                    <Copyright />
                </View>
            </View>
        </Surface>
    );
}