import 'react-native-gesture-handler';
import * as React from 'react';
import { Text, StyleSheet, View, TouchableOpacity, FlatList, Platform } from "react-native";
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { Checkbox } from 'react-native-paper';
import { string } from 'yup';
import SearchBar from './SearchBar';
import { useSelector } from 'react-redux';

function EsiCheckboxTree(props: any) {
  //props variable consists of all <List.Accordion> properties along with map data.
  const { sortByOptionsInit, updateOptions, checkChildChange, changeChecked } = props;
  const [searchPhrase, setSearchPhrase] = React.useState("");
  const [clicked, setClicked] = React.useState(false);

  // React.useEffect(()=>{},[sortByOptionsInit])
  const esiColor = useSelector(state => state.theme);

  const changeExpand = (data: any) => {
    let key = data.key;
    let response = JSON.parse(JSON.stringify(sortByOptionsInit))
    response.filter((item: any) => { return item.key === key })[0].expanded = !sortByOptionsInit.filter((item: any) => { return item.key === key })[0].expanded;
    updateOptions(response);
  }

  const checkChildChecked = (data: any) => {
    let count = 0;
    data.map((item: any) => {
      if (item.checked === "checked") {
        count++
      }
    })
    return (count === data.length && data.length > 0) ? 'checked' : count === 0 ? "unchecked" : "indeterminate";
  }



  const checkParentIsExist = (data: any) => {
    let key = data.key;
    let keys = key.split('-');
    return keys.length > 1
  }

  const styles = StyleSheet.create({

    checkboxContainer: {
      flexDirection: "row",
      paddingLeft: 40,
      marginTop: -10
    },
    checkboxNoParentContainer: {
      flexDirection: "row",
      marginTop: -5,
      paddingLeft: 20,
    },
    checkboxPContainer: {
      flexDirection: "row",
      marginTop: -5
    },
    label: {
      // margin: 6,
      marginTop: 10,
      fontSize: 10,
      color: esiColor.itemColor

    },
    labelP: {
      marginTop: 10,
      fontWeight: 'bold',
      fontSize: 10,
      color: esiColor.itemColor

    },
  });


  const buildCheckBox = (input: any) => {
    return (
      <View  >
        {input.item.children ?
          <View style={{ marginTop: input.index === 0 ? 5 : 0 }} >

            <View key={"che" + input.item.key} style={styles.checkboxPContainer}>
              <TouchableOpacity disabled={input.item.children.length <= 0} style={styles.checkboxPContainer}
                onPress={() => {
                  changeExpand(input.item);
                }}
              >
                {input.item.expanded && input.item.children.length > 0 ?
                  <AntDesign style={{ marginTop: 8, marginRight: -4, marginLeft: 5 }} name="minus" size={16} color="green" />
                  :
                  <AntDesign style={{ marginTop: 8, marginRight: -4, marginLeft: 5 }} name="plus" size={16} color="green" />
                }
              </TouchableOpacity>
              <TouchableOpacity style={styles.checkboxPContainer}
                disabled={input.item.children.length <= 0}
                onPress={() => {
                  checkChildChange(input.item, checkChildChecked(input.item.children));
                }}
              >
                <Checkbox.Android
                  status={checkChildChecked(input.item.children)}
                  uncheckedColor={esiColor.itemColor}

                />

                <Text style={styles.labelP}>{input.item.label}</Text>
              </TouchableOpacity>
            </View>
            {input.item.expanded &&
              <FlatList
                data={input.item.children.slice(0, 10)}
                renderItem={buildCheckBox}
                keyExtractor={(item, index) => input.index + "item" + index}
              />
            }
          </View> :
          <TouchableOpacity
            onPress={() => {
              changeChecked(input.item, input.item.checked);
            }}
          >
            <View style={checkParentIsExist(input.item) ? styles.checkboxContainer : styles.checkboxNoParentContainer}>
              <Checkbox.Android
                status={input.item.checked}
                uncheckedColor={esiColor.itemColor}

              />
              <Text style={checkParentIsExist(input.item) ? styles.label : styles.labelP}>{input.item.label}</Text>
            </View>
          </TouchableOpacity>
        }
      </View>
    );
  }
  // buildCheckBox(sortByOptions)

  const [searchQuery, setSearchQuery] = React.useState('');

  const onChangeSearch = (query: string) => {
    // let response: any[] = [];
    setSearchQuery(query);
    // sortByOptionsInit.map((item: any) => {
    //   let exist = false;
    //   const data = JSON.parse(JSON.stringify(item));
    //   if (item.children) {
    //     data.children = item.children.filter((childItem: any) => {
    //       if ((childItem.label.toLowerCase()).includes(query.toLowerCase())) {
    //         return true;
    //       }
    //     })
    //     response.push(data);
    //   } else {
    //     if (data.label.toLowerCase().includes(query.toLowerCase())) {
    //       response.push(data);
    //     }
    //   }

    // })
    // updateOptions(response);
  };


  const onChangeResponse = (query) => {
    let response: any[] = [];
    sortByOptionsInit?.map((item: any) => {
      let exist = false;
      const data = JSON.parse(JSON.stringify(item));
      if (item.children) {
        data.children = item.children.filter((childItem: any) => {
          if ((childItem.label.toLowerCase()).includes(query.toLowerCase())) {
            return true;
          }
        })
        response.push(data);
      } else {
        if (data.label.toLowerCase().includes(query.toLowerCase())) {
          response.push(data);
        }
      }

    })
    return response;
  }


  return (
    <View>
      {sortByOptionsInit?.length > 1 &&
        <SearchBar
          searchPhrase={searchQuery}
          setSearchPhrase={onChangeSearch}
          clicked={clicked}
          setClicked={setClicked}
        />

      }

      <FlatList
        data={onChangeResponse(searchQuery)?.slice(0, 10)}
        renderItem={buildCheckBox}
        keyExtractor={(item, index) => "item" + index}
      />
    </View>

  );
}

export default EsiCheckboxTree;

