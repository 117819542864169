import {
    COUPONS_REQUEST,
    COUPONS_SUCCESS,
    COUPONS_FAIL,
    COUPON_GET_REQUEST,
    COUPON_GET_SUCCESS,
    COUPON_GET_FAIL,
    COUPON_CREATE_REQUEST,
    COUPON_CREATE_SUCCESS,
    COUPON_CREATE_FAIL,
    COUPON_UPDATE_REQUEST,
    COUPON_UPDATE_SUCCESS,
    COUPON_UPDATE_FAIL,
    COUPON_PARTNERS_REQUEST,
    COUPON_PARTNERS_SUCCESS,
    COUPON_PARTNERS_FAIL,
    COUPON_BRANDS_REQUEST,
    COUPON_BRANDS_SUCCESS,
    COUPON_BRANDS_FAIL,
    COUPON_CATEGORIES_REQUEST,
    COUPON_CATEGORIES_SUCCESS,
    COUPON_CATEGORIES_FAIL ,
    COUPONS_FILTER,
    COUPON_DATA
  } from '../actions/couponAction'
  
  const initialState = {
    coupons: { all: [], error: '', isLoading: false },
    coupon: { coupon: {}, error: '', isLoading: false },
    couponCreate: { coupon: {}, error: '', isLoading: false },
    couponUpdate: { coupon: {}, error: '', isLoading: false },
    couponPartners: { partners: [], error: '', isLoading: false },
    couponBrands: { brands: [], error: '', isLoading: false },
    couponCategories: { categories: [], error: '', isLoading: false },
    couponFilter: { select: 'All', search: '' },
    couponData: { data: {}, error: '', isLoading: false },
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case COUPONS_REQUEST:
        return { ...state, coupons: { all: state.coupons.all, error: '', isLoading: true } };
      case COUPONS_SUCCESS:
        return { ...state, coupons: { all: action.payload, error: '', isLoading: false } };
      case COUPONS_FAIL:
        return { ...state, coupons: { all: [], error: action.payload, isLoading: false } };
  
      case COUPON_GET_REQUEST:
        return { ...state, coupon: { coupon: {}, error: '', isLoading: true } };
      case COUPON_GET_SUCCESS:
        return { ...state, coupon: { coupon: action.payload, error: '', isLoading: false } };
      case COUPON_GET_FAIL:
        return { ...state, coupon: { coupon: {}, error: action.payload, isLoading: false } };
        case COUPON_CREATE_REQUEST:
          return { ...state, couponCreate: { coupon: {}, error: '', isLoading: true } };
        case COUPON_CREATE_SUCCESS:
          return { ...state, couponCreate: { coupon: state.couponCreate.coupon, error: '', isLoading: false } };
        case COUPON_CREATE_FAIL:
          return { ...state, couponCreate: { coupon: {}, error: action.payload, isLoading: false } };
          case COUPON_UPDATE_REQUEST:
        return { ...state, couponUpdate: { coupon: {}, error: '', isLoading: true } };
      case COUPON_UPDATE_SUCCESS:
        return { ...state, couponUpdate: { coupon: state.couponCreate.coupon, error: '', isLoading: false } };
      case COUPON_UPDATE_FAIL:
        return { ...state, couponUpdate: { coupon: {}, error: action.payload, isLoading: false } };
        case COUPON_DATA:
  
            return { ...state, couponData: { data: action.value, error: '', isLoading: true } };
       
          case COUPONS_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
              res = { ...state, couponFilter: { search: state.couponFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
              res = { ...state, couponFilter: { select: state.couponFilter.select, search: action.value } };
            }
            return res;
        case COUPON_PARTNERS_REQUEST:
          return { ...state, couponPartners: { partners: state.couponPartners.partners, error: '', isLoading: true }, };
        case COUPON_PARTNERS_SUCCESS:
          return { ...state, couponPartners: { partners: action.payload, error: '', isLoading: false } };
        case COUPON_PARTNERS_FAIL:
          return { ...state, couponPartners: { partners: [], error: action.payload, isLoading: false } };
          case COUPON_BRANDS_REQUEST:
            return { ...state, couponBrands: { brands: state.couponBrands.brands, error: '', isLoading: true }, };
          case COUPON_BRANDS_SUCCESS:
            return { ...state, couponBrands: { brands: action.payload, error: '', isLoading: false } };
          case COUPON_BRANDS_FAIL:
            return { ...state, couponBrands: { brands: [], error: action.payload, isLoading: false } };
            case COUPON_CATEGORIES_REQUEST:
        return { ...state, couponCategories: { categories: state.couponCategories.categories, error: '', isLoading: true }, };
      case COUPON_CATEGORIES_SUCCESS:
        return { ...state, couponCategories: { categories: action.payload, error: '', isLoading: false } };
      case COUPON_CATEGORIES_FAIL:
        return { ...state, couponCategories: { categories: [], error: action.payload, isLoading: false } };
        default:
        return state;
    }
  }
  