import axios from "axios";
import config from './config';
import shopperconfig from "./shoppers/shopperconfig";

export const WALLET_HISTORY_REQUEST = "WALLET_HISTORY_REQUEST";
export const WALLET_HISTORY_SUCCESS = "WALLET_HISTORY_SUCCESS";
export const WALLET_HISTORY_FAIL = "WALLET_HISTORY_FAIL";

export const WALLET_CREATE_REQUEST = "WALLET_CREATE_REQUEST";
export const WALLET_CREATE_SUCCESS = "WALLET_CREATE_SUCCESS";
export const WALLET_CREATE_FAIL = "WALLET_CREATE_FAIL";

const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
/*=============================================================
                  Get Wallet History Action
 ===============================================================*/

 export const getWalletHistory = (formData: any) => async dispatch => {
    try {
      dispatch({
        type: WALLET_HISTORY_REQUEST
      });
      let { data, status } = await axios.post(
        `${shopperconfig.nodePartnerUrl}/Partner_Wallet/Wallet_History_Get?PageNo=1&PageSize=1000&SortBy=Wallet_History_Id&SortOrder=ASC`, formData, {
          headers: nodeheaders
      });
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: WALLET_HISTORY_SUCCESS,
            payload:data
          });
        } else {
  
          dispatch({
            type: WALLET_HISTORY_SUCCESS,
            payload: data.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: WALLET_HISTORY_FAIL,
        payload: err
      });
    }
  };
/*=============================================================
             ADD Wallet Action
 ===============================================================*/

export const createWallet = (formData: any, navigation: any, toast: any, resetForm: any, successResponseFunction: any) => async dispatch => {
  dispatch({
    type: WALLET_CREATE_REQUEST
  });
  await axios.post(`${config.url}/Partner_Wallet/Withdraw_Request`, formData, {
    headers: headers,
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {

        dispatch({
          type: WALLET_CREATE_SUCCESS
        });
        successResponseFunction();
        resetForm();
      } else {
        toast.show({ message: successResponse.Response_Message, type: 'info', duration: 3000, position: 'top' });
        const errors = {};
        dispatch({
          type: WALLET_CREATE_FAIL,
          payload: successResponse.Response_Message
        });
      }
    })
    .catch(error => {
    toast.show({ message: "Currently server is not working.Please try sometime later.", type: 'info', duration: 3000, position: 'top' });

      dispatch({
        type: WALLET_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
/*=============================================================
                  Get Wallet Action
 ===============================================================*/

export const getWalletByUser = (updateStates: any, formData: any) => async (dispatch: any) => {
   try {
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Wallet/Wallet_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );

    if (data) {
       updateStates(data.results[0], false);
    }
  } catch (error) {
    }
};

