import {
    SBCPARTNERCITYCOUPON_REQUEST,
    SBCPARTNERCITYCOUPON_SUCCESS,
    SBCPARTNERCITYCOUPON_FAIL,

    SBCPARTNERCITYCOUPON_GET_REQUEST,
    SBCPARTNERCITYCOUPON_GET_SUCCESS,
    SBCPARTNERCITYCOUPON_GET_FAIL,

    SBCPARTNERCITYCOUPON_CREATE_REQUEST,
    SBCPARTNERCITYCOUPON_CREATE_SUCCESS,
    SBCPARTNERCITYCOUPON_CREATE_FAIL,

    SBCPARTNERCITYCOUPON_UPDATE_REQUEST,
    SBCPARTNERCITYCOUPON_UPDATE_SUCCESS,
    SBCPARTNERCITYCOUPON_UPDATE_FAIL,

    SBCPARTNERCITYCOUPON_DELETE_REQUEST,
    SBCPARTNERCITYCOUPON_DELETE_SUCCESS,
    SBCPARTNERCITYCOUPON_DELETE_FAIL,

    SBCPARTNERCITY_COUPONS_REQUEST,
    SBCPARTNERCITY_COUPONS_SUCCESS,
    SBCPARTNERCITY_COUPONS_FAIL,

    SBCPARTNERCITYCOUPON_FILTER,

} from '../actions/sbcpartnercityCouponAction'

const initialState = {

    sbcpartnercitycoupons: { all: [], error: '', isLoading: false },
    sbcpartnercitycoupon: { sbcpartnercitycoupon: {}, error: '', isLoading: false },

    sbcpartnercitycouponCreate: { sbcpartnercitycoupon: {}, error: '', isLoading: false },
    sbcpartnercitycouponUpdate: { sbcpartnercitycoupon: {}, error: '', isLoading: false },
    sbcpartnercitycouponDelete: { sbcpartnercitycoupon: {}, error: '', isLoading: false },

    sbcpartcitycoupons: { coupons: [], error: '', isLoading: false },

    sbcpartnercitycouponFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case SBCPARTNERCITYCOUPON_REQUEST:
            return { ...state, sbcpartnercitycoupons: { all: state.sbcpartnercitycoupons.all, error: '', isLoading: true } };
        case SBCPARTNERCITYCOUPON_SUCCESS:
            return { ...state, sbcpartnercitycoupons: { all: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITYCOUPON_FAIL:
            return { ...state, sbcpartnercitycoupons: { all: [], error: action.payload, isLoading: false } };

        case SBCPARTNERCITYCOUPON_GET_REQUEST:
            return { ...state, sbcpartnercitycoupon: { sbcpartnercitycoupon: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYCOUPON_GET_SUCCESS:
            return { ...state, sbcpartnercitycoupon: { sbcpartnercitycoupon: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITYCOUPON_GET_FAIL:
            return { ...state, sbcpartnercitycoupon: { sbcpartnercitycoupon: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITYCOUPON_CREATE_REQUEST:
            return { ...state, sbcpartnercitycouponCreate: { sbcpartnercitycoupon: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYCOUPON_CREATE_SUCCESS:
            return { ...state, sbcpartnercitycouponCreate: { sbcpartnercitycoupon: state.sbcpartnercitycouponCreate.sbcpartnercitycoupon, error: '', isLoading: false } };
        case SBCPARTNERCITYCOUPON_CREATE_FAIL:
            return { ...state, sbcpartnercitycouponCreate: { sbcpartnercitycoupon: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITYCOUPON_UPDATE_REQUEST:
            return { ...state, sbcpartnercitycouponUpdate: { sbcpartnercitycoupon: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYCOUPON_UPDATE_SUCCESS:
            return { ...state, sbcpartnercitycouponUpdate: { sbcpartnercitycoupon: state.sbcpartnercitycouponUpdate.sbcpartnercitycoupon, error: '', isLoading: false } };
        case SBCPARTNERCITYCOUPON_UPDATE_FAIL:
            return { ...state, sbcpartnercitycouponUpdate: { sbcpartnercitycoupon: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITYCOUPON_DELETE_REQUEST:
            return { ...state, sbcpartnercitycouponDelete: { sbcpartnercitycoupon: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYCOUPON_DELETE_SUCCESS:
            return { ...state, sbcpartnercitycouponDelete: { sbcpartnercitycoupon: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITYCOUPON_DELETE_FAIL:
            return { ...state, sbcpartnercitycouponDelete: { sbcpartnercitycoupon: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITY_COUPONS_REQUEST:
            return { ...state, sbcpartcitycoupons: { coupons: state.sbcpartcitycoupons.coupons, error: '', isLoading: true }, };
        case SBCPARTNERCITY_COUPONS_SUCCESS:
            return { ...state, sbcpartcitycoupons: { coupons: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITY_COUPONS_FAIL:
            return { ...state, sbcpartcitycoupons: { coupons: [], error: action.payload, isLoading: false } };

        case SBCPARTNERCITYCOUPON_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, sbcpartnercitycouponFilter: { search: state.sbcpartnercitycouponFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, sbcpartnercitycouponFilter: { select: state.sbcpartnercitycouponFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}