import React, { useState, useEffect } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { Button, Text, Surface, ActivityIndicator } from 'react-native-paper';
import Header from '../auth/components/header';
import isEmpty from '../../state/validations/is-empty';
import { getAllContentManagementStaticAction } from '../../state/actions/contentActions';
import Headers from '../Farmer-Module/header';

export default function TermsAndConditions(props: any) {
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const { navigation } = props;
    const page = props.route?.params?.page;
    const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();
    const [contentsOne, setContentsOne] = useState({});
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const responseFunction = (data) => {
        setContentsOne(data)
    }

    useEffect(() => {
        if (isEmpty(contentsOne)) {
            let formdata = {
                "Cms_Static_Id": "",
                "Name": "Partner_Terms and conditions",
                "Records_Filter": "FILTER"
            }
            dispatch(getAllContentManagementStaticAction(formdata, responseFunction));
        }
    }, [contentsOne]);
    const tagsStyles = {
        p: {
          color: esiColor.itemColor,          
        }   
      };

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            {page === 'farmer' ? <Headers navigation={navigation} /> : <Header navigation={navigation} page={'Auth'} />}

            {/* <Header navigation={navigation} page={'Auth'} /> */}
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                {/* {ID ? <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                    <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                        navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } });
                    }
                    }>
                        Go Back
                    </Button>
                </View>:<View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
                    <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130,height:50, justifyContent: 'flex-start' }} onPress={() => navigation.navigate("B2C",{screen:'Dashboard'})}>
                        Go Back
                    </Button>
                </View>} */}
                <Text style={{ color: esiColor.brandFontColor, fontSize: 20, fontWeight: "bold", justifyContent: 'center', textAlign: 'center', margin: 5 }}>
                    Terms and Conditions
                </Text>
                <View style={{
                    flex: 1,
                    marginHorizontal: 2, borderRadius: 25, padding: 10, margin: 'auto', backgroundColor: esiColor.BackgroundColor, flexDirection: 'column', borderColor: esiColor.itemColor, borderWidth: 1,
                    shadowOffset: { width: 0, height: 2 },
                    shadowColor: esiColor.shadowColor,
                    marginTop: "2%",
                    marginBottom: "5%",
                    shadowOpacity: 0.2,
                    shadowRadius: 10,
                    elevation: 10,
                    marginLeft: "auto", marginRight: "auto",
                    width: dimensions >= 10000 ? "90%" : "95%",
                }}>
                    <View>
                        {!isEmpty(contentsOne) ? (contentsOne?.Description ?
                            <RenderHTML
                                tagsStyles={tagsStyles}
                                source={{ html: contentsOne?.Description }} /> : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                <Text style={{ color: esiColor.itemColor }}>No Data found!</Text></View>) :
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                <ActivityIndicator color='#27b6cc' animating={true} style={{ marginTop: 25 }} />
                            </View>}

                    </View>
                </View>
            </ScrollView>
        </Surface>

    );
}