import React, { useState, useEffect } from "react";
import { getAllGiftVendorsAction, giftvendorsFilter, resetFilter } from "../../../state/actions/giftvendorAction";
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, Image, RefreshControl } from 'react-native';
import { Card, DataTable, Button, Searchbar, Surface, Dialog, Portal, IconButton, DefaultTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../state/validations/is-empty';
import 'react-native-gesture-handler'
import Header from "../../auth/components/header";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import AsyncStorage from "@react-native-async-storage/async-storage";

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Vendor_Id',
    label: 'Vendor ID'
  },
  {
    value: 'Partner_Name',
    label: 'Partner Name'
  },
  {
    value: 'Vendor_Name',
    label: 'Vendor Name'
  },
  {
    value: 'Status',
    label: 'Status'
  },
];
const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const GiftVendorsGet = (props: any) => {
  const { navigation } = props;
  const [giftvendors, setGiftVendors] = useState([]);
  const [page, setPage] = React.useState(0);
  const esiColor = useSelector(state => state.theme);
  const allgiftvendors = useSelector(state => state.giftvendor.giftvendors.all);
  let giftVendorsMenu = useSelector(state => state.giftvendor.giftvendorsFilter.select);
  let giftVendorsSearch = useSelector(state => state.giftvendor.giftvendorsFilter.search);
  const [giftVendors, setData] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const hideDialog = () => setVisible(false);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, allgiftvendors?.length);
  const EmailID = useSelector(state => state.auth?.user);
  const dispatch = useDispatch();

  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);

  const [showDropDown, setShowDropDown] = React.useState(false);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (EmailID) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Partner_Details_Id": EmailID?.Partner_Details_Id,
        "Records_Filter": "FILTER",
        "Vendor_Id": "",
        "Vendor_Name": ""
      };
      handleSearch("");
      dispatch(getAllGiftVendorsAction(formData))
      dispatch(giftvendorsFilter('search', ''));
      dispatch(giftvendorsFilter('select', 'All'));
    }

  }, [EmailID]);

  // for page dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  // navigations
  const handleClickGifts = async (giftVendors) => {
    navigation.navigate('Gift', { giftsdetails: giftVendors.Vendor_Id })
  };
  const handleClickImage = async (giftVendors) => {
    navigation.navigate('GiftImage', { giftsdetails: giftVendors.Vendor_Id, vendorname: giftVendors.Vendor_Name })
  };
  const handleClickedit = async (allgiftvendors) => {
    navigation.navigate('EditGiftVendor', { partnerid: allgiftvendors.Partner_Details_Id, Vendorid: allgiftvendors.Vendor_Id })
  };

  // search
  const [oldSearch, setOldSearch] = React.useState(0);
  const checkgiftvendorForTable = () => {
    if (giftVendorsSearch) {
      try {
        if (giftVendorsMenu === "All") {
          return allgiftvendors.filter(x => {
            let colummns = ["Vendor_Id", "Partner_Name", "Vendor_Name", "Status"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
              const subString = typeof x[colummns[i]] === "string" ? giftVendorsSearch.toLowerCase() : giftVendorsSearch;
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(giftVendorsSearch.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString == giftVendorsSearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return allgiftvendors.filter(x => {
            let mainString = typeof x[giftVendorsMenu] == "string" ? x[giftVendorsMenu].toLowerCase() : x[giftVendorsMenu];
            let subString = typeof x[giftVendorsMenu] == "string" ? giftVendorsSearch.toLowerCase() : giftVendorsSearch;
            if (typeof x[giftVendorsMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString == giftVendorsSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (page > 0) {
          if (oldSearch != giftVendorsSearch.length) {
            setOldSearch(giftVendorsSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch != giftVendorsSearch.length) {
          setOldSearch(giftVendorsSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return allgiftvendors
    }
  }

  useEffect(() => {
    if (!isEmpty(allgiftvendors)) {
      setGiftVendors(allgiftvendors);
    } else {
      setGiftVendors([]);
    }
  }, [allgiftvendors]);

  // Sorting 
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Vendor_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  function descendingComparator(a, b, orderBy) {
    try {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } catch (error) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

  }
  function getComparator(order, orderBy) {
    return order === 'descending'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  const handleChange = itemValue => {
    dispatch(giftvendorsFilter('select', itemValue));
  };
  const handleSearch = value => {
    dispatch(giftvendorsFilter('search', value));
  };
  const data = stableSort(
    checkgiftvendorForTable(), getComparator(order, orderBy))
  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const styles = StyleSheet.create({
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    titles: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 160
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'left',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 150
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
            <IconButton
              icon="close"
              color={esiColor.GBFColor}
              size={20}
              onPress={() => setVisible(!visible)}
            />
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>Gift Vendor View</Text>
          </Dialog.Title>

          <Dialog.ScrollArea>
            <ScrollView >
              <Dialog.Content>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}>
                    <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Vendor ID</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text style={{ color: esiColor.GBFColor }}>{giftVendors?.Vendor_Id}
                    </Text>
                  </View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{giftVendors?.Partner_Details_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}>
                    <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Name</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{giftVendors?.Partner_Name} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Vendor Name</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{giftVendors?.Vendor_Name} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Vendor Email</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{giftVendors?.Vendor_Email_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{giftVendors?.Created_Date} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{giftVendors?.Created_By} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View><View style={{ flex: 2 }}><Text style={{ color: giftVendors?.Status === "Active" ? esiColor.GBFColor : "red" }}>{giftVendors?.Status} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View>
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
          navigation.navigate('PartnerMainCenter')
          handleSearch("")
          dispatch(resetFilter())
        }} >
          Go Back
        </Button>
      </View>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Gift Vendors</Text>
        </View>
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[styles.dropdown]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={filterOptions}
                containerStyle={styles.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={giftVendorsMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={giftVendorsMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Vendor ID" value="Vendor_Id" />
                <Picker.Item label="Partner Name" value="Partner_Name" />
                <Picker.Item label="Vendor Name" value="Vendor_Name" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              variant="outlined"
              onChangeText={(value) => handleSearch(value)}
              value={giftVendorsSearch}
            />
          </View>
        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={styles.mainbox}>
            <Card >
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={styles.databeHeader}>
                  <DataTable.Title  active={orderBy === 'Vendor_Id'} sortDirection={orderBy === 'Vendor_Id' ? order : 'ascending'} onPress={createSortHandler('Vendor_Id')} style={styles.title}><Text style={styles.titletext}>Vendor ID</Text></DataTable.Title>
                  <DataTable.Title  active={orderBy === 'Partner_Name'} sortDirection={orderBy === 'Partner_Name' ? order : 'ascending'} onPress={createSortHandler('Partner_Name')} style={styles.title}><Text style={styles.titletext}>Partner Name</Text></DataTable.Title>
                  <DataTable.Title  active={orderBy === 'Vendor_Name'} sortDirection={orderBy === 'Vendor_Name' ? order : 'ascending'} onPress={createSortHandler('Vendor_Name')} style={styles.title}><Text style={styles.titletext}>Vendor Name</Text></DataTable.Title>
                  <DataTable.Title  active={orderBy === 'Vendor_Email_Id'} sortDirection={orderBy === 'Vendor_Email_Id' ? order : 'ascending'} onPress={createSortHandler('Vendor_Email_Id')} style={styles.title}><Text style={styles.titletext}>Vendor Email ID</Text></DataTable.Title>
                  <DataTable.Title  active={orderBy === 'Created_Date'} sortDirection={orderBy === 'Created_Date' ? order : 'ascending'} onPress={createSortHandler('Created_Date')} style={styles.title}><Text style={styles.titletext}>Created Date</Text></DataTable.Title>
                  <DataTable.Title  style={styles.title}><Text style={styles.titletext}>Status</Text></DataTable.Title>
                  <DataTable.Title  style={styles.title}><Text style={styles.titletext}>User Gifts</Text></DataTable.Title>
                  <DataTable.Title  style={styles.title}><Text style={styles.titletext}>Gift Images</Text></DataTable.Title>
                  <DataTable.Title theme={customTheme} style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {data?.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage)?.map((giftVendors, i) => (
                  <DataTable.Row style={styles.databeBox} key={i}>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{giftVendors.Vendor_Id}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{giftVendors?.Partner_Name}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{giftVendors?.Vendor_Name}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{giftVendors.Vendor_Email_Id}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{giftVendors.Created_Date}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{giftVendors.Status}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}> <Button icon="wallet-giftcard" textColor={esiColor.SIconColor} onPress={(e) => { handleClickGifts(giftVendors); }}></Button></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}> <Button icon="image-move" textColor={esiColor.SIconColor} onPress={(e) => { handleClickImage(giftVendors); }}></Button></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>
                      {(permissions.Gifts_Management_Vendors_Images_User_Gifts === "write") && <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClickedit(giftVendors); }} />
                      }
                      <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(giftVendors); setVisible(!visible) }} /></DataTable.Cell>
                  </DataTable.Row>
                ))
                }
                {isEmpty(data) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                <DataTable.Pagination
                  // theme={customTheme}
                  page={page}
                  numberOfPages={Math.ceil(allgiftvendors?.length / numberOfItemsPerPage)}
                  onPageChange={page => setPage(to < data?.length ? page : 0)}
                  label={allgiftvendors?.length ? `${from + 1}-${to} of ${data?.length}` : `0-0`}
                  showFastPaginationControls={to > data?.length ? false : true}
                  numberOfItemsPerPageList={numberOfItemsPerPageList}
                  numberOfItemsPerPage={numberOfItemsPerPage}
                  onItemsPerPageChange={onItemsPerPageChange}
                  selectPageDropdownLabel={'Rows per page'}
                />
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}

export default GiftVendorsGet;