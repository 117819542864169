import {
  FARMER_SHIPPING_REQUEST,
  FARMER_SHIPPING_SUCCESS,
  FARMER_SHIPPING_FAIL,

  FARMER_SHIPPING_GET_REQUEST,
  FARMER_SHIPPING_GET_SUCCESS,
  FARMER_SHIPPING_GET_FAIL,

  FARMER_SHIPPING_UPDATE_REQUEST,
  FARMER_SHIPPING_UPDATE_SUCCESS,
  FARMER_SHIPPING_UPDATE_FAIL,

  FARMER_SHIPPING_FILTER,
  FARMER_DELIVERY_PERSON_REQUEST,
  FARMER_DELIVERY_PERSON_SUCCESS,
  FARMER_DELIVERY_PERSON_FAIL,
  FARMER_PARTNER_ADDRESS_REQUEST,
  FARMER_PARTNER_ADDRESS_SUCCESS,
  FARMER_PARTNER_ADDRESS_FAIL,
} from '../../actions/farmer/farmershippingAction'

const initialState = {

  shippings: { all: [], error: '', isLoading: false },
  shipping: { shipping: {}, error: '', isLoading: false },
  deliverypersons: { persons: [], error: '', isLoading: false },
  partnerAddres: { address: [], error: '', isLoading: false },
  shippingUpdate: { shipping: {}, error: '', isLoading: false },
  shippingFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

  switch (action.type) {
    case FARMER_SHIPPING_REQUEST:
      return { ...state, shippings: { all: state.shippings.all, error: '', isLoading: true } };
    case FARMER_SHIPPING_SUCCESS:
      return { ...state, shippings: { all: action.payload, error: '', isLoading: false } };
    case FARMER_SHIPPING_FAIL:
      return { ...state, shippings: { all: [], error: action.payload, isLoading: false } };

    case FARMER_SHIPPING_GET_REQUEST:
      return { ...state, shipping: { shipping: {}, error: '', isLoading: true } };
    case FARMER_SHIPPING_GET_SUCCESS:
      return { ...state, shipping: { shipping: action.payload, error: '', isLoading: false } };
    case FARMER_SHIPPING_GET_FAIL:
      return { ...state, shipping: { shipping: {}, error: action.payload, isLoading: false } };
    case FARMER_PARTNER_ADDRESS_REQUEST:
      return { ...state, partnerAddres: { address: state.partnerAddres.address, error: '', isLoading: true }, };
    case FARMER_PARTNER_ADDRESS_SUCCESS:
      return { ...state, partnerAddres: { address: action.payload, error: '', isLoading: false } };
    case FARMER_PARTNER_ADDRESS_FAIL:
      return { ...state, partnerAddres: { address: [], error: action.payload, isLoading: false } };
    case FARMER_DELIVERY_PERSON_REQUEST:
      return { ...state, deliverypersons: { persons: state.deliverypersons.persons, error: '', isLoading: true }, };
    case FARMER_DELIVERY_PERSON_SUCCESS:
      return { ...state, deliverypersons: { persons: action.payload, error: '', isLoading: false } };
    case FARMER_DELIVERY_PERSON_FAIL:
      return { ...state, deliverypersons: { persons: [], error: action.payload, isLoading: false } };

    case FARMER_SHIPPING_UPDATE_REQUEST:
      return { ...state, shippingUpdate: { shipping: {}, error: '', isLoading: true } };
    case FARMER_SHIPPING_UPDATE_SUCCESS:
      return { ...state, shippingUpdate: { shipping: state.shippingUpdate.shipping, error: '', isLoading: false } };
    case FARMER_SHIPPING_UPDATE_FAIL:
      return { ...state, shippingUpdate: { shipping: {}, error: action.payload, isLoading: false } };

    case FARMER_SHIPPING_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, shippingFilter: { search: state.shippingFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, shippingFilter: { select: state.shippingFilter.select, search: action.value } };
      }
      return res;

    default:
      return state;
  }
}