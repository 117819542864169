import { v4 as uuid } from 'uuid';
import product from "../../../assets/static/Dashboard/product.png"
import partnerproducts from "../../../assets/static/Dashboard/partnerproducts.png"

export default [
  
  {
    id: uuid(),
    link: 'FarmerProductGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'product is an object or system made available for consumer use , here we are creating and updating products with respective information about particular product.',
    media: product,
    title: 'Products',
    total: ''
  },
  {
    id: uuid(),
    link: 'FarmerPartnerProductsGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Resources that represent purchasable goods or services. Includes resources for describing the product type and shape (SKU), and for checking the availability of the product in an inventory.',
    media: partnerproducts,
    title: 'Partner Products',
    total: ''
  },
];