import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Button, Searchbar, Portal, Surface, IconButton, TextInput, HelperText, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage';
import isEmpty from '../../../../state/validations/is-empty';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../auth/components/header';
import { getAllAddressforBiddingAction } from '../../../../state/actions/biddingProductsAction';
import { getAllNewUserProductsb2bBiddingQuotesAction, resetFilter } from '../../../../state/actions/b2buserProductsNewBiddingQuoteAction';
import Icon from 'react-native-vector-icons/FontAwesome';

//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'User_Products_Bidding_Id',
        label: 'Bidding Quote ID'
    },
    {
        value: 'Bidding_Product_Id',
        label: 'Bidding Product ID'
    },
    {
        value: 'Product_Name',
        label: 'Product Name'
    },

    {
        value: 'Quantity',
        label: 'Quantity'
    },
    {
        value: 'Buyer_Partner_Detail_Id',
        label: 'Buyer Partner Detail ID'
    },
];
const numberOfItemsPerPageList = [5, 10, 20];

function B2BUserNewBiddingProductQuotes(props: any) {

    const { navigation } = props;
    const ID = props.route?.params?.ID;
    const esiColor = useSelector(state => state.theme);
    //addresses contains the data from  GET API using state call
    const AllBiddingB2bProducts = useSelector(state => state.b2bbiddinguserproductnewquotes.b2b_bidding_product_quotes.all);
    let biddingb2bproducts = AllBiddingB2bProducts?.results;
    const [biddingb2bproductsMenu, setbiddingb2bproductsMenu] = React.useState('All')
    const [biddingb2bproductsSearch, setbiddingb2bproductsSearch] = React.useState('');
    //toast for popup after successful API calls.
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const [page, setPage] = React.useState(0);
    const [webpage, setwebPage] = React.useState(1);
    const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = (Number(from) + Number(numberOfItemsPerPage) > AllBiddingB2bProducts?.Pagination?.TotalCount ? AllBiddingB2bProducts?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
    const onItemsPerPageChange = (e) => {
        numberItemsPerPageChange(e)
        setPage(0)
        setwebPage(1)
        setPagination({ PageNo: Number(page) + Number(1), PageSize: e, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "DESC" })
    }
    const DynamicPageIncrease = (e) => {
        setwebPage(e < AllBiddingB2bProducts?.Pagination?.TotalPages ? e + Number(1) : AllBiddingB2bProducts?.Pagination?.TotalPages)
        setPagination({ PageNo: e == AllBiddingB2bProducts?.Pagination?.TotalPages ? AllBiddingB2bProducts?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "DESC" });
    }
    const DynamicPagedecrese = (e) => {
        setwebPage(e > 1 ? e - Number(1) : 1)
        setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "DESC" });
    }
    const DynamicPage = (e) => {
        setPage(e)
        setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "DESC" });
    }
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "DESC" });
    React.useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            biddingb2bproductsCall();
        }
    }, [pagination])
    const [addressdata, setAddressData] = React.useState([]);
    const callBackAddressData = (data: any) => {
        setAddressData(data)
    }
    let addressdatalist = []
    if (addressdata) {
        if (addressdata[0]) {
            for (let i = 0; i < addressdata.length; i++) {
                addressdatalist.push({
                    label: addressdata[i].Location,
                    value: addressdata[i].Partner_Address_Id
                })
            }
        }
    }
    // search and set search is used for the search functionality
    const [loading, setIsLoading] = React.useState(false);
    const biddingb2bproductsCall = () => {
        setIsLoading(true);
        let formData = {
            "search_by_filter": "",
            "search": "",
            "Records_Filter": "FILTER",
            "Buyer_Partner_Detail_Id": "",
            "B2B_User_Products_Bidding_Id": "",
            "Seller_Partner_Detail_Id": "",
            "Partner_Detail_Id": EmailID.Partner_Details_Id
        };

        dispatch(getAllNewUserProductsb2bBiddingQuotesAction(formData, pagination))
    };
    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);
    const dispatch = useDispatch()
    //Static formData
    const refreshCall = () => {
        setIsLoading(true);
        let pagecall = { PageNo: 1, PageSize: 5, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "DESC" }
        let formData = {
            "search_by_filter": "",
            "search": "",
            "Records_Filter": "FILTER",
            "Buyer_Partner_Detail_Id": "",
            "B2B_User_Products_Bidding_Id": "",
            "Seller_Partner_Detail_Id": "",
            "Partner_Detail_Id": EmailID.Partner_Details_Id,
        };
        dispatch(getAllNewUserProductsb2bBiddingQuotesAction(formData, pagecall))
        setPage(0)
        numberItemsPerPageChange(5)
    };
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });

    const [paginationforaddress, setPaginationForAdress] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_ADDRESS_ID", SortOrder: "DESC" });
    React.useEffect(() => {
        let addressForm = {
            "search_by_filter": "",
            "search": "",
            "Partner_Address_Id": "",
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Records_Filter": "FILTER",
            "Buyer_Partner_Detail_Id": "",
            "B2B_User_Products_Bidding_Id": "",
            "Seller_Partner_Detail_Id": "",
        }
        let formData = {
            "search_by_filter": "",
            "search": "",
            "Records_Filter": "FILTER",
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Buyer_Partner_Detail_Id": "",
            "B2B_User_Products_Bidding_Id": "",
            "Seller_Partner_Detail_Id": "",
        };
        dispatch(getAllAddressforBiddingAction(addressForm, callBackAddressData, paginationforaddress))
        dispatch(getAllNewUserProductsb2bBiddingQuotesAction(formData, pagination))
    }, [permissions]);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        refreshCall();
        wait(2000).then(() => setRefreshing(false));
    }, []);
    //delete address
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    //sorting, pagination & search
    const [order, setOrder] = React.useState('descending');
    const [orderBy, setOrderBy] = React.useState('B2B_User_Products_Bidding_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
        setOrderBy(property);
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    // View Dialog
    const [visible, setVisible] = React.useState(false);
    const [addData, setData] = React.useState();


    const hideDialog = () => setVisible(false);

    const [visibleone, setVisibleone] = React.useState(false);
    const [addDataone, setDataone] = React.useState();


    const hideDialogone = () => setVisibleone(false);
    const handleChange = itemValue => {
        setbiddingb2bproductsMenu(itemValue);
    };
    const handleSearch = value => {
        let formData = {
            "search_by_filter": biddingb2bproductsMenu,
            "search": value,
            "Records_Filter": "FILTER",
            "Partner_Detail_Id": EmailID.Partner_Details_Id,
            "Buyer_Partner_Detail_Id": "",
            "B2B_User_Products_Bidding_Id": "",
            "Seller_Partner_Detail_Id": "",
        };
        setbiddingb2bproductsSearch(value)
        dispatch(getAllNewUserProductsb2bBiddingQuotesAction(formData, pagination))
    };

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };

    const style = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 160
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 250
        },
        rowsPerPageContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 10,
            justifyContent: 'flex-end',
            borderTopColor: esiColor.SBorderColor,
            borderWidth: 0.5,
        },
        rowsPerPagePicker: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.itemColor
        },
        rowsPerPagePickers: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            borderColor: 'gray',
            borderWidth: 0.5,
            paddingHorizontal: 8,
        },
        paginationContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            // backgroundColor: esiColor.SIconColor
        },
        paginationButton: {
            padding: 10,
            marginHorizontal: 10,
            // backgroundColor: esiColor.SIconColor
        },
        pageInfoText: {
            fontSize: 16,
            padding: 10,
            fontWeight: 'bold',
            color: esiColor.itemColor
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>

                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                        navigation.navigate('PartnerBiddingMainCenter')
                        handleSearch("")
                        dispatch(resetFilter())
                    }
                    }>
                        Go Back
                    </Button>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: "column", margin: 12 }}>
                        <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>B2B User New Bidding Products Request</Text>
                    </View>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={filterOptions}
                                containerStyle={style.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={biddingb2bproductsMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                            <Picker
                                selectedValue={biddingb2bproductsMenu}
                                style={{ height: 51, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Bidding Quote ID" value="B2B_User_Products_Bidding_Id" />
                                <Picker.Item label="Bidding Product Id" value="Bidding_Product_Id" />
                                <Picker.Item label="Product Name" value="Product_Name" />
                                <Picker.Item label="Quantity" value="Quantity" />
                                <Picker.Item label="Buyer Partner Detail ID" value="Buyer_Partner_Detail_Id" />

                            </Picker>
                        </View>}

                    <View style={{ flex: dimensions <= 100 ? 0.1 : 0.6 }} />

                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{
                                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.BackgroundColor
                            }}
                            iconColor={esiColor.SIconColor}
                            theme={esiColor.pagination_theme}
                            placeholderTextColor={esiColor.SPHTextColor}
                            placeholder="Search"
                            onChangeText={(value) => handleSearch(value)}
                            value={biddingb2bproductsSearch}
                        />
                    </View>
                    <View style={{ flex: dimensions <= 700 ? null : 0.53 }} />
                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    <ScrollView horizontal={dimensions >= 700 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title style={style.title} active={orderBy === 'B2B_User_Products_Bidding_Id'}
                                            sortDirection={orderBy === 'B2B_User_Products_Bidding_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('B2B_User_Products_Bidding_Id')}><Text style={style.titletext}>Bidding Quote ID</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Bidding_Product_Id'}
                                            sortDirection={orderBy === 'Bidding_Product_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Bidding_Product_Id')}><Text style={style.titletext}>Bidding Product Id</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Product_Name'}
                                            sortDirection={orderBy === 'Product_Name' ? order : 'ascending'}
                                            onPress={createSortHandler('Product_Name')}><Text style={style.titletext}>Product Name</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Quantity'}
                                            sortDirection={orderBy === 'Quantity' ? order : 'ascending'}
                                            onPress={createSortHandler('Quantity')}><Text style={style.titletext}>Quantity</Text></DataTable.Title>


                                        <DataTable.Title style={style.title} active={orderBy === 'Buyer_Partner_Detail_Id'}
                                            sortDirection={orderBy === 'Buyer_Partner_Detail_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Buyer_Partner_Detail_Id')}><Text style={style.titletext}>Buyer Partner Detail ID</Text></DataTable.Title>

                                        <DataTable.Title theme={customTheme} style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        biddingb2bproducts?.map((biddingb2bproduct, index) => (
                                            <DataTable.Row style={style.databeBox} key={index}>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingb2bproduct.B2B_User_Products_Bidding_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingb2bproduct.Bidding_Product_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingb2bproduct.Product_Name}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingb2bproduct.Quantity}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingb2bproduct.Buyer_Partner_Detail_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { navigation.navigate('userb2bNewbiddingRequestDetails', { id: biddingb2bproduct.B2B_User_Products_Bidding_Id }), handleSearch("") }} />
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                        ))
                                    }
                                    {isEmpty(biddingb2bproducts) &&
                                        <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                                No records found!
                                            </Text>
                                            <Image source={require('../../../../assets/images/nodata.gif')}
                                                style={{ width: 300, height: 300, alignSelf: "center" }} />
                                        </View>
                                    }
                                    {Platform.OS !== "web" ?

                                        <DataTable.Pagination
                                            // theme={customTheme}
                                            page={page}
                                            numberOfPages={Math.ceil(AllBiddingB2bProducts?.Pagination?.TotalCount / numberOfItemsPerPage)}
                                            onPageChange={page => DynamicPage(to < AllBiddingB2bProducts?.Pagination?.TotalCount ? page : 0)}
                                            label={AllBiddingB2bProducts?.Pagination?.TotalCount ? `${from + 1}-${to} of ${AllBiddingB2bProducts?.Pagination?.TotalCount}` : '0-0'}
                                            showFastPaginationControls={(Number(page) + Number(1)) > AllBiddingB2bProducts?.Pagination?.TotalPages ? false : true}
                                            numberOfItemsPerPageList={numberOfItemsPerPageList}
                                            numberOfItemsPerPage={numberOfItemsPerPage}
                                            onItemsPerPageChange={onItemsPerPageChange}
                                            selectPageDropdownLabel={'Rows per page'}
                                        />
                                        :
                                        <View style={style.rowsPerPageContainer}>
                                            <Text style={{color: esiColor.Text}}>rows per page: </Text>
                                            <Picker
                                                style={style.rowsPerPagePicker}
                                                selectedValue={numberOfItemsPerPage}
                                                onValueChange={(e) => onItemsPerPageChange(e)}
                                            >
                                                <Picker.Item label="5" value={5} />
                                                <Picker.Item label="10" value={10} />
                                                <Picker.Item label="20" value={20} />
                                            </Picker>
                                            <View style={style.paginationContainer}>
                                                <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-left" size={20} color={esiColor.SIconColor}/>
                                                </TouchableOpacity>
                                                <Text style={style.pageInfoText}>
                                                    {AllBiddingB2bProducts?.Pagination?.PageNo + ' of ' + AllBiddingB2bProducts?.Pagination?.TotalPages}
                                                </Text>
                                                <TouchableOpacity onPress={e => DynamicPageIncrease(to < AllBiddingB2bProducts?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                                                </TouchableOpacity>
                                            </View>
                                        </View>}
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>

                </View>
            </ScrollView>
        </Surface>

    );
}

export default B2BUserNewBiddingProductQuotes