import React, { useState } from "react";
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions ,RefreshControl} from 'react-native';
import { Dialog, Card, Portal, IconButton, DataTable, Button, Searchbar, Surface } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../state/validations/is-empty'
import 'react-native-gesture-handler'
import Header from "../../auth/components/header";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import { getAllshopperPartnerAction, shopperpartnerFilter } from "../../../state/actions/shoppers/shopersAction";
import AsyncStorage from "@react-native-async-storage/async-storage";
const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Discounted_Products_Id',
    label: 'Discounted Products ID'
  },
  {
    value: 'Product_Id',
    label: 'Product ID'
  },
  {
    value: 'Partner_Details_Id',
    label: 'Partner Details ID'
  },
  {
    value: 'Created_By',
    label: 'Created By'
  },
];
const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const ShoppersGet = (props: any) => {
  const { navigation } = props;
  const dispatch = useDispatch()
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const allshopperpartnersGet = useSelector(state => state.shoppers.shopperpartners.all);
  let shopperpartnersMenu = useSelector(state => state.shoppers.shopperpartnerFilter.select);
  let shopperpartnersSearch = useSelector(state => state.shoppers.shopperpartnerFilter.search);

  const EmailID = useSelector(state => state.auth?.user);
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, allshopperpartnersGet?.length);
  const shopperspartnersid = props.route?.params?.shoppersproductparams;
  const [productId, setProductID] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [shopperproduct, setData] = React.useState();
  const hideDialog = () => setVisible(false);
  const handleClickadd = () => {
    props.navigation.navigate('AddShoppersProducts', { shoppersproducts: shopperspartnersid })
  };

  const handleClickedit = async (allshopperpartnersGet) => {
    props.navigation.navigate('EditShoppersProduct', { discountedproductid: allshopperpartnersGet.Discounted_Products_Id, partnerid: allshopperpartnersGet.Partner_Details_Id })
  };
  React.useEffect(() => {
    if(shopperspartnersid!==productId){
    const formData = {
      Records_Filter: 'FILTER',
      Partner_Details_Id: EmailID.Partner_Details_Id,
      Product_Id: shopperspartnersid
    };
    setProductID(shopperspartnersid)
    dispatch(getAllshopperPartnerAction(formData))
    dispatch(shopperpartnerFilter('search', ''));
    dispatch(shopperpartnerFilter('select', 'All'));
  }
    if (!isEmpty(allshopperpartnersGet)) {
      setProducts(allshopperpartnersGet);
    } else {
      setProducts([]);
    }
  }, [productId,shopperspartnersid]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
   //Search
  const [oldSearch, setOldSearch] = React.useState(0);
  const checkshopperproductsForTable = () => {
    if (shopperpartnersSearch) {
      try {
        if (shopperpartnersMenu === "All") {
          return allshopperpartnersGet.filter(x => {
            let colummns = ["Product_Id", 'Discounted_Products_Id', "Partner_Details_Id", "Created_By"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
              const subString = typeof x[colummns[i]] === "string" ? shopperpartnersSearch.toLowerCase() : shopperpartnersSearch;
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(shopperpartnersSearch.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString == shopperpartnersSearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return allshopperpartnersGet.filter(x => {
            let mainString = typeof x[shopperpartnersMenu] == "string" ? x[shopperpartnersMenu].toLowerCase() : x[shopperpartnersMenu];
            let subString = typeof x[shopperpartnersMenu] == "string" ? shopperpartnersSearch.toLowerCase() : shopperpartnersSearch;
            if (typeof x[shopperpartnersMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString == shopperpartnersSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (page > 0) {
          if (oldSearch != shopperpartnersSearch.length) {
            setOldSearch(shopperpartnersSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch != shopperpartnersSearch.length) {
          setOldSearch(shopperpartnersSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return allshopperpartnersGet
    }
  }
  //sorting
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Discounted_Products_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  function descendingComparator(a, b, orderBy) {
    try {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } catch (error) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

  }
  function getComparator(order, orderBy) {
    return order === 'descending'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {

      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleChange = itemValue => {
    dispatch(shopperpartnerFilter('select', itemValue));
  };
  const handleSearch = value => {
    dispatch(shopperpartnerFilter('search', value));
  };
  const data = stableSort(
    checkshopperproductsForTable(), getComparator(order, orderBy))
  return (
    <Surface style={{
      flex: 1
    }}>
      <Header navigation={navigation} />
        <Portal>
          <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto' }}>
            <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
              <IconButton
                icon="close"
                size={20}
                onPress={() => setVisible(!visible)}
              />
            </View>
            <Dialog.Title style={{ fontSize: 20, marginTop: -35, fontWeight: 'bold', textAlign: "center" }}>
              <Text style={{ color: '#27B6CC' }}>Shoppers Product View</Text>
            </Dialog.Title>
            <Dialog.ScrollArea>
              <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
                <Dialog.Content style={{ marginLeft: -30 }}>
                  <View style={{ flexDirection: "row", paddingTop: 15 }}>
                    <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Discounted Products ID</Text></View><View style={{ flex: 2 }}><Text>{shopperproduct?.Discounted_Products_Id} </Text></View>
                  </View>
                  <View style={{
                    flexDirection: "row", paddingTop: 15
                  }}>
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontWeight: "bold", fontSize: 13 }}>Partner Details ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{shopperproduct?.Partner_Details_Id} </Text></View>
                  </View>
                  <View style={{
                    flexDirection: "row", paddingTop: 15
                  }}>
                    <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Status</Text></View><View style={{ flex: 2 }}><Text style={{ color: shopperproduct?.Status === "Active" ? '#27B6CC' : "red" }}>{shopperproduct?.Status} </Text></View>
                  </View>
                  <View style={{
                    flexDirection: "row", paddingTop: 15
                  }}>
                    <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Max Quantity</Text></View><View style={{ flex: 2 }}><Text>{shopperproduct?.Max_Quantity} </Text></View>
                  </View>
                  <View style={{
                    flexDirection: "row", paddingTop: 15
                  }}>
                    <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Min Quantity</Text></View><View style={{ flex: 2 }}><Text>{shopperproduct?.Min_Quantity} </Text></View>
                  </View>
                  <View style={{
                    flexDirection: "row", paddingTop: 15
                  }}>
                    <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Product ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{shopperproduct?.Product_Id} </Text></View>
                  </View>
                  <View style={{
                    flexDirection: "row", paddingTop: 15
                  }}>
                    <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Discount Start Date</Text></View><View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{shopperproduct?.Discount_Start_Date} </Text></View>
                  </View>
                  <View style={{
                    flexDirection: "row", paddingTop: 15
                  }}>
                    <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Discount End Date</Text></View><View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{shopperproduct?.Discount_End_Date} </Text></View>
                  </View>
                  <View style={{
                    flexDirection: "row", paddingTop: 15
                  }}>
                    <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Discount In Percentage</Text></View><View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{shopperproduct?.Discount_In_Percentage} </Text></View>
                  </View>
                  <View style={{
                    flexDirection: "row", paddingTop: 15
                  }}>
                    <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Created By</Text></View><View style={{ flex: 2 }}><Text style={{ color: '#27B6CC' }}>{shopperproduct?.Created_By} </Text></View>
                  </View>
                </Dialog.Content>
              </ScrollView>
            </Dialog.ScrollArea>
          </Dialog>
        </Portal>
        <View >
          <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('Products')}>
            Go Back
          </Button>
        </View>
        <ScrollView refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  colors={['#27B6CC']}
                  tintColor={'#27B6CC'}
                  title={"Pull to refresh"}
                  titleColor={"#27B6CC"}
                />
              } >
          <View style={{ flexDirection: "column" }}>
            <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10 }}>Shoppers Products</Text>
            <View style={{ flexDirection: "row", paddingTop: 5 }}>
              <View style={{ flex: 1.7 }} />
              {(permissions.Products_Partner_Products_Related_Products === "write") &&
                (dimensions <= 700 ?
                  <View style={{ flexShrink: 0.1, paddingTop: 8 }}>
                    <Button style={{ backgroundColor: '#27B6CC' }} mode="contained" onPress={(e) => { handleClickadd(e); }}>
                      Add Shopper Products
                    </Button>
                  </View>
                  : null)}
              <View style={{ flex: 0.1 }} />
            </View>
          </View>
          <View style={{ flexDirection: "row", paddingTop: 20, marginLeft: 5 }}>
            <View style={{ flex: dimensions <= 700 ? 0.08 : 0.04 }} />
            <View style={{ flex: dimensions <= 700 ? 1.5 : 0.5 }}>
              {Platform.OS !== 'web' ?
                <EsiSearchDropdown
                  style={[styles.dropdown]}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  inputSearchStyle={styles.inputSearchStyle}
                  iconStyle={styles.iconStyle}
                  data={filterOptions}
                  containerStyle={styles.containerstyle}
                  maxHeight={180}
                  labelField="label"
                  valueField="value"
                  placeholder={!showDropDown ? 'All' : ''}
                  value={shopperpartnersMenu}
                  onFocus={() => setShowDropDown(true)}
                  onBlur={() => setShowDropDown(false)}
                  onUpdateValue={item => {
                    setShowDropDown(false);
                    handleChange(item.value)
                  }}
                /> :
                <Picker
                  selectedValue={shopperpartnersMenu}
                  style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', backgroundColor: '#ffff' }}
                  onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                >
                  <Picker.Item label="All" value="All" />
                  <Picker.Item label="Discounted Products ID" value="Discounted_Products_Id" />
                  <Picker.Item label="Product ID" value="Product_Id" />
                  <Picker.Item label="Partner Details ID" value="Partner_Details_Id" />
                  <Picker.Item label="Created By" value="Created_By" />
                </Picker>}
            </View>
            <View style={{ flex: dimensions <= 700 ? 0.1 : 0.6 }} />
            <View style={{ flex: 1.8 }}>
              <Searchbar
                style={{ borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7' }}
                placeholder="Search"
                variant="outlined"
                onChangeText={(value) => handleSearch(value)}
                value={shopperpartnersSearch}
              />
            </View>
            <View style={{ flex: dimensions <= 700 ? 0.1 : 1.6 }} />
            {(permissions.Products_Partner_Products_Related_Products === "write") &&
              (dimensions >= 700 ?
                <View style={{ flexShrink: 0.1, paddingTop: 8 }}>
                  <Button style={{ backgroundColor: '#27B6CC' }} mode="contained" onPress={(e) => { handleClickadd(e); }}>
                    Add Shoppers Products
                  </Button>
                </View>
                : null)}

            <View style={{ flex: dimensions <= 700 ? null : 0.07 }} />
          </View>
          <ScrollView horizontal={dimensions >= 700 ? false : true}>
            <View style={styles.mainbox}>
              <Card>
                <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                  <DataTable.Header style={styles.databeHeader}>
                    <DataTable.Title active={orderBy === 'Discounted_Products_Id'} sortDirection={orderBy === 'Discounted_Products_Id' ? order : 'ascending'} onPress={createSortHandler('Discounted_Products_Id')} style={styles.title}><Text style={styles.titletext}>Discounted Products ID</Text></DataTable.Title>
                    <DataTable.Title active={orderBy === 'Product_Id'} sortDirection={orderBy === 'Product_Id' ? order : 'ascending'} onPress={createSortHandler('Product_Id')} style={styles.title}><Text style={styles.titletext}>Product ID</Text></DataTable.Title>
                    <DataTable.Title active={orderBy === 'Partner_Details_Id'} sortDirection={orderBy === 'Partner_Details_Id' ? order : 'ascending'} onPress={createSortHandler('Partner_Details_Id')} style={styles.title}><Text style={styles.titletext}>Partner Details ID</Text></DataTable.Title>
                    <DataTable.Title active={orderBy === 'Created_By'} sortDirection={orderBy === 'Created_By' ? order : 'ascending'} onPress={createSortHandler('Created_By')} style={styles.title}><Text style={styles.titletext}>Created By</Text></DataTable.Title>
                    <DataTable.Title style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                  </DataTable.Header>
                  {data.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((ShoppersproductData, i) => (
                    <DataTable.Row style={styles.databeBox} key={i}>
                      <DataTable.Cell style={styles.tablerow}>{ShoppersproductData.Discounted_Products_Id} </DataTable.Cell>
                      <DataTable.Cell style={styles.tablerow}>{ShoppersproductData.Product_Id} </DataTable.Cell>
                      <DataTable.Cell style={styles.tablerow}>{ShoppersproductData.Partner_Details_Id}</DataTable.Cell>
                      <DataTable.Cell style={styles.tablerow}>{ShoppersproductData.Created_By}</DataTable.Cell>
                      <DataTable.Cell style={styles.tablerow}>
                        {(permissions.Products_Partner_Products_Related_Products === "write") &&
                          <IconButton icon="square-edit-outline" size={20} color='#27B6CC' onPress={(e) => { handleClickedit(ShoppersproductData); }} />
                        }
                        <IconButton icon="eye" size={20} color='#27B6CC' onPress={(e) => { setData(ShoppersproductData); setVisible(!visible) }} /></DataTable.Cell>
                    </DataTable.Row>
                  ))
                  }
                  {isEmpty(data) &&
                    <Text style={{ fontSize: 16, textAlign: 'center' }}>
                      No records found.
                    </Text>
                  }
                  <DataTable.Pagination
                    page={page}
                    numberOfPages={Math.ceil(allshopperpartnersGet?.length / numberOfItemsPerPage)}
                    onPageChange={page => setPage(page)}
                    label={allshopperpartnersGet?.length ? `${from + 1}-${to} of ${allshopperpartnersGet?.length}` : `0-0`}
                    showFastPaginationControls
                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    onItemsPerPageChange={onItemsPerPageChange}
                    selectPageDropdownLabel={'Rows per page'}
                  />
                </DataTable>
              </Card>
            </View>
          </ScrollView>
        </ScrollView>
    </Surface>
  );
}
const styles = StyleSheet.create({
  title: {
    fontWeight: "bold",
    fontSize: 12,
    justifyContent: "center",
    textAlign: "center",
    width: 220
  },
  titletext: {
    fontWeight: "bold",
    fontSize: 14,
    color: 'black',
  },
  tablerow: {
    justifyContent: "center",
    textAlign: "center",
    width: 140
  },
  mainbox: {
    textAlign: 'center',
    margin: 15,
    flex: 1,
    justifyContent: 'space-between',
  },
  databeBox: {
    margin: 10,
    textAlign: 'center',
  },
  databeHeader: {
    margin: 10,
    textAlign: 'left',
  },
  mainbox: {
    textAlign: 'center',
    margin: 15,
    flex: 1,
    justifyContent: 'space-between',
  },
  dropdown: {
    height: 50,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  containerstyle: {
    width: 240
  },
});
export default ShoppersGet;