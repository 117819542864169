import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const ORDERS_REQUEST = 'ORDERS_REQUEST';
export const ORDERS_SUCCESS = "ORDERS_SUCCESS";
export const ORDERS_FAIL = "ORDERS_FAIL";
export const ORDER_GET_REQUEST = "ORDER_GET_REQUEST";
export const ORDER_GET_SUCCESS = "ORDER_GET_SUCCESS";
export const ORDER_GET_FAIL = "ORDER_GET_FAIL";
export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_STATUS_UPDATE_REQUEST = "ORDER_STATUS_UPDATE_REQUEST";
export const ORDER_STATUS_UPDATE_SUCCESS = "ORDER_STATUS_UPDATE_SUCCESS";
export const ORDER_STATUS_UPDATE_FAIL = "ORDER_STATUS_UPDATE_FAIL";
export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";
export const ORDERS_FILTER = "ORDERS_FILTER";

const nodeheaders = shopperconfig.nodeheadersCommon;
/*=============================================================
                  Add order Action
===============================================================*/
const headers = config.headersCommon

export const getSingleB2COrder = (formData:any,callBackOrderData:any )=> async dispatch => {
    try {
      let { data, status } = await axios.post(
        `${shopperconfig.nodePartnerUrl}/Order_Management/Order_GetFlow`,
        formData,
          {
            headers: nodeheaders
        }
      );
      if (data) {
        callBackOrderData(data.results[0])
        }
    } catch (err) { 
      
    }
  };
  export const getSingleB2BOrder = (formData:any,callBackOrderData:any )=> async dispatch => {
    try {
      let { data, status } = await axios.post(
        `${shopperconfig.nodePartnerUrl}/B2C_order_Get/Order_Get`,
        formData,
          {
            headers: nodeheaders
        }
      );
      if (data) {
        callBackOrderData(data.results[0].Order_Data)
        }
    } catch (err) { 
      
    }
  };
// Filter 
export const ordersFilter = (fType, value) => {
    return function (dispatch) {
        dispatch({
            type: ORDERS_FILTER,
            fType: fType,
            value: value, 
        });
    }
}
export const getOrderTrackingDetails = (formData:any, updateStates:any) => async (dispatch) => {
  axios.post(`${shopperconfig.nodePartnerUrl}/B2B_Del_Live_Location/Get`, formData, {

    headers: nodeheaders
  })
      .then(response => {
          let successResponse = response.data;
         if (successResponse?.Success_Response?.Response_Status == "Success") {
              updateStates(true, successResponse?.results);
           } else {
              updateStates(false, {});
           }
      })
      .catch(error => {
          updateStates(false, {});
      })
};