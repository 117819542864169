import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';
import shopperconfig from './shoppers/shopperconfig';

export const SBCPARTNERCITYPRODUCT_REQUEST = "SBCPARTNERCITYPRODUCT_REQUEST";
export const SBCPARTNERCITYPRODUCT_SUCCESS = "SBCPARTNERCITYPRODUCT_SUCCESS";
export const SBCPARTNERCITYPRODUCT_FAIL = "SBCPARTNERCITYPRODUCT_FAIL";

export const SBCPARTNERCITYPRODUCT_GET_REQUEST = "SBCPARTNERCITYPRODUCT_GET_REQUEST";
export const SBCPARTNERCITYPRODUCT_GET_SUCCESS = "SBCPARTNERCITYPRODUCT_GET_SUCCESS";
export const SBCPARTNERCITYPRODUCT_GET_FAIL = "SBCPARTNERCITYPRODUCT_GET_FAIL";

export const SBCPARTNERCITYPRODUCT_CREATE_REQUEST = "SBCPARTNERCITYPRODUCT_CREATE_REQUEST";
export const SBCPARTNERCITYPRODUCT_CREATE_SUCCESS = "SBCPARTNERCITYPRODUCT_CREATE_SUCCESS";
export const SBCPARTNERCITYPRODUCT_CREATE_FAIL = "SBCPARTNERCITYPRODUCT_CREATE_FAIL";

export const SBCPARTNERCITYPRODUCT_UPDATE_REQUEST = "SBCPARTNERCITYPRODUCT_UPDATE_REQUEST";
export const SBCPARTNERCITYPRODUCT_UPDATE_SUCCESS = "SBCPARTNERCITYPRODUCT_UPDATE_SUCCESS";
export const SBCPARTNERCITYPRODUCT_UPDATE_FAIL = "SBCPARTNERCITYPRODUCT_UPDATE_FAIL";

export const SBCPARTNERCITYPRODUCT_DELETE_REQUEST = "SBCPARTNERCITYPRODUCT_DELETE_REQUEST";
export const SBCPARTNERCITYPRODUCT_DELETE_SUCCESS = "SBCPARTNERCITYPRODUCT_DELETE_SUCCESS";
export const SBCPARTNERCITYPRODUCT_DELETE_FAIL = "SBCPARTNERCITYPRODUCT_DELETE_FAIL";

export const SBCPARTNERCITY_PRODUCTS_REQUEST = "SBCPARTNERCITY_PRODUCTS_REQUEST";
export const SBCPARTNERCITY_PRODUCTS_SUCCESS = "SBCPARTNERCITY_PRODUCTS_SUCCESS";
export const SBCPARTNERCITY_PRODUCTS_FAIL = "SBCPARTNERCITY_PRODUCTS_FAIL";

export const SBCPARTNERCITYPRODUCT_FILTER = "SBCPARTNERCITYPRODUCT_FILTER";

/*=============================================================
                  Get All SBCPARTNERCITYPRODUCT Action
===============================================================*/
const cudheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
  }
export const getAllSBCPartnerCityProductAction = (formData: any,pagination = {}) => async dispatch => {
    try {
        dispatch({
            type: SBCPARTNERCITYPRODUCT_REQUEST
        });
        meeapi.post(urlGenarator(`Partner_Service_City/PSC_products_Get`,pagination),
        formData, 
        ).then(response => {
            let data = response.data;
            if (data.Success_Response?.Response_Status === 'Success') {
                dispatch({
                    type: SBCPARTNERCITYPRODUCT_SUCCESS,
                    payload: data
                });
            } else {
                dispatch({
                    type: SBCPARTNERCITYPRODUCT_SUCCESS,
                    payload: []
                });
            }
        })
    } catch (err) {
        dispatch({
            type: SBCPARTNERCITYPRODUCT_FAIL,
            payload: err
        });
    }
};
export const getSBCPartnerCityProductAction = (formData: any,pagination = {},callback:any) => async dispatch => {
    try {
        meeapi.post(urlGenarator(`Partner_Service_City/PSC_products_Get`,pagination),
        formData, 
        ).then(response => {
            let data = response.data;
            if (data.Success_Response?.Response_Status === 'Success') {
                callback(data?.results[0])
            } else {
                callback([])
            }
        })
    } catch (err) {
    }
};

/*=============================================================
                  SBCPARTNERCITYPRODUCT Create Action
===============================================================*/

export const createSBCPartnerCityProductAction = (formData: any, navigation: any, toast: any, partnercityid:any,partnerid: any,partnercityname:any,partnername:any,cityid:any) => async dispatch => {
    dispatch({
        type: SBCPARTNERCITYPRODUCT_CREATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Partner_Service_City_PSC/PSC_Products_Create`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SBCPARTNERCITYPRODUCT_CREATE_SUCCESS
                });
                navigation.navigate('SBCPartnercityproductGet',{partnercityid:partnercityid,partnerid:partnerid,partnercityname:partnercityname,partnername:partnername,cityid:cityid })
                toast.show({ message: 'Partner city product created successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    search:'',
                    Records_Filter: "FILTER",
                    Partner_Details_Id: formData.Partner_Details_Id,
                    Partner_Service_City_Id: formData.Partner_Service_City_Id,
                };
                const pagination = {
                    PageNo: 1, 
                    PageSize: 5, 
                    SortBy: "PARTNER_SERVICE_CITY_PRODUCTS_ID", 
                    SortOrder: "DESC" 
                };
               
                dispatch(getAllSBCPartnerCityProductAction(formValue,pagination))
            } else {
                const errors = {};
                dispatch({
                    type: SBCPARTNERCITYPRODUCT_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SBCPARTNERCITYPRODUCT_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

/*=============================================================
                  SBCPARTNERCITYPRODUCT Update Action
===============================================================*/

export const updateSBCPartnerCityProductAction = (formData: any, navigation: any, toast: any,partnercityid:any,partnerid: any,partnercityname:any,partnername:any,cityid:any ) => async dispatch => {
    dispatch({
        type: SBCPARTNERCITYPRODUCT_UPDATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Partner_Service_City_PSC/PSC_Products_Update`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: SBCPARTNERCITYPRODUCT_UPDATE_SUCCESS
                });
                 toast.show({ message: 'Partner city product updated successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    search:'',
                    Records_Filter: "FILTER",
                    Partner_Details_Id: formData.Partner_Details_Id,
                    Partner_Service_City_Id: formData.Partner_Service_City_Id,
                };
                const pagination = {
                    PageNo: 1, 
                    PageSize: 5, 
                    SortBy: "PARTNER_SERVICE_CITY_PRODUCTS_ID", 
                    SortOrder: "DESC" 
                };
                 dispatch(getAllSBCPartnerCityProductAction(formValue,pagination))
                navigation.navigate('SBCPartnercityproductGet',{partnercityid:partnercityid,partnerid:partnerid,partnercityname:partnercityname,partnername:partnername,cityid:cityid })
            } else {
                const errors = {};
                dispatch({
                    type: SBCPARTNERCITYPRODUCT_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: SBCPARTNERCITYPRODUCT_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

/*=============================================================
                  SBCPARTNERCITYPRODUCT Delete
===============================================================*/

export const deleteSBCPartnerCityProductAction = (formData: any, toast: any) => async dispatch => {
    try {
        let { data, status } = await axios.post(`${config.nodecudurl}/Partner_Service_City_PSC/PSC_Products_Delete`, formData, {
            headers: cudheaders
        });
        if (data) {
            toast.show({ message: 'Partner city product deleted successfully.', type: 'info', duration: 3000, position: 'top' });
            const formValue = {
                Records_Filter: "FILTER",
                Partner_Details_Id: formData.Partner_Details_Id,
                Partner_Service_City_Id: formData.Partner_Service_City_Id,
            };
            const pagination = {
                PageNo: 1, 
                PageSize: 5, 
                SortBy: "PARTNER_SERVICE_CITY_PRODUCTS_ID", 
                SortOrder: "DESC" 
            };
            dispatch(getAllSBCPartnerCityProductAction(formValue,pagination))
        }
    } catch (err) {
    }
};

/*=============================================================
                  Get All products Action
===============================================================*/
export const getAllProducts = (formDataproduct: any) => async dispatch => {
    try {
        dispatch({
            type: SBCPARTNERCITY_PRODUCTS_REQUEST
        });
        let { data } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Product_Management/Get_By_Partner`,
            formDataproduct,
            {
                headers: nodeheaders
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SBCPARTNERCITY_PRODUCTS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SBCPARTNERCITY_PRODUCTS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SBCPARTNERCITY_PRODUCTS_FAIL,
            payload: err
        });
    }
};


// Filter 
export const SBCPartnerCityproductFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: SBCPARTNERCITYPRODUCT_FILTER,
            fType: fType,
            value: value,
        });
    }
}

//Search

export const resetFilter = () => {
    return function (dispatch: any) {
      dispatch({
        type: SBCPARTNERCITYPRODUCT_FILTER,
       fType: "search", 
        value: "",
      });
    }
  }