import axios from 'axios';
import config from '../config';
import shopperconfig from '../shoppers/shopperconfig';

export const DASHBOARD_TOTAL_ORDERS = "DASHBOARD_TOTAL_ORDERS";
export const DASHBOARD_TOTAL_PROFIT = "DASHBOARD_TOTAL_PROFIT";
export const DASHBOARD_TOTAL_USERS = "DASHBOARD_TOTAL_USERS";
export const DASHBOARD_TOTAL_COUPONS = "DASHBOARD_TOTAL_COUPONS";
export const DASHBOARD_TOTAL_DEALS = "DASHBOARD_TOTAL_DEALS";
export const DASHBOARD_TOTAL_PRODUCTS = "DASHBOARD_TOTAL_PRODUCTS";
export const DASHBOARD_TOTAL_BRANDS = "DASHBOARD_TOTAL_BRANDS";

const headers = config.headersCommon;

const nodeheaders = shopperconfig.nodeheadersCommon;
export const dashboardorderCount = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Records_Filter": "ALL",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Date": ''
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Order_Report_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_ORDERS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_ORDERS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_ORDERS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const dashboardprofitCount = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Records_Filter": "ALL",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Date": ''
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Profit_On_Sale_Report_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_PROFIT,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_PROFIT,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_PROFIT,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const dashboardusersCount = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Records_Filter": "ALL",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Date": ''
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Partners_Report_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_USERS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_USERS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_USERS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const dashboardcouponsCount = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Records_Filter": "ALL",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Date": ''
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Coupons_Report_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_COUPONS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_COUPONS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_COUPONS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const dashboarddealsCount = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Records_Filter": "ALL",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Date": ''
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Deals_Report_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_DEALS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_DEALS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_DEALS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const dashboardproductsCount = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Records_Filter": "ALL",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Date": ''
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Products_Report_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_PRODUCTS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_PRODUCTS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_PRODUCTS,
                    data: String(error),
                    success: false
                });
            })
    }
}
export const dashboardbrandsCount = () => {
    return function (dispatch: any) {
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Brand_Get`, {},
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DASHBOARD_TOTAL_BRANDS,
                        data: data.results[0],
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DASHBOARD_TOTAL_BRANDS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DASHBOARD_TOTAL_BRANDS,
                    data: String(error),
                    success: false
                });
            })
    }
}