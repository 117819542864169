
import axios from "axios";
import config from "./../config";
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
//Get Reviews and Ratings
export const getAllDelReviewRatingsAction = (formData: any, setAlldelireviewsandratings: any) => async (dispatch: any) => {
    try {
        let { data } = await axios.post(
            `${config.url}/B2B_Delivery_Reviews_And_Ratings/Get?PageNo=0&PageSize=1000&SortBy=QUESTION&SortOrder=ASC`,
            formData,
            {
                headers: headers,
            }
        );
        if (data) {
            if (data.Is_Data_Exist === "0") {
                setAlldelireviewsandratings({status:false});
            } else {
                setAlldelireviewsandratings({status:true,data:data?.results||[]});
            }
        }
    }
    catch (err) {

    }
};

//Create Reviews and Ratings
export const createDelReviewRatingsAction = (formData: any, toast: any) => async (dispatch: any) => {
    axios.post(
        `${config.nodecudurl}/B2B_Delivery_Reviews_And_Ratings/Create`,
        formData,
        {
            headers: cudheaders,
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                toast.show({ message: 'Review added successfully.', type: 'info', duration: 3000, position: 'top' });
            } else {
                toast.show({ message: 'Failed to add Review, please try again.', type: 'info', duration: 3000, position: 'top' });
            }
        })
        .catch(error => {
            toast.show({ message: "Please try sometime later.Currently server is not working", type: 'info', duration: 3000, position: 'top' });
        })
};