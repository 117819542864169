import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';

export const TRANSACTION_REQUEST = "TRANSACTION_REQUEST";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAIL = "TRANSACTION_FAIL";
export const TRANSACTION_FILTER = "TRANSACTION_FILTER ";

const headers = config.headersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
      queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
} 

/*=============================================================
                   Get All Transactions Action
===============================================================*/

export const getAllTransactionAction = (formData: any,pagination = {}) => async dispatch => {

  try {
    dispatch({
      type: TRANSACTION_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Financial_Transactions/Get`,pagination),
    formData,
    ) .then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: TRANSACTION_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: TRANSACTION_SUCCESS,
          payload: data
        });
      
    }
  })
  } catch (err) {
    dispatch({
      type: TRANSACTION_FAIL,
      payload: err
    });
  }
};


// Filter 
export const transactionFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: TRANSACTION_FILTER,
      fType: fType,
      value: value,
    });
  }
}
export const resetFilter= (fType, value) => {
    return function (dispatch) {
      dispatch({
        type: TRANSACTION_FILTER,
        fType: "search", 
        value: "",
      });
    }
  }