import {
    GIFTVENDOR_REQUEST,
    GIFTVENDOR_SUCCESS,
    GIFTVENDOR_FAIL,
    GIFTVENDOR_GET_REQUEST,
    GIFTVENDOR_GET_SUCCESS,
    GIFTVENDOR_GET_FAIL,
    GIFTVENDOR_CREATE_REQUEST,
    GIFTVENDOR_CREATE_SUCCESS,
    GIFTVENDOR_CREATE_FAIL,
    GIFTVENDOR_UPDATE_REQUEST,
    GIFTVENDOR_UPDATE_SUCCESS,
    GIFTVENDOR_UPDATE_FAIL,
    GIFTVENDOR_DELETE_REQUEST,
    GIFTVENDOR_DELETE_SUCCESS,
    GIFTVENDOR_DELETE_FAIL,
    GIFTVENDORS_FILTER,

    GIFT_PARTNERID_REQUEST,
    GIFT_PARTNERID_SUCCESS,
    GIFT_PARTNERID_FAIL,

    GIFT_USERMAILID_REQUEST,
    GIFT_USERMAILID_SUCCESS,
    GIFT_USERMAILID_FAIL,
} from '../actions/giftvendorAction'

const initialState = {
    giftvendors: { all: [], error: '', isLoading: false },
    giftvendorUpdate: { giftvendor: {}, error: '', isLoading: false },
    giftvendorsFilter: { select: 'ALL', search: '' },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GIFTVENDOR_REQUEST:
            return { ...state, giftvendors: { all: state.giftvendors.all, error: '', isLoading: true } };
        case GIFTVENDOR_SUCCESS:
            return { ...state, giftvendors: { all: action.payload, error: '', isLoading: false } };
        case GIFTVENDOR_FAIL:
            return { ...state, giftvendors: { all: [], error: action.payload, isLoading: false } };
        case GIFTVENDOR_UPDATE_REQUEST:
            return { ...state, giftvendorUpdate: { giftvendor: {}, error: '', isLoading: true } };
        case GIFTVENDOR_UPDATE_SUCCESS:
            return { ...state, giftvendorUpdate: { giftvendor: action.payload, error: '', isLoading: false } };
        case GIFTVENDOR_UPDATE_FAIL:
            return { ...state, giftvendorUpdate: { giftvendor: {}, error: action.payload, isLoading: false } };
        case GIFTVENDORS_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, giftvendorsFilter: { search: state.giftvendorsFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, giftvendorsFilter: { select: state.giftvendorsFilter.select, search: action.value } };
            }
            return res;
        default:
            return state;
    }
}