import * as React from 'react';
import { StatusBar } from 'expo-status-bar';
import useColorScheme from './src/hooks/useColorScheme';
import Navigation from './src/navigation';
import { ToastProvider } from './src/components/custom/react-native-paper-toast/src';
import { useDispatch } from "react-redux";
import { getData } from './src/state/actions/authActions';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { oldCart } from './src/state/actions/shoppers/shoppercartActions';
import { returnTheme } from './src/constants/Colors';

export default function AuthLayer({colorScheme}) {
  // const colorScheme = useColorScheme();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({ type: "THEME_PROVIDER", payload: returnTheme(colorScheme) })
  }, [colorScheme])

  React.useEffect(() => {
    dispatch(getData());
    dispatch(oldCart());
    if (seconds === -1)
      setSeconds(2);
  })
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    if (seconds === 2) {
      const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
      return () => clearTimeout(timer)
    }
  }, [seconds]);
  return (
    <ToastProvider>
      {seconds <= 1 ?
        <Navigation colorScheme={colorScheme} />
        :  <View style={styles.centered}>
        <ActivityIndicator></ActivityIndicator>
      </View>
        
      }
      <StatusBar />
    </ToastProvider>
  );

}
const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});
