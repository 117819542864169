
import axios from "axios";
import config from './../config'
import AsyncStorage from '@react-native-async-storage/async-storage';
import shopperconfig from "./shopperconfig";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
const getDataAsync = async () => {
    try {
        const jsonValue = await AsyncStorage.getItem('cart');
        return jsonValue != null ? JSON.parse(jsonValue) : [];
    } catch (e) {
    }
}
const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;

export const oldCart = () => {
    return function (dispatch) {
        getDataAsync().then((oldCart) => {
            dispatch(cartUpdate(oldCart));
        })
    }
}

export const addCart = (payload: any, ID: any, setResponse: any) => {
    return function (dispatch) {
        axios.post(`${config.nodecudurl}/Partner_Cart/Partner_Cart_Create`, payload, {
            headers: nodeheaders,
        })
            .then(response => {
                let data = response.data;
                if (data.Availabitly_Stock === "0") {
                    setResponse(data.UI_Display_Message);
                }
                else {
                    dispatch(getCart({ Buyer_Partner_Details_ID: ID }))
                    setResponse(data.UI_Display_Message);
                }

            })
            .catch(error => {
            })
    }
}
export const getCart = (payload) => {
    return function (dispatch) {
        axios.post(`${shopperconfig.nodePartnerUrl}/Partner_Cart/Partner_Cart_Get`, payload, {
            headers: nodeheaders
        })
            .then(response => {
                let data = response.data.results;
                let cart = [];
                if (data?.Partner_Cart_Get_Final[0]) {
                    let responseCart = data?.Partner_Cart_Get_Final? data?.Partner_Cart_Get_Final: []
                    for (let i = 0; i < responseCart.length; i++) {
                        let item = {
                            "partnerId": responseCart[i]?.Partner_Details_Id,
                            "partnerName": responseCart[i]?.Partner_Name,
                            "partnerLogo": responseCart[i]?.Logo_Path,
                            "Business_Type": responseCart[i]?.Business_Type,
                            products: [{
                                Product: responseCart[i]?.Products.Product,
                                partnerProduct: responseCart[i]?.Products.Partner_Product,
                                partnerProductId: responseCart[i]?.Products.Partner_Product.Partner_Product_Id,
                                itemCount: responseCart[i]?.Products.Inventory.User_Requested_Quantity,
                                size: responseCart[i]?.Products.Inventory.Size,
                                addressError: "",
                                Discount: responseCart[i]?.Products?.Discounted_Products,
                                isGiftPackErrorMessage: "",
                                address: responseCart[i]?.Products?.Delivery_Address,
                                User_Cart_Id: responseCart[i]?.User_Cart_Id,
                                Inventory: responseCart[i]?.Products.Inventory,
                                isGiftPackMessage: responseCart[i]?.Products?.User_Cart_Details?.Gift_Message || "",
                                isGiftPack: (responseCart[i]?.Products?.User_Cart_Details?.Gift_Message ? true : false),
                            }],

                            addressError: ""
                        }
                        let notExist = true;
                        for (let j = 0; j < cart.length; j++) {
                            if (cart[j].partnerId == responseCart[i]?.Partner_Details_Id) {
                                notExist = false;
                                cart[j].products.push({
                                    Product: responseCart[i]?.Products.Product,
                                    partnerProduct: responseCart[i]?.Products.Partner_Product,
                                    partnerProductId: responseCart[i]?.Products.Partner_Product.Partner_Product_Id,
                                    itemCount: responseCart[i]?.Products.Inventory.User_Requested_Quantity,
                                    size: responseCart[i]?.Products.Inventory.Size,
                                    Discount: responseCart[i]?.Products?.Discounted_Products,
                                    addressError: "",
                                    isGiftPackErrorMessage: "",
                                    address: responseCart[i]?.Products?.Delivery_Address,
                                    User_Cart_Id: responseCart[i]?.User_Cart_Id,
                                    Inventory: responseCart[i]?.Products.Inventory,
                                    isGiftPackMessage: responseCart[i]?.Products?.User_Cart_Details?.Gift_Message || "",
                                    isGiftPack: (responseCart[i]?.Products?.User_Cart_Details?.Gift_Message ? true : false),
                                })
                            }
                        }
                        if (notExist) {
                            cart.push(item);
                        }
                    }
                }
                dispatch({
                    type: GET_CART_ITEMS,
                    data: cart,
                    allCart: response
                })
            })
            .catch(error => {
            })
    }
}
export const addItemMultipleCart = (partner, cartItem, count, responseFunction = (data) => { }) => {
    return function (dispatch) {
        let payload = {
            "B2B_User_Cart_Id": cartItem?.partner?.User_Cart_Id,
            "Partner_Product_Id": cartItem.partner?.partnerProductId,
            "Quantity": count,
            "Partner_Address_Id": ""
        }
        axios.put(`${config.url}/Partner_Cart/Partner_Cart_Update`, payload, {
            headers: headers,
        })
            .then(response => {
                let data = response.data;
                dispatch(getCart({ Buyer_Partner_Details_ID: partner.Partner_Details_Id }))
                responseFunction(data);
                if (data.Response_Status == "Success") {
                }
                else {
                }
            })
            .catch(error => {

            })
    }
}
export const removeItemInCart = (Logindetails, data) => {
    return function (dispatch) {
        let payload = {
            "Cart_ID": data.partner.User_Cart_Id,
            "Email_ID": Logindetails?.Email_Id,
            "Partner_Detail_ID": Logindetails.Partner_Details_Id
        }

        axios.post(`${config.url}/Partner_Cart/Partner_Cart_Delete`, payload, {
            headers: headers,
        })
            .then(response => {
                let data = response.data;

                dispatch(getCart({ Buyer_Partner_Details_ID: Logindetails.Partner_Details_Id }))

                if (data.Response_Status == "Success") {
                }
                else {
                }
            })
            .catch(error => {
            })
    }
}

export const removePartnerInCart = (oldCart1, partners) => {
    return function (dispatch) {
        getDataAsync().then((oldCartTemp) => {
            let oldCart = JSON.parse(JSON.stringify(oldCartTemp));
            let newCart = [];
            for (let i = 0; i < oldCart.length; i++) {
                let notExist = true;
                for (let j = 0; j < partners.length; j++) {
                    if (oldCart[i].partnerId === partners[j]) {
                        notExist = false;
                        break;
                    }
                }
                if (notExist) {
                    newCart.push(oldCart[i]);
                }
            }
            dispatch(cartUpdate(newCart));
        })
    }
}

export const removeAll = () => {
    return function (dispatch) {
        dispatch(cartUpdate([]));
    }
}
export const productAddOrRemoveAddress = (detailId, cartItem, address) => {
    return function (dispatch) {
        let payload = {
            "B2B_User_Cart_Id": cartItem.User_Cart_Id,
            "Partner_Address_Id": address?.Partner_Address_Id
        }
        axios.put(`${config.url}/Partner_Cart/Partner_Cart_Update`, payload, {
            headers: headers,
        })
            .then(response => {
                let data = response.data;
                dispatch(getCart({ Buyer_Partner_Details_ID: detailId.Partner_Details_Id }))
            })
            .catch(error => {
            })
    }
}

export const productAddBillingAddress = (oldCart1, partner, address) => {
    return function (dispatch) {
        getDataAsync().then((oldCartTemp) => {
            let oldCart = JSON.parse(JSON.stringify(oldCartTemp));
            let partnerExist = false;
            let productExist = false;
            for (let i = 0; i < oldCart.length; i++) {
                if (oldCart[i].partnerId === partner.partnerId) {
                    let item = oldCart[i];
                    item.addressError = "";
                    item.address = address;
                    break;
                }
            }
            dispatch(cartUpdate(oldCart));
        })
    }
}

export const paceOrderValidation = (oldCart1, partner, addressCheckResponse, productError, Logindetails, type = null) => {
    return function (dispatch) {
        getDataAsync().then((oldCart1) => {
            let oldCart = JSON.parse(JSON.stringify(oldCart1));
            let partnerExist = false;
            let noError = true;
            for (let i = 0; i < oldCart.length; i++) {
                if (type === "all") {
                    let items = oldCart[i].products;
                    partnerExist = true;
                    for (let j = 0; j < items.length; j++) {
                        let item = items[j];
                        if (item.address) {
                            item.addressError = "";
                        }
                        else {
                            item.addressError = "Shipping Address is required."
                            noError = false;
                        }
                    }
                } else {
                    if (oldCart[i].partnerId === partner.partnerId) {
                        let items = oldCart[i].products;
                        partnerExist = true;
                        for (let j = 0; j < items.length; j++) {
                            let item = items[j];
                            if (item.address) {
                                item.addressError = "";
                            }
                            else {
                                item.addressError = "Shipping Address is required."
                                noError = false;
                            }
                        }
                        break;
                    }
                }
            }
            if (productError.length > 0) {
                noError = false;
            }
            addressCheckResponse(noError);
            dispatch(getCart({ Buyer_Partner_Details_ID: Logindetails.Partner_Details_Id }))

        })
    }
}

export const productCheckAddress = (oldCart1, partner, addressCheckResponse, productError) => {
    return function (dispatch) {
        getDataAsync().then((oldCartTemp) => {
            let oldCart = JSON.parse(JSON.stringify(oldCartTemp));
            let partnerExist = false;
            let noError = true;
            for (let i = 0; i < oldCart.length; i++) {
                if (oldCart[i].partnerId === partner.partnerId) {
                    let items = oldCart[i].products;
                    partnerExist = true;
                    for (let j = 0; j < items.length; j++) {
                        let item = items[j];
                        if (item.address) {
                            item.addressError = "";
                        }
                        else {
                            item.addressError = "Shipping Address is required."
                            noError = false;
                        }
                        if (item.isGiftPack) {
                            if (!(item.isGiftPackMessage)) {
                                item.isGiftPackErrorMessage = "Gift Message is required.";
                                noError = false;
                            } else if (item.isGiftPackMessage.length < 5) {
                                item.isGiftPackErrorMessage = "Gift Message must be more then 15 characters.";
                                noError = false;
                            }
                            else {
                                item.isGiftPackErrorMessage = "";
                            }
                        }
                    }
                    if (oldCart[i].address) {
                        oldCart[i].addressError = "";
                    }
                    else {
                        oldCart[i].addressError = "Billing Address is required.";
                        noError = false;
                    }
                    break;
                }
            }
            if (productError.length > 0) {
                noError = false;
            }
            addressCheckResponse(noError);
            dispatch(cartUpdate(oldCart));
        })
    }
}

export const productRemoveAddressError = (oldCart1) => {
    return function (dispatch) {
        getDataAsync().then((oldCartTemp) => {
            let oldCart = JSON.parse(JSON.stringify(oldCartTemp))
            for (let i = 0; i < oldCart.length; i++) {
                let items = oldCart[i].products;
                for (let j = 0; j < items.length; j++) {
                    let item = items[j];
                    item.addressError = "";
                    item.isGiftPackErrorMessage = "";
                }
                oldCart[i].addressError = "";
            }
            dispatch(cartUpdate(oldCart));
        })
    }
}
export const cartUpdate = (data) => {
    return {
        type: GET_CART_ITEMS,
        data: data
    }
}

export const placeNewOrderAction = (request, setResponse, item) => {
    return function (dispatch) {
        axios.post(`${config.url}/B2B_Order/Create`, request, {
            headers: headers,
        })
            .then(response => {
                setResponse(true, response.data, item);
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const productInventoryAction = (request, setResponse, cart, data, count) => {
    return function (dispatch) {
        axios.post(`${shopperconfig.nodePartnerUrl}/Inventory/Inventory_Stock_Get`, request, {
            headers: nodeheaders
        })
            .then(response => {
                setResponse(true, response.data.results, cart, data, count);

            })
            .catch(error => {

                setResponse(false, "Currently server is not working. Please try again later.", cart, data, count);
            })
    }
}

export const placeNewOrderInventoryAction = (request, setResponse) => {
    return function (dispatch) {
        axios.post(`${config.url}/B2B_Product_Inventory_Stock/Validation_Cart`, request, {
            headers: headers,
        })
            .then(response => {
                setResponse(true, response.data);
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}
