import {
    EVENT_REQUEST,
    EVENT_SUCCESS,
    EVENT_FAIL,
  
    SINGLE_EVENT_REQUEST,
    SINGLE_EVENT_FAIL,
  
    EVENT_UPDATE_REQUEST,
    EVENT_UPDATE_SUCCESS,
    EVENT_UPDATE_FAIL,
  
    EVENT_DATA,
    EVENT_FILTER,
  
  } from '../../actions/Properties/eventsAction'
  
  const initialState = {
  
    userevents: { all: [], error: '', isLoading: false },
    event: { event: {}, error: '', isLoading: false },
  
    eventUpdate: { event: {}, error: '', isLoading: false },
    eventdata: { eventsdata: {}, error: '', isLoading: false },
    usereventFilter: { select: 'All', search: '' },
  };
  
  export default function (state = initialState, action: any) {
  
    switch (action.type) {
      case EVENT_REQUEST:
        return { ...state, userevents: { all: state.userevents.all, error: '', isLoading: true } };
      case EVENT_SUCCESS:
        return { ...state, userevents: { all: action.payload, error: '', isLoading: false } };
      case EVENT_FAIL:
        return { ...state, userevents: { all: [], error: action.payload, isLoading: false } };
  
      case SINGLE_EVENT_REQUEST:
        return { ...state, event: { event: {}, error: '', isLoading: true } };
    case SINGLE_EVENT_FAIL:
        return { ...state, event: { event: {}, error: action.payload, isLoading: false } };
  
      case EVENT_UPDATE_REQUEST:
        return { ...state, eventUpdate: { event: {}, error: '', isLoading: true } };
      case EVENT_UPDATE_SUCCESS:
        return { ...state, eventUpdate: { event: state.eventUpdate.event, error: '', isLoading: false } };
      case EVENT_UPDATE_FAIL:
        return { ...state, eventUpdate: { event: {}, error: action.payload, isLoading: false } };
        case EVENT_DATA:
          return { ...state, eventdata: { eventsdata: action.value, error: '', isLoading: true } };
      case EVENT_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, usereventFilter: { search: state.usereventFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, usereventFilter: { select: state.usereventFilter.select, search: action.value } };
        }
        return res;
  
      default:
        return state;
    }
  }