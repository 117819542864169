import * as React from 'react';
import { Avatar, Button, Colors, HelperText, ProgressBar, Surface, Text, TextInput, TouchableRipple } from 'react-native-paper';
import { material } from 'react-native-typography';
import { Linking, View, Dimensions, ScrollView, Platform, ActivityIndicator } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { forgotCheck } from '../../state/actions/authActions';
import getEnvVars from '../../../environment';
const { BASEURL } = getEnvVars();

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
function Copyright() {
    return (
        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Text onPress={() => {
                Linking.openURL("https://www.easystepin.com/").catch((err) =>
                    console.error('An error occurred', err),
                );
            }} style={[material.caption, {}]}>{'Copyright © '}
                EasyStepIn
                {' 2016 - '}
                {new Date().getFullYear()}
                {'.'}
            </Text>
        </View>
    );
}


const ForgotPassword = ({ toggleModal }: { navigation: any, toggleModal: any, dropDownAlertFun: Function }) => {
    const dispatch = useDispatch();
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const [isSubmited, setIsSubmited] = React.useState(false);
    const [stateInit, setStateInit] = React.useState({
        email: '',
    });


    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(0), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    const signIn = () => {
        toggleModal();
    }
    const [forgotSuccess, setForgotSuccess] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState('');
    const forgotResponse = (status: any, errorMessage: any, otp: any, mobile: any) => {
        setIsSubmited(false);
        setErrorMessage(errorMessage);

        if (status) {
            setForgotSuccess(true);
        } else {
            setSeconds(3);
        }
    }


    return (
        <Surface style={Platform.OS === 'web' ? {
            alignItems: 'center',
            elevation: 0,
            minHeight: 450,
            maxHeight: 480,
            top: 10,
            elevation: 0
        } : {
            alignItems: 'center',
            elevation: 0,
            top: 20,
        }} >

            <TouchableRipple style={{ width: 36, position: 'absolute', right: 0, zIndex: 1 }} onPress={() => { toggleModal(); }}>
                <Avatar.Icon color={'#000000'} style={{ backgroundColor: "#ffffff"  }} size={36} icon="close" />
            </TouchableRipple>
            {
                forgotSuccess ? <View style={{flexDirection:"column",justifyContent:"center",alignSelf:"center",margin:"auto",paddingHorizontal:20}} >
                    <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10, alignSelf: "center", borderColor: '#ffffff' }} />

                    <Text style={{ alignSelf: "center" }} >Please check register email for password change link. </Text>

                    <Button style={{ marginTop: 15, maxWidth: 150, backgroundColor: '#27b6cc', alignSelf: "center" }} mode="contained" onPress={() => {toggleModal() }}>
                        Okay
                    </Button>
                </View> :

                    <ScrollView keyboardShouldPersistTaps='always' style={{
                        minHeight: 450,
                        maxHeight: 480,
                    }} >
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 20
                        }}>
                            <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10 }} />
                            <Text style={material.headline}>Forgot Password</Text>
                        </View>
                        <View>
                            <Formik
                                initialValues={stateInit}

                                validationSchema={Yup.object().shape({
                                    email: Yup.string()
                                        .email('Please provide valid Email Address.')
                                        .max(255)
                                        .required('Email Address is required.')
                                })}

                                onSubmit={(values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
                                    setIsSubmited(true);
                                    setStateInit(values);
                                    setErrorMessage("");
                                    dispatch(forgotCheck({
                                        "Email_Id": values.email,
                                        "Base_URL": BASEURL + "set-password?token=",
                                    }, forgotResponse));
                                }}
                            >
                                {({ handleChange, handleBlur, handleSubmit, values, errors, touched }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any }) => (
                                    <View style={{
                                        flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <View style={{ width: "90%", margin: "auto" }}>
                                            <TextInput selectionColor='rgba(0, 0, 0, 0.5)' style={{ width: "100%", margin: "auto" }}
                                                label="Email Address"
                                                mode='outlined'
                                                onChangeText={handleChange('email')}
                                                onBlur={handleBlur('email')}
                                                value={values.email}
                                                error={Boolean(touched.email && errors.email)}
                                            />
                                            <HelperText style={{ width: "90%" }} type="error" visible={Boolean(touched.email && errors.email)}>
                                                {touched.email && errors.email}
                                            </HelperText>
                                        </View>
                                        <View style={{
                                            width: "90%", margin: "auto", marginTop: -5, alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            {seconds > 0 && <><Text  >{errorMessage}</Text><ProgressBar style={{
                                                height: 5,
                                                width: 300,
                                            }} progress={.30 * seconds} color={'red'} /></>}
                                        </View>
                                        <View style={{
                                            width: "90%", margin: "auto", marginTop: -5, alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            {isSubmited ? <ActivityIndicator color='#27b6cc' /> :
                                                <Button disabled={isSubmited} style={{ marginTop: 15, backgroundColor: '#27b6cc' }} mode="contained" onPress={handleSubmit}>
                                                    Submit
                                                </Button>}
                                        </View>
                                        <View style={{ width: "80%", marginTop: 15, flexDirection: "row", flexWrap: "wrap", justifyContent: 'center' }}>

                                            <TouchableRipple
                                                onPress={signIn}
                                            >
                                                <Text style={{ textDecorationLine: 'underline' }}>Go back to Sign In</Text>
                                            </TouchableRipple>
                                        </View>
                                    </View>
                                )}
                            </Formik>
                        </View>
                    </ScrollView>}
            <Copyright />
        </Surface>
    );
};

export default ForgotPassword;