import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Image, Dimensions, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Button, Text, Searchbar, Portal, Avatar, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import { getAllBrandAction, resetFilter } from '../../state/actions/brandAction';
import RenderHtml from 'react-native-render-html';
import Header from '../auth/components/header';
import isEmpty from '../../state/validations/is-empty';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import Icon from 'react-native-vector-icons/FontAwesome';
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Brand_Id',
    label: 'Brand ID'
  },
  {
    value: 'Brand_Name',
    label: 'Brand Name'
  },

  {
    value: 'Status',
    label: 'Status'
  }
];
const numberOfItemsPerPageList = [5, 10, 20];
function BrandDetails({ navigation }: { navigation: any }) {
  const toast = useToast();
  const [page, setPage] = React.useState(0);
  const [webpage, setwebPage] = React.useState(1);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const esiColor = useSelector(state => state.theme);

  const Allbrands = useSelector(state => state.brand.brands.all);
  let brands = Allbrands?.results;
  const [brandMenu, setBrandMenu] = React.useState('All')
  const [brandSearch, setBrandSearch] = React.useState('');
  const [brand, setBrand] = React.useState();
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > Allbrands?.Pagination?.TotalCount ? Allbrands?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setwebPage(1)
    setPage(0)
    setPagination({ PageNo: Number(1), PageSize: e, SortBy: "BRAND_ID", SortOrder: "DESC" })
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "BRAND_ID", SortOrder: "DESC" });
  }
  const DynamicPageIncrease = (e) => {
    setwebPage(e < Allbrands?.Pagination?.TotalPages ? e + Number(1) : Allbrands?.Pagination?.TotalPages)
    setPagination({ PageNo: e == Allbrands?.Pagination?.TotalPages ? Allbrands?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "BRAND_ID", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "BRAND_ID", SortOrder: "DESC" });
  }
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "BRAND_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      brandsCall();
    }
  }, [pagination])
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const brandsCall = () => {
    setIsLoading(true);
    let formData = {
      "search_by_filter": brandMenu,
      "search": "",
      "Brand_Id": "",
      "Brand_Name": "",
      "Brand_Status": "",
      "Esi_Rating": "",
      "Records_Filter": "FILTER",
      "Todays_Hot": "",
      "Top_Brands": ""
    };
    dispatch(getAllBrandAction(formData, pagination))
  };

  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "BRAND_ID", SortOrder: "DESC" }
    let formData = {
      "search_by_filter": brandMenu,
      "search": "",
      "Brand_Id": "",
      "Brand_Name": "",
      "Brand_Status": "",
      "Esi_Rating": "",
      "Records_Filter": "FILTER",
      "Todays_Hot": "",
      "Top_Brands": ""
    };
    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getAllBrandAction(formData, pagecall))
  };
  const [showDropDown, setShowDropDown] = React.useState(false);
  const dispatch = useDispatch()
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  React.useEffect(() => {
    // if (isEmpty(brands)) {
    let formData = {
      "search_by_filter": brandMenu,
      "search": "",
      "Brand_Id": "",
      "Brand_Name": "",
      "Brand_Status": "",
      "Esi_Rating": "",
      "Records_Filter": "FILTER",
      "Todays_Hot": "",
      "Top_Brands": ""
    };
    setBrand(brands)
    dispatch(getAllBrandAction(formData, pagination))
    // }
  }, [permissions]);

  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  React.useEffect(() => {
    if (!isEmpty(brands)) {
      setBrand(brands);
    } else {
      setBrand([]);
    }
  }, [brands]);
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Brand_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const [visible, setVisible] = React.useState(false);
  const [bdata, setData] = React.useState();

  const hideDialog = () => setVisible(false);

  const handleChange = itemValue => {
    setBrandMenu(itemValue);
  };
  const handleSearch = value => {
    setBrandSearch(value);
    let formData = {
      "search_by_filter": brandMenu,
      "search": value,
      "Brand_Id": "",
      "Brand_Name": "",
      "Brand_Status": "",
      "Esi_Rating": "",
      "Records_Filter": "FILTER",
      "Todays_Hot": "",
      "Top_Brands": ""
    };
    dispatch(getAllBrandAction(formData, pagination))
  };

  const style = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 200,
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 100,
      // color: esiColor.SIconColor
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'center',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      left: 22,
      top: 8,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.BackgroundColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 150,
      backgroundColor: esiColor.BackgroundColor
    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
    },
    rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
    },
    rowsPerPagePickers: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      borderColor: 'gray',
      borderWidth: 0.5,
      paddingHorizontal: 8,
    },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      // backgroundColor:esiColor.SIconColor
    },
    paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      // backgroundColor:esiColor.SIconColor
    },
    pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
    },
  });

  const customTheme = {
    ...DefaultTheme,
    dark: true,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,


    },
  };


  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation}></Header>
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions <= 600 ? "92%" : "65%", maxHeight: (dimensions >= 500) ? "auto" : 500, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ flexDirection: "row" }}><View style={{ flex: dimensions <= 500 ? 1 : 3 }} /><View style={{ flex: dimensions <= 500 ? 1 : 3 }} /><View style={{ flex: 0.3, justifyContent: "flex-end", paddingTop: 10 }}>
            <IconButton icon="close" color={esiColor.GBFColor} size={20} onPress={() => setVisible(!visible)} />
          </View></View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center' }}>View Brand</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 5 }}>
              <Dialog.Content style={{ marginLeft: -28 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Brand Name</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{bdata?.Brand_Name} </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Brand Link</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{bdata?.Brand_Link} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: bdata?.Brand_Status === "Active" ? "green" : "red" }}>{bdata?.Brand_Status} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Brand Rating</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{bdata?.Brand_Esi_Rating} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}></View>
                <View style={{
                  flexDirection: "row", paddingTop: 15, paddingButtom: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Brand Description</Text></View>
                  <View style={{ flex: 2, marginTop: -15 }}><RenderHtml source={{ html: `<span style="color: ${esiColor.Text}">${bdata?.Brand_Description}</span>` }}
                  /></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  borderRadius: 6,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }} >Brand Reviews</Text></View>
                  <View style={{ flex: 2, marginTop: -15 }}><RenderHtml source={{ html: `<span style="color: ${esiColor.Text}">${bdata?.Brand_Esi_Reviews}</span>` }} /></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{bdata?.Created_By} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
          <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
            navigation.navigate('PartnerMainCenter')
            handleSearch("")
            dispatch(resetFilter())
          }
          }>
            Go Back
          </Button>
        </View>


        <View style={{
          flexDirection: "column"
        }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Brand Management</Text>
        </View>

        {(permissions.Brands_Management === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => { navigation.navigate('AddBrand'); dispatch(resetFilter()) }}>

              <Text style={{ color: esiColor.itemButtenColor }}> + Add Brand</Text>

            </Button>
          </View>}



        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== "web" ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[style.dropdown]}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                inputSearchStyle={style.inputSearchStyle}
                iconStyle={style.iconStyle}
                data={filterOptions}
                containerStyle={style.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={brandMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={brandMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.DescColor }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Brand ID" value="Brand_Id" />
                <Picker.Item label="Brand Name" value="Brand_Name" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor,
              }}
              placeholder="Search"
              iconColor={esiColor.SIconColor}
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              onChangeText={(value) => handleSearch(value)}
              value={brandSearch}
            />

          </View>
        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={style.mainbox}>
            <Card>
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={style.databeHeader}>
                  <DataTable.Title style={style.title} active={orderBy === 'Brand_Id'}
                    sortDirection={orderBy === 'Brand_Id' ? order : 'ascending'}
                    onPress={createSortHandler('Brand_Id')}><Text style={style.titletext}>Brand ID</Text></DataTable.Title>
                  <DataTable.Title style={style.title}><Text style={style.titletext}>Image</Text></DataTable.Title>
                  <DataTable.Title style={style.title} active={orderBy === 'Name'}
                    sortDirection={orderBy === 'Name' ? order : 'ascending'}
                    onPress={createSortHandler('Name')}><Text style={style.titletext}> Brand Name</Text></DataTable.Title>
                  <DataTable.Title style={style.title} active={orderBy === 'Status'}
                    sortDirection={orderBy === 'Status' ? order : 'ascending'}
                    onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>
                  <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {
                  brands?.map((brand, index) => (
                    <DataTable.Row style={style.databeBox} key={brand.Brand_Id}>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{brand.Brand_Id}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Avatar.Image size={45} source={{ uri: brand.Brand_Image }} /></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{brand.Brand_Name}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{brand.Brand_Status}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Button icon="eye" textColor={esiColor.SIconColor} size={20} onPress={(e) => { setData(brand); setVisible(!visible) }} /></DataTable.Cell>
                    </DataTable.Row>
                  ))
                }
                {isEmpty(brands) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                {Platform.OS !== "web" ?
                  <DataTable.Pagination
                    // theme={esiColor.pa}
                    page={page}
                    numberOfPages={Math.ceil(Allbrands?.Pagination?.TotalCount / numberOfItemsPerPage)}
                    onPageChange={page => DynamicPage(to < Allbrands?.Pagination?.TotalCount ? page : 0)}
                    label={Allbrands?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Allbrands?.Pagination?.TotalCount}` : '0-0'}
                    showFastPaginationControls={(Number(page) + Number(1)) > Allbrands?.Pagination?.TotalPages ? false : true}
                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    onItemsPerPageChange={onItemsPerPageChange}
                    selectPageDropdownLabel={'Rows per page'}
                  />
                  :
                  <View style={style.rowsPerPageContainer}>
                    <Text style={{ color: esiColor.Text }}>rows per page: </Text>
                    <Picker
                      style={style.rowsPerPagePicker}
                      selectedValue={numberOfItemsPerPage}
                      onValueChange={(e) => onItemsPerPageChange(e)}
                    >
                      <Picker.Item label="5" value={5} />
                      <Picker.Item label="10" value={10} />
                      <Picker.Item label="20" value={20} />
                    </Picker>
                    <View style={style.paginationContainer}>
                      <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                        <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                      <Text style={style.pageInfoText}>
                        {Allbrands?.Pagination?.PageNo + ' of ' + Allbrands?.Pagination?.TotalPages}
                      </Text>
                      <TouchableOpacity onPress={e => DynamicPageIncrease(to < Allbrands?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                        <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                    </View>
                  </View>}

              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}


export default BrandDetails
