import React, { useState } from 'react';
import { Card, Button, TextInput, HelperText, Surface, ActivityIndicator, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Header from '../auth/components/header';
import EsiDatePicker from '../../components/custom/date/date';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import { getshopperProductAction } from '../../state/actions/shoppers/shopersAction';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAllPartnerProductssearch } from '../../state/actions/partnerproductsAction';
import { EditshopperownPhysicalPartner } from '../../state/actions/shopersAction';
import isEmpty from '../../state/validations/is-empty';
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");


const EditownShoppersProduct = (props: any) => {
    const { navigation } = props;
    const toast = useToast();
    const esiColor = useSelector(state => state.theme);
    // Role restriction
    const EmailID = useSelector(state => state.auth?.user);
    const [statusDropDown, setStatusDropDown] = useState("");
    // const shopperpartnersGet = useSelector(state => state.shoppers.singleproduct.all);
    const discountedproductid = props.route?.params?.discountedproductid;
    const partnerid = props.route?.params?.partnerid;
    const partnerproductid = props.route?.params?.partnerproductid;
    const dispatch = useDispatch();
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "DISCOUNTED_PRODUCTS_ID", SortOrder: "DESC" });
    const partnerproductssearch = useSelector(state => state.productpartners.partnerproductssearch.partners);
    const [showProductMasterDropDown, setShowProductDropDown] = React.useState('');
    const [refreshing, setRefreshing] = React.useState(false);
    const [shopperpartnersGet, sethopperpartnersGet] = useState([]);
    const callBackGetData = (data) => {
        if (data) {
            sethopperpartnersGet(data);
        }

    };
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    // screen Dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    React.useEffect(() => {
        if (EmailID) {
            const formData = {
                "search": '',
                "Discounted_Products_Id": discountedproductid,
                "Max_Discount": "",
                "Min_Discount": "",
                "Partner_Details_Id": EmailID.Partner_Details_Id,
                "Product_Id": "",
                "Records_Filter": "FILTER",
                "Status": ""
            };
            dispatch(getshopperProductAction(formData, pagination, callBackGetData))
        }

    }, [EmailID]);


    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        dispatch(getAllPartnerProductssearch("1"))
    }, []);
    let productopt = []
    if (partnerproductssearch) {
        if (partnerproductssearch[0]) {
            for (let i = 0; i < partnerproductssearch?.length; i++) {
                productopt.push(
                    {
                        value: partnerproductssearch[i]?.Product_Id,
                        label: partnerproductssearch[i]?.Product_Name,
                    })
            }
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        datePickerStyle: {
            width: 350,
            height: 60,
            marginTop: 6,
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },
        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        checkbox: {
            flexDirection: "row",
            justifyContent: 'flex-start'
        },
        label: {
            marginBottom: 30,
        },
        editor: {
            backgroundColor: "black",
            borderColor: "black",
            borderWidth: 1,
        },
        rich: {
            minHeight: 130,
            // flex: 1,
        },
        richBar: {
            height: 50,
            backgroundColor: "#F5FCFF",
        },
        text: {
            fontWeight: "bold",
            fontSize: 18,
        },
        tib: {
            textAlign: "center",
            color: "#515156",
        },
        a: {
            fontWeight: "bold",
            color: "purple",
        },
        div: {
            fontFamily: "monospace",
        },
        p: {
            fontSize: 30,
        }, container: {
            paddingTop: 15
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{
            flexShrink: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            } >
                {/* {!isEmpty(shopperpartnersGet?.results) ? */}
                <Formik
                    initialValues={{
                        "Discount_End_Date": shopperpartnersGet?.Discount_End_Date,
                        "Discount_In_Percentage": shopperpartnersGet?.Discount_In_Percentage,
                        "Discount_Start_Date": shopperpartnersGet?.Discount_Start_Date,
                        "Discounted_Products_Id": shopperpartnersGet?.Discounted_Products_Id,
                        "Max_Quantity": shopperpartnersGet?.Max_Quantity + " ",
                        "Min_Quantity": shopperpartnersGet?.Min_Quantity + "",
                        "Modified_By": EmailID?.Email_Id,
                        "Partner_Details_Id": shopperpartnersGet?.Partner_Details_Id,
                        "Product_Id": shopperpartnersGet?.Product_Id,
                        "Status": shopperpartnersGet?.Status
                    }}
                    enableReinitialize={true}
                    validationSchema={
                        Yup.object().shape({
                            Min_Quantity: Yup.number().positive().integer().required(' Min Quantity is required.'),
                            Max_Quantity: Yup.number().positive().moreThan(Yup.ref('Min_Quantity'), 'Require Min Quantity greater than to Max Quantity.').required(' Max Quantity is required.'),
                            // Discount_Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Discount Start Date greater than or equal to today.").required("Discount Start Date is required."),
                            // Discount_End_Date: Yup.date().when("Discount_Start_Date", (Discount_Start_Date, schema) => Discount_Start_Date && schema.min(Discount_Start_Date, "Discount End Date can't be before Discount Start Date.")).required("Discount End Date is required."),

                        })}
                    onSubmit={(values, { setSubmitting, setErrors }) => {

                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        setErrors(errors);
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            formValues.Modified_By = EmailID?.Email_Id
                            dispatch(EditshopperownPhysicalPartner(formValues, props.navigation, toast, partnerproductid));
                        }
                    }
                    }
                >
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('ShoppersownGet', { shoppersproductparams: shopperpartnersGet.Product_Id, partnerproductid: partnerproductid })} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                        Edit Own Shopper products
                                    </Text>
                                    <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                //  theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(props.touched.Min_Quantity && props.errors.Min_Quantity)}
                                                label="Min Quantity"
                                                mode='outlined'
                                                value={props.values.Min_Quantity}
                                                onChangeText={(text) => { props.setFieldValue("Min_Quantity", text.replace(/ /g, '')) }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Min_Quantity && props.errors.Min_Quantity)}>
                                                {props.touched.Min_Quantity && props.errors.Min_Quantity}
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(props.touched.Max_Quantity && props.errors.Max_Quantity)}
                                                label="Max Quantity"
                                                mode='outlined'
                                                value={props.values.Max_Quantity}
                                                onChangeText={(text) => props.setFieldValue('Max_Quantity', text.replace(/ /g, ''))} />
                                            <HelperText type="error" visible={Boolean(props.touched.Max_Quantity && props.errors.Max_Quantity)}>
                                                {props.touched.Max_Quantity && props.errors.Max_Quantity}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: 10 }}>
                                            <TextInput
                                                //  theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(props.touched.Discount_In_Percentage && props.errors.Discount_In_Percentage)}
                                                label="Discount In Percentage"
                                                mode='outlined'
                                                value={props.values.Discount_In_Percentage}
                                                onChangeText={props.handleChange("Discount_In_Percentage")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Discount_In_Percentage && props.errors.Discount_In_Percentage)}>
                                                {props.touched.Discount_In_Percentage && props.errors.Discount_In_Percentage}
                                            </HelperText>
                                        </View>
                                        {/* <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: 7 }}>
                                            <EsiDatePicker
                                                label={'Discount Start Date'}
                                                style={{ width: "100%", height: 50 }}
                                                value={props.values.Discount_Start_Date}
                                                valueUpdate={date => {
                                                    props.setFieldValue('Discount_Start_Date', date)
                                                }} />

                                            <HelperText type="error" visible={Boolean(props.touched.Discount_Start_Date && props.errors.Discount_Start_Date)}>
                                                {props.touched.Discount_Start_Date && props.errors.Discount_Start_Date}
                                            </HelperText>
                                        </View> */}
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        {/* <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: 10 }}>
                                            <EsiDatePicker
                                                label={'Discount End Date'}
                                                style={{ width: "100%" }}
                                                value={props.values.Discount_End_Date}
                                                valueUpdate={date => {
                                                    props.setFieldValue('Discount_End_Date', date)
                                                }} />

                                            <HelperText type="error" visible={Boolean(props.touched.Discount_End_Date && props.errors.Discount_End_Date)}>
                                                {props.touched.Discount_End_Date && props.errors.Discount_End_Date}
                                            </HelperText>
                                        </View> */}
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: 5 }}>
                                            <View style={styles.container}>
                                                {/* Status input field with validation*/}
                                                <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                                                    Status
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={StatusOption}
                                                    placeholder={!statusDropDown ? '-- Please Select --' : ''}
                                                    labelField="label"
                                                    valueField="value"
                                                    maxHeight={StatusOption?.length > 1 ? 250 : 200}
                                                    search={StatusOption?.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Status'}
                                                    dropdownPosition="auto"
                                                    value={props.values.Status}
                                                    name="Status"
                                                    onFocus={() => setStatusDropDown(true)}
                                                    onBlur={() => setStatusDropDown(false)}
                                                    onUpdateValue={item => {
                                                        props.setFieldValue('Status', item.value)
                                                        setStatusDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                                                    {props.touched.Status && props.errors.Status}
                                                </HelperText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ alignItems: "center" }}>
                                        <Button textColor={esiColor.itemButtenColor} style={{ width: '10%', backgroundColor: esiColor.globalButtonColor }}
                                            // mode="outlined"
                                            onPress={props.handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
                {/* : < ActivityIndicator color='#27b6cc' style={{ marginTop: 20 }} />} */}
            </ScrollView >
        </Surface>
    );
};

export default EditownShoppersProduct;