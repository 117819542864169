import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';

export const PARTNERPAYMENT_REQUEST = "PARTNERPAYMENT_REQUEST";
export const PARTNERPAYMENT_SUCCESS = "PARTNERPAYMENT_SUCCESS";
export const PARTNERPAYMENT_FAIL = "PARTNERPAYMENT_FAIL";

export const PARTNERPAYMENT_GET_REQUEST = "PARTNERPAYMENT_GET_REQUEST";
export const PARTNERPAYMENT_GET_SUCCESS = "PARTNERPAYMENT_GET_SUCCESS";
export const PARTNERPAYMENT_GET_FAIL = "PARTNERPAYMENT_GET_FAIL";

export const PARTNERPAYMENT_UPDATE_REQUEST = "PARTNERPAYMENT_UPDATE_REQUEST";
export const PARTNERPAYMENT_UPDATE_SUCCESS = "PARTNERPAYMENT_UPDATE_SUCCESS";
export const PARTNERPAYMENT_UPDATE_FAIL = "PARTNERPAYMENT_UPDATE_FAIL";

export const PARTNERPAYMENT_FILTER = "PARTNERPAYMENT_FILTER";
const cudheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
  }  
/*=============================================================
                   Get All partner payment Action
===============================================================*/
export const getAllPartnerpaymentAction = (formData: any,pagination = {}) => async dispatch => {

    try {
      dispatch({
        type: PARTNERPAYMENT_REQUEST
      });
      meeapi.post(urlGenarator(`/Partner_Management/Payment_Settlement_Get`,pagination),
      formData,
      ) .then(response => {
        let data = response.data;
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: PARTNERPAYMENT_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: PARTNERPAYMENT_SUCCESS,
            payload: data
          });
        
      }
    })
    } catch (err) {
      dispatch({
        type: PARTNERPAYMENT_FAIL,
        payload: err
      });
    }
  };
/*=============================================================
                 Update partner payment Action
===============================================================*/

export const updatePartnerpaymentAction = (formData: any, navigation: any, toast: any) => async dispatch => {
    dispatch({
        type: PARTNERPAYMENT_UPDATE_REQUEST
    });
    axios.put(`${config.nodecudurl}/Partner_Management/Payment_Settlement_Update`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: PARTNERPAYMENT_UPDATE_SUCCESS
                });
                navigation.navigate('PartnerpaymentGet')
                toast.show({ message: 'Partner payment updated successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    Records_Filter: "ALL",
                    Partner_Id: formData.Partner_Id
                };
                dispatch(getAllPartnerpaymentAction(formValue))
            } else {
                const errors = {};
                dispatch({
                    type: PARTNERPAYMENT_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: PARTNERPAYMENT_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


// Filter 
export const partnerpaymentFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: PARTNERPAYMENT_FILTER,
            fType: fType,
            value: value,
        });
    }
}


//search
export const resetFilter = () => {
    return function (dispatch: any) {
      dispatch({
        type: PARTNERPAYMENT_FILTER,
       fType: "search", 
        value: "",
      });
    }
  }