import { EvilIcons } from '@expo/vector-icons';
import { DrawerActions, useRoute } from '@react-navigation/native';
import React, { useEffect, useState, useRef } from 'react';
import { Dimensions, Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import { Appbar, IconButton } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotification } from '../../../state/actions/notificationAction';
import { Audio } from 'expo-av';
import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';
import isEmpty from '../../../state/validations/is-empty';
import { getLogedInUser, loginOut, sessionGetStatus } from '../../../state/actions/authActions';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

const Header = ({ navigation, page }: { navigation: any, page: string }) => {
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userPic = useSelector(state => state.auth?.isAuthenticated ? state.auth?.user?.Profile_Pic_Path ? { uri: state.auth?.user?.Profile_Pic_Path } : require("../../../assets/images/icons/defaultlogo.png") : require("../../../assets/images/icons/defaultlogo.png"));
  React.useEffect(() => {
    return () => {
    };
  }, []);
  const EmailID = useSelector(state => state.auth?.user);
  const route = useRoute();
  const [notifications, setNotifications] = useState();
  const [partnerid, setPartnerId] = useState(EmailID?.Partner_Details_Id);
  const [oldcount, setOldCount] = useState(false)
  const [notificationsound, setnotificationsound] = React.useState();
  const [notificationToken, setNotificationToken] = React.useState("");
  const [sound, setSound] = React.useState();
  const [session, setSession] = React.useState(false);
  const toast = useToast();
  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(
      require('../../../assets/notification.wav')
    );
    setnotificationsound(sound);
    await sound.playAsync();
  }
  async function orderSound() {
    const { sound } = await Audio.Sound.createAsync(
      require('../../../assets/Order.mp3')
    );
    setSound(sound);
    await sound.playAsync();
  }



  React.useEffect(() => {


    return sound
      ? () => {
        sound.unloadAsync();
      }
      : undefined;
  }, [sound]);
  React.useEffect(() => {


    return notificationsound
      ? () => {
        notificationsound.unloadAsync();
      }
      : undefined;
  }, [notificationsound]);
  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  React.useEffect(() => {
    if (lastNotificationResponse &&
      lastNotificationResponse.notification.request.content.data.url
      && lastNotificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER) {
      navigation.navigate(lastNotificationResponse.notification.request.content.data.url);
    }
  }, [lastNotificationResponse]);
  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('Total_Count');
      if (value !== null) {
        let filter = {
          PARTNER_EMAIL_ID: EmailID.User_Type == "PARTNER_ADMIN" ? "" : EmailID?.Email_Id,
          Partner_Details_Id: EmailID?.Partner_Details_Id,
          Count: value,
          Records_Filter: "FILTER"
        }
        setOldCount(true)
        dispatch(getAllNotification(filter, callBackData))
      } else {
        let filter = {
          PARTNER_EMAIL_ID: EmailID.User_Type == "PARTNER_ADMIN" ? "" : EmailID?.Email_Id,
          Partner_Details_Id: EmailID?.Partner_Details_Id,
          Count: '',
          Records_Filter: "FILTER"
        }
        setOldCount(true)
        dispatch(getAllNotification(filter, callBackData))
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  const sessionGetresponse = (data: any) => {
    if (data) {
      signNavigation(data)
    }
  }
  const callBackData = (data: any) => {
    if (Platform.OS === 'web') {
      localStorage.setItem('Total_Count', (data.Total_Count))
    } else {
      AsyncStorage.setItem('Total_Count', JSON.stringify(data.Total_Count))
    }
    setNotifications(data.notification_count)
    setOldCount(false)
    if (data.Sound == "1") {
      if (data.results[0].Type == "Orders" || data.results[0].Type == "B2B_Bidding_Product" || data.results[0].Type == "Bidding_Product" || data.results[0].Type == "Shopper_Orders" && data.results[0].Is_Read == '0') {
        orderSound()
      } else {
        playSound()
      }
    }
  }
  const signNavigation = (data: any) => {
    if (data) {
     dispatch(loginOut(navigation, notificationToken, EmailID, ()=>{},toast));
      navigation.navigate('SignIn');
      setSession(false)
    }
  };
  console.log(EmailID,'local storage data')
  const MINUTE_MS = 7000;
  useEffect(() => {
    const interval = setInterval(() => {
      if ((EmailID?.Partner_Details_Id === partnerid) && !oldcount && isAuthenticated &&!session) {
        if (Platform.OS === "web") {
          let filter = {
            PARTNER_EMAIL_ID: EmailID.User_Type == "PARTNER_ADMIN" ? "" : EmailID?.Email_Id,
            Partner_Details_Id: EmailID?.Partner_Details_Id,
            Count: localStorage.getItem("Total_Count") === null ? '' : parseInt(localStorage.getItem("Total_Count")),
            Records_Filter: "FILTER"
          }
          setOldCount(true)
          dispatch(sessionGetStatus(EmailID?.Partner_Session_Id, sessionGetresponse))
          setPartnerId(EmailID?.Partner_Details_Id)
          dispatch(getLogedInUser(EmailID?.Email_Id, false, () => { }, EmailID?.Partner_Session_Id, () => { },EmailID))
          dispatch(getAllNotification(filter, callBackData))
        } else {
          readData()
        }
      }
    }, MINUTE_MS);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [EmailID])
  const _handleMore = () => {
    if (!(page == "auth"))
      navigation.dispatch(DrawerActions.openDrawer())
  };
  const _handleProfile = () => navigation.navigate('PartnerProfile', { ID: '1' });
  const [dimensions, setDimensions] = useState(Dimensions.get('window').width);
  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  return (
    <Appbar.Header style={{ height: 40, backgroundColor: "#27B6CC", backgroundColor: esiColor.BackgroundColor, shadowOpacity: 0.5, shadowColor: esiColor.brandShadowColor, shadowRadius: 2, borderBottomColor: esiColor.SBorderColor, borderBottomWidth: 0.5 }}>
      {isAuthenticated ?
        <TouchableOpacity onPress={_handleMore}>
          <EvilIcons name="navicon" size={35} color={esiColor.SIconColor} style={{ paddingTop: 5, paddingLeft: 10 }} />
        </TouchableOpacity>
        : null}

      <View style={{ flex: 1, alignItems: 'flex-start', marginLeft: -15 }}>

        <Appbar.Action size={72} color={esiColor.GBFColor} onPress={_handleMore} icon={require('../../../assets/images/img/logo/logo.png')} />
      </View>

      <Appbar.Content />

      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        {isAuthenticated ? <View>
          <IconButton
            icon="bell"
            iconColor={esiColor.GBFColor}
            size={20}
            onPress={(e) => { setSound(new Audio.Sound()), navigation.navigate("Notification") }}
          />
          {notifications && notifications !== "0" ?
            <View
              style={{
                position: 'absolute',
                backgroundColor: 'red',
                width: 16,
                height: 16,
                borderRadius: 15 / 2,
                right: 5,
                top: 9,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Text
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: "#FFFFFF",
                  fontSize: 8,
                }}>
                {notifications > '99' ? '99+' : notifications}
              </Text>
            </View>
            : null}</View> : null}
        <View>
        </View>
      </View>
      {
        page === "Auth" ? <Appbar.Action icon={require('../../../assets/images/icons/user.png')} /> :

          <TouchableOpacity onPress={_handleProfile}>
            <Image source={userPic} style={{ height: 25, width: 25, borderRadius: 50, backgroundColor: 'white', marginRight: 15, marginTop: 2 }}></Image>
          </TouchableOpacity >

      }
    </Appbar.Header >
  );
};

export default Header;