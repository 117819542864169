/*=============================================================
                  Get All Banners Action
===============================================================*/

import axios from "axios";
import config from "../config";
import { meeapi } from "./meeapi";

const urlGenarator = (url: any, pagination: any) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}
export const getProductByIdAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {
    meeapi.post(urlGenarator(`/Home/Single_Partner_Products_Get`, pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getProductsAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {
    meeapi.post(urlGenarator('/B2B_Product/Get', pagination),
    formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination );
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getCouponsAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/Products/GetCoupons', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getDealsAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/Products/GetDeals', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getPartnersAction = (
    formData = { Records_Filter: "All" }, pagination = {},  
    responseFunction: any
) => async (dispatch: any) => {
    meeapi.post(urlGenarator('/B2B_Partner/Get', pagination),
    formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination );
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};
export const getShopByStoresAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    axios.post(`${config.url}/Store_Type/User_Store_Type_Get?PageNo=0&PageSize=10`,
        formData,
        {
            headers: config.headersCommon
        }
    ).then(response => {
        let data = response.data;
        if (data.Store_Type_Details?.length <= 0) {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.Store_Type_Details, data?.Pagination || pagination);
            } catch (error) {
                responseFunction(true, "");
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};
export const getBrandsAction = (
    formData = { Records_Filter: "All" }, pagination = {},  
    responseFunction: any
) => async (dispatch: any) => {
    meeapi.post(urlGenarator('/B2B_Brand/Get', pagination),
    formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination );
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getvisitedProducts = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/Home/Single_Partner_Product_Get_New', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};




export const getAllNotification = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/Home/Notification_Get_User', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const getSingleCartItemCheck = (
    formData = {}, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/Partner_Cart/Partner_Cart_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        responseFunction(data ? true : false, data)
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const getCategory = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {
    meeapi.post(urlGenarator('/B2B_Categories/Get', pagination),
    formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination );
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getSubcategory = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {
    meeapi.post(urlGenarator('/Sub_Category_Management/sub_categories_Get', pagination),
    formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination );
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};
export const getOrder = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {
    meeapi.post(urlGenarator('/B2B_Order/Get', pagination),
    formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination );
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

