import 'react-native-gesture-handler';
import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, Dimensions, TouchableOpacity,RefreshControl } from 'react-native';
import { DataTable,Text, Surface, ActivityIndicator, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from '../../state/validations/is-empty';
import { getWalletHistory } from '../../state/actions/walletAction';
import Header from '../auth/components/header';


function WalletHistory(props) {
  const { navigation } = props;
  const wallets = useSelector(state => state.wallet.walletHistory.wallet);
  const loading = useSelector(state => state.wallet.walletHistory.isLoading);
  const esiColor = useSelector(state => state.theme);
  // Pagination
  let currentItems;
  let length;
  const pages = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxpageNumberLimit, setmaxpageNumberLimit] = useState(5);
  const [minpageNumberLimit, setminpageNumberLimit] = useState(0);
  const handleClick = (event) => {
    setCurrentPage(Number(event))
  }
  const handleprev = () => {
    setCurrentPage(currentPage - 1)
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxpageNumberLimit(maxpageNumberLimit - pageNumberLimit);
      setminpageNumberLimit(minpageNumberLimit - pageNumberLimit);
    }
  }
  const handlenext = () => {
    setCurrentPage(currentPage + 1)
    if (currentPage + 1 > maxpageNumberLimit) {
      setmaxpageNumberLimit(maxpageNumberLimit + pageNumberLimit);
      setminpageNumberLimit(minpageNumberLimit + pageNumberLimit);
    }
  }
  if (wallets) {
    length = wallets.length
    for (let i = 1; i <= Math.ceil(wallets.length / itemsPerPage); i++) {
      pages.push(i);
    }
    const indexOfLastItem = wallets.length > 17 ? currentPage * itemsPerPage : itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    if (wallets) {
      currentItems = wallets[0] ? wallets.slice(indexOfFirstItem, indexOfLastItem) : 0
    }
  }
  let pageIncrementbtn = null;
  if (pages.length > maxpageNumberLimit) {
    pageIncrementbtn =
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 30, borderWidth: 1, borderColor: 'black', borderRadius: 5, height: 40, justifyContent: 'center',
          alignItems: 'center'
        }} onPress={handlenext}>
          <Text style={{ fontWeight: 'bold' }}>....</Text>
        </TouchableOpacity>
      </View>
  }
  let pageDecrementbtn =
    null;
  if (minpageNumberLimit >= 1) {
    pageDecrementbtn =
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 30, borderWidth: 1, borderColor: 'black', borderRadius: 5, height: 40, justifyContent: 'center',
          alignItems: 'center'
        }} onPress={handleprev}>
          <Text style={{ fontWeight: 'bold' }}>....</Text>
        </TouchableOpacity>
      </View>

  }
  const renderPageNumber = pages.map((number) => {
    if (number < maxpageNumberLimit + 1 && number > minpageNumberLimit) {
      return (
        <View key={number} style={{ alignItems: 'center', margin: "0.5%" }}>
          <TouchableOpacity style={{
            backgroundColor: currentPage === number ? "#27B6CC" : null,
            width: 30, borderWidth: 1, borderColor: '#9ce5f0', borderRadius: 5, height: 40, justifyContent: 'center',
            alignItems: 'center'
          }} onPress={() => { handleClick(number) }}>
            <Text style={{ fontWeight: 'bold' }}>{number}</Text>
          </TouchableOpacity>
        </View>
      )
    }
    else {
      return null;
    }

  })
  const EmailID = useSelector(state => state.auth?.user);
  //API dispatch
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isEmpty(EmailID)) {
      let formData = {
        "Wallet_Owner_Id": EmailID?.Partner_Details_Id,
      };
      dispatch(getWalletHistory(formData))
    } else {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Home");
        }
      }, 1000)
    }
  }, [EmailID]);
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
      return new Promise(resolve => setTimeout(resolve, timeout));
  }

  const [load, setIsLoading] = React.useState(false);
  const refreshCall = () => {
    setIsLoading(true);
    let formData = {
      "Wallet_Owner_Id": EmailID?.Partner_Details_Id,
    };
    dispatch(getWalletHistory(formData))
  };
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall()
    wait(2000).then(() => setRefreshing(false));
}, []);

const customTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    text: esiColor.Text,
    placeholder: esiColor.TextPlaceholder
  },
};

const style = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: "bold",
    fontSize: 12,
    justifyContent: "center",
    textAlign: "center",
    width: 80
  },
  titletext: {
    fontWeight: "bold",
    fontSize: 14,
    color: esiColor.Text,
  },
  tablerow: {
    justifyContent: "center",
    textAlign: "center",
    width: 140
  },
  mainbox: {
    textAlign: 'center',
    margin: 15,
    flex: 1,
  },
  databeBox: {
    margin: 10,
    textAlign: 'center',
  },
  databeHeader: {
    margin: 10,
    textAlign: 'center',
    borderBottomColor: esiColor.SBorderColor,
  },
});

  return (
    <Surface style={{ flex: 1,backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} />
        <View style={{ flexDirection: 'row', margin: 2 }}>
          <IconButton
            icon="arrow-left"
            size={30}
            iconColor={esiColor.GBFColor}
            style={{ marginTop: -4 }}
            onPress={() => {
              navigation.navigate("WalletHistory");
            }} />
          <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor, marginTop: 5 }}>
            TRANSACTIONS
          </Text>
        </View>
        <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
          <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginTop: -10 }}>
            <View style={{ flex: 1, margin: 15 }}>
              <ScrollView horizontal={true}>

                <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                  <DataTable.Header>
                    <DataTable.Title style={[style.title]}><Text style={{ fontWeight: 'bold',color:esiColor.itemColor }}>Reason</Text></DataTable.Title>
                    <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold',color:esiColor.itemColor }}>Amount</Text></DataTable.Title>
                    <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold',color:esiColor.itemColor }}>Type</Text></DataTable.Title>
                    <DataTable.Title style={style.title}><Text style={{ fontWeight: 'bold',color:esiColor.itemColor }}>Status</Text></DataTable.Title>
                  </DataTable.Header>
                  {!isEmpty(wallets) ? (
                    currentItems == 0 ? <Text style={{ fontSize: 16, textAlign: 'center' ,color:esiColor.itemColor}}>
                      No records found.
                    </Text> : (
                      currentItems.map((wallet: any) => (
                        <DataTable.Row style={style.databeBox} key={wallet.Wallet_History_Id}>
                          <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{wallet.Transaction_Type === "DB" ? wallet.Debit_Reason : wallet.Credit_Reason}</Text></DataTable.Cell>
                          <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{wallet.Transaction_Type === "DB" ? wallet.Debited_Amount : wallet.Credited_Amount}{'/-'}</Text></DataTable.Cell>
                          <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{wallet.Transaction_Type}</Text></DataTable.Cell>
                          <DataTable.Cell style={style.tablerow}><Text style={{ color: wallet.Status === "Pending" ? "red" : "green", }}>{wallet.Status}</Text></DataTable.Cell>
                        </DataTable.Row>
                      ))
                    )) : <ActivityIndicator color='#27b6cc' animating={loading} style={{ marginTop: 1 }} />}
                </DataTable>
              </ScrollView>
            </View>
          </View>
          <View>
            {length > 16 ? (
              <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity disabled={currentPage === pages[0] ? true : false} onPress={handleprev}
                  style={{
                    width: 40, height: 40, justifyContent: 'center', alignItems: 'center',
                    backgroundColor: currentPage === pages[0] === true ? esiColor.itemColor : esiColor.globalButtonColor,
                    flexDirection: 'column', pointerEvents: currentPage === pages[0] ? "none" : null,
                    borderRadius: 5, borderColor: esiColor.borderColor, borderWidth: 1, opacity: currentPage === pages[0] ? "0.6" : null
                  }}>
                  <Text>Prev</Text>
                </TouchableOpacity>
                {pageDecrementbtn}
                {renderPageNumber}
                {pageIncrementbtn}
                <TouchableOpacity disabled={currentPage === pages[pages.length - 1] ? true : false} onPress={handlenext}
                  style={{
                    width: 40, height: 40, justifyContent: 'center', alignItems: 'center',
                    backgroundColor: currentPage === pages[pages.length - 1] === true ? esiColor.itemColor : esiColor.globalButtonColor,
                    flexDirection: 'column', borderRadius: 5, borderColor: esiColor.borderColor, borderWidth: 1,
                    pointerEvents: currentPage === pages[pages.length - 1] ? "none" : null,
                    opacity: currentPage === pages[pages.length - 1] ? "0.6" : null
                  }}>
                  <Text>Next</Text>
                </TouchableOpacity>
              </View>
            ) : (null)}
          </View>
        </ScrollView>
    </Surface>
  );
}

export default WalletHistory