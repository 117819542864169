import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { updateTransactionAction } from '../../state/actions/transactionhistoryAction';
import Header from '../auth/components/header';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';

//StatusOption is used to get the data from dropdown
const StatusOption = [
    {
        value: 'Processing',
        label: 'Processing'
    },
    {
        value: 'Hold',
        label: 'Hold'
    },
    {
        value: 'Pending',
        label: 'Pending'
    },
    {
        value: 'Success',
        label: 'Success'
    },
    {
        value: 'Failed',
        label: 'Failed'
    },
    {
        value: 'New',
        label: 'New'
    }
];

const EditTransactionhistory = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    //toast is for popup message after succesfull API call
    const toast = useToast();
    //isSubmitted contains the data from Update API using state call
    const isSubmited = useSelector(state => state.transaction.transactionUpdate.isLoading);
    //transaction is used to store the values from previous page using props
    const transaction = props.route?.params?.data;
    const EmailID = useSelector(state => state.auth?.user);

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Transaction_History === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);

    //Dropdowns
    const [statusDropDown, setStatusDropDown] = useState("");

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    /*====================================
            Handler
    ====================================*/

    const values = {
        Transaction_Id: transaction.Transaction_Id ? transaction.Transaction_Id : "",
        Transaction_Status: transaction.Transaction_Status ? transaction.Transaction_Status : "",
        Transaction_Date: transaction.Transaction_Date ? transaction.Transaction_Date : "",
        Transaction_Refernce: transaction.Transaction_Reference ? transaction.Transaction_Reference : "",
        Billing_Country: transaction.Billing_Country ? transaction.Billing_Country : "",
        Currency: transaction.Currency ? transaction.Currency : "",
        Order_Id: transaction.Order_Id ? transaction.Order_Id : "",
        Order_Amount: transaction.Order_Amount ? transaction.Order_Amount : "",
        Order_Status: transaction.Order_Status ? transaction.Order_Status : "",
        Payment_Gateway_Reference_Number: transaction.Payment_Gateway_Reference_Number ? transaction.Payment_Gateway_Reference_Number : "",
        Payment_Method: transaction.Payment_Methed ? transaction.Payment_Methed : "",
        Payment_Mode: transaction.Payment_Mode ? transaction.Payment_Mode : "",
        Payment_Gateway_Response_Code: transaction.Payment_Gateway_Response_Code ? transaction.Payment_Gateway_Response_Code : "",
        Payment_Gateway_Response_Message: transaction.Payment_Gateway_Response_Message ? transaction.Payment_Gateway_Response_Message : "",
        User_Adderss_Id: transaction.User_Address_Id ? transaction.User_Address_Id : "",
        User_Email_Id: transaction.User_Email_Id ? transaction.User_Email_Id : "",
        Wallet_Id: transaction.Wallet_Id ? transaction.Wallet_Id : "",
        Wallet_Amount: transaction.Wallet_Amount ? transaction.Wallet_Amount : "",
        Discount_Value: transaction.Discount_Value ? transaction.Discount_Value : "",
        ESI_Commission_Amount: transaction.Esi_Commission_Amount ? transaction.Esi_Commission_Amount : "",
        //Static data
        Modified_By: EmailID.Email_Id
    }
    //Form validation
    const Form_Validation = Yup.object().shape({
        Transaction_Status: Yup.string().required('Transaction Status is required.')
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(updateTransactionAction(formValues, transaction, navigation, toast));
        }
    }
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('TransactionhistoryGet')}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: '#D6D6D7'
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Edit Transaction History
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Transaction ID field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Transaction ID'
                                                mode='outlined'
                                                name="Transaction_Id"
                                                value={props.values.Transaction_Id}
                                                onChangeText={props.handleChange('Transaction_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Transaction_Date field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Transaction Date'
                                                mode='outlined'
                                                name="Transaction_Date"
                                                value={props.values.Transaction_Date}
                                                onChangeText={props.handleChange('Transaction_Date')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Transaction_Refernce field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Transaction Refernce'
                                                mode='outlined'
                                                name="Transaction_Refernce"
                                                value={props.values.Transaction_Refernce}
                                                onChangeText={props.handleChange('Transaction_Refernce')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Billing_Country field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Billing Country'
                                                mode='outlined'
                                                name="Billing_Country"
                                                value={props.values.Billing_Country}
                                                onChangeText={props.handleChange('Billing_Country')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Currency field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Currency'
                                                mode='outlined'
                                                name="Currency"
                                                value={props.values.Currency.toString()}
                                                onChangeText={props.handleChange('Currency')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order_Id field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Order ID'
                                                mode='outlined'
                                                name="Order_Id"
                                                value={props.values.Order_Id}
                                                onChangeText={props.handleChange('Order_Id')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order_Amount field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Order Amount'
                                                mode='outlined'
                                                name="Order_Amount"
                                                value={props.values.Order_Amount.toString()}
                                                onChangeText={props.handleChange('Order_Amount')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order_Status field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Order Status'
                                                mode='outlined'
                                                name="Order_Status"
                                                value={props.values.Order_Status}
                                                onChangeText={props.handleChange('Order_Status')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Payment_Gateway_Reference_Number field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Payment Gateway Reference Number'
                                                mode='outlined'
                                                name="Payment_Gateway_Reference_Number"
                                                value={props.values.Payment_Gateway_Reference_Number}
                                                onChangeText={props.handleChange('Payment_Gateway_Reference_Number')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Payment_Method field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Payment Method'
                                                mode='outlined'
                                                name="Payment_Method"
                                                value={props.values.Payment_Method}
                                                onChangeText={props.handleChange('Payment_Method')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Payment_Mode field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Payment Mode'
                                                mode='outlined'
                                                name="Payment_Mode"
                                                value={props.values.Payment_Mode}
                                                onChangeText={props.handleChange('Payment_Mode')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Payment_Gateway_Response_Code field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Payment Gateway Response Code'
                                                mode='outlined'
                                                name="Payment_Gateway_Response_Code"
                                                value={props.values.Payment_Gateway_Response_Code}
                                                onChangeText={props.handleChange('Payment_Gateway_Response_Code')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Payment_Gateway_Response_Message field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Payment Gateway Response Message'
                                                mode='outlined'
                                                name="Payment_Gateway_Response_Message"
                                                value={props.values.Payment_Gateway_Response_Message}
                                                onChangeText={props.handleChange('Payment_Gateway_Response_Message')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* User_Adderss_Id field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='User Adderss ID'
                                                mode='outlined'
                                                name="User_Adderss_Id"
                                                value={props.values.User_Adderss_Id}
                                                onChangeText={props.handleChange('User_Adderss_Id')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* User_Email_Id field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='User Email ID'
                                                mode='outlined'
                                                name="User_Email_Id"
                                                value={props.values.User_Email_Id}
                                                onChangeText={props.handleChange('User_Email_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Wallet_Id field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Wallet ID'
                                                mode='outlined'
                                                name="Wallet_Id"
                                                value={props.values.Wallet_Id}
                                                onChangeText={props.handleChange('Wallet_Id')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Wallet_Amount field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Wallet Amount'
                                                mode='outlined'
                                                name="Wallet_Amount"
                                                value={props.values.Wallet_Amount.toString()}
                                                onChangeText={props.handleChange('Wallet_Amount')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Discount_Value field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Discount Value'
                                                mode='outlined'
                                                name="Discount_Value"
                                                value={props.values.Discount_Value}
                                                onChangeText={props.handleChange('Discount_Value')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* ESI_Commission_Amount field with data*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='ESI Commission Amount'
                                                mode='outlined'
                                                name="ESI_Commission_Amount"
                                                value={props.values.ESI_Commission_Amount.toString()}
                                                onChangeText={props.handleChange('ESI_Commission_Amount')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 21 }}>
                                            {/* Transaction_Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Transaction_Status && props.errors.Transaction_Status) && { color: '#b00020' }]}>
                                                Transaction Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Transaction_Status && props.errors.Transaction_Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ? true : false}
                                                searchPlaceholder={'Select Transaction Status'}
                                                dropdownPosition="auto"
                                                value={props.values.Transaction_Status}
                                                name="Transaction_Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Transaction_Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Transaction_Status && props.errors.Transaction_Status)}>
                                                <ErrorMessage name="Transaction_Status" />
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};



const styles = StyleSheet.create({
    label: {
        marginBottom: 30,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        left: 22,
        top: 10,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});

export default EditTransactionhistory;