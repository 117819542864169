import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, Checkbox, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, Platform, RefreshControl } from 'react-native';
import { getdeliveryagentByIdAction, updateDeliveryAgentAction, uploadDeliveryAgentImage } from '../../state/actions/deliveryagentAction';
import Header from '../auth/components/header';
import EsiDatePicker from '../../components/custom/date/date';
import PhoneInput from 'react-native-phone-number-input';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import ImageUpload from '../../components/custom/image-upload/upload';
import isEmpty from '../../state/validations/is-empty';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { imageORfileupload } from '../../state/actions/commonImageOrFileUpload';
import { uriToBlob } from '../../components/custom/uriBlob';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const deliverypersionGender = [
  {
    value: 'Male',
    label: 'Male'
  },
  {
    value: 'Female',
    label: 'Female'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];


const EditDeliveryAgent = (props: any) => {
  const id = props.route?.params?.id;
  const { navigation, ...rest } = props;
  const esiColor = useSelector(state => state.theme);
  const dispatch = useDispatch();
  const isSubmited = useSelector(state => state.deliveryagent.deliveryagentUpdate.isLoading);

  const toast = useToast();
  // Role restriction
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [image, setImage] = useState({ preview: data?.Profile_Pic_Path, raw: data?.Profile_Pic_Path });
  const [imgUrl, setImgUrl] = useState(data?.Profile_Pic_Path);
  const [uploadPic, setUploadPic] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ Del_User_Email_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  // const loginEmail = useSelector(state => state.auth.user.Email_Id);
  const [typeDropDown, setTypeDropDown] = useState("");
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  const [data, setData] = useState([]);
  React.useEffect(() => {
    setSeconds(2);
    return () => {
    };
  }, []);
  const callBackData = (data: any) => {
    setData(data)
    setImgUrl(data[0].Profile_Pic_Path)
    setImage({
      preview: data[0].Profile_Pic_Path,
      raw: data[0].Profile_Pic_Path
    });
  }

  React.useEffect(() => {
    if (id) {
      let formData = {
        Del_User_Detail_ID: id,
        Records_Filter: "FILTER"
      };
      dispatch(getdeliveryagentByIdAction(formData, callBackData));
    }
  }, [id, seconds]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  //mobile number country code

  const callBackUploadImage = (data: any) => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };

  const handleImage = async (result: any) => {
    if (result) {
      let call = result.uri
      let uriParts = result.uri.split('.');
      let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
      let filformate = fileExtension[0].split(':')
      let file = filformate[1]?.split('/')
      let fileData = {
        File_name: "Delivery Agent",
        Functionality: "Delivery Agents",
        ext: file[1],
        File_Type: "Images",
      };
      const img = await fetchImageFromUri(call);
      setImage({
        preview: result.uri,
        raw: img
      });
      dispatch(
        imageORfileupload(img, fileData, callBackUploadImage)
      );
    }
  };

  const fetchImageFromUri = async (uri: any) => {
    const blob = await uriToBlob(uri);
    return blob;
  };

  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };

  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 16
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },

    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    phonetextInput: {
      flex: 1
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        {data?.length > 0 && seconds <= 0 &&
          <Formik
            enableReinitialize={true}
            initialValues={{
              Aadhar: data[0]?.Aadhar ? data[0]?.Aadhar : "",
              DOB: data[0]?.DOB ? data[0]?.DOB : "",
              Del_Total_Amount: data[0]?.Del_Total_Amount ? data[0]?.Del_Total_Amount : "",
              Del_User_Detail_Id: data[0]?.Del_User_Detail_Id ? data[0]?.Del_User_Detail_Id : "",
              Del_User_Email_Id: data[0]?.Del_User_Email_ID ? data[0]?.Del_User_Email_ID : "",
              Driving_Licence_Back_Image: imgUrl,
              Driving_Licence_Front_Image: imgUrl,
              First_Name: data[0]?.First_Name ? data[0]?.First_Name : "",
              Full_Name: data[0]?.Full_Name ? data[0]?.Full_Name : "",
              Gender: data[0]?.Gender ? data[0]?.Gender : "",
              Is_Active: data[0]?.Is_Active > 0 ? 1 : 0,
              Is_Approved: data[0]?.Is_Approved > 0 ? 1 : 0,
              Is_Email_Verified: data[0]?.Is_Email_Verified > 0 ? 1 : 0,
              Is_Mobile_Verified: data[0]?.Is_Mobile_Verified > 0 ? 1 : 0,
              Last_Name: data[0]?.Last_Name ? data[0]?.Last_Name : "",
              License: data[0]?.License ? data[0]?.License : "",
              Marketing_Agreed: data[0]?.Marketing_Agreed > 0 ? 1 : 0,
              Mobile: data[0]?.Mobile ? data[0]?.Mobile : "",
              Pan_Card_Image: imgUrl,
              Pan_Card_Number: data[0]?.Pan_Card_Number ? data[0]?.Pan_Card_Number : "",
              Partner_Id: data[0]?.Partner_ID ? data[0]?.Partner_ID : "",
              Password: data[0]?.Password ? data[0]?.Password : "",
              Profile_Pic_Path: imgUrl,
              Role: data[0]?.Role ? data[0]?.Role : "",
              T_and_C_Aggareed: data[0]?.T_And_C_Aggreed > 0 ? 1 : 0,
            }}
            validationSchema={Yup.object().shape({
              Del_User_Email_ID: Yup.string()
                .email("Please provide a valid Email ID.")
                .test('email-length', 'Email ID must be between 5 and 50 characters.', function (value) {
                  if (value) {
                    return value.length >= 5 && value.length <= 50;
                  }
                  return true;
                }),
              DOB: Yup.date().max(new Date(Date.now() - 567648000000), "you must be at least 18 years").required("Date Of Birth is required"),
              First_Name: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid First Name.').required('First Name is required.'),
              Last_Name: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Last Name.').required('Last Name is required.'),
              Gender: Yup.string().required('Gender is required.'),
              Mobile: Yup.string().required('Mobile Number is required.'),

            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              setSubmitting(false);
              if (values) {
                let formValues = JSON.parse(JSON.stringify(values));
                if (values.Marketing_Agreed) {
                  formValues.Marketing_Agreed = 1;
                } else {
                  formValues.Marketing_Agreed = 0;
                }
                if (values.T_and_C_Aggareed) {
                  formValues.T_and_C_Aggareed = 1;
                } else {
                  formValues.T_and_C_Aggareed = 0;
                }
                if (values.Is_Active) {
                  formValues.Is_Active = 1;
                } else {
                  formValues.Is_Active = 0;
                }
                if (values.Is_Email_Verified) {
                  formValues.Is_Email_Verified = 1;
                } else {
                  formValues.Is_Email_Verified = 0;
                }
                setSeconds(2);
                dispatch(updateDeliveryAgentAction(formValues, props.navigation, setErrorMessage, setErrors, setData, toast));
              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"

              }}>
                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm(),
                        setData([]),
                        navigation.navigate('DeliveryAgent')
                    }}
                  >
                    Go Back
                  </Button>
                </View>

                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Edit Delivery Agent
                    </Text>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        {/* Email ID input field with validation*/}
                        <TextInput

                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          label='Delivery Email ID'
                          mode='outlined'
                          name="Del_User_Email_Id"
                          value={values.Del_User_Email_Id}
                          onChangeText={handleChange('Del_User_Email_Id')}
                          error={Boolean(touched.Del_User_Email_Id && errors.Del_User_Email_Id)}
                        />
                        <HelperText type="error" visible={Boolean(touched.Del_User_Email_Id && errors.Del_User_Email_Id)}>
                          <ErrorMessage name="Del_User_Email_Id" />
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput

                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          label="Mobile Number"
                          mode='outlined'
                          onChangeText={handleChange('Mobile')}
                          left={<TextInput.Affix textStyle={{ color: esiColor.itemColor }} text="+91" />}
                          value={values.Mobile}
                        />
                        <HelperText type="error" visible={Boolean(touched.Mobile && errors.Mobile)}>
                          <ErrorMessage name="Mobile" />
                        </HelperText>
                      </View>
                    </View>
                    {/*Mobile number Field with Validation*/}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                        {/* First name input field with validation*/}
                        <TextInput

                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          label='First Name'
                          mode='outlined'
                          name="First_Name"
                          value={values.First_Name}
                          onChangeText={handleChange('First_Name')}
                          error={Boolean(touched.First_Name && errors.First_Name)}
                        />
                        <HelperText type="error" visible={Boolean(touched.First_Name && errors.First_Name)}>
                          <ErrorMessage name="First_Name" />
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                        {/* Last Name input field with validation*/}
                        <TextInput

                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          label='Last Name'
                          mode='outlined'
                          name="Last_Name"
                          value={values.Last_Name}
                          onChangeText={handleChange('Last_Name')}
                          error={Boolean(touched.Last_Name && errors.Last_Name)}
                        />
                        <HelperText type="error" visible={Boolean(touched.Last_Name && errors.Last_Name)}>
                          <ErrorMessage name="Last_Name" />
                        </HelperText>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                        <EsiDatePicker

                          label="Date of Birth"
                          style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                          value={values.DOB}
                          valueUpdate={date => {
                            setFieldValue('DOB', date)
                          }} />
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <Text style={[styles.dropdownlabel, Boolean(touched.Gender && errors.Gender) && { color: '#b00020' }]}>
                          Gender
                        </Text>
                        {/* Address Type input field with validation*/}
                        <EsiSearchDropdown
                          style={[styles.dropdown, Boolean(touched.Gender && errors.Gender) && { borderColor: '#b00020' }]}
                          placeholderStyle={styles.placeholderStyle}
                          selectedTextStyle={styles.selectedTextStyle}
                          inputSearchStyle={styles.inputSearchStyle}
                          iconStyle={styles.iconStyle}
                          data={deliverypersionGender}
                          labelField="label"
                          valueField="value"
                          maxHeight={deliverypersionGender.length > 1 ? 250 : 200}
                          search={deliverypersionGender.length > 1 ? true : false}
                          searchPlaceholder={'Select Gender'}
                          dropdownPosition="bottom"
                          value={values.Gender}
                          name="Gender"
                          onFocus={() => setTypeDropDown(true)}
                          onBlur={() => setTypeDropDown(false)}
                          onUpdateValue={item => {
                            setFieldValue('Gender', item.value)
                            setTypeDropDown(false);
                          }}
                        />
                        <HelperText type="error" visible={Boolean(touched.Gender && errors.Gender)}>
                          <ErrorMessage name="Gender" />
                        </HelperText>
                      </View>

                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    </View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", paddingTop: 15, paddingBottom: 15 }}>
                      <View style={{ flexShrink: 1, flexDirection: "row" }} >
                        <Checkbox.Android
                          color={esiColor.itemColor}
                          name="Is_Active"
                          status={values.Is_Active ? 'checked' : 'unchecked'}
                          onPress={() => {
                            setFieldValue("Is_Active", !values.Is_Active);
                          }}
                        />
                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>Is Active</Text>
                      </View>
                      {/* Marketing agreed checkbox field*/}
                      <View style={{ flexShrink: 1, flexDirection: "row" }} >
                        <Checkbox.Android
                          color={esiColor.itemColor}
                          name="Marketing_Agreed"
                          status={values.Marketing_Agreed ? 'checked' : 'unchecked'}
                          onPress={() => {
                            setFieldValue("Marketing_Agreed", !values.Marketing_Agreed);
                          }}
                        />
                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>Marketing Agreed</Text>
                      </View>

                      {/* T_and_C_aggreed checkbox field*/}
                      <View style={{ flexShrink: 1, flexDirection: "row" }} >
                        <Checkbox.Android
                          color={esiColor.itemColor}
                          name="T_and_C_Aggareed"
                          status={values.T_and_C_Aggareed ? 'checked' : 'unchecked'}
                          onPress={() => {
                            setFieldValue("T_and_C_Aggareed", !values.T_and_C_Aggareed);
                          }}
                        />
                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>T&C Agreed</Text>
                      </View>
                      <View style={{ flexShrink: 1, flexDirection: "row" }} >
                        <Checkbox.Android
                          color={esiColor.itemColor}
                          name="Is_Email_Verified"
                          status={values.Is_Email_Verified ? 'checked' : 'unchecked'}
                          onPress={() => {
                            setFieldValue("Is_Email_Verified", !values.Is_Email_Verified);
                          }}
                        />
                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>Is Email Verified</Text>
                      </View>
                    </View>
                    <View />
                    <View style={{ alignSelf: 'center', flexShrink: 1, width: 270, paddingTop: 20 }} >
                      <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                    </View>
                    <View>
                      <Text style={{ margin: 10, color: 'red' }}>{errorMessage.global.message}</Text>
                    </View>
                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", marginTop: 'auto', padding: 10 }}>

                      <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                        Save
                      </Button>
                    </View>
                  </Card.Content>
                </Card>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface >

  );
};

export default EditDeliveryAgent;