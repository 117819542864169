import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettingAction,getSettingByIdAction } from '../../state/actions/settingsAction';
import { Card, TextInput, Text, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView } from 'react-native';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import Header from '../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';

//StatusOption used to get the data from dropdown
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const EditSetting = (props: any) => {
    const ID = props.route?.params?.ID;
    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    //toast for popup after successful API calls.
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
      const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
      return () => clearTimeout(timer)
    }, [seconds]);
    React.useEffect(() => {
        if (!(permissions.Settings === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
        setSeconds(2);
    }, [permissions,ID]);
   

    //isSubmitted contains the data from Update API using state call
    const isSubmited = useSelector(state => state.setting.settingUpdate.isLoading);
    const EmailID = useSelector(state => state.auth?.user);
    
    //Dropdowns
    const [statusDropDown, setStatusDropDown] = useState("");

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //form reset
    const [reload, setReload] = React.useState(false);

    React.useEffect(() => {
        if (reload)
          setReload(false);
    }, [reload])
    const [data, setData] = useState([]);
    const callBackData = (data: any) => {
      setData(data)
    }
    React.useEffect(() => {
      if (ID) {
        let formData = {
          Setting_Id: ID,
          Records_Filter: "FILTER"
        }
        dispatch(getSettingByIdAction(formData, callBackData))
      }
    }, [ID]);
    /*====================================
            Handler
    ====================================*/

    const values = {
        Partner_Details_Id: data[0]?.Partner_Details_Id,
        Setting_Id: data[0]?.Setting_Id,
        Field_Name: data[0]?.Field_Name ? data[0]?.Field_Name : "",
        Field_Value: data[0]?.Field_Value ? data[0]?.Field_Value : "",
        Purpose: data[0]?.Purpose ? data[0]?.Purpose : "",
        Status: data[0]?.Status ? data[0]?.Status : "",
        Modified_By: EmailID?.Email_Id,  
    }

    //Form validation
    const Form_Validation = Yup.object().shape({
        Field_Value: Yup.string().required('Field Value is required.'),
        Purpose: Yup.string().required('Purpose is required.').max(45, 'Please provide below 45 characters.'),
        Status: Yup.string().required('Status is required.')
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);

        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(updateSettingAction(formValues, navigation, toast));
        }
    }

    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <ScrollView>
            {data.length > 0 && seconds <= 0 &&
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127}} 
                                  onPress={() => {
                                    props.resetForm();
                                    setReload(true);
                                    navigation.navigate('SettingsGet')}}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "65%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "10%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: '#D6D6D7'
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Edit Settings
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Field Name */}
                                            <TextInput
                                                style={{backgroundColor: 'white'}}
                                                theme={{ colors: {primary: '#27B6CC'}}}
                                                label='Field Name'
                                                mode='outlined'
                                                name="Field_Name"
                                                disabled
                                                value={props.values.Field_Name}
                                                onChangeText={props.handleChange('Field_Name')}
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Field Value input field with validation*/}
                                            <TextInput
                                                style={{backgroundColor: 'white'}}
                                                theme={{ colors: {primary: '#27B6CC'}}}
                                                label='Field Value'
                                                mode='outlined'
                                                name="Field_Value"
                                                placeholder="Value for Field Name"
                                                value={props.values.Field_Value}
                                                onChangeText={props.handleChange('Field_Value')}
                                                error={Boolean(props.touched.Field_Value && props.errors.Field_Value)}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Field_Value && props.errors.Field_Value)}>
                                                <ErrorMessage name="Field_Value" />
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Purpose input field with validation*/}
                                            <TextInput
                                                style={{backgroundColor: 'white'}}
                                                theme={{ colors: {primary: '#27B6CC'}}}
                                                label='Purpose'
                                                mode='outlined'
                                                name="Purpose"
                                                value={props.values.Purpose}
                                                onChangeText={props.handleChange('Purpose')}
                                                error={Boolean(props.touched.Purpose && props.errors.Purpose)}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Purpose && props.errors.Purpose)}>
                                                <ErrorMessage name="Purpose" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 21 }}>
                                            {/* Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                                                Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ?true:false}
                                                searchPlaceholder={'Select Status'}
                                                dropdownPosition="auto"
                                                value={props.values.Status}
                                                name="Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                                                <ErrorMessage name="Status" />
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button disabled={isSubmited} style={{ marginTop: "3%",backgroundColor:'#27B6CC' }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
}
            </ScrollView>
        </Surface>
    );
};

const styles = StyleSheet.create({
    label: {
        marginBottom: 30,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        left: 22,
        top: 10,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});

export default EditSetting;