import { EvilIcons } from '@expo/vector-icons';
import { DrawerActions, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Dimensions, Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import { Appbar, IconButton } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotification } from '../../../state/actions/notificationAction';
import { Audio } from 'expo-av';

const Header = ({ navigation, page }: { navigation: any, page: string }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userName = useSelector(state => state.auth?.user?.User_Name ? state.auth.user.User_Name : "");
  const userPic = useSelector(state => state.auth?.isAuthenticated ? state.auth?.user?.Profile_Pic_Path ? { uri: state.auth?.user?.Profile_Pic_Path } : require("../../../assets/images/icons/defaultlogo.png") : require("../../../assets/images/icons/defaultlogo.png"));
  React.useEffect(() => {
    return () => {
    };
  }, []);
  const EmailID = useSelector(state => state.auth?.user);
  const route = useRoute();
  const [notifications, setNotifications] = useState();
  const [totalcount, setTotalcount] = useState()
  const [notificationsound, setnotificationsound] = React.useState();
  const esiColor = useSelector(state => state.theme);

  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(
      require('../../../assets/notification.wav')
    );
    setnotificationsound(sound);
    await sound.playAsync();
  }
  React.useEffect(() => {
    return notificationsound
      ? () => {
        notificationsound.unloadAsync();
      }
      : undefined;
  }, [notificationsound]);
  const callBackData = (data: any) => {
    if (data.notification_count) {
      if (data.Sound === "1") {
        setNotifications(data.notification_count)
        setTotalcount(data.Total_Count)
        playSound()
      } else {
        setNotifications(data.notification_count)
        setTotalcount(data.Total_Count)
      }
    }
  }
  const [secondsP, setSecondsP] = React.useState(-2);

  const MINUTE_MS = 20000;
  useEffect(() => {
    const interval = setInterval(() => {
      if (EmailID) {
        let filter = {
          PARTNER_EMAIL_ID: EmailID?.Email_Id,
          Partner_Details_Id: EmailID?.Partner_Details_Id,
          Count: totalcount,
          Records_Filter: "FILTER"
        }
        dispatch(getAllNotification(filter, callBackData))
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [EmailID, totalcount])
  const _handleMore = () => {
    if (!(page == "auth"))
      navigation.dispatch(DrawerActions.openDrawer())
  };
  const _handleProfile = () => navigation.navigate('Services', { screen: 'PartnerProfile', params: { page: 'Services' } });
  const [dimensions, setDimensions] = useState(Dimensions.get('window').width);
  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  return (
    <Appbar.Header style={{
      height: 60, backgroundColor: esiColor.BackgroundColor, borderBottomColor: esiColor.SBorderColor,
      borderBottomWidth: 0.3, shadowColor: esiColor.brandShadowColor, shadowOpacity: 0.2, shadowRadius: 2,
      shadowOffset: { width: 1, height: 2 }, elevation: 5
    }}>

      {isAuthenticated ?
        <TouchableOpacity onPress={_handleMore}>
          <EvilIcons name="navicon" size={35} color={esiColor.SIconColor} style={{ paddingTop: 5, paddingLeft: 10 }} />
        </TouchableOpacity>
        : null}

      <View style={{ flex: 1, alignItems: 'flex-start', marginLeft: -15 }}>

        <Appbar.Action size={72} color={esiColor.DescColor} onPress={_handleMore} icon={require('../../../assets/images/img/logo/logo.png')} />
      </View>

      <Appbar.Content />

      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        {isAuthenticated ? <View>
          <IconButton
            icon="bell"
            color={esiColor.SIconColor}
            size={20}
            onPress={(e) => { navigation.navigate("Notification", { id: '3' }) }}
          />
          {notifications && notifications !== "0" ?
            <View
              style={{
                position: 'absolute',
                backgroundColor: 'red',
                width: 16,
                height: 16,
                borderRadius: 15 / 2,
                right: 5,
                top: 9,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Text
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: "#FFFFFF",
                  fontSize: 8,
                }}>
                {notifications > '99' ? '99+' : notifications}
              </Text>
            </View>
            : null}</View> : null}
        <View>
        </View>
      </View>
      {
        page === "Auth" ? <Appbar.Action icon={require('../../../assets/images/icons/user.png')} /> :

          <TouchableOpacity onPress={_handleProfile}>
            <Image source={userPic} style={{ height: 25, width: 25, borderRadius: 50, backgroundColor: 'white', marginRight: 15, marginTop: 2 }}></Image>
          </TouchableOpacity >

      }
    </Appbar.Header >
  );
};

export default Header;