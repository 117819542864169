import React, { useState } from 'react';
import { Card, Button, Checkbox, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, Image, ActivityIndicator, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import * as ImagePicker from 'expo-image-picker';
import * as Yup from 'yup';
import {
    createProductAction
} from '../../../../state/actions/farmer/farmerproductsAction';
import Header from '../../../auth/components/header';
import isEmpty from '../../../../state/validations/is-empty';
import SampleImage from '../../../../assets/custom-components/image-placeholder.png';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { multiImageOrFileUpload } from '../../../../state/actions/multiImageOrFileUpload';
import { uriToBlob } from '../../../../components/custom/uriBlob';
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddFarmerProductspagefour = (props: any) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const { navigation } = props;
    const EmailID = useSelector(state => state.auth?.user);
    const productmaster = useSelector(state => state.farmerproduct.productdata.products);
    const [globalError, setGlobalError] = useState();
    const esiColor = useSelector(state => state.theme);
    // for screen dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    // Multiple pic upload
    const [blobArrayPics, setBlobArrayPics] = useState([{
        key: 1, isdefault: 0, preview: null
    },
    {
        key: 2, isdefault: 0, preview: null
    },
    {
        key: 3, isdefault: 0, preview: null
    },
    {
        key: 4, isdefault: 0, preview: null
    },
    {
        key: 5, isdefault: 0, preview: null
    }]);
    const [imageError, setImageError] = useState();
    const callBackUploadImgae = (status: any, key: any) => {
        setIsPicUpload(false);
        if (status) {

            const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics));
            blobArrayPicsTemp[key - 1].preview = status.File_URL;
            if (key === 1) {
                blobArrayPicsTemp[key - 1].isdefault = 1;
            }
            setBlobArrayPics(blobArrayPicsTemp);
        }
        else {
            // setImageError("Unable to upload image please try again.");
        }
    };


    const [isPicUpload, setIsPicUpload] = useState(false);

    const pickImage = async (key: any) => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
        });
        // if image is not cancelled after upload then  responce will passing to the uploadPic
        let call = result?.assets[0]?.uri
        if (result) {
          let exe = "img";
          let uriParts = result?.assets[0]?.uri.split('.');
          if (Platform.OS == "web") {
            let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
            let filformate = fileExtension[0].split(':')
            exe = filformate[1].split('/')[1]
          } else {
            exe = uriParts[uriParts.length - 1]
          }
          let fileData = {
            File_name: "Products",
            Functionality: "Products",
            ext: exe,
            File_Type: "Images",
          };
          const img = await fetchImageFromUri(call);
          setIsPicUpload(true);
          dispatch(
            multiImageOrFileUpload(img, fileData, callBackUploadImgae,key)
          );
        }
    };
    const fetchImageFromUri = async (uri: any) => {
        const blob = await uriToBlob(uri);
        return blob;
    };
    function handleDefaultChange(status: any, key: any) {
        const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics));
        for (let i = 0; i < blobArrayPicsTemp.length; i++) {
            if (status && key === blobArrayPicsTemp[i].key) {
                blobArrayPicsTemp[i].isdefault = 1;
            } else {
                blobArrayPicsTemp[i].isdefault = 0;
            }
        }
        setBlobArrayPics(blobArrayPicsTemp);
    };

    function handleRemoveImage(key: any) {
        const blobArrayPicsTemp = JSON.parse(JSON.stringify(blobArrayPics));
        let isDefault = false;
        blobArrayPicsTemp[key - 1].blob = null;
        blobArrayPicsTemp[key - 1].preview = null;
        if (blobArrayPics[key - 1].isdefault) {
            isDefault = true
        }
        for (let i = key; i < 5; i++) {
            let a = blobArrayPicsTemp[i - 1];
            let b = blobArrayPicsTemp[i];
            b.key = i;
            a.key = i + 1;
            blobArrayPicsTemp[i - 1] = b;
            blobArrayPicsTemp[i] = a;
        }
        if (isDefault) {
            blobArrayPicsTemp[0].isdefault = 1;
        }
        setBlobArrayPics(blobArrayPicsTemp);
    };

    React.useEffect(() => {
        if (isEmpty(productmaster)) {
            navigation.navigate('AddFarmerProducts')
        }
    }, [productmaster]
    );
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        container: {
            paddingTop: 15
        }, images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },
    
        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        phonetextInput: {
            flex: 1
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });
    return (
        <Surface style={{ flexShrink: 1,backgroundColor: esiColor.BackgroundColor }} >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }
            >
                <Formik
                    initialValues={{
                        Brand_Id: productmaster.Brand_Id,
                        Category_Id: productmaster.Category_Id,
                        Country_Of_Origin: productmaster.Country_Of_Origin,
                        Created_User_Type: EmailID.User_Type,
                        Esi_Rating: productmaster.Esi_Rating,
                        Esi_Review: productmaster.Esi_Review,
                        Features: productmaster.Features,
                        Is_Popular: false,
                        Is_Top_Product: false,
                        Is_Upcoming: false,
                        Is_Verified: false,
                        Product_Color_Variant_Id: productmaster.Product_Color_Variant_Id,
                        Product_Description: productmaster.Product_Description,
                        Product_Master_Id: productmaster.Product_Master_Id,
                        Product_Name: productmaster.Product_Name,
                        Product_Price: productmaster.Product_Price,
                        Product_Short_Description: productmaster.Product_Short_Description,
                        Product_Size_Id: productmaster.Product_Size_Id,
                        Specifications: productmaster.Specifications,
                        Created_By_Email: EmailID.Email_Id,
                        Partner_Details_ID: EmailID.Partner_Details_Id,
                        Status: "In-Active",
                        Sub_Category_Id: productmaster.Sub_Category_Id,
                        Whats_Hot: false,
                        // Warranty_Period: productmaster.Warranty_Period,
                        Trending_Today: false,
                        Today_Recommendations: false,
                        Product_For:'FARMER'

                    }}
                    //field validations
                    validationSchema={Yup.object().shape({
                        Product_Description: Yup.string().required('Please provide Description.'),
                        Features: Yup.string().required('Please provide Features.'),
                        Specifications: Yup.string().required('Please provide Specifications.'),
                    })}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        setErrors(errors);
                        // if (blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0) {
                        //     submitTest = false;
                        //     setImageError("Image is required.");
                        // }
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            if (values.Is_Popular) {
                                formValues.Is_Popular = 1;
                            } else {
                                formValues.Is_Popular = 0;
                            }
                            if (values.Is_Upcoming) {
                                formValues.Is_Upcoming = 1;
                            } else {
                                formValues.Is_Upcoming = 0;
                            }
                            if (values.Whats_Hot) {
                                formValues.Whats_Hot = 1;
                            } else {
                                formValues.Whats_Hot = 0;
                            }
                            if (values.Is_Top_Product) {
                                formValues.Is_Top_Product = 1;
                            } else {
                                formValues.Is_Top_Product = 0;
                            }
                            if (values.Is_Verified) {
                                formValues.Is_Verified = 1;
                            } else {
                                formValues.Is_Verified = 0;
                            }
                            if (values.Trending_Today) {
                                formValues.Trending_Today = 1;
                            } else {
                                formValues.Trending_Today = 0;
                            }
                            if (values.Today_Recommendations) {
                                formValues.Today_Recommendations = 1;
                            } else {
                                formValues.Today_Recommendations = 0;
                            }
                            if (submitTest) { dispatch(createProductAction(formValues, blobArrayPics, navigation, setErrors, setGlobalError, toast,'1')); }
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                    }) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('AddFarmerProductspagethree')} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{ 
                                 width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                 marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                 backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                             }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold",color: esiColor.brandFontColor }}>
                                        Add Product
                                    </Text>
                                    {/* Check Boxes */}
                                    <View style={{ flexDirection: "row", paddingTop: 10, flexWrap: "wrap", justifyContent: "space-between" }}>
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color={esiColor.itemColor}
                                                name="Trending_Today"
                                                status={values.Trending_Today ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Trending_Today", !values.Trending_Today);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10 ,color : esiColor.itemColor }}>Trending Today</Text>
                                        </View>
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color={esiColor.itemColor}
                                                name="Today_Recommendations"
                                                status={values.Today_Recommendations ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Today_Recommendations", !values.Today_Recommendations);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10,color : esiColor.itemColor }}>Today Recommendations</Text>
                                        </View>
                                    </View>
                                    {/* image container */}
                                    <View style={{ flexShrink: 1, paddingTop: 20, marginHorizontal: 20 }} >
                                        {blobArrayPics.filter(item => {
                                            if (item.key == 1) {
                                                return true;
                                            } else {
                                                return blobArrayPics[item.key - 2].preview != null;
                                            }
                                        }).map(option => (
                                            <Card style={{ marginBottom: 5, marginTop: 5 }} key={"image-card" + option.key}>
                                                <Card.Content>
                                                    <View display="flex" justifyContent="center">
                                                        {option.preview ? (
                                                            <Image
                                                                source={{ uri: option.preview }}
                                                                alt="Upload"
                                                                style={styles.images}
                                                            ></Image>
                                                        ) : (
                                                            <>
                                                                <Image
                                                                    source={SampleImage}
                                                                    alt="Upload"
                                                                    style={styles.images}
                                                                ></Image>
                                                            </>
                                                        )}
                                                    </View>
                                                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                        {option.preview ? <View><View style={{ flexShrink: 1, flexDirection: "row" }} >
                                                            <Checkbox.Android
                                                                status={option.isdefault === 1 ? 'checked' : 'unchecked'}
                                                                onPress={(status) => handleDefaultChange(status, option.key)}
                                                                name="Is_Default"
                                                                disabled={option.isdefault === 1 ? true : false}
                                                            />
                                                            <Text style={{ color: option.isdefault === 1 ? 'grey' : 'black', marginTop: 10 }}>Is Default</Text>
                                                        </View>
                                                            <Button style={{ fontSize: 10, color: ' #425A70 ' }} onPress={() => handleRemoveImage(option.key)} >
                                                                <Text style={{ color: esiColor.itemColor }}>REMOVE PICTURE</Text>
                                                            </Button>
                                                        </View> : <Button style={{ fontSize: 10, color: ' #425A70 ' }} disabled={isPicUpload} uppercase={false} title="Choose Photo"accessibilityState={{ disabled: true }} onPress={() => pickImage(option.key)} >{isPicUpload ? <ActivityIndicator style={{ marginTop: 5 }} animating /> : <Text style={{ color: '#27B6CC' }}>CHOOSE FILE</Text>} </Button>}
                                                        <Text style={{ color: "red" }}>{blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0 && imageError}</Text>
                                                    </View>
                                                </Card.Content>
                                            </Card>
                                        ))}
                                    </View>
                                </Card.Content>
                                <View style={{ alignSelf: 'center', margin: 5 }}><Text style={{ color: 'red' }}>{globalError}</Text></View>
                                <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", justifyContent: 'center', paddingBottom: 15 }}>
                                    <Button textColor={esiColor.itemButtenColor}style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                        Save
                                    </Button>
                                </View>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default AddFarmerProductspagefour;