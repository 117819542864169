import Base64 from "../../../hooks/Base64";
import getEnvVars from '../../../../environment';
const { BOOMI_HOST,BOOMI_HOST_PAR, BOOMI_HOST_DEL,NODE_HOST_PAR, NODE_HOST, USERNAME_BOOMI, PASSWORD_BOOMI, USERNAME_NODE, PASSWORD_NODE,NODE_USER_HOST,USERNAME_USER_NODE,PASSWORD_USER_NODE } = getEnvVars();

const shopperconfig = {
// url
url: BOOMI_HOST,
newurl:BOOMI_HOST_PAR,
nodeurl: NODE_HOST+"/Products",
nodePartnerUrl:NODE_HOST_PAR,


// auth
auth: {
username: USERNAME_BOOMI,
password: PASSWORD_BOOMI,
},



// nodeauth
nodeauth: {
username: USERNAME_NODE,
password: PASSWORD_NODE,
},

// headersCommon
headersCommon: {
"Content-Type": "application/json",
// "Access-Control-Allow-Origin": "*", 
 'Authorization': "Basic " + Base64.btoa(USERNAME_BOOMI + ":" + PASSWORD_BOOMI),
// 'x-api-key': "e302e2a2-2e81-46a3-b4bf-986c4fc8eb12"
},
//nodeheaders
nodeheadersCommon: {
"Content-Type": "application/json",
// "Access-Control-Allow-Origin": "*",
'Authorization': "Basic "+Base64.btoa(USERNAME_NODE+":"+PASSWORD_NODE)
}
};

export default shopperconfig;