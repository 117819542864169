import axios from 'axios';
import config from '../config';
import shopperconfig from '../shoppers/shopperconfig';
import { meeapi } from '../node-actions/meeapi';
export const PARTNERSERVICES_REQUEST = "PARTNERSERVICES_REQUEST";
export const PARTNERSERVICES_SUCCESS = "PARTNERSERVICES_SUCCESS";
export const PARTNERSERVICES_FAIL = "PARTNERSERVICES_FAIL";
export const PARTNERSERVICES_GET_REQUEST = "PARTNERSERVICES_GET_REQUEST";
export const PARTNERSERVICES_GET_SUCCESS = "PARTNERSERVICES_GET_SUCCESS";
export const PARTNERSERVICES_GET_FAIL = "PARTNERSERVICES_GET_FAIL";
export const PARTNERSERVICES_UPDATE_REQUEST = "PARTNERSERVICES_UPDATE_REQUEST";
export const PARTNERSERVICES_UPDATE_SUCCESS = "PARTNERSERVICES_UPDATE_SUCCESS";
export const PARTNERSERVICES_UPDATE_FAIL = "PARTNERSERVICES_UPDATE_FAIL";
export const PARTNERSERVICES_CREATE_REQUEST = "PARTNERSERVICES_CREATE_REQUEST";
export const PARTNERSERVICES_CREATE_SUCCESS = "PARTNERSERVICES_CREATE_SUCCESS";
export const PARTNERSERVICES_CREATE_FAIL = "PARTNERSERVICES_CREATE_FAIL";
export const PARTNERSERVICES_FILTER = "PARTNERSERVICES_FILTER";
export const PARTNERSERVICES_DATA = "PARTNERSERVICES_DATA"
const nodeheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
export const partnerserviceData = (value: any) => {
  return function (dispatch) {
    dispatch({
      type: PARTNERSERVICES_DATA,
      value: value,
    });
  }
}

const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
/*=============================================================
                Get All User Services  Action
===============================================================*/


export const getAllpartnerervicesAction = (formData: any, pagination = {}) => async dispatch => {

  try {
    dispatch({
      type: PARTNERSERVICES_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Service/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Success_Response.Is_Data_Exist === '1') {
        dispatch({
          type: PARTNERSERVICES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: PARTNERSERVICES_FAIL,
          payload: data
        });

      }
    })
  } catch (err) {
    dispatch({
      type: PARTNERSERVICES_FAIL,
      payload: err
    });
  }
};
export const createPartnerAction = (formData: any, navigation: any, toast: any, setErrorMessage: any, setErrors: any) => async dispatch => {
  dispatch({
    type: PARTNERSERVICES_CREATE_REQUEST
  });
  setErrorMessage({ Resource_Email_Id: { key: '', message: '' }, Service_Type: { key: '', message: '' }, global: { key: '', message: '' } });
  axios.post(`${config.nodecudurl}/Partner_Service/Create`, formData,
    {
      headers: nodeheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNERSERVICES_CREATE_SUCCESS
        });
        navigation.navigate('Partnerservice')
        toast.show({ message: 'Partner Service successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          Partner_Detail_Id: formData.Partner_Detail_Id
        };
        dispatch(getAllpartnerervicesAction(formValue))
      } else {
        const errors = {};
        if (successResponse.Resource_Email_Id) {
          errors.Resource_Email_Id = successResponse.UI_Display_Message;

        }
        setErrors(errors)
        setErrorMessage({ Resource_Email_Id: { key: successResponse.Resource_Email_Id ? successResponse.Resource_Email_Id : '', message: successResponse.Resource_Email_Id ? successResponse.UI_Display_Message : '' }, Service_Type: { key: successResponse.Service_Type ? successResponse.Service_Type : '', message: successResponse.Service_Type ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Resource_Email_Id) ? successResponse.UI_Display_Message : '' } });

        dispatch({
          type: PARTNERSERVICES_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Resource_Email_Id: { key: '', message: '' }, Service_Type: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: PARTNERSERVICES_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
/*=============================================================
                Update User Services Action
===============================================================*/

export const updatePartnerAction = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: PARTNERSERVICES_UPDATE_REQUEST
  });
  axios.post(`${config.nodecudurl}/Partner_Service/Update`, formData,
    {
      headers: nodeheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNERSERVICES_UPDATE_SUCCESS
        });
        navigation.navigate('Partnerservice')
        toast.show({ message: 'Partner Services successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Partner_Detail_Id: formData.Partner_Detail_Id,
          Partner_Service_Id: "",
          Records_Filter: "FILTER",
          search: "",
          search_by_filter: "All"
        };
        dispatch(getAllpartnerervicesAction(formValue))
      } else {
        const errors = {};
        dispatch({
          type: PARTNERSERVICES_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNERSERVICES_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
export const partnerserviceFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: PARTNERSERVICES_FILTER,
      fType: fType,
      value: value,
    });
  }
}

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: PARTNERSERVICES_FILTER,
      fType: "search",
      value: "",
    });
  }
}


export const getPartnerserviceByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    dispatch({
      type: PARTNERSERVICES_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Service/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)
    }
  } catch (err) {
    dispatch({
      type: PARTNERSERVICES_FAIL,
      payload: err
    });
  }
};



export const uploadPartnerImage = (
  formData: any,
  callBackUploadImgae: any
) => async dispatch => {
  try {

    let { data } = await axios.put(
      `${config.url}/PartnerService_Image/Image?filename=Partner_Service&file_extension=png&filetype=image`,
      formData,
      {
        headers: headers,
        transformRequest: (d) => d,
      }
    );

    if (data) {
      callBackUploadImgae(data);
    }
  } catch (err) {
  }
};

export const bussinessubtypeAction = (formData: any, callBacksubtypeData: any) => async dispatch => {
  try {

    let { data, status } = await axios.post(

      `${shopperconfig.nodePartnerUrl}/Partner_Registraton/Partner_Business_Type_Get`,

      formData,

      {

        headers: nodeheaders

      }

    );

    if (data) {
      callBacksubtypeData(data.results)

    }

  } catch (err) {

    dispatch({

      payload: err

    });

  }

};