import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import EsiCheckboxTree from '../../../components/custom/checkbox-tree';
import { changePriceData } from '../../../state/actions/shoppers/filterServiceActions';


export default function SortByPrice(props) {
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    let sortByOptionsInit = useSelector((state) => state.filters.price);

    const checkChildChange = (data: any, status: string) => {
        let key = data.key;
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        response.filter((item: any) => { return item.key === key })[0].children.map((item: any) => {
            if (status === 'unchecked' || status === 'indeterminate') {
                item.checked = "checked";
            } else {
                item.checked = "unchecked";
            }
        })
        dispatch(changePriceData(response));
    }

    const changeChecked = (data: any, status: string) => {
        let key = data.key;
        let keys = key.split('-');
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        response.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
        response.filter((item: any) => { return !(item.key === key) }).map((item) => {
            item.checked = 'unchecked';
        })

        dispatch(changePriceData(response));
    }

    const updateOptions = ((data: any) => {
        dispatch(changePriceData(data));
    })
    return (
        <View style={{
            backgroundColor: esiColor.BackgroundColor
        }}>
            <View style={{ marginLeft: 10, flexDirection: 'row' }}>
                <Text style={{ fontSize: 14, fontWeight: "bold", color: esiColor.itemColor }}>Price</Text>
            </View>
            <EsiCheckboxTree
                sortByOptionsInit={sortByOptionsInit}
                updateOptions={updateOptions}
                checkChildChange={checkChildChange}
                changeChecked={changeChecked}
                {...props} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {

    }
});
