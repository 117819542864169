import {
    BOOKED_REQUEST,
    BOOKED_SUCCESS,
    BOOKED_FAIL,

    SINGLE_BOOKED_REQUEST,
    SINGLE_BOOKED_SUCCESS,
    SINGLE_BOOKED_FAIL,

    BOOKED_CREATE_REQUEST,
    BOOKED_CREATE_SUCCESS,
    BOOKED_CREATE_FAIL,
    BOOKED_UPDATE_REQUEST,
    BOOKED_UPDATE_SUCCESS,
    BOOKED_UPDATE_FAIL,
    BOOKED_FILTER,
} from '../../actions/Properties/bookedActions'

const initialState = {
    bookings: { all: [], error: '', isLoading: false },
    booking: { booking: {}, error: '', isLoading: false },
    bookingCreate: { booking: {}, error: '', isLoading: false },
    bookingUpdate: { booking: {}, error: '', isLoading: false },
    bookingdata: { bookingdata: {}, error: '', isLoading: false },
    bookingFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case BOOKED_REQUEST:
            return { ...state, bookings: { all: state.bookings.all, error: '', isLoading: true } };
        case BOOKED_SUCCESS:
            return { ...state, bookings: { all: action.payload, error: '', isLoading: false } };
        case BOOKED_FAIL:
            return { ...state, bookings: { all: [], error: action.payload, isLoading: false } };

        case SINGLE_BOOKED_REQUEST:
            return { ...state, booking: { booking: {}, error: '', isLoading: true } };
        case SINGLE_BOOKED_SUCCESS:
            return { ...state, bookings: { booking: action.payload, error: '', isLoading: false } };
        case SINGLE_BOOKED_FAIL:
            return { ...state, booking: { booking: {}, error: action.payload, isLoading: false } };

        case BOOKED_CREATE_REQUEST:
            return { ...state, bookingCreate: { booking: {}, error: '', isLoading: true } };
        case BOOKED_CREATE_SUCCESS:
            return { ...state, bookingCreate: { booking: state.bookingCreate.booking, error: '', isLoading: false } };
        case BOOKED_CREATE_FAIL:
            return { ...state, bookingCreate: { booking: {}, error: action.payload, isLoading: false } };

        case BOOKED_UPDATE_REQUEST:
            return { ...state, bookingUpdate: { booking: {}, error: '', isLoading: true } };
        case BOOKED_UPDATE_SUCCESS:
            return { ...state, bookingUpdate: { booking: state.bookingUpdate.booking, error: '', isLoading: false } };
        case BOOKED_UPDATE_FAIL:
            return { ...state, bookingUpdate: { booking: {}, error: action.payload, isLoading: false } };
        case BOOKED_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, bookingFilter: { search: state.bookingFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, bookingFilter: { select: state.bookingFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}