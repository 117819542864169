import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import { Avatar, Card, Text } from "react-native-paper";
import { useDispatch, useSelector } from 'react-redux';
import { Dimensions, Platform, View } from 'react-native';
import { dashboardprofitCount } from '../../../../state/actions/reports/dashboardActions';
import Ionicons from '@expo/vector-icons/Ionicons';
import { FontAwesome } from '@expo/vector-icons';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

function TotalProfit({ navigate, esiColor }) {
    //API dispatch
    const dispatch = useDispatch();
    //dashprofit contains the data from  GET API using state call
    const dashprofit = useSelector(state => state.dashboard?.Profit?.data);
    const EmailID = useSelector(state => state.auth?.user);
    //Static formData
    useEffect(() => {
        let formData = {
            "Date": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        dispatch(dashboardprofitCount(formData))
    }, [EmailID]);

    return (
        <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', padding: 5 }}>
            <Card style={{
                borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 10, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                shadowOffset: { width: 1, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, elevation: 5, marginLeft: 10, marginRight: 10,
                maxWidth: Platform.OS === "ios" ? windowWidth > 300 ? 450 : 450 : 400, minWidth: Platform.OS === "ios" ? 350 : 320
            }}>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -70 }}>
                    <Avatar.Icon size={50} icon="currency-usd" color="white" style={{ backgroundColor: 'blue' }} />
                </View>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                    <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.brandFontColor }}>TOTAL PROFIT</Text>
                    <Text style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: esiColor?.Text }}>
                        <FontAwesome name="rupee" size={18} color={esiColor?.SIconColor} />{dashprofit.Total ? Math.round(dashprofit.Total * Math.pow(10, 2)) / Math.pow(10, 2) : 0}
                    </Text>
                </View>
                <View style={{ flexDirection: 'row', margin: 10 }}>
                    {dashprofit.Month - dashprofit.Avg >= 0 &&
                        <>
                            <Ionicons name="arrow-up" size={25} color="green" />
                            <View style={{ flexDirection: 'column', margin: 5 }}>
                                <Text style={{ color: esiColor?.Text }}>
                                    {dashprofit.Month ? Math.round(dashprofit.Month * Math.pow(10, 2)) / Math.pow(10, 2) : 0}
                                </Text>
                            </View>
                        </>
                    }
                    {dashprofit.Month - dashprofit.Avg < 0 &&
                        <>
                            <Ionicons name="arrow-down" size={25} color="red" />
                            <View style={{ flexDirection: 'column', margin: 5 }}>
                                <Text style={{ color: esiColor?.Text }}>
                                    {dashprofit.Month ? Math.round(dashprofit.Month * Math.pow(10, 2)) / Math.pow(10, 2) : 0}
                                </Text>
                            </View>
                        </>
                    }

                    <View style={{ flexDirection: 'column', margin: 5 }}>
                        <Text style={{ color: esiColor?.Text }}>
                            Since last 30 days
                        </Text>
                    </View>

                </View>
            </Card>
        </View>
    );
}


export default TotalProfit;