import {
    ALL_TICKET_REQUEST,
    ALL_TICKET_SUCCESS,
    ALL_TICKET_FAIL,

    TICKET_REQUEST,
    TICKET_SUCCESS,
    TICKET_FAIL,

    ALL_TICKET_FILTER,

    ALL_COMMENTS_REQUEST,
    ALL_COMMENTS_SUCCESS,
    ALL_COMMENTS_FAIL,

    COMMENTS_REQUEST,
    COMMENTS_SUCCESS,
    COMMENTS_FAIL,

    TICKET_CREATE_REQUEST,
    TICKET_CREATE_SUCCESS,
    TICKET_CREATE_FAIL,

    COMMENTS_CREATE_REQUEST,
    COMMENTS_CREATE_SUCCESS,
    COMMENTS_CREATE_FAIL,
} from '../actions/ticketingActions';


const initialState = {
    Tickets: { all: [], error: '', isLoading: false },
    Ticket: { Ticket: [], error: '', isLoading: false },
    ticketFilter: { select: 'All', search: '' },
    Comments: { all: [], error: '', isLoading: false },
    Comment: { Comment: [], error: '', isLoading: false },
    TicketCreate: { Ticket: {}, error: '', isLoading: false },
    CommentCreate: { Comment: {}, error: '', isLoading: false },
};

export default function ticketingReducer(state = initialState, action) {
    switch (action.type) {

        case TICKET_CREATE_REQUEST:
            return { ...state, TicketCreate: { Ticket: {}, error: '', isLoading: true } };
        case TICKET_CREATE_SUCCESS:
            return { ...state, TicketCreate: { Ticket: action.payload, error: '', isLoading: false } };
        case TICKET_CREATE_FAIL:
            return { ...state, TicketCreate: { Ticket: {}, error: action.payload, isLoading: false } };

            case COMMENTS_CREATE_REQUEST:
                return { ...state, CommentCreate: { Comment: {}, error: '', isLoading: true } };
            case COMMENTS_CREATE_SUCCESS:
                return { ...state, CommentCreate: { Comment: action.payload, error: '', isLoading: false } };
            case COMMENTS_CREATE_FAIL:
                return { ...state, CommentCreate: { Comment: {}, error: action.payload, isLoading: false } };

        case ALL_COMMENTS_REQUEST:
            return { ...state, Comments: { all: state.Comments.all, error: '', isLoading: true }, };
        case ALL_COMMENTS_SUCCESS:
            return { ...state, Comments: { all: action.payload, error: '', isLoading: false } };
        case ALL_COMMENTS_FAIL:
            return { ...state, Comments: { all: [], error: action.payload, isLoading: false } };
        case COMMENTS_REQUEST:
            return { ...state, Comment: { all: state.Comment.all, error: '', isLoading: true }, };
        case COMMENTS_SUCCESS:
            return { ...state, Comment: { all: action.payload, error: '', isLoading: false } };
        case COMMENTS_FAIL:
            return { ...state, Comment: { all: [], error: action.payload, isLoading: false } };

        case ALL_TICKET_REQUEST:
            return { ...state, Tickets: { all: state.Tickets.all, error: '', isLoading: true } };
        case ALL_TICKET_SUCCESS:
            return { ...state, Tickets: { all: action.payload, error: '', isLoading: false } };
        case ALL_TICKET_FAIL:
            return { ...state, Tickets: { all: [], error: action.payload, isLoading: false } };
        case TICKET_REQUEST:
            return { ...state, Ticket: { all: state.Ticket.all, error: '', isLoading: true } };
        case TICKET_SUCCESS:
            return { ...state, Ticket: { all: action.payload, error: '', isLoading: false } };
        case TICKET_FAIL:
            return { ...state, Ticket: { all: [], error: action.payload, isLoading: false } };

        case  ALL_TICKET_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, ticketFilter: { search: state.ticketFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, ticketFilter: { select: state.ticketFilter.select, search: action.value } };
            }
            return res;
        default:
            return state;
    }
}