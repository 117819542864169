import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, Image, Platform, StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import { ActivityIndicator, Appbar, Avatar, Badge, Searchbar, Surface } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { Entypo, Ionicons } from '@expo/vector-icons';
import { search_products_new } from '../../../state/actions/shoppers/globalSearch';
import { getAllNotification } from '../../../state/actions/notificationAction';
import { Audio } from 'expo-av';
import * as Notifications from 'expo-notifications';
import { getCart } from '../../../state/actions/shoppers/shoppercartActions';

const window = Dimensions.get("window");

const Header = (props) => {
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userName = useSelector(state => state.auth?.user?.User_Name ? state.auth.user.User_Name : "");
  const userPic = useSelector(state => state.auth?.isAuthenticated ? state.auth?.user?.Profile_Pic_Path ? { uri: state.auth?.user?.Profile_Pic_Path } : require("../../../assets/images/icons/defaultlogo.png") : require("../../../assets/images/icons/defaultlogo.png"));
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  const _handleProfile = () => navigation.navigate('B2C', { screen: 'PartnerProfile' });
  const [showResent, setShowResent] = React.useState(true);
  const [seconds, setSeconds] = React.useState(Platform.OS === "web" ? 0 : 0);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  React.useEffect(() => {
    if (lastNotificationResponse &&
      lastNotificationResponse.notification.request.content.data.url
      && lastNotificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER) {
      navigation.navigate(lastNotificationResponse.notification.request.content.data.url);
    }
  }, [lastNotificationResponse]);
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window);
      }
    );
  });
  const gotoPage = (item) => {
    if (Platform.OS === "web") {
      setSearchProduts([]);
    }
    setSearchText("");

    switch (item.type) {
      case 'product':
        props.navigation.navigate('ProductDetails', { Product_Id: item?.id, Partner_Details_Id: item?.Partner_Details_Id })
        break;
      case 'brand':
        props.navigation.navigate('Brandsb2b', { Brand_Id: item?.id })
        break;
      case 'partner':
        props.navigation.navigate('Partnersb2b', { Partner_Detail_Id: item?.id })
        break;
      default:
        break;
    }
  }
  const cart = useSelector((state) => state.cart.cart);
  const EmailID = useSelector(state => state.auth?.user);
  const _esiLogo = () => {
    navigation.navigate('Home')
  }
  const searchbarOnBlur = () => {
    setTimeout(() => {
      setShowResent(true);
    }, 300);
  }


  const goToTop = () => {
    scroll.scrollTo({ x: 0, y: 0, animated: true });
  }
  const textInput = useRef();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchProduts, setSearchProduts] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const onSearch = async (text) => {
    setSearchText(text);
  }

  const responseData = (data) => {
    setSearchProduts(data);
  }
  useEffect(() => {
    dispatch(getCart({ Buyer_Partner_Details_ID: EmailID.Partner_Details_Id }))
  }, []);

  const [notificationsound, setnotificationsound] = React.useState();
  const [notifications, setNotifications] = useState();
  const [totalcount, setTotalcount] = useState()
  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(
      require('../../../assets/notification.wav')
    );
    setnotificationsound(sound);
    await sound.playAsync();
  }
  React.useEffect(() => {
    return notificationsound
      ? () => {
        notificationsound.unloadAsync();
      }
      : undefined;
  }, [notificationsound]);

  const callBackData = (data: any) => {
    if (data.notification_count) {
      if (data.Sound === "1") {
        setNotifications(data.notification_count)
        setTotalcount(data.Total_Count)
        playSound()
      } else {
        setNotifications(data.notification_count)
        setTotalcount(data.Total_Count)
      }
    }
  }


  const MINUTE_MS = 20000;
  useEffect(() => {
    const interval = setInterval(() => {
      if (EmailID) {
        let filter = {
          PARTNER_EMAIL_ID: EmailID?.Email_Id,
          Partner_Details_Id: EmailID?.Partner_Details_Id,
          Count: totalcount,
          Records_Filter: "FILTER"
        }
        dispatch(getAllNotification(filter, callBackData))
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [EmailID, totalcount])

  const styles = StyleSheet.create({

    cart_icon: {
      borderRadius: 50, right: 20, position: 'relative'
    },
    search: {
      borderRadius: 5,
      margin: 10,
      marginTop: Platform.OS === 'web' ? -28 : -30,
      backgroundColor: esiColor.BackgroundColor,
      borderColor: esiColor.SBorderColor,

    },
    searchweb: {
      backgroundColor: esiColor.BackgroundColor,
      borderColor: esiColor.SBorderColor,
      borderRadius: 5,
      margin: 15,
      width: '60%',
    },
    cart_count: {
      position: 'absolute',
      zIndex: 10,
      right: 45,
      marginTop: -30,
      backgroundColor: 'blue',
      borderRadius: 50,
      paddingHorizontal: 5,
      justifyContent: 'center',
    },
    cart_text: {
      color: 'white',
      fontSize: 10,
      textAlign: 'center',
      fontWeight: 'bold'
    },

  });

  return (
    <Surface style={{ zIndex: 1, }}>
      <View style={{ backgroundColor: esiColor.BackgroundColor, 
      shadowOpacity: 0.5, shadowColor: esiColor.brandShadowColor, shadowRadius: 2, elevation: 3,
         borderBottomColor: esiColor.SBorderColor, borderBottomWidth: 0.5 }}>
        <View style={{
          flexDirection: 'row', alignItems: 'center', height: 110, paddingTop: 5,
          marginBottom: dimensions.width <= 700 ? -5 : -24, justifyContent: "space-between", marginTop: Platform.OS === 'web' ? -30 : -10
        }}>
          <View style={{ flexDirection: "row", alignItems: 'center' }}>
            <Appbar.Action style={{ marginTop: 8 }} size={33} color={'#27b6cc'} icon="menu" onPress={() => {
              if (props.isFilter) {
                navigation.getParent().openDrawer();
              } else { navigation.openDrawer(); goToTop() }
            }} />
            <TouchableOpacity onPress={() => {
              if (props.isFilter) {
                navigation.getParent().openDrawer();
              } else { navigation.openDrawer(); goToTop() }
            }}>
              <Image source={require('../../../assets/images/img/logo/logo.png')} resizeMode="contain" style={{ height: 50, width: 100 }} />
            </TouchableOpacity>
          </View>
          {dimensions.width > 700 ?
            <View style={styles.searchweb}>
              <Searchbar
                placeholderTextColor={esiColor.SPHTextColor}
                iconColor={esiColor.SIconColor}
                inputStyle={{ color: esiColor.DescColor }}
                value={searchText}
                onChangeText={(text) => {
                  setShowResent(false);
                  onSearch(text);
                  var formData = {
                    "name": searchText,
                  }
                  dispatch(search_products_new(formData, responseData));
                }}
                style={{
                  maxHeight: 40, width: "100%", marginLeft: 'auto', marginRight: 'auto', marginHorizontal: 'auto',
                  backgroundColor: esiColor.BackgroundColor,
                  borderColor: esiColor.SBorderColor, borderWidth: 0.5
                }}
                onFocus={() => {
                  setShowResent(false);
                  if (!searchText) {
                    setSearchProduts([]);
                    var formData = {
                      "name": searchText || "",
                    }
                    dispatch(search_products_new(formData, responseData));
                  }
                }}

                onBlur={searchbarOnBlur}
                placeholder="Search"
              />
              <View style={{ alignItems: "center" }}>
                {!showResent &&
                  (searchProduts?.length > 0 ?
                    <ScrollView style={{
                      position: 'absolute', backgroundColor: esiColor.BackgroundColor, marginHorizontal: 36,
                      width: dimensions.width <= 700 ? "87%" : "100%", paddingHorizontal: "5%",
                      marginTop: 5, borderColor: 'gray', borderWidth: 1,
                      shadowColor: esiColor.brandShadowColor,
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                      zIndex: 9999,
                      shadowOpacity: 0.22,
                      shadowRadius: 2.22,
                      marginBottom: 10
                    }}>
                      {seconds <= 0 && searchProduts?.slice(0, 5)?.map((item, index) => {
                        return (
                          <TouchableOpacity onPress={(e) => { gotoPage(item) }} key={index} style={{ paddingHorizontal: 10, paddingVertical: 10, borderBottomWidth: 1, borderColor: '#E0E0E0', flexDirection: 'row' }}>
                            <View style={{ flexDirection: 'column', flex: 1 }}>
                              <Text style={{ color: esiColor.itemColor }}>{item.name} {item?.type == "product" && `(${item?.Partner_Name})`}</Text>
                              <Text style={{ fontSize: 12, color: esiColor.DescColor }}>{item.type}</Text>
                            </View>
                            <View>
                              <Image source={{ uri: item.image_path }} style={{ width: 30, height: 30, resizeMode: 'contain' }} />
                            </View>
                          </TouchableOpacity>
                        )
                      })}
                      {seconds <= 0 && searchProduts.length >= 5 && searchText != "" ?
                        <TouchableOpacity onPress={() => { navigation.navigate('all-combinations', { searchValue: searchText }) }}>
                          <Text style={{ marginVertical: 10, marginLeft: 10, color: esiColor.DescColor }} >
                            View All
                          </Text>
                        </TouchableOpacity>
                        : null}
                    </ScrollView>
                    :
                    !showResent &&
                    <ScrollView style={{
                      position: 'absolute', backgroundColor: esiColor.BackgroundColor, marginHorizontal: 36,
                      width: dimensions.width <= 700 ? "87%" : "100%", paddingHorizontal: "5%",
                      marginTop: 5, borderColor: 'gray', borderWidth: 1,
                      shadowColor: esiColor.brandShadowColor,
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                      height: 30,
                      paddingTop: 5,
                      zIndex: 9999,
                      shadowOpacity: 0.22,
                      shadowRadius: 2.22,
                      marginBottom: 10,
                    }}>
                      <TouchableOpacity>
                        {seconds > 0 ? <ActivityIndicator /> :
                          <Text style={{ marginLeft: "40%", color: esiColor.NoDataFound }}>
                            No Data found.
                          </Text>}
                      </TouchableOpacity>
                    </ScrollView>
                  )
                }
              </View>
            </View>
            : null
          }
          <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
            <View style={{ right: 20, position: 'relative', paddingRight: 20 }}>
              {isAuthenticated ?
                <Ionicons name="ios-notifications" size={23} color="#27b6cc"
                  onPress={(e) => { navigation.navigate('Notification', { id: '2' }) }}
                /> : null}
              {notifications && notifications !== "0" ?
                <Badge
                  size={17}
                  style={{
                    position: 'absolute',
                    color: "white",
                    fontWeight: 'bold',
                    bottom: 16,
                    left: 18,
                    backgroundColor: "red"
                  }}>
                  {notifications}
                </Badge>
                : null}
            </View>
            <TouchableOpacity style={styles.cart_icon}
              onPress={() => { navigation.navigate('Cart') }}>
              {cart.length > 0 &&
                <Badge style={{
                  position: 'absolute',
                  color: "white",
                  fontWeight: 'bold',
                  bottom: 12,
                  left: 18,
                  backgroundColor: "#27b6cc"
                }}>
                  {cart.length}
                </Badge>}
              <Entypo name="shopping-cart" size={23} color={esiColor.SIconColor}/>
            </TouchableOpacity>
            {
              !isAuthenticated ?
                <TouchableOpacity onPress={toggleModal}>
                  <Image source={userPic} style={{ height: 25, width: 25, marginRight: 10, borderRadius: 50, backgroundColor: 'white' }} />
                </TouchableOpacity>
                :
                <TouchableOpacity onPress={_handleProfile}>
                  <Avatar.Image size={25} source={userPic} style={{ marginRight: 20 }} />
                </TouchableOpacity>
            }
          </View>
        </View>
        {dimensions.width <= 700 ?
          <View style={styles.search}>
            <Searchbar
              placeholderTextColor={esiColor.SPHTextColor}
              iconColor={esiColor.SIconColor}
              inputStyle={{ color: esiColor.DescColor }}
              value={searchText}
              onChangeText={(text) => {
                setShowResent(false);
                onSearch(text);
                var formData = {
                  "name": searchText,
                  "Records_Filter": "FILTER"
                }
                dispatch(search_products_new(formData, responseData));
              }}
              style={{
                width: dimensions.width <= 700 ? '100%' : '80%', marginLeft: 'auto', marginRight: 'auto', marginHorizontal: 'auto',
                backgroundColor: esiColor.BackgroundColor,
                borderColor: esiColor.SBorderColor, borderWidth: 0.5
              }}
              onFocus={() => {
                setShowResent(false);
                if (!searchText) {
                  setSearchProduts([]);
                  var formData = {
                    "name": searchText || "",
                  }
                  dispatch(search_products_new(formData, responseData));
                }
              }}
              onBlur={searchbarOnBlur}
              placeholder="Search"
            />
            <View style={{ alignItems: "center" }}>
              {!showResent &&
                (searchProduts?.length > 0 ?
                  <ScrollView keyboardShouldPersistTaps={true} style={{
                    position: 'absolute', backgroundColor: esiColor.BackgroundColor, marginHorizontal: 36,
                    width: dimensions.width <= 700 ? "100%" : "65%", paddingHorizontal: "5%",
                    marginTop: 5, borderColor: esiColor.SBorderColor, borderWidth: 1,
                    shadowColor: esiColor.brandShadowColor,
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    zIndex: 9999,
                    shadowOpacity: 0.22,
                    shadowRadius: 2.22,
                    marginBottom: 10
                  }}>
                    {seconds <= 0 && searchProduts?.slice(0, 5)?.map((item, index) => {
                      return (
                        <TouchableOpacity onPress={(e) => { gotoPage(item) }} key={index} style={{ paddingHorizontal: 10, paddingVertical: 10, borderBottomWidth: 1, borderColor: '#E0E0E0', flexDirection: 'row' }}>
                          <View style={{ flexDirection: 'column', flex: 1 }}>
                            <Text style={{color: esiColor.Text}}>{item.name} {item?.type == "product" && `(${item?.Partner_Name})`}</Text>
                            <Text style={{ fontSize: 12, color: esiColor.FHColor }}>{item.type}</Text>
                          </View>
                          <View>
                            <Image source={{ uri: item.image_path }} style={{ width: 30, height: 30, resizeMode: 'contain' }} />
                          </View>
                        </TouchableOpacity>
                      )
                    })}
                    {seconds <= 0 && searchProduts.length >= 5 && searchText != "" ?
                      <TouchableOpacity onPress={() => { navigation.navigate('all-combinations', { searchValue: searchText }) }}>
                        <Text style={{ marginVertical: 10, marginLeft: 10, color: esiColor.FHColor }} >
                          View All
                        </Text>
                      </TouchableOpacity>
                      : null}
                  </ScrollView>
                  :
                  !showResent &&
                  <ScrollView style={{
                    position: 'absolute', backgroundColor: esiColor.BackgroundColor, marginHorizontal: 36, width: dimensions.width <= 700 ? "100%" : "65%", paddingHorizontal: "5%", marginTop: 5, borderColor: esiColor.SBorderColor, borderWidth: 1,
                    shadowColor: esiColor.brandShadowColor,
                    shadowOffset: {
                      width: 1,
                      height: 1,
                    },
                    height: 30,
                    paddingTop: 5,
                    zIndex: 9999,
                    shadowOpacity: 0.22,
                    shadowRadius: 2.22,
                    marginBottom: 10,
                  }}>
                    <TouchableOpacity>
                      {seconds > 0 ? <ActivityIndicator /> :
                        <Text style={{ marginLeft: "40%", color: esiColor.NoDataFound }}>
                          No Data found.
                        </Text>}
                    </TouchableOpacity>
                  </ScrollView>
                )
              }
            </View>

          </View>
          : null}
      </View>
    </Surface>
  );
};

export default Header;

