import {
    PRODUCTCOLORVARIANTS_REQUEST,
    PRODUCTCOLORVARIANTS_SUCCESS,
    PRODUCTCOLORVARIANTS_FAIL,
    PRODUCTCOLORVARIANT_GET_REQUEST,
    PRODUCTCOLORVARIANT_GET_SUCCESS,
    PRODUCTCOLORVARIANT_GET_FAIL,
    PRODUCTCOLORVARIANT_CREATE_REQUEST,
    PRODUCTCOLORVARIANT_CREATE_SUCCESS,
    PRODUCTCOLORVARIANT_CREATE_FAIL,
    PRODUCTCOLORVARIANT_UPDATE_REQUEST,
    PRODUCTCOLORVARIANT_UPDATE_SUCCESS,
    PRODUCTCOLORVARIANT_UPDATE_FAIL,
    PRODUCTCOLORVARIANT_DELETE_REQUEST,
    PRODUCTCOLORVARIANT_DELETE_SUCCESS,
    PRODUCTCOLORVARIANT_DELETE_FAIL,
    
PRODUCTCOLORVARIANTS_FILTER,
PRODUCT_MASTER_FAIL,
PRODUCT_MASTER_REQUEST,
PRODUCT_MASTER_SUCCESS,
       
} from '../actions/productcolorAction'
  
const initialState = {
  productcolorvariants: { all: [], error: '', isLoading: false },
  productcolorvariant: { productcolorvariant: {}, error: '', isLoading: false },
  productcolorvariantCreate: { productcolorvariant: {}, error: '', isLoading: false },
  productcolorvariantUpdate: { productcolorvariant: {}, error: '', isLoading: false },
  productcolorvariantDelete: { productcolorvariant: {}, error: '', isLoading: false },
  productMasters: { masters: [], error: '', isLoading: false }, 
  productcolorvariantFilter: { select: 'All', search: '' },
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case PRODUCTCOLORVARIANTS_REQUEST:
        return { ...state, productcolorvariants: { all: state.productcolorvariants.all, error: '', isLoading: true } };
      case PRODUCTCOLORVARIANTS_SUCCESS:
        return { ...state, productcolorvariants: { all: action.payload, error: '', isLoading: false } };
      case PRODUCTCOLORVARIANTS_FAIL:
        return { ...state, productcolorvariants: { all: [], error: action.payload, isLoading: false } };
      case PRODUCTCOLORVARIANT_GET_REQUEST:
        return { ...state, productcolorvariant: { productcolorvariant: {}, error: '', isLoading: true } };
      case PRODUCTCOLORVARIANT_GET_SUCCESS:
        return { ...state, productcolorvariant: { productcolorvariant: action.payload, error: '', isLoading: false } };
      case PRODUCTCOLORVARIANT_GET_FAIL:
        return { ...state, productcolorvariant: { productcolorvariant: {}, error: action.payload, isLoading: false } };
      case PRODUCTCOLORVARIANT_CREATE_REQUEST:
        return { ...state, productcolorvariantCreate: { productcolorvariant: {}, error: '', isLoading: true } };
      case PRODUCTCOLORVARIANT_CREATE_SUCCESS:
        return { ...state, productcolorvariantCreate: { productcolorvariant: state.productcolorvariantCreate.productcolorvariant, error: '', isLoading: false } };
      case PRODUCTCOLORVARIANT_CREATE_FAIL:
        return { ...state, productcolorvariantCreate: { productcolorvariant: {}, error: action.payload, isLoading: false } };
      case PRODUCTCOLORVARIANT_UPDATE_REQUEST:
        return { ...state, productcolorvariantUpdate: { productcolorvariant: {}, error: '', isLoading: true } };
      case PRODUCTCOLORVARIANT_UPDATE_SUCCESS:
        return { ...state, productcolorvariantUpdate: { productcolorvariant: state.productcolorvariantUpdate.productcolorvariant, error: '', isLoading: false } };
      case PRODUCTCOLORVARIANT_UPDATE_FAIL:
        return { ...state, productcolorvariantUpdate: { productcolorvariant: {}, error: action.payload, isLoading: false } };
      case PRODUCTCOLORVARIANT_DELETE_REQUEST:
        return { ...state, productcolorvariantDelete: { productcolorvariant: {}, error: '', isLoading: true } };
      case PRODUCTCOLORVARIANT_DELETE_SUCCESS:
        return { ...state, productcolorvariantDelete: { productcolorvariant: action.payload, error: '', isLoading: false } };
      case PRODUCTCOLORVARIANT_DELETE_FAIL:
        return { ...state, productcolorvariantDelete: { productcolorvariant: {}, error: action.payload, isLoading: false } };
      case PRODUCTCOLORVARIANTS_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, productcolorvariantFilter: { search: state.productcolorvariantFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, productcolorvariantFilter: { select: state.productcolorvariantFilter.select, search: action.value } };
        }
        return res;  
        
        case PRODUCT_MASTER_REQUEST:
          return { ...state, productMasters: { masters: state.productMasters.masters, error: '', isLoading: true }, };
        case PRODUCT_MASTER_SUCCESS:
          return { ...state, productMasters: { masters: action.payload, error: '', isLoading: false } };
        case PRODUCT_MASTER_FAIL:
          return { ...state, productMasters: { masters: [], error: action.payload, isLoading: false } };      
      default:
        return state;
    }
  }
  