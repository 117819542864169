import {
  DEALS_REQUEST,
  DEALS_SUCCESS,
  DEALS_FAIL,

  DEAL_GET_REQUEST,
  DEAL_GET_SUCCESS,
  DEAL_GET_FAIL,

  DEAL_CREATE_REQUEST,
  DEAL_CREATE_SUCCESS,
  DEAL_CREATE_FAIL,

  DEAL_UPDATE_REQUEST,
  DEAL_UPDATE_SUCCESS,
  DEAL_UPDATE_FAIL,

  DEAL_BRANDS_REQUEST,
  DEAL_BRANDS_SUCCESS,
  DEAL_BRANDS_FAIL,

  DEAL_CATEGORIES_REQUEST,
  DEAL_CATEGORIES_SUCCESS,
  DEAL_CATEGORIES_FAIL,

  DEALS_FILTER,
  DEAL_DATA,

} from '../actions/dealAction'

const initialState = {

  deals: { all: [], error: '', isLoading: false },
  deal: { deal: {}, error: '', isLoading: false },

  dealCreate: { deal: {}, error: '', isLoading: false },
  dealUpdate: { deal: {}, error: '', isLoading: false },

  dealBrands: { brands: [], error: '', isLoading: false },
  dealCategories: { categories: [], error: '', isLoading: false },

  dealFilter: { select: 'All', search: '' },
  dealData: { data: {}, error: '', isLoading: false },
};

export default function (state = initialState, action: any) {

  switch (action.type) {
    case DEALS_REQUEST:
      return { ...state, deals: { all: state.deals.all, error: '', isLoading: true } };
    case DEALS_SUCCESS:
      return { ...state, deals: { all: action.payload, error: '', isLoading: false } };
    case DEALS_FAIL:
      return { ...state, deals: { all: [], error: action.payload, isLoading: false } };

    case DEAL_GET_REQUEST:
      return { ...state, deal: { deal: {}, error: '', isLoading: true } };
    case DEAL_GET_SUCCESS:
      return { ...state, deal: { deal: action.payload, error: '', isLoading: false } };
    case DEAL_GET_FAIL:
      return { ...state, deal: { deal: {}, error: action.payload, isLoading: false } };

    case DEAL_CREATE_REQUEST:
      return { ...state, dealCreate: { deal: {}, error: '', isLoading: true } };
    case DEAL_CREATE_SUCCESS:
      return { ...state, dealCreate: { deal: state.dealCreate.deal, error: '', isLoading: false } };
    case DEAL_CREATE_FAIL:
      return { ...state, dealCreate: { deal: {}, error: action.payload, isLoading: false } };

    case DEAL_UPDATE_REQUEST:
      return { ...state, dealUpdate: { deal: {}, error: '', isLoading: true } };
    case DEAL_UPDATE_SUCCESS:
      return { ...state, dealUpdate: { deal: state.dealUpdate.deal, error: '', isLoading: false } };
    case DEAL_UPDATE_FAIL:
      return { ...state, dealUpdate: { deal: {}, error: action.payload, isLoading: false } };


    case DEAL_BRANDS_REQUEST:
      return { ...state, dealBrands: { brands: state.dealBrands.brands, error: '', isLoading: true }, };
    case DEAL_BRANDS_SUCCESS:
      return { ...state, dealBrands: { brands: action.payload, error: '', isLoading: false } };
    case DEAL_BRANDS_FAIL:
      return { ...state, dealBrands: { brands: [], error: action.payload, isLoading: false } };

    case DEAL_CATEGORIES_REQUEST:
      return { ...state, dealCategories: { categories: state.dealCategories.categories, error: '', isLoading: true }, };
    case DEAL_CATEGORIES_SUCCESS:
      return { ...state, dealCategories: { categories: action.payload, error: '', isLoading: false } };
    case DEAL_CATEGORIES_FAIL:
      return { ...state, dealCategories: { categories: [], error: action.payload, isLoading: false } };

    case DEAL_DATA:
      return { ...state, dealData: { data: action.value, error: '', isLoading: true } };

    case DEALS_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, dealFilter: { search: state.dealFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, dealFilter: { select: state.dealFilter.select, search: action.value } };
      }
      return res;

    default:
      return state;
  }
}