import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { View, ScrollView, RefreshControl, FlatList, Dimensions, Platform, Image, TouchableOpacity } from "react-native";
import { Avatar, Badge, Button, ActivityIndicator, HelperText, IconButton, Menu, Searchbar, Surface, Text, TextInput, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import Modal from "react-native-modal";
import { Formik } from "formik";
import * as Yup from 'yup';
import * as Location from "expo-location";
import isEmpty from "../../state/validations/is-empty";
import Header from '../auth/components/header';
import Pagination from "../../components/custom/pagination/pagination";
// import esiColor from "../../constants/Colors";
import { CategoryFilters } from "./categoryfilter";
import SignIN from "../auth/signin";
import { useToast } from "../../components/custom/react-native-paper-toast/src";
import { api } from "../../state/actions/authActions";
import { availabileBiddingProducts, getBiddingProductsCart, getSingleProductImagesAction } from "../../state/actions/bidding/biddingproductActions";
import { EsiAutocompleteDropdown } from "../../components/DropDowns/AutoCompleteDropDown";
import EsiTextAreaInput from "../../components/custom/TextAreaInput/textareainput";
import { Drawer } from "react-native-drawer-layout";
import { createBids, placeCartBids } from "../../state/actions/bidding/biddingboomiActions";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";
import EsiDatePicker from "../../components/custom/date/date";
import { getProductsImageAction } from "../../state/actions/shoppers/productsActions";
import { StyleSheet } from "react-native";
import ImageCarouselforbidding from "../../components/custom/image-carousel-for-bidding";
export default function NewBidProducts(props) {
    const [open, setOpen] = React.useState(false);
    const { navigation } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [loading, setLoading] = React.useState(false);
    const [allproducts, setAllproducts] = React.useState([]);
    const [pageOld, setPageOld] = React.useState(false);
    const [disable, setDisable] = React.useState(false);
    const [productImage, setProductImage] = useState({});
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const logedInPartnerDetail = useSelector((state) => state.auth.user.Partner_Details_Id);
    const callBackGetData = async (status, data, pagination) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
            setPageOld(true);
            // if (Pagination) {
            //     setPagination(pagination);
            // } else {
            setPagination({ PageNo: 1, PageSize: 50, SortBy: "BIDDING_PRODUCT_ID", SortOrder: "DESC" });
            // }
        }
    }
    let image = []
    if (productImage) {
        if (productImage[0]) {
            if (productImage[0].Product_Image) {
                let data = productImage[0].Product_Image
                for (var i = 0; i < data.length; i++) {
                    image.push({
                        imageuri: data[i].Product_Image,
                        Product_Name: data[i].Product_Name,
                        Description: data[i].Description
                    })
                }
            }
        }
    }
    const callBackImagesData = (data) => {
        if (data) {
            setProductImage(data)
        }
    };
    const statusChangeResponse = (data) => {
        dispatch(getSingleProductImagesAction(data, callBackImagesData));
    }

    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "BIDDING_PRODUCT_ID", SortOrder: "DESC" });

    const [testFill, setTestFill] = React.useState({});

    const [errorfrombackend, setErrorfrombackend] = React.useState('');
    const [search, setSearch] = React.useState("");
    const [pincode, setPincode] = React.useState("");
    const data = props?.route?.params;
    useEffect(() => {
        setSearch("");
    }, [data])
    useEffect(() => {
        (async () => {
            if (Platform.OS === 'android' && !Constants.isDevice) {
                alert(
                    'Oops, this will not work on Snack in an Android emulator. Try it on your device!'
                );
                return;
            }
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                return;
            }

            const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
            api.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${latitude}&lon=${longitude}`).then(async (response) => {
                let info = response?.data?.address?.postcode;
                if (info)
                    setPincode(info)
            }).catch(async (reason) => {
            });
        })();
    }, [])

    const [filters, setFilters] = useState([]);
    const [cartCount, setCartCount] = useState([]);
    const callBackCartData = async (status, data) => {
        if (status && data?.Success_Response.Is_Data_Exist == '1') {
            setCartCount(data?.results);
        } else {
            setCartCount([]);
        }
    }
    const callProducts = () => {
        let formData = {
            search_by_filter: "",
            search: search,
            Records_Filter: ""
        }
        if (filters?.length > 0)
            formData.search_by_filter = "",
                formData.search = search,
                formData.Records_Filter = "FILTER",
                formData.Bidding_Product_Id = "",
                formData.Bidding_Products_Category_Id = filters.map((item) => item.value)
        if (!(JSON.stringify(testFill) === JSON.stringify(formData))) {
            setTestFill(formData);
            setPageOld(false);
            setLoading(true);
            dispatch(availabileBiddingProducts(formData, pagination, callBackGetData));
        }
        if (logedInPartnerDetail)
            dispatch(getBiddingProductsCart({
                Buyer_Partner_Detail_Id: logedInPartnerDetail
            }, callBackCartData));
    }
    useEffect(() => {
        callProducts();
    }, [search, logedInPartnerDetail, filters]);
    const scroll = useRef(null);
    const goToTop = () => {
        scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
    }
    const [isModalVisible, setModalVisible] = React.useState(false);
    const toast = useToast();

    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
        if (status) {

        }
    };
    const [addCartPop, setAddCartPop] = React.useState(false);

    const [imagePop, setImagePop] = React.useState(false);
    const [addCartItem, setAddCartItem] = React.useState('');
    const [measurement, setMeasurement] = React.useState([]);
    const addProductToBid = (data: any) => {
        if (logedInPartnerDetail) {
            setAddCartPop((prop) => !prop);
            setAddCartItem(data);
            setMeasurement(data?.Measurement_Type?.split(',').map(Type => ({ Measurement_Type: Type })))


        } else {
            setModalVisible(!isModalVisible);
        }
    }
    let messurementOPT = []
    if (measurement) {
        if (measurement[0]) {
            for (let i = 0; i < measurement?.length; i++) {
                messurementOPT.push(
                    {

                        value: measurement[i]?.Measurement_Type,
                        label: measurement[i]?.Measurement_Type,
                    })
            }
        }
    }
    const productShow = useMemo(() => {
        let numColumCount = Number((dimensions.width / 250).toFixed() == "1" ? 2 : (dimensions.width / 250).toFixed());
        return <FlatList
            // horizontal={true}
            key={dimensions.width}
            columnWrapperStyle={{ justifyContent: "space-around" }}
            numColumns={numColumCount}
            data={allproducts}
            renderItem={({ item, index, separators }) => (
                <View key={"bid" + index} style={[{
                    backgroundColor: esiColor.BackgroundColor,
                    borderRadius: 8, width: (dimensions.width / Number(numColumCount)) - 10, maxWidth: 215, paddingVertical: 2,
                    margin: 10,
                }, Platform.OS != "android" ? {
                    shadowOffset: { width: 0, height: 0 },
                    shadowColor: esiColor.brandShadowColor,
                    shadowOpacity: 5,
                    shadowRadius: 7,
                    elevation: 20,
                    // transform: [{ perspective: 300 }, { rotateY: 'deg' },
                    // { rotateX: '3deg' }],
                } : {
                    elevation: 20,
                    overflow: "hidden",
                    transform: [{ perspective: 200 }],
                }]}>
                    <View style={{ alignSelf: 'flex-end', right: 0.2, top: 0 }}>
                        <IconButton size={20} icon="eye" iconColor={esiColor.SIconColor} style={{ backgroundColor: esiColor.BackgroundColor }} onPress={() => {
                            setImagePop(true)
                            statusChangeResponse(item.Bidding_Product_Id)
                        }} />
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "center" }}>
                        {
                            item?.Product_Image ? <Image style={{ resizeMode: 'contain', width: (dimensions.width / Number(numColumCount)) - 20, maxWidth: 210, height: ((dimensions.width / Number(numColumCount)) - 20) * .7, maxHeight: 200, borderRadius: 8, backgroundColor: 'transparent' }}
                                source={{ cache: "force-cache", uri: item?.Product_Image }}></Image> : <Image style={{ resizeMode: 'contain', width: (dimensions.width / Number(numColumCount)) - 20, maxWidth: 210, height: ((dimensions.width / Number(numColumCount)) - 20) * .7, maxHeight: 200, borderRadius: 8, backgroundColor: 'transparent' }}
                                    source={require('../../assets/images/EmptyImage.png')}></Image>
                        }
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "center", marginVertical: 5 }}>
                        <Text style={{ flexWrap: "wrap", textAlign: "center", color: esiColor.itemColor }} >{item.Product_Name?.slice(0, 50) + (item.Product_Name?.length > 50 ? "..." : "")}</Text>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "center", marginBottom: 5 }}>
                        <Button icon="cart-plus" uppercase={false} mode="outlined" textColor={esiColor.itemButtenColor} buttonColor={esiColor.globalButtonColor} onPress={() => {
                            addProductToBid(item);
                        }}>
                            Add to Bid
                        </Button>
                    </View>
                </View>
            )}
        />
    }, [allproducts, dimensions])

    const drawerContent = useCallback(() => {
        return (
            <ScrollView
                showsHorizontalScrollIndicator={false}
                style={{ marginVertical: 10 }} >
                <View >
                    <CategoryFilters filters={filters} setFilters={setFilters} />
                </View>
            </ScrollView>
        )
    }, [open, filters])

    const [loadingForm, setloadingForm] = React.useState(false);
    const [formResults, setFormResults] = React.useState(false);
    const [formikSubmitType, setFormikSubmitType] = React.useState(0);

    const formCallbackResponse = (status: boolean, data: any) => {
        setDisable(false)
        if (status) {
            setAddCartPop(false)
            setDisable(false);
            setModalVisible(false)
            setFormResults(false);
            callProducts();
        } else {
            setModalVisible(false)
            setAddCartPop(false)
            setDisable(false);
            setErrorfrombackend(data)
        }
    }

    const canGoBackCallback = useMemo(() => {
        return navigation.canGoBack();
    }, [navigation])


    const style = StyleSheet.create({

        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor

        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor

        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        // containerstyle: {
        //   width: 150
        // }
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />

            <Drawer
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                drawerStyle={{ backgroundColor: esiColor.BackgroundColor }}
                renderDrawerContent={drawerContent}
                drawerPosition={"left"}
            >
                <View style={{ flexDirection: 'row', justifyContent: "space-between", marginBottom: 10 }}>
                    {/* {canGoBackCallback &&
                            <IconButton
                                icon="arrow-left"
                                size={27}
                                color={"#27B6CC"}
                                style={{ marginLeft: 1 }}
                                onPress={() => {
                                    navigation.goBack();
                                }} />
                        } */}
                    <Searchbar style={{
                        marginHorizontal: 10, borderColor: esiColor.SBorderColor,
                        backgroundColor: esiColor.BackgroundColor, minWidth: "30%", maxWidth: "30%",
                        borderWidth: 0.5, marginTop: 10
                    }}
                        inputStyle={{ fontSize: 12, marginLeft: 20 }}
                        value={search}
                        onChangeText={(value) => {
                            setSearch(value);
                        }}
                        iconColor={esiColor.SIconColor}
                        placeholderTextColor={esiColor.SPHTextColor}
                        theme={{ colors: { text: esiColor.SBTextColor } }}
                        multiline={false}
                        placeholder="Search"
                    />
                    <IconButton
                        iconColor={esiColor.SIconColor}
                        icon="filter"
                        size={24}
                        style={{ marginTop: -45, alignSelf: 'flex-end' }}
                        onPress={() => setOpen((prevOpen) => !prevOpen)}
                    />

                </View>
                <ScrollView ref={scroll}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                        />
                    }
                >
                    <View style={{ alignItems: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap', position: 'relative', justifyContent: 'space-around' }}>
                        {isEmpty(allproducts) ? (loading ? <ActivityIndicator /> :
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 30 }}>
                                <Text style={{ color: esiColor.NoDataFound }}>No Products found!</Text>
                            </View>
                        ) : (
                            productShow
                        )
                        }
                    </View>

                    <View style={{ marginBottom: 5 }}>
                        {/* <Pagination pagination={pagination} setPagination={setPagination} /> */}
                    </View>
                </ScrollView>

                {cartCount?.length >= 0 &&
                    <TouchableOpacity onPress={(e) => { navigation.navigate("CartOfBids"); }} style={{ position: 'absolute', right: 0, bottom: 20, backgroundColor: esiColor.esiMainColor, borderRadius: 8, flexDirection: "row", justifyContent: "center", padding: 5 }} >
                        <Ionicons name="cart" size={22} color="white" />
                        <Text style={{ color: "white", marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold" }} > Bids </Text>
                        <Badge style={{ position: "absolute", right: 0, top: -10, color: esiColor.esiMainColor, backgroundColor: esiColor.ImgBck }} >{cartCount?.length}</Badge>
                    </TouchableOpacity>}
            </Drawer>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                backdropOpacity={0.9}
                style={{ alignItems: 'center' }} isVisible={imagePop}>
                <View style={{ flex: 1, maxWidth: 400, minWidth: 250, minHeight: 500, maxHeight: 530, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                    {/* <Surface style={{
                        justifyContent: 'center',
                        borderRadius: 10,
                        top: Platform.OS === 'web' ? 10 : 20,
                        elevation: 0
                    }} > */}
                    <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                        setImagePop(false);
                        callProducts()
                    }}>
                        <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                    </TouchableRipple>
                    {!isEmpty(image) ? <ImageCarouselforbidding image={image} dimensions={dimensions} /> : <ActivityIndicator color='#27b6cc' />}
                    {/* </Surface> */}

                </View>
            </Modal>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={addCartPop}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 450, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                    <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                        if (formikSubmitType == 0) {
                            setModalVisible(false)
                            setAddCartPop(false)
                        } else {
                            setAddCartPop((prop) => !prop);
                        }
                    }}>
                        <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                    </TouchableRipple>
                    <ScrollView
                        showsVerticalScrollIndicator={false}>
                        <Surface theme={{
                            colors: {
                                surface: esiColor.BackgroundColor,
                            }
                        }} style={{
                            justifyContent: 'center',
                            borderRadius: 10,
                            top: Platform.OS === 'web' ? 10 : 20,
                            backgroundColor: esiColor.BackgroundColor
                        }} >
                            <Formik
                                initialValues={
                                    {
                                        Bidding_Product_Id: addCartItem.Bidding_Product_Id,
                                        Quantity: '',
                                        Measurement_Type: addCartItem?.Measurement_Type || "kg",
                                        User_Email_Id: logedInEmail,
                                        Buyer_Partner_Detail_Id: logedInPartnerDetail,
                                        Description: '',
                                        Pincode: pincode || '',
                                        Bidding_Expiry_Date: '',
                                        Bidding_Status: 'INITIATED',
                                    }
                                }
                                validationSchema={Yup.object().shape({
                                    Quantity: Yup.number().positive().min(1, "Quantity should be more then Zere.").required("Quantity is required."),
                                    Measurement_Type: Yup.string().required("Size is required."),
                                    Pincode: Yup.string().min(6, "Pincode should be 6 numbers.").max(6, "Pincode should be 6 numbers.").required("Pincode is required."),
                                    Bidding_Expiry_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Bidding Expiry Date greater than or equal to today.").required("Bidding Expiry Date is required."),

                                })}
                                onSubmit={values => {
                                    setloadingForm(true);
                                    if (formikSubmitType == 0) {
                                        dispatch(createBids([values], formCallbackResponse, navigation));
                                        setDisable(true)
                                    } else {
                                        dispatch(placeCartBids(values, formCallbackResponse, navigation, logedInPartnerDetail, toast))
                                    }
                                }}
                            >
                                {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                    <View style={{
                                        flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                                    }}>
                                        {
                                            formResults ?
                                                <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                                                    <Image source={require('../../assets/images/GIF/bidsuccess.gif')}
                                                        style={{ height: 300, width: '100%', resizeMode: 'contain' }}></Image>
                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <Text style={{ textAlign: "center", fontWeight: "bold" }} >
                                                            {formikSubmitType == 0 ? "Successfully placed your bid." : "Successfully added your item to cart."}
                                                        </Text>
                                                    </View>
                                                </View>
                                                :
                                                <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>

                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <TextInput
                                                            style={{ width: "100%", backgroundColor: esiColor.TextBC }}
                                                            label="Quantity*"
                                                            mode='outlined'
                                                            onChangeText={handleChange("Quantity")}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Quantity}
                                                            error={Boolean(touched.Quantity && errors.Quantity)}
                                                            selectionColor={esiColor.TextSelection}
                                                            theme={{
                                                                colors: {
                                                                    primary: esiColor.TextHighlight,
                                                                    text: esiColor.Text,
                                                                    placeholder: esiColor.TextPlaceholder
                                                                }
                                                            }}
                                                            outlineColor={esiColor.TextOuterLine}
                                                            underlineColor={esiColor.TextUnderline}
                                                            activeOutlineColor={esiColor.TextOuterLine}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Quantity && errors.Quantity)}>
                                                            {touched.Quantity && errors.Quantity}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>

                                                        <EsiSearchDropdown
                                                            style={[style.dropdown]}
                                                            placeholderStyle={style.placeholderStyle}
                                                            selectedTextStyle={style.selectedTextStyle}
                                                            inputSearchStyle={style.inputSearchStyle}
                                                            iconStyle={style.iconStyle}
                                                            containerStyle={style.containerstyle}
                                                            data={messurementOPT}
                                                            labelField="label"
                                                            valueField="value"
                                                            maxHeight={messurementOPT?.length > 1 ? 250 : 200}
                                                            search
                                                            searchPlaceholder={'Select Size'}
                                                            value={messurementOPT?.Measurement_Type}
                                                            onUpdateValue={item => {

                                                                item &&
                                                                    setFieldValue('Measurement_Type', item?.value);
                                                            }}

                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Measurement_Type && errors.Measurement_Type)}>
                                                            {touched.Measurement_Type && errors.Measurement_Type}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>

                                                        <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Description:</Text>
                                                        <EsiTextAreaInput
                                                            error={Boolean(touched.Description && errors.Description)}
                                                            containerStyle={{ height: 170 }}
                                                            style={{ height: 170, backgroundColor: esiColor.TextBC }}
                                                            mode='outlined'
                                                            value={values.Description}
                                                            textareavalueUpdate={value => {
                                                                setFieldValue("Description", value)
                                                            }}
                                                            placeholder={'Please provide additional information'}
                                                            underlineColorAndroid={'transparent'}
                                                            selectionColor={esiColor.TextSelection}
                                                            theme={{
                                                                colors: {
                                                                    primary: esiColor.TextHighlight,
                                                                    text: esiColor.Text,
                                                                    placeholder: esiColor.TextPlaceholder
                                                                }
                                                            }}
                                                            outlineColor={esiColor.TextOuterLine}
                                                            underlineColor={esiColor.TextUnderline}
                                                            activeOutlineColor={esiColor.TextOuterLine}
                                                        />
                                                    </View>
                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <TextInput
                                                            style={{ width: "100%", backgroundColor: esiColor.TextBC }}
                                                            label="Pincode*"
                                                            mode='outlined'
                                                            onChangeText={(text: any) => { setFieldValue("Pincode", text.replace(/\D/gm, '')) }}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Pincode}
                                                            error={Boolean(touched.Pincode && errors.Pincode)}
                                                            placeholder={'Please provide Pincode '}
                                                            underlineColorAndroid={'transparent'}
                                                            selectionColor={esiColor.TextSelection}
                                                            theme={{
                                                                colors: {
                                                                    primary: esiColor.TextHighlight,
                                                                    text: esiColor.Text,
                                                                    placeholder: esiColor.TextPlaceholder
                                                                }
                                                            }}
                                                            outlineColor={esiColor.TextOuterLine}
                                                            underlineColor={esiColor.TextUnderline}
                                                            activeOutlineColor={esiColor.TextOuterLine}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Pincode && errors.Pincode)}>
                                                            {touched.Pincode && errors.Pincode}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <EsiDatePicker
                                                            theme={esiColor.pagination_theme}
                                                            style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                                                            name={'Bidding_Expiry_Date'}
                                                            label={'Bidding Expiry Date'}
                                                            value={values.Bidding_Expiry_Date}
                                                            valueUpdate={item => {
                                                                setFieldValue('Bidding_Expiry_Date', item)
                                                            }}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}>
                                                            {touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                        {disable ? <ActivityIndicator />
                                                            :
                                                            <Button textColor={esiColor.itemButtenColor} style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }}
                                                                uppercase={false} mode="contained"
                                                                onPress={() => {
                                                                    setFormikSubmitType(0);
                                                                    handleSubmit()
                                                                    // setDisable(true)
                                                                }}>
                                                                Ask Quotation

                                                            </Button>}
                                                        <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} mode="outlined" onPress={() => {
                                                            setFormikSubmitType(1);
                                                            handleSubmit()

                                                        }}>
                                                            <FontAwesome5 name={"cart-plus"} size={20} color={esiColor.itemButtenColor} />
                                                        </Button>
                                                    </View>
                                                    <View>
                                                        <Text>
                                                            {errorfrombackend}
                                                        </Text>
                                                    </View>

                                                </View>}
                                    </View>)}
                            </Formik>

                        </Surface>
                    </ScrollView>
                </View>
            </Modal>
        </Surface>
    )
}