import { PUSH_CREATE_FAIL, PUSH_CREATE_REQUEST, PUSH_CREATE_SUCCESS } from "../actions/pushnotificationAction";
const initialState = {
    pushCreate: { pushCreate: {}, error: '', isLoading: false },
};
export default function (state = initialState, action) {
    switch (action.type) {
        case PUSH_CREATE_REQUEST:
            return { ...state, pushCreate: { bankdetail: {}, error: '', isLoading: true } };
        case PUSH_CREATE_SUCCESS:
            return { ...state, pushCreate: { bankdetail: state.pushCreate.pushCreate, error: '', isLoading: false } };
        case PUSH_CREATE_FAIL:
            return { ...state, pushCreate: { bankdetail: {}, error: action.payload, isLoading: false } };
        default:
            return state;
    }
}