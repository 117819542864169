import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderHTML from "react-native-render-html";
import Modal from "react-native-modal";
import { Avatar, Text, TextInput, Surface, TouchableRipple, IconButton, Colors } from "react-native-paper";
import { View, TouchableOpacity, Image, useWindowDimensions, ScrollView, StyleSheet, Dimensions, RefreshControl } from "react-native";
import { Rating } from "react-native-ratings";
import { Ionicons } from "@expo/vector-icons";
import { addBrandReviewAction, editBrandReviewAction, getBrandByIdAction, getUserReviewsbyIdAction } from "../../../state/actions/shoppers/shopperbrandsActions";
import isEmpty from "../../../state/validations/is-empty";
import SignIN from "../../auth/signin";
import Header from "../header/header";
import { useToast } from "../../../components/custom/react-native-paper-toast/src";


// api
export default function BrandDetailsb2b(props) {
    const { navigation } = props;
    const toast = useToast();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const [visible, setVisible] = useState(10);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [rating, setRating] = useState(0);
    //comment param is used here to take the value from the Comment text box
    const [comment, setComment] = useState("")
    const brandId = props?.route?.params?.Brand_Id;
    let brands = useSelector((state) => state.allBrands.brand.brand);
    let brandReviews = useSelector((state) => state.allBrands.brandReviews.all);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const user = useSelector(state => state.auth?.user?.Email_Id);
    //reviewId is used to get the Particular Review ID dynamically
    const [reviewId, setData] = useState()
    //setCommentError param is used here to display validation error message
    const [commenterror, setCommentError] = useState("")
    const [error, setError] = useState("")
    //setRatingError param is used here to display validation error message
    const [ratingerror, setRatingError] = useState("")

    //For add review
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);

    //For edit review
    const [setEditModalVisible] = React.useState(false);
    const [isEditReviewModalVisible, setEditReviewModalVisible] = React.useState(false);
    const BrandReviews = () => {
        let existcomment;
        let UserData = [];
        if (brandReviews) {
            existcomment = brandReviews?.filter(review => review.User_Email_Id === user).length
            UserData = brandReviews.filter(review => review.User_Email_Id === user ? review : "")
        }
        return [existcomment, UserData]
    }
    const [commentvalidation, uservalidation] = BrandReviews();
    //displaying Signin pop up
    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };
    //For Add review
    const toggleModal = () => {
        if (isAuthenticated) {
            setReviewModalVisible(!isReviewModalVisible);
        }
        else {
            setModalVisible(true);
        }

    };
    //For edit review
    const editToggleModal = () => {
        if (isAuthenticated) {
            setEditReviewModalVisible(!isEditReviewModalVisible);
        }
        else {
            setEditModalVisible(true);
        }

    };

    const addReviewResponse = (status, message) => {
        toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
        setReviewModalVisible(!isReviewModalVisible);
        setComment("");
        setRating("");
    }


    const addReviewModal = () => {

        if (comment !== "" && rating > 0) {
            setCommentError("");
            setRatingError("");
            let formData = {
                Brand_Id: brandId,
                Brand_Name: brands.Brand_Name,
                Rating: rating,
                Comments: comment,
                User_Email_Id: user,
                Status: "Active"
            }
            let formValues = JSON.parse(JSON.stringify(formData));
            dispatch(addBrandReviewAction(formValues, brandId, addReviewResponse));
        } else {
            if (isEmpty(comment)) {
                setCommentError("Review is required.")
            }
            if (isEmpty(rating)) {
                setRatingError("Rating is required.")
            }
        }

    }

    const editReviewResponse = (status, message) => {
        toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
        setEditReviewModalVisible(!isEditReviewModalVisible);
        setComment("");
        setRating("");
    }


    const editReviewModal = () => {
        if (comment !== "" && rating > 0) {
            setCommentError("");
            setRatingError("");
            let editData = {
                Brand_Id: brandId,
                Brand_Review_Id: reviewId,
                Brand_Name: brands.Brand_Name,
                Rating: rating,
                Comments: comment,
                User_Email_Id: user,
                Status: "Active"
            }
            let formValues = JSON.parse(JSON.stringify(editData));
            dispatch(editBrandReviewAction(formValues, brandId, editReviewResponse));
        }
        else {
            if (isEmpty(comment)) {
                setCommentError("Review is required.")
            }
            if (isEmpty(rating)) {
                setRatingError("Rating is required.")
            }
        }

    }
    // Comment validations
    const handleOnChange = (value) => {
        if (commentvalidation > 0) {
            setError("You have already added review.")
        }
        if (value === "") {
            setError("")
        }
    }
    // Edit Onchanges
    const editOnChange = (value) => {
        if (value === "") {
            setCommentError("Review is required.")
        } else {
            setCommentError("")
        }
    }
    const [id, setID] = useState();
    const [branddata, setBrandData] = useState();
    const callBackGetBrandData = (brands) => {
        if (brands) {
            setBrandData(brands);

            setID(brandId)
        }
    };
    useEffect(() => {
        onRefresh();
    }, [brandId]);

    const onRefresh = () => {
        dispatch(getBrandByIdAction(brandId, callBackGetBrandData));
        dispatch(getUserReviewsbyIdAction(brandId));
    };
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const esiColor = useSelector(state => state.theme);

    const styles = StyleSheet.create({
        // root: {
        //     backgroundColor: "#ffffff",
        //     flex: 1
        // },
        container: {
            paddingLeft: 12,
            paddingRight: 15,
            paddingVertical: 10,
            flexDirection: 'row',
            alignItems: 'flex-start',
            borderRadius: 2
        },
        content: {
            marginLeft: 16,
            flex: 1,
            borderBottomColor: esiColor.SBorderColor,
            borderBottomWidth: 0.1,
            
        },
        contentHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 6,
            borderRadius: 2,
            borderBottomColor: esiColor.SBorderColor,
            borderBottomWidth: 0.1
        },
        image: {
            width: 45,
            height: 45,
            borderRadius: 20,
        },

        name: {
            fontSize: 16,
            fontWeight: "bold",
            color: esiColor.itemColor
        },
        // time: {
        //     fontSize: 11,
        //     color: "#808080",
        // },
        // container1: {
        //     flex: 2,
        //     padding: 10,
        //     borderRadius: 2
        // },
        // containerStyle:
        // {
        //     backgroundColor: 'white',
        //     padding: 20,
        //     borderColor: 'black',
        //     borderWidth: 1,
        //     borderRadius: 2
        // },
        // separatorm: {
        //     height: 1,
        //     backgroundColor: "#CCCCCC",
        // },
        containerm: {
            paddingLeft: 19,
            paddingRight: 16,
            paddingVertical: 12,
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginLeft: 1,
            borderRadius: 2
        },
        centerElement: {
            justifyContent: 'center',
            alignItems: 'center'
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <TouchableOpacity style={{ marginLeft: 8 }} onPress={() => navigation.navigate('Brandsb2b')}>
                <Ionicons name="arrow-back" size={25} color="#27b6cc" style={{ marginTop: 10 }} />
            </TouchableOpacity>
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={false}
                        onRefresh={onRefresh}
                    />
                }
            >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: '2%', marginRight: 20 }}>

                    <Image source={{ uri: branddata?.Brand_Image }} style={{ height: 100, width: 100, resizeMode: 'contain', marginRight: 20 }}></Image>
                    <Rating
                        readonly={true}
                        rating={branddata?.Brand_Esi_Rating ? parseInt(branddata?.Brand_Esi_Rating) : 0}
                        tintColor={esiColor.TintColor}
                        type='heart'
                        startingValue={5}
                        ratingCount={5}
                        imageSize={20}
                        style={{ paddingVertical: 5 }}
                        minValue={1}
                        onFinishRating={(rating) => ratingCompleted(rating)}
                        name="rating"
                    />
                </View>
                <View style={{ marginLeft: '3%', marginRight: '3%', flex: 1, backgroundColor: esiColor.RTBC }}>
                    {branddata?.Brand_Description &&
                        <RenderHTML
                            contentWidth={width}                           
                            source={{ html: branddata?.Brand_Description }} />
                    }
                </View>

                <View style={{ fontSize: 20, width: 100, alignSelf: 'center', marginTop: 10 }}>
                    <TouchableOpacity style={[styles.centerElement, { backgroundColor: commentvalidation > 0 ? 'grey' : esiColor.globalButtonColor, width: 100, height: 30, borderRadius: 5 }]}
                        onPress={() => toggleModal()} disabled={commentvalidation > 0 ? true : false}>
                        <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold' }}>Add Review</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.container}>
                    <Image style={styles.image} source={require("../../../assets/images/img/logo/meePaisa_logo.png")} />
                    <View style={styles.content}>
                        <View style={styles.contentHeader}>
                            <Text style={styles.name}>meepaisa Review</Text>
                            <View style={{ marginTop: -8 }}>
                                <Rating
                                    rating={brands?.Brand_Esi_Ratting ? (parseInt(brands?.Brand_Esi_Ratting)) : (0)}
                                    numberOfStars={5}
                                    ratingCount={5}
                                    imageSize={20}
                                    style={{ paddingVertical: 10 }}
                                    readonly={true}
                                    startingValue={branddata?.Brand_Esi_Rating}
                                    tintColor={esiColor.TintColor}
                                    type='heart'
                                    onFinishRating={(rating) => ratingCompleted(rating)} />
                            </View>
                        </View>
                        <View style={{ backgroundColor: esiColor.RTBC, flexWrap: "wrap", flex: 1 }}>
                            {branddata?.Brand_Esi_Reviews &&
                                <RenderHTML source={{ html: branddata?.Brand_Esi_Reviews }} />}
                        </View>
                    </View>

                </View>
                <View >
                    {!isEmpty(brandReviews) && (
                        brandReviews.Is_Data_Exist === "0" || isEmpty(brandReviews) ? (
                            <View style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', flex: 1 }}>
                                <Text style={{ color: esiColor.NoDataFound }}>No Brand Reviews found!</Text>
                            </View>
                        ) : typeof brandReviews === "object" && (
                            <>
                                {brandReviews.slice(0, visible).map((data, index) => (
                                    <View key={index}>
                                        <View style={styles.containerm}>
                                            <TouchableOpacity>
                                                {data?.Profile_Pic_Path ?
                                                    <Image style={styles.image} source={{ uri: data.Profile_Pic_Path }} /> :
                                                    <Image style={styles.image} source={require("../../../assets/images/img/logo/meePaisa_logo.png")} />
                                                }
                                            </TouchableOpacity>
                                            <View style={styles.content}>
                                                <View style={styles.contentHeader}>
                                                    <Text style={styles.name}>{data.Full_Name}</Text>
                                                    {/* <View >
                                                        {user && user === data?.User_Email_Id ? (
                                                            <IconButton style={{ marginTop: -4 }} icon="pencil" size={20} onPress={() => { editToggleModal(); setRating(parseInt(data.Rating)); setComment(data.Comments); setData(data.Brand_Review_Id); }} />
                                                        ) : (null)}
                                                    </View> */}
                                                    <View style={{ marginTop: -8 }}>
                                                        <Rating
                                                            readonly={true}
                                                            tintColor={esiColor.TintColor}
                                                            type='heart'
                                                            startingValue={data?.Rating}
                                                            ratingCount={5}
                                                            imageSize={20}
                                                            onFinishRating={(rating) => ratingCompleted(rating)}
                                                            style={{ paddingVertical: 10 }}
                                                        />
                                                    </View>
                                                </View>
                                                <View style={{ backgroundColor: esiColor.RTBC, flexWrap: "wrap", flex: 1 }}>
                                                    {data.Comments &&
                                                        <RenderHTML
                                                            source={{ html: data.Comments }}
                                                        />}
                                                </View>
                                            </View>
                                        </View>

                                    </View>
                                ))}
                                <View style={{ alignSelf: 'center', marginVertical: 15, marginTop: 2 }}>
                                    {brandReviews?.length > visible &&
                                        <Text variant="contained" mode="outlined" style={{ fontWeight: 'bold', color: '#27b6cc' }} onPress={() => setVisible(visible + 10)}>Show More ▼</Text>
                                    }
                                </View>
                            </>
                        ))}
                </View>
            </ScrollView>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 1, shadowRadius: 5 }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            {/* For Add Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isReviewModalVisible}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 300,
                    borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5
                }}>
                    <View style={{
                        alignItems: 'flex-end',
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => setReviewModalVisible(false)}>
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                            <TextInput
                                activeUnderlineColor={esiColor.TextOuterLine}
                                selectionColor={esiColor.TextSelection}
                                theme={{
                                    colors: {
                                        primary: esiColor.TextHighlight,
                                        text: esiColor.Text,
                                        placeholder: esiColor.TextPlaceholder,
                                    }
                                }}
                                outlineColor={esiColor.TextOuterLine}
                                underlineColor={esiColor.TextUnderline}
                                activeOutlineColor={esiColor.TextOuterLine}
                                label='Add Review'
                                mode='outlined'
                                onChangeText={(Text) => { setComment(Text); handleOnChange(Text); setCommentError("") }}
                                multiline={true}
                                numberOfLines={4}
                                value={comment}
                                style={{ maxHeight: 150, minWidth: 250, maxWidth: 250, marginHorizontal: "auto", backgroundColor: esiColor.TextBC }}
                            />
                        </View>
                        <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <Rating
                                tintColor={esiColor.TintColor}
                                type='heart'
                                startingValue={rating}
                                ratingCount={5}
                                imageSize={30}
                                style={{ paddingVertical: 10 }}
                                onFinishRating={(value) => { setRating(value); setRatingError("") }}
                            />
                            {!rating || rating === 0 ? (
                                <Text style={{ color: "red" }}>
                                    Please select the Rating.
                                </Text>
                            ) : (null)
                            }
                            <View style={{ width: 100 }}>
                                <TouchableOpacity style={[styles.centerElement, { backgroundColor: esiColor.globalButtonColor, width: 100, height: 25, borderRadius: 5 }]} onPress={() => addReviewModal()}>
                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
            {/* For Edit Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isEditReviewModalVisible}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 300, borderRadius: 10,
                    backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5
                }}>
                    <View style={{
                        alignItems: 'flex-end',
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setEditReviewModalVisible(false), setComment(''), setRating(0) }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                            <TextInput
                                activeUnderlineColor={esiColor.TextOuterLine}
                                selectionColor={esiColor.TextSelection}
                                theme={{
                                    colors: {
                                        primary: esiColor.TextHighlight,
                                        text: esiColor.Text,
                                        placeholder: esiColor.TextPlaceholder,
                                    }
                                }}
                                outlineColor={esiColor.TextOuterLine}
                                underlineColor={esiColor.TextUnderline}
                                activeOutlineColor={esiColor.TextOuterLine}
                                label='Edit Review'
                                mode='outlined'
                                onChangeText={(Text) => { setComment(Text); editOnChange(Text); setCommentError("") }}
                                multiline={true}
                                numberOfLines={4}
                                value={comment}
                                style={{ maxHeight: 100, minWidth: 250, maxWidth: 250, marginHorizontal: "auto", backgroundColor: esiColor.TextBC }}
                            />
                        </View>
                        <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>

                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <Rating
                                tintColor={esiColor.TintColor}
                                type='heart'
                                startingValue={rating}
                                ratingCount={5}
                                imageSize={30}
                                style={{ paddingVertical: 10 }}
                                onFinishRating={(value) => { setRating(value); setRatingError("") }}
                            />
                            {!rating || rating === 0 ? (
                                <Text style={{ color: "red", fontSize: 16 }}>
                                    Please select the Rating.
                                </Text>
                            ) : (null)
                            }
                            <View style={{ width: 100 }}>
                                <TouchableOpacity style={[styles.centerElement, { backgroundColor: esiColor.globalButtonColor, width: 100, height: 25, borderRadius: 5 }]}
                                    onPress={() => editReviewModal()} >
                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </Surface>
    );
}


