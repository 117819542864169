import React, { useState } from 'react';
import { Card, Button, TextInput, HelperText, Checkbox, Surface } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    addPhysicalPartnerAction,
} from '../../../../state/actions/partnerproductsAction'
import EsiDatePicker from '../../../../components/custom/date/date';
import Header from '../../../auth/components/header';
import isEmpty from '../../../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddProductPartnerPagetwo = (props: any) => {

    const { navigation } = props;
    const toast = useToast();
    const dispatch = useDispatch();

    const productpartner = useSelector(state => state.productpartners.productpartnerdata.partner);
    const EmailID = useSelector(state => state.auth?.user);
    const productpartnersid = props.route?.params?.productspartners;
    const productmasterids=props.route?.params?.productmasterid;
    // Screen dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
  
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    React.useEffect(() => {
        if (isEmpty(productpartner)) {
            navigation.navigate('AddProductPartner', { productmasterid: productmasterids ,productspartners:productpartnersid})
        }
    }, [productpartner]
    );

    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    /*====================================
            Handler
    ====================================*/
    const values = {
        Price: productpartner.Price,
        Gift_Wrap_Amount: productpartner.Gift_Wrap_Amount,
        Discount_Type: productpartner.Discount_Type,
        Discount: productpartner.Discount,
        Esi_Cashback_Type: productpartner.Esi_Cashback_Type,
        Esi_Cashback: productpartner.Esi_Cashback,
        Esi_Commission: productpartner.Esi_Commission,
        Esi_Commission_Type: productpartner.Esi_Commission_Type,
        Product_Id: productpartner.Product_Id,
        Cashback_Label: productpartner.Cashback_Label,
        Discount_Label: productpartner.Discount_Label,
        Warranty_Period: productpartner.Warranty_Period,
        Discount_From_Date: "",
        Discount_To_Date: "",
        Max_Days_To_Return: "",
        Shipping_Amount: "",
        Created_By: EmailID.Email_Id,
        Is_Partner_Shipping_Available: false,
        Add_To_Cart: false,
        Exclusive: false,
        Extra_CashBack: false,
        Get_Coupon: false,
        Is_Default: false,
        Is_Esi_Shipping_Aavailable: false,
        Is_Gift_Wrap_Available: false,
        Is_Popular: false,
        Is_Gift_WrapIs_Shop_By_City_Only_Available: false,
        Is_Return_Available: false,
        Is_Upcoming: false,
        Partner_Address_Id: EmailID.Partner_Address_Id,
        Partner_Details_Id: EmailID?.Partner_Details_Id,
        Product_Master_ID:productpartner.Product_Master_Id,
        Status: "In-Active",
        Todays_Hot: false,
        Verified: false,
        What_Hot: false,
       
    }
    //Form validations
    const Form_Validation = Yup.object().shape({
        Discount_From_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Required From Date should be greater than or equal to today.").required("From Date is required."),
        Discount_To_Date: Yup.date().when("Discount_From_Date", (Discount_From_Date, schema) => Discount_From_Date && schema.min(Discount_From_Date, "To Date can't be before From Date.")).required("To Date is required."),
        Max_Days_To_Return: Yup.number().max(30, "Max Days To Return must be less than 30."),
    })
    const onSubmit = (values, { setSubmitting, setErrors,resetForm }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Add_To_Cart) {
                formValues.Add_To_Cart = 1;
            } else {
                formValues.Add_To_Cart = 0;
            }
            if (values.Is_Gift_Wrap_Available) {
                formValues.Is_Gift_Wrap_Available = 1;
            } else {
                formValues.Is_Gift_Wrap_Available = 0;
            }
            if (values.Is_Esi_Shipping_Aavailable) {
                formValues.Is_Esi_Shipping_Aavailable = 1;
            } else {
                formValues.Is_Esi_Shipping_Aavailable = 0;
            }
            if (values.Verified) {
                formValues.Verified = 1;
            } else {
                formValues.Verified = 0;
            }
            if (values.Get_Coupon) {
                formValues.Get_Coupon = 1;
            } else {
                formValues.Get_Coupon = 0;
            }
            if (values.Exclusive) {
                formValues.Exclusive = 1;
            } else {
                formValues.Exclusive = 0;
            }
            if (values.Is_Popular) {
                formValues.Is_Popular = 1;
            } else {
                formValues.Is_Popular = 0;
            }
            if (values.What_Hot) {
                formValues.What_Hot = 1;
            } else {
                formValues.What_Hot = 0;
            }
            if (values.Todays_Hot) {
                formValues.Todays_Hot = 1;
            } else {
                formValues.Todays_Hot = 0;
            }
            if (values.Extra_CashBack) {
                formValues.Extra_CashBack = 1;
            } else {
                formValues.Extra_CashBack = 0;
            }
            if (values.Is_Upcoming) {
                formValues.Is_Upcoming = 1;
            } else {
                formValues.Is_Upcoming = 0;
            }
            if (values.Is_Default) {
                formValues.Is_Default = 1;
            } else {
                formValues.Is_Default = 0;
            }
            if (values.Is_Esi_Shipping_Available) {
                formValues.Is_Esi_Shipping_Available = 1;
            } else {
                formValues.Is_Esi_Shipping_Available = 0;
            }
            if (values.Is_Partner_Shipping_Available) {
                formValues.Is_Partner_Shipping_Available = 1;
            } else {
                formValues.Is_Partner_Shipping_Available = 0;
            }
            if (values.Is_Return_Available) {
                formValues.Is_Return_Available = 1;
            } else {
                formValues.Is_Return_Available = 0;
            }
            if (values.Is_Shop_By_City_Only) {
                formValues.Is_Shop_By_City_Only = 1;
            } else {
                formValues.Is_Shop_By_City_Only = 0;
            }
            dispatch(addPhysicalPartnerAction(formValues, props.navigation, toast,productpartnersid,productmasterids));
        }
    }
    return (
        <Surface style={{
            flex: 1
        }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('AddProductPartner',{productmasterid:productmasterids,productspartners:productpartnersid})} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#d6d6d7' }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Add Product Partner
                                    </Text>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <EsiDatePicker
                                                    label={'Discount From Date'}
                                                    style={{ width: "100%", backgroundColor: 'white',height:50 }}
                                                    value={props.values.Discount_From_Date}
                                                    valueUpdate={(date) => {
                                                        props.setFieldValue('Discount_From_Date', date);
                                                    }} />
                                                <HelperText type="error" visible={Boolean(props.touched.Discount_From_Date && props.errors.Discount_From_Date)}>
                                                    {props.touched.Discount_From_Date && props.errors.Discount_From_Date}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <EsiDatePicker
                                                    style={{ width: "100%", backgroundColor: 'white' }} label={'Discount to Date'} value={props.values.Discount_To_Date} valueUpdate={(date) => {
                                                        props.setFieldValue('Discount_To_Date', date);
                                                    }} />
                                                <HelperText type="error" visible={Boolean(props.touched.Discount_To_Date && props.errors.Discount_To_Date)}>
                                                    {props.touched.Discount_To_Date && props.errors.Discount_To_Date}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(props.touched.Max_Days_To_Return && props.errors.Max_Days_To_Return)}
                                                    label="Max Days To Return"
                                                    mode='outlined'
                                                    value={props.values.Max_Days_To_Return}
                                                    onChangeText={props.handleChange("Max_Days_To_Return")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Max_Days_To_Return && props.errors.Max_Days_To_Return)}>
                                                    {props.touched.Max_Days_To_Return && props.errors.Max_Days_To_Return}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(props.touched.Shipping_Amount && props.errors.Shipping_Amount)}
                                                    label="Shipping Amount"
                                                    mode='outlined'
                                                    value={props.values.Shipping_Amount}
                                                    onChangeText={props.handleChange("Shipping_Amount")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Shipping_Amount && props.errors.Shipping_Amount)}>
                                                    {props.touched.Shipping_Amount && props.errors.Shipping_Amount}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={styles.checkbox}>
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Is_Partner_Shipping_Available"
                                                status={props.values.Is_Partner_Shipping_Available ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Is_Partner_Shipping_Available", !props.values.Is_Partner_Shipping_Available);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, marginRight: '3%' }}>Is Partner Shipping Available</Text>
                                        </View>
                                        <View style={styles.checkbox}>
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Get_Coupon"
                                                status={props.values.Get_Coupon ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Get_Coupon", !props.values.Get_Coupon);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, marginRight: '3%' }}>Get Coupon</Text>                                            
                                        </View>
                                        <View style={styles.checkbox}>
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Is_Return_Available"
                                                status={props.values.Is_Return_Available ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Is_Return_Available", !props.values.Is_Return_Available);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, marginRight: '3%' }}>Is Return Available</Text>
                                        </View>
                                        <View style={styles.checkbox}>
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Is_Gift_Wrap_Available"
                                                status={props.values.Is_Gift_Wrap_Available ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Is_Gift_Wrap_Available", !props.values.Is_Gift_Wrap_Available);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, marginRight: '3%' }}>Is Gift Wrap Available</Text>
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Is_Shop_By_City_Only"
                                                status={props.values.Is_Shop_By_City_Only ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Is_Shop_By_City_Only", !props.values.Is_Shop_By_City_Only);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10, marginRight: '3%' }}>Is Shop By City Only</Text>
                                        </View>

                                        <Button style={{ width: 130, marginTop: "3%", marginLeft: "auto", marginRight: "auto", backgroundColor: '#27B6CC' }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface>
    );
};
const styles = StyleSheet.create({
    cardCircle: {
        width: (Platform.OS === 'web') ? "75%" : "95%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2%",
        marginBottom: "2%",
        borderRadius: 6,
        borderWidth: 0.01,
        borderColor: '#D6D6D7'
    }, datePickerStyle: {
        width: 350,
        height: 60,
        marginTop: 6,
    },
    itemContainer: {
        justifyContent: 'flex-end',
        flex: 1
    },
    images: {
        width: '100%',
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    checkbox: {
        flexDirection: "row",
        justifyContent: 'flex-start'
    },
    label: {
        marginBottom: 30,
    },
    editor: {
        backgroundColor: "black",
        borderColor: "black",
        borderWidth: 1,
    },
    rich: {
        minHeight: 130,
    },
    richBar: {
        height: 50,
        backgroundColor: "#F5FCFF",
    },
    text: {
        fontWeight: "bold",
        fontSize: 18,
    },
    tib: {
        textAlign: "center",
        color: "#515156",
    },
    a: {
        fontWeight: "bold",
        color: "purple",
    },
    div: {
        fontFamily: "monospace",
    },
    p: {
        fontSize: 30,
    }, container: {
        backgroundColor: 'white',
        paddingTop: 15
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});

export default AddProductPartnerPagetwo;
