import React, { useState, useEffect } from 'react';
import { Card, Button, TextInput, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, ActivityIndicator, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import {
    getAllBrands,
    getAllCategories,
    getAllSubCategories,
    productsdata,
    getAllMasters,
    getAllColours,
    getAllSizes,
    getSingleProduct,
    subcategoryActions
} from '../../../../state/actions/productsAction';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../auth/components/header';
import EsiCountry from '../../../../components/custom/country-state-city/country';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const EditProduct = (props: any) => {
    const [errorMessage, setErrorMessage] = useState({ Product_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    const { navigation } = props;
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const product_id = props.route?.params?.product_id;
    const EmailID = useSelector(state => state.auth?.user);

    const dispatch = useDispatch();

    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Products_Partner_Products_Related_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const esiColor = useSelector(state => state.theme);
    const colours = useSelector(state => state.product.productColours.colours);
    const categories = useSelector(state => state.product.productCategories.categories);
    const subcategories = useSelector(state => state.product.productSubCategories.subcategories);
    const brands = useSelector(state => state.product.productBrands.brands);
    const sizes = useSelector(state => state.product.productSizes.sizes);

    const [showDropDown, setShowDropDown] = React.useState('');
    const [showDropDownbrand, setShowDropDownbrand] = React.useState('');
    const [showDropDownsubcategory, setShowDropDownsubcategory] = React.useState('');
    const [showDropDowncolor, setShowDropDowncolor] = React.useState('');
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "PRODUCT_ID", SortOrder: "DESC" }
    const [productmaster, setProductmaster] = useState({});
    const singleProductCallBack = (status, data) => {
        setGetLoading(false);
        if (status) {
            setProductmaster(data);
        }
    }
    const [subcategoriess, setSubData] = React.useState([]);
    const callBackData = (data: any) => {
        setSubData(data)
    }
    const CID = props?.route?.params?.CID;
    const brand_id = props.route?.params?.brand;
    const Product_Master_id = props.route?.params?.productmaster;
    const [getLoading, setGetLoading] = useState(false);
    React.useEffect(() => {
        if (CID) {
            let formData = {
                "search_by_filter": "",
                "search": "",
                "Category_Id": "",
                "Sub_Category_Id": CID,
                "Category_Name": "",
                "Sub_Category_Name": "",
                "Records_Filter": "FILTER"
            }
            dispatch(subcategoryActions(formData, callBackData))
        }
    }, [CID]);

    const setType = (forsubcategory) => {
        if (forsubcategory !== "") {
            let formData = {

                "search_by_filter": "",
                "search": "",
                "Category_Id": forsubcategory,
                "Sub_Category_Id": "",
                "Category_Name": "",
                "Sub_Category_Name": "",
                "Records_Filter": "FILTER"

            }
            dispatch(subcategoryActions(formData, callBackData))
        }
    }
    let sizesopt = []
    if (sizes) {
        if (sizes[0]) {
            for (let i = 0; i < sizes.length; i++) {
                sizesopt.push(
                    {
                        label: sizes[i].Size,
                        value: sizes[i].Product_Size_Id
                    })
            }
        }
    }
    useEffect(() => {
        if (productmaster?.Product_Id != product_id && !getLoading) {
            setGetLoading(true);
            dispatch(getAllCategories(CID));
            dispatch(getAllBrands(brand_id));
            dispatch(getAllSubCategories(CID));
            dispatch(getAllBrands(brand_id));
            dispatch(getAllCategories(CID));
            dispatch(getAllSubCategories(CID));
            dispatch(getAllMasters());
            dispatch(getAllColours(Product_Master_id));
            dispatch(getAllSizes());
            let formdata = {
                "search_by_filter": "",
                "search": "",
                "Price": "",
                "Partner_Details_Id": "",
                "Records_Filter": "FILTER",
                "Product_Id": product_id
            }
            dispatch(getSingleProduct(formdata, pagecall, singleProductCallBack))
        }
    }, [product_id]);

    useEffect(() => {
        if (productmaster) {
            dispatch(getAllColours(productmaster?.Product_Master_Id, () => { }));
            dispatch(getAllSizes());
        }
    }, [productmaster]);

    //sleep function
    const [reSeconds, setReSeconds] = React.useState(3);
    React.useEffect(() => {
        if (Platform.OS === 'web') {
            setReSeconds(0)
        }
        else {
            const timer = setTimeout(() => reSeconds > 0 ? setReSeconds(reSeconds - 1) : setReSeconds(-1), 1000)
            return () => clearTimeout(timer)
        }
    }, [reSeconds]);
    let categoryopt = []
    let subcategorieopt = []
    let brandopt = []
    if (categories) {
        if (categories[0]) {
            for (
                let i = 0;
                i < categories.length;
                i++) {
                categoryopt.push({
                    label: categories[i].Category_Name,
                    value: categories[i].Category_Id
                })
            }

        }

    }
    if (subcategories) {
        if (subcategories[0]) {
            for (let i = 0; i < subcategories.length; i++) {
                subcategorieopt.push(
                    {
                        label: subcategories[i].Sub_Category_Name,
                        value: subcategories[i].Sub_Category_Id,
                        Category_Id: subcategories[i].Category_Id
                    })
            }

        }

    }

    if (brands) {
        if (brands[0]) {
            for (let i = 0; i < brands.length; i++) {
                brandopt.push({
                    label: brands[i].Brand_Name,
                    value: brands[i].Brand_Id
                })
            }

        }

    }
    let colorsopt = []
    if (colours) {
        if (colours[0]) {
            for (let i = 0; i < colours.length; i++) {
                colorsopt.push(
                    {
                        label: colours[i].Color,
                        value: colours[i].Product_Color_Variant_Id
                    })
            }
        }
    }
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const getFormikValuesBack = (productmaster) => {
        return {
            Product_Name: productmaster.Product_Name,
            Category_Id: productmaster.Category_Id,
            Product_Id: productmaster.Product_Id,
            Brand_Id: productmaster.Brand_Id,
            Product_Short_Description: productmaster.Product_Short_Description,
            Product_Description: productmaster.Product_Description,
            Features: productmaster.Features,
            Specifications: productmaster.Specifications,
            Product_Price: productmaster.Price,
            Product_Size_Id: productmaster.Product_Size_Id,
            Product_Color_Variant_Id: productmaster.Product_Color_Variant_Id,
            Product_Master_Id: productmaster.Product_Master_Id,
            Is_Popular: productmaster.Is_Popular > 0 ? true : false,
            Whats_Hot: productmaster.Whats_Hot > 0 ? true : false,
            Is_Top_Product: productmaster.Is_Top_Product > 0 ? true : false,
            Esi_Review: productmaster.Esi_Reviews,
            Esi_Rating: parseInt(productmaster.Esi_Rating),
            Country_Of_Origin: productmaster.Country_Of_Origin,
            Status: productmaster.Status,
            Is_Upcoming: productmaster.Is_Upcoming > 0 ? true : false,
            Is_Verified: productmaster.Is_Verified > 0 ? true : false,
            Sub_Category_Id: productmaster.Sub_Category_Id,
            Modified_By_Email: EmailID.Email_Id,
            Warranty_Period: productmaster.Warranty_Period,
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        button: {
            marginTop: 30,
            width: '75%',
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'green',
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1,
            marginBottom: "2%"
        },

        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        checkcontainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: "-70%"
        },
        countrydropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            left: 22,
            top: 0.5,
            color: esiColor.SIconColor,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        phonenumbercontainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        phoneContainer: {
            width: '100%',
            height: 50,
        },
        phonetextInput: {
            paddingVertical: 0,
        },
        editor: {
            backgroundColor: "black",
            borderColor: "black",
            borderWidth: 1,
        },
        rich: {
            minHeight: 130,
            // flex: 1,
        },
        richBar: {
            height: 50,
            backgroundColor: "#F5FCFF",
        },
        text: {
            fontWeight: "bold",
            fontSize: 18,
        },
        tib: {
            textAlign: "center",
            color: "#515156",
        },
        a: {
            fontWeight: "bold",
            color: "purple",
        },
        div: {
            fontFamily: "monospace",
        },
        p: {
            fontSize: 30,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            left: 22,
            top: 0.5,
            color: esiColor.SIconColor,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });


    return (
        <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }}  >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }
            >
                {getLoading ? <ActivityIndicator /> :
                    (productmaster?.Product_Id && <Formik
                        initialValues={
                            getFormikValuesBack(productmaster)
                        }
                        validationSchema={Yup.object().shape({
                            Brand_Id: Yup.string().required('Brand Name is required.'),
                            Category_Id: Yup.string().required('Category Name is required.'),
                            Country_Of_Origin: Yup.string().required('Country Of Origin is required.'),
                            Product_Color_Variant_Id: Yup.string().required('Product Color is required.'),
                            Product_Master_Id: Yup.string().required('Product Master Name is required.'),
                            Product_Name: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Product Name.').required('Product Name is required.'),
                            Product_Price: Yup.string().matches(/^\d+(\.\d{1,2})?$/,'Please enter valued Product Price').required('Product Price is required.'),
                            Product_Short_Description: Yup.string().required('Product Short Description is required.'),
                            Product_Size_Id: Yup.string().required('Product Size is required.'),
                            Sub_Category_Id: Yup.string().required('Sub Category Name is required.'),
                            Warranty_Period: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Warranty Period.').required('Warranty Period is required.'),
                        })}

                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            let submitTest = true;
                            setSubmitting(false);
                            const errors = {};
                            setErrors(errors);
                            if (submitTest) {
                                let formValues = JSON.parse(JSON.stringify(values));
                                dispatch(productsdata(formValues, navigation));
                                navigation.navigate('EditProducts', { product_id: product_id })
                            }
                        }
                        }
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            resetForm
                        }) => (
                            <View style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                flexWrap: "wrap"
                            }}>
                                <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%" }}>
                                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                        onPress={() => {
                                            resetForm();
                                            navigation.navigate('Products')
                                        }} >
                                        Go Back
                                    </Button>
                                </View>
                                <Card style={{
                                    width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                    marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                    backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                                }}>
                                    <Card.Content>
                                        <Text style={{ fontSize: 20, fontWeight: "bold", marginBottom: '1%', color: esiColor.brandFontColor }}>
                                            Edit Product
                                        </Text>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                                                <TextInput
                                                    // theme={customTheme}
                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Product_Name && errors.Product_Name)}
                                                    label="Product Name"
                                                    mode='outlined'
                                                    value={values.Product_Name}
                                                    onChangeText={handleChange("Product_Name")}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Product_Name && errors.Product_Name)}>
                                                    {touched.Product_Name && errors.Product_Name}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                <TextInput
                                                    // theme={customTheme}
                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Product_Master_Id && errors.Product_Master_Id)}
                                                    label="Product Master Name"
                                                    mode='outlined'
                                                    disabled
                                                    value={values.Product_Master_Id}
                                                    onChangeText={handleChange("Product_Master_Id")}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Product_Master_Id && errors.Product_Master_Id)}>
                                                    {touched.Product_Master_Id && errors.Product_Master_Id}
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                                                {/* <View style={styles.container}> */}
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Category_Id && errors.Category_Id) && { color: '#b00020' }]}>
                                                        Category Name
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Category_Id && errors.Category_Id) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={categoryopt}
                                                        maxHeight={180}
                                                        labelField="label"
                                                        valueField="value"
                                                        search
                                                        disable
                                                        searchPlaceholder={'Select Category Name'}
                                                        value={values.Category_Id}
                                                        onUpdateValue={item => {
                                                            setType(item?.value ? item.value : "");
                                                            setFieldValue('Category_Id', item.value)
                                                            setShowDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Category_Id && errors.Category_Id)}>
                                                        {touched.Category_Id && errors.Category_Id}
                                                    </HelperText>
                                                </View>
                                            {/* </View> */}
                                            <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                {/* <View style={styles.container}> */}
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id) && { color: '#b00020' }]}>
                                                        Sub Category
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={subcategorieopt}
                                                        maxHeight={180}
                                                        labelField="label"
                                                        valueField="value"
                                                        placeholder={!showDropDownsubcategory ? 'Select item' : '...'}
                                                        search
                                                        disable
                                                        searchPlaceholder={'Select Sub Category'}
                                                        value={values.Sub_Category_Id}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Sub_Category_Id', item.value)
                                                            setShowDropDownsubcategory(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id)}>
                                                        {touched.Sub_Category_Id && errors.Sub_Category_Id}
                                                    </HelperText>
                                                </View>
                                            {/* </View> */}
                                        </View>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                {/* <View style={styles.container}> */}
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Brand_Id && errors.Brand_Id) && { color: '#b00020' }]}>
                                                        Brand Name
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Brand_Id && errors.Brand_Id) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={brandopt}
                                                        maxHeight={180}
                                                        labelField="label"
                                                        valueField="value"
                                                        placeholder={!showDropDownbrand ? 'Select item' : '...'}
                                                        search
                                                        disable
                                                        searchPlaceholder={'Select Casback Lable'}
                                                        value={values.Brand_Id}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Brand_Id', item.value)
                                                            setShowDropDownbrand(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Brand_Id && errors.Brand_Id)}>
                                                        {touched.Brand_Id && errors.Brand_Id}
                                                    </HelperText>
                                                {/* </View> */}
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                {/* <View style={styles.container}> */}
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id) && { color: '#b00020' }]}>
                                                        Color Name
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={colorsopt}
                                                        labelField="label"
                                                        valueField="value"
                                                        placeholder={!showDropDowncolor ? 'Select item' : '...'}
                                                        maxHeight={colorsopt.length > 1 ? 250 : 200}
                                                        search={colorsopt.length > 1 ? true : false}
                                                        searchPlaceholder={'Select  Color Name'}
                                                        value={values.Product_Color_Variant_Id}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Product_Color_Variant_Id', item.value)
                                                            setShowDropDowncolor(false);
                                                        }}
                                                    />

                                                    <HelperText type="error" visible={Boolean(touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id)}>
                                                        {touched.Product_Color_Variant_Id && errors.Product_Color_Variant_Id}
                                                    </HelperText>
                                                </View>
                                            {/* </View> */}
                                        </View>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                {/* <View style={styles.container}> */}
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Product_Size_Id && errors.Product_Size_Id) && { color: '#b00020' }]}>
                                                        Product Size
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Product_Size_Id && errors.Product_Size_Id) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={sizesopt}
                                                        labelField="label"
                                                        valueField="value"
                                                        placeholder={!showDropDowncolor ? 'Select item' : '...'}
                                                        maxHeight={colorsopt.length > 1 ? 250 : 200}
                                                        search={colorsopt.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Size'}
                                                        value={values.Product_Size_Id}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Product_Size_Id', item.value)
                                                            setShowDropDowncolor(false);
                                                        }}
                                                    />

                                                    <HelperText type="error" visible={Boolean(touched.Product_Size_Id && errors.Product_Size_Id)}>
                                                        {touched.Product_Size_Id && errors.Product_Size_Id}
                                                    </HelperText>
                                                </View>
                                            {/* </View> */}
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                <TextInput
                                                    // theme={customTheme}
                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Product_Price && errors.Product_Price)}
                                                    label="Product Price"
                                                    mode='outlined'
                                                    value={(values.Product_Price)?.toString()}
                                                    onChangeText={handleChange("Product_Price")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Product_Price && errors.Product_Price)}>
                                                    {touched.Product_Price && errors.Product_Price}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: '1%' }}>
                                                <Text style={[styles.countrydropdownlabel, Boolean(touched.Country_Of_Origin && errors.Country_Of_Origin) && { color: '#b00020' }]}>
                                                    Country
                                                </Text>
                                                {/*  country input field with validation*/}
                                                <EsiCountry
                                                    error={Boolean(touched.Country_Of_Origin && errors.Country_Of_Origin)}
                                                    labelField="NAME"
                                                    valueField="COUNTRY_CODE"
                                                    value={values.Country_Of_Origin}
                                                    name="Country_Of_Origin"
                                                    search
                                                    disable
                                                    searchPlaceholder={'Select Country'}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Country_Of_Origin', item.COUNTRY_CODE);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Country_Of_Origin && errors.Country_Of_Origin)}>
                                                    <ErrorMessage name="Country_Of_Origin" />
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                <TextInput
                                                    // theme={customTheme}
                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Product_Short_Description && errors.Product_Short_Description)}
                                                    label="Short Description"
                                                    mode='outlined'
                                                    value={values.Product_Short_Description}
                                                    onChangeText={handleChange("Product_Short_Description")}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Product_Short_Description && errors.Product_Short_Description)}>
                                                    {touched.Product_Short_Description && errors.Product_Short_Description}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexGrow: 1, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <TextInput
                                                // theme={customTheme}
                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Warranty_Period && errors.Warranty_Period)}
                                                label="Warranty Period"
                                                mode='outlined'
                                                multiline
                                                value={values.Warranty_Period}
                                                onChangeText={handleChange("Warranty_Period")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Warranty_Period && errors.Warranty_Period)}>
                                                {touched.Warranty_Period && errors.Warranty_Period}
                                            </HelperText>
                                        </View>
                                    </Card.Content>
                                    <View style={{ marginLeft: "1%" }}>
                                        <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", marginVertical: 10 }}>
                                        <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                            Next
                                        </Button>
                                    </View>
                                </Card>
                            </View>
                        )}
                    </Formik>
                    )}
            </ScrollView >
        </Surface >
    );
};

export default EditProduct;