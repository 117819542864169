import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Image, Dimensions, RefreshControl } from 'react-native';
import { Dialog, Card, DataTable, Button, Text, Searchbar, Portal, Avatar, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import isEmpty from '../../../state/validations/is-empty';
import Header from '../../auth/components/header';
import { getAllGiftImagesAction, giftimagesFilter, resetFilter } from '../../../state/actions/giftimageAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Gift_Image_Id',
    label: 'Gift Image ID'
  },
  {
    value: 'Occasion',
    label: 'Occasion'
  },
  {
    value: 'Vendor_Name',
    label: 'Vendor Name'
  },
  {
    value: 'Status',
    label: 'Status'
  }
];
const numberOfItemsPerPageList = [5, 10, 20];
function ImagesGet(props: any) {
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);
  //toast for popup after successful API calls.
  const toast = useToast();
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);

  const [page, setPage] = React.useState(0);

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const vendorid = props.route?.params?.giftsdetails;
  const vendorname = props.route?.params?.vendorname;
  const allgiftimages = useSelector(state => state.giftimage.giftimages.all);
  let imagesMenu = useSelector(state => state.giftimage.giftimagesFilter.select);
  let imagesSearch = useSelector(state => state.giftimage.giftimagesFilter.search);

  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, allgiftimages?.length);

  const [showDropDown, setShowDropDown] = React.useState(false);
  const [images, setImages] = React.useState();
  const dispatch = useDispatch()
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (vendorid) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Gift_Vendor_Id": vendorid,
        "Occassion": "",
        "Records_Filter": "FILTER",
        "Gift_Image_Id": ""
      };
      handleSearch("");
      dispatch(getAllGiftImagesAction(formData, toast))
      dispatch(giftimagesFilter('search', ''));
      dispatch(giftimagesFilter('select', 'All'));
    }
  }, []);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [oldSearch, setOldSearch] = React.useState(0);

  const checkImagesForTable = () => {

    if (imagesSearch) {
      try {
        if (imagesMenu === "All") {
          return allgiftimages.filter(x => {
            let keys = ["Occassion", "Gift_Image_Id", "Type", "Status", "Vendor_Name"];
            for (let i = 0; i < keys.length; i++) {
              try {
                if (x[keys[i]].toLowerCase().includes(imagesSearch.toLowerCase())) {
                  return true;
                }
              } catch (error) {
                if (x[keys[i]].toString().toLowerCase().includes(imagesSearch.toLowerCase())) {
                  return true;
                }
              }
            }
          });

        } else {
          return allgiftimages.filter(x => (x[imagesMenu] ? x[imagesMenu].toLowerCase() : '').includes(imagesSearch.toLowerCase()));
        }
      } finally {
        if (page > 0) {
          if (oldSearch !== imagesSearch.length) {
            setOldSearch(imagesSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch !== imagesSearch.length) {
          setOldSearch(imagesSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return allgiftimages
    }
  }

  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Gift_Image_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'descending'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {

      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const [visible, setVisible] = React.useState(false);
  const [imagedata, setData] = React.useState();

  const hideDialog = () => setVisible(false);

  const handleChange = itemValue => {
    dispatch(giftimagesFilter('select', itemValue));
  };
  const handleSearch = value => {
    dispatch(giftimagesFilter('search', value));
  };
  //navigations
  const handleClickedit = async (images) => {
    navigation.navigate('EditImage', { Images: images.Vendor_Id, Image_Id: images.Gift_Image_Id })
  };

  const data = stableSort(
    checkImagesForTable(), getComparator(order, orderBy))
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const style = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      width: 160,
      fontSize: 35,
      alignItems: 'center',
      justifyContent: 'center',
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },

    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'center',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 150
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation}></Header>
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: (Platform.OS === "web") ? "80%" : "90%", maxHeight: (dimensions >= 500) ? "auto" : 500, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ flexDirection: "row" }}><View style={{ flex: dimensions <= 500 ? 1 : 3 }} /><View style={{ flex: dimensions <= 500 ? 1 : 3 }} /><View style={{ flex: 0.3, justifyContent: "flex-end", paddingTop: 10 }}>
            <IconButton icon="close" color={esiColor.GBFColor} size={20} onPress={() => setVisible(!visible)} />
          </View></View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>View Image</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={onRefresh}
                colors={['#27B6CC']}
                tintColor={'#27B6CC'}
                title={"Pull to refresh"}
                titleColor={"#27B6CC"}
              />
            } contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Dialog.Content style={{ marginLeft: -30 }}>
                <View style={{ flexDirection: "column", paddingTop: 15 }}>
                  <View style={{ flexDirection: "row", paddingTop: 15 }}>
                    <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Vendor ID</Text></View>
                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{imagedata?.Gift_Vendor_Id} </Text></View>
                  </View>
                  <View style={{ flexDirection: "row", paddingTop: 15 }}>
                    <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Gift Image ID</Text></View>
                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{imagedata?.Gift_Image_Id} </Text></View>
                  </View>
                  <View style={{ flexDirection: "row", paddingTop: 15 }}>
                    <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Occasion</Text></View>
                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{imagedata?.Occassion} </Text></View>
                  </View>
                  <View style={{ flexDirection: "row", paddingTop: 15 }}>
                    <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Vendor Name</Text></View>
                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{imagedata?.Vendor_Name} </Text></View>
                  </View>
                  <View style={{ flexDirection: "row", paddingTop: 15 }}>
                    <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View>
                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }} >{imagedata?.Created_Date} </Text></View>
                  </View>
                  <View style={{ flexDirection: "row", paddingTop: 15 }}>
                    <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Vendor Name</Text></View>
                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{imagedata?.Vendor_Name} </Text></View>
                  </View>

                  <View style={{ flexDirection: "row", paddingTop: 15 }}>
                    <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Type</Text></View>
                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{imagedata?.Type} </Text></View>
                  </View>
                  <View style={{ flexDirection: "row", paddingTop: 15 }}>
                    <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                    <View style={{ flex: 2.5 }}><Text style={{ color: imagedata?.Status === "Active" ? esiColor.GBFColor : "red" }}>{imagedata?.Status} </Text></View>
                  </View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View>
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
          navigation.navigate('GiftVendorsGet', { vendorname: vendorname })
          handleSearch("")
          dispatch(resetFilter())
        }} >
          Go Back
        </Button>
      </View>
      <ScrollView>
        <View style={{
          flexDirection: "column"
        }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Gift Images</Text>
            <View style={{ marginRight: 10, paddingTop: 20 }}>
              <View style={{ flex: 1.4 }} />
              {(permissions.Gifts_Management_Vendors_Images_User_Gifts === "write") && (dimensions <= 700 ?
                <View style={{ flex: 1, paddingTop: 8, width: 130, marginBottom: 10 }}>
                  <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => navigation.navigate('AddImage', { allimagesdata: vendorid, vendorname: vendorname })}>
                    Add Image
                  </Button>
                </View>
                : null)}
              <View style={{ flex: 0.1 }} />
            </View>
          </View>
        </View>
        <View style={{
          flexDirection: "row", paddingTop: 20, marginLeft: 5
        }}>
          <View style={{ flex: dimensions <= 700 ? 0.08 : 0.04 }} />
          <View style={{ flex: dimensions <= 700 ? 1.5 : 0.5 }}>
            {Platform.OS !== "web" ?
              <EsiSearchDropdown
                style={[style.dropdown]}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                inputSearchStyle={style.inputSearchStyle}
                iconStyle={style.iconStyle}
                data={filterOptions}
                containerStyle={style.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={imagesMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
              :
              <Picker
                selectedValue={imagesMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Gift Image ID" value="Gift_Image_Id" />
                <Picker.Item label="Occassion" value="Occassion" />
                <Picker.Item label="Type" value="Type" />
                <Picker.Item label="Vendor Name" value="Vendor_Name" />
                <Picker.Item label="Status" value="Status" />
              </Picker>}
          </View>
          <View style={{ flex: dimensions <= 700 ? 0.1 : 0.6 }} />
          <View style={{ flex: 1.8 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              onChangeText={(value) => handleSearch(value)}
              value={imagesSearch}
            />
          </View>
          <View style={{ flex: dimensions <= 700 ? 0.1 : 1 }} />
          {(permissions.Gifts_Management_Vendors_Images_User_Gifts === "write") && (dimensions >= 700 ?
            <View style={{ flex: 0.5, paddingTop: 8, width: 100, marginBottom: 10 }}>
              <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => navigation.navigate('AddImage', { allimagesdata: vendorid, vendorname: vendorname })}>
                Add Image
              </Button>
            </View>
            : null)}

          <View style={{ flex: dimensions <= 700 ? null : 0.07 }} />
        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={style.mainbox}>
            <Card>
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={style.databeHeader}>
                  <DataTable.Title style={style.title} active={orderBy === 'Gift_Image_Id'} sortDirection={orderBy === 'Gift_Image_Id' ? order : 'ascending'} onPress={createSortHandler('Gift_Image_Id')}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Gift Image ID</Text></DataTable.Title>
                  <DataTable.Title style={style.title} ><Text style={{ fontSize: 14, fontWeight: "bold" }}>Image</Text></DataTable.Title>
                  <DataTable.Title style={style.title} active={orderBy === 'Occassion'} sortDirection={orderBy === 'Occassion' ? order : 'ascending'} onPress={createSortHandler('Occassion')}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Occassion</Text></DataTable.Title>
                  <DataTable.Title style={style.title} active={orderBy === 'Type'} sortDirection={orderBy === 'Type' ? order : 'Type'} onPress={createSortHandler('Type')}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Type</Text></DataTable.Title>
                  <DataTable.Title style={style.title} active={orderBy === 'Vendor_Name'} sortDirection={orderBy === 'Vendor_Name' ? order : 'Vendor_Name'} onPress={createSortHandler('Vendor_Name')}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Vendor Name</Text></DataTable.Title>
                  <DataTable.Title style={style.title} active={orderBy === 'Status'} sortDirection={orderBy === 'Status' ? order : 'ascending'} onPress={createSortHandler('Status')}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Status</Text></DataTable.Title>
                  <DataTable.Title theme={customTheme} style={style.title}><Text style={{ fontSize: 14, fontWeight: "bold" }}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {
                  data?.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage)?.map((images, index) => (
                    <DataTable.Row style={style.databeBox} key={images.Gift_Image_Id}>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{images.Gift_Image_Id}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Avatar.Image size={45} source={{ uri: images.Image_URL }} /></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{images.Occassion}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{images.Type}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{images.Vendor_Name}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{images.Status}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}>
                        {(permissions.Gifts_Management_Vendors_Images_User_Gifts === "write") && <IconButton icon="square-edit-outline" iconColor={esiColor.SIconColor} onPress={(e) => { handleClickedit(images); }} />}
                        <Button icon="eye" textColor={esiColor.SIconColor} onPress={(e) => { setData(images); setVisible(!visible) }} />
                      </DataTable.Cell>
                    </DataTable.Row>
                  ))
                }
                {isEmpty(data) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                <DataTable.Pagination
                  // theme={customTheme}
                  page={page}
                  numberOfPages={Math.ceil(allgiftimages?.length / numberOfItemsPerPage)}
                  onPageChange={page => setPage(to < data?.length ? page : 0)}
                  label={allgiftimages?.length ? `${from + 1}-${to} of ${data?.length}` : `0-0`}
                  showFastPaginationControls={to > data?.length ? false : true}
                  numberOfItemsPerPageList={numberOfItemsPerPageList}
                  numberOfItemsPerPage={numberOfItemsPerPage}
                  onItemsPerPageChange={onItemsPerPageChange}
                  selectPageDropdownLabel={'Rows per page'}
                />
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>

  );
}

export default ImagesGet