import {
    RELATEDPRODUCTS_REQUEST,
    RELATEDPRODUCTS_SUCCESS,
    RELATEDPRODUCTS_FAIL, 
    
  RELATED_PRODUCT_CREATE_REQUEST, 
  RELATED_PRODUCT_CREATE_SUCCESS, 
  RELATED_PRODUCT_CREATE_FAIL,

  PRODUCT_FILTER_REQUEST,
  PRODUCT_FILTER_SUCCESS,
  PRODUCT_FILTER_FAIL,

  RELATEDPRODUCTS_FILTER,
    RELATED_PRODUCT_UPDATE_REQUEST,
    RELATED_PRODUCT_UPDATE_SUCCESS,
    RELATED_PRODUCT_UPDATE_FAIL,
} from '../actions/relatedproductsAction'
const initialState = {
    relatedproducts: { all: [], error: '', isLoading: false },
    relatedproductssearch: { all: [], error: '', isLoading: false },
    relatedproductscreate: { all: [], error: '', isLoading: false },
    relatedproductUpdate: { partner: {}, error: '', isLoading: false },
    relatedproductFilter: { select: 'All', search: '' },

};
export default function (state = initialState, action) {
    switch (action.type) {
      case RELATED_PRODUCT_UPDATE_REQUEST:
      return { ...state, relatedproductUpdate: { partner: {}, error: '', isLoading: true } };
    case RELATED_PRODUCT_UPDATE_SUCCESS:
      return { ...state, relatedproductUpdate: { partner: state.relatedproductUpdate.partner, error: '', isLoading: false } };
    case RELATED_PRODUCT_UPDATE_FAIL:
      return { ...state, relatedproductUpdate: { partner: {}, error: action.payload, isLoading: false } };
      case PRODUCT_FILTER_REQUEST:
        return { ...state, relatedproductssearch: { all: state.relatedproductssearch.all, error: '', isLoading: true } };
      case PRODUCT_FILTER_SUCCESS:
        return { ...state, relatedproductssearch: { all: action.payload, error: '', isLoading: false } };
      case PRODUCT_FILTER_FAIL:
        return { ...state, relatedproductssearch: { all: [], error: action.payload, isLoading: false } };
      
      case RELATEDPRODUCTS_REQUEST:
        return { ...state, relatedproducts: { all: state.relatedproducts.all, error: '', isLoading: true } };
      case RELATEDPRODUCTS_SUCCESS:
        return { ...state, relatedproducts: { all: action.payload, error: '', isLoading: false } };
      case RELATEDPRODUCTS_FAIL:
        return { ...state, relatedproducts: { all: [], error: action.payload, isLoading: false } };
        case RELATED_PRODUCT_CREATE_REQUEST:
            return { ...state, relatedproductscreate: { all: state.relatedproductscreate.all, error: '', isLoading: true } };
          case RELATED_PRODUCT_CREATE_SUCCESS:
            return { ...state, relatedproductscreate: { all: action.payload, error: '', isLoading: false } };
          case RELATED_PRODUCT_CREATE_FAIL:
            return { ...state, relatedproductscreate: { all: [], error: action.payload, isLoading: false } };
            case RELATEDPRODUCTS_FILTER:
                    let res = { ...state };
                    if (action.fType === "select") {
                      res = { ...state, relatedproductFilter: { search: state.relatedproductFilter.search, select: action.value } }
                    }
                    else if (action.fType === "search") {
                      res = { ...state, relatedproductFilter: { select: state.relatedproductFilter.select, search: action.value } };
                    }
                    return res;
                    default:
                        return state;    
                    }
                  }