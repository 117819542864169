import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import EsiCheckboxTree from '../../../components/custom/checkbox-tree';
import { changeCashbackData } from '../../../state/actions/shoppers/filterServiceActions';


export default function SortByExtraCashback(props) {
    const dispatch = useDispatch();
    let sortByOptionsInit = useSelector((state) => state.filters.cashback);
    const esiColor = useSelector(state => state.theme);

    const checkChildChange = (data: any, status: string) => {
        let key = data.key;
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        response.filter((item: any) => { return item.key === key })[0].children.map((item: any) => {
            if (status === 'unchecked' || status === 'indeterminate') {
                item.checked = "checked";
            } else {
                item.checked = "unchecked";
            }
        })
        dispatch(changeCashbackData(response));
    }

    const changeChecked = (data: any, status: string) => {
        let key = data.key;
        let keys = key.split('-');
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        if (keys.length === 1) {
            response.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
        } else if (keys.length === 2) {
            response.filter((item: any) => { return item.key === keys[0] })[0].children.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
        }
        dispatch(changeCashbackData(response));
    }

    const updateOptions = ((data: any) => {
        dispatch(changeCashbackData(data));
    })
    return (
        <View style={{ backgroundColor: esiColor.BackgroundColor }}>
            <View style={{ marginLeft: 10, flexDirection: 'row' }}>
                <View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                </View>
            </View>
            <EsiCheckboxTree
                sortByOptionsInit={sortByOptionsInit}
                updateOptions={updateOptions}
                checkChildChange={checkChildChange}
                changeChecked={changeChecked}
                {...props} />
        </View>
    );
}

