import {
    PARTNERBANK_REQUEST,
    PARTNERBANK_SUCCESS,
    PARTNERBANK_FAIL,
    PARTNERBANK_GET_REQUEST,
    PARTNERBANK_GET_SUCCESS,
    PARTNERBANK_GET_FAIL,
    PARTNERBANK_UPDATE_REQUEST,
    PARTNERBANK_UPDATE_SUCCESS,
    PARTNERBANK_UPDATE_FAIL,

} from '../actions/partnerbankAction'

const initialState = {
    partnersbank: { all: [], error: '', isLoading: false },
    partnerbank: { partnerbank: {}, error: '', isLoading: false },
    partnerbankUpdate: { partnerbank: {}, error: '', isLoading: false },

};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case PARTNERBANK_REQUEST:
            return { ...state, partnersbank: { all: state.partnersbank.all, error: '', isLoading: true } };
        case PARTNERBANK_SUCCESS:
            return { ...state, partnersbank: { all: action.payload, error: '', isLoading: false } };
        case PARTNERBANK_FAIL:
            return { ...state, partnersbank: { all: [], error: action.payload, isLoading: false } };

        case PARTNERBANK_GET_REQUEST:
            return { ...state, partnerbank: { partnerbank: {}, error: '', isLoading: true } };
        case PARTNERBANK_GET_SUCCESS:
            return { ...state, partnerbank: { partnerbank: action.payload, error: '', isLoading: false } };
        case PARTNERBANK_GET_FAIL:
            return { ...state, partnerbank: { partnerbank: {}, error: action.payload, isLoading: false } };

        case PARTNERBANK_UPDATE_REQUEST:
            return { ...state, partnerbankUpdate: { partnerbank: {}, error: '', isLoading: true } };
        case PARTNERBANK_UPDATE_SUCCESS:
            return { ...state, partnerbankUpdate: { partnerbank: state.partnerbankUpdate.partnerbank, error: '', isLoading: false } };
        case PARTNERBANK_UPDATE_FAIL:
            return { ...state, partnerbankUpdate: { partnerbank: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}