const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

const isDarkTheam = true;

const esiDarkColor = {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  esiMainColor: "#279bcc",
  TitleColor: "#002d30",
  SubTitleColor: "#000",
  actionColor: "#279bcc",
  readMoreColor: "#28affc",
  amountColor: "#36454F",
  amountStrikeColor: "#97939a",
  subActionColor: "#002d30",

  //meepaisa related colors

  brandFontColor: "#B2EE8C",
  brandShadowColor: "#fff",
  brandDescriptionColor: "#fff",
  brandBorderColor: "#1a1a1a",
  brandCardBackgroundColor: "#1a1a1a",

  //categories & Sub Categories colors
  CSBackgroundColor: "#1a1a1a",
  CSFontColor: "#B2EE8C",
  CSshadowColor: "#CACFD2",

  //leftcontentfontColor
  LCFontColor: "#fff",

  //go back fontColor
  GBFColor: "#27b6cc",

  //footer heading colors
  FHColor: "#27b6cc",

  //background color for all the pages
  BackgroundColor: "#0e0e0e",

  //title/names font color
  TNfontColor: "#B2EE8C",

  //card's background color for all modules
  CBColor: "#1a1a1a",

  //desription color  for all modules
  DescColor: "#fff",

  //Title Color for all modules
  TColor: "#B2EE8C",
  itemColor: "#dadada",
  globalButtonColor: "#145b66",
  itemButtenColor: "#dadada",

  //for no data found text
  NoDataFound: "#fff",

  //search border color
  SBorderColor: "#444444",
  //search placeholder color
  SPHTextColor: "#b4b4b4",
  //for search icon color
  SIconColor: "#27b6cc",
  //for search box type text
  SBTextColor: "#ffffff",
  TintColor: "#1a1a1a",
  // Text field colors
  Text: "#fff",
  TextHighlight: "white",
  TextSelection: "rgba(0, 0, 0, 0.5)",
  TextUnderline: "#27b6cc",
  TextBC: "#000",
  TextOuterLine: "#bababa",
  TextPlaceholder: "#fff",
  // rich text editor background color
  RTBC: '#a6a6a6',
  //for img background color
  ImgBck: "#efefef",


};

const esiLightColor = {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  esiMainColor: "#279bcc",
  TitleColor: "#002d30",
  SubTitleColor: "#000",
  actionColor: "#279bcc",
  readMoreColor: "#28affc",
  amountColor: "#36454F",
  amountStrikeColor: "#97939a",
  subActionColor: "#002d30",

  //meepaisa brand related colors

  brandFontColor: "#000",
  brandShadowColor: "#000",
  brandDescriptionColor: "#fff",
  brandBorderColor: "#1a1a1a",
  brandCardBackgroundColor: "#1a1a1a",

  //categories & Sub Categories colors
  CSBackgroundColor: "#1a1a1a",
  CSFontColor: "#B2EE8C",
  CSshadowColor: "#CACFD2",

  //leftcontentfontColor
  LCFontColor: "#fff",

  //go back fontColor
  GBFColor: "#27b6cc",

  //footer heading colors
  FHColor: "#27b6cc",

  //background color for all the pages
  BackgroundColor: "#fff",

  //title/names font color
  TNfontColor: "#B2EE8C",

  //card's background color for all modules
  CBColor: "#fff",

  //desription color  for all modules
  DescColor: "#000",

  //Title Color for all modules
  TColor: "#B2EE8C",
  itemColor: "#279bcc",
  globalButtonColor: "#145b66",
  itemButtenColor: "#dadada",

  //search border color
  SBorderColor: "#CACFD2",
  //search placeholder color
  SPHTextColor: "#000",
  //for search icon color
  SIconColor: "#27b6cc",
  //for search box type text
  SBTextColor: "#000000",

  //for no data found text
  NoDataFound: "#000",
  TintColor: "",

  // Text field colors
  Text: "#000",
  TextHighlight: "#27b6cc",
  TextSelection: "rgba(0, 0, 0, 0.5)",
  TextUnderline: "#27b6cc",
  TextBC: "white",
  TextOuterLine: "gray",
  TextPlaceholder: "gray",
  // rich text editor background color
  RTBC: 'white',
  //for img background color
  ImgBck: "#efefef",

};
// const esiColor = isDarkTheam ? esiDarkColor : esiLightColor;
// export default esiColor

export const returnTheme = (type) => {
  return type == "dark" ? esiDarkColor : esiLightColor
}

export default true ? esiDarkColor : esiLightColor