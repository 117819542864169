import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image, RefreshControl } from 'react-native';
import { Dialog, Card, DataTable, Button, Searchbar, Portal, Surface, IconButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../header/header';
import isEmpty from '../../../state/validations/is-empty';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import { getPropertysAction, PropertyFilter, resetFilter } from '../../../state/actions/Properties/PropertiesAction';

//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Function_Hall_Id',
        label: 'Function Hall ID'
    },
    {
        value: 'Name',
        label: 'Function Hall Name'
    },
    {
        value: 'License_Number',
        label: 'License Number'
    },
    {
        value: 'City',
        label: 'City'
    },
    {
        value: 'Created_By',
        label: 'Created By'
    },
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function GetProperties(props: any) {
    const { navigation } = props;
    //property contains the data from  GET API using state call
    const property = useSelector(state => state.property.propertys.all);
    //propertyMenu is used to store the cache data in Menu select using state call
    let propertyMenu = useSelector(state => state.property.propertyFilter.select);
    //propertySearch is used to store the cache data in search using state call
    let propertySearch = useSelector(state => state.property.propertyFilter.search);
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);

    //API dispatch
    const dispatch = useDispatch()
    //Static formData
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (EmailID) {
            let formData = {
                "Partner_Detail_ID": EmailID?.Partner_Details_Id,
                "Records_Filter": "FILTER"
            };
            handleSearch("");
            dispatch(getPropertysAction(formData))
            dispatch(PropertyFilter('search', ''));
            dispatch(PropertyFilter('select', 'All'));
        }
    }, [EmailID]);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });


    //sorting, pagination & search
    const [page, setPage] = React.useState(0);
    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, property?.length);

    const [oldSearch, setOldSearch] = React.useState(0);

    const checkAddressForTable = () => {

        if (propertySearch) {
            try {
                if (propertyMenu === "All") {
                    return property.filter(x => {
                        let keys = ["Function_Hall_Id", "Name", "License_Number", "City", "Created_By"];
                        for (let i = 0; i < keys.length; i++) {
                            try {
                                if (x[keys[i]].toLowerCase().includes(propertySearch.toLowerCase())) {
                                    return true;
                                }
                            } catch (error) {
                                if (x[keys[i]]?.toString().toLowerCase().includes(propertySearch.toLowerCase())) {
                                    return true;
                                }
                            }
                        }
                    });

                } else {
                    return property.filter(x => (x[propertyMenu] ? x[propertyMenu].toLowerCase() : '').includes(propertySearch.toLowerCase()));
                }
            } finally {
                if (page > 0) {
                    if (oldSearch !== propertySearch.length) {
                        setOldSearch(propertySearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch !== propertySearch.length) {
                    setOldSearch(propertySearch.length);
                    setPage(0);
                }
            }
        }
        else {
            return property
        }
    }

    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Function_Hall_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
            return -1;
        }
        if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {

            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis?.map((el) => el[0]);
    }

    // View Dialog
    const [visible, setVisible] = React.useState(false);
    const [addData, setData] = React.useState();

    const hideDialog = () => setVisible(false);

    const handleChange = itemValue => {
        dispatch(PropertyFilter('select', itemValue));
    };
    const handleSearch = value => {
        dispatch(PropertyFilter('search', value));
    };

    const data = stableSort(
        checkAddressForTable(), getComparator(order, orderBy))

    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto' }}>
                    <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                        <IconButton icon="close" size={20} onPress={() => setVisible(!visible)} />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                        <Text style={{ color: '#27B6CC' }}>View Propertie</Text>
                    </Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 7 }}>
                            <Dialog.Content style={{ marginLeft: -30 }}>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Function Hall ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.Function_Hall_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Function Hall Name</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Name} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>License Number</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.License_Number} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Hall Type</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Hall_Type} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Hall Seating Capacity</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Hall_Seating_Capacity} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Price Per Day</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Price_Per_Day} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Price Per Hour</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Price_Per_Hour} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Room Cost Per Night</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Room_Cost_Per_Night} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Location</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Location} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Door No</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Door_No} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Veg Price Per Plate</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.Hall_Food_Veg_Price_Per_Plate} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Non Veg Price Per Plate</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Hall_Food_Non_Veg_Price_Per_Plate} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Hall Decoration Price</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Hall_Decoration_Price} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Electricity Price Per Hour</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Electricity_Price_Per_Hour} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>AC Electricity Price Per Hour</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.AC_Electricity_Price_Per_Hour} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Any Extra Charges</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Any_Extra_Charges} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Discount Percentage</Text></View>
                                    <View style={{ flex: 2.5 }}><Text>{addData?.Discount_Percentage} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Country</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.Country} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>State</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.State} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>City</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.City} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Location</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.Location} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Full Description</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.Full_Description} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Short Description</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.Short_Description} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>IS Licensed</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.IS_Licensed} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>IS Verified</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.IS_Verified} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>IS No Legal Complications</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.IS_No_Legal_Complications} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>IS Alchol Party Allowed</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.IS_Alchol_Party_Allowed} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>IS Available For Booking</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.IS_Available_For_Booking} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Status</Text></View>
                                    <View style={{ flex: 2.5 }}><Text  >{addData?.Status} </Text></View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>

            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                    <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                        navigation.navigate("Properties", { screen: 'PropertiesDashboard' });
                        handleSearch("")
                        dispatch(resetFilter())
                    }
                    }>
                        Go Back
                    </Button>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: "column" }}>
                        <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10 }}>Partner Properties</Text>
                    </View>
                    {(permissions.Address_Management === "write") &&
                        <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
                            <Button style={{ backgroundColor: '#27B6CC' }} mode="contained" onPress={() => { navigation.navigate('AddProperty'), handleSearch("") }}>
                                ADD PROPERTIE
                            </Button>
                        </View>}
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={filterOptions}
                                containerStyle={style.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={propertyMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
                            <Picker
                                selectedValue={propertyMenu}
                                style={{ height: 51, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', flexShrink: 1, backgroundColor: '#ffff' }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Function Hall ID" value="Function_Hall_Id" />
                                <Picker.Item label="Function Hall Name" value="Name" />
                                <Picker.Item label="License Number" value="License_Number" />
                                <Picker.Item label="City" value="City" />
                                <Picker.Item label="Created By" value="Created_By" />
                            </Picker>
                        </View>}

                    <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{ borderRadius: 5, borderWidth: 0.3, borderColor: '#bdc3c7' }}
                            placeholder="Search"
                            onChangeText={(value) => handleSearch(value)}
                            value={propertySearch}
                        />
                    </View>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    <ScrollView horizontal={dimensions >= 700 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title style={style.title} active={orderBy === 'Function_Hall_Id'}
                                            sortDirection={orderBy === 'Function_Hall_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Function_Hall_Id')}><Text style={style.titletext}>Function Hall ID</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Name'}
                                            sortDirection={orderBy === 'Name' ? order : 'ascending'}
                                            onPress={createSortHandler('Name')}><Text style={style.titletext}>Function Hall Name</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'License_Number'}
                                            sortDirection={orderBy === 'License_Number' ? order : 'ascending'}
                                            onPress={createSortHandler('License_Number')}><Text style={style.titletext}>License Number</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'City'}
                                            sortDirection={orderBy === 'City' ? order : 'ascending'}
                                            onPress={createSortHandler('City')}><Text style={style.titletext}>City</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Created_By'}
                                            sortDirection={orderBy === 'Created_By' ? order : 'ascending'}
                                            onPress={createSortHandler('Created_By')}><Text style={style.titletext}>Created By</Text></DataTable.Title>

                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>

                                    </DataTable.Header>
                                    {
                                        data?.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((property, index) => (
                                            <DataTable.Row style={style.databeBox} key={property.Function_Hall_Id}>
                                                <DataTable.Cell style={style.tablerow}>{property.Function_Hall_Id}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{property.Name}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{property.License_Number}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{property.City}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{property.Created_By}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    {(permissions.Address_Management === "write") && (<IconButton icon="square-edit-outline" size={20} color='#27B6CC' onPress={() => { navigation.navigate('EditProperty', { id: property.Function_Hall_Id }), handleSearch("") }} />)}
                                                    <IconButton icon="eye" size={20} color='#27B6CC' onPress={(e) => { setData(property); setVisible(!visible) }} />
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                        ))
                                    }
                                    {isEmpty(data) &&
                                        <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20 }}>
                                                No records found!
                                            </Text>
                                            <Image source={require('../../../assets/images/nodata.gif')}
                                                style={{ width: 300, height: 300, alignSelf: "center" }} />
                                        </View>
                                    }
                                    <DataTable.Pagination
                                        page={page}
                                        numberOfPages={Math.ceil(property?.length / numberOfItemsPerPage)}
                                        onPageChange={page => setPage(to < data?.length ? page : 0)}
                                        label={property?.length ? `${from + 1}-${to} of ${data?.length}` : '0-0'}
                                        showFastPaginationControls={to > data?.length ? false : true}
                                        numberOfItemsPerPageList={numberOfItemsPerPageList}
                                        numberOfItemsPerPage={numberOfItemsPerPage}
                                        onItemsPerPageChange={onItemsPerPageChange}
                                        selectPageDropdownLabel={'Rows per page'}
                                    />
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>

                </View>
            </ScrollView>
        </Surface>

    );
}

const style = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontWeight: "bold",
        fontSize: 12,
        justifyContent: "center",
        textAlign: "center",
        width: 160
    },
    titletext: {
        fontWeight: "bold",
        fontSize: 14,
        color: 'black',
    },
    tablerow: {
        justifyContent: "center",
        textAlign: "center",
        width: 140
    },
    mainbox: {
        textAlign: 'center',
        margin: 15,
        flex: 1,
        justifyContent: 'space-between',
    },
    databeBox: {
        margin: 10,
        textAlign: 'center',
    },
    databeHeader: {
        margin: 10,
        textAlign: 'center',
    },
    dropdown: {
        height: 50,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    containerstyle: {
        width: 150
    },
});

export default GetProperties