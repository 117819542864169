import axios from "axios";
import shopperconfig from "./shopperconfig";
import config from "../config";
export const SET_ALL_BRANDS = "SET_ALL_BRANDS";
export const ALL_BRANDS_REQUEST = "ALL_BRANDS_REQUEST";
export const ALL_BRANDS_FAIL = "ALL_BRANDS_FAIL";
export const GET_SINGLE_BRAND = "GET_SINGLE_BRAND";
export const GET_SINGLE_BRAND_REQUEST = "GET_SINGLE_BRAND_REQUEST";
export const GET_SINGLE_BRAND_FAIL = "GET_SINGLE_BRAND_FAIL";
export const TOP_BRAND = "TOP_BRAND";
export const TOP_BRAND_FAIL = "TOP_BRAND_FAIL";
export const TOP_BRAND_SUCCESS = "TOP_BRAND_SUCCESS";
export const ALL_BRAND_USER_REVIEWS_REQUEST = "ALL_BRAND_USER_REVIEWS_REQUEST";
export const ALL_BRAND_USER_REVIEWS = "ALL_BRAND_USER_REVIEWS";
export const ALL_BRAND_USER_REVIEWS_FAIL = "ALL_BRAND_USER_REVIEWS_FAIL";

export const BRAND_REVIEW_CREATE_REQUEST = "BRAND_REVIEW_CREATE_REQUEST";
export const BRAND_REVIEW_CREATE_SUCCESS = "BRAND_REVIEW_CREATE_SUCCESS";
export const BRAND_REVIEW_CREATE_FAIL = "BRAND_REVIEW_CREATE_FAIL";

export const BRAND_REVIEW_UPDATE_REQUEST = "BRAND_REVIEW_UPDATE_REQUEST";
export const BRAND_REVIEW_UPDATE_SUCCESS = "BRAND_REVIEW_UPDATE_SUCCESS";
export const BRAND_REVIEW_UPDATE_FAIL = "BRAND_REVIEW_UPDATE_FAIL";


/*=============================================================
                  Get All Brands Action
===============================================================*/

const headers = shopperconfig.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;

export const getAllBrandsAction = (type) => async dispatch => {
  try {
    let formData;
    if (type === "Is_Top_Brand") {
      dispatch({
        type: TOP_BRAND,
      });

      formData = {
        "Records_Filter": "FILTER",

        "Top_Brands": "1"
      };
    }
    else if (type === "") {
      dispatch({
        type: ALL_BRANDS_REQUEST,
      });


      formData = {
        "Records_Filter": "Filter",
        "Brand_Status": "Active",
      };
    }
    if (type === "") {
      dispatch({
        type: ALL_BRANDS_REQUEST,
      });
    }
    let { data } = await axios.post(
      `${shopperconfig.url}/Brand/Get?PageNo=0&PageSize=500&SortBy=NAME&SortOrder=ASC`,
      formData,
      {
        headers: headers
        // auth: auth,
      },
    );
    if (data) {
      if (type === "Is_Top_Brand") {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: TOP_BRAND_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: TOP_BRAND_SUCCESS,
            payload: data.Brand
          });
        }
      } else if (type === "") {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: SET_ALL_BRANDS,
            payload: data,
          });
        } else {
          dispatch({
            type: SET_ALL_BRANDS,
            payload: data.Brand,
          });
        }
      }
    }
  } catch (error) {
    dispatch({
      type: ALL_BRANDS_FAIL,
    });
  }
};

/*=============================================================
                  Get Brand By Id Action
===============================================================*/

export const getBrandByIdAction = (brandId: any, callBackBrandData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_SINGLE_BRAND_REQUEST,
    });
    const formData = {
      "search_by_filter": "",
      "search": "",
      "Records_Filter": "FILTER",
      "Brand_Id": brandId,
      "Brand_Name": "",
      "Brand_Status": "",
      "Esi_Rating": "",
      "Todays_Hot": "",
      "Top_Brands": ""
    };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2B_Brand/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackBrandData(data.results[0]);
    }
  } catch (error) {
    dispatch({
      type: GET_SINGLE_BRAND_FAIL,
    });
  }
};


export const getAllfilterBrandsAction = (callback: any) => async (dispatch: any) => {
  try {
    let formData = {
      "Records_Filter": "FILTER",
      "Brand_Id": "",
      "Brand_Name": "",
      "Brand_Status": "Active",
      "User_Rating": ""
    };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Brand/Get`,
      formData,
      {
        headers: nodeheaders
      },
    );
    if (data) {
      callback(data.results);
    }
  } catch (error) {
    dispatch({
      type: ALL_BRANDS_FAIL,
    });
  }
};
export const getUserReviewsbyIdAction = (brandId: any) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_BRAND_USER_REVIEWS_REQUEST,
    });
    const formData = {
      Status: "Active",
      Brand_Id: brandId,
      Records_Filter: "FILTER"
    }
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Brand_Review/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_BRAND_USER_REVIEWS,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_BRAND_USER_REVIEWS,
          payload: data.results
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ALL_BRAND_USER_REVIEWS_FAIL,
      payload: error
    });
  }
};

export const addBrandReviewAction = (formData, Brand_Id, addReviewResponse) => async (dispatch) => {

  dispatch({
    type: BRAND_REVIEW_CREATE_REQUEST,
  });
  axios.post(`${shopperconfig.newurl}/Partner_Brand_Review/Create`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: BRAND_REVIEW_CREATE_SUCCESS
        });
        let form = {
          Brand_Id: Brand_Id,
          Record_Filter: "FILTER"
        }
        addReviewResponse(true, 'Review added successfully.');
        dispatch(getUserReviewsbyIdAction(Brand_Id));
      } else {
        addReviewResponse(false, successResponse.UI_Display_Message);
        dispatch({
          type: BRAND_REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      addReviewResponse(false, "Please try sometime later.Currently server is not working.");
      dispatch({
        type: BRAND_REVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};
export const editBrandReviewAction = (formData, Brand_Id, editReviewResponse) => async (dispatch) => {

  dispatch({
    type: BRAND_REVIEW_UPDATE_REQUEST,
  });
  axios.put(`${config.nodecudurl}/Partner_Brand_Review/Update`, formData, {
    headers: nodeheaders
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: BRAND_REVIEW_UPDATE_SUCCESS
        });
        editReviewResponse(true, 'Review updated successfully.');
        dispatch(getUserReviewsbyIdAction(Brand_Id));
      } else {
        editReviewResponse(false, successResponse.UI_Display_Message);
        dispatch({
          type: BRAND_REVIEW_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      editReviewResponse(false, "Please try sometime later.Currently server is not working.");
      dispatch({
        type: BRAND_REVIEW_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};		  