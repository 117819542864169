import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Card, DataTable, Searchbar, Button, Surface, Dialog, Portal, IconButton, DefaultTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProductSizeAction, resetFilter } from "../../../state/actions/productsizeAction";
import isEmpty from '../../../state/validations/is-empty'
import Header from "../../auth/components/header";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from 'react-native-vector-icons/FontAwesome';

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Size_Type',
    label: 'Size Type'
  },
  {
    value: 'Product_Size_Id',
    label: 'Product Size ID'
  },
  {
    value: 'Size',
    label: 'Size'
  },
  {
    value: 'Status',
    label: 'Status'
  },
];

const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const ProductSizeGet = (props: any) => {
  const { navigation } = props;
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const allproductsize = useSelector(state => state.productsize.productsizes.all);
  let productsize = allproductsize?.results;
  const esiColor = useSelector(state => state.theme);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [productmasterMenu, setProductmasterMenu] = React.useState('All')
  const [productmasterSearch, setProductmasterSearch] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [products, setProducts] = useState([]);
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > allproductsize?.Pagination?.TotalCount ? allproductsize?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const productmaster = props.route?.params?.ID
  const [productsizes, setData] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const hideDialog = () => setVisible(false);
  const dispatch = useDispatch()

  const [sizes, setSizes] = React.useState();
  const [pageOld, setPageOld] = React.useState(false);
  const [webpage, setwebPage] = React.useState(1);

  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PRODUCT_SIZE_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      brandsCall();
    }
  }, [pagination])
  const [loading, setIsLoading] = React.useState(false);
  const brandsCall = () => {
    setIsLoading(true);
    let formData = {
      search: '',
      Product_Master_Id: productmaster,
      Records_Filter: "FILTER",
    };
    dispatch(getAllProductSizeAction(formData, pagination))
  };
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setwebPage(1)
    setPagination({ PageNo: Number(1), PageSize: e, SortBy: "PRODUCT_SIZE_ID", SortOrder: "DESC" })
  }
  const DynamicPageIncrease = (e) => {
    setwebPage(e < allproductsize?.Pagination?.TotalPages ? e + Number(1) : allproductsize?.Pagination?.TotalPages)
    setPagination({ PageNo: e == allproductsize?.Pagination?.TotalPages ? allproductsize?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PRODUCT_SIZE_ID", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "PRODUCT_SIZE_ID", SortOrder: "DESC" });
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PRODUCT_SIZE_ID", SortOrder: "DESC" });
  }
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (isEmpty(productsize) || (productmaster !== sizes)) {
      const formData = {
        search: '',
        Product_Master_Id: productmaster,
        Records_Filter: "FILTER"
      };
      setSizes(productmaster)
      dispatch(getAllProductSizeAction(formData, pagination))
    }
  }, [productsize, sizes, productmaster]);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const handleClickadd = async () => {
    props.navigation.navigate('AddProductSize', { productSizes: productmaster })
  };
  const handleClickedit = async (productsize) => {
    props.navigation.navigate('EditProductSize', { productsizes: productsize.Product_Size_Id, productsizess: productsize.Product_Master_Id })
  };
  const [oldSearch, setOldSearch] = React.useState(0);
  const checkproductmasterForTable = () => {
    if (productmasterSearch) {
      try {
        if (productmasterMenu === "All") {
          return productsize?.filter(x => {
            let colummns = ["Size_Type", "Product_Size_Id", "Size", "Status"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
              const subString = typeof x[colummns[i]] === "string" ? productmasterSearch.toLowerCase() : productmasterSearch;
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(productmasterSearch.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString == productmasterSearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return productsize?.filter(x => {
            let mainString = typeof x[productmasterMenu] == "string" ? x[productmasterMenu].toLowerCase() : x[productmasterMenu];
            let subString = typeof x[productmasterMenu] == "string" ? productmasterSearch.toLowerCase() : productmasterSearch;
            if (typeof x[productmasterMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString == productmasterSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (page > 0) {
          if (oldSearch != productmasterSearch.length) {
            setOldSearch(productmasterSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch != productmasterSearch.length) {
          setOldSearch(productmasterSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return productsize
    }
  }

  useEffect(() => {
    if (!isEmpty(productsize)) {
      setProducts(productsize);
    } else {
      setProducts([]);
    }
  }, [productsize]);
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);


  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Product_Size_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleChange = itemValue => {
    setProductmasterMenu(itemValue)

  };
  const handleSearch = value => {
    setProductmasterSearch(value)
    const formData = {
      search: value,
      Product_Master_Id: productmaster,
      Records_Filter: "FILTER",
    };
    dispatch(getAllProductSizeAction(formData, pagination))
  };
  const data =
    checkproductmasterForTable()

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const styles = StyleSheet.create({
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 160
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'left',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 240
    },

    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
    },
    rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
    },
    rowsPerPagePickers: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      borderColor: 'gray',
      borderWidth: 0.5,
      paddingHorizontal: 8,
    },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      // backgroundColor: esiColor.SIconColor
    },
    paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      // backgroundColor: esiColor.SIconColor
    },
    pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
            <IconButton
              icon="close"
              color={esiColor.GBFColor}
              size={20}
              onPress={() => setVisible(!visible)}
            />
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>Product Size View</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Dialog.Content style={{ marginLeft: -30 }}>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Size</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productsizes?.Size} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Size ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{productsizes?.Product_Size_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Size Type</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productsizes?.Size_Type} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Size Measurement</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productsizes?.Size_Measurement} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productsizes?.Created_Date} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{productsizes?.Created_By} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View><View style={{ flex: 2 }}><Text style={{ color: productsizes?.Status === "Active" ? esiColor.GBFColor : "red" }}>{productsizes?.Status} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View >
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
          navigation.navigate('ProductMasterGet')
          handleSearch("")
          dispatch(resetFilter())
        }
        }>
          Go Back
        </Button>
      </View>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      } >
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>product Sizes</Text>
        </View>
        {(permissions.Product_Master_Product_Color_Product_Size === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={(e) => { handleClickadd(); }}>
              Add Product Size
            </Button>
          </View>}
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[styles.dropdown]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={filterOptions}
                containerStyle={styles.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={productmasterMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={productmasterMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Size Type" value="Size_Type" />
                <Picker.Item label="Product Size ID" value="Product_Size_Id" />
                <Picker.Item label="Size" value="Size" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              onChangeText={(value) => handleSearch(value)}
              value={productmasterSearch}
            />
          </View>
        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={styles.mainbox}>
            <Card>
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={styles.databeHeader}>
                  <DataTable.Title active={orderBy === 'Product_Size_Id'} sortDirection={orderBy === 'Product_Size_Id' ? order : 'ascending'} onPress={createSortHandler('Product_Size_Id')} style={styles.title}><Text style={styles.titletext}>Product Size ID</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Size_Measurement'} sortDirection={orderBy === 'Size_Measurement' ? order : 'ascending'} onPress={createSortHandler('Size_Measurement')} style={styles.title}><Text style={styles.titletext}>Size Measurement</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Size_Type'} sortDirection={orderBy === 'Size_Type' ? order : 'ascending'} onPress={createSortHandler('Size_Type')} style={styles.title}><Text style={styles.titletext}>Size Type</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Size'} sortDirection={orderBy === 'Size' ? order : 'ascending'} onPress={createSortHandler('Size')} style={styles.title}><Text style={styles.titletext}>Size</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Status'} sortDirection={orderBy === 'Status' ? order : 'ascending'} onPress={createSortHandler('Status')} style={styles.title}><Text style={styles.titletext}>Status</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Created_Date'} sortDirection={orderBy === 'Created_Date' ? order : 'ascending'} onPress={createSortHandler('Created_Date')} style={styles.title}><Text style={styles.titletext}>Created Date</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Created_By'} sortDirection={orderBy === 'Created_By' ? order : 'ascending'} onPress={createSortHandler('Created_By')} style={styles.title}><Text style={styles.titletext}>Created By</Text></DataTable.Title>
                  <DataTable.Title style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {data?.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((productSize, i) => (
                  <DataTable.Row style={styles.databeBox} key={i}>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productSize.Product_Size_Id}</Text> </DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productSize.Size_Measurement}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productSize.Size_Type}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productSize.Size}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productSize.Status}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productSize.Created_Date}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{productSize.Created_By}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>
                      {(permissions.Product_Master_Product_Color_Product_Size === "write") &&
                        <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { handleClickedit(productSize); }} />
                      }
                      <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(productSize); setVisible(!visible) }} />
                    </DataTable.Cell>
                  </DataTable.Row>
                ))
                }
                {isEmpty(data) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                {Platform.OS !== "web" ?

                  <DataTable.Pagination
                    // theme={customTheme}
                    page={page}
                    numberOfPages={Math.ceil(allproductsize?.Pagination?.TotalCount / numberOfItemsPerPage)}
                    onPageChange={page => DynamicPage(to < allproductsize?.Pagination?.TotalCount ? page : 0)}
                    label={allproductsize?.Pagination?.TotalCount ? `${from + 1}-${to} of ${allproductsize?.Pagination?.TotalCount}` : '0-0'}
                    showFastPaginationControls={(Number(page) + Number(1)) > allproductsize?.Pagination?.TotalPages ? false : true}
                    numberOfItemsPerPageList={numberOfItemsPerPageList}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    onItemsPerPageChange={onItemsPerPageChange}
                    selectPageDropdownLabel={'Rows per page'}
                  />
                  :
                  <View style={styles.rowsPerPageContainer}>
                    <Text style={{ color: esiColor.Text }}>rows per page: </Text>
                    <Picker
                      style={styles.rowsPerPagePicker}
                      selectedValue={numberOfItemsPerPage}
                      onValueChange={(e) => onItemsPerPageChange(e)}
                    >
                      <Picker.Item label="5" value={5} />
                      <Picker.Item label="10" value={10} />
                      <Picker.Item label="20" value={20} />
                    </Picker>
                    <View style={styles.paginationContainer}>
                      <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={styles.paginationButton}>
                        <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                      <Text style={styles.pageInfoText}>
                        {allproductsize?.Pagination?.PageNo + ' of ' + allproductsize?.Pagination?.TotalPages}
                      </Text>
                      <TouchableOpacity onPress={e => DynamicPageIncrease(to < allproductsize?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={styles.paginationButton}>
                        <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                      </TouchableOpacity>
                    </View>
                  </View>}
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}

export default ProductSizeGet;