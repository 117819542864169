import React, { Fragment, useEffect, useState } from "react";
import { View, Dimensions, Text, Platform, RefreshControl } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import EsiBrandCard from "../../../components/custom/brand-card";
import Pagination from "../../../components/custom/pagination/pagination";
import { getBrandsAction } from "../../../state/actions/node-actions/home-actions";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../header/header";
const { height, width } = Dimensions.get('window');
export default function Brandsb2b(props: any) {
  const { item, navigation, ...rest } = props;
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  const data = props?.route?.params;
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = React.useState({
    "search": "",
    "Records_Filter": "ALL",
    "Brand_Id": "",
    "Brand_Name": "",
    "Brand_Status": "",
    "Esi_Rating": "",
    "Todays_Hot": "",
    "Top_Brands": ""
  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "BRAND_ID", SortOrder: "DESC" });
  const callBackGetData = async (status, data, pagination) => {
    goToTop();
    setIsLoading(false);
    if (status) {
      setBrands(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 30, SortBy: "BRAND_ID", SortOrder: "DESC" });
    }
  }
  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      brandsCall();
    }
  }, [pagination])
  const [testFilters, setTestFilters] = useState([]);
  useEffect(() => {
    if (data?.Brand_Id != "") {
      if ((data?.Brand_Id) != (testFilters)) {
        setTestFilters(data?.Brand_Id);
        const formData = {
          "search": "",
          "Records_Filter": data ? 'FILTER' : "ALL",
          "Brand_Id": data?.Brand_Id,
          "Brand_Name": "",
          "Brand_Status": "",
          "Esi_Rating": "",
          "Todays_Hot": "",
          "Top_Brands": ""
        }
        dispatch(getBrandsAction(formData,
          pagination,
          callBackGetData,
        ));
      }
    }
  }, [data]);
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const brandsCall = () => {
    setIsLoading(true);
    dispatch(getBrandsAction(filters,
      pagination,
      callBackGetData
    ));
  };
  //Returns a function to scroll the page up or down by any amount, in pixels. Supports scrolling.
  const goToTop = () => {
    try {
      scroll.scrollTo({ x: 0, y: 0, animated: true });
    } catch (error) {

    }
  }
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }} >
      <Header {...props} />
      <ScrollView ref={(c) => { scroll = c }}
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={brandsCall}
          />
        }
      >
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', margin: 'auto', marginTop: 40 }}>
          {
            isEmpty(brands) ? (
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                <Text style={{ color: esiColor.NoDataFound }}>No Brands found!</Text>
              </View>
            ) : (
              brands?.map((item, index) => (
                <Fragment key={index}>
                  <EsiBrandCard item={item} navigation={navigation} />
                </Fragment>
              ))
            )
          }
        </View>
        <View style={{ marginBottom: Platform.OS == "web" ? -10 : 5 }}>
          <Pagination pagination={pagination} setPagination={setPagination} />
        </View>
      </ScrollView>
    </Surface>
  );
}