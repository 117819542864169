import {
  BRANDS_REQUEST,
  BRANDS_SUCCESS,
  BRANDS_FAIL,
  BRAND_GET_REQUEST,
  BRAND_GET_SUCCESS,
  BRAND_GET_FAIL,
  BRAND_CREATE_REQUEST,
  BRAND_CREATE_SUCCESS,
  BRAND_CREATE_FAIL,
  BRANDS_FILTER,
  RESET
} from '../actions/brandAction'


const initialState = {
  brands: { all: [], error: '', isLoading: false },
  brand: { brand: {}, error: '', isLoading: false },
  brandCreate: { brand: {}, error: '', isLoading: false },
  brandFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action:any) {
  switch (action.type) {
    case BRANDS_REQUEST:
      return { ...state, brands: { all: state.brands.all, error: '', isLoading: true } };
    case BRANDS_SUCCESS:
      return { ...state, brands: { all: action.payload, error: '', isLoading: false } };
    case BRANDS_FAIL:
      return { ...state, brands: { all: [], error: action.payload, isLoading: false } };
    case BRAND_GET_REQUEST:
      return { ...state, brand: { brand: {}, error: '', isLoading: true } };
    case BRAND_GET_SUCCESS:
      return { ...state, brand: { brand: action.payload, error: '', isLoading: false } };
    case BRAND_GET_FAIL:
      return { ...state, brand: { brand: {}, error: action.payload, isLoading: false } };
    case BRAND_CREATE_REQUEST:
      return { ...state, brandCreate: { brand: {}, error: '', isLoading: true } };
    case BRAND_CREATE_SUCCESS:
      return { ...state, brandCreate: { brand: state.brandCreate.brand, error: '', isLoading: false } };
    case BRAND_CREATE_FAIL:
      return { ...state, brandCreate: { brand: {}, error: action.payload, isLoading: false } };
    case RESET:
      return { ...state, brandCreate: { brand: {}, error: '', isLoading: false } };
    case BRANDS_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, brandFilter: { search: state.brandFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, brandFilter: { select: state.brandFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}

