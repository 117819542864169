import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'react-native-elements';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Dimensions, Image, Platform, TouchableOpacity, View } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../state/validations/is-empty';
import { b2bbiddingservicesDateandCount } from '../../state/actions/dynamicCountActions';

const BiddingProductCard = ({ product, navigation,esiColor, ...rest }) => {
  const dispatch = useDispatch();
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const dynamicvalue = useSelector(state => state.corporatedateandcount.b2bbiddingdateandcounts.all);
  const EmailID = useSelector(state => state.auth?.user);
  useEffect(() => {
    if (isEmpty(dynamicvalue) && !isEmpty( EmailID)) {
      let formData={
        Records_Filter: "FILTER",
        Partner_Details_Id: EmailID.Partner_Details_Id
      }
      dispatch(b2bbiddingservicesDateandCount(formData));
    }else{
      if(product.title==="B2B User New Biddng Requests" ){
        product.total=dynamicvalue?.B2B_User_Products_Bidding?.B2B_User_Products_Bidding;
        product.createdAt=dynamicvalue?.B2B_User_Products_Bidding?.B2B_User_Products;
      }else if(product.title==="B2B Partner Bidding Quotes"){
        product.total=dynamicvalue?.Partner_Bidding_Quote_Id?.Partner_Bidding_Quote_Id;
        product.createdAt=dynamicvalue?.Partner_Bidding_Quote_Id?.Partner_Bidding_Quote;
      }
    }

  }, [dynamicvalue,EmailID]);

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  return (
    <View style={{ flexDirection: 'column', flex: 1, flexWrap: 'wrap', padding: 5 }}>
      <Card style={{
         borderRadius: 10, flex: 1, padding: 8, backgroundColor: esiColor.CBColor, margin: 10, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
         shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, elevation: 10, height: 370, maxHeight: 500,
         maxWidth: 350, width: Platform.OS === 'ios' ? dimensions <= 520 ? 370 : 370 : 160 && Platform.OS === 'web' ? dimensions <= 520 ? 300 : 350 : 160 && Platform.OS === 'android' ? 330 : 350,
      }}>
        <Card.Content>
          <View style={{ alignItems: 'center', justifyContent: "center", margin: 5, height: 270, flexWrap: 'wrap' }}>
            <TouchableOpacity onPress={() => { navigation.navigate(product.link) }}>
              <View style={{ margin: 10, alignSelf: 'center', flexWrap: 'wrap', height: 58 }}>
                <Image style={{ width: 58, height: 58, resizeMode: 'contain' }} source={product.media} />
              </View>
              <View style={{ justifyContent: 'center', margin: 10, height: 25 }}>
                <Text style={{ fontWeight: 'bold', fontSize: 20 ,color: esiColor.brandFontColor }}>{product.title}</Text>
              </View>
            </TouchableOpacity>
            <View style={{ height: 130 }}>
              <Text style={{ color: esiColor?.Text }}>{product.description}</Text>
            </View>
          </View>
        </Card.Content>
        <Divider orientation="vertical" width={5} />
        <View style={{ flexDirection: 'row', margin: 10, flex: 1, flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'column' }}>
            <MaterialCommunityIcons name="clock-time-four-outline" size={24} color={esiColor.SIconColor} />
          </View >
          <View style={{ flexDirection: 'column', margin: 5 }}>
            <Text style={{ color: esiColor?.Text }}>  {product.createdAt}</Text>
          </View>
          <View style={{ flexDirection: 'column', flex: 1, marginVertical: 6, alignItems: 'flex-end' }}>
            <Text style={{ color: esiColor?.Text }}>{product.total}</Text>
          </View>
        </View>
      </Card>
    </View>
  );
};
BiddingProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};
export default BiddingProductCard;