import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from "react-native-modal";
import { Image, Platform, StyleSheet, TouchableOpacity, View, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Surface, Text, TouchableRipple, Avatar, Switch, Dialog, Portal, IconButton } from 'react-native-paper';
import { Divider } from 'react-native-elements';
import { MaterialCommunityIcons, Feather, MaterialIcons, Ionicons, AntDesign } from '@expo/vector-icons';
import { deleteAccountAction, loginOut, sessionCheckAction } from '../../../../state/actions/authActions';
import { ScrollView } from 'react-native-gesture-handler';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useToast } from '../../../custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SignIN from '../../../../screens/auth/signin';
import VersionCheck from 'expo-react-native-version-checker';
import { Linking } from "react-native";
import isEmpty from '../../../../state/validations/is-empty';
import { PartnerProfileActions, updateOpenStoreAction } from '../../../../state/actions/partnerprofileActions';
// const window = Dimensions.get("window");
const NavBar = ({ onMobileClose, openMobile, navigation }) => {
  const user = useSelector((state) => state.auth.user);
  const esiColor = useSelector(state => state.theme);
  const userPic = useSelector(state => state.auth?.isAuthenticated ? state.auth?.user?.Profile_Pic_Path ? { uri: state.auth?.user?.Profile_Pic_Path } : require("../../../../assets/images/icons/defaultlogo.png") : require("../../../../assets/images/icons/defaultlogo.png"));
  const dispatch = useDispatch();
  const toast = useToast();
  React.useEffect(() => {
    return () => {
    };
  }, []);
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [notificationToken, setNotificationToken] = React.useState("");
  const [needed, setNeeded] = React.useState("")
  const [version, setVersion] = React.useState()
  const [storeurl, setStoreurl] = React.useState('')
  const [currentState, setCurrentState] = React.useState("SignUp");
  const [modal, setModal] = React.useState(false);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [visible, setVisible] = React.useState(false);
  const hideDialog = () => setVisible(false);
  // if (Platform.OS != 'web') {
  //   VersionCheck('IN').getLatestVersion()
  //     .then(latestVersion => {
  //       setVersion(latestVersion)
  //     });
  //   VersionCheck('IN').needUpdate()
  //     .then(async res => {
  //       setNeeded(res?.isNeeded)
  //       setStoreurl(res.storeUrl)
  //       if (currentState == "SignUp") {
  //         if (res.isNeeded) {
  //           setModal(true)
  //         }
  //       } else if (currentState == "verify") {
  //         setModal(false)
  //       }
  //     });
  // }
  const versionCheck = () => {
    if (currentState == "verify") {
      setModal(false)
    }
  }

  React.useEffect(() => {
    (async () => {
      if (Platform.OS != "web") {
        const ExpoPushToken = await AsyncStorage.getItem('ExpoPushToken');
        setNotificationToken(ExpoPushToken || "");
      }

    })();
  }, []);
  // const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  useEffect(() => {
    if (openMobile && onMobileClose) {
      // onMobileClose();
    }
  }, []);
  const [notifications, setNotifications] = React.useState();
  const signInModalResponse = (status: any) => {
    setModalVisible(!isModalVisible);
  };
  const [dimensions, setDimensions] = useState(Dimensions.get('window').width);
  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const callBackData = (data: any) => {
    if (data) {
      setNotifications(data.Count)
    }
  }
  //partner open actions
  React.useEffect(() => {
    if (user?.Partner_Details_Id) {
      const formData = {
        Partner_Details_Id: user?.Partner_Details_Id
      };
      dispatch(PartnerProfileActions(formData, callBackOpengetData));
    }
  }, [user]);
  const callBackOpengetData = (data: any) => {
    if (data) {
      { data.Is_Partner_Profile_Update === '0' && setVisible(!visible) }
      setIsSwitchOn(Number(data.Is_Open) == '1' ? true : false)
    }
  }
  const setCallBackOpengetData = (data: any) => {
    if (data) {
      const formData = {
        Partner_Details_Id: data?.Partner_Details_Id
      };
      dispatch(PartnerProfileActions(formData, callBackOpengetData));

    }
  }
  const callBackOpenData = (data: any) => {
    if (data) {
      setCallBackOpengetData(data)
    }
  }
  const onToggleSwitch = () => {
    if (!isEmpty(isSwitchOn)) {
      let formData = {
        Name: user.Partner_Name,
        Partner_Details_Id: user?.Partner_Details_Id,
        Is_Open: !isSwitchOn ? 1 : 0,
        Partner_User_Detail_Id: user.Partner_User_Details_Id
      }
      dispatch(updateOpenStoreAction(formData, callBackOpenData));
      setIsSwitchOn(!isSwitchOn);
    }
  }
  const [logoutLoading, setLogoutLoading] = React.useState(false);
  const setLogoutDisabile = (status: any, message: any) => {
    setLogoutLoading(false);
    if (!status) {
      toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
    }
  }
  const _handleSignOut = () => {
    setLogoutLoading(true);
    dispatch(loginOut(navigation, notificationToken, user, setLogoutDisabile, toast));
  };
  const [deleteModel, setDeleteModel] = React.useState(false);
  const _handleDelete = () => {
    setDeleteModel(true);
  };
  const [versionModel, setSersionModel] = React.useState(false);
  const _handleVersion = () => {
    setSersionModel(true);
  };

  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }
  const [seconds, setSeconds] = React.useState(-1);
  // const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  const styles = StyleSheet.create({
    profile_name: {
      fontSize: 20,
      color: esiColor.Text
    },
    item_styles: {
      flexDirection: 'row',
      alignItems: 'center'
    },
  });
  const customModels = () => {
    return (
      <View>
        <Modal animationIn={"slideInDown"}
          // deviceWidth={Dimensions.width}
          // deviceHeight={Dimensions.height}
          style={{ alignItems: 'center' }} isVisible={isModalVisible}>
          <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
            <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

          </View>
        </Modal>

        {/* delete Account */}

        <Modal animationIn={"slideInDown"}
          // deviceWidth={Dimensions.width}
          // deviceHeight={Dimensions.height}
          style={{ alignItems: 'center' }} isVisible={deleteModel}>
          <View style={{ flex: 1, width: - 50, maxWidth: 500, minWidth: 300, minHeight: 250, maxHeight: 350, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>

            <View style={{ alignItems: 'flex-end' }}>
              <TouchableRipple style={{ width: 36 }} onPress={() => { setDeleteModel(false) }} >
                <Avatar.Icon color={esiColor?.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
              </TouchableRipple>
            </View>
            <View style={{ flexDirection: "column", marginTop: -20, marginHorizontal: 10 }} >
              <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Image source={require("../../../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />

              </View>
              <Text style={{ color: esiColor?.Text }}>
                We are sad to see you go, will make sure for better performance.
                We will keep your data in our records for security reasons and you can also reactivate your account in the future.
              </Text>
              <Image source={require("../../../../assets/images/GIF/delete_account.gif")} style={{ width: "100%", height: 150, alignSelf: "center", resizeMode: 'contain' }} />

            </View>
            <View style={{ width: "100%", flexDirection: 'row', justifyContent: 'space-evenly', margin: "auto" }}>

              <Button textColor={esiColor.itemButtenColor} style={{ marginRight: 10, backgroundColor: 'gray' }} mode="contained" onPress={() => {
                setDeleteModel(false);
                dispatch(deleteAccountAction(navigation, user, setLogoutDisabile));
              }}>
                Delete
              </Button>
              <Button textColor={esiColor.itemButtenColor} mode="contained" style={{ backgroundColor: esiColor?.globalButtonColor }} onPress={() => {
                setDeleteModel(false);
              }}>
                Cancel
              </Button>

            </View>

          </View>
        </Modal>
      </View>
    )
  }
  const updateModels = () => {

    return (
      <View>

        {/* delete Account */}

        <Modal animationIn={"slideInDown"}
          // deviceWidth={Dimensions.width}
          // deviceHeight={Dimensions.height}
          style={{ alignItems: 'center' }} isVisible={versionModel}>
          <View style={{ flex: 1, width: - 50, maxWidth: 500, minWidth: 300, minHeight: 250, maxHeight: 350, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>

            <View style={{ alignItems: 'flex-end' }}>
              <TouchableRipple style={{ width: 36 }} onPress={() => { setSersionModel(false) }} >
                <Avatar.Icon color={esiColor?.Text} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
              </TouchableRipple>
            </View>
            <View style={{ flexDirection: "column", marginTop: -20, marginHorizontal: 10, }} >
              <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Image source={require("../../../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />

              </View>
              {needed !== "" ? <Text style={{ textAlign: 'center', color: esiColor?.Text }}>
                you're Using latest Version.
              </Text> : <Text style={{ color: esiColor?.Text }}>
                Your are using an old version of the app. Get your hands on the latest version of the app and enjoy the best of delivery services at your fingertips.it will take only a few moments.
              </Text>}
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, paddingTop: 10
                }}
              >
                <Text style={{ color: esiColor?.Text }}>Provider </Text>
                <Text style={{ color: esiColor?.SIconColor }}>{"EasyStepIn IT Service Private Limited"}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10
                }}
              >
                <Text style={{ color: esiColor?.Text }}>Version </Text>
                <Text style={{ color: esiColor?.SIconColor }}>{version}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10
                }}
              >
                <Text style={{ color: esiColor?.Text }}>Size </Text>
                <Text style={{ color: esiColor?.SIconColor }}>{"8.97MB"}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10
                }}
              >
                <Text style={{ color: esiColor?.Text }}>Category </Text>
                <Text style={{ color: esiColor?.SIconColor }}>{"Shopping"}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10
                }}
              >
                <Text style={{ color: esiColor?.Text }}>Languages </Text>
                <Text style={{ color: esiColor?.SIconColor }}>{"English"}</Text>
              </View>
              {/* <Image source={require("../../../../assets/images/GIF/delete_account.gif")} style={{ width: "100%", height: 150, alignSelf: "center", resizeMode: 'contain' }} /> */}

            </View>
            <View style={{ width: "100%", flexDirection: 'row', justifyContent: 'space-evenly', margin: "auto" }}>

              <Button textColor={esiColor.itemButtenColor} mode="contained" style={{ backgroundColor: 'gray' }} onPress={() => {
                versionCheck()
                setSersionModel(false);
                setCurrentState("verify")
              }}>
                Cancel
              </Button>

              {needed !== "" ? null : <Button textColor={esiColor.itemButtenColor} style={{ marginRight: 10, backgroundColor: esiColor?.globalButtonColor }} mode="contained" onPress={() => {
                setSersionModel(false);
                setModal(false);
                Linking.openURL(storeurl)
              }}>
                Update
              </Button>}

            </View>

          </View>
        </Modal>
      </View>
    )
  }
  return (

    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <View style={{ justifyContent: 'center', paddingLeft: 20, backgroundColor: esiColor?.BackgroundColor, borderBottomWidth: 0.5, borderBottomColor: esiColor.SBorderColor }}>
        <View style={{ margin: 1, paddingTop: Platform.OS === 'ios' ? 46 : 28 && Platform.OS === 'web' ? -7 : 32, flexDirection: "row" }}>
          <TouchableOpacity onPress={() => navigation.navigate('EmployeProfile', { ID: "1" })}>
            <Image source={userPic} style={{ margin: 5, height: 50, width: 50, borderRadius: 45, backgroundColor: esiColor?.Text, marginTop: 10 }}></Image>
          </TouchableOpacity>
          <Text style={[styles.profile_name, { alignSelf: 'flex-end', flex: 1, paddingLeft: 7, marginBottom: 14 }]}>Hi, {user?.Partner_Name}</Text>
        </View>
      </View>

      <ScrollView>
        <Portal>
          <Dialog visible={visible} onDismiss={hideDialog} style={{ width: (dimensions >= 500) ? 400 : 270, maxHeight: (dimensions >= 500) ? 500 : 350, marginLeft: 'auto', marginRight: 'auto', }}>
            <View style={{ alignItems: 'flex-end' }}>
              <IconButton
                icon="close"
                size={20}
                color={esiColor?.SIconColor}
                onPress={() => setVisible(!visible)}
              />
            </View>
            <Dialog.Title style={{ fontSize: 20, marginTop: -20 }}>
              <Text style={{ color: esiColor?.SIconColor, fontSize: 15, textAlign: 'center' }}>Please Update you're Profile</Text>
            </Dialog.Title>
            <Dialog.ScrollArea>
              <ScrollView >
                <Dialog.Content>
                  <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5 }}
                    onPress={() => { navigation.navigate('PartnerProfile'), setVisible(!visible) }}>
                    {/* <View> */}
                    <Text style={{ color: esiColor.itemButtenColor }}>Update</Text>
                    {/* </View> */}
                  </TouchableOpacity>

                </Dialog.Content>
              </ScrollView>
            </Dialog.ScrollArea>
          </Dialog>
        </Portal>

        <Divider orientation="vertical" width={5} />

        <View style={{ justifyContent: 'center', margin: 20, marginTop: 10, marginBottom: 10 }}>
          {/* {(user?.Type === "ResellerShopper") && */}
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              {isSwitchOn == false ? <MaterialCommunityIcons name="account-off" size={24} color={esiColor?.SIconColor} /> : <MaterialCommunityIcons name="account" size={24} color={esiColor?.SIconColor} />}
              {/* <MaterialIcons name="account-off" size={24} color={esiColor?.SIconColor}  /> */}
            </View>
            <View style={{ flexDirection: 'row', flex: 1, padding: 5, justifyContent: 'space-between' }}>
              {isSwitchOn == false ? <Text style={{ fontSize: 16, color: esiColor?.Text }}>Store Close</Text> : <Text style={{ fontSize: 16, color: esiColor?.Text }}>Store Open</Text>}
              {/* <View > */}
              <Switch value={isSwitchOn}
                trackColor={{ true: esiColor?.SIconColor, false: 'red' }}
                style={{ height: 24 }}
                onValueChange={() => {
                  if (isSwitchOn == false) {
                    setModal(true);
                  }
                  onToggleSwitch()
                }
                } />
              {/* </View> */}
            </View>
          </View>
          {
            (isAuthenticated) &&
            <View style={{ flexDirection: 'row', margin: 10 }}>
              <View style={{ flexDirection: 'column' }}>
                <Ionicons name="document" size={24} color={esiColor?.SIconColor} />
              </View>

              <View style={{ flexDirection: 'column', padding: 5 }}>
                <TouchableOpacity onPress={() => navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } })}>
                  <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Buy from other partners</Text>
                </TouchableOpacity>
              </View>
            </View>
          }
          {
            (isAuthenticated) &&
            <View style={{ flexDirection: 'row', margin: 10 }}>
              <View style={{ flexDirection: 'column' }}>
                <MaterialCommunityIcons name="tag-multiple" size={24} color={esiColor?.SIconColor} />
              </View>

              <View style={{ flexDirection: 'column', padding: 5 }}>
                <TouchableOpacity onPress={() => navigation.navigate("Biddingproducts", { screen: 'Biddingproducts' })}>
                  <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Bid Masters</Text>
                </TouchableOpacity>
              </View>
            </View>
          }
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <MaterialCommunityIcons name="view-dashboard-outline" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Dashboard</Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <Ionicons name="bar-chart" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('ReportsGet')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Reports</Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <Feather name="dollar-sign" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('PartnerMainCenter')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Partner Main Centre</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <MaterialCommunityIcons name="offer" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('PartnerBiddingMainCenter')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Bidding Products</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <MaterialIcons name="shopping-basket" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('OrderMainCenter')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Order Management</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <Divider orientation="vertical" width={5} />

        <View style={{ justifyContent: 'center', margin: 20, marginTop: 10, marginBottom: 10 }}>
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <AntDesign name="profile" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('PartnerProfile', { ID: '1' })}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Company Profile</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <Feather name="user" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('Employees')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Partner Employees</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <FontAwesome name="money" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('WalletHistory')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>My Wallet</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <MaterialCommunityIcons name="bank" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('PartnerBank')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Bank Details</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <MaterialCommunityIcons name="cash-multiple" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('Transactions')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Transactions</Text>
              </TouchableOpacity>
            </View>
          </View>

          <Divider orientation="vertical" width={5} />

          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <Ionicons name="business" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('HowPartnerAssociationWorksGet')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>How Partner Association Works</Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <Ionicons name="document" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('ServiceLevelAgreementGet')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Service Level Agreement</Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={{ flexDirection: 'row', margin: 13 }}>
            <View style={{ flexDirection: 'column' }}>
              <FontAwesome name="phone-square" size={20} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5, marginHorizontal: 5, marginVertical: -3 }}>
              <TouchableOpacity onPress={() => navigation.navigate('ContactUs')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Contact Us</Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={{ flexDirection: 'row', margin: 13 }}>
            <View style={{ flexDirection: 'column' }}>
              <FontAwesome name="file-text" size={20} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5, marginHorizontal: 5, marginVertical: -3 }}>
              <TouchableOpacity onPress={() => navigation.navigate('PrivacyPolicy')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Privacy Policy</Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={{ flexDirection: 'row', margin: 13 }}>
            <View style={{ flexDirection: 'column' }}>
              <FontAwesome name="shield" size={22} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5, marginHorizontal: 5, marginVertical: -3 }}>
              <TouchableOpacity onPress={() => navigation.navigate('TermsAndConditions')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Terms and Conditions</Text>
              </TouchableOpacity>
            </View>
          </View>

          <Divider orientation="vertical" width={5} />

          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <MaterialIcons name="delivery-dining" size={24} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('DeliveryAgent')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Delivery Agent</Text>
              </TouchableOpacity>
            </View>
          </View>
          <Divider orientation="vertical" width={5} />
          {/* <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <Feather name="tool" size={24} color={esiColor?.SIconColor}  />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('SettingsGet')}>
                <Text style={{ fontWeight: 'bold' }}>Settings</Text>
              </TouchableOpacity>
            </View>
          </View> */}
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <FontAwesome name="ticket" size={20} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={() => navigation.navigate('Ticketing')}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Support Tickets</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <Feather name="power" size={22} color={esiColor?.SIconColor} />
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity onPress={_handleSignOut}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Logout</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexDirection: 'row', margin: 10 }}>
            <View style={{ flexDirection: 'column' }}>
              <FontAwesome name="user-times" size={20} color={esiColor?.SIconColor} ></FontAwesome>
            </View>
            <View style={{ flexDirection: 'column', padding: 5 }}>
              <TouchableOpacity style={styles.item_styles} disabled={logoutLoading} onPress={_handleDelete}>
                <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>Delete Account</Text>
              </TouchableOpacity>
            </View>
          </View>
          {Platform.OS == 'web' ? null :
            <View style={{ flexDirection: 'row', margin: 10 }}>
              <TouchableOpacity style={styles.item_styles} disabled={logoutLoading} onPress={_handleVersion}>
                <View style={{ flexDirection: 'column' }}>
                  <MaterialIcons name="info" size={24} color={esiColor?.SIconColor} />
                  {needed && needed !== "0" ?
                    <View
                      style={{
                        position: 'absolute',
                        backgroundColor: 'red',
                        width: 16,
                        height: 16,
                        borderRadius: 15 / 2,
                        right: 5,
                        top: 9,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Text
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: "#FFFFFF",
                          fontSize: 8,
                        }}>
                        {"!"}
                      </Text>
                    </View>
                    : null}
                </View>
                <View style={{ flexDirection: 'column', padding: 5 }}>
                  <Text style={{ fontWeight: 'bold', color: esiColor?.Text }}>About</Text>
                </View>
              </TouchableOpacity>
            </View>}
        </View>
      </ScrollView>
      {customModels()}
      {updateModels()}
    </Surface>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};


export default NavBar;