import axios from 'axios';
import config from './config';

export const USERPAYMENT_REQUEST = "USERPAYMENT_REQUEST";
export const USERPAYMENT_SUCCESS = "USERPAYMENT_SUCCESS";
export const USERPAYMENT_FAIL = "USERPAYMENT_FAIL";

export const USERPAYMENT_GET_REQUEST = " USERPAYMENT_GET_REQUEST";
export const USERPAYMENT_GET_SUCCESS = " USERPAYMENT_GET_SUCCESS";
export const USERPAYMENT_GET_FAIL = " USERPAYMENT_GET_FAIL";

export const USERPAYMENT_UPDATE_REQUEST = "USERPAYMENT_UPDATE_REQUEST";
export const USERPAYMENT_UPDATE_SUCCESS = "USERPAYMENT_UPDATE_SUCCESS";
export const USERPAYMENT_UPDATE_FAIL = "USERPAYMENT_UPDATE_FAIL";

export const USERPAYMENT_FILTER = "USERPAYMENT_FILTER";

const headers = config.headersCommon;

/*=============================================================
                   Get All user payment Action
===============================================================*/

export const getAllUserpaymentAction = (formData: any) => async dispatch => {

    try {
        dispatch({
            type: USERPAYMENT_REQUEST
        });
        let { data } = await axios.post(
            `${config.url}/User_Payment_Settlement/User_Payment_Settlement_Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: USERPAYMENT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: USERPAYMENT_SUCCESS,
                    payload: data.User_Payment_Settlement
                });
            }
        }
    } catch (err) {
        dispatch({
            type: USERPAYMENT_FAIL,
            payload: err
        });
    }
};

/*=============================================================
                 Update user payment Action
===============================================================*/

export const updateUserpaymentAction = (formData: any, navigation: any, toast: any) => async dispatch => {
    dispatch({
        type: USERPAYMENT_UPDATE_REQUEST
    });
    axios.put(`${config.url}/User_Payment_Settlement/User_Payment_Settlement_Update`, formData,
        {
            headers: headers
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: USERPAYMENT_UPDATE_SUCCESS
                });
                navigation.navigate('UserpaymentGet')
                toast.show({ message: 'User payment updated successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    Records_Filter: "FILTER",
                    Partner_Details_Id: formData.Partner_Details_Id
                };
                dispatch(getAllUserpaymentAction(formValue))
            } else {
                const errors = {};
                dispatch({
                    type: USERPAYMENT_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: USERPAYMENT_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


// Filter 
export const userpaymentFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: USERPAYMENT_FILTER,
            fType: fType,
            value: value,
        });
    }
}

//search
export const resetFilter = () => {
    return function (dispatch: any) {
      dispatch({
        type: USERPAYMENT_FILTER,
       fType: "search", 
        value: "",
      });
    }
  }


export const getUserPaymentByIdAction = (formData: any,callBackData: any) => async dispatch => {

    try {
        dispatch({
            type: USERPAYMENT_REQUEST
        });
        let { data,status } = await axios.post(
            `${config.url}/User_Payment_Settlement/User_Payment_Settlement_Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            callBackData(data.User_Payment_Settlement) 
        }
    } catch (err) {
        dispatch({
            type: USERPAYMENT_FAIL,
            payload: err
        });
    }
};