import {
    SBCPARTNERCITYDEAL_REQUEST,
    SBCPARTNERCITYDEAL_SUCCESS,
    SBCPARTNERCITYDEAL_FAIL,

    SBCPARTNERCITYDEAL_GET_REQUEST,
    SBCPARTNERCITYDEAL_GET_SUCCESS,
    SBCPARTNERCITYDEAL_GET_FAIL,

    SBCPARTNERCITYDEAL_CREATE_REQUEST,
    SBCPARTNERCITYDEAL_CREATE_SUCCESS,
    SBCPARTNERCITYDEAL_CREATE_FAIL,

    SBCPARTNERCITYDEAL_UPDATE_REQUEST,
    SBCPARTNERCITYDEAL_UPDATE_SUCCESS,
    SBCPARTNERCITYDEAL_UPDATE_FAIL,

    SBCPARTNERCITYDEAL_DELETE_REQUEST,
    SBCPARTNERCITYDEAL_DELETE_SUCCESS,
    SBCPARTNERCITYDEAL_DELETE_FAIL,

    SBCPARTNERCITY_DEALS_REQUEST,
    SBCPARTNERCITY_DEALS_SUCCESS,
    SBCPARTNERCITY_DEALS_FAIL,

    SBCPARTNERCITYDEAL_FILTER,

} from '../actions/sbcpartnercityDealAction'

const initialState = {

    sbcpartnercitydeals: { all: [], error: '', isLoading: false },
    sbcpartnercitydeal: { sbcpartnercitydeal: {}, error: '', isLoading: false },

    sbcpartnercitydealCreate: { sbcpartnercitydeal: {}, error: '', isLoading: false },
    sbcpartnercitydealUpdate: { sbcpartnercitydeal: {}, error: '', isLoading: false },
    sbcpartnercitydealDelete: { sbcpartnercitydeal: {}, error: '', isLoading: false },

    sbcpartcitydeals: { deals: [], error: '', isLoading: false },

    sbcpartnercitydealFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case SBCPARTNERCITYDEAL_REQUEST:
            return { ...state, sbcpartnercitydeals: { all: state.sbcpartnercitydeals.all, error: '', isLoading: true } };
        case SBCPARTNERCITYDEAL_SUCCESS:
            return { ...state, sbcpartnercitydeals: { all: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITYDEAL_FAIL:
            return { ...state, sbcpartnercitydeals: { all: [], error: action.payload, isLoading: false } };

        case SBCPARTNERCITYDEAL_GET_REQUEST:
            return { ...state, sbcpartnercitydeal: { sbcpartnercitydeal: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYDEAL_GET_SUCCESS:
            return { ...state, sbcpartnercitydeal: { sbcpartnercitydeal: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITYDEAL_GET_FAIL:
            return { ...state, sbcpartnercitydeal: { sbcpartnercitydeal: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITYDEAL_CREATE_REQUEST:
            return { ...state, sbcpartnercitydealCreate: { sbcpartnercitydeal: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYDEAL_CREATE_SUCCESS:
            return { ...state, sbcpartnercitydealCreate: { sbcpartnercitydeal: state.sbcpartnercitydealCreate.sbcpartnercitydeal, error: '', isLoading: false } };
        case SBCPARTNERCITYDEAL_CREATE_FAIL:
            return { ...state, sbcpartnercitydealCreate: { sbcpartnercitydeal: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITYDEAL_UPDATE_REQUEST:
            return { ...state, sbcpartnercitydealUpdate: { sbcpartnercitydeal: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYDEAL_UPDATE_SUCCESS:
            return { ...state, sbcpartnercitydealUpdate: { sbcpartnercitydeal: state.sbcpartnercitydealUpdate.sbcpartnercitydeal, error: '', isLoading: false } };
        case SBCPARTNERCITYDEAL_UPDATE_FAIL:
            return { ...state, sbcpartnercitydealUpdate: { sbcpartnercitydeal: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITYDEAL_DELETE_REQUEST:
            return { ...state, sbcpartnercitydealDelete: { sbcpartnercitydeal: {}, error: '', isLoading: true } };
        case SBCPARTNERCITYDEAL_DELETE_SUCCESS:
            return { ...state, sbcpartnercitydealDelete: { sbcpartnercitydeal: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITYDEAL_DELETE_FAIL:
            return { ...state, sbcpartnercitydealDelete: { sbcpartnercitydeal: {}, error: action.payload, isLoading: false } };

        case SBCPARTNERCITY_DEALS_REQUEST:
            return { ...state, sbcpartcitydeals: { deals: state.sbcpartcitydeals.deals, error: '', isLoading: true }, };
        case SBCPARTNERCITY_DEALS_SUCCESS:
            return { ...state, sbcpartcitydeals: { deals: action.payload, error: '', isLoading: false } };
        case SBCPARTNERCITY_DEALS_FAIL:
            return { ...state, sbcpartcitydeals: { deals: [], error: action.payload, isLoading: false } };

        case SBCPARTNERCITYDEAL_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, sbcpartnercitydealFilter: { search: state.sbcpartnercitydealFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, sbcpartnercitydealFilter: { select: state.sbcpartnercitydealFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}