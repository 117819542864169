import React, { useState } from 'react';
import { Card, Button, HelperText, Surface } from 'react-native-paper';
import { View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    productsdata
} from '../../../../state/actions/farmer/farmerproductsAction';
import Header from '../../../auth/components/header';
import EsiEditor from '../../../../components/custom/richtext-editor/richtext-editor';
import isEmpty from '../../../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddFarmerProductspagetwo = (props: any) => {
    const dispatch = useDispatch();
    const { navigation } = props;
    const esiColor = useSelector(state => state.theme);
    const productmaster = useSelector(state => state?.farmerproduct?.productdata?.products);
    const EmailID = useSelector(state => state.auth?.user);

    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [descriptionvalue, setDescriptionValue] = React.useState();

    const [rhFeaturesMin, setRhFeaturesMin] = useState(20);
    const [featuresvalue, setFeaturesValue] = React.useState();
    const [errorMessage, setErrorMessage] = useState({ Product_Name: { key: '', message: '' }, global: { key: '', message: '' } });

    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    // if field value are empty then redirect to first page
    React.useEffect(() => {
        if (isEmpty(productmaster)) {
            navigation.navigate('AddFarmerProducts')
        } else {
            setDescriptionValue(productmaster?.Product_Description)
            setFeaturesValue(productmaster?.Features)
        }
    }, [productmaster]
    );
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    // for screen dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    return (
        <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }} >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }
            >
                <View>
                    {descriptionvalue &&
                        <Formik enableReinitialize={true}
                            initialValues={{
                                Brand_Id: productmaster.Brand_Id,
                                Category_Id: productmaster.Category_Id,
                                Country_Of_Origin: productmaster.Country_Of_Origin,
                                Created_User_Type: EmailID.User_Type,
                                Partner_Details_ID: EmailID.Partner_Details_Id,
                                Esi_Rating: '',
                                Esi_Review: "",
                                Features: productmaster.Features,
                                Is_Popular: false,
                                Is_Top_Product: false,
                                Is_Upcoming: false,
                                Is_Verified: false,
                                Product_Color_Variant_Id: productmaster.Product_Color_Variant_Id,
                                Product_Description: productmaster.Product_Description,
                                Product_Master_Id: productmaster.Product_Master_Id,
                                Product_Name: productmaster.Product_Name,
                                Product_Price: productmaster.Product_Price,
                                Product_Short_Description: productmaster.Product_Short_Description,
                                Product_Size_Id: productmaster.Product_Size_Id,
                                Specifications: productmaster.Specifications,
                                Created_By_Email: EmailID.Email_Id,
                                Warranty_Period: productmaster.Warranty_Period,
                                Status: "In-Active",
                                Sub_Category_Id: productmaster.Sub_Category_Id,
                                Whats_Hot: false,
                            }}
                            //field validations
                            validationSchema={Yup.object().shape({
                                Product_Description: Yup.string().required('Please provide Description.'),
                                Features: Yup.string().required('Please provide Features.'),
                                Specifications: Yup.string().required('Please provide Specifications.'),
                            })}
                            onSubmit={(values, { setSubmitting, setErrors }) => {
                                let submitTest = true;
                                setSubmitting(false);
                                const errors = {};
                                values.Product_Description = descriptionvalue;
                                values.Features = featuresvalue;

                                if (!values.Product_Description || values.Product_Description.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
                                    submitTest = false;
                                    errors.Product_Description = "Description is required.";
                                }
                                else if (values.Product_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
                                    submitTest = false;
                                    errors.Product_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
                                }
                                if (!values.Features || values.Features.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
                                    submitTest = false;
                                    errors.Features = "Features is required.";
                                }
                                else if (values.Features.replace(/(<([^>]+)>)/ig, '').trim().length < rhFeaturesMin) {
                                    submitTest = false;
                                    errors.Features = "Please provide at least " + rhFeaturesMin + " characters.";
                                }

                                setErrors(errors);
                                if (submitTest) {
                                    let formValues = JSON.parse(JSON.stringify(values));
                                    dispatch(productsdata(formValues, props.navigation));
                                    navigation.navigate('AddFarmerProductspagethree')
                                }
                            }
                            }
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                                setFieldValue,
                            }) => (
                                <View style={{
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap"
                                }}>
                                    <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('AddFarmerProducts')} >
                                            Go Back
                                        </Button>
                                    </View>
                                    <Card style={{
                                        width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%",
                                        borderRadius: 6, borderColor: esiColor.SBorderColor,
                                        backgroundColor: esiColor.CBColor,
                                        shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 1
                                    }}>
                                        <Card.Content>
                                            <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                                Add Product
                                            </Text>
                                            {/* EsiEditor for Product Description */}
                                            <View style={{ paddingTop: Platform.OS !== "web" ? 10 : 10, paddingBottom: Platform.OS !== "web" ? 10 : null }}>
                                                <EsiEditor label={"Product Description"} value={descriptionvalue} onUpdateValue={(value) => { setDescriptionValue(value) }} />
                                                <HelperText type="error" visible={(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Product_Description && errors.Product_Description}>
                                                    {(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && touched.Product_Description && errors.Product_Description}
                                                </HelperText>
                                            </View>
                                            {/* EsiEditor for Product Features */}
                                            <View style={{ paddingTop: Platform.OS !== "web" ? 10 : 10, paddingBottom: Platform.OS !== "web" ? 10 : null }}>
                                                <EsiEditor label={"Features"} value={featuresvalue} onUpdateValue={(value) => { setFeaturesValue(value) }} />
                                                <HelperText type="error" visible={(!featuresvalue || featuresvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhFeaturesMin) && touched.Features && errors.Features}>
                                                    {(!featuresvalue || featuresvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhFeaturesMin) && touched.Features && errors.Features}
                                                </HelperText>
                                            </View>
                                        </Card.Content>
                                        <View style={{ marginLeft: "1%" }}>
                                            <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                                        </View>
                                        <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", marginVertical: 10 }}>
                                            <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor}} mode="contained" onPress={handleSubmit}>
                                              <Text style={{color: esiColor.itemButtenColor}}>Next</Text>  
                                            </Button>
                                        </View>
                                    </Card>
                                </View>
                            )}
                        </Formik>
                    }
                </View>
            </ScrollView>
        </Surface>
    );
};


export default AddFarmerProductspagetwo;