import axios from 'axios';
import config from '../config';
import { meeapi } from '../node-actions/meeapi';
export const FARMER_ORDER_RETURN_REQUEST = "FARMER_ORDER_RETURN_REQUEST";
export const FARMER_ORDER_RETURN_SUCCESS = "FARMER_ORDER_RETURN_SUCCESS";
export const FARMER_ORDER_RETURN_FAIL = "FARMER_ORDER_RETURN_FAIL";
export const FARMER_ORDER_RETURN_FILTER = "FARMER_ORDER_RETURN_FILTER";
export const FARMER_ORDER_CANCEL_REQUEST = "FARMER_ORDER_CANCEL_REQUEST";
export const FARMER_ORDER_CANCEL_SUCCESS = "FARMER_ORDER_CANCEL_SUCCESS";
export const FARMER_ORDER_CANCEL_FAIL = "FARMER_ORDER_CANCEL_FAIL";
export const FARMER_ORDER_CANCEL_FILTER = "FARMER_ORDER_CANCEL_FILTER";
/*=============================================================
                  Get All order return Action
===============================================================*/

const headers = config.headersCommon;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
  const urlGenarator = (url, pagination) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
  }
export const getAllReturnOrdersAction = (formData:any,pagination = {}) => async dispatch => {
  try {
    dispatch({
      type: FARMER_ORDER_RETURN_REQUEST
    });
   meeapi.post(urlGenarator(`/Order_Management/Return_Order`,pagination),
      formData,
    ) .then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: FARMER_ORDER_RETURN_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: FARMER_ORDER_RETURN_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: FARMER_ORDER_RETURN_FAIL,
      payload: err
    });
  }
};
// Filter 
export const returnOrdersFilter = (fType: any, value: any) => {
    return function (dispatch) {
      dispatch({
        type: FARMER_ORDER_RETURN_FILTER,
        fType: fType,
        value: value,
      });
    }
  }
//search
export const resetreturnFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: FARMER_ORDER_RETURN_FILTER,
     fType: "search", 
      value: "",
    });
  }
}


//search
export const resetcancelFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: FARMER_ORDER_CANCEL_FILTER,
     fType: "search", 
      value: "",
    });
  }
}




/*=============================================================
                  Get All order Cancellation Action
===============================================================*/
  export const getAllCancelOrdersAction = (formData:any,pagination = {}) => async dispatch => {
    try {
      dispatch({
        type: FARMER_ORDER_CANCEL_REQUEST
      });
     meeapi.post(urlGenarator(`/Order_Management/Cancel_Order`,pagination),
        formData,
      ) .then(response => {
        let data = response.data;
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: FARMER_ORDER_CANCEL_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: FARMER_ORDER_CANCEL_SUCCESS,
            payload: data
          });
        }
      })
    } catch (err) {
      dispatch({
        type: FARMER_ORDER_CANCEL_FAIL,
        payload: err
      });
    }
  };
