import {
  ALL_FARMER_ORDERS_REQUEST,
  ALL_FARMER_ORDERS_SUCCESS,
  ALL_FARMER_ORDERS_FAIL,
  FARMER_ORDERS_FILTER,
  SINGLE_ORDER_REQUEST,
  SINGLE_ORDER_SUCCESS,
  SINGLE_ORDER_FAIL
} from '../../actions/farmer/farmerordermanagementAction'
const initialState = {
  farmerorders: { all: [], error: '', isLoading: false },
  orderFilter: { select: 'All', search: '' },
  singleorder: { all: {}, error: '', isLoading: false },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ALL_FARMER_ORDERS_REQUEST:
      return { ...state,farmerorders: { all: state.farmerorders.all, error: '', isLoading: true } };
    case ALL_FARMER_ORDERS_SUCCESS:
      return { ...state, farmerorders: { all: action.payload, error: '', isLoading: false } };
    case ALL_FARMER_ORDERS_FAIL:
      return { ...state, farmerorders: { all: [], error: action.payload, isLoading: false } };
      case  SINGLE_ORDER_REQUEST:
        return { ...state, singleorder: { all: state.singleorder.all, error: '', isLoading: true } };
      case  SINGLE_ORDER_SUCCESS:
        return { ...state, singleorder: { all: action.payload, error: '', isLoading: false } };
      case  SINGLE_ORDER_FAIL:
        return { ...state, singleorder: { all: {}, error: action.payload, isLoading: false } };
        case FARMER_ORDERS_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, orderFilter: { search: state.orderFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, orderFilter: { select: state.orderFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}
