import {
  USERPAYMENT_REQUEST,
  USERPAYMENT_SUCCESS,
  USERPAYMENT_FAIL,

  USERPAYMENT_GET_REQUEST,
  USERPAYMENT_GET_SUCCESS,
  USERPAYMENT_GET_FAIL,

  USERPAYMENT_UPDATE_REQUEST,
  USERPAYMENT_UPDATE_SUCCESS,
  USERPAYMENT_UPDATE_FAIL,

  USERPAYMENT_FILTER,

} from '../actions/userpaymentAction'

const initialState = {

  userpayments: { all: [], error: '', isLoading: false },
  userpayment: { userpayment: {}, error: '', isLoading: false },
  userpaymentUpdate: { userpayment: {}, error: '', isLoading: false },

  userpaymentFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

  switch (action.type) {
    case USERPAYMENT_REQUEST:
      return { ...state, userpayments: { all: state.userpayments.all, error: '', isLoading: true } };
    case USERPAYMENT_SUCCESS:
      return { ...state, userpayments: { all: action.payload, error: '', isLoading: false } };
    case USERPAYMENT_FAIL:
      return { ...state, userpayments: { all: [], error: action.payload, isLoading: false } };

    case USERPAYMENT_GET_REQUEST:
      return { ...state, userpayment: { userpayment: {}, error: '', isLoading: true } };
    case USERPAYMENT_GET_SUCCESS:
      return { ...state, userpayment: { userpayment: action.payload, error: '', isLoading: false } };
    case USERPAYMENT_GET_FAIL:
      return { ...state, userpayment: { userpayment: {}, error: action.payload, isLoading: false } };

    case USERPAYMENT_UPDATE_REQUEST:
      return { ...state, userpaymentUpdate: { userpayment: {}, error: '', isLoading: true } };
    case USERPAYMENT_UPDATE_SUCCESS:
      return { ...state, userpaymentUpdate: { userpayment: state.userpaymentUpdate.userpayment, error: '', isLoading: false } };
    case USERPAYMENT_UPDATE_FAIL:
      return { ...state, userpaymentUpdate: { userpayment: {}, error: action.payload, isLoading: false } };


    case USERPAYMENT_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, userpaymentFilter: { search: state.userpaymentFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, userpaymentFilter: { select: state.userpaymentFilter.select, search: action.value } };
      }
      return res;

    default:
      return state;
  }
}