import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { View, Dimensions, ScrollView, RefreshControl } from 'react-native';
import Header from '../auth/components/header';
import Headers from '../Farmer-Module/header';
import { updatePartnerBankAction, getPartnerBankAction } from '../../state/actions/partnerbankAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';


export default function Partnerbank(props) {
  //navigation is used for navigation between screens
  const { navigation } = props
  const page = props.route?.params?.page;
  const dispatch = useDispatch();

  //toast for popup after successful API calls.
  const toast = useToast();
  const esiColor = useSelector(state => state.theme);

  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  //partners contains the data from  GET API using state call
  const partnersbank = useSelector(state => state.partnerbank.partnersbank.all);
  const EmailID = useSelector(state => state.auth?.user);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  //dispatch is used to perform the API action
  useEffect(() => {
    let formData = {
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    };
    dispatch(getPartnerBankAction(formData))

  }, []);

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  /*====================================
          Handler
  ====================================*/
  const values = {
    Partner_Details_Id: EmailID?.Partner_Details_Id,
    Upi_Id: partnersbank?.Upi_Id ? partnersbank?.Upi_Id : "",
    Bank_Account_Number: partnersbank?.Bank_Account_Number ? partnersbank?.Bank_Account_Number : "",
    Bank_Name: partnersbank?.Bank_Name ? partnersbank?.Bank_Name : "",
    Bank_Beneficiary_Name: partnersbank?.Bank_Beneficiary_Name ? partnersbank?.Bank_Beneficiary_Name : "",
    Bank_Ifsc_Code: partnersbank?.Bank_Ifsc_Code ? partnersbank?.Bank_Ifsc_Code : "",
    Paypal_Id: partnersbank?.Paypal_Id ? partnersbank?.Paypal_Id : "",
    Modified_By: EmailID.Email_Id,
    Created_By: partnersbank?.Created_By ? partnersbank?.Created_By : "",
    Parnter_Bank_Detail_Id: partnersbank?.Parnter_Bank_Detail_Id ? partnersbank?.Parnter_Bank_Detail_Id : ""
  }

  //Form validation
  const Form_Validation = Yup.object().shape({
    Bank_Name: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Bank Name.').min(4, "Bank Name must be at least 4 characters.").required('Bank Name is required.'),
    Bank_Beneficiary_Name: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Bank Beneficiary Name.').required('Bank Beneficiary Name is required.'),
    Bank_Account_Number: Yup.string()
      .matches(/^\d+$/, 'Account number must contain only numbers')
      .min(9, 'Please enter Valid Account number ')
      .max(16, 'Please enter Valid Account number')
      .required('Bank Account Number is required.'),
    Bank_Ifsc_Code: Yup.string()
      .matches(/^[A-Z]{4}[0][0-9]{6}$/, 'Invalid IFSC code')
      .required('IFSC code is required'),
    Upi_Id: Yup.string().matches(/[a-zA-Z0-9_]{5,}@[a-zA-Z]{3,}/, " UPI ID is not valid.").required('UPI ID is required.'),
    // Bank_Ifsc_Code: Yup.string().matches(/^(?=.*[A-Z])(?=.*[0-9])/, "IFSC Code is not valid.").max(21,"IFSC Code is not valid.").required("Bank IFSC Code is required."),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};


    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(updatePartnerBankAction(formValues, props.navigation, toast));

    }
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  return (
    <Surface style={{ flex: 1 ,backgroundColor: esiColor.BackgroundColor }}>
      {page === 'farmer' ? <Headers navigation={navigation} /> : <Header navigation={navigation} />}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {partnersbank &&
          <Formik initialValues={{ ...values }} enableReinitialize={true} onSubmit={onSubmit} validationSchema={Form_Validation}>
            {(props) => (
              <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                {/* <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => { props.resetForm(); navigation.navigate('Dashboard') }}>
                    Go Back
                  </Button>
                </View> */}
                <View pointerEvents={!(permissions.Partner_Bank === "write") ? 'none' : 'auto'}>
                  <Card style={{
                   width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                   marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                   backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                  }}>
                    <Card.Content>
                      <Text style={{ fontSize: 20, fontWeight: "bold",color:esiColor.brandFontColor }}>
                        Partner Bank Details
                      </Text>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          {/*  Bank Name Input Field  with Validation */}
                          <TextInput
                            
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Bank_Name && props.errors.Bank_Name)}
                            label='Bank Name'
                            name='Bank_Name'
                            mode='outlined'
                            value={props.values.Bank_Name}
                            onChangeText={props.handleChange('Bank_Name')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Bank_Name && props.errors.Bank_Name)}>
                            <ErrorMessage name="Bank_Name" />
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          {/* Bank Beneficiary Name Input Field  with Validation */}
                          <TextInput
                            
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Bank_Beneficiary_Name && props.errors.Bank_Beneficiary_Name)}
                            label='Bank Beneficiary Name'
                            mode='outlined'
                            name="Bank_Beneficiary_Name"
                            value={props.values.Bank_Beneficiary_Name}
                            onChangeText={props.handleChange('Bank_Beneficiary_Name')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Bank_Beneficiary_Name && props.errors.Bank_Beneficiary_Name)}>
                            <ErrorMessage name="Bank_Beneficiary_Name" />
                          </HelperText>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          {/*  Bank Account Number Input Field  with Validation */}
                          <TextInput
                            
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Bank_Account_Number && props.errors.Bank_Account_Number)}
                            label='Bank Account Number'
                            name='Bank_Account_Number'
                            mode='outlined'
                            value={props.values.Bank_Account_Number}
                            onChangeText={props.handleChange('Bank_Account_Number')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Bank_Account_Number && props.errors.Bank_Account_Number)}>
                            <ErrorMessage name="Bank_Account_Number" />
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          {/*  Bank Ifsc Code Input Field  with Validation */}
                          <TextInput
                            
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Bank_Ifsc_Code && props.errors.Bank_Ifsc_Code)}
                            label='Bank IFSC Code'
                            mode='outlined'
                            name="Bank_Ifsc_Code"
                            value={props.values.Bank_Ifsc_Code}
                            onChangeText={props.handleChange('Bank_Ifsc_Code')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Bank_Ifsc_Code && props.errors.Bank_Ifsc_Code)}>
                            <ErrorMessage name="Bank_Ifsc_Code" />
                          </HelperText>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          {/* UPI Id Input Field  with Validation */}
                          <TextInput
                            
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Upi_Id && props.errors.Upi_Id)}
                            label='UPI ID'
                            name='Upi_Id'
                            mode='outlined'
                            value={props.values.Upi_Id}
                            onChangeText={props.handleChange('Upi_Id')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Upi_Id && props.errors.Upi_Id)}>
                            <ErrorMessage name="Upi_Id" />
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          {/*  Paypal Id Input Field  with Validation */}
                          <TextInput
                            
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Paypal_Id && props.errors.Paypal_Id)}
                            label='Paypal ID '
                            mode='outlined'
                            name="Paypal_Id"
                            value={props.values.Paypal_Id}
                            onChangeText={props.handleChange('Paypal_Id')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Paypal_Id && props.errors.Paypal_Id)}>
                            <ErrorMessage name="Paypal_Id" />
                          </HelperText>
                        </View>
                      </View>

                      <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                        <Button textColor={esiColor.itemButtenColor}  style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                          Save
                        </Button>
                      </View>

                    </Card.Content>
                  </Card>
                </View>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface>
  );
};



