import axios from 'axios';
import { getAllFARMEROrdersAction } from '../../actions/farmer/farmerordermanagementAction';
import { meeapi } from '../node-actions/meeapi';
import shopperconfig from '../shoppers/shopperconfig';
import config from '../config';

export const FARMER_SHIPPING_REQUEST = "FARMER_SHIPPING_REQUEST";
export const FARMER_SHIPPING_SUCCESS = "FARMER_SHIPPING_SUCCESS";
export const FARMER_SHIPPING_FAIL = "FARMER_SHIPPING_FAIL";

export const FARMER_SHIPPING_GET_REQUEST = "FARMER_SHIPPING_GET_REQUEST";
export const FARMER_SHIPPING_GET_SUCCESS = "FARMER_SHIPPING_GET_SUCCESS";
export const FARMER_SHIPPING_GET_FAIL = "FARMER_SHIPPING_GET_FAIL";

export const FARMER_SHIPPING_UPDATE_REQUEST = "FARMER_SHIPPING_UPDATE_REQUEST";
export const FARMER_SHIPPING_UPDATE_SUCCESS = "FARMER_SHIPPING_UPDATE_SUCCESS";
export const FARMER_SHIPPING_UPDATE_FAIL = "FARMER_SHIPPING_UPDATE_FAIL";

export const FARMER_SHIPPING_FILTER = "FARMER_SHIPPING_FILTER";

export const FARMER_DELIVERY_PERSON_REQUEST = "FARMER_DELIVERY_PERSON_REQUEST";
export const FARMER_DELIVERY_PERSON_SUCCESS = "FARMER_DELIVERY_PERSON_SUCCESS";
export const FARMER_DELIVERY_PERSON_FAIL = "FARMER_DELIVERY_PERSON_FAIL";
export const FARMER_PARTNER_ADDRESS_REQUEST = "FARMER_PARTNER_ADDRESS_REQUEST";
export const FARMER_PARTNER_ADDRESS_SUCCESS = "FARMER_PARTNER_ADDRESS_SUCCESS";
export const FARMER_PARTNER_ADDRESS_FAIL = "FARMER_PARTNER_ADDRESS_FAIL";
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
      queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
/*=============================================================
                   Get All shipping Action
===============================================================*/

export const getAllShippingAction = (formData: any,pagination = {}) => async dispatch => {

  try {
    dispatch({
      type: FARMER_SHIPPING_REQUEST
    });
    meeapi.post(urlGenarator(`/Shipping/Get`,pagination),
      formData,
    ) .then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: FARMER_SHIPPING_SUCCESS,
          payload: []
        });

      } else {
        dispatch({
          type: FARMER_SHIPPING_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: FARMER_SHIPPING_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                 Update shipping Action
===============================================================*/

export const updateShippingAction = (Order_Update: any, navigation: any, toast: any, ID: any, Partner_Details_Id: any) => async dispatch => {
  dispatch({
    type: FARMER_SHIPPING_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Order_Management/Order_UpdateFlow`, { Order_Update },
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;

      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: FARMER_SHIPPING_UPDATE_SUCCESS
        });
        if (ID) {
          navigation.navigate('ShippingGet')
          toast.show({ message: 'Shipping status updated successfully.', type: 'info', duration: 3000, position: 'top' });
          
          let pagecall= { PageNo: 1, PageSize: 5, SortBy: "FARMER_SHIPPING_ID", SortOrder: "DESC" }
          let formData = {
              "search": "", 
              "search_by_filter":'',
              "Item_Master_Id": "",
              "Order_Id": "",
              "Partner_Details_Id": Partner_Details_Id,
              "Records_Filter":"FILTER",
              "Shipping_Id": "",
              "Shipping_Reference_Id": "",
              "Shipping_Status":""
  
          };
        
          dispatch(getAllShippingAction(formData,pagecall))
        } else {
          toast.show({ message: 'Shipping status updated successfully.', type: 'info', duration: 3000, position: 'top' });
          const pagination={
            PageNo: 1, PageSize: 5, SortBy: "ORDER_ID", SortOrder: "DESC" 
          }
            const formValue = {
            "search_by_filter": "",
            "search": "",
            "Is_Approved": "",
            "Order_Id": "",
            "Order_Status": "",
            "Partner_Detail_ID": Partner_Details_Id,
            "User_Email_Id": "",
            "Records_Filter": "FILTERS"
          };
          dispatch(getAllFARMEROrdersAction(formValue, pagination))
          navigation.navigate('FarmerOrderDetails')
        }

      } else {
        const errors = {};
        dispatch({
          type: FARMER_SHIPPING_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FARMER_SHIPPING_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

// Filter 
export const shippingFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: FARMER_SHIPPING_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//search

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: FARMER_SHIPPING_FILTER,
      fType: "search",
      value: "",
    });
  }
}

export const getShippingByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    dispatch({
      type: FARMER_SHIPPING_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Shipping/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)
    }
  } catch (err) {
    dispatch({
      type: FARMER_SHIPPING_FAIL,
      payload: err
    });
  }
};
///delivery person get by partner Id
export const getDeliveryPesrsonAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: FARMER_DELIVERY_PERSON_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Delivery_Partner/Partner_Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );

    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: FARMER_DELIVERY_PERSON_SUCCESS,
          payload: []
        });

      } else {
        dispatch({
          type: FARMER_DELIVERY_PERSON_SUCCESS,
          payload: data?.results ? data?.results : []
        });
      }
    }
  }
  catch (err) {
    dispatch({
      type: FARMER_DELIVERY_PERSON_FAIL,
      payload: err
    });
  }
};
// partner address 
export const getPartnerAddressAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: FARMER_PARTNER_ADDRESS_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Address/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: FARMER_PARTNER_ADDRESS_SUCCESS,
          payload: []
        });

      } else {
        dispatch({
          type: FARMER_PARTNER_ADDRESS_SUCCESS,
          payload: data.results
        });
      }
    }
  }
  catch (err) {
    dispatch({
      type: FARMER_PARTNER_ADDRESS_FAIL,
      payload: err
    });
  }
};

export const getShippingAddressAction =
  (updateStates: any, formData: any) => async (dispatch: any) => {
    try {
      let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/User/Address_Get`, formData, {
        headers: nodeheaders
      });
      if (data) {
        updateStates(data?.results);
      }
    } catch (error) {
    }
  };