import {
    B2B_ALL_ORDERS_REQUEST,
    B2B_ALL_ORDERS_SUCCESS,
    B2B_ALL_ORDERS_FAIL,
   
    B2B_ORDER_UPDATE_REQUEST,
    B2B_ORDER_UPDATE_SUCCESS,
    B2B_ORDER_UPDATE_FAIL,

    B2B_ORDERS_FILTER,

    RESET,

    B2B_SINGLE_ORDER_REQUEST,
    B2B_SINGLE_ORDER_SUCCESS,
    B2B_SINGLE_ORDER_FAIL

  } from '../actions/b2bordersAction'
  const initialState = {
    b2borders: { all: [], error: '', isLoading: false },
    order: { all: [], error: '', isLoading: false },
    b2bsingleorder: { all: {}, error: '', isLoading: false },
    b2borderupdate: { orderupdate: {}, error: '', isLoading: false },
    b2borderFilter: { select: 'All', search: '' },
  };
  
  export default function (state = initialState, action: any) {
    switch (action.type) {
      case B2B_ALL_ORDERS_REQUEST:
        return { ...state, b2borders: { all: state.b2borders.all, error: '', isLoading: true } };
      case B2B_ALL_ORDERS_SUCCESS:
        return { ...state, b2borders: { all: action.payload, error: '', isLoading: false } };
      case B2B_ALL_ORDERS_FAIL:
        return { ...state, b2borders: { all: [], error: action.payload, isLoading: false } };

        case  B2B_SINGLE_ORDER_REQUEST:
          return { ...state, b2bsingleorder: { all: state.b2bsingleorder.all, error: '', isLoading: true } };
        case  B2B_SINGLE_ORDER_SUCCESS:
          return { ...state, b2bsingleorder: { all: action.payload, error: '', isLoading: false } };
        case  B2B_SINGLE_ORDER_FAIL:
          return { ...state, b2bsingleorder: { all: {}, error: action.payload, isLoading: false } };

         case B2B_ORDER_UPDATE_REQUEST:
        return { ...state, b2borderupdate: { orderupdate: {}, error: '', isLoading: true } };
      case B2B_ORDER_UPDATE_SUCCESS:
        return { ...state, b2borderupdate: { orderupdate: state.b2borderupdate.orderupdate, error: '', isLoading: false } };
      case B2B_ORDER_UPDATE_FAIL:
        return { ...state, b2borderupdate: { orderupdate: {}, error: action.payload, isLoading: false } };
      case RESET:
        return { ...state, b2borderupdate: { brand: {}, error: '', isLoading: false } };
      case B2B_ORDERS_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, b2borderFilter: { search: state.b2borderFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, b2borderFilter: { select: state.b2borderFilter.select, search: action.value } };
        }
        return res;
      default:
        return state;
    }
  }
  