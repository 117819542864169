import { v4 as uuid } from 'uuid';
import inventorymanagement from "../../assets/static/Dashboard/inventorymanagement.jpg"
import coupon_management from "../../assets/static/Dashboard/coupon_management.jpg"
import { View } from 'react-native';

export default [
  {
    id: uuid(),
    link: 'B2BUserNewBiddingProductQuotes',
    createdAt: 'Updated 2 hr ago',
    description: 'New Bidding Quotes it is used to display maily User requsted biddings which we have the products',
    media: inventorymanagement,
    title: 'B2B User New Biddng Requests',
    total: ''
  },
  {
    id: uuid(),
    link: 'B2BUserPartnerBiddingQuotes',
    createdAt: 'Updated 2 hr ago',
    description:
      'Partner Bidding Quotes It is used to get Our Quoted Products.',
    media: coupon_management,
    title: 'B2B Partner Bidding Quotes',
    total: ''
  },
];
