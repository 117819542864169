import {
    
    WALLET_HISTORY_REQUEST,
    WALLET_HISTORY_SUCCESS,
    WALLET_HISTORY_FAIL,

    WALLET_CREATE_REQUEST,
    WALLET_CREATE_SUCCESS,
    WALLET_CREATE_FAIL,
  
} from '../actions/walletAction'

const initialState = {

    walletCreate: { wallet: {}, error: '', isLoading: false },
    walletHistory: { wallet: [], error: '', isLoading: false },
};
export default function (state = initialState, action: any) {
    switch (action.type) {
        case WALLET_CREATE_REQUEST:
            return { ...state, walletCreate: { wallet: {}, error: '', isLoading: true } };
        case WALLET_CREATE_SUCCESS:
            return { ...state, walletCreate: { wallet: action.payload, error: '', isLoading: false } };
        case WALLET_CREATE_FAIL:
            return { ...state, walletCreate: { wallet: {}, error: action.payload, isLoading: false } };
         case WALLET_HISTORY_REQUEST:
            return { ...state, walletHistory: { wallet: state.walletHistory.wallet, error: '', isLoading: true } };
        case WALLET_HISTORY_SUCCESS:
            return { ...state, walletHistory: { wallet: action.payload, error: '', isLoading: false } };
        case WALLET_HISTORY_FAIL:
            return { ...state, walletHistory: { wallet: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}

