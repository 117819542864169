import {
  USERSERVICES_REQUEST,
  USERSERVICES_SUCCESS,
  USERSERVICES_FAIL,
  USERSERVICES_GET_REQUEST,
  USERSERVICES_GET_SUCCESS,
  USERSERVICES_GET_FAIL,
  USERSERVICES_UPDATE_REQUEST,
  USERSERVICES_UPDATE_SUCCESS,
  USERSERVICES_UPDATE_FAIL,
  USERSERVICES_FILTER,
  USERSERVICES_DATA,
  DASHBOARD_SERVICE_TOTAL_COUNT,
  DASHBOARD_PARTNER_SERVICE_COUNT,
  DASHBOARD_USER_SERVICE_TOTAL_COUNT,
  NEWUSERSERVICES_REQUEST,
  NEWUSERSERVICES_SUCCESS,
  NEWUSERSERVICES_FAIL,
  ACCEPTED_USER_SERVICES_REQUEST,
  ACCEPTED_USER_SERVICES_SUCCESS,
  ACCEPTED_USER_SERVICES_FAIL,
  IN_PROGRESS_USER_SERVICES_REQUEST,
  IN_PROGRESS_USER_SERVICES_SUCCESS,
  IN_PROGRESS_USER_SERVICES_FAIL,
  REJECTED_USER_SERVICES_REQUEST,
  REJECTED_USER_SERVICES_SUCCESS,
  REJECTED_USER_SERVICES_FAIL,
  COMPLETED_USER_SERVICES_REQUEST,
  COMPLETED_USER_SERVICES_SUCCESS,
  COMPLETED_USER_SERVICES_FAIL,
  EMPLOYEE_NEW_USER_SERVICES_REQUEST,
  EMPLOYEE_NEW_USER_SERVICES_SUCCESS,
  EMPLOYEE_NEW_USER_SERVICES_FAIL,
  EMPLOYEE_IN_PROGRESS_USER_SERVICES_REQUEST,
  EMPLOYEE_IN_PROGRESS_USER_SERVICES_SUCCESS,
  EMPLOYEE_IN_PROGRESS_USER_SERVICES_FAIL,
  EMPLOYEE_COMPLETED_USER_SERVICES_REQUEST,
  EMPLOYEE_COMPLETED_USER_SERVICES_SUCCESS,
  EMPLOYEE_COMPLETED_USER_SERVICES_FAIL,

} from '../../actions/Services/userservicesActions'

const initialState = {
  Servicetotalcount: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
  Userservicecount: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
  Partnerservicecount: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
  userservices: { all: [], error: '', isLoading: false },
  accepteduserservices: { all: [], error: '', isLoading: false },
  inprogressuserservices: { all: [], error: '', isLoading: false },
  rejecteduserservices: { all: [], error: '', isLoading: false },
  completeduserservices: { all: [], error: '', isLoading: false },
  employenewuserservices: { all: [], error: '', isLoading: false },
  employeinprogresuserservices: { all: [], error: '', isLoading: false },
  employecompleteduserservices: { all: [], error: '', isLoading: false },
  userservice: { userservice: {}, error: '', isLoading: false },
  newuserservice: { newuserservice: {}, error: '', isLoading: false },
  userserviceUpdate: { userservice: {}, error: '', isLoading: false },
  userserviceFilter: { select: 'All', search: '' },
  userserviceData: { data: {}, error: '', isLoading: false },

};

export default function (state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_SERVICE_TOTAL_COUNT:
      return { ...state, Servicetotalcount: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }
    case DASHBOARD_PARTNER_SERVICE_COUNT:
      return { ...state, Partnerservicecount: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }
      case DASHBOARD_USER_SERVICE_TOTAL_COUNT:
        return { ...state, Userservicecount: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } } 
   
        case USERSERVICES_REQUEST:
      return { ...state, userservices: { all: state.userservices.all, error: '', isLoading: true } };
    case USERSERVICES_SUCCESS:
      return { ...state, userservices: { all: action.payload, error: '', isLoading: false } };
    case USERSERVICES_FAIL:
      return { ...state, userservices: { all: [], error: action.payload, isLoading: false } };
     
      case ACCEPTED_USER_SERVICES_REQUEST:
        return { ...state, accepteduserservices: { all: state.accepteduserservices.all, error: '', isLoading: true } };
      case ACCEPTED_USER_SERVICES_SUCCESS:
        return { ...state, accepteduserservices: { all: action.payload, error: '', isLoading: false } };
      case ACCEPTED_USER_SERVICES_FAIL:  
      return { ...state, accepteduserservices: { all: [], error: action.payload, isLoading: false } };
    
      case IN_PROGRESS_USER_SERVICES_REQUEST:
        return { ...state, inprogressuserservices: { all: state.inprogressuserservices.all, error: '', isLoading: true } };
      case IN_PROGRESS_USER_SERVICES_SUCCESS:
        return { ...state, inprogressuserservices: { all: action.payload, error: '', isLoading: false } };
      case IN_PROGRESS_USER_SERVICES_FAIL:
      return { ...state, inprogressuserservices: { all: [], error: action.payload, isLoading: false } };
    
      case REJECTED_USER_SERVICES_REQUEST:
        return { ...state, rejecteduserservices: { all: state.rejecteduserservices.all, error: '', isLoading: true } };
      case REJECTED_USER_SERVICES_SUCCESS:
        return { ...state, rejecteduserservices: { all: action.payload, error: '', isLoading: false } };
      case REJECTED_USER_SERVICES_FAIL:
        return { ...state, rejecteduserservices: { all: [], error: action.payload, isLoading: false } };
       
        case COMPLETED_USER_SERVICES_REQUEST:
        return { ...state, completeduserservices: { all: state.completeduserservices.all, error: '', isLoading: true } };
      case COMPLETED_USER_SERVICES_SUCCESS:
        return { ...state, completeduserservices: { all: action.payload, error: '', isLoading: false } };
      case COMPLETED_USER_SERVICES_FAIL:
        return { ...state, completeduserservices: { all: [], error: action.payload, isLoading: false } };
     
        case EMPLOYEE_NEW_USER_SERVICES_REQUEST:
          return { ...state, employenewuserservices: { all: state.employenewuserservices.all, error: '', isLoading: true } };
        case EMPLOYEE_NEW_USER_SERVICES_SUCCESS:
          return { ...state, employenewuserservices: { all: action.payload, error: '', isLoading: false } };
        case EMPLOYEE_NEW_USER_SERVICES_FAIL:
          return { ...state, employenewuserservices: { all: [], error: action.payload, isLoading: false } };
          case EMPLOYEE_IN_PROGRESS_USER_SERVICES_REQUEST:
            return { ...state, employeinprogresuserservices: { all: state.employeinprogresuserservices.all, error: '', isLoading: true } };
          case EMPLOYEE_IN_PROGRESS_USER_SERVICES_SUCCESS:
            return { ...state, employeinprogresuserservices: { all: action.payload, error: '', isLoading: false } };
          case EMPLOYEE_IN_PROGRESS_USER_SERVICES_FAIL:
            return { ...state, employeinprogresuserservices: { all: [], error: action.payload, isLoading: false } };
            case EMPLOYEE_COMPLETED_USER_SERVICES_REQUEST:
              return { ...state, employecompleteduserservices: { all: state.employecompleteduserservices.all, error: '', isLoading: true } };
            case EMPLOYEE_COMPLETED_USER_SERVICES_SUCCESS:
              return { ...state, employecompleteduserservices: { all: action.payload, error: '', isLoading: false } };
            case EMPLOYEE_COMPLETED_USER_SERVICES_FAIL:
              return { ...state, employecompleteduserservices: { all: [], error: action.payload, isLoading: false } };
               
       case USERSERVICES_GET_REQUEST:
      return { ...state, userservice: { userservice: {}, error: '', isLoading: true } };
    case USERSERVICES_GET_SUCCESS:
      return { ...state, userservice: { userservice: action.payload, error: '', isLoading: false } };
    case USERSERVICES_GET_FAIL:
      return { ...state, userservice: { userservice: {}, error: action.payload, isLoading: false } };
    case USERSERVICES_UPDATE_REQUEST:
      return { ...state, userserviceUpdate: { userservice: {}, error: '', isLoading: true } };
    case USERSERVICES_UPDATE_SUCCESS:
      return { ...state, userserviceUpdate: { userservice: state.userserviceUpdate.userservice, error: '', isLoading: false } };
    case USERSERVICES_UPDATE_FAIL:
      return { ...state, userserviceUpdate: { userservice: {}, error: action.payload, isLoading: false } };
    case USERSERVICES_DATA:
      return { ...state, userserviceData: { data: action.value, error: '', isLoading: true } };
      case NEWUSERSERVICES_REQUEST:
        return { ...state, newuserservice: { all: state.userservices.all, error: '', isLoading: true } };
      case NEWUSERSERVICES_SUCCESS:
        return { ...state, newuserservice: { all: action.payload, error: '', isLoading: false } };
      case NEWUSERSERVICES_FAIL:
        return { ...state, newuserservice: { all: [], error: action.payload, isLoading: false } };
    case USERSERVICES_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, userserviceFilter: { search: state.userserviceFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, userserviceFilter: { select: state.userserviceFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}
