import * as React from 'react';
import 'react-native-gesture-handler';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { Card, Button, Text, Surface, ActivityIndicator } from 'react-native-paper';
import isEmpty from '../../state/validations/is-empty';
import { getAllHowPartnerassocworksAction } from '../../state/actions/howpartnerassocworksAction';
import Header from '../auth/components/header';


function HowPartnerAssocWorks(props: any) {
    //navigation property is used for navigation between screens
    const { navigation } = props;
    const esiColor = useSelector(state => state.theme);
    //API dispatch
    const dispatch = useDispatch();
    //HPAWdata contains the data from  GET API using state call
    const HPAWdata = useSelector(state => state.howpartnerassocwork.howpartnerassocworks.all);
    //for loading
    const loading = useSelector(state => state.howpartnerassocwork.howpartnerassocworks.isLoading);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    //Static formData
    React.useEffect(() => {
        let formData = {
            "Cms_Static_Id": "",
            "Name": "How Partner Association Works",
            "Records_Filter": "FILTER"
        };
        dispatch(getAllHowPartnerassocworksAction(formData))
    }, []);

    const tagsStyles = {
        p: {
          color: esiColor.itemColor,          
        }   
      };

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

    return (
        <Surface style={{ flex: 1,backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('Dashboard')}>
                        Go Back
                    </Button>
                </View>
                <Text style={{color:esiColor.brandFontColor, fontSize: 20, fontWeight: "bold", justifyContent: 'center', textAlign: 'center' }}>
                    How Partner Association Works
                </Text>
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Card style={{
                        flex: 1, borderRadius: 25, backgroundColor: esiColor.BackgroundColor, flexDirection: 'column', shadowColor: '#000', borderColor: "#CACFD2",
                        borderWidth: 0.5, width: dimensions >= 500 ? "70%" : "95%", flexShrink: 1, flexWrap: 'wrap', marginTop: "2%"
                    }}>
                        <View style={{ margin: '3%', flexWrap: 'wrap', flex: 1 }}>
                            {
                                !isEmpty(HPAWdata) ? (
                                    HPAWdata.Is_Data_Exist === "0" ?
                                        <Text style={{color:esiColor.itemColor, justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                            No Data found.
                                        </Text> : (
                                            HPAWdata.map((data, index) => (
                                                <View key={index} >
                                                    <RenderHTML tagsStyles={tagsStyles} contentWidth={320} source={{ html: data?.Description }} />
                                                </View>
                                            ))
                                        )) : <ActivityIndicator animating={loading} color='#27b6cc' style={{ marginVertical: 20, marginHorizontal: 150 }} />
                            }
                        </View>
                    </Card>
                </View>
            </ScrollView>
        </Surface>
    );
}


export default HowPartnerAssocWorks;