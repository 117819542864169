import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Card, DataTable, Button, Searchbar, Surface, IconButton, Avatar, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage';
import isEmpty from '../../../../state/validations/is-empty';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../auth/components/header';
import { getAllBiddingQuotesAction } from '../../../../state/actions/partnerbiddingQuotesActionts';
import Icon from 'react-native-vector-icons/FontAwesome';

//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Partner_Bidding_Quote_Id',
        label: 'Bidding Quote ID'
    },
    {
        value: 'Product_Name',
        label: 'Product Name'
    },
    {
        value: 'Full_Name',
        label: 'User Name'
    },
    {
        value: 'City',
        label: 'City'
    },
    {
        value: 'Bidding_Status',
        label: 'Status'
    },
    {
        value: 'Created_By',
        label: 'Created By'
    },
];
//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];
function PartnerBiddingQuotes(props: any) {
    const { navigation } = props;
    const ID = props.route?.params?.ID;
    //addresses contains the data from  GET API using state call
    const AllBiddinngProducts = useSelector(state => state.biddingquotes.bidding_quotes.all);
    let biddinngproducts = AllBiddinngProducts?.results;
    const [biddingproductsMenu, setbiddingproductsMenu] = React.useState('All')
    const [biddingproductsSearch, setbiddingproductsSearch] = React.useState('');
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const esiColor = useSelector(state => state.theme);
    const [page, setPage] = React.useState(0);
    const [webpage, setwebPage] = React.useState(1);
    const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = (Number(from) + Number(numberOfItemsPerPage) > AllBiddinngProducts?.Pagination?.TotalCount ? AllBiddinngProducts?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
    const onItemsPerPageChange = (e) => {
        numberItemsPerPageChange(e)
        setPage(0)
        setwebPage(1)
        setPagination({ PageNo: Number(page) + Number(1), PageSize: e, SortBy: "Partner_Bidding_Quote_Id", SortOrder: "DESC" })
    }
    const DynamicPageIncrease = (e) => {
        setwebPage(e < AllBiddinngProducts?.Pagination?.TotalPages ? e + Number(1) : AllBiddinngProducts?.Pagination?.TotalPages)
        setPagination({ PageNo: e == AllBiddinngProducts?.Pagination?.TotalPages ? AllBiddinngProducts?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "Partner_Bidding_Quote_Id", SortOrder: "DESC" });
    }
    const DynamicPagedecrese = (e) => {
        setwebPage(e > 1 ? e - Number(1) : 1)
        setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "Partner_Bidding_Quote_Id", SortOrder: "DESC" });
    }
    const DynamicPage = (e) => {
        setPage(e)
        setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "Partner_Bidding_Quote_Id", SortOrder: "DESC" });
    }
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "Partner_Bidding_Quote_Id", SortOrder: "DESC" });
    React.useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            biddinngproductsCall();
        }
    }, [pagination])
    // search and set search is used for the search functionality
    const [loading, setIsLoading] = React.useState(false);
    const biddinngproductsCall = () => {
        setIsLoading(true);
        let formData = {
            "search_by_filter": "",
            "search": "",
            "User_Email_Id": "",
            "User_Products_Bidding_Id": "",
            "Bidding_Product_Id": "",
            "User_Address_Id": "",
            "Records_Filter": "FILTER",
            "Bidding_Status": "",
            "Partner_Detail_Id": EmailID.Partner_Details_Id
        };

        dispatch(getAllBiddingQuotesAction(formData, pagination))
    };
    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };
    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);
    //API dispatch
    const dispatch = useDispatch()
    //Static formData
    const refreshCall = () => {
        setIsLoading(true);
        let pagecall = { PageNo: 1, PageSize: 5, SortBy: "User_Products_Bidding_Id", SortOrder: "DESC" }
        let formData = {
            "search_by_filter": "",
            "search": "",
            "User_Email_Id": "",
            "User_Products_Bidding_Id": "",
            "Bidding_Product_Id": '',
            "User_Address_Id": "",
            "Records_Filter": "FILTER",
            "Bidding_Status": "",
            "Partner_Detail_Id": EmailID.Partner_Details_Id
        };
        dispatch(getAllBiddingQuotesAction(formData, pagecall))
        setPage(0)
        numberItemsPerPageChange(5)
    };
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        let formData = {
            "search_by_filter": "",
            "search": "",
            "User_Email_Id": "",
            "User_Products_Bidding_Id": "",
            "Bidding_Product_Id": '',
            "User_Address_Id": "",
            "Records_Filter": "FILTER",
            "Bidding_Status": "",
            "Partner_Detail_Id": EmailID.Partner_Details_Id
        };
        dispatch(getAllBiddingQuotesAction(formData, pagination))
    }, [permissions]);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        refreshCall();
        wait(2000).then(() => setRefreshing(false));
    }, []);
    //delete address
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    //sorting, pagination & search
    const [order, setOrder] = React.useState('descending');
    const [orderBy, setOrderBy] = React.useState('Partner_Bidding_Quote_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
        setOrderBy(property);
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };


    const handleChange = itemValue => {
        setbiddingproductsMenu(itemValue);
    };
    const handleSearch = value => {
        let formData = {
            "search_by_filter": biddingproductsMenu,
            "search": value,
            "User_Email_Id": "",
            "User_Products_Bidding_Id": "",
            "Bidding_Product_Id": "",
            "User_Address_Id": "",
            "Records_Filter": "FILTER",
            "Bidding_Status": "",
            "Partner_Detail_Id": EmailID.Partner_Details_Id,
        };
        setbiddingproductsSearch(value)
        dispatch(getAllBiddingQuotesAction(formData, pagination))
    };

    const style = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 160
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: 'white',
            left: 22,
            top: 8,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.BackgroundColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 150,
            backgroundColor: esiColor.BackgroundColor

        },
        Chatstyle: {
            width: 240,
        },
        rowsPerPageContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 10,
            borderTopColor: esiColor.SBorderColor,
            justifyContent: 'flex-end',
        },
        rowsPerPagePicker: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.itemColor
        },
        rowsPerPagePickers: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            borderColor: 'gray',
            borderWidth: 0.5,
            paddingHorizontal: 8,
        },
        paginationContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            // backgroundColor: esiColor.SIconColor
        },
        paginationButton: {
            padding: 10,
            // backgroundColor: esiColor.SIconColor,
            marginHorizontal: 10,
        },
        pageInfoText: {
            fontSize: 16,
            padding: 10,
            fontWeight: 'bold',
            color: esiColor.itemColor
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>

                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                        navigation.navigate('PartnerBiddingMainCenter')
                        handleSearch("")
                    }
                    }>
                        Go Back
                    </Button>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: "column", margin: 12 }}>
                        <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Partner Bidding Quotes</Text>
                    </View>
                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={filterOptions}
                                containerStyle={style.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={biddingproductsMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                            <Picker
                                selectedValue={biddingproductsMenu}
                                style={{ height: 51, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, flexShrink: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Bidding Quote ID" value="Partner_Bidding_Quote_Id" />
                                <Picker.Item label="Product Name" value="Product_Name" />
                                <Picker.Item label="User Name" value="Full_Name" />
                                <Picker.Item label="City" value="City" />
                                <Picker.Item label="Created By" value="Created_By" />
                                <Picker.Item label="Status" value="Bidding_Status" />

                            </Picker>
                        </View>}

                    <View style={{ flex: dimensions <= 100 ? 0.1 : 0.6 }} />

                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{ borderRadius: 5, borderWidth: 0.3, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}
                            placeholder="Search"
                            iconColor={esiColor.SIconColor}
                            theme={esiColor.pagination_theme}
                            placeholderTextColor={esiColor.SPHTextColor}
                            onChangeText={(value) => handleSearch(value)}
                            value={biddingproductsSearch}
                        />
                    </View>
                    <View style={{ flex: dimensions <= 700 ? null : 0.53 }} />
                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    <ScrollView horizontal={dimensions >= 700 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Bidding_Quote_Id'}
                                            sortDirection={orderBy === 'Partner_Bidding_Quote_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Bidding_Quote_Id')}><Text style={style.titletext}>Bidding Quote ID</Text></DataTable.Title>

                                        <DataTable.Title style={style.title}><Text style={style.titletext}>User Image</Text></DataTable.Title>
                                        <DataTable.Title style={style.title} active={orderBy === 'Full_Name'}
                                            sortDirection={orderBy === 'Full_Name' ? order : 'ascending'}
                                            onPress={createSortHandler('Full_Name')}><Text style={style.titletext}>User Name</Text></DataTable.Title>
                                        <DataTable.Title style={style.title} active={orderBy === 'Product_Name'}
                                            sortDirection={orderBy === 'Product_Name' ? order : 'ascending'}
                                            onPress={createSortHandler('Product_Name')}><Text style={style.titletext}>Product Name</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'City'}
                                            sortDirection={orderBy === 'City' ? order : 'ascending'}
                                            onPress={createSortHandler('City')}><Text style={style.titletext}>City</Text></DataTable.Title>
                                        <DataTable.Title style={style.title} active={orderBy === 'Created_By'}
                                            sortDirection={orderBy === 'Created_By' ? order : 'ascending'}
                                            onPress={createSortHandler('Created_By')}><Text style={style.titletext}>Created By</Text></DataTable.Title>
                                        <DataTable.Title style={style.title} active={orderBy === 'Bidding_Status'}
                                            sortDirection={orderBy === 'Bidding_Status' ? order : 'ascending'}
                                            onPress={createSortHandler('Bidding_Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>

                                    </DataTable.Header>
                                    {
                                        biddinngproducts?.map((biddingproduct, index) => (
                                            <DataTable.Row style={style.databeBox} key={index}>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Partner_Bidding_Quote_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>{biddingproduct.Profile_Pic_Path ? <Avatar.Image size={45} source={{ uri: biddingproduct.Profile_Pic_Path }} /> : <Avatar.Image size={45} source={require('../../../../assets/images/noUser.png')} />}</DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Full_Name}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Product_Name}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.City}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Created_By}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingproduct.Bidding_Status}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={() => { navigation.navigate('EditPartnerBiddingQuotes', { id: biddingproduct.Partner_Bidding_Quote_Id, ID }), handleSearch("") }} />
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                        ))
                                    }
                                    {isEmpty(biddinngproducts) &&
                                        <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                                No records found!
                                            </Text>
                                            <Image source={require('../../../../assets/images/nodata.gif')}
                                                style={{ width: 300, height: 300, alignSelf: "center" }} />
                                        </View>
                                    }
                                    {Platform.OS !== "web" ?

                                        <DataTable.Pagination
                                            page={page}
                                            numberOfPages={Math.ceil(AllBiddinngProducts?.Pagination?.TotalCount / numberOfItemsPerPage)}
                                            onPageChange={page => DynamicPage(to < AllBiddinngProducts?.Pagination?.TotalCount ? page : 0)}
                                            label={AllBiddinngProducts?.Pagination?.TotalCount ? `${from + 1}-${to} of ${AllBiddinngProducts?.Pagination?.TotalCount}` : '0-0'}
                                            showFastPaginationControls={(Number(page) + Number(1)) > AllBiddinngProducts?.Pagination?.TotalPages ? false : true}
                                            numberOfItemsPerPageList={numberOfItemsPerPageList}
                                            numberOfItemsPerPage={numberOfItemsPerPage}
                                            onItemsPerPageChange={onItemsPerPageChange}
                                            selectPageDropdownLabel={'Rows per page'}
                                        />
                                        :
                                        <View style={style.rowsPerPageContainer}>
                                            <Text style={{color: esiColor.Text}}>rows per page: </Text>
                                            <Picker
                                                style={style.rowsPerPagePicker}
                                                selectedValue={numberOfItemsPerPage}
                                                onValueChange={(e) => onItemsPerPageChange(e)}
                                            >
                                                <Picker.Item label="5" value={5} />
                                                <Picker.Item label="10" value={10} />
                                                <Picker.Item label="20" value={20} />
                                            </Picker>
                                            <View style={style.paginationContainer}>
                                                <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                                                </TouchableOpacity>
                                                <Text style={style.pageInfoText}>
                                                    {AllBiddinngProducts?.Pagination?.PageNo + ' of ' + AllBiddinngProducts?.Pagination?.TotalPages}
                                                </Text>
                                                <TouchableOpacity onPress={e => DynamicPageIncrease(to < AllBiddinngProducts?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                                                    <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                                                </TouchableOpacity>
                                            </View>
                                        </View>}
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>

                </View>
            </ScrollView>
        </Surface>

    );
}



export default PartnerBiddingQuotes