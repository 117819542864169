import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';
export const CITY_ID_GET_REQUEST = "CITY_ID_GET_REQUEST";
export const CITY_ID_GET_SUCCESS = "CITY_ID_GET_SUCCESS";
export const CITY_ID_GET_FAIL = "CITY_ID_GET_FAIL";

export const LOCATION_SERVICES_GET_REQUEST = "LOCATION_SERVICES_GET_REQUEST";
export const LOCATION_SERVICES_GET_SUCCESS = "LOCATION_SERVICES_GET_SUCCESS";
export const LOCATION_SERVICES_GET_FAIL = "LOCATION_SERVICES_GET_FAIL";

export const LOCATION_ID_GET_REQUEST = "LOCATION_ID_GET_REQUEST";
export const LOCATION_ID_GET_SUCCESS = "LOCATION_ID_GET_SUCCESS";
export const LOCATION_ID_GET_FAIL = "LOCATION_ID_GET_FAIL";

export const ADDRESS_ID_GET_REQUEST = "ADDRESS_ID_GET_REQUEST";
export const ADDRESS_ID_GET_SUCCESS = "ADDRESS_ID_GET_SUCCESS";
export const ADDRESS_ID_GET_FAIL = "ADDRESS_ID_GET_FAIL";

export const SHOP_BY_CITY_LOCATION_GET_REQUEST = "SHOP_BY_CITY_LOCATION_GET_REQUEST";
export const SHOP_BY_CITY_LOCATION_GET_SUCCESS = "SHOP_BY_CITY_LOCATION_GET_SUCCESS";
export const SHOP_BY_CITY_LOCATION_GET_FAIL = "SHOP_BY_CITY_LOCATION_GET_FAIL";


export const SHOP_BY_CITY_LOCATION_CREATE_REQUEST = "SETTING_CREATE_REQUEST";
export const SHOP_BY_CITY_LOCATION_CREATE_SUCCESS = "SETTING_CREATE_SUCCESS";
export const SHOP_BY_CITY_LOCATION_CREATE_FAIL = "SETTING_CREATE_FAIL";

export const SHOP_BY_CITY_LOCATION_UPDATE_REQUEST = "SETTING_UPDATE_REQUEST";
export const SHOP_BY_CITY_LOCATION_UPDATE_SUCCESS = "SETTING_UPDATE_SUCCESS";
export const SHOP_BY_CITY_LOCATION_UPDATE_FAIL = "SETTING_UPDATE_FAIL";

export const SHOP_BY_CITY_LOCATION_FILTER = "SHOP_BY_CITY_LOCATION_FILTER";

const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
const cudheaders = config.nodeheadersCommon;
/*=============================================================
                   Get All shipping Action
===============================================================*/
export const getPartnerCityIdsAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: CITY_ID_GET_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Shop_By_City/City_List_Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: CITY_ID_GET_SUCCESS,
          payload: []
        });

      } else {
        dispatch({
          type: CITY_ID_GET_SUCCESS,
          payload: data.results
        });
      }
    }
  }
  catch (err) {
    dispatch({
      type: CITY_ID_GET_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                Get All shipping Action
===============================================================*/
export const getLocationsServicesAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: LOCATION_SERVICES_GET_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/User_Service/Generic_GET`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: LOCATION_SERVICES_GET_SUCCESS,
          payload: []
        });

      } else {
        dispatch({
          type: LOCATION_SERVICES_GET_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: LOCATION_SERVICES_GET_FAIL,
      payload: err
    });
  }
};


export const getAllLocationsAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: LOCATION_ID_GET_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Location_For_City/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: LOCATION_ID_GET_SUCCESS,
          payload: []
        });

      } else {
        dispatch({
          type: LOCATION_ID_GET_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: LOCATION_ID_GET_FAIL,
      payload: err
    });
  }
};



/*=============================================================
                   Get All shipping Action
===============================================================*/
export const getPartnerAddressesAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: ADDRESS_ID_GET_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Address/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ADDRESS_ID_GET_SUCCESS,
          payload: []
        });

      } else {
        dispatch({
          type: ADDRESS_ID_GET_SUCCESS,
          payload: data.results
        });
      }
    }
  }
  catch (err) {
    dispatch({
      type: ADDRESS_ID_GET_FAIL,
      payload: err
    });
  }
};

/*=============================================================
             Get All shipping Action
===============================================================*/

export const getPartnerShopByCityLocationAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: SHOP_BY_CITY_LOCATION_GET_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Shop_by_Location_in_City/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: SHOP_BY_CITY_LOCATION_GET_SUCCESS,
          payload: []
        });

      } else {
        dispatch({
          type: SHOP_BY_CITY_LOCATION_GET_SUCCESS,
          payload: data.results
        });
      }
    }
  }
  catch (err) {
    dispatch({
      type: SHOP_BY_CITY_LOCATION_GET_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                Get All shipping Action
===============================================================*/


export const createPartnerShopByLocationAction = (formData: any, navigation: any, toast: any, partnercityid: any, partnerid: any, partnercityname: any, partnername: any, setErrorMessage: any, setErrors: any) => async dispatch => {
  dispatch({
    type: SHOP_BY_CITY_LOCATION_CREATE_REQUEST
  });
  setErrorMessage({ Response_Message: { key: '', message: '' }, global: { key: '', message: '' } });
  axios.post(`${config.nodecudurl}/Partner_Shop_by_Location_in_City/Create`, formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: SHOP_BY_CITY_LOCATION_CREATE_SUCCESS
        });
        navigation.navigate('partnershopbylocationget', { partnercityid: partnercityid, partnerid: partnerid, partnercityname: partnercityname, partnername: partnername })
        toast.show({ message: 'Partner Service Location created Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Partner_Detail_Id": formData.Partner_Details_Id,
          "City_Id": "",
          "Shop_By_Location_In_City_Id": "",
          "Records_Filter": "FILTER"
        };
        dispatch(getPartnerShopByCityLocationAction(formValue))
      } else {
        const errors = {};
        if (successResponse.Response_Message) {
          errors.Response_Message = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Response_Message: { key: successResponse.Response_Message ? successResponse.Response_Message : '', message: successResponse.Response_Message ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Response_Message) ? successResponse.UI_Display_Message : '' } });
        toast.show({ message: 'Partner Service Location creation failed. Please try again later', type: 'info', duration: 3000, position: 'top' });
        dispatch({
          type: SHOP_BY_CITY_LOCATION_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SHOP_BY_CITY_LOCATION_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
/*=============================================================
                  Get All shipping Action
===============================================================*/
export const updatePartnerShopByLocationAction = (formData: any, navigation: any, toast: any, partnercityid: any, partnerid: any, partnercitylocationid: any, partnercityname: any, partnername: any, setErrorMessage: any, setErrors: any) => async dispatch => {
  dispatch({
    type: SHOP_BY_CITY_LOCATION_UPDATE_REQUEST
  });
  setErrorMessage({ Response_Message: { key: '', message: '' }, global: { key: '', message: '' } });
  axios.post(`${config.nodecudurl}/Partner_Shop_by_Location_in_City/Update`, formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: SHOP_BY_CITY_LOCATION_UPDATE_SUCCESS
        });
        navigation.navigate('partnershopbylocationget', { partnercityid: partnercityid, partnerid: partnerid, partnercityname: partnercityname, partnername: partnername })
        toast.show({ message: 'Setting updated successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Partner_Detail_Id":  formData.Partner_Details_Id,
          "City_Id": "",
          "Shop_By_Location_In_City_Id": "",
          "Records_Filter": "FILTER"

        };
        dispatch(getPartnerShopByCityLocationAction(formValue))
      } else {
        const errors = {};
        if (successResponse.Response_Message) {
          errors.Response_Message = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Response_Message: { key: successResponse.Response_Message ? successResponse.Response_Message : '', message: successResponse.Response_Message ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Response_Message) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: SHOP_BY_CITY_LOCATION_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SHOP_BY_CITY_LOCATION_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
/*=============================================================
                   Get All shipping Action
===============================================================*/

export const shopbycitylocationFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SHOP_BY_CITY_LOCATION_FILTER,
      fType: fType,
      value: value,
    });
  }
}

/*=============================================================
                   Get All shipping Action
===============================================================*/
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: SHOP_BY_CITY_LOCATION_FILTER,
      fType: "search",
      value: "",
    });
  }
}