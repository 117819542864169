import axios from 'axios';
import { meeapi } from './node-actions/meeapi';
import config from './config';

export const B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_REQUEST = "B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_REQUEST";
export const B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_SUCCESS = "B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_SUCCESS";
export const B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_FAIL = "B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_FAIL";

export const B2B_BIDDING_PRODUCTS_QUOTE_CREATE_REQUEST = "B2B_BIDDING_PRODUCTS_QUOTE_CREATE_REQUEST";
export const B2B_BIDDING_PRODUCTS_QUOTE_CREATE_SUCCESS = "B2B_BIDDING_PRODUCTS_QUOTE_CREATE_SUCCESS";
export const B2B_BIDDING_PRODUCTS_QUOTE_CREATE_FAIL = "B2B_BIDDING_PRODUCTS_QUOTE_CREATE_FAIL";

export const USER_B2B_BIDDING_PRODUCTS_REQUEST = "USER_B2B_BIDDING_PRODUCTS_REQUEST";
export const USER_B2B_BIDDING_PRODUCTS_FAIL = "USER_B2B_BIDDING_PRODUCTS_FAIL";

export const B2B_BIDDING_PRODUCT_FILTER = "B2B_BIDDING_PRODUCT_FILTER";

/*=============================================================
                   Add User Action
 ===============================================================*/
const cudheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
/*=============================================================
                   Get All User B2B Bidding Products
===============================================================*/
export const getAllNewUserProductsb2bBiddingQuotesAction = (formData: any, pagination = {}) => async (dispatch: any) => {
 try {
    dispatch({
      type: B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_REQUEST
    });
    meeapi.post(urlGenarator(`/B2C_User_Product_Bidding/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_SUCCESS,
          payload: data
        });

      }
    })
  } catch (err) {
    dispatch({
      type: B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_FAIL,
      payload: err
    });
  }
};
export const getUserb2bproductsBiddingQuotesAction = (formData: any, callBackData: any, pagination: any) => async dispatch => {
  try {
    dispatch({
      type: USER_B2B_BIDDING_PRODUCTS_REQUEST
    });
    meeapi.post(urlGenarator(`/B2C_User_Product_Bidding/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Success_Response.Is_Data_Exist == '1') {
        callBackData(data.results[0])
      }
      else {
        callBackData([])
      }
    })
  } catch (err) {
    dispatch({
      type: USER_B2B_BIDDING_PRODUCTS_FAIL,
      payload: err
    });
  }
};

//Add Partner B2B Bidding products Quote Action
export const AddPartnerBiddingb2bProductQuotesAction = (formData: any, navigation: any) => async dispatch => {
  dispatch({
    type: B2B_BIDDING_PRODUCTS_QUOTE_CREATE_REQUEST
  });
  axios.post(`${config.url}/B2B_Partner_Bidding_Quote/Create`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: B2B_BIDDING_PRODUCTS_QUOTE_CREATE_SUCCESS
        });
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Records_Filter": "FILTER",
          "Buyer_Partner_Detail_Id": "",
          "B2B_User_Products_Bidding_Id": "",
          "Seller_Partner_Detail_Id": "",
          "Partner_Detail_Id": formData.Partner_Detail_Id
        };
        const pagination = {
          PageNo: 1, PageSize: 5, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "DESC"
        }
        dispatch(getAllNewUserProductsb2bBiddingQuotesAction(formValue, pagination));
        navigation.navigate('B2BUserNewBiddingProductQuotes');
      } else {
        dispatch({
          type: B2B_BIDDING_PRODUCTS_QUOTE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: B2B_BIDDING_PRODUCTS_QUOTE_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};
/*=============================================================
                   User Delete
===============================================================*/

// Filter 
export const employeeFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: B2B_BIDDING_PRODUCT_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//Search Filter
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: B2B_BIDDING_PRODUCT_FILTER,
      fType: "search",
      value: "",
    });
  }
}
