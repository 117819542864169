import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import { updatePartnerpaymentAction } from '../../../state/actions/partnerpaymentAction';
import Header from '../../auth/components/header';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//StatusOption is used to get the data from dropdown 
const StatusOption = [

    {
        value: 'PAID',
        label: 'PAID'
    },
    {
        value: 'UNPAID',
        label: 'UNPAID'
    },
    {
        value: 'REFUND',
        label: 'REFUND'
    },
    {
        value: 'PENDING',
        label: 'PENDING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    }
];

const EditPartnerpayment = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    //toast is for popup message after succesfull API call
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const esiColor = useSelector(state => state.theme);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Partner_Payments === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    //isSubmitted contains the data from Update API using state call
    const isSubmited = useSelector(state => state.partnerpayment.partnerpaymentUpdate.isLoading);
    //partpayment is used to store the values from previous page using props
    const partpayment = props.route?.params?.data;

    //Status Dropdown
    const [statusDropDown, setStatusDropDown] = useState("");

    //Dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    /*====================================
            Handler
    ====================================*/

    const values = {
        Partner_Id: partpayment.Partner_Id ? partpayment.Partner_Id : "",
        Partner_Payment_Settlement_Id: partpayment.Partner_Payment_Settlement_Id ? partpayment.Partner_Payment_Settlement_Id : "",
        Partner_Type: partpayment.Partner_Type ? partpayment.Partner_Type : "",
        Tranaction_Id: partpayment.Tranaction_Id ? partpayment.Tranaction_Id : "",
        Partner_Email_Id: partpayment.Partner_Email_Id ? partpayment.Partner_Email_Id : "",
        Order_Product_Id: partpayment.Order_Product_Id ? partpayment.Order_Product_Id : "",
        Order_Line_Item_Id: partpayment.Order_Line_Item_Id ? partpayment.Order_Line_Item_Id : "",
        Order_Line_Item_Amount: partpayment.Order_Line_Item_Amount ? partpayment.Order_Line_Item_Amount : "",
        Order_Id: partpayment.Order_Id ? partpayment.Order_Id : "",
        Final_Settlement_Amount: partpayment.Final_Settlement_Amount ? partpayment.Final_Settlement_Amount : "",
        ESI_Commisssion_Amount: partpayment.ESI_Commisssion_Amount ? partpayment.ESI_Commisssion_Amount : "",
        Payment_Status: partpayment.Payment_Status ? partpayment.Payment_Status : "",
        Total_Order_Amount: partpayment.Total_Order_Amount ? partpayment.Total_Order_Amount : "",
        Modified_By: EmailID.Email_Id
    }
    //Form validation
    const Form_Validation = Yup.object().shape({
        Payment_Status: Yup.string().required('Payment Status is required.')
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(updatePartnerpaymentAction(formValues, navigation, toast));
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('PartnerpaymentGet')}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                        Edit Partner Payment
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Partner ID field with data*/}
                                            <TextInput
                                                //    theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Partner ID'
                                                mode='outlined'
                                                name="Partner_Id"
                                                value={props.values.Partner_Id}
                                                onChangeText={props.handleChange('Partner_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Partner Payment Settlement ID field with data*/}
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Partner Payment Settlement ID'
                                                mode='outlined'
                                                name="Partner_Payment_Settlement_Id"
                                                value={props.values.Partner_Payment_Settlement_Id}
                                                onChangeText={props.handleChange('Partner_Payment_Settlement_Id')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Partner Email ID field with data*/}
                                            <TextInput
                                                // theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Partner Email ID'
                                                mode='outlined'
                                                name="Partner_Email_Id"
                                                value={props.values.Partner_Email_Id}
                                                onChangeText={props.handleChange('Partner_Email_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Transaction ID field with data*/}
                                            <TextInput
                                                //    theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Transaction ID'
                                                mode='outlined'
                                                name="Tranaction_Id"
                                                value={props.values.Tranaction_Id}
                                                onChangeText={props.handleChange('Tranaction_Id')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order ID field with data*/}
                                            <TextInput
                                                //    theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Order ID'
                                                mode='outlined'
                                                name="Order_Id"
                                                value={props.values.Order_Id}
                                                onChangeText={props.handleChange('Order_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Total_Order_Amount field with data*/}
                                            <TextInput
                                                //    theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Total Order Amount'
                                                mode='outlined'
                                                name="Total_Order_Amount"
                                                value={props.values.Total_Order_Amount.toString()}
                                                onChangeText={props.handleChange('Total_Order_Amount')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order Line Item ID field with data*/}
                                            <TextInput
                                                //    theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Order Line Item ID'
                                                mode='outlined'
                                                name="Order_Line_Item_Id"
                                                value={props.values.Order_Line_Item_Id}
                                                onChangeText={props.handleChange('Order_Line_Item_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order Line Item Amount field with data*/}
                                            <TextInput
                                                //    theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Order Line Item Amount'
                                                mode='outlined'
                                                name="Order_Line_Item_Amount"
                                                value={props.values.Order_Line_Item_Amount.toString()}
                                                onChangeText={props.handleChange('Order_Line_Item_Amount')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Order_Product ID field with data*/}
                                            <TextInput
                                                //    theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Order Product ID'
                                                mode='outlined'
                                                name="Order_Product_Id"
                                                value={props.values.Order_Product_Id}
                                                onChangeText={props.handleChange('Order_Product_Id')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* ESI_Commisssion_Amount field with data*/}
                                            <TextInput
                                                //    theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='ESI Commission Amount'
                                                mode='outlined'
                                                name="ESI_Commisssion_Amount"
                                                value={props.values.ESI_Commisssion_Amount.toString()}
                                                onChangeText={props.handleChange('ESI_Commisssion_Amount')}
                                                disabled
                                            />
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Final_Settlement_Amount field with data*/}
                                            <TextInput
                                                //    theme={customTheme}
                                                style={{
                                                    marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                    borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                }}
                                                label='Final Settlement Amount'
                                                mode='outlined'
                                                name="Final_Settlement_Amount"
                                                value={props.values.Final_Settlement_Amount.toString()}
                                                onChangeText={props.handleChange('Final_Settlement_Amount')}
                                                disabled
                                            />
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 21 }}>
                                            {/* Payment_Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Payment_Status && props.errors.Payment_Status) && { color: '#b00020' }]}>
                                                Payment Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Payment_Status && props.errors.Payment_Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ? true : false}
                                                searchPlaceholder={'Select Payment Status'}
                                                dropdownPosition="auto"
                                                value={props.values.Payment_Status}
                                                name="Payment_Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Payment_Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Payment_Status && props.errors.Payment_Status)}>
                                                <ErrorMessage name="Payment_Status" />
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default EditPartnerpayment;