import React, { useState } from 'react';
// import NavBar from './NavBar';
import { useSelector } from 'react-redux';
import { View } from 'react-native';
import B2BNavBar from './NavBarB2B';

const B2BDashboardLayout = (props: any) => {
  const { navigation } = props;
  const [isMobileNavB2COpen, setMobileNavB2COpen] = useState(true);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
      <B2BNavBar
        onMobileClose={() => setMobileNavB2COpen(true)}
        openMobile={isMobileNavB2COpen} navigation={navigation}
      />
  );
};
export default B2BDashboardLayout;
