import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { Button, Card, HelperText, Text, TextInput, Surface, Avatar, Paragraph, IconButton, DefaultTheme } from 'react-native-paper';
import { Dimensions, StyleSheet, ScrollView, View, RefreshControl } from 'react-native';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from '../../../auth/components/header';
import EsiDatePicker from '../../../../components/custom/date/date';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import { getSingleB2BPartnerBiddingQuotesAction, getpartnerAddressIdAction, updateB2BuserpartnerQuote } from '../../../../state/actions/b2cpartnerquoteAction';
import B2BChatModel from '../../../../components/custom/b2cchatmodal';
import isEmpty from '../../../../state/validations/is-empty';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const StatusOption = [
    {
        value: 'INITIATED',
        label: 'INITIATED'
    },
    {
        value: 'PROCESSING',
        label: 'PROCESSING'
    },
    {
        value: 'CANCELLED',
        label: 'CANCELLED'
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED'
    }
];
const Status_Types = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In Active'
    }
];




const EditB2BUserPartnerBiddingQuotes = (props: any) => {

    const ID = props.route?.params?.id || "";
    const currentDate = new Date();
    const page = props.route?.params?.page;
    const { navigation } = props;
    const dispatch = useDispatch();

    const toast = useToast();
    const esiColor = useSelector(state => state.theme);
    const isSubmited = useSelector(state => state.biddingproducts.biddingUpdate.isLoading);
    const EmailID = useSelector(state => state.auth?.user);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "B2B_User_Products_Bidding_Id", SortOrder: "DESC" });

    const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, global: { key: '', message: '' } });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout: any) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);
    React.useEffect(() => {
        return () => {
        };
    }, []);
    // Role restriction
    const [typeDropDown, setTypeDropDown] = useState("");
    const permissions = useSelector(state => state.auth.permissions);

    React.useEffect(() => {
        if (!(permissions.Employees === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
        setSeconds(1);
    }, [permissions, ID]);

    const [data, setData] = useState([]);
    const callBackData = (data: any) => {
        setData(data[0])
    }
    const [addresspagination, setAddresPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_ADDRESS_ID", SortOrder: "DESC" });

    const [addressData, setAddressData] = useState([]);
    const callBackAddressData = (data: any) => {
        setAddressData(data)
    }
    React.useEffect(() => {
        if (data?.Partner_Addres_Id) {
            let addressForm = {
                "search": "",
                "search_by_filter": '',
                "Partner_Address_Id": data?.Partner_Addres_Id,
                "Partner_Details_Id": '',
                "Records_Filter": "FILTER"
            }
            dispatch(getpartnerAddressIdAction(addressForm, callBackAddressData, addresspagination))
        }
    }, [data]);
    const biddingExpDate = new Date(data?.Bidding_Exp_Date);

    const [statusshowDropDown, setStatusShowDropDown] = useState(false);
    const Refreshing = () => {
        let formData = {
            "search_by_filter": "",
            "search": "",
            "B2B_Partner_Bidding_Quote_Id": ID,
            "B2B_User_Products_Bidding_Id": "",
            "Records_Filter": "FILTER",
            "Buyer_Partner_Detail_Id": "",
            "Partner_Detail_Id": ""
        }
        dispatch(getSingleB2BPartnerBiddingQuotesAction(formData, callBackData, pagination))
    };
    React.useEffect(() => {
        if (seconds <= 0) {
            let formData = {
                "search_by_filter": "",
                "search": "",
                "B2B_Partner_Bidding_Quote_Id": ID,
                "B2B_User_Products_Bidding_Id": "",
                "Records_Filter": "FILTER",
                "Buyer_Partner_Detail_Id": "",
                "Partner_Detail_Id": ""
            }
            dispatch(getSingleB2BPartnerBiddingQuotesAction(formData, callBackData, pagination))
        }
    }, [seconds]);

    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    //mobile number country code
    const [reload, setReload] = React.useState(false);
    React.useEffect(() => {
        if (reload)
            setReload(false);
    }, [reload])

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };

    const styles = StyleSheet.create({
        container: {
            paddingTop: 16
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },

        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        phonetextInput: {
            flex: 1
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                {data && biddingExpDate &&

                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            "b2b_Partner_Bidding_Quote_Id": data?.B2B_Partner_Bidding_Quote_Id,
                            "B2B_User_Products_Bidding_Id": data?.User_Products_Bidding_Id,
                            "Created_By": data?.Created_By,
                            "Created_Date": data?.Created_Date,
                            "Modified_By": EmailID?.Email_Id,
                            "Modified_Date": "",
                            "Quotation_Amount": data?.Quotation_Amount + " ",
                            "Partner_Comments": data?.Partner_Comments,
                            "Partner_Details_Id": data?.Partner_Detail_Id,
                            "Partner_Bidding_Available_Product_Id": data?.Partner_Bidding_Available_Product_Id,
                            "Status": data?.Status,
                            "No_Of_Delivery_Days": data?.No_Of_Delivery_Days,
                            "Estimated_Delivery_Date": data?.Estimated_Delivery_Date,
                            "Partner_Location": data?.Zip,
                            "Delivery_Managed_By": data?.Delivery_Managed_By,
                            "Delivery_Fee": data?.Delivery_Fee + " ",
                            "Bidding_Status": data?.Bidding_Status,
                            "Advance_Token_Amount": data?.Advance_Token_Amount + " ",
                            "Esi_Commission_Amount": "",
                            "Final_Advance_Token_Amount": ""
                        }}
                        validationSchema={Yup.object().shape({
                            Quotation_Amount: Yup.number()
                                .positive()
                                .min(Yup.ref('Advance_Token_Amount'), 'Quotation Amount must be greater than or equal to Advance Token Amount.')
                                .required('Quotation Amount is required.'),
                            Advance_Token_Amount: Yup.number().required('Advance Token Amount is required.'),
                            // Estimated_Delivery_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require estimated delivery date greater than or equal to today.").required("Valid estimated delivery date is required."),
                            Bidding_Status: Yup.string().required('Bidding Status is required.'),
                            Partner_Comments: Yup.string().required('Partner comments is required.'),
                            Delivery_Fee: Yup.number().required('Delivery fee is required.'),
                            No_Of_Delivery_Days: Yup.number().required('Delivery days is required.'),

                        })}

                        enableReinitialize={true}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            let submitTest = true;
                            setSubmitting(false);
                            if (values) {
                                let formData = JSON.parse(JSON.stringify(values));
                                dispatch(updateB2BuserpartnerQuote(formData, props.navigation, toast));
                            }
                        }
                        }
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            resetForm
                        }) => (
                            <View style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                flexWrap: "wrap"

                            }}
                            >
                                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                        onPress={() => {
                                            resetForm(),
                                                setReload(true);
                                            setData([]),
                                                navigation.navigate('B2BUserPartnerBiddingQuotes')
                                        }}
                                    >
                                        Go Back
                                    </Button>
                                </View>

                                {!isEmpty(data) ? <View
                                    pointerEvents={((currentDate <= biddingExpDate) || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') && (data.Finalize_Partner_Bidding_Quote_Id == data?.B2B_Partner_Bidding_Quote_Id))) ? "auto" : 'none'}
                                >
                                    <Card style={{ backgroundColor: esiColor.CBColor, width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: (currentDate >= biddingExpDate || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') ? false : (data.Finalize_Partner_Bidding_Quote_Id != data?.B2B_Partner_Bidding_Quote_Id))) ? esiColor.SBorderColor : 'red' }}>
                                        <Card.Content>
                                            {data.Finalize_Partner_Bidding_Quote_Id == data?.B2B_Partner_Bidding_Quote_Id ?
                                                <Card.Actions style={{ alignContent: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                                                    <Paragraph style={{ color: 'green', fontSize: 20, fontWeight: 'bold' }}>Bid Accepted</Paragraph>
                                                </Card.Actions> :
                                                (currentDate >= biddingExpDate || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') ? false : (data.Finalize_Partner_Bidding_Quote_Id != data?.B2B_Partner_Bidding_Quote_Id))) ?
                                                    <Card.Actions style={{ alignContent: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                                                        <Paragraph style={{ color: 'red', fontSize: 20, fontWeight: 'bold' }}>User Accepted Other Bid</Paragraph>
                                                    </Card.Actions> : null}
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>Product Details</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                    <Avatar.Image size={70} source={{ uri: data?.Partner_Image }} />
                                                    <Text style={{ marginTop: 10, color: esiColor.GBFColor }}>  {'   '}{data?.Partner_Name}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>

                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', marginBottom: 5, flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>User Email:</Text>
                                                    <Text style={{ color: esiColor.GBFColor }}> {data?.Email_Id}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>User Mobile Number:</Text>
                                                    <Text style={{ color: esiColor.itemColor }}> {data?.Mobile}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                                                    <Avatar.Image size={70} source={{ uri: data?.Product_Image }} />
                                                    <Text style={{ marginTop: 10, color: esiColor.GBFColor }}>  {'   '}{data?.Product_Name}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Product ID:</Text>
                                                    <Text style={{ color: esiColor.itemColor }}> {data?.Bidding_Product_Id}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5, }}>
                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>QTY:</Text>
                                                    <Text style={{ color: esiColor.itemColor }}> {data?.Quantity}</Text>
                                                </View>

                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Bidding Reqest ID:</Text>
                                                    <Text style={{ color: esiColor.itemColor }}> {data?.B2B_User_Products_Bidding_Id}</Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Bid Date:</Text>
                                                    <Text style={{ color: esiColor.itemColor }}> {data?.Created_Date}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Bid Expiry Date: </Text><Text style={{ color: esiColor.itemColor }}>{data?.Bidding_Exp_Date}</Text>
                                                </View>
                                            </View>

                                            {(data.Finalize_Partner_Bidding_Quote_Id == data?.B2B_Partner_Bidding_Quote_Id) && data.Is_Advance_Amount_settled == '1' ?
                                                <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, marginTop: 30, marginBottom: 30 }}>
                                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>Transactions Details</Text>
                                                </View> : null}

                                            {(data.Finalize_Partner_Bidding_Quote_Id == data?.B2B_Partner_Bidding_Quote_Id) && data.Is_Advance_Amount_settled == '1' ? <View>
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                                                    {data.Advance_Online_Transaction_Id != '' ? <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                                                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Advance Transaction ID:</Text>
                                                        <Text style={{ fontSize: 15, color: esiColor.GBFColor }}> {data.Advance_Online_Transaction_Id}</Text>
                                                    </View> : null}
                                                    {data.Is_Advance_Amount_settled == '1' ? <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Advance Amount Settled: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Advance_Token_Amount}</Text>
                                                    </View> :
                                                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Balance Advance Amount: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Advance_Token_Amount}</Text>
                                                        </View>}

                                                </View>
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                                                    <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                                                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>ESI Commision:</Text>
                                                        <Text style={{ fontSize: 15, color: esiColor.GBFColor }}> {data.ESI_Commision}</Text>
                                                    </View>
                                                    {data.Is_Final_Amount_Settled == '1' ? <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Final Amount Settled: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Total_Amount}</Text>
                                                    </View> :
                                                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                                                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Remaining Balance Amount: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Total_Amount}</Text>
                                                        </View>}
                                                </View>
                                                {data.Is_Final_Amount_Settled == '1' ? <View style={{ flexDirection: 'row', flexGrow: 1, marginLeft: '0.5%', flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.itemColor }}>Final Amount Transaction ID: </Text><Text style={{ fontSize: 15, color: esiColor.GBFColor }}>{data.Online_Transaction_Id}</Text>
                                                </View> : null}</View> : null}
                                        </Card.Content>
                                    </Card>

                                    {addressData.length > 0 ?
                                        <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: (currentDate >= biddingExpDate || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') ? false : (data.Finalize_Partner_Bidding_Quote_Id != data?.B2B_Partner_Bidding_Quote_Id))) ? 'red' : '#d6d6d7' }}>
                                            <Card.Content>
                                                <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>Delivery Details</Text>
                                                </View>
                                                <View style={{ flexDirection: 'column', justifyContent: 'space-between', flexWrap: 'wrap', margin: 15 }}>
                                                    {addressData[0]?.Address_Type && <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>
                                                        {addressData[0]?.Address_Type} Address
                                                    </Text>}
                                                    <View>
                                                        <Text style={{ color: esiColor.itemColor }}>
                                                            {addressData[0]?.Name}
                                                        </Text>
                                                        <Text style={{ color: esiColor.itemColor }}>
                                                            {addressData[0]?.Mobile.substring(0, 15)}
                                                        </Text>
                                                    </View>
                                                    <Text style={{ color: esiColor.itemColor }}>
                                                        {addressData[0]?.Door_No},  {addressData[0]?.Street},  {addressData[0]?.Land_Mark},  {addressData[0]?.City}, {addressData[0]?.State}, {addressData[0]?.Country}, {addressData[0]?.Zip}
                                                    </Text>
                                                    <Text style={{ color: esiColor.itemColor }}>
                                                        {addressData[0]?.Location}
                                                    </Text>
                                                    {addressData[0]?.Latitude && <Text style={{ color: esiColor.itemColor }}>
                                                        Latitude: {addressData[0]?.Latitude}
                                                    </Text>}
                                                    {addressData[0]?.Longitude && <Text style={{ color: esiColor.itemColor }}>
                                                        Longitude: {addressData[0]?.Longitude}
                                                    </Text>}
                                                </View>
                                            </Card.Content>
                                        </Card> : null}
                                    <Card style={{ backgroundColor: esiColor.CBColor, width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: ((currentDate <= biddingExpDate) || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') && (data.Finalize_Partner_Bidding_Quote_Id == data?.B2B_Partner_Bidding_Quote_Id))) ? esiColor.SBorderColor : 'red' }}>
                                        <Card.Content>

                                            <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                                Edit Bidding Quotes
                                            </Text>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                                                <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                                    <TextInput
                                                        // theme={customTheme}
                                                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                        outlineColor={esiColor.TextOuterLine}
                                                        underlineColor={esiColor.TextUnderline}
                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                        error={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}
                                                        onBlur={handleBlur}
                                                        onChangeText={handleChange('Quotation_Amount')}
                                                        disabled={data.Finalize_Partner_Bidding_Quote_Id === data?.B2B_Partner_Bidding_Quote_Id ? true: false}
                                                        label="Quotation Amount"
                                                        name="Quotation_Amount"
                                                        value={values.Quotation_Amount}
                                                        mode="outlined"
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}>
                                                        {touched.Quotation_Amount && errors.Quotation_Amount}
                                                    </HelperText>
                                                </View>
                                                <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                                    <TextInput
                                                        // theme={customTheme}
                                                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                        outlineColor={esiColor.TextOuterLine}
                                                        underlineColor={esiColor.TextUnderline}
                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                        error={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}
                                                        onBlur={handleBlur}
                                                        disabled={data.Finalize_Partner_Bidding_Quote_Id === data?.B2B_Partner_Bidding_Quote_Id ? true: false}
                                                        onChangeText={handleChange('Advance_Token_Amount')}
                                                        label="Advance Token  Amount"
                                                        name="Advance_Token_Amount"
                                                        value={values.Advance_Token_Amount}
                                                        mode="outlined"
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}>
                                                        {touched.Advance_Token_Amount && errors.Advance_Token_Amount}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            {data.Advance_Payment_Status_From_User == true ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                                                <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                                    <TextInput
                                                        // theme={customTheme}
                                                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                        outlineColor={esiColor.TextOuterLine}
                                                        underlineColor={esiColor.TextUnderline}
                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                        error={Boolean(touched.No_Of_Delivery_Days && errors.No_Of_Delivery_Days)}
                                                        onBlur={handleBlur}
                                                        onChangeText={handleChange('No_Of_Delivery_Days')}
                                                        label="Delivery Days"
                                                        name="No_Of_Delivery_Days"
                                                        value={values.No_Of_Delivery_Days}
                                                        mode="outlined"
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.No_Of_Delivery_Days && errors.No_Of_Delivery_Days)}>
                                                        {touched.No_Of_Delivery_Days && errors.No_Of_Delivery_Days}
                                                    </HelperText>
                                                </View>
                                                <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                                    <TextInput
                                                        // theme={customTheme}
                                                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                        outlineColor={esiColor.TextOuterLine}
                                                        underlineColor={esiColor.TextUnderline}
                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                        error={Boolean(touched.Delivery_Fee && errors.Delivery_Fee)}
                                                        onBlur={handleBlur}
                                                        onChangeText={handleChange('Delivery_Fee')}
                                                        label="Delivery Fee"
                                                        name="Delivery_Fee"
                                                        value={values.Delivery_Fee}
                                                        mode="outlined"
                                                        Type='number'
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Delivery_Fee && errors.Delivery_Fee)}>
                                                        {touched.Delivery_Fee && errors.Delivery_Fee}
                                                    </HelperText>
                                                </View>
                                            </View> : null}
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                                                <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                                    <TextInput
                                                        // theme={customTheme}
                                                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                        outlineColor={esiColor.TextOuterLine}
                                                        underlineColor={esiColor.TextUnderline}
                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                        error={Boolean(touched.Partner_Comments && errors.Partner_Comments)}
                                                        onBlur={handleBlur}
                                                        onChangeText={handleChange('Partner_Comments')}
                                                        label="Partner Comments"
                                                        name="Partner_Comments"
                                                        value={values.Partner_Comments}
                                                        mode="outlined"
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Partner_Comments && errors.Partner_Comments)}>
                                                        {touched.Partner_Comments && errors.Partner_Comments}
                                                    </HelperText>
                                                </View>
                                                {data.Advance_Payment_Status_From_User == true ? <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>

                                                    <EsiDatePicker
                                                        theme={customTheme}
                                                        error={Boolean(touched.Estimated_Delivery_Date && errors.Estimated_Delivery_Date)}
                                                        style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                                                        name={'Estimated_Delivery_Date'}
                                                        label={'Estimated Delivery Date'}
                                                        value={values.Estimated_Delivery_Date}
                                                        valueUpdate={item => {
                                                            setFieldValue('Estimated_Delivery_Date', item)
                                                        }}

                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Estimated_Delivery_Date && errors.Estimated_Delivery_Date)}>
                                                        <ErrorMessage name="Estimated_Delivery_Date" />
                                                    </HelperText>
                                                </View> : null}
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                                    <TextInput
                                                        // theme={customTheme}
                                                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                        outlineColor={esiColor.TextOuterLine}
                                                        underlineColor={esiColor.TextUnderline}
                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                        error={Boolean(touched.Delivery_Fee && errors.Delivery_Fee)}
                                                        onBlur={handleBlur}
                                                        onChangeText={handleChange('Delivery_Fee')}
                                                        label="Delivery Fee"
                                                        name="Delivery_Fee"
                                                        value={values.Delivery_Fee}
                                                        mode="outlined"
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Delivery_Fee && errors.Delivery_Fee)}>
                                                        {touched.Delivery_Fee && errors.Delivery_Fee}
                                                    </HelperText>
                                                </View>
                                                <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                                    <TextInput
                                                        // theme={customTheme}
                                                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                        outlineColor={esiColor.TextOuterLine}
                                                        underlineColor={esiColor.TextUnderline}
                                                        activeOutlineColor={esiColor.TextOuterLine}
                                                        error={Boolean(touched.No_Of_Delivery_Days && errors.No_Of_Delivery_Days)}
                                                        onBlur={handleBlur}
                                                        onChangeText={handleChange('No_Of_Delivery_Days')}
                                                        label="No Of Delivery Days"
                                                        name="No_Of_Delivery_Days"
                                                        value={values.No_Of_Delivery_Days}
                                                        mode="outlined"
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.No_Of_Delivery_Days && errors.No_Of_Delivery_Days)}>
                                                        {touched.No_Of_Delivery_Days && errors.No_Of_Delivery_Days}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Bidding_Status && errors.Bidding_Status) && { color: '#b00020' }]}>
                                                        Bidding Status
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Bidding_Status && errors.Bidding_Status) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={StatusOption}
                                                        maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                        search={StatusOption.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Bidding Status '}
                                                        labelField="label"
                                                        valueField="value"
                                                        dropdownPosition="bottom"
                                                        value={values.Bidding_Status}
                                                        name="Bidding_Status"
                                                        onFocus={() => setTypeDropDown(true)}
                                                        onBlur={() => setTypeDropDown(false)}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Bidding_Status', item.value)
                                                            setTypeDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Bidding_Status && errors.Bidding_Status)}>
                                                        {touched.Bidding_Status && errors.Bidding_Status}
                                                    </HelperText>
                                                </View>
                                            </View>

                                        </Card.Content>
                                        <View style={{ marginHorizontal: 30 }}>
                                            <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                                        </View>
                                        <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                                            <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                                Save
                                            </Button>
                                        </View>
                                    </Card>
                                    <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 1, borderColor: (currentDate >= biddingExpDate || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') ? false : (data.Finalize_Partner_Bidding_Quote_Id != data?.B2B_Partner_Bidding_Quote_Id))) ? 'red' : '#d6d6d7', backgroundColor: esiColor.BackgroundColor }}>

                                        <Card.Content>
                                            <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                                Chat for Bidding Quote
                                            </Text>
                                            <B2BChatModel navigation={navigation} data={data} />
                                        </Card.Content>
                                    </Card>
                                </View> :
                                    <View style={{ justifyContent: 'center', alignSelf: 'center' }}>
                                        <IconButton icon="refresh" size={20} iconColor={esiColor.SIconColor} onPress={() => { Refreshing() }} />
                                    </View>
                                }
                            </View>
                        )}
                    </Formik>
                }
            </ScrollView>
        </Surface >

    );
};

export default EditB2BUserPartnerBiddingQuotes;