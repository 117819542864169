import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const SERVICELEVELAGREEMENT_REQUEST = "SERVICELEVELAGREEMENT_REQUEST";
export const SERVICELEVELAGREEMENT_SUCCESS = "SERVICELEVELAGREEMENT_SUCCESS";
export const SERVICELEVELAGREEMENT_FAIL = "SERVICELEVELAGREEMENT_FAIL";

export const SERVICELEVELAGREEMENT_GET_REQUEST = "SERVICELEVELAGREEMENT_GET_REQUEST";
export const SERVICELEVELAGREEMENT_GET_SUCCESS = "SERVICELEVELAGREEMENT_GET_SUCCESS";
export const SERVICELEVELAGREEMENT_GET_FAIL = "SERVICELEVELAGREEMENT_GET_FAIL";

const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
/*=============================================================
                  Get All CMS sla Action
===============================================================*/
export const getAllServicelevelAgreementAction = (formData: any) => async dispatch => {

    try {
        dispatch({
            type: SERVICELEVELAGREEMENT_REQUEST
        });
        let { data } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Content_Management/Get`,
            formData,
            {
                headers: nodeheaders
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SERVICELEVELAGREEMENT_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SERVICELEVELAGREEMENT_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SERVICELEVELAGREEMENT_FAIL,
            payload: err
        });
    }
};