import 'react-native-gesture-handler';
import * as React from 'react';
import { ScrollView, View, StyleSheet, TouchableOpacity, Platform ,RefreshControl} from 'react-native';
import { Avatar, Card, Surface, Text } from 'react-native-paper';
import Header from '../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSelector } from 'react-redux';


function Reports(props: any) {
    const { navigation } = props;
    const esiColor = useSelector(state => state.theme);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    const styles = StyleSheet.create({
        card: {
            borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 10, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 1, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, elevation: 5, marginLeft: 10, marginRight: 10,
            width: Platform.OS === "ios" ? 370 : 340
        },
        center: {
            justifyContent: 'center', alignItems: 'center', alignContent: 'center',color: esiColor.Text 
        },
        text: {
            fontSize: 18, fontWeight: "bold", paddingTop: 10, paddingBottom: 10,color: esiColor.brandFontColor 
        }
    });
    return (
        <Surface style={{ flex: 1,backgroundColor:esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10 ,color: esiColor.brandFontColor }}>
                        Reports
                    </Text>
                </View>
                <ScrollView  refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                            colors={['#27B6CC']}
                            tintColor={'#27B6CC'}
                            title={"Pull to refresh"}
                            titleColor={"#27B6CC"}
                        />
                    }>
                <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', padding: 5, justifyContent: 'center' }}>

                    <View style={{ flexDirection: 'column' }}>
                        <Card style={styles.card}>
                            <View style={styles.center}>
                                <TouchableOpacity onPress={() => navigation.navigate('OrderStatistics')}>
                                    <Avatar.Icon size={50} icon="chart-bar" color="white" style={{ backgroundColor: 'orange' }} />
                                </TouchableOpacity>
                                <Text style={styles.text}>Orders</Text>
                            </View>
                            <Text style={styles.center}>The Order Report is a file that lists all products sold during a set time period. Order report can calculate and display the details of all Orders.</Text>
                        </Card>
                    </View>

                    <View style={{ flexDirection: 'column' }}>
                        <Card style={styles.card}>
                            <View style={styles.center}>
                                <TouchableOpacity onPress={() => navigation.navigate('ProfitStatistics')}>
                                    <Avatar.Icon size={50} icon="currency-usd" color="white" style={{ backgroundColor: 'green' }} />
                                </TouchableOpacity>
                                <Text style={styles.text}>Profit</Text>
                            </View>
                            <Text style={styles.center}>The profit report is an important financial file used by business owners and accountants. The report shows information about the net profit.</Text>
                        </Card>
                    </View>

                    <View style={{ flexDirection: 'column' }}>
                        <Card style={styles.card}>
                            <View style={styles.center}>
                                <TouchableOpacity onPress={() => navigation.navigate('UserStatistics')}>
                                    <Avatar.Icon size={50} icon="account-group" color="white" style={{ backgroundColor: 'blue' }} />
                                </TouchableOpacity>
                                <Text style={styles.text}>Users</Text>
                            </View>
                            <Text style={styles.center}>The User Report provides information about the different users in a business unit and their access. The user is entitled to at least one access.</Text>
                        </Card>
                    </View>

                    <View style={{ flexDirection: 'column' }}>
                        <Card style={styles.card}>
                            <View style={styles.center}>
                                <TouchableOpacity onPress={() => navigation.navigate('CouponStatistics')}>
                                    <Avatar.Icon size={50} icon="credit-card" color="white" style={{ backgroundColor: 'orange' }} />
                                </TouchableOpacity>
                                <Text style={styles.text}>Coupons</Text>
                            </View>
                            <Text style={styles.center}>This Coupon report displays information about the usage of coupons by customers and reports a file that lists all coupons used during a set time period.</Text>
                        </Card>
                    </View>

                    <View style={{ flexDirection: 'column' }}>
                        <Card style={styles.card}>
                            <View style={styles.center}>
                                <TouchableOpacity onPress={() => navigation.navigate('DealStatistics')}>
                                    <Avatar.Icon size={50} icon="tag-multiple" color="white" style={{ backgroundColor: 'green' }} />
                                </TouchableOpacity>
                                <Text style={styles.text}>Deals</Text>
                            </View>
                            <Text style={styles.center}>This Deal report displays information about the usage of Deals by customers and reports a file that lists all Deals used during a set time period.</Text>
                        </Card>
                    </View>

                    <View style={{ flexDirection: 'column' }}>
                        <Card style={styles.card}>
                            <View style={styles.center}>
                                <TouchableOpacity onPress={() => navigation.navigate('ProductStatistics')}>
                                    <Avatar.Icon size={50} icon="cards-outline" color="white" style={{ backgroundColor: 'blue' }} />
                                </TouchableOpacity>
                                <Text style={styles.text}>Products</Text>
                            </View>
                            <Text style={styles.center}>This Product report displays information about the usage of Products by customers and reports a file that lists all Products sold during a set time period.</Text>
                        </Card>
                    </View>

                </View>
            </ScrollView>
        </Surface>
    );
}




export default Reports;
