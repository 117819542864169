import React, {useEffect} from "react";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";
import ProductCard from "../products/product-card";
export default function ProductsF({ productfilter, handleProductClick, navigate }) {
    const esiColor = useSelector(state => state.theme);

    useEffect(() => {
    })
    return (
        productfilter.length > 0 ?
            productfilter.map((data, index) => (
                <View key={data, index}>
                    <ProductCard data={data} navigate={navigate} handleClick={handleProductClick} ProductId={data?.Product_Id} PartnerId={data?.Partner_Details_Id} />
                </View>
            )) : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 30 }}>
                <Text style={{color: esiColor.NoDataFound}}>No Products found!</Text>
            </View>

    );
}
