import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Card, DataTable, Button, Searchbar, Surface, Dialog, Portal, IconButton, Menu, DefaultTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../state/validations/is-empty'
import 'react-native-gesture-handler'
import Header from "../auth/components/header";
import { getAllReturnOrdersAction, resetreturnFilter } from "../../state/actions/orderreturnandcancelAction";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from 'react-native-vector-icons/FontAwesome';

const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Order_Id',
        label: 'Order ID'
    },
    {
        value: 'Partner_Detail_Id',
        label: 'Partner Detail ID'
    },
    {
        value: 'User_Billing_Address_Id',
        label: 'User Billing Address ID'
    },
    {
        value: 'Order_Status',
        label: 'Order Status'
    },
];
const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const ReturnOrdersGet = ({ navigation }) => {
    const [pageMenu, setPageMenu] = React.useState('OrderReturnGet')
    const [orders, setOrders] = useState([]);
    const esiColor = useSelector(state => state.theme);
    const [page, setPage] = React.useState(0);
    const [showDropDown, setShowDropDown] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const EmailID = useSelector(state => state.auth?.user);
    const Allreturnorders = useSelector(state => state.returnorders.orderReturn.all);
    let returnorders = Allreturnorders?.results;
    const dispatch = useDispatch()
    const [returnorder, setReturnorder] = React.useState();
    const [returnOrdersMenu, setReturnorderMenu] = React.useState('All')
    const [returnOrdersSearch, setReturnorderSearch] = React.useState('');
    const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = (Number(from) + Number(numberOfItemsPerPage) > Allreturnorders?.Pagination?.TotalCount ? Allreturnorders?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
    const onItemsPerPageChange = (e) => {
        numberItemsPerPageChange(e)
        setPagination({ PageNo: Number(page) + Number(1), PageSize: e, SortBy: "ORDER_ID", SortOrder: "DESC" })
    }
    const DynamicPage = (e) => {
        setPage(e)
        setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "ORDER_ID", SortOrder: "DESC" });
    }
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "ORDER_ID", SortOrder: "DESC" });
    React.useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            returnordersCall();
        }
    }, [pagination])
    const [loading, setIsLoading] = React.useState(false);
    const returnordersCall = () => {
        setIsLoading(true);
        let formData = {
            "search_by_filter": returnOrdersMenu,
            "search": "",
            "Order_Id": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
        };
        dispatch(getAllReturnOrdersAction(formData, pagination))
    };
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const refreshCall = () => {
        setIsLoading(true);
        let pagecall = { PageNo: 1, PageSize: 5, SortBy: "ORDER_ID", SortOrder: "DESC" }
        let formData = {
            "search_by_filter": returnOrdersMenu,
            "search": "",
            "Order_Id": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
        };
        setPage(0)
        numberItemsPerPageChange(5)
        dispatch(getAllReturnOrdersAction(formData, pagecall))
    };

    React.useEffect(() => {
        let formData = {
            "search_by_filter": returnOrdersMenu,
            "search": "",
            "Order_Id": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
        };
        setReturnorder(returnorders);

        dispatch(getAllReturnOrdersAction(formData, pagination))
    }, []);
    // for page dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    // navigations
    const handleClickCancel = async () => {
        navigation.navigate('OrderCancelGet')
        setVisibles(false)
    };
    // search
    useEffect(() => {
        if (!isEmpty(returnorders)) {
            setOrders(returnorders);
        } else {
            setOrders([]);
        }
    }, [returnorders]);

    // Sorting 
    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Partner_Detail_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setPagination({ PageNo: Number(1), PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
        setOrderBy(property);
        setPage(0);
    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const [visibles, setVisibles] = React.useState(false);
    const [returnorderData, setData] = React.useState();

    const hideDialog = () => setVisible(false);
    const handleChange = itemValue => {
        setReturnorderMenu(itemValue);
    };
    const handleSearch = value => {
        setReturnorderSearch(value)
        let formData = {
            "search_by_filter": returnOrdersMenu,
            "search": value,
            "Order_Id": "",
            "Partner_Details_Id": EmailID?.Partner_Details_Id,
        };
        setPage(0)
        numberItemsPerPageChange(5)
        dispatch(getAllReturnOrdersAction(formData, pagination))
    };
    const openMenu = () => setVisibles(true);

    const closeMenu = () => setVisibles(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        refreshCall();
        wait(2000).then(() => setRefreshing(false));
    }, []);
    const handleChangeMenu = (itemValue: any) => {
        navigation.navigate(itemValue)
        // setPageMenu(itemValue);
    };

    const [webpage, setwebPage] = React.useState(1);
    const DynamicPageIncrease = (e) => {
        setwebPage(e < Allreturnorders?.Pagination?.TotalPages ? e + Number(1) : Allreturnorders?.Pagination?.TotalPages)
        setPagination({ PageNo: e == Allreturnorders?.Pagination?.TotalPages ? Allreturnorders?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "ORDER_ID", SortOrder: "DESC" });
    }
    const DynamicPagedecrese = (e) => {
        setwebPage(e > 1 ? e - Number(1) : 1)
        setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "ORDER_ID", SortOrder: "DESC" });
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };

    const styles = StyleSheet.create({
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 200
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 120
        },
        description: {
            justifyContent: "center",
            textAlign: "center",
            width: 200,
            marginLeft: 10,
            marginRight: 10,
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'left',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 180
        },
        rowsPerPageContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 10,
            justifyContent: 'flex-end',
            borderTopColor: esiColor.SBorderColor,
            borderWidth: 0.5,
        },
        rowsPerPagePicker: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.itemColor
        },
        rowsPerPagePickers: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            borderColor: 'gray',
            borderWidth: 0.5,
            paddingHorizontal: 8,
        },
        paginationContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: esiColor.BackgroundColor
        },
        paginationButton: {
            padding: 10,
            marginHorizontal: 10,
            backgroundColor: esiColor.BackgroundColor
        },
        pageInfoText: {
            fontSize: 16,
            padding: 10,
            fontWeight: 'bold',
            color: esiColor.itemColor
        },
    });

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} />
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 1 }}>
                    <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
                        <IconButton
                            color={esiColor.GBFColor}
                            icon="close"
                            size={20}
                            onPress={() => setVisible(!visible)}
                        />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                        <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>Return Order View</Text>
                    </Dialog.Title>

                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
                            <Dialog.Content style={{ marginLeft: -35 }}>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Order ID</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.GBFColor }}>{returnorderData?.Order_Id} </Text></View>
                                </View>

                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Detail ID</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.GBFColor }}>{returnorderData?.Partner_Detail_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>User Billing Address ID</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.GBFColor }}>{returnorderData?.User_Billing_Address_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Invoice Number</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.GBFColor }}>{returnorderData?.Invoice_Number} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>User Email ID</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.GBFColor }}>{returnorderData?.User_Email_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Total</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Total} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Order Date</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Order_Date} </Text></View>
                                </View>

                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Order Status</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Order_Status} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Shipping Status</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Shipping_Status} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Payment Status</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Payment_Status} </Text></View>
                                </View>

                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Currency</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Currency} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Wallet Amount</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Wallet_Amount} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Coupon Amount</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Coupon_Amount} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Gift Amount</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Gift_Amount} </Text></View>
                                </View>

                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Confirmation Status</Text></View><View style={{ flex: 1.5 }}><Text style={{ color: esiColor.itemColor }}>{returnorderData?.Confirmation_Status} </Text></View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>
            <View>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                    navigation.navigate('OrderMainCenter')
                    handleSearch("")
                    dispatch(resetreturnFilter())
                }} >
                    Go Back
                </Button>
            </View>
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ flexDirection: "column" }}>
                    <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 5, color: esiColor.brandFontColor }}>Returned Orders</Text>
                </View>
                <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
                    <View style={{ flexShrink: 0.1, paddingTop: 5 }}>
                        <View style={{
                            flex: 1,
                            paddingTop: 10,
                            flexDirection: 'row',
                            justifyContent: 'center',

                        }}>
                            {Platform.OS == "web" ? <Picker
                                selectedValue={pageMenu}
                                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                onValueChange={(itemValue, itemIndex) => handleChangeMenu(itemValue)}
                            >
                                <Picker.Item label="Returned Orders" value="OrderReturnGet" />
                                <Picker.Item label="Cancelled Orders" value="OrderCancelGet" />
                            </Picker>
                                :
                                <Menu
                                    visible={visibles}
                                    onDismiss={closeMenu}
                                    style={{ marginTop: 40 }}
                                    anchor={<Button style={{ backgroundColor: esiColor.BackgroundColor }} mode="outlined" onPress={openMenu} icon='chevron-down' color={esiColor.Text}><Text style={{ color: esiColor.Text }}>Returned Orders
                                    </Text></Button>}>
                                    <Menu.Item onPress={(e) => { handleClickCancel(); }} title="Cancelled Orders" />
                                </Menu>}
                        </View>
                    </View>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 850 ? 2.0 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[styles.dropdown]}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                data={filterOptions}
                                containerStyle={styles.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={returnOrdersMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View> :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
                            <Picker
                                selectedValue={returnOrdersMenu}
                                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Order ID" value="Order_Id" />
                                <Picker.Item label="Partner Detail ID" value="Partner_Detail_Id" />
                                <Picker.Item label="User Billing Address ID" value="User_Billing_Address_Id" />
                                <Picker.Item label="Order Status" value="Order_Status" />
                            </Picker>
                        </View>}
                    <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />
                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{
                                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.BackgroundColor
                            }}
                            iconColor={esiColor.SIconColor}
                            theme={esiColor.pagination_theme}
                            placeholderTextColor={esiColor.SPHTextColor}
                            placeholder="Search"
                            variant="outlined"
                            onChangeText={(value) => handleSearch(value)}
                            value={returnOrdersSearch}
                        />
                    </View>
                </View>
                <ScrollView horizontal={dimensions >= 700 ? false : true}>
                    <View style={styles.mainbox}>
                        <Card >
                            <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                <DataTable.Header style={styles.databeHeader}>
                                    <DataTable.Title active={orderBy === 'Order_Id'} sortDirection={orderBy === 'Order_Id' ? order : 'ascending'} onPress={createSortHandler('Order_Id')} style={styles.title}><Text style={styles.titletext}>Order ID</Text></DataTable.Title>
                                    <DataTable.Title active={orderBy === 'Partner_Detail_Id'} sortDirection={orderBy === 'Partner_Detail_Id' ? order : 'ascending'} onPress={createSortHandler('Partner_Detail_Id')} style={styles.title}><Text style={styles.titletext}>Partner Detail ID</Text></DataTable.Title>
                                    <DataTable.Title active={orderBy === 'User_Billing_Address_Id'} sortDirection={orderBy === 'User_Billing_Address_Id' ? order : 'ascending'} onPress={createSortHandler('User_Billing_Address_Id')} style={styles.title}><Text style={styles.titletext}>User Billing Address ID</Text></DataTable.Title>
                                    <DataTable.Title active={orderBy === 'Order_Status'} sortDirection={orderBy === 'Order_Status' ? order : 'ascending'} onPress={createSortHandler('Order_Status')} style={styles.title}><Text style={styles.titletext}>Order Status</Text></DataTable.Title>
                                    <DataTable.Title active={orderBy === 'Invoice_Number'} sortDirection={orderBy === 'Invoice_Number' ? order : 'ascending'} onPress={createSortHandler('Invoice_Number')} style={styles.title}><Text style={styles.titletext}>Invoice Number</Text></DataTable.Title>
                                    <DataTable.Title active={orderBy === 'User_Email_Id'} sortDirection={orderBy === 'User_Email_Id' ? order : 'ascending'} onPress={createSortHandler('User_Email_Id')} style={styles.title}><Text style={styles.titletext}>User Email ID</Text></DataTable.Title>
                                    <DataTable.Title style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                                </DataTable.Header>

                                {returnorders?.map((returnOrders, index) => (
                                    <DataTable.Row style={styles.databeBox} key={returnOrders.Order_Id}>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{returnOrders.Order_Id}</Text>
                                        </DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{returnOrders.Partner_Detail_Id}</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{returnOrders.User_Billing_Address_Id}</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{returnOrders.Order_Status}</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{returnOrders.Invoice_Number}</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{returnOrders.User_Email_Id}</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.tablerow}>
                                            <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(returnOrders); setVisible(!visible) }} /></DataTable.Cell>
                                    </DataTable.Row>
                                ))
                                }
                                {isEmpty(returnorders) &&
                                    <View>
                                        <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                            No records found!
                                        </Text>
                                        <Image source={require('../../assets/images/nodata.gif')}
                                            style={{ width: 300, height: 300, alignSelf: "center" }} />
                                    </View>
                                }
                                {Platform.OS !== "web" ?
                                    <DataTable.Pagination
                                        // theme={customTheme}
                                        page={page}
                                        numberOfPages={Math.ceil(Allreturnorders?.Pagination?.TotalCount / numberOfItemsPerPage)}
                                        onPageChange={page => DynamicPage(to < Allreturnorders?.Pagination?.TotalCount ? page : 0)}
                                        label={Allreturnorders?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Allreturnorders?.Pagination?.TotalCount}` : '0-0'}
                                        showFastPaginationControls={(Number(page) + Number(1)) > Allreturnorders?.Pagination?.TotalPages ? false : true}
                                        numberOfItemsPerPageList={numberOfItemsPerPageList}
                                        numberOfItemsPerPage={numberOfItemsPerPage}
                                        onItemsPerPageChange={onItemsPerPageChange}
                                        selectPageDropdownLabel={'Rows per page'}
                                    /> :
                                    <View style={styles.rowsPerPageContainer}>
                                        <Text style={{ color: esiColor.Text }}>rows per page: </Text>
                                        <Picker
                                            style={styles.rowsPerPagePicker}
                                            selectedValue={numberOfItemsPerPage}
                                            onValueChange={(e) => onItemsPerPageChange(e)}
                                        >
                                            <Picker.Item label="5" value={5} />
                                            <Picker.Item label="10" value={10} />
                                            <Picker.Item label="20" value={20} />
                                        </Picker>
                                        <View style={styles.paginationContainer}>
                                            <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={styles.paginationButton}>
                                                <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                                            </TouchableOpacity>
                                            <Text style={styles.pageInfoText}>
                                                {Allreturnorders?.Pagination?.PageNo + ' of ' + Allreturnorders?.Pagination?.TotalPages}
                                            </Text>
                                            <TouchableOpacity onPress={e => DynamicPageIncrease(to < Allreturnorders?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={styles.paginationButton}>
                                                <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                                            </TouchableOpacity>
                                        </View>
                                    </View>}
                            </DataTable>
                        </Card>
                    </View>
                </ScrollView>
            </ScrollView>
        </Surface>
    );
}

export default ReturnOrdersGet;