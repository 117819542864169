import {
    CONTENTRESET,
    SET_ALL_CONTENTS,
} from "../actions/contentActions";


const initialState = {
    allContents: {},
};

export default function contentReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ALL_CONTENTS:
            return {
                ...state,
                allContents: action.payload,
            };
        case CONTENTRESET:
            return {
                ...state,
                Contents: action.payload,
            };
        default:
            return state;
    }
}