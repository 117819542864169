import {
    GIFTIMAGE_REQUEST,
    GIFTIMAGE_SUCCESS,
    GIFTIMAGE_FAIL,
    GIFTIMAGE_GET_REQUEST,
    GIFTIMAGE_GET_SUCCESS,
    GIFTIMAGE_GET_FAIL,
    GIFTIMAGE_CREATE_REQUEST,
    GIFTIMAGE_CREATE_SUCCESS,
    GIFTIMAGE_CREATE_FAIL,
    GIFTIMAGE_UPDATE_REQUEST,
    GIFTIMAGE_UPDATE_SUCCESS,
    GIFTIMAGE_UPDATE_FAIL,
    GIFTIMAGE_DELETE_REQUEST,
    GIFTIMAGE_DELETE_SUCCESS,
    GIFTIMAGE_DELETE_FAIL,

    GIFT_VENDORID_REQUEST,
    GIFT_VENDORID_SUCCESS,
    GIFT_VENDORID_FAIL,

    GIFTIMAGES_FILTER,

} from '../actions/giftimageAction'
  
const initialState = {
    giftimages: { all: [], error: '', isLoading: false },
    giftimage: { giftimage: {}, error: '', isLoading: false },
    giftimageCreate: { giftimage: {}, error: '', isLoading: false },
    giftimageUpdate: { giftimage: {}, error: '', isLoading: false },
    giftimageDelete: { giftimage: {}, error: '', isLoading: false },
	
    giftVendorid: { giftvendorid: [], error: '', isLoading: false },

    giftimagesFilter: { select: 'ALL', search: '' },
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case GIFTIMAGE_REQUEST:
        return { ...state, giftimages: { all: state.giftimages.all, error: '', isLoading: true } };
      case GIFTIMAGE_SUCCESS:
        return { ...state, giftimages: { all: action.payload, error: '', isLoading: false } };
      case GIFTIMAGE_FAIL:
        return { ...state, giftimages: { all: [], error: action.payload, isLoading: false } };
      case GIFTIMAGE_GET_REQUEST:
        return { ...state, giftimage: { giftimage: {}, error: '', isLoading: true } };
      case GIFTIMAGE_GET_SUCCESS:
        return { ...state, giftimage: { giftimage: action.payload, error: '', isLoading: false } };
      case GIFTIMAGE_GET_FAIL:
        return { ...state, giftimage: { giftimage: {}, error: action.payload, isLoading: false } };
      case GIFTIMAGE_CREATE_REQUEST:
        return { ...state, giftimageCreate: { giftimage: {}, error: '', isLoading: true } };
      case GIFTIMAGE_CREATE_SUCCESS:
        return { ...state, giftimageCreate: { giftimage: action.payload, error: '', isLoading: false } };
      case GIFTIMAGE_CREATE_FAIL:
        return { ...state, giftimageCreate: { giftimage: {}, error: action.payload, isLoading: false } };
      case GIFTIMAGE_UPDATE_REQUEST:
        return { ...state, giftimageUpdate: { giftimage: {}, error: '', isLoading: true } };
      case GIFTIMAGE_UPDATE_SUCCESS:
        return { ...state, giftimageUpdate: { giftimage: action.payload, error: '', isLoading: false } };
      case GIFTIMAGE_UPDATE_FAIL:
        return { ...state, giftimageUpdate: { giftimage: {}, error: action.payload, isLoading: false } };
      case GIFTIMAGE_DELETE_REQUEST:
        return { ...state, giftimageDelete: { giftimage: {}, error: '', isLoading: true } };
      case GIFTIMAGE_DELETE_SUCCESS:
        return { ...state, giftimageDelete: { giftimage: action.payload, error: '', isLoading: false } };
      case GIFTIMAGE_DELETE_FAIL:
        return { ...state, giftimageDelete: { giftimage: {}, error: action.payload, isLoading: false } };
      case GIFTIMAGES_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, giftimagesFilter: { search: state.giftimagesFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, giftimagesFilter: { select: state.giftimagesFilter.select, search: action.value } };
        }
        return res;    

        case GIFT_VENDORID_REQUEST:
          return { ...state, giftVendorid: { giftvendorid: state.giftVendorid.giftvendorid, error: '', isLoading: true }, };
        case GIFT_VENDORID_SUCCESS:
          return { ...state, giftVendorid: { giftvendorid: action.payload, error: '', isLoading: false } };
        case GIFT_VENDORID_FAIL:
          return { ...state, giftVendorid: { giftvendorid: [], error: action.payload, isLoading: false } };

      default:
        return state;
    }
  }
  