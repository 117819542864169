import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView, Dimensions, TouchableOpacity, Text, RefreshControl } from 'react-native';
import isEmpty from '../../state/validations/is-empty';
import Header from '../auth/components/header';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { createWallet, getWalletByUser, getWalletHistory } from '../../state/actions/walletAction';
import Headers from '../Farmer-Module/header';
import Headerse from '../Services/header/header'


const Withdrawals = (props: any) => {
  const windowWidth = Dimensions.get('window').width;
  const { navigation } = props;
  const EmailID = useSelector(state => state.auth?.user);
  const page = props.route?.params?.page;
  const esiColor = useSelector(state => state.theme);
  //toast for popup after successful API calls.
  const toast = useToast();

  // isSubmitted contains the data from create API using state call
  const isSubmited = useSelector(state => state.wallet.walletCreate.isLoading);
  const dispatch = useDispatch();
  const [error, setError] = React.useState("");

  const [wallet, setWallet] = React.useState({});
  const updateStatesWallet = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data));
    setWallet(add);
  };

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );

  });


  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  //API dispatch
  useEffect(() => {
    if (!isEmpty(EmailID)) {
      let data = {
        "Wallet_Owner": EmailID?.Partner_Details_Id,
        "Records_Filter": "FILTER",
        "Wallet_Id": ""
      };
      dispatch(getWalletByUser(updateStatesWallet, data));
      let formData = {
        "Wallet_Owner_Id": EmailID?.Partner_Details_Id,
      };
      dispatch(getWalletHistory(formData))
    }
    else {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Home");
        }
      }, 1000)
    }

  }, [EmailID]);



  // initial values
  const values = {
    "Partner_Id": EmailID?.Partner_Details_Id,
    "Reason": "",
    "Upi_Id": "",
    "Wallet_Id": "",
    "Withdraw_Amount": ""
  }

  // Form Validations
  const Form_Validation = Yup.object().shape({
    Upi_Id: Yup.string().matches(/[a-zA-Z0-9_]{5,}@[a-zA-Z]{3,}/, " UPI ID is not valid.").required('UPI ID is required.'),
    Withdraw_Amount: Yup.number().max(wallet?.Remaining_Balance || 500, "Withdraw Amount should be <= " + (Number(wallet?.Remaining_Balance) || 500) + ".").required("Withdraw Amount is required."),
    Reason: Yup.string().required("Reason is required."),
  })


  const successResponse = () => {
    toast.show({ message: 'Withdraw request submitted successfully.', type: 'info', duration: 3000, position: 'top' });
    const formValue = {
      "Wallet_Owner_Id": wallet?.Wallet_Id
    };
    dispatch(getWalletHistory(formValue));
    let data = {
      "Wallet_Owner": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Wallet_Id": ""
    };
    dispatch(getWalletByUser(updateStatesWallet, data));
    navigation.navigate('WalletHistory');
  }

  const [loading, setIsLoading] = React.useState(false);
  const refreshCall = () => {
    setIsLoading(true);
    let data = {
      "Wallet_Owner": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Wallet_Id": ""
    };
    dispatch(getWalletByUser(updateStatesWallet, data))
  };
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const onSubmit = (values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
    setSubmitting(false);

    let request = JSON.parse(JSON.stringify(values));
    request.Partner_Id = EmailID?.Partner_Details_Id;
    request.Wallet_Id = wallet?.Wallet_Id;
    dispatch(createWallet(request, navigation, toast, resetForm, successResponse));
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    containerone: {
      backgroundColor: 'skyblue',
      borderRadius: 6,
      borderWidth: 0.02,
      borderColor: '#D6D6D7',
      justifyContent: 'center',
      alignItems: 'center',
      width: 200,
      height: 200
    },
    button: {
      alignItems: "center",
      padding: 5,
      backgroundColor: esiColor.globalButtonColor,
      borderRadius: 10,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    datePickerStyle: {
      width: 358,
      height: 60,
      marginTop: 6,
    },

  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>

      {/* {page === 'farmer' ? <Headers {...props} /> : <Header {...props} />} */}
      {page === 'farmer' ? <Headers {...props} /> : page == 'Services' ? <Headerse {...props} /> : <Header {...props} />}

      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', marginTop: 5 }}>
        <View style={{ marginRight: 10 }}>
          <TouchableOpacity onPress={() => navigation.navigate('MyWallet')} style={styles.button}>
            <Text style={{ fontSize: 15, color: 'white', fontWeight: "bold" }}>TRANSACTIONS</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ justifyContent: 'center', marginTop: 18 }}>
        {wallet &&
          <View key={wallet.Wallet_Id} style={{ flexDirection: 'column', justifyContent: 'center' }}>
            <Text style={{ fontSize: 22, fontWeight: "bold", textAlign: 'center', color: '#27b6cc' }}>Available Balance</Text>
            <Text style={{ fontSize: 24, marginVertical: 5, fontWeight: "bold", textAlign: 'center', color: "#27b6cc" }}>₹{wallet.Remaining_Balance}</Text>
            <Text style={{ textAlign: 'center', }}>
              {seconds > 0 ?
                (<Text style={{ color: "red", justifyContent: 'center', textAlign: 'center', alignContent: 'center' }}>You don't have sufficient balance to withdrawal.</Text>) : ("")}  </Text>
          </View>
        }
      </View>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />

      }>
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
          {(props) => (
            <View>
              <Card style={{
                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
              }}>
                <Card.Content>
                  <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    <View style={{ flexShrink: 1, width: 600 }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, maxWidth: windowWidth > 150 ? 500 : 500, minWidth: windowWidth > 200 ? 200 : '10%', marginRight: '0.5%', flexShrink: 1, paddingTop: 5 }}>
                          {/* UPI field with validation */}
                          <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                            
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            label='UPI ID'
                            mode='outlined'
                            name="Upi_Id"
                            value={props.values.Upi_Id}
                            onChangeText={props.handleChange('Upi_Id')}
                            error={Boolean(props.touched.Upi_Id && props.errors.Upi_Id)}
                            disabled={parseInt(wallet) < 500 || error ? true : false}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Upi_Id && props.errors.Upi_Id)}>
                            <ErrorMessage name="Upi_Id" />
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, maxWidth: windowWidth > 150 ? 500 : 500, minWidth: windowWidth > 200 ? 200 : '10%', marginLeft: '0.5%', flexShrink: 1, paddingTop: 5 }}>
                          {/* Reason field with validation */}
                          <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                            
                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            label='Reason'
                            mode='outlined'
                            name="Reason"
                            value={props.values.Reason}
                            onChangeText={props.handleChange('Reason')}
                            error={Boolean(props.touched.Reason && props.errors.Reason)}
                            disabled={wallet.Reason < 500 || error ? true : false}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Reason && props.errors.Reason)}>
                            <ErrorMessage name="Reason" />
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexGrow: 1, maxWidth: windowWidth > 150 ? 600 : 500, minWidth: windowWidth > 200 ? 200 : '10%', marginRight: '0.5%', flexShrink: 1, paddingTop: 5 }}>
                        {/* withdraw amount field with validation */}
                        <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                          
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          label='Withdraw Amount'
                          mode='outlined'
                          name="Withdraw_Amount"

                          value={props.values.Withdraw_Amount}
                          onChangeText={(text) => {
                            let updateText = text.replace(/\D/g, '');
                            props.setFieldValue('Withdraw_Amount', updateText);
                          }
                          }
                          keyboardType="Numeric"
                          error={Boolean(props.touched.Withdraw_Amount && props.errors.Withdraw_Amount)}
                        />
                        <HelperText type="error" visible={Boolean(props.touched.Withdraw_Amount && props.errors.Withdraw_Amount)}>
                          <ErrorMessage name="Withdraw_Amount" />
                        </HelperText>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                    <Button
                      disabled
                      style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor}} mode="contained" onPress={() => {
                        props.handleSubmit();
                      }}>
                      <Text style={{ color: 'white' }}>WITHDRAW</Text>
                    </Button>
                  </View>
                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};

export default Withdrawals;
