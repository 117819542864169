import {
  EMPLOYEE_REQUEST,
  EMPLOYEE_SUCCESS,
  EMPLOYEE_FAIL,
  EMPLOYEE_GET_REQUEST,
  
  EMPLOYEE_GET_SUCCESS,
  EMPLOYEE_GET_FAIL,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_CREATE_FAIL,
  EMPLOYEE_UPDATE_REQUEST,
  EMPLOYEE_UPDATE_SUCCESS,
  EMPLOYEE_UPDATE_FAIL,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_FAIL,
  EMPLOYEE_FILTER,
  EMPLOYEE_ADMINROLE_REQUEST,
  EMPLOYEE_ADMINROLE_SUCCESS,
  EMPLOYEE_ADMINROLE_FAIL,


} from '../actions/employeeAction'

const initialState = {
  employees: { all: [], error: '', isLoading: false },
  employee: { employee: {}, error: '', isLoading: false },
  employeeCreate: { employee: {}, error: '', isLoading: false },
  employeeUpdate: { employee: {}, error: '', isLoading: false },
  employeeDelete: { employee: {}, error: '', isLoading: false },
  employeeFilter: { select: 'All', search: '' },
  employeeRoles: { roles: [], error: '', isLoading: false },
  
};

export default function (state = initialState, action:any) {
  switch (action.type) {
    case EMPLOYEE_REQUEST:
      return { ...state, employees: { all: state.employees.all, error: '', isLoading: true } };
    case EMPLOYEE_SUCCESS:
      return { ...state, employees: { all: action.payload, error: '', isLoading: false } };
    case EMPLOYEE_FAIL:
      return { ...state, employees: { all: [], error: action.payload, isLoading: false } };
    case EMPLOYEE_GET_REQUEST:
      return { ...state, employee: { employeeCreate: {}, error: '', isLoading: true } };
    case EMPLOYEE_GET_SUCCESS:
      return { ...state, employee: { employee: action.payload, error: '', isLoading: false } };
    case EMPLOYEE_GET_FAIL:
      return { ...state, employee: { employee: {}, error: action.payload, isLoading: false } }
    case EMPLOYEE_CREATE_REQUEST:
      return { ...state, employeeCreate: { employee: {}, error: '', isLoading: true } };
    case EMPLOYEE_CREATE_SUCCESS:
      return { ...state, employeeCreate: { employee: state.employeeCreate.user, error: '', isLoading: false } };
    case EMPLOYEE_CREATE_FAIL:
      return { ...state, employeeCreate: { employee: {}, error: action.payload, isLoading: false } };
    case EMPLOYEE_UPDATE_REQUEST:
      return { ...state, employeeUpdate: { employee: {}, error: '', isLoading: true } };
    case EMPLOYEE_UPDATE_SUCCESS:
      return { ...state, employeeUpdate: { employee: state.employeeUpdate.employee, error: '', isLoading: false } };
    case EMPLOYEE_UPDATE_FAIL:
      return { ...state, employeeUpdate: { employee: {}, error: action.payload, isLoading: false } };
    case EMPLOYEE_DELETE_REQUEST:
      return { ...state, employeeDelete: { employee: {}, error: '', isLoading: true } };
    case EMPLOYEE_DELETE_SUCCESS:
      return { ...state, employeeDelete: { employee: action.payload, error: '', isLoading: false } };
    case EMPLOYEE_DELETE_FAIL:
      return { ...state, employeeDelete: { employee: {}, error: action.payload, isLoading: false } };
      
      case EMPLOYEE_ADMINROLE_REQUEST:
      return { ...state, employeeRoles: { roles: state.employeeRoles.roles, error: '', isLoading: true } };
    case EMPLOYEE_ADMINROLE_SUCCESS:
      return { ...state, employeeRoles: { roles: action.payload, error: '', isLoading: false } };
    case EMPLOYEE_ADMINROLE_FAIL:
      return { ...state, employeeRoles: { roles: [], error: action.payload, isLoading: false } }
    case EMPLOYEE_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, employeeFilter: { search: state.employeeFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, employeeFilter: { select: state.employeeFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}


