import { v4 as uuid } from 'uuid';
import products_mgt from "../../assets/static/Dashboard/products_mgt.jpg"
import product from "../../assets/static/Dashboard/product.png"
import discounted from "../../assets/static/Dashboard/discounted.png"
import partnerproducts from "../../assets/static/Dashboard/partnerproducts.png"

export default [
  {
    id: uuid(),
    link: 'ProductMasterGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Product Master defines the product specifications  , features , colors etc., to define a product.',
    media: products_mgt,
    title: 'Product Masters',
    total: ''
  },
  {
    id: uuid(),
    link: 'Products',
    createdAt: 'Updated 2 hr ago',
    description:
      'product is an object or system made available for consumer use , here we are creating and updating products with respective information about particular product.',
    media: product,
    title: 'Products',
    total: ''
  },
  {
    id: uuid(),
    link: 'PartnerownProductsGet',
    createdAt: 'Updated 2 hr ago',
    description:
      'Resources that represent purchasable goods or services. Includes resources for describing the product type and shape (SKU), and for checking the availability of the product in an inventory.',
    media: partnerproducts,
    title: 'Partner Products',
    total: ''
  },
];