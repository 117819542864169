import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Checkbox, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { View, ScrollView, Dimensions, StyleSheet, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import EsiDatePicker from '../../../../components/custom/date/date';
import { updateUserservicesAction } from '../../../../state/actions/Services/userservicesActions';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../Services/header/header';
import { CountryCode } from '../../../../components/custom/countrycode';
import PhoneInput from 'react-native-phone-number-input';
//StatusOption used to get the data from dropdown
const StatusOption = [
  {
    value: 'Pending',
    label: 'Pending'
  },
  {
    value: 'Accepted',
    label: 'Accepted'
  },
  {
    value: 'Cancelled',
    label: 'Cancelled'
  },
  {
    value: 'Rejected',
    label: 'Rejected'
  },
  {
    value: 'New',
    label: 'New'
  },
  {
    value: 'Completed',
    label: 'Completed'
  },
];

const StatususerOption = [
  {
    value: 'Pending',
    label: 'Pending'
  },
  {
    value: 'Accepted',
    label: 'Accepted'
  },
  {
    value: 'Cancelled',
    label: 'Cancelled'
  },

  {
    value: 'New',
    label: 'New'
  },
  {
    value: 'Completed',
    label: 'Completed'
  },
];

const EditUserservicepagetwo = (props: any) => {
  const toast = useToast();
  //navigation is used for navigation between screens
  const { navigation } = props;
  //isSubmitted contains the data from Update API using state call
  const isSubmited = useSelector(state => state.userservice.userserviceUpdate.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  const data = useSelector(state => state.userservice.userserviceData.data);
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  //Dropdowns
  const [statusDropDown, setStatusDropDown] = useState("");

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const phoneInput = useRef(null);
  const getCountryCode = (value) => {
    let dailCode = "91";
    if (value) {
      if (value.split('+').length > 0) {
        let pSplit = value.split('+');
        let count = pSplit.length > 1 ? 1 : 0;
        if (pSplit[count].split(' ').length > 0) {
          dailCode = value.split('+')[count].split(' ')[0];
        }
      }
    }
    return CountryCode.find((item) => item.dial_code == dailCode)?.code;
  }

  const getCurrentMobileNumber = (value) => {
    let dailNumber = value;
    if (value) {
      if (value.split(' ').length > 0) {
        dailNumber = value.split(' ')[1];
      }
    }
    return dailNumber;
  }

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  /*====================================
          Handler
  ====================================*/
  const values = {
    User_Service_Request_Id: data?.User_Service_Request_Id,
    User_Email_ID: data?.User_Email_Id,
    User_Cancel_Reason: data?.User_cancel_Reason,
    Status: data?.Status,
    Service_Start_Date: data?.Service_Start_Date,
    Service_Required_Date: data?.Service_Required_Date,
    Service_Requested_Date: data?.Service_Request_date,
    Service_Request_Total_Time_In_Hours: data?.Service_Request_Total_Time_in_Hours,
    Service_Request_Total_Time_In_Days: data?.Service_Request_Total_Time_in_Days,
    Service_Request_Total_Tax: data?.Service_Request_Total_Tax,
    Service_Request_Total_Price_Before_Tax: data?.Service_Request_Total_Price_Before_tax,
    Service_Request_Status_From_Partner: data?.Service_Request_Status_From_Partner,
    Service_Price: data?.Service_Price,
    Service_End_Date: data?.Service_End_Date,
    Seervice_Request_Total_Price_After_Tax: data?.Service_Request_Total_Price_After_Tax,
    SGST_Percentage: data?.SGST_Percentage,
    SGST: data?.SGST,
    Price_Per_Hour: data?.Price_Per_Hour,
    Price_Per_Day: data?.Price_Per_Day,
    Payment_Status: data?.Payment_Status,
    Payment_Reference_Id: data?.Payment_Reference_Id,
    Partner_Total_Share: data?.Partner_Total_share,
    Partner_Service_Id: data?.Partner_Service_Id,
    Modified_By: EmailID.Email_Id,
    Meepaisa_Commission: data?.Meepaisa_Commission,
    IGST_Percentage: data?.IGST_Percentage,
    IGST: data?.IGST,
    Discount_Amount: data?.Discount_Amount,
    Cashback_Amount: data?.Cash_Back_Amount,
    CGST_Percentage: data?.CGST_Percentage,
    CGST: data?.CGST,
    Any_Extra_Charges: data?.Any_Extra_Charges,
    After_Discount_Service_Price: data?.After_Discount_Amount_Service_Price,
    CashBack_Settled_To_Users: data.CashBack_Settled_To_Users > 0 ? true : false,
    Service_Person_Name: data?.Service_Person_Name,
    Service_Mobile_Number: data?.Service_Mobile_Number,
  }
  //Form validation
  const Form_Validation = Yup.object().shape({
    Service_Request_Status_From_Partner: Yup.string().required('Service Request Status From Partner is required.'),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.CashBack_Settled_To_Users) {
        formValues.CashBack_Settled_To_Users = 1;
      } else {
        formValues.CashBack_Settled_To_Users = 0;
      }
      dispatch(updateUserservicesAction(formValues, navigation, toast));
    }
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 15
    },
    label: {
      marginBottom: 30,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView>
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
          {(props) => (
            <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
              <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('EditUserservice', { data: data })}>
                  Go Back
                </Button>
              </View>
              <Card style={{
                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
              }}>
                <Card.Content>
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                    Edit User Services
                  </Text>
                  <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    <View style={{ flexShrink: 1, width: 650 }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Price_Per_Day && props.errors.Price_Per_Day)}
                            label='Price Per Day'
                            name='Price_Per_Day'
                            mode='outlined'
                            value={values.Price_Per_Day}
                            onChangeText={props.handleChange('Price_Per_Day')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Price_Per_Day && props.errors.Price_Per_Day)}>
                            <ErrorMessage name="Price_Per_Day" />
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Price_Per_Hour && props.errors.Price_Per_Hour)}
                            label='Price Per Hour'
                            name='Price_Per_Hour'
                            mode='outlined'
                            value={props.values.Price_Per_Hour}
                            onChangeText={props.handleChange('Price_Per_Hour')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Price_Per_Hour && props.errors.Price_Per_Hour)}>
                            <ErrorMessage name="Price_Per_Hour" />
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <EsiDatePicker
                            error={Boolean(props.touched.Service_End_Date && props.errors.Service_End_Date)}

                            style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                            name={'Service_End_Date'}
                            label={'Service End  Date'}
                            value={props.values.Service_End_Date}
                            valueUpdate={item => {
                              props.setFieldValue('Service_End_Date', item)
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_End_Date && props.errors.Service_End_Date)}>
                            <ErrorMessage name="Service_End_Date" />
                          </HelperText>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.User_Cancel_Reason && props.errors.User_Cancel_Reason)}
                            label='User Cancel Reason'
                            name='User_Cancel_Reason'
                            mode='outlined'
                            value={props.values.User_Cancel_Reason}
                            onChangeText={props.handleChange('User_Cancel_Reason')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.User_Cancel_Reason && props.errors.User_Cancel_Reason)}>
                            <ErrorMessage name="User_Cancel_Reason" />
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Seervice_Request_Total_Price_After_Tax && props.errors.Seervice_Request_Total_Price_After_Tax)}
                            label='Seervice Request Total Price After Tax'
                            name='Seervice_Request_Total_Price_After_Tax'
                            mode='outlined'
                            value={props.values.Seervice_Request_Total_Price_After_Tax}
                            onChangeText={props.handleChange('Seervice_Request_Total_Price_After_Tax')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Seervice_Request_Total_Price_After_Tax && props.errors.Seervice_Request_Total_Price_After_Tax)}>
                            <ErrorMessage name="Seervice_Request_Total_Price_After_Tax" />
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Service_Price && props.errors.Service_Price)}
                            label='Service Price'
                            name='Service_Price'
                            mode='outlined'
                            value={props.values.Service_Price}
                            onChangeText={props.handleChange('Service_Price')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_Price && props.errors.Service_Price)}>
                            <ErrorMessage name="Service_Price" />
                          </HelperText>
                        </View>

                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Service_Request_Total_Price_Before_Tax && props.errors.Service_Request_Total_Price_Before_Tax)}
                            label='Service Request Total Price Before Tax'
                            name='Service_Request_Total_Price_Before_Tax'
                            mode='outlined'
                            value={props.values.Service_Price}
                            onChangeText={props.handleChange('Service_Request_Total_Price_Before_Tax')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_Request_Total_Price_Before_Tax && props.errors.Service_Request_Total_Price_Before_Tax)}>
                            <ErrorMessage name="Service_Request_Total_Price_Before_Tax" />
                          </HelperText>
                        </View>

                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ width: 322, flexShrink: 1, paddingTop: 15 }}>
                          <View style={styles.container}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                              Service Request Status From Partner
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Service_Request_Status_From_Partner && props.errors.Service_Request_Status_From_Partner) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              data={StatusOption}
                              maxHeight={StatusOption.length > 1 ? 250 : 200}
                              search={StatusOption.length > 1 ? true : false}
                              labelField="label"
                              valueField="value"
                              dropdownPosition="auto"
                              value={props.values.Service_Request_Status_From_Partner}
                              name="Service_Request_Status_From_Partner"
                              onFocus={() => setStatusDropDown(true)}
                              onBlur={() => setStatusDropDown(false)}
                              onUpdateValue={item => {
                                props.setFieldValue('Service_Request_Status_From_Partner', item.value)
                                setStatusDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Service_Request_Status_From_Partner && props.errors.Service_Request_Status_From_Partner)}>
                              <ErrorMessage name="Service_Request_Status_From_Partner" />
                            </HelperText>
                          </View>
                        </View>
                        <View style={{ width: 322, flexShrink: 1, paddingTop: 15 }}>
                          <View style={styles.container}>
                            <Text style={[styles.dropdownlabel, Boolean(props.touched.User_Service_Request_Status && props.errors.User_Service_Request_Status) && { color: '#b00020' }]}>
                              User Service Request Status
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.User_Service_Request_Status && props.errors.User_Service_Request_Status) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              data={StatususerOption}
                              maxHeight={StatususerOption.length > 1 ? 250 : 200}
                              search={StatususerOption.length > 1 ? true : false}
                              labelField="label"
                              valueField="value"
                              dropdownPosition="auto"
                              value={props.values.User_Service_Request_Status}
                              name="User_Service_Request_Status"
                              onFocus={() => setStatusDropDown(true)}
                              onBlur={() => setStatusDropDown(false)}
                              onUpdateValue={item => {
                                props.setFieldValue('User_Service_Request_Status', item.value)
                                setStatusDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.User_Service_Request_Status && props.errors.User_Service_Request_Status)}>
                              <ErrorMessage name="User_Service_Request_Status" />
                            </HelperText>
                          </View>
                        </View>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput

                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(props.touched.Service_Person_Name && props.errors.Service_Person_Name)}
                            label='Service Person Name'
                            name='Service_Person_Name'
                            mode='outlined'
                            value={props.values.Service_Person_Name}
                            onChangeText={props.handleChange('Service_Person_Name')}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_Person_Name && props.errors.Service_Person_Name)}>
                            <ErrorMessage name="Service_Person_Name" />
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: "1%" }}>
                          <View style={styles.container}>
                            <PhoneInput
                              ref={phoneInput}
                              value={getCurrentMobileNumber(values.Service_Mobile_Number)}
                              defaultCode={getCountryCode(values.Service_Mobile_Number)}
                              onChangeText={(text) => {
                                props.setFieldValue("Service_Mobile_Number", "+" + phoneInput.current?.getCallingCode() + " " + text);
                              }}
                              onChangeCountry={(value) => {
                                let callingCode = "+" + value?.callingCode[0] + " ";
                                let dailNumber = values.Service_Mobile_Number?.split(' ')[1] ? values.Service_Mobile_Number?.split(' ')[1] : values.Service_Mobile_Number;
                                props.setFieldValue("Service_Mobile_Number", callingCode + dailNumber)
                              }}
                              layout="first"
                              withShadow
                              containerStyle={{
                                width: '100%',
                                margin: "auto",
                                backgroundColor: esiColor.BackgroundColor,
                                overflow: 'hidden',
                                borderRadius: 3,
                                borderColor: Boolean(props.touched.Service_Mobile_Number && props.errors.Service_Mobile_Number) ? "#b00020" : "#000000",
                                borderStyle: "solid",
                                borderWidth: Boolean(props.touched.Service_Mobile_Number && props.errors.Service_Mobile_Number) ? 2 : 1
                              }}
                              textContainerStyle={{ backgroundColor: esiColor.BackgroundColor }}
                              textInputStyle={Platform.OS === "web" ? {
                                height: 30, marginRight: -10, outlineStyle: 'none', overflow: 'hidden',
                              } : {
                                height: 30, marginRight: -10, overflow: 'hidden',
                              }}
                              codeTextStyle={{ marginLeft: -15 }}
                              countryPickerButtonStyle={{ width: 50, margin: "auto", marginLeft: 10, paddingBottom: 5 }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Service_Mobile_Number && props.errors.Service_Mobile_Number)}>
                              {props.touched.Service_Mobile_Number && props.errors.Service_Mobile_Number}
                            </HelperText>
                          </View>

                        </View>

                      </View>
                      <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", paddingTop: 15, paddingBottom: 15 }}>
                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                          <Checkbox.Android
                            color={esiColor.itemColor}
                            name="CashBack_Settled_To_Users"
                            status={props.values.CashBack_Settled_To_Users ? 'checked' : 'unchecked'}
                            onPress={() => {
                              props.setFieldValue("CashBack_Settled_To_Users", !props.values.CashBack_Settled_To_Users);
                            }}
                          />
                          <Text style={{ marginTop: 10, color: esiColor.itemColor }}>CashBack Settled To Users</Text>
                        </View>
                      </View>
                    </View>

                  </View>

                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", flexShrink: 1 }}>
                    <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" textColor={esiColor.itemButtenColor} onPress={props.handleSubmit}>
                      Save
                    </Button>
                  </View>

                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>
  );
};

export default EditUserservicepagetwo;