import 'react-native-gesture-handler';
import React, { useEffect } from "react";
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Button, Text, Searchbar, Portal, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import { getAllCouponAction, resetFilter } from '../../state/actions/couponAction';
import Header from '../auth/components/header';
import RenderHtml from 'react-native-render-html';
import isEmpty from '../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import Icon from 'react-native-vector-icons/FontAwesome';

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Coupon_Id',
    label: 'Coupon ID'
  },
  {
    value: 'Name',
    label: 'Coupon Name'
  },
  {
    value: 'Type',
    label: 'Type'
  },
  {
    value: 'Code',
    label: 'Code'
  },
  {
    value: 'Status',
    label: 'Status'
  }
];
//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function CouponDetails({ navigation }: { navigation: any }) {

  //coupons contains the data from  GET API using state call
  const coupons = useSelector(state => state.coupon.coupons.all);
  let Couponsall = coupons?.results;
  const esiColor = useSelector(state => state.theme);
  const [coupon, setCoupon] = React.useState();
  const EmailID = useSelector(state => state.auth?.user);
  const [page, setPage] = React.useState(0);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [couponMenu, setCouponMenu] = React.useState('All')
  const [couponSearch, setCouponSearch] = React.useState('');
  const [webpage, setwebPage] = React.useState(1);
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > coupons?.Pagination?.TotalCount ? coupons?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setwebPage(1)
    setPagination({ PageNo: Number(page) + Number(1), PageSize: e, SortBy: "COUPON_ID", SortOrder: "DESC" })
  }
  const DynamicPageIncrease = (e) => {
    setwebPage(e < coupons?.Pagination?.TotalPages ? e + Number(1) : coupons?.Pagination?.TotalPages)
    setPagination({ PageNo: e == coupons?.Pagination?.TotalPages ? coupons?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "COUPON_ID", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "COUPON_ID", SortOrder: "DESC" });
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "COUPON_ID", SortOrder: "DESC" });
  }
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "COUPON_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      couponsCall();
    }
  }, [pagination])
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const couponsCall = () => {
    setIsLoading(true);
    let formData = {
      "search_by_filter": couponMenu,
      "search": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    };
    dispatch(getAllCouponAction(formData, pagination))
  };
  const [showDropDown, setShowDropDown] = React.useState(false);
  //API dispatch
  const dispatch = useDispatch()
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "COUPON_ID", SortOrder: "DESC" }
    let formData = {
      "search_by_filter": couponMenu,
      "search": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    };
    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getAllCouponAction(formData, pagecall))
  };
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  React.useEffect(() => {
    let formData = {
      "search_by_filter": couponMenu,
      "search": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    };
    setCoupon(Couponsall);

    dispatch(getAllCouponAction(formData, pagination))
  }, []);

  //dimensions
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  useEffect(() => {
    if (!isEmpty(Couponsall)) {
      setCoupon(Couponsall);
    } else {
      setCoupon([]);
    }
  }, [Couponsall]);
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Coupon_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: Number(1), PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  //dialog
  const [visible, setVisible] = React.useState(false);
  const [couponData, setData] = React.useState();

  const hideDialog = () => setVisible(false);
  const handleChange = itemValue => {
    setCouponMenu(itemValue);
  };
  const handleSearch = value => {
    setCouponSearch(value)
    let formData = {
      "search_by_filter": couponMenu,
      "search": value,
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    };
    dispatch(getAllCouponAction(formData, pagination))
  };
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const style = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 80
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 160,
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'center',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 150
    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
    },
    rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
    },
    rowsPerPagePickers: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      borderColor: 'gray',
      borderWidth: 0.5,
      paddingHorizontal: 8,
    },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      // backgroundColor: esiColor.SIconColor
    },
    paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      // backgroundColor: esiColor.SIconColor
    },
    pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 500, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
            <IconButton icon="close" color={esiColor.GBFColor} size={20} onPress={() => setVisible(!visible)} />
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>View Coupon</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView
              contentContainerStyle={{ paddingHorizontal: 5 }}>
              <Dialog.Content style={{ marginLeft: -10 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Coupon ID</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.GBFColor }}>{couponData?.Coupon_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Code</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Code} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Brand ID</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Brand_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Category ID</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Category_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Start Date</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Valid_From_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>End Date</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Valid_Through_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discount Type</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Discount_Type} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discount Amount</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Discount_Amount} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discount Label</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Discount_Label} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>ESI Cashback Type</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.ESI_Cashback_Type} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>ESI Cashback Amount</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.ESI_Cashback_Amount} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.7 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Cashback Label</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Cashback_Label} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Mobile Redirect URL</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Mobile_Redirect_Url} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Web Redirect URL</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Web_Redirect_Url} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Description</Text></View>
                  <View style={{ flex: 0.9, marginTop: -2 }}><RenderHtml source={{ html: `<span style="color: ${esiColor.Text}">${couponData?.Description}</span>` }} /></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Verified Deal</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Verified} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Whats Hot</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Whats_Hot} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Exclusive</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Exclusive} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Extra Cashback</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Extra_Cashback} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Is Top Coupon</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Is_Top_Coupon} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Created_By} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Modified By</Text></View>
                  <View style={{ flex: 0.9 }}><Text style={{ color: esiColor.itemColor }}>{couponData?.Modified_By} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>

      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
          <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
            navigation.navigate('PartnerMainCenter')
            handleSearch("")
            dispatch(resetFilter())
          }
          }>
            Go Back
          </Button>
        </View>
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 5, color: esiColor.brandFontColor }}>Coupon Management</Text>
        </View>
        {(permissions.Coupons_Management === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => { navigation.navigate('AddCouponBasic'), handleSearch("") }}>
              ADD COUPON
            </Button>
          </View>
        }

        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 800 ? 1.5 : 1.0, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[style.dropdown]}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                inputSearchStyle={style.inputSearchStyle}
                iconStyle={style.iconStyle}
                data={filterOptions}
                containerStyle={style.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={couponMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={couponMenu}
                style={{ height: 50, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Coupon ID" value="Coupon_Id" />
                <Picker.Item label="Coupon Name" value="Name" />
                <Picker.Item label="Type" value="Type" />
                <Picker.Item label="Code" value="Code" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>
          }
          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />
          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              onChangeText={(value) => handleSearch(value)}
              value={couponSearch}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>

          <ScrollView horizontal={dimensions >= 700 ? false : true}>
            <View style={style.mainbox}>
              <Card>
                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                  <DataTable.Header style={style.databeHeader}>
                    <DataTable.Title style={style.title} active={orderBy === 'Coupon_Id'}
                      sortDirection={orderBy === 'Coupon_Id' ? order : 'ascending'}
                      onPress={createSortHandler('Coupon_Id')}><Text style={style.titletext}>Coupon ID</Text></DataTable.Title>

                    <DataTable.Title  style={style.title} active={orderBy === 'Name'}
                      sortDirection={orderBy === '' ? order : 'ascending'}
                      onPress={createSortHandler('Name')}><Text style={style.titletext}>Coupon Name</Text></DataTable.Title>
                    <DataTable.Title  style={style.title} active={orderBy === 'Type'}
                      sortDirection={orderBy === 'Type' ? order : 'ascending'}
                      onPress={createSortHandler('Type')}><Text style={style.titletext}>Type</Text></DataTable.Title>

                    <DataTable.Title  style={style.title} active={orderBy === 'Code'}
                      sortDirection={orderBy === 'Code' ? order : 'ascending'}
                      onPress={createSortHandler('Code')}><Text style={style.titletext}>Code</Text></DataTable.Title>

                    <DataTable.Title style={style.title} active={orderBy === 'Status'}
                      sortDirection={orderBy === 'Status' ? order : 'ascending'}
                      onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                    <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                  </DataTable.Header>
                  {
                    Couponsall?.map((coupon, index) => (
                      <DataTable.Row style={style.databeBox} key={coupon.Coupon_Id}>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{coupon.Coupon_Id}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{coupon.Name}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{coupon.Type}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{coupon.Code}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{coupon.Status}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}>
                          {(permissions.Coupons_Management === "write") && <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={() => navigation.navigate('EditCouponBasic', { ID: coupon.Coupon_Id })} />}
                          <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(coupon); setVisible(!visible) }} /></DataTable.Cell>
                      </DataTable.Row>


                    ))
                  }
                  {isEmpty(Couponsall) &&
                    <View>
                      <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                        No records found!
                      </Text>
                      <Image source={require('../../assets/images/nodata.gif')}
                        style={{ width: 300, height: 300, alignSelf: "center" }} />
                    </View>
                  }
                  {Platform.OS !== "web" ?

                    <DataTable.Pagination
                      // theme={customTheme}
                      page={page}
                      numberOfPages={Math.ceil(coupons?.Pagination?.TotalCount / numberOfItemsPerPage)}
                      onPageChange={page => DynamicPage(to < coupons?.Pagination?.TotalCount ? page : 0)}
                      label={coupons?.Pagination?.TotalCount ? `${from + 1}-${to} of ${coupons?.Pagination?.TotalCount}` : '0-0'}
                      showFastPaginationControls={(Number(page) + Number(1)) > coupons?.Pagination?.TotalPages ? false : true}
                      numberOfItemsPerPageList={numberOfItemsPerPageList}
                      numberOfItemsPerPage={numberOfItemsPerPage}
                      onItemsPerPageChange={onItemsPerPageChange}
                      selectPageDropdownLabel={'Rows per page'}
                    />
                    :
                    <View style={style.rowsPerPageContainer}>
                      <Text style={{color: esiColor.Text}}>rows per page: </Text>
                      <Picker
                        style={style.rowsPerPagePicker}
                        selectedValue={numberOfItemsPerPage}
                        onValueChange={(e) => onItemsPerPageChange(e)}
                      >
                        <Picker.Item label="5" value={5} />
                        <Picker.Item label="10" value={10} />
                        <Picker.Item label="20" value={20} />
                      </Picker>
                      <View style={style.paginationContainer}>
                        <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                          <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                        </TouchableOpacity>
                        <Text style={style.pageInfoText}>
                          {coupons?.Pagination?.PageNo + ' of ' + coupons?.Pagination?.TotalPages}
                        </Text>
                        <TouchableOpacity onPress={e => DynamicPageIncrease(to < coupons?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                          <Icon name="angle-right" size={20} color={esiColor.SIconColor}/>
                        </TouchableOpacity>
                      </View>
                    </View>}
                </DataTable>
              </Card>
            </View>
          </ScrollView>

        </View>
      </ScrollView>
    </Surface>

  );
}

export default CouponDetails