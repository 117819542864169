
import {
    SHOPPERPARTNERS_REQUEST,
    SHOPPERPARTNERS_SUCCESS,
    SHOPPERPARTNERS_FAIL,
  
    SHOPPER_PARTNERS_REQUEST,
    SHOPPER_PARTNERS_SUCCESS,
    SHOPPER_PARTNERS_FAIL,

    SHOPPERPRODUCT_REQUEST,
    SHOPPERPRODUCT_SUCCESS,
    SHOPPERPRODUCT_FAIL,

    SHOPPERPARTNER_CREATE_REQUEST,
    SHOPPERPARTNER_CREATE_SUCCESS,
    SHOPPERPARTNER_CREATE_FAIL,
  
    SHOPPERPARTNER_UPDATE_REQUEST,
    SHOPPERPARTNER_UPDATE_SUCCESS,
    SHOPPERPARTNER_UPDATE_FAIL,
  
    SHOPPERPARTNERS_FILTER,
    SHOPPERPARTNERS_DATA,
  
    SHOPPERPHYSICALPARTNER_ADDRESS_REQUEST,
    SHOPPERPHYSICALPARTNER_ADDRESS_SUCCESS,
    SHOPPERPHYSICALPARTNER_ADDRESS_FAIL
  } from '../../actions/shoppers/shopersAction'
  
  const initialState = {
    shopperpartnerCreate: { shopperpartner: {}, error: '', isLoading: false },
    shopperpartnerUpdate: { shopperpartner: {}, error: '', isLoading: false },
    shopperpartners: { all: [], error: '', isLoading: false },
    singleproduct: { all: [], error: '', isLoading: false },
    shopperproducts: { all: [], error: '', isLoading: false },
    shopperpartnerFilter: { select: 'All', search: '' },
    shopperpartnerdata: { shopperpartner: {}, error: '', isLoading: false },
    shopperpartnerAddress: { shopperaddress: [], error: '', isLoading: false },
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case SHOPPERPARTNERS_REQUEST:
        return { ...state, shopperpartners: { all: state.shopperpartners.all, error: '', isLoading: true } };
      case SHOPPERPARTNERS_SUCCESS:
        return { ...state, shopperpartners: { all: action.payload, error: '', isLoading: false } };
      case SHOPPERPARTNERS_FAIL:
        return { ...state, shopperpartners: { all: [], error: action.payload, isLoading: false } };
      case SHOPPER_PARTNERS_SUCCESS:
        return { ...state, singleproduct: { all: state.singleproduct.all, error: '', isLoading: true } };
      case SHOPPER_PARTNERS_SUCCESS:
        return { ...state, singleproduct: { all: action.payload, error: '', isLoading: false } };
      case SHOPPER_PARTNERS_FAIL:
        return { ...state, singleproduct: { all: [], error: action.payload, isLoading: false } };
    
        case SHOPPERPRODUCT_REQUEST:
          return { ...state, shopperproducts: { all: state.shopperproducts.all, error: '', isLoading: true } };
        case SHOPPERPRODUCT_SUCCESS:
          return { ...state, shopperproducts: { all: action.payload, error: '', isLoading: false } };
        case SHOPPERPRODUCT_FAIL:
          return { ...state, shopperproducts: { all: [], error: action.payload, isLoading: false } };
      case SHOPPERPARTNERS_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, shopperpartnerFilter: { search: state.shopperpartnerFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, shopperpartnerFilter: { select: state.shopperpartnerFilter.select, search: action.value } };
        }
        return res;
  
      case SHOPPERPARTNERS_DATA:
        return { ...state, productpartnerdata: { shopperpartner: action.value, error: '', isLoading: true } };
  
      case SHOPPERPARTNER_CREATE_REQUEST:
        return { ...state, shopperpartnerCreate: { shopperpartner: {}, error: '', isLoading: true } };
      case SHOPPERPARTNER_CREATE_SUCCESS:
        return { ...state, shopperpartnerCreate: { shopperpartner: state.shopperpartnerCreate.shopperpartner, error: '', isLoading: false } };
      case SHOPPERPARTNER_CREATE_FAIL:
        return { ...state, shopperpartnerCreate: { shopperpartner: {}, error: action.payload, isLoading: false } };
  
      case SHOPPERPARTNER_UPDATE_REQUEST:
        return { ...state, shopperpartnerUpdate: { shopperpartner: {}, error: '', isLoading: true } };
      case SHOPPERPARTNER_UPDATE_SUCCESS:
        return { ...state, shopperpartnerUpdate: { shopperpartner: state.shopperpartnerUpdate.shopperpartner, error: '', isLoading: false } };
      case SHOPPERPARTNER_UPDATE_FAIL:
        return { ...state, shopperpartnerUpdate: { shopperpartner: {}, error: action.payload, isLoading: false } };
  
      case SHOPPERPHYSICALPARTNER_ADDRESS_REQUEST:
        return { ...state, shopperpartnerAddress: { shopperpartnerAddress: state.shopperpartnerAddress.shopperaddress, error: '', isLoading: true }, };
      case SHOPPERPHYSICALPARTNER_ADDRESS_SUCCESS:
        return { ...state, shopperpartnerAddress: { shopperpartnerAddress: action.payload, error: '', isLoading: false } };
      case SHOPPERPHYSICALPARTNER_ADDRESS_FAIL:
        return { ...state, shopperpartnerAddress: { shopperpartnerAddress: state.shopperpartnerAddress.shopperaddress, error: action.payload, isLoading: false } };
  
      default:
        return state;
    }
  }
