import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const EMPLOYEE_REQUEST = "EMPLOYEE_REQUEST";
export const EMPLOYEE_SUCCESS = "EMPLOYEE_SUCCESS";
export const EMPLOYEE_FAIL = "EMPLOYEE_FAIL";
export const EMPLOYEE_GET_REQUEST = "EMPLOYEE_GET_REQUEST";
export const EMPLOYEE_GET_SUCCESS = "EMPLOYEE_GET_SUCCESS";
export const EMPLOYEE_GET_FAIL = "EMPLOYEE_GET_FAIL";
export const EMPLOYEE_CREATE_REQUEST = "EMPLOYEE_CREATE_REQUEST";
export const EMPLOYEE_CREATE_SUCCESS = "EMPLOYEE_CREATE_SUCCESS";
export const EMPLOYEE_CREATE_FAIL = "EMPLOYEE_CREATE_FAIL";
export const EMPLOYEE_UPDATE_REQUEST = "EMPLOYEE_UPDATE_REQUEST";
export const EMPLOYEE_UPDATE_SUCCESS = "EMPLOYEE_UPDATE_SUCCESS";
export const EMPLOYEE_UPDATE_FAIL = "EMPLOYEE_UPDATE_FAIL";
export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_FAIL = "EMPLOYEE_DELETE_FAIL";
export const EMPLOYEE_FILTER = "EMPLOYEE_FILTER ";

export const EMPLOYEE_ADMINROLE_REQUEST = "EMPLOYEE_ADMINROLE_REQUEST";
export const EMPLOYEE_ADMINROLE_SUCCESS = "EMPLOYEE_ADMINROLE_SUCCESS";
export const EMPLOYEE_ADMINROLE_FAIL = "EMPLOYEE_ADMINROLE_FAI";

/*=============================================================
                   Add User Action
 ===============================================================*/
const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
export const AddEmployeeAction = (formData: any, navigation: any, setErrorMessage: any, setErrors: any, toast: any, page: any) => async dispatch => {
  dispatch({
    type: EMPLOYEE_CREATE_REQUEST
  });
  setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  axios.post(`${config.url}/Partner_Login/Employee_create`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: EMPLOYEE_CREATE_SUCCESS
        });
        toast.show({
          message: 'Employee Added successfully.', type: 'info', duration: 3000, position: 'top'
        });
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Email_Id": "",
          "Partner_Details_Id": formData?.Partner_Details_Id,
          "Partner_Employee_Id": "",
          "Records_Filter": "FILTER"
        };
        dispatch(getAllEmployeeAction(formValue, toast));
        navigation.navigate('Employees', { page: page });
      } else {
        const errors = {};
        if (successResponse.Email_Id) {
          errors.Email_Id = successResponse.UI_Display_Message;
        }
        if (successResponse.Mobile_Number) {
          errors.Mobile = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Email_Id: { key: successResponse.Email_Id ? successResponse.Email_Id : '', message: successResponse.Email_Id ? successResponse.UI_Display_Message : '' }, Mobile: { key: successResponse.Mobile_Number ? successResponse.Mobile_Number : '', message: successResponse.Mobile_Number ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Mobile_Number || successResponse.Email_Id) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: EMPLOYEE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: EMPLOYEE_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};


/*=============================================================
                 Update User Action
===============================================================*/

export const EditEmployeeAction = (formData: any, navigation: any, setErrorMessage: any, setErrors: any, setData: any, toast: any, page: any) => async dispatch => {
  dispatch({
    type: EMPLOYEE_UPDATE_REQUEST
  });
  axios.post(`${config.url}/Partner_Login/Employee_Update`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: EMPLOYEE_UPDATE_SUCCESS
        });
        setData([])
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Email_Id": "",
          "Partner_Details_Id": formData?.Partner_Details_Id,
          "Partner_Employee_Id": "",
          "Records_Filter": "FILTER"
        };
        dispatch(getAllEmployeeAction(formValue, toast));
        navigation.navigate('Employees', { page: page });
        toast.show({
          message: 'Employee updated successfully.', type: 'info', duration: 3000, position: 'top'
        });
      } else {
        dispatch({
          type: EMPLOYEE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: EMPLOYEE_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working."
      });
    })
};
/*=============================================================
                   Get All User Action
===============================================================*/

export const getAllEmployeeAction = (formData: any, toast: any) => async dispatch => {
  try {
    dispatch({
      type: EMPLOYEE_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Login/Employee_Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: EMPLOYEE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: EMPLOYEE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAIL,
      payload: err
    });
  }
};


export const getEmployeeByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    dispatch({
      type: EMPLOYEE_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Login/Employee_Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results[0])
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                   User Delete
===============================================================*/

export const deleteEmployeeAction = (formData: any, toast: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(`${config.url}/Partner_Login/Employee_Update`, formData, {

      headers: headers
    });
    if (data) {
      const formValue = {
        "search_by_filter": "",
          "search": "",
          "Email_Id": "",
          "Partner_Details_Id": formData?.Partner_Details_Id,
          "Partner_Employee_Id": "",
          "Records_Filter": "FILTER"
      };
      dispatch(getAllEmployeeAction(formValue, toast));
      toast.show({
        message: 'Employee deleted successfully.', type: 'info', duration: 3000, position: 'top'
      });
    }
  } catch (err) {
  }
};
// Filter 
export const employeeFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: EMPLOYEE_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//Search Filter
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: EMPLOYEE_FILTER,
      fType: "search",
      value: "",
    });
  }
}
/*=============================================================
                   Get All User Action
===============================================================*/

export const getAllRolesAction = () => async dispatch => {
  try {
    let formData = {
      "Role_Name": "",
      "Records_Filter": "PARTNER_%"
    };
    dispatch({
      type: EMPLOYEE_ADMINROLE_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Parnter_Roles/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: EMPLOYEE_ADMINROLE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: EMPLOYEE_ADMINROLE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_ADMINROLE_FAIL,
      payload: err
    });
  }
};