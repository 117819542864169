import axios from 'axios';
import { meeapi } from './node-actions/meeapi';
import config from './config';

export const BIDDING_QUOTES_REQUEST = "BIDDING_QUOTES_REQUEST";
export const BIDDING_QUOTES_SUCCESS = "BIDDING_QUOTES_SUCCESS";
export const BIDDING_QUOTES_FAIL = "BIDDING_QUOTES_FAIL";
export const SINGLE_BIDDING_QUOTES_REQUEST = "SINGLE_BIDDING_QUOTES_REQUEST";
export const SINGLE_BIDDING_QUOTES_SUCCESS = "SINGLE_BIDDING_QUOTES_SUCCESS";
export const SINGLE_BIDDING_QUOTES_FAIL = "SINGLE_BIDDING_QUOTES_FAIL";

export const PARTNER_BIDDING_QUOTE_UPDATE_REQUEST = "PARTNER_BIDDING_QUOTE_UPDATE_REQUEST";
export const PARTNER_BIDDING_QUOTE_UPDATE_SUCCESS = "PARTNER_BIDDING_QUOTE_UPDATE_SUCCESS";
export const PARTNER_BIDDING_QUOTE_UPDATE_FAIL = "PARTNER_BIDDING_QUOTE_UPDATE_FAIL";

export const BIDDING_SINGLE_PRODUCTS_REQUEST = "BIDDING_SINGLE__PRODUCTS_REQUEST";
export const BIDDING_SINGLE__PRODUCTS_FAIL = "BIDDING_SINGLE__PRODUCTS_FAIL";

export const GENERIC_PRODUCTS_REQUEST = "GENERIC_PRODUCTS_REQUEST";
export const GENERIC_PRODUCTS_FAIL = "GENERIC_PRODUCTS_FAIL";


export const PARTNER_BIDDING_ADDRESS_REQUEST = "PARTNER_BIDDING_ADDRESS_REQUEST";
export const PARTNER_BIDDING_ADDRESS_FAIL = "PARTNER_BIDDING_ADDRESS_FAIL";

export const BIDDING_PRODUCT_FILTER = "BIDDING_PRODUCT_FILTER";
export const BIDDING_QUOTE_CREATE_REQUEST = "BIDDING_QUOTE_CREATE_REQUEST";
export const BIDDING_QUOTE_CREATE_SUCCESS = "BIDDING_QUOTE_CREATE_SUCCESS";
export const BIDDING_QUOTE_CREATE_FAIL = "BIDDING_QUOTE_CREATE_FAIL";

export const BIDDING_PRODUCT_UPDATE_REQUEST = "BIDDING_PRODUCT_UPDATE_REQUEST";
export const BIDDING_PRODUCT_UPDATE_SUCCESS = "BIDDING_PRODUCT_UPDATE_SUCCESS";
export const BIDDING_PRODUCT_UPDATE_FAIL = "BIDDING_PRODUCT_UPDATE_FAIL";

/*=============================================================
                   Add User Action
 ===============================================================*/
const headers = config.headersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
/*=============================================================
                   Get All User Action
===============================================================*/
export const getSingleBiddingQuotesAction = (formData: any, callBackData: any, pagination: any) => async dispatch => {
  try {
    dispatch({
      type: SINGLE_BIDDING_QUOTES_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Bidding_Quotations/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data) {
        callBackData(data.results[0])
      }
    })
  } catch (err) {
    dispatch({
      type: SINGLE_BIDDING_QUOTES_FAIL,
      payload: err
    });
  }
};
export const getAddressIdAction = (formData: any, callBackData: any,pagination:any) => async dispatch => {
   try {
  meeapi.post(urlGenarator(`/User/Address_Get`,pagination),
      formData,
      ).then(response => {
    let data = response.data;
    if (data.Is_Data_Exist === '0') {
      callBackData([])
    }else {
       callBackData(data.results)
    }
  })
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};



export const getAllBiddingQuotesAction = (formData: any, pagination = {}) => async (dispatch: any) => {
try {
    dispatch({
      type: BIDDING_QUOTES_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Bidding_Quotations/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
    if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BIDDING_QUOTES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: BIDDING_QUOTES_SUCCESS,
          payload: data
        });

      }
    })
  } catch (err) {
    dispatch({
      type: BIDDING_QUOTES_FAIL,
      payload: err
    });
  }
};
// export const AddPartnerBiddingQuotesAction = (formData: any, navigation: any) => async dispatch => {
//   dispatch({
//     type: BIDDING_QUOTE_CREATE_REQUEST
//   });
//   axios.post(`${config.url}/Partner_Bidding_Quotations/Create`, formData, {
//     headers: headers
//   })
//     .then(response => {
//       let successResponse = response.data;
//       if (successResponse.Response_Status == "Success") {
//         dispatch({
//           type: BIDDING_QUOTE_CREATE_SUCCESS
//         });
//         const formValue = {
//           "search_by_filter": "string",
//           "search": "string",
//           "Partner_Bidding_Quote_Id": "string",
//           "User_Products_Bidding_Id": "string",
//           "Partner_Detail_Id": "string",
//           "Records_Filter": "string"
//         };
//         const pagination = {
//           PageNo: 1, PageSize: 5, SortBy: "Partner_Bidding_Quote_Id", SortOrder: "DESC"
//         }
//         dispatch(getAllBiddingQuotesAction(formValue, pagination));
//         navigation.navigate('PartnerBiddingQuotes');
//       } else {
//         dispatch({
//           type: BIDDING_QUOTE_CREATE_FAIL,
//           payload: successResponse.UI_Display_Message
//         });
//       }
//     })
//     .catch(error => {
//       dispatch({
//         type: BIDDING_QUOTE_CREATE_FAIL,
//         payload: "Please try sometime later.Currently server is not working"
//       });
//     })
// };

export const updateBiddingQuote = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: PARTNER_BIDDING_QUOTE_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Partner_Bidding_Quotations/Update`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNER_BIDDING_QUOTE_UPDATE_SUCCESS
        });
        navigation.navigate('PartnerBiddingQuotes', { productpartnerparams: formData.Product_Id });
        toast.show({ message: 'Quote Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "Partner_Bidding_Quote_Id",
          SortOrder: "DESC"
        };
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "User_Email_Id": "",
          "User_Products_Bidding_Id": "",
          "Bidding_Product_Id": '',
          "User_Address_Id": "",
          "Records_Filter": "FILTER",
          "Bidding_Status": "",
          "Partner_Detail_Id": formData.Partner_Details_Id,
        };
        dispatch(getAllBiddingQuotesAction(formValue, pagination))
      } else {
        dispatch({
          type: PARTNER_BIDDING_QUOTE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_BIDDING_QUOTE_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};

export const getBiddingProductIdAction = (formData: any, callBackData: any, pagination: any) => async dispatch => {
  try {
    dispatch({
      type: BIDDING_SINGLE_PRODUCTS_REQUEST
    });
    meeapi.post(urlGenarator(`/partner_bidding/available_products_get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data) {
        callBackData(data.results)
      }
    })
  } catch (err) {
    dispatch({
      type: BIDDING_SINGLE__PRODUCTS_FAIL,
      payload: err
    });
  }
};



export const getAllAddressforBiddingAction = (formData: any, callBackData: any, pagination: any) => async dispatch => {
  try {
    dispatch({
      type: PARTNER_BIDDING_ADDRESS_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Address/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data) {
        callBackData(data.results)
      }
    })
  } catch (err) {
    dispatch({
      type: PARTNER_BIDDING_ADDRESS_FAIL,
      payload: err
    });
  }
};
export const getGenericProductsAction = (formData: any, callBackData: any, pagination = {}
) => async dispatch => {
  try {
    dispatch({
      type: GENERIC_PRODUCTS_REQUEST
    });
    meeapi.post(urlGenarator(`/Generic_Product/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;

      if (data) {
        callBackData(data.results)
      }
    })
  } catch (err) {
    dispatch({
      type: GENERIC_PRODUCTS_FAIL,
      payload: err
    });
  }
};


/*=============================================================
                   User Delete
===============================================================*/

// Filter 
export const employeeFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: BIDDING_PRODUCT_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//Search Filter
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: BIDDING_PRODUCT_FILTER,
      fType: "search",
      value: "",
    });
  }
}
/*=============================================================
                   Get All User Action
===============================================================*/