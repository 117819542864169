import {
    REVIEWSANDRATINGS_DATA,
    REVIEWSANDRATINGS_REQUEST,
    REVIEWSANDRATINGS_SUCCESS,
    REVIEWSANDRATINGS_FAIL,
    REVIEWSANDRATINGS_GET_REQUEST,
    REVIEWSANDRATINGS_GET_SUCCESS,
    REVIEWSANDRATINGS_GET_FAIL,
    REVIEWSANDRATINGS_FILTER,
    AVERAGE_REVIEWSANDRATINGS_REQUEST,
    AVERAGE_REVIEWSANDRATINGS_SUCCESS,
    AVERAGE_REVIEWSANDRATINGS_FAIL,
} from '../actions/reviewsandratingsAction';

const initialState = {
    reviewsandratings: { all: [], error: '', isLoading: false },
    reviewandrating: { reviewandrating: {}, error: '', isLoading: false },
    reviewandratingFilter: { select: 'All', search: '' },
    ReviewsandRatingsData: { data: {}, error: '', isLoading: false },
    Averagereviewandrating: { Average: {}, error: '', isLoading: false },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REVIEWSANDRATINGS_REQUEST:
            return { ...state, reviewsandratings: { all: state.reviewsandratings.all, error: '', isLoading: true } };
        case REVIEWSANDRATINGS_SUCCESS:
            return { ...state, reviewsandratings: { all: action.payload, error: '', isLoading: false } };
        case REVIEWSANDRATINGS_FAIL:
            return { ...state, reviewsandratings: { all: [], error: action.payload, isLoading: false } };

            case AVERAGE_REVIEWSANDRATINGS_REQUEST:
                return { ...state, Averagereviewandrating: {Average:state.Averagereviewandrating.Average, error: '', isLoading: true } };
            case AVERAGE_REVIEWSANDRATINGS_SUCCESS:
                return { ...state, Averagereviewandrating: { Average: action.payload, error: '', isLoading: false } };
            case AVERAGE_REVIEWSANDRATINGS_FAIL:
                return { ...state, Averagereviewandrating: { Average: [], error: action.payload, isLoading: false } };

        case REVIEWSANDRATINGS_GET_REQUEST:
            return { ...state, reviewandrating: { reviewandrating: {}, error: '', isLoading: true } };
        case REVIEWSANDRATINGS_GET_SUCCESS:
            return { ...state, reviewandrating: { reviewandrating: action.payload, error: '', isLoading: false } };
        case REVIEWSANDRATINGS_GET_FAIL:
            return { ...state, reviewandrating: { reviewandrating: {}, error: action.payload, isLoading: false } };

        case REVIEWSANDRATINGS_DATA:

            return { ...state, ReviewsandRatingsData: { data: action.value, error: '', isLoading: true } };

        case REVIEWSANDRATINGS_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, reviewandratingFilter: { search: state.reviewandratingFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, reviewandratingFilter: { select: state.reviewandratingFilter.select, search: action.value } };
            }
            return res;

        default:
            return state;
    }
}