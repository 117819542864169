import 'react-native-gesture-handler';
import * as React from 'react';
import { TouchableOpacity, View, FlatList } from "react-native";
import { Text } from "react-native-paper";
import { useSelector } from 'react-redux';


function Pagination(props: any) {
  //props variable consists of all <List.Accordion> properties along with map data.

  const { setPagination, pagination } = props;
  const esiColor = useSelector(state => state.theme);



  const pageIncrementbtn = () => {
    return (
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 35, borderWidth: 1, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 3, borderRadius: 5, marginRight: 5, height: 35, justifyContent: 'center',
          alignItems: 'center',
        }} onPress={() => {
          setPagination({ ...pagination, PageNo: pagination.PageNo == 1 ? 4 : pagination.PageNo + 2 });
        }}>
          <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>....</Text>
        </TouchableOpacity>
      </View>
    )

  }
  const pageDecrementbtn = () => {
    return (
      <View style={{ alignItems: 'center', margin: "0.5%" }}>
        <TouchableOpacity style={{
          width: 35, borderWidth: 1, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 3, borderRadius: 5, marginRight: 5, height: 35, justifyContent: 'center',
          alignItems: 'center',
        }} onPress={() => {
          setPagination({ ...pagination, PageNo: pagination.PageNo == pagination.TotalPages ? pagination.TotalPages - 3 : pagination.PageNo - 2 });
        }}>
          <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>....</Text>
        </TouchableOpacity>
      </View>)
  }

  const buildNumberArray = (value: Number) => {
    const pages = [];
    for (var i = 1; i <= value; i++) {
      if (i == pagination.PageNo - 1 || i == pagination.PageNo + 1 || i == pagination.PageNo || (pagination.PageNo == 1 && i == 3) || (pagination.PageNo == pagination.TotalPages && i == pagination.PageNo - 2))
        pages.push(i);
    }
    return pages
  }


  const renderPageNumber = () => {

    return (
      <View style={{ justifyContent: 'space-between', alignItems: 'center', }}>
        <FlatList
          data={buildNumberArray(pagination.TotalPages)}
          contentContainerStyle={{ flexDirection: 'row' }}
          renderItem={(item) => {
            const number = item.item
            return (
              <View key={item.index}>
                <TouchableOpacity disabled={pagination.PageNo === number ? true : false} key={item.key} style={{
                  backgroundColor: pagination.PageNo === number ? esiColor.globalButtonColor : null,
                  width: 35, borderWidth: 1, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor, borderRadius: 5, marginRight: 5, height: 35, justifyContent: 'center',
                  alignItems: 'center',
                }} onPress={() => {
                  setPagination({ ...pagination, PageNo: number });
                }}>
                  <Text style={{ fontWeight: 'bold', color: esiColor.itemColor }}>{number}</Text>
                </TouchableOpacity>
              </View>
            );
          }} />
      </View>
    )
  }

  return (
    (pagination.TotalPages && pagination.TotalPages > 1) ?
      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <TouchableOpacity disabled={pagination.PageNo == 1 ? true : false} onPress={() => {
          setPagination({ ...pagination, PageNo: pagination.PageNo - 1 });
        }}
          style={{
            width: 35, height: 35, marginRight: 5, justifyContent: 'center', alignItems: 'center',
            flexDirection: 'column', pointerEvents: pagination.PageNo == 1 ? "none" : null,
            borderRadius: 5, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 3, borderWidth: 1, opacity: pagination.PageNo == 1 ? "0.6" : null
          }}>
          <Text style={{ color: pagination.PageNo == 1 === true ? esiColor.itemColor : esiColor.itemColor }}>Prev</Text>
        </TouchableOpacity>
        {(pagination.PageNo > 2 && pagination.TotalPages > 3) && pageDecrementbtn()}
        {renderPageNumber()}
        {pagination.PageNo < pagination.TotalPages - 1 && pagination.TotalPages > 3 && pageIncrementbtn()}
        <TouchableOpacity disabled={pagination.PageNo === pagination.TotalPages ? true : false} onPress={() => {
          setPagination({ ...pagination, PageNo: pagination.PageNo + 1 });
        }}
          style={{
            width: 35, height: 35, justifyContent: 'center', alignItems: 'center',
            flexDirection: 'column', borderRadius: 5, borderColor: esiColor.SBorderColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 3, borderWidth: 1,
            pointerEvents: pagination.PageNo === pagination.TotalPages ? "none" : null,
            opacity: pagination.PageNo === pagination.TotalPages ? "0.6" : null
          }}>
          <Text style={{ color: pagination.PageNo === pagination.TotalPages === true ? esiColor.itemColor : esiColor.itemColor }}>Next</Text>
        </TouchableOpacity>
      </View> :
      <></>
  );
}

export default Pagination;
