import {
    GIFT_REQUEST,
    GIFT_SUCCESS,
    GIFT_FAIL,
    GIFT_GET_REQUEST,
    GIFT_GET_SUCCESS,
    GIFT_GET_FAIL,
    GIFT_CREATE_REQUEST,
    GIFT_CREATE_SUCCESS,
    GIFT_CREATE_FAIL,
    GIFT_UPDATE_REQUEST,
    GIFT_UPDATE_SUCCESS,
    GIFT_UPDATE_FAIL,
    GIFT_DELETE_REQUEST,
    GIFT_DELETE_SUCCESS,
    GIFT_DELETE_FAIL,
    GIFT_FILTER,

  GIFT_VENDORID_REQUEST,
  GIFT_VENDORID_SUCCESS,
  GIFT_VENDORID_FAIL,

  GIFT_IMAGEID_REQUEST,
  GIFT_IMAGEID_SUCCESS,
  GIFT_IMAGEID_FAIL,

  GIFT_MAILID_REQUEST,
  GIFT_MAILID_SUCCESS,
  GIFT_MAILID_FAIL,
} from '../actions/giftsAction'
  
const initialState = {
    gifts: { all: [], error: '', isLoading: false },
    gift: { gift: {}, error: '', isLoading: false },
    giftCreate: { gift: {}, error: '', isLoading: false },
    giftUpdate: { gift: {}, error: '', isLoading: false },
    giftDelete: { gift: {}, error: '', isLoading: false },

    giftVendorid: { vendorid: [], error: '', isLoading: false },
    giftImageid: { imageid: [], error: '', isLoading: false },
    giftMailid: { mailid: [], error: '', isLoading: false },


    giftFilter: { select: 'ALL', search: '' },
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case GIFT_REQUEST:
        return { ...state, gifts: { all: state.gifts.all, error: '', isLoading: true } };
      case GIFT_SUCCESS:
        return { ...state, gifts: { all: action.payload, error: '', isLoading: false } };
      case GIFT_FAIL:
        return { ...state, gifts: { all: [], error: action.payload, isLoading: false } };
      case GIFT_GET_REQUEST:
        return { ...state, gift: { gift: {}, error: '', isLoading: true } };
      case GIFT_GET_SUCCESS:
        return { ...state, gift: { gift: action.payload, error: '', isLoading: false } };
      case GIFT_GET_FAIL:
        return { ...state, gift: { gift: {}, error: action.payload, isLoading: false } };
      case GIFT_CREATE_REQUEST:
        return { ...state, giftCreate: { gift: {}, error: '', isLoading: true } };
      case GIFT_CREATE_SUCCESS:
        return { ...state, giftCreate: { gift: action.payload, error: '', isLoading: false } };
      case GIFT_CREATE_FAIL:
        return { ...state, giftCreate: { gift: {}, error: action.payload, isLoading: false } };
      case GIFT_UPDATE_REQUEST:
        return { ...state, giftUpdate: { gift: {}, error: '', isLoading: true } };
      case GIFT_UPDATE_SUCCESS:
        return { ...state, giftUpdate: { gift: action.payload, error: '', isLoading: false } };
      case GIFT_UPDATE_FAIL:
        return { ...state, giftUpdate: { gift: {}, error: action.payload, isLoading: false } };
      case GIFT_DELETE_REQUEST:
        return { ...state, giftDelete: { gift: {}, error: '', isLoading: true } };
      case GIFT_DELETE_SUCCESS:
        return { ...state, giftDelete: { gift: action.payload, error: '', isLoading: false } };
      case GIFT_DELETE_FAIL:
        return { ...state, giftDelete: { gift: {}, error: action.payload, isLoading: false } };
      case GIFT_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, giftFilter: { search: state.giftFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, giftFilter: { select: state.giftFilter.select, search: action.value } };
        }
        return res;  
         
        case GIFT_VENDORID_REQUEST:
          return { ...state, giftVendorid: { vendorid: state.giftVendorid.vendorid, error: '', isLoading: true }, };
        case GIFT_VENDORID_SUCCESS:
          return { ...state, giftVendorid: { vendorid: action.payload, error: '', isLoading: false } };
        case GIFT_VENDORID_FAIL:
          return { ...state, giftVendorid: { vendorid: [], error: action.payload, isLoading: false } };
        
          case GIFT_IMAGEID_REQUEST:
            return { ...state, giftImageid: { imageid: state.giftImageid.imageid, error: '', isLoading: true }, };
          case GIFT_IMAGEID_SUCCESS:
            return { ...state, giftImageid: { imageid: action.payload, error: '', isLoading: false } };
          case GIFT_IMAGEID_FAIL:
            return { ...state, giftImageid: { imageid: [], error: action.payload, isLoading: false } };

            case GIFT_MAILID_REQUEST:
              return { ...state, giftMailid: { mailid: state.giftMailid.mailid, error: '', isLoading: true }, };
            case GIFT_MAILID_SUCCESS:
              return { ...state, giftMailid: { mailid: action.payload, error: '', isLoading: false } };
            case GIFT_MAILID_FAIL:
              return { ...state, giftMailid: { mailid: [], error: action.payload, isLoading: false } };
         
      default:
        return state;
    }
  }