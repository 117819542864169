import React, { useState } from "react";
import { Text, TextInput, HelperText, IconButton, Avatar } from 'react-native-paper';
import { View, StyleSheet, ScrollView, Image, ActivityIndicator } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Formik } from "formik";
import * as Yup from 'yup';
import isEmpty from "../../state/validations/is-empty";
import { getbiddingcommentsAction, AddBiddingQuotecommentAction } from "../../state/actions/bidding/biddingproductActions";
export default function ESIbiddingCommentModel(props: any) {
    const { data } = props
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth?.user);
    const [reload, setReload] = useState(false);
    const [Comment, setComment] = useState([]);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 20, SortBy: "b2b_Bidding_Product_Comment_Id", SortOrder: "ASC" });
    const callBackGetData = (data) => {
        setReload(false);
        if (data) {
            setComment(data);
        }

    };
    const Refreshing = () => {
        let formdata = {
            "search_by_filter": "",
            "search": "",
            "b2b_Bidding_Product_Comment_Id": "",
            "Records_Filter": "FILTER",
            "Partner_Bidding_Product_Quote_Id": data,
            "Partner_Detail_Id": "",
            "Commented_By": 'PARTNER'
        }
        dispatch(getbiddingcommentsAction(formdata, callBackGetData, pagination))

    };
    React.useEffect(() => {
        if (data) {
            let formdata = {
                "search_by_filter": "",
                "search": "",
                "b2b_Bidding_Product_Comment_Id": "",
                "Records_Filter": "FILTER",
                "Partner_Bidding_Product_Quote_Id": data,
                "Partner_Detail_Id": "",
                "Commented_By": 'PARTNER'
            }
            dispatch(getbiddingcommentsAction(formdata, callBackGetData, pagination))

        }
    }, [data]);
    return (

        <View style={{ height: 360 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'flex-end' }}>
                <IconButton icon="refresh" size={20} color='#27B6CC' onPress={() => { Refreshing() }} />
            </View>
            <View style={{ paddingTop: 10 }}>
                {isEmpty(Comment) ? <View>
                    <Text style={{ fontSize: 16, textAlign: 'center' }}>
                        No comments found.
                    </Text>
                </View> :

                    <View style={{ height: 240 }}>
                        <ScrollView showsVerticalScrollIndicator={false} nestedScrollEnabled={true}>
                            {Comment?.length > 0 && Comment?.map((data: any, index: any) => (<View key={index}>
                                <View style={data.Commented_By == 'USER' ? styles.rightContainer : styles.leftContainer} >

                                    <Text style={{ fontSize: 12, marginLeft: 16, padding: 2 }}>{data.Comments}</Text>
                                    <Text style={styles.timestamp}>{data.Comments_Time} ago</Text>
                                </View>
                                <View style={{ paddingBottom: 5 }} />
                            </View>
                            ))}
                        </ScrollView>
                    </View>
                }

            </View>

            <Formik initialValues={{
                Comments: "",
                Created_By: user.Email_Id,
                Partner_Bidding_Product_Quote_Id: data,
            }}

                //field validations
                validationSchema={Yup.object().shape({
                    Comments: Yup.string().required('Comment  is required.'),
                })}
                onSubmit={(values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
                    let submitTest = true;
                    setSubmitting(false);
                    const errors = {};
                    setErrors(errors);
                    if (values) {
                        let formValues = JSON.parse(JSON.stringify(values));
                        formValues.Created_By = user.Email_Id
                        formValues.Partner_Bidding_Product_Quote_Id = data
                        formValues.Commented_By = 'USER',
                            setReload(true);
                        dispatch(AddBiddingQuotecommentAction(formValues, data, resetForm, setReload, callBackGetData));
                    }
                }
                }
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    resetForm
                }) => (
                    <View style={styles.container}>
                        <TextInput
                            placeholder="Send Message"
                            onChangeText={handleChange('Comments')}
                            value={values.Comments}
                            error={Boolean(touched.Comments && errors.Comments)}
                            right={<TextInput.Icon name="send" color="#27B6CC" mode="contained" onPress={handleSubmit} />}
                            mode='outlined'
                            outlineColor="#27B6CC"
                            activeOutlineColor="#27B6CC"
                            right={reload ? <ActivityIndicator /> : <TextInput.Icon name="send" color="#27B6CC" mode="contained" onPress={handleSubmit} />}
                        />
                        <HelperText type="error" visible={Boolean(touched.Comments && errors.Comments)}>
                            <ErrorMessage name="Comments" />
                        </HelperText>
                    </View>
                )}
            </Formik>
        </View >

    )
}
const styles = StyleSheet.create({
    container: {
        width: "100%",
        position: 'absolute', bottom: 0
    },
    leftContainer: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'flex-start',
        backgroundColor: "#F1F2F5",
        // width: 180,
        borderRadius: 10,
        padding: 10,
    },
    rightContainer: {
        flex: 1,
        flexDirection: 'row',
        alignSelf: 'flex-end',
        alignItems: 'center',
        backgroundColor: "#F1F2ff",
        // width: 180,
        padding: 10,
        borderRadius: 10
    },
    timestamp: {
        alignSelf: 'flex-end',
        bottom: 0,
        marginLeft: 8,
        fontSize: 8,
        color: 'gray',
    }
});