import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Button, Searchbar, Portal, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import isEmpty from '../../../../state/validations/is-empty';
import Header from '../../header/header';
import { getAllBiddingServicessAction, resetFilter, updateserviceavailablility } from '../../../../state/actions/Services/biddingServicesAction';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Icon from 'react-native-vector-icons/FontAwesome';
//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Partner_Bidding_Available_Service_Id',
        label: 'Bidding Service ID'
    },
    {
        value: 'Service_Name',
        label: 'Service Name'
    },
    {
        value: 'Partner_Address_Id',
        label: 'Address ID'
    },
    {
        value: 'Status',
        label: 'Status'
    },
    {
        value: 'Created_By',
        label: 'Created By'
    },
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function PartnerBiddingServices(props: any) {
    const { navigation } = props;
    const ID = props.route?.params?.ID;
    const esiColor = useSelector(state => state.theme);
    //addresses contains the data from  GET API using state call
    const Allbiddingservices = useSelector(state => state.biddingservices.bidding_srvices.all);
    let biddingservices = Allbiddingservices?.results;
    const [biddingserviceMenu, setbiddingserviceMenu] = React.useState('All')
    const [biddingserviceSearch, setbiddingserviceSearch] = React.useState('');
    //toast for popup after successful API calls.
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const [page, setPage] = React.useState(0);
    const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = (Number(from) + Number(numberOfItemsPerPage) > Allbiddingservices?.Pagination?.TotalCount ? Allbiddingservices?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
    const onItemsPerPageChange = (e) => {
        numberItemsPerPageChange(e)
        setPage(0)
        setPagination({ PageNo: Number(page) + Number(1), PageSize: e, SortBy: "Partner_Bidding_Available_Service_Id", SortOrder: "DESC" })
    }
    const DynamicPage = (e) => {
        setPage(e)
        setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "Partner_Bidding_Available_Service_Id", SortOrder: "DESC" });
    }
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "Partner_Bidding_Available_Service_Id", SortOrder: "DESC" });
    React.useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            biddingservicesCall();
        }
    }, [pagination])

    //pagination for web    
  const [webpage, setwebPage] = React.useState(1);
  const DynamicPageIncrease = (e) => {
    setwebPage(e < Allbiddingservices?.Pagination?.TotalPages ? e + Number(1) : Allbiddingservices?.Pagination?.TotalPages)
    setPagination({ PageNo: e == Allbiddingservices?.Pagination?.TotalPages ? Allbiddingservices?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "Partner_Bidding_Available_Service_Id", SortOrder: "DESC" });
  }
    const DynamicPagedecrese = (e) => {
        setwebPage(e > 1 ? e - Number(1) : 1)
        setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "Partner_Bidding_Available_Service_Id", SortOrder: "DESC" });
      }
    // search and set search is used for the search functionality
    const [loading, setIsLoading] = React.useState(false);
    const biddingservicesCall = () => {
        setIsLoading(true);
        let formData = {
            "search_by_filter": biddingserviceMenu,
            "search": "",
            "Partner_Bidding_Available_Service_Id": "",
            "Bidding_Service_Id": "",
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Partner_Address_Id": "",
            "Records_Filter": "FILTER",
            "Is_Service_Available": "",
        };

        dispatch(getAllBiddingServicessAction(formData, pagination))
    };
    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);

    //API dispatch
    const dispatch = useDispatch()
    //Static formData
    const refreshCall = () => {
        setIsLoading(true);
        let pagecall = { PageNo: 1, PageSize: 5, SortBy: "Partner_Bidding_Available_Service_Id", SortOrder: "DESC" }
        let formData = {
            "search_by_filter": biddingserviceMenu,
            "search": "",
            "Partner_Bidding_Available_Service_Id": "",
            "Bidding_Service_Id": "",
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Partner_Address_Id": "",
            "Records_Filter": "FILTER",
            "Is_Service_Available": "",
        };
        dispatch(getAllBiddingServicessAction(formData, pagecall))
        setPage(0)
        numberItemsPerPageChange(5)
    };
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        let formData = {
            "search_by_filter": biddingserviceMenu,
            "search": "",
            "Partner_Bidding_Available_Service_Id": "",
            "Bidding_Service_Id": "",
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Partner_Address_Id": "",
            "Records_Filter": "FILTER",
            "Is_Service_Available": "",
        };
        dispatch(getAllBiddingServicessAction(formData, pagination))
    }, [permissions]);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        refreshCall();
        wait(2000).then(() => setRefreshing(false));
    }, []);
    //delete address

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    //sorting, pagination & search
    const [order, setOrder] = React.useState('descending');
    const [orderBy, setOrderBy] = React.useState('Partner_Bidding_Available_Service_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
        setOrderBy(property);
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    // View Dialog
    const [visible, setVisible] = React.useState(false);
    const [addData, setData] = React.useState();

    const hideDialog = () => setVisible(false);

    const handleChange = itemValue => {
        setbiddingserviceMenu(itemValue);
    };
    const handleSearch = value => {
        let formData = {
            "search_by_filter": biddingserviceMenu,
            "search": value,
            "Partner_Bidding_Available_Service_Id": "",
            "Bidding_Service_Id": "",
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Partner_Address_Id": "",
            "Records_Filter": "FILTER",
            "Is_Service_Available": "",
        };
        setbiddingserviceSearch(value)
        dispatch(getAllBiddingServicessAction(formData, pagination))
    };
    const UpdateBiddingService = (service) => {
        const formValues = {
            "Is_Service_Available": '1',
            "Modified_By": EmailID?.Email_Id,
            "Partner_Address_Id": service?.Partner_Address_Id,
            "Partner_Bidding_Available_Service_Id": service?.Partner_Bidding_Available_Service_Id,
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Bidding_Service_Id": service?.Bidding_Service_Id,
            "Status": service?.Status
        };
        numberItemsPerPageChange(5)
        dispatch(updateserviceavailablility(formValues, navigation, toast));
    };
    const DeleteBiddingService = (service) => {
        const formValues = {
            "Is_Service_Available": service.Is_Service_Available,
            "Modified_By": EmailID?.Email_Id,
            "Partner_Address_Id": service?.Partner_Address_Id,
            "Partner_Bidding_Available_Service_Id": service?.Partner_Bidding_Available_Service_Id,
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Bidding_Service_Id": service?.Bidding_Service_Id,
            "Status": 'In-Active'
        };
        numberItemsPerPageChange(5)
        dispatch(updateserviceavailablility(formValues, navigation, toast));
    };
    const UpdateBiddingServices = (service) => {
        const formValues = {
            "Is_Service_Available": '0',
            "Modified_By": EmailID?.Email_Id,
            "Partner_Address_Id": service?.Partner_Address_Id,
            "Partner_Bidding_Available_Service_Id": service?.Partner_Bidding_Available_Service_Id,
            "Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Bidding_Service_Id": service?.Bidding_Service_Id,
            "Status": service?.Status
        };
        numberItemsPerPageChange(5)
        dispatch(updateserviceavailablility(formValues, navigation, toast));
    };
    const style = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 160
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: 'white',
            left: 22,
            top: 8,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.BackgroundColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 150,
            backgroundColor: esiColor.BackgroundColor
        },
        rowsPerPageContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 10,
            justifyContent: 'flex-end',
            borderTopColor: esiColor.SBorderColor,
            borderWidth: 0.5,
        },
        rowsPerPagePicker: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.itemColor
        },
        rowsPerPagePickers: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            borderColor: 'gray',
            borderWidth: 0.5,
            paddingHorizontal: 8,
        },
        paginationContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            // backgroundColor: esiColor.SIconColor
        },
        paginationButton: {
            padding: 10,
            marginHorizontal: 10,
            // backgroundColor: esiColor.SIconColor
        },
        pageInfoText: {
            fontSize: 16,
            padding: 10,
            fontWeight: 'bold',
            color: esiColor.itemColor
        },
    });
    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };
    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
                    <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                        <IconButton icon="close" color={esiColor.GBFColor} size={20} onPress={() => setVisible(!visible)} />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center", borderBottomColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                        <Text style={{ color: esiColor.brandFontColor, textAlign: 'center' }}>View Address</Text>
                    </Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 7 }}>
                            <Dialog.Content style={{ marginLeft: -30 }}>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Bidding Service ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.Text }}>{addData?.Partner_Bidding_Available_Service_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Service ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Bidding_Service_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Service City</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.City_Name} </Text></View>
                                </View>

                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Service Available</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Is_Service_Available == '1' ? 'Yes' : 'No'} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Created_By} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Created_Date} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{addData?.Status} </Text></View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>

            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>

                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                    <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
                        navigation.navigate('PartnerBiddingServicesMain')
                        handleSearch("")
                        dispatch(resetFilter())
                    }
                    }>
                        Go Back
                    </Button>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <View style={{ flexDirection: "column" }}>
                        <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Partner Bidding Services</Text>
                    </View>

                    {/* {(permissions.Address_Management === "write") && */}
                    <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
                        <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => { navigation.navigate('AddPartnerBiddingServices'), handleSearch("") }}>
                            <Text style={{ color: esiColor.itemButtenColor }}>+Add Bidding Services</Text>
                        </Button>
                    </View>
                    {/* } */}

                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={filterOptions}
                                containerStyle={style.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={biddingserviceMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                            <Picker
                                selectedValue={biddingserviceMenu}
                                style={{ height: 51, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.DescColor }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Bidding Service ID" value="Partner_Bidding_Available_Service_Id" />
                                <Picker.Item label="Service Name" value="Service_Name" />
                                <Picker.Item label="Address ID" value="Partner_Address_Id" />
                                <Picker.Item label="Created By" value="Created_By" />
                                <Picker.Item label="Status" value="Status" />

                            </Picker>
                        </View>}

                    <View style={{ flex: dimensions <= 100 ? 0.1 : 0.6 }} />

                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{ borderRadius: 5, borderWidth: 0.3, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, }}
                            iconColor={esiColor.SIconColor}
                            theme={{ colors: { text: esiColor.SBTextColor } }}
                            placeholderTextColor={esiColor.SPHTextColor}
                            placeholder="Search"
                            onChangeText={(value) => handleSearch(value)}
                            value={biddingserviceSearch}
                        />
                    </View>
                    <View style={{ flex: dimensions <= 700 ? null : 0.53 }} />
                </View>

                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                    <ScrollView horizontal={dimensions >= 700 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Bidding_Available_Service_Id'}
                                            sortDirection={orderBy === 'Partner_Bidding_Available_Service_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Bidding_Available_Service_Id')}><Text style={style.titletext}>Bidding Service ID</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Service_Name'}
                                            sortDirection={orderBy === 'Service_Name' ? order : 'ascending'}
                                            onPress={createSortHandler('Service_Name')}><Text style={style.titletext}>Service Name</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Address_Id'}
                                            sortDirection={orderBy === 'Partner_Address_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Address_Id')}><Text style={style.titletext}>Address ID</Text></DataTable.Title>



                                        <DataTable.Title style={style.title} active={orderBy === 'Created_By'}
                                            sortDirection={orderBy === 'Created_By' ? order : 'ascending'}
                                            onPress={createSortHandler('Created_By')}><Text style={style.titletext}>Created By</Text></DataTable.Title>
                                        <DataTable.Title style={style.title} active={orderBy === 'Status'}
                                            sortDirection={orderBy === 'Status' ? order : 'ascending'}
                                            onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Service Available</Text></DataTable.Title>

                                    </DataTable.Header>
                                    {
                                        biddingservices?.map((biddingService, index) => (
                                            <DataTable.Row style={style.databeBox} key={biddingService.Partner_Bidding_Available_Service_Id}>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingService.Partner_Bidding_Available_Service_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingService.Service_Name}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingService.Partner_Address_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingService.Created_By}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{biddingService.Status}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    {/* {(permissions.Address_Management === "write") && ( */}
                                                    <IconButton icon="square-edit-outline" size={20}   iconColor={esiColor.globalButtonColor} onPress={() => { navigation.navigate('EditPartnerBiddingServices', { id: biddingService.Partner_Bidding_Available_Service_Id, ID }), handleSearch("") }} />
                                                    {/* )} */}
                                                    <IconButton icon="eye" size={20}   iconColor={esiColor.globalButtonColor} onPress={(e) => { setData(biddingService); setVisible(!visible) }} />
                                                    {/* {(permissions.Address_Management === "write") &&  */}
                                                    <IconButton icon="delete" size={20}   iconColor={esiColor.globalButtonColor} onPress={(e) => DeleteBiddingService(biddingService)} />
                                                    {/* } */}
                                                </DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}> {biddingService?.Is_Service_Available == '0' ? <IconButton icon="close" size={30} iconColor='red' onPress={(e) => { UpdateBiddingService(biddingService) }} /> : <IconButton icon="check"   iconColor={esiColor.globalButtonColor} size={30} onPress={(e) => { UpdateBiddingServices(biddingService) }} />}</DataTable.Cell>

                                            </DataTable.Row>
                                        ))
                                    }
                                    {isEmpty(biddingservices) &&
                                        <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                                No records found!
                                            </Text>
                                            <Image source={require('../../../../assets/images/nodata.gif')}
                                                style={{ width: 300, height: 300, alignSelf: "center" }} />
                                        </View>
                                    }
                                     {Platform.OS !== "web" ?
                                    <DataTable.Pagination
                                        page={page}
                                        numberOfPages={Math.ceil(Allbiddingservices?.Pagination?.TotalCount / numberOfItemsPerPage)}
                                        onPageChange={page => DynamicPage(to < Allbiddingservices?.Pagination?.TotalCount ? page : 0)}
                                        label={Allbiddingservices?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Allbiddingservices?.Pagination?.TotalCount}` : '0-0'}
                                        showFastPaginationControls={(Number(page) + Number(1)) > Allbiddingservices?.Pagination?.TotalPages ? false : true}
                                        numberOfItemsPerPageList={numberOfItemsPerPageList}
                                        numberOfItemsPerPage={numberOfItemsPerPage}
                                        onItemsPerPageChange={onItemsPerPageChange}
                                        selectPageDropdownLabel={'Rows per page'}
                                    />
                                    :
                                    <View style={style.rowsPerPageContainer}>
                                      <Text style={{ color: esiColor.Text }}>rows per page: </Text>
                                      <Picker
                                        style={style.rowsPerPagePicker}
                                        selectedValue={numberOfItemsPerPage}
                                        onValueChange={(e) => onItemsPerPageChange(e)}
                                      >
                                        <Picker.Item label="5" value={5} />
                                        <Picker.Item label="10" value={10} />
                                        <Picker.Item label="20" value={20} />
                                      </Picker>
                                      <View style={style.paginationContainer}>
                                        <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                                          <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                                        </TouchableOpacity>
                                        <Text style={style.pageInfoText}>
                                          {Allbiddingservices?.Pagination?.PageNo + ' of ' + Allbiddingservices?.Pagination?.TotalPages}
                                        </Text>
                                        <TouchableOpacity onPress={e => DynamicPageIncrease(to < Allbiddingservices?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                                          <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                                        </TouchableOpacity>
                                      </View>
                                    </View>}
                  
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>

                </View>
            </ScrollView>
        </Surface>

    );
}



export default PartnerBiddingServices