import axios from "axios";
import config from './config';
import { meeapi } from "./node-actions/meeapi";

export const BIDDING_COMMENTS_CREATE_REQUEST = "BIDDING_COMMENTS_CREATE_REQUEST";
export const BIDDING_COMMENTS_CREATE_SUCCESS = "BIDDING_COMMENTS_CREATE_SUCCESS";
export const BIDDING_COMMENTS_CREATE_FAIL = "BIDDING_COMMENTS_CREATE_FAIL";
export const ALL_BIDDING_COMMENTS_REQUEST = "ALL_BIDDING_COMMENTS_REQUEST";
export const ALL_BIDDING_COMMENTS_SUCCESS = "ALL_BIDDING_COMMENTS_SUCCESS";
export const ALL_BIDDING_COMMENTS_FAIL = "ALL_BIDDING_COMMENTS_FAIL";


const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
const urlGenarator = (url: any, pagination: any) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}
/*=============================================================
                  Get All Tickets Action
===============================================================*/
export const GetAllBiddingQuoteComments = (formData: any, callBackGetData: any, pagination: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: ALL_BIDDING_COMMENTS_REQUEST
        });
        meeapi.post(urlGenarator(`/Bidding_Products_Comments/Get`, pagination),
            formData,).then(response => {
                let data = response.data;
                if (data) {
                    if (data.Success_Response.Is_Data_Exist === '0') {
                        callBackGetData([])
                        dispatch({
                            type: ALL_BIDDING_COMMENTS_SUCCESS,
                            payload: data
                        });
                    } else {
                        callBackGetData(data.results)
                        dispatch({
                            type: ALL_BIDDING_COMMENTS_SUCCESS,
                            payload: data.results
                        });
                    }
                }
            })
    } catch (err) {
        dispatch({
            type: ALL_BIDDING_COMMENTS_FAIL,
            payload: err
        });
    }
};

/*=============================================================
                Create Ticket comment Action
===============================================================*/
export const AddBiddingQuotecommentAction = (formData: any, getid: any, resetForm: any, setReload: any, callBackGetData: any) => async dispatch => {
    dispatch({
        type: BIDDING_COMMENTS_CREATE_REQUEST
    });
    await axios.post(`${config.nodecudurl}/Bidding_Products_Comments/Create`, formData, {
        headers: nodeheaders,
    })
        .then(response => {

            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {

                dispatch({
                    type: BIDDING_COMMENTS_CREATE_SUCCESS
                });
                resetForm();
                setReload(true);
                const formValue = {
                    Records_Filter: 'FILTER',
                    Partner_Bidding_Product_Quote_Id: getid
                };
                const pagination = {
                    PageNo: 1,
                    PageSize: 5,
                    SortBy: "Bidding_Product_Comment_Id",
                    SortOrder: "ASC"
                }
                dispatch(GetAllBiddingQuoteComments(formValue, callBackGetData, pagination))
            } else {
                const errors = {};

                dispatch({
                    type: BIDDING_COMMENTS_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BIDDING_COMMENTS_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};
