import React from 'react';
import { Card, Button, TextInput, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Text, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    productpartnerdata
} from '../../../../state/actions/partnerproductsAction'
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';

const Discount_Type = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'A',
        label: 'Amount'
    },
    {
        value: 'P',
        label: 'Percentage'
    }
];

//discountlabelType and cashbacklabelType are used to get the data from dropdown
const discountlabelType = [
    {
        value: '',
        label: '--Please Select--'
    },
    {
        value: 'UPTO',
        label: 'UPTO'
    },
    {
        value: 'FLAT',
        label: 'FLAT'
    },
];



const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddProductPartner = (props: any) => {

    const { navigation } = props;
    const dispatch = useDispatch();
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const productpartnersid = props.route?.params?.productspartners;
  const productmasterids=props.route?.params?.productmasterid;
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Products_Partner_Products_Related_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const productid = props.route?.params?.productspartners;
    const productmasterid = props.route?.params?.productmasterid;
    const [showDropDownProductPartnersDiscount, setShowDropDownProductPartnersDiscount] = React.useState('');
    const [disclabelDropDown, setdisclabelDropDown] = React.useState("");

    //screen dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    /*====================================
            Handler
    ====================================*/
    const values = {
        Price: "",
        Gift_Wrap_Amount: "",
        Discount_Type: "",
        Discount: "",
        Esi_Cashback_Type: "",
        Esi_Cashback: "",
        Esi_Commission: "",
        Esi_Commission_Type: "",
        Created_By: EmailID.Email_Id,
        Product_Id: productid,
        Cashback_Label: '',
        Product_Master_ID:productmasterid,
        Discount_Label: '',
        Warranty_Period: '',
    }
    //form Validations
    const Form_Validation = Yup.object().shape({
        Price: Yup.number().required(' Price is required.'),
        Warranty_Period: Yup.string().required('Warranty Period is required.'),
        Discount_Type: Yup.string().test('oneOfRequired', 'Discount Type is required.',
            function (item) {
                return (this.parent.Discount_Type)
            }
        ),
        Discount: Yup.number().when("Discount_Type", (Discount_Type, schema) => {
            if (Discount_Type == "A") {
                return schema.min(1, "Discount Amount must be at least 1.").required('Discount Amount is required.');
            } else if (Discount_Type == "P") {
                return schema.min(1, "Discount Percentage must be at least 1.").max(100, "Discount Percentage must be less than 100.").required('Discount Percentage is required.');
            }
        }),
        Discount_Label: Yup.string().when("Discount", (Discount, schema) => {
            if (Discount) {
                return schema.required('Discount Label is required.');
            }
        }),
    })
    const onSubmit = (values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(productpartnerdata(formValues));
            navigation.navigate('AddPartnerProductsPagetwo', { productspartners: productmasterids ,productmasterid:productmasterid})
        }
    }

    return (
        <Surface style={{
            flex: 1
        }}>
            <Header navigation={navigation} />
            <ScrollView 
             refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        props.resetForm();
                                        navigation.navigate('ProductPartnerGet',{productpartnerparams: productpartnersid ,productmaster:productmasterids})
                                    }} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#d6d6d7' }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Add Product Partner
                                    </Text>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(props.touched.Price && props.errors.Price)}
                                                    label="Price"
                                                    mode='outlined'
                                                    value={props.values.Price}
                                                    onChangeText={props.handleChange("Price")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Price && props.errors.Price)}>
                                                    {props.touched.Price && props.errors.Price}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(props.touched.Gift_Wrap_Amount && props.errors.Gift_Wrap_Amount)}
                                                    label="Gift Wrap Amount"
                                                    mode='outlined'
                                                    value={props.values.Gift_Wrap_Amount}
                                                    onChangeText={props.handleChange("Gift_Wrap_Amount")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Gift_Wrap_Amount && props.errors.Gift_Wrap_Amount)}>
                                                    {props.touched.Gift_Wrap_Amount && props.errors.Gift_Wrap_Amount}
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { color: '#b00020' }]}>
                                                        Discount Type
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(props.touched.Discount_Type && props.errors.Discount_Type) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={Discount_Type}
                                                        labelField="label"
                                                        valueField="value"
                                                        maxHeight={Discount_Type.length > 1 ? 250 : 200}
                                                        search={Discount_Type.length > 1 ?true:false}
                                                        searchPlaceholder={'Select Discount Type'}
                                                        placeholder={!showDropDownProductPartnersDiscount ? 'Select item' : '...'}
                                                        value={props.values.Discount_Type}
                                                        onUpdateValue={item => {
                                                            props.setFieldValue('Discount_Type', item.value)
                                                            setShowDropDownProductPartnersDiscount(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(props.touched.Discount_Type && props.errors.Discount_Type)}>
                                                        {props.touched.Discount_Type && props.errors.Discount_Type}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(props.touched.Discount && props.errors.Discount)}
                                                    label={"Discount " + (props.values.Discount_Type === "A" ? "Amount ₹" : props.values.Discount_Type === "P" ? "Percentage %" : "")}
                                                    mode='outlined'
                                                    disabled={props.values.Discount_Type ? false : true}
                                                    value={props.values.Discount}
                                                    onChangeText={props.handleChange("Discount")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Discount && props.errors.Discount)}>
                                                    {props.touched.Discount && props.errors.Discount}
                                                </HelperText>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { color: '#b00020' }]}>
                                                        Discount Label
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(props.touched.Discount_Label && props.errors.Discount_Label) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={discountlabelType}
                                                        labelField="label"
                                                        valueField="value"
                                                        disable={props.values.Discount ? false : true}
                                                        placeholder={!disclabelDropDown ? 'Select item' : '...'}
                                                        maxHeight={discountlabelType.length > 1 ? 250 : 200}
                                                        search={discountlabelType.length > 1 ?true:false}
                                                        searchPlaceholder={'Select Discount Lable'}
                                                        value={props.values.Discount_Label}
                                                        onUpdateValue={item => {
                                                            props.setFieldValue('Discount_Label', item.value)
                                                            setdisclabelDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(props.touched.Discount_Label && props.errors.Discount_Label)}>
                                                        {props.touched.Discount_Label && props.errors.Discount_Label}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                error={Boolean(props.touched.Warranty_Period && props.errors.Warranty_Period)}
                                                label="Warranty Period"
                                                mode='outlined'
                                                multiline
                                                value={props.values.Warranty_Period}
                                                onChangeText={props.handleChange("Warranty_Period")}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Warranty_Period && props.errors.Warranty_Period)}>
                                                {props.touched.Warranty_Period && props.errors.Warranty_Period}
                                            </HelperText>
                                            </View>
                                        </View>

                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            </View>

                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                            </View>
                                        </View>
                                        <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                            <Button style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={props.handleSubmit}>
                                                Next
                                            </Button>
                                        </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface>
    );
};
const styles = StyleSheet.create({
    checkbox: {
        flexDirection: "row",
        justifyContent: 'flex-start'
    },
    label: {
        marginBottom: 30,
    },
    editor: {
        backgroundColor: "black",
        borderColor: "black",
        borderWidth: 1,
    },
    rich: {
        minHeight: 130,
    },
    richBar: {
        height: 50,
        backgroundColor: "#F5FCFF",
    },
    text: {
        fontWeight: "bold",
        fontSize: 18,
    },
    tib: {
        textAlign: "center",
        color: "#515156",
    },
    a: {
        fontWeight: "bold",
        color: "purple",
    },
    div: {
        fontFamily: "monospace",
    },
    p: {
        fontSize: 30,
    }, container: {
        backgroundColor: 'white',
        paddingTop: 15
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});


export default AddProductPartner;

