import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const HOWPARTNERASSOCWORKS_REQUEST = "HOWPARTNERASSOCWORKS_REQUEST";
export const HOWPARTNERASSOCWORKS_SUCCESS = "HOWPARTNERASSOCWORKS_SUCCESS";
export const HOWPARTNERASSOCWORKS_FAIL = "HOWPARTNERASSOCWORKS_FAIL";

export const HOWPARTNERASSOCWORKS_GET_REQUEST = "HOWPARTNERASSOCWORKS_GET_REQUEST";
export const HOWPARTNERASSOCWORKS_GET_SUCCESS = "HOWPARTNERASSOCWORKS_GET_SUCCESS";
export const HOWPARTNERASSOCWORKS_GET_FAIL = "HOWPARTNERASSOCWORKS_GET_FAIL";

const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
/*=============================================================
                  Get All CMS HOW Partner ASSOCIATION WORKS Action
===============================================================*/
export const getAllHowPartnerassocworksAction = (formData: any) => async (dispatch:any) => {

    try {
        dispatch({
            type: HOWPARTNERASSOCWORKS_REQUEST
        });
        let { data } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Content_Management/Get`,
            formData,
            {
                headers: nodeheaders
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: HOWPARTNERASSOCWORKS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: HOWPARTNERASSOCWORKS_SUCCESS,
                    payload: data.results
                });
            }
        }
    } catch (err) {
        dispatch({
            type: HOWPARTNERASSOCWORKS_FAIL,
            payload: err
        });
    }
};