import {
    USER_NEW_BIDDING_SERVICES_QUOTES_REQUEST,
    USER_NEW_BIDDING_SERVICES_QUOTES_SUCCESS,
    USER_NEW_BIDDING_SERVICES_QUOTES_FAIL,
    BIDDING_SERVICE_QUOTE_CREATE_REQUEST,
    BIDDING_SERVICE_QUOTE_CREATE_SUCCESS,
    BIDDING_SERVICE_QUOTE_CREATE_FAIL,

    BIDDING_SERVICE_FILTER,
  
  
  } from '../actions/userNewBiddingQuoteForServiceAction';
  
  const initialState = {
    bidding_service_quotes: { all: [], error: '', isLoading: false },
    // biddingproductavailability: { partner: {}, error: '', isLoading: false },
     biddingCreate: { biddingservice: {}, error: '', isLoading: false },
    // biddingUpdate: { bidding: {}, error: '', isLoading: false },
    biddingserviceFilter: { select: 'All', search: '' }, 
  };
  
  export default function (state = initialState, action:any) {
    switch (action.type) {
      case USER_NEW_BIDDING_SERVICES_QUOTES_REQUEST:
        return { ...state, bidding_service_quotes: { all: state.bidding_service_quotes.all, error: '', isLoading: true } };
      case USER_NEW_BIDDING_SERVICES_QUOTES_SUCCESS:
        return { ...state, bidding_service_quotes: { all: action.payload, error: '', isLoading: false } };
      case USER_NEW_BIDDING_SERVICES_QUOTES_FAIL:
        return { ...state, bidding_service_quotes: { all: [], error: action.payload, isLoading: false } };
    //     case BIDDING_PRODUCT_AVAILABLE_UPDATE_REQUEST:
    //       return { ...state, biddingproductavailability: { partner: {}, error: '', isLoading: true } };
    //     case BIDDING_PRODUCT_AVAILABLE_UPDATE_SUCCESS:
    //       return { ...state, biddingproductavailability: { partner: state.biddingproductavailability.partner, error: '', isLoading: false } };
    //     case   BIDDING_PRODUCT_AVAILABLE_UPDATE_FAIL:
    //       return { ...state, biddingproductavailability: { partner: {}, error: action.payload, isLoading: false } };
    
        case BIDDING_SERVICE_QUOTE_CREATE_REQUEST:
        return { ...state, biddingCreate: { biddingservice: {}, error: '', isLoading: true } };
        case   BIDDING_SERVICE_QUOTE_CREATE_SUCCESS:
        return { ...state, biddingCreate: { biddingservice: state.biddingCreate.user, error: '', isLoading: false } };
        case BIDDING_SERVICE_QUOTE_CREATE_FAIL:
        return { ...state, biddingCreate: { biddingservice: {}, error: action.payload, isLoading: false } };
       case BIDDING_SERVICE_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, biddingserviceFilter: { search: state.biddingserviceFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, biddingserviceFilter: { select: state.biddingserviceFilter.select, search: action.value } };
        }
        return res;
      default:
        return state;
    }
  }
  
  
  
  