import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const TICKETS_REQUEST = "TICKETS_REQUEST";
export const TICKETS_SUCCESS = "TICKETS_SUCCESS";
export const TICKETS_FAIL = "TICKETS_FAIL";
export const TICKETS_GET_REQUEST = "TICKETS_GET_REQUEST";
export const TICKETS_GET_SUCCESS = "TICKETS_GET_SUCCESS";
export const TICKETS_GET_FAIL = "TICKETS_GET_FAIL";
export const TICKETS_CREATE_REQUEST = "TICKETS_CREATE_REQUEST";
export const TICKETS_CREATE_SUCCESS = "TICKETS_CREATE_SUCCESS";
export const TICKETS_CREATE_FAIL = "TICKETS_CREATE_FAIL";
export const TICKETS_UPDATE_REQUEST = "TICKETS_UPDATE_REQUEST";
export const TICKETS_UPDATE_SUCCESS = "TICKETS_UPDATE_SUCCESS";
export const TICKETS_UPDATE_FAIL = "TICKETS_UPDATE_FAIL";
export const TICKETS_DELETE_REQUEST = "TICKETS_DELETE_REQUEST";
export const TICKETS_DELETE_SUCCESS = "TICKETS_DELETE_SUCCESS";
export const TICKETS_DELETE_FAIL = "TICKETS_DELETE_FAIL";
export const TICKETS_FILTER = "TICKETS_FILTER ";

/*=============================================================
                   Add Ticket Action
 ===============================================================*/
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const nodeheaders = config.nodeheadersCommon;
export const AddTicketsAction = (formData: any, navigation: any, setErrorMessage: any, setErrors: any, toast: any) => async (dispatch: any) => {
  dispatch({
    type: TICKETS_CREATE_REQUEST
  });
  setErrorMessage({ Ticket_Subject: { key: '', message: '' }, global: { key: '', message: '' } });
  axios.post(`${config.nodecudurl}/Partner_Tickets/Create`, formData, {
   headers: cudheaders
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: TICKETS_CREATE_SUCCESS
        });
        toast.show({
          message: 'Ticket Added successfully.', type: 'info', duration: 3000, position: 'top'
        });
        const formValue = {
          Records_Filter: 'ALL',
        };
        dispatch(getAllTicketsAction(formValue, toast));
        navigation.navigate('Tickets');
      } else {
        const errors = {};
        if (successResponse.Email_Id) {
          errors.Ticket_Subject = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Ticket_Subject: { key: successResponse.Ticket_Subject ? successResponse.Ticket_Subject : '', message: successResponse.Ticket_Subject ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Ticket_Subject) ? successResponse.UI_Display_Message : '' } });
        dispatch({
          type: TICKETS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Ticket_Subject: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: TICKETS_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};


/*=============================================================
                 Update Ticket Action
===============================================================*/

export const EditTicketsAction = (formData: any, navigation: any, setErrorMessage: any, setErrors: any, toast: any) => async (dispatch: any) => {
  dispatch({
    type: TICKETS_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Partner_Tickets/Update`, formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: TICKETS_UPDATE_SUCCESS
        });
        toast.show({
          message: 'Ticket updated successfully.', type: 'info', duration: 3000, position: 'top'
        });
        const formValue = {
          Records_Filter: 'ALL',
        };
        dispatch(getAllTicketsAction(formValue, toast));
        navigation.navigate('Tickets');
      } else {
        dispatch({
          type: TICKETS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: TICKETS_UPDATE_FAIL,
        payload: "Please try again later.Currently server is not working."
      });
    })
};
/*=============================================================
                   Get All Ticket Action
===============================================================*/

export const getAllTicketsAction = (formData: any, toast: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: TICKETS_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Tickets/Get`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: TICKETS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: TICKETS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: TICKETS_FAIL,
      payload: err
    });
  }
};


export const uploadTicketAttachment = (
  imageData: any, fileData: any,
  callBackUploadImgae: any, setSizeError: any
) => async (dispatch: any) => {
  try {
    let { data } = await axios.post(
      `${config.url}/Partner_Tickets/File_Upload?filename=${fileData.fileName}&file_extension=${fileData.ext}&filetype=text&ticket_id=${fileData.TicketsId}`,
      imageData,
      {
        headers:headers,
        transformRequest: (d) => d,
      }
    );
    if (data) {
      if (data.Response_Status === "Success") {
        callBackUploadImgae(data);
      } else {

        setSizeError(data.UI_Display_Message + ".")
      }
    }
  } catch (err) {
  }
};


/*=============================================================
                   User Delete
===============================================================*/

export const deleteTicketsAction = (formData: any, toast: any) => async (dispatch: any) => {
  try {
    let { data } = await axios.post(`${config.nodecudurl}/Partner_Tickets/Delete`, formData, {
      headers: cudheaders
    });
    if (data) {
      const formValue = {
        Records_Filter: 'ALL',
      };
      dispatch(getAllTicketsAction(formValue, toast));
      toast.show({
        message: 'Ticket deleted successfully.', type: 'info', duration: 3000, position: 'top'
      });
    }
  } catch (err) {
  }
};
// Filter 
export const ticketFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: TICKETS_FILTER,
      fType: fType,
      value: value,
    });
  }
}