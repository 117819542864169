import * as React from 'react';
import { View, Text, ScrollView, Dimensions, StyleSheet, RefreshControl, Image } from 'react-native';
import { Card, Surface, Button } from 'react-native-paper';
import Header from '../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../state/validations/is-empty';
import { getsingleorderAction } from '../../state/actions/ordermanagementAction';
import { getSingleB2BOrder, getSingleB2COrder } from '../../state/actions/ordersAction';
import { updateShippingAction, updateb2bOrderAction } from '../../state/actions/shoppers/shoppersordersAction';
import { useToast } from '../../components/custom/react-native-paper-toast/src';

const B2BOrderNotification = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const esiColor = useSelector(state => state.theme);

    const dispatch = useDispatch();
    //orders is used to store the values from previous page using props
    const orders = props.route?.params?.data;
    //dimensions
    const toast = useToast();
    const EmailID = useSelector(state => state.auth?.user);
    const [orderdetails, setOrderDetails] = React.useState([]);
    const [billingdetails, setBillingDetails] = React.useState([]);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {

        if (!isEmpty(EmailID)) {
            let formData = {
                "Records_Filter": "FILTER",
                "Partner_Details_Id": EmailID.Partner_Details_Id,
                "Shopper_Order_Id": orders
            }
            dispatch(getSingleB2BOrder(formData,callBackOrderData))
        }
    }, [orders, EmailID]);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
            }
        );
    });
    const callBackOrderData = (data: any) => {
        if (data) {
            setOrderDetails(data)
            setBillingDetails(data.Billing_Address[0])
        }
    }
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const RejectOrders = (rejectorder) => {
        const formValues = {
            "Buyer_Partner_Detail_ID": rejectorder?.Buyer_Partner_Detail_Id,
            "Cancel_Comment": "",
            "Cancel_Reason": "",
            "Item": [
              {
                "Item_ID":rejectorder?.Item_Data?.Item_Master_Data[0]?.Item_Master_Id,
                "Item_Status": 'REJECTED',
              }
            ],
            "Order_ID": rejectorder?.Shopper_Order_Id
        };
        dispatch(updateb2bOrderAction(formValues, navigation, toast));
    };
    const AcceptOrders = (acceptorder) => {
        const formValues = {
            "Buyer_Partner_Detail_ID": acceptorder?.Buyer_Partner_Detail_Id,
            "Cancel_Comment": "",
            "Cancel_Reason": "",
            "Item": [
              {
                "Item_ID":acceptorder?.Item_Data?.Item_Master_Data[0]?.Item_Master_Id,
                "Item_Status": 'ACCEPTED',
              }
            ],
            "Order_ID": acceptorder?.Shopper_Order_Id
        };
        dispatch(updateb2bOrderAction(formValues, navigation, toast));
    };
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1, flex: 1 }}>
                    <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 126, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('Notification')}>
                        Go Back
                    </Button>
                </View>
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Card style={{
                        width: dimensions <= 500 ? "96%" : "75%", marginLeft: "auto", marginRight: "auto",
                        marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: '#D6D6D7'
                    }}>
                        <View style={{
                            width: dimensions <= 500 ? "96%" : "75%", marginLeft: "auto", marginRight: "auto",
                            marginTop: "2%", marginBottom: "2%",
                        }}>
                            <Text style={{ fontSize: 20, fontWeight: "bold", paddingBottom: 10 }}>
                                Order Summary
                            </Text>
                            <View style={{ flexDirection: 'row', alignSelf: 'flex-start', marginVertical: 3 }}>
                                <Image source={{ uri: orderdetails?.Item_Data?.Item_Master_Data[0]?.Product_Image }} style={{ height: 100, width: "100%", resizeMode: 'contain' }} />
                                <Text>{'                    '}    </Text>
                            </View>
                            <View>
                           </View>
                            <View style={{ flexDirection: 'row', alignSelf: 'flex-start', marginVertical: 3, flex: 1, marginBottom: 20 }}>
                                <Text style={{ color: '#27B6CC', fontWeight: 'bold', }}>
                                    {orderdetails?.Item_Data?.Item_Master_Data[0]?.Product_Name.substring(0, 100)}
                                </Text>
                            </View>
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
                                <View style={{ flex: 0.5, marginRight: 10 }}>
                                    <Text style={{ color: 'grey' }}>
                                        Order ID
                                    </Text>
                                </View>
                                <View style={{ flex: 0.5, marginLeft: 10 }}>
                                    <Text>
                                        {orderdetails?.Shopper_Order_Id}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between', marginTop: 10 }}>
                                <View style={{ flex: 0.5, marginRight: 10 }}>
                                    <Text style={{ color: 'grey' }}>
                                        Item Master ID
                                    </Text>
                                </View>
                                <View style={{ flex: 0.5, marginLeft: 10 }}>
                                    <Text>
                                        {orderdetails?.Item_Data?.Item_Master_Data[0]?.Item_Master_Id}
                                    </Text>
                                </View>
                            </View>
                            
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between', marginTop: 10 }}>
                                <View style={{ flex: 0.5, marginRight: 10 }}>
                                    <Text style={{ color: 'grey' }}>
                                        Item Master Status
                                    </Text>
                                </View>
                                <View style={{ flex: 0.5, marginLeft: 10, }}>
                                    <Text style={{ color:orderdetails?.Item_Data?.Item_Master_Data[0]?.Line_Item_Status==='NEW'?'blue': orderdetails?.Item_Data?.Item_Master_Data[0]?.Line_Item_Status==='REJECTED'?'red':'green'}}>{ orderdetails?.Item_Data?.Item_Master_Data[0]?.Line_Item_Status}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between', marginTop: 10 }}>
                                <View style={{ flex: 0.5, marginRight: 10 }}>
                                    <Text style={{ color: 'grey' }}>
                                        Quantity
                                    </Text>
                                </View>
                                <View style={{ flex: 0.5, marginLeft: 10 }}>
                                    <Text>
                                        {orderdetails?.Item_Data?.Item_Master_Data[0]?.Quantity}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between', marginTop: 10 }}>
                                <View style={{ flex: 0.5, marginRight: 10 }}>
                                    <Text style={{ color: 'grey' }}>
                                        Shipping Address
                                    </Text>
                                </View>
                                <View style={{ flex: 0.5, marginLeft: 10 }}>
                                    <Text>
                                        {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Address[0].Door_No}, {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Address[0].Street}, {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Address[0].Land_Mark}, {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Address[0].City}, {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Address[0].State}, {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Address[0].Country}, {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Address[0].Zip}, {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Address[0].Mobile}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between', marginTop: 10 }}>
                                <View style={{ flex: 0.5, marginRight: 10 }}>
                                    <Text style={{ color: 'grey' }}>
                                        Billing Address
                                    </Text>
                                </View>
                                <View style={{ flex: 0.5, marginLeft: 10 }}>
                                    <Text>
                                        {billingdetails?.Door_No}, {billingdetails?.Street}, {billingdetails?.Land_Mark}, {billingdetails?.City}, {billingdetails?.State}, {billingdetails?.Country}, {billingdetails?.Zip}, {billingdetails?.Mobile}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between', marginTop: 10 }}>
                                <View style={{ flex: 0.5, marginRight: 10 }}>
                                    <Text style={{ color: 'grey' }}>
                                        Expected Delivery Time
                                    </Text>
                                </View>
                                <View style={{ flex: 0.5, marginLeft: 10 }}>
                                    <Text>
                                        {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary[0]?.Expected_Delivery_Time}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between', marginTop: 10 }}>
                                <View style={{ flex: 0.5, marginRight: 10 }}>
                                    <Text style={{ color: 'grey' }}>
                                        Shipping ID
                                    </Text>
                                </View>
                                <View style={{ flex: 0.5, marginLeft: 10 }}>
                                    <Text>
                                        {orderdetails?.Item_Data?.Item_Master_Data[0]?.Shipping_Summary[0]?.Shipping_ID}
                                    </Text>
                                </View>
                            </View>{orderdetails?.Item_Data?.Item_Master_Data[0]?.Line_Item_Status==='NEW'&&
                            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 25 }}>
                                <Button textColor="red" mode="contained" onPress={(e) => { RejectOrders(orderdetails) }} >Reject</Button>
                                <Button textColor="#27b6cc" mode="contained" onPress={(e) => { AcceptOrders(orderdetails) }} >Accept</Button>
                            </View>}
                        </View>
                    </Card>
                </View>
            </ScrollView>
        </Surface>
    )
}

export default B2BOrderNotification;


const styles = StyleSheet.create({
    mainview: {
        flexDirection: "row", justifyContent: 'space-between', flexWrap: 'wrap', paddingTop: 15
    },
    text: {
        fontWeight: "bold", fontSize: 13
    }
})