import React, { useState } from 'react';
import data from './data';
import { ScrollView, StyleSheet, View } from 'react-native';
import ProductCard from './ProductCard';
import { Button, Surface, Text } from 'react-native-paper';
import Header from '../auth/components/header';

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingBottom: 3,
    paddingTop: 3
  },
  productCard: {
    height: '100%'
  }
});

const PaymentsMain = (props: any) => {
  const { navigation } = props;
  const [products] = useState(data);

  return (
    <Surface style={{ flex: 1 }}>
      <Header navigation={navigation} />
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10 }}>
          Payments & Settlements
        </Text>
      </View>
      <ScrollView>
      <View style={{ width: 50, alignItems: "flex-start", flexShrink: 1 }}>
        <Button icon="keyboard-backspace" color='#27B6CC' style={{ width: 150 ,justifyContent: 'flex-start'}} onPress={() => navigation.navigate('OrderMainCenter')}>
          Go Back
        </Button>
      </View>
        <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
          {products.map(product => (
            <View key={product.id}>
              <ProductCard
                style={styles.productCard}
                product={product} navigation={navigation}
              />
            </View>
          ))}
        </View>
      </ScrollView>
    </Surface>
  );
};

export default PaymentsMain;