
import React, { useState } from 'react';
import NavBar from './NavBar';
import { useSelector } from 'react-redux';
import { View } from 'react-native';

const DashboardLayout = (props: any) => {
  const { navigation } = props;
  const [isMobileNavOpen, setMobileNavOpen] = useState(true);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
      <NavBar
        onMobileClose={() => setMobileNavOpen(true)}
        openMobile={isMobileNavOpen} navigation={navigation}
      />
  );
};
export default DashboardLayout;

