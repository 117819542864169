import axios from 'axios';
import config from '../config';

export const BOOKED_REQUEST = "BOOKED_REQUEST";
export const BOOKED_SUCCESS = "BOOKED_SUCCESS";
export const BOOKED_FAIL = "BOOKED_FAIL";

export const SINGLE_BOOKED_REQUEST = "SINGLE_BOOKED_REQUEST";
export const SINGLE_BOOKED_SUCCESS = "SINGLE_BOOKED_SUCCESS";
export const SINGLE_BOOKED_FAIL = "SINGLE_BOOKED_FAIL";

export const BOOKED_CREATE_REQUEST = "BOOKED_CREATE_REQUEST";
export const BOOKED_CREATE_SUCCESS = "BOOKED_CREATE_SUCCESS";
export const BOOKED_CREATE_FAIL = "BOOKED_CREATE_FAIL";

export const BOOKED_UPDATE_REQUEST = "BOOKED_UPDATE_REQUEST";
export const BOOKED_UPDATE_SUCCESS = "BOOKED_UPDATE_SUCCESS";
export const BOOKED_UPDATE_FAIL = "BOOKED_UPDATE_FAIL";

export const BOOKED_FILTER = "BOOKED_FILTER";
/*=============================================================
                  Get All PROPERTY Action
===============================================================*/

const headers = config.headersCommon;

export const getBookedAction = (formData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: BOOKED_REQUEST
        });
        let { data } = await axios.post(
            `${config.url}/Property_Booked_Date/Get`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: BOOKED_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: BOOKED_SUCCESS,
                    payload: data?.Property_Booked_Date
                });
            }
        }
    } catch (err) {
        dispatch({
            type: BOOKED_FAIL,
            payload: err
        });
    }
};

/*=============================================================
                  PROPERTY Create Action
===============================================================*/

export const createBookedAction = (formData: any, navigation: any, toast: any) => async (dispatch: any) => {
    dispatch({
        type: BOOKED_CREATE_REQUEST
    });
    axios.post(`${config.url}/Property_Booked_Date/Create`, formData,
        {
            headers: headers
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: BOOKED_CREATE_SUCCESS
                });
                navigation.navigate('bookeddatesget')
                const formValue = {
                    "Partner_Detail_ID": formData?.Partner_Detail_Id,
                    "Records_Filter": "FILTER"
                };
                dispatch(getBookedAction(formValue))
            } else {
                const errors = {};
                dispatch({
                    type: BOOKED_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BOOKED_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


/*=============================================================
                  Address Update Action
===============================================================*/

export const updateBookedAction = (formData: any, navigation: any,) => async (dispatch: any) => {
    dispatch({
        type: BOOKED_UPDATE_REQUEST
    });
    axios.put(`${config.url}/Property_Booked_Date/Update`, formData,
        {
            headers: headers
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: BOOKED_UPDATE_SUCCESS
                });
                navigation.navigate('GetProperties')
                const formValue = {
                    "Partner_Detail_ID": formData?.Partner_Detail_Id,
                    "Records_Filter": "FILTER"
                };
                dispatch(getBookedAction(formValue))
            } else {
                const errors = {};
                dispatch({
                    type: BOOKED_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BOOKED_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};

/*=============================================================
                  Address Delete
===============================================================*/


// Filter 
export const BookedFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: BOOKED_FILTER,
            fType: fType,
            value: value,
        });
    }
}


export const getBookedByIdAction = (formData: any) => async dispatch => {
    try {
        dispatch({
            type: SINGLE_BOOKED_REQUEST
        });
        let { data, status } = await axios.post(
            `${config.url}/Property_Booked_Date/Get`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: SINGLE_BOOKED_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: SINGLE_BOOKED_SUCCESS,
                    payload: data?.Property_Booked_Date[0]
                });
            }
        }
    } catch (err) {
        dispatch({
            type: SINGLE_BOOKED_FAIL,
            payload: err
        });
    }
};