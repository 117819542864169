import React from "react";
import { View, Text, ScrollView, Platform, RefreshControl } from "react-native";
import { Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Header from "../auth/components/header";
import TotalBrands from "../reports/dashboardView/count/totalBrands";
import TotalCoupons from "../reports/dashboardView/count/totalCoupons";
import TotalDeals from "../reports/dashboardView/count/totalDeals";
import TotalOrders from "../reports/dashboardView/count/totalOrders";
import TotalProducts from "../reports/dashboardView/count/totalProducts";
import TotalProfit from "../reports/dashboardView/count/totalProfit";
import TotalUsers from "../reports/dashboardView/count/totalUsers";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default function Dashboard(props: any) {
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const { navigation } = props;
    //  //Clean up
    React.useEffect(() => {
        return () => {
        };
    }, []);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    return (
        <Surface style={{ flex: 1,backgroundColor:esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10,color: esiColor.brandFontColor }}>
                    Dashboard
                </Text>
            </View>
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ flexDirection: Platform.OS === "web" ? 'row' : 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flex: 1 }} >

                    <View style={{ flexDirection: 'row' }}>
                        <TotalOrders navigate={navigation.navigate} esiColor={esiColor} />
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <TotalProfit navigate={navigation.navigate}  esiColor={esiColor}/>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <TotalUsers navigate={navigation.navigate} esiColor={esiColor}/>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <TotalCoupons navigate={navigation.navigate} esiColor={esiColor}/>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <TotalDeals navigate={navigation.navigate} esiColor={esiColor}/>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <TotalProducts navigate={navigation.navigate} esiColor={esiColor}/>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <TotalBrands navigate={navigation.navigate} esiColor={esiColor}/>
                    </View>

                </View>
            </ScrollView>
        </Surface>
    );
};