import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image, RefreshControl } from 'react-native';
import { Dialog, Card, DataTable, Button, Searchbar, Portal, Surface, IconButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../auth/components/header';
import isEmpty from '../../state/validations/is-empty';
import { getAllTransactionAction, TransactionFilter,resetFilter } from '../../state/actions/transactionhistoryAction';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';

//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Transaction_Id',
        label: 'Transaction ID'
    },
    {
        value: 'Order_Id',
        label: 'Order ID'
    },
    {
        value: 'Order_Amount',
        label: 'Order Amount'
    },
    {
        value: 'Order_Status',
        label: 'Order Status'
    },
    {
        value: 'Transaction_Status',
        label: 'Transaction Status'
    },
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function TransactionDetails({ navigation }: { navigation: any }) {

    //transactions contains the data from  GET API using state call
    const transactions = useSelector(state => state.transaction.transactions.all);
    //transactionMenu is used to store the cache data in Menu select using state call
    let transactionMenu = useSelector(state => state.transaction.transactionFilter.select);
    //transactionSearch is used to store the cache data in search using state call
    let transactionSearch = useSelector(state => state.transaction.transactionFilter.search);

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);
    const EmailID = useSelector(state => state.auth?.user);
    const [transaction, setTransaction] = React.useState();
    //API dispatch
    const dispatch = useDispatch()
    //Static formData
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (isEmpty(transactions)) {
            let formData = {
                Partner_Id: EmailID?.Partner_Details_Id,
                Records_Filter: "ALL"
            };
            setTransaction(transactions)
            handleSearch("");
            dispatch(getAllTransactionAction(formData))
            dispatch(TransactionFilter('search', ''));
            dispatch(TransactionFilter('select', 'All'));
        }
    }, [transactions]);

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });


    //sorting, pagination & search
    const [page, setPage] = React.useState(0);
    const [sort, setSort] = React.useState(false);
    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, transactions?.length);

    const [oldSearch, setOldSearch] = React.useState(0);

    const checkTransactionForTable = () => {

        if (transactionSearch) {
            try {
                if (transactionMenu === "All") {
                    return transactions.filter(x => {
                        let keys = ["Transaction_Id", "Order_Id", "Order_Amount", "Order_Status", "Transaction_Status"];
                        for (let i = 0; i < keys?.length; i++) {
                            try {
                                if (x[keys[i]].toLowerCase().includes(transactionSearch.toLowerCase())) {
                                    return true;
                                }
                            } catch (error) {
                                if (x[keys[i]]?.toString().toLowerCase().includes(transactionSearch.toLowerCase())) {
                                    return true;
                                }
                            }
                        }
                    });

                } else {
                    return transactions.filter(x => (x[transactionMenu] ? x[transactionMenu].toLowerCase() : '').includes(transactionSearch.toLowerCase()));
                }
            } finally {
                if (page > 0) {
                    if (oldSearch !== transactionSearch?.length) {
                        setOldSearch(transactionSearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch !== transactionSearch?.length) {
                    setOldSearch(transactionSearch?.length);
                    setPage(0);
                }
            }
        }
        else {
            return transactions
        }
    }

    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Transaction_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
            return -1;
        }
        if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {

            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis?.map((el) => el[0]);
    }

    // View Dialog
    const [visible, setVisible] = React.useState(false);
    const [transactionData, setData] = React.useState();

    const hideDialog = () => setVisible(false);

    const handleChange = itemValue => {
        dispatch(TransactionFilter('select', itemValue));
    };
    const handleSearch = value => {
        dispatch(TransactionFilter('search', value));
    };


    const data = stableSort(
        checkTransactionForTable(), getComparator(order, orderBy))
        const [refreshing, setRefreshing] = React.useState(false);
        const wait = (timeout) => {
            return new Promise(resolve => setTimeout(resolve, timeout));
        }
        const onRefresh = React.useCallback(() => {
            setRefreshing(true);
            wait(2000).then(() => setRefreshing(false));
        }, []);
    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
                <Portal>
                    <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto' }}>
                        <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                            <IconButton icon="close" size={20} onPress={() => setVisible(!visible)} />
                        </View>
                        <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                            <Text style={{ color: '#27B6CC' }}>View Transaction</Text>
                        </Dialog.Title>
                        <Dialog.ScrollArea>
                            <ScrollView contentContainerStyle={{ paddingHorizontal: 10 }}>
                                <Dialog.Content style={{ marginLeft: -30 }}>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Transaction ID</Text></View>
                                        <View style={{ flex: 2.5 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Transaction_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Transaction Status</Text></View>
                                        <View style={{ flex: 2.5 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Transaction_Status} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Transaction Date</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Transaction_Date} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Transaction Reference</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Transaction_Reference} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Partner Details ID</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Partner_Detail_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Billing Country</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Billing_Country} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Currency</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Currency} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Order Id</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Order_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Order Amount</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Order_Amount} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Order Status</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Order_Status} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Payment Gateway Reference Number</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Payment_Gateway_Reference_Number} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Payment Method</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Payment_Methed} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Payment Mode</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Payment_Mode} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Payment Gateway Response Code</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Payment_Gateway_Response_Code} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Payment Gateway Response Message</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Payment_Gateway_Response_Message} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>User Address ID</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.User_Address_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>User Email ID</Text></View>
                                        <View style={{ flex: 2.5 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.User_Email_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Wallet ID</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Wallet_Id} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Wallet Amount</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Wallet_Amount} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Discount Value</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Discount_Value} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>ESI Commission Amount</Text></View>
                                        <View style={{ flex: 2.5 }}><Text>{transactionData?.Esi_Commission_Amount} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Created By</Text></View>
                                        <View style={{ flex: 2.5 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Created_By} </Text></View>
                                    </View>
                                    <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                        <View style={{ flex: 2.5 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Modified By</Text></View>
                                        <View style={{ flex: 2.5 }}><Text style={{ color: '#27B6CC' }}>{transactionData?.Modified_By} </Text></View>
                                    </View>
                                </Dialog.Content>
                            </ScrollView>
                        </Dialog.ScrollArea>
                    </Dialog>
                </Portal>

                <ScrollView>
                    <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
                        <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() =>{ navigation.navigate('OrderMainCenter')
                    handleSearch("")
                    dispatch(resetFilter())
                    }}>
                            Go Back
                        </Button>
                    </View>
                    <View style={{ flexDirection: "column" }}>
                        <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, paddingBottom: 10 }}>Transaction History</Text>
                    </View>

                    <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                        {Platform.OS !== 'web' ?
                            <View style={{ flex: dimensions <= 500 ? 2.8 : 0.5, marginLeft: 15 }}>
                                <EsiSearchDropdown
                                    style={[style.dropdown]}
                                    placeholderStyle={style.placeholderStyle}
                                    selectedTextStyle={style.selectedTextStyle}
                                    inputSearchStyle={style.inputSearchStyle}
                                    iconStyle={style.iconStyle}
                                    data={filterOptions}
                                    containerStyle={style.containerstyle}
                                    maxHeight={180}
                                    labelField="label"
                                    valueField="value"
                                    placeholder={!showDropDown ? 'All' : ''}
                                    value={transactionMenu}
                                    onFocus={() => setShowDropDown(true)}
                                    onBlur={() => setShowDropDown(false)}
                                    onUpdateValue={item => {
                                        setShowDropDown(false);
                                        handleChange(item.value)
                                    }}
                                />
                            </View>
                            :
                            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                                <Picker
                                    selectedValue={transactionMenu}
                                    style={{ height: 50, width: dimensions <= 500 ? 140 : 150, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', flexShrink: 1, backgroundColor: '#ffff' }}
                                    onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                                >
                                    <Picker.Item label="All" value="All" />
                                    <Picker.Item label="Transaction ID" value="Transaction_Id" />
                                    <Picker.Item label="Order ID" value="Order_Id" />
                                    <Picker.Item label="Order Amount" value="Order_Amount" />
                                    <Picker.Item label="Order Status" value="Order_Status" />
                                    <Picker.Item label="Transaction Status" value="Transaction_Status" />
                                </Picker>
                            </View>
                        }
                        <View style={{ flex: dimensions <= 500 ? 0.1 : 0.1 }} />
                        <View style={{ width: dimensions <= 500 ? '52%' : "60%", marginRight: 12, marginLeft: 5 }}>
                            <Searchbar
                                style={{ borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', marginRight: 15, marginLeft: 10 }}
                                placeholder="Search"
                                onChangeText={(value) => handleSearch(value)}
                                value={transactionSearch}
                            />
                        </View>
                    </View>



                    <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>

                        <ScrollView refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  colors={['#27B6CC']}
                  tintColor={'#27B6CC'}
                  title={"Pull to refresh"}
                  titleColor={"#27B6CC"}
                />
              }horizontal={dimensions >= 700 ? false : true}>
                            <View style={style.mainbox}>
                                <Card>
                                    <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                                        <DataTable.Header style={style.databeHeader}>
                                            <DataTable.Title style={style.title} active={orderBy === 'Transaction_Id'}
                                                sortDirection={orderBy === 'Transaction_Id' ? order : 'ascending'}
                                                onPress={createSortHandler('Transaction_Id')}><Text style={style.titletext}>Transaction ID</Text></DataTable.Title>

                                            <DataTable.Title style={style.title} active={orderBy === 'Order_Id'}
                                                sortDirection={orderBy === 'Order_Id' ? order : 'ascending'}
                                                onPress={createSortHandler('Order_Id')}><Text style={style.titletext}>Order ID</Text></DataTable.Title>

                                            <DataTable.Title style={style.title} active={orderBy === 'Order_Amount'}
                                                sortDirection={orderBy === 'Order_Amount' ? order : 'ascending'}
                                                onPress={createSortHandler('Order_Amount')}><Text style={style.titletext}>Order Amount</Text></DataTable.Title>

                                            <DataTable.Title style={style.title} active={orderBy === 'Order_Status'}
                                                sortDirection={orderBy === 'Order_Status' ? order : 'ascending'}
                                                onPress={createSortHandler('Order_Status')}><Text style={style.titletext}>Order Status</Text></DataTable.Title>

                                            <DataTable.Title style={style.title} active={orderBy === 'Transaction_Status'}
                                                sortDirection={orderBy === 'Transaction_Status' ? order : 'ascending'}
                                                onPress={createSortHandler('Transaction_Status')}><Text style={style.titletext}>Transaction Status</Text></DataTable.Title>

                                            <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                        </DataTable.Header>
                                        {
                                            data?.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((transaction, index) => (
                                                <DataTable.Row style={style.databeBox} key={transaction.Transaction_Id}>
                                                    <DataTable.Cell style={style.tablerow}>{transaction.Transaction_Id}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>{transaction.Order_Id}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>{transaction.Order_Amount}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>{transaction.Order_Status}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>{transaction.Transaction_Status}</DataTable.Cell>
                                                    <DataTable.Cell style={style.tablerow}>
                                                        {(permissions.Transaction_History === "write") && <IconButton icon="square-edit-outline" size={20} color='#27B6CC' onPress={() => navigation.navigate('EditTransactionhistory', { data: transaction })} />}
                                                        <IconButton icon="eye" size={20} color='#27B6CC' onPress={(e) => { setData(transaction); setVisible(!visible) }} /></DataTable.Cell>
                                                </DataTable.Row>
                                            ))
                                        }
                                        {isEmpty(data) &&
                                           <View>
                                           <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center",paddingTop:20 }}>
                                             No records found!
                                           </Text>
                                           <Image source={require('../../assets/images/nodata.gif')}
                                            style={{ width: 300, height: 300,alignSelf:"center" }} />
                                          </View>
                                        }
                                        <DataTable.Pagination
                                            page={page}
                                            numberOfPages={Math.ceil(transactions?.length / numberOfItemsPerPage)}
                                            onPageChange={page => setPage(to < data?.length ? page : 0)}
                                            label={transactions?.length ? `${from + 1}-${to} of ${data?.length}` : '0-0'}
                                            showFastPaginationControls={to > data?.length ? false : true}
                                            numberOfItemsPerPageList={numberOfItemsPerPageList}
                                            numberOfItemsPerPage={numberOfItemsPerPage}
                                            onItemsPerPageChange={onItemsPerPageChange}
                                            selectPageDropdownLabel={'Rows per page'}
                                        />
                                    </DataTable>
                                </Card>
                            </View>
                        </ScrollView>
                    </View>
                </ScrollView>
        </Surface>

    );
}

const style = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontWeight: "bold",
        fontSize: 12,
        justifyContent: "center",
        textAlign: "center",
        width: 200
    },
    titletext: {
        fontWeight: "bold",
        fontSize: 14,
        color: 'black',
    },
    tablerow: {
        justifyContent: "center",
        textAlign: "center",
        width: 140
    },
    mainbox: {
        textAlign: 'center',
        margin: 15,
        flex: 1,
        justifyContent: 'space-between',
    },
    databeBox: {
        margin: 10,
        textAlign: 'center',
    },
    databeHeader: {
        margin: 10,
        textAlign: 'center',
    },
    dropdown: {
        height: 50,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
    containerstyle: {
        width: 150
    },
});

export default TransactionDetails;