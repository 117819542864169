import * as React from 'react';
import { Avatar, Button, Colors, HelperText, ProgressBar, Surface, Text, TextInput } from 'react-native-paper';
import { material } from 'react-native-typography';
import { Linking, View, Dimensions, ScrollView, ActivityIndicator, ImageBackground } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Modal from "react-native-modal";
import { setPasswordAction, tokenVarivicationAction } from '../../state/actions/authActions';
import getEnvVars from '../../../environment';
import isEmpty from '../../state/validations/is-empty';
import Header from './components/header';
const { BASEURL } = getEnvVars();

function Copyright() {
    return (

        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Text onPress={() => {
                Linking.openURL("https://www.easystepin.com/").catch((err) =>
                    console.error('An error occurred', err),
                );
            }} style={[material.caption, {}]}>{'Copyright © '}
                EasyStepIn
                {' 2016 - '}
                {new Date().getFullYear()}
                {'.'}
            </Text>
        </View>

    );
}


const SignUPPassword = (props: any) => {
    const [isSubmited, setIsSubmited] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const dispatch = useDispatch();
    const [signupInit, setSignupInit] = React.useState({
        password: '',
        cpassword: ''
    });

    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const [errorMessage, setErrorMessage] = React.useState('');
    const [modelMessage, setModelMessage] = React.useState('');
    const setPasswordCallResponse = (status: any, errorMessage: any, response: any) => {
        setErrorMessage(errorMessage);
        setIsSubmited(false);
        if (!status) {
            setSeconds(3);
        } else {
            setModelMessage(errorMessage);
            setTokenRequestStatusError(true);
        }
    }

    // password token realted one
    const [tokenRequestStatus, setTokenRequestStatus] = React.useState(false);
    const [tokenRequestStatusError, setTokenRequestStatusError] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    React.useEffect(() => {
        if (isEmpty(props?.route?.params?.token)) {
            setModelMessage(`Unauthorised url access, please try with valid url.`);
            setTokenRequestStatusError(true);
        } else {
            setTokenRequestStatus(true);
            setModelMessage(`Your password generated successfully. Please login to your partner account`);
            dispatch(tokenVarivicationAction({ Token: props?.route?.params?.token }, setTokenCallResponse));
        }

    }, [props?.route?.params?.token]);

    const setTokenCallResponse = (status: any, errorMessage: any) => {
        setTokenRequestStatus(false);
        if (status) {
        } else {
            setModelMessage(errorMessage);
            setTokenRequestStatusError(true);
        }
    }


    return (
        <Surface style={{
            flex: 1
        }} >
            <Header navigation={props.navigation} />
            {tokenRequestStatus ? <ActivityIndicator color='#27b6cc' /> :
                <ScrollView keyboardShouldPersistTaps='always' style={{

                }} showsVerticalScrollIndicator={false} >
                    <View style={{ flexDirection: "row", flexWrap: "wrap", flex: 1 }}>
                        <View style={{
                            flex: dimensions.width <= 400 ? 0 : dimensions.width <= 600 ? 1 : dimensions.width <= 800 ? 3 : 4
                        }}>
                            <ImageBackground style={{ height: "100%", width: "100%" }} source={require('../../assets/images/img/loginBg.png')} resizeMode="cover" >
                            </ImageBackground>
                        </View>
                        <View style={{ padding: 30, flex: dimensions.width <= 400 ? 4 : dimensions.width <= 600 ? 180 : 2 }}>
                            <View style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: 20
                            }}>
                                <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10 }} />
                                <Text style={material.headline}>Set Password</Text>

                                <View>
                                    <Formik
                                        initialValues={signupInit}

                                        validationSchema={Yup.object().shape({
                                            password: Yup.string()
                                                .max(255)
                                                .matches(/[a-z]+/, "At least one lowercase letter is required.")
                                                .matches(/[A-Z]+/, "At least one uppercase letter is required.")
                                                .matches(/[^\da-zA-Z]+/, "At least one special character is required.")
                                                .matches(/.*\d+/, "At least one number is required.")
                                                .min(8, "At least minimum 8 characters are required.")
                                                .required('Password is required.'),
                                            cpassword: Yup.string()
                                                .max(255).oneOf([Yup.ref('password'), null], 'Passwords must match.')
                                                .required('Confirm Password is required.'),

                                        })}

                                        onSubmit={(values: any, { setSubmitting }: { setSubmitting: any, setErrors: any }) => {
                                            setSubmitting(false);
                                            setSignupInit(values);
                                            setErrorMessage("");
                                            dispatch(setPasswordAction({
                                                New_Password: values.password,
                                                Password_Token: props?.route?.params?.token
                                            }, setPasswordCallResponse));
                                            setIsSubmited(true);

                                        }}
                                    >
                                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any, setFieldValue: any }) => (
                                            <View style={{
                                                flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                                justifyContent: 'center', marginBottom: "40%"
                                            }}>
                                                <View style={{ width: "80%", margin: "auto" }}>
                                                    <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                                        style={{ width: "100%" }}
                                                        label="Password"
                                                        mode='outlined'
                                                        onChangeText={handleChange('password')}
                                                        onBlur={handleBlur('password')}
                                                        value={values.password}
                                                        secureTextEntry={!show}
                                                        right={<TextInput.Icon onPress={() => setShow(!show)} name={show ? "eye" : "eye-off"} />}
                                                        error={Boolean(touched.password && errors.password)}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.password && errors.password)}>
                                                        {touched.password && errors.password}
                                                    </HelperText>
                                                </View>
                                                <View style={{ width: "80%", margin: "auto" }}>
                                                    <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                                        style={{ width: "100%" }}
                                                        label="Confirm Password"
                                                        mode='outlined'
                                                        onChangeText={handleChange('cpassword')}
                                                        onBlur={handleBlur('cpassword')}
                                                        value={values.cpassword}
                                                        secureTextEntry={true}
                                                        error={Boolean(touched.cpassword && errors.cpassword)}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.cpassword && errors.cpassword)}>
                                                        {touched.cpassword && errors.cpassword}
                                                    </HelperText>
                                                </View>


                                                <View style={{
                                                    width: "80%", margin: "auto", marginTop: -5, alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    {seconds > 0 && <><Text  >{errorMessage}</Text><ProgressBar style={{
                                                        height: 5,
                                                        width: 250,
                                                    }} progress={.30 * seconds} color={'red'} /></>}
                                                </View>
                                                <View style={{
                                                    width: "80%", margin: "auto", marginTop: -5, alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    {isSubmited ? <ActivityIndicator color='#27b6cc' /> :
                                                        <Button style={{ marginTop: 15, backgroundColor: '#27b6cc' }} disabled={isSubmited} mode="contained" onPress={handleSubmit}>
                                                            Submit
                                                        </Button>}
                                                </View>
                                            </View>
                                        )}
                                    </Formik>

                                </View>
                            </View>
                        </View>
                    </View>
                </ScrollView>}
            <Copyright />
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={tokenRequestStatusError}>
                <View style={{ flex: 1, flexDirection: "column", padding: 15, maxWidth: dimensions.width - 100, minWidth: 300, minHeight: 100, maxHeight: 250, borderRadius: 10, backgroundColor: "#ffffff"  }}>
                    <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10, alignSelf: "center", borderColor: '#ffffff' }} />
                    <Text style={{ alignSelf: "center" }} >Congratulations, Password successfully created. Please Login. </Text>

                    <Button style={{ marginTop: 15, maxWidth: 150, backgroundColor: '#27b6cc', alignSelf: "center" }} mode="contained" onPress={() => {
                        setTokenRequestStatusError(false);
                        props.navigation.navigate("SignIn");
                    }}>
                        Okay
                    </Button>
                </View>
            </Modal>
        </Surface>
    );
};

export default SignUPPassword;
