import { Dimensions, Image, Platform, ScrollView, StyleSheet, View } from "react-native";
import { ActivityIndicator, Avatar, Button, Card, Colors, HelperText, IconButton, RadioButton, Surface, Text, TextInput, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Header from '../auth/components/header';
import { Formik } from "formik";
import Modal from "react-native-modal";
import RenderHTML from "react-native-render-html";
import ImageCarousel from "../../components/custom/image-carousel";
import { addbiddingProductReviewAction, biddingPaymentAction, getAllBiddingAddressAction, getPartnerQuoteDetails, getProductsImageAction, getbiddingUserReviewsbyIdAction } from "../../state/actions/bidding/biddingproductActions";
import ESIbiddingCommentModel from "./biddingcomments";
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";
import esiColor from "../../constants/Colors";
import useRazorpay from "react-razorpay";
import razorpayPay from "../../hooks/razorpay";
import isEmpty from "../../state/validations/is-empty";
import { getWalletByUser, getWalletHistory } from "../../state/actions/walletAction";
import { TouchableOpacity } from "react-native";
import { useToast } from "../../components/custom/react-native-paper-toast/src";
import { Rating } from "react-native-ratings";
export default function PartnerQuotedetails(props: any) {
  const dispatch = useDispatch();
  const ID = useSelector(state => state.auth.user.Partner_Details_Id)
  const esiColor = useSelector(state => state.theme);

  const { navigation } = props;
  const toast = useToast();
  const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
  const Product_Id = props?.route?.params?.quoteid;
  const Bidding_product = props?.route?.params?.Bidding_product;
  const b2bbidding = props?.route?.params?.b2bbidding;
  const [product, setProduct] = useState({});
  const dimensions = Dimensions.get('window');
  const { height, width } = Dimensions.get('window');
  const [productLoading, setProductLoading] = useState(false);
  const [walletAmout, setWalletAmount] = useState(false);
  const [address, setAddress] = React.useState([]);
  const [errormessage, setErrorMessage] = React.useState('');
  const [addCard, setAddCard] = React.useState([]);
  let productImage = useSelector((state) => state.comment.biddingproductImages.all);
  const [formikSubmitType, setFormikSubmitType] = React.useState(0);
  const [addresserror, setAdsdresssError] = React.useState('');
  const [paymenterror, setPaymentError] = React.useState('');
  const [loadingForm, setloadingForm] = React.useState(false);
  const [formResults, setFormResults] = React.useState(false);
  const [resetForm, setResetForm] = React.useState(false);
  const user = useSelector((state) => state.auth.user);
  let RazorPay = null;
  if (Platform.OS === "web") {
    RazorPay = useRazorpay();
  }
  let addressopt = []
  if (address) {
    if (address[0]) {
      for (let i = 0; i < address.length; i++) {
        addressopt.push(
          {
            label: address[i].Address_Type + " " + "," + " " + address[i].Door_No + " " + "," + " " + address[i].Land_Mark + " " + "," + " " + address[i].Street + " " + "," + " " + address[i].City + " " + "," + " " + address[i].State + " " + "," + " " + address[i].Zip,
            value: address[i].Partner_Address_Id
          })
      }
    }
  }

  const updateStates = (data: any, selectaddress: any) => {
    setAddress(data);
    setAddCard(data)
  };
  const getAddress = () => {

    let data = {
      Partner_Details_Id: ID,
      Records_Filter: "FILTER",
    };

    dispatch(getAllBiddingAddressAction(updateStates, data));
  }
  const callbackFunction = (data, response) => {
    if (!data) {
      setPaymentError(response)
    } else {
      setModalVisible(!isModalVisible);
      setPaymentError('')
    }
  }
  const addAdresspop = (data: any) => {
    if (logedInEmail) {
      getAddress(data);
      setModalVisible((prop) => !prop);
    } else {
      setModalVisible(!isModalVisible);
    }
  }
  const [reload, setReload] = useState(false);
  const [Reviews, setReview] = useState([]);
  const [commenterror, setCommentError] = useState("")
  const [partnercommenterror, setPartnerCommentError] = useState("")
  const [ratingerror, setRatingError] = useState("")
  const [partnerratingerror, setPartnerRatingError] = useState("")
  const [error, setError] = useState("")
  const [comment, setComment] = useState("");
  const [partnercomment, setPartnerComment] = useState("");

  const [selectaddress, setSelectAddress] = useState("");
  const [showDropDown, setShowDropDown] = React.useState('');
  const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const callBackreviewGetData = (data) => {
    setReload(false);
    if (data) {
      setReview(data);
    }
  };

  const [rating, setRating] = useState(0);
  const [partnerrating, setPartnerRating] = useState(0);
  const ratingCompleted = (rating) => {
    setRating(rating);
  }
  React.useEffect(() => {
    if (product) {
      let formdata = {
        "B2B_Partner_Bidding_Quote_Id": product[0]?.B2B_Partner_Bidding_Quote_Id,
        "Records_Filter": "FILTER",
        "Partner_Detail_Id": "",
        "B2B_User_Products_Bidding_Id": ""
      }
      dispatch(getbiddingUserReviewsbyIdAction(formdata, callBackreviewGetData))
    }
  }, [product]);
  let existcomment;
  const addReviewModal = () => {
    if (!isEmpty(comment) && rating > 0) {
      setCommentError("")
      setReviewModalVisible(false)
      setPartnerCommentError("")
      setRatingError("")
      setPartnerRatingError("")
      let formData = {
        B2B_User_Products_Bidding_Id: product[0].B2B_User_Products_Bidding_Id,
        Modified_By: logedInEmail,
        Finalize_Partner_Bidding_Quote_Id: product[0].Finalize_Partner_Bidding_Quote_Id,
        Buyer_Partner_Detail_Id: ID,
        User_Review_For_Product: comment,
        User_Rating_For_Product: rating,
        User_Review_For_Partner: partnercomment,
        User_Rating_For_Partner: partnerrating,
        Total_Advance_Amount:product[0].Total_Advance_Amount,
        Partner_Balance_Amount:product[0].Partner_Balance_Amount
      }
      let formValues = JSON.parse(JSON.stringify(formData));
      dispatch(addbiddingProductReviewAction(formValues, product[0].B2B_User_Products_Bidding_Id, resetForm, setReload, callBackGetData));
    } else {
      if (isEmpty(comment)) {
        setPartnerCommentError("Partner Review is required.")
      }
      if (isEmpty(rating)) {
        setRatingError("Product Rating is required.")
      }
      if (isEmpty(comment)) {
        setCommentError("Product Review is required.")
      }
      if (isEmpty(rating)) {
        setPartnerRatingError("Partner Rating is required.")
      }
    }

  }
  const handleOnChange = (value) => {
    if (existcomment > 0) {
      setError("You have already added review.")
    }

    if (value === "") {
      setError("")
    }
  }
  const toggleModal = () => {
    if (isAuthenticated) {
      setReviewModalVisible(!isReviewModalVisible);
    }
    else {
      setModalVisible(true);
    }
  };


  let image = []
  if (productImage) {
    if (productImage[0]) {
      if (productImage[0].Product_Image) {
        let data = productImage[0].Product_Image
        for (var i = 0; i < data.length; i++) {
          image.push({ imageuri: data[i].Product_Image })
        }
      }
    }
  }
  const callBackGetData = (data) => {
    if (data) {
      setProduct(data);
    }
  };
  const [load, setLoad] = useState('')
  const [wallet, setWallet] = React.useState([]);
  const updateStatesWallet = (data: any, loading: any) => {
    setWallet(JSON.parse(JSON.stringify(data)))
    // setWallet(data);
  };
  const [walletFinalAmount, setwalletFinalAmount] = React.useState(false);

  React.useEffect(() => {
    if (wallet?.Remaining_Balance) {
      if (Number(wallet?.Remaining_Balance) > (product[0]?.Advance_Payment_Status_From_User == 0 ?
        Number(product[0]?.Total_Advance_Amount) - Number(wallet?.Remaining_Balance)
        : Number(product[0]?.Remaining_Amount) - Number(Number(wallet?.Remaining_Balance)))
      ) {
        setwalletFinalAmount(true)
      }
    }
  }, [wallet?.Remaining_Balance, product]);

  const updateaddressStates = (data: any, loading: any) => {
    setAddCard(data)
  };
  // "Cancelled_Reason": "string",
  // Bidding_Status
  React.useEffect(() => {
    if (Product_Id != load) {
      setProductLoading(true);
      dispatch(getPartnerQuoteDetails(Product_Id, callBackGetData, b2bbidding));
      dispatch(getProductsImageAction(Bidding_product));
      dispatch(getAllBiddingAddressAction(updateaddressStates, { Partner_Details_Id: ID, Records_Filter: "FILTER" }));
      if (!isEmpty(ID)) {
        let data = {
          "Partner_Id": ID,
        };
        let formData = {
          "Wallet_Owner": ID,
          "Records_Filter": "FILTER",
          "Wallet_Id": ""
        };
        dispatch(getWalletByUser(updateStatesWallet, data));
        dispatch(getWalletHistory(formData))
      }
    }
  }, [Product_Id, load]);
  useEffect(() => {
    if (!isEmpty(ID)) {
      let data = {
        "Wallet_Owner": ID,
        "Records_Filter": "FILTER",
        "Wallet_Id": ""
      };
      dispatch(getWalletByUser(updateStatesWallet, data));
      let formData = {
        "Wallet_Owner_Id": ID,
      };
      dispatch(getWalletHistory(formData))
    }
  }, [ID]);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [isModalVisible, setModalVisible] = React.useState(false);
  const placeNewOrderwithwalletandOnline = (type: any, transactionId: any, amount: any) => {
    let formData = {
      Advance_Online_Amount: product[0]?.Advance_Payment_Status_From_User == 0 && Number(product[0].Total_Advance_Amount) ? amount : 0 + "",
      Advance_Online_Transaction_Id: transactionId,
      Advance_Wallet_Amount: product[0]?.Advance_Payment_Status_From_User == 0 && Number(wallet?.Remaining_Balance) ? amount : 0,
      Finalize_Partner_Bidding_Quote_Id: product[0]?.B2B_Partner_Bidding_Quote_Id,
      Modified_By: logedInEmail,
      Buyer_Partner_Detail_Id: ID,
      Total_Advance_Amount: product[0]?.Advance_Payment_Status_From_User == 0 && Number(product[0].Total_Advance_Amount) && Number(product[0].Total_Advance_Amount)+" ",
      Wallet_Amount: product[0]?.Advance_Payment_Status_From_User == 1 && Number(wallet?.Remaining_Balance) ? amount : 0+" ",
      Partner_Balance_Amount: product[0]?.Advance_Payment_Status_From_User == 1 && Number(wallet?.Remaining_Balance) ? amount : 0 + "",
      B2B_User_Products_Bidding_Id: product[0]?.B2B_User_Products_Bidding_Id,
      // Is_Partner_Advance_Amount_Settled: product[0]?.Advance_Payment_Status_From_User == 0 ? 0 : 1,
      Partner_Address_Id: selectaddress
    };
    setOrderPlaced(true);
    setPaymentError('')
    dispatch(biddingPaymentAction(formData, callbackFunction, toast));
  };
  const placeNewOrder = (type: any, transactionId: any, amount: any) => {
    let formData = {
      Advance_Online_Amount: product[0]?.Advance_Payment_Status_From_User == 0 ? amount : "" + "",
      Advance_Online_Transaction_Id: product[0]?.Advance_Payment_Status_From_User == 0 ? transactionId : '',
      Advance_Wallet_Amount: "",
      Finalize_Partner_Bidding_Quote_Id: product[0]?.B2B_Partner_Bidding_Quote_Id,
      Modified_By: logedInEmail,
      Total_Advance_Amount: amount,
      Partner_Balance_Amount: product[0]?.Advance_Payment_Status_From_User == 1 && wallet?.Remaining_Balance ? amount : 0 + "",
      B2B_User_Products_Bidding_Id: product[0]?.B2B_User_Products_Bidding_Id,
      // Is_Partner_Advance_Amount_Settled: product[0]?.Advance_Payment_Status_From_User == 0 ? 0 : 1,
      Partner_Address_Id: selectaddress,
      Online_Transaction_Id: product[0]?.Advance_Payment_Status_From_User == 1 ? transactionId : ''
    };
    setOrderPlaced(true);
    setPaymentError('')
    // toast.show({ message: 'Product Updated Successfully.', type: 'info', duration: 3000, position: 'top' });

    dispatch(biddingPaymentAction(formData, callbackFunction, toast));
  };
  const placeNewOrderwithwallet = (type: any, transactionId: any, amount: any) => {
    let formData = {
      Advance_Online_Amount: product[0]?.Advance_Payment_Status_From_User == 0 ? amount : 0,
      Advance_Online_Transaction_Id: transactionId,
      Advance_Wallet_Amount: product[0]?.Advance_Payment_Status_From_User == 0 ? Number(product[0].Total_Advance_Amount) : Number(product[0].Remaining_Amount) ? amount : 0 ,
      Finalize_Partner_Bidding_Quote_Id: product[0]?.B2B_Partner_Bidding_Quote_Id,
      Modified_By: logedInEmail,
      Buyer_Partner_Detail_Id: ID,
      Total_Advance_Amount: product[0]?.Advance_Payment_Status_From_User == 0 ? Number(product[0].Total_Advance_Amount) : Number(product[0].Remaining_Amount) && Number(product[0].Total_Advance_Amount),
      Wallet_Amount: product[0]?.Advance_Payment_Status_From_User == 1 ? Number(product[0].Remaining_Amount) : Number(product[0].Remaining_Amount) ? amount : 0,
      Partner_Balance_Amount: product[0]?.Advance_Payment_Status_From_User == 1 ? Number(product[0]?.Remaining_Amount) : Number(product[0].Remaining_Amount) ? amount : 0 + "",
      B2B_User_Products_Bidding_Id: product[0]?.B2B_User_Products_Bidding_Id,
      // Is_Partner_Advance_Amount_Settled: product[0]?.Advance_Payment_Status_From_User == 0 ? 0 : 1,
      Partner_Address_Id: selectaddress
    };
    setOrderPlaced(true);
    setPaymentError('')
    dispatch(biddingPaymentAction(formData, callbackFunction, toast));
  };
  const payNow = () => {
    addAdresspop(false)
    let amount = product[0]?.Advance_Payment_Status_From_User == 0 ? Number(product[0].Total_Advance_Amount) : Number(product[0].Remaining_Amount)
    if (amount > 0) {
      razorpayPay(RazorPay, { user: user, amount: amount }, (status: any, response: any) => {
        if (status) {
          placeNewOrder("razorpay", response.razorpay_payment_id, product[0]?.Advance_Payment_Status_From_User == 0 ? Number(product[0].Total_Advance_Amount) : Number(product[0].Remaining_Amount));
        }
      });
    }
  }
  const payNowwithwallet = () => {
    addAdresspop(false)
    let amount = product[0]?.Advance_Payment_Status_From_User == 0 ? Number(product[0].Total_Advance_Amount) - Number(wallet?.Remaining_Balance) : Number(product[0].Remaining_Amount) - Number(Number(wallet?.Remaining_Balance))
    if (amount > 0) {
      razorpayPay(RazorPay, { user: user, amount: amount }, (status: any, response: any) => {
        if (status) {
          placeNewOrderwithwalletandOnline("razorpay", response.razorpay_payment_id, product[0]?.Advance_Payment_Status_From_User == 0 ? Number(product[0].Total_Advance_Amount) - Number(wallet?.Remaining_Balance) : Number(product[0].Remaining_Amount) - Number(wallet?.Remaining_Balance));
        }
      });
    }
  }
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    if (seconds === 2) {
      const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
      return () => clearTimeout(timer)
    }
  }, [seconds]);
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} />
      <View>
        <IconButton
          icon="arrow-left"
          size={27}
          color={"#27B6CC"}
          style={{ marginTop: -2, marginLeft: 1 }}
          onPress={() => { navigation.navigate('BidProductDetails'), setLoad(Product_Id) }}
        />
      </View>
      {product ?
        <ScrollView style={{ maxHeight: dimensions.height - 20 }}>

          <View style={{ justifyContent: 'center', marginLeft: 20 }}>
            <ImageCarousel image={image} dimensions={dimensions} />
            <Text style={{ fontSize: 15, color: esiColor.brandFontColor, fontWeight: 'bold', marginLeft: 50, marginTop: 15, textAlign: 'center', }}>{product[0]?.Product_Name}</Text>
          </View>
          <View style={{ marginLeft: 10 }}>
            <View style={{ flexDirection: "row", paddingTop: 15 }}>
              <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Status :</Text></View>
              <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Bidding_Status} </Text></View>
            </View>
            <View style={{ flexDirection: "row", paddingTop: 10 }}>
              <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Quantity :</Text></View>
              <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Quantity}   {product[0]?.Measurement_Type}</Text>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Pincode :</Text></View>
              <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.User_Pincode} </Text></View>
            </View>

            {product[0]?.Advance_Payment_Status_From_User == 0 &&
              <View style={{ flexDirection: "row", paddingTop: 10 }}>
                <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Advance Amount :</Text></View>
                <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Number(product[0]?.Total_Advance_Amount)} </Text></View>
              </View>
            }
            {product[0]?.Advance_Payment_Status_From_User == 1 &&
              <View>
                <View style={{ flexDirection: "row", paddingTop: 10 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Advance Amount : </Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Total_Advance_Amount} </Text></View>
                </View>
                {
                  product[0]?.Advance_Online_Transaction_Id == '0' || product[0]?.Advance_Online_Transaction_Id == '' || product[0]?.Advance_Online_Transaction_Id == undefined ? null :
                    <View style={{ flexDirection: "row", paddingTop: 10 }}>
                      <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Advance Transaction ID :  </Text></View>
                      <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Advance_Online_Transaction_Id} </Text></View>
                    </View>}
                {
                  product[0]?.Advance_Online_Amount == '0' || product[0]?.Advance_Online_Amount == '' || product[0]?.Advance_Online_Amount == undefined ? null :
                    <View style={{ flexDirection: "row", paddingTop: 10 }}>
                      <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Advance Online Payment :  </Text></View>
                      <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Advance_Online_Amount} </Text></View>
                    </View>}
              </View>
            }
            {product[0]?.Advance_Payment_Status_From_User == 1 ?
              <View style={{ flexDirection: "row", paddingTop: 10 }}>
                <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Remaining Amount :</Text></View>
                <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Remaining_Amount} </Text></View>
              </View>:null}
            <View>
              {product[0]?.Final_Payment_Status_From_User == 1 &&
                <View style={{ flexDirection: "row", paddingTop: 10 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Final Online Payment Amount : </Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Remaining_Amount} </Text></View>
                </View>}

              {
                product[0]?.Advance_Online_Transaction_Id == '0' || product[0]?.Advance_Online_Transaction_Id == '' || product[0]?.Advance_Online_Transaction_Id == undefined ? null :
                  <View style={{ flexDirection: "row", paddingTop: 10 }}>
                    <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.DescColor }}>Online Transaction ID :  </Text></View>
                    <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Online_Transaction_Id} </Text></View>
                  </View>}
            </View>

            {product[0]?.Final_Payment_Status_From_User == 0 || product[0]?.Advance_Payment_Status_From_User == 0 ?
              <View style={{ flexDirection: "row", justifyContent: "center", marginBottom: 5 }}>
                <Button uppercase={false} mode="outlined" color={esiColor.actionColor} onPress={() => {
                  addAdresspop(address);
                }}>
                  Pay
                </Button>
              </View> : null}
            {product[0]?.Advance_Payment_Status_From_User == 0 &&
              <View style={{ flexDirection: "row", paddingTop: 10, justifyContent: 'center' }}>
                <View><Text style={{ fontWeight: "bold", fontSize: 13, color: '#27b6cc' }}>Pay Advance Amount</Text></View>
              </View>}
            {product[0]?.Advance_Payment_Status_From_User == 1 && product[0]?.Final_Payment_Status_From_User == 0 ?
              <View style={{ flexDirection: "row", paddingTop: 10, justifyContent: 'center' }}>
                <View><Text style={{ fontWeight: "bold", fontSize: 13, color: '#27b6cc' }}>Pay Remaining Amount</Text></View>
              </View> : null}
          </View>
          <View style={{ alignSelf: 'center', width: '100%' }}>
            <Text style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'center', fontFamily: 'system-ui', color: esiColor.DescColor }}>Description </Text>
          </View>
          <View>
            <Text style={{ color: esiColor.itemColor }} >{product[0]?.Description}</Text>
          </View>

          <View>
            {product[0]?.Advance_Payment_Status_From_User == 1 &&

              <View style={{
                shadowColor: esiColor.brandShadowColor,
                // shadowOffset: { width: -2, height: 4 },
                shadowOpacity: 5,
                shadowRadius: 5,
                marginHorizontal: 2, borderRadius: 10, padding: 2, backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                alignItems: 'flex-start',
                // elevation: 10,
                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
                maxHeight: height > 250 ? 250 : 90,
                minHeight: 100,
                // borderColor: 'grey',
                borderWidth: 0.5,
              }}>

                <View style={{ flex: 2, flexDirection: 'column' }}  >
                  <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.itemColor }}>Delivery Address</Text>
                  <Text style={{ color: esiColor.itemColor }}>Delivery Address: {product[0]?.Buyer_Partner_Location}</Text>
                  {/* <Text style={{ color: esiColor.itemColor }}>D.NO : {addCard[0]?.Door_No}{addCard[0]?.Location}</Text>
                  <Text style={{ color: esiColor.itemColor }}>Mobile Number : {addCard[0]?.Mobile_Number}</Text> */}

                </View>
              </View>}

            <View style={{
              shadowColor: esiColor.brandShadowColor,
              // shadowOffset: { width: -2, height: 4 },
              shadowOpacity: 5,
              shadowRadius: 5,
              marginHorizontal: 2, borderRadius: 10, padding: 2, backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
              alignItems: 'flex-start',
              // elevation: 10,
              maxWidth: (Platform.OS === 'web' ? 650 : 550),
              minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
              maxHeight: height > 250 ? 250 : 90,
              minHeight: 100,
              // borderColor: 'grey',
              borderWidth: 0.5,
            }}>
              <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                <Image source={{ cache: "force-cache", uri: product[0]?.Product_Image }} style={{ height: 100, width: 80, resizeMode: 'contain' }}></Image>
              </View>
              <View style={{ flex: 2, flexDirection: 'column' }}  >
                <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{product[0]?.Partner_Name}</Text>
                <Text style={{ color: esiColor.itemColor }}>Quotation Amount : {product[0]?.Total_Amount}</Text>
                <Text style={{ color: esiColor.itemColor }}>Delivery Days : {product[0]?.No_Of_Delivery_Days}</Text>
                <Text style={{ color: esiColor.itemColor }}>Location: {product[0]?.Partner_Location}</Text>
              </View>
            </View>
            <View>
              <Text style={{ fontSize: 12 }} >{product[0]?.User_Review_For_Partner}</Text>
            </View>
            <View style={{ alignSelf: 'center', width: '100%' }}>
              <Text style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'center', fontFamily: 'system-ui', color: esiColor.itemColor }}>Comments </Text>
            </View>

            <Card.Content>
              <ESIbiddingCommentModel navigation={navigation} data={Product_Id} />
            </Card.Content>
            <View style={{ marginBottom: 100 }} ></View>
          </View>
          {
            product[0]?.Bidding_Status ? product[0]?.Bidding_Status == "COMPLETED" ?
              <View style={{ alignSelf: 'center', width: '100%' }}>
                <Text style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'center', fontFamily: 'system-ui', color: esiColor.itemColor }}>Reviews </Text>
              </View> : null : null
          }
          {product[0]?.Bidding_Status == "COMPLETED" ?
            <ScrollView >
              {(Reviews?.length > 0) && (Reviews?.filter((review) => {
                return (review?.Buyer_Partner_Detail_Id === ID && review?.User_Rating_For_Product)
              }).length) > 0 && Reviews?.map((data: any, index: any) => (
                <View key={index} style={{ flexDirection: "row", marginTop: 15 }}>
                  <Image source={{ uri: data?.Logo_Path }} style={{
                    width: 30,
                    height: 30,
                    // borderRadius: '50%',
                    shadowRadius: 5,
                    marginRight: 10
                  }} />
                  <View style={{ flexDirection: 'column', marginLeft: 10, backgroundColor: 'gray' }}>
                    <Text rkType='primary3 mediumLine'>{data.User_Review_For_Product &&
                      <RenderHTML
                        source={{ html: data.User_Review_For_Product }}
                      />}</Text>
                    <Text rkType='primary3 mediumLine'>{data.User_Review_For_Partner &&
                      <RenderHTML
                        source={{ html: data.User_Review_For_Partner }}
                      />}</Text>
                  </View>
                  <View style={{ marginRight: 20, flex: 1, alignItems: 'flex-end' }}>
                    <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                      <Rating
                        readonly={true}
                        type='heart'
                        // ratingBackgroundColor='#c8c7c8'
                        tintColor={esiColor.TintColor}
                        startingValue={data?.User_Rating_For_Product}
                        ratingCount={5}
                        imageSize={20}
                        onFinishRating={(rating) => ratingCompleted(rating)}
                        style={{ paddingVertical: 10 }}
                      />
                    </View>
                    <View style={{ marginRight: 10, flex: 1, alignItems: 'flex-end' }}>
                      <Rating
                        readonly={true}
                        type='heart'
                        // ratingBackgroundColor='#c8c7c8'
                        tintColor={esiColor.TintColor}
                        startingValue={data?.User_Rating_For_Partner}
                        ratingCount={5}
                        imageSize={20}
                        onFinishRating={(rating) => ratingCompleted(rating)}
                        style={{ paddingVertical: 10 }}
                      />
                    </View>
                  </View>
                  <View style={{ paddingBottom: 5 }} />
                </View>
              ))}
            </ScrollView> : null}
          {product[0]?.Bidding_Status == "COMPLETED" ?
            <View style={{ flexDirection: 'column', flex: 1, alignSelf: 'flex-end', margin: 10 }}>
              <TouchableOpacity disabled={((Reviews.length > 0) && (Reviews?.filter((review) => {
                return review?.Buyer_Partner_Detail_Id === ID && review?.User_Rating_For_Product
              }).length) > 0) ? true : false} style={{
                backgroundColor: ((Reviews.length > 0) && (Reviews?.filter((review) => {
                  return review?.Buyer_Partner_Detail_Id === ID && review?.User_Rating_For_Product
                }).length) > 0) ? '#F0F0F0' : esiColor.globalButtonColor, width: 110, height: 30, borderRadius: 5, justifyContent: 'center', alignItems: 'center'
              }} onPress={() => toggleModal()}>
                <Text style={{ color: esiColor.itemButtenColor }}>ADD REVIEW</Text>
              </TouchableOpacity>
            </View> : null}
          {product[0]?.Bidding_Status == "COMPLETED" ?
            <Modal animationIn={"slideInDown"}
              deviceWidth={dimensions.width}
              deviceHeight={dimensions.height}
              style={{ alignItems: 'center' }} isVisible={isReviewModalVisible}>
              <View style={{
                flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 450, borderRadius: 10,
                backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7
              }}>
                <View style={{
                  alignItems: 'flex-end', marginTop: '-6%'
                }}>
                  <TouchableRipple style={{ width: 36 }} onPress={() => { setReviewModalVisible(false), setComment(''), setRating(0), setPartnerComment(''), setPartnerRating(0) }}>
                    <Avatar.Icon color={esiColor.Text} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                  </TouchableRipple>
                </View>
                <View style={{ margin: '4%' }}>
                  <TextInput
                    label='Add Product Review'
                    mode='outlined'
                    onChangeText={(Text) => { setComment(Text); handleOnChange(Text); setCommentError("") }}
                    multiline={true}
                    numberOfLines={2}
                    value={Reviews?.User_Review_For_Product}
                    selectionColor={esiColor.TextSelection}
                    theme={{
                      colors: {
                        primary: esiColor.TextHighlight,
                        text: esiColor.Text,
                        placeholder: esiColor.TextPlaceholder
                      }
                    }}
                    outlineColor={esiColor.TextOuterLine}
                    underlineColor={esiColor.TextUnderline}
                    activeOutlineColor={esiColor.TextOuterLine}
                  />
                  <Text style={{ color: 'red' }}>{commenterror}</Text>
                  <Text style={{ color: 'red' }}>{error}</Text>
                  <TextInput
                    label='Add Partner Review'
                    mode='outlined'
                    onChangeText={(Text) => { setPartnerComment(Text); handleOnChange(Text); setPartnerCommentError("") }}
                    multiline={true}
                    numberOfLines={2}
                    value={Reviews?.User_Review_For_Partner}
                    selectionColor={esiColor.TextSelection}
                    theme={{
                      colors: {
                        primary: esiColor.TextHighlight,
                        text: esiColor.Text,
                        placeholder: esiColor.TextPlaceholder
                      }
                    }}
                    outlineColor={esiColor.TextOuterLine}
                    underlineColor={esiColor.TextUnderline}
                    activeOutlineColor={esiColor.TextOuterLine}
                  />
                  <Text style={{ color: 'red' }}>{partnercommenterror}</Text>
                  <Text style={{ color: 'red' }}>{error}</Text>
                  <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}>
                    <Rating
                      type='heart'
                      // ratingBackgroundColor='#c8c7c8'
                      tintColor={esiColor.TintColor}
                      startingValue={rating}
                      ratingCount={5}
                      imageSize={30}
                      style={{ paddingVertical: 10 }}
                      onFinishRating={(value) => { setRating(value); setRatingError("") }}
                    />
                    {!rating || rating === 0 ? (
                      <Text style={{ color: "red" }}>
                        Please select the Rating for product.
                      </Text>
                    ) : (null)
                    }
                    <Rating
                      type='heart'
                      // ratingBackgroundColor='#c8c7c8'
                      tintColor={esiColor.TintColor}
                      startingValue={partnerrating}
                      ratingCount={5}
                      imageSize={30}
                      style={{ paddingVertical: 10 }}
                      onFinishRating={(value) => { setPartnerRating(value); setPartnerRatingError("") }}
                    />
                    {!partnerrating || partnerrating === 0 ? (
                      <Text style={{ color: "red" }}>
                        Please select the Rating for partner.
                      </Text>
                    ) : (null)
                    }

                    <View style={{ width: 100 }}>
                      <Button style={{ alignItems: 'center', marginTop: 10, backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => addReviewModal()}
                      >
                        <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                      </Button>
                    </View>
                  </View>

                </View>

              </View>
            </Modal> : null}
        </ScrollView> :
        <ActivityIndicator color='#27b6cc' />
      }
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={isModalVisible}>
        <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 100, maxHeight: 200, borderRadius: 10, backgroundColor: "white" }}>
          <Surface style={{
            justifyContent: 'center',
            borderRadius: 10,
            top: Platform.OS === 'web' ? 10 : 20,
            elevation: 0
          }} >
            <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
              if (formikSubmitType == 0) {
                setModalVisible(false)
                addAdresspop(false)
                addAdresspop((prop) => !prop);
              } else {
                setModalVisible(!isModalVisible);
                addAdresspop(false)
              }
            }}>
              <Avatar.Icon color={"block"} style={{ backgroundColor: "white" }} size={36} icon="close" />
            </TouchableRipple>
            <Formik>
              {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                <View style={{
                  flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                }}>
                  {loadingForm ?
                    <ActivityIndicator color={esiColor.esiMainColor} />
                    :
                    formResults ?
                      <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                        <Image source={require('../../assets/images/GIF/ordergif.gif')}
                          style={{ height: 300, width: '100%', resizeMode: 'contain' }}></Image>
                        <View style={{ width: "90%", margin: "auto" }}>
                          <Text style={{ textAlign: "center", fontWeight: "bold" }} >
                            {formikSubmitType == 0 ? " " : ""}
                          </Text>
                        </View>
                      </View>
                      :
                      <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                        {product[0]?.Advance_Payment_Status_From_User == 0 &&
                          <View>
                            <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>
                              <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                containerStyle={style.containerstyle}
                                data={addressopt}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'Select Address' : '...'}
                                maxHeight={addressopt.length > 1 ? 250 : 200}
                                search={addressopt.length > 1 ? true : false}
                                searchPlaceholder={'Select Address'}
                                value={selectaddress}
                                onUpdateValue={item => {
                                  setSelectAddress(item.value)
                                  setShowDropDown(false);
                                }}
                              />
                            </View>
                            {walletAmout &&
                              <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>
                                <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                  label='Advance Amount'
                                  mode='outlined'
                                  name="Advance_Wallet_Amount"
                                  value={product[0]?.Advance_Payment_Status_From_User == 0 ? Number(product[0].Total_Advance_Amount) +" ": Number(product[0].Remaining_Amount)+" "}
                                  onChangeText={(text) => {
                                    // setFieldValue('Advance_Wallet_Amount', text);
                                    setErrorMessage('Please enter Amount');
                                  }
                                  }
                                  keyboardType="Numeric"
                                // error={Boolean(touched.Advance_Wallet_Amount && errors.Advance_Wallet_Amount)}
                                />
                                {/* <HelperText type="error" visible={Boolean(touched.Advance_Wallet_Amount && props.errors.Advance_Wallet_Amount)}>
                            <ErrorMessage name="Advance_Wallet_Amount" />
                          </HelperText> */}
                              </View>

                            }</View>


                        }
                        {product[0]?.Advance_Payment_Status_From_User == 1 &&
                          <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>
                            <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                              label='Remaining_Amount'
                              mode='outlined'
                              name="Remaining_Amount"
                              value={product[0]?.Advance_Payment_Status_From_User == 1 ? Number(product[0].Remaining_Amount)+" " : Number(walletFinalAmount?.Remaining_Amount)+" "}
                              onChangeText={(text) => {
                                // setFieldValue('Remaining_Amount', text);
                                setErrorMessage('Please enter Amount');
                              }
                              }
                              keyboardType="Numeric"
                            // error={Boolean(touched.Advance_Wallet_Amount && errors.Advance_Wallet_Amount)}
                            />
                            {/* <HelperText type="error" visible={Boolean(touched.Advance_Wallet_Amount && props.errors.Advance_Wallet_Amount)}>
                            <ErrorMessage name="Advance_Wallet_Amount" />
                          </HelperText> */}
                          </View>}
                        <Text style={{ textAlign: 'center', }}>
                          {isEmpty(selectaddress) &&
                            (<Text style={{ color: "red", justifyContent: 'center', textAlign: 'center', alignContent: 'center' }}>{addresserror}</Text>)}  </Text>
                        {wallet?.Remaining_Balance > 0 &&
                          <View style={{ flexDirection: "row", marginBottom: 5 }}>
                            {/* {walletAmout || */}
                            <View style={{
                              flexDirection: "row", alignSelf: 'flex-start'
                            }}>
                              <RadioButton
                                color="#27B6CC"
                                value={walletAmout}
                                status={walletAmout ? 'checked' : 'unchecked'}
                                onPress={() => setWalletAmount(!walletAmout)}
                              />
                              <Text style={{ marginTop: 10, color: '#black' }}>Use Wallet.</Text>
                            </View>

                            {/* } */}
                          </View>
                        }

                        <View>
                          <Text style={{ color: 'red' }}>
                            {paymenterror}
                          </Text>
                        </View>
                        {(walletAmout && !walletFinalAmount) && <View>
                          <Text style={{ color: 'red' }}>
                            You don't have sufficiant Ballence will dibit {wallet?.Remaining_Balance}{" "}from wallet and need to pay {product[0]?.Advance_Payment_Status_From_User == 0 ?
                              Number(product[0]?.Total_Advance_Amount) - Number(wallet?.Remaining_Balance)
                              : Number(product[0]?.Remaining_Amount) - Number(Number(wallet?.Remaining_Balance))}
                          </Text>
                          <Text style={{ color: 'blue' }}>

                          </Text>
                        </View>}
                        <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                          {!walletAmout ? <Button style={{ marginTop: 15, backgroundColor: '#27b6cc' }} uppercase={false} mode="contained" onPress={() => {
                            if (isEmpty(selectaddress) && (product[0]?.Advance_Payment_Status_From_User == '0')) {
                              setAdsdresssError('Delivery Address is required.')
                            } else {
                              payNow();
                            }
                          }}>
                            Pay amount
                          </Button>
                            : <View>
                              {/* {!walletFinalAmount&& <View>
                                   <Text style={{ color: 'blue' }}>
                                      You don't have sufficiant Ballence will dibit{wallet?.Remaining_Balance}from wallet
                                    </Text>
                                    <Text style={{ color: 'blue' }}>
                                      and need to pay{product[0]?.Advance_Payment_Status_From_User == 0 ?
                                        Number(product[0]?.Total_Advance_Amount) - Number(wallet?.Remaining_Balance)
                                        : Number(product[0]?.Remaining_Amount) - Number(Number(wallet?.Remaining_Balance))}
                                    </Text>
                                  </View>} */}
                              {walletFinalAmount ? <Button style={{ marginTop: 15, backgroundColor: '#27b6cc' }} uppercase={false} mode="contained" onPress={() => {
                                if (isEmpty(selectaddress) && (product[0]?.Advance_Payment_Status_From_User == '0')) {
                                  setAdsdresssError('Delivery Address is required.')
                                } else {
                                  placeNewOrderwithwallet()
                                }
                              }}>
                                Pay amount
                              </Button>
                                :
                                <Button style={{ marginTop: 15, backgroundColor: '#27b6cc' }} uppercase={false} mode="contained" onPress={() => {
                                  if (isEmpty(selectaddress) && (product[0]?.Advance_Payment_Status_From_User == '0')) {
                                    setAdsdresssError('Delivery Address is required.')
                                  } else {
                                    payNowwithwallet();
                                  }
                                }}>
                                  Pay amount
                                </Button>}
                            </View>
                          }
                        </View>
                      </View>

                  }
                </View>)}
            </Formik>
            <View>
            </View>
          </Surface>
        </View>
      </Modal>
    </Surface >
  )
}
const style = StyleSheet.create({

  dropdown: {
    height: 58,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  // containerstyle: {
  //   width: 150
  // }
});

