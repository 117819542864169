import React, { useState } from 'react';
import data from './data';
import fooddata from './fooddata';
import { ScrollView, StyleSheet, View } from 'react-native';
import ProductCard from './ProductCard';
import { Surface, Text, Button } from 'react-native-paper';
import Header from '../auth/components/header';
import { useSelector } from 'react-redux';

const styles = StyleSheet.create({
  productCard: {
    height: '100%'
  }
});

const PartnerProductMain = (props: any) => {
  const { navigation } = props;
  const esiColor = useSelector(state => state.theme);
  const [products] = useState(data);
  const [foodproducts] = useState(fooddata);
  const EmailID = useSelector(state => state.auth?.user);
  if(EmailID.Type==='FOOD'||EmailID.Type==='GROCERY'){
    return (
      <Surface style={{ flex: 1,  backgroundColor: esiColor.BackgroundColor }}>
        <Header navigation={navigation} />
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: 23, fontWeight: "bold",color:esiColor.brandFontColor, padding: 10 }}>
            Product Management
          </Text>
        </View>
        <ScrollView>
        <View style={{ width: 50, alignItems: "flex-start", flexShrink: 1 }}>
              <Button textColor={esiColor.GBFColor}  icon="keyboard-backspace" style={{ width: 150 ,justifyContent: 'flex-start'}} onPress={() => navigation.navigate('PartnerMainCenter')}>
                Go Back
              </Button>
        </View>
          <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
            {foodproducts.map(product => (
              <View key={product.id}>
                <ProductCard
                  style={styles.productCard} esiColor={esiColor}
                  product={product} navigation={navigation}
                />
              </View>
            ))}
          </View>
        </ScrollView>
      </Surface>
    );
  }else{
    return (
      <Surface style={{ flex: 1,backgroundColor: esiColor.BackgroundColor }}>
        <Header navigation={navigation} />
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: 23, fontWeight: "bold", padding: 10 ,color:esiColor.brandFontColor}}>
            Product Management
          </Text>
        </View>
        <ScrollView>
        <View style={{ width: 50, alignItems: "flex-start", flexShrink: 1 }}>
              <Button textColor={esiColor.GBFColor}  icon="keyboard-backspace" style={{ width: 150 ,justifyContent: 'flex-start'}} onPress={() => navigation.navigate('PartnerMainCenter')}>
                Go Back
              </Button>
        </View>
          <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
            {products.map(product => (
              <View key={product.id}>
                <ProductCard
                  style={styles.productCard} esiColor={esiColor}
                  product={product} navigation={navigation}
                />
              </View>
            ))}
          </View>
        </ScrollView>
      </Surface>
    );
  }
  
};

export default PartnerProductMain;