import {
    INVENTORIES_REQUEST,
    INVENTORIES_SUCCESS,
    INVENTORIES_FAIL,
    INVENTORIES_SIZES_REQUEST,
    INVENTORIES_SIZES_SUCCESS,
    INVENTORIES_SIZES_FAIL,
    INVENTORY_GET_REQUEST,
    INVENTORY_GET_SUCCESS,
    INVENTORY_GET_FAIL,
    INVENTORY_CREATE_REQUEST,
    INVENTORY_CREATE_SUCCESS,
    INVENTORY_CREATE_FAIL,
    INVENTORY_UPDATE_REQUEST,
    INVENTORY_UPDATE_SUCCESS,
    INVENTORY_UPDATE_FAIL,
    INVENTORIES_FILTER,
    INVENTORY_PRODUCTS_REQUEST,
    INVENTORY_PRODUCTS_SUCCESS,
    INVENTORY_PRODUCTS_FAIL, 

    INVENTORY_PARTNERS_REQUEST,
    INVENTORY_PARTNERS_SUCCESS,
    INVENTORY_PARTNERS_FAIL, 

    INVENTORY_DETAILS_REQUEST,
    INVENTORY_DETAILS_SUCCESS,
    INVENTORY_DETAILS_FAIL, 

    PRODUCTSIZE_INVENTORY_REQUEST,
    PRODUCTSIZE_INVENTORY_SUCCESS,
    PRODUCTSIZE_INVENTORY_FAIL,
    
    INVENTORY_PRODUCT_MASTER_FAIL,
    INVENTORY_PRODUCT_MASTER_REQUEST,
    INVENTORY_PRODUCT_MASTER_SUCCESS,
    
} from '../actions/inventoryAction'
 
const initialState = {
    inventories: { all: [], error: '', isLoading: false },
    sizes: { all: [], error: '', isLoading: false },
    inventory: { inventory: {}, error: '', isLoading: false },
    inventoryCreate: { inventory: {}, error: '', isLoading: false },
    inventoryUpdate: { inventory: {}, error: '', isLoading: false },
    inventoryProducts: { products: [], error: '', isLoading: false },
    inventoryPartners: { partners: [], error: '', isLoading: false },
    inventoryDetails: { details: [], error: '', isLoading: false },
    inventoryiMasters: { imasters: [], error: '', isLoading: false }, 
    inventorySizes: { sizes: [], error: '', isLoading: false }, 
    inventoryFilter: { select: 'All', search: '' },
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case INVENTORIES_REQUEST:
        return { ...state, inventories: { all: state.inventories.all, error: '', isLoading: true } };
      case INVENTORIES_SUCCESS:
        return { ...state, inventories: { all: action.payload, error: '', isLoading: false } };
      case INVENTORIES_FAIL:
        return { ...state, inventories: { all: [], error: action.payload, isLoading: false } };
        case INVENTORIES_SIZES_REQUEST:
          return { ...state, sizes: { all: state.sizes.all, error: '', isLoading: true } };
        case INVENTORIES_SIZES_SUCCESS:
          return { ...state, sizes: { all: action.payload, error: '', isLoading: false } };
        case INVENTORIES_SIZES_FAIL:
          return { ...state, sizes: { all: [], error: action.payload, isLoading: false } };
       case INVENTORY_GET_REQUEST:
        return { ...state, inventory: { inventory: {}, error: '', isLoading: true } };
      case INVENTORY_GET_SUCCESS:
        return { ...state, inventory: { inventory: action.payload, error: '', isLoading: false } };
      case INVENTORY_GET_FAIL:
        return { ...state, inventory: { inventory: {}, error: action.payload, isLoading: false } };
      case INVENTORY_CREATE_REQUEST:
        return { ...state, inventoryCreate: { inventory: {}, error: '', isLoading: true } };
      case INVENTORY_CREATE_SUCCESS:
        return { ...state, inventoryCreate: { inventory: state.inventoryCreate.inventory, error: '', isLoading: false } };
      case INVENTORY_CREATE_FAIL:
        return { ...state, inventoryCreate: { inventory: {}, error: action.payload, isLoading: false } };
      case INVENTORY_UPDATE_REQUEST:
        return { ...state, inventoryUpdate: { inventory: {}, error: '', isLoading: true } };
      case INVENTORY_UPDATE_SUCCESS:
        return { ...state, inventoryUpdate: { inventory: state.inventoryUpdate.inventory, error: '', isLoading: false } };
      case INVENTORY_UPDATE_FAIL:
        return { ...state, inventoryUpdate: { inventory: {}, error: action.payload, isLoading: false } };
      case INVENTORIES_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, inventoryFilter: { search: state.inventoryFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, inventoryFilter: { select: state.inventoryFilter.select, search: action.value } };
        }
        return res;   
        case INVENTORY_PRODUCTS_REQUEST:
        return { ...state, inventoryProducts: { products: state.inventoryProducts.products, error: '', isLoading: true }, };
      case INVENTORY_PRODUCTS_SUCCESS:
        return { ...state, inventoryProducts: { products: action.payload, error: '', isLoading: false } };
      case INVENTORY_PRODUCTS_FAIL:
        return { ...state, inventoryProducts: { products: [], error: action.payload, isLoading: false } };

        case INVENTORY_PARTNERS_REQUEST:
            return { ...state, inventoryPartners: { partners: state.inventoryPartners.partners, error: '', isLoading: true }, };
          case INVENTORY_PARTNERS_SUCCESS:
            return { ...state, inventoryPartners: { partners: action.payload, error: '', isLoading: false } };
          case INVENTORY_PARTNERS_FAIL:
            return { ...state, inventoryPartners: { partners: [], error: action.payload, isLoading: false } };

            case INVENTORY_DETAILS_REQUEST:
                return { ...state, inventoryDetails: { details: state.inventoryDetails.details, error: '', isLoading: true }, };
              case INVENTORY_DETAILS_SUCCESS:
                return { ...state, inventoryDetails: { details: action.payload, error: '', isLoading: false } };
              case INVENTORY_DETAILS_FAIL:
                return { ...state, inventoryDetails: { details: [], error: action.payload, isLoading: false } };
               
                case INVENTORY_PRODUCT_MASTER_REQUEST:
                  return { ...state, inventoryiMasters: { imasters: state.inventoryiMasters.imasters, error: '', isLoading: true }, };
                case INVENTORY_PRODUCT_MASTER_SUCCESS:
                  return { ...state, inventoryiMasters: { imasters: action.payload, error: '', isLoading: false } };
                case INVENTORY_PRODUCT_MASTER_FAIL:
                  return { ...state, inventoryiMasters: { imasters: [], error: action.payload, isLoading: false } };   
                  
                  case PRODUCTSIZE_INVENTORY_REQUEST:
                  return { ...state, inventorySizes: { sizes: state.inventorySizes.sizes, error: '', isLoading: true }, };
                case PRODUCTSIZE_INVENTORY_SUCCESS:
                  return { ...state, inventorySizes: { sizes: action.payload, error: '', isLoading: false } };
                case PRODUCTSIZE_INVENTORY_FAIL:
                  return { ...state, inventorySizes: { sizes: [], error: action.payload, isLoading: false } };      
      default:
        return state;
    }
  }
  