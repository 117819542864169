import React, { useEffect } from "react";
import { Dimensions, Image, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Searchbar, Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import { getAllOrdersAction, ordersFilter } from "../../../state/actions/shoppers/shoppersordersAction";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../header/header";

import ordergif from "../../../assets/images/GIF/ordergif.gif"
export default function myOrders(props) {
    const { navigation } = props;
    const esiColor = useSelector(state => state.theme);
    const { height, width } = Dimensions.get('window');
    const windowWidth = Dimensions.get('window').width;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const windowHeight = Dimensions.get('window').height;
    const [showDropDown, setShowDropDown] = React.useState(false);
    const [values, setValues] = React.useState()
    const [page, setPage] = React.useState(0);
    let orderMenu = useSelector(state => state.allOrders.ordersFilter.select);
    let orderSearch = useSelector(state => state.allOrders.ordersFilter.search);
    let allorders = useSelector((state) => state.allOrders.Orders.all);
    let Orders = []
    if (allorders) {
        for (let i = 0; i < allorders.length; i++) {
            let data = allorders[i].Order_Data[0].Item_Data.Item_Master_Data
            for (let j = 0; j < data.length; j++) {
                Orders.push({
                    Order_Id: allorders[i]?.Order_Data[0]?.Order_Id,
                    Order_Status: data[j].Order_Status,
                    Order_Date: data[j].Order_Date,
                    Price: data[j].Price,
                    Payment_Status: data[j].Payment_Status,
                    Product_Id: data[j].Product_Id,
                    Product_Image: data[j].Product_Image,
                    Product_Name: data[j].Product_Name,
                    Shopper_Email_Id: data[j].User_Email_Id,
                    User_Address_Id: data[j].User_Address_Id,
                    Item_Master_Id: data[j].Item_Master_Id,
                    Is_Return_Flag: data[j].Is_Return_Flag,
                    Partner_Name: data[j].Buyer_Partner_Name,
                    Net_Price: data[j].Net_Price,
                    Seller_Partner_Name: data[j].Seller_Partner_Name,
                }
                )
            }
        }
    }
    const dispatch = useDispatch();
    const filterOptions = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Order_Status',
            label: 'Order Status'
        },
        {
            value: 'Seller_Partner_Name',
            label: 'Partner Name'
        },
    ];
    const [oldSearch, setOldSearch] = React.useState(0);
    const checkOrdersForTable = () => {
        if (orderSearch) {
            try {
                if (orderMenu === "All") {
                    return Orders.filter(x => {
                        let colummns = ["Order_Status", "Product_Name", "Seller_Partner_Name", "Order_Id"];
                        for (let i = 0; i < colummns.length; i++) {
                            let mainString = typeof x[colummns[i]] == "string" ?
                                (colummns[i] === "Order_Status" ?
                                    x[colummns[i]] === "NEW" ? "ORDERED" : x[colummns[i]] === "COMPLETED" ? "DELIVERED" : x[colummns[i]] === "RETURN" ? "RETURN COMPLETE" : x[colummns[i]] === "REFUND" ? "REFUNDED" : x[colummns[i]]
                                    : x[colummns[i]]).toLowerCase()
                                : x[colummns[i]];


                            const subString = typeof x[colummns[i]] === "string" ? orderSearch.toLowerCase() : orderSearch;
                            if (typeof x[colummns[i]] === "string") {
                                if (mainString.includes(orderSearch.toLowerCase())) {
                                    return true;
                                }
                            }
                            else {
                                if (mainString == orderSearch) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    });
                } else {
                    return Orders.filter(x => {
                        let mainString = typeof x[orderMenu] == "string" ?
                            (orderMenu === "Order_Status" ?
                                x[orderMenu] === "NEW" ? "ORDERED" : x[orderMenu] === "COMPLETED" ? "DELIVERED" : x[orderMenu] === "RETURN" ? "RETURN COMPLETE" : x[orderMenu] === "REFUND" ? "REFUNDED" : x[orderMenu]
                                : x[orderMenu]).toLowerCase()
                            : x[orderMenu];
                        let subString = typeof x[orderMenu] == "string" ? orderSearch.toLowerCase() : orderSearch;

                        if (typeof x[orderMenu] === "string") {
                            return mainString.includes(subString);
                        }
                        else {
                            if (mainString == orderSearch) {
                                return true;
                            }
                        }
                    })
                }
            } finally {
                if (page > 0) {
                    if (oldSearch != orderSearch.length) {
                        setOldSearch(orderSearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch != orderSearch.length) {
                    setOldSearch(orderSearch.length);
                    setPage(0);
                }
            }
        }
        else {
            return Orders
        }
    }
    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Product_Color_Variant_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };
    function descendingComparator(a, b, orderBy) {
        try {
            if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
                return -1;
            }
            if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
                return 1;
            }
            return 0;
        } catch (error) {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }
    }
    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleChange = itemValue => {
        dispatch(ordersFilter('select', itemValue));
    };
    const handleSearch = value => {
        dispatch(ordersFilter('search', value));
    };
    const data = stableSort(
        checkOrdersForTable(), getComparator(order, orderBy))
    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            let orderformData = {
                "Order_Status": "",
                "Payment_Status": "",
                "Records_Filter": "FILTER",
                "Shopper_Email_Id": logedInEmail,
                "Shopper_Line_Item_Order_Status": "",
                "Shopper_Order_Id": Orders?.Order_Id,
                "Shopper_Order_Line_Item_Id": Orders?.Item_Master_Id

            }
            const setPagination = { PageNo: 1, PageSize: 20, SortBy: "Shopper_Order_Id", SortOrder: "DESC" };
            dispatch(ordersFilter('search', ''));
            dispatch(ordersFilter('select', 'All'));
            dispatch(getAllOrdersAction(orderformData, setPagination));
        }

        else {
            setSeconds(2);
        }
    }, []);
    const [seconds, setSeconds] = React.useState(-1);

    const styles = StyleSheet.create({
        // separator: {
        //     height: 1,
        //     backgroundColor: "#CCCCCC",
        // },
        dropdown: {
            height: 50,
            maxWidth: 150,
            // borderColor: '#27b6cc',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            // activeColor: esiColor.itemColor,
            // iconColor: esiColor.itemColor,
            // backgroundColor: esiColor.BackgroundColor,
            // borderColor: esiColor.SBorderColor,

        },
        placeholderStyle: {
            fontSize: 16,
            // color: esiColor.BackgroundColor

        },
        selectedTextStyle: {
            fontSize: 16,
            // color: esiColor.SIconColor

        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            maxWidth: 200,
        },
        text: {
            fontSize: 12,
            fontFamily: 'system-ui',
            color: esiColor.DescColor

        }
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: data.length > 0 ? esiColor.CBColor : esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <View style={{ marginBottom: (Platform.OS === 'web' ? 10 : 100), marginRight: 10, marginLeft: 10 }}>
                <View style={{
                    flexDirection: "row", paddingTop: 20
                }}>
                    <View style={{ flex: 5 }}>
                        <EsiSearchDropdown
                            style={[styles.dropdown]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={filterOptions}
                            containerStyle={styles.containerstyle}
                            maxHeight={180}
                            labelField="label"
                            valueField="value"
                            placeholder={!showDropDown ? 'All' : 'All'}
                            searchPlaceholder="Search..."
                            value={orderMenu}
                            onFocus={() => setShowDropDown(true)}
                            onBlur={() => setShowDropDown(false)}
                            onUpdateValue={item => {
                                setShowDropDown(false);
                                handleChange(item.value)
                                setValues(item)
                            }}
                        />
                    </View>
                    <View style={{ paddingLeft: 20, flex: 5 }}>
                        <Searchbar
                            style={{ borderRadius: 5, borderWidth: 0.5, maxWidth: 400, borderColor: esiColor.SBorderColor, height: 30, backgroundColor: esiColor.BackgroundColor }}
                            placeholder="Orders"
                            placeholderTextColor={esiColor.SPHTextColor}
                            onChangeText={(value) => handleSearch(value)}
                            value={orderSearch}
                            iconColor={esiColor.SIconColor}
                            theme={{ colors: { text: esiColor.SBTextColor } }}
                        />
                    </View>
                </View>
                {data.length <= 0 ?
                    <View style={{ alignItems: "center", marginTop: 10 }}>

                        <View>
                            <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={ordergif}></Image>
                            <Text style={{ textAlign: "center", color: esiColor.NoDataFound }}>Oops! No orders found...</Text>
                        </View>

                    </View>
                    :
                    <ScrollView style={{ height: windowHeight - ((windowHeight / 100) * 30) }} >
                        {data.map((data, index) => (
                            <View key={index} style={{
                                shadowColor: esiColor.brandShadowColor,
                                // shadowOffset: { width: -2, height: 4 },
                                shadowOpacity: 7,
                                shadowRadius: 5,
                                marginHorizontal: 2, borderRadius: 10, padding: 2,
                                backgroundColor: esiColor.CBColor, marginTop: (Platform.OS === 'web' ? 10 : 10),
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                elevation: 5,
                                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
                                maxHeight: windowHeight > 250 ? 250 : 90,
                                minHeight: 140,
                                // borderColor: 'grey',
                                // borderWidth: 0.5,
                            }}>
                                <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                                    <TouchableOpacity onPress={() => {
                                        navigation.navigate('OrderDetail', { orderId: data?.Order_Id, masterId: data?.Item_Master_Id, addressId: data.User_Address_Id });
                                    }}>
                                        <Image source={{ uri: data.Product_Image }} style={{ height: 100, marginTop: 15, width: 80, resizeMode: 'contain' }}></Image>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flex: 2, flexDirection: 'column' }}  >
                                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{data.Product_Name}</Text>
                                    <Text style={styles.text}>Order ID : {data.Order_Id}</Text>
                                    <Text style={styles.text}>Order Status : <Text style={{ color: data.Order_Status === "NEW" ? "blue" : data.Order_Status === "COMPLETED" ? "green" : data.Order_Status === "CANCELLED" ? "red" : data.Order_Status === "RETURN" ? "orange" : esiColor.itemColor }}>{data.Order_Status === "NEW" ? "ORDERED" : data.Order_Status === "COMPLETED" ? "DELIVERED" : data.Order_Status === "RETURN" ? "RETURN COMPLETE" : data.Order_Status === "REFUND" ? "REFUNDED" : data.Order_Status}</Text>
                                    </Text>
                                    <Text style={styles.text}>Order Date : {data.Order_Date}</Text>
                                    <Text style={styles.text}>Partner Name :<Text style={{ color: esiColor.brandFontColor }}>{data.Seller_Partner_Name}</Text></Text>
                                </View>
                            </View>
                        ))}
                    </ScrollView>
                }
            </View>

        </Surface>
    )
}
