import React, { useState } from 'react';
import { Card, Button, TextInput, HelperText, Checkbox, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, ScrollView, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import Header from '../../../Services/header/header'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import isEmpty from '../../../../state/validations/is-empty';
import { createPartnerAction, uploadPartnerImage } from '../../../../state/actions/Services/partnerservicesActions';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import EsiEditor from '../../../../components/custom/richtext-editor/richtext-editor';
import ImageUpload from '../../../../components/custom/image-upload/upload';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    },

];
const AddPartnerservicesPagetwo = (props: any) => {
    const toast = useToast();
    const { navigation } = props;
    const isSubmited = useSelector(state => state.partnerservice.partnerserviceCreate.isLoading);
    const data = useSelector(state => state.partnerservice.partnerserviceData.data);
    const [errorMessage, setErrorMessage] = useState({ Resource_Email_Id: { key: '', message: '' }, Service_Type: { key: '', message: '' }, global: { key: '', message: '' } });
    const EmailID = useSelector(state => state.auth?.user);
    const esiColor = useSelector(state => state.theme);
    const [statusDropDown, setStatusDropDown] = useState("");
    const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);
    const [descriptionvalue, setDescriptionValue] = React.useState('');

    const dispatch = useDispatch();
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    //To check the data is empty or not
    React.useEffect(() => {
        if (isEmpty(data)) {
            navigation.navigate('Addpartnerservice')
        } else {
            setDescriptionValue(data?.Service_Description)
        }
    }, [data])

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    // img upload

    const [image, setImage] = useState({ preview: '', raw: '' });
    const [imgUrl, setImgUrl] = useState('');
    const [uploadPic, setUploadPic] = useState(false);

    const callBackUploadImage = (data: any) => {
        if (data) {
            setImgUrl(data.File_URL);
        }
        setUploadPic(false);
    };

    const handleImage = async (result: any) => {
        const img = await fetchImageFromUri(result.uri);
        setImage({
            preview: result.uri,
            raw: img
        });
        dispatch(
            uploadPartnerImage(img, callBackUploadImage)
        );
    };

    const fetchImageFromUri = async (uri: any) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        return blob;
    };
    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };
    /*====================================
            Handler
    ====================================*/
    const values = {
        City: data.City,
        Country: data.Country,
        Expected_Service_Fee: data.Expected_Service_Fee,
        Location: data.Location,
        Resource_Email_Id: data.Resource_Email_Id,
        Resource_Mobile: data.Resource_Mobile,
        Resource_Name: data.Resource_Name,
        Sample_Service_Image_URL: imgUrl,
        Created_By: EmailID.Email_Id,
        Sample_Service_Video_URL: data.Sample_Service_Video_URL,
        Service_Description: data.Service_Description,
        Service_Name: data.Service_Name,
        Service_Price_Day: data.Service_Price_Day,
        Service_Price_Per_Hour: data.Service_Price_Per_Hour,
        Service_Type: data.Service_Type,
        State: data.State,
        Status: data.Status,
        Is_Avaliable_For_Services: false,
        Is_Custome_Service_Fee: false,
        Is_Home_Service_Avaliable: false,
        Partner_Service_Id: data.Partner_Service_Id,
        Partner_Detail_Id: EmailID.Partner_Details_Id,
        Discount_Amount: data.Discount_Amount,
        Zip: data.Zip,
    }
    //Form validation
    const Form_Validation = Yup.object().shape({
        Sample_Service_Video_URL: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            'Please provide valid URL.').required('Sample Service Video URL is required.'),
        Status: Yup.string().required('Status is required.'),
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        values.Sample_Service_Image_URL = imgUrl;
        values.Service_Description = descriptionvalue;
        if (!values.Sample_Service_Image_URL) {
            submitTest = false;
            errors.Sample_Service_Image_URL = "Image is required.";
        }
        if (!values.Service_Description || values.Service_Description.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
            submitTest = false;
            errors.Service_Description = "Description is required.";
        }
        else if (values.Service_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
            submitTest = false;
            errors.Service_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
        }

        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            if (values.Is_Avaliable_For_Services) {
                formValues.Is_Avaliable_For_Services = 1;
            } else {
                formValues.Is_Avaliable_For_Services = 0;
            }
            if (values.Is_Custome_Service_Fee) {
                formValues.Is_Custome_Service_Fee = 1;
            } else {
                formValues.Is_Custome_Service_Fee = 0;
            }
            if (values.Is_Home_Service_Avaliable) {
                formValues.Is_Home_Service_Avaliable = 1;
            } else {
                formValues.Is_Home_Service_Avaliable = 0;
            }
            dispatch(createPartnerAction(formValues, navigation, toast, setErrorMessage, setErrors));
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1 , backgroundColor: esiColor.BackgroundColor}}>
            <Header navigation={navigation} />
            <ScrollView>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} >
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('Addpartnerservice')}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", color:esiColor.brandFontColor }}>
                                        Add partner Services
                                    </Text>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <TextInput
                                                  
                                                   style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                   outlineColor={esiColor.TextOuterLine}
                                                   underlineColor={esiColor.TextUnderline}
                                                   activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(props.touched.Sample_Service_Video_URL && props.errors.Sample_Service_Video_URL)}
                                                    label="Sample Service Video URL"
                                                    mode='outlined'
                                                    multiline
                                                    value={props.values.Sample_Service_Video_URL}
                                                    onChangeText={props.handleChange("Sample_Service_Video_URL")}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Sample_Service_Video_URL && props.errors.Sample_Service_Video_URL)}>
                                                    {props.touched.Sample_Service_Video_URL && props.errors.Sample_Service_Video_URL}
                                                </HelperText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                                                Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ? true : false}
                                                labelField="label"
                                                valueField="value"
                                                dropdownPosition="auto"
                                                value={props.values.Status}
                                                name="Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                                                <ErrorMessage name="Status" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", paddingTop: 15, paddingBottom: 15 }}>
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color={esiColor.itemColor}
                                                name="Is_Avaliable_For_Services"
                                                status={props.values.Is_Avaliable_For_Services ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Is_Avaliable_For_Services", !props.values.Is_Avaliable_For_Services);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10,color:esiColor.itemColor }}>Is Avaliable For Services</Text>
                                        </View>
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color={esiColor.itemColor}
                                                name="Is_Custome_Service_Fee"
                                                status={props.values.Is_Custome_Service_Fee ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Is_Custome_Service_Fee", !props.values.Is_Custome_Service_Fee);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10 ,color:esiColor.itemColor}}>Is Custom Service Fee</Text>
                                        </View>
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color={esiColor.itemColor}
                                                name="Is_Home_Service_Avaliable"
                                                status={props.values.Is_Home_Service_Avaliable ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    props.setFieldValue("Is_Home_Service_Avaliable", !props.values.Is_Home_Service_Avaliable);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10 ,color:esiColor.itemColor}}>Is Home Service Avaliable</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <EsiEditor label={"Description"} value={descriptionvalue} onUpdateValue={(value) => { setDescriptionValue(value) }} />
                                            <HelperText type="error" visible={(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Service_Description && props.errors.Service_Description}>
                                                {(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Service_Description && props.errors.Service_Description}
                                            </HelperText>
                                        </View>


                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                                            <HelperText type="error" visible={!imgUrl && props.touched.Sample_Service_Image_URL && props.errors.Sample_Service_Image_URL}>
                                                {!imgUrl && props.touched.Sample_Service_Image_URL && props.errors.Sample_Service_Image_URL}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <Text>
                                        <ErrorMessage name="Resource_Email_Id" />
                                    </Text>
                                    <View style={{ marginLeft: "auto", marginRight: "auto" }}>
                                        <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" textColor={esiColor.itemButtenColor} onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default AddPartnerservicesPagetwo;