import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';

export const PARTNER_PRODUCT_REQUEST = "PARTNER_PRODUCT_REQUEST";
export const PARTNER_PRODUCT_SUCCESS = "PARTNER_PRODUCT_SUCCESS";
export const PARTNER_PRODUCT_FAIL = "PARTNER_PRODUCT_FAIL";

export const PARTNERS_REQUEST = "PARTNERS_REQUEST";
export const PARTNERS_SUCCESS = "PARTNERS_SUCCESS";
export const PARTNERS_FAIL = "PARTNERS_FAIL";
export const PARTNERS_FILTER = "PARTNERS_FILTER";
export const PRODUCTPARTNERS_DATA = "PRODUCTPARTNERS_DATA";

export const PARTNER_CREATE_REQUEST = "PARTNER_CREATE_REQUEST";
export const PARTNER_CREATE_SUCCESS = "PARTNER_CREATE_SUCCESS";
export const PARTNER_CREATE_FAIL = "PARTNER_CREATE_FAIL";

export const PARTNER_UPDATE_REQUEST = "PARTNER_UPDATE_REQUEST";
export const PARTNER_UPDATE_SUCCESS = "PARTNER_UPDATE_SUCCESS";
export const PARTNER_UPDATE_FAIL = "PARTNER_UPDATE_FAIL";

export const PHYSICALPARTNER_ADDRESS_REQUEST = "PHYSICALPARTNER_ADDRESS_REQUEST";
export const PHYSICALPARTNER_ADDRESS_SUCCESS = "PHYSICALPARTNER_ADDRESS_SUCCESS";
export const PHYSICALPARTNER_ADDRESS_FAIL = "PHYSICALPARTNER_ADDRESS_FAIL";

export const PARTNER_PRODUCT_FILTER_REQUEST = "PARTNER_PRODUCT_FILTER_REQUEST";
export const PARTNER_PRODUCT_FILTER_SUCCESS = "PARTNER_PRODUCT_FILTER_SUCCESS";
export const PARTNER_PRODUCT_FILTER_FAIL = "PARTNER_PRODUCT_FILTER_FAIL";
const headers = config.headersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}

export const invalid_data = () => {
  return {
    type: RESET
  }
}
/*====================================================================
                    Physical Product Partner
=======================================================================*/

export const addPhysicalPartnerAction = (formData: any, navigation: any, toast: any, productpartnersid: any, productmasterids: any) => async dispatch => {
  dispatch({
    type: PARTNER_CREATE_REQUEST
  });
  axios.post(`${config.url}/Partner_Product/Partner_Product_Create`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNER_CREATE_SUCCESS
        });
        navigation.navigate('ProductPartnerGet', { productpartnerparams: formData.Product_Id });
        toast.show({ message: 'Product Partner Added Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Cashback_Label": "",
          "Discount_Label": "",
          "Partner_Details_Id": formData.Partner_Details_Id,
          "Records_Filter": "FILTER",
          "Partner_Email_Id": "",
          "Partner_Product_Id": formData.Product_Id,
          "Product_Id": "",
          "Warranty_Period": ""
        };
        dispatch(getAllProductPartnerAction(formValue))
      } else {
        dispatch({
          type: PARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_CREATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};



/*=============================================================
          Update Physical Partner By Id
===============================================================*/
export const EditPhysicalPartner = (formData: any, productpartner: any, navigation: any, toast: any,) => async dispatch => {
  dispatch({
    type: PARTNER_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Partner_Product/Partner_Product_Update`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNER_UPDATE_SUCCESS
        });
        navigation.navigate('ProductPartnerGet', { productpartnerparams: formData.Product_Id });
        toast.show({ message: 'Product Partner Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Cashback_Label": "",
          "Discount_Label": "",
          "Partner_Details_Id": formData.Partner_Details_Id,
          "Records_Filter": "FILTER",
          "Partner_Email_Id": "",
          "Partner_Product_Id": formData.Product_Id,
          "Product_Id": "",
          "Warranty_Period": ""
        };
        dispatch(getAllProductPartnerAction(formValue))
      } else {
        dispatch({
          type: PARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};

/*=============================================================
                  Get All PARTERS Action
===============================================================*/

export const getProductPartnerAction = (formData: any, pagination = {}) => async dispatch => {
  try {

    dispatch({
      type: PARTNER_PRODUCT_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Product/Partner_Product_Get`, pagination), formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {

        dispatch({
          type: PARTNER_PRODUCT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNER_PRODUCT_SUCCESS,
          payload: data.results[0]
        });
      }
    })
  } catch (err) {
    dispatch({
      type: PARTNER_PRODUCT_FAIL,
      payload: err
    });
  }
};
export const getAllProductPartnerAction = (formData: any, pagination = {}) => async dispatch => {
  try {
    dispatch({
      type: PARTNERS_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Product/Partner_Product_Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: PARTNERS_SUCCESS,
          payload: data
        });
      }
    })
  } catch (err) {
    dispatch({
      type: PARTNERS_FAIL,
      payload: err
    });
  }
};

// Filter 
export const productpartnerFilter = (fType: any, value: any) => {
  return function (dispatch) {
    dispatch({
      type: PARTNERS_FILTER,
      fType: fType,
      value: value,
    });
  }
}
//save cache data
export const productpartnerdata = (value: any) => {
  return function (dispatch) {
    dispatch({
      type: PRODUCTPARTNERS_DATA,
      // fType: fType,
      value: value,
    });
  }
}

//Search
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: PARTNERS_FILTER,
      fType: "search",
      value: "",
    });
  }
}
export const addownPartnerproductAction = (formData: any, navigation: any, toast: any, resetForm: any, productpartnersid: any, productmasterids: any) => async dispatch => {
  dispatch({
    type: PARTNER_CREATE_REQUEST
  });
  axios.post(`${config.url}/Partner_Product/Partner_Product_Create`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNER_CREATE_SUCCESS
        });
        navigation.navigate('PartnerownProductsGet', { productmaster: productmasterids, productpartnerparams: productpartnersid });
        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "PARTNER_PRODUCT_ID",
          SortOrder: "ASC"
        };
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Cashback_Label": "",
          "Discount_Label": "",
          "Partner_Details_Id": formData.Partner_Details_Id,
          "Records_Filter": "FILTER",
          "Partner_Email_Id": "",
          "Partner_Product_Id": "",
          "Product_Id": "",
          "Warranty_Period": ""
        };
        dispatch(getAllProductPartnerAction(formValue, pagination))
        resetForm()
      } else {
        dispatch({
          type: PARTNER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_CREATE_FAIL,
        payload: "Currently server is not working.Please try again later."
      });
    })
};

export const EditownPartnerproduct = (formData: any, productpartner: any, navigation: any, toast: any, partnerproductpage: any) => async dispatch => {
  dispatch({
    type: PARTNER_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Partner_Product/Partner_Product_Update`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNER_UPDATE_SUCCESS
        });
        if (partnerproductpage) {
          navigation.navigate('PartnerownProductsGet')
        } else {
          navigation.navigate('ProductPartnerGet', { productpartnerparams: formData.Product_Id });
        }
        toast.show({ message: 'Product Partner Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "PARTNER_PRODUCT_ID",
          SortOrder: "ASC"
        };
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "Cashback_Label": "",
          "Discount_Label": "",
          "Partner_Details_Id": formData.Partner_Details_Id,
          "Records_Filter": "FILTER",
          "Partner_Email_Id": "",
          "Partner_Product_Id": "",
          "Product_Id": "",
          "Warranty_Period": ""
        };
        dispatch(getAllProductPartnerAction(formValue, pagination))
      } else {
        dispatch({
          type: PARTNER_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};


export const getAllPartnerProductssearch = (formData: any) => async dispatch => {
  if (formData === '1') {
    let formValues = {
      "Records_Filter": "ALL",
      "Product_Name": "",
    };
    try {
      dispatch({
        type: PARTNER_PRODUCT_FILTER_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Product_Management/Product_Get_Dropdown_Search`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: PARTNER_PRODUCT_FILTER_FAIL,
        payload: err
      });
    }
  } else {
    let formValues = {
      "Product_Name": formData,
      "Records_Filter": "FILTER",
    };
    try {
      dispatch({
        type: PARTNER_PRODUCT_FILTER_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Product_Management/Product_Get_Dropdown_Search`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: PARTNER_PRODUCT_FILTER_FAIL,
        payload: err
      });
    }
  }
};


export const getAllProductmasterssearch = (formData: any) => async dispatch => {
  if (formData === '1') {
    let formValues = {
      "search_by_filter": "",
      "search": "",
      "Brand_Id": "",
      "Category_Id": "",
      "Is_Product_Active": "",
      "Min_Mrp": "",
      "Partner_Details_Id": "",
      "Product_Master_Id": "",
      "Product_Name": "",
      "Records_Filter": "ALL",
      "Sku": "",
      "Status": "",
      "Upc_Code": "",
      "Warranty_Period": ""
    };
    try {
      dispatch({
        type: PARTNER_PRODUCT_FILTER_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Product_Master/Get`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: PARTNER_PRODUCT_FILTER_FAIL,
        payload: err
      });
    }
  } else {
    let formValues = {
      "search_by_filter": "",
      "search": formData,
      "Brand_Id": "",
      "Category_Id": "",
      "Is_Product_Active": "",
      "Min_Mrp": "",
      "Partner_Details_Id": "",
      "Product_Master_Id": "",
      "Product_Name": "",
      "Records_Filter": "FILTER",
      "Sku": "",
      "Status": "",
      "Upc_Code": "",
      "Warranty_Period": ""
    };
    try {
      dispatch({
        type: PARTNER_PRODUCT_FILTER_REQUEST
      });
      let { data } = await axios.post(
        `${config.nodePartnerUrl}/Product_Master/Get`,
        formValues,
        {
          headers: config.nodeheadersCommon
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: PARTNER_PRODUCT_FILTER_SUCCESS,
            payload: data?.results
          });
        }
      }
    } catch (err) {
      dispatch({
        type: PARTNER_PRODUCT_FILTER_FAIL,
        payload: err
      });
    }
  }
};