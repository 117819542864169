import React from 'react';
import { Card, Button, TextInput, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import Header from '../../../Services/header/header';
import { partnerserviceData } from '../../../../state/actions/Services/partnerservicesActions';
import EsiCountry from '../../../../components/custom/country-state-city/country';
import EsiState from '../../../../components/custom/country-state-city/state';
import EsiCity from '../../../../components/custom/country-state-city/city';
import { getLocationsServicesAction } from '../../../../state/actions/partnershopbylocationAction';
import { getbussinessubtypeAction } from '../../../../state/actions/authActions';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const Addpartnerservice = (props: any) => {
    const { navigation } = props;
    const dispatch = useDispatch();
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const esiColor = useSelector(state => state.theme);
    const [refreshing, setRefreshing] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const phoneInput = React.useRef(null);
    const [countryField, setCountryField] = React.useState();
    const [stateField, setStateField] = React.useState();
    const partnerlocation = useSelector(state => state.shopbycitylocation.services.services);
    const maxhourprice = Number(partnerlocation?.Per_Hour_Rate)
    const maxdayprice = Number(partnerlocation?.Per_Daye_Rate)

    if (!maxhourprice) { }
    const setType = (cityid) => {
        if (cityid !== "") {
            let formData =
            {
                "City_Id": cityid,
                "Service_Type": EmailID.Business_Sub_Type,
                "Partner_Services_Generic_Id": "",
                "Records_Filter": "FILTER",
                "Service_Name": ""
            };
            dispatch(getLocationsServicesAction(formData));
        }
    }

    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });

    React.useEffect(() => {
        if (!(permissions.Products_Partner_Products_Related_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const [subtypedata, setsubtypeData] = React.useState([]);
    const callBacksubtypeData = (data) => {
        setsubtypeData(data)

    }
    React.useEffect(() => {
        let data = {
            "Business_Type": "SERVICES",
            "Records_Filter": "FILTER"
        }
        dispatch(getbussinessubtypeAction(data, callBacksubtypeData))
    }, []);
    //screen dimensions
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    //form reset
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        container: {
            paddingTop: 15
        }, images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },

        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        phonetextInput: {
            flex: 1
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        countrydropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            left: 22,
            top: 0.5,
            color: 'black',
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }} >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }
            >
                <Formik
                    initialValues={{
                        City: "",
                        Country: "",
                        Expected_Service_Fee: "",
                        Location: "",
                        Resource_Email_Id: "",
                        Resource_Mobile: "",
                        Resource_Name: "",
                        Sample_Service_Image_URL: "",
                        Created_By: EmailID.Email_Id,
                        Service_Price_Per_Hour: '',
                        Partner_Detail_Id: EmailID.Partner_Details_Id,
                        Sample_Service_Video_URL: '',
                        Service_Description: '',
                        Service_Name: EmailID.Business_Sub_Type,
                        Service_Price_Day: '',
                        Service_Type: EmailID.Business_Sub_Type,
                        State: '',
                        Status: '',
                        Zip: '',
                        Partner_Service_Id: '',
                        Discount_Amount: ''
                    }}
                    //field validations
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({

                        Service_Price_Per_Hour: Yup.number()
                            .positive()
                            .test('max-hour-price', 'Require Service Price Per Hour less than to ' + maxhourprice + '.', function (value) {
                                if (isNaN(maxhourprice)) {
                                    return this.createError({ message: 'There is no service for this location' });
                                }
                                return value <= maxhourprice;
                            })
                            .required('Service Price Per Hour is required'),
                        Expected_Service_Fee: Yup.number().required(' Expected Service Fee is required.'),
                        Service_Name: Yup.string().required('Service Name is required.'),
                        Service_Price_Day: Yup.number()
                            .positive()
                            .test('max-day-price', 'Require Service Price Per day less than to ' + maxdayprice + '.', function (value) {
                                if (isNaN(maxdayprice)) {
                                    return this.createError({ message: 'There is no service for this location' });
                                }
                                return value <= maxdayprice;
                            })
                            .required('Service Price Per day is required'),
                        // .positive().max(maxdayprice, 'Require Service Price Per Day less than to ' + maxdayprice + '.').required('Service Price Per Day is required.'),
                        State: Yup.string()
                            .required('State is required.'),
                        City: Yup.string()
                            .required('City is required.'),
                        Country: Yup.string()
                            .required('Country is required.'),
                        Zip: Yup.string()
                            .required('Zip is required.'),
                    })}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        setErrors(errors);
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            dispatch(partnerserviceData(formValues, navigation));
                            navigation.navigate('AddPartnerservicesPagetwo')
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        resetForm
                    }) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        resetForm();
                                        navigation.navigate('Partnerservice')
                                    }} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", margin: 10, color: esiColor.brandFontColor }}>
                                        Add Partner Services
                                    </Text>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 230, flexShrink: 1, paddingTop: 10 }}>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Country && errors.Country) && { color: '#b00020' }]}>
                                                    Country
                                                </Text>
                                                <EsiCountry
                                                    error={Boolean(touched.Country && errors.Country)}
                                                    labelField="NAME"
                                                    valueField="COUNTRY_CODE"
                                                    value={values.Country}
                                                    name="Country"
                                                    search
                                                    searchPlaceholder={'Select Country'}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Country', item.COUNTRY_CODE);
                                                        if (countryField != item.NAME)
                                                            setCountryField(item.COUNTRY_CODE);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Country && errors.Country)}>
                                                    <ErrorMessage name="Country" />
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, flexShrink: 1, marginLeft: '0.5%' }}>
                                        <View style={styles.container}>
                                            <Text style={[styles.dropdownlabel, Boolean(touched.State && errors.State) && { color: '#b00020' }]}>
                                                State
                                            </Text>
                                            <EsiState
                                                error={Boolean(touched.State && errors.State)}
                                                labelField="NAME"
                                                valueField="STATE_CODE"
                                                value={values.State}
                                                name="State"
                                                search
                                                searchPlaceholder={'Select State'}
                                                CountryCode={countryField}
                                                onUpdateValue={item => {
                                                    setFieldValue('State', item.STATE_CODE);
                                                    if (stateField != item.NAME)
                                                        setStateField(item.STATE_CODE);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.State && errors.State)}>
                                                <ErrorMessage name="State" />
                                            </HelperText>
                                        </View>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                        <View style={styles.container}>
                                           <Text style={[styles.dropdownlabel, Boolean(touched.City && errors.City) && { color: '#b00020' }]}>
                                                City
                                            </Text>
                                            <EsiCity
                                                error={Boolean(touched.City && errors.City)}
                                                labelField="NAME"
                                                valueField="CITY_ID"
                                                CountryCode={countryField}
                                                StateCode={stateField}
                                                value={values.City}
                                                name="City"
                                                search
                                                searchPlaceholder={'Select City'}
                                                onUpdateValue={item => {
                                                    item &&
                                                        setType(item?.CITY_ID);
                                                    setFieldValue('City', item.CITY_ID);

                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.City && errors.City)}>
                                                <ErrorMessage name="City" />
                                            </HelperText>
                                        </View>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <TextInput

                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Expected_Service_Fee && errors.Expected_Service_Fee)}
                                                label="Expected Service Fee"
                                                mode='outlined'
                                                value={values.Expected_Service_Fee}
                                                onChangeText={handleChange("Expected_Service_Fee")}
                                                keyboardType='numeric'
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Expected_Service_Fee && errors.Expected_Service_Fee)}>
                                                {touched.Expected_Service_Fee && errors.Expected_Service_Fee}
                                            </HelperText>
                                        </View>
                                    </View>


                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <TextInput

                                                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                    outlineColor={esiColor.TextOuterLine}
                                                    underlineColor={esiColor.TextUnderline}
                                                    activeOutlineColor={esiColor.TextOuterLine}
                                                    error={Boolean(touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour)}
                                                    label="Service Price Per Hour"
                                                    mode='outlined'
                                                    multiline
                                                    value={values.Service_Price_Per_Hour}
                                                    onChangeText={handleChange("Service_Price_Per_Hour")}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour)}>
                                                    {touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1.5% ' }}>
                                            <TextInput

                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Service_Price_Day && errors.Service_Price_Day)}
                                                label="Service Price Day"
                                                mode='outlined'
                                                multiline
                                                value={values.Service_Price_Day}
                                                onChangeText={handleChange("Service_Price_Day")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Service_Price_Day && errors.Service_Price_Day)}>
                                                {touched.Service_Price_Day && errors.Service_Price_Day}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                            <TextInput

                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Zip && errors.Zip)}
                                                label="Zip"
                                                mode='outlined'
                                                multiline
                                                value={values.Zip}
                                                onChangeText={handleChange("Zip")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Zip && errors.Zip)}>
                                                {touched.Zip && errors.Zip}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained"textColor={esiColor.itemButtenColor} onPress={handleSubmit}>
                                            Next
                                        </Button>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface >
    );
};

export default Addpartnerservice;