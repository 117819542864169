import axios from 'axios';
import config from '../config';
import shopperconfig from '../shoppers/shopperconfig';

export const ORDERS_STATISTICS = "ORDERS_STATISTICS";
export const PROFIT_STATISTICS = "PROFIT_STATISTICS";
export const USERS_STATISTICS = "USERS_STATISTICS";
export const COUPONS_STATISTICS = "COUPONS_STATISTICS";
export const DEALS_STATISTICS = "DEALS_STATISTICS";
export const PRODUCTS_STATISTICS = "PRODUCTS_STATISTICS";

const headers = config.headersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
export const statisticsorderCount = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Date": "",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Order_Count_Monthwise_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: ORDERS_STATISTICS,
                        data: data.results,
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: ORDERS_STATISTICS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: ORDERS_STATISTICS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const statisticsProfit = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Date": "",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Total_Profit_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: PROFIT_STATISTICS,
                        data: data.results,
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: PROFIT_STATISTICS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PROFIT_STATISTICS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const statisticsUsers = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Date": "",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/User_Statistics_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: USERS_STATISTICS,
                        data: data.results,
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: USERS_STATISTICS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: USERS_STATISTICS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const statisticsCoupons = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Date": "",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Coupons_Statistics_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response?.Response_Status === "Success") {
                    dispatch({
                        type: COUPONS_STATISTICS,
                        data: data.results,
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: COUPONS_STATISTICS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: COUPONS_STATISTICS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const statisticsDeals = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Date": "",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Deals_Statistics_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: DEALS_STATISTICS,
                        data: data.results,
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: DEALS_STATISTICS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: DEALS_STATISTICS,
                    data: String(error),
                    success: false
                });
            })
    }
}

export const statisticsProducts = (formData: any) => {
    return function (dispatch: any) {
        let payload = {
            "Date": "",
            "Partner_Details_Id": formData.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        axios.post(`${shopperconfig.nodePartnerUrl}/Reports/Product_Statistics_Count_Get`, payload,
            {
                headers: nodeheaders
            })
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status === "Success") {
                    dispatch({
                        type: PRODUCTS_STATISTICS,
                        data: data.results,
                        success: true
                    });
                }
                else {
                    dispatch({
                        type: PRODUCTS_STATISTICS,
                        data: data.Success_Response.UI_Display_Message,
                        success: false
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: PRODUCTS_STATISTICS,
                    data: String(error),
                    success: false
                });
            })
    }
}