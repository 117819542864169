import React, { useState } from 'react';
import * as Print from "expo-print";
import { shareAsync } from "expo-sharing";
import { Card, Button, Dialog, IconButton, TextInput, HelperText, Portal, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import Header from '../auth/components/header';
import { getsingleorderAction, InvoiceAction } from '../../state/actions/ordermanagementAction';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import { getDeliveryPesrsonAction, getPartnerAddressAction, getShippingAddressAction, updateShippingAction } from '../../state/actions/shippingAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
//dropdown options
const allorderstatus = [
  {
    value: 'NEW',
    label: 'NEW'
  },
  {
    value: 'ORDERED',
    label: 'ORDERED'
  },
  {
    value: 'ACCEPTED',
    label: 'ACCEPTED'
  },
  {
    value: 'REJECTED',
    label: 'REJECTED'
  },
  {
    value: 'PACKED',
    label: 'PACKED'
  },
  {
    value: 'SHIPPED',
    label: 'SHIPPED'
  },
  {
    value: 'OUT FOR DELIVERY',
    label: 'OUT FOR DELIVERY'
  },
  {
    value: 'IN-TRANSIT',
    label: 'IN-TRANSIT'
  },
  {
    value: 'DELIVERED',
    label: 'DELIVERED'
  },
  {
    value: 'CANCELLED',
    label: 'CANCELLED'
  },
  {
    value: 'PARTIAL CANCELLATION',
    label: 'PARTIAL CANCELLATION'
  },
  {
    value: 'RETURNED',
    label: 'RETURNED'
  }, {
    value: 'PARTIAL RETURNED',
    label: 'PARTIAL RETURNED'
  },
];
const reasonfordelaylist = [
  {
    value: 'Delay due to Rain',
    label: 'Delay due to Rain'
  },
  {
    value: 'Delay due to Traffic',
    label: 'Delay due to Traffic'
  },
  {
    value: 'Delay due to Accident',
    label: 'Delay due to Accident'
  },
  {
    value: 'Delay due to Heavy Orders',
    label: 'Delay due to Heavy Orders'
  },
  {
    value: 'Delay due to Vehicle Repair',
    label: 'Delay due to Vehicle Repair'
  }
];
const shippingtypelist = [
  {
    value: 'Live',
    label: 'Live'
  },
  {
    value: 'standard',
    label: 'standard'
  },
];
const cancelreason = [
  {
    value: 'OUT OF STOCK',
    label: 'OUT OF STOCK'
  },
  {
    value: 'PRODUCT STOPPED BY MANUFACTURER',
    label: 'PRODUCT STOPPED BY MANUFACTURER'
  },
];


const EditOrders = (props: any) => {
  const { navigation } = props;
  const [pdata, setPData] = React.useState();
  const [data, setData] = React.useState();
  const esiColor = useSelector(state => state.theme);
  //toast for Popup message
  const toast = useToast();
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const [visible, setVisible] = React.useState(false);
  const [issubmitted, setSubmit] = React.useState(false);

  const [visibleone, setVisibleOne] = React.useState(false);

  const [visibles, setVisibles] = React.useState(false);

  const hideDialog = () => setVisible(false);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (!(permissions.Order_Management == "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);
  const dispatch = useDispatch();
  const [showDropDown, setShowDropDown] = React.useState('');
  const [reasonfordelay, setreasonfordelayDropDown] = React.useState('');
  const [shippingtype, setShippingTypeDropDown] = React.useState('');
  const [showorderDropDown, setOrderShowDropDown] = React.useState('');
  const [deliverypersonDropDown, setDeliveryPersonDropDown] = useState("");
  const [cancellshowDropDown, setCancellShowDropDown] = useState("");
  const [cancelllshowDropDown, setCancelllShowDropDown] = useState("");
  const [cancelshowDropDown, setCancelShowDropDown] = useState("");
  const [orderaddressDropDown, setOrderAddressDropDown] = useState("");
  const [invoiceDetails, setDetails] = useState();
  const isSubmited = useSelector(state => state.ordersmanagement.brandCreate.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  const deliverypersons = useSelector(state => state.shipping.deliverypersons.persons);
  const partneraddress = useSelector(state => state.shipping.partnerAddres.address);
  const orderID = props.route?.params?.orderid;
  const partnerID = props.route?.params?.partnerid;
  const ID = props.route?.params?.id;
  const Orders = useSelector(state => state.ordersmanagement.singleorder.all)
  const [item, setItem] = React.useState('');
  const [itemadress, setItemadress] = React.useState('');
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  //  const billing = Orders?.    partnerAddres: { address: []
  const [address, setAddress] = useState();

  const UpdateState = (data: any) => {
    setAddress(data.Google_Map_Location)
  }
  const updateStatesInvoice = (status, data) => {
    if (status) {
      setDetails(data);
      printToFile(data);
    }

  };
  const updateStatesadreses = (status, data) => {
    if (status) {
      setDetails(data);
      printToFileadress(data)
    }

  };
  const shippingAddress = (Id: any) => {
    let formData = {
      Records_Filter: "FILTER",
      User_Address_Id: Id
    }
    dispatch(getShippingAddressAction(UpdateState, formData))
  }
  React.useEffect(() => {
    if (partnerID) {
      let filter = {
        "search": "",
        "Brand_Id": "",
        "CASHBACK_LABEL": "",
        "Category_Id": "",
        "DISCOUNT_LABEL": "",
        "Deal_Id": "",
        "Partner_ID": partnerID,
        "Records_Filter": "FILTER",
        "Status": "",
        "Title": ""
      }
      dispatch(getDeliveryPesrsonAction(filter))
    }
  }, [partnerID])
  const refreshCall = () => {
    let formData = {
      "search_by_filter": "",
      "search": "",
      "Is_Approved": "",
      "Order_Id": orderID,
      "Order_Status": "",
      "Partner_Detail_ID": partnerID,
      "User_Email_Id": "",
      "Records_Filter": "FILTER"
    }
    dispatch(getsingleorderAction(formData))
  }
  React.useEffect(() => {
    if (partnerID) {
      let filter = {
        "search_by_filter": "",
        "search": "",
        "Partner_Address_Id": "",
        "Partner_Details_Id": partnerID,
        "Records_Filter": "FILTER"
      }
      dispatch(getPartnerAddressAction(filter))
    }
  }, [partnerID])
  React.useEffect(() => {
    if ((Orders?.Order_Id != orderID) && partnerID) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Is_Approved": "",
        "Order_Id": orderID,
        "Order_Status": "",
        "Partner_Detail_ID": partnerID,
        "User_Email_Id": "",
        "Records_Filter": "FILTER"
      }
      dispatch(getsingleorderAction(formData))
    }
  }, [partnerID, orderID]);
  React.useEffect(() => {
    if (partnerID && orderID) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Is_Approved": "",
        "Order_Id": orderID,
        "Order_Status": "",
        "Partner_Detail_ID": partnerID,
        "User_Email_Id": "",
        "Records_Filter": "FILTER"
      }
      dispatch(getsingleorderAction(formData))
    }

  }, [partnerID, orderID]);

  //for Invoice

  React.useEffect(() => {
    if (item) {
      let formData1 = {
        "Item_Master_Id": item,
        "Order_Id": orderID
      }
      dispatch(InvoiceAction(formData1, updateStatesInvoice))
    }
  }, [item]);

  React.useEffect(() => {
    if (itemadress) {
      let formData1 = {
        "Item_Master_Id": item,
        "Order_Id": orderID
      }
      dispatch(InvoiceAction(formData1, updateStatesadreses))
    }
  }, [itemadress]);

  let deliverypersonslist = []
  if (deliverypersons) {
    if (deliverypersons[0]) {
      for (let i = 0; i < deliverypersons.length; i++) {
        deliverypersonslist.push({
          label: deliverypersons[i].Full_Name,
          value: deliverypersons[i].Del_User_Email_ID
        })
      }
    }
  }
  let partneraddresslist = []
  if (partneraddress) {
    if (partneraddress) {
      for (let i = 0; i < partneraddress.length; i++) {
        partneraddresslist.push({
          label: partneraddress[i].Location?.substring(0, 60),
          value: partneraddress[i].Partner_Address_Id,
          Pickup_Location: partneraddress[i].Google_Map_Location,
        })
      }
    }
  }
  //iterating values 
  let allOrderDetails = []
  if (Orders) {
    for (let i = 0; i < Orders?.Item_Data?.Item_Master_Data?.length; i++) {
      let lineitemdata = Orders?.Item_Data?.Item_Master_Data
      allOrderDetails.push({
        Order_Type: Orders.Order_Type,
        Order_Id: Orders?.Order_Id,
        Del_Order_Id: Orders?.Del_Order_Id,
        Order_Status: Orders?.Order_Status,
        Order_Is_Approved: Orders?.Is_Approved,
        Brand_Id: lineitemdata[i].Brand_Id,
        Order_PickUp_Address_Id: lineitemdata[i].Order_Pickup_Address_Id,
        Brand_Image_Path: lineitemdata[i].Brand_Image_Path,
        Brand_Name: lineitemdata[i].Brand_Name,
        Is_Approved: lineitemdata[i].Is_Approved,
        Is_Item_Return_Flag: lineitemdata[i].Is_Item_Return_Flag,
        Item_Master_Id: lineitemdata[i].Item_Master_Id,
        Line_Item_Status: lineitemdata[i].Line_Item_Status,
        MRP: lineitemdata[i].MRP,
        Max_Days_To_Return: lineitemdata[i].Max_Days_To_Return,
        Order_Date: lineitemdata[i].Order_Date,
        Partner_Details_Id: lineitemdata[i].Partner_Details_Id,
        Payment_Status: lineitemdata[i].Payment_Status,
        Product_Id: lineitemdata[i].Product_Id,
        Product_Image: lineitemdata[i].Product_Image,
        Product_Name: lineitemdata[i].Product_Name,
        Quantity: lineitemdata[i].Quantity,
        Selling_Price: lineitemdata[i].Selling_Price,

        Item_Financial_Meepaisa_Billable_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Billable_Amount,
        Item_Financial_Meepaisa_Gift_Wrap_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Gift_Wrap_Amount,
        Item_Financial_Meepaisa_Packaging_Charges: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Packaging_Charges,
        Item_Financial_Meepaisa_Shipping_Fee: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Shipping_Fee,
        Item_Financial_Meepaisa_Total_Tax_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.Meepaisa_Total_Tax_Amount,
        Item_Financial_Net_Price: lineitemdata[i]?.Item_Financial_Summary[0]?.Net_Price,
        Item_Financial_Partner_Total_Price: lineitemdata[i]?.Item_Financial_Summary[0]?.Partner_Total_Price,
        Item_Financial_User_Billable_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.User_Billable_Amount,
        Item_Financial_User_Total_Tax_Amount: lineitemdata[i]?.Item_Financial_Summary[0]?.User_Total_Tax_Amount,

        Shipping_Address_Type: lineitemdata[i]?.Shipping_Address[0]?.Address_Type,
        Shipping_Address_City: lineitemdata[i]?.Shipping_Address[0]?.City,
        Shipping_Address_Country: lineitemdata[i]?.Shipping_Address[0]?.Country,
        Shipping_Address_Created_By: lineitemdata[i]?.Shipping_Address[0]?.Created_By,
        Shipping_Address_Door_No: lineitemdata[i]?.Shipping_Address[0]?.Door_No,
        Shipping_Address_Email_Id: lineitemdata[i]?.Shipping_Address[0]?.Email_Id,
        Shipping_Address_Google_Map_Location: lineitemdata[i]?.Shipping_Address[0]?.Google_Map_Location,
        Shipping_Address_Land_Mark: lineitemdata[i]?.Shipping_Address[0]?.Land_Mark,
        Shipping_Address_Location: lineitemdata[i]?.Shipping_Address[0]?.Location,
        Shipping_Address_Mobile: lineitemdata[i]?.Shipping_Address[0]?.Mobile,
        Shipping_Address_Shipping_Address_Id: lineitemdata[i]?.Shipping_Address[0]?.Shipping_Address_Id,
        Shipping_Address_State: lineitemdata[i]?.Shipping_Address[0]?.State,
        Shipping_Address_Street: lineitemdata[i]?.Shipping_Address[0]?.Street,
        Shipping_Address_Zip: lineitemdata[i]?.Shipping_Address[0]?.Zip,

        Shipping_Summary_Expected_Delivery_Time: lineitemdata[i]?.Shipping_Summary[0]?.Expected_Delivery_Time,
        Shipping_Summary_Live_Delivered_By_Email: lineitemdata[i]?.Shipping_Summary[0]?.Live_Delivered_By_Email,
        Delivery_Message: lineitemdata[i]?.Shipping_Summary[0]?.Delivery_Message,
        Shipping_Summary_Live_Delivered_By_Name: lineitemdata[i]?.Shipping_Summary[0]?.Live_Delivered_By_Name,
        Shipping_Summary_Live_Delivery_Person_Phone: lineitemdata[i]?.Shipping_Summary[0]?.Live_Delivery_Person_Phone,
        Shipping_Summary_Shipping_Channel: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Channel,
        Shipping_Summary_Shipping_Fee: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Fee,
        Shipping_Summary_Shipping_ID: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_ID,
        Shipping_Summary_Shipping_Tax: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Tax,
        Shipping_Summary_Shipping_Total: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Total,
        Shipping_Summary_Shipping_Type: lineitemdata[i]?.Shipping_Summary[0]?.Shipping_Type,

      })
    }
  }
  //billing Address
  let orderdata = []
  if (Orders) {
    for (let i = 0; i < Orders?.Billing_Address?.length; i++) {
      let billing = Orders.Billing_Address
      for (let j = 0; j < billing.length; j++) {
        let financial = Orders.Financial_Summary
        for (let k = 0; k < financial.length; k++) {
          let payment = Orders.Payment_Summary
          for (let l = 0; l < financial.length; l++) {
            orderdata.push({
              Order_Type: Orders.Order_Type,
              Order_Id: Orders?.Order_Id,
              Order_Status: Orders?.Order_Status,
              Order_Is_Approved: Orders?.Is_Approved,
              Del_Order_Id: Orders?.Del_Order_Id,
              Address_Type: billing[i].Address_Type,
              Billing_Address_Id: billing[i].Billing_Address_Id,
              City: billing[i].City,
              Country: billing[i].Country,
              Created_By: billing[i].Created_By,
              Door_No: billing[i].Door_No,
              Email_Id: billing[i].Email_Id,
              Google_Map_Location: billing[i].Google_Map_Location,
              Land_Mark: billing[i].Land_Mark,
              Location: billing[i].Location,
              Mobile: billing[i].Mobile,
              State: billing[i].State,
              Street: billing[i].Street,
              Zip: billing[i].Zip,

              Cashback_To_User: financial[j].Cashback_To_User,
              Corp_Coupon_Amount: financial[j].Corp_Coupon_Amount,
              Coupon_Amount: financial[j].Coupon_Amount,
              Delivery_Person_Tip: financial[j].Delivery_Person_Tip,
              Gift_Voucher_Amount: financial[j].Gift_Voucher_Amount,
              Meepaisa_Gift_Wrap_Amount: financial[j].Meepaisa_Gift_Wrap_Amount,
              Meepaisa_Packaging_Charges: financial[j].Meepaisa_Packaging_Charges,
              Meepaisa_Shipping_Fee: financial[j].Meepaisa_Shipping_Fee,
              Meepaisa_Total_Tax_Amount: financial[j].Meepaisa_Total_Tax_Amount,
              Meepiasa_Billable_Amount_After_Taxes: financial[j].Meepiasa_Billable_Amount_After_Taxes,
              Meepiasa_Billable_Amount_Before_Taxes: financial[j].Meepiasa_Billable_Amount_Before_Taxes,
              Total_Order_Amount: financial[j].Total_Order_Amount,
              User_Billable_Amount_After_Taxes: financial[j].User_Billable_Amount_After_Taxes,
              User_Billable_Amount_Before_Taxes: financial[j].User_Billable_Amount_Before_Taxes,
              User_Shipping_Fee: financial[j].User_Shipping_Fee,
              User_Total_Tax_Amount: financial[j].User_Total_Tax_Amount,
              Wallet_Amount: financial[j].Wallet_Amount,

              Payment_Date: payment[k].Payment_Date,
              Payment_Method: payment[k].Payment_Method,
              Payment_Reference_Id: payment[k].Payment_Reference_Id,
              Payment_Status: payment[k].Payment_Status,
              User_Billable_Amount: payment[k].User_Billable_Amount,
            })
          }
        }
      }
    }
  }
  // for invoice 

  const getInvoice = (data) => {
    return `

            <html>
            <head>
        <style>
        .grid-container {
        display: grid;
        grid-template-columns: auto auto;
        }.hr.solid {
        border-top: 5px solid #2EE1F4;
        }
        table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        }
        td{
        border: 1px solid #2EE1F4;
        text-align: right;
        padding: 8px;
        }
        th {
        border: 1px solid #2EE1F4;
        text-align: right;
        background-color: #2EE1F4;
        padding: 8px;
        }
        </style>
        
                <meta charset="utf-8">
                <title>Invoice</title>
                <script src="script.js"></script>
            </head>
            <body onclick="window.print()" >
                <header>
            <center>
                    <h1 style="color:#2EE1F4;">Invoice</h1>
            </center>
                    <address contenteditable>
            <div class="grid-container">
            <img src="`+ require('../../assets/images/img/logo/logo.png') + `" width="100" height="80" style="background-color: rgb(39, 182, 204);">
            <span style="text-align:right">

            <p>Order Id: `+ data?.OrderId + `</p>

            <p>Order Date: `+ data?.OrderDate + `</p>

            <p>Invoice Nummber: `+ data?.InvoiceNo + `</p>

            <p>Invoice Date: `+ data?.InvoiceDate + `</p>            


            </span>
            </div>
            <hr class="solid">
            <div class="grid-container">
            <span>
            <h4>Billing Address:</h4>
            <p>`+ data?.BillingAddress?.Name + `</p>
            <p> `+ data?.BillingAddress?.AddressDrToSt + `</p>
            <p>`+ data?.BillingAddress?.City + `,` + data?.BillingAddress?.State + `,` + data?.BillingAddress?.Country + `</p>
            <p>`+ data?.BillingAddress?.PhoneNumber + `</p>
            <p>`+ data?.BillingAddress?.email + `</p>
            </span>
            <span style="text-align:right">
            <h4>Shipping Address:</h4>
            <p>`+ data?.ShippingAddress?.Name + `</p>
            <p> `+ data?.ShippingAddress?.AddressDrToSt + `</p>
            <p>`+ data?.ShippingAddress?.City + `,` + data?.ShippingAddress?.State + `,` + data?.ShippingAddress?.Country + `</p>
            <p>`+ data?.ShippingAddress?.PhoneNumber + `</p>
            <p>`+ data?.ShippingAddress?.email + `</p>
            </span>
            </div>
            <hr class="solid">
            <div class="grid-container">
            <span>
            <h4>Sold By:</h4>
            <p>`+ data?.Partner.Name + `</p>
                <p>`+ data?.Partner?.AddressDrToSt + `</p>
                <p>`+ data?.Partner?.City + `,` + data?.Partner?.State + `,` + data?.Partner?.Country + `</p>
                <p>`+ data?.Partner?.GST + `</p>
                <p>`+ data?.Partner?.Pan + `</p>

            </span>
            <span style="text-align:right">
         
            </span>
            </div>
            <hr class="solid">
            <table>
        <tr>
            <th>SI No</th>
            <th style="width:70%">Description</th>
            <th>rate</th>
            <th>QTY</th>
            <th>Amount</th> 
            <th>IGST</th>
            <th>IGST Amount</th>
        </tr>

        <tr> 
        <td>1</td>
        <td>`+ data?.product?.Name + `</td>
        <td>`+ data?.product?.Amount + `</td>
        <td>`+ data?.product?.Qty + `</td>
        <td>`+ data?.product?.Grand_Total + `</td>
        <td>`+ data?.product?.IGST + `</td>
        <td>`+ data?.product?.IGSTAmount + `</td>
        </tr>
        
        <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        </tr>
       
        </table>
                    </address>
                    </header>
                <aside>
                    <div contenteditable>
                        <p>Note: This is computer generated Invoice and signature is not required.</p>
                    </div>
                </aside>
            </body>
        </html>
`;
  }


  const printToFile = async (data) => {
    const html = getInvoice(data);
    if (Platform.OS == 'web') {
      const pW = window.open('', '', 'height=500, width=500')
      pW?.document.write(html);
      pW?.document.close();
    }
    else {
      try {
        const { uri } = await Print.printToFileAsync({ html });
        await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
      } catch (error) {
      }
    }
  }


  const getInvoiceadress = (data) => {
    return `
          <html>
          <head>
      <style>
      .grid-container {
      display: grid;
      grid-template-columns: auto auto;
      }.hr.solid {
      border-top: 5px solid #2EE1F4;
      }
      table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      }
      td{
      border: 1px solid #2EE1F4;
      text-align: right;
      padding: 8px;
      }
      th {
      border: 1px solid #2EE1F4;
      text-align: right;
      background-color: #2EE1F4;
      padding: 8px;
      }
      </style>
              <meta charset="utf-8">
              <title>Invoice</title>
              <script src="script.js"></script>
          </head>
          <body onclick="window.print()" >
              <header>
          <center>
                  <h1 style="color:#2EE1F4;">Invoice</h1>
          </center>
                  <address contenteditable>
          <hr class="solid">
          <div class="grid-container">
          <span>
          <h4>Billing Address:</h4>
          <p>`+ data?.BillingAddress?.Name + `</p>
          <p> `+ data?.BillingAddress?.AddressDrToSt + `</p>
          <p>`+ data?.BillingAddress?.City + `,` + data?.BillingAddress?.State + `,` + data?.BillingAddress?.Country + `</p>
          <p>`+ data?.BillingAddress?.PhoneNumber + `</p>
          <p>`+ data?.BillingAddress?.email + `</p>
          </span>
          <span style="text-align:right">
          <h4>Shipping Address:</h4>
          <p>`+ data?.ShippingAddress?.Name + `</p>
          <p> `+ data?.ShippingAddress?.AddressDrToSt + `</p>
          <p>`+ data?.ShippingAddress?.City + `,` + data?.ShippingAddress?.State + `,` + data?.ShippingAddress?.Country + `</p>
          <p>`+ data?.ShippingAddress?.PhoneNumber + `</p>
          <p>`+ data?.ShippingAddress?.email + `</p>
          </span>
          </div>
          <hr class="solid">
          <div class="grid-container">
          <span>
          <h4>Sold By:</h4>
          <p>`+ data?.Partner.Name + `</p>
              <p>`+ data?.Partner?.AddressDrToSt + `</p>
              <p>`+ data?.Partner?.City + `,` + data?.Partner?.State + `,` + data?.Partner?.Country + `</p>
              <p>`+ data?.Partner?.GST + `</p>
              <p>`+ data?.Partner?.Pan + `</p>

          </span>
          </div>
          <hr class="solid">
         
                  </address>
                  </header>
              <aside>
                  <div contenteditable>
                      <p>Note: This is computer generated Invoice and signature is not required.</p>
                  </div>
              </aside>
          </body>
      </html>
`;
  }
  const printToFileadress = async (data) => {
    const html = getInvoiceadress(data);
    if (Platform.OS == 'web') {
      const pW = window.open('', '', 'height=500, width=500')
      pW?.document.write(html);
      pW?.document.close();
    }
    else {
      try {
        const { uri } = await Print.printToFileAsync({ html });
        await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
      } catch (error) {
      }
    }
  }
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      flexShrink: 1,
    },
    texinputstyle: {
      paddingTop: 10,
      width: (Platform.OS == 'web') ? "49%" : "100%"
    },
    button: {
      marginTop: 30,
      width: '75%',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'green',
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1,
      marginBottom: "2%"
    },

    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    checkcontainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: "-70%"
    },
    phonenumbercontainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    phoneContainer: {
      width: '100%',
      height: 50,
    },
    phonetextInput: {
      paddingVertical: 0,
    },
    containerone: {
      paddingTop: 15
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <ScrollView>
        <Portal>
          <Dialog visible={visibleone} onDismiss={hideDialog} style={{ width: (dimensions >= 500) ? 400 : 270, maxHeight: (dimensions >= 500) ? 500 : 350, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.CBColor, borderColor: esiColor.SBorderColor, borderWidth: 1 }}>
            <View style={{ alignItems: 'flex-end' }}>
              <IconButton
                color={esiColor.GBFColor}
                icon="close"
                size={20}
                onPress={() => setVisibleOne(!visibleone)}
              />
            </View>
            <Dialog.Title style={{ marginLeft: 75, fontSize: 20, marginTop: -20 }}>
              <View style={{
                justifyContent: 'center', alignContent: 'center', alignItems: 'center',
                alignSelf: 'center', marginRight: 20,
              }}>
                <Text style={{ color: esiColor.itemColor, fontSize: 20 }}>Change Status</Text>
              </View>
            </Dialog.Title>
            <Dialog.ScrollArea>
              <ScrollView >
                <Dialog.Content>
                  <Formik initialValues={{
                    "Item_Data": {
                      "Item_Master_Data": [
                        {
                          "Item_Master_Id": data?.Item_Master_Id,
                          "Partner_Details_Id": partnerID,
                          "Item_Master_Update_Flag": "YES",
                          "Line_Item_Status": data?.Line_Item_Status,
                          "Product_Id": data?.Product_Id,
                        }
                      ]
                    },
                    "Cancel_Return_User_Comment": "",
                    "Order_Id": orderdata[0]?.Order_Id,
                    "Cancel_Return_User_Reason_Type": "",
                  }} validationSchema={Yup.object().shape({
                    Cancel_Return_User_Reason_Type: Yup.string().when("Order_Status", (Order_Status, schema) => {
                      if (Order_Status == "CANCELLED") {
                        return schema.required('Cancel Return User Reason Type is required.');
                      }
                    }),
                    Cancel_Return_User_Comment: Yup.string().when("Order_Status", (Order_Status, schema) => {
                      if (Order_Status == "CANCELLED") {
                        return schema.required('Cancel Return User Comment is required.');
                      }
                    }),
                  })}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting, setErrors }) => {

                      const errors = {};
                      let submitTest = true;
                      setSubmitting(false);
                      setErrors(errors);
                      if (submitTest) {
                        let formData = JSON.parse(JSON.stringify(values));
                        refreshCall()
                        dispatch(updateShippingAction(formData, props.navigation, toast, ID, EmailID.Partner_Details_Id))
                        setVisibleOne(!visibleone);
                      }
                    }
                    }>
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      setFieldValue,
                      resetForm
                    }) => (
                      <View>
                        <View style={{ flexGrow: 1, flexShrink: 1 }}>
                          <View style={styles.container}>
                            <View style={styles.containerone}>
                              <Text style={[styles.dropdownlabel]}>
                                Order Status
                              </Text>
                              <EsiSearchDropdown
                                data={allorderstatus}
                                style={[styles.dropdown]}
                                maxHeight={allorderstatus.length > 1 ? 250 : 200}
                                search={allorderstatus.length > 1 ? true : false}
                                labelField="label"
                                valueField="value"
                                searchPlaceholder={'Select  All Order Status'}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                value={values.Item_Data.Item_Master_Data[0].Line_Item_Status}
                                onUpdateValue={item => {
                                  setFieldValue('Item_Data.Item_Master_Data[0].Line_Item_Status', item.value)
                                  setShowDropDown(false);
                                }}
                              />
                            </View>
                          </View>
                        </View>
                        {values.Item_Data.Item_Master_Data[0].Line_Item_Status == 'CANCELLED' ?
                          <View>
                            <View style={{ flexGrow: 1, flexShrink: 1 }}>
                              <View style={styles.container}>
                                <View style={styles.containerone}>
                                  <Text style={[styles.dropdownlabel, Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type) && { color: '#b00020' }]}>
                                    Cancel Return User Reason Type
                                  </Text>
                                  <EsiSearchDropdown
                                    data={cancelreason}
                                    style={[styles.dropdown, Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type) && { borderColor: '#b00020' }]}
                                    maxHeight={cancelreason.length > 1 ? 250 : 200}
                                    search={cancelreason.length > 1 ? true : false}
                                    labelField="label"
                                    valueField="value"
                                    placeholderStyle={styles.placeholderStyle}
                                    selectedTextStyle={styles.selectedTextStyle}
                                    inputSearchStyle={styles.inputSearchStyle}
                                    iconStyle={styles.iconStyle}
                                    value={values.Cancel_Return_User_Reason_Type}
                                    onUpdateValue={item => {
                                      setFieldValue('Cancel_Return_User_Reason_Type', item.value)
                                      setCancelllShowDropDown(false);
                                    }}
                                  />
                                  <HelperText type="error" visible={Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type)}>
                                    {touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type}
                                  </HelperText>
                                </View>
                              </View>
                            </View>
                            <View style={{ flexGrow: 1, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                              <TextInput
                                // theme={customTheme}
                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                outlineColor={esiColor.TextOuterLine}
                                underlineColor={esiColor.TextUnderline}
                                activeOutlineColor={esiColor.TextOuterLine}
                                error={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}
                                label="Cancel_Return_User_Comment"
                                mode='outlined'
                                value={values.Cancel_Return_User_Comment}
                                onChangeText={handleChange("Cancel_Return_User_Comment")}
                                keyboardType='numeric'
                              />
                              <HelperText type="error" visible={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}>
                                {touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment}
                              </HelperText>
                            </View>
                          </View> : null}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                          <Button mode="outlined" textColor={esiColor.itemButtenColor} buttonColor={esiColor.globalButtonColor} disabled={isSubmited} mode="contained" onPress={handleSubmit} >
                            Save </Button>
                          <Button mode="outlined" textColor={esiColor.itemButtenColor} buttonColor={'gray'} onPress={() => { setVisibleOne(!visibleone) }} >
                            Cancel </Button>
                        </View>
                      </View>
                    )}
                  </Formik>
                </Dialog.Content>
              </ScrollView>
            </Dialog.ScrollArea>
          </Dialog>
        </Portal>
        <Portal>
          <Dialog visible={visibles} onDismiss={hideDialog} style={{ width: (dimensions >= 500) ? 400 : 320, maxHeight: (dimensions >= 500) ? 600 : 450, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.CBColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
            <View style={{ alignItems: 'flex-end' }}>
              <IconButton
                icon="close"
                size={20}
                color={esiColor.GBFColor}
                onPress={() => setVisibles(!visibles)}
              />
            </View>
            <Dialog.Title style={{ marginLeft: 75, fontSize: 20, marginTop: -20 }}>
              <View style={{
                justifyContent: 'center', alignContent: 'center', alignItems: 'center',
                alignSelf: 'center', marginRight: 20,
              }}>
                <Text style={{ color: esiColor.GBFColor, fontSize: 20 }}>Change Status</Text>
              </View>
            </Dialog.Title>
            <Dialog.ScrollArea>
              <ScrollView >
                <Dialog.Content>
                  <Formik initialValues={{
                    "Item_Data": {
                      "Item_Master_Data": [
                        {
                          "Item_Master_Id": pdata?.Item_Master_Id,
                          "Partner_Details_Id": partnerID,
                          "Item_Master_Update_Flag": "YES",
                          "Line_Item_Status": pdata?.Line_Item_Status,
                          "Product_Id": pdata?.Product_Id,
                          "Shipping_Address": {
                            "Item_Master_Shipping_Address_Update_Flag": "YES",
                            "Shipping_Address_Id": pdata?.Order_PickUp_Address_Id
                          },
                          "Shipping_Summary": {
                            "Item_Master_Shipping_Update_Flag": "YES",
                            "Shipping_Type": pdata?.Shipping_Summary_Shipping_Type,
                            "Delivery_Message": pdata?.Delivery_Message,
                            "Live_Delivered_By_Email": pdata?.Shipping_Summary_Live_Delivered_By_Email,
                            "Order_Delivery_Transactions": {
                              "Del_Order_Id": pdata?.Del_Order_Id,
                              "Order_Delivery_Transaction_Update_Flag": "YES"
                            },
                          }
                        }
                      ]
                    },
                    "Order_Id": orderdata[0]?.Order_Id,
                    "Cancel_Return_User_Comment": "",
                    "Cancel_Return_User_Reason_Type": "",
                  }} validationSchema={Yup.object().shape({
                    Cancel_Return_User_Reason_Type: Yup.string().when("Item_Data.Item_Master_Data[0].Line_Item_Status", (Line_Item_Status, schema) => {
                      if (Line_Item_Status == "CANCELLED") {
                        return schema.required('Cancel_Return_User_Reason_Type is required.');
                      }
                    }),
                    Cancel_Return_User_Comment: Yup.string().when("Item_Data.Item_Master_Data[0].Line_Item_Status", (Line_Item_Status, schema) => {
                      if (Line_Item_Status == "CANCELLED") {
                        return schema.required('Cancel_Return_User_Comment is required.');
                      }
                    }),
                  })}

                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      const errors = {};
                      let submitTest = true;
                      setSubmitting(false);
                      setErrors(errors);
                      if (submitTest) {
                        let formValues = JSON.parse(JSON.stringify(values));
                        dispatch(updateShippingAction(formValues, props.navigation, toast, ID, EmailID.Partner_Details_Id))
                        setVisibles(!visibles);
                      }
                    }
                    }>
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      setFieldValue,
                      resetForm
                    }) => (
                      <View>
                        <View style={{ flexGrow: 1, flexShrink: 1 }}>
                          <View style={styles.container}>
                            <View style={styles.containerone}>
                              <Text style={[styles.dropdownlabel]}>
                                Order Status
                              </Text>
                              <EsiSearchDropdown
                                data={allorderstatus}
                                style={[styles.dropdown]}
                                maxHeight={allorderstatus.length > 1 ? 250 : 200}
                                search={allorderstatus.length > 1 ? true : false}
                                labelField="label"
                                valueField="value"
                                searchPlaceholder={'Select  All Order Status'}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                value={values.Item_Data.Item_Master_Data[0].Line_Item_Status}
                                onUpdateValue={item => {
                                  setFieldValue('Item_Data.Item_Master_Data[0].Line_Item_Status', item.value)
                                  setOrderShowDropDown(false);
                                }}
                              />
                            </View>
                          </View>
                        </View>
                        {values.Item_Data.Item_Master_Data[0].Line_Item_Status == 'CANCELLED' ?
                          <View>
                            <View style={{ flexGrow: 1, flexShrink: 1 }}>
                              <View style={styles.container}>
                                <View style={styles.containerone}>
                                  <Text style={[styles.dropdownlabel, Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type) && { color: '#b00020' }]}>
                                    Cancel Return User Reason Type
                                  </Text>
                                  <EsiSearchDropdown
                                    data={cancelreason}
                                    style={[styles.dropdown, Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type) && { borderColor: '#b00020' }]}
                                    maxHeight={cancelreason.length > 1 ? 250 : 200}
                                    search={cancelreason.length > 1 ? true : false}
                                    labelField="label"
                                    valueField="value"
                                    placeholderStyle={styles.placeholderStyle}
                                    selectedTextStyle={styles.selectedTextStyle}
                                    inputSearchStyle={styles.inputSearchStyle}
                                    iconStyle={styles.iconStyle}
                                    value={values.Cancel_Return_User_Reason_Type}
                                    onUpdateValue={item => {
                                      setFieldValue('Cancel_Return_User_Reason_Type', item.value)
                                      setCancellShowDropDown(false);
                                    }}
                                  />
                                  <HelperText type="error" visible={Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type)}>
                                    {touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type}
                                  </HelperText>
                                </View>
                              </View>
                            </View>
                            <View style={{ flexGrow: 1, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                              <TextInput
                                // theme={customTheme}
                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                outlineColor={esiColor.TextOuterLine}
                                underlineColor={esiColor.TextUnderline}
                                activeOutlineColor={esiColor.TextOuterLine}
                                error={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}
                                label="Cancel_Return_User_Comment"
                                mode='outlined'
                                value={values.Cancel_Return_User_Comment}
                                onChangeText={handleChange("Cancel_Return_User_Comment")}
                                keyboardType='numeric'
                              />
                              <HelperText type="error" visible={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}>
                                {touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment}
                              </HelperText>
                            </View>
                          </View> : null}
                        <View style={{ flexGrow: 1, flexShrink: 1 }}>
                          <View style={styles.container}>
                            <View style={styles.containerone}>
                              <Text style={[styles.dropdownlabel]}>
                                Del User Email ID
                              </Text>
                              <EsiSearchDropdown
                                data={deliverypersonslist}
                                style={[styles.dropdown]}
                                maxHeight={deliverypersonslist.length > 1 ? 250 : 200}
                                search={deliverypersonslist.length > 1 ? true : false}
                                labelField="label"
                                valueField="value"
                                searchPlaceholder={'Select Del User Email ID'}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                value={values.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Email}
                                onUpdateValue={item => {
                                  setFieldValue('Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Email', item.value)
                                  setDeliveryPersonDropDown(false);
                                }}
                              />
                            </View>
                          </View>
                        </View>
                        <View style={{ flexGrow: 1, flexShrink: 1 }}>
                          <View style={styles.container}>
                            <View style={styles.containerone}>
                              <Text style={[styles.dropdownlabel]}>
                                PickUp Address ID
                              </Text>
                              <EsiSearchDropdown
                                data={partneraddresslist}
                                style={[styles.dropdown]}
                                maxHeight={partneraddresslist.length > 1 ? 250 : 200}
                                search={partneraddresslist.length > 1 ? true : false}
                                labelField="label"
                                valueField="value"
                                searchPlaceholder={'Select PickUp Address ID'}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                value={values.Item_Data.Item_Master_Data[0].Shipping_Address.Shipping_Address_Id}
                                onUpdateValue={item => {
                                  item &&
                                    setFieldValue('Item_Data.Item_Master_Data[0].Shipping_Address.Shipping_Address_Id', item.value)
                                  setFieldValue('Shipping_Address_Id', item?.Shipping_Address_Id)
                                  setOrderAddressDropDown(false);
                                }}
                              />
                            </View>
                          </View>
                        </View>
                        <View style={{ flexGrow: 1, flexShrink: 1 }}>
                          <View style={styles.container}>
                            <View style={styles.containerone}>
                              <Text style={[styles.dropdownlabel]}>
                                Reason for Delay
                              </Text>
                              <EsiSearchDropdown
                                data={reasonfordelaylist}
                                style={[styles.dropdown]}
                                maxHeight={deliverypersonslist.length > 1 ? 250 : 200}
                                search={deliverypersonslist.length > 1 ? true : false}
                                labelField="label"
                                valueField="value"
                                searchPlaceholder={'Select Reason for Delay'}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                value={values.Item_Data.Item_Master_Data[0].Shipping_Summary.Delivery_Message}
                                onUpdateValue={item => {
                                  setFieldValue('Item_Data.Item_Master_Data[0].Shipping_Summary.Delivery_Message', item.value)
                                  setreasonfordelayDropDown(false);
                                }}
                              />
                            </View>
                          </View>
                        </View>
                        <View style={{ flexGrow: 1, flexShrink: 1 }}>
                          <View style={styles.container}>
                            <View style={styles.containerone}>
                              <Text style={[styles.dropdownlabel]}>
                                Shipping Type
                              </Text>
                              <EsiSearchDropdown
                                data={shippingtypelist}
                                style={[styles.dropdown]}
                                maxHeight={deliverypersonslist.length > 1 ? 250 : 200}
                                search={deliverypersonslist.length > 1 ? true : false}
                                labelField="label"
                                valueField="value"
                                searchPlaceholder={'Select Shipping Type'}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                value={values.Item_Data.Item_Master_Data[0].Shipping_Summary.Shipping_Type}
                                onUpdateValue={item => {
                                  setFieldValue('Item_Data.Item_Master_Data[0].Shipping_Summary.Shipping_Type', item.value)
                                  setShippingTypeDropDown(false);
                                }}
                              />
                            </View>
                          </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                          <Button mode="outlined" textColor={esiColor.itemButtenColor} buttonColor={esiColor.globalButtonColor} disabled={isSubmited} mode="contained" onPress={handleSubmit} >
                            Save </Button>
                          <Button mode="outlined" textColor={esiColor.itemButtenColor} buttonColor={'gray'} onPress={() => { setVisibles(!visibles) }} >
                            Cancel </Button>
                        </View>
                      </View>
                    )}
                  </Formik>
                </Dialog.Content>
              </ScrollView>
            </Dialog.ScrollArea>
          </Dialog>
        </Portal>
        {orderdata?.length == '0' ? <View style={{
          flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
          justifyContent: 'center', maxWidth: 450
        }}>
          <View style={{ width: "100%", margin: "auto" }}>
            <ActivityIndicator color='#27b6cc' />
          </View>
        </View> : <Formik
          enableReinitialize={true}
          initialValues={{
            "Item_Data": {
              "Item_Master_Data": [
                {
                  "Partner_Details_Id": partnerID,
                  "Shipping_Address": {
                    "Item_Master_Shipping_Address_Update_Flag": "YES",
                    "Shipping_Address_Id": " "
                  },
                  "Shipping_Summary": {
                    "Item_Master_Shipping_Update_Flag": "YES",
                    "Live_Delivered_By_Email": " ",
                    "Order_Delivery_Transactions": {
                      "Del_Order_Id": orderdata[0].Del_Order_Id,
                      "Order_Delivery_Transaction_Update_Flag": "YES"
                    },
                  }
                }
              ]
            },
            "Cancel_Return_User_Comment": "",
            "Cancel_Return_User_Reason_Type": "",
            "Order_Id": orderdata[0]?.Order_Id,
            "Order_Update_Type": 'O',
            "Order_Status": orderdata[0]?.Order_Status,
            "Order_Update_Flag": "YES",
          }}
          validationSchema={Yup.object().shape({
            Cancel_Return_User_Reason_Type: Yup.string().when("Order_Status", (Order_Status, schema) => {
              if (Order_Status == "CANCELLED") {
                return schema.required('Cancel Return User Reason Type is required.');
              }
            }),
            Cancel_Return_User_Comment: Yup.string().when("Order_Status", (Order_Status, schema) => {
              if (Order_Status == "CANCELLED") {
                return schema.required('Cancel Return User Comment is required.');
              }
            }),
          })}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            let submitTest = true;
            setSubmitting(false);
            if (values) {
              let formValues = JSON.parse(JSON.stringify(values));
              dispatch(updateShippingAction(formValues, props.navigation, toast, ID, EmailID.Partner_Details_Id));
            }
          }
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            resetForm
          }) => (
            <View style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              flexWrap: "wrap"
            }}>
              {ID == '1' ? <View style={{ flexDirection: 'row', marginTop: "2%", marginBottom: "2%", justifyContent: 'space-between' }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('ShippingGet')}>
                  Go Back
                </Button>
              </View> :
                <View style={{ flexDirection: 'row', marginTop: "2%", marginBottom: "2%", justifyContent: 'space-between' }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('OrderDetails')}>
                    Go Back
                  </Button>
                </View>}

              <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 1, backgroundColor: esiColor.CBColor, borderColor: esiColor.SBorderColor }}>
                <Card.Content>
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                    {Orders?.Order_Edit == '1' ? "Update Order level" : "Update order"}
                  </Text>
                  <View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15, backgroundColor: esiColor.CBColor }}>
                      <View style={{ width: dimensions / 2, justifyContent: 'space-between' }}>
                        <Text style={{ fontWeight: 'bold', paddingBottom: 15, color: esiColor.Text }}>Billing Address</Text>
                        <Text style={{ color: esiColor.Text }}>Billing Address Type: {orderdata[0]?.Address_Type}</Text>
                        <Text style={{ color: esiColor.itemColor }}>{orderdata[0]?.Door_No} {","}{orderdata[0]?.Location}</Text>
                        <Text style={{ color: esiColor.itemColor }}>{orderdata[0]?.Street}</Text>
                        <Text style={{ color: esiColor.itemColor }}>{orderdata[0]?.City}{","}{orderdata[0]?.State}</Text>
                        <Text style={{ color: esiColor.itemColor }}>{orderdata[0]?.Country}{","}{orderdata[0]?.Zip}</Text>
                        <Text style={{ color: esiColor.itemColor }}>{orderdata[0]?.Mobile}</Text>
                        <Text style={{ color: esiColor.itemColor }}>{orderdata[0]?.Email_Id}</Text>
                      </View>
                      <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-start', flexWrap: 'wrap', paddingTop: 10 }}>
                        <View style={{ width: dimensions / 2, justifyContent: 'space-between', paddingRight: 20 }}>

                          {orderdata[0] && <Text style={{ fontWeight: 'bold', paddingBottom: 15, color: esiColor.Text }}>Billing Amount</Text>}
                          {orderdata[0]?.Cashback_To_User == '0' || orderdata[0]?.Cashback_To_User == '0.0' || orderdata[0]?.Cashback_To_User == undefined ? null : <Text style={{ color: esiColor.Text }}>User cashBack: {orderdata[0]?.Cashback_To_User}</Text>}
                          {orderdata[0]?.Corp_Coupon_Amount == '0' || orderdata[0]?.Corp_Coupon_Amount == '0.0' || orderdata[0]?.Corp_Coupon_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Corp coupon Amount: {orderdata[0]?.Corp_Coupon_Amount}</Text>}
                          {orderdata[0]?.Coupon_Amount == '0' || orderdata[0]?.Coupon_Amount == '0.0' || orderdata[0]?.Coupon_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Coupon Amount: {orderdata[0]?.Coupon_Amount}</Text>}
                          {orderdata[0]?.Delivery_Person_Tip == '0' || orderdata[0]?.Delivery_Person_Tip == '0.0' || orderdata[0]?.Delivery_Person_Tip == undefined ? null : <Text style={{ color: esiColor.Text }}>Delivery Person Tip: {orderdata[0]?.Delivery_Person_Tip}</Text>}
                          {orderdata[0]?.Gift_Voucher_Amount == '0' || orderdata[0]?.Gift_Voucher_Amount == '0.0' || orderdata[0]?.Gift_Voucher_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Gift Voucher Amount: {orderdata[0]?.Gift_Voucher_Amount}</Text>}
                          {orderdata[0]?.Meepaisa_Gift_Wrap_Amount == '0' || orderdata[0]?.Meepaisa_Gift_Wrap_Amount == '0.0' || orderdata[0]?.Meepaisa_Gift_Wrap_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepaisa Gift Wrap Amount: {orderdata[0]?.Meepaisa_Gift_Wrap_Amount}</Text>}
                          {orderdata[0]?.Meepaisa_Packaging_Charges == '0' || orderdata[0]?.Meepaisa_Packaging_Charges == '0.0' || orderdata[0]?.Meepaisa_Packaging_Charges == undefined ? null : <Text style={{ color: esiColor.Text }}>Packaging Charges: {orderdata[0]?.Meepaisa_Packaging_Charges}</Text>}
                          {orderdata[0]?.Meepaisa_Shipping_Fee == '0' || orderdata[0]?.Meepaisa_Shipping_Fee == '0.0' || orderdata[0]?.Meepaisa_Shipping_Fee == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepaisa Shipping Fee: {orderdata[0]?.Meepaisa_Shipping_Fee}</Text>}
                          {orderdata[0]?.Meepaisa_Total_Tax_Amount == '0' || orderdata[0]?.Meepaisa_Total_Tax_Amount == '0.0' || orderdata[0]?.Meepaisa_Total_Tax_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepaisa Total Tax Amount: {orderdata[0]?.Meepaisa_Total_Tax_Amount}</Text>}
                          {orderdata[0]?.Meepiasa_Billable_Amount_After_Taxes == '0' || orderdata[0]?.Meepiasa_Billable_Amount_After_Taxes == '0.0' || orderdata[0]?.Meepiasa_Billable_Amount_After_Taxes == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepiasa After Taxes: {orderdata[0]?.Meepiasa_Billable_Amount_After_Taxes}</Text>}
                          {orderdata[0]?.Meepiasa_Billable_Amount_Before_Taxes == '0' || orderdata[0]?.Meepiasa_Billable_Amount_Before_Taxes == '0.0' || orderdata[0]?.Meepiasa_Billable_Amount_Before_Taxes == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepiasa Before Taxes: {orderdata[0]?.Meepiasa_Billable_Amount_Before_Taxes}</Text>}
                          {orderdata[0]?.Total_Order_Amount == '0' || orderdata[0]?.Total_Order_Amount == '0.0' || orderdata[0]?.Total_Order_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Total Order Amount: {orderdata[0]?.Total_Order_Amount}</Text>}
                          {orderdata[0]?.User_Billable_Amount_After_Taxes == '0' || orderdata[0]?.User_Billable_Amount_After_Taxes == '0.0' || orderdata[0]?.User_Billable_Amount_After_Taxes == undefined ? null : <Text style={{ color: esiColor.Text }}>User After Taxes: {orderdata[0]?.User_Billable_Amount_After_Taxes}</Text>}
                          {orderdata[0]?.User_Billable_Amount_Before_Taxes == '0' || orderdata[0]?.User_Billable_Amount_Before_Taxes == '0.0' || orderdata[0]?.User_Billable_Amount_Before_Taxes == undefined ? null : <Text style={{ color: esiColor.Text }}>User Before Taxes: {orderdata[0]?.User_Billable_Amount_Before_Taxes}</Text>}
                          {orderdata[0]?.User_Shipping_Fee == '0' || orderdata[0]?.User_Shipping_Fee == '0.0' || orderdata[0]?.User_Shipping_Fee == undefined ? null : <Text style={{ color: esiColor.Text }}>User Shipping Fee: {orderdata[0]?.User_Shipping_Fee}</Text>}
                          {orderdata[0]?.User_Total_Tax_Amount == '0' || orderdata[0]?.User_Total_Tax_Amount == '0.0' || orderdata[0]?.User_Total_Tax_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>User Total Tax Amount: {orderdata[0]?.User_Total_Tax_Amount}</Text>}
                          {orderdata[0]?.Wallet_Amount == '0' || orderdata[0]?.Wallet_Amount == '0.0' || orderdata[0]?.Wallet_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Wallet Amount: {orderdata[0]?.Wallet_Amount}</Text>}
                        </View>
                      </View>
                    </View>
                    {Orders?.Order_Edit == '1' ? <View>
                      <View style={{ flexGrow: 1, flexShrink: 1 }}>
                        <View style={styles.container}>
                          <View style={styles.containerone}>
                            <Text style={[styles.dropdownlabel]}>
                              All Order Status
                            </Text>
                            <EsiSearchDropdown
                              data={allorderstatus}
                              style={[styles.dropdown]}
                              maxHeight={allorderstatus.length > 1 ? 250 : 200}
                              search={allorderstatus.length > 1 ? true : false}
                              labelField="label"
                              valueField="value"
                              disable={Orders?.Order_Edit == '1' ? false : true}
                              searchPlaceholder={'Select All Order Status'}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              value={values.Order_Status}
                              onUpdateValue={item => {
                                setFieldValue('Order_Status', item.value)
                                setOrderShowDropDown(false);
                              }}
                            />
                          </View>
                        </View>
                      </View>
                      {values.Order_Status == 'CANCELLED' ?
                        <View>
                          <View style={{ flexGrow: 1, flexShrink: 1 }}>
                            <View style={styles.container}>
                              <View style={styles.containerone}>
                                <Text style={[styles.dropdownlabel, Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type) && { color: '#b00020' }]}>
                                  Cancel Return User Reason Type
                                </Text>
                                <EsiSearchDropdown
                                  data={cancelreason}
                                  style={[styles.dropdown, Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type) && { borderColor: '#b00020' }]}
                                  maxHeight={cancelreason.length > 1 ? 250 : 200}
                                  search={cancelreason.length > 1 ? true : false}
                                  labelField="label"
                                  valueField="value"
                                  placeholderStyle={styles.placeholderStyle}
                                  selectedTextStyle={styles.selectedTextStyle}
                                  inputSearchStyle={styles.inputSearchStyle}
                                  iconStyle={styles.iconStyle}
                                  value={values.Cancel_Return_User_Reason_Type}
                                  onUpdateValue={item => {
                                    setFieldValue('Cancel_Return_User_Reason_Type', item.value)
                                    setCancelShowDropDown(false);
                                  }}
                                />
                                <HelperText type="error" visible={Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type)}>
                                  {touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type}
                                </HelperText>
                              </View>
                            </View>
                          </View>
                          <View style={{ flexGrow: 1, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                            <TextInput
                              // theme={customTheme}
                              style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                              outlineColor={esiColor.TextOuterLine}
                              underlineColor={esiColor.TextUnderline}
                              activeOutlineColor={esiColor.TextOuterLine}
                              error={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}
                              label="Cancel_Return_User_Comment"
                              mode='outlined'
                              value={values.Cancel_Return_User_Comment}
                              onChangeText={handleChange("Cancel_Return_User_Comment")}
                              keyboardType='numeric'
                            />
                            <HelperText type="error" visible={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}>
                              {touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment}
                            </HelperText>
                          </View>
                        </View> : null}
                      <View style={{ flexGrow: 1, flexShrink: 1 }}>
                        <View style={styles.container}>
                          <View style={styles.containerone}>
                            <Text style={[styles.dropdownlabel]}>
                              Del User Email ID
                            </Text>
                            <EsiSearchDropdown
                              data={deliverypersonslist}
                              style={[styles.dropdown]}
                              maxHeight={deliverypersonslist.length > 1 ? 250 : 200}
                              search={deliverypersonslist.length > 1 ? true : false}
                              labelField="label"
                              disable={Orders?.Order_Edit == '1' ? false : true}
                              valueField="value"
                              searchPlaceholder={'Select  Del User Email ID'}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              value={values.Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Email}
                              onUpdateValue={item => {
                                setFieldValue('Item_Data.Item_Master_Data[0].Shipping_Summary.Live_Delivered_By_Email', item.value)
                                setDeliveryPersonDropDown(false);
                              }}
                            />
                          </View>
                        </View>
                      </View>
                      <View style={{ flexGrow: 1, flexShrink: 1 }}>
                        <View style={styles.container}>
                          <View style={styles.containerone}>
                            <Text style={[styles.dropdownlabel]}>
                              PickUp Address ID
                            </Text>
                            <EsiSearchDropdown
                              data={partneraddresslist}
                              style={[styles.dropdown]}
                              maxHeight={partneraddresslist.length > 1 ? 250 : 200}
                              search={partneraddresslist.length > 1 ? true : false}
                              labelField="label"
                              valueField="value"
                              disable={Orders?.Order_Edit == '1' ? false : true}
                              searchPlaceholder={'Select PickUp Address ID'}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              value={values.Item_Data.Item_Master_Data[0].Shipping_Address.Shipping_Address_Id}
                              onUpdateValue={item => {
                                item &&
                                  setFieldValue('Item_Data.Item_Master_Data[0].Shipping_Address.Shipping_Address_Id', item.value)
                                setFieldValue('Shipping_Address_Id', item?.Shipping_Address_Id)
                                setOrderAddressDropDown(false);
                              }}
                            />
                          </View>
                        </View>
                      </View>
                      <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                        <Button textColor={esiColor.itemButtenColor} disabled={isSubmited || Orders?.Order_Edit == '0'} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                          Save
                        </Button>
                      </View>
                    </View> : null}
                  </View>
                </Card.Content>
              </Card>
              {allOrderDetails.map((data, index) => (
                <View key={index}>
                  <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, backgroundColor: esiColor.CBColor, borderColor: esiColor.SBorderColor, borderWidth: 1 }}>
                    <Card.Content>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15 }}>
                        <View style={{ width: (dimensions / 3) }}>
                          <Text style={{ fontWeight: 'bold', paddingBottom: 15, color: esiColor.Text }}>Shipping Address</Text>
                          <Text style={{ color: esiColor.itemColor }}>{data.Shipping_Address_Type}</Text>
                          <Text style={{ color: esiColor.itemColor }}>{data.Shipping_Address_Shipping_Address_Id}</Text>
                          <Text style={{ color: esiColor.itemColor }}>{data.Shipping_Address_Created_By}</Text>
                          <Text style={{ color: esiColor.itemColor }}>{data.Shipping_Address_Door_No}{","}{data.Shipping_Address_Location}</Text>
                          <Text style={{ color: esiColor.itemColor }}>{data.Shipping_Address_Street}{","}{data.Shipping_Address_Land_Mark}</Text>
                          <Text style={{ color: esiColor.itemColor }}>{data.Shipping_Address_City}{","}{data.Shipping_Address_State}</Text>
                          <Text style={{ color: esiColor.itemColor }}>{data.Shipping_Address_Country}{","}{data.Shipping_Address_Zip}</Text>
                          <Text style={{ color: esiColor.itemColor }}>{data.Shipping_Address_Mobile}</Text>
                          <Text style={{ color: esiColor.itemColor }}>{data.Shipping_Address_Email_Id}</Text>
                        </View>
                        <View style={{ flexDirection: 'column', justifyContent: 'flex-start', paddingTop: 10, width: (dimensions / 2.5) - 10 }}>
                          <Text style={{ fontWeight: 'bold', paddingBottom: 15, color: esiColor.Text }}>Product Details</Text>
                          <Image
                            source={{ uri: data.Product_Image }}
                            style={{ width: 100, height: 100 }} />
                          <Text style={{ color: esiColor.itemColor }}>{data.Product_Name}</Text>
                          <View style={{ flexDirection: 'row' }}>
                            <Text style={{ color: esiColor.Text }}>Status:</Text>
                            <Text style={{ color: data.Line_Item_Status == "SHIPPED" ? "orange" : esiColor.itemColor || data.Line_Item_Status == "DELIVERED" ? esiColor.SIconColor : esiColor.itemColor }}> {data.Line_Item_Status}</Text>
                          </View>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: 15 }}>
                        <View style={{ width: (dimensions / 3), justifyContent: 'space-between' }}>
                          <Text style={{ fontWeight: 'bold', paddingBottom: 15, color: esiColor.Text }}>Order Item Details </Text>
                          <Text style={{ color: esiColor.Text }}>Order ID:<Text style={{ color: esiColor.itemColor }}>{data.Order_Id}</Text> </Text>
                          <Text style={{ color: esiColor.Text }}>Item Master ID:<Text style={{ color: esiColor.itemColor }}>{data.Item_Master_Id}</Text>  </Text>
                          <Text style={{ color: esiColor.Text }}>Product ID:<Text style={{ color: esiColor.itemColor }}>{data.Product_Id}</Text> </Text>
                          <Text style={{ color: esiColor.Text }}>Price:<Text style={{ color: esiColor.itemColor }}>{data.Selling_Price}</Text> </Text>
                          <Text style={{ color: esiColor.Text }}>Quantity:<Text style={{ color: esiColor.itemColor }}>{data.Quantity}</Text> </Text>
                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', paddingTop: 10, width: (dimensions / 2.5) }}>
                          <Text style={{ fontWeight: 'bold', paddingBottom: 15, color: esiColor.Text }}>Price Summary</Text>
                          {data?.Item_Financial_Partner_Total_Price == '0' || data?.Item_Financial_Partner_Total_Price == '0.0' || data?.Item_Financial_Partner_Total_Price == undefined ? null : <Text style={{ color: esiColor.Text }}>Partner Total Price: <Text style={{ color: esiColor.itemColor }}>{data?.Item_Financial_Partner_Total_Price}</Text> </Text>}
                          {data?.Item_Financial_Meepaisa_Billable_Amount == '0' || data?.Item_Financial_Meepaisa_Billable_Amount == '0.0' || data?.Item_Financial_Meepaisa_Billable_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepaisa Billable Amount: <Text style={{ color: esiColor.itemColor }}>{data?.Item_Financial_Meepaisa_Billable_Amount}</Text></Text>}
                          {data?.Item_Financial_Meepaisa_Gift_Wrap_Amount == '0' || data?.Item_Financial_Meepaisa_Gift_Wrap_Amount == '0.0' || data?.Item_Financial_Meepaisa_Gift_Wrap_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepaisa Gift Wrap Amount: <Text style={{ color: esiColor.itemColor }}>{data?.Item_Financial_Meepaisa_Gift_Wrap_Amount}</Text></Text>}
                          {data?.Item_Financial_Meepaisa_Packaging_Charges == '0' || data?.Item_Financial_Meepaisa_Packaging_Charges == '0.0' || data?.Item_Financial_Meepaisa_Packaging_Charges == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepaisa Packaging Charges:<Text style={{ color: esiColor.itemColor }}>{data?.Item_Financial_Meepaisa_Packaging_Charges}</Text> </Text>}
                          {data?.Item_Financial_Meepaisa_Shipping_Fee == '0' || data?.Item_Financial_Meepaisa_Shipping_Fee == '0.0' || data?.Item_Financial_Meepaisa_Shipping_Fee == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepaisa Shipping Fee:<Text style={{ color: esiColor.itemColor }}>{data?.Item_Financial_Meepaisa_Shipping_Fee}</Text> </Text>}
                          {data?.Item_Financial_Meepaisa_Total_Tax_Amount == '0' || data?.Item_Financial_Meepaisa_Total_Tax_Amount == '0.0' || data?.Item_Financial_Meepaisa_Total_Tax_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>Meepaisa Total Tax Amount:<Text style={{ color: esiColor.itemColor }}>{data?.Item_Financial_Meepaisa_Total_Tax_Amount}</Text></Text>}
                          {data?.Item_Financial_Net_Price == '0' || data?.Item_Financial_Net_Price == '0.0' || data?.Item_Financial_Net_Price == undefined ? null : <Text style={{ color: esiColor.Text }}>Net Price:<Text style={{ color: esiColor.itemColor }}>{data?.Item_Financial_Net_Price}</Text> </Text>}
                          {data?.Item_Financial_User_Billable_Amount == '0' || data?.Item_Financial_User_Billable_Amount == '0.0' || data?.Item_Financial_User_Billable_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>User Billable Amount: <Text style={{ color: esiColor.itemColor }}>{data?.Item_Financial_User_Billable_Amount}</Text></Text>}
                          {data?.Item_Financial_User_Total_Tax_Amount == '0' || data?.Item_Financial_User_Total_Tax_Amount == '0.0' || data?.Item_Financial_User_Total_Tax_Amount == undefined ? null : <Text style={{ color: esiColor.Text }}>User Total Tax Amount: <Text style={{ color: esiColor.itemColor }}>{data?.Item_Financial_User_Total_Tax_Amount}</Text></Text>}
                        </View>
                      </View>
                      <View>
                        {Orders?.Order_Type == "RETAIL" ? <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly', paddingTop: 15 }}>
                          <View>
                            {data.Line_Item_Status == 'NEW' || data.Line_Item_Status == 'ORDERED' || data.Line_Item_Status == 'ACCEPTED' || data.Line_Item_Status == 'PACKED' ? <View style={{ width: 175, marginLeft: "auto", marginRight: "auto" }}>
                              <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5 }}
                                onPress={() => { setData(data); setVisibleOne(!visibleone), setSubmit(!issubmitted) }}
                              >
                                {/* <View > */}
                                <Text style={{ color: esiColor.itemButtenColor }}> Change Status</Text>
                                {/* </View> */}
                              </TouchableOpacity>
                            </View> :
                              <View style={{ width: 200, marginLeft: "auto", marginRight: "auto" }}>
                                <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5 }}
                                  onPress={() => { setPData(data); shippingAddress(data.Shipping_Address_Shipping_Address_Id), setVisibles(!visibles), setSubmit(!issubmitted) }}
                                >
                                  {/* <View > */}
                                  <Text style={{ color: esiColor.itemButtenColor }}>Assign Delivery person</Text>
                                  {/* </View> */}
                                </TouchableOpacity>
                              </View>}
                          </View>
                          {/* for live delivery person */}
                          {data.Line_Item_Status == "NEW" || data.Shipping_Summary_Shipping_Type == "Live" ?
                            <View
                              style={{
                                shadowColor: esiColor.brandShadowColor,
                                shadowOffset: { width: -2, height: 4 },
                                shadowOpacity: 0.2,
                                shadowRadius: 3,
                                marginHorizontal: 1, padding: 1, margin: 'auto',
                                backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS == 'web' ? 15 : 0),
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                elevation: 5,
                                maxWidth: (Platform.OS == 'web' ? 650 : 550),
                                // maxHeight: windowHeight > 250 ? 250 : 75,
                                minHeight: 100,
                                borderColor: esiColor.SBorderColor,
                                borderWidth: 0.5,
                                borderRadius: 10
                              }}
                            >
                              <TouchableOpacity onPress={() => {
                                if (data?.Line_Item_Status == "IN-TRANSIT" && data?.Shipping_Summary_Shipping_Type == "LIVE") {
                                  navigation.navigate("OrderTracking", { ID: data?.Item_Master_Id });
                                }
                              }} >

                                <View style={{ flex: 2, flexDirection: 'column' }}  >
                                  <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.Text }}>{data?.Shipping_Summary_Live_Delivered_By_Name}</Text>
                                  <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.Text }}>{data?.Shipping_Summary_Live_Delivery_Person_Phone}</Text>
                                </View>
                              </TouchableOpacity>
                            </View> :
                            null}
                          {(Orders?.Order_Status != "CANCELLED" && Orders?.Order_Status != "RETURNED") &&

                            <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5, height: 30, width: 130 }}
                              onPress={() => { setItem(data.Item_Master_Id) }}>
                              {/* onPress={printToFile}> */}
                              {/* <View > */}
                              <Text style={{ color: esiColor.itemButtenColor }} >Download Invoice</Text>
                              {/* </View> */}
                            </TouchableOpacity>
                          }
                          {(Orders?.Order_Status != "CANCELLED" && Orders?.Order_Status != "RETURNED") &&

                            <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginTop: 5, height: 30, width: 75 }}
                              onPress={() => { setItemadress(data.Item_Master_Id) }}>
                              {/* onPress={printToFile}> */}
                              {/* <View > */}
                              <Text style={{ color: esiColor.itemButtenColor }} >Print</Text>
                              {/* </View> */}
                            </TouchableOpacity>
                          }
                        </View> : null}
                      </View>
                    </Card.Content>
                  </Card>
                </View>))}
            </View>
          )}
        </Formik>}
      </ScrollView >
    </Surface >
  );
};

export default EditOrders;