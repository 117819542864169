import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
const ENV = {
    dev: {
        BOOMI_HOST: "https://apiqa.meepaisa.com/ws/rest/ESICB",
        BOOMI_HOST_PAR: "https://apiqa.meepaisa.com/ws/rest/ESICB_Partner",
        BOOMI_HOST_DEL: "https://apiqa.meepaisa.com/ws/rest/Meepaisa_Delivery",
        USERNAME_BOOMI: 'meepaisa_qa@easystepin-6WV1UR',
        PASSWORD_BOOMI: 'c44bafa4-3f94-457f-9a95-32df8110ef9c',
        NODE_HOST_PAR_CUD: 'https://cud-uat-partner.meepaisa.com',
        NODE_HOST: "https://node-checkproduct-uat.meepaisa.com",
        NODE_HOST_PAR: "https://node-partner-uat.meepaisa.com",
        USERNAME_NODE: 'ESICashback@easystepin-6WV1UR',
        PASSWORD_NODE: 'b0b963cf-41ee-4240-8767-7c4921b460f8',
        NODE_USER_HOST: "https://node-user-uat.meepaisa.com",
        USERNAME_USER_NODE: 'ESICashback@easystepin-6WV1UR',
        PASSWORD_USER_NODE: 'b0b963cf-41ee-4240-8767-7c4921b460f8',
        BASEURL: "https://partner-uat.meepaisa.com/",
        API_KEY: "rzp_test_r0cSwenhbbzJdF",
        API_SECRET: "xQkvgc1WkuqxIPLui68q6Lbk"
    },
    prod: {
        BOOMI_HOST: "https://apiprod.meepaisa.com/ws/rest/ESICB",
        BOOMI_HOST_PAR: "https://apiprod.meepaisa.com/ws/rest/ESICB_Partner",
        BOOMI_HOST_DEL: "https://apiprod.meepaisa.com/ws/rest/Meepaisa_Delivery",
        USERNAME_BOOMI: "meepaisa_prod@easystepin-6WV1UR",
        PASSWORD_BOOMI: "4c32aef7-de87-44b9-b44c-ca2ca5d92092",
        NODE_HOST_PAR_CUD: 'https://cud-partner.meepaisa.com',
        NODE_HOST: "https://prod-user.meepaisa.com",
        NODE_HOST_PAR: "https://node-partner.meepaisa.com",
        USERNAME_NODE: "ESICashback@easystepin-6WV1UR",
        PASSWORD_NODE: "b0b963cf-41ee-4240-8767-7c4921b460f8",
        NODE_USER_HOST: "https://node.meepaisa.com",
        USERNAME_USER_NODE: 'meepaisa@easystepin-6WV1UR',
        PASSWORD_USER_NODE: '2be5ae9b-45ac-4450-ae39-97be12308d90',
        BASEURL: "https://partner.meepaisa.com/",
        API_KEY: "rzp_live_SR43tNThczUuzd",
        API_SECRET: "kkH5d2NrZ5RM77oINYqFpAJK"
    },
};


    const getEnvVars = (prop = process.env) => {
        const env = prop.NODE_ENV;
        if (__DEV__) {
            return ENV.dev;
        } else if (env.trim() == 'production') {
            return ENV.prod;
        } else if (env.trim() == 'development') {
            return ENV.dev;
        }
        else if (env.trim() == 'preview') {
            return ENV.dev;
        }
        else if (env.trim() == 'production') {
            return ENV.prod;
        } else if (!(__DEV__)) {
            return ENV.dev;
        }
    };

    export default getEnvVars;
