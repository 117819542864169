import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { Button, Card, HelperText, Text, TextInput, Surface, RadioButton, Avatar, Paragraph, IconButton, DefaultTheme } from 'react-native-paper';
import { Dimensions, StyleSheet, ScrollView, View, RefreshControl } from 'react-native';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAllAddressforBiddingAction } from '../../../../state/actions/biddingProductsAction';
import Header from '../../../auth/components/header';
import EsiDatePicker from '../../../../components/custom/date/date';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import { getSingleBiddingServiceQuotesAction, updateBiddingServiceQuote } from '../../../../state/actions/partnerbiddingServiceQuotesAction';
import ESIChatModelForServices from '../../../../components/custom/chatmodelforservice';
import isEmpty from '../../../../state/validations/is-empty';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const Payment_Mode_Types = [
  {
    value: 'ONLINE',
    label: 'ONLINE'
  },
  {
    value: 'COD',
    label: 'COD'
  }
];
const Status_Types = [
  {
    value: '',
    label: '-- Please Select --'
  },
  {
    value: 'INITIATED',
    label: 'INITIATED'
  },
  {
    value: 'PROCESSING',
    label: 'PROCESSING'
  },
  {
    value: 'COMPLETED',
    label: 'COMPLETED'
  }
];
const EditPartnerServiceBiddingQuotes = (props: any) => {

  const ID = props.route?.params?.id || "";
  const currentDate = new Date();
  const page = props.route?.params?.page;
  const { navigation } = props;
  const dispatch = useDispatch();

  const esiColor = useSelector(state => state.theme);
  const toast = useToast();
  const isSubmited = useSelector(state => state.biddingservicequotes.biddingServiceQuoteUpdate.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "User_Service_Bidding_Id", SortOrder: "DESC" });

  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, global: { key: '', message: '' } });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }

  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  React.useEffect(() => {
    return () => {
    };
  }, []);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);

  React.useEffect(() => {
    if (!(permissions.Employees === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
    setSeconds(2);
  }, [permissions, ID]);

  const [data, setData] = useState([]);
  const callBackData = (data: any) => {
    setData(data[0])
  }

  const [addDatachat, setDataChat] = React.useState();
  const [addressdata, setAddresData] = useState([]);
  const biddingExpDate = new Date(data.Bidding_Expiry_Date);
  const [statusshowDropDown, setStatusShowDropDown] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [productsshowDropDown, setProductsShowDropDown] = useState(false);
  const genericpagination = {}

  const callBackAddressData = (data: any) => {
    setReload(false);
    setAddresData(data)
  }


  let addressdatalist = []
  if (addressdata) {
    if (addressdata[0]) {
      for (let i = 0; i < addressdata.length; i++) {
        addressdatalist.push({
          label: addressdata[i].Location,
          value: addressdata[i].Partner_Address_Id
        })
      }
    }
  }
  React.useEffect(() => {

    let addressForm = {
      "search_by_filter": "",
      "search": "",
      "Partner_Address_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    }
    dispatch(getAllAddressforBiddingAction(addressForm, callBackAddressData, pagination))
  }, [permissions]);


  React.useEffect(() => {
    if (seconds <= 0) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Partner_Bidding_Service_Quote_Id": ID,
        "Bidding_Status": "",
        "Partner_Detail_Id": "",
        "Partner_Bidding_Available_Servivce_Id": "",
        "Records_Filter": "FILTER",
        "User_Service_Bidding_Id": "",
        "Bidding_Service_Id": "",
        "Status": "",
        "Service_Type": ""
      }
      dispatch(getSingleBiddingServiceQuotesAction(formData, callBackData, pagination))
    }
  }, [seconds]);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [loading, setIsLoading] = React.useState(false);
  const refreshCall = () => {
    setIsLoading(true);
    let formData = {
      "search_by_filter": "",
      "search": "",
      "Partner_Bidding_Service_Quote_Id": ID,
      "Bidding_Status": "",
      "Partner_Detail_Id": "",
      "Partner_Bidding_Available_Servivce_Id": "",
      "Records_Filter": "FILTER",
      "User_Service_Bidding_Id": "",
      "Bidding_Service_Id": "",
      "Status": "",
      "Service_Type": ""
    };
    dispatch(getSingleBiddingServiceQuotesAction(formData, callBackData, pagination))
  };
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);
  //mobile number country code
  const [reload, setReload] = React.useState(false);
  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])
  const styles = StyleSheet.create({
    container: {
      paddingTop: 16
    },
    cardCircle: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "2%",
      marginBottom: "2%",
      borderRadius: 6,
      borderWidth: 0.01,
      borderColor: '#D6D6D7'
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },

    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    phonetextInput: {
      flex: 1
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 8,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });
  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }} >
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        {data && biddingExpDate &&

          <Formik
            enableReinitialize={true}
            initialValues={{
              "Modified_By": EmailID.Email_Id,
              "User_Service_Bidding_Id": data?.User_Service_Bidding_Id,
              "Advance_Token_Amount": data?.Advance_Token_Amount + " ",
              "Partner_Detail_Id": data?.Partner_Detail_Id,
              "Partner_Bidding_Available_Service_Id": data?.Partner_Bidding_Available_Servivce_Id,
              "Partner_Bidding_Service_Quote_Id": data?.Partner_Bidding_Service_Quote_Id,
              "Partner_Location": data?.Zip,
              "Delivery_Fee": data?.Delivery_Fee + " ",
              "Delivery_Managed_By": data?.Delivery_Managed_By,
              "Estimated_Delivery_Date": data?.Estimated_Delivery_Date,
              "Number_Of_Delivery_Days": data?.No_Of_Delivery_Days,
              "Partner_Comments": data?.Partner_Comments,
              "Quotation_Amount": data?.Quotation_Amount + " ",
              "Bidding_Status": data?.Bidding_Status,
              "Status": data?.Status,
              "Service_Price_Per_Day": data.Service_Price_Per_Day + " ",
              "Service_Price_Per_Hour": data.Service_Price_Per_Hour + " ",


              "ESI_Commission_Amount": data.ESI_Commision_Amount + " ",
              "Extra_Service_Per_Day": "",
              "Extra_Service_Per_Hour": "",
              "Partner_Total_Amount": data.Partner_Total_Amount + " ",
              "Payment_Mode": data.Payment_Mode,

              "Service_Request_Total_Time_In_Days": "",
              "Service_Request_Total_Time_In_Hours": "",

            }}
            validationSchema={Yup.object().shape({
              Quotation_Amount: Yup.number()
                .positive()
                .min(Yup.ref('Advance_Token_Amount'), 'Quotation Amount must be greater than or equal to Advance Token Amount.')
                .required('Quotation Amount is required.'),
              // Quotation_Amount: Yup.number().required('Quotation Amount is required.'),
              Partner_Comments: Yup.string().required('partner comments is required.'),
              Bidding_Status: Yup.string().required('Bidding Status is required.'),
              Service_Request_Total_Time_In_Hours: Yup.number().when('Bidding_Status', { is: "COMPLETED", then: Yup.number().min(0, 'Total Time In Hours than to 0.').required('Total Time In Hours is required.') }),
              Service_Request_Total_Time_In_Days: Yup.number().when('Bidding_Status', { is: "COMPLETED", then: Yup.number().min(0, 'Total Time In Days than to 0.').required('Total Time In Days is required.') }),
              Service_Price_Per_Day: Yup.number().required('Service Price Per Day is required.'),
              Service_Price_Per_Hour: Yup.number().required('Service Price Per Hour is required.'),

            })}

            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              if (values) {
                let formData = JSON.parse(JSON.stringify(values));
                dispatch(updateBiddingServiceQuote(formData, props.navigation, toast, setReload));
              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"

              }}>
                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm(),
                        setReload(true);
                      setData([]),
                        navigation.navigate('PartnerBiddingServiceQuotes')
                    }}
                  >
                    Go Back
                  </Button>
                </View>
                {!isEmpty(data) ? <View
                  pointerEvents={((currentDate <= biddingExpDate) || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') && (data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Service_Quote_Id))) ? "auto" : 'none'}
                >

                  <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", borderRadius: 6, borderWidth: 0.01, backgroundColor: esiColor.CBColor, borderColor: esiColor.SBorderColor }}>
                    <Card.Content>{data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Service_Quote_Id ?
                      <Card.Actions style={{ alignContent: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                        <Paragraph style={{ color: 'green', fontSize: 20, fontWeight: 'bold',color: esiColor.Text }}>Bid Accepted</Paragraph>
                      </Card.Actions> :

                      (currentDate >= biddingExpDate || ((data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') ? false : (data.Finalize_Partner_Bidding_Quote_Id != data?.Partner_Bidding_Service_Quote_Id))) ?
                        <Card.Actions style={{ alignContent: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                          <Paragraph style={{ color: 'red', fontSize: 20, fontWeight: 'bold',color: esiColor.Text }}>User Accepted Other Bid</Paragraph>
                        </Card.Actions> : null}
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold" ,color: esiColor.brandFontColor, }}>Service Details</Text>
                        </View>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Avatar.Image size={50} source={{ uri: data.Profile_Pic_Path }} />
                          {/* {'  '} */}
                          <Text style={{ marginTop: 10 ,color: esiColor.Text}}>  {'   '}{data.Full_Name}</Text>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Avatar.Image size={50} source={{ uri: data.Service_Image }} />
                          {/* {'  '} */}
                          <Text style={{ marginTop: 10,color: esiColor.Text }}>  {'   '}{data.Service_Type}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Service ID:</Text>
                         <Text style={{ color: esiColor.itemColor }}>{data.Bidding_Service_Id}</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5, }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>User Mobile:</Text>
                         <Text style={{ color: esiColor.itemColor }}>{data.Mobile}</Text>
                        </View>

                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Email ID:</Text>
                         <Text style={{ color: esiColor.itemColor }}>{data.Email_Id}</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5, }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Bid Status:</Text>
                         <Text style={{ color: esiColor.itemColor }}>{data.Bidding_Status}</Text>
                        </View>

                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Bidding User Request ID:</Text>
                         <Text style={{ color: esiColor.itemColor }}>{data.User_Service_Bidding_Id}</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold' ,color: esiColor.Text}}>Bid Date:</Text>
                         <Text style={{ color: esiColor.itemColor }}>{data.Created_Date}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Bid Expiry Date: </Text><Text>{data.Bidding_Expiry_Date}</Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5, }}>
                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold' ,color: esiColor.Text}}>Bid Status:</Text>
                         <Text style={{ color: esiColor.itemColor }}>{data.Bidding_Status}</Text>
                        </View>

                        <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Bidding User Request ID:</Text>
                         <Text style={{ color: esiColor.itemColor }}>{data.User_Service_Bidding_Id}</Text>
                        </View>
                      </View>
                      {data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Service_Quote_Id ?
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, marginTop: 30, marginBottom: 30 }}>
                          <Text style={{ fontSize: 20, fontWeight: "bold", color: 'blue' }}>Transactions Details</Text>
                        </View> : null}

                      {data.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Service_Quote_Id ? <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                          {data.Is_Advance_Amount_settled == '1' ? <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Advance Amount Settled: </Text>
                            <Text style={{ fontSize: 15, color: 'green' }}>{data.Advance_Token_Amount}</Text>
                          </View> :
                            <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                              <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Balance Advance Amount: </Text><Text style={{ fontSize: 15, color: 'green' }}>{data.Advance_Token_Amount}</Text>
                            </View>}
                          {data.Advance_Online_Transaction_Id != '' ? <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Advance Transaction ID: </Text>
                            <Text style={{ fontSize: 15, color: 'green' }}>{data.Advance_Online_Transaction_Id}</Text>
                          </View> :
                            null}
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>ESI Commision:</Text>
                            <Text style={{ fontSize: 15, color: 'green' }}> {data.ESI_Commision_Amount}</Text>
                          </View>
                          {data.Is_Final_Amount_Settled == '1' ? <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Final Amount Settled: </Text>
                            <Text style={{ fontSize: 15, color: 'green' }}>{data.Remaining_Amount_After_Advance_Amount}</Text>
                          </View> :
                            <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                              <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Remaining Balance Amount: </Text><Text style={{ fontSize: 15, color: 'green' }}>{data.Remaining_Amount_After_Advance_Amount}</Text>
                            </View>}

                        </View>
                        {data.Is_Final_Amount_Settled == '1' ? <View style={{ flexDirection: 'row', flexGrow: 1, marginLeft: '0.5%', flexShrink: 1 }}>
                          <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Final Amount Transaction ID: </Text><Text style={{ fontSize: 15, color: 'green' }}>{data.Online_Transaction_Id}</Text>
                        </View> : null}</View> : null}
                    </Card.Content>

                  </Card>
                  <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                    <Card.Content>
                      <Text style={{ fontSize: 20, fontWeight: "bold",color: esiColor.brandFontColor }}>
                        Edit Bidding Quotes
                      </Text>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput
                            
                             style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                             outlineColor={esiColor.TextOuterLine}
                             underlineColor={esiColor.TextUnderline}
                             activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('Quotation_Amount')}
                            label="Quotation amount"
                            name="Quotation_Amount"
                            value={values.Quotation_Amount}
                            mode="outlined"
                          />
                          <HelperText type="error" visible={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}>
                            {touched.Quotation_Amount && errors.Quotation_Amount}
                          </HelperText>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          <TextInput
                            
                             style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                             outlineColor={esiColor.TextOuterLine}
                             underlineColor={esiColor.TextUnderline}
                             activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('Advance_Token_Amount')}
                            label="Advance amount"
                            name="Advance_Token_Amount"
                            value={values.Advance_Token_Amount}
                            mode="outlined"
                          />
                          <HelperText type="error" visible={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}>
                            {touched.Advance_Token_Amount && errors.Advance_Token_Amount}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput
                            
                             style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                             outlineColor={esiColor.TextOuterLine}
                             underlineColor={esiColor.TextUnderline}
                             activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('Service_Price_Per_Hour')}
                            label="Service Price Per Hour"
                            name="Service_Price_Per_Hour"
                            value={values.Service_Price_Per_Hour}
                            mode="outlined"
                          />
                          <HelperText type="error" visible={Boolean(touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour)}>
                            {touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour}
                          </HelperText>
                        </View>

                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                          <TextInput
                            
                             style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                             outlineColor={esiColor.TextOuterLine}
                             underlineColor={esiColor.TextUnderline}
                             activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(touched.Service_Price_Per_Day && errors.Service_Price_Per_Day)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('Service_Price_Per_Day')}
                            label="Service Price Per Day"
                            name="Service_Price_Per_Day"
                            value={values.Service_Price_Per_Day}
                            mode="outlined"
                          />
                          <HelperText type="error" visible={Boolean(touched.Service_Price_Per_Day && errors.Service_Price_Per_Day)}>
                            {touched.Service_Price_Per_Day && errors.Service_Price_Per_Day}
                          </HelperText>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                        <View style={{ flexGrow: 1, width: 230, flexShrink: 1, paddingTop: 20 }}>
                          <TextInput
                            
                             style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                             outlineColor={esiColor.TextOuterLine}
                             underlineColor={esiColor.TextUnderline}
                             activeOutlineColor={esiColor.TextOuterLine}
                            error={Boolean(touched.Partner_Comments && errors.Partner_Comments)}
                            onBlur={handleBlur}
                            onChangeText={handleChange('Partner_Comments')}
                            label="partner comments"
                            name="Partner_Comments"
                            value={values.Partner_Comments}
                            mode="outlined"
                          />
                          <HelperText type="error" visible={Boolean(touched.Partner_Comments && errors.Partner_Comments)}>
                            {touched.Partner_Comments && errors.Partner_Comments}
                          </HelperText>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        {(data.Finalize_Partner_Bidding_Quote_Id != 'null' || data.Finalize_Partner_Bidding_Quote_Id != '') ? null :
                          // <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 230, flexShrink: 1, paddingTop: 10 }}>
                            <View style={styles.container}>
                              <Text style={[styles.dropdownlabel, Boolean(touched.Payment_Mode && errors.Payment_Mode) && { color: '#b00020' }]}>
                                Payment Mode
                              </Text>
                              <EsiSearchDropdown
                                style={[styles.dropdown, Boolean(touched.Payment_Mode && errors.Payment_Mode) && { borderColor: '#b00020' }]}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                data={Payment_Mode_Types}
                                labelField="label"
                                valueField="value"
                                maxHeight={Payment_Mode_Types.length > 1 ? 250 : 200}
                                search={Payment_Mode_Types.length > 5 ? true : false}
                                searchPlaceholder={'Select Payment Mode'}
                                value={values.Payment_Mode}
                                onFocus={() => setStatusShowDropDown(true)}
                                onBlur={() => setStatusShowDropDown(false)}
                                onUpdateValue={item => {
                                  setFieldValue('Payment_Mode', item.value)
                                  setStatusShowDropDown(false);
                                }}
                              />
                              <HelperText type="error" visible={Boolean(touched.Payment_Mode && errors.Payment_Mode)}>
                                {touched.Payment_Mode && errors.Payment_Mode}
                              </HelperText>
                            </View>
                          </View>
                          // </View>
                        }
                        <View style={{ flexGrow: 1, width: 230, flexShrink: 1, paddingTop: 10 }}>
                          <View style={styles.container}>
                            <Text style={[styles.dropdownlabel, Boolean(touched.Bidding_Status && errors.Bidding_Status) && { color: '#b00020' }]}>
                              Bidding Status
                            </Text>
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(touched.Bidding_Status && errors.Bidding_Status) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              data={Status_Types}
                              labelField="label"
                              valueField="value"
                              maxHeight={Status_Types.length > 1 ? 250 : 200}
                              search={Status_Types.length > 1 ? true : false}
                              searchPlaceholder={'Select Bidding Status'}
                              value={values.Bidding_Status}
                              onFocus={() => setShowDropDown(true)}
                              onBlur={() => setShowDropDown(false)}
                              onUpdateValue={item => {
                                setFieldValue('Bidding_Status', item.value)
                                setShowDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(touched.Bidding_Status && errors.Bidding_Status)}>
                              {touched.Bidding_Status && errors.Bidding_Status}
                            </HelperText>
                          </View>
                        </View>
                      </View>
                      {values.Bidding_Status == 'COMPLETED' &&
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <TextInput
                              
                               style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                               outlineColor={esiColor.TextOuterLine}
                               underlineColor={esiColor.TextUnderline}
                               activeOutlineColor={esiColor.TextOuterLine}
                              error={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}
                              onBlur={handleBlur}
                              onChangeText={item => {
                                let PPrice = item * values.Service_Price_Per_Day
                                setFieldValue("Service_Request_Total_Time_In_Days", item);
                                setFieldValue('Extra_Service_Per_Day', PPrice)
                              }}
                              label="Service Request Total Time In Days"
                              name="Service_Request_Total_Time_In_Days"
                              value={values.Service_Request_Total_Time_In_Days}
                              mode="outlined"
                            />
                            <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}>
                              {touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days}
                            </HelperText>
                          </View>
                          <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                            <TextInput
                             
                              style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                              outlineColor={esiColor.TextOuterLine}
                              underlineColor={esiColor.TextUnderline}
                              activeOutlineColor={esiColor.TextOuterLine}
                              error={Boolean(touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours)}
                              onBlur={handleBlur}
                              onChangeText={item => {
                                let PPrice = item * values.Extra_Service_Per_Hour
                                setFieldValue("Service_Request_Total_Time_In_Hours", item);
                                setFieldValue('Extra_Service_Per_Hour', PPrice)
                              }}
                              label="Service Request Total Time In Hours"
                              name="Service_Request_Total_Time_In_Hours"
                              value={values.Service_Request_Total_Time_In_Hours}
                              mode="outlined"
                            />
                            <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours)}>
                              {touched.Service_Request_Total_Time_In_Hours && errors.Service_Request_Total_Time_In_Hours}
                            </HelperText>
                          </View>
                        </View>}
                    </Card.Content>
                    <View style={{ marginHorizontal: 30 }}>
                      <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                    </View>
                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                      <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                        Save
                      </Button>
                    </View>
                  </Card>
                  <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",  borderRadius: 6, borderWidth: 0.01,  backgroundColor: esiColor.CBColor, borderColor: esiColor.SBorderColor}}>
                    <Card.Content>
                      <Text style={{ fontSize: 20, fontWeight: "bold",color: esiColor.brandFontColor,  }}>
                        Chat for Bidding Quote
                      </Text>
                      {/* <ScrollView> */}
                      <ESIChatModelForServices navigation={navigation} data={data} />

                      {/* </ScrollView> */}
                    </Card.Content>
                  </Card>
                </View>
                  :
                  <View style={{ justifyContent: 'center', alignSelf: 'center' }}>
                    <IconButton icon="refresh" size={20} color='#27B6CC' onPress={() => { refreshCall() }} />
                  </View>
                }
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface >

  );
};

export default EditPartnerServiceBiddingQuotes;