import React, { useEffect, useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { Avatar, HelperText, Colors, Text, TextInput, TouchableRipple } from "react-native-paper";
import { Rating } from "react-native-ratings";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-native-modal";
import isEmpty from "../../../state/validations/is-empty";
import { createDelReviewRatingsAction, getAllDelReviewRatingsAction } from "../../../state/actions/shoppers/shoppersdeliveryRatingsAction";
import SignIN from "../../auth/signin";
import { useToast } from "../../../components/custom/react-native-paper-toast/src";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function ESIDeliveryRating(props: any) {
    const { orderid, lineitemid, ordStatus, toggle, ...rest } = props;
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const toast = useToast();

    //For add review
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);
    const [rating, setRating] = useState(0);
    const user = useSelector(state => state.auth?.user);
    const [comment, setComment] = useState();

    const toggleModal = () => {

        if (isAuthenticated) {
            setReviewModalVisible(!isReviewModalVisible);
        }
        else {
            setModalVisible(true);
        }
    };

    const addReviewModal = (formData) => {
        let data = {
            Comments: comment,
            Del_User_Email_Id: alldelireviewsandratings?.Del_User_Email_Id,
            Name: user?.User_Name,
            Order_Id: orderid,
            Order_Line_Item_Id: lineitemid,
            Rating: rating,
            Status: "Active",
            User_Email_Id: user?.Email_Id,
        }
        let formValues = JSON.parse(JSON.stringify(formData));
        dispatch(createDelReviewRatingsAction(formValues, toast));
        setReviewModalVisible(false);
    }
    const [alldelireviewsandratings, setAlldelireviewsandratings] = useState({});
    const containerStyle = { backgroundColor: 'white', padding: 20 };
    const dispatch = useDispatch();
    useEffect(() => {
        let formData = {
            "Records_Filter": "FILTER",
            "Order_ID": "S-O-0000008",
            "Order_Line_Item_ID": "0000008"
        }
        dispatch(getAllDelReviewRatingsAction(formData, setAlldelireviewsandratings));
    }, [orderid, lineitemid]);

    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                props.navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    //For add review
    const [issModalVisible, settModalVisible] = React.useState(false);

    //displaying Signin pop up
    const signInModalResponse = () => {
        settModalVisible(!issModalVisible);
    };

    // Tost message code.
    let dropDownAlertRef = React.useRef();
    const dropDownAlertFun = (type, title, sub_title) => {
        const inMilliSeconds = Math.floor(Math.random() * 6000 + 1);
        const inSeconds = (inMilliSeconds / 1000).toFixed(2);
        dropDownAlertRef.alertWithType(
            type,
            title,
            sub_title,
            {},
            inMilliSeconds
        );
    }

    //For view Review & Ratings
    const [view, setViewModalVisible] = React.useState(false);
    const [isViewReviewModalVisible, setViewReviewModalVisible] = React.useState(false);
    const [data, setData] = React.useState();

    const viewToggleModal = () => {
        if (isAuthenticated) {
            setViewReviewModalVisible(!isViewReviewModalVisible);
        }
        else {
            setViewModalVisible(true);
        }
    };
    const esiColor = useSelector(state => state.theme);


    return (
        <View>
            <View>
                {isEmpty(alldelireviewsandratings?.data) && ordStatus === "DELIVERED" ?
                    <TouchableOpacity
                        style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", padding: 5, marginVertical: 5 }}
                        onPress={() => toggleModal()}
                    >
                        <Text style={{ color: esiColor.itemButtenColor }}>Add Delivery Review</Text>
                    </TouchableOpacity>
                    :

                    alldelireviewsandratings && ordStatus === "DELIVERED" ?
                        alldelireviewsandratings?.data?.map((delorder, index) => (
                            <TouchableOpacity style={{ marginHorizontal: 5 }} key={"del" + index} onPress={() => { viewToggleModal(), setData(delorder) }}>
                                <Text style={{ fontWeight: "bold", color: "#27b6cc" }}>Delivery Review</Text>
                                <Rating
                                    readonly={true}
                                    rating={delorder?.Rating ? parseInt(delorder?.Rating) : 0}
                                    type='heart'
                                    // ratingBackgroundColor='#c8c7c8'
                                    tintColor={esiColor.TintColor}
                                    startingValue={delorder?.Rating}
                                    ratingCount={5}
                                    imageSize={20}
                                    style={{ marginTop: -20 }}
                                    minValue={1}
                                    onFinishRating={(rating) => ratingCompleted(rating)}
                                    name="rating"
                                />
                            </TouchableOpacity>
                        ))
                        :
                        null
                }
            </View>

            {/* For Add Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isReviewModalVisible}
                contentContainerStyle={containerStyle}
            >
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 300, borderRadius: 10,
                    backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5
                }}>
                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => setReviewModalVisible(false)}>
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <DeliveryAddReview dorderid={orderid} dorderlineid={lineitemid} responseFunction={addReviewModal}
                    />
                </View>
            </Modal>
            {/* For view Review & Ratings */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isViewReviewModalVisible}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 200, borderRadius: 10,
                    backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5
                }}>
                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setViewReviewModalVisible(false) }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <Text style={{ fontWeight: "bold", padding: 10, color: esiColor.DescColor }}>Comment   <Text style={{ color: esiColor.itemColor }}>{data?.Comments}</Text></Text>
                        <Text style={{ fontWeight: "bold", padding: 10, color: esiColor.DescColor }}>Rating   </Text>
                        <Rating
                            readonly={true}
                            rating={data?.Rating ? parseInt(data?.Rating) : 0}
                            tintColor={esiColor.TintColor}
                            type='heart'
                            startingValue={data?.Rating}
                            ratingCount={5}
                            imageSize={20}
                            style={{ marginTop: -30 }}
                            minValue={1}
                            onFinishRating={(rating) => ratingCompleted(rating)}
                            name="rating"

                        />
                    </View>
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={issModalVisible}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, minHeight: 480, maxHeight: 500,
                    borderRadius: 10, backgroundColor: esiColor.BackgroundColor,
                    shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5
                }}>
                    <SignIN dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
        </View>

    )
}

const DeliveryAddReview = ({ responseFunction, dorderid, dorderlineid, alldelireviewsandratings }) => {
    const [rating, setRating] = useState(0);
    const [ratingError, setRatingError] = useState(false);
    const [comment, setComment] = useState("");
    const user = useSelector(state => state.auth?.user);
    // Comment validations
    const handleOnChange = (value) => {
        setComment(value);
    }

    const addReviewModal = () => {
        let error = false;
        if (rating <= 0) {
            setRatingError(true);
            error = true;
        } else {
            setRatingError(false);
        }
        if (!error) {
            let formData = {
                Comments: comment,
                Del_User_Email_Id: alldelireviewsandratings?.Del_User_Email_Id,
                Name: user?.User_Name,
                Order_Id: dorderid,
                Order_Line_Item_Id: dorderlineid,
                Rating: rating,
                Status: "Active",
                User_Email_Id: user?.Email_Id,
            }
            responseFunction(formData);
        }
    }
    const esiColor = useSelector(state => state.theme);

    return (
        <View style={{ margin: '4%' }}>
            <TextInput
                style={{ backgroundColor: esiColor.TextBC }}
                activeUnderlineColor={esiColor.TextOuterLine}
                selectionColor={esiColor.TextSelection}
                theme={{
                    colors: {
                        text: esiColor.Text,
                        placeholder: esiColor.TextPlaceholder
                    }
                }}
                outlineColor={esiColor.TextOuterLine}
                underlineColor={esiColor.TextUnderline}
                activeOutlineColor={esiColor.TextOuterLine}
                label='Add Review'
                mode='outlined'
                onChangeText={(Text) => { handleOnChange(Text); }}
                multiline={true}
                numberOfLines={4}
                value={comment}
            />
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center'
            }}>
                <Rating
                    tintColor={esiColor.TintColor}
                    type='heart'
                    startingValue={rating || 0}
                    ratingCount={5}
                    imageSize={30}
                    style={{ paddingVertical: 10 }}
                    onFinishRating={(value) => { setRating(value); setRatingError(false) }}
                />
                <HelperText>
                    {ratingError &&
                        <Text style={{ color: "red" }}>
                            Please select the Rating.
                        </Text>
                    }
                </HelperText>
                <View>
                    <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5 }} onPress={() => addReviewModal()}>
                        <Text style={{ color: esiColor.itemButtenColor, margin: 10 }}>SUBMIT</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}