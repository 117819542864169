import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Card, HelperText, Surface, Text, TextInput ,DefaultTheme} from 'react-native-paper';
import { Dimensions, ScrollView, StyleSheet, View, RefreshControl } from 'react-native';
import { AddPartnerBiddingServiceQuotesAction, getUserServicesBiddingQuotesAction } from '../../../../state/actions/userNewBiddingQuoteForServiceAction';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../header/header';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAllAddressforBiddingAction } from '../../../../state/actions/Services/biddingServicesAction';
import isEmpty from '../../../../state/validations/is-empty';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const userNewbiddingRequestServiceDetails = (props: any) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { navigation } = props;
  const ID = props.route?.params?.id;
  const esiColor = useSelector(state => state.theme);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [errorMessage, setErrorMessage] = useState({ Email_Id: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.userServiceBiddingquotes.biddingCreate.isLoading);
  const EmailID = useSelector(state => state.auth?.user);
  const [paginationforaddress, setPaginationForAdress] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_ADDRESS_ID", SortOrder: "DESC" });

  const [seconds, setSeconds] = React.useState(-1);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "User_Service_Bidding_Id", SortOrder: "DESC" });
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });

  React.useEffect(() => {
    if (!(permissions.Employees === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }

    setSeconds(2);
  }, [permissions, ID]);

  const [ESICOMMISION, setESICOMMISION] = useState('');

  const [ESICOMMISIONAMOUNT, setESICOMMISIONAMOUNT] = useState('');
  const ESICommisionCalculations = (data: any) => {
    setESICOMMISIONAMOUNT(data)
  }
  const [addressdata, setAddresData] = useState([]);
  const [userbiddata, setUserBidData] = useState([]);
  const callBackAddressData = (data: any) => {
    setAddresData(data)
  }
  const callBackUserBidData = (data: any) => {
    setUserBidData(data[0])
  }

  //   filtered_data = [record for record in data if record['key'] != key_to_remove]

  // print(filtered_data)


  let addressdatalist = []
  if (addressdata) {
    if (addressdata[0]) {
      for (let i = 0; i < addressdata.length; i++) {
        addressdatalist.push({
          label: addressdata[i].Location,
          value: addressdata[i].Partner_Address_Id
        })
      }
    }
  }

  React.useEffect(() => {
    if (seconds <= 0) {
      let addressForm = {
        "search_by_filter": "",
        "search": "",
        "Partner_Address_Id": "",
        "Partner_Details_Id": EmailID?.Partner_Details_Id,
        "Records_Filter": "FILTER"
      }
      let formData = {
        "search_by_filter": "",
        "search": "",
        "User_Email_Id": "",
        "User_Service_Bidding_Id": ID,
        "Bidding_Service_Id": '',
        "User_Address_Id": "",
        "Records_Filter": "FILTER",
        "Bidding_Status": "",
        "Partner_Detail_Id": EmailID.Partner_Details_Id
      };
      dispatch(getAllAddressforBiddingAction(addressForm, callBackAddressData, paginationforaddress))
      dispatch(getUserServicesBiddingQuotesAction(formData, callBackUserBidData, pagination))
    }
  }, [seconds]);
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  // Alert Popup
  const [addressshowDropDown, setAddressShowDropDown] = useState(false);

  //form reset
  const [reload, setReload] = React.useState(false);

  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])
  const styles = StyleSheet.create({
    container: {
      paddingTop: 15
    },
    cardCircle: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "2%",
      marginBottom: "2%",
      borderRadius: 6,
      borderWidth: 0.01,
      borderColor: '#D6D6D7'
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },

    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    phonetextInput: {
      flex: 1
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 8,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    }, selectedTextStyles: {
      fontSize: 12,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });
  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }} >
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        {!reload &&
          <Formik
            enableReinitialize={true}
            initialValues={{
              "Advance_Token_Amount": '',
              "Bidding_Status": userbiddata.Bidding_Status,
              "Created_By": EmailID.Created_By,
              // "Delivery_Fee": biddingsQuotedata.Delivery_Fee,
              // "Delivery_Managed_By": biddingsQuotedata,
              // "Estimated_Delivery_Date": biddingsQuotedata,
              // "No_Of_Delivery_Days": biddingsQuotedata,
              "Partner_Bidding_Available_Service_Id": userbiddata.Partner_Bidding_Available_Service_Id,
              "Partner_Comments": '',
              "Partner_Detail_Id": userbiddata.Partner_Detail_Id,
              "Partner_Location": '',
              "Quotation_Amount": '',
              "Status": userbiddata.Status,
              "User_Service_Bidding_Id": userbiddata.User_Service_Bidding_Id,
              "Service_Price_Per_Day": '',
              "Service_Price_Per_Hour": '',
            }}

            validationSchema={Yup.object().shape({
              Advance_Token_Amount: Yup.number().moreThan(0, 'Advance Token Amount than to 0.').required('Advance Token Amount is required.'),
              // Quotation_Amount: Yup.number().moreThan(0, 'Quotation Amount than to 0.').required('Quotation Amount is required.'),
              Quotation_Amount: Yup.number()
                .positive()
                .min(Yup.ref('Advance_Token_Amount'), 'Quotation Amount must be greater than or equal to Advance Token Amount.')
                .required('Quotation Amount is required.'),
              Partner_Comments: Yup.string().required('Partner Comments is required.'),
              Partner_Location: Yup.string().required('Partner Address is required.'),
              Service_Price_Per_Day: Yup.number().required('Service Price Per Day is required.'),
              Service_Price_Per_Hour: Yup.number().required('Service Price Per Hour is required.'),
            })}

            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              if (values) {
                let formData = JSON.parse(JSON.stringify(values));
                dispatch(AddPartnerBiddingServiceQuotesAction(formData, navigation))
              }
            }
            }
          >

            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}>
                <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm();
                      setReload(true);
                      setSeconds(2);
                      navigation.navigate('UserNewBiddingQuotesForServices')
                    }}
                  >
                    Go Back
                  </Button>
                </View>
                <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",  borderRadius: 6, borderWidth: 0.01,  backgroundColor: esiColor.CBColor, borderColor: esiColor.SBorderColor}}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", paddingBottom: 20,color: esiColor.brandFontColor, }}>
                      User Request Details
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Bidding Reqest ID:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.User_Service_Bidding_Id}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>User Email ID:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.User_Email_Id}</Text>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5, }}>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold' ,color: esiColor.Text}}>Service Type:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.Service_Type}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Bidding Service ID:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.Bidding_Service_Id}</Text>
                      </View>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Bidding Status:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.Bidding_Status}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Address:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.City}, {userbiddata.User_Pincode}</Text>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold' ,color: esiColor.Text}}>Bid Date:</Text><Text style={{ color: esiColor.itemColor }}> {userbiddata.Created_Date}</Text>
                      </View>
                      <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <Text style={{ fontSize: 15, fontWeight: 'bold' ,color: esiColor.Text}}>Bid Expiry Date: </Text><Text style={{ color: esiColor.itemColor }}>{userbiddata.Bidding_Expiry_Date}</Text>
                      </View>
                    </View>
                    {!isEmpty(values.Quotation_Amount) ? <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', margin: 5 }}>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>ESI Commision:</Text>
                            <Text style={{ color: esiColor.itemColor }}> {userbiddata.Esi_Commision_Type == "P" ? userbiddata.Esi_Commision + '%' : userbiddata.Esi_Commision + 'Rs'}</Text>
                          </View>
                          <View style={{ flexDirection: 'row', flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                            <Text style={{ fontSize: 15, fontWeight: 'bold',color: esiColor.Text }}>Total commision Amount: </Text>
                            <Text style={{ color: esiColor.itemColor }}>{ESICOMMISIONAMOUNT}</Text>
                          </View>
                        </View>
                      </View> : null}
                  </Card.Content>
                </Card>
                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Quotation Form
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                          theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}
                          onBlur={handleBlur}
                          onChangeText={item => {
                            let PPrice = userbiddata?.Esi_Commision_Type == "P" ? (item / 100 * userbiddata?.Esi_Commision).toFixed(2) : userbiddata?.Esi_Commision
                            setFieldValue("Quotation_Amount", item);
                            ESICommisionCalculations(PPrice)
                          }}
                          keyboardType='numeric'
                          label="Quotation Amount"
                          name="Quotation_Amount"
                          value={values.Quotation_Amount}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Quotation_Amount && errors.Quotation_Amount)}>
                          {touched.Quotation_Amount && errors.Quotation_Amount}
                        </HelperText>
                      </View>

                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                          style={{ backgroundColor: 'white' }}
                          theme={customTheme}
                          error={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Advance_Token_Amount')}
                          label="Advance Token Amount"
                          name="Advance_Token_Amount"
                          value={values.Advance_Token_Amount}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Advance_Token_Amount && errors.Advance_Token_Amount)}>
                          {touched.Advance_Token_Amount && errors.Advance_Token_Amount}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                           style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                           outlineColor={esiColor.TextOuterLine}
                           underlineColor={esiColor.TextUnderline}
                           activeOutlineColor={esiColor.TextOuterLine}
                         theme={customTheme}
                          error={Boolean(touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour)}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Service_Price_Per_Hour')}
                          label="Service Price Per Hour"
                          name="Service_Price_Per_Hour"
                          value={values.Service_Price_Per_Hour}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour)}>
                          {touched.Service_Price_Per_Hour && errors.Service_Price_Per_Hour}
                        </HelperText>
                      </View>

                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                        <TextInput
                          style={{ backgroundColor: 'white' }}
                          theme={customTheme}
                          error={Boolean(touched.Service_Price_Per_Day && errors.Service_Price_Per_Day)}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Service_Price_Per_Day')}
                          label="Service Price Per Day"
                          name="Service_Price_Per_Day"
                          value={values.Service_Price_Per_Day}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Service_Price_Per_Day && errors.Service_Price_Per_Day)}>
                          {touched.Service_Price_Per_Day && errors.Service_Price_Per_Day}
                        </HelperText>
                      </View>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                        <TextInput
                          style={{ backgroundColor: 'white' }}
                          theme={customTheme}
                          error={Boolean(touched.Partner_Comments && errors.Partner_Comments)}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          onBlur={handleBlur}
                          onChangeText={handleChange('Partner_Comments')}
                          label="Partner Comments"
                          name="Partner_Comments"
                          value={values.Partner_Comments}
                          mode="outlined"
                        />
                        <HelperText type="error" visible={Boolean(touched.Partner_Comments && errors.Partner_Comments)}>
                          {touched.Partner_Comments && errors.Partner_Comments}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1 }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Partner_Location && errors.Partner_Location) && { color: '#b00020' }]}>
                            Partner Address
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Partner_Location && errors.Partner_Location) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyles}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={addressdatalist}
                            labelField="label"
                            valueField="value"
                            theme={customTheme}
                            maxHeight={addressdatalist.length > 1 ? 250 : 200}
                            search={addressdatalist.length > 1 ? true : false}
                            searchPlaceholder={'Select Partner Address'}
                            value={values.Partner_Location}
                            onFocus={() => setAddressShowDropDown(true)}
                            onBlur={() => setAddressShowDropDown(false)}
                            onUpdateValue={item => {
                              setFieldValue('Partner_Location', item.value)
                              setAddressShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Partner_Location && errors.Partner_Location)}>
                            {touched.Partner_Location && errors.Partner_Location}
                          </HelperText>
                        </View>
                      </View>
                    </View>
                  </Card.Content>
                  <View style={{ marginHorizontal: 30 }}>
                    <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", paddingBottom: 15 }}>
                    <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                      Save
                    </Button>
                  </View>
                </Card>
              </View>
            )}
          </Formik>}
      </ScrollView>
    </Surface>
  );
};

export default userNewbiddingRequestServiceDetails;