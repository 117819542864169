import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const SETTING_REQUEST = "SETTING_REQUEST";
export const SETTING_SUCCESS = "SETTING_SUCCESS";
export const SETTING_FAIL = "SETTING_FAIL";

export const SETTING_GET_REQUEST = "SETTING_GET_REQUEST";
export const SETTING_GET_SUCCESS = "SETTING_GET_SUCCESS";
export const SETTING_GET_FAIL = "SETTING_GET_FAIL";

export const SETTING_CREATE_REQUEST = "SETTING_CREATE_REQUEST";
export const SETTING_CREATE_SUCCESS = "SETTING_CREATE_SUCCESS";
export const SETTING_CREATE_FAIL = "SETTING_CREATE_FAIL";

export const SETTING_UPDATE_REQUEST = "SETTING_UPDATE_REQUEST";
export const SETTING_UPDATE_SUCCESS = "SETTING_UPDATE_SUCCESS";
export const SETTING_UPDATE_FAIL = "SETTING_UPDATE_FAIL";

export const SETTING_DELETE_REQUEST = "SETTING_DELETE_REQUEST";
export const SETTING_DELETE_SUCCESS = "SETTING_DELETE_SUCCESS";
export const SETTING_DELETE_FAIL = "SETTING_DELETE_FAIL";

export const SETTING_FIELDNAME_REQUEST = "SETTING_FIELDNAME_REQUEST";
export const SETTING_FIELDNAME_SUCCESS = "SETTING_FIELDNAME_SUCCESS";
export const SETTING_FIELDNAME_FAIL = "SETTING_FIELDNAME_FAIL";

export const SETTING_FILTER = "SETTING_FILTER ";
const cudheaders = config.nodeheadersCommon;
const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;

/*=============================================================
                  Get All Settings Action
===============================================================*/
export const getAllSettingsAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: SETTING_REQUEST
    });
    let { data } = await axios.post(
      `${config.url}/Partner_Settings/Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
      formData,
      {
        headers: headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SETTING_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SETTING_SUCCESS,
          payload: data.Setting
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SETTING_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                settings Create Action
===============================================================*/

export const createSettingsAction = (formData: any, navigation: any, toast: any, setErrorMessage: any) => async dispatch => {
  dispatch({
    type: SETTING_CREATE_REQUEST
  });
  setErrorMessage({ Setting_Field_Name: { key: '', message: '' }, global: { key: '', message: '' } });

  axios.post(`${config.nodecudurl}/Partner_Settings/Create`, formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: SETTING_CREATE_SUCCESS
        });
        navigation.navigate('SettingsGet')
        toast.show({ message: 'Setting created successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          Partner_Details_Id: formData.Partner_Details_Id
        };
        dispatch(getAllSettingsAction(formValue))
      } else {
        const errors = {};
        if (successResponse.Setting_Field_Name) {
          errors.Setting_Field_Name = successResponse.UI_Display_Message;
        }
        setErrorMessage({ Setting_Field_Name: { key: successResponse.Setting_Field_Name ? successResponse.Setting_Field_Name : '', message: successResponse.Setting_Field_Name ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Setting_Field_Name) ? successResponse.UI_Display_Message : '' } });

        dispatch({
          type: SETTING_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Setting_Field_Name: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: SETTING_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};


/*=============================================================
                Settings Update Action
===============================================================*/

export const updateSettingAction = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: SETTING_UPDATE_REQUEST
  });
  axios.put(`${config.nodecudurl}/Partner_Settings/Update`, formData,
    {
      headers: cudheaders
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: SETTING_UPDATE_SUCCESS
        });
        navigation.navigate('SettingsGet')
        toast.show({ message: 'Setting updated successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          Partner_Details_Id: formData.Partner_Details_Id
        };
        dispatch(getAllSettingsAction(formValue))
      } else {
        const errors = {};
        dispatch({
          type: SETTING_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: SETTING_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                  Setting Delete
===============================================================*/

export const deleteSettingsAction = (formData: any, toast: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(`${config.nodecudurl}/Partner_Settings/Delete`, formData, {
      headers: cudheaders
    });
    if (data) {
      toast.show({ message: 'Setting deleted successfully.', type: 'info', duration: 3000, position: 'top' });
      const formValue = {
        Records_Filter: "FILTER",
        Partner_Details_Id: formData.Partner_Details_Id
      };
      dispatch(getAllSettingsAction(formValue))
    }
  } catch (err) {
  }
};

/*=============================================================
                  Get All setting field names Action
===============================================================*/
export const getAllSettingFieldnames = () => async dispatch => {
  try {
    let formData = {
      "Default_Name": "",
      "Records_Filter": ""
    };
    dispatch({
      type: SETTING_FIELDNAME_REQUEST
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Parnter_Settings_Default/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SETTING_FIELDNAME_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SETTING_FIELDNAME_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SETTING_FIELDNAME_FAIL,
      payload: err
    });
  }
};

// Filter 
export const settingFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SETTING_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//search Filter
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: SETTING_FILTER,
      fType: "search",
      value: "",
    });
  }
}

export const getSettingByIdAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    dispatch({
      type: SETTING_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Settings/Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data.results)
    }
  } catch (err) {
    dispatch({
      type: SETTING_FAIL,
      payload: err
    });
  }
};

