import {
  B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_REQUEST,
  B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_SUCCESS,
  B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_FAIL,
  B2B_BIDDING_PRODUCTS_QUOTE_CREATE_REQUEST,
  B2B_BIDDING_PRODUCTS_QUOTE_CREATE_SUCCESS,
  B2B_BIDDING_PRODUCTS_QUOTE_CREATE_FAIL,
  USER_B2B_BIDDING_PRODUCTS_REQUEST,
  USER_B2B_BIDDING_PRODUCTS_FAIL,
  B2B_BIDDING_PRODUCT_FILTER,

} from '../actions/b2buserProductsNewBiddingQuoteAction';

const initialState = {
  b2b_bidding_product_quotes: { all: [], error: '', isLoading: false },
  biddingb2bCreate: { b2bbiddingproduct: {}, error: '', isLoading: false },
  biddingserviceFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_REQUEST:
      return { ...state, b2b_bidding_product_quotes: { all: state.b2b_bidding_product_quotes.all, error: '', isLoading: true } };
    case B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_SUCCESS:
      return { ...state, b2b_bidding_product_quotes: { all: action.payload, error: '', isLoading: false } };
    case B2B_USER_NEW_BIDDING_PRODUCTS_QUOTES_FAIL:
      return { ...state, b2b_bidding_product_quotes: { all: [], error: action.payload, isLoading: false } };
    case B2B_BIDDING_PRODUCTS_QUOTE_CREATE_REQUEST:
      return { ...state, biddingb2bCreate: { b2bbiddingproduct: {}, error: '', isLoading: true } };
    case B2B_BIDDING_PRODUCTS_QUOTE_CREATE_SUCCESS:
      return { ...state, biddingb2bCreate: { b2bbiddingproduct: state.biddingb2bCreate.user, error: '', isLoading: false } };
    case B2B_BIDDING_PRODUCTS_QUOTE_CREATE_FAIL:
      return { ...state, biddingb2bCreate: { b2bbiddingproduct: {}, error: action.payload, isLoading: false } };
    case B2B_BIDDING_PRODUCT_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, biddingserviceFilter: { search: state.biddingserviceFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, biddingserviceFilter: { select: state.biddingserviceFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}



