import {
    SERVICELEVELAGREEMENT_REQUEST,
    SERVICELEVELAGREEMENT_SUCCESS,
    SERVICELEVELAGREEMENT_FAIL,

    SERVICELEVELAGREEMENT_GET_REQUEST,
    SERVICELEVELAGREEMENT_GET_SUCCESS,
    SERVICELEVELAGREEMENT_GET_FAIL,

} from '../actions/servicelevelagreementAction'

const initialState = {

    servicelevelagreements: { all: [], error: '', isLoading: false },
    servicelevelagreement: { servicelevelagreement: {}, error: '', isLoading: false },

};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case SERVICELEVELAGREEMENT_REQUEST:
            return { ...state, servicelevelagreements: { all: state.servicelevelagreements.all, error: '', isLoading: true } };
        case SERVICELEVELAGREEMENT_SUCCESS:
            return { ...state, servicelevelagreements: { all: action.payload, error: '', isLoading: false } };
        case SERVICELEVELAGREEMENT_FAIL:
            return { ...state, servicelevelagreements: { all: [], error: action.payload, isLoading: false } };

        case SERVICELEVELAGREEMENT_GET_REQUEST:
            return { ...state, servicelevelagreement: { servicelevelagreement: {}, error: '', isLoading: true } };
        case SERVICELEVELAGREEMENT_GET_SUCCESS:
            return { ...state, servicelevelagreement: { servicelevelagreement: action.payload, error: '', isLoading: false } };
        case SERVICELEVELAGREEMENT_GET_FAIL:
            return { ...state, servicelevelagreement: { servicelevelagreement: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}