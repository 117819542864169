import React, { useState, useEffect } from "react";
import { Image, StyleSheet, View, Dimensions, TouchableOpacity, Pressable } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Card, Surface, Text, TextInput, Colors, TouchableRipple, IconButton, ActivityIndicator } from "react-native-paper";
import { Rating } from "react-native-ratings";
import { ScrollView } from "react-native-gesture-handler";
import RenderHTML from "react-native-render-html";
import Modal from "react-native-modal";
import isEmpty from "../../../state/validations/is-empty";
import SignIN from "../../auth/signin";
import Header from "../header/header";
import ImageCarousel from "../../../components/custom/image-carousel";
import { addProductReviewAction, editProductReviewAction, getPartnerproductsbyIdAction, getProductByIdAction, getProductColorsAction, getProductsImageAction, getProductSizeActionWishList, getRelatedProductAction, getRelatedProductByIdAction, getUserReviewsbyIdAction, productsizereset } from "../../../state/actions/shoppers/productsActions";
import DropdownAlert from 'react-native-dropdownalert';
import { TabBar, TabView } from 'react-native-tab-view';
import { Entypo } from "@expo/vector-icons";
import { Tooltip } from 'react-native-elements';
import { addCart } from "../../../state/actions/shoppers/shoppercartActions";
import { useToast } from "../../../components/custom/react-native-paper-toast/src";
import AsyncStorage from "@react-native-async-storage/async-storage";


const { height, width } = Dimensions.get('window');

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

// api

export default function ProductDetails(props) {
  const { navigation } = props;
  const EmailID = useSelector(state => state.auth.user?.Email_Id)
  const ID = useSelector(state => state.auth.user?.Partner_Details_Id)
  const Partnerdetail = useSelector(state => state.auth.user)
  const Logindetails = useSelector(state => state.auth.user)
  const { height, width } = Dimensions.get('window');
  const toast = useToast();
  //For add review
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);

  //For edit review
  const [setEditModalVisible] = React.useState(false);
  const [isEditReviewModalVisible, setEditReviewModalVisible] = React.useState(false);

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [PartnerDetails, setPartnerDetails] = useState();
  const [selected, setSelected] = useState(false);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const handleSelect = (id) => {
    if (id === selected) {
      setSize("")
    }
    setSelected(id !== selected ? id : "")
  };

  const [imageURL, setImageURL] = useState(false)
  const [visible, setVisible] = useState(2);

  const handleClicks = () => {
    setVisible((prevValue) => prevValue + 2);
  }

  const signInModalResponse = (status) => {
    setModalVisible(!isModalVisible);
  };

  const [commenterror, setCommentError] = useState("")
  const [ratingerror, setRatingError] = useState("")
  const [error, setError] = useState("")
  let existcomment;
  const addReviewModal = () => {


    if (!isEmpty(comment) && rating > 0) {
      setCommentError("")
      setRatingError("")
      let formData = {
        PRODUCT_ID: Product_Id,
        RATING: rating,
        COMMENTS: comment,
        EMAIL_ID: EmailID,
        STATUS: "Active"
      }
      let formValues = JSON.parse(JSON.stringify(formData));
      dispatch(addProductReviewAction(formValues, Product_Id, responseFunction));
    } else {
      if (isEmpty(comment)) {
        setCommentError("Comment is required.")
      }
      if (isEmpty(rating)) {
        setRatingError("Rating is required.")
      }
    }

  }

  const responseFunction = (status, message) => {
    toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
    setReviewModalVisible(!isReviewModalVisible);
    setComment("");
    setRating("");
  }

  const toggleModal = () => {
    if (isAuthenticated) {
      setReviewModalVisible(!isReviewModalVisible);
    }
    else {
      setModalVisible(true);
    }
  };

  //For edit review

  const editToggleModal = () => {
    if (isAuthenticated) {
      setEditReviewModalVisible(!isEditReviewModalVisible);
    }
    else {
      setEditModalVisible(true);
    }

  };


  const editReviewModal = () => {

    if (!isEmpty(comment) && rating > 0) {
      setCommentError("")
      setRatingError("")
      let editData = {
        Product_Id: Product_Id,
        Shopper_Product_Review_Id: reviewId,
        Rating: rating,
        Comments: comment,
        Email_Id: EmailID,
        Status: "Active"
      }
      let formValues = JSON.parse(JSON.stringify(editData));
      dispatch(editProductReviewAction(formValues, Product_Id, responseFunctionUpdate));

    } else {
      if (isEmpty(comment)) {
        setCommentError("Comment is required.")
      }
      if (isEmpty(rating)) {
        setRatingError("Rating is required.")
      }
    }
  }
  const responseFunctionUpdate = (status, message) => {
    toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
    setEditReviewModalVisible(!isEditReviewModalVisible);
    setComment("");
    setRating("");
  }

  // Comment validations

  const handleOnChange = (value) => {
    if (existcomment > 0) {
      setError("You have already added review.")
    }
    if (value === "") {
      setError("")
    }
  }

  const editOnChange = (value) => {
    if (value === "") {
      setCommentError("Review is required.")
    } else {
      setCommentError("")
    }
  }

  const onCloseModal = () => {
    setSize({ size: "", quantity: "" })
    setModalIsOpen(false);
    setSelected(false)
    setPartnerDetails()
    setComment("")
    setRating("")
  };

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window);
      }
    );
  });

  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const Product_Id = props?.route?.params?.Product_Id;
  const Partner_Details_Id = props?.route?.params?.Partner_Details_Id;
  const masterId = props?.route?.params?.masterId;
  let cart = useSelector((state) => state.cart.cart);
  const [product, setProductData] = useState();
  const [discountdata, setDiscountData] = useState();
  const [cartdata, setCartData] = useState();
  const ratingCompleted = (rating) => {
    setRating(rating);
  }
  const [id, setID] = useState();
  const [reviewId, setData] = useState()
  let Id = [];
  let multiID;

  const getItem = (item) => {
    // Function for click on an item
    alert('Id : ' + item.id + ' Title : ' + item.title);
  };

  // for image Carousel
  let productImage = useSelector((state) => state.allProducts.productImages.all);
  let image = []
  if (productImage) {
    if (productImage[0]) {
      if (productImage[0].Product_Image) {
        let data = productImage[0].Product_Image
        for (var i = 0; i < data.length; i++) {
          image.push({ imageuri: data[i].Product_Image })
        }
      }
    }
  }
  let productReviews = useSelector((state) => state.allProducts.productReviews.all);
  let partnerproduct = useSelector((state) => state.allProducts.partnerproducts.all);
  let relatedproduct = useSelector((state) => state.allProducts.related.all);
  let colors = useSelector((state) => state.allProducts.colors.all);
  let sizes = useSelector((state) => state.allProducts.sizes.all);
  if (productReviews) {
    existcomment = productReviews.filter(review => review.Email_Id === EmailID).length
  }

  if (!isEmpty(product)) {
    if (product.Is_Data_Exist === "0") {
      Id = []
    }
    else if (typeof product === "object") {
      if (relatedproduct) {
        let array = relatedproduct[0]?.Related_Products
        for (let i = 0; i < array?.length; i++) {
          Id.push(array[i].Related_Product_Of_Product_Id)
        }
      } else {
        Id = [];
      }
      if (Id) {
        multiID = Id.toString().replace(/\[.*\]/g, '')
      }
    }
  }
  const [size, setSize] = useState({ size: "", quantity: "" })

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [inventoryIsOpen, setInventoryIsOpen] = React.useState(false);
  const cartData = () => {
    let item = { partner: PartnerDetails, product: product, size: size.size, quantity: Number(size.quantity), Discount: cartdata.Discounts, Min: cartdata?.Discounts[0] ? cartdata?.Discounts[0].Min_Quantity : 1 }
    setModalIsOpen(!modalIsOpen)
    setSelected(false)
    let payload = {
      "Buyer_Partner_Detail_ID": ID,
      "Partner_Product_Id": partnerproduct.Partner_Product_Id,
      "Quantity": item.Min,
      "Seller_Partner_Detail_Id": partnerproduct.Partner_Details_Id,
      "Size": item.size
    }
    dispatch(addCart(payload, ID, setResponse));
    setSize({ size: "", quantity: "" })
    setPartnerDetails()

  }

  const [openredirect, setRedirectionOpen] = React.useState(false);
  const login = (data) => {
    if (data) {
      setPartnerDetails(data);
      dispatch(getProductSizeActionWishList(Product_Id, data.Partner_Details_Id, data, sizeAddtoCart));
      setInventoryIsOpen(false)
      setModalIsOpen(true);
      setCartLoading(true);
    }
  };
  const callBackGetData = (data) => {
    setProductLoading(false);
    if (data) {
      setProductData(data);
      setID(Product_Id)
    }
  };

  const [productLoading, setProductLoading] = useState(false);
  const [productpartnerdiscount, setPartnerData] = useState();
  useEffect(() => {
    if (Product_Id != product?.Product_Id) {
      setProductLoading(true);
      setProductData({});
      dispatch(getProductByIdAction(Product_Id, callBackGetData, ID));
      dispatch(getProductsImageAction(Product_Id));
      dispatch(getUserReviewsbyIdAction(Product_Id));
      dispatch(getPartnerproductsbyIdAction(Product_Id, ID));
      dispatch(getRelatedProductByIdAction(Product_Id));
    }
    if (multiID !== "") {
      dispatch(getRelatedProductAction(multiID, Partnerdetail, Product_Id))
    }
    dispatch(getProductColorsAction(masterId))
  }, [Product_Id]);

  const [cartSize, setCartSize] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);
  const [cartErrorMessage, setCartErrorMessage] = useState("");
  const setResponse = (message) => {
    toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
  };
  const sizeAddtoCart = (status, response, partnerData, message) => {
    setCartLoading(false);
    setCartData(partnerData)
    if (status && !isEmpty(response)) {
      if (response.length > 1) {
        setCartSize(response);
      } else {
        let item = { partner: partnerData, product: product, size: response[0]?.Product_Size, quantity: response[0]?.Product_Available_Stock, Discount: partnerData.Discounts, Min: partnerData?.Discounts[0] ? partnerData?.Discounts[0].Min_Quantity : 1 };
        let payload = {
          "Buyer_Partner_Detail_ID": ID,
          "Partner_Product_Id": partnerData.Partner_Product_Id,
          "Quantity": item.Min,
          "Seller_Partner_Detail_Id": partnerData.Partner_Details_Id,
          "Size": item.size
        }
        dispatch(addCart(payload, ID, setResponse));
        dispatch(productsizereset());
        setPartnerDetails();
        setPartnerData()
        setDiscountData()
        setModalIsOpen(false);
      }
    } else {
      setCartErrorMessage(message);
    }
  }

  const backClick = () => {
    props.navigation.navigate('Main', { screen: 'All', params: { page: 'products', filter: 'all' } });
  };

  const handleColorclick = (Product_Id) => {
    props.navigation.navigate('ProductDetails', { masterId: masterId, Product_Id: Product_Id, Partner_Details_Id: Partner_Details_Id });
  };

  const handleClick = async (item) => {
    props.navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id })
  };
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'first', title: 'Description' },
    { key: 'second', title: 'Features' },
    { key: 'third', title: 'Specifications' },
  ]);
  const renderScene = ({ route, jumpTo }) => {
    switch (route.key) {
      case 'first':
        return <ScrollView showsVerticalScrollIndicator={false}>
          <View style={{ paddingHorizontal: 15, paddingTop: 15, backgroundColor: esiColor.RTBC }}>
            {product[0]?.Product_Description &&
              <RenderHTML
                source={{ html: product[0]?.Product_Description }}
              />}
          </View>
        </ScrollView>
      case 'second':
        return <ScrollView showsVerticalScrollIndicator={false}>
          <View style={{ paddingHorizontal: 15, paddingTop: 15, backgroundColor: esiColor.RTBC }}>
            {product[0]?.Features &&
              <RenderHTML
                source={{ html: product[0]?.Features }}
              />}
          </View>
        </ScrollView>
      case 'third':
        return <ScrollView showsVerticalScrollIndicator={false}>
          <View style={{ paddingHorizontal: 15, paddingTop: 15, backgroundColor: esiColor.RTBC }}>
            {product[0]?.Specifications &&
              <RenderHTML
                source={{ html: product[0]?.Specifications }}
              />}
          </View>
        </ScrollView>
    }
  };
  // Tost message code.
  let dropDownAlertRef = React.useRef();
  const dropDownAlertFun = (type, title, sub_title) => {
    const inMilliSeconds = Math.floor(Math.random() * 6000 + 1);
    const inSeconds = (inMilliSeconds / 1000).toFixed(2);
    dropDownAlertRef.alertWithType(
      type,
      title,
      sub_title,
      {},
      inMilliSeconds
    );
  }

  const esiColor = useSelector(state => state.theme);

  const styles = StyleSheet.create({
    pagingText: { color: '#888', margin: 3 },
    pagingActiveText: { color: '#fff', margin: 3 },

    root: {
      backgroundColor: "#ffffff",
      marginTop: 20

    },

    containerm: {
      paddingLeft: 1,
      paddingRight: 16,
      paddingVertical: 12,
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginLeft: 1,
      width: '100%',
    },

    content: {
      marginLeft: 16,
      flex: 1,
    },
    tooltip: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'red',
      maxHeight: 100,
      maxWidth: 500,
      borderRadius: 13
    },
    contentHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    separatorm: {
      height: 1,
      // backgroundColor: "#CCCCCC",
    },
    image: {
      width: 45,
      height: 45,
      borderRadius: 20,
      marginTop: 10
    },

    name: {
      fontSize: 16,
      fontWeight: "bold",
      width: '50%',
      color: esiColor.itemColor
    },
    card: {
      minHeight: 400,
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignSelf: 'flex-start'
    },
    similarcard: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    pcard: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',

      flexShrink: 1,
      backgroundColor: esiColor.BackgroundColor
    },

    productDetailsSize: {
      width: 50,
      height: 50,
      backgroundColor: 'rgb(42, 175, 82)',
      borderRadius: 50,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 20,
      marginBottom: 20,
      borderColor: '#c4c4da',
      borderWidth: 1,
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white'

    },

    productDetailsSizeSelect: {
      width: 50,
      marginTop: 20,
      marginBottom: 20,
      height: 50,
      backgroundColor: 'white',
      borderRadius: 50,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderColor: '#c4c4da',
      borderWidth: 1,
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',

    },

    ribbon: {
      padding: 15,
      height: 20,
      backgroundColor: 'rgb(207, 152, 49)',
      color: '#dddeda',
      fontSize: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: '-0.1vw',
      marginLeft: 4,
      maxWidth: 100
    },

    productDetailsImgSelect: {
      backgroundColor: 'white',
      borderColor: '#c4c4da',
      borderWidth: 1,
      position: 'relative',
      textAlign: 'center',
      width: 50,
      height: 50,
      marginLeft: 15,
      maxHeight: 50,
      maxWidth: 50,
      marginTop: 30,
      borderRadius: 10,
      shadowRadius: 2
    },

    productDetailsImgSelect_color: {
      backgroundColor: 'white',
      borderStyle: "solid",
      borderColor: '#27b6cc',
      borderWidth: 2,
      position: 'relative',
      textAlign: 'center',
      alignItems: 'center',
      width: 50,
      height: 50,
      marginLeft: 15,
      maxHeight: 50,
      maxWidth: 50,
      marginTop: 30,
      borderRadius: 10,
      shadowRadius: 2

    },
    container: {
      paddingLeft: 1,
      paddingRight: 16,
      paddingVertical: 12,
      flexDirection: 'row',
      alignItems: 'flex-start'
    },
    content: {
      marginLeft: 16,
      flex: 1,
      backgroundColor: 'gray'
    },
    contentHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 6,
      borderBottomColor: esiColor.SBorderColor,
      borderBottomWidth: 1
    },
    separator: {
      height: 1,
      backgroundColor: "#CCCCCC"
    },
    image: {
      width: 45,
      height: 45,
      borderRadius: 20,
      marginLeft: 20
    },
    time: {
      fontSize: 11,
      color: "#808080",
    },
    name: {
      fontSize: 16,
      fontWeight: "bold",
      color: esiColor.itemColor

    },

  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <DropdownAlert
        ref={ref => {
          if (ref) {
            dropDownAlertRef = ref;
          }
        }}
        zIndex={1}
        showCancel={true}
      />
      <Header navigation={navigation} />
      <View style={{ marginLeft: 3, marginTop: -20 }}>
        <IconButton
          icon="arrow-left"
          size={27}
          color={esiColor.SIconColor}
          style={{ marginLeft: 3, marginTop: 40 }}
          onPress={() => backClick()}
        />
      </View>

      {product ?
        <ScrollView style={{ maxHeight: dimensions.height - 20 }}>
          <View style={{
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: "space-between"
          }}>
            <View style={{ flexDirection: 'column', flexShrink: 1, justifyContent: 'space-evenly' }}>
              <ImageCarousel image={image} dimensions={dimensions} />

              <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Text style={{ color: esiColor.DescColor, fontWeight: 'bold', margin: '3%', paddingLeft: dimensions.width <= 700 ? null : "20%" }}>Brand: <Text style={{ color: esiColor.brandFontColor }}>{product[0]?.Brand_Name}</Text> </Text>

                <Rating
                  readonly={true}
                  type='heart'
                  // ratingBackgroundColor='#c8c7c8'
                  startingValue={product[0]?.Esi_Rating}
                  ratingCount={5}
                  imageSize={20}
                  onFinishRating={(rating) => ratingCompleted(rating)}
                  style={{ paddingVertical: 10 }}
                  tintColor={esiColor.TintColor}

                />
              </View>

              {/* Colors */}

              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
              >

                <View style={{ flexDirection: "row", paddingLeft: dimensions.width <= 700 ? null : 80, marginTop: -10, width: dimensions <= 500 ? 400 : 350 }} >
                  {colors.Is_data_Exist !== "0" && colors?.map((data, index) => (
                    <View key={index}>
                      <TouchableOpacity >
                        <Card onPress={() => handleColorclick(data.Product_Id)}
                          style={data.Product_Id === Product_Id ? styles.productDetailsImgSelect_color : styles.productDetailsImgSelect}>

                          <Image source={{ uri: data?.Color_Variant.Color_Variant[0].Product_Image }} style={{ height: 45, resizeMode: 'center', width: 45, borderRadius: 10 }} />
                        </Card>
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>

              </ScrollView>

            </View>

            <View style={{ flexDirection: "column", flexShrink: 1, alignSelf: 'flex-start', justifyContent: "center", width: 700 }}>
              <View>
                <View style={{ alignItems: 'center', paddingRight: 5, paddingTop: 10 }}>
                  <Text style={{ textAlign: 'center', fontSize: 15, color: esiColor.brandFontColor, fontWeight: 'bold' }}>{product[0] && product[0]?.Product_Name}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <View style={{ paddingTop: 10, flexDirection: 'row' }}>
                  <View>
                    <Text style={{ textAlign: 'center', fontWeight: 'bold', opacity: 0.8, color: esiColor.itemColor, fontSize: 16 }}>₹{parseFloat(product[0]?.Partner_Selling_Price)}</Text>
                  </View>
                  <View style={{ marginLeft: 5, marginTop: 1 }} >
                    {/*Toottip*/}
                    <Tooltip backgroundColor={esiColor.BackgroundColor} width={350} height={80}
                      popover={<Text style={{ color: esiColor.DescColor }}>The prices shown in the product details page may change in the Supplier website based on the Seller price.</Text>}>
                      <Entypo name="info-with-circle" size={16} color="#27b6cc" />
                    </Tooltip>
                  </View>
                  <View style={{ paddingLeft: 10 }}>
                    <Text style={{ textAlign: 'center', textDecorationLine: 'line-through', opacity: 0.8, height: 25, fontSize: 16, fontWeight: 'bold', color: esiColor.itemColor }}>₹{product[0]?.Original_Price}</Text>
                  </View>
                </View>
              </View>
              {/* Partner Cards */}
              <View style={{ alignItems: 'center', paddingRight: 5 }}>
                <Text style={{ color: esiColor.itemColor, paddingTop: 10, marginLeft: 10, marginBottom: 5 }}> Grab this exciting offer from MeePaisa preferred partners</Text>
              </View>
              <View style={{ alignItems: 'center', paddingRight: 5 }}>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}>
                  <View style={styles.pcard}>
                    {!isEmpty(partnerproduct) ? (
                      partnerproduct.Is_Data_Exist === "0" ? (
                        <NoDataFound text={partnerproduct.UI_Display_Message} />
                      ) : typeof partnerproduct === "object" ? (
                        partnerproduct.map((data, index) => (

                          <View style={{ flexShrink: 1 }} key={data, index}>

                            <Card style={{
                              margin: 10, borderRadius: 4, width: 175, height: 215, borderColor: esiColor.SBorderColor,
                              borderWidth: 0.5, backgroundColor: esiColor.BackgroundColor
                            }}>
                              {parseInt(data?.Cashback_Product_Price) != 0 &&
                                <View style={{ flexDirection: 'row' }}>
                                  <View style={{ flexDirection: 'column', flex: 1 }}>
                                    <Text style={{ padding: 2, fontSize: 14, color: '#ffffff', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, marginBottom: 8 }}>{data.Is_Stock_Available === "1" ? <Text style={{ color: "green" }}>In Stock</Text> : (data.Is_Stock_Available === "0" ? <Text style={{ color: "red" }}>Out of Stock</Text> : "")}</Text>
                                  </View>
                                </View>
                              }
                              <View style={{ borderBottomColor: esiColor.SBorderColor, borderBottomWidth: 1, marginBottom: 10, marginTop: 10 }} />
                              <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 3 }}>
                                <Image source={{ uri: data?.Logo_Path }} style={{ height: 40, flex: 1, resizeMode: 'contain', marginBottom: 3, width: 100 }} />
                              </View>

                              <View style={{ width: '100%', marginTop: 12, flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 10 }}>
                                <Text style={{ paddingVertical: 3, color: esiColor.SIconColor, fontSize: 14, fontWeight: 'bold', textDecorationLine: 'line-through' }}>₹{parseFloat(data?.Partner_Selling_Price)}
                                </Text>
                              </View>
                              <View style={{ paddingLeft: 10 }}>
                                <Text style={{ textAlign: 'center', opacity: 0.8, height: 25, fontSize: 16, fontWeight: 'bold', color: esiColor.itemColor }}>₹{parseFloat(product[0]?.Partner_Selling_Price)}</Text>
                              </View>

                              <View style={{ flex: 1, marginTop: 5, justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
                                <TouchableOpacity disabled={data.Is_Stock_Available === "0" ? true : false} onPress={(e) => { setInventoryIsOpen(true); setDiscountData(data.Discounts); setPartnerData(data) }} style={{ pointerEvents: data.Partner_Type === "Physical" && data.Is_Stock_Available === "0" ? "none" : "" }}>
                                  <View style={{ opacity: data.Is_Stock_Available === "0" ? 0.5 : null, backgroundColor: esiColor.globalButtonColor, borderRadius: 2, paddingVertical: 5, paddingHorizontal: 10, height: 27 }}>
                                    <Text><Text style={{ color: esiColor.itemButtenColor, fontWeight: "bold", marginBottom: 2 }}>View Offers</Text>{isEmpty(sizes) && PartnerDetails && PartnerDetails.Partner_Details_Id === data.Partner_Details_Id}</Text>
                                  </View>
                                </TouchableOpacity>

                              </View>
                            </Card>
                          </View>

                        ))) : (
                        typeof partners
                      )
                    ) : (
                      <View style={{ marginBottom: 20, paddingLeft: 15 }}>
                        <Text style={{ color: esiColor.NoDataFound, fontWeight: 'bold' }}>Oops!!! No Partner Products found.</Text>
                      </View>
                    )}
                  </View>
                </ScrollView>
              </View>
            </View>
            <View style={{ flexDirection: "column", flexShrink: 1, alignSelf: 'flex-start', justifyContent: "center", width: 50 }} />
          </View>
          {/* Tabs Data */}

          <View style={{ minHeight: 100, paddingLeft: dimensions.width <= 700 ? null : 40, maxHeight: 400, height: 400, width: (width > height) ? height : width, padding: 2, justifyContent: 'space-evenly' }}>
            <TabView
              renderTabBar={props => <TabBar {...props}
                style={{ backgroundColor: esiColor.BackgroundColor }}
                activeColor='#27b6cc'
                inactiveColor={esiColor.itemColor}
                labelStyle={{ fontWeight: 'bold', fontSize: 11 }}
                indicatorStyle={{ backgroundColor: '#27b6cc' }} />}
              navigationState={{ index, routes }}
              renderScene={renderScene}
              onIndexChange={setIndex}
            />
          </View>

          {/* Product Reviews */}
          <View style={{ width: dimensions.width <= 700 ? '100%' : '80%' }}>
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ flexDirection: 'column', flex: 2, alignSelf: 'flex-start', marginLeft: 50, marginTop: 15 }}>
                <Text style={{ fontSize: 17, fontWeight: 'bold', color: esiColor.DescColor }}>Product Reviews </Text>
              </View>

              <View style={{ flexDirection: 'column', flex: 1, alignSelf: 'flex-end', margin: 10 }}>
                <TouchableOpacity disabled={((productReviews?.length > 0) && (productReviews?.filter((review) => {
                  return review?.Email_Id === EmailID
                }).length) > 0) ? true : false} style={{
                  backgroundColor: ((productReviews?.length > 0) && (productReviews?.filter((review) => {
                    return review?.Email_Id === EmailID
                  }).length) > 0) ? 'gray' : esiColor.globalButtonColor, width: 110, height: 30, borderRadius: 5, justifyContent: 'center', alignItems: 'center'
                }} onPress={() => toggleModal()}>
                  <Text style={{ color: esiColor.itemButtenColor }}>ADD REVIEW</Text>
                </TouchableOpacity>
              </View>

            </View>
            <View style={styles.containerm}>
              <Image style={styles.image} source={require("../../../assets/images/img/logo/meePaisa_logo.png")} />
              <View style={styles.content}>
                <View style={styles.contentHeader}>
                  <Text style={styles.name}>meepaisa Review</Text>
                  <View style={{ marginTop: -8 }}>
                    <Rating
                      rating={product[0]?.Esi_Rating ? (parseInt(product[0]?.Esi_Rating)) : (0)}
                      numberOfStars={5}
                      ratingCount={5}
                      imageSize={20}
                      style={{ paddingVertical: 10 }}
                      readonly={true}
                      tintColor={esiColor.TintColor}
                      type='heart'
                      // ratingBackgroundColor='#c8c7c8'
                      onFinishRating={(rating) => ratingCompleted(rating)} />
                  </View>
                </View>
                <View style={{ flexWrap: "wrap", flex: 1 }}>
                  {product[0]?.Esi_Reviews &&
                    <RenderHTML source={{ html: product[0]?.Esi_Reviews }} />}
                </View>
              </View>
            </View >

            <View>
              {!isEmpty(productReviews) && (
                productReviews?.Is_Data_Exist === "0" || isEmpty(productReviews) ? (
                  <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    <Text style={{ color: esiColor.NoDataFound, fontWeight: 'bold' }}>No Product Reviews found!</Text>
                  </View>
                ) : typeof productReviews === "object" && (
                  <>
                    {productReviews?.slice(0, visible).map((data, index) => (
                      <View key={index}>
                        <View style={styles.separatorm} />
                        <View style={styles.container}>
                          <TouchableOpacity onPress={() => { }}>
                            <Image style={styles.image} source={{ uri: data.Logo_Path }} />
                          </TouchableOpacity>
                          <View style={styles.content}>
                            <View style={styles.contentHeader}>
                              <Text style={styles.name}>{data.Partner_Name}</Text>
                              <Text style={styles.name}>{data.Full_Name}
                              </Text>

                              <Rating
                                readonly={true}
                                tintColor={esiColor.TintColor}
                                type='heart'
                                startingValue={data?.Rating}
                                ratingCount={5}
                                imageSize={20}
                                onFinishRating={(rating) => ratingCompleted(rating)}
                                style={{ paddingVertical: 10 }}
                              />
                            </View>
                            <Text rkType='primary3 mediumLine'>{data.Comments &&
                              <RenderHTML
                                tagsStyles={{
                                  span: { color: esiColor.DescColor }
                                }}
                                source={{ html: data.Comments }}
                              />}</Text>
                          </View>
                        </View>

                      </View>
                    ))}
                    <View style={{ alignSelf: 'center', marginVertical: 15, marginTop: 2 }}>
                      {productReviews?.length > visible &&
                        <Text variant="contained" mode="outlined" style={{ fontWeight: 'bold', color: '#27b6cc' }} onPress={() => setVisible(visible + 3)}>Show More ▼</Text>
                      }
                    </View>
                  </>
                ))}
            </View>
          </View>
          {/* Similar Products */}

        </ScrollView> :
        <ActivityIndicator color='#27b6cc' />
      }

      {/* For Signin */}
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={isModalVisible}>
        <View style={{
          flex: 1, maxWidth: 500, minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10,
          backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
          shadowOpacity: 1, shadowRadius: 5
        }}>
          <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
        </View>
      </Modal>

      {/* For Add Review */}
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={isReviewModalVisible}>
        <View style={{
          flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 325, borderRadius: 10,
          backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
          shadowOpacity: 1, shadowRadius: 5
        }}>
          <View style={{
            alignItems: 'flex-end', marginTop: '-6%'
          }}>
            <TouchableRipple style={{ width: 36 }} onPress={() => { setReviewModalVisible(false), setComment(''), setRating(0) }}>
              <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
            </TouchableRipple>
          </View>
          <View style={{ margin: '4%' }}>

            <TextInput
              style={{ backgroundColor: esiColor.TextBC }}
              activeUnderlineColor={esiColor.TextOuterLine}
              selectionColor={esiColor.TextSelection}
              theme={{
                colors: {
                  primary: esiColor.TextHighlight,
                  text: esiColor.Text,
                  placeholder: esiColor.TextPlaceholder,
                }
              }}
              outlineColor={esiColor.TextOuterLine}
              underlineColor={esiColor.TextUnderline}
              activeOutlineColor={esiColor.TextOuterLine}
              label='Add Review'
              mode='outlined'
              onChangeText={(Text) => { setComment(Text); handleOnChange(Text); setCommentError("") }}
              multiline={true}
              numberOfLines={4}
              value={comment}
            />
            <Text style={{ color: 'red' }}>{commenterror}</Text>
            <Text style={{ color: 'red' }}>{error}</Text>

            <View style={{
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center'
            }}>
              <Rating
                tintColor={esiColor.TintColor}
                type='heart'
                startingValue={rating}
                ratingCount={5}
                imageSize={30}
                style={{ paddingVertical: 10 }}
                onFinishRating={(value) => { setRating(value); setRatingError("") }}
              />
              {!rating || rating === 0 ? (
                <Text style={{ color: "red" }}>
                  Please select the Rating.
                </Text>
              ) : (null)
              }
              <View style={{ width: 100 }}>
                <Button style={{ alignItems: 'center', marginTop: 10, backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => addReviewModal()}>
                  <Text style={{ color: esiColor.itemButtenColor }}>SUBMIT</Text>
                </Button>
              </View>
            </View>

          </View>

        </View>
      </Modal>

      {/* For Edit Review */}
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={isEditReviewModalVisible}>
        <View style={{
          flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 325, borderRadius: 10,
          backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
          shadowOpacity: 1, shadowRadius: 5
        }}>

          <View style={{
            alignItems: 'flex-end', marginTop: '-6%'
          }}>
            <TouchableRipple style={{ width: 36 }} onPress={() => { setEditReviewModalVisible(false), setComment(''), setRating(0) }} >
              <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
            </TouchableRipple>
          </View>
          <View style={{ margin: '4%' }}>

            <TextInput
              label='Edit Review'
              mode='outlined'
              onChangeText={(Text) => { setComment(Text); editOnChange(Text); setCommentError("") }}
              multiline={true}
              numberOfLines={4}

              value={comment}
              style={{ backgroundColor: esiColor.TextBC }}
              activeUnderlineColor={esiColor.TextOuterLine}
              selectionColor={esiColor.TextSelection}
              theme={{
                colors: {
                  primary: esiColor.TextHighlight,
                  text: esiColor.Text,
                  placeholder: esiColor.TextPlaceholder,
                }
              }}
              outlineColor={esiColor.TextOuterLine}
              underlineColor={esiColor.TextUnderline}
              activeOutlineColor={esiColor.TextOuterLine}
            />
            <Text style={{ color: 'red' }}>{commenterror}</Text>
            <View style={{
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center'
            }}>
              <Rating
                tintColor={esiColor.TintColor}
                type='heart'
                startingValue={rating}
                ratingCount={5}
                imageSize={30}
                style={{ paddingVertical: 10 }}
                onFinishRating={(value) => { setRating(value); setRatingError("") }}

              />
              {!rating || rating === 0 ? (
                <Text style={{ color: "red", fontSize: 16 }}>
                  Please select the Rating.
                </Text>
              ) : (null)
              }

              <View style={{ width: 100 }}>
                <Button style={{ alignItems: 'center', marginTop: 10, backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => editReviewModal()}>
                  <Text style={{ color: esiColor.itemButtenColor }}>SUBMIT</Text>
                </Button>
              </View>
            </View>

          </View>

        </View>
      </Modal>

      {/* Partner Redirection Modal */}
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={openredirect}>
        <View style={{
          flex: 1, maxWidth: 500, minWidth: 300, minHeight: 200, maxHeight: 200,
          borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
          shadowOpacity: 1, shadowRadius: 5
        }}>

          <View style={{
            alignItems: 'flex-end', marginTop: '-6%'
          }}>
            <TouchableRipple style={{ width: 36 }} onPress={() => { setRedirectionOpen(false) }} >
              <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
            </TouchableRipple>
          </View>
          <Text style={{ color: esiColor.DescColor, alignItems: 'center', marginLeft: 15 }}>Redirecting to partner site. Please wait....</Text>

          <View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <Button icon="arrow-right-bold" style={{ size: 15, marginTop: 20 }} />
              <Image source={{ uri: imageURL }} style={{ height: 60, width: 60, resizeMode: 'contain' }} />
            </View>
          </View>
        </View>


      </Modal>

      {/* Size Selection Modal */}
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={modalIsOpen}>
        <View style={{
          flex: 1, maxWidth: 500, minWidth: 300, minHeight: 200, maxHeight: 250, borderRadius: 10,
          backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
          shadowOpacity: 1, shadowRadius: 5
        }}>

          <View style={{
            alignItems: 'flex-end', marginTop: '-6%'
          }}>
            <TouchableRipple style={{ width: 36 }} onPress={() => { onCloseModal(); setCartErrorMessage(""); }} >
              <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
            </TouchableRipple>
          </View>
          {
            !isEmpty(cartSize) ?
              <View>
                <Text style={{ textAlign: "center", color: esiColor.DescColor }}>
                  Select Size
                </Text>

                <View style={{ textAlign: "center", justifyContent: "space-evenly", flexDirection: 'row' }}>
                  {
                    cartSize?.map((size, index) => (
                      <View key={index}>
                        <TouchableOpacity
                          key={index}
                          onPress={() => { setSize({ size: size.Product_Size, quantity: size.Product_Available_Stock }); handleSelect(size.Product_Size) }}
                        >
                          <View style={selected === size.Product_Size ? styles.productDetailsSize : styles.productDetailsSizeSelect}><Text icon="format-size">{size.Product_Size}</Text>
                          </View>

                        </TouchableOpacity>
                        {
                          size.Product_Available_Stock ?
                            <Text >
                              {parseInt(size.Product_Available_Stock) < 11 ?
                                <Text style={styles.ribbon}>{size.Product_Available_Stock} Left</Text>
                                : null}
                            </Text> : null
                        }
                      </View>
                    ))
                  }
                </View>

                <View>
                  {/* <Pressable onPress={cartData}> */}
                  <Button
                    variant="contained"
                    color={esiColor.globalButtonColor}
                    type="submit"
                    onPress={() => cartData()}
                    disabled={size.size && size.quantity ? false : true}
                    style={{ color: size.size && size.quantity ? "" : "blue" }}
                  >
                    Proceed
                  </Button>
                  {/* </Pressable> */}
                </View>
              </View>
              :
              cartLoading ? <ActivityIndicator color='#27b6cc' /> :
                <View style={{ flexDirection: 'row', justifyContent: 'center', textAlign: "center" }}>
                  <Text style={{ color: "red" }} >{cartErrorMessage}</Text>
                </View>
          }
        </View>
      </Modal >
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={inventoryIsOpen}>
        <View style={{
          flex: 1, maxWidth: 500, minWidth: 300, minHeight: 200, maxHeight: 250, borderRadius: 10,
          backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
          shadowOpacity: 1, shadowRadius: 5
        }}>
          <View style={{
            alignItems: 'flex-end', marginTop: '-6%'
          }}>

            <TouchableOpacity onPress={() => { setInventoryIsOpen(false) }}>
              <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={30} icon="close" />
            </TouchableOpacity>

          </View>
          <View>
            <Text style={{ textAlign: 'center', fontSize: 17, color: esiColor.DescColor, fontWeight: 'bold' }}>Discount offers based on Quantity</Text>

            <View style={{ borderBottomColor: '#27B6CC', borderBottomWidth: 1.5 }} />
            <View style={{ height: 150 }}>
              <ScrollView>
                {discountdata?.map((data, index) => (
                  <View key={index}>
                    <Text style={{ textAlign: 'center', fontSize: 12, padding: 8, color: 'green', fontWeight: 'bold' }}>By Purchasing {data.Min_Quantity} to {data.Max_Quantity} Products will get {data.Discount_In_Percentage}% discount</Text>
                    <Text style={{ textAlign: 'center', fontSize: 12, color: 'orange', fontWeight: 'bold' }}>(Each product cost {data.Price_Per_Quantity}/-)</Text>
                  </View>
                ))}
              </ScrollView>
            </View>
          </View>

          <View style={{ flex: 1, marginTop: 5, justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
            <TouchableOpacity onPress={() => { login(productpartnerdiscount) }}>
              <View style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 6, paddingVertical: 5, paddingHorizontal: 10, height: 27 }}>
                <Text style={{ color: esiColor.itemButtenColor, fontWeight: "bold" }}>Add to Cart</Text>
              </View>
            </TouchableOpacity>

          </View>
        </View>
      </Modal >

    </Surface >

  );
}

