import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import { Avatar, Card, Text } from "react-native-paper";
import { useDispatch, useSelector } from 'react-redux';
import { Dimensions, Platform, View } from 'react-native';
import { dashboardbrandsCount } from '../../../../state/actions/reports/dashboardActions';
import Ionicons from '@expo/vector-icons/Ionicons';

//dimensions for height and width
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

function TotalBrands({ navigate,esiColor }) {
    //API dispatch
    const dispatch = useDispatch();
    //dashbrands contains the data from  GET API using state call
    const dashbrands = useSelector(state => state.dashboard.Brands.data);
    //dispatch is used to call the API
    useEffect(() => {
        dispatch(dashboardbrandsCount())
    }, []);

    return (
        <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', padding: 5 }}>
            <Card style={{
                borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 10, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                shadowOffset: { width: 1, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, elevation: 5, marginLeft: 10, marginRight: 10,
                maxWidth: Platform.OS === "ios" ? windowWidth > 300 ? 450 : 450 : 400, minWidth: Platform.OS === "ios" ? 350 : 320
            }}>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -70 }}>
                    <Avatar.Icon size={50} icon="tag-heart-outline" color="white" style={{ backgroundColor: 'orange' }} />
                </View>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                    <Text style={{ padding: 10, fontWeight: 'bold',color: esiColor.brandFontColor  }}>AVAILABLE BRANDS</Text>
                    <Text style={{ padding: 10, fontSize: 20, fontWeight: "bold",color: esiColor.Text  }}>{dashbrands.Total}</Text>
                </View>
                <View style={{ flexDirection: 'row', margin: 10 }}>
                    {dashbrands.Month - dashbrands.Avg >= 0 && <>

                        <Ionicons name="arrow-up" size={25} color="green" />
                        <View style={{ flexDirection: 'column', margin: 5 }}>
                            <Text style={{ color: esiColor?.Text }}>
                                {dashbrands.Month}
                            </Text>
                        </View>
                    </>
                    }
                    {dashbrands.Month - dashbrands.Avg < 0 &&
                        <>
                            <Ionicons name="arrow-down" size={25} color="red" />
                            <View style={{ flexDirection: 'column', margin: 5 }}>
                                <Text style={{ color: esiColor?.Text }}>
                                    {dashbrands.Month}
                                </Text>
                            </View>
                        </>
                    }

                    <View style={{ flexDirection: 'column', margin: 5 }}>
                        <Text style={{ color: esiColor?.Text }}>
                            Since last 30 days
                        </Text>
                    </View>

                </View>
            </Card>
        </View>
    );
}


export default TotalBrands;