import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Image, Dimensions, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Text, Searchbar, Portal, Surface, Menu, Button, DefaultTheme } from 'react-native-paper';
import { IconButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getEmployeCompletedRequestsAction, userserviceFilter } from '../../../state/actions/Services/userservicesActions';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import isEmpty from '../../../state/validations/is-empty';
import Header from "../header/header";
import Icon from 'react-native-vector-icons/FontAwesome';
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const numberOfItemsPerPageList = [5, 10, 20];

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'User_Service_Request_Id',
    label: 'User Service Request ID'
  },
  {
    value: 'User_Email_Id',
    label: 'User Email ID'
  },
  {
    value: 'Service_Request_Status_From_Partner',
    label: 'Service Request '
  },
  {
    value: 'Service_Type',
    label: 'Service Type'
  },
  {
    value: 'Status',
    label: 'Status'
  }

];

const EmployeCompletedserservices = ({ navigation }) => {
  const [page, setPage] = React.useState(0);

  const toast = useToast();

  const [showDropDown, setShowDropDown] = React.useState(false);
  const EmailID = useSelector(state => state.auth?.user);
  let Alluserservice = useSelector(state => state.userservice.employecompleteduserservices.all);
  let userservice = Alluserservice?.results;
  const esiColor = useSelector(state => state.theme);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [userserviceMenu, setUserserviceMenu] = React.useState('All')
  const [userserviceSearch, setUserserviceSearch] = React.useState('');
  const [oldSearch, setOldSearch] = React.useState(0);
  const [visibles, setVisibles] = React.useState(false);
  const openMenu = () => setVisibles(true);

  const closeMenu = () => setVisibles(false);
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > Alluserservice?.Pagination?.TotalCount ? Alluserservice?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setPagination({ PageNo: Number(page) + Number(1), PageSize: e, SortBy: "User_Service_request_Id", SortOrder: "DESC" })
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "User_Service_request_Id", SortOrder: "DESC" });
  }
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "User_Service_request_Id", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      userservicesCall();
    }
  }, [pagination])
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const userservicesCall = () => {
    if (isEmpty(EmailID)) {
      setIsLoading(true);
      let formData = {
        "search": "",
        "search_by_filter": userserviceMenu,
        "Partner_Employee_Id": EmailID?.Partner_User_Details_Id,
        "Partner_Detail_Id": EmailID?.Partner_Details_Id,
        "User_Service_request_Status": "",
        "User_Service_request_Id": "",
        "User_Email_Id": "",
        "Partner_Service_Id": "",
        "City_Id": "",
        "Service_Request_Status_From_Partner": "COMPLETED",
        "Records_Filter": "FILTER"
      };

      dispatch(getEmployeCompletedRequestsAction(formData, pagination))
    }
  };
  //API dispatch
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "User_Service_request_Id", SortOrder: "DESC" }
    let formData = {
      "search": "",
      "search_by_filter": userserviceMenu,
      "Partner_Employee_Id": EmailID?.Partner_User_Details_Id,
      "Partner_Detail_Id": EmailID?.Partner_Details_Id,
      "User_Service_request_Status": "COMPLETED",
      "User_Service_request_Id": "",
      "User_Email_Id": "",
      "Partner_Service_Id": "",
      "City_Id": "",
      "Service_Request_Status_From_Partner": "COMPLETED",
      "Records_Filter": "FILTER"
    };

    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getEmployeCompletedRequestsAction(formData, pagecall))
  };

  React.useEffect(() => {
    return () => {
    };
  }, []);
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const dispatch = useDispatch()
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const MINUTE_MS = 2000;

  React.useEffect(() => {
    if (!isEmpty(EmailID)) {
      let formData = {
        "search": "",
        "search_by_filter": userserviceMenu,
        "Partner_Employee_Id": EmailID?.Partner_User_Details_Id,
        "Partner_Detail_Id": EmailID?.Partner_Details_Id,
        "User_Service_request_Status": "COMPLETED",
        "User_Service_request_Id": "",
        "User_Email_Id": "",
        "Partner_Service_Id": "",
        "City_Id": "",
        "Service_Request_Status_From_Partner": "COMPLETED",
        "Records_Filter": "FILTER"
      };
      dispatch(getEmployeCompletedRequestsAction(formData, pagination))
    }
  }, [permissions, EmailID]);
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('User_Service_Request_Id');

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setOrderBy(property);
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property);
  };
  const [visible, setVisible] = React.useState(false);
  const [Viewdata, setData] = React.useState();

  const hideDialog = () => setVisible(false);

  const handleChange = (itemValue: any) => {
    setUserserviceMenu(itemValue);
  };
  const handleSearch = (value: any) => {

    let formData = {
      "search": value,
      "search_by_filter": userserviceMenu,
      "Partner_Employee_Id": EmailID?.Partner_User_Details_Id,
      "Partner_Detail_Id": EmailID?.Partner_Details_Id,
      "User_Service_request_Status": "COMPLETED",
      "User_Service_request_Id": "",
      "User_Email_Id": "",
      "Partner_Service_Id": "",
      "City_Id": "",
      "Service_Request_Status_From_Partner": "COMPLETED",
      "Records_Filter": "FILTER"
    };
    setUserserviceSearch(value)

    dispatch(getEmployeCompletedRequestsAction(formData, pagination))
  };

  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [userservicepageMenu, setUserservicePageMenu] = React.useState('EmployeCompletedserservices')
  const handleChangeMenu = (itemValue: any) => {
    navigation.navigate(itemValue)
    setUserservicePageMenu(itemValue);

  };
  const handleClickNewServicess = async () => {
    navigation.navigate('EmployeNewuserservices')
    setVisibles(false)
  };
  const handleClickInProgressServicess = async () => {
    navigation.navigate('EmployeInprogressserservices')
    setVisibles(false)
  };
  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };
  const [webpage, setwebPage] = React.useState(1);
  const DynamicPageIncrease = (e) => {
    setwebPage(e < Alluserservice?.Pagination?.TotalPages ? e + Number(1) : Alluserservice?.Pagination?.TotalPages)
    setPagination({ PageNo: e == Alluserservice?.Pagination?.TotalPages ? Alluserservice?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "Partner_Bidding_Available_Service_Id", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "Partner_Bidding_Available_Service_Id", SortOrder: "DESC" });
  }
  const style = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    containerstyle: {
      width: 180,
      backgroundColor: esiColor.BackgroundColor
    },
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 160
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      marginLeft: 7,
      width: 140
    },
    tablerowaction: {
      justifyContent: "center",
      textAlign: "center",
      marginLeft: -5,
      width: 140
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'center',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: 'white',
      left: 22,
      top: 8,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.BackgroundColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
  },
  rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
  },
  rowsPerPagePickers: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      borderColor: 'gray',
      borderWidth: 0.5,
      paddingHorizontal: 8,
  },
  paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      // backgroundColor: esiColor.SIconColor
  },
  paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      // backgroundColor: esiColor.SIconColor
  },
  pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
  },
  });
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      {/* view popup */}
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: dimensions <= 500 ? 1 : 3 }} />
            <View style={{ flex: dimensions <= 500 ? 1 : 3 }} />
            <View style={{ flex: 0.3, justifyContent: "flex-end", paddingTop: 10 }}>
              <IconButton
                iconColor={esiColor.GBFColor}
                icon="close"
                size={20}
                onPress={() => setVisible(!visible)}
              />
            </View></View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor }}>Services View</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView >
              <Dialog.Content >
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>User Service ID</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.User_Service_Request_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>User Email ID</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.User_Email_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>User Service Request </Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.User_Service_Request_Status} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Advance Payment Status</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.Advance_Payment_Status} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Service Id</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.Partner_Service_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Payment Status</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.Payment_Status} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Review</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.Review} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>User Name</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.User_Full_Name} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>User Mobile</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.User_Mobile} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Service Start Date</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.Service_Start_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Service End Date</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.Service_End_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 3 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{Viewdata?.Status} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>

        <View style={{
          flexDirection: "column"
        }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}> Employee Completed Servicess</Text>
        </View>
        <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
          <View style={{ flexShrink: 0.1, paddingTop: 5 }}>
            <View style={{
              flex: 1,
              paddingTop: 10,
              flexDirection: 'row',
              justifyContent: 'center',

            }}>
              {Platform.OS == "web" ?
                <Picker
                  selectedValue={userservicepageMenu}
                  style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.DescColor }}
                  onValueChange={(itemValue, itemIndex) => handleChangeMenu(itemValue)}
                >
                  {/* <Picker.Item label="Accepted Services" value="Accepteduserservices" /> */}
                  <Picker.Item label="Completed Services" value="EmployeCompletedserservices" />
                  <Picker.Item label="New Services" value="EmployeNewuserservices" />
                  <Picker.Item label="In-Progress Services" value="EmployeInprogressserservices" />
                  {/* <Picker.Item label="Rejected Services" value="Inprogressuserservices" /> */}
                </Picker>
                :
                <Menu
                  visible={visibles}
                  onDismiss={closeMenu}
                  style={{ marginTop: 40 }}
                  anchor={<Button style={{ borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.DescColor }} mode="outlined" onPress={openMenu} icon='chevron-down' color='white'><Text style={{ color: 'white' }}>Completed Services</Text></Button>}>
                  <Menu.Item onPress={(e) => { handleClickNewServicess(); }} title="New Services" />
                  <Menu.Item onPress={(e) => { handleClickInProgressServicess(); }} title="In-Progress Services" />

                </Menu>}
            </View>
          </View>
        </View>
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== "web" ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[style.dropdown]}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                inputSearchStyle={style.inputSearchStyle}
                iconStyle={style.iconStyle}
                data={filterOptions}
                containerStyle={style.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={userserviceMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={userserviceMenu}
                style={{ height: 51, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1,  borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.DescColor }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="User Service Request ID" value="User_Service_Request_Id" />
                <Picker.Item label="User Email ID" value="User_Email_Id" />
                <Picker.Item label="Service Request " value="Service_Request_Status_From_Partner" />
                <Picker.Item label="Service Type" value="Service_Type" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
               style={{ borderRadius: 5, borderWidth: 0.3, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, }}
               iconColor={esiColor.SIconColor}
               theme={{ colors: { text: esiColor.SBTextColor } }}
               placeholderTextColor={esiColor.SPHTextColor}
               placeholder="Search"
              onChangeText={(value) => handleSearch(value)}
              value={userserviceSearch}
            />
          </View>
        </View>
        <ScrollView horizontal={Platform.OS === 'web' && dimensions >= 500 ? false : true}>
          <View style={style.mainbox}>
            <Card>
              {/* Datatable */}
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={style.databeHeader}>
                  <DataTable.Title  style={style.title} active={orderBy === 'User_Service_Request_Id'}
                    sortDirection={orderBy === 'User_Service_Request_Id' ? order : 'ascending'}
                    onPress={createSortHandler('User_Service_Request_Id')}><Text style={style.titletext}>User Service Request ID</Text></DataTable.Title>

                  <DataTable.Title  style={style.title} active={orderBy === 'User_Email_Id'}
                    sortDirection={orderBy === 'User_Email_Id' ? order : 'ascending'}
                    onPress={createSortHandler('User_Email_Id')}><Text style={style.titletext}>User Email ID</Text></DataTable.Title>

                  <DataTable.Title  style={style.title} active={orderBy === 'Service_Request_Status_From_Partner'}
                    sortDirection={orderBy === 'Service_Request_Status_From_Partner' ? order : 'ascending'}
                    onPress={createSortHandler('Service_Request_Status_From_Partner')}><Text style={style.titletext}>Service Request</Text></DataTable.Title>

                  <DataTable.Title  style={style.title} active={orderBy === 'Service_Type'}
                    sortDirection={orderBy === 'Service_Type' ? order : 'ascending'}
                    onPress={createSortHandler('Service_Type')}><Text style={style.titletext}>Service Type</Text></DataTable.Title>

                  <DataTable.Title  style={style.title} active={orderBy === 'Status'}
                    sortDirection={orderBy === 'Status' ? order : 'ascending'}
                    onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                  <DataTable.Title  style={style.title}><Text style={{ justifyContent: 'center', fontSize: 14, fontWeight: "bold" ,color:esiColor.Text }}>Actions</Text></DataTable.Title>

                </DataTable.Header>
                {
                  userservice?.map((userservice, index) => (
                    <DataTable.Row style={style.databeBox} key={userservice?.User_Service_Request_Id}>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{userservice?.User_Service_Request_Id}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{userservice?.User_Email_Id}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{userservice?.Service_Request_Status_From_Partner}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{userservice?.Service_Type}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{userservice?.Status}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerowaction}>
                        <IconButton
                          icon="eye"
                          iconColor={esiColor.globalButtonColor}
                          size={20}
                          onPress={(e) => { setData(userservice); setVisible(!visible) }}
                        />
                      </DataTable.Cell>
                    </DataTable.Row>
                  ))
                }
                {isEmpty(userservice) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                 {Platform.OS !== "web" ?
                <DataTable.Pagination
                  page={page}
                  numberOfPages={Math.ceil(Alluserservice?.Pagination?.TotalCount / numberOfItemsPerPage)}
                  onPageChange={page => DynamicPage(to < Alluserservice?.Pagination?.TotalCount ? page : 0)}
                  label={Alluserservice?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Alluserservice?.Pagination?.TotalCount}` : '0-0'}
                  showFastPaginationControls={(Number(page) + Number(1)) > Alluserservice?.Pagination?.TotalPages ? false : true}
                  numberOfItemsPerPageList={numberOfItemsPerPageList}
                  numberOfItemsPerPage={numberOfItemsPerPage}
                  onItemsPerPageChange={onItemsPerPageChange}
                  selectPageDropdownLabel={'Rows per page'}
                />
                :
                <View style={style.rowsPerPageContainer}>
                  <Text style={{ color: esiColor.Text }}>rows per page: </Text>
                  <Picker
                    style={style.rowsPerPagePicker}
                    selectedValue={numberOfItemsPerPage}
                    onValueChange={(e) => onItemsPerPageChange(e)}
                  >
                    <Picker.Item label="5" value={5} />
                    <Picker.Item label="10" value={10} />
                    <Picker.Item label="20" value={20} />
                  </Picker>
                  <View style={style.paginationContainer}>
                    <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                      <Icon name="angle-left" size={20} color={esiColor.Text} />
                    </TouchableOpacity>
                    <Text style={style.pageInfoText}>
                      {Alluserservice?.Pagination?.PageNo + ' of ' + Alluserservice?.Pagination?.TotalPages}
                    </Text>
                    <TouchableOpacity onPress={e => DynamicPageIncrease(to < Alluserservice?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                      <Icon name="angle-right" size={20} color={esiColor.Text} />
                    </TouchableOpacity>
                  </View>
                </View>}
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}



export default EmployeCompletedserservices