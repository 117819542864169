import axios from "axios";
import config from "./../config";
import shopperconfig from "./shopperconfig";
export const ALL_TRANSACTION_REQUEST = "ALL_TRANSACTION_REQUEST";
export const ALL_TRANSACTION_GET = "ALL_TRANSACTION_GET";
export const ALL_TRANSACTION_FAIL = "ALL_TRANSACTION_FAIL";

/**Authentication Permissions */
const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
export const getAllTransactions = (formData:any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_TRANSACTION_REQUEST,
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2B_Transactions/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_TRANSACTION_GET,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_TRANSACTION_GET,
          payload: data.results
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ALL_TRANSACTION_FAIL,
      payload: error
    });
  }
};

