import {
  SHOPPERS_INVENTORIES_REQUEST,
  SHOPPERS_INVENTORIES_SUCCESS,
  SHOPPERS_INVENTORIES_FAIL,
  SHOPPERS_INVENTORIES_SIZES_REQUEST,
  SHOPPERS_INVENTORIES_SIZES_SUCCESS,
  SHOPPERS_INVENTORIES_SIZES_FAIL,
  SHOPPERS_INVENTORY_GET_REQUEST,
  SHOPPERS_INVENTORY_GET_SUCCESS,
  SHOPPERS_INVENTORY_GET_FAIL,
  SHOPPERS_INVENTORY_CREATE_REQUEST,
  SHOPPERS_INVENTORY_CREATE_SUCCESS,
  SHOPPERS_INVENTORY_CREATE_FAIL,
  SHOPPERS_INVENTORY_UPDATE_REQUEST,
  SHOPPERS_INVENTORY_UPDATE_SUCCESS,
  SHOPPERS_INVENTORY_UPDATE_FAIL,
  SHOPPERS_INVENTORIES_FILTER,
  SHOPPERS_INVENTORY_PRODUCTS_REQUEST,
  SHOPPERS_INVENTORY_PRODUCTS_SUCCESS,
  SHOPPERS_INVENTORY_PRODUCTS_FAIL,

  SHOPPERS_INVENTORY_PARTNERS_REQUEST,
  SHOPPERS_INVENTORY_PARTNERS_SUCCESS,
  SHOPPERS_INVENTORY_PARTNERS_FAIL,

  SHOPPERS_INVENTORY_DETAILS_REQUEST,
  SHOPPERS_INVENTORY_DETAILS_SUCCESS,
  SHOPPERS_INVENTORY_DETAILS_FAIL,

  SHOPPERS_PRODUCTSIZE_INVENTORY_REQUEST,
  SHOPPERS_PRODUCTSIZE_INVENTORY_SUCCESS,
  SHOPPERS_PRODUCTSIZE_INVENTORY_FAIL,

  SHOPPERS_INVENTORY_PRODUCT_MASTER_FAIL,
  SHOPPERS_INVENTORY_PRODUCT_MASTER_REQUEST,
  SHOPPERS_INVENTORY_PRODUCT_MASTER_SUCCESS,

} from '../../actions/shoppers/shoppersinventoryAction'

const initialState = {
  shopperinventories: { all: [], error: '', isLoading: false },
  sizes: { all: [], error: '', isLoading: false },
  inventory: { inventory: {}, error: '', isLoading: false },
  inventoryCreate: { inventory: {}, error: '', isLoading: false },
  inventoryUpdate: { inventory: {}, error: '', isLoading: false },
  inventoryProducts: { products: [], error: '', isLoading: false },
  inventoryPartners: { partners: [], error: '', isLoading: false },
  inventoryDetails: { details: [], error: '', isLoading: false },
  inventoryiMasters: { imasters: [], error: '', isLoading: false },
  inventorySizes: { sizes: [], error: '', isLoading: false },
  inventoryFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOPPERS_INVENTORIES_REQUEST:
      return { ...state, shopperinventories: { all: state.shopperinventories.all, error: '', isLoading: true } };
    case SHOPPERS_INVENTORIES_SUCCESS:
      return { ...state, shopperinventories: { all: action.payload, error: '', isLoading: false } };
    case SHOPPERS_INVENTORIES_FAIL:
      return { ...state, shopperinventories: { all: [], error: action.payload, isLoading: false } };
    case SHOPPERS_INVENTORIES_SIZES_REQUEST:
      return { ...state, sizes: { all: state.sizes.all, error: '', isLoading: true } };
    case SHOPPERS_INVENTORIES_SIZES_SUCCESS:
      return { ...state, sizes: { all: action.payload, error: '', isLoading: false } };
    case SHOPPERS_INVENTORIES_SIZES_FAIL:
      return { ...state, sizes: { all: [], error: action.payload, isLoading: false } };
    case SHOPPERS_INVENTORY_GET_REQUEST:
      return { ...state, inventory: { inventory: {}, error: '', isLoading: true } };
    case SHOPPERS_INVENTORY_GET_SUCCESS:
      return { ...state, inventory: { inventory: action.payload, error: '', isLoading: false } };
    case SHOPPERS_INVENTORY_GET_FAIL:
      return { ...state, inventory: { inventory: {}, error: action.payload, isLoading: false } };
    case SHOPPERS_INVENTORY_CREATE_REQUEST:
      return { ...state, inventoryCreate: { inventory: {}, error: '', isLoading: true } };
    case SHOPPERS_INVENTORY_CREATE_SUCCESS:
      return { ...state, inventoryCreate: { inventory: state.inventoryCreate.inventory, error: '', isLoading: false } };
    case SHOPPERS_INVENTORY_CREATE_FAIL:
      return { ...state, inventoryCreate: { inventory: {}, error: action.payload, isLoading: false } };
    case SHOPPERS_INVENTORY_UPDATE_REQUEST:
      return { ...state, inventoryUpdate: { inventory: {}, error: '', isLoading: true } };
    case SHOPPERS_INVENTORY_UPDATE_SUCCESS:
      return { ...state, inventoryUpdate: { inventory: state.inventoryUpdate.inventory, error: '', isLoading: false } };
    case SHOPPERS_INVENTORY_UPDATE_FAIL:
      return { ...state, inventoryUpdate: { inventory: {}, error: action.payload, isLoading: false } };
    case SHOPPERS_INVENTORIES_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, inventoryFilter: { search: state.inventoryFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, inventoryFilter: { select: state.inventoryFilter.select, search: action.value } };
      }
      return res;
    case SHOPPERS_INVENTORY_PRODUCTS_REQUEST:
      return { ...state, inventoryProducts: { products: state.inventoryProducts.products, error: '', isLoading: true }, };
    case SHOPPERS_INVENTORY_PRODUCTS_SUCCESS:
      return { ...state, inventoryProducts: { products: action.payload, error: '', isLoading: false } };
    case SHOPPERS_INVENTORY_PRODUCTS_FAIL:
      return { ...state, inventoryProducts: { products: [], error: action.payload, isLoading: false } };

    case SHOPPERS_INVENTORY_PARTNERS_REQUEST:
      return { ...state, inventoryPartners: { partners: state.inventoryPartners.partners, error: '', isLoading: true }, };
    case SHOPPERS_INVENTORY_PARTNERS_SUCCESS:
      return { ...state, inventoryPartners: { partners: action.payload, error: '', isLoading: false } };
    case SHOPPERS_INVENTORY_PARTNERS_FAIL:
      return { ...state, inventoryPartners: { partners: [], error: action.payload, isLoading: false } };

    case SHOPPERS_INVENTORY_DETAILS_REQUEST:
      return { ...state, inventoryDetails: { details: state.inventoryDetails.details, error: '', isLoading: true }, };
    case SHOPPERS_INVENTORY_DETAILS_SUCCESS:
      return { ...state, inventoryDetails: { details: action.payload, error: '', isLoading: false } };
    case SHOPPERS_INVENTORY_DETAILS_FAIL:
      return { ...state, inventoryDetails: { details: [], error: action.payload, isLoading: false } };

    case SHOPPERS_INVENTORY_PRODUCT_MASTER_REQUEST:
      return { ...state, inventoryiMasters: { imasters: state.inventoryiMasters.imasters, error: '', isLoading: true }, };
    case SHOPPERS_INVENTORY_PRODUCT_MASTER_SUCCESS:
      return { ...state, inventoryiMasters: { imasters: action.payload, error: '', isLoading: false } };
    case SHOPPERS_INVENTORY_PRODUCT_MASTER_FAIL:
      return { ...state, inventoryiMasters: { imasters: [], error: action.payload, isLoading: false } };

    case SHOPPERS_PRODUCTSIZE_INVENTORY_REQUEST:
      return { ...state, inventorySizes: { sizes: state.inventorySizes.sizes, error: '', isLoading: true }, };
    case SHOPPERS_PRODUCTSIZE_INVENTORY_SUCCESS:
      return { ...state, inventorySizes: { sizes: action.payload, error: '', isLoading: false } };
    case SHOPPERS_PRODUCTSIZE_INVENTORY_FAIL:
      return { ...state, inventorySizes: { sizes: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}
