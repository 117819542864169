import { AntDesign, EvilIcons, Fontisto, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import { View, Text, Image, Dimensions, ScrollView, Platform, RefreshControl } from 'react-native';
import { ActivityIndicator, Button, Card, DefaultTheme, Surface } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDeliveryAgentAction } from '../../state/actions/deliveryagentAction';
import Header from '../auth/components/header';
import isEmpty from '../../state/validations/is-empty';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Pagination from '../../components/custom/pagination/pagination';


function DeliveryAgentDetails({ navigation }: { navigation: any }) {

    const partnerID = useSelector(state => state.auth?.user);
    let Alldeliveryagent = useSelector((state) => state.deliveryagent?.deliveryagents?.all);
    let deliveryagent = Alldeliveryagent?.results;
    const esiColor = useSelector(state => state.theme);
    const [deliveryagents, setDeliveryagent] = React.useState([]);
    const permissions = useSelector(state => state.auth.permissions);
    //API dispatch
    const dispatch = useDispatch()
    const [deal, setDeals] = React.useState();
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const [tickets, setTicket] = React.useState();
    const [loading, setIsLoading] = React.useState(false);
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "DEL_USER_EMAIL_ID", SortOrder: "DESC" });
    const callBackGetData = async (status, data, pagination) => {
        goToTop();
        setIsLoading(false);
        if (status) {
            setTicket(data);
        }
        setPageOld(true);
        if (pagination) {
            setPagination(pagination);
        } else {
            setPagination({ PageNo: 1, PageSize: 30, SortBy: "DEL_USER_EMAIL_ID", SortOrder: "DESC" });
        }
    }
    React.useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            ticketsCall();
        }
    }, [pagination])
    const ticketsCall = () => {
        const formData = {
            "search": "",
            "Brand_Id": "",
            "CASHBACK_LABEL": "",
            "Category_Id": "",
            "DISCOUNT_LABEL": "",
            "Deal_Id": "",
            "Partner_ID": partnerID?.Partner_Details_Id,
            "Records_Filter": "FILTER",
            "Status": "",
            "Title": "",
            "Is_Active": "1"

        };
        setIsLoading(true);
        dispatch(getAllDeliveryAgentAction(formData,
            pagination,
            callBackGetData
        ));
    };
    const goToTop = () => {
        try {
            scroll.scrollTo({ x: 0, y: 0, animated: true });
        } catch (error) {

        }
    }
    React.useEffect(() => {
        if (!isEmpty(partnerID)) {
            const formData = {
                "search": "",
                "Brand_Id": "",
                "CASHBACK_LABEL": "",
                "Category_Id": "",
                "DISCOUNT_LABEL": "",
                "Deal_Id": "",
                "Partner_ID": partnerID?.Partner_Details_Id,
                "Records_Filter": "FILTER",
                "Status": "",
                "Title": "",
                "Is_Active": "1"
            };
            setDeliveryagent(partnerID);
            dispatch(getAllDeliveryAgentAction(formData, pagination, callBackGetData))
        } else {
            setTimeout(() => {
                if (isEmpty(partnerID)) {
                    navigation.navigate("DeliveryAgent");
                }
            }, 1000)
        }
    }, [partnerID]);
    //dimensions
    const windowWidth = Dimensions.get('window').width;
    const screen = Dimensions.get("screen");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const halfdimentions = dimensions / 1.06;
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };


    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <View style={{ flexDirection: "row", alignSelf: 'flex-end' }}>
                <View style={{ flexWrap: "wrap", flexShrink: 1, marginLeft: 10, marginRight: 14, marginTop: 20, marginBottom: 20 }}>
                    <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => navigation.navigate('AddDeliveryAgent')}>
                        ADD DELIVERY AGENT
                    </Button>
                </View>
            </View>
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                {!isEmpty(deliveryagent) ?
                    !deliveryagent.length ? (
                        <ActivityIndicator color='#27b6cc' />



                    ) :
                        deliveryagent.map((data, index) => (
                            <View key={index} style={{ flex: 1, flexWrap: 'wrap', padding: 5 }}>
                                <Card style={{
                                    borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.BackgroundColor, margin: 10, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                                    shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.5, shadowRadius: 7, elevation: 10, marginLeft: 10, marginRight: 10,
                                    maxWidth: Platform.OS === "ios" ? windowWidth > 300 ? 450 : 200 : 360, minWidth: Platform.OS === "ios" ? 350 : 320
                                }}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <View style={{ justifyContent: 'center', alignItems: 'center', width: 120 }}>

                                            <View style={{
                                                height: 50, backgroundColor: '#27b6cc', borderWidth: 0, width: 50, alignItems: 'center',
                                                justifyContent: 'center', borderRadius: 50, marginTop: Platform.OS === "web" ? 5 : 10
                                            }}>
                                                {data?.Profile_Pic_Path ? <Image style={{ width: 50, height: 50, borderRadius: 50 }} source={{ uri: data?.Profile_Pic_Path }} /> :
                                                    data?.Gender === "Male" ? <Fontisto name="male" size={20} color="white" /> : <Fontisto name="female" size={20} color="white" />}
                                                <View
                                                    style={{
                                                        position: 'absolute',
                                                        backgroundColor: data?.Is_Active === "0" ? 'red' : 'green',
                                                        width: 16,
                                                        height: 16,
                                                        borderRadius: 15 / 2,
                                                        right: 5,
                                                        top: 35,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                    <Text
                                                        style={{
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: "#FFFFFF",
                                                            fontSize: 8,
                                                        }}>
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={{ flex: 1, width: 120, justifyContent: 'center', alignItems: 'center' }}>
                                                <Text style={{ fontSize: 14, marginTop: Platform.OS === "web" ? 10 : 3, color: esiColor.Text, fontWeight: 'bold', textTransform: 'capitalize' }}>
                                                    {!data?.Full_Name ? "Delivery Agent" : data?.Full_Name}
                                                </Text>
                                                <View>
                                                    <Text style={{ marginTop: Platform.OS === "web" ? 10 : 5, fontSize: 10, color: '#27b6cc', fontWeight: 'bold' }} onPress={() => navigation.navigate("EditDeliveryAgent", { id: data.Del_User_Detail_ID })}>
                                                        EDIT
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={{ margin: 5 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={{ flexDirection: 'row', padding: 4 }}>
                                                    <MaterialIcons name="email" size={20} color='#27b6cc' />
                                                    <View style={{ borderWidth: 0, width: '100%', flexDirection: 'row', padding: 2, flex: 0.7 }}>
                                                        <Text style={{ color: 'gray', fontSize: 12 }}>
                                                            {data?.Del_User_Email_ID ? data?.Del_User_Email_ID : "example.gmail.com"}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={{ flexDirection: 'row', padding: 4 }}>
                                                    <EvilIcons name="calendar" size={23} color='#27b6cc' />
                                                    <View style={{ borderWidth: 0, width: '85%', flexDirection: 'row', padding: 2 }}>
                                                        <Text style={{ color: 'gray', fontSize: 12 }}>
                                                            {data?.DOB ? data?.DOB : "DD-MM-YYYY"}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={{ flexDirection: 'row', padding: 4 }}>
                                                    <AntDesign name="mobile1" size={20} color='#27b6cc' />
                                                    <View style={{ borderWidth: 0, width: '85%', flexDirection: 'row', padding: 2 }}>
                                                        <Text style={{ color: 'gray', fontSize: 12 }}>
                                                            {data?.Mobile ? data?.Mobile : "××××××××××"}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={{ flexDirection: 'row', padding: 4 }}>
                                                    <MaterialIcons name="groups" size={20} color='#27b6cc' />
                                                    <View style={{ borderWidth: 0, width: '85%', flexDirection: 'row', padding: 2 }}>
                                                        <Text style={{ color: 'gray', fontSize: 12 }}>
                                                            {data?.Role ? data?.Role : "****"}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </Card>
                            </View>
                        )) :
                    <View style={{ justifyContent: 'center', alignItems: 'center', margin: 10 }}>
                        <Image source={require('../../assets/images/deliveryagentpic.jpg')}
                            style={{ borderRadius: 25, height: 300, width: 300, resizeMode: 'contain' }} />
                        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, width: 300, marginTop: -85 }}>
                            <Text style={{ fontSize: 16, fontWeight: 'bold', color: esiColor.itemColor }}>
                                We do not have your delivery agent details
                            </Text>
                            <Text style={{ textAlign: 'center', marginVertical: 7, flexWrap: 'wrap', color: esiColor.itemColor }}>
                                Add your delivery agent details so that we can store your details easily!
                            </Text>
                        </View>
                    </View>
                }
                <View>
                    <Pagination pagination={pagination} setPagination={setPagination} />
                </View>
            </ScrollView>
        </Surface>
    );
}
export default DeliveryAgentDetails;