import {
  BIDDING_SERVICES_REQUEST,
  BIDDING_SERVICES_SUCCESS,
  BIDDING_SERVICES_FAIL,
  BIDDING_SERVICES_AVAILABLE_UPDATE_REQUEST,
  BIDDING_SERVICES_AVAILABLE_UPDATE_SUCCESS,
  BIDDING_SERVICES_AVAILABLE_UPDATE_FAIL,


  BIDDING_SERVICES_CREATE_REQUEST,
  BIDDING_SERVICES_CREATE_SUCCESS,
  BIDDING_SERVICES_CREATE_FAIL,
  BIDDING_SERVICES_UPDATE_REQUEST,
  BIDDING_SERVICES_UPDATE_SUCCESS,
  BIDDING_SERVICES_UPDATE_FAIL,
  BIDDING_SERVICES_FILTER,


} from '../actions/Services/biddingServicesAction'

const initialState = {
  bidding_srvices: { all: [], error: '', isLoading: false },
  biddingsrviceavailability: { partner: {}, error: '', isLoading: false },
  biddingCreate: { bidding: {}, error: '', isLoading: false },
  biddingUpdate: { bidding: {}, error: '', isLoading: false },
  biddingFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case BIDDING_SERVICES_REQUEST:
      return { ...state, bidding_srvices: { all: state.bidding_srvices.all, error: '', isLoading: true } };
    case BIDDING_SERVICES_SUCCESS:
      return { ...state, bidding_srvices: { all: action.payload, error: '', isLoading: false } };
    case BIDDING_SERVICES_FAIL:
      return { ...state, bidding_srvices: { all: [], error: action.payload, isLoading: false } };
    case BIDDING_SERVICES_AVAILABLE_UPDATE_REQUEST:
      return { ...state, biddingsrviceavailability: { partner: {}, error: '', isLoading: true } };
    case BIDDING_SERVICES_AVAILABLE_UPDATE_SUCCESS:
      return { ...state, biddingsrviceavailability: { partner: state.biddingsrviceavailability.partner, error: '', isLoading: false } };
    case BIDDING_SERVICES_AVAILABLE_UPDATE_FAIL:
      return { ...state, biddingsrviceavailability: { partner: {}, error: action.payload, isLoading: false } };

    case BIDDING_SERVICES_CREATE_REQUEST:
      return { ...state, biddingCreate: { bidding: {}, error: '', isLoading: true } };
    case BIDDING_SERVICES_CREATE_SUCCESS:
      return { ...state, biddingCreate: { bidding: state.biddingCreate.user, error: '', isLoading: false } };
    case BIDDING_SERVICES_CREATE_FAIL:
      return { ...state, biddingCreate: { bidding: {}, error: action.payload, isLoading: false } };
    case BIDDING_SERVICES_UPDATE_REQUEST:
      return { ...state, biddingUpdate: { bidding: {}, error: '', isLoading: true } };
    case BIDDING_SERVICES_UPDATE_SUCCESS:
      return { ...state, biddingUpdate: { bidding: state.biddingUpdate.bidding, error: '', isLoading: false } };
    case BIDDING_SERVICES_UPDATE_FAIL:

    case BIDDING_SERVICES_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, biddingFilter: { search: state.biddingFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, biddingFilter: { select: state.biddingFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}


