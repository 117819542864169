import { CATEGORYRESET ,SET_ALL_CATEGORIES} from "../../actions/shoppers/shoppercategoriesActions";

const initialState = {
  allCategories: [],
};

export default function categoriesReducer(state = initialState, action:any) {
  switch (action.type) {
    case SET_ALL_CATEGORIES:
      return {
        ...state,
        allCategories: action.payload,
      };

    case CATEGORYRESET:
      return {
        ...state, allCategories: []
      };

    default:
      return state;
  }
}
