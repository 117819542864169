
import React, { Component, useState, useEffect } from "react";
import { getAllProductAction, resetFilter } from "../../../state/actions/farmer/farmerproductsAction";
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Card, DataTable, Button, Searchbar, Surface, IconButton, Portal, Dialog, DefaultTheme } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../state/validations/is-empty'
import 'react-native-gesture-handler'
import RenderHtml from 'react-native-render-html';
import Header from "../../auth/components/header";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from 'react-native-vector-icons/FontAwesome';

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Product_Id',
    label: 'Product ID'
  },
  {
    value: 'Product_Name',
    label: 'Product Name'
  },
  {
    value: 'Price',
    label: 'Price'
  },
  {
    value: 'Status',
    label: 'Status'
  },
];
const numberOfItemsPerPageList = [5, 10, 20];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const FarmerProductGet = ({ navigation }: { navigation: any }) => {
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const [productss, setProducts] = useState([]);
  const [page, setPage] = React.useState(0);
  const esiColor = useSelector(state => state.theme);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const Products = useSelector(state => state.farmerproduct.products.all);
  let allProducts = Products?.results;
  const [productMenu, setProductMenu] = React.useState('All')
  const [productSearch, setProductSearch] = React.useState('');
  const EmailID = useSelector(state => state.auth.user);
  const [products, setData] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const hideDialog = () => setVisible(false);
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > allProducts?.Pagination?.TotalCount ? allProducts?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const [product, setProduct] = React.useState();
  const dispatch = useDispatch()
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setPagination({ PageNo: Number(1), PageSize: e, SortBy: "PRODUCT_ID", SortOrder: "DESC" })
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "PRODUCT_ID", SortOrder: "DESC" });
  }
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PRODUCT_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      productsCall();
    }
  }, [pagination])

  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const productsCall = () => {
    setIsLoading(true);
    let formData = {
      search_by_filter: productMenu,
      search: '',
      Records_Filter: "FILTER",
      Partner_Details_Id: EmailID.Partner_Details_Id
    };
    dispatch(getAllProductAction(formData, pagination))
  };
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    let formData = {
      search_by_filter: productMenu,
      search: '',
      Records_Filter: "FILTER",
      Partner_Details_Id: EmailID.Partner_Details_Id
    };
    setProduct(allProducts);

    dispatch(getAllProductAction(formData, pagination))
  }, []);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const handleClickpartner = async (allProducts) => {
    navigation.navigate('ProductPartnerGet', { productpartnerparams: allProducts.Product_Id, productmaster: allProducts.Product_Master_Id })
  };
  const handleClickrelatedproducts = async (allProducts) => {
    navigation.navigate('RelatedProducts', { relatedproductparams: allProducts })
  };
  const handleClickadd = async (productmaster) => {
    navigation.navigate('AddFarmerProducts', { master: productmaster })
  };
  const handleClickedit = async (product) => {
    navigation.navigate('EditFarmerProduct', { product_id: product.Product_Id, CID: product.Category_Id })
  };
  const [oldSearch, setOldSearch] = React.useState(0);

  useEffect(() => {
    if (!isEmpty(allProducts)) {
      setProducts(allProducts);
    } else {
      setProducts([]);
    }
  }, [allProducts]);
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "PRODUCT_ID", SortOrder: "DESC" }
    let formData = {
      search: "",
      search_by_filter: productMenu,
      Records_Filter: "FILTER",
      Partner_Details_Id: EmailID.Partner_Details_Id
    };
    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getAllProductAction(formData, pagecall))
  };
  // Sorting 
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Product_Id');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleChange = itemValue => {
    setProductMenu(itemValue);
  };
  const handleSearch = value => {
    setProductSearch(value)
    let formData = {
      search: value,
      search_by_filter: productMenu,
      Records_Filter: "FILTER",
      Partner_Details_Id: EmailID.Partner_Details_Id
    };
    dispatch(getAllProductAction(formData, pagination))
  };
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const [webpage, setwebPage] = React.useState(1);
  const DynamicPageIncrease = (e) => {
    setwebPage(e < Products?.Pagination?.TotalPages ? e + Number(1) : Products?.Pagination?.TotalPages)
    setPagination({ PageNo: e == Products?.Pagination?.TotalPages ? Products?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "Product_Id", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "Product_Id", SortOrder: "DESC" });
  }

  const styles = StyleSheet.create({
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'left',
      borderBottomColor: esiColor.SBorderColor,
    },
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 160
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 240
    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
  },
  rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
  },
  // rowsPerPagePickers: {
  //     margin: 10,
  //     width: 70,
  //     height: 40,
  //     borderRadius: 10,
  //     borderColor: 'gray',
  //     borderWidth: 0.5,
  //     paddingHorizontal: 8,
  // },
  paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: esiColor.BackgroundColor
  },
  paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      backgroundColor: esiColor.BackgroundColor
  },
  pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
  },
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
            <IconButton
              icon="close"
              color={esiColor.GBFColor}
              size={20}
              onPress={() => setVisible(!visible)}
            />
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center", borderBottomColor: esiColor.SBorderColor, borderBottomWidth: 1 }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>Product View</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView
              contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Dialog.Content style={{ marginLeft: -35 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}>
                    <Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{products?.Product_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Name</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{products?.Product_Name} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Brand ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{products?.Brand_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Category</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{products?.Category_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Sub Category</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{products?.Sub_Category_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Master ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor}}>{products?.Product_Master_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Color</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{products?.Product_Color} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Size ID</Text></View><View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{products?.Product_Size_Id} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Short Description</Text></View><View style={{ flex: 2 }}><RenderHtml
                    source={{ html: `<span style="color: ${esiColor.Text}">${products?.Product_Short_Description}</span>` }}
                  /></View>
                </View>

                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Long Description</Text></View><View style={{ flex: 2, marginTop: -15 }}><RenderHtml
                    source={{ html: `<span style="color: ${esiColor.Text}">${products?.Product_Description}</span>` }}
                  /></View>
                </View>

                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Specifications</Text></View><View style={{ flex: 2, marginTop: -15 }}><RenderHtml
                    source={{ html: `<span style="color: ${esiColor.Text}">${products?.Specifications}</span>` }}
                  /></View>
                </View>

                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Features</Text></View><View style={{ flex: 2, marginTop: -15 }}><RenderHtml
                    source={{ html: `<span style="color: ${esiColor.Text}">${products?.Features}</span>` }}
                  /></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15,
                  // borderBottomWidth: 0.01,
                  // borderTopWidth: 0.01,
                  // borderColor: '#D6D6D7'
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>ESI Reviews</Text></View><View style={{ flex: 2, marginTop: -15 }}><RenderHtml
                    source={{ html: `<span style="color: ${esiColor.Text}">${products?.Esi_Reviews}</span>` }}
                  /></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 2 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View><View style={{ flex: 2 }}><Text style={{ color: products?.Status === "Active" ? '#27B6CC' : "red" }}>{products?.Status} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View>
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
          navigation.navigate('FarmerPartnerProductMain')
          handleSearch("")
          dispatch(resetFilter())
        }
        } >
          Go Back
        </Button>
      </View>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <View style={{
          flexDirection: "column"
        }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Products</Text>
        </View>

        {(permissions.Products_Partner_Products_Related_Products === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained"textColor={esiColor.itemButtenColor} onPress={(e) => { handleClickadd(); }}>
              Add Product
            </Button>
          </View>}



        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[styles.dropdown]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={filterOptions}
                containerStyle={styles.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={productMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={productMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Product ID" value="Product_Id" />
                <Picker.Item label="Product Name" value="Product_Name" />
                <Picker.Item label="Price" value="Price" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}
              
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              onChangeText={(value) => handleSearch(value)}
              value={productSearch}
            />
          </View>

        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={styles.mainbox}>
            <Card >
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={styles.databeHeader}>
                  <DataTable.Title  active={orderBy === 'Product_Id'} sortDirection={orderBy === 'Product_Id' ? order : 'ascending'} onPress={createSortHandler('Product_Id')} style={styles.title}><Text style={styles.titletext}>Product ID</Text></DataTable.Title>
                  <DataTable.Title  active={orderBy === 'Product_Name'} sortDirection={orderBy === 'Product_Name' ? order : 'ascending'} onPress={createSortHandler('Product_Name')} style={styles.title}><Text style={styles.titletext}>Product Name</Text></DataTable.Title>
                  <DataTable.Title  style={{ width: 180, alignItems: 'center', justifyContent: 'center' }} active={orderBy === 'Price'} sortDirection={orderBy === 'Price' ? order : 'ascending'} onPress={createSortHandler('Price')} style={styles.title}><Text style={styles.titletext}>Price</Text></DataTable.Title>
                  <DataTable.Title  active={orderBy === 'Status'} sortDirection={orderBy === 'Status' ? order : 'ascending'} onPress={createSortHandler('Status')} style={styles.title}><Text style={styles.titletext}>Status</Text></DataTable.Title>
                  {/* <DataTable.Title style={styles.title}><Text style={styles.titletext}>Product Partners</Text></DataTable.Title> */}
                  {/* <DataTable.Title style={styles.title}><Text style={styles.titletext}>Related Products</Text></DataTable.Title> */}
                  <DataTable.Title  style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {allProducts?.map((products, i) => (
                  <DataTable.Row style={styles.databeBox} key={i}>
                    <DataTable.Cell style={styles.tablerow} ><Text style={{ color: esiColor.itemColor }}>{products.Product_Id}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{products.Product_Name}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{products.Price}</Text></DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}><Text style={{ color: esiColor.itemColor }}>{products.Status}</Text></DataTable.Cell>
                    {/* <DataTable.Cell style={styles.tablerow}><Button icon="account-group" color="#27B6CC" onPress={(e) => { handleClickpartner(products); }}></Button></DataTable.Cell> */}
                    {/* <DataTable.Cell style={styles.tablerow}> <Button icon="format-line-weight" color="#27B6CC" style={{ transform: [{ rotate: '180deg' }] }} onPress={(e) => { handleClickrelatedproducts(products.Product_Id); }}></Button></DataTable.Cell> */}
                    <DataTable.Cell style={styles.tablerow}>
                      {(permissions.Products_Partner_Products_Related_Products === "write") &&
                        <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.globalButtonColor} onPress={(e) => { handleClickedit(products); }} />
                      }<IconButton icon="eye" size={20} iconColor={esiColor.globalButtonColor} onPress={(e) => { setData(products); setVisible(!visible) }} />
                    </DataTable.Cell>
                  </DataTable.Row>
                ))
                }
                {isEmpty(allProducts) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                {Platform.OS !== "web" ?
                <DataTable.Pagination
                  
                  page={page}
                  numberOfPages={Math.ceil(Products?.Pagination?.TotalCount / numberOfItemsPerPage)}
                  onPageChange={page => DynamicPage(to < Products?.Pagination?.TotalCount ? page : 0)}
                  label={Products?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Products?.Pagination?.TotalCount}` : '0-0'}
                  showFastPaginationControls={(Number(page) + Number(1)) > Products?.Pagination?.TotalPages ? false : true}
                  numberOfItemsPerPageList={numberOfItemsPerPageList}
                  numberOfItemsPerPage={numberOfItemsPerPage}
                  onItemsPerPageChange={onItemsPerPageChange}
                  selectPageDropdownLabel={'Rows per page'}
                /> :
                 <View style={styles.rowsPerPageContainer}>
                  <Text style={{ color: esiColor.Text }}>rows per page: </Text>
                  <Picker
                    style={styles.rowsPerPagePicker}
                    selectedValue={numberOfItemsPerPage}
                    onValueChange={(e) => onItemsPerPageChange(e)}
                  >
                    <Picker.Item label="5" value={5} />
                    <Picker.Item label="10" value={10} />
                    <Picker.Item label="20" value={20} />
                  </Picker>
                  <View style={styles.paginationContainer}>
                    <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={styles.paginationButton}>
                      <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                    </TouchableOpacity>
                    <Text style={styles.pageInfoText}>
                      {Products?.Pagination?.PageNo + ' of ' + Products?.Pagination?.TotalPages}
                    </Text>
                    <TouchableOpacity onPress={e => DynamicPageIncrease(to < Products?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={styles.paginationButton}>
                      <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                    </TouchableOpacity>
                  </View>
                </View>}
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}

export default FarmerProductGet;
