import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const GIFTIMAGE_REQUEST = "GIFTIMAGE_REQUEST";
export const GIFTIMAGE_SUCCESS = "GIFTIMAGE_SUCCESS";
export const GIFTIMAGE_FAIL = "GIFTIMAGE_FAIL";
export const GIFTIMAGE_GET_REQUEST = "GIFTIMAGE_GET_REQUEST";
export const GIFTIMAGE_GET_SUCCESS = "GIFTIMAGE_GET_SUCCESS";
export const GIFTIMAGE_GET_FAIL = "GIFTIMAGE_GET_FAIL";
export const GIFTIMAGE_CREATE_REQUEST = "GIFTIMAGE_CREATE_REQUEST";
export const GIFTIMAGE_CREATE_SUCCESS = "GIFTIMAGE_CREATE_SUCCESS";
export const GIFTIMAGE_CREATE_FAIL = "GIFTIMAGE_CREATE_FAIL";
export const GIFTIMAGE_UPDATE_REQUEST = "GIFTIMAGE_UPDATE_REQUEST";
export const GIFTIMAGE_UPDATE_SUCCESS = "GIFTIMAGE_UPDATE_SUCCESS";
export const GIFTIMAGE_UPDATE_FAIL = "GIFTIMAGE_UPDATE_FAIL";
export const GIFTIMAGE_DELETE_REQUEST = "GIFTIMAGE_DELETE_REQUEST";
export const GIFTIMAGE_DELETE_SUCCESS = "GIFTIMAGE_DELETE_SUCCESS";
export const GIFTIMAGE_DELETE_FAIL = "GIFTIMAGE_DELETE_FAIL";
export const GIFTIMAGES_FILTER = "GIFTIMAGES_FILTER";

export const GIFT_VENDORID_REQUEST = "GIFT_VENDORID_REQUEST";
export const GIFT_VENDORID_SUCCESS = "GIFT_VENDORID_SUCCESS";
export const GIFT_VENDORID_FAIL = "GIFT_VENDORID_FAIL";
const headers = config.headersCommon;
/*=============================================================
                Get All Gift images Action
===============================================================*/
const nodeheaders = shopperconfig.nodeheadersCommon;
export const getAllGiftImagesAction = (formData: any, toast: any) => async dispatch => {
  try {
    dispatch({
      type: GIFTIMAGE_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Gift/Image_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: GIFTIMAGE_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFTIMAGE_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFTIMAGE_FAIL,
      payload: err
    });
  }
};

// Filter 
export const giftimagesFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: GIFTIMAGES_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//search

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: GIFTIMAGES_FILTER,
      fType: "search",
      value: "",
    });
  }
}

/*=============================================================
                  Add gift image Action
===============================================================*/

export const createGiftImage = (formData: any, imgUrl: any, navigation: any, setErrorMessage: any, setErrors: any, toast: any,vendorname) => async dispatch => {
  dispatch({
    type: GIFTIMAGE_CREATE_REQUEST
  });
  await axios.post(
    `${config.url}/Gift/Image_Create?filename=BTS&file_extension=jpg&filetype=Image&created_by=${formData.Created_By}&occassion=${formData.Occassion}&status=${formData.Status}&type=${formData.Type}&vendor_id=${formData.Vendor_Id}`,
    imgUrl,
    {
      headers: headers,
      transformRequest: (d) => d,
    }
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: GIFTIMAGE_CREATE_SUCCESS
        });
        const getformData = {
          "Gift_Vendor_Id": formData.Vendor_Id,
          "Records_Filter": "FILTER"
        };
        dispatch(getAllGiftImagesAction(getformData));
        toast.show({
          message: 'gift image Created successfully.', type: 'info', duration: 3000, position: 'top'
        });
        navigation.navigate('GiftImage',{ giftsdetails: formData.Vendor_Id, vendorname: vendorname });

      } else {
        dispatch({
          type: GIFTIMAGE_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage("Currently server is not working. Please try again later.");
      dispatch({
        type: GIFTIMAGE_CREATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })

}


/*=============================================================
                Update gift image Action
===============================================================*/

export const updateGiftImageAction = (formData: any, navigation: any, toast: any) => async dispatch => {
  dispatch({
    type: GIFTIMAGE_UPDATE_REQUEST
  });
  await axios.put(
    `${config.url}/Gift/Update`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: GIFTIMAGE_UPDATE_SUCCESS
        });
        const getformData = {
          "search_by_filter": "",
          "search": "",
          "Gift_Vendor_Id":  formData?.Vendor_Id,
          "Occassion": "",
          "Records_Filter": "FILTER"
        };
        dispatch(getAllGiftImagesAction(getformData, toast));
        toast.show({
          message: 'gift image Updated successfully.', type: 'info', duration: 3000, position: 'top'
        });
        navigation.navigate('GiftImage');

      } else {
        dispatch({
          type: GIFTIMAGE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GIFTIMAGE_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
}
export const updateImageAction = (formData: any, imagesGet: any, callBackUploadImage: any) => async dispatch => {
  try {
    let { data } = await axios.post(
      `${config.url}/Gift/Image_Update?filename=book&file_extension=png&filetype=image modified_by=${imagesGet.Modified_By}&occassion=${imagesGet.Occassion}&status=${imagesGet.Status}&type=${imagesGet.Type}&vendor_id=${imagesGet.Vendor_Id}&gift_image_id=${imagesGet.Gift_Image_Id}`,
      formData,
      {
        headers: headers,
        transformRequest: (d) => d,
      }
    );
    if (data) {
      callBackUploadImage(data);
    }
  } catch (err) {
  }
};


export const singleimageAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Gift/Image_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data?.results[0])

    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};