import React, { useState } from "react";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import EditorContainer from '../../RichTextEditor';
import { StyleSheet, Text, View } from 'react-native';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";

const EsiEditor = (props: any) => {

    //props consists of all react text field properties along with onUpdateValue function.

    // onUpdateValue function will return onchange value of field 

    // seperating label,value,onUpdateValue from the props.
    const { label, value, onUpdateValue, ...rest } = props;
    // coverting html data to draft data using htmt to draft converter
    //ex: <h1>Hi</h1>  after converting it would display like hi in h1 style format.

    const contentStateReviews = ContentState.createFromBlockArray(htmlToDraft(value).contentBlocks);

    // Using Creatcontent in EditorState it will display content in Description box.
    const [editorState, setEditorState,] = useState(EditorState.createWithContent(contentStateReviews));

    //Using onEditorStateChange function have onChange Value and we are assigning that value after converting Draft to Html 
    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
        onUpdateValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };
    const esiColor = useSelector(state => state.theme);
    const customStyleMap = {
      
            backgroundColor: 'yellow',
      
    }

    // Example output format we are getting is Plain Html:
    // ex: <h1>Hi <p>This is the data</p><h1>

    return (
        <View {...rest}>
            <View style={props.webStyle ? props.webStyle : styles.description}>
                <Text style={{ color: esiColor.Text }}>{label}</Text>
                <div style={{ overflow: "auto", }}>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={(value: any) => onEditorStateChange(value)}
                        toolbar={{
                            styles: { color: esiColor.Text, background: "#f0f0f0" }, // Set the background color here
                            // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'remove', 'history'],
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                        }}
                        // editorStyle={{
                        //     border: '1px solid #ddd',
                        //     minHeight: '200px',
                        //     padding: '10px',
                            
                        //   }}
                    />
                </div>
            </View>
        </View>
    );
}

//Default border styles which are required.

const styles = StyleSheet.create({
    description: {
        width: '100%',
        height: '20rem',
        padding: '1rem',
        overflow: 'scroll',
        border: '1px solid grey',
        marginTop: '12px'
    }
});
export default EsiEditor;