import 'react-native-gesture-handler';
import * as React from 'react';
import Header from '../auth/components/header';
import { Text, View, StyleSheet, Picker, ScrollView, Platform, Dimensions, TouchableOpacity, Image, RefreshControl } from 'react-native';
import { Searchbar, Surface, Button, Modal } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getalltickets, resetFilter } from "../../state/actions/ticketingActions";
import isEmpty from '../../state/validations/is-empty'
import EsiURLButton from '../../components/custom/linkingfunction/linkingFunction';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import ESICommentModel from '../../components/custom/comment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Pagination from '../../components/custom/pagination/pagination';
import Headers from '../Farmer-Module/header';
const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Ticket_Id',
    label: 'Ticket ID'
  },
  {
    value: 'Ticket_Status',
    label: 'Ticket Status'
  },
  {
    value: 'Ticket_User_Type',
    label: 'Type'
  },
];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function Ticketing(props: any) {
  const page = props.route?.params?.page;
  let AllTicket = useSelector((state) => state.Ticket.Tickets.all);
  let Ticket = AllTicket?.results;
  const [ticketMenu, setTicketMenu] = React.useState('All')
  const [ticketSearch, setTicketSearch] = React.useState('');
  const isLoading = useSelector(state => state.Ticket.Tickets.isLoading);
  const EmailID = useSelector(state => state.auth.user.Email_Id);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [cashbacks, setCashbacks] = React.useState([]);
  const [ticket, setTickets] = React.useState([]);
  const { navigation } = props;
  const dispatch = useDispatch()
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [visible, setVisible] = React.useState(false);
  const [commentdata, setData] = React.useState();
  const [tickets, setTicket] = React.useState();
  const [search, setSearch] = React.useState('');
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const esiColor = useSelector(state => state.theme);
  const containerStyle = { backgroundColor: esiColor.BackgroundColor, padding: 20, flex: 1, width: 350, maxWidth: 500, minWidth: 300, minHeight: 350, maxHeight: 400, borderRadius: 10, alignSelf: "center" };
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    ticketsCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "TICKET_ID", SortOrder: "DESC" });
  const callBackGetData = async (status, data, pagination) => {
    goToTop();
    setIsLoading(false);
    if (status) {
      setTicket(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 30, SortBy: "TICKET_ID", SortOrder: "DESC" });
    }
  }

  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      ticketsCall();
    }
  }, [pagination])
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const ticketsCall = () => {
    const formData = {
      "search_by_filter": ticketMenu,
      "search": search,
      "Created_By": "",
      "Records_Filter": "FILTER",
      "Ticket_Id": "",
      "Ticket_Status": "",
      "Ticket_Subject": "",
      "Ticket_Owner": EmailID
    };
    setIsLoading(true);
    dispatch(getalltickets(formData,
      pagination,
      callBackGetData
    ));
  };

  //Returns a function to scroll the page up or down by any amount, in pixels. Supports scrolling.
  const goToTop = () => {
    try {
      scroll.scrollTo({ x: 0, y: 0, animated: true });
    } catch (error) {

    }
  }
  React.useEffect(() => {
    if (!isEmpty(EmailID)) {
      const formData = {
        "search_by_filter": '',
        "search": '',
        "Created_By": '',
        "Records_Filter": "FILTER",
        "Ticket_Id": "",
        "Ticket_Status": "",
        "Ticket_Subject": "",
        "Ticket_Owner": EmailID
      };
      setCashbacks(Ticket);
      setTickets(EmailID);
      dispatch(getalltickets(formData, pagination, callBackGetData))
    } else {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Ticketing");
        }
      }, 1000)
    }
  }, [EmailID]);

  React.useEffect(() => {
    if (!isEmpty(Ticket)) {
      setCashbacks(Ticket);
    } else {
      setCashbacks([]);
    }
  }, [Ticket]);

  const handleChange = itemValue => {
    setTicketMenu(itemValue);
  };
  const handleSearch = value => {
    setPagination({ PageNo: 1, PageSize: 30, SortBy: "TICKET_ID", SortOrder: "DESC" });
    setSearch(value)
    let formData = {
      "search_by_filter": ticketMenu,
      "search": value,
      "Created_By": "",
      "Records_Filter": "FILTER",
      "Ticket_Id": "",
      "Ticket_Status": "",
      "Ticket_Subject": "",
      "Ticket_Owner": EmailID
    };
    setTicketSearch(value)

    dispatch(getalltickets(formData, pagination, callBackGetData))
  };


  const styles = StyleSheet.create({
    button: {
      alignItems: "center",
      padding: 5,
      backgroundColor: "#27B6CC",
    },
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 100
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: 'black',
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    mainbox: {
      margin: 15,
      flex: 1,
      width: "90%",
      justifyContent: 'space-between',
      alignSelf: 'center',
    }, placeholderStyle: {
      fontSize: 16,
      color: esiColor.BackgroundColor

    },
    selectedTextStyle: {
      fontSize: 15,
      color: esiColor.SIconColor

    }, containerstyle: {
      width: 240,
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    dropdown: {
      height: 50,
      // borderColor: '#27b6cc',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderColor: esiColor.SBorderColor,
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    databeHeader: {
      margin: 10,
      textAlign: 'left',
    },

  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      {page === 'farmer' ? <Headers navigation={props.navigation} /> : <Header navigation={props.navigation} />}
      <View style={{ alignItems: 'flex-start', margin: 10 }}>
        {/* {ID ? <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ justifyContent: 'flex-start' }} onPress={() => {
          navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } });
          handleSearch("")
          dispatch(resetFilter())
        }}>

          <Text style={{ fontWeight: "bold", color: esiColor.brandFontColor }}>Support Tickets</Text>

        </Button> : <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ justifyContent: 'flex-start' }} onPress={() => {
          navigation.navigate("B2C", { screen: 'Dashboard' });
          handleSearch("")
          dispatch(resetFilter())
        }}>

          <Text style={{ fontWeight: "bold" }}>Support Tickets</Text>

        </Button>} */}
        <View style={{ alignSelf: 'flex-end', flexWrap: "wrap", flexShrink: 1, marginRight: 10 }} >
          <Button style={{
            backgroundColor: esiColor.globalButtonColor,
          }} mode="contained" onPress={() => navigation.navigate('Addtickets')}>
            <Text style={{ fontSize: 14, color: esiColor.itemButtenColor, fontWeight: "bold" }}> + CREATE SUPPORT TICKET</Text>
          </Button>
        </View>
      </View>
      <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
        {Platform.OS !== 'web' ?
          <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
            <EsiSearchDropdown
              style={[styles.dropdown]}
              placeholderStyle={styles.placeholderStyle}
              selectedTextStyle={styles.selectedTextStyle}
              inputSearchStyle={styles.inputSearchStyle}
              iconStyle={styles.iconStyle}
              data={filterOptions}
              containerStyle={styles.containerstyle}
              maxHeight={180}
              labelField="label"
              valueField="value"
              placeholder={!showDropDown ? 'All' : ''}
              value={ticketMenu}
              onFocus={() => setShowDropDown(true)}
              onBlur={() => setShowDropDown(false)}
              onUpdateValue={item => {
                setShowDropDown(false);
                handleChange(item.value)
              }}
            />

          </View>
          :
          <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
            <Picker
              selectedValue={ticketMenu}
              style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
              onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
            >
              <Picker.Item label="All" value="" />
              <Picker.Item label="Ticket ID" value="Ticket_Id" />
              <Picker.Item label="Ticket Status" value="Ticket_Status" />
              <Picker.Item label="Type" value="Ticket_User_Type" />
            </Picker>
          </View>}
        <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />
        <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
          <Searchbar
            style={{ borderRadius: 5, borderWidth: 0.5, borderColor: esiColor.SBorderColor, height: 50, backgroundColor: esiColor.BackgroundColor }}
            placeholder="Search"
            placeholderTextColor={esiColor.SPHTextColor}
            variant="outlined"
            onChangeText={(value) => handleSearch(value)}
            value={ticketSearch}
            iconColor={esiColor.SIconColor}
            theme={{ colors: { text: esiColor.SBTextColor } }}
          />
        </View>
      </View>
      <ScrollView style={{ marginTop: 20 }} refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <View style={styles.mainbox}>
          <View style={{ marginTop: 8 }} >
            {!isEmpty(Ticket) ?
              <View>
                {Ticket.Is_Data_Exist ? (
                  <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Image source={require('../../assets/images/not-found-animate.gif')}
                      style={{ width: 300, height: 300 }} />
                    <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.NoDataFound }}>No Records Found!</Text>
                  </View>
                ) :
                  Ticket?.map((tickets, index) => (
                    <TouchableOpacity style={{
                      marginHorizontal: 2, borderRadius: 10, padding: 2, margin: 'auto', marginTop: 10,
                      backgroundColor: esiColor.CBColor,
                      // borderColor: "#CACFD2",
                      shadowColor: esiColor.brandShadowColor,
                      // shadowOffset: { width: -2, height: 4 },
                      shadowOpacity: 7,
                      shadowRadius: 5,
                      elevation: 5,
                      minHeight: 100,
                      flex: 1,
                      width: "100%", justifyContent: 'space-between',
                      marginBottom: 10
                    }} key={index} 
                    disabled={tickets?.Ticket_Status=='In-Active'?true : false} 
                    onPress={() => { setVisible(true), setData(tickets) }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, margin: '1%', padding: 10 }}>
                        <View >
                          <Text style={{ fontSize: 15, color: esiColor.itemColor }}>Ticket ID :{tickets?.Ticket_Id}</Text>
                          <Text style={{ fontSize: 15, marginTop: 4, marginBottom: 4, marginTop: 10, color: esiColor.itemColor }}>Type :{" "}{tickets?.Type}</Text>
                          <Text style={{ fontSize: 15, color: esiColor.itemColor }}>Ticket Status:  <Text style={{ color: tickets?.Ticket_Status === "New" ? "red" : tickets?.Ticket_Status === "Inprogress" ? 'green' : tickets?.Ticket_Status === "Hold" ? 'blue' : tickets?.Ticket_Status === "Closed" ? 'orange' : esiColor.itemColor }}>{tickets?.Ticket_Status}</Text>
                          </Text>
                          <Text style={{ fontSize: 15, marginTop: 5, color: esiColor.itemColor }}> Attachment:  <EsiURLButton url={tickets?.Ticket_Attachment} /> </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  ))}
              </View>

              :
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Image source={require('../../assets/images/not-found-animate.gif')}
                  style={{ width: 300, height: 300 }} />
                <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.NoDataFound }}>No Records Found!</Text>
              </View>
            }

          </View>
        </View>
        <View>
          <Pagination pagination={pagination} setPagination={setPagination} />
        </View>
      </ScrollView>
      <Modal
        visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
        <ESICommentModel navigation={navigation} openModal={visible} data={commentdata} closeModal={hideModal} />
      </Modal>
    </Surface>
  );
}


export default Ticketing