import * as React from 'react';
import { Text, View, Dimensions, StyleSheet, ScrollView, Platform, Picker, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Button, Card, DataTable, DefaultTheme, IconButton, Searchbar, Surface } from 'react-native-paper';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { useDispatch, useSelector } from 'react-redux';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import isEmpty from '../../state/validations/is-empty';
import Header from '../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAllb2bOrdersAction } from '../../state/actions/b2bordersAction';
import { resetFilter } from '../../state/actions/ordermanagementAction';
import Icon from 'react-native-vector-icons/FontAwesome';

//dropdown
const filterOptions = [
    {
        value: 'ALL',
        label: 'All'
    },
    {
        value: 'Shopper_Order_Id',
        label: 'Shopper Order ID'
    },
    {
        value: 'Shopper_Email_Id',
        label: 'Shopper Email ID'
    },
    {
        value: 'Net_Price',
        label: 'Price'
    },
    {
        value: 'Line_Item_Status',
        label: 'Status'
    },
];
const numberOfItemsPerPageList = [5, 10, 20];
function B2bOrderDetails(props: any) {
    const { navigation } = props;
    const toast = useToast();
    // Role restriction
    const [orders, setOrders] = React.useState();
    const esiColor = useSelector(state => state.theme);

    const permissions = useSelector(state => state.auth.permissions);
    const [page, setPage] = React.useState(0);
    const [searchnew, setSearchnew] = React.useState("");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const EmailID = useSelector(state => state.auth?.user);
    const allOrders = useSelector(state => state.b2borders.b2borders.all);
    const isLoading = useSelector(state => state.b2borders.b2borders.isLoading);
    let Orders = allOrders?.results;
    const [orderMenu, setOrderMenu] = React.useState('All')
    const [orderSearch, setOrderSearch] = React.useState('');
    const [searchold, setSearchold] = React.useState("");

    //iterating values 
    let allOrderDetails = []
    if (Orders) {
        for (let i = 0; i < Orders?.length; i++) {
            let data = Orders[i].Order_Data?.Item_Data?.Item_Master_Data
            for (let j = 0; j < data?.length; j++) {
                allOrderDetails.push({
                    Shopper_Order_Id: Orders[i]?.Order_Data?.Shopper_Order_Id,
                    Shopper_Email_Id: Orders[i]?.Order_Data.Shopper_Email_Id,
                    Item_Master_Id: data[j].Item_Master_Id,
                    Line_Item_Status: data[j].Line_Item_Status,
                    Max_Days_To_Return: data[j].Max_Days_To_Return,
                    Selling_Price: data[j].Selling_Price,
                    Order_Date: data[j].Order_Date,
                    Partner_Details_Id: data[j].Partner_Details_Id,
                    Payment_Status: data[j].Payment_Status,
                    MRP: data[j].MRP,
                    Order_Status: Orders[i]?.Order_Data?.Order_Status,
                    Product_Id: data[j].Product_Id,
                    Product_Image: data[j].Product_Image,
                    Product_Name: data[j].Product_Name,
                    Quantity: data[j].Quantity,
                    Is_Approved: Orders[i]?.Order_Data.Is_Approved,
                }
                )
            }
        }
    }
    const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const [webpage, setwebPage] = React.useState(1);
    const from = page * numberOfItemsPerPage;
    const to = (Number(from) + Number(numberOfItemsPerPage) > allOrders?.Pagination?.TotalCount ? allOrders?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
    const onItemsPerPageChange = (e) => {
        numberItemsPerPageChange(e)
        setPage(0)
        setwebPage(1)
        setPagination({ PageNo: Number(1), PageSize: e, SortBy: "SHOPPER_ORDER_ID", SortOrder: "DESC" })
    }
    const DynamicPageIncrease = (e) => {
        setwebPage(e < allOrders?.Pagination?.TotalPages ? e + Number(1) : allOrders?.Pagination?.TotalPages)
        setPagination({ PageNo: e == allOrders?.Pagination?.TotalPages ? allOrders?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "SHOPPER_ORDER_ID", SortOrder: "DESC" });
    }
    const DynamicPagedecrese = (e) => {
        setwebPage(e > 1 ? e - Number(1) : 1)
        setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "SHOPPER_ORDER_ID", SortOrder: "DESC" });
    }
    const DynamicPage = (e) => {
        setPage(e)
        setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "SHOPPER_ORDER_ID", SortOrder: "DESC" });
    }
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "SHOPPER_ORDER_ID", SortOrder: "DESC" });
    React.useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            orderCall();
        }
    }, [pagination])

    // search and set search is used for the search functionality
    const [loading, setIsLoading] = React.useState(false);
    const orderCall = () => {
        setIsLoading(true);
        let formData = {
            "search_by_filter": orderMenu,
            "search": searchold,
            "Seller_Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Records_Filter": "FILTER",
            "Shopper_Order_Id": ""
        };
        dispatch(getAllb2bOrdersAction(formData, pagination))
    };
    const [showDropDown, setShowDropDown] = React.useState(false);
    const dispatch = useDispatch()
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    const refreshCall = () => {
        setIsLoading(true);
        let pagecall = { PageNo: 1, PageSize: 5, SortBy: "SHOPPER_ORDER_ID", SortOrder: "DESC" }
        let formData = {
            "search_by_filter": orderMenu,
            "search": "",
            "Seller_Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Records_Filter": "FILTER",
            "Shopper_Order_Id": ""
        };
        setPage(0)
        numberItemsPerPageChange(5)
        dispatch(getAllb2bOrdersAction(formData, pagecall))
    };
    React.useEffect(() => {
        let formData = {
            "search_by_filter": orderMenu,
            "search": "",
            "Seller_Partner_Detail_Id": EmailID?.Partner_Details_Id,
            "Records_Filter": "FILTER",
            "Shopper_Order_Id": ""
        };
        setOrders(Orders);

        dispatch(getAllb2bOrdersAction(formData, pagination))
    }, []);
    React.useEffect(() => {
        if (!isEmpty(Orders)) {
            setOrders(Orders);
        } else {
            setOrders([]);
        }
    }, [Orders]);
    React.useEffect(() => {

        handleSearch(searchnew);

    }, [isLoading]);
    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Shopper_Order_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
        setOrderBy(property);
        setPage(0);
    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleChange = itemValue => {
        setOrderMenu(itemValue);
    };
    const handleSearch = value => {
        setOrderSearch(value)
        setSearchnew(value);
        if (!isLoading && (searchold != value)) {
            let formData = {
                "search_by_filter": orderMenu,
                "search": value,
                "Seller_Partner_Detail_Id": EmailID?.Partner_Details_Id,
                "Records_Filter": "FILTER",
                "Shopper_Order_Id": ""
            };
            setSearchold(value)
            dispatch(getAllb2bOrdersAction(formData, pagination))
        }
    };
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        refreshCall();
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };

    const style = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontSize: 35,
            alignItems: 'center',
            justifyContent: 'center',
        },
        titles: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 100
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 180,
            paddingLeft: 10,
        },
        tablerows: {
            justifyContent: "center",
            textAlign: "center",
            width: 180,
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 150
        },
        rowsPerPageContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 10,
            justifyContent: 'flex-end',
            borderTopColor: esiColor.SBorderColor,
            borderWidth: 0.5,
        },
        rowsPerPagePicker: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.itemColor
        },
        rowsPerPagePickers: {
            margin: 10,
            width: 70,
            height: 40,
            borderRadius: 10,
            borderColor: 'gray',
            borderWidth: 0.5,
            paddingHorizontal: 8,
        },
        paginationContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            // backgroundColor: esiColor.SIconColor
        },
        paginationButton: {
            padding: 10,
            marginHorizontal: 10,
            // backgroundColor: esiColor.SIconColor
        },

        pageInfoText: {
            fontSize: 16,
            padding: 10,
            fontWeight: 'bold',
            color: esiColor.itemColor
        },
    });

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation}></Header>
            <View>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => {
                    navigation.navigate('OrderMainCenter')
                    handleSearch("")
                    dispatch(resetFilter())
                }} >
                    Go Back
                </Button>
            </View>
            <ScrollView>
                <View style={{
                    flexDirection: "column"
                }}>
                    <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Order Details</Text>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
                    {Platform.OS !== "web" ?
                        <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={filterOptions}
                                containerStyle={style.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={orderMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
                            <Picker
                                selectedValue={orderMenu}
                                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Shopper Order ID" value="Shopper_Order_Id" />
                                <Picker.Item label="Shopper Email ID" value="Shopper_Email_Id" />
                                <Picker.Item label="Is Approved" value="Is_Approved" />
                                <Picker.Item label="Order Status" value="Order_Status" />
                            </Picker>
                        </View>}

                    <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{
                                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.BackgroundColor
                            }}
                            iconColor={esiColor.SIconColor}
                            theme={esiColor.pagination_theme}
                            placeholderTextColor={esiColor.SPHTextColor}
                            placeholder="Search"
                            onChangeText={(value) => handleSearch(value)}
                            value={orderSearch}
                        />
                    </View>
                </View>
                <ScrollView
                    horizontal={dimensions >= 700 ? false : true}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                            colors={['#27B6CC']}
                            tintColor={'#27B6CC'}
                            title={"Pull to refresh"}
                            titleColor={"#27B6CC"}
                        />
                    }>
                    <View style={style.mainbox}>
                        <Card>
                            <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                <DataTable.Header style={style.databeHeader}>
                                    <DataTable.Title style={style.titles} active={orderBy === 'Shopper_Order_Id'} sortDirection={orderBy === 'Shopper_Order_Id' ? order : 'ascending'} onPress={createSortHandler('Shopper_Order_Id')}>
                                        <Text style={style.titletext}>Shopper Order ID</Text></DataTable.Title>
                                    <DataTable.Title style={style.titles} active={orderBy === 'Shopper_Email_Id'} sortDirection={orderBy === 'Shopper_Email_Id' ? order : 'ascending'} onPress={createSortHandler('Shopper_Email_Id')}>
                                        <Text style={style.titletext}>Shopper Email ID</Text></DataTable.Title>
                                    <DataTable.Title style={style.titles} active={orderBy === 'Is_Approved'} sortDirection={orderBy === 'Is_Approved' ? order : 'ascending'} onPress={createSortHandler('Is_Approved')}>
                                        <Text style={style.titletext}>Is Approved</Text></DataTable.Title>
                                    <DataTable.Title style={style.titles} active={orderBy === 'Order_Status'} sortDirection={orderBy === 'Order_Status' ? order : 'ascending'} onPress={createSortHandler('Order_Status')}>
                                        <Text style={style.titletext}>Order Status</Text></DataTable.Title>
                                    {(permissions.Order_Management === "write") && <DataTable.Title style={style.titles}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                    }
                                </DataTable.Header>
                                {Orders?.map((allorder, index) => (
                                    <DataTable.Row style={style.databeBox} key={index}>
                                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{allorder?.Order_Data?.Shopper_Order_Id}</Text></DataTable.Cell>
                                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{allorder?.Order_Data?.Shopper_Email_Id}</Text></DataTable.Cell>
                                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{allorder?.Order_Data?.Is_Approved}</Text></DataTable.Cell>
                                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{allorder?.Order_Data?.Order_Status}</Text></DataTable.Cell>
                                        {(permissions.Order_Management === "write") && <DataTable.Cell style={style.tablerows}> <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={(e) => navigation.navigate('B2bEditOrder', { orderid: allorder.Order_Data.Shopper_Order_Id, partnerid: EmailID?.Partner_Details_Id })}
                                        /></DataTable.Cell>
                                        }
                                    </DataTable.Row>
                                ))
                                }
                                {isEmpty(Orders) &&
                                    <View>
                                        <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                            No records found!
                                        </Text>
                                        <Image source={require('../../assets/images/nodata.gif')}
                                            style={{ width: 300, height: 300, alignSelf: "center" }} />
                                    </View>
                                }
                                {Platform.OS !== "web" ?

                                    <DataTable.Pagination
                                        // theme={customTheme}
                                        page={page}
                                        numberOfPages={Math.ceil(allOrders?.Pagination?.TotalCount / numberOfItemsPerPage)}
                                        onPageChange={page => DynamicPage(to < allOrders?.Pagination?.TotalCount ? page : 0)}
                                        label={allOrders?.Pagination?.TotalCount ? `${from + 1}-${to} of ${allOrders?.Pagination?.TotalCount}` : '0-0'}
                                        showFastPaginationControls={(Number(page) + Number(1)) > allOrders?.Pagination?.TotalPages ? false : true}
                                        numberOfItemsPerPageList={numberOfItemsPerPageList}
                                        numberOfItemsPerPage={numberOfItemsPerPage}
                                        onItemsPerPageChange={onItemsPerPageChange}
                                        selectPageDropdownLabel={'Rows per page'}
                                    />
                                    :
                                    <View style={style.rowsPerPageContainer}>
                                        <Text style={{ color: esiColor.Text }}>rows per page: </Text>
                                        <Picker
                                            style={style.rowsPerPagePicker}
                                            selectedValue={numberOfItemsPerPage}
                                            onValueChange={(e) => onItemsPerPageChange(e)}
                                        >
                                            <Picker.Item label="5" value={5} />
                                            <Picker.Item label="10" value={10} />
                                            <Picker.Item label="20" value={20} />
                                        </Picker>
                                        <View style={style.paginationContainer}>
                                            <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                                                <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                                            </TouchableOpacity>
                                            <Text style={style.pageInfoText}>
                                                {allOrders?.Pagination?.PageNo + ' of ' + allOrders?.Pagination?.TotalPages}
                                            </Text>
                                            <TouchableOpacity onPress={e => DynamicPageIncrease(to < allOrders?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                                                <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                                            </TouchableOpacity>
                                        </View>
                                    </View>}
                            </DataTable>
                        </Card>
                    </View>
                </ScrollView>
            </ScrollView>
        </Surface>
    );
}

export default B2bOrderDetails