import * as React from 'react';
import { Image, StyleSheet} from 'react-native';
import { Text, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';
import Header from './auth/components/header';

export default function NotFoundScreen({ navigation }: RootStackScreenProps<'NotFound'>) {
  return (
    <>
      <Header navigation={navigation} />
      <View style={styles.container}>
        <Text style={{ color: '#27b6cc', fontWeight: 'bold', fontSize: 20 }}>Oops! Page not found!</Text>
        <Image source={require('../assets/images/img/404.jpg')}
          style={{ width: 450, height: 280 }} />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});