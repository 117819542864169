import axios from "axios";
import isEmpty from "../../validations/is-empty";
import { meeapi } from "../node-actions/meeapi";
import config from "./../config";
import shopperconfig from "./shopperconfig";

export const ALL_PRODUCTS_REQUEST = "ALL_PRODUCTS_REQUEST";
export const ALL_PRODUCTS_FAIL = "ALL_PRODUCTS_FAIL";
export const PRODUCTRESET = "PRODUCTRESET";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const PRODUCTSIZERESET = "PRODUCTSIZERESET";
export const ALL_IMAGE_PRODUCTS_REQUEST = "ALL_IMAGE_PRODUCTS_REQUEST";
export const ALL_IMAGE_PRODUCTS = "ALL_IMAGE_PRODUCTS";
export const ALL_IMAGE_PRODUCTS_FAIL = "ALL_IMAGE_PRODUCTS_FAIL";
export const ALL_USER_REVIEWS_REQUEST = "ALL_USER_REVIEWS_REQUEST";
export const ALL_USER_REVIEWS = "ALL_USER_REVIEWS";
export const ALL_USER_REVIEWS_FAIL = "ALL_USER_REVIEWS_FAIL";

export const ALL_PRODUCTS_PARTNER_REQUEST = "ALL_PRODUCTS_PARTNER_REQUEST";
export const ALL_PRODUCTS_BRAND_REQUEST = "ALL_PRODUCTS_BRAND_REQUEST";
export const SET_ALL_FILTER_PARTNER_PRODUCTS = "SET_ALL_FILTER_PARTNER_PRODUCTS";
export const SET_ALL_FILTER_BRAND_PRODUCTS = "SET_ALL_FILTER_BRAND_PRODUCTS";

export const REVIEW_CREATE_REQUEST = "REVIEW_CREATE_REQUEST";
export const REVIEW_CREATE_SUCCESS = "REVIEW_CREATE_SUCCESS";
export const REVIEW_CREATE_FAIL = "REVIEW_CREATE_FAIL";
export const REVIEW_UPDATE_REQUEST = "REVIEW_UPDATE_REQUEST";
export const REVIEW_UPDATE_SUCCESS = "REVIEW_UPDATE_SUCCESS";
export const REVIEW_UPDATE_FAIL = "REVIEW_UPDATE_FAIL";
export const ALL_PARTNER_PRODUCTS_REQUEST = "ALL_PARTNER_PRODUCTS_REQUEST";
export const ALL_PARTNER_PRODUCTS = "ALL_PARTNER_PRODUCTS";
export const ALL_PARTNER_PRODUCTS_FAIL = "ALL_PARTNER_PRODUCTS_FAIL";
export const SET_ALL_PARTNER_PRODUCTS = "SET_ALL_PARTNER_PRODUCTS";
export const ALL_RELATED_PRODUCTS_REQUEST = "ALL_RELATED_PRODUCTS_REQUEST";
export const SET_ALL_RELATED_PRODUCTS = "SET_ALL_RELATED_PRODUCTS";
export const ALL_RELATED_PRODUCTS_FAIL = "ALL_RELATED_PRODUCTS_FAIL";
export const ALL_RELATED_REQUEST = "ALL_RELATED_REQUEST";
export const SET_ALL_RELATED = "SET_ALL_RELATED";
export const ALL_RELATED_FAIL = "ALL_RELATED_FAIL";
export const ALL_COLOR_REQUEST = "ALL_COLOR_REQUEST";
export const SET_ALL_COLOR = "SET_ALL_COLOR";
export const ALL_COLOR_FAIL = "ALL_COLOR_FAIL";
export const ALL_SIZE_REQUEST = "ALL_SIZE_REQUEST";
export const SET_ALL_SIZE = "SET_ALL_SIZE";
export const ALL_SIZE_FAIL = "ALL_SIZE_FAIL";
export const VISITOR_CREATE_REQUEST = "VISITOR_CREATE_REQUEST";
export const VISITOR_CREATE_SUCCESS = "VISITOR_CREATE_SUCCESS";
export const VISITOR_REVIEW_CREATE_FAIL = "VISITOR_REVIEW_CREATE_FAIL";
export const ALL_FILTER_BRAND_PRODUCTS = "ALL_FILTER_BRAND_PRODUCTS";
export const ALL_PRODUCTS_BRAND_FAIL = "ALL_PRODUCTS_BRAND_FAIL";
export const TOP_PRODUCT = "TOP_PRODUCT";
export const TOP_PRODUCT_FAIL = "TOP_PRODUCT_FAIL";
export const TOP_PRODUCT_SUCCESS = "TOP_PRODUCT_SUCCESS";
export const ALL_VISITED_REQUEST = "ALL_VISITED_REQUEST";
export const SET_ALL_VISITED = "SET_ALL_VISITED";
export const ALL_VISITED_FAIL = "ALL_VISITED_FAIL";
export const VISITEDPRODUCTRESET = "VISITEDPRODUCTRESET";
export const ALL_SUBCATEGORY_PRODUCTS_REQUEST = "ALL_SUBCATEGORY_PRODUCTS_REQUEST";
export const ALL_SUBCATEGORY_PRODUCTS = "ALL_SUBCATEGORY_PRODUCTS";
export const ALL_SUBCATEGORY_PRODUCTS_FAIL = "ALL_SUBCATEGORY_PRODUCTS_FAIL";
export const VISITED_PRODUCTS_REQUEST = "VISITED_PRODUCTS_REQUEST";
export const VISITED_PRODUCTS_SUCCESS = "VISITED_PRODUCTS_SUCCESS";
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
/*=============================================================
                  Get All Products Action
===============================================================*/
const headers = shopperconfig.headersCommon;
const cudheaders = config.nodeheadersCommon;
const nodeheaders = shopperconfig.nodeheadersCommon;
const urlheaders = shopperconfig.nodeheadersCommon;

export const adduservisitAction = (formData: any) => async (dispatch: any) => {
  dispatch({
    type: VISITOR_CREATE_REQUEST,
  });
  axios.post(`${config.url}/B2B_Cart/Create`, formData, {
    headers: headers,

  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: VISITOR_CREATE_SUCCESS
        });
        dispatch(getvisitedProductByIdAction(formData.Buyer_Partner_Details_Id));
      } else {

        dispatch({
          type: VISITOR_REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: VISITOR_REVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

export const getAllProductsAction = (Id, type) => async (dispatch) => {
  let Brandid = [];
  let Partnerid = [];
  let topproducts = "";
  let subcategoryid = [];
  try {
    if (type === "Brand") {
      dispatch({
        type: ALL_PRODUCTS_BRAND_REQUEST,
      });
      Brandid.push(Id);
    }
    else if (type === "Partner") {
      dispatch({
        type: ALL_PARTNER_PRODUCTS_REQUEST,
      });
      Partnerid.push(Id);
    }
    else if (type === "Subcategory") {
      dispatch({
        type: ALL_SUBCATEGORY_PRODUCTS_REQUEST,
      });
      subcategoryid.push(Id);
    }
    else if (type === "Is_Top_Product") {
      topproducts = "1"
      dispatch({
        type: TOP_PRODUCT,
      });

    }
    else {
      dispatch({
        type: ALL_PRODUCTS_REQUEST,
      });
    }

    let formData =
    {
      Records_Filter: "FILTER",
      Status: "Active",
    }
    if (!isEmpty(Brandid))
      formData.Brand_Id = Brandid;
    if (!isEmpty(Partnerid))
      formData.Partner_Details_Id = Partnerid;
    if (!isEmpty(topproducts))
      formData.Is_Top_Product = topproducts;

    let { data } = await axios.post(
      `${shopperconfig.nodeurl}/B2B_Product/Get?PageNo=0&PageSize=1000&SortBy=PRODUCT_NAME&SortOrder=ASC`,
      formData,
      {
        headers: urlheaders
      }
    );
    if (data) {
      let dataresponse = { data: data, form: formData }
      if (type === "Brand") {
        dispatch({
          type: ALL_FILTER_BRAND_PRODUCTS,
          payload: dataresponse,
        });
      } else if (type === "Partner") {
        dispatch({
          type: ALL_PARTNER_PRODUCTS,
          payload: dataresponse,
        });
      }
      else if (type === "Subcategory") {
        dispatch({
          type: ALL_SUBCATEGORY_PRODUCTS,
          payload: dataresponse,
        });
      }
      else if (type === "Is_Top_Product") {
        dispatch({
          type: TOP_PRODUCT_SUCCESS,
          payload: dataresponse,
        });
      }
      else {
        dispatch({
          type: SET_ALL_PRODUCTS,
          payload: data.Product_Details,
        })
      }
    }
  } catch (error) {
    if (type === "Brand") {
      dispatch({
        type: ALL_PRODUCTS_BRAND_FAIL,
        payload: error
      });
    } else if (type === "Partner") {
      dispatch({
        type: ALL_PARTNER_PRODUCTS_FAIL,
        payload: error
      });
    }
    else if (type === "Subcategory") {
      dispatch({
        type: ALL_SUBCATEGORY_PRODUCTS_FAIL,
        payload: error
      });
    }
    else if (type === "All") {

      dispatch({
        type: ALL_PRODUCTS_FAIL,
        payload: error
      });
    }
  }
};

/*=============================================================
                  Get Product By Id Action
===============================================================*/


export const getProductfilterAction = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_PRODUCTS_REQUEST,
    });

    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2B_Product/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    let dataresponse = { data: data, form: formData, type: "Filters" }
    if (data) {
      dispatch({
        type: SET_ALL_PRODUCTS,
        payload: dataresponse,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_PRODUCTS_FAIL,
      payload: error
    });
  }
};


export const getProductByIdAction = (
  productId,
  callBackGetData,
  ID
) => async () => {
  try {
    const formData = {
      "Brand_Id": "",
      "Category_Id": "",
      "Discount": "",
      "Discount_In_Percentage": "",
      "Discounted_Product_Id": "",
      "Esi_Cash_Back": "",
      "ExtraCashBack": "",
      "Is_Popular": "",
      "Is_Top_Product": "",
      "Is_Upcoming": "",
      "Is_Verified": "",
      "Max_Price": "",
      "Max_Quantity": "",
      "Max_Rating": "",
      "Min_Price": "",
      "Min_Quantity": "",
      "Min_Rating": "",
      "Partner_Details_Id": ID,
      "Product_Id": productId,
      "Product_Name": "",
      "Records_Filter": "FILTER",
      "Sub_Category_Id": "",
      "Todays_Trending": ""
    };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Discounted_Products/Get_Single_Partner_Product`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackGetData(data.results);
    }
  } catch (error) {
  }

};

export const getProductsImageAction = (ProductId: any, pagination = {}) => async (dispatch) => {
  const formData = {
    "search": "",
    "Product_Id": ProductId,
    "Records_Filter": "FILTER",
    "Status": ""
  }
  try {
    dispatch({
      type: ALL_IMAGE_PRODUCTS_REQUEST,
    });
    meeapi.post(urlGenarator(`/Products/Product_Image_Get`, formData),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_IMAGE_PRODUCTS,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_IMAGE_PRODUCTS,
          payload: data.results,
        });

      }
    })
  } catch (error) {
    dispatch({
      type: ALL_IMAGE_PRODUCTS_FAIL,
      payload: error
    });
  }
};

export const getUserReviewsbyIdAction = (ProductId) => async (dispatch) => {

  try {
    dispatch({
      type: ALL_USER_REVIEWS_REQUEST,
    });
    const formData = {
      Product_Id: ProductId,
      Records_Filter: "FILTER"
    }
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Reviews/Product_Review_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      dispatch({
        type: ALL_USER_REVIEWS,
        payload: data.results,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_USER_REVIEWS_FAIL,
      payload: error
    });
  }
};

export const getPartnerproductsbyIdAction = (ProductId: any, ID: any) => async (dispatch) => {

  try {
    dispatch({
      type: ALL_PARTNER_PRODUCTS_REQUEST,
    });
    const formData = {
      "Partner_Details_Id":ID,
      "Product_Id": ProductId,
      "Discounted_Products_Id": "",
      "Records_Filter": "FILTER"
    };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Discounted_Products/Physical_Partner_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      dispatch({
        type: SET_ALL_PARTNER_PRODUCTS,
        payload: data.results,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_PARTNER_PRODUCTS_FAIL,
      payload: error
    });
  }
};

export const getRelatedProductByIdAction = (
  Id
) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_RELATED_REQUEST,
    });
    const formData = {
      "search_by_filter": "",
      "search": "",
      "Product_Id": Id,
      "Records_Filter": "FILTER",
      "Product_Name": ""
    };

    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Products/Related_Product_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      dispatch({
        type: SET_ALL_RELATED,
        payload: data.results,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_RELATED_FAIL,
      payload: error
    });
  }
};

export const getRelatedProductAction = (ID, Partnerdetail, Product_Id) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_RELATED_PRODUCTS_REQUEST,
    });
    let formData = {
      "Brand_Id": "",
      "Category_Id": "",
      "Discount": "",
      "Discount_In_Percentage": "",
      "Discounted_Product_Id": "",
      "Esi_Cash_Back": "",
      "ExtraCashBack": "",
      "Is_Popular": "",
      "Is_Top_Product": "",
      "Is_Upcoming": "",
      "Is_Verified": "",
      "Max_Price": "",
      "Max_Quantity": "",
      "Max_Rating": "",
      "Min_Price": "",
      "Min_Quantity": "",
      "Min_Rating": "",
      "Partner_Details_Id": Partnerdetail.Partner_Details_Id,
      "Product_Id": Product_Id,
      "Product_Name": "",
      "Records_Filter": "FILTER",
      "Sub_Category_Id": "",
      "Todays_Trending": ""
    }
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Discounted_Products/Get_Single_Partner_Product`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      dispatch({
        type: SET_ALL_RELATED_PRODUCTS,
        payload: data.results,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_RELATED_PRODUCTS_FAIL,
      payload: error
    });
  }
};



export const getProductColorsAction = (masterId) => async (dispatch) => {
  let formData = {
    Product_Master_Id: masterId
  }


  try {
    dispatch({
      type: ALL_COLOR_REQUEST,
    });
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/B2B_Product_Color/Color_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === "0") {
        dispatch({
          type: SET_ALL_COLOR,
          payload: data.results.Color_Variant,
        });
      }
      else {
        dispatch({
          type: SET_ALL_COLOR,
          payload: data.Color_Variant.Color_Variant,
        });
      }
    }

  } catch (error) {
    dispatch({
      type: ALL_COLOR_FAIL,
      payload: error
    });
  }
};

export const getProductSizeAction = (pid, pdid) => async (dispatch) => {
  let formData = {
    Partner_Details_Id: pdid,
    Product_Id: pid
  }
  try {
    dispatch({
      type: ALL_SIZE_REQUEST,
    });
    let { data } = await axios.post(
      `${shopperconfig.newurl}/B2B_Product_Size/Get`,
      formData,
      {
        headers: headers
      }
    );

    if (data) {
      if (data.Is_Data_Exist === "0") {
        dispatch({
          type: SET_ALL_SIZE,
          payload: data,
        });
      }

      else {
        dispatch({
          type: SET_ALL_SIZE,
          payload: data.Product_Siz.Product_Size,
        });
      }

    }
  } catch (error) {
    dispatch({
      type: ALL_SIZE_FAIL,
      payload: error
    });
  }
};


export const getProductSizeActionWishList = (pid, pdid, wishlistId, setResponse) => async (dispatch) => {
  let formData = {
    Partner_Details_Id: pdid,
    Product_Id: pid
  }

  try {
    let { data } = await axios.post(
      `${shopperconfig.newurl}/B2B_Product_Size/Get`,
      formData,
      {
        headers: headers
      }
    );
    if (data) {
      
      if (data.Success_Response.Is_Data_Exist=== "0") {
        setResponse(true, data, wishlistId, data.UI_Display_Message);
      }
      else {
        setResponse(true, data.Product_Size.Product_Size[0].Product_Size.Product_Size, wishlistId, "Success response.")
      }

    }
  } catch (error) {
    setResponse(false, [], wishlistId, "Please try again later.")
  }
};

export const productsizereset = () => async (dispatch) => {
  dispatch({
    type: PRODUCTSIZERESET,
    payload: []
  });
}


export const addProductReviewAction = (formData, ProductId, responseFunction) => async (dispatch) => {

  dispatch({
    type: REVIEW_CREATE_REQUEST,
  });
  axios.post(`${config.nodecudurl}/B2B_Product_Review/Create`, formData, {
    headers: cudheaders,
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: REVIEW_CREATE_SUCCESS
        });
        dispatch(getUserReviewsbyIdAction(ProductId));
        responseFunction(true, "Review added successfully.");
      } else {
        responseFunction(false, successResponse.UI_Display_Message);
        dispatch({
          type: REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      responseFunction(false, "Please try sometime later.Currently server is not working");
      dispatch({
        type: REVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

export const editProductReviewAction = (formData, Product_Id, responseFunctionUpdate) => async (dispatch) => {

  dispatch({
    type: REVIEW_UPDATE_REQUEST,
  });
  axios.put(`${shopperconfig.url}/Reviews/Product_Review_Update`, formData, {
    headers: cudheaders
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: REVIEW_UPDATE_SUCCESS
        });
        responseFunctionUpdate(true, 'Review updated successfully.');
        dispatch(getUserReviewsbyIdAction(Product_Id));
      } else {
        responseFunctionUpdate(true, successResponse.UI_Display_Message);
        dispatch({
          type: REVIEW_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      responseFunctionUpdate(true, "Please try sometime later.Currently server is not working");
      dispatch({
        type: REVIEW_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

export const resetRecentlyProducts = () => async (dispatch) => {
  dispatch({
    type: VISITEDPRODUCTRESET,
    payload: []
  });
}

export const getvisitedProductByIdAction = (formData:any) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_RELATED_REQUEST,
    });
    const formdata = {
      "Buyer_Partner_Details_ID": formData
    };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Cart/Partner_Cart_Get`,
      formdata,
      {
        headers: nodeheaders
      }
    );

    if (data) {
      let Id = [];
      let visitors = data.results[0];
      let mailid;
      if (visitors) {
        for (let i = 0; i < visitors?.Partner_Cart_Get_Final[0]?.length; i++) {
          if (visitors[i].Business_Type==="RETAIL") {
            Id.push(visitors[i].Business_Type)
          }
        }
      }

      if (Id) {
        mailid = Id.toString().replace(/\[.*\]/g, '')
      }
      dispatch(getProductForVisit(mailid));

      dispatch({
        type: SET_ALL_VISITED,
        payload: data.results,
      });
    }

  } catch (error) {
    dispatch({
      type: ALL_VISITED_FAIL,
      payload: error
    });
  }

};

export const getProductForVisit = (productId, Partnerdetail) => async (dispatch: any) => {
  dispatch({
    type: VISITED_PRODUCTS_REQUEST,
    payload: []
  });
  try {
    const formData = {
      Records_Filter: "FILTER",
      Product_Id: productId,
      Partner_Details_Id: Partnerdetail.Partner_Details_Id
    };

    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Products/Single_Partner_Products_Get`,
      formData,
      {
        headers: urlheaders
      }
    );
    if (data) {
      if (data.Success_Response.Response_Status === "Success") {
        dispatch({
          type: VISITED_PRODUCTS_SUCCESS,
          payload: data.results
        });
      } else {
        dispatch({
          type: VISITED_PRODUCTS_SUCCESS,
          payload: []
        });
      }
    }
  } catch (error) {
  }

};

export const getproductwish = (
  mailId,
  productId,
  callBackGetData
) => async () => {
  try {
    const formData = {
      Records_Filter: "FILTER",
      Product_Id: productId,
      Status: "Active"
    };
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/GetProducts?PageNo=0&PageSize=1000&SortBy=PRODUCT_NAME&SortOrder=ASC`,

      formData,
      {
        headers: urlheaders
      }

    );

    if (data) {

      callBackGetData(data.Product_Details);
    }
  } catch (error) {
  }

};
