import {
  ActionType

} from '../actions/authActions';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform, StyleSheet } from 'react-native';
import secureLocalStorage from 'react-secure-storage';
import * as SecureStore from 'expo-secure-store';
import EncryptedStorage from 'react-native-encrypted-storage';
const storeData = async (value) => {
  try {
    
    const jsonValue = JSON.stringify(value) 
   
      await AsyncStorage.setItem('user', jsonValue)
  
  } catch (e) {
  }
}

const removeValue = async () => {
  try {
      await AsyncStorage.removeItem('user')
    }
  catch(e) {
  }

}

const getPermissions = (user: any) => {
  try {
    return {
      Dashboard: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read",
      Employees: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read",
      Product_Master_Product_Color_Product_Size: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Products_Partner_Products_Related_Products: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Ticket_Management: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER" || user?.User_Type === "PARTNER_EMPLOYEE") ? "write" : "read"
      ,
      Address_Management: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Deals_Management: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Partner_Profile: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Coupons_Management: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Brands_Management: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read",
      Content_Management: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      FAQ: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Partner_Payments: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      User_Payments: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Transaction_History: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Order_Cancel_and_Return: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Partner_Bank: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Inventory_Management: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      How_Partner_Association_Works: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Service_level_Agreement: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Shop_by_City_Management_Coupons_Deals_Products: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Shipping_Management: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER" || user?.User_Type === "PARTNER_EMPLOYEE") ? "write" : "read"
      ,
      Gifts_Management_Vendors_Images_User_Gifts: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Reviews_and_Ratings: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
      ,
      Order_Management: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER" || user?.User_Type === "PARTNER_EMPLOYEE") ? "write" : "read"
      ,
      Settings: (user?.User_Type === "PARTNER_ADMIN" || user?.User_Type === "PARTNER") ? "write" : "read"
    }
  } catch (e) {
    // read error
  }
}

const initialState = {
  user: {},
  error: '',
  isAuthenticated: false,
  isLoading: false,
  permissions: {}
}
export default function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case ActionType.USER_SIGNIN:
      return { isLoading: false, user: JSON.parse(action.data), error: '', isAuthenticated: action.data?true:false, permissions: getPermissions(JSON.parse(action.data)) }
    case ActionType.USER_REQUEST:
      return { ...state, error: '', isLoading: true, isAuthenticated: false }
    case ActionType.USER_SUCCESS:
      if (action.login) {
        storeData(action.data);
        return { isLoading: false, user: action.data, error: '', isAuthenticated: true, permissions: getPermissions(action.data) }
      } else {
        if (state.isAuthenticated) {
          storeData(action.data);
          return {isLoading: false, user: action.data, error: '', isAuthenticated: true,permissions: getPermissions(action.data) }
        } else {
          return { ...state }
        }
      }
    case ActionType.USER_FAIL:
      if (action.login) {
        return { isLoading: false, user: {}, error: action.data, isAuthenticated: false }
      } else {
        return { ...state }
      }
    case ActionType.USER_SIGNUP_REQUEST:
      return { ...state, error: '' }
    case ActionType.USER_SIGNUP_SUCCESS:
      return { ...state, error: '' }
    case ActionType.USER_SIGNUP_FAIL:
      return { ...state, error: action.data }
    case ActionType.USER_LOGOUT:
      removeValue();
      return { ...state, isAuthenticated: false, user: {}, permissions: getPermissions({}) };
    default:
      return state;
  }
}