import React, { useState, useEffect } from 'react';
import { Card, Button, Checkbox, Surface } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, Image, ActivityIndicator, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {  Formik } from 'formik';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import * as ImagePicker from 'expo-image-picker';
import * as Yup from 'yup';
import {
    updateProductAction,
    getProductImages,
    imageDefaultUpdate,
    imageDelete,
    singleImageUploadUpdate,
} from '../../../../state/actions/farmer/farmerproductsAction';
import Header from '../../../auth/components/header';
import isEmpty from '../../../../state/validations/is-empty';
import SampleImage from '../../../../assets/custom-components/image-placeholder.png';
import AsyncStorage from '@react-native-async-storage/async-storage';
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const EditFarmerProductpagefour = (props: any) => {
    const toast = useToast();
    const [errorMessage, setErrorMessage] = useState({ Product_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    const { navigation } = props;
    const esiColor = useSelector(state => state.theme);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const product_id = props.route?.params?.product_id;
    const productmaster = useSelector(state => state.farmerproduct.productdata.products);
    const EmailID = useSelector(state => state.auth?.user);
    const dispatch = useDispatch();
    React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (isEmpty(product_id)) {
            navigation.navigate('FarmerProductGet')
          } else {
        dispatch(getProductImages(product_id, callBackGetImages));
    }
    }, [product_id]
    );
    useEffect(() => {
        dispatch(getProductImages(product_id, callBackGetImages));
    }, []);
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    // img upload
    const [blobArrayPics, setBlobArrayPics] = useState([{
        key: 1, preview: null, Product_Image_Id: null, Product_Default: 0
    },
    {
        key: 2, preview: null, Product_Image_Id: null, Product_Default: 0
    },
    {
        key: 3, preview: null, Product_Image_Id: null, Product_Default: 0
    },
    {
        key: 4, preview: null, Product_Image_Id: null, Product_Default: 0
    },
    {
        key: 5, preview: null, Product_Image_Id: null, Product_Default: 0
    }]);
    const [imageError, setImageError] = useState();
    const callBackUploadImage = (status: any) => {
        setIsPicUpload(false);
        setImageDefault(true);
        if (status) {
            dispatch(getProductImages(product_id, callBackGetImages));
        }
        else {
            setImageError("Unable to upload image please try again.");
        }
    };
    const [imageDefault, setImageDefault] = useState(false);

    function handleDefaultChange(status: any, key: any, imageId: any) {
        setImageDefault(true);
        dispatch(imageDefaultUpdate(productmaster.Product_Id, EmailID.Email_Id, imageId, callBackUploadImage));
    };
    const [isPicUpload, setIsPicUpload] = useState(false);
    const pickImage = async (key: any) => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
        });
        // if image is not cancelled after upload then  responce will passing to the uploadPic
        if (!result.cancelled) {
             const img = await fetchImageFromUri(result?.assets[0]?.uri);
            setIsPicUpload(true);
            dispatch(
                singleImageUploadUpdate(img, productmaster.Product_Id, EmailID.Email_Id, key, callBackUploadImage)
            );
        }
    };
    const fetchImageFromUri = async (uri: any) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        return blob;
    };
    function handleRemoveImage(key: any, imageId: any) {
        if (imageId) {
            dispatch(imageDelete(imageId, callBackDeleteImage, key));
        }
        else {
            callBackDeleteImage(imageId, true, key);
        }
    };

    const callBackDeleteImage = (imageId, status, key) => {

        dispatch(getProductImages(product_id, callBackGetImages));

    };



    const callBackGetImages = (images) => {
        setImageDefault(false);
        let count = 0;
        const blobArrayPicsTemp = [{
            key: 1, preview: null, Product_Image_Id: null, Product_Default: 0
        },
        {
            key: 2, preview: null, Product_Image_Id: null, Product_Default: 0
        },
        {
            key: 3, preview: null, Product_Image_Id: null, Product_Default: 0
        },
        {
            key: 4, preview: null, Product_Image_Id: null, Product_Default: 0
        },
        {
            key: 5, preview: null, Product_Image_Id: null, Product_Default: 0
        }];
        setBlobArrayPics([{
            key: 1, preview: null, Product_Image_Id: null, Product_Default: 0
        },
        {
            key: 2, preview: null, Product_Image_Id: null, Product_Default: 0
        },
        {
            key: 3, preview: null, Product_Image_Id: null, Product_Default: 0
        },
        {
            key: 4, preview: null, Product_Image_Id: null, Product_Default: 0
        },
        {
            key: 5, preview: null, Product_Image_Id: null, Product_Default: 0
        }]);
        if (images[0].Product_Image.length > 5) {
            for (let i = 0; i <= images.length - 6; i++) {
                blobArrayPicsTemp.push({
                    key: i + 6, preview: null, Product_Image_Id: null, Product_Default: 0
                })
            }
        }
        images[0].Product_Image.map(image => {
            blobArrayPicsTemp[count].preview = image.Product_Image;
            blobArrayPicsTemp[count].Product_Image_Id = image.Product_Image_Id;
            blobArrayPicsTemp[count].Product_Default = Number(image.Is_Default);
            count++
        })
        setBlobArrayPics(blobArrayPicsTemp);
     };
    useEffect(() => {
        if (isEmpty(product_id)) {
            navigation.navigate('EditFarmerProduct')
          }  else if(!isEmpty(productmaster)){
           } else{
            navigation.navigate('EditFarmerProduct',{product_id:product_id})
        }
    }, [product_id,productmaster]
    );
    const [subLoading, setSubLoading] = useState(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        button: {
            marginTop: 30,
            width: '75%',
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'green',
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1,
            marginBottom: "2%"
        },
    
        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        checkcontainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: "-70%"
        },
        phonenumbercontainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        phoneContainer: {
            width: '100%',
            height: 50,
        },
        phonetextInput: {
            paddingVertical: 0,
        },
        editor: {
            backgroundColor: "black",
            borderColor: "black",
            borderWidth: 1,
        },
        rich: {
            minHeight: 130,
            // flex: 1,
        },
        richBar: {
            height: 50,
            backgroundColor: "#F5FCFF",
        },
        text: {
            fontWeight: "bold",
            fontSize: 18,
        },
        tib: {
            textAlign: "center",
            color: "#515156",
        },
        a: {
            fontWeight: "bold",
            color: "purple",
        },
        div: {
            fontFamily: "monospace",
        },
        p: {
            fontSize: 30,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flexShrink: 1,backgroundColor: esiColor.BackgroundColor }}  >
            <Header navigation={navigation} />
            <ScrollView   refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik enableReinitialize={true}
                    initialValues={{
                        Product_For: 'FARMER',
                        Product_Name: productmaster.Product_Name,
                        Category_Id: productmaster.Category_Id,
                        Product_Id: productmaster.Product_Id,
                        Brand_Id: productmaster.Brand_Id,
                        Product_Short_Description: productmaster.Product_Short_Description,
                        Product_Description: productmaster.Product_Description,
                        Features: productmaster.Features,
                        Specifications: productmaster.Specifications,
                        Product_Price: productmaster.Product_Price,
                        Product_Size_Id: productmaster.Product_Size_Id,
                        Product_Color_Variant_Id: productmaster.Product_Color_Variant_Id,
                        Product_Master_Id: productmaster.Product_Master_Id,
                        Is_Popular: productmaster.Is_Popular > 0 ? true : false,
                        Whats_Hot: productmaster.Whats_Hot > 0 ? true : false,
                        Is_Top_Product: productmaster.Is_Top_Product > 0 ? true : false,
                        Esi_Review: productmaster.Esi_Review,
                        Esi_Rating: parseInt(productmaster.Esi_Rating),
                        Country_Of_Origin: productmaster.Country_Of_Origin,
                        Status: productmaster.Status,
                        Is_Upcoming: productmaster.Is_Upcoming > 0 ? true : false,
                        Is_Verified: productmaster.Is_Verified > 0 ? true : false,
                        Sub_Category_Id: productmaster.Sub_Category_Id,
                        Modified_By_Email: EmailID.Email_Id,
                        Warranty_Period: productmaster.Warranty_Period,
                    }}
                    validationSchema={Yup.object().shape({
                    })}

                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        if (blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0) {
                            submitTest = false;
                            setImageError("Image is required.");
                        }
                        setErrors(errors);
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            if (values.Is_Popular) {
                                formValues.Is_Popular = 1;
                            } else {
                                formValues.Is_Popular = 0;
                            }
                            if (values.Is_Upcoming) {
                                formValues.Is_Upcoming = 1;
                            } else {
                                formValues.Is_Upcoming = 0;
                            }
                            if (values.Whats_Hot) {
                                formValues.Whats_Hot = 1;
                            } else {
                                formValues.Whats_Hot = 0;
                            }
                            if (values.Is_Top_Product) {
                                formValues.Is_Top_Product = 1;
                            } else {
                                formValues.Is_Top_Product = 0;
                            }
                            if (values.Is_Verified) {
                                formValues.Is_Verified = 1;
                            } else {
                                formValues.Is_Verified = 0;
                            }
                            setSubLoading(true);
                            dispatch(updateProductAction(formValues, navigation, toast,EmailID,'1'));
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                    }) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }} pointerEvents={subLoading ? "none" : "auto"} >
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('EditFarmerProductpagethree',{ product_id: product_id })} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{ 
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5 
                             }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", marginBottom: '1%',color:esiColor.brandFontColor }}>
                                        Edit Product
                                    </Text>
                                    <View style={{ flexDirection: "row", flexWrap: "wrap", paddingTop: 10, justifyContent: 'space-between' }}>
                                    </View>
                                    <View style={{ flexShrink: 1, paddingTop: 20, marginHorizontal: 20 }} >
                                        {blobArrayPics.filter(item => {
                                            if (item.key == 1) {
                                                return true;
                                            } else {
                                                return blobArrayPics[item.key - 2].preview != null;
                                            }
                                        }).map(option => (
                                            <Card style={{ marginBottom: 5, marginTop: 5 }} key={"image-card" + option.key}>
                                                <Card.Content>
                                                    <View display="flex" justifyContent="center">
                                                        {option.preview ? (
                                                            <Image
                                                                source={{ uri: option.preview }}
                                                                alt="Upload"
                                                                style={styles.images}
                                                            ></Image>
                                                        ) : (
                                                            <>
                                                                <Image
                                                                    source={SampleImage}
                                                                    alt="Upload"
                                                                    style={styles.images}
                                                                ></Image>
                                                            </>
                                                        )}
                                                    </View>
                                                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                        {option.preview ? <View><View style={{ flexShrink: 1, flexDirection: "row" }} >
                                                            <Checkbox.Android
                                                                status={Number(option.Product_Default) === 1 ? 'checked' : 'unchecked'}
                                                                onPress={(status) => handleDefaultChange(status, option.key, option.Product_Image_Id)}
                                                                name="Is_Default"
                                                                disabled={Number(option.Product_Default) === 1 ? true : false}
                                                            />
                                                            <Text style={{ color: option.Product_Default === 1 ? 'grey' : esiColor.itemColor, marginTop: 10 }}>Is Default</Text>
                                                        </View>
                                                            <Button style={{ fontSize: 10, color: ' #425A70 ' }} onPress={() => handleRemoveImage(option.key, option.Product_Image_Id)} >
                                                                <Text style={{ color: esiColor.itemColor }}>REMOVE PICTURE</Text>
                                                            </Button>
                                                        </View> : <Button style={{ fontSize: 10, color: ' #425A70 ' }} disabled={isPicUpload} uppercase={false} title="Choose Photo" onPress={() => pickImage(option.key)} >{isPicUpload ? <ActivityIndicator style={{ marginTop: 5 }} animating /> : <Text style={{ color: '#27B6CC' }}>CHOOSE FILE</Text>} </Button>}
                                                        <Text style={{ color: "red" }}>{blobArrayPics.filter(item => { return !(!item.preview) }).length <= 0 && imageError}</Text>
                                                    </View>
                                                </Card.Content>
                                            </Card>
                                        ))}
                                    </View>
                                </Card.Content>
                                <View style={{ marginLeft: "1%" }}>
                                    <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                                </View>
                                <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", justifyContent: 'center', paddingBottom: 15 }}>
                                    <Button textColor={esiColor.itemButtenColor} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                                        Save
                                    </Button>
                                </View>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface >
    );
};


export default EditFarmerProductpagefour;