import {
    ORDERS_STATISTICS,
    PROFIT_STATISTICS,
    USERS_STATISTICS,
    COUPONS_STATISTICS,
    DEALS_STATISTICS,
    PRODUCTS_STATISTICS
} from "../../actions/reports/statisticsActions";


const initialState = {
    Orders: { data: [], error: "" },
    Profit: { data: [], error: "" },
    Users: { data: [], error: "" },
    Coupons: { data: [], error: "" },
    Deals: { data: [], error: "" },
    Products: { data: [], error: "" },
};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case ORDERS_STATISTICS:
            return { ...state, Orders: { data: action.success ? action.data : [], error: action.success ? "" : action.data } }

        case PROFIT_STATISTICS:
            return { ...state, Profit: { data: action.success ? action.data : [], error: action.success ? "" : action.data } }

        case USERS_STATISTICS:
            return { ...state, Users: { data: action.success ? action.data : [], error: action.success ? "" : action.data } }

        case COUPONS_STATISTICS:
            return { ...state, Coupons: { data: action.success ? action.data : [], error: action.success ? "" : action.data } }

        case DEALS_STATISTICS:
            return { ...state, Deals: { data: action.success ? action.data : [], error: action.success ? "" : action.data } }

        case PRODUCTS_STATISTICS:
            return { ...state, Products: { data: action.success ? action.data : [], error: action.success ? "" : action.data } }

        default:
            return state;
    }
}