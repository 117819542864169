import React, { useState, useCallback, useEffect } from 'react';
import { Card, Button, TextInput, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import * as Yup from 'yup';

import {
    getAllCategories,
    getAllSubCategories,
    productsdata,
} from '../../../../state/actions/farmer/farmerproductsAction';
import Header from '../../../auth/components/header';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import isEmpty from '../../../../state/validations/is-empty';
import { getAllProductmasterssearch } from '../../../../state/actions/farmer/farmerpartnerproductsAction';
import { Formik } from 'formik';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");



const AddFarmerProducts = (props: any) => {
    const dispatch = useDispatch();
    const { navigation } = props;
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const esiColor = useSelector(state => state.theme);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Products_Partner_Products_Related_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    const [errorMessage, setErrorMessage] = useState({ Product_Name: { key: '', message: '' }, global: { key: '', message: '' } });
    const [showDropDown, setShowDropDown] = React.useState('');
    const [showDropDownsubcategory, setShowDropDownsubcategory] = React.useState('');

    const [allItems, setAllValues] = React.useState('');
    //getting colours by calling reducer
    //here iterating colours name and colours ID values 


    //getting masters by calling reducer
    const partnerproductssearch = useSelector(state => state.farmerpartnerproduct.partnerproductssearch.partners);
    React.useEffect(() => {
        if (!isEmpty(partnerproductssearch)) {
            setAllValues(partnerproductssearch[0])
        }
    }, [partnerproductssearch]);
    //getting categories by calling reducer
    const categories = useSelector(state => state.farmerproduct.productCategories.categories);
    //here iterating categorie name and categories ID values 
    let categoryopt = []
    if (categories) {
        if (categories[0]) {
            for (
                let i = 0;
                i < categories.length;
                i++) {
                categoryopt.push({
                    label: categories[i].Category_Name,
                    value: categories[i].Category_Id
                })
            }
        }
    }
    //getting subcategories by calling reducer
    const subcategories = useSelector(state => state.farmerproduct.productSubCategories.subcategories);
    //here iterating subcategories name and subcategories ID values 
    let subcategorieopt = []
    if (subcategories) {
        if (subcategories[0]) {
            for (let i = 0; i < subcategories.length; i++) {
                subcategorieopt.push(
                    {
                        label: subcategories[i].Sub_Category_Name,
                        value: subcategories[i].Sub_Category_Id
                    })
            }
        }
    }

    // for screen dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    React.useEffect(() => {
        dispatch(getAllProductmasterssearch("P-M-0014492"))
    }, []);
    useEffect(() => {
        if (!isEmpty(allItems)) {
            dispatch(getAllCategories(allItems?.Category_Id));
            dispatch(getAllSubCategories(allItems?.Sub_Category_Id));
        }
    }, [allItems]);
    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        container: {
            paddingTop: 15
        }, images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },

        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        phonetextInput: {
            flex: 1
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        countrydropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            left: 22,
            top: 0.5,
            color: esiColor.itemColor,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }} >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }
            >
                <Formik
                    initialValues={{
                        Product_For: 'FARMER',
                        Brand_Id: allItems?.Brand_Id,
                        Category_Id: allItems?.Category_Id,
                        Country_Of_Origin: "IN",
                        Created_User_Type: EmailID.User_Type,
                        Esi_Rating: '',
                        Esi_Review: "",
                        Features: allItems?.Features,
                        Is_Popular: false,
                        Is_Top_Product: false,
                        Is_Upcoming: false,
                        Is_Verified: false,
                        Product_Description: allItems?.Long_Description,
                        Product_Master_Id: "P-M-0014492",
                        Product_Name: "",
                        Product_Price: "",
                        Product_Short_Description: "",
                        Specifications: allItems?.Specifications,
                        Created_By_Email: EmailID.Email_Id,
                        Partner_Details_ID: EmailID.Partner_Details_Id,
                        Status: "Active",
                        Sub_Category_Id: allItems?.Sub_Category_Id,
                        Whats_Hot: false,
                    }}
                    //field validations
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        Category_Id: Yup.string().required('Category Name is required.'),
                        Product_Name: Yup.string().required('Product Name is required.'),
                        Product_Price: Yup.string().matches(/^\d+(\.\d{1,2})?$/, 'Please enter valued Product Price').required('Product Price is required.'),
                        Product_Short_Description: Yup.string().required('Short Description is required.'),
                        Sub_Category_Id: Yup.string().required('Sub Category Name is required.'),
                    })}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        setErrors(errors);
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            dispatch(productsdata(formValues, props.navigation));
                            navigation.navigate('AddFarmerProductspagetwo')
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        resetForm
                    }) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        resetForm();
                                        navigation.navigate('FarmerProductGet')
                                    }} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                        Add Product
                                    </Text>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        {/* Text input for product name */}
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput

                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Product_Name && errors.Product_Name)}
                                                label="Product Name"
                                                mode='outlined'
                                                value={values.Product_Name}
                                                onChangeText={handleChange("Product_Name")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Product_Name && errors.Product_Name)}>
                                                {touched.Product_Name && errors.Product_Name}
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Category_Id && errors.Category_Id) && { color: '#b00020' }]}>
                                                    Category Name
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Category_Id && errors.Category_Id) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={categoryopt}
                                                    labelField="label"
                                                    valueField="value"
                                                    maxHeight={categoryopt.length > 1 ? 250 : 200}
                                                    search={categoryopt.length > 1 ? true : false}
                                                    searchPlaceholder={'Select   Category Name'}
                                                    value={values.Category_Id}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Category_Id', item.value)
                                                        setShowDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Category_Id && errors.Category_Id)}>
                                                    {touched.Category_Id && errors.Category_Id}
                                                </HelperText>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        {/* Dropdown for Categorys  */}

                                        {/* Dropdown for subCategorys  */}
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id) && { color: '#b00020' }]}>
                                                    Sub Category
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={subcategorieopt}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder={!showDropDownsubcategory ? 'Select item' : '...'}
                                                    maxHeight={subcategorieopt.length > 1 ? 250 : 200}
                                                    search={subcategorieopt.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Sub Category'}
                                                    value={values.Sub_Category_Id}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Sub_Category_Id', item.value)
                                                        setShowDropDownsubcategory(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Sub_Category_Id && errors.Sub_Category_Id)}>
                                                    {touched.Sub_Category_Id && errors.Sub_Category_Id}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                            <TextInput

                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Product_Price && errors.Product_Price)}
                                                label="Product Price"
                                                mode='outlined'
                                                value={values.Product_Price}
                                                onChangeText={handleChange("Product_Price")}
                                                keyboardType='numeric'
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Product_Price && errors.Product_Price)}>
                                                {touched.Product_Price && errors.Product_Price}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        {/* TextInput for Product Short Description  */}
                                        <View style={{ flexGrow: 1, width: 200, flexShrink: 1 }}>
                                            <TextInput

                                                style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                                outlineColor={esiColor.TextOuterLine}
                                                underlineColor={esiColor.TextUnderline}
                                                activeOutlineColor={esiColor.TextOuterLine}
                                                error={Boolean(touched.Product_Short_Description && errors.Product_Short_Description)}
                                                label="Short Description"
                                                mode='outlined'
                                                value={values.Product_Short_Description}
                                                onChangeText={handleChange("Product_Short_Description")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Product_Short_Description && errors.Product_Short_Description)}>
                                                {touched.Product_Short_Description && errors.Product_Short_Description}
                                            </HelperText>
                                        </View>
                                    </View>
                                </Card.Content>
                                <View style={{ marginLeft: "1%" }}>
                                    <Text style={{ color: "red" }}>{errorMessage.global.message}</Text>
                                </View>
                                <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", marginVertical: 10 }}>
                                    <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                        Next
                                    </Button>
                                </View>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface >
    );
};

export default AddFarmerProducts;