import React, { useState } from 'react';
import { Card, Button, TextInput, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Text,  ScrollView, Dimensions,RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addshopperPhysicalPartnerAction } from '../../../state/actions/shoppers/shopersAction';
import Header from '../../auth/components/header';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import EsiDatePicker from '../../../components/custom/date/date';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddShopperProducts = (props: any) => {
    const { navigation } = props;
    const EmailID = useSelector(state => state.auth?.user);
    const dispatch = useDispatch();
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const [statusDropDown, setStatusDropDown] = useState("");
    const productid = props.route?.params?.shoppersproducts;
    //screen dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
      return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
      setRefreshing(true);
      wait(2000).then(() => setRefreshing(false));
    }, []);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });

    /*====================================
            Handler
    ====================================*/
    const values = {
        "Created_By": EmailID?.Email_Id,
        "Discount_End_Date": "",
        "Discount_In_Percentage": "",
        "Discount_Start_Date": "",
        "Max_Quantity": "",
        "Min_Quantity": "",
        "Partner_Details_Id": EmailID?.Partner_Details_Id,
        "Product_Id": productid,
        "Status": ""
    }
    //form Validations
    const Form_Validation = Yup.object().shape({
        Min_Quantity: Yup.number().required(' Min Quantity is required.'),
        Max_Quantity: Yup.number().required(' Max Quantity is required.'),
        Status: Yup.string().required('Status is required.'),
        Discount_In_Percentage: Yup.number().required(' Discount In Percentage is required.'),
        Discount_Start_Date: Yup.date().min(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000), "Require Discount Start Date greater than or equal to today.").required("Discount Start Date is required."),
        Discount_End_Date: Yup.date().when("Discount_Start_Date", (Discount_Start_Date, schema) => Discount_Start_Date && schema.min(Discount_Start_Date, "Discount End Date can't be before Discount Start Date.")).required("Discount End Date is required."),
    })
    const onSubmit = (values, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            formValues.Created_By = EmailID?.Email_Id
            formValues.Partner_Details_Id = EmailID?.Partner_Details_Id
            dispatch(addshopperPhysicalPartnerAction(formValues, props.navigation, toast));
        }
    }

    return (
        <Surface style={{
            flexShrink: 1
        }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  colors={['#27B6CC']}
                  tintColor={'#27B6CC'}
                  title={"Pull to refresh"}
                  titleColor={"#27B6CC"}
                />
              } >
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }} onPress={() => navigation.navigate('ShoppersGet')} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, borderColor: '#d6d6d7' }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Add Shopper products
                                    </Text>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: "white" }}
                                                    error={Boolean(props.touched.Min_Quantity && props.errors.Min_Quantity)}
                                                    label="Min Quantity"
                                                    mode='outlined'
                                                    value={props.values.Min_Quantity}
                                                    onChangeText={props.handleChange("Min_Quantity")}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Min_Quantity && props.errors.Min_Quantity)}>
                                                    {props.touched.Min_Quantity && props.errors.Min_Quantity}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <TextInput
                                                    style={{ backgroundColor: "white" }}
                                                    error={Boolean(props.touched.Max_Quantity && props.errors.Max_Quantity)}
                                                    label="Max Quantity"
                                                    mode='outlined'
                                                    value={props.values.Max_Quantity}
                                                    onChangeText={props.handleChange("Max_Quantity")}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Max_Quantity && props.errors.Max_Quantity)}>
                                                    {props.touched.Max_Quantity && props.errors.Max_Quantity}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                <TextInput
                                                    style={{ backgroundColor: "white" }}
                                                    error={Boolean(props.touched.Discount_In_Percentage && props.errors.Discount_In_Percentage)}
                                                    label="Discount In Percentage"
                                                    mode='outlined'
                                                    value={props.values.Discount_In_Percentage}
                                                    onChangeText={props.handleChange("Discount_In_Percentage")}
                                                />
                                                <HelperText type="error" visible={Boolean(props.touched.Discount_In_Percentage && props.errors.Discount_In_Percentage)}>
                                                    {props.touched.Discount_In_Percentage && props.errors.Discount_In_Percentage}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <EsiDatePicker
                                                    label={'Discount Start Date'}
                                                    style={{ width: "100%" }}
                                                    value={props.values.Discount_Start_Date}
                                                    valueUpdate={date => {
                                                        props.setFieldValue('Discount_Start_Date', date)
                                                    }} />

                                                <HelperText type="error" visible={Boolean(props.touched.Discount_Start_Date && props.errors.Discount_Start_Date)}>
                                                    {props.touched.Discount_Start_Date && props.errors.Discount_Start_Date}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                                                <EsiDatePicker
                                                    label={'Discount End Date'}
                                                    style={{ width: "100%" }}
                                                    value={props.values.Discount_End_Date}
                                                    valueUpdate={date => {
                                                        props.setFieldValue('Discount_End_Date', date)
                                                    }} />

                                                <HelperText type="error" visible={Boolean(props.touched.Discount_End_Date && props.errors.Discount_End_Date)}>
                                                    {props.touched.Discount_End_Date && props.errors.Discount_End_Date}
                                                </HelperText>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                <View style={{ width: 322, flexShrink: 1, paddingTop: 15 }}>
                                                    {/* Status input field with validation*/}
                                                    <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                                                        Status
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={StatusOption}
                                                        placeholder={!statusDropDown ? '-- Please Select --' : ''}
                                                        labelField="label"
                                                        valueField="value"
                                                        maxHeight={StatusOption?.length > 1 ? 250 : 200}
                                                        search={StatusOption?.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Status'}
                                                        dropdownPosition="auto"
                                                        value={props.values.Status}
                                                        name="Status"
                                                        onFocus={() => setStatusDropDown(true)}
                                                        onBlur={() => setStatusDropDown(false)}
                                                        onUpdateValue={item => {
                                                            props.setFieldValue('Status', item.value)
                                                            setStatusDropDown(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                                                        {props.touched.Status && props.errors.Status}
                                                    </HelperText>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ alignItems: "center" }}>
                                            <Button style={{ width: '30%', backgroundColor: '#27B6CC' }}
                                                mode="outlined"
                                                onPress={props.handleSubmit}
                                            >
                                                <Text style={{ fontWeight: 'bold', color: "white" }}>Save  </Text>
                                            </Button>
                                        </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface>
    );
};
const styles = StyleSheet.create({
    checkbox: {
        flexDirection: "row",
        justifyContent: 'flex-start'
    },
    label: {
        marginBottom: 30,
    },
    editor: {
        backgroundColor: "black",
        borderColor: "black",
        borderWidth: 1,
    },
    rich: {
        minHeight: 130,
    },
    richBar: {
        height: 50,
        backgroundColor: "#F5FCFF",
    },
    text: {
        fontWeight: "bold",
        fontSize: 18,
    },
    tib: {
        textAlign: "center",
        color: "#515156",
    },
    a: {
        fontWeight: "bold",
        color: "purple",
    },
    div: {
        fontFamily: "monospace",
    },
    p: {
        fontSize: 30,
    }, container: {
        backgroundColor: 'white',
        paddingTop: 15
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});


export default AddShopperProducts;