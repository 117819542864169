import axios from 'axios';
import config from './config';

export const TRANSACTIONS_REQUEST = "TRANSACTIONS_REQUEST";
export const TRANSACTIONS_SUCCESS = "TRANSACTIONS_SUCCESS";
export const TRANSACTIONS_FAIL = "TRANSACTIONS_FAIL";

export const TRANSACTION_GET_REQUEST = "TRANSACTION_GET_REQUEST";
export const TRANSACTION_GET_SUCCESS = "TRANSACTION_GET_SUCCESS";
export const TRANSACTION_GET_FAIL = "TRANSACTION_GET_FAIL";

export const TRANSACTION_UPDATE_REQUEST = "TRANSACTION_UPDATE_REQUEST";
export const TRANSACTION_UPDATE_SUCCESS = "TRANSACTION_UPDATE_SUCCESS";
export const TRANSACTION_UPDATE_FAIL = "TRANSACTION_UPDATE_FAIL";

export const TRANSACTIONS_FILTER = "TRANSACTIONS_FILTER";

const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
/*=============================================================
                   Get All Transactions Action
===============================================================*/

export const getAllTransactionAction = (formData: any) => async dispatch => {

    try {
        dispatch({
            type: TRANSACTIONS_REQUEST
        });
        let { data } = await axios.post(
            `${config.url}/Partner_Transaction/Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
            formData,
            {
                headers: headers
            }
        );
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: TRANSACTIONS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: TRANSACTIONS_SUCCESS,
                    payload: data.Transactions
                });
            }
        }
    } catch (err) {
        dispatch({
            type: TRANSACTIONS_FAIL,
            payload: err
        });
    }
};

/*=============================================================
                 Update partner payment Action
===============================================================*/

export const updateTransactionAction = (formData: any, transaction: any, navigation: any, toast: any) => async dispatch => {
    dispatch({
        type: TRANSACTION_UPDATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/Partner_Transaction/Update`, formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: TRANSACTION_UPDATE_SUCCESS
                });
                navigation.navigate('TransactionhistoryGet')
                toast.show({ message: 'Partner transaction updated successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    Records_Filter: "ALL",
                    Partner_Id: transaction.Partner_Detail_Id,
                };
                dispatch(getAllTransactionAction(formValue))
            } else {
                const errors = {};
                dispatch({
                    type: TRANSACTION_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: TRANSACTION_UPDATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


// Filter 
export const TransactionFilter = (fType: any, value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: TRANSACTIONS_FILTER,
            fType: fType,
            value: value,
        });
    }
}

//Search
export const resetFilter = () => {
    return function (dispatch: any) {
      dispatch({
        type: TRANSACTIONS_FILTER,
       fType: "search", 
        value: "",
      });
    }
  }


export const getTransactionByIdAction = (formData: any, callBackData: any) => async dispatch => {
    try {
      dispatch({
        type: TRANSACTIONS_REQUEST
      });
      let { data, status } = await axios.post(
        `${config.url}/Partner_Transaction/Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`,
        formData,
        {
          // Axios looks for the `auth` option, and, if it is set, formats a
          // basic auth header for you automatically.
          headers: headers
        }
      );
      if (data) {
        callBackData(data.Transactions)
      }
    } catch (err) {
      dispatch({
        type: TRANSACTIONS_FAIL,
        payload: err
      });
    }
  };