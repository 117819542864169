import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { addBrandAction, uploadBrandImage } from '../../state/actions/brandAction';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, RefreshControl, Platform } from 'react-native';
import Header from '../auth/components/header';
import ImageUpload from '../../components/custom/image-upload/upload';
import EsiEditor from '../../components/custom/richtext-editor/richtext-editor';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { uriToBlob } from '../../components/custom/uriBlob';
import { imageORfileupload } from '../../state/actions/commonImageOrFileUpload';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddBrand = ({ navigation }: { navigation: any }) => {

  //toast for popup after successful API calls.

  const toast = useToast();
  const esiColor = useSelector(state => state.theme);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const EmailID = useSelector(state => state.auth?.user);

  const [errorMessage, setErrorMessage] = useState({ Brand_Name: { key: '', message: '' }, global: { key: '', message: '' } });
  const isSubmited = useSelector(state => state.brand.brandCreate.isLoading);
  const [rhDiscriptionMin, setRhDiscriptionMin] = useState(20);

  const [descriptionvalue, setDescriptionValue] = React.useState('');

  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  //Screen dimension calculting call.

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  // img upload

  const [image, setImage] = useState({ preview: '', raw: '' });
  const [imgUrl, setImgUrl] = useState('');
  const [uploadPic, setUploadPic] = useState(false);

  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);

  React.useEffect(() => {
    if (!(permissions.Brands_Management === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);

  const callBackUploadImage = (data: any) => {
    if (data) {
      setImgUrl(data.File_URL);
    }
    setUploadPic(false);
  };
  const handleImage = async (result: any) => {
    let call = result.uri
    if (result) {
      let exe = "img";
      let uriParts = result.uri.split('.');
      if (Platform.OS == "web") {
        let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
        let filformate = fileExtension[0].split(':')
        exe = filformate[1].split('/')[1]
      } else {
        exe = uriParts[uriParts.length - 1]
      }
      let fileData = {
        File_name: "Brand",
        Functionality: "Brands",
        ext: exe[1],
        File_Type: "Images",
      };
      const img = await fetchImageFromUri(call);
      setImage({
        preview: result.uri,
        raw: img
      });
      dispatch(
        imageORfileupload(img, fileData, callBackUploadImage)
      );
    }
  };
  const fetchImageFromUri = async (uri) => {
    const blob = await uriToBlob(uri);
    return blob;
  };
  const handleRemoveImage = e => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
  };
  const setDescriptionValues = () => {
    setImgUrl('');
    setImage({
      preview: '',
      raw: ''
    });
    setDescriptionValue('')
  };

  const dispatch = useDispatch();

  //form reset
  const [reload, setReload] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])

  /*====================================
          Handler
  ====================================*/

  const values = {
    Brand_Name: "",
    Brand_Link: "",
    Brand_Description: "",
    Brand_Image: "",
    Brand_User_Rating: "",
    Brand_Is_Popular: false,
    Brand_Today_Hot: false,
    Is_Top_Brand: false,
    Created_By: "",
    Brand_Status: "In-Active",
    Brand_Esi_Reviews: '',
    Brand_Esi_Rating: "0",
  }

  const Form_Validation = Yup.object().shape({
    Brand_Name: Yup.string().matches(/^[a-zA-Z][\sa-zA-Z]*/, "Please remove special characters & white spaces at the beginning for Name.").min(2, "Brand Name should have atleast 2 characters.").notOneOf([errorMessage.Brand_Name.key, null], errorMessage.Brand_Name.message).required("Brand Name is required."),
    Brand_Link: Yup.string().matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
      'Please provide valid link.'
    ).required('Brand Link is required.'),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors, resetForm }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};

    values.Brand_Image = imgUrl;
    values.Brand_Description = descriptionvalue;
    if (!values.Brand_Image) {
      submitTest = false;
      errors.Brand_Image = "Image is required.";
    }
    if (!values.Brand_Description || values.Brand_Description.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
      submitTest = false;
      errors.Brand_Description = "Description is required.";
    }
    else if (values.Brand_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) {
      submitTest = false;
      errors.Brand_Description = "Please provide at least " + rhDiscriptionMin + " characters.";
    }
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Brand_Is_Popular) {
        formValues.Brand_Is_Popular = 1;
      } else {
        formValues.Brand_Is_Popular = 0;
      }
      if (values.Brand_Today_Hot) {
        formValues.Brand_Today_Hot = 1;
      } else {
        formValues.Brand_Today_Hot = 0;
      }
      if (values.Is_Top_Brand) {
        formValues.Is_Top_Brand = 1;
      } else {
        formValues.Is_Top_Brand = 0;
      }
      formValues.Created_By = EmailID.Email_Id;
      formValues.Brand_Status = "In-Active",
        setImage({ preview: '', raw: '' })
      resetForm();
      setDescriptionValues()
      setImgUrl('');
      setDescriptionValue('')
      dispatch(addBrandAction(formValues, navigation, setErrorMessage, setErrors, toast));
    }
  }

  const styles = StyleSheet.create({
    cardCircle: {
      width: (window.width >= 500) ? "75%" : "97%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "2%",
      marginBottom: "2%",
      borderRadius: 6,
      borderWidth: 0.01,
      borderColor: esiColor.brandBorderColor,
      backgroundColor: esiColor.BackgroundColor
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },

  });
  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };
  return (
    <Surface style={{
      flex: 1,backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation}>
          {(props) => (
            <View style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}>
              <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                <Button icon="keyboard-backspace" textColor={esiColor.GBFColor} style={{ width: 150 }}
                  onPress={() => {
                    setReload(true);
                    setImage({ preview: '', raw: '' })
                    // resetForm();
                    setDescriptionValue(" ")
                    setImgUrl('');
                    navigation.navigate('BrandsGet')
                  }}>
                  Go Back
                </Button>
              </View>
              <Card style={styles.cardCircle}>
                <Card.Content>
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                    Add Brand
                  </Text>
                  <View style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                  }}>
                    <View style={{ flexShrink: 1, width: 770 }}>
                      <TextInput
                        theme={esiColor.pagination_theme}
                        // theme={{ colors: { text: esiColor.Text } }}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(props.touched.Brand_Name && props.errors.Brand_Name)}
                        label='Brand Name'
                        mode='outlined'
                        value={props.values.Brand_Name}
                        onChangeText={props.handleChange('Brand_Name')}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Brand_Name && props.errors.Brand_Name)}>
                        <ErrorMessage name="Brand_Name" />
                      </HelperText>
                      <EsiEditor label={"Description"} value={descriptionvalue} onUpdateValue={(value) => { setDescriptionValue(value) }} />
                      <HelperText type="error" visible={(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Brand_Description && props.errors.Brand_Description}>
                        {(!descriptionvalue || descriptionvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDiscriptionMin) && props.touched.Brand_Description && props.errors.Brand_Description}
                      </HelperText>
                      <View style={{
                        flexDirection: "row",
                        paddingTop: 10,
                        flexWrap: "wrap",
                        justifyContent: "space-between"
                      }}>
                      </View>
                      <TextInput
                        // theme={text : esiColor.Text}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        label='Brand Link'
                        mode='outlined'
                        value={props.values.Brand_Link}
                        onChangeText={props.handleChange('Brand_Link')}
                        error={Boolean(props.touched.Brand_Link && props.errors.Brand_Link)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Brand_Link && props.errors.Brand_Link)}>
                        <ErrorMessage name="Brand_Link" />
                      </HelperText>
                    </View>
                    <View />
                    <View style={{ flexShrink: 1, width: 270, paddingTop: 20 }} >
                      <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: esiColor.TextOuterLine }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                      <HelperText type="error" visible={!imgUrl && props.touched.Brand_Image && props.errors.Brand_Image}>
                        {!imgUrl && props.touched.Brand_Image && props.errors.Brand_Image}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                    <Button disabled={isSubmited || uploadPic} textColor={esiColor.itemButtenColor} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                      Save
                    </Button>
                  </View>
                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface>

  );
};

export default AddBrand;