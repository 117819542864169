import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import EsiCheckboxTree from '../../../components/custom/checkbox-tree';
import { changeMainData } from '../../../state/actions/shoppers/filterServiceActions';


export default function SortByPage(props) {
  const { navigation } = props;
  let sortByOptionsInit = useSelector((state) => state.filters.main);
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  React.useEffect(() => {
  }, [props.state.routes[0].params])

  const checkChildChecked = (data: any) => {
    let count = 0;
    data.map((item: any) => {
      if (item.checked === "checked") {
        count++
      }
    })
    return (count === data.length && data.length > 0) ? 'checked' : count === 0 ? "unchecked" : "indeterminate";
  }

  const checkChildChange = (data: any, status: string) => {
    let pageTest = props.state.routes[0].params.page
    let key = data.key;
    let page = null;
    if (key === '1') {
      page = 'products';
    } else if (key === '2') {
      page = 'coupons';
    } else if (key === '3') {
      page = 'deals';
    }
    if (page != pageTest) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'All',
            params: { page: page },
          },
        ],
      })
    }
    let response = JSON.parse(JSON.stringify(sortByOptionsInit))
    response.filter((item: any) => { return item.key === key })[0].children.map((item: any) => {
      if (status === 'unchecked' || status === 'indeterminate') {
        item.checked = "checked";
      } else {
        if (item.value === 'Is_Top_Product' || item.value === 'Is_Top_Coupon' || item.value === 'Is_Top_Deal') {
          item.checked = "checked";
        } else {
          item.checked = "unchecked";
        }
      }
    })
    response.filter((item: any) => { return item.key != key }).map((children: any) => {
      children.children.map((item: any) => {
        item.checked = "unchecked";
      })
    })
    dispatch(changeMainData(response));
  }

  const changeChecked = (data: any, status: string) => {
    let key = data.key;
    let keys = key.split('-');
    let response = JSON.parse(JSON.stringify(sortByOptionsInit))
    if (keys.length === 1) {
      response.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
    } else if (keys.length === 2) {
      let pageTest = props.state.routes[0].params.page
      let page = null;
      if (keys[0] === '1') {
        page = 'products';
      } else if (keys[0] === '2') {
        page = 'coupons';
      } else if (keys[0] === '3') {
        page = 'deals';
      }
      if (page != pageTest) {
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'All',
              params: { page: page },
            },
          ],
        })
      }
      response.filter((item: any) => { return item.key === keys[0] })[0].children.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
      response.filter((item: any) => { return item.key != keys[0] }).map(children => {
        children.children.map((item: any) => { item.checked = "unchecked" });
      });
      if (status === "checked") {
        if (response.filter((item: any) => { return item.key === keys[0] }).filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).length <= 0) {
          response.filter((item: any) => { return item.key === keys[0] })[0].children.map((item: any) => {
            if (item.value === 'Is_Top_Product' || item.value === 'Is_Top_Coupon' || item.value === 'Is_Top_Deal') {
              item.checked = "checked";
            }
          })
        }
      }

    }
    dispatch(changeMainData(response));
  }

  const updateOptions = ((data: any) => {
    dispatch(changeMainData(data));
  })

  return (
    <View style={{
      marginTop: 5,
      backgroundColor: esiColor.BackgroundColor
    }}>
      <View style={{ marginLeft: 10, flexDirection: 'row' }}>
        <View>
        </View>
        <View>
          <Text style={{ fontSize: 14,color: esiColor.itemColor, marginTop: 7 }} >Sort By</Text>
        </View>


      </View>
      <EsiCheckboxTree
        sortByOptionsInit={sortByOptionsInit}
        updateOptions={updateOptions}
        checkChildChange={checkChildChange}
        changeChecked={changeChecked}
        {...props} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 5
  }
});
