import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderHTML from "react-native-render-html";
import Modal from "react-native-modal";
import { Avatar, Text, TextInput, Surface, TouchableRipple, IconButton, Colors, Card, HelperText } from "react-native-paper";
import { View, TouchableOpacity, Image, useWindowDimensions, ScrollView, StyleSheet, Dimensions, RefreshControl } from "react-native";
import { Rating } from "react-native-ratings";
import { Ionicons } from "@expo/vector-icons";
import { addPartnerReviewAction, editPartnerReviewAction, getPartnersByIdAction, getUserReviewsbyIdAction } from "../../../state/actions/shoppers/shopperpartnerActions";
import { useToast } from "../../../components/custom/react-native-paper-toast/src";
import isEmpty from "../../../state/validations/is-empty";
import SignIN from "../../auth/signin";
import Header from "../header/header";
import { getPartnersAction } from "../../../state/actions/node-actions/home-actions";
import MeeProducts from "./MeeProducts";

// api
export default function PartnerDetailsb2b(props) {
    const { navigation } = props;
    const toast = useToast();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const [visible, setVisible] = useState(10);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [rating, setRating] = useState();
    //comment param is used here to take the value from the Comment text box
    const [comment, setComment] = useState("");
    const partnerId = props?.route?.params?.Partner_Details_Id;
    let Partner = useSelector((state) => state.allPartners.partner.all);
    let partnerReviews = useSelector((state) => state.allPartners.partnerReviews.all);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const user = useSelector(state => state.auth?.user?.Email_Id);
    //reviewId is used to get the Particular Review ID dynamically
    const [reviewId, setData] = useState()
    //setCommentError param is used here to display validation error message
    const [commenterror, setCommentError] = useState("")
    const [error, setError] = useState("")

    //For add review
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);

    //For edit review
    const [setEditModalVisible] = React.useState(false);
    const [isEditReviewModalVisible, setEditReviewModalVisible] = React.useState(false);
    const PartnerReviews = () => {
        let existcomment;
        let UserData = [];
        if (partnerReviews?.length > 0) {
            existcomment = partnerReviews.filter(review => review.Partner_Email_Id === user).length
            UserData = partnerReviews.filter(review => review.Partner_Email_Id === user ? review : "")
        }
        return [existcomment, UserData]
    }
    const [commentvalidation, uservalidation] = PartnerReviews();

    //displaying Signin pop up
    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };
    //For Add review
    const toggleModal = () => {
        if (isAuthenticated) {
            setReviewModalVisible(!isReviewModalVisible);
        }
        else {
            setModalVisible(true);
        }

    };
    //For edit review
    const editToggleModal = () => {
        if (isAuthenticated) {
            setEditReviewModalVisible(!isEditReviewModalVisible);
        }
        else {
            setEditModalVisible(true);
        }

    };
    const addReviewModal = (formData) => {
        formData.Email_ID = user;
        formData.Reviewer_Email_ID = user;
        let formValues = JSON.parse(JSON.stringify(formData));
        dispatch(addPartnerReviewAction(formValues, partnerId, toast));
        setReviewModalVisible(false);
    }
    const editReviewModal = () => {
        if (commenterror === "") {
            let editData = {
                Partner_Details_Id: partnerId,
                Partner_Review_Id: reviewId,
                Rating: rating,
                Comments: comment,
                Email_ID: user,
                Reviewer_Email_Id: user,
                Modified_By: user,
                Status: "Active"
            }
            let formValues = JSON.parse(JSON.stringify(editData));
            dispatch(editPartnerReviewAction(formValues, partnerId));
            toast.show({ message: 'Review updated successfully.', type: 'info', duration: 3000, position: 'top' });
            setEditReviewModalVisible(!isEditReviewModalVisible);
            setComment("")
            setRating("")
        }
    }
    // Comment validations
    const handleOnChange = (value) => {
        if (commentvalidation > 0) {
            setError("You have already added review.")
        }
        if (value === "") {
            setError("")
        }
    }
    // Edit Onchanges
    const editOnChange = (value) => {
        if (value === "") {
            setCommentError("Review is required.")
        } else {
            setCommentError("")
        }
    }
    const [partnerdata, setPartnerData] = useState();
    const callBackGetPartnerData = (status, Partner) => {
        if (status) {
            setPartnerData(Partner[0]);
        }
    };
    useEffect(() => {
        onRefresh();
    }, [partnerId]);


    const onRefresh = () => {
        setVisible(10);
        dispatch(getPartnersAction({
            Partner_Details_Id: partnerId,
            Records_Filter: "FILTER",
            Status: 'Active'
        },
            { PageNo: 1, PageSize: 10, SortBy: "PD.PARTNER_DETAILS_ID", SortOrder: "ASC" }
            , callBackGetPartnerData));
        dispatch(getUserReviewsbyIdAction(partnerId));
    };
    // Tost message code.
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const esiColor = useSelector(state => state.theme);

    const styles = StyleSheet.create({
        // root: {
        //     backgroundColor: "#ffffff",
        //     flex: 1
        // },
        container: {
            paddingLeft: 15,
            paddingVertical: 10,
            marginRight: 15,
            flexDirection: 'row',
            alignItems: 'flex-start'
        },
        content: {
            marginLeft: 16,
            flex: 1,
        },
        contentHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        image: {
            width: 45,
            height: 45,
            borderRadius: 20,
        },
        // time: {
        //     fontSize: 11,
        //     color: "#808080",
        // },
        name: {
            fontSize: 16,
            fontWeight: "bold",
            color: esiColor.DescColor
        },
        // container1: {
        //     flex: 2,
        //     padding: 10,
        // },
        // containerStyle:
        // {
        //     backgroundColor: 'white',
        //     padding: 20,
        //     borderColor: 'black',
        //     borderWidth: 1,
        // },
        containerm: {
            paddingLeft: 19,
            paddingRight: 16,
            paddingVertical: 12,
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginLeft: 1
        },
        centerElement: {
            justifyContent: 'center',
            alignItems: 'center'
        },
    });

    const PartnerAddRiview = ({ partnerId, responseFunction }) => {
        const [error, setError] = useState("");
        const [rating, setRating] = useState(0);
        const [ratingError, setRatingError] = useState(false);
        const [comment, setComment] = useState("");
        const [commenterror, setCommentError] = useState("");
        // Comment validations
        const handleOnChange = (value) => {
            setComment(value);
            if (value) {
                if (value.length < 10) {
                    setCommentError("Review should be greater than 10 characters.");
                } else {
                    setCommentError("");
                }
            } else {
                setCommentError("Review is required.");
            }
        }

        const addReviewModal = () => {
            let error = false;
            if (comment === "") {
                setCommentError("Review is required.");
                error = true;
            } else {
                if (comment.length < 10) {
                    setCommentError("Review should be greterthen ten.");
                    error = true;
                } else {
                    setCommentError("");
                }
            }
            if (rating <= 0) {
                setRatingError(true);
                error = true;
            } else {
                setRatingError(false);
            }


            if (!error) {
                let formData = {
                    Partner_Details_Id: partnerId,
                    Rating: rating,
                    Comments: comment,
                    Status: "Active"
                }
                responseFunction(formData);
            }
        }

        return (
            <View style={{ margin: '4%' }}>
                <View style={{ flexDirection: "row", justifyContent: "center" }}>
                    <TextInput
                        // style={{ }}
                        activeUnderlineColor={esiColor.TextOuterLine}
                        selectionColor={esiColor.TextSelection}
                        theme={{
                            colors: {
                                primary: esiColor.TextHighlight,
                                text: esiColor.Text,
                                placeholder: esiColor.TextPlaceholder,
                            }
                        }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        label='Add Review'
                        mode='outlined'
                        onChangeText={(Text) => { handleOnChange(Text); }}
                        multiline={true}
                        numberOfLines={4}
                        value={comment}
                        style={{ maxHeight: 100, minWidth: 250, maxWidth: 250, marginHorizontal: "auto", backgroundColor: esiColor.TextBC }}
                    />
                </View>
                <HelperText>
                    {commenterror &&
                        <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>
                    }
                </HelperText>
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center'
                }}>
                    <Rating
                        tintColor={esiColor.TintColor}
                        type='heart'
                        startingValue={rating || 0}
                        ratingCount={5}
                        imageSize={30}
                        style={{ paddingVertical: 10 }}
                        onFinishRating={(value) => { setRating(value); setRatingError(false) }}
                    />
                    <HelperText>
                        {ratingError &&
                            <Text style={{ color: "red" }}>
                                Please select the Rating.
                            </Text>
                        }
                    </HelperText>
                    <View style={{ width: 100 }}>
                        <TouchableOpacity style={[styles.centerElement, { backgroundColor: esiColor.globalButtonColor, width: 100, height: 25, borderRadius: 5 }]} onPress={() => addReviewModal()}>
                            <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }


    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ flexDirection: "column", width: "100%" }} >
                <View style={{ flexDirection: "row", marginHorizontal: '2%' }} >
                    <TouchableOpacity style={{ flex: 3, marginLeft: 8, alignSelf: "flex-start" }} onPress={() => navigation.navigate('Partnersb2b')}>
                        <Ionicons name="arrow-back" size={25} color={esiColor.SIconColor} style={{ marginTop: 10 }} />
                    </TouchableOpacity>
                    <View style={{ zIndex: 1, flex: 3 }}>
                        {partnerdata?.Is_Open != "1" &&
                            <Text style={{ fontSize: 14, paddingHorizontal: 10, paddingVertical: 5, backgroundColor: '#DC143C', borderRadius: 5, color: 'white', marginTop: 10, marginVertical: 5, fontWeight: "bold", alignSelf: "center" }}> Store Closed</Text>
                        }
                    </View>
                    <View style={{ flex: 3 }}>
                        <Rating
                            readonly={true}
                            rating={partnerdata?.Esi_Rating ? parseInt(partnerdata?.Esi_Rating) : 0}
                            tintColor={esiColor.TintColor}
                            type='heart'
                            startingValue={partnerdata?.Esi_Rating ? parseInt(partnerdata?.Esi_Rating) : 0}
                            ratingCount={5}
                            imageSize={20}
                            style={{ paddingVertical: 5, alignSelf: "flex-end", marginTop: 8, marginRight: 5 }}
                            minValue={1}
                            onFinishRating={(rating) => ratingCompleted(rating)}
                            name="rating"
                        />
                    </View>
                </View>
            </View>
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={false}
                        onRefresh={onRefresh}
                    />
                }
                style={{ marginHorizontal: '2%' }}>

                {/* <Provider> */}
                <Card style={{ backgroundColor: esiColor.BackgroundColor }} >
                    <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }} >
                            <Image source={{ cache: "force-cache", uri: partnerdata?.Logo_Path }} style={{ maxHeight: 150, minHeight: 100, width: dimensions.width * 0.9, borderRadius: 8 }} resizeMode={'stretch'}></Image>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "center", marginVertical: 2 }} >
                            <Text style={{ fontSize: 20, color: esiColor.brandFontColor }}>{partnerdata?.Name}</Text>
                        </View>
                    </View>

                    <View style={{ flex: 1, marginLeft: '3%', marginRight: '3%', backgroundColor: esiColor.RTBC }}>
                        {partnerdata?.Short_Description &&
                            <RenderHTML
                                contentWidth={width}
                                source={{ html: partnerdata?.Short_Description }} />}
                    </View>
                    <View>
                        <View style={{ marginLeft: '3%', flex: 1, marginRight: '3%', backgroundColor: esiColor.RTBC }}>
                            {partnerdata?.Description &&
                                <RenderHTML
                                    contentWidth={width}
                                    source={{ html: partnerdata?.Description }} />}
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginVertical: 5 }}>
                        <View style={{ flexDirection: 'row', marginLeft: 10 }}>
                            <Text style={{ color: esiColor.DescColor }}>GST: </Text>{!isEmpty(partnerdata?.Gst) ? <Text style={{ color: esiColor.itemColor }}>
                                {partnerdata?.Gst.substring(0, 2) + "*********" + partnerdata?.Gst.substring(partnerdata?.Gst.length - 2, partnerdata?.Gst.length)}
                            </Text>
                                : <Text style={{ color: esiColor.itemColor }}>**************</Text>}
                        </View>
                        <View style={{ flexDirection: 'row', marginLeft: 15 }}>
                            <Text style={{ color: esiColor.DescColor }}>PAN: </Text>{!isEmpty(partnerdata?.Pan) ?
                                <Text style={{ color: esiColor.itemColor }}>
                                    {partnerdata?.Pan.substring(0, 2) + "*********" + partnerdata?.Pan.substring(partnerdata?.Pan.length - 2, partnerdata?.Pan.length)}
                                </Text>
                                : <Text style={{ color: esiColor.itemColor }}>**************</Text>}
                        </View>
                    </View>

                </Card>


                <Card style={{ marginVertical: 5, backgroundColor: esiColor.BackgroundColor }} >
                    <View style={{ flexDirection: 'row', justifyContent: "space-around", zIndex: 100, marginVertical: 10 }}>
                        <View>
                            <TouchableOpacity disabled={partnerdata?.Product_Count == '0'} onPress={(e) => {

                                props.navigation.navigate('PartnerDetailsb2b', { Partner_Details_Id: partnerId, back: "PartnerDetailsb2b" });
                            }}>
                            </TouchableOpacity>
                        </View>
                    </View>

                </Card>
                {partnerdata?.Partner_Products_Count != '0' &&
                    <View style={{ marginVertical: 5 }}>
                        <MeeProducts navigation={props.navigation} partnerId={partnerdata?.Partner_Details_Id} />
                    </View>}
                <View style={{ fontSize: 20, width: 100, alignSelf: 'center', marginBottom: 10 }}>
                    <TouchableOpacity style={[styles.centerElement, { backgroundColor: commentvalidation > 0 ? 'grey' : esiColor.globalButtonColor, width: 100, height: 30, borderRadius: 5 }]}
                        onPress={() => toggleModal()} disabled={commentvalidation > 0 ? true : false}>
                        <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold' }}>Add Review</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.container}>
                    <Image style={styles.image} source={require("../../../assets/images/img/logo/meePaisa_logo.png")} />
                    <View style={styles.content}>
                        <View style={styles.contentHeader}>
                            <Text style={styles.name}>meepaisa Review</Text>
                            <View style={{ marginTop: -8 }}>
                                <Rating
                                    rating={partnerdata?.Esi_Rating ? (parseInt(partnerdata.Esi_Rating)) : (0)}
                                    numberOfStars={5}
                                    ratingCount={5}
                                    imageSize={20}
                                    style={{ paddingVertical: 10 }}
                                    readonly={true}
                                    startingValue={partnerdata?.Esi_Rating}
                                    tintColor={esiColor.TintColor}
                                    type='heart'
                                    onFinishRating={(rating) => ratingCompleted(rating)} />
                            </View>
                        </View>
                        <View style={{ backgroundColor: esiColor.RTBC, flexWrap: "wrap", flex: 1 }}>
                            {partnerdata?.Esi_Reviews &&
                                <RenderHTML source={{ html: partnerdata?.Esi_Reviews }} />}
                        </View>
                    </View>
                </View >
                <View>
                    {!isEmpty(partnerReviews) && (
                        partnerReviews.Is_Data_Exist === "0" || isEmpty(partnerReviews) ? (
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                <Text style={{ color: esiColor.NoDataFound }}>No Partner Reviews found!</Text>
                            </View>
                        ) : typeof partnerReviews === "object" && (
                            <>
                                {partnerReviews.slice(0, visible).map((data, index) => (
                                    <View key={index}>
                                        <View />
                                        <View style={styles.containerm}>
                                            <TouchableOpacity>
                                                {data?.Profile_Pic_Path ?
                                                    <Image style={styles.image} source={{ cache: "force-cache", uri: data.Profile_Pic_Path }} /> :
                                                    <Image style={styles.image} source={require("../../../assets/images/img/logo/meePaisa_logo.png")} />
                                                }

                                            </TouchableOpacity>
                                            <View style={styles.content}>
                                                <View style={styles.contentHeader}>
                                                    <Text style={styles.name}>{data.Full_Name}
                                                    </Text>
                                                    {/* <View style={{}}>
                                                        {user && user === data?.Reviewer_Email_Id ? (
                                                            <IconButton icon="pencil" style={{ marginTop: -4 }} size={20} onPress={() => { editToggleModal(); setRating(parseInt(data.Rating)); setComment(data.Comments); setData(data.Partner_Review_Id); }} />
                                                        ) : (null)}
                                                    </View> */}
                                                    <View style={{ marginTop: -8 }}>
                                                        <Rating
                                                            readonly={true}
                                                            tintColor={esiColor.TintColor}
                                                            type='heart'
                                                            startingValue={data?.Rating}
                                                            ratingCount={5}
                                                            imageSize={20}
                                                            onFinishRating={(rating) => ratingCompleted(rating)}
                                                            style={{ paddingVertical: 10 }}
                                                        />

                                                    </View>
                                                </View>
                                                <View style={{ backgroundColor: esiColor.RTBC, flexWrap: "wrap", flex: 1 }}>
                                                    {data.Comments &&
                                                        <RenderHTML
                                                            source={{ html: data.Comments }}
                                                        />}
                                                </View>
                                            </View>
                                        </View>

                                    </View>
                                ))}
                                <View style={{ alignSelf: 'center', marginVertical: 15, marginTop: 2 }}>
                                    {partnerReviews?.length > visible &&
                                        <Text variant="contained" mode="outlined" style={{ fontWeight: 'bold', color: '#27b6cc' }} onPress={() => setVisible(visible + 10)}>Show More ▼</Text>
                                    }
                                </View>
                            </>
                        ))}
                </View>
                {/* </Provider> */}
            </ScrollView>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 1, shadowRadius: 2 }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            {/* For Add Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isReviewModalVisible}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 300, borderRadius: 10,
                    backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5
                }}>
                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => setReviewModalVisible(false)}>
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <PartnerAddRiview responseFunction={addReviewModal} partnerId={partnerId} />
                </View>
            </Modal>
            {/* For Edit Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isEditReviewModalVisible}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 300,
                    borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, borderWidth: 0.5,
                    shadowOpacity: 1, shadowRadius: 5
                }}>
                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setEditReviewModalVisible(false), setComment(''), setRating(0) }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                            <TextInput
                                activeUnderlineColor={esiColor.TextOuterLine}
                                selectionColor={esiColor.TextSelection}
                                theme={{
                                    colors: {
                                        primary: esiColor.TextHighlight,
                                        text: esiColor.Text,
                                        placeholder: esiColor.TextPlaceholder,
                                    }
                                }}
                                outlineColor={esiColor.TextOuterLine}
                                underlineColor={esiColor.TextUnderline}
                                activeOutlineColor={esiColor.TextOuterLine}
                                label='Edit Review'
                                mode='outlined'
                                onChangeText={(Text) => { setComment(Text); editOnChange(Text) }}
                                multiline={true}
                                numberOfLines={4}
                                value={comment}
                                style={{ maxHeight: 100, minWidth: 250, maxWidth: 250, marginHorizontal: "auto", backgroundColor: esiColor.TextBC }}
                            />
                        </View>
                        <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <Rating
                                tintColor={esiColor.TintColor}
                                type='heart'
                                startingValue={rating}
                                ratingCount={5}
                                imageSize={30}
                                style={{ paddingVertical: 10 }}
                                onFinishRating={(value) => { setRating(value) }}
                            />
                            {!rating || rating === 0 ? (
                                <Text style={{ color: "red", fontSize: 16 }}>
                                    Please select the Rating.
                                </Text>
                            ) : (null)
                            }
                            <View style={{ width: 100 }}>
                                <TouchableOpacity style={[styles.centerElement, { backgroundColor: esiColor.globalButtonColor, width: 100, height: 25, borderRadius: 5 }]}
                                    onPress={() => editReviewModal()} >
                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </Surface>
    );
}

