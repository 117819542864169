import axios from "axios";
import config from './config';
import { meeapi } from "./node-actions/meeapi";
import shopperconfig from "./shoppers/shopperconfig";
export const ALL_TICKET_REQUEST = "ALL_TICKET_REQUEST";
export const ALL_TICKET_SUCCESS = "ALL_TICKET_SUCCESS";
export const ALL_TICKET_FAIL = "ALL_TICKET_FAIL";

export const TICKET_REQUEST = "TICKET_REQUEST";
export const TICKET_SUCCESS = "TICKET_SUCCESS";
export const TICKET_FAIL = "TICKET_FAIL";

export const ALL_TICKET_FILTER = "ALL_TICKET_FILTER";

export const ALL_COMMENTS_REQUEST = "ALL_COMMENTS_REQUEST";
export const ALL_COMMENTS_SUCCESS = "ALL_COMMENTS_SUCCESS";
export const ALL_COMMENTS_FAIL = "ALL_COMMENTS_FAIL";

export const COMMENTS_REQUEST = "COMMENTS_REQUEST";
export const COMMENTS_SUCCESS = "COMMENTS_SUCCESS";
export const COMMENTS_FAIL = "COMMENTS_FAIL";

export const TICKET_CREATE_REQUEST = "TICKET_CREATE_REQUEST";
export const TICKET_CREATE_SUCCESS = "TICKET_CREATE_SUCCESS";
export const TICKET_CREATE_FAIL = "TICKET_CREATE_FAIL";

export const COMMENTS_CREATE_REQUEST = "COMMENTS_CREATE_REQUEST";
export const COMMENTS_CREATE_SUCCESS = "COMMENTS_CREATE_SUCCESS";
export const COMMENTS_CREATE_FAIL = "COMMENTS_CREATE_FAIL";

const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
const urlGenarator = (url: any, pagination: any) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}
const cudheaders = config.nodeheadersCommon;
/*=============================================================
                  Get All Tickets Action
===============================================================*/
export const getalltickets = (formData: any, pagination = {},  responseFunction: any) => async dispatch => {
  try {
    dispatch({
      type: ALL_COMMENTS_REQUEST
    });
    meeapi.post(urlGenarator(`/Partner_Tickets/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        responseFunction(true, []);
        dispatch({
          type: ALL_TICKET_SUCCESS,
          payload: data
        });
      } else {
        responseFunction(true, data.results, data.Pagination );
        dispatch({
          type: ALL_TICKET_SUCCESS,
          payload: data
        });

      }
    })
  } catch (err) {
    responseFunction(false, err);
    dispatch({
      type: ALL_TICKET_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                  Get All Tickets Action
===============================================================*/
export const GetAllComments = (formData: any, callBackGetData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_COMMENTS_REQUEST
    });
    let { data } = await axios.post(`${shopperconfig.nodePartnerUrl}/Partner_Tickets/Comment_Get`,
      formData, {
        headers: nodeheaders
    });
    if (data) {

      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_COMMENTS_SUCCESS,
          payload: data
        });
      } else {
        callBackGetData(data.results)
        dispatch({
          type: ALL_COMMENTS_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ALL_TICKET_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                 Filter Action
===============================================================*/
export const ticketFilter = (fType: any, value: any) => {
  return function (dispatch) {
    dispatch({
      type: ALL_TICKET_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//Search

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: ALL_TICKET_FILTER,
      fType: "search",
      value: "",
    });
  }
}

export const getTicketsByIdAction = (getid: any, callBackGetData: any) => async () => {
  try {
    const formData = {
      "search_by_filter": "",
      "search": "",
      "Comments": "",
      "Created_By": "",
      "Records_Filter": "FILTER",
      "Status": "Active",
      "Ticket_Comment_id": "",
      "Ticket_Id":getid
    };

    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Tickets/Comment_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {

      if (data.Is_Data_Exist === "0") {

        callBackGetData([])

      } else {

        callBackGetData(data.results)

      }

    }
  } catch (error) {
    dispatch({
      type: ALL_TICKET_FAIL,
      payload: error
    });
  }

};
/*=============================================================
                 file upload Action
===============================================================*/
export const uploadAttachment = (imageData: any, fileData: any, callBackUploadFile: any) => async (dispatch: any) => {

  try {
    let { data } = await axios.post(
      `${config.url}/Partner_Upload/Upload?filename=${fileData.fileName}&file_extension=${fileData.ext}&filetype=document=${fileData.ticketId}`,

      imageData,
      {
        headers: config.headersCommon,
        transformRequest: (d) => d,
      }
    );
    if (data) {

      callBackUploadFile(data);
    }
  } catch (err) {
  }
};


/*=============================================================
                Create Ticket Action
===============================================================*/
export const AddTicketsAction = (formData: any, navigation: any, toast: any, resetForm: any, EmailID: any) => async dispatch => {
  dispatch({
    type: TICKET_CREATE_REQUEST
  });
  await axios.post(`${config.nodecudurl}/Partner_Tickets/Create`, formData, {
    headers: cudheaders
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: TICKET_CREATE_SUCCESS
        });
        resetForm();
        navigation.navigate('Ticketing')
        toast.show({ message: 'Ticket Created Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          Created_By: EmailID
        };
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "TICKET_ID",
          SortOrder: "ASC"
        }
        dispatch(getalltickets(formValue, pagination))
      } else {
        const errors = {};
        dispatch({
          type: TICKET_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: TICKET_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};


/*=============================================================
                Create Ticket comment Action
===============================================================*/
export const AddTicketcommentAction = (formData: any, getid: any, resetForm: any, setReload: any, callBackGetData: any) => async dispatch => {
  dispatch({
    type: COMMENTS_CREATE_REQUEST
  });
  await axios.post(`${config.nodecudurl}/Partner_Tickets/Comment_Create`, formData, {
    headers: cudheaders
  })
    .then(response => {

      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {

        dispatch({
          type: COMMENTS_CREATE_SUCCESS
        });
        resetForm();
        setReload(true);
        const formValue = {
          Ticket_Id: getid,
          Records_Filter: "FILTER",
          Status: "Active"
        };
        dispatch(GetAllComments(formValue, callBackGetData))
      } else {
        const errors = {};

        dispatch({
          type: COMMENTS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: COMMENTS_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};


export const getallTicketByIdAction = (formData: any, callBackData: any) => async () => {
  try {
    let { data } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Partner_Tickets/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {

      if (data.Is_Data_Exist === "0") {

        callBackData([])

      } else {

        callBackData(data.results)

      }

    }
  } catch (error) {

  }

};
