import axios from "axios";
import config from './config';
import shopperconfig from "./shoppers/shopperconfig";
export const SET_ALL_CONTENTS = "SET_ALL_CONTENTS"
export const CONTENTRESET = "CONTENTRESET";
const nodeheaders = shopperconfig.nodeheadersCommon;
const headers = config.headersCommon;

//get content
export const getAllContentManagementStaticAction = (formData: any, responseFunction: any) => async (dispatch: any) => {
    try {
        let { data } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/Content_Management/Get`,
            formData,
            {
                headers: nodeheaders
            }
        );
        var Content_Management_StaticData = data.results[0]
        if (data) {
            responseFunction(Content_Management_StaticData);
            dispatch({
                type: SET_ALL_CONTENTS,
                payload: Content_Management_StaticData,
            });
        } else {
            responseFunction({});
        }
    } catch (error) {
        responseFunction({});

    }
};