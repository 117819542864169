import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView, RefreshControl } from 'react-native';
import Header from '../../header/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GetEmployeeAction, getNewUserserviceByIdAction, GetServicesAction, updateUserservicesAction, userserviceByIdAction } from '../../../../state/actions/Services/userservicesActions';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import EsiDatePicker from '../../../../components/custom/date/date';

const StatusOption = [
  {
    value: 'ACCEPTED',
    label: 'ACCEPTED'
  },
  {
    value: 'INITIATED',
    label: 'INITIATED'
  },
  {
    value: 'CANCELLED',
    label: 'CANCELLED'
  },
];
export default function EditAcceptedUserservice(props) {
  //navigation is used for navigation between screens
  const { navigation } = props
  const dispatch = useDispatch();

  //toast for popup after successful API calls.
  const toast = useToast();
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const [data, setData] = useState([]);
  const esiColor = useSelector(state => state.theme);
  const [currentDate, setCurrentdate] = useState("");
  const [employeedata, setEmployeeData] = useState([]);
  const [serviceOptiondata, setServiceOptiondata] = useState([]);
  const [employeDropDown, setEmployeDropDown] = React.useState('');

  let employeedataopt = []
  if (employeedata) {
    if (employeedata[0]) {
      for (let i = 0; i < employeedata?.length; i++) {
        employeedataopt.push(
          {
            value: employeedata[i]?.Partner_User_Details_Id,
            label: employeedata[i]?.Full_Name.substring(0, 40),
            Mobile_Number: employeedata[i]?.Mobile_Number,
            Full_Name: employeedata[i]?.Full_Name,
            Email_Id: employeedata[i]?.Email_Id,
          })
      }
    }
  }
  let serviceOption = []
  if (serviceOptiondata) {
    if (serviceOptiondata[0]) {
      for (let i = 0; i < serviceOptiondata?.length; i++) {
        serviceOption.push(
          {
            value: serviceOptiondata[i]?.Partner_Service_Id,
            label: serviceOptiondata[i]?.Service_Name.substring(0, 40),
          })
      }
    }
  }
  const ID = props?.route?.params?.id;
  const [servicedata, setServiceData] = useState([]);
  //partners contains the data from  GET API using state call
  const EmailID = useSelector(state => state.auth?.user);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const callBackemployedata = (data: any) => {
    setEmployeeData(data)
  }
  const callBackservicedata = (data: any) => {
    setServiceOptiondata(data)
  }
  const callBackData = (data: any) => {
    setData(data)
  }
  React.useEffect(() => {
    let formData = {
      "search_by_filter": "",
      "search": "",
      "Email_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Partner_Employee_Id": "",
      "Records_Filter": "FILTER"
    };
    let ServicessData = {
      "search_by_filter": "",
      "search": "",
      "Partner_Detail_Id": EmailID?.Partner_Details_Id,
      "Partner_Service_Id": "",
      "Records_Filter": "FILTER"
    };
    dispatch(GetEmployeeAction(formData, callBackemployedata))
    dispatch(GetServicesAction(ServicessData, callBackservicedata))
  }, []);

  //dispatch is used to perform the API action
  useEffect(() => {
    let date = new Date().toJSON().slice(0, 10);;

    // This arrangement can be altered based on how we want the date's format to appear.
    // let currentDate = `${day}-${month}-${year}`;
    setCurrentdate(date)
    let formData = {
      "search": "",
      "search_by_filter": "",
      "Partner_Employee_Id": "",
      "Partner_Detail_Id": EmailID?.Partner_Details_Id,
      "User_Service_request_Status": "",
      "User_Service_request_Id": ID,
      "User_Email_Id": "",
      "Partner_Service_Id": "",
      "City_Id": "",
      "Service_Request_Status_From_Partner": "",
      "Records_Filter": "FILTER"
    };
    setServiceData(ID)
    dispatch(userserviceByIdAction(formData, callBackData));
  }, [ID, servicedata]);

  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [statusDropDown, setStatusDropDown] = useState("");
  const [countryField, setCountryField] = React.useState();

  /*====================================
          Handler
  ====================================*/
  const values = {
    User_Service_Request_Id: data?.User_Service_Request_Id,
    Partner_Detail_Id: EmailID?.Partner_Details_Id,
    Status: data?.Status ? data.Status : "",
    Service_Start_Date: currentDate,
    Modified_By: EmailID.Email_Id,
    Partner_Employee_Id: "",
    Service_Mobile_Number: "",
    User_Cancel_Reason: "",
    Service_Person_Email_Id: "",
    Service_Person_Name: "",
    Partner_Service_Id: serviceOptiondata[0]?.Partner_Service_Id,
    User_Service_Request_Status: data?.User_Service_Request_Status ? data.User_Service_Request_Status : "",
  }
  //Form validation
  const Form_Validation = Yup.object().shape({
    Service_Request_Status_From_Partner: Yup.string().required('Service Request Status From Partner is Required.'),
    Partner_Employee_Id: Yup.string().when('Service_Request_Status_From_Partner', { is: "INITIATED", then: Yup.string().required('Service Person Name is required.') }),
    Service_Mobile_Number: Yup.string().when('Service_Request_Status_From_Partner', { is: "INITIATED", then: Yup.string().required('Mobile Number is required.') }),
    User_Cancel_Reason: Yup.string().when('Service_Request_Status_From_Partner', { is: "CANCELLED", then: Yup.string().required('cancel reason is required.') }),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};


    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(updateUserservicesAction(formValues, props.navigation, toast, "2", EmailID));

    }
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    container: {
      paddingTop: 15
  },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      left: 22,
      top: 0.5,
      color: esiColor.SIconColor,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.DescColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{ flex: 1 ,backgroundColor: esiColor.BackgroundColor}}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {data &&
          <Formik initialValues={{ ...values }} enableReinitialize={true} onSubmit={onSubmit} validationSchema={Form_Validation}>
            {(props) => (
              <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => { props.resetForm(); navigation.navigate('Accepteduserservices') }}>
                    Go Back
                  </Button>
                </View>
                <View pointerEvents={!(permissions.Partner_Bank === "write") ? 'none' : 'auto'}>
                  <Card style={{
                    width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                    marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                    backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                  }}>
                    <Card.Content>
                      <Text style={{ fontSize: 20, fontWeight: "bold" ,color: esiColor.brandFontColor }}>
                        Edit User Service
                      </Text>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                        </View>
                        {data.Advance_Payment_Status === "SUCCESS" ?
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                            <Text style={{ fontWeight: "bold", color: 'green' }}>Advance Payment: {data?.Advance_Payment_Status} </Text>
                          </View> :
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                            <Text style={{ fontWeight: "bold", color: 'red' }}>Please collect:{data?.Advance_Amount} </Text>
                          </View>
                        }
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Price Per Hour:   {data.Price_Per_Hour} </Text>
                        </View>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15, flexDirection: 'row' }}>
                          <Text style={{ fontWeight: "bold", color: '#27b6cc' }}>Price Per Day:  {data.Price_Per_Day}  </Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                        <View style={styles.container}>
                                              <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                            Service Request Status From Partner
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(props.touched.Service_Request_Status_From_Partner && props.errors.Service_Request_Status_From_Partner) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={StatusOption}
                            maxHeight={StatusOption.length > 1 ? 250 : 200}
                            search={StatusOption.length > 1 ? true : false}
                            labelField="label"
                            valueField="value"
                            dropdownPosition="auto"
                            value={props.values.Service_Request_Status_From_Partner}
                            name="Service_Request_Status_From_Partner"
                            onFocus={() => setStatusDropDown(true)}
                            onBlur={() => setStatusDropDown(false)}
                            onUpdateValue={item => {
                              props.setFieldValue('Service_Request_Status_From_Partner', item.value)
                              props.setFieldValue('User_Service_Request_Status', item.value)
                              setStatusDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.Service_Request_Status_From_Partner && props.errors.Service_Request_Status_From_Partner)}>
                            <ErrorMessage name="Service_Request_Status_From_Partner" />
                          </HelperText>
                        </View>
                        </View>
                      </View>
                      {props.values.Service_Request_Status_From_Partner === 'INITIATED' ?
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                            {/*  Bank Name Input Field  with Validation */}
                            <EsiSearchDropdown
                              style={[styles.dropdown, Boolean(props.touched.Partner_Employee_Id && props.errors.Partner_Employee_Id) && { borderColor: '#b00020' }]}
                              placeholderStyle={styles.placeholderStyle}
                              selectedTextStyle={styles.selectedTextStyle}
                              inputSearchStyle={styles.inputSearchStyle}
                              iconStyle={styles.iconStyle}
                              data={employeedataopt}
                              labelField="label"
                              valueField="value"
                              maxHeight={employeedataopt.length > 1 ? 250 : 200}
                              search={employeedataopt.length > 1 ? true : false}
                              searchPlaceholder={'Select ServicePerson Name'}
                              value={props.values.Partner_Employee_Id}
                              onUpdateValue={item => {
                                item &&
                                  props.setFieldValue('Partner_Employee_Id', item?.value)
                                props.setFieldValue('Service_Mobile_Number', item?.Mobile_Number)
                                props.setFieldValue('Service_Person_Name', item?.Full_Name)
                                props.setFieldValue('Service_Person_Email_Id', item?.Email_Id)

                                setEmployeDropDown(false);
                              }}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Partner_Employee_Id && props.errors.Partner_Employee_Id)}>
                              <ErrorMessage name="Partner_Employee_Id" />
                            </HelperText>
                          </View>
                          <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: "1.2%" }}>
                            <TextInput style={{ width: "100%", margin: "auto", backgroundColor: 'white' }}
                              theme={{ colors: { primary: '#27B6CC' } }}
                              label="Mobile Number"
                              mode='outlined'
                              onChangeText={props.handleChange('Service_Mobile_Number')}
                              value={props.values.Service_Mobile_Number}
                            />
                            <HelperText type="error" visible={Boolean(props.touched.Service_Mobile_Number && props.errors.Service_Mobile_Number)}>
                              {props.touched.Service_Mobile_Number && props.errors.Service_Mobile_Number}
                            </HelperText>
                          </View>
                        </View> : null}
                      {props.values.Service_Request_Status_From_Partner === 'CANCELLED' ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, margin: 'auto' }}>
                          <TextInput
                            style={{ backgroundColor: 'white' }}
                            theme={{ colors: { primary: '#27B6CC' } }}
                            error={Boolean(props.touched.User_Cancel_Reason && props.errors.User_Cancel_Reason)}
                            label="Cancel Reason"
                            mode='outlined'
                            value={props.values.User_Cancel_Reason}
                            onChangeText={props.handleChange("User_Cancel_Reason")}
                          />
                          <HelperText type="error" visible={Boolean(props.touched.User_Cancel_Reason && props.errors.User_Cancel_Reason)}>
                            {props.touched.User_Cancel_Reason && props.errors.User_Cancel_Reason}
                          </HelperText>
                        </View>
                      </View> : null}
                      <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                        <Button style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" textColor={esiColor.itemButtenColor} onPress={props.handleSubmit}>
                          Save
                        </Button>
                      </View>

                    </Card.Content>
                  </Card>
                </View>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </Surface>
  );
};

