import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Image, Dimensions, RefreshControl } from 'react-native';
import { Dialog, Card, DataTable, Button, Text, Searchbar, Portal, Surface, DefaultTheme } from 'react-native-paper';
import { IconButton } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import { deleteEmployeeAction, employeeFilter, getAllEmployeeAction, getAllRolesAction, resetFilter } from '../../state/actions/employeeAction';
import Header from '../auth/components/header';
import Headers from '../Services/header/header'
import isEmpty from '../../state/validations/is-empty';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Partner_Employee_Id',
    label: 'Employee ID'
  },
  {
    value: 'Email_Id',
    label: 'Employee Email ID'
  },
  {
    value: 'Status',
    label: 'Status'
  },
];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const numberOfItemsPerPageList = [5, 10, 20];

function EmployeeDetails(props: any) {
  const [page, setPage] = React.useState(0);
  const { navigation } = props;
  const Page = props.route?.params?.ID;
  const esiColor = useSelector(state => state.theme);
  const toast = useToast();
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const employees = useSelector(state => state.employee.employees.all);
  let employeeMenu = useSelector(state => state.employee.employeeFilter.select);
  let employeeSearch = useSelector(state => state.employee.employeeFilter.search);
  const roles = useSelector(state => state.employee.employeeRoles.roles);
  const [refreshing, setRefreshing] = React.useState(false);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const EmailID = useSelector(state => state.auth?.user.Partner_Details_Id);
  const [employee, setEmployee] = React.useState();
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, employees?.length);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const ID = props.route?.params?.ID
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!isEmpty(EmailID)) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Email_Id": "",
        "Partner_Details_Id": EmailID,
        "Partner_Employee_Id": "",
        "Records_Filter": "FILTER"
      };
      setEmployee(employees);
      handleSearch("");
      dispatch(getAllEmployeeAction(formData, toast))
      dispatch(employeeFilter('search', ''));
      dispatch(employeeFilter('select', 'All'));
    }
  }, [EmailID]);
  React.useEffect(() => {
    let formData1 = {
      Filter: "PARTNER_%",

    };
    dispatch(getAllRolesAction(formData1))

  }, [employee, refreshing]);


  const [oldSearch, setOldSearch] = React.useState(0);

  const checkEmployeesForTable = () => {

    if (employeeSearch) {
      try {
        if (employeeMenu === "All") {
          return employees.filter(x => {
            let keys = ["Partner_Employee_Id", "Email_Id", "Status"];
            for (let i = 0; i < keys.length; i++) {
              try {
                if (x[keys[i]].toLowerCase().includes(employeeSearch.toLowerCase())) {
                  return true;
                }
              } catch (error) {
                if (x[keys[i]].toString().toLowerCase().includes(employeeSearch.toLowerCase())) {
                  return true;
                }
              }
            }
          });

        } else {
          return employees.filter(x => (x[employeeMenu] ? x[employeeMenu]?.toLowerCase() : '').includes(employeeSearch?.toLowerCase()));
        }
      } finally {
        if (page > 0) {
          if (oldSearch !== employeeSearch.length) {
            setOldSearch(employeeSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch !== employeeSearch.length) {
          setOldSearch(employeeSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return employees
    }
  }

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    let formData1 = {
      Filter: "PARTNER_%",

    };
    dispatch(getAllRolesAction(formData1))
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Partner_Employee_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'descending'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {

      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const [visible, setVisible] = React.useState(false);
  const [Edata, setData] = React.useState();
  const [rolevisible, setRoleVisible] = React.useState(false);
  const [Rdata, setRoleData] = React.useState();


  const hideDialog = () => setVisible(false);
  const rolehideDialog = () => setRoleVisible(false);

  const handleChange = itemValue => {
    dispatch(employeeFilter('select', itemValue));
  };
  const handleSearch = value => {
    dispatch(employeeFilter('search', value));
  };

  const data = stableSort(
    checkEmployeesForTable(), getComparator(order, orderBy))


  const deleteEmployee = (employee) => {
    if (employee) {
      const deleteData = {
        Partner_Details_Id: employee.Partner_Details_Id,
        Partner_Employee_Email_Id: employee.Email_Id,
        Mobile_Number: employee.Mobile_Number,
        Is_Active: 0,
        User_Type: employee.User_Type,
        Partner_Employee_Id: employee.Partner_Employee_Id
      };
      dispatch(deleteEmployeeAction(deleteData, toast));
    }
  };
  const [reload, setReload] = React.useState(false);

  React.useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)

  }, [seconds]);
  const pages = props.route?.params?.page;

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const style = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 35,
      alignItems: 'center',
      justifyContent: 'center',
      width: 200
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'center',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 150
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 140
    },
    
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      {pages === 'Services' ? <Headers navigation={navigation} /> : <Header navigation={navigation} />}
      {/* <Header navigation={navigation} /> */}
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: (Platform.OS === "web") ? "60%" : "95%", maxHeight: (dimensions >= 500) ? "auto" : 500, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: dimensions <= 500 ? 1 : 3 }} />
            <View style={{ flex: dimensions <= 500 ? 1 : 3 }} />
            <View style={{ flex: 0.3, justifyContent: "flex-end", paddingTop: 10 }}>
              <IconButton icon="close" color={esiColor.GBFColor} size={20} onPress={() => setVisible(!visible)} />
            </View>
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>View Partner Employee</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 1 }}>
              <Dialog.Content style={{ marginLeft: -20 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Email ID</Text></View>
                  <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{Edata?.Email_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Mobile</Text></View>
                  <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{Edata?.Mobile_Number} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Type</Text></View>
                  <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{Edata?.User_Type} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View>
                  <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{Edata?.Created_By} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                  <View style={{ flex: 2.5 }}><Text style={{ color: Edata?.Status === "Active" ? esiColor.GBFColor : "red" }}>{Edata?.Status} </Text></View>

                  {/* </View> */}
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Is Active</Text></View>
                  <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{Edata?.Is_Active} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <Portal>
        <Dialog visible={rolevisible} onDismiss={rolehideDialog} style={{ width: (Platform.OS === "web") ? "70%" : "80%", maxHeight: (dimensions >= 500) ? "auto" : 500, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor }}>
          <View style={{ flexDirection: "row" }}><View style={{ flex: dimensions <= 500 ? 1 : 3 }} /><View style={{ flex: dimensions <= 500 ? 1 : 3 }} />
            <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
              <IconButton
                color={esiColor.GBFColor}
                icon="close"
                size={20}
                onPress={() => setRoleVisible(!rolevisible)}
              />
            </View></View>
          <Dialog.Title  style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}><Text style={{ color: esiColor.itemColor }}> Available Roles</Text></Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Dialog.Content>
                <View style={{ flexDirection: "column", width: 180, marginRight: 'auto', marginLeft: 'auto', padding: 5 }}>
                  <View style={{ flex: 1 }}>
                    {roles.map((data, index) => (
                      <View key={index} style={{ paddingBottom: 10 }} >
                        <Text style={{ color: '#026473', fontWeight: 'bold' }}>{data?.Role_Name?.replace(/_/g, ' ')}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <ScrollView>
        <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
          {pages === "Services" ? <Button  textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }}
            onPress={() => {
              navigation.navigate("Services", { screen: 'ServicesDashboard' })
              handleSearch("")
              dispatch(resetFilter())
            }
            }>
            Go Back
          </Button> : <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }}
            onPress={() => {
              navigation.navigate('Dashboard')
              handleSearch("")
              dispatch(resetFilter())
            }
            }>
            Go Back
          </Button>}
        </View>
        <View style={{
          flexDirection: "column"
        }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, color: esiColor.brandFontColor }}>Partner Employee</Text>
        </View>
        <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
          <Button textColor={esiColor.itemButtenColor} compact={true} style={{ backgroundColor: esiColor.globalButtonColor, width: 170, }} mode="contained"
            onPress={() => { setRoleData(roles); setRoleVisible(!rolevisible) }}>
            Available Roles
          </Button>
        </View>
        {(permissions.Employees === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button textColor={esiColor.itemButtenColor} compact={true} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained"
              onPress={() => navigation.navigate('AddEmployee')}>
              Add Employee
            </Button>
          </View>}
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== "web" ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[style.dropdown]}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                inputSearchStyle={style.inputSearchStyle}
                iconStyle={style.iconStyle}
                data={filterOptions}
                containerStyle={style.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={employeeMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={employeeMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Employee ID" value="Partner_Employee_Id" />
                <Picker.Item label="Employee Email ID" value="Email_Id" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              iconColor={esiColor.SIconColor}              
              placeholderTextColor={esiColor.SPHTextColor}
              placeholder="Search"
              onChangeText={(value) => handleSearch(value)}
              value={employeeSearch}
            />
          </View>
        </View>
        <ScrollView refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        } horizontal={Platform.OS === 'web' && dimensions >= 500 ? false : true}>
          <View style={style.mainbox}>
            <Card>
              <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                <DataTable.Header style={style.databeHeader}>
                  <DataTable.Title  style={style.title} active={orderBy === 'Partner_Employee_Id'}
                    sortDirection={orderBy === 'Partner_Employee_Id' ? order : 'ascending'}
                    onPress={createSortHandler('Partner_Employee_Id')}><Text style={style.titletext}>Employee ID</Text></DataTable.Title>
                  <DataTable.Title  style={style.title} active={orderBy === 'Email_Id'}
                    sortDirection={orderBy === 'Email_Id' ? order : 'ascending'}
                    onPress={createSortHandler('Email_Id')}><Text style={style.titletext}>Employee Email ID</Text></DataTable.Title>
                  <DataTable.Title  style={style.title} active={orderBy === 'Status'}
                    sortDirection={orderBy === 'Status' ? order : 'ascending'}
                    onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>
                  <DataTable.Title  style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {
                  data.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((employee, index) => (
                    <DataTable.Row style={style.databeBox} key={employee.Partner_Employee_Id}>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{employee.Partner_Employee_Id}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{employee.Email_Id}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{employee.Status}</Text></DataTable.Cell>
                      <DataTable.Cell style={style.tablerow}>
                        <IconButton
                          icon="eye"
                          iconColor={esiColor.SIconColor}
                          size={20}
                          onPress={(e) => { setData(employee); setVisible(!visible) }}
                        />

                        {(permissions.Employees === "write") && (
                          <IconButton
                            icon="square-edit-outline"
                            iconColor={esiColor.SIconColor}
                            size={20}
                            onPress={(e) => navigation.navigate("EditEmployee", { id: employee.Partner_User_Details_Id, page: Page })}
                          />)}
                        {(permissions.Employees === "write") && (<IconButton
                          icon="delete"
                          iconColor={esiColor.SIconColor}
                          size={20}
                          onPress={(e) => deleteEmployee(employee)}
                        />)}
                      </DataTable.Cell>
                    </DataTable.Row>
                  ))
                }
                {isEmpty(data) &&
                  <View>
                    <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                      No records found!
                    </Text>
                    <Image source={require('../../assets/images/nodata.gif')}
                      style={{ width: 300, height: 300, alignSelf: "center" }} />
                  </View>
                }
                <DataTable.Pagination                  
                  page={page}
                  numberOfPages={Math.ceil(employees?.length / numberOfItemsPerPage)}
                  onPageChange={page => setPage(to < data?.length ? page : 0)}
                  label={employees?.length ? `${from + 1}-${to} of ${data?.length}` : '0-0'}
                  showFastPaginationControls={to > data?.length ? false : true}
                  numberOfItemsPerPageList={numberOfItemsPerPageList}
                  numberOfItemsPerPage={numberOfItemsPerPage}
                  onItemsPerPageChange={onItemsPerPageChange}
                  selectPageDropdownLabel={'Rows per page'}
                />
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}

export default EmployeeDetails