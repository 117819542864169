import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Checkbox, Text, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, Platform, RefreshControl, TouchableOpacity } from 'react-native';
import EsiCity from '../../../../components/custom/country-state-city/city';
import EsiState from '../../../../components/custom/country-state-city/state';
import EsiCountry from '../../../../components/custom/country-state-city/country';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import isEmpty from '../../../../state/validations/is-empty';
import Modal from "react-native-modal";
import AddressLocation from '../../../../components/custom/location/address-location';
import Header from '../../header/header';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import { getPropertyByIdAction, propertydata } from '../../../../state/actions/Properties/PropertiesAction';
import EsiEditor from '../../../../components/custom/richtext-editor/richtext-editor';

//AddresstypeOption and countryOption are used to get the data from dropdown
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];



const EditProperty = (props: any) => {
    //navigation is used for navigation between screens
    const { navigation } = props
    const ID = props.route?.params?.id;
    const dispatch = useDispatch();
    //toast for popup after successful API calls.
    const toast = useToast();

    // Role restriction
    const Propertydata = useSelector(state => state.property.propertys.property);
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const [countryField, setCountryField] = React.useState();
    const [stateField, setStateField] = React.useState();
    //addresses is used to store the values from previous page using props
    const [rhDescriptionMin, setRhDescriptionMin] = useState(20);
    const [descriptionsvalue, setDescriptionsValue] = React.useState();
  React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Address_Management === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
        setSeconds(2);
    }, [permissions, ID]);
    
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    React.useEffect(() => {
       if (isEmpty(ID)) {
            navigation.navigate('EditProperty')
        } else {
            setDescriptionsValue(Propertydata?.Full_Description)
        }
    }, [ID,Propertydata]
    );
    React.useEffect(() => {
        let formData = {
            "Function_Hall_ID": ID,
            "Records_Filter": "FILTER"
        }
        dispatch(getPropertyByIdAction(formData))
    }, []);
    //isSubmitted contains the data from Update API using state call
    const isSubmited = useSelector(state => state.property.propertyUpdate.isLoading);
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    //reload
    const [seconds, setSeconds] = React.useState(0);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);
    //form reset
    const [reload, setReload] = React.useState(false);
    const [isLocationVisible, setIsLocationVisible] = React.useState(false);
    React.useEffect(() => {
        if (reload)
            setReload(false);
    }, [reload])
    /*====================================
            Handler
    ====================================*/

    const values = {
        "Function_Hall_Id": Propertydata?.Function_Hall_Id,
        "Name": Propertydata?.Name,
        "License_Number": Propertydata?.License_Number,
        "Partner_Detail_Id": Propertydata?.Partner_Detail_Id,
        "Modified_By": EmailID?.Email_Id,
        "Hall_Type": Propertydata?.Hall_Type,
        "Hall_Seating_Capacity": Propertydata?.Hall_Seating_Capacity,
        "Price_Per_Day": Propertydata?.Price_Per_Day,
        "Price_Per_Hour": Propertydata?.Price_Per_Hour,
        "Room_Cost_Per_Night": Propertydata?.Room_Cost_Per_Night,
        "Hall_Food_Veg_Price_Per_Plate": Propertydata?.Hall_Food_Veg_Price_Per_Plate,
        "Hall_Food_Non_Veg_Price_Per_Plate": Propertydata?.Hall_Food_Non_Veg_Price_Per_Plate,
        "Hall_Decoration_Price": Propertydata?.Hall_Decoration_Price,
        "Electricity_Price_Per_Hour": Propertydata?.Electricity_Price_Per_Hour,
        "AC_Electricty_Price_Per_Hour": Propertydata?.AC_Electricity_Price_Per_Hour,
        "Any_Extra_Charges": Propertydata?.Any_Extra_Charges,
        "Discount_Percentage": Propertydata?.Discount_Percentage,
        "Country": Propertydata?.Country,
        "State": Propertydata?.State,
        "City": Propertydata?.City_ID,
        "Location": Propertydata?.Location,
        "Full_Description": Propertydata?.Full_Description,
        "Short_Description": Propertydata?.Short_Description,
        "Is_Licensed": Propertydata?.IS_Licensed > 0 ? true : false,
        "Is_Verified": Propertydata?.IS_Verified > 0 ? true : false,
        "Is_No_Legal_Complications": Propertydata?.IS_No_Legal_Complications > 0 ? true : false,
        "Is_Alchol_Party_Allowed": Propertydata?.IS_Alchol_Party_Allowed > 0 ? true : false,
        "Is_Available_For_Booking": Propertydata?.IS_Available_For_Booking > 0 ? true : false,
        "Status": Propertydata?.Status
    }


    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>{descriptionsvalue&&
                <Formik enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object().shape({
                        Name: Yup.string().required('Name is required.'),
                        License_Number: Yup.string().required('License Number is required.'),
                        Hall_Type: Yup.string().required('Hall Type is required.'),
                        Hall_Seating_Capacity: Yup.string().required('Hall Seating Capacity is required.'),
                        Price_Per_Day: Yup.string().required('Price Per Day is required.'),
                        Price_Per_Hour: Yup.string().required('Price Per Hour is required.'),
                        Room_Cost_Per_Night: Yup.string().required('Room Cost Per Night is required.'),
                        Hall_Food_Veg_Price_Per_Plate: Yup.string().required('Hall Food Veg Price Per Plate is required.'),
                        Hall_Food_Non_Veg_Price_Per_Plate: Yup.string().required('Hall Food Non Veg Price Per Plate is required.'),
                        Hall_Decoration_Price: Yup.string().required('Hall Decoration Price is required.'),
                        Electricity_Price_Per_Hour: Yup.string().required('Electricity Price Per Hour is required.'),
                        AC_Electricty_Price_Per_Hour: Yup.string().required('AC Electricty Price Per Hour is required.'),
                        Any_Extra_Charges: Yup.string().required('Any Extra Charges is required.'),
                        Discount_Percentage: Yup.string().required('Discount Percentage is required.'),
                        Country: Yup.string().required('Country is required.'),
                        State: Yup.string().required('State is required.'),
                        City: Yup.string().required('City is required.'),
                        Location: Yup.string()
                            .test('Location', 'Please provide valid URL.', (value) => {
                                return /^https?\:\/\/(www\.)?google\.[a-z]+\/maps\b/.test(value) || /^https?\:\/\/maps\b/.test(value);
                            })
                            .required('Google Map Location URL is required.'),
                        Short_Description: Yup.string().required('Short Description is required.'),
                        Status: Yup.string().required('Status is required.'),

                    })}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        values.Full_Description = descriptionsvalue;
                        if (!values.Full_Description || values.Full_Description.replace(/(<([^>]+)>)/ig, '').trim().length == 0) {
                            submitTest = false;
                            errors.Full_Description = "Description are required.";
                        }
                        else if (values.Full_Description.replace(/(<([^>]+)>)/ig, '').trim().length < rhDescriptionMin) {
                            submitTest = false;
                            errors.Full_Description = "Please provide at least " + rhDescriptionMin + " characters";
                        }
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            if (values.Is_Licensed) {
                                formValues.Is_Licensed = 1;
                            } else {
                                formValues.Is_Licensed = 0;
                            }
                            if (values.Is_Verified) {
                                formValues.Is_Verified = 1;
                            } else {
                                formValues.Is_Verified = 0;
                            }
                            if (values.Is_No_Legal_Complications) {
                                formValues.Is_No_Legal_Complications = 1;
                            } else {
                                formValues.Is_No_Legal_Complications = 0;
                            }
                            if (values.Is_Alchol_Party_Allowed) {
                                formValues.Is_Alchol_Party_Allowed = 1;
                            } else {
                                formValues.Is_Alchol_Party_Allowed = 0;
                            }
                            if (values.Is_Available_For_Booking) {
                                formValues.Is_Available_For_Booking = 1;
                            } else {
                                formValues.Is_Available_For_Booking = 0;
                            }
                            dispatch(propertydata(formValues));
                            navigation.navigate('EditPropertyPageTwo', { ID })
                        }
                    }

                    }
                >
                    {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any, setFieldValue: any }) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                            <View style={{ width: dimensions >= 700 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        setReload(true);
                                        navigation.navigate('GetProperties')
                                    }}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: '#D6D6D7'
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Edit Propertie
                                    </Text>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Name input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Function Hall Name'
                                                mode='outlined'
                                                name="Name"
                                                value={values.Name}
                                                onChangeText={handleChange('Name')}
                                                error={Boolean(touched.Name && errors.Name)}
                                                maxLength={6}
                                                keyboardType='numeric'
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Name && errors.Name)}>
                                                <ErrorMessage name="Name" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Land Mark input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='License Number'
                                                mode='outlined'
                                                name="License_Number"
                                                value={values.License_Number}
                                                onChangeText={handleChange('License_Number')}
                                                error={Boolean(touched.License_Number && errors.License_Number)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.License_Number && errors.License_Number)}>
                                                <ErrorMessage name="License_Number" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Hall_Type input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Hall Type'
                                                mode='outlined'
                                                name="Hall_Type"
                                                value={values.Hall_Type}
                                                onChangeText={handleChange('Hall_Type')}
                                                error={Boolean(touched.Hall_Type && errors.Hall_Type)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Hall_Type && errors.Hall_Type)}>
                                                <ErrorMessage name="Hall_Type" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Name input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Hall Seating Capacity'
                                                mode='outlined'
                                                name="Hall_Seating_Capacity"
                                                value={values.Hall_Seating_Capacity}
                                                onChangeText={handleChange('Hall_Seating_Capacity')}
                                                error={Boolean(touched.Hall_Seating_Capacity && errors.Hall_Seating_Capacity)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Hall_Seating_Capacity && errors.Hall_Seating_Capacity)}>
                                                <ErrorMessage name="Hall_Seating_Capacity" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Price_Per_Day input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Price Per Day'
                                                mode='outlined'
                                                name="Price_Per_Day"
                                                value={values.Price_Per_Day}
                                                onChangeText={handleChange('Price_Per_Day')}
                                                error={Boolean(touched.Price_Per_Day && errors.Price_Per_Day)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Price_Per_Day && errors.Price_Per_Day)}>
                                                <ErrorMessage name="Price_Per_Day" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Name input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Price Per Hour'
                                                mode='outlined'
                                                name="Price_Per_Hour"
                                                value={values.Price_Per_Hour}
                                                onChangeText={handleChange('Price_Per_Hour')}
                                                error={Boolean(touched.Price_Per_Hour && errors.Price_Per_Hour)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Price_Per_Hour && errors.Price_Per_Hour)}>
                                                <ErrorMessage name="Price_Per_Hour" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Room_Cost_Per_Night input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Room Cost Per Night'
                                                mode='outlined'
                                                name="Room_Cost_Per_Night"
                                                value={values.Room_Cost_Per_Night}
                                                onChangeText={handleChange('Room_Cost_Per_Night')}
                                                error={Boolean(touched.Room_Cost_Per_Night && errors.Room_Cost_Per_Night)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Room_Cost_Per_Night && errors.Room_Cost_Per_Night)}>
                                                <ErrorMessage name="Room_Cost_Per_Night" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Hall_Food_Veg_Price_Per_Plate input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Hall Food Veg Price Per Plate'
                                                mode='outlined'
                                                name="Hall_Food_Veg_Price_Per_Plate"
                                                value={values.Hall_Food_Veg_Price_Per_Plate}
                                                onChangeText={handleChange('Hall_Food_Veg_Price_Per_Plate')}
                                                error={Boolean(touched.Hall_Food_Veg_Price_Per_Plate && errors.Hall_Food_Veg_Price_Per_Plate)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Hall_Food_Veg_Price_Per_Plate && errors.Hall_Food_Veg_Price_Per_Plate)}>
                                                <ErrorMessage name="Hall_Food_Veg_Price_Per_Plate" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Hall_Food_Non_Veg_Price_Per_Plate input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Hall Food Non Veg Price Per Plate'
                                                mode='outlined'
                                                name="Hall_Food_Non_Veg_Price_Per_Plate"
                                                value={values.Hall_Food_Non_Veg_Price_Per_Plate}
                                                onChangeText={handleChange('Hall_Food_Non_Veg_Price_Per_Plate')}
                                                error={Boolean(touched.Hall_Food_Non_Veg_Price_Per_Plate && errors.Hall_Food_Non_Veg_Price_Per_Plate)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Hall_Food_Non_Veg_Price_Per_Plate && errors.Hall_Food_Non_Veg_Price_Per_Plate)}>
                                                <ErrorMessage name="Hall_Food_Non_Veg_Price_Per_Plate" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Hall_Decoration_Price input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Hall Decoration Price'
                                                mode='outlined'
                                                name="Hall_Decoration_Price"
                                                value={values.Hall_Decoration_Price}
                                                onChangeText={handleChange('Hall_Decoration_Price')}
                                                error={Boolean(touched.Hall_Decoration_Price && errors.Hall_Decoration_Price)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Hall_Decoration_Price && errors.Hall_Decoration_Price)}>
                                                <ErrorMessage name="Hall_Decoration_Price" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Electricity_Price_Per_Hour input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Electricity Price Per Hour'
                                                mode='outlined'
                                                name="Electricity_Price_Per_Hour"
                                                value={values.Electricity_Price_Per_Hour}
                                                onChangeText={handleChange('Electricity_Price_Per_Hour')}
                                                error={Boolean(touched.Electricity_Price_Per_Hour && errors.Electricity_Price_Per_Hour)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Electricity_Price_Per_Hour && errors.Electricity_Price_Per_Hour)}>
                                                <ErrorMessage name="Electricity_Price_Per_Hour" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* AC_Electricty_Price_Per_Hour input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='AC Electricty Price Per Hour'
                                                mode='outlined'
                                                name="AC_Electricty_Price_Per_Hour"
                                                value={values.AC_Electricty_Price_Per_Hour}
                                                onChangeText={handleChange('AC_Electricty_Price_Per_Hour')}
                                                error={Boolean(touched.AC_Electricty_Price_Per_Hour && errors.AC_Electricty_Price_Per_Hour)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.AC_Electricty_Price_Per_Hour && errors.AC_Electricty_Price_Per_Hour)}>
                                                <ErrorMessage name="AC_Electricty_Price_Per_Hour" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Any_Extra_Charges input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Any Extra Charges'
                                                mode='outlined'
                                                name="Any_Extra_Charges"
                                                value={values.Any_Extra_Charges}
                                                onChangeText={handleChange('Any_Extra_Charges')}
                                                error={Boolean(touched.Any_Extra_Charges && errors.Any_Extra_Charges)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Any_Extra_Charges && errors.Any_Extra_Charges)}>
                                                <ErrorMessage name="Any_Extra_Charges" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Name input field with validation*/}
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                label='Discount Percentage'
                                                mode='outlined'
                                                name="Discount_Percentage"
                                                value={values.Discount_Percentage}
                                                onChangeText={handleChange('Discount_Percentage')}
                                                error={Boolean(touched.Discount_Percentage && errors.Discount_Percentage)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Discount_Percentage && errors.Discount_Percentage)}>
                                                <ErrorMessage name="Discount_Percentage" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                            <Text style={[styles.dropdownlabel, Boolean(touched.Country && errors.Country) && { color: '#b00020' }]}>
                                                Country
                                            </Text>
                                            {/*  country input field with validation*/}
                                            <EsiCountry
                                                error={Boolean(touched.Country && errors.Country)}
                                                labelField="NAME"
                                                valueField="COUNTRY_CODE"
                                                value={values.Country}
                                                name="Country"
                                                search
                                                searchPlaceholder={'Select Country'}
                                                onUpdateValue={item => {
                                                    setFieldValue('Country', item.COUNTRY_CODE);
                                                    if (countryField != item.NAME)
                                                        setCountryField(item.COUNTRY_CODE);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Country && errors.Country)}>
                                                <ErrorMessage name="Country" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                            <Text style={[styles.dropdownlabel, Boolean(touched.State && errors.State) && { color: '#b00020' }]}>
                                                State
                                            </Text>
                                            {/* state input field with validation*/}
                                            <EsiState
                                                error={Boolean(touched.State && errors.State)}
                                                labelField="NAME"
                                                valueField="STATE_CODE"
                                                value={values.State}
                                                name="State"
                                                search
                                                searchPlaceholder={'Select State'}
                                                CountryCode={countryField}
                                                onUpdateValue={item => {
                                                    setFieldValue('State', item.STATE_CODE);
                                                    if (stateField != item.NAME)
                                                        setStateField(item.STATE_CODE);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.State && errors.State)}>
                                                <ErrorMessage name="State" />
                                            </HelperText>
                                        </View>

                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                            <Text style={[styles.dropdownlabel, Boolean(touched.City && errors.City) && { color: '#b00020' }]}>
                                                City
                                            </Text>
                                            {/* City input field with validation*/}
                                            <EsiCity
                                                error={Boolean(touched.City && errors.City)}
                                                labelField="NAME"
                                                valueField="CITY_ID"
                                                CountryCode={countryField}
                                                StateCode={stateField|| Propertydata?.State}
                                                value={values.City}
                                                name="City"
                                                search
                                                searchPlaceholder={'Select City'}
                                                onUpdateValue={(item) => {
                                                    setFieldValue('City', item.CITY_ID)
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.City && errors.City)}>
                                                <ErrorMessage name="City" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                            {/* Location input field with validation*/}
                                            <TouchableOpacity onPress={() => { setIsLocationVisible(!isLocationVisible); }}>
                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    label='Location'
                                                    mode='outlined'
                                                    name="Location"
                                                    value={values.Location}
                                                    onChangeText={(value) => {
                                                        setFieldValue("Location", value);
                                                    }}
                                                    error={Boolean(touched.Location && errors.Location)}
                                                    right={<TextInput.Icon icon={!(isEmpty(values.Latitude) || isEmpty(values.Longitude)) ? "map-marker-check-outline" : "map-marker-plus-outline"} onPress={() => {
                                                        setIsLocationVisible(!isLocationVisible);
                                                    }} color={!(isEmpty(values.Latitude) || isEmpty(values.Longitude)) ? "green" : "#27b6cc"} />}
                                                />
                                            </TouchableOpacity>
                                            <HelperText type="error" visible={Boolean(touched.Location && errors.Location)}>
                                                <ErrorMessage name="Location" />
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ paddingTop: Platform.OS !== "web" ? 10 : 10, paddingBottom: Platform.OS !== "web" ? '20%' : null }}>
                                    <EsiEditor label={"Description"} value={descriptionsvalue} onUpdateValue={(value) => { setDescriptionsValue(value) }} />
                                    <HelperText type="error" visible={(!descriptionsvalue || descriptionsvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDescriptionMin) && touched.Full_Description
                                                    && errors.Full_Description}>
                                                    {(!descriptionsvalue || descriptionsvalue.replace(/(<([^>]+)>)/ig, '').trim().length < rhDescriptionMin) && touched.Full_Description
                                                        && errors.Full_Description}
                                                </HelperText>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                error={Boolean(touched.Short_Description && errors.Short_Description)}
                                                label="Short Description"
                                                mode='outlined'
                                                value={values.Short_Description}
                                                onChangeText={handleChange("Short_Description")}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Short_Description && errors.Short_Description)}>
                                                {touched.Short_Description && errors.Short_Description}
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 7 }}>
                                            {/* Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(touched.Status && errors.Status) && { color: '#b00020' }]}>
                                                Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(touched.Status && errors.Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ? true : false}
                                                searchPlaceholder={'Select Status'}
                                                dropdownPosition="auto"
                                                value={values.Status}
                                                name="Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    setFieldValue('Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Status && errors.Status)}>
                                                <ErrorMessage name="Status" />
                                            </HelperText>
                                        </View>

                                    </View>
                                    <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", paddingTop: 15, paddingBottom: 15 }}>
                                        {/* Is Active checkbox field*/}
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Is_Licensed"
                                                status={values.Is_Licensed ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Is_Licensed", !values.Is_Licensed);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10 }}>Is Licensed</Text>
                                        </View>

                                        {/* Is Default checkbox field*/}
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Is_Verified"
                                                status={values.Is_Verified ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Is_Verified", !values.Is_Verified);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10 }}>Is Verified</Text>
                                        </View>

                                        {/* Is Pickup Available checkbox field*/}
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Is_No_Legal_Complications"
                                                status={values.Is_No_Legal_Complications ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Is_No_Legal_Complications", !values.Is_No_Legal_Complications);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10 }}>Is NovLegal Complications</Text>
                                        </View>

                                        {/* Is Shipping Available checkbox field*/}
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Is_Alchol_Party_Allowed"
                                                status={values.Is_Alchol_Party_Allowed ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Is_Alchol_Party_Allowed", !values.Is_Alchol_Party_Allowed);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10 }}>Is Alchol Allowed</Text>
                                        </View>
                                        <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                            <Checkbox.Android
                                                color="#27B6CC"
                                                name="Is_Available_For_Booking"
                                                status={values.Is_Available_For_Booking ? 'checked' : 'unchecked'}
                                                onPress={() => {
                                                    setFieldValue("Is_Available_For_Booking", !values.Is_Available_For_Booking);
                                                }}
                                            />
                                            <Text style={{ marginTop: 10 }}>Is Available For Booking</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                                            Next
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                            <Modal animationIn={"slideInDown"}
                                deviceWidth={dimensions.width}
                                deviceHeight={dimensions.height}
                                style={{ alignItems: 'center' }} isVisible={isLocationVisible}>
                                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: "#fff" }}>
                                    <AddressLocation isLocationVisible={isLocationVisible} setIsLocationVisible={setIsLocationVisible} filters={{
                                        Latitude: values.Latitude,
                                        Longitude: values.Longitude
                                    }}
                                        setFilters={(address, lan, lon) => {
                                            setFieldValue("Location", 'http://maps.google.com/maps?q=' + lan + ',' + lon)
                                            setIsLocationVisible(!isLocationVisible);
                                        }}

                                    />
                                </View>
                            </Modal>
                        </View>
                    )}
                </Formik>}
            </ScrollView>
        </Surface>
    );
};



const styles = StyleSheet.create({
    label: {
        marginBottom: 30,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        color: 'black',
        left: 22,
        top: 10,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});

export default EditProperty;