import React, { useState, useRef, useEffect } from 'react';
import { Card, Button, TextInput, HelperText, Checkbox, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-native-phone-number-input';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import {
  getAllBrands,
  getAllCategories,
  getAllSubCategories,
  productmastersdata,
  subcategoryAction,
} from '../../../../state/actions/productmastersAction';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import EsiDatePicker from '../../../../components/custom/date/date';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../auth/components/header';
import { CountryCode } from '../../../../components/custom/countrycode';
import AsyncStorage from '@react-native-async-storage/async-storage';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const AddProductMaster = (props: any) => {
  const { navigation } = props;

  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const EmailID = useSelector(state => state.auth?.user);
  const esiColor = useSelector(state => state.theme);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (!(permissions.Product_Master_Product_Color_Product_Size === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);
  //toast for popup after successful API calls.
  const toast = useToast();
  const [setErrorMessage] = useState();
  const dispatch = useDispatch();
  const [showDropDown, setShowDropDown] = React.useState('');
  const [showDropDownbrand, setShowDropDownbrand] = React.useState('');
  const [showDropDownsubcategory, setShowDropDownsubcategory] = React.useState('');
  const phoneInput = useRef(null);


  useEffect(() => {
    dispatch(getAllCategories(''));
    dispatch(getAllBrands(''));
    dispatch(getAllSubCategories(''));
  }, []);
  const [subcategories, setData] = React.useState([]);
  const callBackData = (data: any) => {
    setData(data)
  }
  const setType = (BUSINESSTYPE) => {
    if (BUSINESSTYPE !== "") {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Category_Id": BUSINESSTYPE,
        "Sub_Category_Id": "",
        "Category_Name": "",
        "Sub_Category_Name": "",
        "Records_Filter": "FILTER"

      }
      dispatch(subcategoryAction(formData, callBackData))
    }
  }
  //Screen dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const categoryupdateStates = (data) => {
    if (data) {
      dispatch(getAllCategories(data));
    }

  };
  const subcategoryupdateStates = (data) => {
    if (data) {
      dispatch(getAllSubCategories(data));
    }

  };
  const brandupdateStates = (data) => {
    if (data) {
      dispatch(getAllBrands(data));
    }

  };
  //getting categories by calling reducer
  const categories = useSelector(state => state.product.productCategories.categories);
  //here iterating categorie name and categories ID values 
  let categoryopt = []
  if (categories) {
    if (categories[0]) {
      for (
        let i = 0;
        i < categories.length;
        i++) {
        categoryopt.push({
          label: categories[i].Category_Name,
          value: categories[i].Category_Id
        })
      }
    }
  }
  let subcategorieopt = []
  if (subcategories) {
    if (subcategories[0]) {
      for (let i = 0; i < subcategories.length; i++) {
        subcategorieopt.push(
          {
            label: subcategories[i].Sub_Category_Name,
            value: subcategories[i].Sub_Category_Id
          })
      }
    }
  }
  //getting brands by calling reducer 
  const brands = useSelector(state => state.product.productBrands.brands);
  //here iterating brands name and brands ID values 
  let brandopt = []
  if (brands) {
    if (brands[0]) {
      for (let i = 0; i < brands.length; i++) {
        brandopt.push({
          label: brands[i].Brand_Name,
          value: brands[i].Brand_Id
        })
      }
    }
  }

  const handleClickback = async (productmaster) => {
    navigation.navigate('ProductMasterGet', { master: productmaster })
  };

  //mobile number country code
  const getCountryCode = (value) => {
    let dailCode = "91";
    if (value) {
      if (value.split('+').length > 0) {
        let pSplit = value.split('+');
        let count = pSplit.length > 1 ? 1 : 0;
        if (pSplit[count].split(' ').length > 0) {
          dailCode = value.split('+')[count].split(' ')[0];
        }
      }
    }
    return CountryCode.find((item) => item.dial_code == dailCode)?.code;
  }

  const getCurrentMobileNumber = (value) => {
    let dailNumber = value;
    if (value) {
      if (value.split(' ').length > 0) {
        dailNumber = value.split(' ')[1];
      }
    }
    return dailNumber;
  }

  //form reset
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    editor: {
      backgroundColor: "black",
      borderColor: "black",
      borderWidth: 1,
    },
    rich: {
      minHeight: 130,
      // flex: 1,
    },
    richBar: {
      height: 50,
      backgroundColor: "#F5FCFF",
    },
    text: {
      fontWeight: "bold",
      fontSize: 18,
    },
    tib: {
      textAlign: "center",
      color: "#515156",
    },
    a: {
      fontWeight: "bold",
      color: "purple",
    },
    div: {
      fontFamily: "monospace",
    },
    p: {
      fontSize: 30,
    },
    container: {
      paddingTop: 15
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
  });

  return (
    <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <ScrollView ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      }>
        <Formik
          initialValues={{
            Sku: "",
            Category: '',
            Sub_Category: "",
            Brand: "",
            Product_Name: "",
            Product_Type: "",
            Pattern_Name: "",
            Upc_Code: "",
            Height: "",
            Weight: "",
            Width: "",
            Length: "",
            Manufacturer: "",
            Manufacturer_Contact: "",
            Manufacturer_Cost: "",
            Mrp: "",
            Max_Mrp: "",
            Min_Mrp: "",
            Short_Description: "",
            Created_By: EmailID.Email_Id,
            Features: "",
            Is_Product_Active: false,
            Long_Description: "",
            Sales_Start_Date: "",
            Specifications: "",
            Status: "In-Active",
            Warranty_Period: '',
          }}
          //field validations
          validationSchema={Yup.object().shape({
            Sku: Yup.string().required('SKU ID is required.'),
            Category: Yup.string().required('Category Name is required.'),
            Sub_Category: Yup.string().required('Sub Category Name is required.'),
            Brand: Yup.string().required('Brand Name is required.'),
            Product_Name: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Product Master Name.').required('Product Master Name is required.'),
            Product_Type: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Product Type.').required('Product Type is required.'),
            Pattern_Name: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Pattern Name.').required('Pattern Name is required.'),
            Sales_Start_Date: Yup.date().min(Date(), "Partner Sales Date must not be past date and today.").required('Partner Start Date is required.'),
            Upc_Code: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid UPC Code.').required('UPC Code is required.'),
            Height: Yup.string().required('Height is required in cm.'),
            Weight: Yup.string().required('Weight is required in g.'),
            Width: Yup.string().required('Width is required in cm.'),
            Length: Yup.string().required('Length is required in cm.'),
            Manufacturer: Yup.string().matches(/^(?:(?!(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])))/, 'Please provide valid Manufacturer.').required('Manufacturer is required.'),
            Manufacturer_Cost: Yup.string().required('Manufacturer Cost is required.'),
            Mrp: Yup.string().required('MRP is required.'),
            Max_Mrp: Yup.number().positive().moreThan(Yup.ref('Min_Mrp'), 'Require Min MRP greater than to Max MRP.').required('Max MRP is required.'),
            Min_Mrp: Yup.number().required('Min MRP is required.'),
            Short_Description: Yup.string().required('Short Description is required.'),
            Manufacturer_Contact: Yup.string()
            .matches(/^\d+$/, 'Manufacturer_Contact number must contain only numbers')
            .min(10, 'Manufacturer_Contact number must be exactly 10 digits')
            .max(10, 'Manufacturer_Contact number must be exactly 10 digits')
            .required('Manufacturer_Contact Number is required.'),
          })}
          onSubmit={(values: any, { setSubmitting, setErrors }) => {
            console.log(values)
            let submitTest = true;
            setSubmitting(false);
            const errors = {};
            setErrors(errors);
            if (submitTest) {
              let formValues = JSON.parse(JSON.stringify(values));
              dispatch(productmastersdata(formValues, navigation));
              navigation.navigate('AddProductMasters')
            }
          }
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            resetForm
          }) => (
            <View style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              flexWrap: "wrap"
            }}>
              <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                  onPress={(e) => {
                    resetForm();
                    handleClickback();
                  }} >
                  Go Back
                </Button>
              </View>
              <Card style={{
                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
              }}>
                <Card.Content>
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                    Add Product Master
                  </Text>
                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    {/* Text input for SKU ID*/}
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Sku && errors.Sku)}
                        label="SKU ID"
                        mode='outlined'
                        value={values.Sku}
                        onChangeText={handleChange("Sku")}
                      />
                      <HelperText type="error" visible={Boolean(touched.Sku && errors.Sku)}>
                        {touched.Sku && errors.Sku}
                      </HelperText>
                    </View>
                    {/* EsiSearchDropdown for Category */}
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <View style={styles.container}>
                        <Text style={[styles.dropdownlabel, Boolean(touched.Category && errors.Category) && { color: '#b00020' }]}>
                          Category Name
                        </Text>
                        <EsiSearchDropdown
                          style={[styles.dropdown, Boolean(touched.Category && errors.Category) && { borderColor: '#b00020' }]}
                          placeholderStyle={styles.placeholderStyle}
                          selectedTextStyle={styles.selectedTextStyle}
                          inputSearchStyle={styles.inputSearchStyle}
                          iconStyle={styles.iconStyle}
                          data={categoryopt}
                          labelField="label"
                          valueField="value"
                          maxHeight={categoryopt.length > 1 ? 250 : 200}
                          search
                          searchPlaceholder={'Select Category Name'}
                          searchQuery={item => { categoryupdateStates(item) }}
                          value={values.Category}
                          onUpdateValue={item => {
                            setType(item?.value ? item.value : "");
                            setFieldValue('Category', item.value)
                            setShowDropDown(false);
                          }}
                        />
                        <HelperText type="error" visible={Boolean(touched.Category && errors.Category)}>
                          {touched.Category && errors.Category}
                        </HelperText>
                      </View>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    {/* EsiSearchDropdown for Sub Category */}
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <View style={styles.container}>
                        <Text style={[styles.dropdownlabel, Boolean(touched.Sub_Category && errors.Sub_Category) && { color: '#b00020' }]}>
                          Sub Category Name
                        </Text>
                        <EsiSearchDropdown
                          style={[styles.dropdown, Boolean(touched.Sub_Category && errors.Sub_Category) && { borderColor: '#b00020' }]}
                          placeholderStyle={styles.placeholderStyle}
                          selectedTextStyle={styles.selectedTextStyle}
                          inputSearchStyle={styles.inputSearchStyle}
                          iconStyle={styles.iconStyle}
                          data={subcategorieopt}
                          labelField="label"
                          valueField="value"
                          placeholder={!showDropDownsubcategory ? 'Select item' : '...'}
                          maxHeight={subcategorieopt.length > 1 ? 250 : 200}
                          search={subcategorieopt.length > 1 ? true : false}
                          searchPlaceholder={'Select Sub Category Name'}
                          value={values.Sub_Category}
                          searchQuery={item => { subcategoryupdateStates(item) }}
                          onUpdateValue={item => {
                            setFieldValue('Sub_Category', item.value)
                            setShowDropDownsubcategory(false);
                          }}
                        />
                        <HelperText type="error" visible={Boolean(touched.Sub_Category && errors.Sub_Category)}>
                          {touched.Sub_Category && errors.Sub_Category}
                        </HelperText>
                      </View>
                    </View>
                    {/* EsiSearchDropdown for Brand */}
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <View style={styles.container}>
                        <Text style={[styles.dropdownlabel, Boolean(touched.Brand && errors.Brand) && { color: '#b00020' }]}>
                          Brand Name
                        </Text>
                        <EsiSearchDropdown
                          style={[styles.dropdown, Boolean(touched.Brand && errors.Brand) && { borderColor: '#b00020' }]}
                          placeholderStyle={styles.placeholderStyle}
                          selectedTextStyle={styles.selectedTextStyle}
                          inputSearchStyle={styles.inputSearchStyle}
                          iconStyle={styles.iconStyle}
                          data={brandopt}
                          labelField="label"
                          valueField="value"
                          placeholder={!showDropDownbrand ? 'Select item' : '...'}
                          maxHeight={brandopt.length > 1 ? 250 : 200}
                          search={brandopt.length > 1 ? true : false}
                          searchPlaceholder={'Select Brand Name'}
                          searchQuery={item => { brandupdateStates(item) }}
                          value={values.Brand}
                          onUpdateValue={item => {
                            setFieldValue('Brand', item.value)
                            setShowDropDownbrand(false);
                          }}
                        />
                        <HelperText type="error" visible={Boolean(touched.Brand && errors.Brand)}>
                          {touched.Brand && errors.Brand}
                        </HelperText>
                      </View>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    {/* Text input for Product Name */}
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Product_Name && errors.Product_Name)}
                        label="Product Master Name"
                        mode='outlined'
                        value={values.Product_Name}
                        onChangeText={handleChange("Product_Name")}
                      />
                      <HelperText type="error" visible={Boolean(touched.Product_Name && errors.Product_Name)}>
                        {touched.Product_Name && errors.Product_Name}
                      </HelperText>
                    </View>

                    {/* Text input for Product Type */}
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Product_Type && errors.Product_Type)}
                        label="Product Type"
                        mode='outlined'
                        value={values.Product_Type}
                        onChangeText={handleChange("Product_Type")}
                      />
                      <HelperText type="error" visible={Boolean(touched.Product_Type && errors.Product_Type)}>
                        {touched.Product_Type && errors.Product_Type}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    {/* Text input for Pattern Name */}
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Pattern_Name && errors.Pattern_Name)}
                        label="Pattern Name"
                        mode='outlined'
                        value={values.Pattern_Name}
                        onChangeText={handleChange("Pattern_Name")}
                      />
                      <HelperText type="error" visible={Boolean(touched.Pattern_Name && errors.Pattern_Name)}>
                        {touched.Pattern_Name && errors.Pattern_Name}
                      </HelperText>
                    </View>
                    {/* Text input for UPC Code */}
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Upc_Code && errors.Upc_Code)}
                        label="UPC Code"
                        mode='outlined'
                        value={values.Upc_Code}
                        onChangeText={handleChange("Upc_Code")}
                      />
                      <HelperText type="error" visible={Boolean(touched.Upc_Code && errors.Upc_Code)}>
                        {touched.Upc_Code && errors.Upc_Code}
                      </HelperText>
                    </View>
                  </View>

                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Height && errors.Height)}
                        label="Height"
                        mode='outlined'
                        value={values.Height}
                        onChangeText={handleChange("Height")}
                        keyboardType='numeric'
                        placeholder='cm'
                      />
                      <HelperText type="error" visible={Boolean(touched.Height && errors.Height)}>
                        {touched.Height && errors.Height}
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Weight && errors.Weight)}
                        label="Weight"
                        mode='outlined'
                        value={values.Weight}
                        onChangeText={handleChange("Weight")}
                        keyboardType='numeric'
                        placeholder='g'
                      />
                      <HelperText type="error" visible={Boolean(touched.Weight && errors.Weight)}>
                        {touched.Weight && errors.Weight}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Width && errors.Width)}
                        label="Width"
                        mode='outlined'
                        value={values.Width}
                        onChangeText={handleChange("Width")}
                        keyboardType='numeric'
                        placeholder='cm'
                      />
                      <HelperText type="error" visible={Boolean(touched.Width && errors.Width)}>
                        {touched.Width && errors.Width}
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Length && errors.Length)}
                        label="Length"
                        mode='outlined'
                        value={values.Length}
                        onChangeText={handleChange("Length")}
                        keyboardType='numeric'
                        placeholder='cm'
                      />
                      <HelperText type="error" visible={Boolean(touched.Length && errors.Length)}>
                        {touched.Length && errors.Length}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    <View style={{ flexGrow: 1, width: 200, marginTop: 10, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Manufacturer && errors.Manufacturer)}
                        label="Manufacturer"
                        mode='outlined'
                        value={values.Manufacturer}
                        onChangeText={handleChange("Manufacturer")}
                      />
                      <HelperText type="error" visible={Boolean(touched.Manufacturer && errors.Manufacturer)}>
                        {touched.Manufacturer && errors.Manufacturer}
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      {/* <Text style={{ textAlign: "left", color: esiColor.Text }}>Manufacturer Contact</Text> */}
                      {/* <PhoneInput
                        ref={phoneInput}
                        value={getCurrentMobileNumber(values.Manufacturer_Contact)}
                        defaultCode={getCountryCode(values.Manufacturer_Contact)}
                        onChangeText={(text) => {
                          setFieldValue("Manufacturer_Contact", "+" + phoneInput.current?.getCallingCode() + " " + text);
                        }}
                        onChangeCountry={(value) => {
                          let callingCode = "+" + value?.callingCode[0] + " ";
                          let dailNumber = values.Manufacturer_Contact?.split(' ')[1] ? values.Manufacturer_Contact?.split(' ')[1] : values.Manufacturer_Contact;
                          setFieldValue("Manufacturer_Contact", callingCode + dailNumber)
                        }}
                        layout="first"
                        withShadow
                        containerStyle={{
                          width: '100%',
                          margin: "auto",
                          backgroundColor: esiColor.BackgroundColor,
                          overflow: 'hidden',
                          borderRadius: 3,
                          borderColor: Boolean(touched.Manufacturer_Contact && errors.Manufacturer_Contact) ? "#b00020" : esiColor.TextOuterLine,
                          borderStyle: "solid",
                          borderWidth: Boolean(touched.Manufacturer_Contact && errors.Manufacturer_Contact) ? 2 : 1
                        }}
                        textContainerStyle={{ backgroundColor: esiColor.BackgroundColor }}
                        textInputStyle={Platform.OS === "web" ? {
                          height: 30, marginRight: -10, outlineStyle: 'none', overflow: 'hidden', color: esiColor.Text
                        } : {
                          height: 30, marginRight: -10, overflow: 'hidden', color: esiColor.Text
                        }}
                        codeTextStyle={{ marginLeft: -15, color: esiColor.Text, backgroundColor: esiColor.BackgroundColor }}
                        countryPickerButtonStyle={{ width: 50, margin: "auto", marginLeft: 10, paddingBottom: 5, backgroundColor: esiColor.BackgroundColor }}
                      /> */}
                      <TextInput style={{ width: "100%", margin: "auto", backgroundColor: esiColor.BackgroundColor }}
                        theme={{ colors: { primary: esiColor.TextOuterLine} }}
                        label="Manufacturer Contact"
                        mode='outlined'
                        onChangeText={handleChange('Manufacturer_Contact')}
                        left={<TextInput.Affix textStyle={{color: esiColor.Text}} text="+91" />}
                        onBlur={(props) => {
                          handleBlur(props);
                        }}
                        value={values.Manufacturer_Contact}
                        />
                      <HelperText type="error" visible={Boolean(touched.Manufacturer_Contact && errors.Manufacturer_Contact)}>
                        {touched.Manufacturer_Contact && errors.Manufacturer_Contact}
                      </HelperText>
                    </View>
                  </View>

                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Manufacturer_Cost && errors.Manufacturer_Cost)}
                        label="Manufacturer Cost"
                        mode='outlined'
                        value={values.Manufacturer_Cost}
                        onChangeText={handleChange("Manufacturer_Cost")}
                        keyboardType='numeric'
                      />
                      <HelperText type="error" visible={Boolean(touched.Manufacturer_Cost && errors.Manufacturer_Cost)}>
                        {touched.Manufacturer_Cost && errors.Manufacturer_Cost}
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Mrp && errors.Mrp)}
                        label="MRP"
                        mode='outlined'
                        value={values.Mrp}
                        onChangeText={handleChange("Mrp")}
                        keyboardType='numeric'
                      />
                      <HelperText type="error" visible={Boolean(touched.Mrp && errors.Mrp)}>
                        {touched.Mrp && errors.Mrp}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Max_Mrp && errors.Max_Mrp)}
                        label="Max MRP"
                        mode='outlined'
                        value={values.Max_Mrp}
                        onChangeText={handleChange("Max_Mrp")}
                        keyboardType='numeric'
                      />
                      <HelperText type="error" visible={Boolean(touched.Max_Mrp && errors.Max_Mrp)}>
                        {touched.Max_Mrp && errors.Max_Mrp}
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Min_Mrp && errors.Min_Mrp)}
                        label="Min MRP"
                        mode='outlined'
                        value={values.Min_Mrp}
                        onChangeText={handleChange("Min_Mrp")}
                        keyboardType='numeric'
                      />
                      <HelperText type="error" visible={Boolean(touched.Min_Mrp && errors.Min_Mrp)}>
                        {touched.Min_Mrp && errors.Min_Mrp}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: 10 }}>
                      <EsiDatePicker
                        // theme={customTheme}
                        style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                        label={'Partner Sales Start Date'}
                        value={values.Sales_Start_Date}
                        valueUpdate={date => {
                          setFieldValue('Sales_Start_Date', date)
                        }} />

                      <HelperText type="error" visible={Boolean(touched.Sales_Start_Date && errors.Sales_Start_Date)}>
                        {touched.Sales_Start_Date && errors.Sales_Start_Date}
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: 15 }}>
                      <TextInput
                        // theme={customTheme}
                        style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Short_Description && errors.Short_Description)}
                        label="Short Description"
                        mode='outlined'
                        value={values.Short_Description}
                        onChangeText={handleChange("Short_Description")}
                      />
                      <HelperText type="error" visible={Boolean(touched.Short_Description && errors.Short_Description)}>
                        {touched.Short_Description && errors.Short_Description}
                      </HelperText>
                    </View>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                    <Button textColor={esiColor.itemButtenColor} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                      NEXT
                    </Button>
                  </View>
                </Card.Content>
              </Card>
            </View>
          )}
        </Formik>
      </ScrollView>
    </Surface >
  );
};

export default AddProductMaster;