import 'react-native-gesture-handler';
import * as React from 'react';
import { View, Platform, StyleSheet, Dimensions, Picker } from 'react-native';
import { BarChart } from 'react-native-chart-kit';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import isEmpty from '../../../state/validations/is-empty';

//statistic property contains the API data
function Statistics({   statistic ,esiColor}) {
    const [selectedYear, setSelectedYear] = React.useState('');
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    //yearlist contains the statistics API data for dropdown
    let yearlist = []
    if (statistic) {
        if (statistic[0]) {
            for (let i = 0; i < statistic.length; i++) {
                yearlist.push({
                    label: statistic[i].Year,
                    value: statistic[i].Year
                })
            }
        }
    }
    React.useEffect(()=>{
        if(isEmpty(selectedYear)&&statistic.length>0){
            setSelectedYear(statistic[0]?.Year)
        }
    },[selectedYear]);
    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);
    React.useEffect(()=>{
    },[selectedYear]);
    //handleChange contains the timeSpan filter data
    const handleChange = (itemValue) => {
        setSelectedYear(itemValue);
    };


    let outputData = {};
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    for (const item of statistic) {
        const year = item.Year;
        const monthsData = item.Months;

        // Initialize arrays for labels and data
        let labels = [];
        let data = Array(12).fill(0);

        for (let i = 0; i < 12; i++) {
            const month = months[i];
            labels.push(month.substr(0, 3)); // Use the first 3 letters of the month for labels
            if (monthsData.hasOwnProperty(month)) {
                data[i] = monthsData[month];
            }
        }
        const dataset = {
            data
        };
        const yearData = {
            labels,
            datasets: [dataset]
        };
        outputData[year] = yearData;
    }
    function extractDataForYear(data, year) {
        if (data[year]) {
          const { labels, datasets } = data[year];
          return {
            labels,
            datasets: datasets.map((dataset) => ({
              data: dataset.data,
            })),
          };
        } else {
          return null; // Year not found in the data
        }
      }
      const year2023Data = extractDataForYear(outputData, selectedYear);
    //emptyData contains the labels and datasets to display when the array is empty
    const emptyData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
        ]
    };
    const style = StyleSheet.create({
        dropdown: {
            height: 50,
            borderColor:esiColor?.backgroundcolor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 10,
        },
        placeholderStyle: {
            fontSize: 16,
        },
        selectedTextStyle: {
            fontSize: 16,
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 150
        },
    })

    return (
        <View style={{ margin: 'auto' ,backgroundColor:esiColor?.BackgroundColor}}>
            <View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, alignSelf: 'flex-start', width: 150 }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 850 ? 1 : 0.5 }}>
                            {/* EsiSearchDropdown component used for dropdown for mobile */}
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={yearlist}
                                containerStyle={style.containerstyle}
                                maxHeight={100}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'Select Year' : ''}
                                value={selectedYear}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    handleChange(item.value);
                                    setShowDropDown(false);
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
                            {/* Picker component used for dropdown for web */}
                            <Picker
                                selectedValue={selectedYear}
                                style={{ height: 50, width: dimensions <= 500 ? 100 : 150, borderRadius: 5, borderWidth: 1,backgroundColor:esiColor?.backgroundcolor, flexShrink: 1 }}
                                onValueChange={(itemValue) => handleChange(itemValue)}
                            >
                                {yearlist.map((yearData) => (
                                    <Picker.Item key={yearData.label} label={yearData.label} value={yearData.label} />
                                ))}
                            </Picker>
                        </View>
                    }
                </View>
            </View>
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            {/* BarChart component and its properties used for chart display */}
            {year2023Data ? (
                <BarChart
                    data={year2023Data} // Use year2023Data instead of calling extractDataForYear again
                    width={Platform.OS === "web" ? 1000 : 600}
                    height={Platform.OS === "web" ? 350 : 300}
                    chartConfig={{
                        backgroundColor:esiColor.CBColor,
                        backgroundGradientTo: '#f0f4f5',
                        backgroundGradientFrom: '#f0f4f5',
                        decimalPlaces: 2,
                        color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                        style: {
                            borderRadius: 16,
                        },
                    }}
                    style={{
                        marginVertical: 8,
                        borderRadius: 16,
                    }}
                />
            ) : (
                <BarChart
                    data={emptyData}
                    width={Platform.OS === "web" ? 1000 : 600}
                    height={Platform.OS === "web" ? 350 : 300}
                    chartConfig={{
                        backgroundColor: esiColor.CBColor,
                        backgroundGradientTo: '#f0f4f5',
                        backgroundGradientFrom: '#f0f4f5',
                        decimalPlaces: 2,
                        color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                        style: {
                            borderRadius: 16,
                        },
                    }}
                    style={{
                        marginVertical: 8,
                        borderRadius: 16,
                    }}
                />
            )}
        </View>
    </View>
);
}


export default Statistics;


