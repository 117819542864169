import React, { Component, useEffect, useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList
} from 'react-native';
import { getAllNotification, getMoreNotification, updateNotificationAction } from '../../state/actions/notificationAction';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Surface } from 'react-native-paper';
import Header from '../auth/components/header';
import { getSingleCoupon } from '../../state/actions/couponAction';
import { getSingleProduct } from '../../state/actions/productsAction';
import { getSingleDeal } from '../../state/actions/dealAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
function Notification(props: any) {

  const { navigation } = props;
  const [notifications, setNotifications] = useState([]);
  const EmailID = useSelector(state => state.auth?.user);
  const id = props.route?.params?.id;
  const esiColor = useSelector(state => state.theme);

  let pagecall = { PageNo: 1, PageSize: 5, SortBy: "PRODUCT_ID", SortOrder: "DESC" }
  const dispatch = useDispatch()
  const callBackData = (data: any) => {
    if (data) {
      setNotifications(data)
    }
  }
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  useEffect(() => {
    if (EmailID) {
      let filter = {
        "PARTNER_EMAIL_ID": EmailID.User_Type == "PARTNER_ADMIN" ? "" : EmailID?.Email_Id,
        "Partner_Details_Id": EmailID?.Partner_Details_Id,
        "Records_Filter": "FILTER"
      }
      dispatch(getAllNotification(filter, callBackData))
    }
  }, [])
  const showMore = (data) => {
    let filter = {
      "PARTNER_EMAIL_ID": EmailID.User_Type == "PARTNER_ADMIN" ? "" : EmailID?.Email_Id,
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    }
    const pagination = {
      PageNo: 1,
      PageSize: data,
      SortBy: "NOTIFICATION_ID",
      SortOrder: "DESC"
    }
    dispatch(getMoreNotification(filter, callBackData, pagination))
  }
  const showLess = (data) => {
    let filter = {
      "PARTNER_EMAIL_ID": EmailID.User_Type == "PARTNER_ADMIN" ? "" : EmailID?.Email_Id,
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER"
    }
    const pagination = {
      PageNo: 1,
      PageSize: data,
      SortBy: "NOTIFICATION_ID",
      SortOrder: "DESC"
    }
    dispatch(getMoreNotification(filter, callBackData, pagination))
  }
  const callBackCouponData = (data: any) => {
    if (data) {
      navigation.navigate('couponNotification', { data: data })
    }
  }
  const callBackDealData = (data: any) => {
    if (data) {
      navigation.navigate('dealNotification', { data: data })
    }
  }
  // 
  const callBackProductData = (data: any) => {
    if (data) {
      navigation.navigate('productNotification', { data: data })
    }
  }
  const readAllNotification = () => {
    let filter = {
      "Is_All_Read": "1",
      "Partner_Detail_Id": EmailID?.Partner_Details_Id
    }
    dispatch(updateNotificationAction(filter, EmailID, callBackData))
  }
  const notificationData = (notification: any) => {
    if (notification.Is_Partner_Read != 1) {
      let filter = {
        "Is_Partner_Read": "1",
        "Notification_Id": notification.Notification_Id
      }
      dispatch(updateNotificationAction(filter, EmailID, callBackData))
    }
    if (notification.Type == "Coupons") {
      let formdata = {
        Records_Filter: "FILTER",
        Coupon_Id: notification.Coupon_Id,
        Partner_Details_Id: notification.Partner_Details_Id
      }
      dispatch(getSingleCoupon(formdata, callBackCouponData))
    }
    else if (notification.Type == "Product") {
      let formdata = {
        "search_by_filter": "",
        "search": "",
        "Price": "",
        "Partner_Details_Id": "",
        "Records_Filter": "FILTER",
        "Product_Id": notification.Product_Id
      }
      dispatch(getSingleProduct(formdata, pagecall, callBackProductData))
    }
    else if (notification.Type == "Deal") {
      let formdata = {
        Records_Filter: "FILTER",
        Deal_Id: notification.Deal_Id
      }
      dispatch(getSingleDeal(formdata, callBackDealData))
    }
    else if (notification.Type == "Orders") {
      navigation.navigate('orderNotification', { data: notification.Order_Id })
    } else if (notification.Type == "Shopper_Orders") {
      navigation.navigate('b2borderNotification', { data: notification.Order_Id })
    } else if (notification.Type == "Products Bidding") {
      navigation.navigate('UserBiddingProductsOrderNotification', { data: notification.User_Products_Bidding_Id })
    } else if (notification.Type == "B2B_Bidding_Product") {
      navigation.navigate('B2BBiddingProductOrderNotification', { data: notification.B2B_User_Bidding_Products_Id })
    }
  }
  const ListEmptyView = () => {
    return (
      <View style={styles.MainContainer}>
        <Text style={{ textAlign: 'center', color: esiColor.NoDataFound }}>Currently don't have notifications to display.</Text>
      </View>

    );
  }
  const styles = StyleSheet.create({
    root: {
      backgroundColor: esiColor.BackgroundColor

    },
    MainContainer: {
      justifyContent: 'center',
      flex: 1,
      margin: 10
    },
    text: {
      marginBottom: 1,
      flexDirection: 'column',
      flexWrap: 'wrap',
      margin: 5,
      paddingTop: 8
    },
    content: {
      flex: 1,
      marginLeft: 16,
      marginRight: 0
    },
    timeAgo: {
      fontSize: 12,
      textAlign: "right",
      color: esiColor.globalButtonColor,
      paddingBottom: 8,
      fontWeight: "bold"
    },
    name: {
      fontSize: 16,
      color: esiColor.DescColor,
      fontWeight: "bold"
    },
    description: {
      fontSize: 13,
      color: esiColor.itemColor

    }
  });

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
      {id == '1' ? <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, height: 50, justifyContent: 'flex-start' }} onPress={() => navigation.navigate("Properties", { screen: 'PropertiesDashboard' })}>
          Go Back
        </Button></View> : id == '2' ? <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
          <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, height: 50, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('B2B', { screen: 'Main', params: { screen: 'All', params: { page: 'products', filter: 'all' } } })}>
            Go Back
          </Button></View> : id == '3' ? <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
            <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, height: 50, justifyContent: 'flex-start' }} onPress={() => navigation.navigate("Services", { screen: 'ServicesDashboard' })}>
              Go Back
            </Button></View> : id == '5' ? <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
              <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, height: 50, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('Farmer_Dashboard')}>
                Go Back
              </Button></View> : <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%", flexShrink: 1 }}>
        <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, height: 50, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('Farmer_Dashboard')}>
          Go Back
        </Button></View>}
      <Text style={{ fontSize: 20, fontWeight: "bold", justifyContent: 'center', textAlign: 'center', color: esiColor.brandFontColor }}>
        Notifications
      </Text>
      <TouchableOpacity onPress={(e) => { readAllNotification() }} >
        <Text style={{ fontSize: 12, fontWeight: "bold", justifyContent: 'flex-end', textAlign: 'right', color: esiColor.SIconColor, marginRight: 5, textDecorationLine: 'underline' }}>
          mark all as Read
        </Text>
      </TouchableOpacity>
      <FlatList
        style={styles.root}
        data={notifications.results}
        extraData={notifications.results}
        ItemSeparatorComponent={() => {
          return (
            <View />
          )
        }}
        keyExtractor={(item) => {
          return item.Notification_Id;
        }}
        ListEmptyComponent={ListEmptyView}
        renderItem={(item) => {
          const Notification = item.item;
          let mainContentStyle;
          return (

            <TouchableOpacity onPress={(e) => { notificationData(Notification) }} style={[{ marginHorizontal: 10, marginVertical: 5, borderRadius: 8, paddingVertical: 2 }, !Notification.Is_Partner_Read ? { borderColor: esiColor.SBorderColor, borderWidth: 0.2 } : { borderColor: esiColor.SBorderColor, borderWidth: 0.2 }]}>
              <View style={styles.content}>
                <View style={mainContentStyle}>
                  <View style={styles.text}>
                    <Text style={{
                      fontSize: 16,
                      color: !Notification.Is_Partner_Read ? esiColor?.itemColor : esiColor.SBorderColor,
                      fontWeight: "bold"
                    }}>{Notification.Subject}</Text>
                    <Text style={{
                      fontSize: 13,
                      color: !Notification.Is_Partner_Read ? esiColor?.itemColor : esiColor.SBorderColor,
                    }}>{Notification.Description}</Text>
                  </View>
                  <View style={{ flex: 1, paddingRight: 8 }}>
                    <Text style={{
                      fontSize: 12,
                      textAlign: "right",
                      color: !Notification.Is_Partner_Read ? esiColor?.itemColor : esiColor.SBorderColor,
                      paddingBottom: 8,
                      // fontWeight: "bold"
                    }}>{moment(Notification.Created_Date).fromNow() + " "}</Text>
                  </View>

                </View>
              </View>
            </TouchableOpacity>
          );
        }} />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {notifications?.results?.length >= 20 ? <TouchableOpacity onPress={(e) => { showLess(notifications?.results.length - Number(10)) }} >
          <View>
            <Text style={{ color: esiColor?.brandFontColor, alignSelf: 'flex-end', margin: 10, textDecorationLine: 'underline', fontSize: 10 }}>
              Show Less
            </Text>
          </View>
        </TouchableOpacity> : <TouchableOpacity disabled onPress={(e) => { showLess(notifications?.results.length - Number(10)) }} >
          <View>
            <Text style={{ color: notifications?.results?.length >= 20 ? esiColor?.brandFontColor : esiColor.SBorderColor, alignSelf: 'flex-end', margin: 10, textDecorationLine: 'underline', fontSize: 10 }}>
              Show Less
            </Text>
          </View>
        </TouchableOpacity>}

        {notifications?.results?.length >= 10 ? <TouchableOpacity onPress={(e) => { showMore(notifications?.results.length + Number(10)) }} >
          <View>
            <Text style={{ color: esiColor?.brandFontColor, alignSelf: 'flex-end', margin: 10, textDecorationLine: 'underline', fontSize: 10 }}>
              Show More
            </Text>
          </View>
        </TouchableOpacity> : <TouchableOpacity disabled onPress={(e) => { showMore(notifications?.results.length + Number(10)) }} >
          <View>
            <Text style={{ color: esiColor?.brandFontColor, alignSelf: 'flex-end', margin: 10, textDecorationLine: 'underline', fontSize: 10 }}>
              Show More
            </Text>
          </View>
        </TouchableOpacity>}
      </View>
    </Surface>
  );
}



export default Notification