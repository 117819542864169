import {
    TICKETS_REQUEST,
    TICKETS_SUCCESS,
    TICKETS_FAIL,
    TICKETS_GET_REQUEST,
    TICKETS_GET_SUCCESS,
    TICKETS_GET_FAIL,
    TICKETS_CREATE_REQUEST,
    TICKETS_CREATE_SUCCESS,
    TICKETS_CREATE_FAIL,
    TICKETS_UPDATE_REQUEST,
    TICKETS_UPDATE_SUCCESS,
    TICKETS_UPDATE_FAIL,
    TICKETS_DELETE_REQUEST,
    TICKETS_DELETE_SUCCESS,
    TICKETS_DELETE_FAIL,
    TICKETS_FILTER,
  } from '../actions/ticketActions'
  
  const initialState = {
    tickets: { all: [], error: '', isLoading: false },
    ticket: { ticket: {}, error: '', isLoading: false },
    ticketCreate: { ticket: {}, error: '', isLoading: false },
    ticketUpdate: { ticket: {}, error: '', isLoading: false },
    ticketDelete: { ticket: {}, error: '', isLoading: false },
    ticketFilter: { select: 'All', search: '' },
  };
  
  export default function (state = initialState, action:any) {
    switch (action.type) {
      case TICKETS_REQUEST:
        return { ...state, tickets: { all: state.tickets.all, error: '', isLoading: true } };
      case TICKETS_SUCCESS:
        return { ...state, tickets: { all: action.payload, error: '', isLoading: false } };
      case TICKETS_FAIL:
        return { ...state, tickets: { all: [], error: action.payload, isLoading: false } };
      case TICKETS_GET_REQUEST:
        return { ...state, ticket: { ticketCreate: {}, error: '', isLoading: true } };
      case TICKETS_GET_SUCCESS:
        return { ...state, ticket: { ticket: action.payload, error: '', isLoading: false } };
      case TICKETS_GET_FAIL:
        return { ...state, ticket: { ticket: {}, error: action.payload, isLoading: false } }
      case TICKETS_CREATE_REQUEST:
        return { ...state, ticketCreate: { ticket: {}, error: '', isLoading: true } };
      case TICKETS_CREATE_SUCCESS:
        return { ...state, ticketCreate: { ticket: state.ticketCreate, error: '', isLoading: false } };
      case TICKETS_CREATE_FAIL:
        return { ...state, ticketCreate: { ticket: {}, error: action.payload, isLoading: false } };
      case TICKETS_UPDATE_REQUEST:
        return { ...state, ticketUpdate: { ticket: {}, error: '', isLoading: true } };
      case TICKETS_UPDATE_SUCCESS:
        return { ...state, ticketUpdate: { employee: state.ticketUpdate.ticket, error: '', isLoading: false } };
      case TICKETS_UPDATE_FAIL:
        return { ...state, ticketUpdate: { ticket: {}, error: action.payload, isLoading: false } };
      case TICKETS_DELETE_REQUEST:
        return { ...state, ticketDelete: { ticket: {}, error: '', isLoading: true } };
      case TICKETS_DELETE_SUCCESS:
        return { ...state, ticketDelete: { ticket: action.payload, error: '', isLoading: false } };
      case TICKETS_DELETE_FAIL:
        return { ...state, ticketDelete: { ticket: {}, error: action.payload, isLoading: false } };
      case TICKETS_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, ticketFilter: { search: state.ticketFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, ticketFilter: { select: state.ticketFilter.select, search: action.value } };
        }
        return res;
      default:
        return state;
    }
  }
  
  
  