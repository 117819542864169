import React from "react";
import { TextInput } from "react-native-paper";
import { View } from "react-native";
export default function EsiTextAreaInput(props) {
    const { textareavalueUpdate, ...rest } = props;
    return (
        <View style={{ marginHorizontal: 7,  zIndex: -1}}>
        <TextInput selectionColor='rgba(0, 0, 0, 0.5)' 
            error={props.error}
            {...rest}
            label={props.label}
            value={props.value}
            // mode='outlined'
            onChangeText={text => props.textareavalueUpdate(text)}
        />
        </View>
    )
}
