import axios from "axios";
import config from './config';
import { meeapi } from "./node-actions/meeapi";

export const BIDDING_SERVICES_COMMENTS_CREATE_REQUEST = "BIDDING_SERVICES_COMMENTS_CREATE_REQUEST";
export const BIDDING_SERVICES_COMMENTS_CREATE_SUCCESS = "BIDDING_SERVICES_COMMENTS_CREATE_SUCCESS";
export const BIDDING_SERVICES_COMMENTS_CREATE_FAIL = "BIDDING_SERVICES_COMMENTS_CREATE_FAIL";

export const BIDDING_SERVICES_COMMENTS_REQUEST = "BIDDING_SERVICES_COMMENTS_REQUEST";
export const BIDDING_SERVICES_COMMENTS_SUCCESS = "BIDDING_SERVICES_COMMENTS_SUCCESS";
export const BIDDING_SERVICES_COMMENTS_FAIL = "BIDDING_SERVICES_COMMENTS_FAIL";


const headers = config.headersCommon;
const nodeheaders = config.nodeheadersCommon;
const urlGenarator = (url: any, pagination: any) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}
/*=============================================================
     Get bidding Service quote comments Action
===============================================================*/
export const GetAllBiddingServiceQuoteComments = (formData: any, callBackGetData: any, pagination = {}) => async (dispatch: any) => {
    try {
        dispatch({
            type: BIDDING_SERVICES_COMMENTS_REQUEST
        });
        meeapi.post(urlGenarator(`/Bidding_Service_Comments/Get`, pagination),
            formData,
        ).then(response => {
            let data = response.data;
            if (data.Is_Data_Exist === '0') {
                callBackGetData([])
                dispatch({
                    type: BIDDING_SERVICES_COMMENTS_SUCCESS,
                    payload: data
                });
            } else {
                callBackGetData(data.results)
                dispatch({
                    type: BIDDING_SERVICES_COMMENTS_SUCCESS,
                    payload: data
                });

            }
        })
    } catch (err) {
        dispatch({
            type: BIDDING_SERVICES_COMMENTS_FAIL,
            payload: err
        });
    }
};
/*=============================================================
               Create bidding Service quote comments Action
===============================================================*/
export const AddBiddingServiceQuotecommentAction = (formData: any, getid: any, resetForm: any, setReload: any, callBackGetData: any) => async dispatch => {
    dispatch({
        type: BIDDING_SERVICES_COMMENTS_CREATE_REQUEST
    });
    await axios.post(`${config.nodecudurl}/Bidding_Service_Comments/Create`, formData, {
        headers: nodeheaders,
    })
        .then(response => {

            let successResponse = response.data;
            if (successResponse.Response_Status === "Success") {

                dispatch({
                    type: BIDDING_SERVICES_COMMENTS_CREATE_SUCCESS
                });
                resetForm();
                setReload(true);
                const formValue = {
                    search_by_filter: "",
                    search: "",
                    Records_Filter: 'FILTER',
                    Bidding_Service_Comment_Id: "",
                    Partner_Bidding_Service_Quote_Id: getid
                };
                const pagination = {
                    PageNo: 1,
                    PageSize: 5,
                    SortBy: "Bidding_Service_Comment_Id",
                    SortOrder: "ASC"
                }
                dispatch(GetAllBiddingServiceQuoteComments(formValue, callBackGetData, pagination))
            } else {
                const errors = {};

                dispatch({
                    type: BIDDING_SERVICES_COMMENTS_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: BIDDING_SERVICES_COMMENTS_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};
