import { Dimensions, Image, Platform, ScrollView, TouchableOpacity, View } from "react-native";
import { ActivityIndicator, Button, Dialog, HelperText, IconButton, Portal, Surface, Text, TextInput } from "react-native-paper";
import { Divider } from 'react-native-elements';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Header from '../auth/components/header';
import ImageCarousel from "../../components/custom/image-carousel";
import { getBiddingProductDetails, getbiddingpartnerProductsAction, getProductsImageAction, biddingCancelAction } from "../../state/actions/bidding/biddingproductActions";
import isEmpty from "../../state/validations/is-empty";
import { ErrorMessage, Formik } from "formik";
import { useToast } from "../../components/custom/react-native-paper-toast/src";

export default function BidProductDetails(props: any) {
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  const ID = useSelector(state => state.auth.user?.Partner_Details_Id)
  const { navigation } = props;
  const Product_Id = props?.route?.params?.id;
  const toast = useToast();
  // const sec = props?.route?.params?.sec;
  const Bidding_product = props?.route?.params?.productid;
  const [product, setProduct] = useState({});
  const dimensions = Dimensions.get('window');
  const { height, width } = Dimensions.get('window');
  const [productLoading, setProductLoading] = useState(false);
  let numColumCount = Number((dimensions.width / 250).toFixed() == "1" ? 2 : (dimensions.width / 250).toFixed());
  let productImage = useSelector((state) => state.comment.biddingproductImages.all);
  let partnerproduct = useSelector((state) => state.comment.biddingpartnerproducts.all);
  let image = []
  if (productImage) {
    if (productImage[0]) {
      if (productImage[0].Product_Image) {
        let data = productImage[0].Product_Image
        for (var i = 0; i < data.length; i++) {
          image.push({ imageuri: data[i].Product_Image })
        }
      }
    }
  }

  const [issubmitted, setSubmit] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const hideDialog = () => {
    setVisible(false);
  }
  const statusChangeResponse = (status, data) => {
    if (status) {
      dispatch(getBiddingProductDetails(Product_Id, callBackGetData, ID));
      dispatch(getbiddingpartnerProductsAction(Product_Id, ID));
      dispatch(getProductsImageAction(Bidding_product));
    }
  }
  const callBackGetData = (data) => {
    if (data) {
      setProduct(data);
      setLoad(data?.b2b_User_Products_Bidding_Id)

    }
  };
  const [load, setLoad] = useState('')

  React.useEffect(() => {
    if (Product_Id != load) {
      setProductLoading(true);
      dispatch(getBiddingProductDetails(Product_Id, callBackGetData, ID));
      dispatch(getbiddingpartnerProductsAction(Product_Id, ID));
      dispatch(getProductsImageAction(Bidding_product));
    }
  }, [Product_Id, load]);
  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} />
      <View>
        <IconButton
          icon="arrow-left"
          size={27}
          iconColor={esiColor.SIconColor}
          style={{ marginTop: -2, marginLeft: 1 }}
          onPress={() => { navigation.navigate('Biddingproducts'), setLoad(Product_Id) }}
        />
      </View>
      {product ?
        <ScrollView style={{ maxHeight: dimensions.height - 20 }}>
          <Portal>
            <Dialog visible={visible} onDismiss={hideDialog} style={{
              width: (dimensions.width >= 500) ? 400 : dimensions.width - 50, maxHeight: (dimensions.height >= 500) ? 500 : dimensions.height - 50, marginLeft: 'auto', marginRight: 'auto',
              backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7
            }}>

              <Dialog.ScrollArea>
                <View style={{ flexDirection: "row" }} >
                  <View style={{ flex: 1, alignItems: "flex-end" }}>
                    <IconButton style={{ marginTop: 1, marginRight: -10, backgroundColor: esiColor.BackgroundColor }}
                      icon="close"
                      size={20}
                      // color={"#27b6cc"}
                      iconColor={esiColor.Text}
                      onPress={() => setVisible(!visible)}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row" }} >
                  <View style={{ flex: 1 }} >
                    <Text style={{ color: esiColor.GBFColor, fontSize: 20, textAlign: "center", marginTop: -20, marginBottom: 20 }}>Bidding Cancellation Form</Text>
                    <Text style={{ color: "orange", fontSize: 12, textAlign: "center", marginBottom: 5 }}>NOTE: there is no refund option if you have already paid..</Text>
                  </View>
                </View>
                <ScrollView >
                  <Dialog.Content>

                    <Formik
                      initialValues={
                        {
                          Cancelled_Reason: ''
                        }
                      }
                      validationSchema={Yup.object().shape({
                        Cancelled_Reason: Yup.string()
                          .required('Reason is required.')
                      })}
                      onSubmit={values => {
                        let updateData = {
                          Bidding_Status: 'CANCEL',
                          Cancelled_Reason: values.Cancelled_Reason,
                          B2B_User_Products_Bidding_Id: product[0]?.B2B_User_Products_Bidding_Id,
                        }
                        dispatch(biddingCancelAction(updateData, statusChangeResponse, toast))
                        setSubmit(!issubmitted);
                        setVisible(!visible);
                      }}
                    >
                      {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                        <View style={{
                          flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                          <View style={{ width: "100%", margin: "auto", paddingTop: 5 }}>
                            <TextInput
                              // theme={{ colors: { primary: '#27B6CC' } }}
                              style={{ marginTop: 15, backgroundColor: esiColor.TextBC }}
                              error={Boolean(touched.Cancelled_Reason && errors.Cancelled_Reason)}
                              label='Cancelled Reason'
                              name='Cancelled_Reason'
                              mode='outlined'
                              value={values.Cancelled_Reason}
                              onChangeText={handleChange('Cancelled_Reason')}
                              selectionColor={esiColor.TextSelection}
                              theme={{
                                colors: {
                                  primary: esiColor.TextHighlight,
                                  text: esiColor.Text,
                                  placeholder: esiColor.TextPlaceholder
                                }
                              }}
                              outlineColor={esiColor.TextOuterLine}
                              underlineColor={esiColor.TextUnderline}
                              activeOutlineColor={esiColor.TextOuterLine}
                            />
                            <HelperText type="error" visible={Boolean(touched.Cancelled_Reason && errors.Cancelled_Reason)}>
                              <ErrorMessage name="Cancelled_Reason" />
                            </HelperText>
                          </View>
                          <View style={{ margin: "auto" }}>
                            <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                              <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                            </Button>
                          </View>
                        </View>
                      )}
                    </Formik>
                  </Dialog.Content>
                </ScrollView>
              </Dialog.ScrollArea>
            </Dialog>
          </Portal>
          <View style={{ alignItems: 'center' }}>

            <ImageCarousel image={image} dimensions={dimensions} />

            <Text style={{ fontSize: 15, color: esiColor.brandFontColor, fontWeight: 'bold', marginLeft: 50, marginTop: 15, textAlign: 'center', }}>{product[0]?.Product_Name}</Text>
          </View>
          <View style={{ flexDirection: "row", paddingTop: 15 }}>
            <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.itemColor }}>Status :</Text></View>
            <View style={{ flex: 2, justifyContent: 'space-between' }}>
              <Text style={{ color: esiColor.Text }}>{product[0]?.Bidding_Status ? product[0]?.Bidding_Status : 'New'} </Text>
              {product[0]?.Bidding_Status == 'CANCEL' || product[0]?.Bidding_Status == 'COMPLETED' ? null : <View style={{ margin: 5 }}>
                <TouchableOpacity
                  style={{
                    backgroundColor: 'gray', width: 80, borderRadius: 5, alignItems: "center", padding: 5, opacity: issubmitted ? 0.2 : "",

                  }}
                  onPress={() => { setVisible(!visible) }}
                >
                  <Text style={{ color: esiColor.itemButtenColor }}> CANCEL </Text>
                </TouchableOpacity>
              </View>}
            </View>
          </View>
          <View style={{ flexDirection: "row", paddingTop: 15 }}>
            <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Quantity :</Text></View>
            <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Quantity} </Text></View>
          </View>
          <View style={{ flexDirection: "row", paddingTop: 15 }}>
            <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Pincode :</Text></View>
            <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{product[0]?.Pincode} </Text></View>
          </View>
          <View style={{ alignSelf: 'center', width: '100%' }}>
            <Text style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'center', color: esiColor.Text }}>Description </Text>
          </View>
          <View>
            <Text style={{ fontSize: 12, color: esiColor.itemColor }} >{product[0]?.Description}</Text>
          </View>
          <View style={{ alignSelf: 'center', width: '100%' }}>
            <Text style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'center', color: esiColor.itemColor }}>Bidding Partner Quotes</Text>
          </View>
          <ScrollView
            showsVerticalScrollIndicator={true} nestedScrollEnabled={true}
          >
            {!isEmpty(partnerproduct) ? (
              partnerproduct.Is_Data_Exist === "0" ? (
                <NoDataFound text={partnerproduct.UI_Display_Message} />
              ) : typeof partnerproduct === "object" ? (
                partnerproduct.map((data, index) => (
                  <TouchableOpacity key={index} onPress={() => {
                    navigation.navigate('PartnerQuotedetails', { id: Product_Id, quoteid: data?.B2B_Partner_Bidding_Quote_Id, Bidding_product: Bidding_product, b2bbidding: data.B2B_User_Products_Bidding_Id, sec: true });
                  }}>
                    <View key={index} style={{
                      shadowColor: esiColor.brandShadowColor,
                      // shadowOffset: { width: -2, height: 4 },
                      shadowOpacity: 5,
                      shadowRadius: 5,
                      marginHorizontal: 2, borderRadius: 10, padding: 2,
                      backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10),
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      // elevation: 10,
                      maxWidth: (Platform.OS === 'web' ? 650 : "100%"),
                      minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
                      maxHeight: height > 250 ? 250 : 90,
                      minHeight: 100,
                      // borderColor: 'grey',
                      borderWidth: 0.5,
                    }}>
                      <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                        <Image source={{ cache: "force-cache", uri: data?.Product_Image }} style={{ height: 100, width: 80, resizeMode: 'contain' }}></Image>
                      </View>
                      <View style={{ flex: 2, flexDirection: 'column' }}  >
                        <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{data?.Partner_Name}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Quantity: {data?.Quantity} {data?.Measurement_Type}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Advance Amount : {Number(data.Total_Advance_Amount)}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Delivery days : {data?.No_Of_Delivery_Days}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Location : {data?.Partner_Location}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Description : {data?.Description}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                ))) : (
                typeof partnerproduct
              )
            ) : (
              <View style={{ marginBottom: 20, paddingLeft: 15 }}>
                <Text style={{ color: esiColor.NoDataFound, fontWeight: 'bold' }}>Oops!!! No Partner Quotes found.</Text>
              </View>
            )}
            <View style={{ paddingBottom: 5 }} />
          </ScrollView>
        </ScrollView> :

        <ActivityIndicator color='#27b6cc' />
      }
    </Surface>
  )
}
