import React, { useState } from 'react';
import { TextField, makeStyles, createTheme, ThemeProvider } from '@material-ui/core';
import { View } from 'react-native';
import useColorScheme from '../../../hooks/useColorScheme';

const EsiDatePicker = (props: any) => {
    // props variable consist all react text field properties along with valueUpdate function.
    // valueUpdate function will return onChange value of date field.

    // valueUpdate function is a custom property as it dont have in react native textfield properties.
    const { valueUpdate, ...rest } = props

    const colorScheme = useColorScheme();
    const lightTheme = createTheme({
        palette: {
            type: 'light', // Use the light color palette
            primary: {
                main: '#2196F3', // Primary color
            },
            secondary: {
                main: '#FF4081', // Secondary color
            },
            // Customize other colors as needed
            background: {
                default: '#F5F5F5', // Background color
                paper: '#FFFFFF', // Paper color
            },
            text: {
                primary: '#333333', // Primary text color
                secondary: '#666666', // Secondary text color
            },
        },
        // Add other theme configurations as needed
    });

    const darkTheme = createTheme({
        palette: {
            type: 'dark', // Use the dark color palette
            primary: {
                main: '#2196F3', // Primary color for dark theme
            },
            secondary: {
                main: '#FF4081', // Secondary color for dark theme
            },
            // Customize other colors as needed
            background: {
                default: '#121212', // Background color for dark theme
                paper: '#1E1E1E', // Paper color for dark theme
            },
            text: {
                primary: '#FFFFFF', // Primary text color for dark theme
                secondary: '#B0B0B0', // Secondary text color for dark theme
            },
        },
        // Add other theme configurations as needed
    });

    return (
        <View style={{ marginTop: 10 }} >
            <ThemeProvider theme={colorScheme == "dark" ? darkTheme : lightTheme} >

            <TextField
                style={[rest.style]}
                {...rest}
                fullWidth
                error={props.error}
                variant='outlined'
                label={props.label}
                type="date"
                onChange={(e) => props.valueUpdate(e.target.value)}
                value={props.value}
                InputLabelProps={{
                    shrink: true,
                }}

            />
            </ThemeProvider>
        </View>

    );
};

export default EsiDatePicker;