import {
  ALL_BIDDING_COMMENTS_REQUEST,
  ALL_BIDDING_COMMENTS_SUCCESS,
  ALL_BIDDING_COMMENTS_FAIL,
  BIDDING_COMMENTS_CREATE_REQUEST,
  BIDDING_COMMENTS_CREATE_SUCCESS,
  BIDDING_COMMENTS_CREATE_FAIL,
  ALL_BIDDING_PARTNER_PRODUCTS_REQUEST,
  ALL_BIDDING_PARTNER_PRODUCTS,
  ALL_BIDDING_PARTNER_PRODUCTS_FAIL,
  QUOTE_REVIEW_CREATE_REQUEST,
  QUOTE_REVIEW_CREATE_SUCCESS,
  QUOTE_REVIEW_CREATE_FAIL,
  QUOTE_ALL_USER_REVIEWS_REQUEST,
  QUOTE_ALL_USER_REVIEWS,
  QUOTE_ALL_USER_REVIEWS_FAIL,
  ALL_BIDDING_IMAGE_PRODUCTS_REQUEST,
  ALL_BIDDING_IMAGE_PRODUCTS,
  ALL_BIDDING_IMAGE_PRODUCTS_FAIL
} from '../../actions/bidding/biddingproductActions';
const initialState = {
  BiddingComments: { all: [], error: '', isLoading: false },
  BiddingCommentCreate: { Comment: {}, error: '', isLoading: false },
  biddingpartnerproducts: { all: [], error: '', isLoading: false },
  biddingquoteReviews: { all: [], error: '', isLoading: false },
  biddingquotereviewsCreate: { biddingqoutereview: {}, error: '', isLoading: false },
  biddingproductImages: { all: [], error: '', isLoading: false },
};

export default function commentReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_BIDDING_PARTNER_PRODUCTS_REQUEST:
      return { ...state, biddingpartnerproducts: { all: state.biddingpartnerproducts.all, error: '', isLoading: true } };
    case ALL_BIDDING_PARTNER_PRODUCTS:
      return { ...state, biddingpartnerproducts: { all: action.payload, error: '', isLoading: false } };
    case ALL_BIDDING_PARTNER_PRODUCTS_FAIL:
      return { ...state, biddingpartnerproducts: { all: [], error: action.payload, isLoading: false } };
    case ALL_BIDDING_COMMENTS_REQUEST:
      return { ...state, BiddingComments: { all: state.BiddingComments.all, error: '', isLoading: true }, };
    case ALL_BIDDING_COMMENTS_SUCCESS:
      return { ...state, BiddingComments: { all: action.payload, error: '', isLoading: false } };
    case ALL_BIDDING_COMMENTS_FAIL:
      return { ...state, BiddingComments: { all: [], error: action.payload, isLoading: false } };
    case BIDDING_COMMENTS_CREATE_REQUEST:
      return { ...state, BiddingCommentCreate: { Comment: {}, error: '', isLoading: true } };
    case BIDDING_COMMENTS_CREATE_SUCCESS:
      return { ...state, BiddingCommentCreate: { Comment: action.payload, error: '', isLoading: false } };
    case BIDDING_COMMENTS_CREATE_FAIL:
      return { ...state, BiddingCommentCreate: { Comment: {}, error: action.payload, isLoading: false } };
    case QUOTE_ALL_USER_REVIEWS_REQUEST:
      return { ...state, biddingquoteReviews: { all: state.biddingquoteReviews.all, error: '', isLoading: true } };
    case QUOTE_ALL_USER_REVIEWS:
      return { ...state, biddingquoteReviews: { all: action.payload, error: '', isLoading: false } };
    case QUOTE_ALL_USER_REVIEWS_FAIL:
      return { ...state, biddingquoteReviews: { all: [], error: action.payload, isLoading: false } };
    case QUOTE_REVIEW_CREATE_REQUEST:
      return { ...state, biddingquotereviewsCreate: { biddingqoutereview: {}, error: '', isLoading: true } };
    case QUOTE_REVIEW_CREATE_SUCCESS:
      return { ...state, biddingquotereviewsCreate: { biddingqoutereview: state.biddingquotereviewsCreate.biddingqoutereview, error: '', isLoading: false } };
    case QUOTE_REVIEW_CREATE_FAIL:
      return { ...state, biddingquotereviewsCreate: { biddingqoutereview: {}, error: action.payload, isLoading: false } };
      case ALL_BIDDING_IMAGE_PRODUCTS_REQUEST:
      return { ...state, biddingproductImages: { all: state.biddingproductImages.all, error: '', isLoading: true } };
    case ALL_BIDDING_IMAGE_PRODUCTS:
      return { ...state, biddingproductImages: { all: action.payload, error: '', isLoading: false } };
    case ALL_BIDDING_IMAGE_PRODUCTS_FAIL:
      return { ...state, biddingproductImages: { all: [], error: action.payload, isLoading: false } };

    default:
      return state;
  }
}