import React, { useState } from "react"
import { View, Text, Image, StyleSheet, Platform, Dimensions } from "react-native";
import isEmpty from "../../../../state/validations/is-empty";
import Map from "./web-plugin/map";


const TrackingMap = (props) => {
    const { origin, destination, navigation } = props;
    // const { origin, destination } = {origin:{ latitude: 12.9559746, longitude: 77.6950884 },destination:{ latitude: 13.004625, longitude: 77.683904 }};


    const GOOGLE_MAP_API_KEY = "AIzaSyB8X9VRFX7CwQ08_QRGmpVeUMzlQK86Rcs";
    const [places, setPlaces] = useState([{}, {}]);

    React.useEffect(() => {
        if ((JSON.stringify(places[0]) != JSON.stringify(origin)) || (JSON.stringify(places[1]) != JSON.stringify(destination))) {
            setPlaces([origin, destination]);
        }
    }, [origin, destination]);

    return (
        !isEmpty(places) ?
            <Map
                googleMapURL={
                    'https://maps.googleapis.com/maps/api/js?key=' +
                    GOOGLE_MAP_API_KEY +
                    '&libraries=geometry,drawing,places'
                }
                markers={places}
                loadingElement={<div
                    style={{ height: `100%` }}
                />}
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div
                    style={{ height: `100%` }}
                />}
                defaultCenter={{ lat: 25.798939, lng: -80.291409 }}
                defaultZoom={11}
            />
            :
            <></>
    )
}

export default TrackingMap