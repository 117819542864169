import {
  PARTNERPAYMENT_REQUEST,
  PARTNERPAYMENT_SUCCESS,
  PARTNERPAYMENT_FAIL,

  PARTNERPAYMENT_GET_REQUEST,
  PARTNERPAYMENT_GET_SUCCESS,
  PARTNERPAYMENT_GET_FAIL,

  PARTNERPAYMENT_UPDATE_REQUEST,
  PARTNERPAYMENT_UPDATE_SUCCESS,
  PARTNERPAYMENT_UPDATE_FAIL,

  PARTNERPAYMENT_FILTER,

} from '../actions/partnerpaymentAction'

const initialState = {

  partnerpayments: { all: [], error: '', isLoading: false },
  partnerpayment: { partpayment: {}, error: '', isLoading: false },
  partnerpaymentUpdate: { partpayment: {}, error: '', isLoading: false },

  partnerpaymentFilter: { select: 'All', search: '' },
};

export default function (state = initialState, action: any) {

  switch (action.type) {
    case PARTNERPAYMENT_REQUEST:
      return { ...state, partnerpayments: { all: state.partnerpayments.all, error: '', isLoading: true } };
    case PARTNERPAYMENT_SUCCESS:
      return { ...state, partnerpayments: { all: action.payload, error: '', isLoading: false } };
    case PARTNERPAYMENT_FAIL:
      return { ...state, partnerpayments: { all: [], error: action.payload, isLoading: false } };

    case PARTNERPAYMENT_GET_REQUEST:
      return { ...state, partnerpayment: { partnerpayment: {}, error: '', isLoading: true } };
    case PARTNERPAYMENT_GET_SUCCESS:
      return { ...state, partnerpayment: { partnerpayment: action.payload, error: '', isLoading: false } };
    case PARTNERPAYMENT_GET_FAIL:
      return { ...state, partnerpayment: { partnerpayment: {}, error: action.payload, isLoading: false } };

    case PARTNERPAYMENT_UPDATE_REQUEST:
      return { ...state, partnerpaymentUpdate: { partnerpayment: {}, error: '', isLoading: true } };
    case PARTNERPAYMENT_UPDATE_SUCCESS:
      return { ...state, partnerpaymentUpdate: { partnerpayment: state.partnerpaymentUpdate.partpayment, error: '', isLoading: false } };
    case PARTNERPAYMENT_UPDATE_FAIL:
      return { ...state, partnerpaymentUpdate: { partnerpayment: {}, error: action.payload, isLoading: false } };


    case PARTNERPAYMENT_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, partnerpaymentFilter: { search: state.partnerpaymentFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, partnerpaymentFilter: { select: state.partnerpaymentFilter.select, search: action.value } };
      }
      return res;

    default:
      return state;
  }
}