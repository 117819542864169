import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const GIFT_REQUEST = "GIFT_REQUEST";
export const GIFT_SUCCESS = "GIFT_SUCCESS";
export const GIFT_FAIL = "GIFT_FAIL";
export const GIFT_GET_REQUEST = "GIFT_GET_REQUEST";
export const GIFT_GET_SUCCESS = "GIFT_GET_SUCCESS";
export const GIFT_GET_FAIL = "GIFT_GET_FAIL";
export const GIFT_CREATE_REQUEST = "GIFT_CREATE_REQUEST";
export const GIFT_CREATE_SUCCESS = "GIFT_CREATE_SUCCESS";
export const GIFT_CREATE_FAIL = "GIFT_CREATE_FAIL";
export const GIFT_UPDATE_REQUEST = "GIFT_UPDATE_REQUEST";
export const GIFT_UPDATE_SUCCESS = "GIFT_UPDATE_SUCCESS";
export const GIFT_UPDATE_FAIL = "GIFT_UPDATE_FAIL";
export const GIFT_DELETE_REQUEST = "GIFT_DELETE_REQUEST";
export const GIFT_DELETE_SUCCESS = "GIFT_DELETE_SUCCESS";
export const GIFT_DELETE_FAIL = "GIFT_DELETE_FAIL";
export const GIFT_FILTER = "GIFT_FILTER";

export const GIFT_VENDORID_REQUEST = "GIFT_VENDORID_REQUEST";
export const GIFT_VENDORID_SUCCESS = "GIFT_VENDORID_SUCCESS";
export const GIFT_VENDORID_FAIL = "GIFT_VENDORID_FAIL";

export const GIFT_IMAGEID_REQUEST = "GIFT_IMAGEID_REQUEST";
export const GIFT_IMAGEID_SUCCESS = "GIFT_IMAGEID_SUCCESS";
export const GIFT_IMAGEID_FAIL = "GIFT_IMAGEID_FAIL";

export const GIFT_MAILID_REQUEST = "GIFT_MAILID_REQUEST";
export const GIFT_MAILID_SUCCESS = "GIFT_MAILID_SUCCESS";
export const GIFT_MAILID_FAIL = "GIFT_MAILID_FAIL";
const headers = config.headersCommon;
/*=============================================================
                 Get All Bill Receipt Action
===============================================================*/
const nodeheaders = shopperconfig.nodeheadersCommon;
export const getAllGiftAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: GIFT_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Gift/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: GIFT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFT_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFT_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                Update gift voucher Action
===============================================================*/

export const updateGiftAction = (formData: any, navigation: any) => async dispatch => {
  dispatch({
    type: GIFT_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Gift/Update`,
    formData, {
    headers: headers
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: GIFT_UPDATE_SUCCESS
        });
        const formValues = {
          "Gift_Card_Vendor_Id": formData.Gift_Card_Vendor_Id,
          'Records_Filter': 'FILTER'
        }; dispatch(getAllGiftAction(formValues))
        navigation.navigate('Gift', { giftsdetails: formData.Gift_Card_Vendor_Id })
                                  
      } else {
        dispatch({
          type: GIFT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GIFT_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};

/*=============================================================
                  Get All userimageids Action
 ===============================================================*/

export const getAllimageids = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: GIFT_IMAGEID_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Gift/Image_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: GIFT_IMAGEID_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: GIFT_IMAGEID_SUCCESS,
          payload: data.results
        });
      }
    }
  } catch (err) {
    dispatch({
      type: GIFT_IMAGEID_FAIL,
      payload: err
    });
  }
};
// Filter 
export const giftFilter = (fType, value) => {
  return function (dispatch) {
    dispatch({
      type: GIFT_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//search

export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: GIFT_FILTER,
      fType: "search",
      value: "",
    });
  }
}

export const singlegiftAction = (formData: any, callBackData: any) => async dispatch => {
  try {
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Gift/Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      callBackData(data?.results[0])

    }
  } catch (err) {
    dispatch({
      payload: err
    });
  }
};