import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, RefreshControl } from 'react-native';
import ImageUpload from '../../../components/custom/image-upload/upload';
import Header from '../../auth/components/header';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import { singleimageAction, updateGiftImageAction, updateImageAction } from '../../../state/actions/giftimageAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

const statusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];
const typeOption = [
    {
        value: 'Card',
        label: 'Card'
    },
    {
        value: 'Voucher',
        label: 'Voucher'
    }
];
const purposeOption = [
    {
        value: 'Birthday',
        label: 'Birthday'
    },
    {
        value: 'Anniversary',
        label: 'Anniversary'
    },
    {
        value: 'Festival',
        label: 'Festival'
    },
    {
        value: 'Occasion',
        label: 'Occasion'
    },

];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function EditImage(props: any) {
    const esiColor = useSelector(state => state.theme);
    const { navigation } = props;
    //toast for popup after successful API calls.
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Gifts_Management_Vendors_Images_User_Gifts === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);

    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

    const isSubmited = useSelector(state => state.brand.brandCreate.isLoading);
    const Image_Id = props.route?.params?.Image_Id;

    const [showDropDown, setShowDropDown] = React.useState('');
    const [imagesGet, setData] = React.useState([]);
    const callBackData = (data: any) => {
        setData(data)
        setImage({
            preview: data?.Image_URL,
            raw: data?.Image_URL
        })
        imagesGet?.Image_URL
    }
    React.useEffect(() => {
        let formData = {
            "search_by_filter": "",
            "search": "",
            "Gift_Vendor_Id": "",
            "Occassion": "",
            "Records_Filter": "FILTER",
            "Gift_Image_Id": Image_Id
        };
        dispatch(singleimageAction(formData, callBackData));
    }, []);

    //Screen dimension calculting call.

    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    //image upload
    const [image, setImage] = useState({ preview: imagesGet?.Image_URL, raw: imagesGet?.Image_URL });
    const [imgUrl, setImgUrl] = useState(imagesGet?.Image_URL);
    const [uploadPic, setUploadPic] = useState(false);
    const [imageError, setImageError] = useState();
    const callBackUploadImage = (data: any) => {
        if (data) {
            setImgUrl(data.File_Url);
        }
        setUploadPic(false);
    };

    const handleImage = async (result: any) => {
        const img = await fetchImageFromUri(result.uri);
        setImage({
            preview: result.uri,
            raw: img
        });
        dispatch(
            updateImageAction(img, imagesGet, callBackUploadImage)
        );
    };

    const fetchImageFromUri = async (uri: any) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        return blob;
    };

    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };

    const dispatch = useDispatch();

    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    /*====================================
            Handler
    ====================================*/

    const values = {
        Gift_Image_Id: imagesGet.Gift_Image_Id,
        Vendor_Id: imagesGet.Gift_Vendor_Id,
        Type: imagesGet.Type,
        Occassion: imagesGet.Occassion,
        Status: imagesGet.Status,
        Modified_By: EmailID.Email_Id
    }
    const Form_Validation = Yup.object().shape({
        Type: Yup.string().required('Type is required.'),
        Occassion: Yup.string().required('Occasion is required.'),
        Status: Yup.string().required('Status is required.'),
    })
    const onSubmit = (values: any, { setSubmitting, setErrors }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setImageError("");
        setErrors(errors);
        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            formValues.Created_By = EmailID.Email_Id;
            dispatch(updateGiftImageAction(formValues, navigation, toast));
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },
        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        container: {
            paddingTop: 15
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },

    });

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor
        }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                    {(props) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        props.resetForm();
                                        navigation.navigate('GiftImage', { giftsdetails: imagesGet.Vendor_Id })
                                    }}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold",color: esiColor.brandFontColor }}>
                                        Edit Image
                                    </Text>
                                    <View style={{
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        justifyContent: 'space-between',
                                    }}>
                                        <View style={{ flexShrink: 1, width: 770 }}>
                                            <View style={{
                                                flexDirection: "row",
                                                flexWrap: "wrap",
                                                justifyContent: 'space-between',
                                            }}>
                                                <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                    <TextInput
                                                        // theme={customTheme}
                                                        style={{
                                                          marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                          borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                        }}
                                                        label='Vendor Name'
                                                        mode='outlined'
                                                        disabled
                                                        value={imagesGet.Vendor_Name}
                                                        onChangeText={props.handleChange('Vendor_Name')}
                                                    />
                                                </View>
                                                <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                    <View style={{ flexGrow: 1, flexShrink: 1, marginTop: '1%' }}>
                                                        <View style={styles.container}>
                                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Occassion && props.errors.Occassion) && { color: '#b00020' }]}>
                                                                Occasion
                                                            </Text>
                                                            <EsiSearchDropdown
                                                                style={[styles.dropdown, Boolean(props.touched.Occassion && props.errors.Occassion) && { borderColor: '#b00020' }]}
                                                                placeholderStyle={styles.placeholderStyle}
                                                                selectedTextStyle={styles.selectedTextStyle}
                                                                inputSearchStyle={styles.inputSearchStyle}
                                                                iconStyle={styles.iconStyle}
                                                                data={purposeOption}
                                                                labelField="label"
                                                                valueField="value"
                                                                maxHeight={purposeOption.length > 1 ? 250 : 200}
                                                                search={purposeOption.length > 1 ? true : false}
                                                                searchPlaceholder={'Select Occasion'}
                                                                value={values.Occassion}
                                                                onUpdateValue={item => {
                                                                    props.setFieldValue('Occassion', item.value)
                                                                    setShowDropDown(false);
                                                                }}
                                                            />
                                                            <HelperText type="error" visible={Boolean(props.touched.Occassion && props.errors.Occassion)}>
                                                                {props.touched.Occassion && props.errors.Occassion}
                                                            </HelperText>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                    <View style={{ flexGrow: 1, flexShrink: 1, marginTop: '1%' }}>
                                                        <View style={styles.container}>
                                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Type && props.errors.Type) && { color: '#b00020' }]}>
                                                                Type
                                                            </Text>
                                                            <EsiSearchDropdown
                                                                style={[styles.dropdown, Boolean(props.touched.Type && props.errors.Type) && { borderColor: '#b00020' }]}
                                                                placeholderStyle={styles.placeholderStyle}
                                                                selectedTextStyle={styles.selectedTextStyle}
                                                                inputSearchStyle={styles.inputSearchStyle}
                                                                iconStyle={styles.iconStyle}
                                                                data={typeOption}
                                                                labelField="label"
                                                                valueField="value"
                                                                maxHeight={typeOption.length > 1 ? 250 : 200}
                                                                search={typeOption.length > 1 ? true : false}
                                                                searchPlaceholder={'Select Type'}
                                                                value={values.Type}
                                                                onUpdateValue={item => {
                                                                    props.setFieldValue('Type', item.value)
                                                                    setShowDropDown(false);
                                                                }}
                                                            />
                                                            <HelperText type="error" visible={Boolean(props.touched.Type && props.errors.Type)}>
                                                                {props.touched.Type && props.errors.Type}
                                                            </HelperText>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                                                    <View style={styles.container}>
                                                        <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                                                            Status
                                                        </Text>
                                                        <EsiSearchDropdown
                                                            style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                                                            placeholderStyle={styles.placeholderStyle}
                                                            selectedTextStyle={styles.selectedTextStyle}
                                                            inputSearchStyle={styles.inputSearchStyle}
                                                            iconStyle={styles.iconStyle}
                                                            data={statusOption}
                                                            labelField="label"
                                                            valueField="value"
                                                            maxHeight={statusOption.length > 1 ? 250 : 200}
                                                            search={statusOption.length > 1 ? true : false}
                                                            searchPlaceholder={'Select Status'}
                                                            value={values.Status}
                                                            onUpdateValue={item => {
                                                                props.setFieldValue('Status', item.value)
                                                                setShowDropDown(false);
                                                            }}
                                                        />
                                                        <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                                                            {props.touched.Status && props.errors.Status}
                                                        </HelperText>
                                                    </View>
                                                </View>
                                                <View />
                                            </View>
                                        </View>
                                        <View style={{ flexShrink: 1, width: 300, margin: 20 }} >
                                            <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: esiColor.SBorderColor }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                                            <HelperText type="error" visible={!imgUrl && props.touched.Image && props.errors.Image}>
                                                {!imgUrl && props.touched.Image && props.errors.Image}
                                            </HelperText>
                                        </View>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button textColor={esiColor.itemButtenColor} disabled={isSubmited || uploadPic} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>

    );
};

export default EditImage