import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Image, RefreshControl, TouchableOpacity } from 'react-native';
import { Dialog, Card, DataTable, Button, Text, Searchbar, Portal, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import { getAllDealAction, resetFilter } from '../../state/actions/dealAction';
import Header from '../auth/components/header';
import RenderHtml from 'react-native-render-html';
import isEmpty from '../../state/validations/is-empty';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from 'react-native-vector-icons/FontAwesome';

//filterOptions for filter dropdown
const filterOptions = [
  {
    value: 'ALL',
    label: 'All'
  },
  {
    value: 'Deal_Id',
    label: 'Deal ID'
  },
  {
    value: 'Partner_Name',
    label: 'Partner_Name'
  },
  {
    value: 'Title',
    label: 'Title'
  },
  {
    value: 'Start_Date',
    label: 'Start Date'
  },
  {
    value: 'End_Date',
    label: 'End Date'
  },
  {
    value: 'Status',
    label: 'Status'
  }
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function DealDetails({ navigation }: { navigation: any }) {
  const esiColor = useSelector(state => state.theme);
  //deals contains the data from  GET API using state call
  const Alldeals = useSelector(state => state.deal.deals.all);
  let deals = Alldeals?.results;
  const [deal, setDeals] = React.useState();
  const [dealMenu, setDealMenu] = React.useState('All')
  const [dealSearch, setDealSearch] = React.useState('');
  const EmailID = useSelector(state => state.auth?.user);
  const [page, setPage] = React.useState(0);
  const [webpage, setwebPage] = React.useState(1);
  const [numberOfItemsPerPage, numberItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = (Number(from) + Number(numberOfItemsPerPage) > Alldeals?.Pagination?.TotalCount ? Alldeals?.Pagination?.TotalCount : Number(from) + Number(numberOfItemsPerPage));
  const onItemsPerPageChange = (e) => {
    numberItemsPerPageChange(e)
    setPage(0)
    setwebPage(1)
    setPagination({ PageNo: Number(page) + Number(1), PageSize: e, SortBy: "DEAL_ID", SortOrder: "DESC" })
  }
  const DynamicPageIncrease = (e) => {
    setwebPage(e < Alldeals?.Pagination?.TotalPages ? e + Number(1) : Alldeals?.Pagination?.TotalPages)
    setPagination({ PageNo: e == Alldeals?.Pagination?.TotalPages ? Alldeals?.Pagination?.TotalPages : e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "DEAL_ID", SortOrder: "DESC" });
  }
  const DynamicPagedecrese = (e) => {
    setwebPage(e > 1 ? e - Number(1) : 1)
    setPagination({ PageNo: e > 1 ? e - Number(1) : 1, PageSize: numberOfItemsPerPage, SortBy: "DEAL_ID", SortOrder: "DESC" });
  }
  const DynamicPage = (e) => {
    setPage(e)
    setPagination({ PageNo: e + Number(1), PageSize: numberOfItemsPerPage, SortBy: "DEAL_ID", SortOrder: "DESC" });
  }
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "DEAL_ID", SortOrder: "DESC" });
  React.useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      dealsCall();
    }
  }, [pagination])
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const dealsCall = () => {
    setIsLoading(true);
    let formData = {
      "search": "",
      "search_by_filter": dealMenu,
      "Brand_Id": "",
      "CASHBACK_LABEL": "",
      "Category_Id": "",
      "DISCOUNT_LABEL": "",
      "Deal_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Status": "",
      "Title": ""
    };

    dispatch(getAllDealAction(formData, pagination))
  };
  //filter dropdown
  const [showDropDown, setShowDropDown] = React.useState(false);

  //API dispatch
  const dispatch = useDispatch()
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  const refreshCall = () => {
    setIsLoading(true);
    let pagecall = { PageNo: 1, PageSize: 5, SortBy: "DEAL_ID", SortOrder: "DESC" }
    let formData = {
      "search": "",
      "search_by_filter": "",
      "Brand_Id": "",
      "CASHBACK_LABEL": "",
      "Category_Id": "",
      "DISCOUNT_LABEL": "",
      "Deal_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Status": "",
      "Title": ""
    };
    setPage(0)
    numberItemsPerPageChange(5)
    dispatch(getAllDealAction(formData, pagecall))
  };
  //Static formData
  const permissions = useSelector(state => state.auth.permissions);
  React.useEffect(() => {
    let formData = {
      "search": "",
      "search_by_filter": dealMenu,
      "Brand_Id": "",
      "CASHBACK_LABEL": "",
      "Category_Id": "",
      "DISCOUNT_LABEL": "",
      "Deal_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Status": "",
      "Title": ""
    };
    setDeals(deals);
    dispatch(getAllDealAction(formData, pagination))
  }, [permissions]);



  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  //sorting, pagination & search
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshCall();
    wait(2000).then(() => setRefreshing(false));
  }, []);
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Deal_Id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setPagination({ PageNo: 1, PageSize: numberOfItemsPerPage, SortBy: property, SortOrder: order === 'ascending' ? "ASC" : "DESC" })
    setOrderBy(property);
    setPage(0);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  // View Dialog
  const [visible, setVisible] = React.useState(false);
  const [dealData, setData] = React.useState();
  const hideDialog = () => setVisible(false);
  const handleChange = itemValue => {
    setDealMenu(itemValue);
  };
  const handleSearch = value => {
    let formData = {
      "search": value,
      "search_by_filter": dealMenu,
      "Brand_Id": "",
      "CASHBACK_LABEL": "",
      "Category_Id": "",
      "DISCOUNT_LABEL": "",
      "Deal_Id": "",
      "Partner_Details_Id": EmailID?.Partner_Details_Id,
      "Records_Filter": "FILTER",
      "Status": "",
      "Title": ""
    };
    setDealSearch(value)
    dispatch(getAllDealAction(formData, pagination))
  };

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text
    },
  };

  const style = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      textAlign: "center",
      width: 200
    },
    titletext: {
      fontWeight: "bold",
      fontSize: 14,
      color: esiColor.Text,
    },
    tablerow: {
      justifyContent: "center",
      textAlign: "center",
      width: 80
    },
    mainbox: {
      textAlign: 'center',
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
    },
    databeBox: {
      margin: 10,
      textAlign: 'center',
    },
    databeHeader: {
      margin: 10,
      textAlign: 'center',
      borderBottomColor: esiColor.SBorderColor,
    },
    dropdown: {
      height: 50,
      borderColor: esiColor.SBorderColor,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    containerstyle: {
      width: 150
    },
    rowsPerPageContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      justifyContent: 'flex-end',
      borderTopColor: esiColor.SBorderColor,
      borderWidth: 0.5,
    },
    rowsPerPagePicker: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.itemColor
    },
    rowsPerPagePickers: {
      margin: 10,
      width: 70,
      height: 40,
      borderRadius: 10,
      borderColor: 'gray',
      borderWidth: 0.5,
      paddingHorizontal: 8,
      backgroundColor: esiColor.SIconColor
    },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      // backgroundColor: esiColor.SIconColor
    },
    paginationButton: {
      padding: 10,
      marginHorizontal: 10,
      // backgroundColor: esiColor.SIconColor
    },
    pageInfoText: {
      fontSize: 16,
      padding: 10,
      fontWeight: 'bold',
      color: esiColor.itemColor
    },
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
          <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
            <IconButton icon="close" color={esiColor.GBFColor} size={20} onPress={() => setVisible(!visible)} />
          </View>
          <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
            <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>View Deal</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 5 }}>
              <Dialog.Content style={{ marginLeft: -30 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Deal ID</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.GBFColor }}>{dealData?.Deal_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Title</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Title} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Brand ID</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Brand_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Category ID</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Category_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Start Date</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Start_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>End Date</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.End_Date} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discount Type</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Discount_Type} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discount</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Discount} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Discount Label</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Discount_Label} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>ESI Cashback Type</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Esi_Cashback_Type} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Esi Cashback Amount</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Esi_Cashback_Amount} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Cashback Label</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Cashback_Label} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Mobile Redirect URL</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Mobile_Redirect_Url} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Web Redirect URL</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Web_Redirect_Url} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Description</Text></View>
                  <View style={{ flex: 2, marginTop: -15 }}><RenderHtml source={{ html: `<span style="color: ${esiColor.Text}">${dealData?.Deal_Description}</span>` }} /></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Verified Deal</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Verified_Deal} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Whats Hot</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Whats_Hot} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Exclusive</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Exclusive} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Extra Cashback</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Extra_Cashback} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Is Top Deal</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Is_Top_Deal} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Created_By_Email_Id} </Text></View>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1.6 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Modified By</Text></View>
                  <View style={{ flex: 2 }}><Text style={{ color: esiColor.itemColor }}>{dealData?.Modified_By} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>

      <ScrollView>
        <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", flexShrink: 1 }}>
          <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => {
            navigation.navigate('PartnerMainCenter')
            handleSearch("")
            dispatch(resetFilter())
          }
          }>
            Go Back
          </Button>
        </View>
        <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
          <View style={{ flexDirection: "column" }}>
            <Text style={{ fontSize: 22, fontWeight: "bold", paddingTop: 10, paddingLeft: 15, color: esiColor.brandFontColor }}>Deal Management</Text>
          </View>
          {(permissions.Deals_Management === "write") &&
            <View style={{ flexWrap: "wrap", flexShrink: 1, marginLeft: 10, marginRight: 14, marginTop: 10, marginBottom: 20 }}>
              <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => navigation.navigate('AddDealBasic')}>
                ADD DEAL
              </Button>
            </View>
          }
        </View>

        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 500 ? 1.8 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[style.dropdown]}
                placeholderStyle={style.placeholderStyle}
                selectedTextStyle={style.selectedTextStyle}
                inputSearchStyle={style.inputSearchStyle}
                iconStyle={style.iconStyle}
                data={filterOptions}
                containerStyle={style.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={dealMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={dealMenu}
                style={{ height: 50, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="ALL" />
                <Picker.Item label="Deal ID" value="Deal_Id" />
                <Picker.Item label="Partner Name" value="Partner_Name" />
                <Picker.Item label="Title" value="Title" />
                <Picker.Item label="Start Date" value="Start_Date" />
                <Picker.Item label="End Date" value="End_Date" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>
          }
          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.1 }} />
          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              theme={esiColor.pagination_theme}
              placeholderTextColor={esiColor.SPHTextColor}
              style={{
                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.BackgroundColor
              }}
              placeholder="Search"
              onChangeText={(value) => handleSearch(value)}
              value={dealSearch}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1 }}>
          <ScrollView refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              colors={['#27B6CC']}
              tintColor={'#27B6CC'}
              title={"Pull to refresh"}
              titleColor={"#27B6CC"}
            />
          } horizontal={dimensions >= 700 ? false : true}>
            <View style={style.mainbox}>
              <Card>
                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                  <DataTable.Header style={style.databeHeader}>
                    <DataTable.Title  style={style.title} active={orderBy === 'Deal_Id'}
                      sortDirection={orderBy === 'Deal_Id' ? order : 'ascending'}
                      onPress={createSortHandler('Deal_Id')}><Text style={style.titletext}>Deal ID</Text></DataTable.Title>

                    <DataTable.Title  style={style.title} active={orderBy === 'Partner_Name'}
                      sortDirection={orderBy === 'Partner_Name' ? order : 'ascending'}
                      onPress={createSortHandler('Name')}><Text style={style.titletext}>Partner Name</Text></DataTable.Title>

                    <DataTable.Title  style={style.title} active={orderBy === 'Title'}
                      sortDirection={orderBy === 'Title' ? order : 'ascending'}
                      onPress={createSortHandler('Title')}><Text style={style.titletext}>Title</Text></DataTable.Title>

                    <DataTable.Title  style={style.title} active={orderBy === 'Start_Date'}
                      sortDirection={orderBy === 'Start_Date' ? order : 'ascending'}
                      onPress={createSortHandler('Start_Date')}><Text style={style.titletext}>Start Date</Text></DataTable.Title>

                    <DataTable.Title  style={style.title} active={orderBy === 'End_Date'}
                      sortDirection={orderBy === 'End_Date' ? order : 'ascending'}
                      onPress={createSortHandler('End_Date')}><Text style={style.titletext}>End Date</Text></DataTable.Title>

                    <DataTable.Title  style={style.title} active={orderBy === 'Status'}
                      sortDirection={orderBy === 'Status' ? order : 'ascending'}
                      onPress={createSortHandler('Status')}><Text style={style.titletext}>Status</Text></DataTable.Title>

                    <DataTable.Title theme={customTheme} style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                  </DataTable.Header>
                  {
                    deals?.map((deal, index) => (
                      <DataTable.Row style={style.databeBox} key={deal.Deal_Id}>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{deal.Deal_Id}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{deal.Partner_Name}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{deal.Title?.substring(0, 10)} {deal.Title?.length > 10 && "..."}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{deal.Start_Date}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{deal.End_Date}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{deal.Status}</Text></DataTable.Cell>
                        <DataTable.Cell style={style.tablerow}>
                          <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(deal); setVisible(!visible) }} />
                        </DataTable.Cell>
                      </DataTable.Row>
                    ))
                  }
                  {isEmpty(deals) &&
                    <View>
                      <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                        No records found!
                      </Text>
                      <Image source={require('../../assets/images/nodata.gif')}
                        style={{ width: 300, height: 300, alignSelf: "center" }} />
                    </View>
                  }
                  {Platform.OS !== "web" ?

                    <DataTable.Pagination
                      // theme={customTheme}
                      page={page}
                      numberOfPages={Math.ceil(Alldeals?.Pagination?.TotalCount / numberOfItemsPerPage)}
                      onPageChange={page => DynamicPage(to < Alldeals?.Pagination?.TotalCount ? page : 0)}
                      label={Alldeals?.Pagination?.TotalCount ? `${from + 1}-${to} of ${Alldeals?.Pagination?.TotalCount}` : '0-0'}
                      showFastPaginationControls={(Number(page) + Number(1)) > Alldeals?.Pagination?.TotalPages ? false : true}
                      numberOfItemsPerPageList={numberOfItemsPerPageList}
                      numberOfItemsPerPage={numberOfItemsPerPage}
                      onItemsPerPageChange={onItemsPerPageChange}
                      selectPageDropdownLabel={'Rows per page'}
                    />
                    :
                    <View style={style.rowsPerPageContainer}>
                      <Text style={{color: esiColor.Text}}>rows per page: </Text>
                      <Picker
                        style={style.rowsPerPagePicker}
                        selectedValue={numberOfItemsPerPage}
                        onValueChange={(e) => onItemsPerPageChange(e)}
                      >
                        <Picker.Item label="5" value={5} />
                        <Picker.Item label="10" value={10} />
                        <Picker.Item label="20" value={20} />
                      </Picker>
                      <View style={style.paginationContainer}>
                        <TouchableOpacity onPress={e => DynamicPagedecrese(webpage >= 1 ? webpage : 1)} style={style.paginationButton}>
                          <Icon name="angle-left" size={20} color={esiColor.SIconColor} />
                        </TouchableOpacity>
                        <Text style={style.pageInfoText}>
                          {Alldeals?.Pagination?.PageNo + ' of ' + Alldeals?.Pagination?.TotalPages}
                        </Text>
                        <TouchableOpacity onPress={e => DynamicPageIncrease(to < Alldeals?.Pagination?.TotalCount ? webpage >= 1 ? webpage : 1 : 1)} style={style.paginationButton}>
                          <Icon name="angle-right" size={20} color={esiColor.SIconColor} />
                        </TouchableOpacity>
                      </View>
                    </View>}
                </DataTable>
              </Card>
            </View>
          </ScrollView>

        </View>
      </ScrollView>
    </Surface>

  );
}

export default DealDetails