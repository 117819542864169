import {
    B2B_USER_PARTNER_COMMENT_QUOTES_FAIL,
    B2B_USER_PARTNER_COMMENT_QUOTES_REQUEST,
    B2B_USER_PARTNER_COMMENT_QUOTES_SUCCESS,
    B2B_USER_PARTNER_QUOTES_FAIL,
    B2B_USER_PARTNER_QUOTES_REQUEST,
    B2B_USER_PARTNER_QUOTES_SUCCESS,
    B2B_USER_PARTNER_QUOTES_UPDATE_FAIL,
    B2B_USER_PARTNER_QUOTES_UPDATE_REQUEST,
    B2B_USER_PARTNER_QUOTES_UPDATE_SUCCESS
} from "../actions/b2cpartnerquoteAction";

const initialState = {
    b2buserpartnerQuotes: { all: [], error: '', isLoading: false },
    b2buserpartnerQuotesUpdate: { b2buserpartnerQuotesUpdate: {}, error: '', isLoading: false },
    b2buserpartnercommentQuotes: { all: [], error: '', isLoading: false },
    b2buserpartnercommentQuotesCreate: { b2buserpartnercommentQuotesCreate: {}, error: '', isLoading: false },
};

export default function b2buserpartnerReducer(state = initialState, action: any) {
    switch (action.type) {

        case B2B_USER_PARTNER_QUOTES_REQUEST:
            return { ...state, b2buserpartnerQuotes: { all: state.b2buserpartnerQuotes.all, error: '', isLoading: true }, };
        case B2B_USER_PARTNER_QUOTES_SUCCESS:
            return { ...state, b2buserpartnerQuotes: { all: action.payload, error: '', isLoading: false } };
        case B2B_USER_PARTNER_QUOTES_FAIL:
            return { ...state, b2buserpartnerQuotes: { all: [], error: action.payload, isLoading: false } };

        case B2B_USER_PARTNER_QUOTES_UPDATE_REQUEST:
            return { ...state, b2buserpartnerQuotesUpdate: { b2buserpartnerQuotesUpdate: {}, error: '', isLoading: true } };
        case B2B_USER_PARTNER_QUOTES_UPDATE_SUCCESS:
            return { ...state, b2buserpartnerQuotesUpdate: { b2buserpartnerQuotesUpdate: state.b2buserpartnerQuotesUpdate.b2buserpartnerQuotesUpdate, error: '', isLoading: false } };
        case B2B_USER_PARTNER_QUOTES_UPDATE_FAIL:
            return { ...state, b2buserpartnerQuotesUpdate: { b2buserpartnerQuotesUpdate: {}, error: action.payload, isLoading: false } };

        case B2B_USER_PARTNER_COMMENT_QUOTES_REQUEST:
            return { ...state, b2buserpartnercommentQuotes: { all: state.b2buserpartnercommentQuotes.all, error: '', isLoading: true }, };
        case B2B_USER_PARTNER_COMMENT_QUOTES_SUCCESS:
            return { ...state, b2buserpartnercommentQuotes: { all: action.payload, error: '', isLoading: false } };
        case B2B_USER_PARTNER_COMMENT_QUOTES_FAIL:
            return { ...state, b2buserpartnercommentQuotes: { all: [], error: action.payload, isLoading: false } };

        case B2B_USER_PARTNER_QUOTES_UPDATE_REQUEST:
            return { ...state, b2buserpartnerQuotesUpdb2buserpartnercommentQuotesCreateate: { b2buserpartnercommentQuotesCreate: {}, error: '', isLoading: true } };
        case B2B_USER_PARTNER_QUOTES_UPDATE_SUCCESS:
            return { ...state, b2buserpartnerQuotesb2buserpartnercommentQuotesCreateUpdate: { b2buserpartnercommentQuotesCreate: state.b2buserpartnercommentQuotesCreate.b2buserpartnercommentQuotesCreate, error: '', isLoading: false } };
        case B2B_USER_PARTNER_QUOTES_UPDATE_FAIL:
            return { ...state, b2buserpartnercommentQuotesCreate: { b2buserpartnercommentQuotesCreate: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}