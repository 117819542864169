
import axios from 'axios';
import config from './config';
import { meeapi } from './node-actions/meeapi';

export const B2B_USER_PARTNER_QUOTES_REQUEST = "B2B_USER_PARTNER_QUOTES_REQUEST";
export const B2B_USER_PARTNER_QUOTES_SUCCESS = "B2B_USER_PARTNER_QUOTES_SUCCESS";
export const B2B_USER_PARTNER_QUOTES_FAIL = "B2B_USER_PARTNER_QUOTES_FAIL";

export const B2B_USER_PARTNER_QUOTES_UPDATE_REQUEST = "B2B_USER_PARTNER_QUOTES_UPDATE_REQUEST";
export const B2B_USER_PARTNER_QUOTES_UPDATE_SUCCESS = "B2B_USER_PARTNER_QUOTES_UPDATE_SUCCESS";
export const B2B_USER_PARTNER_QUOTES_UPDATE_FAIL = "B2B_USER_PARTNER_QUOTES_UPDATE_FAIL";

export const B2B_USER_PARTNER_COMMENT_QUOTES_REQUEST = "B2B_USER_PARTNER_COMMENT_QUOTES_REQUEST";
export const B2B_USER_PARTNER_COMMENT_QUOTES_SUCCESS = "B2B_USER_PARTNER_COMMENT_QUOTES_SUCCESS";
export const B2B_USER_PARTNER_COMMENT_QUOTES_FAIL = "B2B_USER_PARTNER_COMMENT_QUOTES_FAIL";

export const B2B_USER_PARTNER_SINGLE_QUOTES_REQUEST = "B2B_USER_PARTNER_SINGLE_QUOTES_REQUEST";
export const B2B_USER_PARTNER_SINGLE_QUOTES_SUCCESS = "B2B_USER_PARTNER_SINGLE_QUOTES_SUCCESS";
export const B2B_USER_PARTNER_SINGLE_QUOTES_FAIL = "B2B_USER_PARTNER_SINGLE_QUOTES_FAIL";

export const B2B_USER_PARTNER_QUOTES_CREATE_REQUEST = "B2B_USER_PARTNER_QUOTES_CREATE_REQUEST";
export const B2B_USER_PARTNER_QUOTES_CREATE_SUCCESS = "B2B_USER_PARTNER_QUOTES_CREATE_SUCCESS";
export const B2B_USER_PARTNER_QUOTES_CREATE_FAIL = "B2B_USER_PARTNER_QUOTES_CREATE_FAIL";

const headers = config.headersCommon;
const urlGenarator = (url: any, pagination: any) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}
const cudheaders = config.nodeheadersCommon;
export const getAllB2BPartnerBiddingQuotesAction = (formData: any, pagination = {}) => async (dispatch: any) => {
    try {
        dispatch({
            type: B2B_USER_PARTNER_QUOTES_REQUEST
        });
        meeapi.post(urlGenarator(`/B2C_Partner_Bidding_Quotes/Get`, pagination),
            formData,
        ).then(response => {
            let data = response.data;
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: B2B_USER_PARTNER_QUOTES_SUCCESS,
                    payload: data
                });
            } else {
                dispatch({
                    type: B2B_USER_PARTNER_QUOTES_SUCCESS,
                    payload: data
                });

            }
        })
    } catch (err) {
        dispatch({
            type: B2B_USER_PARTNER_QUOTES_FAIL,
            payload: err
        });
    }
};

export const updateB2BuserpartnerQuote = (formData: any, navigation: any, toast: any) => async (dispatch: any) => {
    dispatch({
        type: B2B_USER_PARTNER_QUOTES_UPDATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/B2B_Partner_Bidding_Quote/Update`,
        formData,
        {
            headers: cudheaders
        })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: B2B_USER_PARTNER_QUOTES_UPDATE_SUCCESS
                });
                navigation.navigate('B2BUserPartnerBiddingQuotes', { productpartnerparams: formData.Product_Id });
                toast.show({ message: 'Quote Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
                const pagination = {
                    PageNo: 1,
                    PageSize: 5,
                    SortBy: "B2B_Partner_Bidding_Quote_Id",
                    SortOrder: "DESC"
                };
                const formValue = {
                    "B2B_User_Products_Bidding_Id": '',
                    "Partner_Detail_Id": formData.Partner_Details_Id,
                    "Records_Filter": "FILTER",
                    "Buyer_Partner_Detail_Id": "",
                    "search_by_filter": "",
                    "search": ""
                };
                dispatch(getAllB2BPartnerBiddingQuotesAction(formValue, pagination))
            } else {
                dispatch({
                    type: B2B_USER_PARTNER_QUOTES_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: B2B_USER_PARTNER_QUOTES_UPDATE_FAIL,
                payload: "Please try sometime later.Currently server is not working."
            });
        })
};
export const getpartnerAddressIdAction = (formData: any, callBackData: any,pagination:any) => async dispatch => {
     try {
    meeapi.post(urlGenarator(`/Partner_Address/Get`,pagination),
        formData,
        ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        callBackData([])
      }else {
         callBackData(data.results)
      }
    })
    } catch (err) {
     dispatch({
        payload: err
      });
    }
  };


export const getSingleB2BPartnerBiddingQuotesAction = (formData: any, callBackGetData: any, pagination: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: B2B_USER_PARTNER_SINGLE_QUOTES_REQUEST
        });
        meeapi.post(urlGenarator(`/B2C_Partner_Bidding_Quotes/Get`, pagination),
            formData).then(response => {
                let data = response.data;
                if (data) {
                    if (data.Success_Response.Is_Data_Exist === '0') {
                        callBackGetData([])
                        dispatch({
                            type: B2B_USER_PARTNER_SINGLE_QUOTES_SUCCESS,
                            payload: data
                        });
                    } else {
                        callBackGetData(data.results)
                        dispatch({
                            type: B2B_USER_PARTNER_SINGLE_QUOTES_SUCCESS,
                            payload: data.results
                        });
                    }
                }
            })
    } catch (err) {
        dispatch({
            type: B2B_USER_PARTNER_SINGLE_QUOTES_FAIL,
            payload: err
        });
    }
};

export const getAllB2BPartnerBiddingCommentsQuotesAction = (formData: any, callBackGetData: any, pagination: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: B2B_USER_PARTNER_COMMENT_QUOTES_REQUEST
        });
        meeapi.post(urlGenarator(`/B2C_Bidding_Product_Comments/Get`, pagination),
            formData).then(response => {
                let data = response.data;
                if (data) {
                    if (data.Success_Response.Is_Data_Exist === '0') {
                        callBackGetData([])
                        dispatch({
                            type: B2B_USER_PARTNER_COMMENT_QUOTES_SUCCESS,
                            payload: data
                        });
                    } else {
                        callBackGetData(data.results)
                        dispatch({
                            type: B2B_USER_PARTNER_COMMENT_QUOTES_SUCCESS,
                            payload: data.results
                        });
                    }
                }
            })
    } catch (err) {
        dispatch({
            type: B2B_USER_PARTNER_COMMENT_QUOTES_FAIL,
            payload: err
        });
    }
};

export const AddPartnerBiddingB2buserCommentsQuotesAction = (formData: any, getid: any, resetForm: any, setReload: any, callBackGetData: any,) => async (dispatch: any) => {
    dispatch({
        type: B2B_USER_PARTNER_QUOTES_CREATE_REQUEST
    });
    axios.post(`${config.nodecudurl}/B2B_Bidding_Product_Comments/Create`, formData, {
        headers: cudheaders
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: B2B_USER_PARTNER_QUOTES_CREATE_SUCCESS
                });
                resetForm()
                setReload(true)
                const pagination = {
                    PageNo: 1,
                    PageSize: 5,
                    SortBy: "b2b_Bidding_Product_Comment_Id",
                    SortOrder: "ASC"
                };
                const formValue = {
                    Records_Filter: 'FILTER',
            Partner_Bidding_Product_Quote_Id: getid
      
                };
                dispatch(getAllB2BPartnerBiddingCommentsQuotesAction(formValue, callBackGetData, pagination))
            } else {
                dispatch({
                    type: B2B_USER_PARTNER_QUOTES_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: B2B_USER_PARTNER_QUOTES_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};