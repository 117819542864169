import 'react-native-gesture-handler';
import * as React from 'react';
import { View, Platform } from "react-native";
import { Avatar, Colors, Surface, TouchableRipple } from "react-native-paper";
import * as Location from "expo-location";
import isEmpty from '../../../state/validations/is-empty';
import DraggableMap from '../map/DraggableMap/DraggableMap';
import { useSelector } from 'react-redux';



function AddressLocation(props: any) {
  //props variable consists of all <List.Accordion> properties along with map data.
  const { isLocationVisible, setIsLocationVisible, filters, setFilters } = props;
  const [locationLoad, setLocationLoad] = React.useState(false);
  const [locationStock, setLocationStock] = React.useState(false);
  const [deviceLocation, setLocation] = React.useState({latitude:filters?.latitude,longitude:filters?.longitude});
  React.useEffect(() => {
    (async () => {
      if ((isEmpty(deviceLocation.latitude) || isEmpty(deviceLocation.longitude)) && !locationStock) {
        setLocationLoad(true);
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          setLocation({ latitude: 12.956161044024359, longitude: 77.69511468708515 });
          setLocationStock(true);
          setLocationLoad(false);
          return;
        }
        const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
        setLocation({ latitude, longitude });
      }else if((isEmpty(deviceLocation.latitude) || isEmpty(deviceLocation.longitude))){
        setLocation({ latitude: 12.956161044024359, longitude: 77.69511468708515 });
      }
    })();
  })
  const [manuvalZip, setManuvalZip] = React.useState("");


  React.useEffect(() => {
   
  }, [manuvalZip])
  const esiColor = useSelector(state => state.theme);

  return (
    <Surface style={{
      // alignItems: 'flex-end',
      justifyContent: 'center',
      borderRadius: 10,
      top: Platform.OS === 'web' ? 10 : 0,
      elevation: 0,  backgroundColor: esiColor.BackgroundColor
    }} >
      <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
        setIsLocationVisible(!isLocationVisible);
      }}>
        <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor:esiColor.BackgroundColor }} size={36} icon="close" />
      </TouchableRipple>
      <View>
          <View style={{ maxWidth: 250, minWidth: 250, maxHeight: 400, minHeight: 350,marginLeft:25,marginTop:20 }} >
            <DraggableMap latitude={deviceLocation.latitude} longitude={deviceLocation.longitude} setZipcode={(value) => { setManuvalZip(value) }} showSelectedAddress={true} setAddressWithLanLog = {(address,lan,lon,zip)=>{
              setFilters(address,lan,lon,zip);
            }}  />
          </View>
      </View>
    </Surface>
  );
}

export default AddressLocation;