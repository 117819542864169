import {
BIDDING_SERVICES_QUOTES_REQUEST,
BIDDING_SERVICES_QUOTES_SUCCESS,
BIDDING_SERVICES_QUOTES_FAIL,
BIDDING_SERVICE_FILTER,

PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_REQUEST,
PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS,
PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_FAIL,

SINGLE_BIDDING_SERVICE_QUOTES_REQUEST,
SINGLE_BIDDING_SERVICE_QUOTES_SUCCESS,
SINGLE_BIDDING_SERVICE_QUOTES_FAIL,

} from '../actions/partnerbiddingServiceQuotesAction';

const initialState = {
  bidding_service_quotes: { all: [], error: '', isLoading: false },
  single_service_quote: { all: [], error: '', isLoading: false },
  biddingServiceQuoteUpdate: { partnerservice: {}, error: '', isLoading: false },
  biddingserviceFilter: { select: 'All', search: '' }, 
};

export default function (state = initialState, action:any) {
  switch (action.type) {
    case BIDDING_SERVICES_QUOTES_REQUEST:
      return { ...state, bidding_service_quotes: { all: state.bidding_service_quotes.all, error: '', isLoading: true } };
    case BIDDING_SERVICES_QUOTES_SUCCESS:
      return { ...state, bidding_service_quotes: { all: action.payload, error: '', isLoading: false } };
    case BIDDING_SERVICES_QUOTES_FAIL:
      return { ...state, bidding_service_quotes: { all: [], error: action.payload, isLoading: false } };

      case SINGLE_BIDDING_SERVICE_QUOTES_REQUEST:
      return { ...state, single_service_quote: { all: state.single_service_quote.all, error: '', isLoading: true } };
    case SINGLE_BIDDING_SERVICE_QUOTES_SUCCESS:
      return { ...state, single_service_quote: { all: action.payload, error: '', isLoading: false } };
    case SINGLE_BIDDING_SERVICE_QUOTES_FAIL:
      return { ...state, single_service_quote: { all: [], error: action.payload, isLoading: false } };
      case PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_REQUEST:
        return { ...state, biddingServiceQuoteUpdate: { partnerservice: {}, error: '', isLoading: true } };
      case PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS:
        return { ...state, biddingServiceQuoteUpdate: { partnerservice: state.biddingServiceQuoteUpdate.partner, error: '', isLoading: false } };
      case   PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_FAIL:
        return { ...state, biddingServiceQuoteUpdate: { partnerservice: {}, error: action.payload, isLoading: false } };
     
     case BIDDING_SERVICE_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, biddingserviceFilter: { search: state.biddingserviceFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, biddingserviceFilter: { select: state.biddingserviceFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}



