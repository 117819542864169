import 'react-native-gesture-handler';
import React, {useEffect } from 'react';
import { Avatar, Card, Text } from "react-native-paper";
import { useDispatch, useSelector } from 'react-redux';
import { Dimensions, Platform, View } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { dashboardbookingCount } from '../../../../../state/actions/Properties/PropertiesAction';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

function TotalBookings({navigate}) {
    //API dispatch
    const dispatch = useDispatch();
    //totalbookings contains the data from  GET API using state call
    const totalbookings = useSelector(state => state.property.bookingcount.data);
    const EmailID = useSelector(state => state.auth?.user);
      //dispatch is used to call the API
      useEffect(() => {
        if(EmailID){
        let formData = {
            "Partner_Detail_ID": EmailID?.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        dispatch(dashboardbookingCount(formData))
    }
    }, [EmailID]);

    return (
        <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', padding: 5 }}>
            <Card style={{
                borderRadius: 10, flex: 1, padding: 10, backgroundColor: 'white', margin: 10, flexDirection: 'row', shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, elevation: 10, marginLeft: 10, marginRight: 10,
                maxWidth: Platform.OS === "ios" ? windowWidth > 300 ? 450 : 450 : 400, minWidth: Platform.OS === "ios" ? 350 : 320
            }}>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -70 }}>
                    <Avatar.Icon size={50} icon="chart-bar" color="white" style={{ backgroundColor: 'orange' }} />
                </View>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                    <Text style={{ padding: 10, fontWeight: 'bold' }}>TOTAL BOOKINGS</Text>
                    <Text style={{ padding: 10, fontSize: 20, fontWeight: "bold" }}>{totalbookings.Total}</Text>
                </View>
                <View style={{ flexDirection: 'row', margin: 10 }}>
                    {totalbookings.Month - totalbookings.Avg >= 0 &&
                        <>
                            <Ionicons name="arrow-up" size={25} color="green" />
                            <View style={{ flexDirection: 'column', margin: 5 }}>
                                <Text >
                                    {totalbookings.Month}
                                </Text>
                            </View>
                        </>
                    }
                    {totalbookings.Month - totalbookings.Avg < 0 &&
                        <>
                            <Ionicons name="arrow-down" size={25} color="red" />
                            <View style={{ flexDirection: 'column', margin: 5 }}>
                                <Text >
                                    {totalbookings.Month}
                                </Text>
                            </View>
                        </>
                    }

                    <View style={{ flexDirection: 'column', margin: 5 }}>
                        <Text >
                            Since last 30 days
                        </Text>
                    </View>

                </View>
            </Card>
        </View>
    );
}


export default TotalBookings;