import axios from 'axios';
import { getLogedInUser, loginOutSuccess } from './authActions';
import config from './config';
import AsyncStorage from '@react-native-async-storage/async-storage';

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

//   ====================
//  Change Password:
//  ====================
const headers = config.headersCommon;
const removeValue = async () => {
  try {
      await AsyncStorage.removeItem('user')
      await AsyncStorage.removeItem('Total_Count')
  } catch (e) {
  }

}
const getDataAsync = async () => {
  try {
      const jsonValue = await AsyncStorage.getItem('user');
      return jsonValue != null ? jsonValue : null;
  } catch (e) {
      // error reading value
  }
}
export const ChangePasswordAction =
  (updateStates, formData) => async (dispatch) => {
    try {
      let { data } = await axios.post(`${config.url}/Partner_Login/Partner_Change_Password`,
      formData, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=ut',
          Authorization:
            'Basic RVNJSGVhbHRoQGVhc3lzdGVwaW4tNldWMVVSOmYwZWUyYTRlLTUxMzgtNDc0Mi1iZjhiLTZjZGY1M2M1OTYzMw=='
        }
      });

      if (data) {
        updateStates(data, false);
      }
    } catch (error) {
    }
  };




 export const changePasswordAction =
  (formData:any, changePasswordResponse:any, resetForm:any,toast: any,navigate:any) => async (dispatch:any) => {
    try {
      let { data } = await axios.post(`${config.url}/Partner_Login/Partner_Change_Password`,   
	  formData,
        {
          headers:headers
        }
		);

      if (data) {
        if(data.Response_Status=="Success"){
          changePasswordResponse(true, "");
          resetForm();
          toast.show({ message: 'Password is changed successfully.', type: 'info', duration: 3000, position: 'top' });
          removeValue().then((data) => {
            getDataAsync().then((data) => {
                navigate.replace('SignIn');
                // setLogoutDisabile(true);
                dispatch(loginOutSuccess());
            })
        });
        }else{
          changePasswordResponse(false, data.UI_Display_Message);
        }
      }
    } catch (error) {
      changePasswordResponse(true, "Please try again.");
    }
  };
//   ======================
//    Upload Profile
//   =======================
  export const uploadProfileAction =
  (profilelBackUploadImage:any, img:any, emailid:any,user_parsed:any) => async (dispatch) => {
    try {
      let { data } = await axios.post(
        `${config.url}/Partner_Login/Partner_Profile_Pic_Image_Upload?filename=Sample&file_extension=jpg&filetype=image&emailid=${emailid}`,
        img,
        {
          headers:headers,
          transformRequest: (d) => d,
        }
      );

      if (data) {
        profilelBackUploadImage(data);
        dispatch(getLogedInUser(emailid,false,()=>{},user_parsed,()=>{},() => { }));
      }
    } catch (error) {
     }
  };