import React from 'react';
import { Card, Button, TextInput, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    getAllPartnerProductssearch,
    productpartnerdata
} from '../../../../state/actions/farmer/farmerpartnerproductsAction'
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import Header from '../../../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const Discount_Type = [
    {
        value: '',
        label: '-Please Select-'
    },
    {
        value: 'A',
        label: 'Amount'
    },
    {
        value: 'P',
        label: 'Percentage'
    }
];
const AddFarmerPartnerProducts = (props: any) => {
    const { navigation } = props;
    const dispatch = useDispatch();
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    const esiColor = useSelector(state => state.theme);
    const productpartnersid = props.route?.params?.productspartners;
    const productmasterids = props.route?.params?.productmasterid;
    const partnerproductssearch = useSelector(state => state.farmerpartnerproduct.partnerproductssearch.partners);
    const [showDropDownProductPartnersDiscount, setShowDropDownProductPartnersDiscount] = React.useState('');
    const [showProductMasterDropDown, setShowProductDropDown] = React.useState('');
    const [refreshing, setRefreshing] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const [price, setPrice] = React.useState('');
    React.useEffect(() => {
        setRefreshing(true);
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        dispatch(getAllPartnerProductssearch("1"))
    }, []);
    React.useEffect(() => {
        if (!(permissions.Products_Partner_Products_Related_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    let productopt = []
    if (partnerproductssearch) {
        if (partnerproductssearch[0]) {
            for (let i = 0; i < partnerproductssearch?.length; i++) {
                productopt.push(
                    {

                        Price: partnerproductssearch[i]?.Price,
                        value: partnerproductssearch[i]?.Product_Id,
                        label: partnerproductssearch[i]?.Product_Name.substring(0, 60),
                        Product_Master_Id: partnerproductssearch[i]?.Product_Master_Id
                    })
            }
        }
    }
    //screen dimensions
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const updateStates = (data) => {
        if (data) {
            dispatch(getAllPartnerProductssearch(data))
        }

    };
    //form reset
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(1000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        container: {
            paddingTop: 15
        }, images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        itemContainer: {
            justifyContent: 'flex-end',
            flex: 1
        },

        checkbox: {
            alignSelf: "center",
        },
        label: {
            marginBottom: 30,
        },
        phonetextInput: {
            flex: 1
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        icon: {
            marginRight: 5,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        countrydropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            left: 22,
            top: 0.5,
            color: esiColor.itemColor,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flexShrink: 1, backgroundColor: esiColor.BackgroundColor }} >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }
            >
                <Formik
                    initialValues={{
                        Price: "",
                        Partner_Original_Selling_Price: '',
                        Gift_Wrap_Amount: "",
                        Discount_Type: "",
                        Partner_Original_Discount_Amount: "",
                        Esi_Cashback_Type: "",
                        Esi_Cashback: "",
                        Esi_Commission: "",
                        Esi_Commission_Type: "",
                        Partner_Email_ID: EmailID.Email_Id,
                        Created_By: EmailID.Email_Id,
                        Partner_Address_Id: EmailID.Partner_Address_Id,
                        Product_Id: '',
                        Cashback_Label: 'UPTO',
                        Product_Master_ID: '',
                        Discount_Label: '',
                        Warranty_Period: '',
                        Partner_Original_Discount: ''
                    }}

                    enableReinitialize={true}
                    //field validations
                    validationSchema={Yup.object().shape({
                        Partner_Original_Selling_Price: Yup.number().required(' Price is required.'),
                        Product_Id: Yup.string().required(' Product Name is required.'),
                        // Warranty_Period: Yup.string().required('Warranty Period is required.'),
                        Discount_Type: Yup.string().test('oneOfRequired', 'Discount Type is required.',
                            function (item) {
                                return (this.parent.Discount_Type)
                            }
                        ),
                    })}
                    onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        setErrors(errors);
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            resetForm();
                            dispatch(productpartnerdata(formValues));
                            navigation.navigate('AddFarmerPartnerProductspagetwo')
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        resetForm
                    }) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        resetForm();
                                        navigation.navigate('FarmerPartnerProductsGet', { productpartnerparams: productpartnersid, productmaster: productmasterids })
                                    }} >
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                            Add Own Product Partner
                                        </Text>
                                        {price ? <Text style={{ fontSize: 16, color: esiColor.itemColor }}>
                                            Product Price: {price}
                                        </Text> : null}
                                    </View>

                                    <View style={{ flexGrow: 1, flexShrink: 1 }}>
                                        <View style={styles.container}>
                                            <Text style={[styles.dropdownlabel, Boolean(touched.Product_Id && errors.Product_Id) && { color: '#b00020' }]}>
                                                Product Name
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(touched.Product_Id && errors.Product_Id) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={productopt}
                                                labelField="label"
                                                valueField="value"
                                                searchQuery={item => { updateStates(item) }}
                                                maxHeight={productopt.length > 1 ? 250 : 200}
                                                search
                                                searchPlaceholder={'Select Product Name'}
                                                value={values.Product_Id}
                                                onUpdateValue={item => {

                                                    item &&
                                                        setFieldValue('Product_Id', item?.value),
                                                        setFieldValue("Product_Master_ID", item?.Product_Master_Id),
                                                        setPrice(item?.Price ? item.Price : "");
                                                    setFieldValue("Price", item?.Price);
                                                    setShowProductDropDown(false);
                                                }}

                                            />
                                            <HelperText type="error" visible={Boolean(touched.Product_Id && errors.Product_Id)}>
                                                {touched.Product_Id && errors.Product_Id}
                                            </HelperText>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap"
                                        }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                                <View style={styles.container}>
                                                    <Text style={[styles.dropdownlabel, Boolean(touched.Discount_Type && errors.Discount_Type) && { color: '#b00020' }]}>
                                                        Discount Type
                                                    </Text>
                                                    <EsiSearchDropdown
                                                        style={[styles.dropdown, Boolean(touched.Discount_Type && errors.Discount_Type) && { borderColor: '#b00020' }]}
                                                        placeholderStyle={styles.placeholderStyle}
                                                        selectedTextStyle={styles.selectedTextStyle}
                                                        inputSearchStyle={styles.inputSearchStyle}
                                                        iconStyle={styles.iconStyle}
                                                        data={Discount_Type}
                                                        labelField="label"
                                                        valueField="value"
                                                        maxHeight={Discount_Type.length > 1 ? 250 : 200}
                                                        search={Discount_Type.length > 1 ? true : false}
                                                        searchPlaceholder={'Select Discount Type'}
                                                        placeholder={!showDropDownProductPartnersDiscount ? 'Select item' : '...'}
                                                        value={values.Discount_Type}
                                                        onUpdateValue={item => {
                                                            setFieldValue('Discount_Type', item.value)
                                                            setFieldValue("Partner_Original_Discount_Amount", '');
                                                            setFieldValue("Partner_Original_Selling_Price", '');
                                                            setShowDropDownProductPartnersDiscount(false);
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Discount_Type && errors.Discount_Type)}>
                                                        {touched.Discount_Type && errors.Discount_Type}
                                                    </HelperText>
                                                </View>
                                            </View>
                                            <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: 10 }}>
                                                <TextInput
                                                    theme={customTheme}
                                                    style={{
                                                        marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                        // borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                    }}
                                                    error={Boolean(touched.Partner_Original_Discount_Amount && errors.Partner_Original_Discount_Amount)}
                                                    label={<Text style={{ color: esiColor.itemColor }}>{"Discount" + (values.Discount_Type === "A" ? "Amount ₹" : values.Discount_Type === "P" ? "Percentage %" : "")}</Text>}
                                                    mode='outlined'
                                                    disabled={values.Discount_Type ? false : true}
                                                    value={values.Partner_Original_Discount_Amount}
                                                    onChangeText={item => {
                                                        let PPrice = values.Discount_Type == "P" ? (item / 100 * price).toFixed(2) : item
                                                        setFieldValue("Partner_Original_Discount_Amount", item);
                                                        setFieldValue("Partner_Original_Selling_Price", price - PPrice);
                                                        setFieldValue("Partner_Original_Discount", PPrice);
                                                    }}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Partner_Original_Discount_Amount && errors.Partner_Original_Discount_Amount)}>
                                                    {touched.Partner_Original_Discount_Amount && errors.Partner_Original_Discount_Amount}
                                                </HelperText>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: 10 }}>
                                                <TextInput
                                                    theme={customTheme}
                                                    style={{
                                                        marginTop: 15, backgroundColor: esiColor.BackgroundColor,
                                                        // borderColor: esiColor.TextOuterLine, borderWidth: 1
                                                    }}
                                                    error={Boolean(touched.Partner_Original_Selling_Price && errors.Partner_Original_Selling_Price)}
                                                    label={<Text style={{ color: esiColor.itemColor }}>The product amount we give you</Text>}
                                                    mode='outlined'
                                                    disabled
                                                    value={values.Partner_Original_Selling_Price.toString()}
                                                    onChangeText={handleChange("Partner_Original_Selling_Price")}
                                                    keyboardType='numeric'
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Partner_Original_Selling_Price && errors.Partner_Original_Selling_Price)}>
                                                    {touched.Partner_Original_Selling_Price && errors.Partner_Original_Selling_Price}
                                                </HelperText>
                                            </View>
                                            {/* <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: 10 }}>

                                                <TextInput
                                                    style={{ backgroundColor: 'white' }}
                                                    theme={{ colors: { primary: '#27B6CC' } }}
                                                    error={Boolean(touched.Warranty_Period && errors.Warranty_Period)}
                                                    label="Warranty Period"
                                                    mode='outlined'
                                                    multiline
                                                    value={values.Warranty_Period}
                                                    onChangeText={handleChange("Warranty_Period")}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Warranty_Period && errors.Warranty_Period)}>
                                                    {touched.Warranty_Period && errors.Warranty_Period}
                                                </HelperText>
                                            </View> */}
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                                        </View>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button textColor={esiColor.itemButtenColor} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                            Next
                                        </Button>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView >
        </Surface >
    );
};

export default AddFarmerPartnerProducts;