import {
    DASHBOARD_TOTAL_ORDERS,
    DASHBOARD_TOTAL_PROFIT,
    DASHBOARD_TOTAL_USERS,
    DASHBOARD_TOTAL_COUPONS,
    DASHBOARD_TOTAL_DEALS,
    DASHBOARD_TOTAL_PRODUCTS,
    DASHBOARD_TOTAL_BRANDS,
} from "../../actions/reports/dashboardActions";


const initialState = {
    Orders: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Profit: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Users: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Coupons: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Deals: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Products: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
    Brands: { data: { Total: 0, Avg: 0, Month: 0 }, error: "" },
};

export default function (state = initialState, action: any) {

    switch (action.type) {

        case DASHBOARD_TOTAL_ORDERS:
            return { ...state, Orders: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }
        case DASHBOARD_TOTAL_PROFIT:
            return { ...state, Profit: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }
        case DASHBOARD_TOTAL_USERS:
            return { ...state, Users: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } };
        case DASHBOARD_TOTAL_COUPONS:
            return { ...state, Coupons: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } }
        case DASHBOARD_TOTAL_DEALS:
            return { ...state, Deals: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } };
        case DASHBOARD_TOTAL_PRODUCTS:
            return { ...state, Products: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } };
        case DASHBOARD_TOTAL_BRANDS:
            return { ...state, Brands: { data: action.success ? action.data : { Total: 0, Avg: 0, Month: 0 }, error: action.success ? "" : action.data } };

        default:
            return state;
    }
}