import axios from 'axios';
import { meeapi } from './node-actions/meeapi';
import config from './config';

export const BIDDING_SERVICES_QUOTES_REQUEST = "BIDDING_SERVICES_QUOTES_REQUEST";
export const BIDDING_SERVICES_QUOTES_SUCCESS = "BIDDING_SERVICES_QUOTES_SUCCESS";
export const BIDDING_SERVICES_QUOTES_FAIL = "BIDDING_SERVICES_QUOTES_FAIL";

export const PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_REQUEST = "PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_REQUEST";
export const PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS = "PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS";
export const PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_FAIL = "PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_FAIL";

export const SINGLE_BIDDING_SERVICE_QUOTES_REQUEST = "SINGLE_BIDDING_SERVICE_QUOTES_REQUEST";
export const SINGLE_BIDDING_SERVICE_QUOTES_SUCCESS = "SINGLE_BIDDING_SERVICE_QUOTES_SUCCESS";
export const SINGLE_BIDDING_SERVICE_QUOTES_FAIL = "SINGLE_BIDDING_SERVICE_QUOTES_FAIL";

export const BIDDING_SERVICE_FILTER = "BIDDING_SERVICE_FILTER";


/*=============================================================
                   Add User Action
 ===============================================================*/
const headers = config.headersCommon;
const cudheaders = config.nodeheadersCommon;
const urlGenarator = (url, pagination) => {
  let queryString = "?";
  Object.keys(pagination).map((key) => {
    queryString += key + "=" + pagination[key] + "&"
  })
  return (url + queryString).slice(0, -1);
}

export const getAllBiddingServiceQuotesAction = (formData: any, pagination = {}) => async (dispatch: any) => {
  try {
    dispatch({
      type: BIDDING_SERVICES_QUOTES_REQUEST
    });
    meeapi.post(urlGenarator(`/Bidding_Service_Quotes/Get`, pagination),
      formData,
    ).then(response => {
      let data = response.data;
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: BIDDING_SERVICES_QUOTES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: BIDDING_SERVICES_QUOTES_SUCCESS,
          payload: data
        });

      }
    })
  } catch (err) {
    dispatch({
      type: BIDDING_SERVICES_QUOTES_FAIL,
      payload: err
    });
  }
};

export const updateBiddingServiceQuote = (formData: any, navigation: any, toast: any,setReload:any) => async dispatch => {
  dispatch({
    type: PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_REQUEST
  });
  axios.put(`${config.url}/Bidding_Service_Quotes/Update`,
    formData,
    {
      headers: headers
    })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_SUCCESS
        });
        navigation.navigate('PartnerBiddingServiceQuotes', { servicepartnerparams: formData.Product_Id });
        toast.show({ message: 'Quote Updated Successfully.', type: 'info', duration: 3000, position: 'top' });
        resetForm()
        setReload(true)
        const pagination = {
          PageNo: 1,
          PageSize: 5,
          SortBy: "Partner_Bidding_Service_Quote_Id",
          SortOrder: "DESC"
        };
        const formValue = {
          "search_by_filter": "",
          "search": "",
          "User_Email_Id": "",
          "User_Products_Bidding_Id": "",
          "Bidding_Product_Id": '',
          "User_Address_Id": "",
          "Records_Filter": "FILTER",
          "Bidding_Status": "",
          "Partner_Detail_Id": formData.Partner_Details_Id,
        };
        dispatch(getAllBiddingServiceQuotesAction(formValue, pagination))
      } else {
        dispatch({
          type: PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PARTNER_BIDDING_SERVICE_QUOTE_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working."
      });
    })
};


export const getSingleBiddingServiceQuotesAction = (formData: any, callBackData: any, pagination: any) => async dispatch => {
  try {
     dispatch({
       type: SINGLE_BIDDING_SERVICE_QUOTES_REQUEST
     });
     meeapi.post(urlGenarator(`/Bidding_Service_Quotes/Get`, pagination),
       formData,
     ).then(response => {
       let data = response.data;
       if (data) {
         callBackData(data.results)
       }
     })
   } catch (err) {
      dispatch({
       type: SINGLE_BIDDING_SERVICE_QUOTES_FAIL,
       payload: err
     });
   }
 };
// Filter 
export const employeeFilter = (fType: any, value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: BIDDING_SERVICE_FILTER,
      fType: fType,
      value: value,
    });
  }
}

//Search Filter
export const resetFilter = () => {
  return function (dispatch: any) {
    dispatch({
      type: BIDDING_SERVICE_FILTER,
      fType: "search",
      value: "",
    });
  }
}
