import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Card, HelperText, Surface, Text, TextInput } from 'react-native-paper';
import { Dimensions, RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import Header from '../auth/components/header';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import { createInventoryAction, getAllPartners, getAllSizes } from '../../state/actions/inventoryAction';
import EsiDatePicker from '../../components/custom/date/date';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const Status = [
    {
        value: '',
        label: '-- Please Select --'
    },
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const AddInventory = ({ navigation }: { navigation: any }) => {

    const dispatch = useDispatch();
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Inventory_Management === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);
    useEffect(() => {
        dispatch(getAllPartners(EmailID));
    }, []);
    const partners = useSelector(state => state.inventory.inventoryPartners.partners);
    const sizes = useSelector(state => state.inventory.sizes.all);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const [errorMessage, setErrorMessage] = useState({ Product_Id: { key: '', message: '' }, global: { key: '', message: '' } });
    const isSubmited = useSelector(state => state.inventory.inventoryCreate.isLoading);
    const [showstatusDropDown, setstatusShowDropDown] = useState(false);

    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    const setType = (Product_Master_Id) => {
        if (Product_Master_Id !== "") {
            dispatch(getAllSizes(Product_Master_Id, EmailID));
        }
    }

    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    return (
        <Surface style={{ flex: 1 }} >
            <Header navigation={navigation} />
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        colors={['#27B6CC']}
                        tintColor={'#27B6CC'}
                        title={"Pull to refresh"}
                        titleColor={"#27B6CC"}
                    />
                }>
                <Formik
                    initialValues={{
                        Availability_Status: "",
                        Available_Date: "",
                        Created_By: EmailID.Email_Id,
                        Inventory_Version: "",
                        Partner_Details_Id: EmailID?.Partner_Details_Id,
                        Partner_Product_Id: "",
                        Product_Id: "",
                        Total_Stock: "",
                        Product_Master_Id: "",
                        Product_Size: "",
                    }}
                    validationSchema={Yup.object().shape({
                        Product_Size: Yup.string().required('Product Size is required.'),
                        Availability_Status: Yup.string().required('Availability Status is required.'),
                        Available_Date: Yup.date().min(Date(), "Date must not be past date.").required('Available Date is required.'),
                        Inventory_Version: Yup.string().required('Inventory Version is required.'),
                        Total_Stock: Yup.string().required('Total Stock is required.'),
                        Product_Id: Yup.string().notOneOf([errorMessage.Product_Id.key, null], errorMessage.Product_Id.message).required('Product Name is required.'),
                        Partner_Product_Id: Yup.string().required('Partner Product Name is required.'),
                        Product_Master_Id: Yup.string().required('Product Master Name is required.'),
                    })}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        let formData = JSON.parse(JSON.stringify(values));
                        dispatch(createInventoryAction(formData, navigation, setErrorMessage, setErrors, toast))
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        resetForm
                    }) => (
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            flexWrap: "wrap"
                        }}>
                            <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 127 }}
                                    onPress={() => {
                                        resetForm();
                                        navigation.navigate('Inventory')
                                    }}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={styles.cardCircle}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                        Add Inventory
                                    </Text>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-evenly",
                                        flexWrap: "wrap"
                                    }}>
                                        <View style={{ flexShrink: 1, width: 750, paddingTop: 15 }}>
                                            {
                                                values.Partner_Product_Id ?
                                                    <View style={styles.container}>
                                                        <Text style={[styles.dropdownlabel]}>
                                                            Partner Product Name
                                                        </Text>
                                                        <EsiSearchDropdown
                                                            style={[styles.dropdown]}
                                                            placeholderStyle={styles.placeholderStyle}
                                                            selectedTextStyle={styles.selectedTextStyle}
                                                            inputSearchStyle={styles.inputSearchStyle}
                                                            iconStyle={styles.iconStyle}
                                                            data={partners}
                                                            labelField="Product_Master_Name"
                                                            valueField="Partner_Product_Id"
                                                            placeholder={!showstatusDropDown ? 'Select item' : '...'}
                                                            maxHeight={partners.length > 1 ? 250 : 200}
                                                            search={partners.length > 1 ? true : false}
                                                            searchPlaceholder={'Partner Product Name'}
                                                            value={values.Partner_Product_Id}
                                                            onFocus={() => setstatusShowDropDown(true)}
                                                            onBlur={() => setstatusShowDropDown(false)}
                                                            onUpdateValue={item => {
                                                                setType(item?.Product_Master_Id ? item?.Product_Master_Id : '');
                                                                setFieldValue('Partner_Product_Id', item.Partner_Product_Id)
                                                                setFieldValue('Product_Id', item.Product_Id)
                                                                setFieldValue('Product_Master_Id', item.Product_Master_Id)
                                                                setstatusShowDropDown(false);
                                                            }}
                                                        />
                                                    </View> :
                                                    <View style={styles.container}>
                                                        <Text style={[styles.dropdownlabel, Boolean(touched.Partner_Product_Id && errors.Partner_Product_Id) ? { color: '#b00020' } : {}]}>
                                                            Partner Product Name
                                                        </Text>
                                                        <EsiSearchDropdown
                                                            style={[styles.dropdown, Boolean(touched.Partner_Product_Id && errors.Partner_Product_Id) ? { borderColor: '#b00020' } : {}]}
                                                            placeholderStyle={styles.placeholderStyle}
                                                            selectedTextStyle={styles.selectedTextStyle}
                                                            inputSearchStyle={styles.inputSearchStyle}
                                                            iconStyle={styles.iconStyle}
                                                            data={partners}
                                                            labelField="Product_Name"
                                                            valueField="Partner_Product_Id"
                                                            placeholder={!showstatusDropDown ? 'Select item' : '...'}
                                                            maxHeight={partners.length > 1 ? 250 : 200}
                                                            search={partners.length > 1 ? true : false}
                                                            searchPlaceholder={'Partner Product Name'}
                                                            value={values.Partner_Product_Id}
                                                            onFocus={() => setstatusShowDropDown(true)}
                                                            onBlur={() => setstatusShowDropDown(false)}
                                                            onUpdateValue={item => {
                                                                setType(item?.Product_Master_Id ? item?.Product_Master_Id : '');
                                                                setFieldValue('Partner_Product_Id', item.Partner_Product_Id)
                                                                setFieldValue('Product_Id', item.Product_Id)
                                                                setFieldValue('Product_Master_Id', item.Product_Master_Id)
                                                                setstatusShowDropDown(false);
                                                            }}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Partner_Product_Id && errors.Partner_Product_Id)}>
                                                            {touched.Partner_Product_Id && errors.Partner_Product_Id}
                                                        </HelperText>
                                                    </View>}

                                            <View style={styles.container}>
                                                <Text style={styles.dropdownlabel}>
                                                    Product Name
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={styles.dropdown}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    iconColor='white'
                                                    data={partners}
                                                    labelField="Product_Name"
                                                    valueField="Product_Id"
                                                    disable
                                                    placeholder={!showstatusDropDown ? 'Select item' : '...'}
                                                    maxHeight={partners.length > 1 ? 250 : 200}
                                                    search={partners.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Casback Lable'}
                                                    value={values.Product_Id}
                                                    onFocus={() => setstatusShowDropDown(true)}
                                                    onBlur={() => setstatusShowDropDown(false)}
                                                    onUpdateValue={item => {

                                                        setFieldValue('Product_Id', item.value)
                                                        setstatusShowDropDown(false);
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.container}>
                                                <Text style={styles.dropdownlabel}>
                                                    Product Master Name
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={styles.dropdown}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    iconColor='white'
                                                    data={partners}
                                                    disable
                                                    labelField="Product_Master_Name"
                                                    valueField="Product_Master_Id"
                                                    placeholder={!showstatusDropDown ? 'Select item' : '...'}
                                                    searchPlaceholder={'Select Product Master Name'}
                                                    value={values.Product_Master_Id}
                                                    onFocus={() => setstatusShowDropDown(true)}
                                                    onBlur={() => setstatusShowDropDown(false)}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Product_Master_Id', item.value)
                                                        setstatusShowDropDown(false);
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Product_Size && errors.Product_Size) && { color: '#b00020' }]}>
                                                    Product Size
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Product_Size && errors.Product_Size) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={sizes}
                                                    labelField="Size"
                                                    valueField="Product_Size_Id"
                                                    placeholder={!showstatusDropDown ? 'Select item' : '...'}
                                                    maxHeight={sizes.length > 1 ? 250 : 200}
                                                    search={sizes.length > 1 ? true : false}
                                                    searchPlaceholder={'Select Product Size'}
                                                    value={values.Product_Size}
                                                    onFocus={() => setstatusShowDropDown(true)}
                                                    onBlur={() => setstatusShowDropDown(false)}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Product_Size', item.Product_Size_Id)
                                                        setstatusShowDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Product_Size && errors.Product_Size)}>
                                                    {touched.Product_Size && errors.Product_Size}
                                                </HelperText>
                                            </View>
                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                error={Boolean(touched.Inventory_Version && errors.Inventory_Version)}
                                                onBlur={handleBlur}
                                                onChangeText={handleChange('Inventory_Version')}
                                                label="Inventory Version"
                                                name="Inventory_Version"
                                                value={values.Inventory_Version}
                                                mode="outlined"
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Inventory_Version && errors.Inventory_Version)}>
                                                {touched.Inventory_Version && errors.Inventory_Version}
                                            </HelperText>
                                            <View style={{ flexGrow: 1, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                                <EsiDatePicker
                                                    label={'Available Date'}
                                                    style={{ width: "100%", backgroundColor: 'white', height: 50 }}
                                                    value={values.Available_Date}
                                                    valueUpdate={date => {
                                                        setFieldValue('Available_Date', date)
                                                    }} />
                                                <HelperText type="error" visible={Boolean(touched.Available_Date && errors.Available_Date)}>
                                                    {touched.Available_Date && errors.Available_Date}
                                                </HelperText>
                                            </View>

                                            <TextInput
                                                style={{ backgroundColor: 'white' }}
                                                theme={{ colors: { primary: '#27B6CC' } }}
                                                error={Boolean(touched.Total_Stock && errors.Total_Stock)}
                                                onBlur={handleBlur}
                                                onChangeText={handleChange('Total_Stock')}
                                                label="Total Stock"
                                                name="Total_Stock"
                                                value={values.Total_Stock}
                                                mode="outlined"
                                                keyboardType='numeric'
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Total_Stock && errors.Total_Stock)}>
                                                {touched.Total_Stock && errors.Total_Stock}
                                            </HelperText>
                                            <View style={styles.container}>
                                                <Text style={[styles.dropdownlabel, Boolean(touched.Availability_Status && errors.Availability_Status) && { color: '#b00020' }]}>
                                                    Availability Status
                                                </Text>
                                                <EsiSearchDropdown
                                                    style={[styles.dropdown, Boolean(touched.Availability_Status && errors.Availability_Status) && { borderColor: '#b00020' }]}
                                                    placeholderStyle={styles.placeholderStyle}
                                                    selectedTextStyle={styles.selectedTextStyle}
                                                    inputSearchStyle={styles.inputSearchStyle}
                                                    iconStyle={styles.iconStyle}
                                                    data={Status}
                                                    maxHeight={Status.length > 1 ? 250 : 200}
                                                    search={Status.length > 1 ? true : false}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder={!showstatusDropDown ? 'Select item' : '...'}
                                                    searchPlaceholder={'Select Availability Status'}
                                                    value={values.Availability_Status}
                                                    onFocus={() => setstatusShowDropDown(true)}
                                                    onBlur={() => setstatusShowDropDown(false)}
                                                    onUpdateValue={item => {
                                                        setFieldValue('Availability_Status', item.value)
                                                        setstatusShowDropDown(false);
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Availability_Status && errors.Availability_Status)}>
                                                    {touched.Availability_Status && errors.Availability_Status}
                                                </HelperText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: '#27B6CC' }} mode="contained" onPress={handleSubmit}>
                                            Save
                                        </Button>
                                    </View>
                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        paddingTop: 15
    },
    cardCircle: {
        width: (window.width >= 500) ? "75%" : "97%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2%",
        marginBottom: "2%",
        borderRadius: 6,
        borderWidth: 0.01,
        borderColor: '#D6D6D7'
    },
    itemContainer: {
        justifyContent: 'flex-end',
        flex: 1
    },
    files: {
        width: '100%',
        height: 100,
        borderRadius: 5,
        marginBottom: 15
    },
    checkbox: {
        alignSelf: "center",
    },
    label: {
        marginBottom: 30,
    },
    phonetextInput: {
        flex: 1
    },
    dropdown: {
        height: 58,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 5,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownlabel: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
    },
    selectedTextStyle: {
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});


export default AddInventory;