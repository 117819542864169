import axios from 'axios';
import config from './config';
import shopperconfig from './shoppers/shopperconfig';

export const CONTENTDATEANDCOUNT_REQUEST = 'CONTENTDATEANDCOUNT_REQUEST';
export const CONTENTDATEANDCOUNT_SUCCESS = "CONTENTDATEANDCOUNT_SUCCESS";
export const CONTENTDATEANDCOUNT_FAIL = "CONTENTDATEANDCOUNT_FAIL";
export const PARTNERMAINCENTERDATEANDCOUNT_REQUEST = 'PARTNERMAINCENTERDATEANDCOUNT_REQUEST';
export const PARTNERMAINCENTERDATEANDCOUNT_SUCCESS = "PARTNERMAINCENTERDATEANDCOUNT_SUCCESS";
export const PARTNERMAINCENTERDATEANDCOUNT_FAIL = "PARTNERMAINCENTERDATEANDCOUNT_FAIL";
export const ORDERDATEANDCOUNT_REQUEST = 'ORDERDATEANDCOUNT_REQUEST';
export const ORDERDATEANDCOUNT_SUCCESS = "ORDERDATEANDCOUNT_SUCCESS";
export const ORDERDATEANDCOUNT_FAIL = "ORDERDATEANDCOUNT_FAIL";
export const PARTNERMAINCENTERDATEANDCOUNTNEW_REQUEST = 'PARTNERMAINCENTERDATEANDCOUNTNEW_REQUEST';
export const PARTNERMAINCENTERDATEANDCOUNTNEW_SUCCESS = "PARTNERMAINCENTERDATEANDCOUNTNEW_SUCCESS";
export const PARTNERMAINCENTERDATEANDCOUNTNEW_FAIL = "PARTNERMAINCENTERDATEANDCOUNTNEW_FAIL";
export const USERBIDDINGDATEANDCOUNT_REQUEST = 'USERBIDDINGDATEANDCOUNT_REQUEST';
export const USERBIDDINGDATEANDCOUNT_SUCCESS = "USERBIDDINGDATEANDCOUNT_SUCCESS";
export const USERBIDDINGDATEANDCOUNT_FAIL = "USERBIDDINGDATEANDCOUNT_FAIL";
export const SERVICEBIDDINGDATEANDCOUNT_REQUEST = 'SERVICEBIDDINGDATEANDCOUNT_REQUEST';
export const SERVICEBIDDINGDATEANDCOUNT_SUCCESS = "SERVICEBIDDINGDATEANDCOUNT_SUCCESS";
export const SERVICEBIDDINGDATEANDCOUNT_FAIL = "SERVICEBIDDINGDATEANDCOUNT_FAIL";
export const B2BBIDDINGDATEANDCOUNT_REQUEST = 'B2BBIDDINGDATEANDCOUNT_REQUEST';
export const B2BBIDDINGDATEANDCOUNT_SUCCESS = "B2BBIDDINGDATEANDCOUNT_SUCCESS";
export const B2BBIDDINGDATEANDCOUNT_FAIL = "B2BBIDDINGDATEANDCOUNT_FAIL";
const nodeheaders = shopperconfig.nodeheadersCommon;
/*=============================================================
          Get ESI Main Center Date and count Action
===============================================================*/

const headers = config.headersCommon;
export const contentDateandCount = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: CONTENTDATEANDCOUNT_REQUEST
    });
    let { data, status } = await axios.post(
      `${config.url}/Total_Count_And_Date/Content_Management`,
      formData,
      {
        headers: headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: CONTENTDATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: CONTENTDATEANDCOUNT_SUCCESS,
          payload: data.Partner_Content_Management[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: CONTENTDATEANDCOUNT_FAIL,
      payload: err
    });
  }
};

export const partnermaincenterDateandCount = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: PARTNERMAINCENTERDATEANDCOUNT_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Total_Count_And_Date/Main_Center`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response?.Response_Status === 'Success') {
        dispatch({
          type: PARTNERMAINCENTERDATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      
      } else{
        dispatch({
          type: PARTNERMAINCENTERDATEANDCOUNT_FAIL,
          payload: []
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERMAINCENTERDATEANDCOUNT_FAIL,
      payload: err
    });
  }
};


export const partnermaincenterDateandCountnew = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: PARTNERMAINCENTERDATEANDCOUNTNEW_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Total_Count_And_Date/Main_Center`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: PARTNERMAINCENTERDATEANDCOUNTNEW_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: PARTNERMAINCENTERDATEANDCOUNTNEW_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: PARTNERMAINCENTERDATEANDCOUNTNEW_FAIL,
      payload: err
    });
  }
};

export const orderDateandCount = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: ORDERDATEANDCOUNT_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Total_Count_And_Date/Partner_Order_Management`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ORDERDATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ORDERDATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ORDERDATEANDCOUNT_FAIL,
      payload: err
    });
  }
};


export const userbiddingDateandCount = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: USERBIDDINGDATEANDCOUNT_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Total_Count_And_Date/Bidding_Products_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USERBIDDINGDATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USERBIDDINGDATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USERBIDDINGDATEANDCOUNT_FAIL,
      payload: err
    });
  }
};


export const biddingservicesDateandCount = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: SERVICEBIDDINGDATEANDCOUNT_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Total_Count_And_Date/Bidding_Services_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: SERVICEBIDDINGDATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SERVICEBIDDINGDATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SERVICEBIDDINGDATEANDCOUNT_FAIL,
      payload: err
    });
  }
};

export const b2bbiddingservicesDateandCount = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: B2BBIDDINGDATEANDCOUNT_REQUEST
    });
    let { data, status } = await axios.post(
      `${shopperconfig.nodePartnerUrl}/Total_Count_And_Date/B2B_Bidding_Products_Get`,
      formData,
      {
        headers: nodeheaders
      }
    );
    if (data) {
      if (data.Success_Response.Is_Data_Exist === '0') {
        dispatch({
          type: B2BBIDDINGDATEANDCOUNT_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: B2BBIDDINGDATEANDCOUNT_SUCCESS,
          payload: data.results[0]
        });
      }
    }
  } catch (err) {
    dispatch({
      type: B2BBIDDINGDATEANDCOUNT_FAIL,
      payload: err
    });
  }
}; 