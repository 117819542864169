import React, { useState, useEffect } from 'react';
import { Card, Button, TextInput, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView, RefreshControl, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  updateProductColorVariantAction,
  getAllProductColorVariantAction,
  getProductColorByIdAction,

} from './../../../state/actions/productcolorAction';
import Header from '../../auth/components/header';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import AsyncStorage from '@react-native-async-storage/async-storage';

const EditColor = (props: any) => {
  const toast = useToast();
  const { navigation } = props;

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );

  });

  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const EmailID = useSelector(state => state.auth?.user);
  const esiColor = useSelector(state => state.theme);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    if (!(permissions.Product_Master_Product_Color_Product_Size === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);
  const dispatch = useDispatch();

  const productcolors = props.route?.params?.productcolor;

  //form reset
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  //get color data
  const [data, setData] = useState([]);
  const productcoloredit = props.route?.params?.productcolors;

  const callbackProductColorData = (data: any) => {
    setData(data)
  }

  React.useEffect(() => {
    if (productcolors) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Color": "",
        "Product_Color_Variant_Id": productcolors,
        "Product_Master_Id": "",
        "Records_Filter": "FILTER",
        "Status": ""
      };
      dispatch(getProductColorByIdAction(formData, callbackProductColorData));
    }
  }, [productcolors]);

  /*====================================
            Handler
    ====================================*/
  const values = {
    Color: data.Color,
    Product_Color_Variant_Id: data.Product_Color_Variant_Id,
    Modified_By: EmailID.Email_Id,
    Product_Master_Id: data.Product_Master_Id
  }
  //form Validations
  const Form_Validation = Yup.object().shape({
    Color: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid Color name').required('Color is required.'),
  })
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      formValues.Created_By = EmailID.Email_Id;
      dispatch(updateProductColorVariantAction(formValues, productcoloredit, props.navigation, toast));
    }
  }

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  return (
    <Surface style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={props.navigation} />
      <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
        {(props) => (
          <Card style={{
            width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
            marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
            backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
          }}>
            <Card.Content>
              <View >
                <Button textColor={esiColor.SIconColor} icon="keyboard-backspace" style={{ width: 127 }}
                  onPress={() => {
                    props.resetForm();
                    navigation.navigate('ProductColors', { ID: productcoloredit })
                  }}>
                  Go Back
                </Button>
              </View>
              <ScrollView
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                  />
                }>
                <View >
                  <TextInput
                    // theme={customTheme}
                    style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                    outlineColor={esiColor.TextOuterLine}
                    underlineColor={esiColor.TextUnderline}
                    activeOutlineColor={esiColor.TextOuterLine}
                    error={Boolean(props.touched.Color && props.errors.Color)}
                    label="Color"
                    mode='outlined'
                    value={props.values.Color}
                    onChangeText={props.handleChange("Color")}
                  />
                  <HelperText type="error" visible={Boolean(props.touched.Color && props.errors.Color)}>
                    {props.touched.Color && props.errors.Color}
                  </HelperText>
                </View>

                <Button textColor={esiColor.itemButtenColor} style={{ width: 130, marginTop: "3%", marginLeft: "auto", marginRight: "auto", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                  Save
                </Button>
              </ScrollView>

            </Card.Content>
          </Card>
        )}
      </Formik>
    </Surface>
  );
};

export default EditColor;