import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { changeMainData } from '../../../state/actions/shoppers/filterServiceActions';
import AllProducts from '../products/allProducts';

export default function AllFilters(props) {
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    let sortByOptionsInit = useSelector((state) => state.filters.main);
    const checkChildChecked = (data: any) => {
        let count = 0;
        data.map((item: any) => {
            if (item.checked === "checked") {
                count++
            }
        })
        return (count === data.length && data.length > 0) ? 'checked' : count === 0 ? "unchecked" : "indeterminate";
    }

    React.useEffect(() => {
        let isChecked = false;
        if (checkChildChecked(sortByOptionsInit[0].children) === 'checked' || checkChildChecked(sortByOptionsInit[0].children) === 'indeterminate') {
            isChecked = true;
        }
        if (!isChecked) {
            let response = JSON.parse(JSON.stringify(sortByOptionsInit))
            response[0].children.map((item: any) => {
                if (item.value === 'Is_Top_Product') {
                    item.checked = "checked";
                }
            })
            dispatch(changeMainData(response));
        }
    }, [sortByOptionsInit]);

    return (
        <View style={{
            flex: 1,
            backgroundColor: esiColor.BackgroundColor
        }}>
            {(checkChildChecked(sortByOptionsInit[0].children) === 'checked' || checkChildChecked(sortByOptionsInit[0].children) === 'indeterminate') &&
                <AllProducts {...props} isFilter={true} />}
        </View>
    );
}
