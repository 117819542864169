import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropertiesNavBar from './Propertiesindex';

const PropertiesDashboardLayout = (props: any) => {
  const { navigation } = props;
  const [isMobileNavB2COpen, setMobileNavB2COpen] = useState(true);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
      <PropertiesNavBar
        onMobileClose={() => setMobileNavB2COpen(true)}
        openMobile={isMobileNavB2COpen} navigation={navigation}
      />
  );
};
export default PropertiesDashboardLayout;
