/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 * 
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Pressable } from 'react-native';

import Colors from '../constants/Colors';
import ForgotPassword from '../screens/auth/forgot-password';
import SignIN from '../screens/auth/signin';
import SignUP from '../screens/auth/signup';
import AddBrand from '../screens/brand-management/addBrand';
import Dashboard from '../screens/dashboard/dashboard';
import AddEmployee from '../screens/employees/addEmployee';
import EditEmployee from '../screens/employees/editEmployee';
import EmployeeDetails from '../screens/employees/employeesGet';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import TabOneScreen from '../screens/TabOneScreen';
import TabTwoScreen from '../screens/TabTwoScreen';
import LinkingConfiguration from './LinkingConfiguration';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform, StyleSheet } from 'react-native';
import AddTicket from '../screens/ticketing-management/addTicket';
import EditTicket from '../screens/ticketing-management/editTicket';
import TicketDetails from '../screens/ticketing-management/ticketGet';
import BrandDetails from '../screens/brand-management/brandsGet';
import AddressDetails from '../screens/partneraddress/getAddress';
import AddAddress from '../screens/partneraddress/addAddress';
import EditAddress from '../screens/partneraddress/editAddress';
import DealDetails from '../screens/deal-management/getDeal';
import AddDealBasic from '../screens/deal-management/add-deal/addDealbasicinfo';
import AddDealConfig from '../screens/deal-management/add-deal/addDealconfiginfo';
import EditDealBasic from '../screens/deal-management/edit-deal/editDealbasicinfo';
import EditDealConfig from '../screens/deal-management/edit-deal/editDealconfiginfo';
import ProductMastersGet from '../screens/product-management/product-master/productmastersGet';
import AddProductMaster from '../screens/product-management/product-master/add-product-master/addProductMaster';
import EditProductMaster from '../screens/product-management/product-master/edit-product-master/editProductMaster';
import ProductColorGet from '../screens/product-management/product-colors/produtColor';
import AddColor from '../screens/product-management/product-colors/addColor';
import EditColor from '../screens/product-management/product-colors/editColor';
import EditProductSize from '../screens/product-management/product-sizes/editSize';
import AddProductSize from '../screens/product-management/product-sizes/addSize';
import ProductSizeGet from '../screens/product-management/product-sizes/productSizes';
import ProductGet from '../screens/product-management/products/productsGet';
import RelatedProductsGet from '../screens/product-management/related-products/relatedProductGet';
import AddRelatedProduct from '../screens/product-management/related-products/addRelatedProducts';
import EditRelatedProduct from '../screens/product-management/related-products/editRelatedProducts';
import PartnerProductsGet from '../screens/product-management/partner-products/partnerProductsGet';
import EditProduct from '../screens/product-management/products/edit-products/editProducts';
import AddProductPartner from '../screens/product-management/partner-products/add-product-partner/addProductPartner';
import EditProductPartner from '../screens/product-management/partner-products/edit-product-partner/editProductPartner';
import AddProducts from '../screens/product-management/products/add-products/addProduct';
import AddProductPartnerPagetwo from '../screens/product-management/partner-products/add-product-partner/addProductpartnerpagetwo';
import EditProductPartnerPageTwo from '../screens/product-management/partner-products/edit-product-partner/editProductPartnerpagetwo';
import EditProductpagetwo from '../screens/product-management/products/edit-products/editProductspagetwo';
import AddProductMasterpagetwo from '../screens/product-management/product-master/add-product-master/addProductMasterPagetwo';
import AddProductMasterpagethree from '../screens/product-management/product-master/add-product-master/addProductMasterPagethree';
import EditProductMastertwo from '../screens/product-management/product-master/edit-product-master/editProductmasterspagetwo';
import EditProductMasterthree from '../screens/product-management/product-master/edit-product-master/editProductMasterpagethree';
import EditProductpagethree from '../screens/product-management/products/edit-products/editProductspagethree';
import EditProductpagefour from '../screens/product-management/products/edit-products/editProductspagefour';
import AddProductspagetwo from '../screens/product-management/products/add-products/addProductpagetwo';
import AddProductspagethree from '../screens/product-management/products/add-products/addproductpagethree';
import AddProductspagefour from '../screens/product-management/products/add-products/addproductspagefour';
import CouponDetails from '../screens/coupon-management/getCoupon';
import AddCouponBasic from '../screens/coupon-management/add-coupon/addCouponbasicinfo';
import AddCouponConfig from '../screens/coupon-management/add-coupon/addCouponconfiginfo';
import EditCouponBasic from '../screens/coupon-management/edit-coupon/editCouponbasicinfo';
import EditCouponConfig from '../screens/coupon-management/edit-coupon/editCouponconfiginfo';
import PartnerProfile from '../screens/partner-profile/editcompanyprofile';
import EmployeProfile from '../screens/partner-profile/editemployeprofile';
import PartnerpaymentDetails from '../screens/payments-settlements/partnerpayments/getPartnerpayment';
import UserpaymentDetails from '../screens/payments-settlements/userpayments/getUserpayment';
import TransactionDetails from '../screens/transactionhistory/getTransactionhistory';
import EditPartnerpayment from '../screens/payments-settlements/partnerpayments/updatePartnerpayment';
import EditUserpayment from '../screens/payments-settlements/userpayments/updateUserpayment';
import EditTransactionhistory from '../screens/transactionhistory/updateTransactionhistory';
import ReturnOrdersGet from '../screens/order-return-and-cancel/orderReturnGet';
import CancelOrdersGet from '../screens/order-return-and-cancel/orderCancelGet';
import SettingsDetails from '../screens/partner-settings/getSettings';
import AddSetting from '../screens/partner-settings/addSettings';
import EditSetting from '../screens/partner-settings/editSettings';
import Partnerbank from '../screens/partner-bank/partnerbank';
import Transactions from '../screens/Transactions/Transactions';
import HowPartnerAssocWorks from '../screens/CMS/HowPartnerAssocWorks';
import ServiceLevelAgreement from '../screens/CMS/ServiceLevelAgreement';
import InventoryDetails from '../screens/inventory-management/getInvetory';
import AddInventory from '../screens/inventory-management/addInventory';
import EditIneventory from '../screens/inventory-management/editInventory';
import SbcpartnercityDetails from '../screens/partner-shopbycity/sbc-partnercities/sbcpartnercityGet';
import AddSBCPartnercity from '../screens/partner-shopbycity/sbc-partnercities/addsbcpartnerCity';
import EditSBCPartnercity from '../screens/partner-shopbycity/sbc-partnercities/editsbcpartnerCity';
import SbcpartnercitycouponDetails from '../screens/partner-shopbycity/sbc-partnercitycoupons/sbcpartnercityCouponGet';
import AddSBCPartnercitycoupon from '../screens/partner-shopbycity/sbc-partnercitycoupons/addsbcpartnercityCoupon';
import EditSBCPartnercitycoupon from '../screens/partner-shopbycity/sbc-partnercitycoupons/editsbcpartnercityCoupon';
import SbcpartnercitydealDetails from '../screens/partner-shopbycity/sbc-partnercitydeals/sbcpartnercityDealGet';
import EditSBCPartnercitydeal from '../screens/partner-shopbycity/sbc-partnercitydeals/editsbcpartnercityDeal';
import AddSBCPartnercitydeal from '../screens/partner-shopbycity/sbc-partnercitydeals/addsbcpartnercityDeal';
import SbcpartnercityproductDetails from '../screens/partner-shopbycity/sbc-partnercityproducts/sbcpartnercityProductGet';
import AddSBCPartnercityproduct from '../screens/partner-shopbycity/sbc-partnercityproducts/addsbcpartnercityProduct';
import EditSBCPartnercityproduct from '../screens/partner-shopbycity/sbc-partnercityproducts/editsbcpartnercityProduct';
import ReviewsandratingsDetails from '../screens/review-and-ratings/getReviewandRatings';
import ShippingDetails from '../screens/shipping-management/shippings/shippingGet';
import EditShipping from '../screens/shipping-management/shippings/updateShipping';
import ViewShipping from '../screens/shipping-management/shippings/viewShipping';
import ShippingreturnedDetails from '../screens/shipping-management/returnedshippings/returnedShippingGet';
import ViewreturnedShipping from '../screens/shipping-management/returnedshippings/viewreturnedShippings';
import ShippingcancelledDetails from '../screens/shipping-management/cancelledshippings/cancelledShippingGet';
import ViewcancelledShipping from '../screens/shipping-management/cancelledshippings/viewcancelledShippings';
import GiftVendorsGet from '../screens/gift-management/gift-vendors/giftVendorsGet';
import EditGiftVendor from '../screens/gift-management/gift-vendors/editGiftVendors';
import GiftGet from '../screens/gift-management/gifts/giftsGet';
import ImagesGet from '../screens/gift-management/gift-images/imagesGet';
import EditGifts from '../screens/gift-management/gifts/editGifts';
import AddImage from '../screens/gift-management/gift-images/addImage';
import EditImage from '../screens/gift-management/gift-images/editImages';
import * as SecureStore from 'expo-secure-store';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from '../state/actions/authActions';
import isEmpty from '../state/validations/is-empty';
import Reports from '../screens/reports/reportsData';
import OrderStatistics from '../screens/reports/statistics/orderStatistics';
import ProfitStatistics from '../screens/reports/statistics/profitStatistics';
import UserStatistics from '../screens/reports/statistics/userStatistics';
import CouponStatistics from '../screens/reports/statistics/couponStatistics';
import DealStatistics from '../screens/reports/statistics/dealStatistics';
import ProductStatistics from '../screens/reports/statistics/productStatistics';
import CouponNotification from '../screens/notifications/couponNotifications';
import Notification from '../screens/notifications/notifications';
import DealNotification from '../screens/notifications/dealNotifications';
import ProductNotification from '../screens/notifications/productNotifications';
import OrderNotification from '../screens/notifications/b2corderNotifications';
import DashboardLayout from '../components/layouts/DashboardLayout';
import PartnerMain from '../screens/partner-main-centre-main-page';
import OrderMain from '../screens/partner-order-management';
import PartnerProductMain from '../screens/partner-product-management';
import PaymentsMain from '../screens/partner-payment-and-settlements';
import DeliveryAgentDetails from '../screens/partner-delivery-agent/getDeliveryAgent';
import AddDeliveryAgent from '../screens/partner-delivery-agent/addDeliveryAgent';
import BrandsGet from '../screens/brand-management/brandsGet';
import AddressGet from '../screens/partneraddress/getAddress';
import EditOrders from '../screens/Order-management/editOrders';
import OrderDetails from '../screens/Order-management/orderDetails';
import ContactUs from '../screens/CMS/ContactUs';
import Privacypolicy from '../screens/CMS/PrivacyPolicy';
import TermsAndConditions from '../screens/CMS/TermsandConditions';
import Ticketing from '../screens/Tickets/ticket-get';
import Addtickets from '../screens/Tickets/Add-ticket';
import Comments from '../screens/Tickets/comments';
import SignUPPassword from '../screens/auth/signup-set-password';
import Addpartnershopbylocation from '../screens/partner-shopbycity/partner-shop-by-location/addpartnershopbylocation';
import Editpartnershopbylocation from '../screens/partner-shopbycity/partner-shop-by-location/editpartnershopbylocation';
import Partnershopbylocationget from '../screens/partner-shopbycity/partner-shop-by-location/partnershopbylocationget';
import EditDeliveryAgent from '../screens/partner-delivery-agent/editDeliveryAgent';
import { createStackNavigator } from '@react-navigation/stack';
import B2BDashboardLayout from '../components/layouts/DashboardLayout/B2Bindex';
import EsiDrawer from '../screens/Shoppers/Drawer';
import AllFilters from '../screens/Shoppers/Drawer/all';
import AllProducts from '../screens/Shoppers/products/allProducts';
import SearchProduct from '../screens/Shoppers/SearchProduct/globalSearch';
import ProductDetails from '../screens/Shoppers/products/ProductDetails';
import Useraddresses from '../screens/Shoppers/Address/addressGet';
import AddUserAddress from '../screens/Shoppers/Address/AddAddress';
import EditUserAddress from '../screens/Shoppers/Address/EditAddress';
import Cart from '../screens/Shoppers/cart/Cart';
import CartSummary from '../screens/Shoppers/cart/CartSummary';
import AllTransaction from '../screens/Shoppers/transaction-history/all-transatcions-history';
import MyOrderDetails from '../screens/Shoppers/orderDetails/orderDetails';
import MyOrders from '../screens/Shoppers/myOrders/orders';
import ShoppersGet from '../screens/Shoppers/discountProducts/DiscountProductsGet';
import AddShopperProducts from '../screens/Shoppers/discountProducts/addDiscountProducts';
import EditShoppersProduct from '../screens/Shoppers/discountProducts/editDiscountProducts';
import ShoppersinventoryDetails from '../screens/Shoppers/shoppers-inventory-management/getShoppersInvetory';
import ShoppersInventoryCreate from '../screens/Shoppers/shoppers-inventory-management/addShoppersInventory';
import ShoppersInventoryUpdate from '../screens/Shoppers/shoppers-inventory-management/editShoppersInventory';
import Ordersget from '../screens/Order-management/ordersget';
import DeliveryTracking from '../screens/Order-management/deliveryTracking';
import AllCombinations from '../screens/Shoppers/all-Combination/AllCombination';
import B2bOrderDetails from '../screens/B2B-Order-management/orderDetails';
import B2bEditOrders from '../screens/B2B-Order-management/editOrders';
import WalletHistory from '../screens/partnerwallet/WithdrawHistory';
import Withdrawals from '../screens/partnerwallet/Withdrawals';
import PartnerownProductsGet from '../screens/ownpartner-products/ownPartnerproductsGet';
import AddownProductPartnerPagetwo from '../screens/ownpartner-products/add-ownpartner-products/addownPartnerProductspageTwo';
import AddownProductPartner from '../screens/ownpartner-products/add-ownpartner-products/addownPartnerProducts';
import EditownProductPartner from '../screens/ownpartner-products/edit-ownpartner-products/editownPartnerProducts';
import EditownProductPartnerPageTwo from '../screens/ownpartner-products/edit-ownpartner-products/editownPartnerProductspagetwo';
import EditownShoppersProduct from '../screens/ownDiscountedproducts/editownDiscountProducts';
import AddownShopperProducts from '../screens/ownDiscountedproducts/addownDiscountProducts';
import ShoppersownGet from '../screens/ownDiscountedproducts/DiscountownProductsGet';
import ActivateAccount from '../screens/auth/activate-account';
import ServicesDashboard from '../screens/Services/dashboard/dashboard';
import ServicesDashboardLayout from '../components/layouts/DashboardLayout/Services';
import PropertiesDashboard from '../screens/Properties/dashboard/dashboard';
import PropertiesDashboardLayout from '../components/layouts/DashboardLayout/Properties';
import GetProperties from '../screens/Properties/AllProperties/getProperties';
import AddProperty from '../screens/Properties/AllProperties/addPropertie';
import EditProperty from '../screens/Properties/AllProperties/editProperties/editPropertie';
import GetEvents from '../screens/Properties/UserEvents/getEvents';
import EditUserEvents from '../screens/Properties/UserEvents/edit-user-events/editUserEventsone';
import EditUserEventspagetwo from '../screens/Properties/UserEvents/edit-user-events/editUserEventstwo';
import EditUserEventspagethree from '../screens/Properties/UserEvents/edit-user-events/editUserEventsthree';
import Newuserservices from '../screens/Services/userservices/newuserserviceGet';
import EditUserservice from '../screens/Services/userservices/edit-userservice/editUserservice';
import EditUserservicepagetwo from '../screens/Services/userservices/edit-userservice/editUserservicepagetwo';
import Partnerservice from '../screens/Services/partner-services/partnerServicesget';
import Addpartnerservice from '../screens/Services/partner-services/add-partnerservice/addPartnerservice';
import AddPartnerservicesPagetwo from '../screens/Services/partner-services/add-partnerservice/addPartnerservicepagetwo';
import EditPartnerservice from '../screens/Services/partner-services/edit-partnerservices/editPartnerservices';
import EditPartnerservicepagetwo from '../screens/Services/partner-services/edit-partnerservices/editPartnerservicespagetwo';
import EditPropertyPageTwo from '../screens/Properties/AllProperties/editProperties/editPropertie-Image-Info';
import Brandsb2b from '../screens/Shoppers/Brands/brands';
import Categoryb2b from '../screens/Shoppers/Categories/categories';
import Partnersb2b from '../screens/Shoppers/Partners/partners';
import BrandDetailsb2b from '../screens/Shoppers/brand-details/BrandDetails';
import PartnerDetailsb2b from '../screens/Shoppers/partner-details/PartnerDetails';
import SubCategory from '../screens/Shoppers/SubCategory/subcategories';
import EditNewUserservice from '../screens/Services/userservices/edit-userservice/editAccepteduserservice';
import bookeddatesget from '../screens/Properties/BookedDates/getBookeddates';
import Accepteduserservices from '../screens/Services/userservices/accepteduserserviceGet';
import Rejecteduserservices from '../screens/Services/userservices/rejecteduserserviceGet';
import Inprogressuserservices from '../screens/Services/userservices/inprogressuserserviceGet';
import EmployeNewuserservices from '../screens/Services/employe-services/employenewuserservicesGet';
import EmployeInprogressserservices from '../screens/Services/employe-services/employeeinprogressuserserviceGet';
import EmployeCompletedserservices from '../screens/Services/employe-services/employeecompleteduserserviceGet';
import Completeduserservices from '../screens/Services/userservices/completeduserserviceGet';
import EditAcceptedUserservice from '../screens/Services/userservices/edit-userservice/editAccepteduserservice';
import EditInprogressuserservice from '../screens/Services/userservices/edit-userservice/editInprogressuserservice';
import EditEmployeInprogressuserservice from '../screens/Services/employe-services/edit-employe-services/editemployeeInprogressUserservice';
import B2BOrderNotification from '../screens/notifications/b2borderNotifications';
import Biddingproducts from '../screens/bidding/products';
import BiddingDashboardLayout from '../components/layouts/DashboardLayout/B2Bbiddingindex';
import { CategoryFilters } from '../screens/bidding/categoryfilter';
import NewBidProducts from '../screens/bidding/Newbiddingproducts';
import BidProductDetails from '../screens/bidding/ProductDetails';
import CartOfBids from '../screens/bidding/CartofBids';
import PartnerBiddingMain from '../screens/partner-bidding-Products-center';
import AddPartnerBiddingProducts from '../screens/b2c-bidding-management/Partner_Available_products/addPartnerBiddingProducts';
import EditPartnerBiddingProducts from '../screens/b2c-bidding-management/Partner_Available_products/editPartnerBiddingProducts';
import PartnerBiddingProducts from '../screens/b2c-bidding-management/Partner_Available_products/partnerBiddingProductsGet';
import PartnerBiddingQuotes from '../screens/b2c-bidding-management/user-bidding-products/Partnet_Bidding_quotes/partnerBiddingQuotes';
import EditPartnerBiddingQuotes from '../screens/b2c-bidding-management/user-bidding-products/Partnet_Bidding_quotes/editPartnerBiddingQuotes';
import AddPartnerBiddingServices from '../screens/Services/bidding_management/Available-partner-services/addPartnerBiddingServices';
import PartnerBiddingServicesMain from '../screens/Services/partner-bidding-Services-center';
import UserNewBiddingQuotesForServices from '../screens/Services/bidding_management/User-new-Bidding-Quotes/usernewBiddingQuotes';
import userNewbiddingRequestServiceDetails from '../screens/Services/bidding_management/User-new-Bidding-Quotes/userNewbiddingRequestServiceDetails';
import PartnerBiddingServiceQuotes from '../screens/Services/bidding_management/Partnet_Bidding_quotes/partnerBiddingQuotes';
import EditPartnerServiceBiddingQuotes from '../screens/Services/bidding_management/Partnet_Bidding_quotes/editPartnerServiceBiddingQuotes';
import B2BUserNewBiddingProductQuotes from '../screens/b2c-bidding-management/b2b-user-products/User-new-Bidding-Quotes/usernewBiddingQuotes';
import userb2bNewbiddingRequestDetails from '../screens/b2c-bidding-management/b2b-user-products/User-new-Bidding-Quotes/b2buserNewbiddingRequestDetails';
import EditPartnerBiddingServices from '../screens/Services/bidding_management/Available-partner-services/editPartnerBiddingServices';
import PartnerBiddingServices from '../screens/Services/bidding_management/Available-partner-services/partnerBiddingServicesGet';
import userNewbiddingRequestDetails from '../screens/b2c-bidding-management/user-bidding-products/User-new-Bidding-Quotes/userNewbiddingRequestDetails';
import UserNewBiddingQuotes from '../screens/b2c-bidding-management/user-bidding-products/User-new-Bidding-Quotes/usernewBiddingQuotes';
import B2BUserPartnerBiddingQuotes from '../screens/b2c-bidding-management/b2b-user-products/B2C-Partnet_Bidding_Quotes/partnerB2CBiddingQuotes';
import EditB2BUserPartnerBiddingQuotes from '../screens/b2c-bidding-management/b2b-user-products/B2C-Partnet_Bidding_Quotes/editB2CPartnerBiddingQuotes';
import PartnerQuotedetails from '../screens/bidding/Partnerquotesdetails';
import UserBiddingProductsOrderNotification from '../screens/notifications/UsersBiddingProductsOrder';
import B2BBiddingProductOrderNotification from '../screens/notifications/B2BBiddingProductsOrders';
import UserBiddingServicesOrderNotification from '../screens/notifications/UserBiddingServiceOrders';
import FarmerDashboardLayout from '../components/layouts/DashboardLayout/Farmer';
import Farmer_Dashboard from '../screens/Farmer-Module/farmer_dashboard';
import FarmerPartnerProductMain from '../screens/Farmer-Module/Farmer-product-List';
import FarmerProductGet from '../screens/Farmer-Module/products/productsGet';
import AddFarmerProducts from '../screens/Farmer-Module/products/add-products/addProduct';
import AddFarmerProductspagetwo from '../screens/Farmer-Module/products/add-products/addProductpagetwo';
import AddFarmerProductspagethree from '../screens/Farmer-Module/products/add-products/addproductpagethree';
import AddFarmerProductspagefour from '../screens/Farmer-Module/products/add-products/addproductspagefour';
import FarmerPartnerProductsGet from '../screens/Farmer-Module/ownpartner-products/ownPartnerproductsGet';
import EditFarmerProduct from '../screens/Farmer-Module/products/edit-products/editProducts';
import EditFarmerProductpagetwo from '../screens/Farmer-Module/products/edit-products/editProductspagetwo';
import EditFarmerProductpagethree from '../screens/Farmer-Module/products/edit-products/editProductspagethree';
import EditFarmerProductpagefour from '../screens/Farmer-Module/products/edit-products/editProductspagefour';
import AddFarmerPartnerProducts from '../screens/Farmer-Module/ownpartner-products/add-ownpartner-products/addownPartnerProducts';
import AddFarmerPartnerProductspagetwo from '../screens/Farmer-Module/ownpartner-products/add-ownpartner-products/addownPartnerProductspageTwo';
import EditFarmerPartnerProducts from '../screens/Farmer-Module/ownpartner-products/edit-ownpartner-products/editownPartnerProducts';
import EditFarmerPartnerProductspagetwo from '../screens/Farmer-Module/ownpartner-products/edit-ownpartner-products/editownPartnerProductspagetwo';
import FarmerInventoryDetails from '../screens/Farmer-Module/inventory-management/getInvetory';
import EditFarmerIneventory from '../screens/Farmer-Module/inventory-management/editInventory';
import FarmerOrdersMain from '../screens/Farmer-Module/farmer-order-management';
import FarmerOrderCancelGet from '../screens/Farmer-Module/Order-management/order-return-and-cancel/orderCancelGet';
import FarmerOrderReturnGet from '../screens/Farmer-Module/Order-management/order-return-and-cancel/orderReturnGet';
import FarmerOrderDetails from '../screens/Farmer-Module/Order-management/orders-details/orderDetails';
import FarmerEditOrders from '../screens/Farmer-Module/Order-management/orders-details/editOrders';
import FarmerAddressDetails from '../screens/Farmer-Module/partneraddress/getFarmerAddress';
import AddFarmerAddress from '../screens/Farmer-Module/partneraddress/addFarmerAddress';
import EditFarmerAddress from '../screens/Farmer-Module/partneraddress/editFarmerAddress';


export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {

  const user = useSelector(state => state.auth.user);

  // async function getValueFor(key) {
  //   let result = await SecureStore.getItemAsync(key);
  //   // alert(result);
  //   if (result) {
  //     dispatch({
  //       type: ActionType.USER_SUCCESS,
  //       data: JSON.parse(result),
  //       login: false
  //   });
  //   }

  // }
  // getValueFor('user');
  // alert(user);

  React.useEffect(() => {

  }, [user])

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <Root />
    </NavigationContainer>
  );
}

const HomeStack = createDrawerNavigator<EsiDrawerStackParamList>();

function AllNavigator() {
  return (
    <HomeStack.Navigator screenOptions={{
      drawerPosition: "right", swipeEnabled: false
    }} drawerContent={(props) => <EsiDrawer {...props} />}>
      <HomeStack.Screen name="All" component={AllFilters} options={{ headerShown: false }} />
    </HomeStack.Navigator>

  );

}

const PartnerStack = createStackNavigator();

function Root() {
  return (
    <PartnerStack.Navigator>
      <Stack.Screen name="SignIn" component={SignIN} options={{ headerShown: false }} />
      <Stack.Screen name="SignUp" component={SignUP} options={{ headerShown: false }} />
      <Stack.Screen name="ForgotPassword" component={ForgotPassword} options={{ headerShown: false }} />
      <Stack.Screen name="SetPassword" component={SignUPPassword} options={{ headerShown: false }} />
      <Stack.Screen name="ActivateAccount" component={ActivateAccount} options={{ headerShown: false }} />
      <Stack.Screen name="Notification" component={Notification} options={{ headerShown: false }} />
      <PartnerStack.Screen name="B2C" component={B2CRootNavigator} options={{ headerShown: false }} />
      <PartnerStack.Screen name="B2B" component={B2BRootNavigator} options={{ headerShown: false }} />
      <PartnerStack.Screen name="Services" component={ServicesRootNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="OrderTracking" component={DeliveryTracking} options={{ headerShown: false }} />
      <PartnerStack.Screen name="Properties" component={PropertiesRootNavigator} options={{ headerShown: false }} />
      <PartnerStack.Screen name="Farmer" component={FarmerRootNavigator} options={{ headerShown: false }} />
      <PartnerStack.Screen name="Biddingproducts" component={BiddingRootNavigator} options={{ headerShown: false }} />
    </PartnerStack.Navigator>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 * 
 
/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */

const Stack = createDrawerNavigator<HomeMainStackParamList>();

function B2CRootNavigator() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return (

    <Stack.Navigator backBehavior="history" screenOptions={{ swipeEnabled: false }} drawerContent={(props) => <DashboardLayout {...props} />}>
      <Stack.Screen name="Dashboard" component={Dashboard} options={{ headerShown: false }} />
      <Stack.Screen name="ReportsGet" component={Reports} options={{ headerShown: false }} />
      <Stack.Screen name="OrderStatistics" component={OrderStatistics} options={{ headerShown: false }} />
      <Stack.Screen name="ProfitStatistics" component={ProfitStatistics} options={{ headerShown: false }} />
      <Stack.Screen name="UserStatistics" component={UserStatistics} options={{ headerShown: false }} />
      <Stack.Screen name="CouponStatistics" component={CouponStatistics} options={{ headerShown: false }} />
      <Stack.Screen name="DealStatistics" component={DealStatistics} options={{ headerShown: false }} />
      <Stack.Screen name="ProductStatistics" component={ProductStatistics} options={{ headerShown: false }} />
      <Stack.Screen name="BrandsGet" component={BrandsGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddBrand" component={AddBrand} options={{ headerShown: false }} />
      <Stack.Screen name="Employees" component={EmployeeDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddEmployee" component={AddEmployee} options={{ headerShown: false }} />
      <Stack.Screen name="EditEmployee" component={EditEmployee} options={{ headerShown: false }} />
      <Stack.Screen name="Tickets" component={TicketDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddTicket" component={AddTicket} options={{ headerShown: false }} />
      <Stack.Screen name="EditTicket" component={EditTicket} options={{ headerShown: false }} />
      <Stack.Screen name="AddressGet" component={AddressGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddAddress" component={AddAddress} options={{ headerShown: false }} />
      <Stack.Screen name="EditAddress" component={EditAddress} options={{ headerShown: false }} />
      <Stack.Screen name="GiftVendorsGet" component={GiftVendorsGet} options={{ headerShown: false }} />
      <Stack.Screen name="EditGiftVendor" component={EditGiftVendor} options={{ headerShown: false }} />
      <Stack.Screen name="Gift" component={GiftGet} options={{ headerShown: false }} />
      <Stack.Screen name="EditGift" component={EditGifts} options={{ headerShown: false }} />
      <Stack.Screen name="GiftImage" component={ImagesGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddImage" component={AddImage} options={{ headerShown: false }} />
      <Stack.Screen name="EditImage" component={EditImage} options={{ headerShown: false }} />
      <Stack.Screen name="OrderReturnGet" component={ReturnOrdersGet} options={{ headerShown: false }} />
      <Stack.Screen name="OrderCancelGet" component={CancelOrdersGet} options={{ headerShown: false }} />
      <Stack.Screen name="DealsGet" component={DealDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddDealBasic" component={AddDealBasic} options={{ headerShown: false }} />
      <Stack.Screen name="AddDealConfig" component={AddDealConfig} options={{ headerShown: false }} />
      <Stack.Screen name="EditDealBasic" component={EditDealBasic} options={{ headerShown: false }} />
      <Stack.Screen name="EditDealConfig" component={EditDealConfig} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerpaymentGet" component={PartnerpaymentDetails} options={{ headerShown: false }} />
      <Stack.Screen name="EditPartnerpayment" component={EditPartnerpayment} options={{ headerShown: false }} />
      <Stack.Screen name="UserpaymentGet" component={UserpaymentDetails} options={{ headerShown: false }} />
      <Stack.Screen name="EditUserpayment" component={EditUserpayment} options={{ headerShown: false }} />
      <Stack.Screen name="TransactionhistoryGet" component={TransactionDetails} options={{ headerShown: false }} />
      <Stack.Screen name="EditTransactionhistory" component={EditTransactionhistory} options={{ headerShown: false }} />
      <Stack.Screen name="Inventory" component={InventoryDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddInventory" component={AddInventory} options={{ headerShown: false }} />
      <Stack.Screen name="EditInventory" component={EditIneventory} options={{ headerShown: false }} />
      <Stack.Screen name="Products" component={ProductGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddProduct" component={AddProducts} options={{ headerShown: false }} />
      <Stack.Screen name="AddProducts" component={AddProductspagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="Addproducts" component={AddProductspagethree} options={{ headerShown: false }} />
      <Stack.Screen name="addproducts" component={AddProductspagefour} options={{ headerShown: false }} />
      <Stack.Screen name="EditProduct" component={EditProduct} options={{ headerShown: false }} />
      <Stack.Screen name="EditProducts" component={EditProductpagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="Editproducts" component={EditProductpagethree} options={{ headerShown: false }} />
      <Stack.Screen name="editproducts" component={EditProductpagefour} options={{ headerShown: false }} />
      <Stack.Screen name="RelatedProducts" component={RelatedProductsGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddRelatedProducts" component={AddRelatedProduct} options={{ headerShown: false }} />
      <Stack.Screen name="EditRelatedProducts" component={EditRelatedProduct} options={{ headerShown: false }} />
      <Stack.Screen name="ProductPartnerGet" component={PartnerProductsGet} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerownProductsGet" component={PartnerownProductsGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddownProductPartnerPagetwo" component={AddownProductPartnerPagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="AddownProductPartner" component={AddownProductPartner} options={{ headerShown: false }} />
      <Stack.Screen name="EditownProductPartner" component={EditownProductPartner} options={{ headerShown: false }} />
      <Stack.Screen name="EditownProductPartnerPageTwo" component={EditownProductPartnerPageTwo} options={{ headerShown: false }} />
      <Stack.Screen name="AddPartnerProductsPagetwo" component={AddProductPartnerPagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="AddProductPartner" component={AddProductPartner} options={{ headerShown: false }} />
      <Stack.Screen name="EditProductPartner" component={EditProductPartner} options={{ headerShown: false }} />
      <Stack.Screen name="EditPartnerProductsPagetwo" component={EditProductPartnerPageTwo} options={{ headerShown: false }} />
      {/* <Stack.Screen name="OrderReturnGet" component={ReturnOrdersGet} options={{ headerShown: false }} /> */}
      <Stack.Screen name="ProductMasterGet" component={ProductMastersGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddProductMaster" component={AddProductMaster} options={{ headerShown: false }} />
      <Stack.Screen name="AddProductMasters" component={AddProductMasterpagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="AddProductmasters" component={AddProductMasterpagethree} options={{ headerShown: false }} />
      <Stack.Screen name="EditProductMaster" component={EditProductMaster} options={{ headerShown: false }} />
      <Stack.Screen name="EditProductMasters" component={EditProductMastertwo} options={{ headerShown: false }} />
      <Stack.Screen name="EditProductmasters" component={EditProductMasterthree} options={{ headerShown: false }} />
      <Stack.Screen name="ProductColors" component={ProductColorGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddProductColor" component={AddColor} options={{ headerShown: false }} />
      <Stack.Screen name="EditProductColor" component={EditColor} options={{ headerShown: false }} />
      <Stack.Screen name="ProductSizes" component={ProductSizeGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddProductSize" component={AddProductSize} options={{ headerShown: false }} />
      <Stack.Screen name="EditProductSize" component={EditProductSize} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerProfile" component={PartnerProfile} options={{ headerShown: false }} />
      <Stack.Screen name="EmployeProfile" component={EmployeProfile} options={{ headerShown: false }} />
      <Stack.Screen name="CouponsGet" component={CouponDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddCouponBasic" component={AddCouponBasic} options={{ headerShown: false }} />
      <Stack.Screen name="AddCouponConfig" component={AddCouponConfig} options={{ headerShown: false }} />
      <Stack.Screen name="EditCouponBasic" component={EditCouponBasic} options={{ headerShown: false }} />
      <Stack.Screen name="EditCouponConfig" component={EditCouponConfig} options={{ headerShown: false }} />
      <Stack.Screen name="ShippingGet" component={ShippingDetails} options={{ headerShown: false }} />
      <Stack.Screen name="EditShipping" component={EditShipping} options={{ headerShown: false }} />
      <Stack.Screen name="ViewShipping" component={ViewShipping} options={{ headerShown: false }} />
      <Stack.Screen name="ReturnedShippingGet" component={ShippingreturnedDetails} options={{ headerShown: false }} />
      <Stack.Screen name="ViewReturnedShipping" component={ViewreturnedShipping} options={{ headerShown: false }} />
      <Stack.Screen name="CancelledShippingGet" component={ShippingcancelledDetails} options={{ headerShown: false }} />
      <Stack.Screen name="ViewCancelledShipping" component={ViewcancelledShipping} options={{ headerShown: false }} />
      <Stack.Screen name="SettingsGet" component={SettingsDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddSetting" component={AddSetting} options={{ headerShown: false }} />
      <Stack.Screen name="EditSetting" component={EditSetting} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerBank" component={Partnerbank} options={{ headerShown: false }} />
      <Stack.Screen name='Transactions' component={Transactions} options={{ headerShown: false }} />
      <Stack.Screen name="ReviewsandRatingsGet" component={ReviewsandratingsDetails} options={{ headerShown: false }} />
      <Stack.Screen name="HowPartnerAssociationWorksGet" component={HowPartnerAssocWorks} options={{ headerShown: false }} />
      <Stack.Screen name="PrivacyPolicy" component={Privacypolicy} options={{ headerShown: false }} />
      <Stack.Screen name="ContactUs" component={ContactUs} options={{ headerShown: false }} />
      <Stack.Screen name="TermsAndConditions" component={TermsAndConditions} options={{ headerShown: false }} />
      {/* <Stack.Screen name="DashboardLayout" component={DashboardLayout} options={{ headerShown: false }} /> */}
      <Stack.Screen name="PartnerMainCenter" component={PartnerMain} options={{ headerShown: false }} />
      <Stack.Screen name="OrderMainCenter" component={OrderMain} options={{ headerShown: false }} />
      <Stack.Screen name="ProductMainCenter" component={PartnerProductMain} options={{ headerShown: false }} />
      <Stack.Screen name="PaymentsMainCenter" component={PaymentsMain} options={{ headerShown: false }} />
      <Stack.Screen name="ServiceLevelAgreementGet" component={ServiceLevelAgreement} options={{ headerShown: false }} />
      <Stack.Screen name="SBCPartnercityGet" component={SbcpartnercityDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddSBCPartnercity" component={AddSBCPartnercity} options={{ headerShown: false }} />
      <Stack.Screen name="EditSBCPartnercity" component={EditSBCPartnercity} options={{ headerShown: false }} />
      <Stack.Screen name="SBCPartnercitycouponGet" component={SbcpartnercitycouponDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddSBCPartnercitycoupon" component={AddSBCPartnercitycoupon} options={{ headerShown: false }} />
      <Stack.Screen name="EditSBCPartnercitycoupon" component={EditSBCPartnercitycoupon} options={{ headerShown: false }} />
      <Stack.Screen name="SBCPartnercitydealGet" component={SbcpartnercitydealDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddSBCPartnercitydeal" component={AddSBCPartnercitydeal} options={{ headerShown: false }} />
      <Stack.Screen name="EditSBCPartnercitydeal" component={EditSBCPartnercitydeal} options={{ headerShown: false }} />
      <Stack.Screen name="SBCPartnercityproductGet" component={SbcpartnercityproductDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddSBCPartnercityproduct" component={AddSBCPartnercityproduct} options={{ headerShown: false }} />
      <Stack.Screen name="EditSBCPartnercityproduct" component={EditSBCPartnercityproduct} options={{ headerShown: false }} />
      <Stack.Screen name="OrderManagementGet" component={Ordersget} options={{ headerShown: false }} />
      <Stack.Screen name="EditOrder" component={EditOrders} options={{ headerShown: false }} />
      <Stack.Screen name="OrderDetails" component={OrderDetails} options={{ headerShown: false }} />
      <Stack.Screen name="B2bOrderDetails" component={B2bOrderDetails} options={{ headerShown: false }} />
      <Stack.Screen name="B2bEditOrder" component={B2bEditOrders} options={{ headerShown: false }} />
      {/* <Stack.Screen name="ActivateAccount" component={ActivateAccount} options={{ headerShown: false }} /> */}
      <Stack.Screen name="UserBiddingProductsOrderNotification" component={UserBiddingProductsOrderNotification} options={{ headerShown: false }} />
      <Stack.Screen name="B2BBiddingProductOrderNotification" component={B2BBiddingProductOrderNotification} options={{ headerShown: false }} />
      <Stack.Screen name="UserBiddingServicesOrderNotification" component={UserBiddingServicesOrderNotification} options={{ headerShown: false }} />
      <Stack.Screen name="couponNotification" component={CouponNotification} options={{ headerShown: false }} />
      <Stack.Screen name="dealNotification" component={DealNotification} options={{ headerShown: false }} />
      <Stack.Screen name="productNotification" component={ProductNotification} options={{ headerShown: false }} />
      <Stack.Screen name="orderNotification" component={OrderNotification} options={{ headerShown: false }} />
      <Stack.Screen name="b2borderNotification" component={B2BOrderNotification} options={{ headerShown: false }} />
      <Stack.Screen name="DeliveryAgent" component={DeliveryAgentDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddDeliveryAgent" component={AddDeliveryAgent} options={{ headerShown: false }} />
      <Stack.Screen name="Ticketing" component={Ticketing} options={{ headerShown: false }} />
      <Stack.Screen name="Addtickets" component={Addtickets} options={{ headerShown: false }} />
      <Stack.Screen name="Comments" component={Comments} options={{ headerShown: false }} />
      <Stack.Screen name="addpartnershopbylocation" component={Addpartnershopbylocation} options={{ headerShown: false }} />
      <Stack.Screen name="editpartnershopbylocation" component={Editpartnershopbylocation} options={{ headerShown: false }} />
      <Stack.Screen name="partnershopbylocationget" component={Partnershopbylocationget} options={{ headerShown: false }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Screen name="EditDeliveryAgent" component={EditDeliveryAgent} options={{ headerShown: false }} />
      <Stack.Screen name="ShoppersGet" component={ShoppersGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddShoppersProducts" component={AddShopperProducts} options={{ headerShown: false }} />
      <Stack.Screen name="EditShoppersProduct" component={EditShoppersProduct} options={{ headerShown: false }} />
      {/* <Stack.Screen name="ShoppersInventoryGet" component={ShoppersinventoryDetails} options={{ headerShown: false }} /> */}
      <Stack.Screen name="ShoppersInventoryCreate" component={ShoppersInventoryCreate} options={{ headerShown: false }} />
      <Stack.Screen name="ShoppersInventoryUpdate" component={ShoppersInventoryUpdate} options={{ headerShown: false }} />
      <Stack.Screen name="WalletHistory" component={Withdrawals} options={{ headerShown: false }} />
      <Stack.Screen name="MyWallet" component={WalletHistory} options={{ headerShown: false }} />
      <Stack.Screen name="ShoppersownGet" component={ShoppersownGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddownShopperProducts" component={AddownShopperProducts} options={{ headerShown: false }} />
      <Stack.Screen name="EditownShoppersProduct" component={EditownShoppersProduct} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerBiddingMainCenter" component={PartnerBiddingMain} options={{ headerShown: false }} />
      <Stack.Screen name="AddPartnerBiddingProducts" component={AddPartnerBiddingProducts} options={{ headerShown: false }} />
      <Stack.Screen name="EditPartnerBiddingProducts" component={EditPartnerBiddingProducts} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerBiddingProducts" component={PartnerBiddingProducts} options={{ headerShown: false }} />
      <Stack.Screen name="UserNewBiddingQuotes" component={UserNewBiddingQuotes} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerBiddingQuotes" component={PartnerBiddingQuotes} options={{ headerShown: false }} />
      <Stack.Screen name="EditPartnerBiddingQuotes" component={EditPartnerBiddingQuotes} options={{ headerShown: false }} />
      <Stack.Screen name="userNewbiddingRequestDetails" component={userNewbiddingRequestDetails} options={{ headerShown: false }} />
      <Stack.Screen name="B2BUserNewBiddingProductQuotes" component={B2BUserNewBiddingProductQuotes} options={{ headerShown: false }} />
      <Stack.Screen name="userb2bNewbiddingRequestDetails" component={userb2bNewbiddingRequestDetails} options={{ headerShown: false }} />
      <Stack.Screen name="B2BUserPartnerBiddingQuotes" component={B2BUserPartnerBiddingQuotes} options={{ headerShown: false }} />
      <Stack.Screen name="EditB2BUserPartnerBiddingQuotes" component={EditB2BUserPartnerBiddingQuotes} options={{ headerShown: false }} />
      <Stack.Group >
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

const B2BStack = createDrawerNavigator<B2BHomeMainStackParamList>();

function B2BRootNavigator() {
  return (
    <B2BStack.Navigator backBehavior="history" drawerContent={(props) => <B2BDashboardLayout {...props} />}>
      <B2BStack.Screen name="Main" component={AllNavigator} options={{ headerShown: false }} />
      <B2BStack.Screen name="AllProducts" component={AllProducts} options={{ headerShown: false }} />
      <Stack.Screen name="EmployeProfile" component={EmployeProfile} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerProfile" component={PartnerProfile} options={{ headerShown: false }} />
      <Stack.Screen name="ServiceLevelAgreementGet" component={ServiceLevelAgreement} options={{ headerShown: false }} />
      <Stack.Screen name="ContactUs" component={ContactUs} options={{ headerShown: false }} />
      <Stack.Screen name="PrivacyPolicy" component={Privacypolicy} options={{ headerShown: false }} />
      <Stack.Screen name="TermsAndConditions" component={TermsAndConditions} options={{ headerShown: false }} />
      <Stack.Screen name="Ticketing" component={Ticketing} options={{ headerShown: false }} />
      <Stack.Screen name="Addtickets" component={Addtickets} options={{ headerShown: false }} />
      <B2BStack.Screen name="DeliveryAgent" component={DeliveryAgentDetails} options={{ headerShown: false }} />
      <B2BStack.Screen name="EditDeliveryAgent" component={EditDeliveryAgent} options={{ headerShown: false }} />
      <B2BStack.Screen name="AddDeliveryAgent" component={AddDeliveryAgent} options={{ headerShown: false }} />
      <B2BStack.Screen name="ProductDetails" component={ProductDetails} options={{ headerShown: false }} />
      <Stack.Screen name="SubCategory" component={SubCategory} options={{ headerShown: false }} />
      {/* <B2BStack.Screen name="UserAddress" component={Useraddresses} options={{ headerShown: false }} />
       <B2BStack.Screen name="AddUserAddress" component={AddUserAddress} options={{ headerShown: false }} />
       <B2BStack.Screen name="EditUserAddress" component={EditUserAddress} options={{ headerShown: false }} /> */}
      <Stack.Screen name="AddressGet" component={AddressGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddAddress" component={AddAddress} options={{ headerShown: false }} />
      <Stack.Screen name="EditAddress" component={EditAddress} options={{ headerShown: false }} />
      <B2BStack.Screen name="Cart" component={Cart} options={{ headerShown: false }} />
      <B2BStack.Screen name="CartSummary" component={CartSummary} options={{ headerShown: false }} />
      <B2BStack.Screen name="Transactions" component={AllTransaction} options={{ headerShown: false }} />
      <B2BStack.Screen name="OrderDetail" component={MyOrderDetails} options={{ headerShown: false }} />
      <B2BStack.Screen name="Orders" component={MyOrders} options={{ headerShown: false }} />
      <Stack.Screen name="all-combinations" component={AllCombinations} options={{ headerShown: false }} />
      <Stack.Screen name="Brandsb2b" component={Brandsb2b} options={{ headerShown: false }} />
      <Stack.Screen name="Categoryb2b" component={Categoryb2b} options={{ headerShown: false }} />
      <Stack.Screen name="Partnersb2b" component={Partnersb2b} options={{ headerShown: false }} />
      <Stack.Screen name="BrandDetailsb2b" component={BrandDetailsb2b} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerDetailsb2b" component={PartnerDetailsb2b} options={{ headerShown: false }} />
    </B2BStack.Navigator>
  );
}
const ServicesStack = createDrawerNavigator<ServicesHomeMainStackParamList>();

function ServicesRootNavigator() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return (

    <ServicesStack.Navigator backBehavior="history" screenOptions={{ swipeEnabled: false }} drawerContent={(props) => <ServicesDashboardLayout {...props} />}>
      <ServicesStack.Screen name="ServicesDashboard" component={ServicesDashboard} options={{ headerShown: false }} />
      <Stack.Screen name="Newuserservices" component={Newuserservices} options={{ headerShown: false }} />
      <Stack.Screen name="EditUserservice" component={EditUserservice} options={{ headerShown: false }} />
      <Stack.Screen name="EditUserservicepagetwo" component={EditUserservicepagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="Partnerservice" component={Partnerservice} options={{ headerShown: false }} />
      <Stack.Screen name="Addpartnerservice" component={Addpartnerservice} options={{ headerShown: false }} />
      <Stack.Screen name="AddPartnerservicesPagetwo" component={AddPartnerservicesPagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="EditPartnerservice" component={EditPartnerservice} options={{ headerShown: false }} />
      <Stack.Screen name="EditPartnerservicepagetwo" component={EditPartnerservicepagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="AddressGet" component={AddressGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddAddress" component={AddAddress} options={{ headerShown: false }} />
      <Stack.Screen name="EditAddress" component={EditAddress} options={{ headerShown: false }} />
      <ServicesStack.Screen name="EmployeProfile" component={EmployeProfile} options={{ headerShown: false }} />
      <ServicesStack.Screen name="PartnerProfile" component={PartnerProfile} options={{ headerShown: false }} />
      <Stack.Screen name="Accepteduserservices" component={Accepteduserservices} options={{ headerShown: false }} />
      <Stack.Screen name="Rejecteduserservices" component={Rejecteduserservices} options={{ headerShown: false }} />
      <Stack.Screen name="Completeduserservices" component={Completeduserservices} options={{ headerShown: false }} />
      <Stack.Screen name="Employees" component={EmployeeDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddEmployee" component={AddEmployee} options={{ headerShown: false }} />
      <Stack.Screen name="EditEmployee" component={EditEmployee} options={{ headerShown: false }} />
      <Stack.Screen name="Inprogressuserservices" component={Inprogressuserservices} options={{ headerShown: false }} />
      <Stack.Screen name="EmployeNewuserservices" component={EmployeNewuserservices} options={{ headerShown: false }} />
      <Stack.Screen name="EmployeCompletedserservices" component={EmployeCompletedserservices} options={{ headerShown: false }} />
      <Stack.Screen name="EmployeInprogressserservices" component={EmployeInprogressserservices} options={{ headerShown: false }} />
      <Stack.Screen name="EditAcceptedUserservice" component={EditAcceptedUserservice} options={{ headerShown: false }} />
      <Stack.Screen name="EditNewUserservice" component={EditNewUserservice} options={{ headerShown: false }} />
      <Stack.Screen name="EditInprogressuserservice" component={EditInprogressuserservice} options={{ headerShown: false }} />
      <Stack.Screen name="WalletHistory" component={Withdrawals} options={{ headerShown: false }} />
      <Stack.Screen name="MyWallet" component={WalletHistory} options={{ headerShown: false }} />
      <Stack.Screen name="EditEmployeInprogressuserservice" component={EditEmployeInprogressuserservice} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerBiddingServicesMain" component={PartnerBiddingServicesMain} options={{ headerShown: false }} />
      <Stack.Screen name="AddPartnerBiddingServices" component={AddPartnerBiddingServices} options={{ headerShown: false }} />
      <Stack.Screen name="EditPartnerBiddingServices" component={EditPartnerBiddingServices} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerBiddingServices" component={PartnerBiddingServices} options={{ headerShown: false }} />
      <Stack.Screen name="UserBiddingServicesOrderNotification" component={UserBiddingServicesOrderNotification} options={{ headerShown: false }} />
      <Stack.Screen name="UserNewBiddingQuotesForServices" component={UserNewBiddingQuotesForServices} options={{ headerShown: false }} />
      <Stack.Screen name="userNewbiddingRequestServiceDetails" component={userNewbiddingRequestServiceDetails} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerBiddingServiceQuotes" component={PartnerBiddingServiceQuotes} options={{ headerShown: false }} />
      <Stack.Screen name="EditPartnerServiceBiddingQuotes" component={EditPartnerServiceBiddingQuotes} options={{ headerShown: false }} />
    </ServicesStack.Navigator>
  );
}
const PropertiesStack = createDrawerNavigator<PropertiesHomeMainStackParamList>();

function PropertiesRootNavigator() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return (

    <PropertiesStack.Navigator backBehavior="history" screenOptions={{ swipeEnabled: false }} drawerContent={(props) => <PropertiesDashboardLayout {...props} />}>
      <PropertiesStack.Screen name="PropertiesDashboard" component={PropertiesDashboard} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="GetProperties" component={GetProperties} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="AddProperty" component={AddProperty} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="EditProperty" component={EditProperty} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="EditPropertyPageTwo" component={EditPropertyPageTwo} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="GetEvents" component={GetEvents} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="EditUserEvents" component={EditUserEvents} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="EditUserEventspagetwo" component={EditUserEventspagetwo} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="EmployeProfile" component={EmployeProfile} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="PartnerProfile" component={PartnerProfile} options={{ headerShown: false }} />
      <PropertiesStack.Screen name="bookeddatesget" component={bookeddatesget} options={{ headerShown: false }} />
    </PropertiesStack.Navigator>
  );
}
const BiddingStack = createDrawerNavigator<BiddingHomeMainStackParamList>();

function BiddingRootNavigator() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return (

    <BiddingStack.Navigator backBehavior="history" screenOptions={{ swipeEnabled: false }} drawerContent={(props) => <BiddingDashboardLayout {...props} />}>
      <Stack.Screen name="Biddingproducts" component={Biddingproducts} options={{ headerShown: false }} />
      <Stack.Screen name="CategoryFilters" component={CategoryFilters} options={{ headerShown: false }} />
      <Stack.Screen name="NewBidProducts" component={NewBidProducts} options={{ headerShown: false }} />
      <Stack.Screen name="BidProductDetails" component={BidProductDetails} options={{ headerShown: false }} />
      <Stack.Screen name="CartOfBids" component={CartOfBids} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerQuotedetails" component={PartnerQuotedetails} options={{ headerShown: false }} />
    </BiddingStack.Navigator>
  );
}
const FarmerStack = createDrawerNavigator<FarmerHomeMainStackParamList>();

function FarmerRootNavigator() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return (

    <FarmerStack.Navigator backBehavior="history" screenOptions={{ swipeEnabled: false }} drawerContent={(props) => <FarmerDashboardLayout {...props} />}>
      <Stack.Screen name="Farmer_Dashboard" component={Farmer_Dashboard} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerPartnerProductMain" component={FarmerPartnerProductMain} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerOrdersMain" component={FarmerOrdersMain} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerProductGet" component={FarmerProductGet} options={{ headerShown: false }} />
      <Stack.Screen name="AddFarmerProducts" component={AddFarmerProducts} options={{ headerShown: false }} />
      <Stack.Screen name="AddFarmerProductspagetwo" component={AddFarmerProductspagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="AddFarmerProductspagethree" component={AddFarmerProductspagethree} options={{ headerShown: false }} />
      <Stack.Screen name="AddFarmerProductspagefour" component={AddFarmerProductspagefour} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerPartnerProductsGet" component={FarmerPartnerProductsGet} options={{ headerShown: false }} />
      <Stack.Screen name="EditFarmerProduct" component={EditFarmerProduct} options={{ headerShown: false }} />
      <Stack.Screen name="EditFarmerProductpagetwo" component={EditFarmerProductpagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="EditFarmerProductpagethree" component={EditFarmerProductpagethree} options={{ headerShown: false }} />
      <Stack.Screen name="EditFarmerProductpagefour" component={EditFarmerProductpagefour} options={{ headerShown: false }} />
      <Stack.Screen name="AddFarmerPartnerProducts" component={AddFarmerPartnerProducts} options={{ headerShown: false }} />
      <Stack.Screen name="AddFarmerPartnerProductspagetwo" component={AddFarmerPartnerProductspagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="EditFarmerPartnerProducts" component={EditFarmerPartnerProducts} options={{ headerShown: false }} />
      <Stack.Screen name="EditFarmerPartnerProductspagetwo" component={EditFarmerPartnerProductspagetwo} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerInventoryDetails" component={FarmerInventoryDetails} options={{ headerShown: false }} />
      <Stack.Screen name="EditFarmerIneventory" component={EditFarmerIneventory} options={{ headerShown: false }} />
      <FarmerStack.Screen name="PartnerProfile" component={PartnerProfile} options={{ headerShown: false }} />
      <Stack.Screen name="WalletHistory" component={Withdrawals} options={{ headerShown: false }} />
      <Stack.Screen name="MyWallet" component={WalletHistory} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerBank" component={Partnerbank} options={{ headerShown: false }} />
      <Stack.Screen name='Transactions' component={Transactions} options={{ headerShown: false }} />
      <Stack.Screen name="PrivacyPolicy" component={Privacypolicy} options={{ headerShown: false }} />
      <Stack.Screen name="ContactUs" component={ContactUs} options={{ headerShown: false }} />
      <Stack.Screen name="TermsAndConditions" component={TermsAndConditions} options={{ headerShown: false }} />
      <Stack.Screen name="Ticketing" component={Ticketing} options={{ headerShown: false }} />
      <Stack.Screen name="Addtickets" component={Addtickets} options={{ headerShown: false }} />
      <Stack.Screen name="Comments" component={Comments} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerOrderDetails" component={FarmerOrderDetails} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerOrderCancelGet" component={FarmerOrderCancelGet} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerOrderReturnGet" component={FarmerOrderReturnGet} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerEditOrders" component={FarmerEditOrders} options={{ headerShown: false }} />
      <Stack.Screen name="EmployeProfile" component={EmployeProfile} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerAddressDetails" component={FarmerAddressDetails} options={{ headerShown: false }} />
      <Stack.Screen name="AddFarmerAddress" component={AddFarmerAddress} options={{ headerShown: false }} />
      <Stack.Screen name="EditFarmerAddress" component={EditFarmerAddress} options={{ headerShown: false }} />
     
      </FarmerStack.Navigator>
  );
}