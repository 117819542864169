import 'react-native-gesture-handler';
import React, {useEffect } from 'react';
import { Avatar, Card, Text } from "react-native-paper";
import { useDispatch, useSelector } from 'react-redux';
import { Dimensions, Platform, View } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { dashboardActivebokingCount } from '../../../../../state/actions/Properties/PropertiesAction';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

function TotalActiveBookings({navigate}) {
    //API dispatch
    const dispatch = useDispatch();
    //Activebooking contains the data from  GET API using state call
    const Activebooking = useSelector(state => state.property.Activebookingcount.data);
    const EmailID = useSelector(state => state.auth?.user);
  //dispatch is used to call the API
    useEffect(() => {
        let formData = {
            "Partner_Detail_ID": EmailID?.Partner_Details_Id,
            "Records_Filter": "FILTER"
        };
        dispatch(dashboardActivebokingCount(formData))
    }, []);

    return (
        <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', padding: 5 }}>
            <Card style={{
                borderRadius: 10, flex: 1, padding: 10, backgroundColor: 'white', margin: 10, flexDirection: 'row', shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, elevation: 10, marginLeft: 10, marginRight: 10,
                maxWidth: Platform.OS === "ios" ? windowWidth >300 ? 450 : 450 : 400, minWidth: Platform.OS === "ios" ? 350 : 320
            }}>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -70 }}>
                    <Avatar.Icon size={50} icon="clipboard-text-search-outline" color="white" style={{ backgroundColor: 'blue' }} />
                </View>
                <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                    <Text style={{ padding: 10, fontWeight: 'bold' }}>ACTIVE BOOKINGS</Text>
                    <Text style={{ padding: 10, fontSize: 20, fontWeight: "bold" }}>{Activebooking.Total}</Text>
                </View>
                <View style={{ flexDirection: 'row', margin: 10 }}>
                    {Activebooking.Month - Activebooking.Avg >= 0 &&
                        <>
                            <Ionicons name="arrow-up" size={25} color="green" />
                            <View style={{ flexDirection: 'column', margin: 5 }}>
                                <Text >
                                    {Activebooking.Month ? Math.round(Activebooking.Month * Math.pow(10, 2)) / Math.pow(10, 2) : 0}
                                </Text>
                            </View>
                        </>
                    }
                    {Activebooking.Month - Activebooking.Avg < 0 &&
                        <>
                            <Ionicons name="arrow-down" size={25} color="red" />
                            <View style={{ flexDirection: 'column', margin: 5 }}>
                                <Text >
                                    {Activebooking.Month ? Math.round(Activebooking.Month * Math.pow(10, 2)) / Math.pow(10, 2) : 0}
                                </Text>
                            </View>
                        </>
                    }

                    <View style={{ flexDirection: 'column', margin: 5 }}>
                        <Text >
                            Since last 30 days
                        </Text>
                    </View>

                </View>
            </Card>
        </View>
    );
}


export default TotalActiveBookings;