import {
    PRODUCTSIZES_REQUEST,
    PRODUCTSIZES_SUCCESS,
    PRODUCTSIZES_FAIL,
    PRODUCTSIZE_GET_REQUEST,
    PRODUCTSIZE_GET_SUCCESS,
    PRODUCTSIZE_GET_FAIL,
    PRODUCTSIZE_CREATE_REQUEST,
    PRODUCTSIZE_CREATE_SUCCESS,
    PRODUCTSIZE_CREATE_FAIL,
    PRODUCTSIZE_UPDATE_REQUEST,
    PRODUCTSIZE_UPDATE_SUCCESS,
    PRODUCTSIZE_UPDATE_FAIL,
    PRODUCTSIZE_DELETE_REQUEST,
    PRODUCTSIZE_DELETE_SUCCESS,
    PRODUCTSIZE_DELETE_FAIL,   

    PRODUCTSIZES_FILTER,   
    
} from '../actions/productsizeAction'
  
const initialState = {
    productsizes: { all: [], error: '', isLoading: false },
    productsize: { productsize: {}, error: '', isLoading: false },
    productsizeCreate: { productsize: {}, error: '', isLoading: false },
    productsizeUpdate: { productsize: {}, error: '', isLoading: false },
    productsizeDelete: { productsize: {}, error: '', isLoading: false },
    productmasterFilter: { select: 'All', search: '' },
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case PRODUCTSIZES_REQUEST:
        return { ...state, productsizes: { all: state.productsizes.all, error: '', isLoading: true } };
      case PRODUCTSIZES_SUCCESS:
        return { ...state, productsizes: { all: action.payload, error: '', isLoading: false } };
      case PRODUCTSIZES_FAIL:
        return { ...state, productsizes: { all: [], error: action.payload, isLoading: false } };
      case PRODUCTSIZE_GET_REQUEST:
        return { ...state, productsize: { productsize: {}, error: '', isLoading: true } };
      case PRODUCTSIZE_GET_SUCCESS:
        return { ...state, productsize: { productsize: action.payload, error: '', isLoading: false } };
      case PRODUCTSIZE_GET_FAIL:
        return { ...state, productsize: { productsize: {}, error: action.payload, isLoading: false } };
      case PRODUCTSIZE_CREATE_REQUEST:
        return { ...state, productsizeCreate: { productsize: {}, error: '', isLoading: true } };
      case PRODUCTSIZE_CREATE_SUCCESS:
        return { ...state, productsizeCreate: { productsize: state.productsizeCreate.productsize, error: '', isLoading: false } };
      case PRODUCTSIZE_CREATE_FAIL:
        return { ...state, productsizeCreate: { productsize: {}, error: action.payload, isLoading: false } };
      case PRODUCTSIZE_UPDATE_REQUEST:
        return { ...state, productsizeUpdate: { productsize: {}, error: '', isLoading: true } };
      case PRODUCTSIZE_UPDATE_SUCCESS:
        return { ...state, productsizeUpdate: { productsize: state.productsizeUpdate.productsize, error: '', isLoading: false } };
      case PRODUCTSIZE_UPDATE_FAIL:
        return { ...state, productsizeUpdate: { productsize: {}, error: action.payload, isLoading: false } };
      case PRODUCTSIZE_DELETE_REQUEST:
        return { ...state, productsizeDelete: { productsize: {}, error: '', isLoading: true } };
      case PRODUCTSIZE_DELETE_SUCCESS:
        return { ...state, productsizeDelete: { productsize: action.payload, error: '', isLoading: false } };
      case PRODUCTSIZE_DELETE_FAIL:
        return { ...state, productsizeDelete: { productsize: {}, error: action.payload, isLoading: false } };
      case PRODUCTSIZES_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, productmasterFilter: { search: state.productmasterFilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, productmasterFilter: { select: state.productmasterFilter.select, search: action.value } };
        }
        return res;      
      default:
        return state;
    }
  }
  