import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Text, Button, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView } from 'react-native';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import { getAllDeals, getSBCPartnerCityDealAction, updateSBCPartnerCityDealAction } from '../../../state/actions/sbcpartnercityDealAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//StatusOption used to get the data from dropdown
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const EditSBCPartnercitydeal = (props: any) => {
    const esiColor = useSelector(state => state.theme);
    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    //toast for popup after successful API calls.
    const toast = useToast();
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    const EmailID = useSelector(state => state.auth?.user);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);

    //isSubmitted contains the data from Create API using state call
    const isSubmited = useSelector(state => state.sbcpartnercitydeal.sbcpartnercitydealUpdate.isLoading);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_DEAL_ID", SortOrder: "DESC" });

    //sbcpartnercitydeals and sbcpartnercitydealnames contains the data from API's using state call
    const [sbcpartnercitydeals, setSbcpartnercitydeals] = useState([]);
    const sbcpartnercitydealnames = useSelector(state => state.sbcpartnercitydeal?.sbcpartcitydeals.deals?.results);
    //partnercitydealedit contains the data from Get table
    const partnerid = props.route?.params?.partnerid;
    const partnercityid = props.route?.params?.partnercityid;
    const partnercitydealid = props.route?.params?.partnercitydealid;
    const partnercityname = props.route?.params?.partnercityname;
    const partnername = props.route?.params?.partnername;
    const cityid = props.route?.params?.cityid;

    //Dropdowns
    const [statusDropDown, setStatusDropDown] = useState("");
    const [dealsDropDown, setDealsDropDown] = useState("");
    const callBackGetData = (data) => {
        if (data) {
            setSbcpartnercitydeals(data);
        }

    };
    //dispatch is used to perform the API action
    //static formData
    useEffect(() => {
        let formData =
        {
            "City_Name": "",
            "Partner_Details_Id": partnerid,
            "search": "",
            "City_Id": "",
            "Deal_Id": "",
            "Partner_Name": "",
            "Partner_Service_City_Deal_Id": partnercitydealid,
            "Partner_Service_City_Id": partnercityid,
            "Records_Filter": "FILTER",
            "Status": ""
        };
        dispatch(getSBCPartnerCityDealAction(formData, pagination, callBackGetData));

        let formDatadeal =
        {
            Records_Filter: "FILTER",
            Partner_Details_Id: partnerid,
        };
        dispatch(getAllDeals(formDatadeal));
    }, []);

    //dealslist used to store the label,value in an array from API data  
    let dealslist = []
    if (sbcpartnercitydealnames) {
        if (sbcpartnercitydealnames.length >= 1) {
            for (let i = 0; i < sbcpartnercitydealnames.length; i++) {
                dealslist.push({
                    label: sbcpartnercitydealnames[i].Title,
                    value: sbcpartnercitydealnames[i].Deal_Id
                })
            }
        }
    }

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    /*====================================
            Handler
    ====================================*/
    const values = {
        Partner_Service_City_Deal_Id: sbcpartnercitydeals[0]?.Partner_Service_City_Deal_Id,
        Deal_Id: sbcpartnercitydeals[0]?.Deal_Id,
        Status: sbcpartnercitydeals[0]?.Status,
        Partner_Service_City_Id: sbcpartnercitydeals[0]?.Partner_Service_City_Id,
        City_Name: sbcpartnercitydeals[0]?.City_Name,
        Partner_Details_Id: sbcpartnercitydeals[0]?.Partner_Details_Id,
        Partner_Name: sbcpartnercitydeals[0]?.Partner_Name,
        Modified_By: EmailID.Email_Id
    }

    //Form validation
    const Form_Validation = Yup.object().shape({
        Status: Yup.string().required('Status is required.')
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);

        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(updateSBCPartnerCityDealAction(formValues, navigation, toast, partnercityid, partnerid, { partnercityname: sbcpartnercitydeals[0]?.City_Name }, { partnername: sbcpartnercitydeals[0].Partner_Name }, cityid));
        }
    }

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });


    return (
        <Surface style={{ flex: 1 ,backgroundColor: esiColor.BackgroundColor}}>
            <Header navigation={navigation} />
            <ScrollView>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 146, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('SBCPartnercitydealGet', { partnercityid: partnercityid, partnerid: partnerid, partnercityname: partnercityname, partnername: partnername, cityid: cityid })}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                               width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                               marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                               backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold" ,color: esiColor.brandFontColor}}>
                                        Edit Partner City Deal
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                            {/* Deal Title field */}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Deal_Id && props.errors.Deal_Id) && { color: '#b00020' }]}>
                                                Deal Title
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Deal_Id && props.errors.Deal_Id) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                iconColor='white'
                                                disable
                                                data={dealslist}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={dealslist.length > 1 ? 250 : 200}
                                                search={dealslist.length > 1 ? true : false}
                                                searchPlaceholder={'Select Deal Title'}
                                                dropdownPosition="auto"
                                                value={props.values.Deal_Id}
                                                name="Deal_Id"
                                                onFocus={() => setDealsDropDown(true)}
                                                onBlur={() => setDealsDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Deal_Id', item.value)
                                                    setDealsDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Deal_Id && props.errors.Deal_Id)}>
                                                <ErrorMessage name="Deal_Id" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                            {/* Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                                                Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                labelField="label"
                                                valueField="value"
                                                dropdownPosition="auto"
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ? true : false}
                                                searchPlaceholder={'Select Casback Lable'}
                                                value={props.values.Status}
                                                name="Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                                                <ErrorMessage name="Status" />
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default EditSBCPartnercitydeal;