import React, { useState } from "react";
import { Card, Surface, Text } from "react-native-paper";
import { Image, Platform, StyleSheet, TouchableOpacity, View, ScrollView, Dimensions } from "react-native";
import { useSelector } from "react-redux";

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const { height, width } = Dimensions.get('window');

export default function ImageCarouselforbidding(props: any) {
    // image is a Product images object as we use this object to display the info front-end
    const { image, dimensions } = props
    const esiColor = useSelector(state => state.theme);

    //For image scroll
    const [isImageViewVisible, setIsImageViewVisible] = useState(false);
    const [active, setactive] = useState(0);
    const removeTags = (str) => {
        if (!str || typeof str !== 'string') return '';
        return str.replace(/<[^>]*>/g, '');
    };
    const change = ({ nativeEvent }: { nativeEvent: any }) => {
        const slide = Math.ceil(nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width)
        if (slide !== active) {
            setactive(slide);
        }
    }

    const zoomImage = (image) => {
        const images = [

        ];

        image.map((item => {
            images.push(
                {
                    source: {
                        uri: item.imageuri,
                    },
                    width: 806,
                    height: height,
                }
            )
        }))
        return images
    }

    return (
        isImageViewVisible ?
            <ImageView
                images={zoomImage(image)}
                imageIndex={0}
                isVisible={isImageViewVisible}
                onClose={() => setIsImageViewVisible(false)}
            /> :
            <View>
                <View style={{ width: dimensions <= 500 ? 400 : 350, height: dimensions <= 500 ? 550 : 600, marginTop: 20, maxHeight: 400, maxWidth: 350, borderRadius: 10, shadowRadius: 5 }}>
                    <ScrollView
                        horizontal
                        onScroll={change}
                        showsHorizontalScrollIndicator={false}
                        style={{ width: 350, height: 400, width: dimensions <= 500 ? 400 : 350, height: dimensions <= 500 ? 400 : 350, maxHeight: 400, maxWidth: 350 }}>
                        {image.map((image: any, index: any) => (
                            <Image
                                key={index}
                                source={{ uri: image.imageuri }}
                                style={{ height: 400, width: 350, width: dimensions <= 500 ? 400 : 350, height: dimensions <= 500 ? 400 : 350, maxHeight: 400, maxWidth: 350, resizeMode: 'cover', borderRadius: 10, shadowRadius: 2 }}>
                            </Image>
                        ))
                        }
                    </ScrollView>
                    <View style={{ flexDirection: 'row', position: 'absolute', bottom: 0, alignSelf: 'center' }}>
                        {image.map((image: any, index: any) => (
                            <Text key={index} style={index == active ? styles.pagingActiveText : styles.pagingText}>⬤</Text>
                        )
                        )}
                    </View>

                </View>
                <View style={{ position: 'absolute', bottom: 0, alignSelf: 'center', marginBottom: -40 }}>
                    <Text style={{ color: esiColor.brandFontColor, fontSize: 12, fontWeight: 'bold' }}> product name: {removeTags(image[0].Product_Name)}</Text>
                    <Text style={{ color: esiColor.itemColor, fontSize: 12, marginTop: 5 }}>Description: {removeTags(image[0].Description)}</Text>
                </View>
            </View>
    )

}


const styles = StyleSheet.create({
    pagingText: { color: '#888', margin: 3 },
    pagingActiveText: { color: '#fff', margin: 3 },
})