import {
    HOWPARTNERASSOCWORKS_REQUEST,
    HOWPARTNERASSOCWORKS_SUCCESS,
    HOWPARTNERASSOCWORKS_FAIL,

    HOWPARTNERASSOCWORKS_GET_REQUEST,
    HOWPARTNERASSOCWORKS_GET_SUCCESS,
    HOWPARTNERASSOCWORKS_GET_FAIL,

} from '../actions/howpartnerassocworksAction'

const initialState = {

    howpartnerassocworks: { all: [], error: '', isLoading: false },
    howpartnerassocwork: { howpartnerassocwork: {}, error: '', isLoading: false },

};

export default function (state = initialState, action: any) {

    switch (action.type) {
        case HOWPARTNERASSOCWORKS_REQUEST:
            return { ...state, howpartnerassocworks: { all: state.howpartnerassocworks.all, error: '', isLoading: true } };
        case HOWPARTNERASSOCWORKS_SUCCESS:
            return { ...state, howpartnerassocworks: { all: action.payload, error: '', isLoading: false } };
        case HOWPARTNERASSOCWORKS_FAIL:
            return { ...state, howpartnerassocworks: { all: [], error: action.payload, isLoading: false } };

        case HOWPARTNERASSOCWORKS_GET_REQUEST:
            return { ...state, howpartnerassocwork: { howpartnerassocwork: {}, error: '', isLoading: true } };
        case HOWPARTNERASSOCWORKS_GET_SUCCESS:
            return { ...state, howpartnerassocwork: { howpartnerassocwork: action.payload, error: '', isLoading: false } };
        case HOWPARTNERASSOCWORKS_GET_FAIL:
            return { ...state, howpartnerassocwork: { howpartnerassocwork: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}