import axios from 'axios';
import config from './../config';
import shopperconfig from './shopperconfig';

const urlheaders = config.nodeheadersCommon;

export const ALL_SEARCH_REQUEST = "ALL_SEARCH_REQUEST";
export const ALL_SEARCH__GET = "ALL_SEARCH__GET";
export const ALL_SEARCH__FAIL = "ALL_SEARCH__FAIL";
export const SEARCH_ADD = "SEARCH_ADD";
export const SEARCH_REMOVE = "SEARCH_REMOVE";
/*=============================================================
                 Profile Action
===============================================================*/


export const search_products_new = (formData: any, responseData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: ALL_SEARCH_REQUEST,
        });
        let { data } = await axios.post(
            `${shopperconfig.nodePartnerUrl}/B2Bsearch/Get`,
            formData,
            {
                headers: urlheaders
            }
        );
        if (data) {
            responseData(data.results);
            dispatch({
                type: ALL_SEARCH__GET,
                payload: data.results,
            });
        }
    } catch (error) {
        responseData([]);
        dispatch({
            type: ALL_SEARCH__FAIL,
            payload: error
        });
    }
};
export const searchFilter = (value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: ALL_SEARCH_REQUEST,
            value: value,
        });
    }

}
