import 'react-native-gesture-handler';
import * as React from 'react';
import { View, StyleSheet, Platform, Picker, ScrollView, Dimensions, Text, Image, RefreshControl } from 'react-native';
import { Dialog, Card, DataTable, Button, Searchbar, Portal, Surface, IconButton, DefaultTheme } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../../auth/components/header';
import isEmpty from '../../../state/validations/is-empty';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import { getPartnerShopByCityLocationAction, shopbycitylocationFilter, resetFilter } from '../../../state/actions/partnershopbylocationAction';
import { deleteSBCPartnerCityProductAction } from '../../../state/actions/sbcpartnercityProductAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';


//filterOptions for filter dropdown
const filterOptions = [
    {
        value: 'All',
        label: 'All'
    },
    {
        value: 'Shop_By_Location_In_City_Id',
        label: 'Partner City Product ID'
    },
    {
        value: 'City_Id',
        label: 'City ID'
    },
    {
        value: 'Partner_Address_Id',
        label: 'Partner Address ID'
    }, {
        value: 'Partner_Detail_Id',
        label: 'Partner Detail ID'
    },
];

//passing static number of items for page list
const numberOfItemsPerPageList = [5, 10, 20];

function Partnershopbylocationget(props: any) {
    const esiColor = useSelector(state => state.theme);
    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    //sbcpartnercityproducts contains the data from GET API using state call
    const shopbycitylocation = useSelector(state => state.shopbycitylocation.shopbylocation.all);
    //shopbycitylocationMenu is used to store the cache data in Menu select using state call
    let shopbycitylocationMenu = useSelector(state => state.shopbycitylocation.shopbycitylocationFilter.select);
    //shopbycitylocationSearch is used to store the cache data in search using state call
    let shopbycitylocationSearch = useSelector(state => state.shopbycitylocation.shopbycitylocationFilter.search);
    //toast for popup after successful API calls.
    const toast = useToast();

    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);

    //filter dropdown
    const [showDropDown, setShowDropDown] = React.useState(false);

    //partnercity contains the data from main table(partner cities get table)
    const partnercityid = props.route?.params?.partnercityid;
    const partnerid = props.route?.params?.partnerid;
    const partnercityname = props.route?.params?.partnercityname;
    const partnername = props.route?.params?.partnername;
    const cityid = props.route?.params?.cityid;
    //API dispatch
    const dispatch = useDispatch()
    const [shopbycityproducts, setShopbycityproducts] = React.useState();
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
    //Static formData
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (isEmpty(shopbycitylocation || cityid)) {
            let formData =
            {
                "search_by_filter": "",
                "search": "",
                "Partner_Detail_Id": partnerid,
                "City_Id": cityid,
                "Shop_By_Location_In_City_Id": "",
                "Records_Filter": "FILTER"

            };
            setShopbycityproducts(cityid)
            dispatch(getPartnerShopByCityLocationAction(formData))
            dispatch(shopbycitylocationFilter('search', ''));
            dispatch(shopbycitylocationFilter('select', 'All'));
        }
    }, []);

    const handleClickproductadd = async () => {
        navigation.navigate('addpartnershopbylocation', { partnercityid: partnercityid, partnerid: partnerid, partnername: partnername, partnercityname: partnercityname, cityid: cityid })
    };

    //delete sbcpartnercityproduct
    const deleteSBCPartnercityproduct = (sbcpartnercityproduct: any) => {
        if (sbcpartnercityproduct) {
            const deleteData = {
                Partner_Details_Id: sbcpartnercityproduct.Partner_Details_Id,
                Partner_Service_City_Id: sbcpartnercityproduct.Shop_By_Location_In_City_Id,
                Shop_By_Location_In_City_Id: sbcpartnercityproduct.Shop_By_Location_In_City_Id,
            };
            dispatch(deleteSBCPartnerCityProductAction(deleteData, toast));
        }
    };

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });


    //sorting, pagination & search
    const [page, setPage] = React.useState(0);
    const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
    const from = page * numberOfItemsPerPage;
    const to = Math.min((page + 1) * numberOfItemsPerPage, shopbycitylocation?.length);

    const [oldSearch, setOldSearch] = React.useState(0);

    const checkShopbycitylocationForTable = () => {

        if (shopbycitylocationSearch) {
            try {
                if (shopbycitylocationMenu === "All") {
                    return shopbycitylocation?.filter(x => {
                        let keys = ["Shop_By_Location_In_City_Id", "City_Id", "Partner_Address_Id", "Partner_Detail_Id"];
                        for (let i = 0; i < keys.length; i++) {
                            try {
                                if (x[keys[i]]?.toLowerCase()?.includes(shopbycitylocationSearch?.toLowerCase())) {
                                    return true;
                                }
                            } catch (error) {
                                if (x[keys[i]]?.toString()?.toLowerCase()?.includes(shopbycitylocationSearch?.toLowerCase())) {
                                    return true;
                                }
                            }
                        }
                    });

                } else {
                    return shopbycitylocation?.filter(x => (x[shopbycitylocationMenu] ? x[shopbycitylocationMenu]?.toLowerCase() : '')?.includes(shopbycitylocationSearch?.toLowerCase()));
                }
            } finally {
                if (page > 0) {
                    if (oldSearch !== shopbycitylocationSearch.length) {
                        setOldSearch(shopbycitylocationSearch.length);
                        setPage(0);
                    }
                }
                else if (oldSearch !== shopbycitylocationSearch.length) {
                    setOldSearch(shopbycitylocationSearch.length);
                    setPage(0);
                }
            }
        }
        else {
            return shopbycitylocation
        }
    }

    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Shop_By_Location_In_City_Id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
            return -1;
        }
        if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {

            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);

    }

    const handleChange = itemValue => {
        dispatch(shopbycitylocationFilter('select', itemValue));
    };
    const handleSearch = value => {
        dispatch(shopbycitylocationFilter('search', value));
    };

    const data = stableSort(
        checkShopbycitylocationForTable(), getComparator(order, orderBy))

    // View Dialog
    const [visible, setVisible] = React.useState(false);
    const [shopbycitylocationData, setData] = React.useState();

    const hideDialog = () => setVisible(false);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text
        },
    };

    const style = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 170
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14,
            color: esiColor.Text,
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            margin: 15,
            flex: 1,
            justifyContent: 'space-between',
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
            borderBottomColor: esiColor.SBorderColor,
        },
        dropdown: {
            height: 50,
            borderColor: esiColor.SBorderColor,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
        },
        icon: {
            marginRight: 5,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            width: 150
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? "60%" : "90%", maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.TextOuterLine, borderWidth: 1 }}>
                    <View style={{ alignItems: "flex-end", paddingTop: 10 }}>
                        <IconButton icon="close" color={esiColor.GBFColor} size={20} onPress={() => setVisible(!visible)} />
                    </View>
                    <Dialog.Title style={{ fontSize: 18, marginTop: -35, textAlign: "center" }}>
                        <Text style={{ color: esiColor.brandFontColor, textAlign: 'center', fontSize: 20 }}>View Partner City Product</Text>
                    </Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{ paddingHorizontal: 7 }}>
                            <Dialog.Content style={{ marginLeft: -30 }}>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Service City Product ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{shopbycitylocationData?.Shop_By_Location_In_City_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Service City ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{shopbycitylocationData?.Shop_By_Location_In_City_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Details ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{shopbycitylocationData?.Partner_Details_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Partner Name</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{shopbycitylocationData?.Partner_Name} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>City Name</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{shopbycitylocationData?.City_Name} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product ID</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.GBFColor }}>{shopbycitylocationData?.Product_Id} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Product Name</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{shopbycitylocationData?.Product_Name} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Status</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{shopbycitylocationData?.Status} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created By</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{shopbycitylocationData?.Created_By} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Created Date</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{shopbycitylocationData?.Created_Date} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Modified By</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{shopbycitylocationData?.Modified_By} </Text></View>
                                </View>
                                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                                    <View style={{ flex: 2.0 }}><Text style={{ fontWeight: "bold", fontSize: 13, color: esiColor.Text }}>Modified Date</Text></View>
                                    <View style={{ flex: 2.5 }}><Text style={{ color: esiColor.itemColor }}>{shopbycitylocationData?.Modified_Date} </Text></View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                </Dialog>
            </Portal>
            <View >
                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 146, justifyContent: 'flex-start' }} onPress={() => {
                    navigation.navigate('SBCPartnercityGet')
                    handleSearch("")
                    dispatch(resetFilter())
                }}>
                    Go Back
                </Button>
            </View>
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <View style={{ flexDirection: "column" }}>
                    <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10, paddingBottom: 10, color: esiColor.brandFontColor }}>{partnercityname} Locations</Text>
                </View>
                {/* {dimensions <= 700 ? */}
                {/* <View > */}
                {(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write") &&
                    <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
                        <Button textColor={esiColor.itemButtenColor} style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={() => { handleClickproductadd() }}>
                            ADD SHOP BY CITY LOCATION
                        </Button>
                    </View>}
                <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
                    {Platform.OS !== 'web' ?
                        <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
                            <EsiSearchDropdown
                                style={[style.dropdown]}
                                placeholderStyle={style.placeholderStyle}
                                selectedTextStyle={style.selectedTextStyle}
                                inputSearchStyle={style.inputSearchStyle}
                                iconStyle={style.iconStyle}
                                data={filterOptions}
                                containerStyle={style.containerstyle}
                                maxHeight={180}
                                labelField="label"
                                valueField="value"
                                placeholder={!showDropDown ? 'All' : ''}
                                value={shopbycitylocationMenu}
                                onFocus={() => setShowDropDown(true)}
                                onBlur={() => setShowDropDown(false)}
                                onUpdateValue={item => {
                                    setShowDropDown(false);
                                    handleChange(item.value)
                                }}
                            />
                        </View>
                        :
                        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
                            <Picker
                                selectedValue={shopbycitylocationMenu}
                                style={{ height: 50, width: dimensions <= 500 ? 130 : 120, marginLeft: 15, borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor, flexShrink: 1, backgroundColor: esiColor.BackgroundColor, color: esiColor.Text }}
                                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                            >
                                <Picker.Item label="All" value="All" />
                                <Picker.Item label="Partner City Product ID" value="Shop_By_Location_In_City_Id" />
                                <Picker.Item label="City ID" value="City_Id" />
                                <Picker.Item label="Partner Address ID" value="Partner_Address_Id" />
                                <Picker.Item label="Partner Detail ID" value="Partner_Detail_Id" />
                            </Picker>
                        </View>}

                    <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

                    <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
                        <Searchbar
                            style={{
                                borderRadius: 5, borderWidth: 1, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.BackgroundColor
                            }}
                            iconColor={esiColor.SIconColor}
                            theme={esiColor.pagination_theme}
                            placeholderTextColor={esiColor.SPHTextColor}
                            placeholder="Search"
                            onChangeText={(value) => handleSearch(value)}
                            value={shopbycitylocationSearch}
                        />
                    </View>
                    <ScrollView horizontal={dimensions >= 700 ? false : true}>
                        <View style={style.mainbox}>
                            <Card>
                                <DataTable style={{ borderWidth: 1, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor }}>
                                    <DataTable.Header style={style.databeHeader}>
                                        <DataTable.Title style={style.title} active={orderBy === 'Shop_By_Location_In_City_Id'}
                                            sortDirection={orderBy === 'Shop_By_Location_In_City_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Shop_By_Location_In_City_Id')}><Text style={style.titletext}>Shop By Location In City Id</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'City_Id'}
                                            sortDirection={orderBy === 'City_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('City_Id')}><Text style={style.titletext}>City Id</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Address_Id'}
                                            sortDirection={orderBy === 'Partner_Address_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Address_Id')}><Text style={style.titletext}>Partner Address Id</Text></DataTable.Title>

                                        <DataTable.Title style={style.title} active={orderBy === 'Partner_Detail_Id'}
                                            sortDirection={orderBy === 'Partner_Detail_Id' ? order : 'ascending'}
                                            onPress={createSortHandler('Partner_Detail_Id')}><Text style={style.titletext}>Partner Detail Id</Text></DataTable.Title>

                                        <DataTable.Title style={style.title}><Text style={style.titletext}>Actions</Text></DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        data?.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((sbcpartnercityproduct, index) => (
                                            <DataTable.Row style={style.databeBox} key={sbcpartnercityproduct.Shop_By_Location_In_City_Id}>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityproduct.Shop_By_Location_In_City_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityproduct.City_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityproduct.Partner_Address_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}><Text style={{ color: esiColor.itemColor }}>{sbcpartnercityproduct.Partner_Detail_Id}</Text></DataTable.Cell>
                                                <DataTable.Cell style={style.tablerow}>
                                                    {(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write") && <IconButton icon="square-edit-outline" size={20} iconColor={esiColor.SIconColor} onPress={() => navigation.navigate('editpartnershopbylocation', { partnerid: sbcpartnercityproduct.Partner_Detail_Id, partnercityid: sbcpartnercityproduct.Partner_Service_City_Id, partnercitylocationid: sbcpartnercityproduct.Shop_By_Location_In_City_Id, cityid: sbcpartnercityproduct.City_Id })} />
                                                    }
                                                    <IconButton icon="eye" size={20} iconColor={esiColor.SIconColor} onPress={(e) => { setData(sbcpartnercityproduct); setVisible(!visible) }} />
                                                    {(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write") && <IconButton icon="delete" size={20} iconColor={esiColor.SIconColor} onPress={(e) => deleteSBCPartnercityproduct(sbcpartnercityproduct)} />
                                                    }
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                        ))
                                    }
                                    {isEmpty(data) &&
                                        <View>
                                            <Text style={{ fontSize: 16, justifyContent: 'center', textAlign: "center", paddingTop: 20, color: esiColor.NoDataFound }}>
                                                No records found!
                                            </Text>
                                            <Image source={require('../../../assets/images/nodata.gif')}
                                                style={{ width: 300, height: 300, alignSelf: "center" }} />
                                        </View>
                                    }
                                    <DataTable.Pagination
                                        // theme={customTheme}
                                        page={page}
                                        numberOfPages={Math.ceil(shopbycitylocation?.length / numberOfItemsPerPage)}
                                        onPageChange={page => setPage(to < data?.length ? page : 0)}
                                        label={shopbycitylocation.length ? `${from + 1}-${to} of ${data?.length}` : '0-0'}
                                        showFastPaginationControls={to > data?.length ? false : true}
                                        numberOfItemsPerPageList={numberOfItemsPerPageList}
                                        numberOfItemsPerPage={numberOfItemsPerPage}
                                        onItemsPerPageChange={onItemsPerPageChange}
                                        selectPageDropdownLabel={'Rows per page'}
                                    />
                                </DataTable>
                            </Card>
                        </View>
                    </ScrollView>
                </View>
            </ScrollView>
        </Surface>
    );
}

export default Partnershopbylocationget;