import axios from 'axios';
import config from './../config';

export const SHOPPERS_INVENTORIES_REQUEST = "SHOPPERS_INVENTORIES_REQUEST";
export const SHOPPERS_INVENTORIES_SUCCESS = "SHOPPERS_INVENTORIES_SUCCESS";
export const SHOPPERS_INVENTORIES_FAIL = "SHOPPERS_INVENTORIES_FAIL";
export const SHOPPERS_INVENTORIES_SIZES_REQUEST = "SHOPPERS_INVENTORIES_SIZES_REQUEST";
export const SHOPPERS_INVENTORIES_SIZES_SUCCESS = "SHOPPERS_INVENTORIES_SIZES_SUCCESS";
export const SHOPPERS_INVENTORIES_SIZES_FAIL = "SHOPPERS_INVENTORIES_SIZES_FAIL";
export const SHOPPERS_INVENTORY_GET_REQUEST = "SHOPPERS_INVENTORY_GET_REQUEST";
export const SHOPPERS_INVENTORY_GET_SUCCESS = "SHOPPERS_INVENTORY_GET_SUCCESS";
export const SHOPPERS_INVENTORY_GET_FAIL = "SHOPPERS_INVENTORY_GET_FAIL";
export const SHOPPERS_INVENTORY_CREATE_REQUEST = "SHOPPERS_INVENTORY_CREATE_REQUEST";
export const SHOPPERS_INVENTORY_CREATE_SUCCESS = "SHOPPERS_INVENTORY_CREATE_SUCCESS";
export const SHOPPERS_INVENTORY_CREATE_FAIL = "SHOPPERS_INVENTORY_CREATE_FAIL";
export const SHOPPERS_INVENTORY_UPDATE_REQUEST = "SHOPPERS_INVENTORY_UPDATE_REQUEST";
export const SHOPPERS_INVENTORY_UPDATE_SUCCESS = "SHOPPERS_INVENTORY_UPDATE_SUCCESS";
export const SHOPPERS_INVENTORY_UPDATE_FAIL = "SHOPPERS_INVENTORY_UPDATE_FAIL";

export const SHOPPERS_INVENTORY_PRODUCTS_REQUEST = "SHOPPERS_INVENTORY_PRODUCTS_REQUEST";
export const SHOPPERS_INVENTORY_PRODUCTS_SUCCESS = "SHOPPERS_INVENTORY_PRODUCTS_SUCCESS";
export const SHOPPERS_INVENTORY_PRODUCTS_FAIL = "SHOPPERS_INVENTORY_PRODUCTS_FAIL";

export const SHOPPERS_INVENTORY_PARTNERS_REQUEST = "SHOPPERS_INVENTORY_PARTNERS_REQUEST";
export const SHOPPERS_INVENTORY_PARTNERS_SUCCESS = "SHOPPERS_INVENTORY_PARTNERS_SUCCESS";
export const SHOPPERS_INVENTORY_PARTNERS_FAIL = "SHOPPERS_INVENTORY_PARTNERS_FAIL";

export const SHOPPERS_INVENTORY_DETAILS_REQUEST = "SHOPPERS_INVENTORY_DETAILS_REQUEST";
export const SHOPPERS_INVENTORY_DETAILS_SUCCESS = "SHOPPERS_INVENTORY_DETAILS_SUCCESS";
export const SHOPPERS_INVENTORY_DETAILS_FAIL = "SHOPPERS_INVENTORY_DETAILS_FAIL";

export const SHOPPERS_INVENTORY_PRODUCT_MASTER_REQUEST = "SHOPPERS_INVENTORY_PRODUCT_MASTER_REQUEST";
export const SHOPPERS_INVENTORY_PRODUCT_MASTER_SUCCESS = "SHOPPERS_INVENTORY_PRODUCT_MASTER_SUCCESS";
export const SHOPPERS_INVENTORY_PRODUCT_MASTER_FAIL = "SHOPPERS_INVENTORY_PRODUCT_MASTER_FAIL";

export const SHOPPERS_PRODUCTSIZE_INVENTORY_REQUEST = "SHOPPERS_PRODUCTSIZE_INVENTORY_REQUEST";
export const SHOPPERS_PRODUCTSIZE_INVENTORY_SUCCESS = "SHOPPERS_PRODUCTSIZE_INVENTORY_SUCCESS";
export const SHOPPERS_PRODUCTSIZE_INVENTORY_FAIL = "SHOPPERS_PRODUCTSIZE_INVENTORY_FAIL";

export const SHOPPERS_INVENTORIES_FILTER = "SHOPPERS_INVENTORIES_FILTER";

/*=============================================================
                  Add Inventory Action
===============================================================*/

const headers = config.headersCommon;

export const createshoppersInventoryAction = (formData:any, navigation: any, setErrorMessage:any,setErrors:any,toast:any) => async (dispatch:any) => {
  dispatch({
      type: SHOPPERS_INVENTORY_CREATE_REQUEST
    });
    axios.post(`${config.url}/Inventory_Discounted_Products/Create`,
     formData, {
      headers:headers
    })
      .then(response => {
        
        let successResponse = response.data;
        if (successResponse.Response_Status === "Success") {
          dispatch({
            type: SHOPPERS_INVENTORY_CREATE_SUCCESS
          });
          toast.show({
            message: 'Shoppers inventory Added successfully.', type: 'info', duration: 3000, position: 'top'
          });
          let newformData = {
            "Partner_Details_Id":formData?.Partner_Details_Id,
            "Records_Filter": "FILTER"
          };
          dispatch(getAllshoppersInventoryAction(newformData, toast))
          navigation.navigate('ShoppersInventoryGet');
        }  else { 
          const errors = {};
        if (successResponse.Product_Id) {
          errors.Product_Id = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Product_Id: { key: successResponse.Product_Id ? successResponse.Product_Id : '', message: successResponse.Product_Id ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Product_Id) ? successResponse.UI_Display_Message : '' } });
          dispatch({
            type: SHOPPERS_INVENTORY_CREATE_FAIL,
            payload: successResponse.UI_Display_Message
          });
        }
      })
      .catch(error => { 
        dispatch({
          type: SHOPPERS_INVENTORY_CREATE_FAIL,
          payload: "Currently server is not working. Please try again later."
        });
      })
  };

  /*=============================================================
                  Update Inventory Action
===============================================================*/

export const updateshoppersInventoryAction = (formData:any, navigation:any, setErrorMessage:any,setErrors:any,toast:any) => async (dispatch:any) => {
    dispatch({
      type: SHOPPERS_INVENTORY_UPDATE_REQUEST
    });
    axios.put(`${config.url}/Inventory_Discounted_Products/Update`, formData, {
      headers:headers
    })
      .then(response => {
        let successResponse = response.data;
        if (successResponse.Response_Status == "Success") {
          dispatch({
            type: SHOPPERS_INVENTORY_UPDATE_SUCCESS
          });
          toast.show({
            message: 'Shoppers inventory updated successfully.', type: 'info', duration: 3000, position: 'top'
          });
          let newformData = {
            "Partner_Details_Id":formData?.Partner_Details_Id,
            "Records_Filter": "FILTER"
          };
          dispatch(getAllshoppersInventoryAction(newformData, toast))
          navigation.navigate('ShoppersInventoryGet');
        } else { 
          const errors = {};
          if (successResponse.Product_Id) {
            errors.Product_Id = successResponse.UI_Display_Message;
          }
          setErrors(errors)
          setErrorMessage({ Product_Id: { key: successResponse.Product_Id ? successResponse.Product_Id : '', message: successResponse.Product_Id ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Product_Id) ? successResponse.UI_Display_Message : '' } });
          dispatch({
            type: SHOPPERS_INVENTORY_UPDATE_FAIL,
            payload: successResponse.UI_Display_Message
          });
        }
      })
      .catch(error => {
        dispatch({         
          type: SHOPPERS_INVENTORY_CREATE_FAIL,
          payload: "Currently server is not working. Please try again later."
        });
      })
  };
  
  /*=============================================================
                  Get All Inventory Action
===============================================================*/

export const getAllshoppersInventoryAction = (formData:any,toast:any )=> async (dispatch:any) => {
    try {
      dispatch({
        type: SHOPPERS_INVENTORIES_REQUEST
      });
      let { data, status } = await axios.post(
        `${config.url}/Inventory_Discounted_Products/Get?PageNo=0&PageSize=500&SortBy=AVAILABILITY_STATUS&SortOrder=ASC`,
        formData,
        {
          headers:headers
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {   
          dispatch({
            type: SHOPPERS_INVENTORIES_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: SHOPPERS_INVENTORIES_SUCCESS,
            payload: data.Inventory_For_Discounted_Products
          });
        }
      }
    } catch (err) {
      dispatch({
        type: SHOPPERS_INVENTORIES_FAIL,
        payload: err
      });
    }
  }; 
  export const getAllProductSizes = (Product_Master_id: any, EmailID: any) => async (dispatch:any) => {
   try {
    let formData = {
      Product_Master_Id: Product_Master_id,
      Partner_Details_Id: EmailID?.Partner_Details_Id
    };
      dispatch({
        type: SHOPPERS_INVENTORIES_SIZES_REQUEST
      });
      let { data, status } = await axios.post(
        `${config.url}/Partner_Product/Size_Get`,
        formData,
        {
          // Axios looks for the `auth` option, and, if it is set, formats a
          // basic auth header for you automatically.
          headers: headers
        }
      );
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: SHOPPERS_INVENTORIES_SIZES_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: SHOPPERS_INVENTORIES_SIZES_SUCCESS,
            payload: data.ProductSize
          });
        }
      }
    } catch (err) {
      dispatch({
        type: SHOPPERS_INVENTORIES_SIZES_FAIL,
        payload: err
      });
    }
  };
export const shoppersinventoryFilter = (fType:any, value:any) => {
    return function (dispatch:any) {
      dispatch({
        type: SHOPPERS_INVENTORIES_FILTER,
        fType: fType,
        value: value,
      });
    }
  }
   /*=============================================================
                  Get all products
===============================================================*/

  export const getAllInventoryProducts = () => async (dispatch:any) => {
    try {
      let formData = {
        Records_Filter: "ALL",      
        Partner_Details_Id:""  ,
        Product_Id:""
      };
      dispatch({
        type: SHOPPERS_INVENTORY_PRODUCTS_REQUEST
      });
      let { data, status } = await axios.post(
        `${config.url}/Products/Get_Admin`,
        formData,
        {
          // Axios looks for the `auth` option, and, if it is set, formats a
          // basic auth header for you automatically.
          headers:headers
        }
      );
      if (data) {
        if (data.Success_Response.Is_Data_Exist === '0') {
          dispatch({
            type: SHOPPERS_INVENTORY_PRODUCTS_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: SHOPPERS_INVENTORY_PRODUCTS_SUCCESS,
            payload: data.Product
          });
        }
      }
    } catch (err) {
      dispatch({
        type: SHOPPERS_INVENTORY_PRODUCTS_FAIL,
        payload: err
      });
    }
  };
  /*=============================================================
                 Get all partners
===============================================================*/
export const getAllInventoryPartners = (EmailID:any) => async (dispatch:any) => {
  try {
    let formData = {
      Partner_Address_Id: "",
      Partner_Product_Id: "",
      Partner_Type: " ",
      Partner_Details_Id:EmailID?.Partner_Details_Id,
      Product_Id: " ",
      Records_Filter: "FILTER",
      Status:"Active"
    };
    dispatch({
      type: SHOPPERS_INVENTORY_PARTNERS_REQUEST
    });
    let { data, status } = await axios.post(
      `${config.url}/Partner_Product/Physical_Partner_Product_GET?PageNo=0&PageSize=1000&SortBy=PRODUCT_MASTER_ID&SortOrder=ASC`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers:headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SHOPPERS_INVENTORY_PARTNERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SHOPPERS_INVENTORY_PARTNERS_SUCCESS,
          payload: data.Partner_Product
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SHOPPERS_INVENTORY_PARTNERS_FAIL,
      payload: err
    });
  }
};

  /*=============================================================
                 Get all details
===============================================================*/
export const getAllDetails = () => async (dispatch:any) => {
  try {
    let formData = {
      Records_Filter: "ALL",
      Partner_Details_Id: "",
      Name: "",
      Top_Stores: "",
      ExtraCashBack: ""
    };
    dispatch({
      type: SHOPPERS_INVENTORY_DETAILS_REQUEST
    });
    let { data, status } = await axios.post(
      `${config.url}/Partners/OnlineAndPhysical_Details_Get_Admin`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: headers
      }
    );
    if (data) {
      if (data.Success_Response) {
        dispatch({
          type: SHOPPERS_INVENTORY_DETAILS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SHOPPERS_INVENTORY_DETAILS_SUCCESS,
          payload: data.Partner_Details
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SHOPPERS_INVENTORY_DETAILS_FAIL,
      payload: err
    });
  }
};
  
 /*=============================================================
                Get All Master Id
===============================================================*/
 
  
export const getAllInventoryMasters = () => async (dispatch:any) => {
  try {
    let formData = {
      Active: "",
      Brand: " ",
      Category: " ",
      Msrp: " ",
      Product_Master_Id: " ",
      Product_Name: " ",
      Records_Filter: " ",
      Sku: " ",
      Stock_Status: " ",
      Upc_Code: " "
    };
    dispatch({
      type: SHOPPERS_INVENTORY_PRODUCT_MASTER_REQUEST
    });
    let { data, status } = await axios.post(
      `${config.url}/Products/Product_Master_Get_Admin?PageNo=0&PageSize=1000&SortBy=PRODUCT_MASTER_ID&SortOrder=ASC`,
      formData,
      {
        // Axios looks for the `auth` option, and, if it is set, formats a
        // basic auth header for you automatically.
        headers: headers
      }
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SHOPPERS_INVENTORY_PRODUCT_MASTER_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: SHOPPERS_INVENTORY_PRODUCT_MASTER_SUCCESS,
          payload: data.Product_Master
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SHOPPERS_INVENTORY_PRODUCT_MASTER_FAIL,
      payload: err
    });
  }
};