import React, { useState } from 'react';
import { Card, Button,   HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  EditrelatedproductAction, getAllProductssearch,
} from '../../../state/actions/relatedproductsAction';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import AsyncStorage from '@react-native-async-storage/async-storage';
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const EditRelatedProduct = (props: any) => {
  const { navigation } = props;
  const toast = useToast();
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const EmailID = useSelector(state => state.auth?.user);
  const esiColor = useSelector(state => state.theme);
  React.useEffect(() => {
        const signNavigation = async () => {
            const user=await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if ( !user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
  React.useEffect(() => {
    if (!(permissions.Products_Partner_Products_Related_Products === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
  }, [permissions]);
  const dispatch = useDispatch();

  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  React.useEffect(() => {
    dispatch(getAllProductssearch("1"))
}, []);
const updateStates = (data) => {
  if (data) {
      dispatch(getAllProductssearch(data))
  }

};
  const [showDropDownRelatedProducts, setShowDropDownRelatedProducts] = React.useState('');
  const allProducts = useSelector(state => state.relatedproducts.relatedproductssearch.all);
  const relatedproductId = props.route?.params?.relatedproductId;
  const relatedofproductId = props.route?.params?.relatedofproductId;
  const productid = props.route?.params?.productid;
  let productopt = []
  if (allProducts) {
    if (allProducts[0]) {
      for (
        let i = 0;
        i < allProducts.length;
        i++) {
        productopt.push({
          label: allProducts[i].Product_Name,
          value: allProducts[i].Product_Id
        })
      }
    }
  }

  //form reset
  const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);
  /*====================================
           Handler
   ====================================*/
  const values = {
    Related_Product_Of_Product_Id: relatedofproductId,
    Modified_By: EmailID.Email_Id,
    Product_Id: productid,
    Related_Product_Id: relatedproductId,
  }

  const Form_Validation = Yup.object().shape({
    Related_Product_Of_Product_Id: Yup.string().required('Related Product is required.'),
  })
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    setErrors(errors);
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      formValues.Created_By = EmailID.Email_Id;
      dispatch(EditrelatedproductAction(formValues, productid, props.navigation, toast));
    }
  }

  const styles = StyleSheet.create({
    container: {
      paddingTop: 15
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
  });

  return (
    <Surface style={{
      flex: 1,backgroundColor: esiColor.BackgroundColor
    }}>
      <Header navigation={navigation} />
        <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
          {(props) => (
            <Card style={{ width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
            marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
            backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5 }}>
              <Card.Content>
                <View >
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      props.resetForm();
                      navigation.navigate('RelatedProducts',{relatedproductparams:productid})
                    }}>
                    Go Back
                  </Button>
                </View>
                <View>
                  <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                    <View style={styles.container}>
                      <Text style={[styles.dropdownlabel, Boolean(props.touched.Related_Product_Of_Product_Id && props.errors.Related_Product_Of_Product_Id) && { color: '#b00020' }]}>
                        Related Product
                      </Text>
                      <EsiSearchDropdown
                        style={[styles.dropdown, Boolean(props.touched.Related_Product_Of_Product_Id && props.errors.Related_Product_Of_Product_Id) && { borderColor: '#b00020' }]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={productopt}
                        labelField="label"
                        valueField="value"
                        searchQuery={item => { updateStates(item) }}
                        maxHeight={productopt.length > 1 ? 250 : 200}
                        search={productopt.length > 1 ?true:false}
                        searchPlaceholder={'Select Related Product'}
                        value={props.values.Related_Product_Of_Product_Id}
                        onUpdateValue={item => {
                          props.setFieldValue('Related_Product_Of_Product_Id', item.value)
                          setShowDropDownRelatedProducts(false);
                        }}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Related_Product_Of_Product_Id && props.errors.Related_Product_Of_Product_Id)}>
                        {props.touched.Related_Product_Of_Product_Id && props.errors.Related_Product_Of_Product_Id}
                      </HelperText>
                    </View>

                    <Button textColor={esiColor.itemButtenColor} style={{ width: 130, marginTop: "3%", marginLeft: "auto", marginRight: "auto", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                      Save
                    </Button>
                  </ScrollView>
                </View>
              </Card.Content>
            </Card>
          )}
        </Formik>
    </Surface>
  );
};


export default EditRelatedProduct;