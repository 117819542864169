import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Surface, Text, Colors, Button, TouchableRipple, Avatar, IconButton } from 'react-native-paper';
import { View, Linking, TouchableOpacity, Platform, Image, RefreshControl, ScrollView, FlatList } from 'react-native';
import Header from "../header/header";
import EsiPartnerCard from "../../../components/custom/partner-card";
import { Dimensions } from 'react-native';
import { getPartnersAction } from "../../../state/actions/node-actions/home-actions";
import Modal from "react-native-modal";
import isEmpty from "../../../state/validations/is-empty";
import { EvilIcons } from "@expo/vector-icons";
import Pagination from "../../../components/custom/pagination/pagination";
import { useToast } from "../../../components/custom/react-native-paper-toast/src";
import PartnerLocation from "../../../components/custom/location/partner-location";
import SignIN from "../../auth/signin";

export default function AllPartners(props: any) {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const [isModalVisible, setModalVisible] = React.useState(false);
    const toast = useToast();
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    const user = useSelector(state => state.auth?.user?.Partner_Details_Id);
    const dispatch = useDispatch();
    const { navigation, ...rest } = props;
    // dimensions are used for the width and height of card 
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [imageURL, setImageURL] = useState(false);
    const esiColor = useSelector(state => state.theme);

    const EmailID = useSelector(state => state.auth.user)
    const data = props?.route?.params;
    const [partners, setpartners] = useState([]);
    const [filters, setFilters] = React.useState(
        {}
    );

    useEffect(() => {
        if (data) {
            setFilters(
                {
                    "Logged_In_Partner_Details_Id": user,
                    "Email_Id": "",
                    "Partner_Details_Id": data.Partner_Detail_Id,
                    "Records_Filter": "FILTER",
                    "Status": ""

                }
            );
        } else {
            setFilters({
                "Email_Id": "",
                "Partner_Details_Id": EmailID.Partner_Details_Id,
                "Records_Filter": "",
                "Status": ""
            });
        }
    }, [data])
    const [pageOld, setPageOld] = React.useState(false);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "PD.PARTNER_DETAILS_ID", SortOrder: "DESC" });
    const [searchBarOpen, setSearchBarOpen] = React.useState(false);
    const callBackGetData = async (status, data, pagination) => {

        setIsLoading(false);
        if (status) {
            setpartners(data);
        }
        if (pagination) {
            setPageOld(true);
            setPagination(pagination);
        } else {
            setPageOld(true);
            setPagination({ PageNo: 1, PageSize: 30, SortBy: "PD.PARTNER_DETAILS_ID", SortOrder: "DESC" });
        }
    }

    useEffect(() => {
        if (pageOld) {
            setPageOld(false);
        } else {
            if (!isEmpty(filters)) {
                goToTop();
                setIsLoading(true);
                dispatch(getPartnersAction(filters,
                    pagination,
                    callBackGetData
                ));
            }
        }
    }, [pagination])
    const [testFilters, setTestFilters] = useState({});
    useEffect(() => {
        if ((JSON.stringify(testFilters) != JSON.stringify(filters)) && !isEmpty(filters)) {
            setTestFilters(filters);
            partnersCall();
        }
    }, [filters]);

    useEffect(() => {
        // dispatch(getAllStoreTypesAction(callbackStoreTypes));
    }, []);

    const [storeTypes, setStoreTypes] = React.useState([]);

    const callbackStoreTypes = (status, data) => {
        if (status) {
            setStoreTypes(data);
        }
    }

    const [loading, setIsLoading] = React.useState(false);
    const partnersCall = () => {
        goToTop();
        setIsLoading(true);
        dispatch(getPartnersAction(filters,
            pagination,
            callBackGetData
        ));
    };
    // Authentication
    const onOpenModal = (item) => {
        let Url = item.Web_Redirection_Url;
        setTimeout(function () {
            Linking.openURL(Url);
            setRedirectionOpen(false);
        }, 2000);
    };
    const [openredirect, setRedirectionOpen] = React.useState(false);
    const toggleModal = (item) => {
        if (isAuthenticated) {
            if (item.Type === "Online") {
                setRedirectionOpen(true)
                onOpenModal(item);
                setImageURL(item.Logo_Path)
            }
        }
        else {
            setModalVisible(true);
        }
    }
    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };
    const scroll = useRef(null);
    const goToTop = () => {
        if (scroll)
            scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
    }
    const [isLocationVisible, setIsLocationVisible] = useState(false);

    return (
        <Surface theme={{
            colors: {
                surface: esiColor.BackgroundColor,
            }
        }} style={{ flex: 1 }}>
            <Header {...props} />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 4, paddingHorizontal: 5 }} >
                </View>
                <View style={{ flex: 3 }} >
                    <TouchableOpacity style={{ marginRight: -5, flexDirection: "row", justifyContent: "flex-end" }}
                        onPress={() => {

                            if (data?.top || data?.top == false || data?.top == true) {
                                navigation.setParams({
                                    top: (data?.top == false || data?.top == "false") ? true : false,
                                });
                            } else {
                                setPageOld(true);
                                setPagination({ PageNo: 1, PageSize: 30, SortBy: "PD.PARTNER_DETAILS_ID", SortOrder: "DESC" });
                                setFilters({
                                    ...filters,
                                    Top_Stores: filters?.Top_Stores == "" ? 1 : ""
                                });
                            }
                        }}
                    >
                    </TouchableOpacity>
                </View>
            </View>
            <View style={{ flexDirection: "row", marginBottom: 2 }}>
                <View style={{ flex: 5, flexDirection: "row" }} >
                    {filters?.Type == "Physical" && <>
                        <TouchableOpacity style={{ flexDirection: "row" }} onPress={() => { setIsLocationVisible(true) }}>
                            <EvilIcons
                                name="location"
                                size={20}
                                style={{ marginLeft: 5 }}

                            />
                            <Text style={{
                                marginTop: Platform.OS == "web" ? 3 : 0,
                                fontWeight: 'bold',
                                color: "#27b6cc"
                            }}> {(filters?.Zipcode || filters?.City_ID) ? filters?.ZipcodeLocation || filters?.City_Info : "Select Location"}</Text>

                        </TouchableOpacity>
                        {(filters?.Zipcode || filters?.City_ID) ?
                            <IconButton size={16} style={{ marginTop: 1 }} color="#27b6cc" icon={"close"}
                                onPress={() => {
                                    setFilters({ ...filters, Zipcode: "", ZipcodeLocation: "", City_ID: "", City_Info: "" });
                                }}
                            /> : <></>
                        }
                    </>
                    }


                </View>
                <View style={{ flex: 5 }}>
                </View>
            </View>
            <ScrollView ref={scroll} style={{ flexGrow: 1, flex: 12 }}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={partnersCall}
                    />
                }
            >
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', margin: 'auto' }}>
                    {isEmpty(partners) ? (
                        !loading &&
                        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                            <Text  style={{color: esiColor.NoDataFound}}>No partners found!</Text>
                        </View>
                    ) : (<FlatList
                        data={partners}
                        renderItem={({ item, index, separators }) => (
                            <EsiPartnerCard item={item} isLocation={(filters?.Zipcode || filters?.City_ID) ? true : false} toggleModal={toggleModal} signInModalResponse={signInModalResponse} isAuthenticated={isAuthenticated} isModalVisible={isModalVisible} setModalVisible={setModalVisible} {...props} />
                        )}
                    />
                    )
                    }
                </View>
                <View >
                    <Pagination pagination={pagination} setPagination={setPagination} />
                </View>
            </ScrollView>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isLocationVisible}>
                <View style={{ flex: 1, maxWidth: dimensions.width - 10, minWidth: dimensions.width - 20, minHeight: 500, maxHeight: dimensions.height - 100, borderRadius: 10, backgroundColor: "#ffffff" }}>
                    <PartnerLocation isLocationVisible={isLocationVisible} setIsLocationVisible={setIsLocationVisible} filters={filters} setFilters={setFilters} />
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: "#ffffff" }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={openredirect}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 150, borderRadius: 10, backgroundColor: "#ffffff" }}>
                    <View style={{ alignItems: 'flex-end', marginTop: '-6%' }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setRedirectionOpen(false) }} >
                            <Avatar.Icon color={'black'} style={{ backgroundColor: 'white', marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View>
                        <Text style={{ color: "#27b6cc", textAlign: 'center', marginBottom: 10 }}>Redirecting to partner site. Please wait....</Text>
                    </View>
                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <Button textColor="#27b6cc" icon="arrow-right-bold" style={{ size: 15, marginTop: 10 }} />
                            {imageURL &&
                                <Image source={{ cache: "force-cache", uri: imageURL }} style={{ height: 60, width: 60, resizeMode: 'contain' }} />}
                        </View>
                    </View>
                </View>
            </Modal>
        </Surface>

    );
}
