
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  getAllBrands,
  getAllCategories,
  getAllSubCategories,
  productmastersdata,
  getProductByIdAction,
  subcategoryAction
} from '../../../../state/actions/productmastersAction';
import { Card, Button, TextInput, HelperText, Surface, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Text, Platform, ScrollView, Dimensions, RefreshControl } from 'react-native';
import PhoneInput from 'react-native-phone-number-input';
import EsiSearchDropdown from '../../../../components/custom/searchable-dropdown';
import EsiDatePicker from '../../../../components/custom/date/date';
import Header from '../../../auth/components/header';
import { useToast } from '../../../../components/custom/react-native-paper-toast/src';
import { CountryCode } from '../../../../components/custom/countrycode';
import AsyncStorage from '@react-native-async-storage/async-storage';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const EditProductMaster = (props: any) => {
  const { navigation } = props;
  const ID = props?.route?.params?.ID;
  const CID = props?.route?.params?.CID;
  const toast = useToast();
  const dispatch = useDispatch();
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const EmailID = useSelector(state => state.auth?.user);
  const esiColor = useSelector(state => state.theme);
  //reload
  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  React.useEffect(() => {
    if (!(permissions.Product_Master_Product_Color_Product_Size === "write")) {
      toast.show({
        message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
      });
    }
    setSeconds(2);
  }, [permissions, ID]);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const [showDropDown, setShowDropDown] = React.useState('');
  const [showDropDownbrand, setShowDropDownbrand] = React.useState('');
  const [showDropDownsubcategory, setShowDropDownsubcategory] = React.useState('');

  const [data, setData] = useState([]);
  const callbackProductData = (data: any) => {
    setData(data)
  }
  React.useEffect(() => {
    if (CID) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Category_Id": CID,
        "Sub_Category_Id": "",
        "Category_Name": "",
        "Sub_Category_Name": "",
        "Records_Filter": "FILTER"
      }
      dispatch(subcategoryAction(formData, callBackData))
    }
  }, [CID]);
  React.useEffect(() => {
    if (ID) {
      let formData = {
        "search_by_filter": "",
        "search": "",
        "Brand_Id": "",
        "Category_Id": "",
        "Is_Product_Active": "",
        "Min_Mrp": "",
        "Partner_Details_Id": "",
        "Product_Master_Id": ID,
        "Product_Name": "",
        "Records_Filter": "FILTER",
        "Sku": "",
        "Status": "",
        "Upc_Code": "",
        "Warranty_Period": ""
      };
      dispatch(getAllCategories());
      dispatch(getAllBrands());
      dispatch(getAllSubCategories());
      dispatch(getProductByIdAction(formData, callbackProductData));
    }
  }, [ID]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  //  const productmasters = useSelector(state => state.productmasters.productmasters.all);
  const categories = useSelector(state => state.product.productCategories.categories);
  // const subcategories = useSelector(state => state.product.productSubCategories.subcategories);
  const brands = useSelector(state => state.product.productBrands.brands);

  let categoryopt = []
  if (categories) {
    if (categories[0]) {
      for (
        let i = 0;
        i < categories?.length;
        i++) {
        categoryopt.push({
          label: categories[i].Category_Name,
          value: categories[i].Category_Id
        })
      }
    }
  }
  const [subcategories, setSubData] = React.useState([]);
  let subcategorieopt = []
  if (subcategories) {
    if (subcategories[0]) {
      for (let i = 0; i < subcategories?.length; i++) {
        subcategorieopt.push(
          {
            label: subcategories[i].Sub_Category_Name,
            value: subcategories[i].Sub_Category_Id
          })
      }
    }
  }

  let brandopt = []
  if (brands) {
    if (brands[0]) {
      for (let i = 0; i < brands?.length; i++) {
        brandopt.push({
          label: brands[i].Brand_Name,
          value: brands[i].Brand_Id
        })
      }
    }
  }
  const callBackData = (data: any) => {
    setSubData(data)
  }
  const setType = (forsubcategory) => {
    if (forsubcategory !== "") {
      let formData = {
        "Category_Id": forsubcategory,
        "Status": "",
        "Sub_Category_Name": "",
        "Records_Filter": "FILTER"

      }
      dispatch(subcategoryAction(formData, callBackData))
    }
  }
  const phoneInput = useRef(null);

  //mobile number country code
  const getCountryCode = (value) => {
    let dailCode = "91";
    if (value) {
      if (value.split('+')?.length > 0) {
        let pSplit = value.split('+');
        let count = pSplit?.length > 1 ? 1 : 0;
        if (pSplit[count].split(' ')?.length > 0) {
          dailCode = value.split('+')[count].split(' ')[0];
        }
      }
    }
    return CountryCode.find((item) => item.dial_code == dailCode)?.code;
  }

  const getCurrentMobileNumber = (value) => {
    let dailNumber = value;
    if (value) {
      if (value.split(' ')?.length > 1) {
        dailNumber = value.split(' ')[1];
      }
    }
    return dailNumber;
  }

  //form reset
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const customTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      text: esiColor.Text,
      placeholder: esiColor.TextPlaceholder
    },
  };

  const styles = StyleSheet.create({
    datePickerStyle: {
      width: 350,
      height: 60,
      marginTop: 6,
    },
    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },
    images: {
      width: '100%',
      height: 100,
      borderRadius: 5,
      marginBottom: 15
    },
    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },
    editor: {
      backgroundColor: "black",
      borderColor: "black",
      borderWidth: 1,
    },
    rich: {
      minHeight: 130,
      // flex: 1,
    },
    richBar: {
      height: 50,
      backgroundColor: "#F5FCFF",
    },
    text: {
      fontWeight: "bold",
      fontSize: 18,
    },
    tib: {
      textAlign: "center",
      color: "#515156",
    },
    a: {
      fontWeight: "bold",
      color: "purple",
    },
    div: {
      fontFamily: "monospace",
    },
    p: {
      fontSize: 30,
    }, container: {      
      paddingTop: 15
    },
    dropdown: {
      height: 58,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,
    },
    icon: {
      marginRight: 5,
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    scrollView: {
      height: '20%',
      width: '80%',
      margin: 20,
      alignSelf: 'center',
      padding: 20,
      borderWidth: 5,
      borderRadius: 5,
      borderColor: 'black',
      backgroundColor: 'lightblue'
    },
    contentContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'lightgrey',
      paddingBottom: 50
    }
  });

  return (
    <Surface style={{ flex: 1,backgroundColor: esiColor.BackgroundColor  }}>
      <Header navigation={navigation} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#27B6CC']}
            tintColor={'#27B6CC'}
            title={"Pull to refresh"}
            titleColor={"#27B6CC"}
          />
        }>
        {data?.length > 0 && seconds <= 0 &&
          <Formik
            initialValues={{
              Product_Master_Id: data[0].Product_Master_Id,
              Sku: data[0].Sku,
              Category: data[0].Category_Id,
              Sub_Category: data[0].Sub_Category_Id,
              Brand: data[0].Brand_Id,
              Product_Name: data[0].Product_Name,
              Product_Type: data[0].Product_Type,
              Pattern_Name: data[0].Pattern_Name,
              Upc_Code: data[0].Upc_Code,
              Height: data[0].Height,
              Weight: data[0].Weight,
              Width: data[0].Width,
              Length: data[0].Length,
              Manufacturer: data[0].Manufacturer,
              Manufacturer_Contact: data[0].Manufacturer_Contact,
              Manufacturer_Cost: data[0].Manufacturer_Cost,
              Mrp: data[0].Mrp,
              Max_Mrp: data[0].Max_Mrp,
              Min_Mrp: data[0].Min_Mrp,
              Short_Description: data[0].Short_Description,
              Modified_By: EmailID.Email_Id,
              Features: data[0].Features,
              Is_Product_Active: data[0].Is_Product_Active > 0 ? true : false,
              Long_Description: data[0].Long_Description,
              Sales_Start_Date: data[0].Sales_Start_Date,
              Specifications: data[0].Specifications,
              Status: data[0].Status,
              Warranty_Period: data[0].Warranty_Period,
            }}
            validationSchema={Yup.object().shape({
              Sku: Yup.string().required('SKU ID is required.'),
              Category: Yup.string().required('Category Name is required.'),
              Sub_Category: Yup.string().required('Sub Category Name is required.'),
              Brand: Yup.string().required('Brand Name is required.'),
              Product_Name: Yup.string().required('Product Master Name is required.'),
              Product_Type: Yup.string().required('Product Type is required.'),
              Pattern_Name: Yup.string().required('Pattern Name is required.'),
              Upc_Code: Yup.string().required('UPC Code is required.'),
              Height: Yup.string().required('Height is required in cm.'),
              Weight: Yup.string().required('Weight is required in g.'),
              Width: Yup.string().required('Width is required in cm.'),
              Length: Yup.string().required('Length is required in cm.'),
              Manufacturer: Yup.string().required('Manufacturer is required.'),
              Manufacturer_Cost: Yup.string().required('Manufacturer Cost is required.'),
              Mrp: Yup.string().required('MRP is required.'),
              Max_Mrp: Yup.number().positive().moreThan(Yup.ref('Min_Mrp'),'Require Min MRP MRP greater than to Max MRP.').required('Max MRP is required.'),
              Min_Mrp: Yup.number().required('Min MRP is required.'),
              Warranty_Period: Yup.string().required('Warranty Period is required.'),
              Sales_Start_Date: Yup.date().min(Date(), "Partner Sales Date must not be past date and today.").required('Partner Sales Start Date is required.'),
              Short_Description: Yup.string().required('Short Description is required.'),
              Manufacturer_Contact: Yup.string().
                test("phone-required", "Manufacturer Contact is required.", (value: any) => {
                  if (value) {
                    if (!("+" + phoneInput.current?.getCallingCode() == value)) {
                      return true;
                    }
                  }
                  return false;
                })
                .test("phone-validation", "Please provide valid Manufacturer Contact.", (value: any) => {
                  let mobileValue = (value || "").split(" ");
                  if (mobileValue[0] === "+91") {
                    if (/^[0-9]\d*$/.test(mobileValue[1])) {
                      if (mobileValue[1]?.length === 10) {
                        return true;
                      }
                    }
                  } else {
                    if (/^[0-9]\d*$/.test(mobileValue[1])) {
                      return true;
                    }
                  }
                  return false
                }),
            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              let submitTest = true;
              setSubmitting(false);
              const errors = {};
              setErrors(errors);
              if (submitTest) {
                let formValues = JSON.parse(JSON.stringify(values));
                dispatch(productmastersdata(formValues));
                navigation.navigate('EditProductMasters', { ID, CID })
              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (

              <View style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}>
                <View style={{ width: dimensions > 500 ? "75%" : "95%", marginLeft: "auto", marginRight: "auto", marginTop: "2%" }}>
                  <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 127 }}
                    onPress={() => {
                      resetForm();
                      navigation.navigate('ProductMasterGet')
                    }}>
                    Go Back
                  </Button>
                </View>
                <Card style={{  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5 }}>
                  <Card.Content>
                    <Text style={{ fontSize: 20, fontWeight: "bold",color: esiColor.brandFontColor }}>
                      Edit Product Master
                    </Text>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      flexShrink: 1
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: '1%' }}>
                        <TextInput
                        //  theme={customTheme}
                         style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                         outlineColor={esiColor.TextOuterLine}
                         underlineColor={esiColor.TextUnderline}
                         activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Sku && errors.Sku)}
                          label="SKU ID"
                          mode='outlined'
                          value={values.Sku}
                          onChangeText={handleChange("Sku")}
                        />
                        <HelperText type="error" visible={Boolean(touched.Sku && errors.Sku)}>
                          {touched.Sku && errors.Sku}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Category && errors.Category) && { color: '#b00020' }]}>
                            Category Name
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Category && errors.Category) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={categoryopt} labelField="label"
                            valueField="value"
                            maxHeight={categoryopt?.length > 1 ? 250 : 200}
                            search={categoryopt?.length > 1 ? true : false}
                            searchPlaceholder={'Select Category Name'}
                            value={values.Category}
                            onUpdateValue={item => {
                              setType(item?.value ? item.value : "");
                              setFieldValue('Category', item.value)
                              setShowDropDown(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Category && errors.Category)}>
                            {touched.Category && errors.Category}
                          </HelperText>
                        </View>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Sub_Category && errors.Sub_Category) && { color: '#b00020' }]}>
                            Sub Category Name
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Sub_Category && errors.Sub_Category) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={subcategorieopt}
                            labelField="label"
                            valueField="value"
                            placeholder={!showDropDownsubcategory ? 'Select item' : '...'}
                            maxHeight={subcategorieopt?.length > 1 ? 250 : 200}
                            search={subcategorieopt?.length > 1 ? true : false}
                            searchPlaceholder={'Select Sub Category Name'}
                            value={values.Sub_Category}
                            onUpdateValue={item => {
                              setFieldValue('Sub_Category', item.value)
                              setShowDropDownsubcategory(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Sub_Category && errors.Sub_Category)}>
                            {touched.Sub_Category && errors.Sub_Category}
                          </HelperText>
                        </View>
                      </View>
                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                        <View style={styles.container}>
                          <Text style={[styles.dropdownlabel, Boolean(touched.Brand && errors.Brand) && { color: '#b00020' }]}>
                            Brand Name
                          </Text>
                          <EsiSearchDropdown
                            style={[styles.dropdown, Boolean(touched.Brand && errors.Brand) && { borderColor: '#b00020' }]}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            inputSearchStyle={styles.inputSearchStyle}
                            iconStyle={styles.iconStyle}
                            data={brandopt}
                            labelField="label"
                            valueField="value"
                            placeholder={!showDropDownbrand ? 'Select item' : '...'}
                            maxHeight={brandopt?.length > 1 ? 250 : 200}
                            search={brandopt?.length > 1 ? true : false}
                            searchPlaceholder={'Select Brand Name'}
                            value={values.Brand}
                            onUpdateValue={item => {
                              setFieldValue('Brand', item.value)
                              setShowDropDownbrand(false);
                            }}
                          />
                          <HelperText type="error" visible={Boolean(touched.Brand && errors.Brand)}>
                            {touched.Brand && errors.Brand}
                          </HelperText>
                        </View>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Product_Name && errors.Product_Name)}
                          label="Product Master Name"
                          mode='outlined'
                          value={values.Product_Name}
                          onChangeText={handleChange("Product_Name")}
                        />
                        <HelperText type="error" visible={Boolean(touched.Product_Name && errors.Product_Name)}>
                          {touched.Product_Name && errors.Product_Name}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Product_Type && errors.Product_Type)}
                          label="Product Type"
                          mode='outlined'
                          value={values.Product_Type}
                          onChangeText={handleChange("Product_Type")}
                        />
                        <HelperText type="error" visible={Boolean(touched.Product_Type && errors.Product_Type)}>
                          {touched.Product_Type && errors.Product_Type}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Pattern_Name && errors.Pattern_Name)}
                          label="Pattern Name"
                          mode='outlined'
                          value={values.Pattern_Name}
                          onChangeText={handleChange("Pattern_Name")}
                        />
                        <HelperText type="error" visible={Boolean(touched.Pattern_Name && errors.Pattern_Name)}>
                          {touched.Pattern_Name && errors.Pattern_Name}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Upc_Code && errors.Upc_Code)}
                          label="UPC Code"
                          mode='outlined'
                          value={values.Upc_Code}
                          onChangeText={handleChange("Upc_Code")}
                        />
                        <HelperText type="error" visible={Boolean(touched.Upc_Code && errors.Upc_Code)}>
                          {touched.Upc_Code && errors.Upc_Code}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Height && errors.Height)}
                          label="Height"
                          mode='outlined'
                          value={values.Height}
                          onChangeText={handleChange("Height")}
                          keyboardType='numeric'
                          placeholder='cm'
                        />
                        <HelperText type="error" visible={Boolean(touched.Height && errors.Height)}>
                          {touched.Height && errors.Height}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Weight && errors.Weight)}
                          label="Weight"
                          mode='outlined'
                          value={values.Weight}
                          onChangeText={handleChange("Weight")}
                          keyboardType='numeric'
                          placeholder='g'
                        />
                        <HelperText type="error" visible={Boolean(touched.Weight && errors.Weight)}>
                          {touched.Weight && errors.Weight}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Width && errors.Width)}
                          label="Width"
                          mode='outlined'
                          value={values.Width}
                          onChangeText={handleChange("Width")}
                          keyboardType='numeric'
                          placeholder='cm'
                        />
                        <HelperText type="error" visible={Boolean(touched.Width && errors.Width)}>
                          {touched.Width && errors.Width}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched?.Length && errors.Length)}
                          label="Length"
                          mode='outlined'
                          value={values.Length}
                          onChangeText={handleChange("Length")}
                          keyboardType='numeric'
                          placeholder='cm'
                        />
                        <HelperText type="error" visible={Boolean(touched?.Length && errors?.Length)}>
                          {touched?.Length && errors?.Length}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginTop: 10 }}>
                        <TextInput
                          //  theme={customTheme}
                           style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                           outlineColor={esiColor.TextOuterLine}
                           underlineColor={esiColor.TextUnderline}
                           activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Manufacturer && errors.Manufacturer)}
                          label="Manufacturer"
                          mode='outlined'
                          value={values.Manufacturer}
                          onChangeText={handleChange("Manufacturer")}
                        />
                        <HelperText type="error" visible={Boolean(touched.Manufacturer && errors.Manufacturer)}>
                          {touched.Manufacturer && errors.Manufacturer}
                        </HelperText>
                      </View>

                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                        <Text style={{ textAlign: "left" }}>Manufacturer Contact</Text>

                        <PhoneInput
                          ref={phoneInput}
                          value={getCurrentMobileNumber(values.Manufacturer_Contact)}
                          defaultCode={getCountryCode(values.Manufacturer_Contact)}
                          onChangeText={(text) => {
                            setFieldValue("Manufacturer_Contact", "+" + phoneInput.current?.getCallingCode() + " " + text);
                          }}
                          onChangeCountry={(value) => {
                            let callingCode = "+" + value?.callingCode[0] + " ";
                            let dailNumber = values.Manufacturer_Contact?.split(' ')[1] ? values.Manufacturer_Contact?.split(' ')[1] : values.Manufacturer_Contact;
                            setFieldValue("Manufacturer_Contact", callingCode + dailNumber)
                          }}
                          layout="first"
                          withShadow
                          containerStyle={{
                            width: '100%',
                            margin: "auto",
                            backgroundColor:esiColor.BackgroundColor,
                            overflow: 'hidden',
                            borderRadius: 3,
                            borderColor: Boolean(touched.Manufacturer_Contact && errors.Manufacturer_Contact) ? "#b00020" :  esiColor.TextOuterLine,
                            borderStyle: "solid",
                            borderWidth: Boolean(touched.Manufacturer_Contact && errors.Manufacturer_Contact) ? 2 : 1
                          }}
                          textContainerStyle={{ backgroundColor: esiColor.BackgroundColor }}
                          textInputStyle={Platform.OS === "web" ? {
                            height: 30, marginRight: -10, outlineStyle: 'none', overflow: 'hidden',color:esiColor.Text 
                          } : {
                            height: 30, marginRight: -10, overflow: 'hidden',color:esiColor.Text 
                          }}
                          codeTextStyle={{ marginLeft: -15 ,color:esiColor.Text }}
                          countryPickerButtonStyle={{ width: 50, margin: "auto", marginLeft: 10, paddingBottom: 5 }}
                        />
                        <HelperText type="error" visible={Boolean(touched.Manufacturer_Contact && errors.Manufacturer_Contact)}>
                          {touched.Manufacturer_Contact && errors.Manufacturer_Contact}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Manufacturer_Cost && errors.Manufacturer_Cost)}
                          label="Manufacturer Cost"
                          mode='outlined'
                          value={values.Manufacturer_Cost}
                          onChangeText={handleChange("Manufacturer_Cost")}
                          keyboardType='numeric'
                        />
                        <HelperText type="error" visible={Boolean(touched.Manufacturer_Cost && errors.Manufacturer_Cost)}>
                          {touched.Manufacturer_Cost && errors.Manufacturer_Cost}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Mrp && errors.Mrp)}
                          label="MRP"
                          mode='outlined'
                          value={values.Mrp}
                          onChangeText={handleChange("Mrp")}
                          keyboardType='numeric'
                        />
                        <HelperText type="error" visible={Boolean(touched.Mrp && errors.Mrp)}>
                          {touched.Mrp && errors.Mrp}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          //  theme={customTheme}
                           style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                           outlineColor={esiColor.TextOuterLine}
                           underlineColor={esiColor.TextUnderline}
                           activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Max_Mrp && errors.Max_Mrp)}
                          label="Max MRP"
                          mode='outlined'
                          value={values.Max_Mrp}
                          onChangeText={handleChange("Max_Mrp")}
                          keyboardType='numeric'
                        />
                        <HelperText type="error" visible={Boolean(touched.Max_Mrp && errors.Max_Mrp)}>
                          {touched.Max_Mrp && errors.Max_Mrp}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Min_Mrp && errors.Min_Mrp)}
                          label="Min MRP"
                          mode='outlined'
                          value={values.Min_Mrp}
                          onChangeText={handleChange("Min_Mrp")}
                          keyboardType='numeric'
                        />
                        <HelperText type="error" visible={Boolean(touched.Min_Mrp && errors.Min_Mrp)}>
                          {touched.Min_Mrp && errors.Min_Mrp}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}>
                      <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, marginBottom: '3%', marginTop: 10 }}>
                        <EsiDatePicker
                        //  theme={customTheme}
                         label={'Partner Sales Start Date'}
                         style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                          value={values.Sales_Start_Date}
                          valueUpdate={date => {
                            setFieldValue('Sales_Start_Date', date)
                          }} />
                        <HelperText type="error" visible={Boolean(touched.Sales_Start_Date && errors.Sales_Start_Date)}>
                          {touched.Sales_Start_Date && errors.Sales_Start_Date}
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, marginTop: 10 }}>
                        <TextInput
                          // theme={customTheme}
                          style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          activeOutlineColor={esiColor.TextOuterLine}
                          error={Boolean(touched.Short_Description && errors.Short_Description)}
                          label="Short Description"
                          mode='outlined'
                          value={values.Short_Description}
                          onChangeText={handleChange("Short_Description")}
                        />
                        <HelperText type="error" visible={Boolean(touched.Short_Description && errors.Short_Description)}>
                          {touched.Short_Description && errors.Short_Description}
                        </HelperText>
                      </View>
                    </View>
                    <View style={{ flexGrow: 1, marginLeft: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        //  theme={customTheme}
                         style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                         outlineColor={esiColor.TextOuterLine}
                         underlineColor={esiColor.TextUnderline}
                         activeOutlineColor={esiColor.TextOuterLine}
                        error={Boolean(touched.Warranty_Period && errors.Warranty_Period)}
                        label="Warranty Period"
                        mode='outlined'
                        multiline
                        value={values.Warranty_Period}
                        onChangeText={handleChange("Warranty_Period")}
                      />
                      <HelperText type="error" visible={Boolean(touched.Warranty_Period && errors.Warranty_Period)}>
                        {touched.Warranty_Period && errors.Warranty_Period}
                      </HelperText>
                    </View>
                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                      <Button textColor={esiColor.itemButtenColor} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                        Next
                      </Button>
                    </View>
                  </Card.Content>
                </Card>
              </View>

            )}
          </Formik>}
      </ScrollView>
    </Surface>
  );
};

export default EditProductMaster;