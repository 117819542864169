import {
  ALL_ORDERS_REQUEST,
  ALL_ORDERS_SUCCESS,
  ALL_ORDERS_FAIL,
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_FAIL,
  PRODUCT_REVIEW_CREATE_REQUEST,
  PRODUCT_REVIEW_CREATE_SUCCESS,
  PRODUCT_REVIEW_CREATE_FAIL,
  USERSREVIEW_REQUEST,
  USERSREVIEW_SUCCESS,
  USERSREVIEW_FAIL,
  ALL_ORDERS_FILTER,
  PRODUCT_REVIEW_UPDATE_REQUEST,
  PRODUCT_REVIEW_UPDATE_SUCCESS,
  PRODUCT_REVIEW_UPDATE_FAIL,
  NOTIFICATION_SHIPPING_UPDATE_REQUEST,
  NOTIFICATION_SHIPPING_UPDATE_SUCCESS,
  NOTIFICATION_SHIPPING_UPDATE_FAIL,
} from '../../actions/shoppers/shoppersordersAction'
const initialState = {
  Orders: { all: [], error: '', isLoading: false },
  Order: { all: [], error: '', isLoading: false },
  usersreview: { all: [], error: '', isLoading: false },
  productreviewCreate: { productreview: {}, error: '', isLoading: false },
  ordersFilter: { select: 'All', search: '' },
  productreviewUpdate: { user: {}, error: '', isLoading: false },
  Ordersnotofication: { user: {}, error: '', isLoading: false },
};
export default function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_SHIPPING_UPDATE_REQUEST:
      return { ...state, Ordersnotofication: { user: {}, error: '', isLoading: true } };
    case NOTIFICATION_SHIPPING_UPDATE_SUCCESS:
      return { ...state, Ordersnotofication: { user: state.Ordersnotofication.user, error: '', isLoading: false } };
    case NOTIFICATION_SHIPPING_UPDATE_FAIL:
      return { ...state, Ordersnotofication: { user: {}, error: action.payload, isLoading: false } };
  
    case ALL_ORDERS_REQUEST:
      return { ...state, Orders: { all: state.Orders.all, error: '', isLoading: true } };
    case ALL_ORDERS_SUCCESS:
      return { ...state, Orders: { all: action.payload, error: '', isLoading: false } };
    case ALL_ORDERS_FAIL:
      return { ...state, Orders: { all: [], error: action.payload, isLoading: false } };
    case ORDERS_REQUEST:
      return { ...state, Order: { all: state.Order.all, error: '', isLoading: true } };
    case ORDERS_SUCCESS:
      return { ...state, Order: { all: action.payload, error: '', isLoading: false } };
    case ORDERS_FAIL:
      return { ...state, Order: { all: [], error: action.payload, isLoading: false } };
    case PRODUCT_REVIEW_CREATE_REQUEST:
      return { ...state, productreviewCreate: { productreview: {}, error: '', isLoading: true } };
    case PRODUCT_REVIEW_CREATE_SUCCESS:
      return { ...state, productreviewCreate: { productreview: state.productreviewCreate.productreview, error: '', isLoading: false } };
    case PRODUCT_REVIEW_CREATE_FAIL:
      return { ...state, productreviewCreate: { productreview: {}, error: action.payload, isLoading: false } };
    case PRODUCT_REVIEW_UPDATE_REQUEST:
      return { ...state, productreviewUpdate: { user: {}, error: '', isLoading: true } };
    case PRODUCT_REVIEW_UPDATE_SUCCESS:
      return { ...state, productreviewUpdate: { user: state.productreviewUpdate.user, error: '', isLoading: false } };
    case PRODUCT_REVIEW_UPDATE_FAIL:
      return { ...state, productreviewUpdate: { user: {}, error: action.payload, isLoading: false } };
    case USERSREVIEW_REQUEST:
      return { ...state, usersreview: { all: state.usersreview.all, error: '', isLoading: true } };
    case USERSREVIEW_SUCCESS:
      return { ...state, usersreview: { all: action.payload, error: '', isLoading: false } };
    case USERSREVIEW_FAIL:
      return { ...state, usersreview: { all: [], error: action.payload, isLoading: false } };
    case ALL_ORDERS_FILTER:
      let res = { ...state };
      if (action.fType === "select") {
        res = { ...state, ordersFilter: { search: state.ordersFilter.search, select: action.value } }
      }
      else if (action.fType === "search") {
        res = { ...state, ordersFilter: { select: state.ordersFilter.select, search: action.value } };
      }
      return res;
    default:
      return state;
  }
}
