import React, { useState } from "react";
import { Text, View, StyleSheet, ScrollView, Picker, Platform, Dimensions, RefreshControl } from 'react-native';
import { Dialog, Card, Portal, IconButton, DataTable, Button, Searchbar, Surface } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import 'react-native-gesture-handler'
import AsyncStorage from "@react-native-async-storage/async-storage";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../../auth/components/header";
import { BookedFilter, getBookedAction } from "../../../state/actions/Properties/bookedActions";
import EsiSearchDropdown from "../../../components/custom/searchable-dropdown";
const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Function_Hall_Booking_ID',
    label: 'Function Hall Booking ID'
  },
  {
    value: 'Booked_Date',
    label: 'Booked Date'
  },
  {
    value: 'Partner_Rejection_Reason',
    label: 'Partner Rejection Reason'
  },
  {
    value: 'Status',
    label: 'Status'
  },
];
const numberOfItemsPerPageList = [5, 10, 20];
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const bookeddatesget = (props: any) => {
  const { navigation } = props;
  const dispatch = useDispatch()
  // Role restriction
  const permissions = useSelector(state => state.auth.permissions);
  const eventid = props.route?.params?.eventid;
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const allbookingsGet = useSelector(state => state.bookingsred.bookings.all);
  let bookingMenu = useSelector(state => state.bookingsred.bookingFilter.select);
  let bookingSearch = useSelector(state => state.bookingsred.bookingFilter.search);
  const EmailID = useSelector(state => state.auth?.user);
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, allbookingsGet?.length);
  const bookingid = props.route?.params?.bookingidparams;
  const [BookingId, setBookingID] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [bookings, setData] = React.useState();
  const hideDialog = () => setVisible(false);
  const handleClickadd = () => {
    props.navigation.navigate('AddownShopperProducts', { shoppersproducts: shopperspartnersid, partnerproductid: partnerproductid })
  };

  const handleClickedit = async (allbookingsGet) => {
    props.navigation.navigate('EditownShoppersProduct', { discountedproductid: allshopperpartnersGet.Discounted_Products_Id, partnerid: allshopperpartnersGet.Partner_Details_Id })
  };
  React.useEffect(() => {
    if (bookingid !== BookingId) {
      const formData = {
       
          "Booked_Date": "",
          "Name": "",
          "Records_Filter": "ALL"
        
      };
      setBookingID(bookingid)
      dispatch(getBookedAction(formData))
      dispatch(BookedFilter('search', ''));
      dispatch(BookedFilter('select', 'All'));
    }
    if (!isEmpty(allbookingsGet)) {
      setProducts(allbookingsGet);
    } else {
      setProducts([]);
    }


  }, [BookingId, bookingid]);
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [refreshing, setRefreshing] = React.useState(false);
  const wait = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  React.useEffect(() => {
    const signNavigation = async () => {
      const user = await AsyncStorage.getItem("user");
      const user_parsed = JSON.parse(user);
      if (!user_parsed?.Partner_Session_Id) {
        navigation.navigate('SignIn');
      }
    };
    signNavigation();
  });

  const [oldSearch, setOldSearch] = React.useState(0);
  const checkshopperproductsForTable = () => {
    //Search
    if (bookingSearch) {
      try {
        if (bookingMenu === "All") {
          return allbookingsGet.filter(x => {
            let colummns = ["Function_Hall_Booking_ID", 'Booked_Date', "Partner_Rejection_Reason", "Status"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
              const subString = typeof x[colummns[i]] === "string" ? bookingSearch.toLowerCase() : bookingSearch;
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(bookingSearch.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString == bookingSearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return allbookingsGet.filter(x => {
            let mainString = typeof x[bookingMenu] == "string" ? x[bookingMenu].toLowerCase() : x[bookingMenu];
            let subString = typeof x[bookingMenu] == "string" ? bookingMenu.toLowerCase() : bookingSearch;
            if (typeof x[bookingMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString == bookingSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (page > 0) {
          if (oldSearch != bookingSearch.length) {
            setOldSearch(bookingSearch.length);
            setPage(0);
          }
        }
        else if (oldSearch != bookingSearch.length) {
          setOldSearch(bookingSearch.length);
          setPage(0);
        }
      }
    }
    else {
      return allbookingsGet
    }
  }
  //sorting
  const [order, setOrder] = React.useState('ascending');
  const [orderBy, setOrderBy] = React.useState('Function_Hall_Booking_ID');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ascending';
    setOrder(isAsc ? 'descending' : 'ascending');
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  function descendingComparator(a, b, orderBy) {
    try {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } catch (error) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

  }
  function getComparator(order, orderBy) {
    return order === 'descending'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {

      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  const handleChange = itemValue => {
    dispatch(BookedFilter('select', itemValue));
  };
  const handleSearch = value => {
    dispatch(BookedFilter('search', value));
  };
  const data = stableSort(
    checkshopperproductsForTable(), getComparator(order, orderBy))
  return (
    <Surface style={{
      flex: 1
    }}>
      <Header navigation={navigation} />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{ width: dimensions >= 700 ? '60%' : '90%', maxHeight: (dimensions >= 500) ? 500 : 400, marginLeft: 'auto', marginRight: 'auto' }}>
          <View style={{ alignItems: 'flex-end', paddingTop: 10 }}>
            <IconButton
              icon="close"
              size={20}
              onPress={() => setVisible(!visible)}
            />
          </View>
          <Dialog.Title style={{ fontSize: 20, marginTop: -35, fontWeight: 'bold', textAlign: "center" }}>
            <Text style={{ color: '#27B6CC' }}>Booked Halls</Text>
          </Dialog.Title>
          <Dialog.ScrollArea>
            <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Dialog.Content style={{ marginLeft: -30 }}>
                <View style={{ flexDirection: "row", paddingTop: 15 }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Function Hall ID</Text></View><View style={{ flex: 1 }}><Text>{bookings?.Function_Hall_ID} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: "bold", fontSize: 13 }}>Function Hall Booking ID</Text></View><View style={{ flex: 0.9 }}><Text style={{ color: '#27B6CC' }}>{bookings?.Function_Hall_Booking_ID} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Status</Text></View><View style={{ flex: 1 }}><Text style={{ color: bookings?.Status === "Active" ? '#27B6CC' : "red" }}>{bookings?.Status} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Event ID</Text></View><View style={{ flex: 1 }}><Text>{bookings?.Event_ID} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Booked Date</Text></View><View style={{ flex: 1 }}><Text>{bookings?.Booked_Date} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Booking Status</Text></View><View style={{ flex: 1 }}><Text style={{ color: '#27B6CC' }}>{bookings?.Booking_Status} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Partner Rejection Reason</Text></View><View style={{ flex: 1 }}><Text style={{ color: '#27B6CC' }}>{bookings?.Partner_Rejection_Reason} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Is Default</Text></View><View style={{ flex: 1 }}><Text style={{ color: '#27B6CC' }}>{bookings?.Is_Default} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Modified By</Text></View><View style={{ flex: 1 }}><Text style={{ color: '#27B6CC' }}>{bookings?.Modified_By} </Text></View>
                </View>
                <View style={{
                  flexDirection: "row", paddingTop: 15
                }}>
                  <View style={{ flex: 1 }}><Text style={{ fontWeight: "bold", fontSize: 13 }}>Created By</Text></View><View style={{ flex: 1 }}><Text style={{ color: '#27B6CC' }}>{bookings?.Modified_Date} </Text></View>
                </View>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
      <View >
        <Button textColor='#27B6CC' icon="keyboard-backspace" style={{ width: 130, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('GetEvents')}>
          Go Back
        </Button>
      </View>
      <ScrollView refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#27B6CC']}
          tintColor={'#27B6CC'}
          title={"Pull to refresh"}
          titleColor={"#27B6CC"}
        />
      } >
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontSize: 22, fontWeight: "bold", paddingLeft: 17, paddingTop: 10 }}>Booked Halls</Text>
        </View>

        {(permissions.Property_Booked_Date === "write") &&
          <View style={{ justifyContent: 'flex-end', flexDirection: "row", flexWrap: "wrap", flexShrink: 1, margin: 12 }}>
            <Button style={{ backgroundColor: '#27B6CC' }} mode="contained" onPress={(e) => { handleClickadd(e); }}>
              Add Booked Halls
            </Button>
          </View>}
        <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, justifyContent: 'space-between' }}>
          {Platform.OS !== 'web' ?
            <View style={{ flex: dimensions <= 850 ? 1.5 : 0.5, marginLeft: 15 }}>
              <EsiSearchDropdown
                style={[styles.dropdown]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={filterOptions}
                containerStyle={styles.containerstyle}
                maxHeight={180}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                value={bookingMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
            </View>
            :
            <View style={{ flexDirection: "row", flexWrap: "wrap", flexShrink: 1, marginLeft: 15 }}>
              <Picker
                selectedValue={bookingMenu}
                style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7', backgroundColor: '#ffff' }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
              >
                <Picker.Item label="All" value="All" />
                <Picker.Item label="Function Hall Booking ID" value="Function_Hall_Booking_ID" />
                <Picker.Item label="Booked Date" value="Booked_Date" />
                <Picker.Item label="Partner Rejection Reason" value="Partner_Rejection_Reason" />
                <Picker.Item label="Status" value="Status" />
              </Picker>
            </View>}

          <View style={{ flex: dimensions <= 500 ? 0.1 : 0.6 }} />

          <View style={{ width: dimensions <= 500 ? '55%' : "60%", marginRight: 12, marginLeft: 5 }}>
            <Searchbar
              style={{ borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7' }}
              placeholder="Search"
              variant="outlined"
              onChangeText={(value) => handleSearch(value)}
              value={bookingSearch}
            />
          </View>
        </View>
        <ScrollView horizontal={dimensions >= 700 ? false : true}>
          <View style={styles.mainbox}>
            <Card>
              <DataTable style={{ borderWidth: 1, borderColor: '#bdc3c7' }}>
                <DataTable.Header style={styles.databeHeader}>
                  <DataTable.Title active={orderBy === 'Function_Hall_Booking_ID'} sortDirection={orderBy === 'Function_Hall_Booking_ID' ? order : 'ascending'} onPress={createSortHandler('Function_Hall_Booking_ID')} style={styles.title}><Text style={styles.titletext}>Function Hall Booking ID</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Booked_Date'} sortDirection={orderBy === 'Booked_Date' ? order : 'ascending'} onPress={createSortHandler('Booked_Date')} style={styles.title}><Text style={styles.titletext}>Booked Date</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Partner_Rejection_Reason'} sortDirection={orderBy === 'Partner_Rejection_Reason' ? order : 'ascending'} onPress={createSortHandler('Partner_Rejection_Reason')} style={styles.title}><Text style={styles.titletext}>Partner Rejection Reason</Text></DataTable.Title>
                  <DataTable.Title active={orderBy === 'Status'} sortDirection={orderBy === 'Status' ? order : 'ascending'} onPress={createSortHandler('Status')} style={styles.title}><Text style={styles.titletext}>Status</Text></DataTable.Title>
                  <DataTable.Title style={styles.title}><Text style={styles.titletext}>Actions</Text></DataTable.Title>
                </DataTable.Header>
                {data?.slice(page * numberOfItemsPerPage, page * numberOfItemsPerPage + numberOfItemsPerPage).map((bookingData, i) => (
                  <DataTable.Row style={styles.databeBox} key={i}>
                    <DataTable.Cell style={styles.tablerow}>{bookingData.Function_Hall_Booking_ID} </DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>{bookingData.Booked_Date} </DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>{bookingData.Partner_Rejection_Reason}</DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>{bookingData.Status}</DataTable.Cell>
                    <DataTable.Cell style={styles.tablerow}>
                      {(permissions.Property_Booked_Date === "write") &&
                        <IconButton icon="square-edit-outline" size={20} color='#27B6CC' onPress={(e) => { handleClickedit(bookingData); }} />
                      }
                      <IconButton icon="eye" size={20} color='#27B6CC' onPress={(e) => { setData(bookingData); setVisible(!visible) }} /></DataTable.Cell>
                  </DataTable.Row>
                ))
                }
                {isEmpty(data) &&
                  <Text style={{ fontSize: 16, textAlign: 'center' }}>
                    No records found.
                  </Text>
                }
                <DataTable.Pagination
                  page={page}
                  numberOfPages={Math.ceil(allbookingsGet?.length / numberOfItemsPerPage)}
                  onPageChange={page => setPage(page)}
                  label={allbookingsGet?.length ? `${from + 1}-${to} of ${allbookingsGet?.length}` : `0-0`}
                  showFastPaginationControls
                  numberOfItemsPerPageList={numberOfItemsPerPageList}
                  numberOfItemsPerPage={numberOfItemsPerPage}
                  onItemsPerPageChange={onItemsPerPageChange}
                  selectPageDropdownLabel={'Rows per page'}
                />
              </DataTable>
            </Card>
          </View>
        </ScrollView>
      </ScrollView>
    </Surface>
  );
}
const styles = StyleSheet.create({
  title: {
    fontWeight: "bold",
    fontSize: 12,
    justifyContent: "center",
    textAlign: "center",
    width: 220
  },
  titletext: {
    fontWeight: "bold",
    fontSize: 14,
    color: 'black',
  },
  tablerow: {
    justifyContent: "center",
    textAlign: "center",
    width: 140
  },
  mainbox: {
    textAlign: 'center',
    margin: 15,
    flex: 1,
    justifyContent: 'space-between',
  },
  databeBox: {
    margin: 10,
    textAlign: 'center',
  },
  databeHeader: {
    margin: 10,
    textAlign: 'left',
  },
  mainbox: {
    textAlign: 'center',
    margin: 15,
    flex: 1,
    justifyContent: 'space-between',
  },
  dropdown: {
    height: 50,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 5,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  dropdownlabel: {
    position: 'absolute',
    backgroundColor: 'white',
    left: 22,
    top: 2,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  containerstyle: {
    width: 240
  },
});
export default bookeddatesget;