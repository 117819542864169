import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, Checkbox, DefaultTheme } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView, RefreshControl } from 'react-native';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import Header from '../auth/components/header';
import ImageUpload from '../../components/custom/image-upload/upload';
import { createDeliveryAgentAction, uploadDeliveryAgentImage } from '../../state/actions/deliveryagentAction';
import EsiDatePicker from '../../components/custom/date/date';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../components/custom/react-native-paper-toast/src';
import { imageORfileupload } from '../../state/actions/commonImageOrFileUpload';
import { uriToBlob } from '../../components/custom/uriBlob';


const deliverypersionGender = [
    {
        value: 'Male',
        label: 'Male'
    },
    {
        value: 'Female',
        label: 'Female'
    },
    {
        value: 'Others',
        label: 'Others'
    }
];

const AddDeliveryAgent = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    //toast for popup after successful API calls.
    const toast = useToast();
    // img upload
    const [image, setImage] = useState({ preview: '', raw: '' });
    const [imgUrl, setImgUrl] = useState('');
    const [uploadPic, setUploadPic] = useState(false);
    const callBackUploadImage = (data: any) => {
        if (data) {
            setImgUrl(data.File_URL);
        }
        setUploadPic(false);
    };
    const fetchImageFromUri = async (uri: any) => {
        const blob = await uriToBlob(uri);
        return blob;
    };
    const handleImage = async (result) => {
        if (result) {
            let call = result.uri
            let uriParts = result.uri.split('.');
            let fileExtension = uriParts[uriParts.length - 1].toLowerCase().split(';');
            let filformate = fileExtension[0].split(':')
            let file = filformate[1]?.split('/')
            let fileData = {
                File_name: "Delivery Agent",
                Functionality: "Delivery Agents",
                ext: file[1],
                File_Type: "Images",
            };
            const img = await fetchImageFromUri(call);
            setImage({
                preview: result.uri,
                raw: img
            });
            dispatch(
                imageORfileupload(img, fileData, callBackUploadImage)
            );
        }
    };
    const handleRemoveImage = e => {
        setImgUrl('');
        setImage({
            preview: '',
            raw: ''
        });
    };

    // Role restriction
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });

    const [errorMessage, setErrorMessage] = useState({ Del_User_Email_ID: { key: '', message: '' }, Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
    //isSubmitted contains the data from Create API using state call
    const isSubmited = useSelector(state => state.deliveryagent.deliveryagentCreate.isLoading);
    const partnerID = useSelector(state => state.auth.user?.Partner_Details_Id);
    //Dropdowns
    const [typeDropDown, setTypeDropDown] = useState("");

    //mobile
    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });


    //form reset
    const [refreshing, setRefreshing] = React.useState(false);
    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        wait(2000).then(() => setRefreshing(false));
    }, []);

    const customTheme = {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            text: esiColor.Text,
            placeholder: esiColor.TextPlaceholder
        },
    };

    const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        images: {
            width: '100%',
            height: 100,
            borderRadius: 5,
            marginBottom: 15
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    colors={['#27B6CC']}
                    tintColor={'#27B6CC'}
                    title={"Pull to refresh"}
                    titleColor={"#27B6CC"}
                />
            }>
                <Formik initialValues={{
                    DOB: "",
                    Del_User_Email_ID: "",
                    First_Name: "",
                    Full_Name: "",
                    Gender: "Male",
                    Is_Active: 1,
                    Is_Email_Verified: false,
                    Last_Name: "",
                    Marketing_Agreed: false,
                    Mobile: "",
                    Partner_Id: partnerID,
                    Profile_Pic_Path: "",
                    Role: "PARTNER_DELIVERY",
                    T_and_C_Agreed: 0
                }}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        First_Name: Yup.string().matches(/^[A-Za-z ]*$/, 'Please provide valid First Name.').required('First Name is required.'),
                        Last_Name: Yup.string().matches(/^[A-Za-z ]*$/, 'Please provide valid Last Name.').required('Last Name is required.'),
                        Gender: Yup.string().required('Gender is required.'),
                        Mobile: Yup.string().required('Mobile Number is required.').min(10, "Please provide valid Mobile Number.").max(10, "Please provide valid Mobile Number."),
                        DOB: Yup.date().max(new Date(Date.now() - 567648000000), "you must be at least 18 years").required("Date Of Birth is required"),
                        Del_User_Email_ID: Yup.string()
                            .email("Please provide a valid Email ID.")
                            .test('email-length', 'Email ID must be between 5 and 50 characters.', function (value) {
                                if (value) {
                                    return value.length >= 5 && value.length <= 50;
                                }
                                return true;
                            }),
                    })
                    }
                    const onSubmit={(values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
                        let submitTest = true;
                        setSubmitting(false);
                        const errors = {};
                        values.Profile_Pic_Path = imgUrl;
                        setErrors(errors);
                        if (submitTest) {
                            let formValues = JSON.parse(JSON.stringify(values));
                            if (values.Marketing_Agreed) {
                                formValues.Marketing_Agreed = 1;
                            } else {
                                formValues.Marketing_Agreed = 0;
                            }
                            if (values.T_and_C_Agreed) {
                                formValues.T_and_C_Agreed = 1;
                            } else {
                                formValues.T_and_C_Agreed = 0;
                            }
                            if (values.Is_Active) {
                                formValues.Is_Active = 1;
                            } else {
                                formValues.Is_Active = 0;
                            }
                            if (values.Is_Email_Verified) {
                                formValues.Is_Email_Verified = 1;
                            } else {
                                formValues.Is_Email_Verified = 0;
                            }
                            dispatch(createDeliveryAgentAction(formValues, navigation, setErrorMessage, setErrors, toast));
                        }
                    }
                    }
                >{({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    resetForm
                }) => (
                    <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                        <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                            <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 150 }}
                                onPress={() => {
                                    resetForm();
                                    // setReload(true);
                                    navigation.navigate('DeliveryAgent')
                                }}>
                                Go Back
                            </Button>
                        </View>
                        <Card style={{
                            width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                            marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                            backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                        }}>
                            <Card.Content>
                                <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                                    Add Delivery Agent
                                </Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                        {/* Email ID input field with validation*/}
                                        <TextInput
                                            
                                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                            outlineColor={esiColor.TextOuterLine}
                                            underlineColor={esiColor.TextUnderline}
                                            activeOutlineColor={esiColor.TextOuterLine}
                                            label='Delivery Email ID'
                                            mode='outlined'
                                            name="Del_User_Email_ID"
                                            value={values.Del_User_Email_ID}
                                            onChangeText={handleChange('Del_User_Email_ID')}
                                            error={Boolean(touched.Del_User_Email_ID && errors.Del_User_Email_ID)}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.Del_User_Email_ID && errors.Del_User_Email_ID)}>
                                            <ErrorMessage name="Del_User_Email_ID" />
                                        </HelperText>
                                    </View>
                                    <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                        {/* First name input field with validation*/}
                                        <TextInput
                                            
                                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                            outlineColor={esiColor.TextOuterLine}
                                            underlineColor={esiColor.TextUnderline}
                                            activeOutlineColor={esiColor.TextOuterLine}
                                            label='First Name'
                                            mode='outlined'
                                            name="First_Name"
                                            value={values.First_Name}
                                            onChangeText={handleChange('First_Name')}
                                            error={Boolean(touched.First_Name && errors.First_Name)}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.First_Name && errors.First_Name)}>
                                            <ErrorMessage name="First_Name" />
                                        </HelperText>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                        {/* Last Name input field with validation*/}
                                        <TextInput
                                            
                                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                            outlineColor={esiColor.TextOuterLine}
                                            underlineColor={esiColor.TextUnderline}
                                            activeOutlineColor={esiColor.TextOuterLine}
                                            label='Last Name'
                                            mode='outlined'
                                            name="Last_Name"
                                            value={values.Last_Name}
                                            onChangeText={handleChange('Last_Name')}
                                            error={Boolean(touched.Last_Name && errors.Last_Name)}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.Last_Name && errors.Last_Name)}>
                                            <ErrorMessage name="Last_Name" />
                                        </HelperText>
                                    </View>
                                    <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                                        <EsiDatePicker
                                            
                                            label="Date of Birth"
                                            style={{ width: "100%", backgroundColor: esiColor.BackgroundColor, height: 50 }}
                                            value={values.DOB}
                                            valueUpdate={date => {
                                                setFieldValue('DOB', date)
                                            }} />
                                        <HelperText type="error" visible={Boolean(touched.DOB && errors.DOB)}>
                                            <ErrorMessage name="DOB" />
                                        </HelperText>
                                    </View>
                                </View>
                                {/*Mobile number Field with Validation*/}
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, marginTop: 15 }}>

                                        <TextInput
                                            
                                            style={{ marginTop: 15, backgroundColor: esiColor.BackgroundColor }}
                                            outlineColor={esiColor.TextOuterLine}
                                            underlineColor={esiColor.TextUnderline}
                                            activeOutlineColor={esiColor.TextOuterLine}
                                            label="Mobile Number"
                                            mode='outlined'
                                            onChangeText={handleChange('Mobile')}
                                            left={<TextInput.Affix text="+91" />}
                                            value={values.Mobile}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.Mobile && errors.Mobile)}>
                                            <ErrorMessage name="Mobile" />
                                        </HelperText>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                                        <Text style={[styles.dropdownlabel, Boolean(touched.Gender && errors.Gender) && { color: '#b00020' }]}>
                                            Gender
                                        </Text>
                                        {/* Address Type input field with validation*/}
                                        <EsiSearchDropdown
                                            style={[styles.dropdown, Boolean(touched.Gender && errors.Gender) && { borderColor: '#b00020' }]}
                                            placeholderStyle={styles.placeholderStyle}
                                            selectedTextStyle={styles.selectedTextStyle}
                                            inputSearchStyle={styles.inputSearchStyle}
                                            iconStyle={styles.iconStyle}
                                            data={deliverypersionGender}
                                            labelField="label"
                                            valueField="value"
                                            maxHeight={deliverypersionGender.length > 1 ? 250 : 200}
                                            search={deliverypersionGender.length > 1 ? true : false}
                                            searchPlaceholder={'Select Gender'}
                                            dropdownPosition="bottom"
                                            value={values.Gender}
                                            name="Gender"
                                            onFocus={() => setTypeDropDown(true)}
                                            onBlur={() => setTypeDropDown(false)}
                                            onUpdateValue={item => {
                                                setFieldValue('Gender', item.value)
                                                setTypeDropDown(false);
                                            }}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.Gender && errors.Gender)}>
                                            <ErrorMessage name="Gender" />
                                        </HelperText>
                                    </View>
                                </View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                </View>
                                <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", paddingTop: 15, paddingBottom: 15 }}>
                                    {/* Marketing agreed checkbox field*/}
                                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                        <Checkbox.Android
                                            color={esiColor.itemColor}
                                            name="Marketing_Agreed"
                                            status={values.Marketing_Agreed ? 'checked' : 'unchecked'}
                                            onPress={() => {
                                                setFieldValue("Marketing_Agreed", !values.Marketing_Agreed);
                                            }}
                                        />
                                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>Marketing Agreed</Text>
                                    </View>

                                    {/* T_and_C_Agreed checkbox field*/}
                                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                        <Checkbox.Android
                                            color={esiColor.itemColor}
                                            name="T_and_C_Agreed"
                                            status={values.T_and_C_Agreed ? 'checked' : 'unchecked'}
                                            onPress={() => {
                                                setFieldValue("T_and_C_Agreed", !values.T_and_C_Agreed);
                                            }}
                                        />
                                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>T&C Agreed</Text>
                                    </View>
                                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                                        <Checkbox.Android
                                            color={esiColor.itemColor}
                                            name="Is_Email_Verified"
                                            status={values.Is_Email_Verified ? 'checked' : 'unchecked'}
                                            onPress={() => {
                                                setFieldValue("Is_Email_Verified", !values.Is_Email_Verified);
                                            }}
                                        />
                                        <Text style={{ marginTop: 10, color: esiColor.itemColor }}>Is Email Verified</Text>
                                    </View>
                                </View>
                                <View />
                                <View style={{ alignSelf: 'center', flexShrink: 1, width: 270, paddingTop: 20 }} >
                                    <ImageUpload style={{ borderRadius: 6, borderWidth: 0.01, borderColor: '#D6D6D7' }} imagestyle={styles.images} handleRemoveImage={handleRemoveImage} image={image} uploadPic={handleImage} />
                                </View>
                                <View style={{ alignSelf: 'center', flexShrink: 1, width: 270, paddingTop: 20 }}>
                                    <Text style={{ margin: 10, color: 'red' }}>{errorMessage.global.message}</Text>
                                </View>
                                <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", marginTop: 'auto', padding: 10 }}>
                                    <Button textColor={esiColor.itemButtenColor} disabled={isSubmited || uploadPic} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                        Save
                                    </Button>
                                </View>
                            </Card.Content>
                        </Card>
                    </View>
                )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default AddDeliveryAgent;