
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Text, Button, HelperText, Surface } from 'react-native-paper';
import { StyleSheet, View, Dimensions, ScrollView } from 'react-native';
import EsiSearchDropdown from '../../../components/custom/searchable-dropdown';
import Header from '../../auth/components/header';
import { getAllProducts, getAllSBCPartnerCityProductAction, getSBCPartnerCityProductAction, updateSBCPartnerCityProductAction } from '../../../state/actions/sbcpartnercityProductAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from '../../../components/custom/react-native-paper-toast/src';

//StatusOption used to get the data from dropdown
const StatusOption = [
    {
        value: 'Active',
        label: 'Active'
    },
    {
        value: 'In-Active',
        label: 'In-Active'
    }
];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const EditSBCPartnercityproduct = (props: any) => {

    //navigation is used for navigation between screens
    const { navigation, ...rest } = props
    const dispatch = useDispatch();
    //toast for popup after successful API calls.
    const toast = useToast();
    const esiColor = useSelector(state => state.theme);
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 5, SortBy: "PARTNER_SERVICE_CITY_PRODUCTS_ID", SortOrder: "DESC" });
    // Role restriction
    const permissions = useSelector(state => state.auth.permissions);
    React.useEffect(() => {
        const signNavigation = async () => {
            const user = await AsyncStorage.getItem("user");
            const user_parsed = JSON.parse(user);
            if (!user_parsed?.Partner_Session_Id) {
                navigation.navigate('SignIn');
            }
        };
        signNavigation();
    });
    React.useEffect(() => {
        if (!(permissions.Shop_by_City_Management_Coupons_Deals_Products === "write")) {
            toast.show({
                message: "You don't have access, contact your Administrator.", type: 'info', duration: 3000, position: 'top'
            });
        }
    }, [permissions]);

    //isSubmitted contains the data from Create API using state call
    const isSubmited = useSelector(state => state.sbcpartnercityproduct.sbcpartnercityproductUpdate.isLoading);
    const sbcpartnercityproductnames = useSelector(state => state.sbcpartnercityproduct.sbcpartcityproducts.products);
    //partnercityproductedit contains the data from Get table
    const partnerid = props.route?.params?.partnerid;
    const partnercityid = props.route?.params?.partnercityid;
    const partnercityproductid = props.route?.params?.partnercityproductid;
    const partnercityname = props.route?.params?.partnercityname;
    const partnername = props.route?.params?.partnername;
    const cityid = props.route?.params?.cityid;

    //Dropdowns
    const [statusDropDown, setStatusDropDown] = useState("");
    const [productsDropDown, setProductsDropDown] = useState("");
    const [sbcpartnercityproducts, setsbcproductData] = useState([]);
    const EmailID = useSelector(state => state.auth?.user);
    const callbackdata = (data: any) => {
        setsbcproductData(data)
      }
    useEffect(() => {
        let formData =
        {
            "search_by_filter": "",
            "search": "",
            "City_Id": "",
            "Partner_Details_Id": "",
            "Partner_Service_City_Id": "",
            "Partner_Name": "",
            "Records_Filter": "FILTER",
            "Partner_Service_City_Products_Id": partnercityproductid,
            "Product_Id": "",
            "Status": ""

        };
        dispatch(getSBCPartnerCityProductAction(formData, pagination,callbackdata));

        let formDataproduct =
        {
            "Partner_Details_Id": partnerid,
            "Records_Filter": ""
        };
        dispatch(getAllProducts(formDataproduct));
    }, []);

    //productslist used to store the label,value in an array from API data  
    let productslist = []
    if (sbcpartnercityproductnames) {
        if (sbcpartnercityproductnames) {
            for (let i = 0; i < sbcpartnercityproductnames.length; i++) {
                productslist.push({
                    label: sbcpartnercityproductnames[i].Products.Product_Name,
                    value: sbcpartnercityproductnames[i].Products.Product_Id
                })
            }
        }
    }

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });

    /*====================================
            Handler
    ====================================*/

    const values = {
        Partner_Service_City_Products_Id: sbcpartnercityproducts?.Partner_Service_City_Products_Id,
        Product_Id: sbcpartnercityproducts?.Product_Id,
        Status: sbcpartnercityproducts?.Status,
        Partner_Service_City_Id: sbcpartnercityproducts?.Partner_Service_City_Id,
        City_Name: sbcpartnercityproducts?.City_Name,
        Partner_Details_Id: sbcpartnercityproducts?.Partner_Details_Id,
        Partner_Name: sbcpartnercityproducts?.Partner_Name,
        Modified_By: EmailID.Email_Id
    }

    //Form validation
    const Form_Validation = Yup.object().shape({
        Status: Yup.string().required('Status is required.')
    })

    const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
        let submitTest = true;
        setSubmitting(false);
        const errors = {};
        setErrors(errors);

        if (submitTest) {
            let formValues = JSON.parse(JSON.stringify(values));
            dispatch(updateSBCPartnerCityProductAction(formValues, navigation, toast, partnercityid, partnerid, partnercityname, partnername, cityid));
        }
    }

      const styles = StyleSheet.create({
        label: {
            marginBottom: 30,
        },
        dropdownlabel: {
            position: 'absolute',
            backgroundColor: esiColor.BackgroundColor,
            color: esiColor.SIconColor,
            left: 22,
            top: 10,
            zIndex: 999,
            paddingHorizontal: 8,
            fontSize: 14,
        },
        dropdown: {
            height: 58,
            borderColor: 'gray',
            borderWidth: 0.5,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.DescColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
    });

    return (
        <Surface style={{ flex: 1,backgroundColor: esiColor.BackgroundColor }}>
            <Header navigation={navigation} />
            <ScrollView>
                <Formik initialValues={{ ...values }} onSubmit={onSubmit} validationSchema={Form_Validation} enableReinitialize={true}>
                    {(props) => (
                        <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>
                            <View style={{ width: 50, alignItems: "flex-start", marginTop: "2%", marginBottom: "2%" }}>
                                <Button textColor={esiColor.GBFColor} icon="keyboard-backspace" style={{ width: 146, justifyContent: 'flex-start' }} onPress={() => navigation.navigate('SBCPartnercityproductGet', { partnercityid: partnercityid, partnerid: partnerid, partnercityname: partnercityname, partnername: partnername, cityid })}>
                                    Go Back
                                </Button>
                            </View>
                            <Card style={{
                                width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                                marginTop: "2%", marginBottom: "2%", borderRadius: 10, borderWidth: 0.5, borderColor: esiColor.SBorderColor,
                                backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 5
                            }}>
                                <Card.Content>
                                    <Text style={{ fontSize: 20, fontWeight: "bold",color: esiColor.brandFontColor }}>
                                        Edit Partner City Product
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                            {/* Product Name field */}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Product_Id && props.errors.Product_Id) && { color: '#b00020' }]}>
                                                Product Name
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Product_Id && props.errors.Product_Id) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                iconColor='white'
                                                data={productslist}
                                                disable
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={productslist.length > 1 ? 250 : 200}
                                                search={productslist.length > 1 ? true : false}
                                                searchPlaceholder={'Select Product Name'}
                                                dropdownPosition="auto"
                                                value={props.values.Product_Id}
                                                name="Product_Id"
                                                onFocus={() => setProductsDropDown(true)}
                                                onBlur={() => setProductsDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Product_Id', item.value)
                                                    setProductsDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Product_Id && props.errors.Product_Id)}>
                                                <ErrorMessage name="Product_Id" />
                                            </HelperText>
                                        </View>
                                        <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 22 }}>
                                            {/* Status input field with validation*/}
                                            <Text style={[styles.dropdownlabel, Boolean(props.touched.Status && props.errors.Status) && { color: '#b00020' }]}>
                                                Status
                                            </Text>
                                            <EsiSearchDropdown
                                                style={[styles.dropdown, Boolean(props.touched.Status && props.errors.Status) && { borderColor: '#b00020' }]}
                                                placeholderStyle={styles.placeholderStyle}
                                                selectedTextStyle={styles.selectedTextStyle}
                                                inputSearchStyle={styles.inputSearchStyle}
                                                iconStyle={styles.iconStyle}
                                                data={StatusOption}
                                                labelField="label"
                                                valueField="value"
                                                maxHeight={StatusOption.length > 1 ? 250 : 200}
                                                search={StatusOption.length > 1 ? true : false}
                                                searchPlaceholder={'Select Status'}
                                                dropdownPosition="auto"
                                                value={props.values.Status}
                                                name="Status"
                                                onFocus={() => setStatusDropDown(true)}
                                                onBlur={() => setStatusDropDown(false)}
                                                onUpdateValue={item => {
                                                    props.setFieldValue('Status', item.value)
                                                    setStatusDropDown(false);
                                                }}
                                            />
                                            <HelperText type="error" visible={Boolean(props.touched.Status && props.errors.Status)}>
                                                <ErrorMessage name="Status" />
                                            </HelperText>
                                        </View>
                                    </View>

                                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                                        <Button textColor={esiColor.itemButtenColor} disabled={isSubmited} style={{ marginTop: "3%", backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                                            Save
                                        </Button>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>
                    )}
                </Formik>
            </ScrollView>
        </Surface>
    );
};

export default EditSBCPartnercityproduct;