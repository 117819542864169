import React, { useEffect, useState } from "react";
import { ActivityIndicator, Card, Surface, Text } from 'react-native-paper';
import { useDispatch, useSelector } from "react-redux";
import { Image, StyleSheet, View, ScrollView, Platform, Dimensions, RefreshControl } from "react-native";
import Header from "../header/header";
import Pagination from "../../../components/custom/pagination/pagination";
import { getCategory } from "../../../state/actions/node-actions/home-actions";
export default function Categoryb2b(props: any) {
  const { navigation } = props;
  const dispatch = useDispatch();
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const esiColor = useSelector(state => state.theme);

  const halfdimentions = dimensions / 2;
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = React.useState({
    "Records_Filter": "ALL"
  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 30, SortBy: "Category_Id", SortOrder: "DESC" });
  const callBackGetData = async (status, data, pagination) => {
    goToTop();

    setIsLoading(false);
    if (status) {
      setCategories(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 30, SortBy: "Category_Id", SortOrder: "DESC" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      categoryCall();
    }
  }, [pagination])
  const [testFilters, setTestFilters] = useState({});
  let type = props?.route?.params?.type
  useEffect(() => {

    if (JSON.stringify(testFilters) != JSON.stringify(filters)) {
      setTestFilters(filters);
      categoryCall();
    }
  }, [filters]);
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const categoryCall = () => {
    setIsLoading(true);
    dispatch(getCategory(filters,
      pagination,
      callBackGetData
    ));
  };

  //Returns a function to scroll the page up or down by any amount, in pixels. Supports scrolling.
  const goToTop = () => {
    try {
      scroll.scrollTo({ x: 0, y: 0, animated: true });
    } catch (error) {

    }
  }
  return (
    <Surface style={{ flex: 1, position: Platform.OS === 'web' ? 'absolute' : null, top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null, right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : null, backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} />
      <View style={{ flexDirection: "column", marginVertical: 5, justifyContent: 'center' }}>
        <Text style={{ fontSize: 22, fontWeight: "bold", alignSelf: 'center', color: esiColor.brandFontColor, margin: 2 }}>All Categories</Text>
      </View>
      <ScrollView ref={(c) => { scroll = c }}
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={categoryCall}
          />
        }
      >
        {loading ?
          <ActivityIndicator color='#27b6cc' animating={loading} style={{ marginTop: 20 }} />
          :
          <View style={styles.Card}>
            {categories.length === 0 ?
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <Text style={{ color: esiColor.NoDataFound }}>No categories available to display</Text>
              </View> :
              categories.map((item, index) => {
                return (
                  <Card key={item?.Category_Id} style={{
                    margin: 10, height: 150, maxHeight: 150, maxWidth: 230, width: (halfdimentions - 20),
                    shadowColor: esiColor.brandShadowColor, borderRadius: 2, shadowRadius: 10, elevation: 10, opacity: 10, backgroundColor: esiColor.BackgroundColor
                  }} onPress={(e) => navigation.navigate('SubCategory', { category_id: item?.Category_Id })} >
                    <View style={{ alignItems: 'flex-end', flex: 1, marginTop: -30, flexDirection: 'row', justifyContent: 'space-between' }}>

                      <Image source={{ uri: item?.Category_Image_Mobile }}
                        style={{
                          height: 100, width: "100%", resizeMode: 'contain',
                          shadowColor: "#CACFD2", borderRadius: 2, shadowRadius: 10, elevation: 10, opacity: 10

                        }}
                      />
                    </View>
                    <View style={{ alignItems: "stretch", marginTop: 11, paddingLeft: 10 }}>
                      <Text style={{ textAlign: 'center', color: esiColor.itemColor, height: 36 }}>{item?.Category_Name}</Text>
                    </View>
                  </Card>
                )
              })
            }
          </View>
        }
        <View style={{ marginBottom: Platform.OS == "web" ? -10 : 5 }}>
          <Pagination pagination={pagination} setPagination={setPagination} />
        </View>
      </ScrollView>

    </Surface>
  );
}
const styles = StyleSheet.create({
  Card: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
  },
});
