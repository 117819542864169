import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import B2BbiddingNavBar from './B2BbiddingNavBar';

const B2BbiddingDashboardLayout = (props: any) => {
  const { navigation } = props;
  const [isMobileNavB2COpen, setMobileNavB2COpen] = useState(true);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
      <B2BbiddingNavBar
        onMobileClose={() => setMobileNavB2COpen(true)}
        openMobile={isMobileNavB2COpen} navigation={navigation}
      />
  );
};
export default B2BbiddingDashboardLayout;
